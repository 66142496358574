import React, { Component } from "react";
import PropTypes from "prop-types";

import { Dialog } from "material-ui";
import { connect } from "react-redux";
import UpdatePatientInfoForm from "./update-patient-info-form";
import {
  loadPatient,
  updatePatientInfo,
  sendResetPasswordEmail,
} from "src/redux/modules/patient";

const connected = connect(
  null,
  { updatePatientInfo, loadPatient, sendResetPasswordEmail }
);
export class UpdatePatientInfo extends Component {
  static contextTypes = {
    patient: PropTypes.object,
  };
  static propTypes = {
    handleClose: PropTypes.func,
    loadPatient: PropTypes.func,
  };
  handleSubmit = patientData => {
    // Move SSN out of patient data into sensitive data.
    const data = Object.assign({}, patientData, {
      sensitive_data: {
        ssn: patientData.ssn,
      },
    });
    delete data.ssn;

    const updatePatient = this.props.updatePatientInfo(
      this.context.patient.id,
      data
    );
    updatePatient.then(() => {
      this.props.loadPatient(this.context.patient.id);
      this.props.handleClose();
    });
    return updatePatient;
  };
  sendResetPasswordEmail = () => {
    this.props.sendResetPasswordEmail(this.context.patient.email);
  };

  render() {
    return (
      <div>
        <Dialog
          title="Update Patient Info"
          modal={false}
          open={this.props.open}
          onRequestClose={this.props.handleClose}
          contentClassName="full-width-dialog-content"
        >
          <UpdatePatientInfoForm
            initialValues={this.context.patient}
            onSubmit={this.handleSubmit}
            sendResetPasswordEmail={this.sendResetPasswordEmail}
          />
        </Dialog>
      </div>
    );
  }
}

export default connected(UpdatePatientInfo);
