import React, { useState } from "react";
import orderBy from "lodash/orderBy";
import styled, { css } from "react-emotion";
import color from "src/styles/color";
import { host, titleCase } from "src/shared/util";
import ImageModal from "src/components/image-modal/ImageModal";
import Template from "./Template";
import { PhotoType } from "./types";

type Props = {
  patientID: string;
  files: { id: string; subtype: string }[];
};

const View: React.FC<Props> = props => {
  const sortedFiles = orderBy(props.files, ["createdAt"], ["desc"]);
  return (
    <>
      <Template
        onRenderItem={typ => {
          const photo = sortedFiles.find(
            f => f.subtype === typ.replace(/ /g, "")
          );
          return <Item photoType={typ} value={photo && photo.id} key={typ} />;
        }}
      />
    </>
  );
};

export default View;

type ItemProps = {
  photoType: PhotoType;
  value: string | undefined;
};

const Item: React.FC<ItemProps> = props => {
  const [showModal, setShowModal] = useState(false);
  const { photoType, value } = props;
  const url = value && `${host()}/api/v2/files/${value}`;

  return (
    <ItemWrapper>
      {showModal && url && (
        <ImageModal
          imageURL={url}
          altText={titleCase(photoType)}
          onClose={() => setShowModal(false)}
        />
      )}
      <div css={uploadWrapperCSS} onClick={() => url && setShowModal(true)}>
        {url ? (
          <img alt={titleCase(photoType)} src={url} />
        ) : (
          <span>{titleCase(photoType)} (no photo)</span>
        )}
      </div>
      <PhotoName>{url && titleCase(photoType)}</PhotoName>
    </ItemWrapper>
  );
};

const textCSS = css`
  color: ${color.gray3};
  font-weight: bold;
  text-align: center;
`;

const PhotoName = styled.p`
  margin: 0;
  height: 1rem;
  ${textCSS};
`;

const uploadWrapperCSS = css`
  flex: 1;
  margin: 0 0 3px !important;
  border: 1px solid ${color.borderLight};
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  > span {
    ${textCSS};
  }

  > img {
    max-height: calc(150px - 1rem - 6px);
    max-width: 100%;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: stretch;
  align-self: stretch;
  height: 150px;
`;
