import React, { useState } from "react";
import styled, { css, cx } from "react-emotion";
import { DateTime } from "luxon";

import { getOrderName } from "./util";
import { Order, Patient } from "src/types/gql";
import money from "src/utils/money";
import color from "src/styles/color";
import ExpandedOrder from "./ExpandedOrder";
import ExpandArrow from "src/shared/ExpandArrow";

type ListProps = {
  patient: Patient;
  orders: Order[];
  refetchOrders?: () => void;
};

const ListWrapper = styled.div``;

const header = css`
  font-size: 14px;
  font-weight: bold;
  padding: 12px 30px;
  > div {
    color: ${color.gray3};
  }
`;

export const OrderList: React.FC<ListProps> = ({
  orders,
  patient,
  refetchOrders,
}) => (
  <ListWrapper>
    <Grid className={header}>
      <div>Date</div>
      <div>Items</div>
      <div>Status</div>
      <div>Total</div>
      <div>Balance</div>
    </Grid>

    {orders.map(o => {
      return (
        <OrderItem
          order={o}
          key={o.id}
          patient={patient}
          refetchOrders={refetchOrders}
        />
      );
    })}
  </ListWrapper>
);

type Props = {
  order: Order;
  patient: Patient;
  refetchOrders?: () => void;
};

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  padding: 20px 30px;
  border-top: 1px solid ${color.border};
  grid-template-columns:
    minmax(100px, 140px)
    auto
    minmax(50px, 80px)
    minmax(100px, 125px)
    minmax(100px, 125px)
    30px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const pointer = css`
  cursor: pointer;
`;

const closed = css`
  opacity: 0.6;
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
`;

export const OrderItem: React.SFC<Props> = ({
  order,
  patient,
  refetchOrders,
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Grid
        onClick={() => setExpanded(!expanded)}
        className={cx([pointer, order.status === "closed" && closed])}
      >
        <div>{DateTime.fromISO(order.createdAt).toFormat("LLLL d, yyyy")}</div>
        <div>{getOrderName(order)}</div>
        <div>{order.status}</div>
        <div>{money(order.total)}</div>
        <div>{money(order.balance)}</div>
        <ArrowContainer>
          <ExpandArrow expanded={expanded} />
        </ArrowContainer>
      </Grid>
      {expanded && (
        <ExpandedOrder
          order={order}
          patient={patient}
          refetchOrders={refetchOrders}
        />
      )}
    </>
  );
};

export default OrderList;
