import { useMemo, useState } from "react";
import { notification } from "antd";

import { useQuery, useMutation } from "src/utils/http/gqlQuery";
import { gqlError } from "src/shared/util";
import { TimelineItem } from "src/state/useTimeline";
import { TrayLog, ArchType } from "src/types/gql";

export type Treatment = {
  id: string;
  treatmentCycles: TC[];
};

type TC = {
  id: string;
  endType?: string;
  startsAt: number;
  maxCount: number;
  cadence: number;
  impressTreatmentPlanID: number;
  phases: Array<{
    id: string;
    phaseType: string;
    appointmentSubtype?: {
      name: string;
    };
    wts: Array<{
      id: string;
      stage: number;
      archType: string;
      isWearable: boolean;
    }>;
  }>;
};

const dataGQL = `
query TimelineAndTC($userID: ID!) {
  timeline: trayTimeline(userID: $userID) {
    trayNumber
    phase
    status
    archType
    start
    end
    plannedEnd
    appointmentSubtypeID
    isWearable
    appointmentSubtype {
      id
      name
    }

    log {
      id
      archType
      stage
      nextStage
      startTime
      endTime
      plannedEndTime
      photos {
        id
        file {
          id
          filename
          type
          createdAt
        }
      }
    }
    logs {
      id
      treatmentCycleID
      archType
      trayBuild {
        id
        serialNumber
        archType
      }
      startTime
      endTime
      plannedEndTime
      stage
      nextStage
      photos {
        id
        file {
          id
          filename
          type
        }
      }
    }
  }

  trayLogs(userID: $userID) {
    id
    treatmentCycleID
    archType
    trayBuild {
      id
      serialNumber
      archType
    }
    startTime
    endTime
    plannedEndTime
    stage
    nextStage
    photos {
      id
      file {
        id
        filename
        type
      }
    }
  }
  treatment: activeOrLastTreatment(userID: $userID) {
    id
    treatmentCycles {
      id
      endType
      startsAt
      maxCount
      cadence
      impressTreatmentPlanID
      phases {
        id
        phaseType
        appointmentSubtype {
          name
        }
        wts: wearTimelineStages {
          id
          stage,
          archType
          isWearable
        }
      }
    }
  }
}
`;

export const useData = (userID: string) => {
  return useQuery<{
    treatment: Treatment;
    timeline: TimelineItem[];
    trayLogs: TrayLog[];
  }>({ query: dataGQL, variables: { userID } });
};

export type PhaseTimelineGroup = {
  phase: string;
  status: string;
  isWearable: boolean;
  appointmentSubtype: { name: string } | null;
  timeline: TimelineItem[];
  // list of all wear timeline stage IDs for this group, needed for the
  // werable toggle.
  wtsIDs: string[];
};

export const useFormattedTimeline = (
  timeline: TimelineItem[],
  treatment: Treatment
): PhaseTimelineGroup[] => {
  // Bucket timelines into phases.  We use the timeline because it blends
  // together wear timeline stage dates and tray log dates, handling
  // all cases for patients during the transition to tray logs.
  return useMemo(() => {
    let currentPhase;
    let results = [] as PhaseTimelineGroup[];

    // iterate thru each timeline item and group by phase
    timeline.forEach(t => {
      if (currentPhase !== t.phase) {
        currentPhase = t.phase;
        // finish the current phase
        results.push({
          phase: t.phase,
          status: t.status,
          appointmentSubtype: t.appointmentSubtype,
          timeline: [],
          wtsIDs: [],
          // isWearable is updated to false below when iterating through logs on the
          // the first non-wearable timeline entry found
          isWearable: true,
        });
      }

      const last = results.length - 1;
      results[last].timeline.push(t);

      if (!t.isWearable) {
        results[last].isWearable = false;
      }

      // in_status is most often found in the middle of a phase.  if this is
      // in_status, mark the phase as such and skip updating.
      if (t.status === "in_progress") {
        results[last].status = t.status;
        return;
      }

      // If the phase isn't in progress, the status is always the same
      // as the last timeline item in the phase..
      if (results[last].status !== "in_progress") {
        results[last].status = t.status;
      }
    });

    // Now we have our items grouped by phase, let's add WTS ids for each phase.
    results.forEach(r => {
      // for every timeline item, find the relevant timeline item and add
      // it to the set.
      const idSet = new Set<string>();

      r.timeline.forEach(timeline => {
        treatment.treatmentCycles.forEach(tc => {
          tc.phases.forEach(p => {
            p.wts.forEach(wts => {
              // add upper and lower if the tray # matches.  timelines right now
              // are only for upper
              if (wts.stage === timeline.trayNumber) {
                idSet.add(wts.id);
              }
            });
          });
        });
      });

      r.wtsIDs = r.wtsIDs.concat(Array.from(idSet));
    });

    return results;
  }, [timeline, treatment.treatmentCycles]);
};

export const useUpdateTrayLogs = () => {
  const [, execute] = useMutation(updateTrayLogs);
  return execute;
};

export const useSetPhaseIsWearable = () => {
  const [, execute] = useMutation(setPhaseIsWearable);
  return execute;
};

export const useUpdateWearable = () => {
  const [, execute] = useMutation(setPhaseIsWearable);
  const [saving, setSaving] = useState(false);

  return async (ids: string[], wearable: boolean) => {
    if (saving) {
      return;
    }

    setSaving(true);
    const result = await execute({
      input: {
        wearTimelineStageIDs: ids,
        isWearable: wearable,
      },
    });
    setSaving(false);
    if (result.error) {
      return notification.error({
        message: `Error updating trays as ${
          wearable ? "wearable" : "unwearable"
        }: ${gqlError(result.error)}`,
      });
    }
    notification.success({
      message: `The trays have been marked as ${
        wearable ? "wearable" : "unwearable"
      }`,
    });
  };
};

// useSetInProgress returns an async function which creates new tray logs
// for the given tray timeline, setting them as "in progress".
export const useSetInProgress = () => {
  const [, execute] = useMutation(setInProgressGQL);
  const [saving, setSaving] = useState(false);

  return async (trayNumber: number, userID: string, archType: ArchType) => {
    if (saving) {
      return;
    }

    const base = {
      userId: userID,
      stage: trayNumber,
    };

    var trayLogs;
    if (archType === "both") {
      trayLogs = [
        Object.assign({}, base, { archType: "upper" }),
        Object.assign({}, base, { archType: "lower" }),
      ];
    } else {
      trayLogs = [Object.assign({}, base, { archType: archType })];
    }

    setSaving(true);
    const result = await execute({
      trayLogs: trayLogs,
    });
    setSaving(false);
    if (result.error) {
      return notification.error({
        message: `Error making this tray current: ${gqlError(result.error)}`,
      });
    }
    notification.success({
      message: `Tray ${trayNumber} is now the current tray.  Change the start date, if necessary.`,
    });
  };
};

export const useAssociatePhoto = () => {
  const [, execute] = useMutation(associateFileGQL);
  const [saving, setSaving] = useState(false);

  return async (fileID: string, trayLogID: string) => {
    if (saving) {
      return;
    }
    setSaving(true);
    const result = await execute({ input: [{ fileID, trayLogID }] });
    setSaving(false);
    if (result.error) {
      return notification.error({
        message: `Error assocaiting photo: ${gqlError(result.error)}`,
      });
    }
    notification.success({ message: `Photo uploaded` });
  };
};

export const useUpdateCadence = () => {
  const [, execute] = useMutation(updateCadenceGQL);
  const [saving, setSaving] = useState(false);

  return async (userID: string, cadence: number) => {
    if (saving) {
      return;
    }
    setSaving(true);
    const result = await execute({ userID, cadence });
    setSaving(false);
    if (result.error) {
      return notification.error({
        message: `Error updating cadence: ${gqlError(result.error)}`,
      });
    }
    notification.success({ message: `Cadence updated` });
  };
};

// useDeleteTrayLogs returns a function to delete tray logs from the timeline.
export const useDeleteTrayLogs = () => {
  const [, execute] = useMutation(deleteTrayLogsGQL);
  const [saving, setSaving] = useState(false);

  return async (userID: string, ids: string[]) => {
    if (saving) {
      return;
    }
    setSaving(true);
    const result = await execute({ userID, ids });
    setSaving(false);
    if (result.error) {
      return notification.error({
        message: `Error deleting tray logs: ${gqlError(result.error)}`,
      });
    }
    notification.success({ message: `Tray logs deleted` });
  };
};

// useDeleteStages returns a function to delete the underlying wear timeline stages
// from a patient's treatment.  These stages will be removed from the timeline permanently.
export const useDeleteStages = () => {
  const [, execute] = useMutation(deleteStagesGQL);
  const [saving, setSaving] = useState(false);

  return async (userID: string, ids: string[]) => {
    if (saving) {
      return;
    }
    setSaving(true);
    const result = await execute({ userID, ids });
    setSaving(false);
    if (result.error) {
      return notification.error({
        message: `Error deleting stages: ${gqlError(result.error)}`,
      });
    }
    notification.success({ message: `Stages deleted` });
  };
};

const timelineFields = `
trayNumber
phase
status
archType
start
end
plannedEnd
appointmentSubtypeID
isWearable
appointmentSubtype {
  id
  name
}

log {
  id
  archType
  stage
  nextStage
  startTime
  endTime
  plannedEndTime
  photos {
    id
    file {
      id
      filename
      type
      createdAt
    }
  }
}
`;

const deleteTrayLogsGQL = `
  mutation DeleteTrayLogs($userID: ID!, $ids: [ID!]!) {
    deleteTrayLogs(userID: $userID, ids: $ids) {
      ${timelineFields}
    }
  }
`;

const setInProgressGQL = `
  mutation AddTrayLogs($trayLogs: [AddTrayLog!]!) {
    addTrayLogs(addTrayLogs: $trayLogs) {
      id
    }
  }
`;

const updateCadenceGQL = `
  mutation UpdateCadence($userID: ID!, $cadence: Int!) {
    updateCadence(userID: $userID, cadence: $cadence) {
      ${timelineFields}
    }
  }
`;

const updateTrayLogs = `
  mutation UpdateTrayLog($updateTrayLogs: [UpdateTrayLog!]!) {
    updateTrayLogs(updateTrayLogs: $updateTrayLogs) {
      id
      startTime
      endTime
      plannedEndTime
      nextStage
    }
  }
`;

const setPhaseIsWearable = `
  mutation SetPhaseIsWearable($input: SetStagesIsWearable!) {
    setStagesIsWearable(setStagesIsWearable: $input) {
      id
      stage
      isWearable
    }
  }
`;

const deleteStagesGQL = `
  mutation DeleteStages($userID: ID!, $ids: [ID!]!) {
    deleteStages(userID: $userID, ids: $ids) {
      ${timelineFields}
    }
  }
`;

const associateFileGQL = `
  mutation AddTrayLogPhotos($input: [AddTrayLogPhoto!]!) {
    addTrayLogPhotos(addTrayLogPhotos: $input) {
      id trayLogID
    }
  }
`;
