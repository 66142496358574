import React, { useEffect, useState, useContext } from "react";
import styled from "react-emotion";
import gql from "graphql-tag";
import { AutoComplete } from "antd";
import { pipe, subscribe } from "wonka";
import { debounce } from "lodash";

import MagnifyingGlass from "src/shared/Icons/MagnifyingGlass";
import { Context, createRequest } from "src/utils/http/gqlQuery";
import Small from "./Small";
import Basic from "./Basic";
import color from "src/styles/color";

type Props = {
  onChange: (p: BaseUser) => void;
  view?: View;
  query?: any;

  style?: any;
  className?: string;
};

type View = "small" | "basic";

// The response type we fetch when querying.
export type BaseUser = {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  pronouns: string;
  mobilePhoneNumber: string;
  email: string;
};

type SearchState = {
  fetching: boolean;
  error: boolean;
  data: Array<BaseUser>;
};

const Perimeter = styled.div`
  position: relative;

  svg {
    position: absolute;
    z-index: 1;
    top: 50%;
    margin-top: -6px;
    left: 12px;
  }
`;

const StyledAutoComplete = styled(AutoComplete)`
  height: 100%;
  width: 100%;
  autocorrect: "off";
  autocapitalize: "off";
  spellcheck: "false";

  & input {
    padding-left: 32px;
    border-radius: 2px !important;

    &:hover,
    &:focus {
      border-color: ${color.primary} !important;
    }

    &::placeholder {
      color: ${color.gray2};
    }
  }
`;

const defaultQuery = gql`
  query SearchUser($search: String, $page: Int, $perPage: Int) {
    patients: patients(search: $search)
      @paginated(page: $page, perPage: $perPage) {
      id
      name
      firstName
      lastName
      preferredName
      pronouns
      email
      mobilePhoneNumber
    }
  }
`;

// doSearch runs a debounced grapqhl query without being a hook, and calls setResults
// once the query has ran.
const doSearch = debounce((input, results, setResults, client, query) => {
  if (input === "") {
    // Don't search for all patients
    return;
  }
  // Reset the state, but keep the previous results the same
  setResults({ fetching: true, error: false, data: results.data });

  // Create a new GQL reques **without a hook** so that we can call this via side effects.
  // Note that we can't call useQuery within useEffect as hooks must be called within the main
  // body of a component.  That means that, for search, hooks are out of the question as
  // we'd *have* to invoke search with no terms.

  // TODO: refactor to make use of useQuery instead of this function
  const request = createRequest(query, { search: input, page: 1, perPage: 25 });
  const { unsubscribe } = pipe(
    client.executeQuery(request),
    subscribe((data: any) => {
      if (data && data.data && data.data.patients) {
        setResults({
          fetching: false,
          error: false,
          data: data.data.patients.slice(0, 25),
        });
      }
    })
  );
  return unsubscribe;
}, 200);

const PatientSearch = ({ onChange, view, style, className, query }: Props) => {
  // We need  the urql client so that we can call a GQL function without it being a hook.
  // We can call GQL conditionally
  const client = useContext(Context);
  const [value, setValue] = useState("");

  // Keep track of the graphql state ourselves
  const [results, setResults] = useState<SearchState>({
    fetching: false,
    data: [],
    error: false,
  });

  useEffect(() => {
    return doSearch(value, results, setResults, client, query || defaultQuery);
    // We don't want client/results in here as these are by reference and will cause search
    // to be requested in an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const C = (() => {
    switch (view) {
      case "small":
        return Small;
      default:
        return Basic;
    }
  })();

  return (
    <Perimeter style={style} className={className}>
      <MagnifyingGlass />
      <StyledAutoComplete
        style={{ width: "100%" }}
        value={value}
        onChange={id => setValue(id as string)}
        optionLabelProp="title"
        onSelect={id => {
          const patient = results.data.find(p => p.id === id);
          if (patient) {
            onChange(patient);
          }
        }}
      >
        {results.data.map(p => (
          <AutoComplete.Option key={p.id} value={p.id} title={p.name}>
            <C patient={p} />
          </AutoComplete.Option>
        ))}
      </StyledAutoComplete>
    </Perimeter>
  );
};

export default PatientSearch;
