import React from "react";
import Visa from "src/shared/Icons/Visa";
import Amex from "src/shared/Icons/Amex";
import Mastercard from "src/shared/Icons/Mastercard";
import Discover from "src/shared/Icons/Discover";

export const getCardIcon = (brand: string) => {
  switch (brand) {
    case "amex":
      return <Amex />;
    case "mastercard":
      return <Mastercard />;
    case "discover":
      return <Discover />;
    case "visa":
      return <Visa />;
  }
  return null;
};
