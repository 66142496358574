import React, { createContext, useContext } from "react";
import gql from "graphql-tag";

import { useQuery } from "src/utils/http/gqlQuery";
import {
  Clinic,
  Staff,
  Doctor,
  AppointmentSubtype,
  AppointmentType,
} from "src/types/gql";

const query = gql`
  query SchedulingData {
    clinics: clinics {
      id
      name
      nickname
      city
      region
      type
      line1
      timezone
      BookableResources {
        id
        name
      }
      Staff {
        id
      }
    }
    doctors: staff(role: "orthodontist") {
      id
      firstName
      lastName
    }
    staff: staff(role: "sales_manager") {
      id
      firstName
      lastName
    }
    types: appointmentTypes {
      id
      name
      enabled
      doctorDuration
      largestPatientBuffer
      largestRemainingDuration
    }
    subtypes: appointmentSubtypes {
      id
      name
      enabled
      doctorOffset
      appointmentDuration
      appointmentType {
        id
        name
      }
    }
  }
`;

type Data = {
  clinics: Array<Clinic>;
  doctors: Array<Doctor>;
  staff: Array<Staff>;
  doctorOptions: Array<{ value: string; label: string }>;
  staffOptions: Array<{ value: string; label: string }>;
  types: Array<AppointmentType>;
  subtypes: Array<AppointmentSubtype>;
};

const SchedulingContext = createContext<Data>({
  clinics: [],
  doctors: [],
  staff: [],
  doctorOptions: [],
  staffOptions: [],
  types: [],
  subtypes: [],
});

export const SchedulingContextProvider = props => {
  const [{ fetching, data, error }] = useQuery({ query });

  if (fetching) {
    // TODO: Loading indicator
    return null;
  }

  if (error) {
    // TODO: Error message
    return null;
  }

  const { clinics, staff, doctors, types, subtypes } = data;
  const doctorOptions = doctors
    .sort((a, b) => a.firstName.localeCompare(b.firstName))
    .map(d => ({
      value: d.id,
      label: `${d.firstName} ${d.lastName}`,
    }));
  const staffOptions = staff
    .sort((a, b) => a.firstName.localeCompare(b.firstName))
    .map(d => ({
      value: d.id,
      label: `${d.firstName} ${d.lastName}`,
    }));

  return (
    <SchedulingContext.Provider
      value={{
        clinics,
        doctors,
        staff,
        types,
        subtypes,
        doctorOptions,
        staffOptions,
      }}
    >
      {props.children}
    </SchedulingContext.Provider>
  );
};

export default function useSchedulingContext() {
  return useContext(SchedulingContext);
}
