import React from "react";
import Item from "./item";
import style from "./style";

const Labels = ({ location: { query } }) => (
  <div style={style.base}>
    <div style={style.page}>
      <div style={style.inner}>
        {new Array(10).fill(1).map((_, i) => {
          const idx = i;
          const stageStart = (idx + 1) * 3 - 2;

          // The last two rows on page 1 have phone numbers
          if (idx >= 8) {
            return (
              <div style={style.row} key={idx}>
                <Item patient={query.name} text={query.phone} />
                <Item patient={query.name} text={query.phone} />
                <Item patient={query.name} text={query.phone} />
              </div>
            );
          }

          return (
            <div style={style.row} key={idx}>
              <Item patient={query.name} text={`Stage ${stageStart}`} />
              <Item patient={query.name} text={`Stage ${stageStart + 1}`} />
              <Item patient={query.name} text={`Stage ${stageStart + 2}`} />
            </div>
          );
        })}
      </div>
    </div>
    <div style={style.page}>
      <div style={style.inner}>
        {new Array(10).fill(1).map((_, i) => {
          const idx = i + 8;
          const stageStart = (idx + 1) * 3 - 2;
          return (
            <div style={style.row} key={idx}>
              <Item patient={query.name} text={`Stage ${stageStart}`} />
              <Item patient={query.name} text={`Stage ${stageStart + 1}`} />
              <Item patient={query.name} text={`Stage ${stageStart + 2}`} />
            </div>
          );
        })}
      </div>
    </div>
    <div style={style.page}>
      <div style={style.inner}>
        {new Array(10).fill(1).map((_, i) => {
          const idx = i + 18;
          const stageStart = (idx + 1) * 3 - 2;
          return (
            <div style={style.row} key={idx}>
              <Item patient={query.name} text={`Stage ${stageStart}`} />
              <Item patient={query.name} text={`Stage ${stageStart + 1}`} />
              <Item patient={query.name} text={`Stage ${stageStart + 2}`} />
            </div>
          );
        })}
      </div>
    </div>
  </div>
);

export default Labels;
