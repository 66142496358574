import React from "react";
import { DateTime } from "luxon";
import Button from "src/shared/Button";
import { Task } from "../../types";
import { Item, Wrapper } from "./styled";

type Props = {
  task: Task;
  onChangeContent: (to: string | null) => void;
};

// References are either references linked to from the task, or "fake" references used as
// buttons to link to task-specific content.
const References = props => {
  const { task } = props;

  if (task.references.length === 0) {
    return <p>No build created.</p>;
  }

  return (
    <>
      {task.references.map(b => (
        <Wrapper>
          <Button
            kind="invisible"
            onClick={() => props.onChangeContent(["qa", b.id])}
          >
            <Item>
              QA Build (created{" "}
              {DateTime.fromISO(b.model.createdAt).toFormat("DDD, t")})
            </Item>
          </Button>
        </Wrapper>
      ))}
    </>
  );
};

export default References;
