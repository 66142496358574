import React, { useState, useEffect } from "react";
import moment from "moment";
import { isEmpty, get } from "lodash";
import { Modal, Form, DatePicker } from "antd";
import { Treatment, DismissalReason } from "src/types/gql";
import {
  useUpdateTreatment,
  UpdateTreatmentType,
} from "src/state/useTreatments";
import Button from "src/shared/Button";
import color from "src/styles/color";
import Loading from "src/shared/Loading";
import { ButtonStyle } from "./styles";

type AsyncState = {
  state: "none" | "loading" | "error";
  error?: string | undefined;
};

interface Props {
  treatment: Treatment;
  visible: boolean;
  setVisible(visible: boolean): void;
}

const TreatmentEdit = (props: Props) => {
  const { treatment, setVisible } = props;
  const [updatedTreatment, setUpdatedTreatment] = useState<UpdateTreatmentType>(
    {} as UpdateTreatmentType
  );
  const [deleteFields, setDeleteFields] = useState<Array<string>>([]);

  useEffect(() => {
    if (treatment && treatment.id) {
      setUpdatedTreatment({
        id: treatment.id,
        startDate: treatment.startDate,
        endDate: treatment.endDate,
        dismissalReason: treatment.dismissalReason,
      });
    }
  }, [treatment]);

  const updateTreatment = useUpdateTreatment();

  const [status, setStatus] = useState<AsyncState>({ state: "none" });

  const addToDeletesField = (property: string) => {
    let fieldsCopy = deleteFields;
    if (!fieldsCopy.find(f => f === property)) {
      fieldsCopy.push(property);
      setDeleteFields(fieldsCopy);
    }
  };

  const removeDeletesField = (property: string) => {
    let filteredFields = deleteFields.filter(f => f !== property);
    setDeleteFields(filteredFields);
  };

  const onOk = async () => {
    if (status.state === "loading" || isEmpty(updatedTreatment)) {
      return;
    }

    setStatus({ state: "loading" });
    const result = await updateTreatment({
      updateRequest: updatedTreatment,
      deleteFields: deleteFields,
    });
    if (result.error) {
      setStatus({
        state: "error",
        error: result.error && result.error.message,
      });
      return;
    }
    setStatus({ state: "none", error: undefined });
    setVisible(false);
  };

  if (isEmpty(updatedTreatment)) {
    return <Loading />;
  }

  return (
    <Modal
      title="Edit patient's treatment"
      onOk={onOk}
      onCancel={() => setVisible(false)}
      visible={props.visible}
      destroyOnClose={true}
      footer={[
        <Button
          className={ButtonStyle}
          key="back"
          onClick={() => setVisible(false)}
        >
          Cancel
        </Button>,
        <Button
          className={ButtonStyle}
          key="submit"
          type="primary"
          loading={status.state === "loading"}
          onClick={onOk}
        >
          Update
        </Button>,
      ]}
    >
      <Form>
        <Form.Item label="Start date (when paid for treatment)">
          <DatePicker
            defaultValue={
              get(updatedTreatment, "startDate")
                ? moment(updatedTreatment.startDate)
                : undefined
            }
            allowClear={false}
            onChange={(d, ds) =>
              setUpdatedTreatment(
                Object.assign({}, updatedTreatment, {
                  startDate: d ? d.toISOString() : undefined,
                })
              )
            }
          />
        </Form.Item>
        <Form.Item label="Graduation date">
          <DatePicker
            defaultValue={
              get(updatedTreatment, "endDate")
                ? moment(updatedTreatment.endDate)
                : undefined
            }
            onChange={(d, ds) => {
              if (!d) {
                addToDeletesField("EndDate");
              } else {
                removeDeletesField("EndDate");
              }
              setUpdatedTreatment(
                Object.assign({}, updatedTreatment, {
                  endDate: d ? d.toISOString() : undefined,
                })
              );
            }}
          />
        </Form.Item>

        <Form.Item label="Dismissal Reason">
          <select
            defaultValue={treatment.dismissalReason}
            value={updatedTreatment.dismissalReason}
            onChange={e => {
              if (!e.target.value) {
                addToDeletesField("DismissedByID");
                addToDeletesField("DismissalReason");
                setUpdatedTreatment({
                  ...updatedTreatment,
                  dismissalReason: undefined,
                });
                return;
              }

              setUpdatedTreatment({
                ...updatedTreatment,
                dismissalReason: e.target.value as DismissalReason,
              });
            }}
          >
            <optgroup label="">
              <option value="">Not Dismissed</option>
            </optgroup>
            <optgroup label="Dismissed">
              <option value="patient_led">Patient Led</option>
              <option value="practice_led">Practice Led</option>
              <option value="willful_lack_of_communication">
                Willful Lack of Communication
              </option>
            </optgroup>
          </select>
        </Form.Item>
      </Form>
      {/* TODO: Added Dismiss button */}

      {status.error && (
        <p style={{ color: color.red, textAlign: "right" }}>{status.error}</p>
      )}
    </Modal>
  );
};

export default TreatmentEdit;
