import React from "react";
import { notification } from "antd";

import { useQuery } from "src/utils/http/gqlQuery";
import ConfirmModal from "src/shared/ConfirmModal";
import { Discount } from "src/types/gql";
import { useRemoveDiscount } from "../query";
import { orderGql } from "../query";

type Props = {
  patientID: string;
  onClose: () => void;
  discount: Discount;
};

const RemovePromoModal: React.FC<Props> = ({
  onClose,
  patientID,
  discount,
}) => {
  const [, refetch] = useQuery({
    query: orderGql,
    variables: { userID: patientID },
    pause: true,
  });

  const removeDiscount = useRemoveDiscount();

  const onClick = async () => {
    const result = await removeDiscount({
      input: { discountID: discount.id },
    });
    if (result.error) {
      notification.error({
        message: "There was an error removing this promo code",
      });
      return;
    }
    notification.success({ message: "Promo code removed" });

    // refetch all orders and clear the cache
    refetch();
    onClose();
  };

  return (
    <ConfirmModal
      onClose={onClose}
      onConfirm={onClick}
      type="delete"
      title="Are you sure?"
      text="Do you really want to remove this promo code?"
      actionText="Remove"
    />
  );
};

export default RemovePromoModal;
