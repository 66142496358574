import React, { useMemo } from "react";
import { DateTime } from "luxon";
import { diffInDays } from "src/shared/time";
import { titleCase } from "src/shared/util";
// local
import TreatmentTimelineSkeleton from "./TreatmentTimelineSkeleton";
import {
  CheckCircle,
  TrayCircle,
  EmptyCircle,
  Diamond,
  EmptyFilledCircle,
} from "./Circles";
import {
  Perimeter,
  Row,
  InnerRow,
  Line,
  ProgressBar,
  EventLabel,
  Label,
  Date,
  eventLabelTopOffset,
} from "./styles";
import { useKeyDates, Props } from "./util";

const TreatmentTimeline = (props: Props) => {
  const { treatment, appointments, timeline } = props;

  // There are four key dates for a timeline:
  //
  // 1: BV, always the first item
  // 2: Date of first tray
  // 3: Date of last planned tray
  // 4: Est'd treatment end
  //
  // Either #3 or #4 will be the last date:
  // - #3 will be the last date if tx time is running over estimate
  // - #4 will be the last date if we haven't planned all of tx
  //
  // There are several other important dates to denote along this timeline:
  //
  // - Date of payment (not yet shown)
  // - Date of each visit

  const dates = useKeyDates(props);
  const { bv, currentTrayStart, totalDays } = dates;

  if (!bv) {
    return null;
  }

  const getPercent = (date: DateTime) =>
    bv && totalDays ? (diffInDays(date, bv) / totalDays) * 100 : 0;
  const progressPercent = getPercent(currentTrayStart);

  // eslint-disable-next-line
  const phases = useMemo(() => {
    const result = [] as Array<{
      phase: string;
      date: string | null;
      appt?: string | null;
    }>;
    let last: string;
    timeline.forEach(tl => {
      if (last !== tl.phase) {
        result.push({
          phase: tl.phase,
          date: tl.start || tl.plannedEnd,
          appt: tl.appointmentSubtype && tl.appointmentSubtype.name,
        });
        last = tl.phase;
      }
    });

    // If the last tl item isn't a retainer, add an expected refinement.
    const lastTL = timeline[timeline.length - 1];
    if (lastTL && lastTL.phase !== "retainer") {
      result.push({
        phase: lastTL.phase,
        date: lastTL.plannedEnd,
        appt: "ref_grad",
      });
    }
    return result;
  }, [timeline]);

  if (props.loadingTreatment) {
    return <TreatmentTimelineSkeleton />;
  }

  if (!treatment) {
    return null;
  }

  return (
    <Perimeter>
      <Row>
        <InnerRow>
          <Line />
          <ProgressBar style={{ width: `${progressPercent}%` }} />
          {/* current tray */}
          <TrayCircle style={{ left: `${progressPercent}%`, zIndex: 2 }}>
            {treatment.currentTrayNumber}
          </TrayCircle>

          {appointments.map(a => {
            const C =
              currentTrayStart &&
              currentTrayStart.toISODate() < (a.startTime as string)
                ? EmptyFilledCircle
                : CheckCircle;
            const left = getPercent(DateTime.fromISO(a.startTime as string));
            return <C style={{ left: `${left}%`, zIndex: 1 }} key={a.id} />;
          })}

          {/* list phases in timeline */}
          {phases.map((p, n) => {
            if (!p.date) {
              return null;
            }
            return (
              <Diamond
                theme={{
                  filled:
                    currentTrayStart && p.date < currentTrayStart.toISODate(),
                }}
                style={{ left: `${getPercent(DateTime.fromISO(p.date))}%` }}
                key={n}
              />
            );
          })}

          {/* list upcoming appointments */}
          {phases.map((p, n) => {
            if (
              !p.appt ||
              !p.date ||
              (currentTrayStart && p.date < currentTrayStart.toISODate())
            ) {
              return null;
            }

            const left = getPercent(DateTime.fromISO(p.date));
            return <EmptyCircle style={{ left: `${left}%` }} key={n} />;
          })}
        </InnerRow>
      </Row>

      {/*Labels */}
      <Row style={{ height: "auto", marginTop: "20px" }}>
        <InnerRow>
          {appointments.map((a, n) => {
            const left = getPercent(DateTime.fromISO(a.startTime as string));
            return (
              <EventLabel
                style={{
                  left: `calc(${left}% - 40px)`,
                  top: -eventLabelTopOffset,
                  alignItems: "center",
                }}
                key={n}
              >
                <Label>
                  {titleCase(
                    a.appointmentSubtype
                      ? a.appointmentSubtype.name
                      : a.appointmentType.name
                  )}
                </Label>
                <Date>
                  {DateTime.fromISO(a.startTime as string).toISODate()}
                </Date>
              </EventLabel>
            );
          })}

          {phases.map((p, n) => {
            if (!p.date) {
              return null;
            }
            const left = getPercent(DateTime.fromISO(p.date));
            return (
              <EventLabel
                style={{ left: `calc(${left}% - 50px)`, alignItems: "center" }}
                key={n}
              >
                <Label>{titleCase(p.phase)}</Label>
                <Date>{DateTime.fromISO(p.date).toISODate()}</Date>
              </EventLabel>
            );
          })}

          {/*anticipated appts */}
          {phases.map((p, n) => {
            if (
              !p.appt ||
              !p.date ||
              (currentTrayStart && p.date < currentTrayStart.toISODate())
            ) {
              return null;
            }
            const left = getPercent(DateTime.fromISO(p.date));
            return (
              <EventLabel
                style={{
                  left: `calc(${left}% - 40px)`,
                  top: -eventLabelTopOffset,
                  alignItems: "center",
                  opacity: 0.7,
                }}
                key={n}
              >
                <Label>Exp. {titleCase(p.appt)}</Label>
                <Date>{DateTime.fromISO(p.date).toISODate()}</Date>
              </EventLabel>
            );
          })}
        </InnerRow>
      </Row>
    </Perimeter>
  );
};

export default TreatmentTimeline;
