import React, { useState } from "react";
import styled, { css } from "react-emotion";
import { groupBy } from "lodash";
import { notification, Tabs } from "antd";
import { AddProduct, Patient } from "src/types/gql";

import Box from "src/shared/Box";
import Button, { ButtonRow } from "src/shared/Button";
import Loading from "src/shared/Loading";
import Modal from "src/shared/Modal";
import Error from "src/shared/Error";
import color from "src/styles/color";
import { useCreateOrder } from "./query";
import { useAddableProducts } from "../query";
import { LineItemList } from "./LineItem";

type Props = {
  patient: Patient;
  onClose: () => void;
};

type AsyncState = {
  state: "none" | "loading" | "error";
  error?: string | undefined;
};

const Content = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  flex: 1;

  height: 500px;
  width: 650px;
`;

const ErrorWrapper = styled.div`
  color: ${color.red};
`;

const StyledTabs = styled(Tabs)`
  overflow-y: scroll;

  > .ant-tabs-bar {
    position: fixed;
    width: 650px;
    background-color: white;
  }

  .ant-tabs-tab {
    font-weight: 600;
    font-size: 16px;
    color: ${color.gray3};

    // override antd default (blue)
    &:hover {
      color: ${color.primary};
    }
  }

  .ant-tabs-tab-active {
    color: ${color.primary};

    // override antd default (blue)
    &:hover {
      color: ${color.primary};
    }
  }

  .ant-tabs-ink-bar {
    background-color: ${color.primary};
  }

  .ant-tabs-tab:first-of-type {
    margin-left: 17px;
  }

  .ant-tabs-content {
    margin-top: 46px;
  }
`;

const StyledButtonRow = styled(ButtonRow)`
  border-top: 0.5px solid ${color.border};
`;

const NewOrder: React.FC<Props> = ({ onClose, patient }) => {
  const [selected, setSelected] = useState<AddProduct[]>([]);
  const [fetching, error, products] = useAddableProducts();
  const [status, setStatus] = useState<AsyncState>({ state: "none" });
  const createOrder = useCreateOrder();

  const onSubmit = async () => {
    if (selected.length === 0 || status.state === "loading") {
      return;
    }
    setStatus({ state: "loading" });

    const result = await createOrder({
      input: {
        userId: patient.id,
        items: selected,
        discounts: [],
      },
    });

    if (result.error) {
      setStatus({
        state: "error",
        error: result ? result.error.message : "Something happened",
      });
      return;
    }

    notification.success({
      message: "Order created",
    });

    onClose();
  };

  if (fetching) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }
  const productsByType = groupBy(products, "type");
  return (
    <Modal onClose={onClose}>
      <Box title="Create order">
        <Content>
          <StyledTabs defaultActiveKey="treatment">
            <Tabs.TabPane tab="Treatment" key="treatment">
              <LineItemList
                products={productsByType["treatment"]}
                selected={selected}
                onChange={setSelected}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Replacement" key="item">
              <LineItemList
                products={productsByType["item"]}
                selected={selected}
                onChange={setSelected}
                allowQuantity
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Extra services" key="service">
              <LineItemList
                products={productsByType["service"]}
                selected={selected}
                onChange={setSelected}
                allowQuantity
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Manufacturing" key="manufacturing">
              <LineItemList
                products={productsByType["manufacturing"]}
                selected={selected}
                onChange={setSelected}
              />
            </Tabs.TabPane>
          </StyledTabs>

          <StyledButtonRow
            position="right"
            className={css`
              padding: 16px 30px;
            `}
          >
            {status.error && <ErrorWrapper>{status.error}</ErrorWrapper>}
            <Button onClick={onClose}>Cancel</Button>
            <Button
              kind="primary"
              disabled={selected.length === 0 || status.state === "loading"}
              onClick={onSubmit}
            >
              {status.state === "loading" ? "Saving..." : "Create"}
            </Button>
          </StyledButtonRow>
        </Content>
      </Box>
    </Modal>
  );
};

export default NewOrder;
