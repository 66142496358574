import { kea } from "kea";
import { request } from "src/shared/util";
import {
  InternalNoteReference,
  InternalNote,
  CreateInternalNoteRequest,
} from "src/types/api";

interface Modifiers {
  replaceNotesForRef(
    ref: InternalNoteReference,
    notes: Array<InternalNote>
  ): replaceNotes;
  addNote(note: InternalNote): InternalNote;
  replaceNote(note: InternalNote): InternalNote;
}

interface Thunks {
  getInternalNotes(ref: InternalNoteReference): Promise<unknown>;
  updateInternalNote(note: InternalNote): Promise<unknown>;
  createInternalNote(note: CreateInternalNoteRequest): Promise<unknown>;
}

export type Actions = Modifiers & Thunks;

export interface ReducerProps {
  notes: Array<InternalNote>;
}

export type Props = ReducerProps & {
  actions: Actions;
};

// Action specific types
type replaceNotes = {
  ref: InternalNoteReference;
  notes: Array<InternalNote>;
};

export const InternalNotesEnhancer = kea({
  actions: () => ({
    // replaceNotesForRef is used to replace all existing notes within the
    // current state for a reference with new notes.
    //
    // This allows us to keep all previous state, request new notes, then
    // replace - ensuring that the UI is up to date without jank.
    replaceNotesForRef: (
      ref: InternalNoteReference,
      notes: Array<InternalNote>
    ): replaceNotes => ({
      ref,
      notes,
    }),

    addNote: (note: InternalNote) => note,
    replaceNote: (note: InternalNote) => note,
  }),

  reducers: ({ actions }) => ({
    notes: [
      [],
      {
        [actions.replaceNotesForRef]: (
          prev: Array<InternalNote>,
          replace: replaceNotes
        ): Array<InternalNote> => {
          // Remove all notes for the given reference.
          const filtered = prev.filter(
            (i: InternalNote) =>
              i.model_id !== replace.ref.model_id &&
              i.model_type !== replace.ref.model_type
          );
          return filtered.concat(replace.notes);
        },

        [actions.addNote]: (
          prev: Array<InternalNote>,
          note: InternalNote
        ): Array<InternalNote> => {
          return [note].concat(prev);
        },

        [actions.replaceNote]: (
          prev: Array<InternalNote>,
          note: InternalNote
        ): Array<InternalNote> => {
          // Remove all notes for the given reference.
          const filtered = prev.filter((i: InternalNote) => i.id !== note.id);
          return [note].concat(filtered);
        },
      },
    ],
  }),

  thunks: ({ actions }: { actions: Modifiers }) => ({
    getInternalNotes: async (ref: InternalNoteReference) => {
      // Expect an array of notes here.
      const result = await request(
        `/api/v1/internal_notes?model_type=${ref.model_type}&model_id=${
          ref.model_id
        }`
      );
      if (Array.isArray(result)) {
        actions.replaceNotesForRef(ref, result);
      }
    },

    createInternalNote: async (note: CreateInternalNoteRequest) => {
      const result = await request(`/api/v1/internal_notes`, {
        method: "POST",
        body: JSON.stringify(note),
      });

      if (result.id) {
        actions.addNote(result);
      }
    },

    updateInternalNote: async (note: InternalNote) => {
      const result = await request(`/api/v1/internal_notes/${note.id}`, {
        method: "PUT",
        body: JSON.stringify(note),
      });

      if (result.id) {
        actions.replaceNote(result);
      }
    },
  }),
});
