import React from "react";
import { Section, SectionTitle, Divider, Continue } from "./styledComponents";
import { renderTitlegroupQuestions } from "./consts";

const Diagnosis = props => {
  const { ui } = props;

  return (
    <>
      <Section>
        <SectionTitle>Extraoral</SectionTitle>
        {renderTitlegroupQuestions(ui, "Extraoral")}
      </Section>
      <Divider />

      <Section>
        <SectionTitle>Skeletal</SectionTitle>
        {renderTitlegroupQuestions(ui, "Skeletal")}
      </Section>
      <Divider />

      <Section>
        <SectionTitle>Oral Health</SectionTitle>
        {renderTitlegroupQuestions(ui, "Oral Health")}
      </Section>
      <Divider />

      <Section>
        <SectionTitle>Intraoral</SectionTitle>
        {renderTitlegroupQuestions(ui, "Intraoral", [])}
      </Section>

      <Section>
        <SectionTitle>Diagnosis Summary</SectionTitle>
        {renderTitlegroupQuestions(ui, "Diagnosis Summary")}
      </Section>
      <Divider />

      <Section>
        <SectionTitle>Next Steps</SectionTitle>
        {renderTitlegroupQuestions(ui, "Next Steps")}
      </Section>

      <Continue next={props.next} />
    </>
  );
};

export default Diagnosis;
