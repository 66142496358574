import React, { useCallback } from "react";
import styled, { css } from "react-emotion";
import { get, debounce } from "lodash";
import { Checkbox as BaseCheckBox } from "antd";
import color from "src/styles/color";

const Perimeter = styled.div`
  position: relative;
  display: inline-block;
  max-width: 400px;
  width: 100%;
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${color.primary};
    border-color: ${color.primary};
  }
  & .ant-checkbox-checked::after {
    border-color: ${color.primary};
  }

  & .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${color.primary};
  }
`;

const Checkbox = styled(BaseCheckBox)`
  margin: 14px 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InnerRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(50% - 8px);
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 16px;
  align-items: center;
  ${({ theme: { first } }) =>
    first &&
    css`
      margin-left: 0;
    `}

  ${({ theme: { last } }) =>
    last &&
    css`
      margin-right: 0;
    `}
`;

const Label = styled.div`
  font-weight: bold;
  color: ${color.gray3};
`;

const VerticalLine = styled.div`
  border-left: 1px solid ${color.gray3};
  position: absolute;
  left: 50%;
  height: 100%;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Line = styled.div`
  border-bottom: 1px solid ${color.gray3};
  width: 100%;
  margin: 0 16px;
  height: 1px;
`;

const Bold = styled.div`
  font-weight: bold;
  color: ${color.gray3};
`;
const Horizontal = () => (
  <Box>
    <Bold>right</Bold>
    <Line />
    <Bold>left</Bold>
  </Box>
);

const TeethPicker = props => {
  const { value, setValue, handleChange, disabled } = props;
  const data = {
    UR: ["1", "2", "3", "4", "5", "6", "7", "8"],
    UL: ["9", "10", "11", "12", "13", "14", "15", "16"],
    LR: ["32", "31", "30", "29", "28", "27", "26", "25"],
    LL: ["24", "23", "22", "21", "20", "19", "18", "17"],
  };

  const debounced = useCallback(debounce(handleChange, 1500), [handleChange]);

  const onChange = v => ({ target: { checked } }) => {
    const newValue = checked ? value.concat([v]) : value.filter(i => i !== v);
    debounced(newValue);
    setValue(newValue);
  };
  return (
    <Perimeter>
      <VerticalLine />
      <Row>
        <InnerRow>
          {get(data, "UR").map((v, idx, arr) => (
            <Column key={v} theme={{ first: idx === 0 }}>
              <Label>{arr.length - idx}</Label>
              <Checkbox
                onChange={onChange(v)}
                checked={value.includes(v)}
                disabled={disabled}
              />
            </Column>
          ))}
        </InnerRow>

        <InnerRow>
          {get(data, "UL").map((v, idx, arr) => (
            <Column key={v} theme={{ last: idx === arr.length - 1 }}>
              <Label>{idx + 1}</Label>
              <Checkbox
                onChange={onChange(v)}
                checked={value.includes(v)}
                disabled={disabled}
              />
            </Column>
          ))}
        </InnerRow>
      </Row>

      <Horizontal />

      <Row>
        <InnerRow>
          {get(data, "LR").map((v, idx, arr) => (
            <Column key={v} theme={{ first: idx === 0 }}>
              <Checkbox
                onChange={onChange(v)}
                checked={value.includes(v)}
                disabled={disabled}
              />
              <Label>{arr.length - idx}</Label>
            </Column>
          ))}
        </InnerRow>

        <InnerRow>
          {get(data, "LL").map((v, idx, arr) => (
            <Column key={v} theme={{ last: idx === arr.length - 1 }}>
              <Checkbox
                onChange={onChange(v)}
                checked={value.includes(v)}
                disabled={disabled}
              />
              <Label>{idx + 1}</Label>
            </Column>
          ))}
        </InnerRow>
      </Row>
    </Perimeter>
  );
};

export default TeethPicker;
