import { withProps } from "recompose";

// WithEnhancers merges the actions of multiple enhancers automatically in
// this.props.actions.  If there are conflicts, later enhancers take precedence
// and overwrite actions from previous enhancers.
//
// Usage:
//
//   compose(
//     ...WithEnhancers(A, B, C)
//   )
export const WithEnhancers = (...enhancers) => {
  // Create an array containing the enhancer and a HOC mapping the actions
  // to previous actions, merging with previous enhancers.  Because the mapping
  // function returns an array we must flatten the result to produce a single-depth
  // list.
  const allEnhancers = enhancers
    .map(enhancer => {
      return [
        enhancer,
        withProps(props => ({
          previousActions: Object.assign(
            {},
            props.previousActions || {},
            props.actions || {}
          ),
        })),
      ];
    })
    .flat();

  // If there are any actions already, use these as the basis for merging into.
  const mergePrevious = [
    withProps(props => ({ previousActions: props.actions })),
  ];

  return mergePrevious.concat(
    allEnhancers,
    // And then move "previousActions", which contains all merged actions, to
    // this.props.actions.
    [withProps(props => ({ actions: props.previousActions }))]
  );
};
