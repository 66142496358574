import { Action, Thunk, action, thunk } from "easy-peasy";
import { Treatment, Referral } from "src/types/api";
import { request } from "src/shared/util";
import { message } from "antd";
import { ApiRequestState } from "src/types/local";
import { useActions } from "../hooks";

export interface TreatmentState {
  data: { [id: string]: Treatment };

  setTreatment: Action<TreatmentState, Treatment>;
  fetchTreatmentRequest: ApiRequestState;

  // Actions
  setFetchTreatmentRequest: Action<TreatmentState, ApiRequestState>;

  // Effects
  updateTreatment: Thunk<TreatmentState, Treatment>;
  updateReferral: Thunk<TreatmentState, Referral>;
}

export const treatments: TreatmentState = {
  // Treatments keyed by ID.
  data: {},

  setTreatment: action((state, tx) => {
    state.data[tx.id] = tx;
  }),

  fetchTreatmentRequest: { loading: false, error: false },

  setFetchTreatmentRequest: action((state, req) => {
    state.fetchTreatmentRequest = req;
  }),

  updateTreatment: thunk(async (actions, tx: Treatment) => {
    actions.setFetchTreatmentRequest({ loading: true, error: false });
    const result = await request(`/api/v1/treatments/${tx.id}`, {
      method: "PUT",
      body: JSON.stringify({
        start_date: tx.start_date,
        end_date: tx.end_date,
        on_hold_reason: tx.on_hold_reason,
        on_hold_date: tx.on_hold_date,
        dismissed_by_id: tx.dismissed_by_id,
      }),
    });

    if (result.error) {
      actions.setFetchTreatmentRequest({ loading: false, error: true });
      message.error("Something went wrong while updating the treatment");
      return null;
    }
    message.success("Treatment updated!");
    actions.setFetchTreatmentRequest({ loading: false, error: false });
    return result;
  }),

  updateReferral: thunk(async (actions, ref: Referral) => {
    const result = await request(`/api/v1/treatments/referrals/${ref.id}`, {
      method: "PUT",
      body: JSON.stringify({
        outcome: ref.outcome,
        patient_needs_rescan_after: ref.patient_needs_rescan_after,
        is_holding_treatment: ref.is_holding_treatment,
      }),
    });

    if (result.error) {
      message.error("Something went wrong while updating the referral");
      return null;
    }
    message.success("Referral updated!");

    return result;
  }),
};

export const useUpdateTreatment = () => {
  const updateTreatment = useActions(actions => {
    return actions.treatments.updateTreatment;
  });
  return (tx: Treatment) => {
    updateTreatment(tx);
  };
};

export const useUpdateReferral = () => {
  const updateReferral = useActions(actions => {
    return actions.treatments.updateReferral;
  });
  return (tx: Referral) => {
    updateReferral(tx);
  };
};
