import styled from "react-emotion";
import color from "src/styles/color";

export const TitleRow = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

export const IconActions = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

export const EditButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${color.red};

  display: flex;
  flex-flow: row;
  align-items: center;

  &:hover {
    text-decoration: underline;
    color: ${color.red};
  }
`;

export const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${color.red};

  display: flex;
  flex-flow: row;
  align-items: center;

  &:hover {
    text-decoration: underline;
    color: ${color.red};
  }
`;

export const SpecialistItem = styled.div`
  display: flex;
  flex-flow: column;
  padding: 16px 24px;
  line-height: 19px;
`;

export const SpecialistState = styled.div`
  font-size: 10px;
  margin-left: 8px;
`;

export const VerifiedSpecialistNameAndState = styled.div`
  font-weight: 600;
  font-size: 16;
  display: flex;
`;
