import styled from "react-emotion";
import { Menu } from "antd";
import textStyles from "src/styles/textStyles";
import color from "src/styles/color";

export const Perimeter = styled.div`
  display: flex;
  flex-direction: column;
  background: ${color.background};
  padding: 24px 0 10px 0;
  border-bottom: 1px solid ${color.border};
  position: relative;
`;

export const Photo = styled.div`
position: absolute;
width: 134px;
height: 134px;
background: ${color.white};
border: 5px solid ${color.white};
border-radius: 50%;
margin-left: 32px;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
}
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 188px;
`;

export const Bottom = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding-left: 188px;
`;

export const Col = styled.div`
  ${textStyles("medium")};
  margin-top: 5px;
  display: flex;
  align-items: center;
  margin-right: 24px;
  & > svg {
    margin-right: 8px;
  }
`;

export const PatientNameAndStatus = styled.div`
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  margin-bottom: 8px;
`;

export const PatientName = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  transition: all 0.1s;
`;

export const PatientPronoun = styled.span`
  font-size: 18px;
  line-height: 1.4;
  transition: all 0.1s;
  margin-left: 5px;
`;

export const PatientStatus = styled.div`
  margin-left: 8px;
  color: ${color.orange};
  margin-bottom: 2px;
`;

export const Row = styled.div`
  display: flex;
`;

export const WhiteBackground = styled.div`
  position: absolute;
  width: 132px;
  height: 132px;
  margin: 0 0 0 32px;
  background: ${color.white};
  border-radius: 132px;
  z-index: 1;
`;

export const RightButtons = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 12px;
`;

export const StyleMenu = styled(Menu)`
  min-width: 160px;
  & .ant-dropdown-menu-item:hover {
    background-color: ${color.gray1};
  }
`;
