// @flow
import React, { PureComponent } from "react";
import { Button, message } from "antd";
import styled from "react-emotion";
import { FormsEnhancer } from "src/state/Forms";

// type PassedProps = {
//   patientId: string,
//   formId: string,
// };

// type Props = FormProps & PassedProps;

// type State = {
//   loading: boolean,
// };

class NoSubmissions extends PureComponent {
  state = {
    loading: false,
  };

  newSubmission = async () => {
    const hide = message.loading("Creating new response", 0);

    const { formId, patientId, actions } = this.props;
    this.setState({ loading: true });

    const result = await actions.newSubmission({ formId, patientId });

    // Hide the "Creating new response" message, as the request has now finished
    hide();

    if (!result.id) {
      // Errorred out.
      this.setState({ loading: false });
      message.error(result.errors || "We couldn't make a response");
      return;
    }

    message.success("Taking you to your response");
  };

  render() {
    return (
      <Wrapper loading={this.state.loading}>
        <h1>No responses</h1>
        <Button
          type="primary"
          size="large"
          disabled={this.state.loading}
          onClick={this.newSubmission}
        >
          {this.state.loading ? "Creating submission" : "Start a new response"}
        </Button>
      </Wrapper>
    );
  }
}

export default FormsEnhancer(NoSubmissions);

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  padding: 24px;
  background: white;
  ${props => props.loading && `opacity: 0.5;`} > h1 {
    margin: 1rem 0 1rem;
    color: #999;
    font-size: 1.5rem;
    font-weight: normal;
  }
`;
