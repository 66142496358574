import React from "react";
import color from "src/styles/color";

interface Props {
  fill?: string;
}

const Phone = ({ fill = color.gray3 }: Props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.731 9.49142L9.87777 7.6338C9.50864 7.26533 8.89736 7.27652 8.51538 7.65951L7.58172 8.59508C7.52274 8.56249 7.46168 8.52846 7.39749 8.49235C6.80789 8.16492 6.00093 7.71614 5.15177 6.86444C4.3001 6.01094 3.85192 5.20091 3.52424 4.6096C3.48966 4.54695 3.45653 4.48655 3.42381 4.42919L4.05043 3.80209L4.3585 3.49294C4.74107 3.1094 4.75162 2.4969 4.38336 2.12736L2.53014 0.26953C2.16188 -0.0994993 1.55033 -0.0883041 1.16776 0.295237L0.645456 0.821726L0.659729 0.835927C0.484595 1.0599 0.338248 1.31822 0.229341 1.59679C0.128949 1.86195 0.0664456 2.11499 0.0378657 2.36854C-0.206839 4.40179 0.720198 6.26003 3.23606 8.78163C6.71374 12.267 9.5163 12.0037 9.6372 11.9908C9.90052 11.9593 10.1529 11.8962 10.4093 11.7964C10.6849 11.6885 10.9424 11.542 11.1658 11.3669L11.1772 11.377L11.7063 10.8577C12.0881 10.4743 12.0991 9.86156 11.731 9.49142Z"
      fill={fill}
    />
  </svg>
);

export default Phone;
