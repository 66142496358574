import { sortBy, last, get } from "lodash";

const typeToReadable = {
  ReferralLetter: "Referral Letter",
  SummaryOfFindings: "Summary of Findings",
  TreatmentSummary: "Treatment Summary",
  ClearanceForm: "Clearance Form",
  PatientConsent: "Patient Consent",
};

const ExternalCareEventNames = {
  EmailPatientDocuments: "email patient documents",
  EmailExternalSpecialist: "email external specialist",
};

export const getReadableType = fileType => {
  return typeToReadable[fileType] || fileType;
};

export const getLastSendEvent = emailEvents => {
  const bySendTime = sortBy(emailEvents, event => event.createdAt);
  return last(bySendTime);
};

export const getEmail = event => {
  switch (event.name) {
    case ExternalCareEventNames.EmailExternalSpecialist:
      return get(
        event,
        "eventData.externalSpecialist.verifiedSpecialist.email"
      );
    case ExternalCareEventNames.EmailPatientDocuments:
      return get(event, "eventData.user.email");
    default:
      return "";
  }
};

export const DOC_TYPES = [
  "ClearanceForm",
  "SummaryOfFindings",
  "ReferralLetter",
];
