import React from "react";

const EmptyForm = props => (
  <svg
    width="96"
    height="104"
    viewBox="0 0 96 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
    style={props.style}
  >
    <path
      d="M95.9996 3.55556V99.5556C95.9935 101.516 94.4049 103.105 92.444 103.111H81.7773C83.7383 103.105 85.3268 101.516 85.3329 99.5556V3.55556C85.3268 1.59462 83.7383 0.0060764 81.7773 0H92.444C94.4049 0.0060764 95.9935 1.59462 95.9996 3.55556Z"
      fill="#F3F3F3"
    />
    <path
      d="M85.3336 3.55556V99.5556C85.3275 101.516 83.7389 103.105 81.778 103.111H71.1113C73.0723 103.105 74.6608 101.516 74.6669 99.5556V3.55556C74.6608 1.59462 73.0723 0.0060764 71.1113 0H81.778C83.7389 0.0060764 85.3275 1.59462 85.3336 3.55556Z"
      fill="#F3F3F3"
    />
    <path
      d="M71.1111 0H3.55556C1.59462 0.0060764 0.0060764 1.59462 0 3.55556V99.5556C0.0060764 101.516 1.59462 103.105 3.55556 103.111H71.1111C73.072 103.105 74.6606 101.516 74.6667 99.5556V3.55556C74.6606 1.59462 73.072 0.0060764 71.1111 0Z"
      fill="#F3F3F3"
    />
    <path
      d="M78.2224 3.55556V99.5556C78.2164 101.516 76.6278 103.105 74.6669 103.111H71.1113C73.0723 103.105 74.6608 101.516 74.6669 99.5556V3.55556C74.6608 1.59462 73.0723 0.0060764 71.1113 0H74.6669C76.6278 0.0060764 78.2164 1.59462 78.2224 3.55556Z"
      fill="#A2A9AD"
    />
    <path
      d="M63.9997 0V17.7778L58.6663 10.6667L53.333 17.7778V0H63.9997Z"
      fill="#FC5F22"
    />
    <path
      d="M58.6664 10.6667L60.4442 13.0313L56.8887 17.7778V13.0313L58.6664 10.6667Z"
      fill="#D7D7D7"
    />
    <path d="M67.5556 0V17.7778L64 13.0313V0H67.5556Z" fill="#D7D7D7" />
    <path
      d="M88.8885 3.55556V99.5556C88.8824 101.516 87.2938 103.105 85.3329 103.111H81.7773C83.7383 103.105 85.3268 101.516 85.3329 99.5556V3.55556C85.3268 1.59462 83.7383 0.0060764 81.7773 0H85.3329C87.2938 0.0060764 88.8824 1.59462 88.8885 3.55556Z"
      fill="#A2A9AD"
    />
    <path
      d="M15.9998 23.1111C12.0727 23.1111 8.88867 19.9271 8.88867 16V0H12.4442V16C12.4442 17.9635 14.0362 19.5556 15.9998 19.5556C17.9633 19.5556 19.5553 17.9635 19.5553 16V7.11111H23.1109V16C23.1109 19.9271 19.9269 23.1111 15.9998 23.1111Z"
      fill="#343A3A"
    />
  </svg>
);

export default EmptyForm;
