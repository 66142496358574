import React, { Component } from "react";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";

import { Field, reduxForm } from "redux-form";

const FORM_NAME = "update-patient-info";

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    hintText={label}
    floatingLabelText={label}
    errorText={touched && error}
    {...input}
    {...custom}
  />
);

const reduxedForm = reduxForm({
  form: FORM_NAME,
});
export class UpdatePatientInfoForm extends Component {
  onSubmit = values => {
    const { handleSubmit } = this.props;
    handleSubmit(values);
  };

  render() {
    return (
      <form onSubmit={this.onSubmit} autoComplete="off">
        <div>
          <div>
            <RaisedButton
              label="Send reset password email"
              onClick={this.props.sendResetPasswordEmail}
            />
          </div>
          <div>
            <Field
              id="first_name"
              name="first_name"
              hintText="First Name"
              component={renderTextField}
            />
          </div>
          <div>
            <Field
              id="last_name"
              name="last_name"
              hintText="Last Name"
              component={renderTextField}
            />
          </div>
          {/*<div> API DOES NOT ALLOW THIS TO BE UPDATED*/}
          {/*<Field*/}
          {/*id="email"*/}
          {/*name="email"*/}
          {/*hintText="Email"*/}
          {/*component={renderTextField}*/}
          {/*/>*/}
          {/*</div>*/}
          <div>
            <Field
              id="mobile_phone_number"
              name="mobile_phone_number"
              hintText="Mobile Phone Number"
              component={renderTextField}
            />
          </div>
          <div>
            <Field
              id="ssn"
              name="ssn"
              hintText="Social Security Number"
              component={renderTextField}
            />
          </div>
          <div>
            <Field
              type="password"
              id="password"
              name="password"
              hintText="Change Password"
              component={renderTextField}
            />
          </div>
          <div>
            <RaisedButton
              disabled={this.props.submitting}
              type="submit"
              label="Update Patient Info"
              primary
            />
          </div>
        </div>
      </form>
    );
  }
}

export default reduxedForm(UpdatePatientInfoForm);
