import React from "react";
import styled from "react-emotion";
import { Input } from "antd";
import { getPalmer } from "src/shared/ToothChart/util";
import { phaseMapping } from "src/shared/util";
import getIPRText, { isIPR, procedureToUniversal } from "./getIPRText";

const getAttachmentText = procedures => {
  if (procedures.length === 0) {
    return;
  }

  const result = [];

  // Sort all buccan and lingual attachments into their own sets;   each set
  // contains the numbers of teeth that have this particular buccal/lingual
  // attachment.  This lets us scan and map adjascent groups of attachments.
  const att = {
    buccal: new Set(),
    lingual: new Set(),
    all: procedures,
  };

  att.all.forEach(a => {
    att[a.entry_data.position].add(a.toothNumber);
  });

  att.all.forEach(a => {
    const num = a.toothNumber;
    const pos = a.entry_data.position;

    if (!att[pos].has(num)) {
      // This has already been seen. Remove it.
      return;
    }

    att[pos].delete(num);

    // If this is buccal, check to see how many adjascent teeth have buccal attachments.
    if (a.entry_data.position === "buccal") {
      let adj = num;

      while (att.buccal.has(adj + 1) && adj !== 16) {
        // Remove this from the set so that we don't hit it again.
        att.buccal.delete(adj + 1);
        adj = adj + 1;
      }

      if (adj !== num && num < 16) {
        // Is the number > 16?  If so reverse the order.
        result.push(`${getPalmer(num)}-${getPalmer(adj)}`);
        return;
      }

      if (adj !== num && num > 16) {
        // Is the number > 16?  If so reverse the order. so that it still reads
        // left to right (LL4-LL7 vs LL7-LL4)
        result.push(`${getPalmer(adj)}-${getPalmer(num)}`);
        return;
      }

      // This has no adjascent teeth.  Does it have a lingual?
      if (adj === num && att.lingual.has(adj)) {
        // Remove this from lingual set.
        att.lingual.delete(adj);

        result.push(`${getPalmer(adj)} (B/L)`);
        return;
      }

      result.push(`${getPalmer(num)}`);
      return;
    }

    // This is a lingual tooth.  This may have been marked before a buccal
    // tooth so we still need to check for the B/L pair.
    if (att.buccal.has(num)) {
      // Remove this from lingual set.
      att.buccal.delete(num);

      result.push(`${getPalmer(num)} (B/L)`);
      return;
    }

    result.push(`${getPalmer(num)} (L)`);
  });

  return `    -- Attachments: ${result.join(", ")}`;
};

const getDisclusionText = procedures => {
  if (procedures.length === 0) {
    return;
  }
  const result = procedures.map(p => p.tooth_name);
  return `    -- Disclusions: ${result.join(", ")}`;
};

const summarizeByPosition = (label, procedures) => {
  if (procedures.length === 0) {
    return;
  }
  const byTooth = new Map();
  for (const { tooth_name, entry_data } of procedures) {
    const position = entry_data.position[0].toUpperCase();
    const entry = byTooth.get(tooth_name);
    if (entry) {
      entry.push(position);
      entry.sort();
    } else {
      byTooth.set(tooth_name, [position]);
    }
  }

  const result = [...byTooth.entries()].map(
    ([tooth_name, positions]) => `${tooth_name} (${positions.join("/")})`
  );
  return `    -- ${label}: ${result.join(", ")}`;
};

const getPowerarmText = procedures => {
  if (procedures.length === 0) {
    return;
  }
  const result = procedures.map(p => p.tooth_name);
  return `    -- Powerarms: ${result.join(", ")}`;
};

export const getPhaseText = phase => {
  const stages = (() => {
    const hasUpperLower =
      phase.upper_stages !== undefined || phase.lower_stages !== undefined;

    if (!hasUpperLower) {
      return `${phase.stages} U/L`;
    }

    if (phase.upper_stages === phase.lower_stages) {
      return `${phase.upper_stages} U/L`;
    }

    return `${phase.upper_stages} U, ${phase.lower_stages} L`;
  })();

  const intro = `(${stages}) ${phaseMapping[phase.type]}`;

  if (!phase.procedures) {
    return intro;
  }

  return [intro].concat(getRxSummary(phase.procedures)).join("\n");
};

export const getRxSummary = items => {
  // We want to order all operations by tooth.
  const procedures = items
    .map(procedureToUniversal)
    .sort((a, b) => a.toothNumber - b.toothNumber);

  try {
    // And then we run through, in sequence:
    //  - Attachments
    //  - IPR
    //  - Disclusions
    //  - Elastics
    //  - Powerarms
    const procText = [
      getAttachmentText(
        procedures.filter(p => p.entry_type === "add_attachment")
      ),
      getIPRText(procedures.filter(isIPR)),
      getDisclusionText(procedures.filter(p => p.entry_type === "disclusion")),
      summarizeByPosition(
        "Caplin hooks",
        procedures.filter(
          p => p.entry_type === "elastics" || p.entry_type === "add_hook"
        )
      ),
      summarizeByPosition(
        "Tray cutouts",
        procedures.filter(p => p.entry_type === "tray_cutouts")
      ),
      summarizeByPosition(
        "Bracket cutouts",
        procedures.filter(p => p.entry_type === "bracket_cutouts")
      ),
      getPowerarmText(procedures.filter(p => p.entry_type === "add_powerarms")),
    ].filter(str => !!str);
    return procText;
  } catch (e) {
    return [];
  }
};

const Summary = ({ data }) => {
  return (
    <Wrapper>
      <h3>Auto generated summary from tooth chart:</h3>
      <Input.TextArea
        value={(data || []).map(getPhaseText).join("\n")}
        autosize={{ minRows: 2, maxRows: 12 }}
      />
    </Wrapper>
  );
};

export default Summary;

const Wrapper = styled("div")`
  padding: 2rem 0 0;

  h3 {
    font-size: 1rem;
    font-weight: 400;
    margin: 0 0 0.5rem;
    opacity: 0.8;
    display: inline-block;
  }
`;
