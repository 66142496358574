import { V, S } from "src/shared-styles";
export default {
  V,
  S,
  submit: {
    verticalAlign: "top",
    display: "inline-block",
    width: 124,
    marginLeft: 6,
    marginTop: 3,
  },
  verified: {
    position: "absolute",
    right: 130,
    top: 11,
    zIndex: 1,
    color: "green",
  },
  formRow: {
    display: "inline-block",
    width: `calc(100% - 130px)`,
  },
  form: {
    position: "relative",
  },
  card: {
    base: {
      color: "#32325d",
      lineHeight: "18px",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
