import React from "react";
import { css } from "react-emotion";
import color from "src/styles/color";
import Button, { ButtonRow } from "src/shared/Button";
import Modal from "src/shared/Modal";

export interface Props {
  imageURL: string;
  onClose: () => void;
  altText?: string;
}

const modalImageCSS = css`
  max-height: calc(100vh - 100px);
  max-width: calc(100vw - 100px);
`;

const modalButtonsCSS = css`
  padding: 8px;
  border-top: 1px solid ${color.borderLight};
`;

export default function({ imageURL, onClose, altText }: Props) {
  return (
    <Modal onClose={onClose}>
      <img src={imageURL} className={modalImageCSS} alt={altText} />
      <ButtonRow position="right" className={modalButtonsCSS}>
        <Button kind="default" onClick={onClose}>
          Close
        </Button>
      </ButtonRow>
    </Modal>
  );
}
