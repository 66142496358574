import React from "react";
import { message } from "antd";
import { State, Dispatch, SyncItem } from "./useSubmissions";

import {
  useCreateAnswer,
  useUpdateSubmission,
} from "src/scenes/PatientProfile/Forms/GQLForms/queries";
import { gqlError } from "src/shared/util";

const MAX_ATTEMPTS = 2;
const DELETED_RECORD_ERRORS = new Set(["No UUID found", "record not found"]);

export const useLocallyUnsyncedData = (dispatch: Dispatch) => {
  // Load previous unsycned answers from localstorage.
  React.useEffect(() => {
    const unsynced =
      window &&
      window.localStorage &&
      window.localStorage.getItem("unsynced-submissions");
    if (!unsynced) {
      return;
    }
    try {
      const parsed = JSON.parse(unsynced);
      const answers = {};

      // Convert JSON into maps
      Object.keys(parsed.answers).forEach(submissionID => {
        answers[submissionID] = new Map(parsed.answers[submissionID]);
      });
      parsed.answers = answers;

      dispatch({ type: "fetchUnsynced", data: parsed });
    } catch (e) {}
  }, [dispatch]);
};

export const useLocalDeadLetter = (dispatch: Dispatch) => {
  // Load previous dead letter items.
  React.useEffect(() => {
    const dl =
      (window &&
        window.localStorage &&
        window.localStorage.getItem("deadletter")) ||
      "[]";
    try {
      const parsed = JSON.parse(dl);
      dispatch({ type: "fetchDeadLetter", data: parsed });
    } catch (e) {}
  }, [dispatch]);
};

// useSyncSlice returns an ordered array of items to sync, from earliest first.
export const useSyncSlice = (s: State): SyncItem[] => {
  return React.useMemo(() => {
    const results: SyncItem[] = [];

    Object.values(s.unsynced.answers).forEach(map => {
      map.forEach(a => {
        if (typeof a.answer !== "undefined") {
          results.push({ type: "answer", data: a });
        }
      });
    });

    Object.values(s.unsynced.toothData).forEach(td => {
      results.push({ type: "toothdata", data: td });
    });

    // Least attempts then earliest syncs first.
    results.sort((a, b) => {
      if (a.data.attempts === b.data.attempts) {
        return a.data.createdAt.localeCompare(b.data.createdAt);
      }
      return (a.data.attempts || 0) - (b.data.attempts || 0);
    });

    return results;
  }, [s.unsynced]);
};

// useBackgroundSync syncs answers and tooth chart data to the backend, in series,
// from the earliest item to the newest.
export const useBackgroundSync = (s: State, d: Dispatch): SyncItem[] => {
  // Set up mutation and query funcs for mutating state.
  const createAnswer = useCreateAnswer();
  const updateSubmission = useUpdateSubmission();

  const sync = async (item: SyncItem) => {
    const syncing = (syncing: boolean) => {
      d({ type: "syncing", id: item.data.localID, syncing });
    };

    syncing(true);

    switch (item.type) {
      case "answer": {
        const a = item.data;
        const result = await createAnswer({
          answer: {
            submissionID: a.submissionID,
            questionID: a.questionID,
            answer: a.answer,
          },
        });
        syncing(false);

        if (result.error || !result.data) {
          if (DELETED_RECORD_ERRORS.has(gqlError(result.error))) {
            // Just dismiss this error and treat as synced.
            // There's nothing actionable to be done in background-sync if the patient, form, submission, or question has been deleted.
            d({ type: "answerSynced", unsynced: a });
            return;
          } else if (item.data.attempts === MAX_ATTEMPTS) {
            // Move to dead letter.
            d({ type: "deadletter", data: item });
          } else {
            d({
              type: "setUnsyncedAnswer",
              data: {
                ...item.data,
                attempts: (item.data.attempts || 0) + 1,
                lastAttemptAt: new Date().valueOf(),
              },
            });
          }

          message.error(
            "There was an error saving the answer: " + gqlError(result.error)
          );
          return;
        }

        d({ type: "answerSynced", unsynced: a, synced: result.data.addAnswer });
        break;
      }
      case "toothdata": {
        const td = item.data;
        const result = await updateSubmission({
          input: {
            id: td.submissionID,
            toothData: td.data,
          },
        });
        syncing(false);

        if (result.error || !result.data) {
          // Handle up to MAX_ATTEMPTS attempts
          if (item.data.attempts === MAX_ATTEMPTS) {
            // Move to dead letter.
            d({ type: "deadletter", data: item });
          } else {
            d({
              type: "setUnsyncedToothData",
              data: {
                ...item.data,
                attempts: (item.data.attempts || 0) + 1,
                lastAttemptAt: new Date().valueOf(),
              },
            });
          }

          message.error(
            "There was an error saving the tooth chart: " +
              gqlError(result.error)
          );
          return;
        }

        d({
          type: "toothDataSynced",
          unsynced: td,
          synced: { data: result.data.updateSubmission.toothData || "" },
        });
        break;
      }
    }
  };

  // Sync data.
  const toSync = useSyncSlice(s);

  const pop = () => {
    if (!navigator.onLine) {
      // Offline - do not sync
      return;
    }

    // Calculate items to sync - we dont want to thrash the server, so set a minimum
    // backoff.
    const now = new Date().valueOf();
    const items = toSync.filter(i => {
      const threshold = backoff(i.data.attempts || 0);
      return !i.data.lastAttemptAt || now - i.data.lastAttemptAt > threshold;
    });

    if (items.length === 0) {
      return;
    }

    const next = items[0];

    if (s.syncing[next.data.localID]) {
      // Do not sync this item twice.
      return;
    }

    try {
      sync(next);
    } catch (e) {
      d({ type: "syncing", id: next.data.localID, syncing: false });
    }
  };

  React.useEffect(() => {
    pop();

    const int = window.setInterval(() => {
      pop();
    }, 5000);

    return () => window.clearInterval(int);

    // eslint-disable-next-line
  }, [toSync, window.navigator.onLine]);

  return toSync;
};

const min = 1000 * 5; // min 5 seconds
const max = 1000 * 60; // max 1 minute
export const backoff = (attempts: number) => {
  const result = Math.max(Math.pow(attempts, 1.5) * 5000, min);
  return Math.min(max, Math.round(result));
};
