import React from "react";
import styled from "react-emotion";
import StaffPopover from "src/shared/Popovers/StaffPopover/StaffPopover";
import { Staff } from "src/types/gql";
import Add from "src/shared/Icons/Add";
import { toggle } from "src/shared/util";
import InvisibleButton from "../NewTask/InvisibleButton";
import AssignStaffIcon from "../Icons/AssignStaff";
import Assigned from "./Assigned";
import color from "src/styles/color";

type Props = {
  value: Staff[];
  onChange?: (s: Staff[]) => void;
};

const Assignees: React.FC<Props> = ({ value, onChange }) => {
  if ((!value || value.length === 0) && !onChange) {
    return (
      <InvisibleButton>
        <AssignStaffIcon />
      </InvisibleButton>
    );
  }

  if (!value || value.length === 0) {
    return (
      <StaffPopover
        onClick={(s?: Staff) => {
          if (!s) {
            return;
          }
          onChange && onChange(toggle(value, s));
        }}
        value={value}
      >
        <InvisibleButton>
          <AssignStaffIcon />
          Assign staff
        </InvisibleButton>
      </StaffPopover>
    );
  }

  return (
    <Wrapper>
      {value.map(s => (
        <Assigned
          key={s.id}
          staff={s}
          onClick={() => {
            onChange && onChange(value.filter(a => a.id !== s.id));
          }}
        />
      ))}
      <StaffPopover
        align="end"
        onClick={(s?: Staff) => {
          if (!s) {
            return;
          }
          onChange && onChange(toggle(value, s));
        }}
        value={value}
      >
        <AddButton>
          <Add fill={color.gray3} />
        </AddButton>
      </StaffPopover>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const AddButton = styled.div`
  cursor: pointer;
  padding: 0 10px;
`;

export default Assignees;
