import React from "react";

type Props = {
  fill?: string;
  background?: string;
  size?: number;
  outlined?: boolean;
  style?: object;
  className?: any;
  onClick?: () => void;
};

const Cross = ({
  fill = "#fff",
  background = "transparent",
  size = 20,
  outlined = false,
  style = {},
  className,
  onClick,
}: Props) => {
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        width: size * 1.5,
        height: size * 1.5,
        borderStyle: "solid",
        borderWidth: !outlined ? 0 : size / 10,
        borderRadius: size + 20,
        borderColor: fill,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: background,
        ...style,
      }}
    >
      <svg
        width={size}
        height={size}
        style={{ margin: 0 }}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7779 13.7042L11.8577 9.78398L15.7779 5.86378L14.1148 4.20066L10.1946 8.12086L6.29416 4.22046L4.63104 5.88358L8.53144 9.78398L4.63104 13.6844L6.29416 15.3475L10.1946 11.4471L14.1148 15.3673L15.7779 13.7042Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

export default Cross;
