import { QUESTION_FORM } from "../event-type";
import { QUESTION_TYPE, READY_FOR_RETAINER } from "../constant";

export default {
  attachToothChart: true,
  postTimestamp: null,
  postUserName: null,
  postUserUUID: null,
  complete: false,
  formName: "pra", // this must match the name of the form in config/treatment-forms/index
  type: QUESTION_FORM,
  questionList: [
    {
      displayName: "Welcome session",
      type: "alert",
    },
    {
      displayName: "Update / Add Chief Motivations",
      type: "text",
    },
    {
      displayName: "Update / Add Health History Questions",
      type: "text",
    },
    {
      displayName: "Getting to know you",
      type: "alert",
    },
    {
      displayName: "Reviewed medical & dental history with the patient?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      displayName: "Additional Notes from Reviewing the Medical History",
      type: "text",
    },
    {
      displayName: "Does the patient desire Night-Time-Only Treatment?",
      type: "select",
      possibleAnswerList: ["No", "Yes"],
    },
    {
      displayName: "Progress CBCT Scan Performed?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      displayName: "Radiographic Exam",
      type: "alert",
    },
    {
      displayName: "Any tooth decay identified from xrays?",
      type: "select",
      possibleAnswerList: ["No", "Yes"],
    },
    {
      displayName: "Any gum problems identified from xrays?",
      type: "select",
      possibleAnswerList: ["No", "Yes"],
    },
    {
      displayName: "Any root resorption noted?",
      type: "select",
      possibleAnswerList: ["No", "Yes"],
    },
    {
      displayName: "Any TMJD noted?",
      type: "select",
      possibleAnswerList: ["No", "Yes"],
    },
    {
      displayName: "Physical Exam",
      type: "alert",
    },
    {
      displayName: "TMJ ",
      type: "multiselect",
      possibleAnswerList: [
        "Reviewed, Within Normal Limits",
        "History of Pain",
        "History of Clicking",
        "Current Pain",
        "Current Clicking",
      ],
    },
    {
      displayName: "CO/CR Shift ",
      type: "multiselect",
      possibleAnswerList: [
        "Reviewed, Within Normal Limits",
        "Minor Anterior Slide",
        "Minor Lateral Slide",
        "Current Pain",
        "Current Clicking",
      ],
    },
    {
      displayName: "Gum Health",
      type: "multiselect",
      possibleAnswerList: [
        "Probing sites are within normal limits",
        "Attachment Loss Localized",
        "Increased Pocket Depth",
        "Active Periodontal Disease",
      ],
    },
    {
      displayName: "Caries Inspection",
      type: "select",
      possibleAnswerList: [
        "No caries evident",
        " Suspicious lesion(s) present",
      ],
    },
    {
      displayName: "Oral Hygiene",
      type: "select",
      possibleAnswerList: ["Good", "Fair", "Poor"],
    },
    {
      displayName: "Oral Cancer",
      type: "select",
      possibleAnswerList: ["No Cancer Evident", "Suspicious Lesions Present"],
    },
    {
      displayName: "Additional Notes From the Exam",
      type: "text",
    },
    {
      displayName: "Doctor assessment",
      type: "alert",
    },
    {
      conditionId: "plan",
      dataKey: "plan",
      displayName: "Plan",
      type: "select",
      possibleAnswerList: ["Additional alignment needed", READY_FOR_RETAINER],
    },
    {
      displayCondition: {
        id: "plan",
        answer: ["Additional alignment needed"],
      },
      displayName: "Additional alignment duration estimate",
      type: "select",
      possibleAnswerList: [
        "1-2 mos",
        "3-4 mos",
        "5-6 mos",
        "7-8 mos",
        "9-10 mos",
        "10-12 mos",
        "12-18 mos",
        ">18 mos",
      ],
    },
    {
      displayCondition: {
        id: "plan",
        answer: ["Additional alignment needed"],
      },
      displayName: "Attachments",
      type: "select",
      possibleAnswerList: [
        "No attachments necessary",
        "Debonded Attachments",
        "Attachments Placed",
        "Attachments needed",
      ],
    },
    {
      displayCondition: {
        id: "plan",
        answer: ["Additional alignment needed"],
      },
      displayName: "Reviewed treatment plan with patient",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      displayCondition: {
        id: "plan",
        answer: ["Ready for retainers"],
      },
      displayName: "Lower",
      dataKey: "lower",
      type: "select",
      possibleAnswerList: [
        "Anterior Only Retainer",
        "5-5 Retainers",
        "Full Coverage",
      ],
    },
    {
      displayCondition: {
        id: "plan",
        answer: ["Ready for retainers"],
      },
      displayName: "Upper",
      dataKey: "upper",
      type: "select",
      possibleAnswerList: [
        "Anterior Only Retainer",
        "5-5 Retainers",
        "Full Coverage",
      ],
    },
    {
      displayCondition: {
        id: "plan",
        answer: ["Ready for retainers"],
      },
      displayName: "Scan to use for Retainer",
      dataKey: "scanToUseForRetainer",
      type: "select",
      possibleAnswerList: ["Use last active stage", "New scan taken"],
    },
    {
      displayName: "Plan Notes",
      type: "text",
    },

    {
      displayName: "Procedures rendered",
      type: "alert",
    },
    {
      displayName: "Placed Attachments?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      displayName: "Removed Attachments?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      displayName: "Interoral Scan Taken?",
      type: "select",
      possibleAnswerList: ["Progress", "Active Retention"],
    },
    {
      displayName: "Xray Taken?",
      type: "select",
      possibleAnswerList: ["Progress", "Final"],
    },
    {
      displayName: "Any IPR Performed on the Patient?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      displayName: "Treatment Notes",
      type: "text",
    },
    {
      displayName: "Social History",
      type: QUESTION_TYPE.SOCIAL_NOTE,
    },
    {
      displayName: "Records",
      type: "alert",
    },
    {
      displayName: "Did you update the tooth chart?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      displayName:
        "Did you add a record folder by clicking the [ + RECORD FOLDER ] to the right?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      displayName: "Did you save the scans to Box?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      displayName:
        "Did you take each of the following patient photos? Check off each one. ",
      type: "multiselect",
      possibleAnswerList: [
        "frontal relaxed",
        "frontal smiling",
        "profile relaxed",
        "profile smiling",
      ],
    },
    {
      displayName: "Did you upload all of the photos to Box?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
  ],
};
