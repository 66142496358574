import styled from "react-emotion";
import color from "src/styles/color";

export const Background = styled.div`
  width: 100%;
  background: ${color.background2};
  position: relative;
`;

export const Perimeter = styled.div`
  width: 100%;
  max-width: 1096px;
  margin: 0 auto;
  padding: 48px 0;
`;

export const Header = styled.div`
  border: 1px solid ${color.border};
  margin-bottom: 24px;
  background: ${color.white};
`;

export const SelectedTab = styled.div``;

export const TreatmentRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 0;
`;
