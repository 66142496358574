// @flow
import React from "react";
import ToothChart from "src/shared/ToothChart/ToothChart";
import { Collapse } from "antd";
import ViewFormQuestion from "./FormQuestion";
import { Content, toothChartCss } from "./Shared";
import SubmissionWrapper from "./SubmissionWrapper";
import { getSubmissionAnswer } from "./answers";

const Generic = props => {
  const { submission, form, sessionAnswers, teeth, editable, patient } = props;

  const stagesVisible = () => {
    switch (form.slug) {
      case "referral":
        return ["prescribed", "antecedent"];
      default:
        return ["antecedent", "estimated", "rendered"];
    }
  };

  return (
    <SubmissionWrapper {...props}>
      <Content>
        {form.forms_questions.map((q, n) => {
          return (
            <ViewFormQuestion
              allAnswers={sessionAnswers.concat(submission.answers || [])}
              patient={patient}
              submission={submission}
              answer={getSubmissionAnswer({
                answers: submission.answers,
                questionID: q.id,
                submissionID: submission.id,
              })}
              onChange={value => props.onAnswer(q.id, value)}
              editable={editable}
              key={q.id}
              q={q}
            />
          );
        })}
      </Content>

      <Collapse bordered={false}>
        <Collapse.Panel header="Tooth chart" className={toothChartCss}>
          <ToothChart
            stagesVisible={stagesVisible()}
            previousProcedures={teeth}
            procedures={props.toothData || []}
            setProcedures={props.onToothChartUpdateAsync}
            disabled={!editable}
          />
        </Collapse.Panel>
      </Collapse>
    </SubmissionWrapper>
  );
};

export default Generic;
