import React from "react";
import { TimelineParams } from "src/components/patient-timeline/PatientTimeline";

// state
import Loading from "src/shared/Loading";
import { useClinicalRecord } from "src/state/hooks/clinicalRecords";
import { useShippingAddress } from "src/state/useAddresses";
import { usePatientStatus } from "src/state/usePatientStatus";
// local
import Overview from "src/scenes/Patient/Overview/Overview";
import PatientProfileHeader from "src/scenes/PatientProfile/PatientProfileHeader";
import TreatmentInfo from "src/scenes/PatientProfile/TreatmentInfo";
import TreatmentTimeline from "src/scenes/PatientProfile/TreatmentTimeline";
import Tabs from "src/scenes/PatientProfile/Tabs";
import TreatmentStatus from "src/scenes/PatientProfile/TreatmentStatus";
import { ConsentsContextProvider } from "src/scenes/PatientProfile/Consents/useConsentsContext";
import {
  Background,
  Perimeter,
  Header,
  SelectedTab,
  TreatmentRow,
} from "./styles";
import { useTreatment } from "./queries";

interface Params extends TimelineParams {
  userId: string;
}

const PatientProfile = (props: any) => {
  const params: Params = props.params;
  const { userId } = params;

  // Application-level data
  const [, , availablePatientStatuses] = usePatientStatus();

  // The core data for the patient
  const [{ fetching, data }, refetch] = useTreatment(userId);
  const [, , address] = useShippingAddress(userId);
  const [clinicalRecord] = useClinicalRecord(userId);

  const renderActiveTab = () =>
    data &&
    React.Children.map(props.children, (c: React.ReactNode) =>
      React.cloneElement(c as React.ReactElement<any>, {
        patient: data && data.user,
        appointments: data && data.user.appointments,
        clinicalRecord,
      })
    );

  if (fetching || !data) {
    return <Loading />;
  }

  return (
    <Background id="patient-profile-background">
      {/* div id above is used for scroll handler in another component */}
      <ConsentsContextProvider patientId={userId}>
        <Perimeter>
          <Header>
            <PatientProfileHeader
              patient={data.user}
              patientStatuses={availablePatientStatuses}
              patientAddress={address}
              clinicalRecord={clinicalRecord}
              treatment={data && data.activeOrLastTreatment}
              refetch={refetch}
            />

            <div>
              <TreatmentInfo
                treatment={data && data.activeOrLastTreatment}
                loadingTreatment={fetching}
                patient={data.user}
              />
              <TreatmentRow>
                <TreatmentStatus
                  timeline={data ? data.trayTimeline : []}
                  treatment={data && (data.activeOrLastTreatment as any)}
                  patient={data.user}
                />
                <TreatmentTimeline
                  timeline={data ? data.trayTimeline : []}
                  treatment={data && data.activeOrLastTreatment}
                  appointments={data && data.user.appointments}
                  loadingTreatment={fetching}
                />
              </TreatmentRow>
            </div>
            <Tabs
              patient={data.user}
              pathname={props.location.pathname}
              patientAvatarUrl={data.user.avatarURL}
            />
          </Header>
          <SelectedTab>
            {renderActiveTab()}
            {!props.children && (
              <Overview
                patient={data.user}
                clinicalRecord={clinicalRecord || undefined}
                treatment={data && data.activeOrLastTreatment}
                appointments={data.user.appointments}
                timelineParams={props.params}
              />
            )}
          </SelectedTab>
        </Perimeter>
      </ConsentsContextProvider>
    </Background>
  );
};

export default PatientProfile;
