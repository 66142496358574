import React, { Component } from "react";
import PropTypes from "prop-types";
import Gallery from "../../../components/gallery/gallery";

export default class File extends Component {
  static propTypes = {
    event: PropTypes.object,
  };

  render() {
    const { event } = this.props;
    return <Gallery fileList={event.fileList} />;
  }
}
