import React from "react";

import { CounterProps } from "./types";
import { CounterText } from "./styles";

const Counter: React.FC<CounterProps> = props => {
  const { value } = props;

  return value === null ? null : <CounterText>{value}</CounterText>;
};

export default Counter;
