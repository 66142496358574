import React, { Component } from "react";
import { compose } from "recompose";
import { FormsEnhancer } from "src/state/Forms";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import theme from "src/utils/material-ui-theme";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

import head from "./head";
import styles from "./app-style";
import { load as loadServerConfig } from "src/redux/modules/server-config";

const modifiers = compose(
  connect(
    state => ({
      globalErrorList: state.globalError.errorList,
    }),
    {
      loadServerConfig,
    }
  ),
  FormsEnhancer
);

class App extends Component {
  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    this.props.loadServerConfig();
    // from FormsEnhancer
    this.props.actions.getForms();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.globalErrorList !== nextProps.globalErrorList) {
      this.setState({ errorOpen: true });
    }
  }

  render() {
    return (
      <MuiThemeProvider muiTheme={getMuiTheme(theme)}>
        <div style={styles.root}>
          <Helmet {...head} />
          <div style={styles.contentContainer}>{this.props.children}</div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default modifiers(App);
