import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import styles from "./doctor-input-style";
import { RaisedButton, TextField } from "material-ui";

const FORM_NAME = "noteForm";
export const INTERNAL_NOTE = "internalNote";

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    hintText={label}
    floatingLabelText={label}
    errorText={touched && error}
    {...input}
    {...custom}
  />
);

const form = reduxForm({
  form: FORM_NAME,
});

class NoteForm extends Component {
  handleSubmit = e => {
    e.preventDefault();
    const { handleSubmit, reset } = this.props;
    handleSubmit(e);
    reset();
  };

  render() {
    return (
      <form>
        <Field
          id={INTERNAL_NOTE}
          name={INTERNAL_NOTE}
          hintText="Internal note about the treatment"
          multiLine
          fullWidth
          component={renderTextField}
        />
        <div style={styles.noteSubmitContainer}>
          <RaisedButton
            style={styles.submitNote}
            onClick={this.handleSubmit}
            id="note-submit"
            type="submit"
            label="Add internal note"
            primary
          />
        </div>
      </form>
    );
  }
}
export default form(NoteForm);
