import React, { useState, useEffect } from "react";
import styled, { css } from "react-emotion";
import SelectList from "src/shared/SelectList";
import InputText from "src/shared/InputText";
import Popover from "src/shared/Popover";
import { titleCase } from "src/shared/util";
import { pipelineTypes } from "src/scenes/Tasks/constants";

type Props = {
  value?: string[];
  onClick: (selected?: string) => void;
};

const PipelineTypePopover: React.FC<Props> = ({ children, value, onClick }) => {
  const [filter, setFilter] = useState("");
  const [filtered, setFiltered] = useState<string[]>([] as string[]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const f = filter.toLowerCase();

    const newFiltered = pipelineTypes.filter(t => {
      return titleCase(t)
        .toLowerCase()
        .includes(f);
    });

    setFiltered(newFiltered);
  }, [filter]);

  const Content = (
    <Wrapper>
      <InputText
        style={{ width: "100%" }}
        onChange={e => setFilter(e.target.value)}
      />

      <SelectList<string>
        values={filtered}
        value={value}
        onClick={val => onClick(val)}
        render={(pt: string | undefined) => titleCase(pt || "")}
        className={selectCSS}
      />
    </Wrapper>
  );

  return (
    <Popover
      content={Content}
      visible={visible}
      close={() => setVisible(false)}
    >
      <div onClick={() => setVisible(true)}>{children}</div>
    </Popover>
  );
};

export default PipelineTypePopover;

const Wrapper = styled.div`
  background: #fff;
  padding: 12px;
`;

const selectCSS = css`
  max-height: 16rem;
`;
