// Trays is a scene rendering a table to show trays for a patient.  Each
// tray may be printed via multiple builds;  we use the "patient builds"
// API endpoint to list all builds for this patient, which totals all
// the builds available for these trays.
//
// We also list trays by Treatment Cycle, so we need to mix in both the
// TreatmentCycle and Build enhancers to show these correctly.
import React from "react";
import {
  compose,
  lifecycle,
  branch,
  renderComponent,
  withProps,
} from "recompose";
import styled from "react-emotion";
import { TreatmentCyclesEnhancer } from "src/state/TreatmentCycles";
import { BuildsEnhancer } from "src/state/Builds";
import { WithEnhancers } from "src/shared/hocs";
import Loading from "src/shared/Loading";
import TrayTable from "src/shared/TrayTable/TrayTable";
import color from "src/styles/color";

const modifiers = compose(
  ...WithEnhancers(TreatmentCyclesEnhancer, BuildsEnhancer),
  lifecycle({
    componentDidMount() {
      const { id } = this.props.patient;
      // Get all TCs for the patient, which also gets each tray
      this.props.actions.getPatientTreatmentCycles(id);
      // Get all builds for the patient
      this.props.actions.getTrayBuildsForUser(id);
    },
  }),
  withProps(props => ({
    // Add the patient's treatment cycles ordered by start date.
    //
    // By default, this.props.treatmentCycles has all loaded cycles
    // from every patient.
    patientCycles: props.treatmentCycles
      .filter(t => t.user_id === props.patient.id)
      .sort(
        (a, b) =>
          new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf()
      ),
  })),
  branch(
    props =>
      props.patientCycles.length === 0 &&
      props.getPatientTreatmentCyclesRequest.loading,
    renderComponent(() => <Loading />)
  )
);

const Wrapper = styled("div")`
  min-height: 50vh;
  padding: 18px 0;
  width: 100%;
  border: 1px solid ${color.border};
  background: ${color.white};
`;

const PatientTrays = props => {
  const Component = props.tableComponent ? props.tableComponent : TrayTable;
  return (
    <Wrapper>
      {props.patientCycles.map(tc => {
        return (
          <Component
            buildsEnabled={props.buildsEnabled}
            editingDisabled={props.editingDisabled}
            tc={tc}
            key={tc.id}
            id={tc.id}
            patient={props.patient}
            pipelineID={props.pipelineID}
          />
        );
      })}
    </Wrapper>
  );
};

export default modifiers(PatientTrays);
