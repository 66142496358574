import { V, S } from "src/shared-styles";
const styles = {
  V,
  S,
  container: {
    ...S.container,
    padding: V.spacing,
  },
  positioner: {
    paddingTop: V.spacingHuge,
    paddingBottom: V.spacingHuge + V.spacingLarge,
    zIndex: 50,
    position: "absolute",
    top: 0,
    left: 0,
    background: V.colors.whiteGreyBackground,
    width: "100vw",
  },
  bottomPositioner: {
    background: V.colors.white,
    borderTop: V.border,
    height: V.spacingHuge,
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100vw",
  },
  buttonContainer: {
    float: "right",
  },
  button: {
    height: V.spacingLarge,
  },
  textField: {
    fontSize: V.fontLarge,
  },
};

export default styles;
