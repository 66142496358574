import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { testPredicate } from "src/utils/optionality";
import styles from "./styles";

/**
 * Questionnaire renders a user's questionnaire responses in line with the
 * questions asked.
 *
 * It can be set to show only adverse responses or all responses.
 */
export default class QuestionnaireResponse extends PureComponent {
  static propTypes = {
    questionnaire: PropTypes.object,
    responses: PropTypes.object,

    showAdverseOnly: PropTypes.bool,
  };

  render() {
    const { responses, questionnaire, showAdverseOnly } = this.props;

    if (!questionnaire) {
      return null;
    }

    return (
      <div>
        <p>
          <strong>{questionnaire.name}</strong>
        </p>
        {questionnaire.questions.map(q => {
          // XXX: Potentially move into own component and use selectors to
          //      manipulate data; this feels dirty.
          let response = responses[q.id];
          let isAdverse = false;

          if (Array.isArray(q.predicates) && q.predicates.length > 0) {
            const show = q.predicates.every(pred =>
              testPredicate(pred, responses[pred.id])
            );
            if (!show) {
              return null;
            }
          }

          if (Array.isArray(q.adverse) && q.adverse.length > 0) {
            isAdverse = q.adverse.find(predicate =>
              testPredicate(predicate, response)
            );
          }

          if (Array.isArray(response)) {
            response = response.join(", ");
          }

          if (!response) {
            isAdverse = true;
          }

          if (showAdverseOnly && !isAdverse) {
            return null;
          }

          return (
            <div key={q.question}>
              <p style={styles.question}>{q.question}</p>
              <p style={isAdverse ? styles.isAdverse : styles.response}>
                {response || <em>No response</em>}
              </p>
            </div>
          );
        })}
      </div>
    );
  }
}
