const gray = {
  gray5: "#343A3A",
  gray4: "#5D6161",
  gray3: "#A2A9AD",
  gray2: "#E0E1E2",
  gray1: "#F3F3F3",
};

const status = {
  green: "#27AE60",
  yellow: "#D9A917",
};

const color = {
  primary: "#FC5F22",
  primaryHover: "#EA8057",
  primaryShading: "#FFE7DE",
  border: "#D7D7D7",
  borderLight: "#D7D7D799",
  white: "#FFFFFF",
  red: "#FF3B30",
  orange: "#FC5F22",
  background: "#F9F9F9",
  background2: "#F4F2F2",
  background3: "#F3F3F3",
  backgroundWarning: "#FFCCC9",
  font: gray.gray5,
  warning: "#E24C4B",
  link: "#2C649E",
  blue: "#40A9FF",
  teal: "#59B8B8",
  black: "#000000",

  ...gray,
  ...status,
};

export default color;
