import React from "react";
import Dcm from "./Dcm";
import Jpg from "./Jpg";
import Misc from "./Misc";
import Pdf from "./Pdf";
import Stl from "./Stl";
import Zip from "./Zip";

type Props = {
  filename: string;
};

export default function FileIcon({ filename }: Props) {
  const ext = (filename.split(".").pop() || "").toLowerCase();
  switch (ext) {
    case "dcm":
      return <Dcm />;
    case "jpg":
      return <Jpg />;
    case "pdf":
      return <Pdf />;
    case "stl":
      return <Stl />;
    case "zip":
      return <Zip />;
    default:
      return <Misc />;
  }
}
