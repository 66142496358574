import React, { useReducer, useState } from "react";
import styled from "react-emotion";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import { notification, AutoComplete } from "antd";
import { get, isEmpty } from "lodash";
import { useSchedulingRestrictions } from "src/state/self";
// shared
import { useMutation } from "src/utils/http/gqlQuery";
import Button from "src/shared/Button";
import DatePicker from "src/shared/DatePicker";
import Dropdown from "src/shared/Dropdown";
import { gqlError, getAppointmentName } from "src/shared/util";
import Trash from "src/shared/Icons/Trash";
import Popconfirm from "src/shared/Popconfirm";
import time from "src/shared/time";
import InternalNotes from "src/shared/InternalNotes/V1Rest/InternalNotes";
// prep notes
import PatientFormPrepNotes from "src/scenes/PatientProfile/Forms/GQLForms/PatientInformation";
import { FormsContextProvider } from "src/scenes/PatientProfile/Forms/GQLForms/useFormsContext";
// local
import useCalendarViewContext from "../useCalendarViewContext";
import OffsetPicker from "../OffsetPicker";
import { getLabel } from "../util";
import useSchedulingContext from "src/scenes/SchedulingV2/useSchedulingContext";
import { Row, Label, Item, Footer, FormWrapper } from "./Styled";
// types
import { AppointmentWithOffset, PatientSearchResult } from "./types";
import { AppointmentType } from "src/types/api";
import { AppointmentSubtype } from "src/types/gql";
import { StyledAutoComplete } from "../../Styled";

type Props = {
  appt: AppointmentWithOffset;
  patient?: PatientSearchResult;
  onSaveCompleted: (subtypeId?: string) => void;
  enableSave?: boolean;
  close: () => void;
};

type UpdateAppointment = {
  id: string;
  doctorId?: string | undefined;
  doctorStartTime?: DateTime | undefined;
  doctorEndTime?: DateTime | undefined;
  bookableResourceId?: string | undefined;

  // The current staff members assigned.
  staff: Array<string>;

  // These are not in the graphql query but make UI life easy.
  // We need the appointment type to get the duration of the doctor timne.

  // The initial or modified date.
  date: DateTime;

  // The initial or modified offset.
  offset: number;

  // The initial or modified type.
  type: AppointmentType;

  subtypes: Array<AppointmentSubtype>;

  subtype?: AppointmentSubtype | undefined;

  // Whether we've edited the form.  Defaults to false.
  hasEdit: boolean;
  timezone: string;
};

type Action =
  | { type: "doctor"; id: string }
  | { type: "room"; id: string }
  | { type: "type"; value: AppointmentType }
  | { type: "subtype"; value: AppointmentSubtype | undefined }
  | { type: "offset"; offset: number }
  | { type: "date"; date: DateTime }
  // For now we can only assign one staff member to an appointment via the UI.
  | { type: "staff"; id: string | undefined };

const getDefaultSubtype = (
  typeName: string,
  subtypes: Array<AppointmentSubtype>
) => {
  const findSubtype = name => subtypes.find(s => s.name === name);

  switch (typeName) {
    case "misc_short":
      return findSubtype("thirty");
    case "misc_long":
      return findSubtype("sixty");
    case "staff_only":
      return findSubtype("thirty_staff_only");
    case "followup":
      return findSubtype("ref_grad");
    default:
      return findSubtype("orthodontics");
  }
};

const reducer = (
  state: UpdateAppointment,
  action: Action
): UpdateAppointment => {
  const { startOfDay, toUTC, addSeconds, diffInSeconds } = time(state.timezone);

  const computeOffset = (doctorOffset: number): number => {
    const apptStartTime =
      state.doctorStartTime &&
      toUTC(addSeconds(state.doctorStartTime, -doctorOffset));
    return diffInSeconds(apptStartTime, state.date);
  };

  switch (action.type) {
    case "doctor":
      return { ...state, doctorId: action.id, hasEdit: true };
    case "room":
      return { ...state, bookableResourceId: action.id, hasEdit: true };
    case "type": {
      const type = action.value;

      let subtype = getDefaultSubtype(type.name, state.subtypes);

      // When changing appointment types, the existing subtype will be incorrect.
      // So if `getDefaultSubtype` returns nothing, then we need to choose the first available subtype.
      if (!subtype && state.subtype) {
        subtype = state.subtypes.find(it => it.appointmentType.id === type.id);
      }

      return {
        ...state,
        type,
        subtype,
        offset: computeOffset(
          subtype ? subtype.doctorOffset : type.largestPatientBuffer
        ),
        hasEdit: true,
      };
    }
    case "subtype": {
      const subtype = action.value;
      if (!subtype) {
        return state;
      }
      return {
        ...state,
        subtype,
        offset: computeOffset(subtype.doctorOffset),
        hasEdit: true,
      };
    }
    case "staff":
      // Set the staff member to the given ID, or an empty array
      return { ...state, staff: action.id ? [action.id] : [], hasEdit: true };
    case "offset": {
      // This form returns the offset of the appointment start time, although we record
      // the doctor start time in the schema.
      //
      // This means that we need to subtract the largest patient buffer from the
      // offset in order to properly update things.
      const { offset } = action;
      const { date, type } = state;
      const d = startOfDay(date);

      // Calculate the new start and end time using the changed offset.
      const doctorStartTime = toUTC(
        addSeconds(d, offset + type.largestPatientBuffer)
      );
      const doctorEndTime = toUTC(
        addSeconds(doctorStartTime, type.doctorDuration)
      );

      return {
        ...state,
        offset,
        doctorStartTime: DateTime.fromISO(doctorStartTime),
        doctorEndTime: DateTime.fromISO(doctorEndTime),
        hasEdit: true,
      };
    }
    case "date": {
      const d = startOfDay(action.date);
      const t = state.type;

      // Calculate the new start and end time using the changed offset.
      const doctorStartTime = toUTC(
        addSeconds(d, state.offset + t.largestPatientBuffer)
      );
      const doctorEndTime = toUTC(
        addSeconds(doctorStartTime, t.doctorDuration)
      );

      return {
        ...state,
        date: DateTime.fromISO(d),
        doctorStartTime: DateTime.fromISO(doctorStartTime),
        doctorEndTime: DateTime.fromISO(doctorEndTime),
        hasEdit: true,
      };
    }
  }
};

const edit = gql`
  mutation UpdateAppointment(
    $appt: UpdateAppointment!
    $id: ID!
    $staff: [ID!]
  ) {
    update: updateAppointment(appointment: $appt) {
      id
      doctorStartTime
      doctorEndTime
      bookableResource {
        id
      }
      doctorId
      doctor {
        name
      }
      appointmentType {
        id
        name
      }
    }

    setStaff: updateAppointmentStaff(appointmentId: $id, staff: $staff) {
      id
      staff {
        id
        name
        roles {
          role
        }
      }
    }
  }
`;

const deleteGql = gql`
  mutation deleteAppointment($id: ID!) {
    appt: deleteAppointment(appointmentId: $id) {
      ids
    }
  }
`;

const Actions = styled.div`
  margin: 12px 4px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const EditForm = ({
  appt,
  onSaveCompleted,
  enableSave,
  close,
  patient,
}: Props) => {
  const { isScheduleManager, canCancelBV } = useSchedulingRestrictions();
  const { clinic, types } = useCalendarViewContext();
  const {
    doctorOptions: availableDoctorOptions,
    staffOptions,
    subtypes,
  } = useSchedulingContext();
  const { startOfDay, toUTC } = time(clinic.timezone);
  const [deleteSlotVisible, setDeleteSlotVisible] = useState(false);
  const [searchText, setSearchText] = useState("");

  let staffForAsignment: any = staffOptions;

  if (searchText) {
    staffForAsignment = staffForAsignment.filter(s => {
      return s.label.toLowerCase().indexOf(searchText) >= 0;
    });
  }

  const [, doEdit] = useMutation(edit);
  const [, doDelete] = useMutation(deleteGql);

  // We need the current appointment type - and all of its offsets - to change the start
  // times of the appointments.  This is because to change the start time you *also need to
  // change the end time*, and to do that we need the duration of the doctor time from the
  // type.
  const currentType =
    types.find(t => t.id === appt.appointmentType.id) ||
    ({} as AppointmentType);

  const isBV = currentType.name === "beginning";
  const canDelete = isBV ? canCancelBV : isScheduleManager;

  let currentSubtype: AppointmentSubtype | undefined = getDefaultSubtype(
    currentType.name,
    subtypes
  );
  if (appt.appointmentSubtype) {
    currentSubtype = appt.appointmentSubtype;
  }
  if (patient) {
    currentSubtype = patient.nextAppointmentSubtype;
  }

  const defaultState = {
    hasEdit: false,
    id: appt.id,
    doctorStartTime: DateTime.fromISO(appt.doctorStartTime),
    doctorEndTime: DateTime.fromISO(appt.doctorEndTime),
    date: DateTime.fromISO(startOfDay(appt.doctorStartTime)),
    type: currentType,
    subtypes: subtypes,
    subtype: currentSubtype,
    offset: appt.offset,
    staff: appt.staff.map(s => s.id),
    timezone: clinic.timezone,
  };

  const [state, dispatch] = useReducer(reducer, defaultState);

  // Instantiate all options for dropdowns.
  const canChangeCategory =
    isBV && !canCancelBV ? false : isScheduleManager && !appt.userId;
  const typeOptions = types
    .filter(t => {
      if (!canChangeCategory) {
        return true;
      }

      return t.name === "beginning" ? canCancelBV : true;
    })
    .map(t => ({ value: t.id, label: getLabel(t) }))
    .filter(e => !!e.label);
  const rooms = clinic.BookableResources.map(r => ({
    label: r.name,
    value: r.id,
  }));

  const onSave = async () => {
    if (state.hasEdit) {
      const input = {
        id: state.id,
        doctorId: state.doctorId,
        doctorStartTime:
          state.doctorStartTime &&
          state.doctorStartTime !== defaultState["doctorStartTime"]
            ? toUTC(state.doctorStartTime)
            : undefined,
        doctorEndTime:
          state.doctorEndTime &&
          state.doctorEndTime !== defaultState["doctorEndTime"]
            ? toUTC(state.doctorEndTime)
            : undefined,
        appointmentTypeId:
          defaultState["type"].id !== state.type.id ? state.type.id : undefined,
        bookableResourceId:
          defaultState["bookableResourceId"] !== state.bookableResourceId
            ? state.bookableResourceId
            : undefined,
      };
      if (state.subtype) {
        if (!defaultState["subtype"]) {
          input["appointmentSubtypeId"] = state.subtype.id;
        } else if (defaultState["subtype"].id !== state.subtype.id) {
          input["appointmentSubtypeId"] = state.subtype.id;
        }
      }

      const result = await doEdit({
        appt: input,
        id: appt.id,
        staff: state.staff,
      });
      if (result.error) {
        return notification.error({
          message: `Error updating appointment: ${gqlError(result.error)}`,
        });
      }
      notification.success({ message: "Appointment updated" });
    }

    onSaveCompleted(get(state, "subtype.id"));
  };

  const onDelete = async () => {
    const result = await doDelete({ id: appt.id });
    if (result.error) {
      return notification.error({
        message: `Error deleting appointment: ${gqlError(result.error)}`,
      });
    }
    notification.success({ message: "Appointment deleted" });
  };

  let doctorOptions = availableDoctorOptions;
  if (
    !isEmpty(appt.doctor) &&
    !availableDoctorOptions.some(doc => doc.value === appt.doctor.id)
  ) {
    const currDoctorOption = {
      value: appt.doctor.id,
      label: `${appt.doctor.firstName} ${appt.doctor.lastName}`,
    };
    doctorOptions = [currDoctorOption, ...availableDoctorOptions];
  }

  return (
    <FormWrapper>
      <Row>
        <Item>
          <Label>Doctor</Label>
          <Dropdown
            selected={state.doctorId || appt.doctorId}
            options={doctorOptions}
            onSelect={d => {
              dispatch({ type: "doctor", id: d.value });
            }}
          />
        </Item>
        <Item>
          <Label>Assistant</Label>
          <StyledAutoComplete
            style={{ width: "100%" }}
            dataSource={staffForAsignment}
            defaultValue={state.staff}
            placeholder="Find by name"
            onSearch={text => {
              setSearchText(text);
              if (text === "") {
                dispatch({ type: "staff", id: undefined });
              }
            }}
            onSelect={id => {
              const staff = staffForAsignment.find(s => s.value === id);
              if (staff) {
                dispatch({ type: "staff", id: staff.value });
              }
            }}
          >
            {staffForAsignment.map(s => (
              <AutoComplete.Option key={s.value} value={s.value}>
                {s.label}
              </AutoComplete.Option>
            ))}
          </StyledAutoComplete>
        </Item>
      </Row>
      <Row>
        <Item>
          <Label>Category</Label>
          <Dropdown
            selected={getLabel(state.type)}
            disabled={!canChangeCategory}
            options={typeOptions}
            onSelect={n => {
              const t = types.find(t => t.id === n.value);
              if (t) {
                dispatch({ type: "type", value: t });
              }
            }}
          />
        </Item>

        <Item>
          <Label>Sub-Category</Label>
          <Dropdown
            disabled={(isBV && !canCancelBV) || !isScheduleManager}
            selected={
              (state.subtype && getAppointmentName(state.subtype.name)) ||
              "(none)"
            }
            options={subtypes
              .filter(s => s.appointmentType.id === state.type.id)
              .map(s => ({
                value: s.id,
                label: getAppointmentName(s.name) || s.name,
              }))}
            onSelect={n => {
              const s = subtypes.find(t => t.id === n.value);
              if (s) {
                dispatch({ type: "subtype", value: s });
              }
            }}
          />
        </Item>
      </Row>

      <Row>
        <Item>
          <Label>Location</Label>
          <Dropdown
            selected={clinic.name || "(none)"}
            disabled={true}
            options={[]}
            onSelect={() => {}}
          />
        </Item>
        <Item>
          <Label>Room</Label>
          <Dropdown
            selected={state.bookableResourceId || appt.bookableResource.id}
            options={rooms}
            onSelect={r => dispatch({ type: "room", id: r.value })}
          />
        </Item>
      </Row>
      <Row>
        <Item>
          <Label>Date</Label>
          <DatePicker
            style={{ flex: 1, display: "flex" }}
            date={state.doctorStartTime && state.doctorStartTime.toISO()}
            disabled={(isBV && !canCancelBV) || !isScheduleManager}
            onChange={(d: string) =>
              dispatch({ type: "date", date: DateTime.fromISO(d) })
            }
            timezone={clinic.timezone}
          />
        </Item>
        <Item>
          <Label>Start Time</Label>
          <OffsetPicker
            value={state.offset}
            disabled={(isBV && !canCancelBV) || !isScheduleManager}
            onChange={(offset: number) => dispatch({ type: "offset", offset })}
          />
        </Item>
      </Row>

      <br />
      <hr />

      {appt.user && (
        <FormsContextProvider patient={appt.user}>
          <div>
            <Label>Prep notes</Label>
            <PatientFormPrepNotes
              patient={appt.user as any}
              isIndirect={appt.appointmentType.name.includes("indirect")}
            />
          </div>

          <div>
            <Label>Schedule notes</Label>
            <InternalNotes objectKind="Appointment" objectID={appt.id} />
          </div>
        </FormsContextProvider>
      )}

      <Footer>
        <Actions>
          {canDelete && (
            <Popconfirm
              placement="topLeft"
              title="Are you sure you want to delete this slot?"
              onConfirm={onDelete}
              visible={deleteSlotVisible}
              setVisible={(visible: boolean) => setDeleteSlotVisible(visible)}
              onCancel={() => {
                setDeleteSlotVisible(false);
              }}
            >
              <Button onClick={() => setDeleteSlotVisible(true)}>
                <Trash />
              </Button>
            </Popconfirm>
          )}
          <div>
            <Button
              type="primary"
              onClick={onSave}
              disabled={!state.hasEdit && !enableSave}
            >
              Save
            </Button>
          </div>
        </Actions>
      </Footer>
    </FormWrapper>
  );
};

export default EditForm;
