import { findAndReplaceMergeOnId, findAndDeleteById } from "src/utils/helperFn";
import { mapValues, unionBy, set, replace } from "lodash";
import {
  TASK_LIST_LIMIT,
  TASK_LIST_HISTORICAL_LIMIT,
} from "../../config/constant";

const LOAD_MORE_INBOX_SUCCESS = "task-list/LOAD_MORE_INBOX_SUCCESS";
const LOAD_INBOX_SUCCESS = "task-list/LOAD_INBOX_SUCCESS";
const LOAD_PATIENT_SUCCESS = "task-list/LOAD_PATIENT_SUCCESS";
const LOAD_TASK_COUNT_SUCCESS = "task-list/LOAD_TASK_COUNT_SUCCESS";
const LOAD_MANUFACTURING_REPORT_SUCCESS =
  "task-list/LOAD_MANUFACTURING_REPORT_SUCCESS";
const LOAD_USER_REPORT_SUCCESS = "task-list/LOAD_USER_REPORT_SUCCESS";

const PUT = "task/PUT";
const PUT_SUCCESS = "task/PUT_SUCCESS";
const PUT_FAIL = "task/PUT_FAIL";

const POST = "task/POST";
const POST_SUCCESS = "task/POST_SUCCESS";
const POST_FAIL = "task/POST_FAIL";

const DELETE_SUCCESS = "@@task/DELETE_SUCCESS";

const LOAD_HISTORICAL_SUCCESS = "@@task-list/LOAD_HISTORICAL_SUCCESS";

const TASK_API = "/api/v1/tasks";
const TASK_INBOX_API = "/api/v1/tasks/inbox";
const TASK_COUNT_API = "/api/v1/tasks/counts";
const MANUFACTURING_REPORT_API = "/api/v1/tasks/manufacturing_reports";

const TASK_HISTORICAL_API = "/api/v1/tasks/historical";

const initialState = {
  taskListCollection: {
    // others are by key using getKey with query params. are arrays
  },
  count: {},
  manufacturingReportCollection: {},
};
// props can either be props or query params
const getReportKey = props =>
  props &&
  replace(
    `${props.startTimeRange || props.start_time_range}-${props.endTimeRange ||
      props.end_time_range}`,
    /\./g,
    ""
  );
export const getPatientTaskList = (state, props) =>
  state.taskList.taskListCollection[props.patientId];
export const getCount = state => state.taskList.count;
export const getManufacturingReport = (state, props) =>
  state.taskList.manufacturingReportCollection[getReportKey(props)];
export const getUserReport = state => {
  // state.taskList.userReport is an array of objects in the structure:
  //   {
  //     appointment: {}, Appointment
  //     tasks: [], // Array<Task>,
  //     user: {}, // User
  //   }
  return (state.taskList.userReport || []).map(data => {
    // find the task with the earliest ship date.
    const shippingTasks = data.tasks
      .filter(t => t.category === "ship")
      .sort(
        (a, b) =>
          new Date(a.due_date).valueOf() - new Date(b.due_date).valueOf()
      );

    // Add the earliest shipping task to the data, if it exists.
    return Object.assign({}, data, { earliestShippingTask: shippingTasks[0] });
  });
};

export const getKey = queryParams => {
  if (!queryParams) return null;
  return `${queryParams["category[]"]}${queryParams.task_type}${
    queryParams.assigned_role
  }${queryParams.manual}`;
};

export default function reducer(state = initialState, action = {}) {
  const result = action.result;
  const queryParams = action.queryParams;
  const key = getKey(queryParams);
  const stateCopy = { ...state };
  switch (action.type) {
    case LOAD_INBOX_SUCCESS:
      return set(stateCopy, `taskListCollection.${key}`, result);
    case LOAD_MORE_INBOX_SUCCESS:
      // we can do unionBy to save prior elements for optimization
      // however this causes the issue of closed tasks still sticking around - not good
      return set(
        stateCopy,
        `taskListCollection.${key}`,
        unionBy(result, state.taskListCollection[key], "id")
      );
    case LOAD_HISTORICAL_SUCCESS:
      return set(
        stateCopy,
        `taskListCollection.${key}`,
        unionBy(result, state.taskListCollection[key], "id")
      );
    case LOAD_PATIENT_SUCCESS:
      return set(
        stateCopy,
        `taskListCollection.${queryParams.user_id}`,
        result
      );
    case PUT:
    case PUT_SUCCESS:
      return {
        ...state,
        taskListCollection: mapValues(state.taskListCollection, taskList => {
          return findAndReplaceMergeOnId(taskList, result || action.task);
        }),
      };
    case POST_SUCCESS:
      return {
        ...state,
        taskListCollection: mapValues(state.taskListCollection, taskList => {
          return [action.result, ...taskList];
        }),
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        taskListCollection: mapValues(state.taskListCollection, taskList => {
          return findAndDeleteById(taskList, action.id);
        }),
      };
    case LOAD_MANUFACTURING_REPORT_SUCCESS:
      return set(
        stateCopy,
        `manufacturingReportCollection.${getReportKey(queryParams)}`,
        action.result
      );
    case LOAD_USER_REPORT_SUCCESS:
      return set(stateCopy, `userReport`, action.result);
    case PUT_FAIL:
    case POST_FAIL:
      return state;
    case LOAD_TASK_COUNT_SUCCESS:
      return {
        ...state,
        count: action.result,
      };
    default:
      return state;
  }
}

export const loadInboxTask = ({ queryParams }) => ({
  types: [null, LOAD_INBOX_SUCCESS, null],
  queryParams,
  promise: client =>
    client.get(TASK_INBOX_API, {
      params: {
        limit: TASK_LIST_LIMIT,
        ...queryParams,
      },
    }),
});

export const loadMoreInboxTask = ({ queryParams }) => ({
  types: [null, LOAD_MORE_INBOX_SUCCESS, null],
  queryParams,
  promise: client =>
    client.get(TASK_INBOX_API, {
      params: {
        limit: TASK_LIST_LIMIT,
        ...queryParams,
      },
    }),
});

export const loadPatientTask = ({ queryParams }) => ({
  types: [null, LOAD_PATIENT_SUCCESS, null],
  queryParams,
  promise: client => client.get(TASK_API, { params: queryParams }),
});

export function loadHistorical({ queryParams }) {
  return {
    types: [null, LOAD_HISTORICAL_SUCCESS, null],
    queryParams,
    promise: client =>
      client.get(TASK_HISTORICAL_API, {
        params: {
          limit: TASK_LIST_HISTORICAL_LIMIT,
          ...queryParams,
        },
      }),
  };
}
export function put(task) {
  return {
    types: [PUT, PUT_SUCCESS, PUT_FAIL],
    task,
    promise: client =>
      client.put(`/api/v1/tasks/${task.id}`, { data: { ...task } }),
  };
}

export function post(task) {
  return {
    types: [POST, POST_SUCCESS, POST_FAIL],
    promise: client => client.post(TASK_API, { data: { ...task } }),
  };
}

export function destroy(id) {
  return {
    types: [null, DELETE_SUCCESS, null],
    id,
    promise: client => client.del(`${TASK_API}/${id}`),
  };
}

export const loadTaskCount = () => ({
  types: [null, LOAD_TASK_COUNT_SUCCESS, null],
  promise: client => client.get(TASK_COUNT_API),
});

export const loadManufacturingReport = queryParams => ({
  queryParams,
  types: [null, LOAD_MANUFACTURING_REPORT_SUCCESS, null],
  promise: client =>
    client.get(MANUFACTURING_REPORT_API, { params: queryParams }),
});

export const loadUserReport = () => ({
  types: [null, LOAD_USER_REPORT_SUCCESS, null],
  promise: client => client.get("/api/v1/tasks/user_reports"),
});
