import { useEffect } from "react";
import { Action, Thunk, thunk, action } from "easy-peasy";
import { useActions, useStore } from "../hooks";
import { request } from "src/shared/util";
import { ProducerIdentifier } from "src/types/api";
import { ApiRequestState } from "src/types/local";

export interface ProducerIdentifierState {
  data: Array<ProducerIdentifier>;
  fetchRequest: ApiRequestState;

  setData: Action<ProducerIdentifierState, Array<ProducerIdentifier>>;
  setFetchRequest: Action<ProducerIdentifierState, ApiRequestState>;
  fetch: Thunk<ProducerIdentifierState, null>;
}

export const producerIdentifiers: ProducerIdentifierState = {
  // data stores each ProducerIdentifier record
  data: [],
  fetchRequest: { loading: false, error: false },

  // Actions
  setData: action((state, data) => {
    state.data = data;
  }),
  setFetchRequest: action((state, fetchRequest) => {
    state.fetchRequest = fetchRequest;
  }),

  fetch: thunk(async (actions, payload) => {
    actions.setFetchRequest({ loading: true, error: false });
    const result = await request(`/api/v1/manufacturing/producer_identifiers`);
    if (Array.isArray(result)) {
      actions.setData(result);
      actions.setFetchRequest({ loading: false, error: false });
      return result;
    }
    actions.setFetchRequest({ loading: false, error: true });
    return [];
  }),
};

export const useFetchProducers = () => {
  // Load producers for manufacturer selection
  const producers = useStore(state => {
    return state.producerIdentifiers;
  });
  const fetch = useActions(actions => {
    return actions.producerIdentifiers.fetch;
  });
  useEffect(() => {
    fetch(null);
  }, [fetch]);
  return [producers.data, producers.fetchRequest];
};
