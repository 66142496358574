import React from "react";
import Cross from "./Cross";

type Props = {
  fill?: string;
  size?: number;
  outlined?: boolean;
  style?: object;
  className?: any;
  onClick?: () => void;
};

const HoverClear: React.SFC<Props> = ({ className, style = {}, onClick }) => (
  <Cross
    size={12}
    outlined
    fill="#fff"
    className={className}
    onClick={onClick}
    style={{ ...style, border: "0 none" }}
    background="#A2A9AD"
  />
);

export default HoverClear;
