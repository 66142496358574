import { useMemo } from "react";
import { kea } from "kea";
import { request } from "src/shared/util";
import { TreatmentCycle, Tray } from "src/types/api";
import { Phase, ApiRequestState } from "src/types/local";

interface Modifiers {
  setTreatmentCycles(tcs: Array<TreatmentCycle>): Array<TreatmentCycle>;

  // Thunk request modifiers
  getPatientTreatmentCyclesStart(): null;
  getPatientTreatmentCyclesError(): null;
  getPatientTreatmentCyclesEnd(): null;
}

interface Thunks {
  getPatientTreatmentCycles(patientId: string): Promise<any>;
  postTreatmentSetup(
    patientId: string,
    cadence: number,
    phases: Array<Phase>,
    notes: string | undefined
  ): Promise<any>;
}

type TCMap = { [id: string]: TreatmentCycle };

interface ReducerProps {
  treatmentCyclesById: TCMap;
  getPatientTreatmentCyclesRequest: ApiRequestState;
}

interface Selectors {
  treatmentCycles: Array<TreatmentCycle>;
}

export type Props = ReducerProps &
  Selectors & {
    actions: Actions;
  };

type Actions = Modifiers & Thunks;

export const TreatmentCyclesEnhancer = kea<Props>({
  actions: () => ({
    setTreatmentCycles: (tcs: Array<TreatmentCycle>) => tcs,
    getPatientTreatmentCyclesStart: () => null,
    getPatientTreatmentCyclesError: () => null,
    getPatientTreatmentCyclesEnd: () => null,
  }),

  reducers: ({ actions }: { actions: { [action: string]: string } }) => ({
    treatmentCyclesById: [
      {},
      {
        [actions.setTreatmentCycles]: (
          prev: TCMap,
          add: Array<TreatmentCycle>
        ) => {
          const map = add.reduce((obj: TCMap, tc) => {
            obj[tc.id] = tc; // eslint-disable-line
            return obj;
          }, {});
          return Object.assign({}, prev, map);
        },
      },
    ],
    getPatientTreatmentCyclesRequest: [
      { error: false, loading: false },
      {
        [actions.getPatientTreatmentCyclesStart]: () => ({
          error: false,
          loading: true,
        }),
        [actions.getPatientTreatmentCyclesError]: () => ({
          error: true,
          loading: false,
        }),
        [actions.getPatientTreatmentCyclesEnd]: () => ({
          error: false,
          loading: false,
        }),
      },
    ],
  }),

  selectors: ({ selectors }: any) => ({
    treatmentCycles: [
      () => [selectors.treatmentCyclesById],
      (map: TCMap) => {
        return Object.values(map);
      },
    ],
  }),

  thunks: ({ actions }: any) => ({
    getPatientTreatmentCycles: async (patientId: string) => {
      actions.getPatientTreatmentCyclesStart();
      // I don't quite know why this has a user_id query param instead of
      // being restful.  We should eventually refactor this to make it better.
      const result = await request(
        `/api/v1/treatment_cycles?user_id=${patientId}`
      );
      if (!Array.isArray(result)) {
        actions.getPatientTreatmentCyclesError();
        return result;
      }

      // Set the tccs in the reducer state and update the request state
      // to show that we've ended the request.
      actions.setTreatmentCycles(result);
      actions.getPatientTreatmentCyclesEnd();

      return result;
    },

    updateTray: async (tray: Tray) => {
      const result = await request(`/api/v1/wear_timeline_stages/${tray.id}`, {
        method: "PUT",
        body: JSON.stringify(tray),
      });
      // TODO: Properly upsert the result instead of refetching the user's
      // treatment cycles.
      actions.getPatientTreatmentCycles(tray.user_id);
      return result;
    },

    updateTrayCadence: async (tray: Tray, delta: number) => {
      const result = await request(
        `/api/v1/wear_timeline_stages/${tray.id}/cadence`,
        {
          method: "PUT",
          body: JSON.stringify({
            delta_in_days: delta,
          }),
        }
      );
      // TODO: Properly upsert the result instead of refetching the user's
      // treatment cycles.
      actions.getPatientTreatmentCycles(tray.user_id);
      return result;
    },

    updateTrays: async (trays: Array<Tray>) => {
      const all = trays.map(t =>
        request(`/api/v1/wear_timeline_stages/${t.id}`, {
          method: "PUT",
          body: JSON.stringify(t),
        })
      );

      await Promise.all(all);
      actions.getPatientTreatmentCycles(trays[0].user_id);
    },

    postTreatmentSetup: async (
      patientId: string,
      cadence: number,
      phases: Array<Phase>,
      notes?: string
    ) => {
      const result = await request(
        `/api/v1/patients/${patientId}/treatment_cycles`,
        {
          method: "POST",
          body: JSON.stringify({
            cadence,
            phases,
            notes,
          }),
        }
      );
      if (result.id) {
        actions.setTreatmentCycles([result]);
      }
      return result;
    },
  }),
});

// useWearableTrays takes a patient ID and all treatment cycles, returning a
// tuple containing only the patient's treatment cycles and the patient's
// wearable trays.
export const useWearableTrays = (
  patientId: string,
  tcs: Array<TreatmentCycle>
): [Array<TreatmentCycle>, Array<Tray>] => {
  return useMemo((): [Array<TreatmentCycle>, Array<Tray>] => {
    const cycles = tcs.filter(tc => tc.user_id === patientId);
    const trays: Array<Tray> = cycles
      .map((tc: TreatmentCycle) => tc.wear_schedule)
      .flat()
      .filter((t: Tray) => !t.skipped)
      .sort((a, b) => a.stage - b.stage);
    return [cycles, trays];
  }, [patientId, tcs]);
};
