import React, { useState } from "react";
import { AutoComplete } from "antd";
import styled, { css } from "react-emotion";
import gql from "graphql-tag";
import { notification } from "antd";

import { useMutation } from "src/utils/http/gqlQuery";
import Modal from "src/shared/Modal";
import Button, { ButtonRow } from "src/shared/Button";
import color from "src/styles/color";

import useSchedulingContext from "src/scenes/SchedulingV2/useSchedulingContext";
import { StyledAutoComplete } from "../Styled";

interface Props {
  appointmentIds: Array<string>;
  clinicId: string;
  closeModal: () => void;
}

const updateDoctorGQL = gql`
  mutation UpdateAppointment($input: UpdateAppointment!) {
    updateAppointment(appointment: $input) {
      id
      doctor {
        id
      }
    }
  }
`;

const updateStaffGQL = gql`
  mutation UpdateAppointment($id: ID!, $staff: [ID!]) {
    setStaff: updateAppointmentStaff(appointmentId: $id, staff: $staff) {
      id
      staff {
        id
        name
        roles {
          role
        }
      }
    }
  }
`;

const AllAssignmentModal = (props: Props) => {
  const [doctorSearch, setDoctorSearch] = useState("");
  const [staffSearch, setStaffSearch] = useState("");

  const [selectedDoctorID, setSelectedDoctorID] = useState<string | null>(null);
  const [selectedStaffID, setSelectedStaffID] = useState<string | null>(null);

  const { doctorOptions, staffOptions } = useSchedulingContext();
  const [, updateDoctor] = useMutation(updateDoctorGQL);
  const [, updateStaff] = useMutation(updateStaffGQL);
  const { appointmentIds } = props;

  const doctors = doctorSearch
    ? doctorOptions.filter(s => {
        return s.label.toLowerCase().indexOf(doctorSearch.toLowerCase()) >= 0;
      })
    : doctorOptions;

  const staff = staffSearch
    ? staffOptions.filter(s => {
        return s.label.toLowerCase().indexOf(staffSearch.toLowerCase()) >= 0;
      })
    : staffOptions;

  const onSubmit = () => {
    appointmentIds.forEach(async appointmentId => {
      if (selectedDoctorID) {
        const result = await updateDoctor({
          input: {
            id: appointmentId,
            doctorId: selectedDoctorID,
          },
        });
        if (result.error) {
          notification.error({
            message: `There was an error assigning this doctor`,
          });
        }
      }

      if (selectedStaffID) {
        const result = await updateStaff({
          id: appointmentId,
          staff: [selectedStaffID],
        });
        if (result.error) {
          notification.error({
            message: `There was an error assigning the staff member`,
          });
        }
      }
    });

    notification.info({
      message: `Finished assigning staff`,
    });
    props.closeModal();
  };

  return (
    <Modal onClose={props.closeModal}>
      <>
        <Content>
          <p>Doctor</p>

          <StyledAutoComplete
            style={{ width: "100%" }}
            dataSource={doctors as any}
            placeholder={`Find doctor by name`}
            onSearch={text => {
              setDoctorSearch(text);
              if (text === "") {
                setSelectedDoctorID(null);
              }
            }}
            onSelect={id => {
              const staff = doctors.find(s => s.value === id);
              if (staff) {
                setSelectedDoctorID(staff.value);
              }
            }}
          >
            {doctors.map(s => (
              <AutoComplete.Option key={s.value} value={s.value}>
                {s.label}
              </AutoComplete.Option>
            ))}
          </StyledAutoComplete>

          <p style={{ marginTop: "2rem" }}>Staff</p>

          <StyledAutoComplete
            style={{ width: "100%" }}
            dataSource={staff as any}
            placeholder={`Find staff by name`}
            onSearch={text => {
              setStaffSearch(text);
              if (text === "") {
                setSelectedStaffID(null);
              }
            }}
            onSelect={id => {
              const result = staff.find(s => s.value === id);
              if (result) {
                setSelectedStaffID(result.value);
              }
            }}
          >
            {staff.map(s => (
              <AutoComplete.Option key={s.value} value={s.value}>
                {s.label}
              </AutoComplete.Option>
            ))}
          </StyledAutoComplete>
        </Content>
        <ButtonRow position="right" className={buttonRowCSS}>
          <Button kind="default" onClick={props.closeModal}>
            Cancel
          </Button>
          <Button
            kind="primary"
            onClick={() => {
              onSubmit();
            }}
          >
            Save
          </Button>
        </ButtonRow>
      </>
    </Modal>
  );
};

const Content = styled.div`
  display: block;
  width: 400px;
  padding: 1rem;
`;

const buttonRowCSS = css`
  margin: 1rem 0 0;
  padding: 0.75rem;
  border-top: 1px solid ${color.borderLight};
`;

export default AllAssignmentModal;
