import React from "react";
import { FormUI } from "src/scenes/PatientProfile/Forms/GQLForms/useFormUI";
import { Question } from "src/scenes/PatientProfile/Forms/GQLForms/types";
import QuestionFC from "src/scenes/PatientProfile/Forms/Question";
import { generateDxSummary } from "../Summary/diagnosisSummary";
import { generateFindingsSummary } from "../Summary/findingsSummary";
import { generateOptionsAndGoalsSummary } from "../Summary/treatmentOptionSummary";
import { generateNextStepTasksSummary } from "../Summary/nextStepsSummary";
import {
  calculateChoices,
  calculateDisplayPredicate,
} from "../GQLForms/consts";

// consts contains utilities to aid in handling questions from GQL => rest.

export const renderTitlegroupQuestions = (
  ui: FormUI,
  tg: string,
  exclude: string[] = []
) => {
  return (
    <>
      {(ui.form.titlegroupQuestionSlugs[tg] || [])
        .filter(slug => !exclude.includes(slug))
        .map(slug => {
          const props = questionProps(ui, slug);
          if (props.display === false) {
            return null;
          }
          return <QuestionFC {...props} />;
        })}
    </>
  );
};

const generatedAnswerQuestions = {
  generated_dx_summary: generateDxSummary,
  treatment_goals_generated_summary: generateOptionsAndGoalsSummary,
  generated_findings_summary: generateFindingsSummary,
  generated_next_steps: generateNextStepTasksSummary,
};

// questionProps returns all props for the Question component.
export const questionProps = (ui: FormUI, slug: string) => {
  const isSubmitted = !!ui.submission.submittedAt;
  const q = ui.form.questions[slug];
  if (!q) {
    console.warn("slug not found in questionProps: " + slug);
    return { handleChange: () => {}, handleBlur: () => {}, display: false }; // noop, question not found.
  }

  // Attempt to find the current answer from ui.submission.
  const answer = ui.getAnswerValue(slug);

  // onAnswer saves an answer to a question in the BV.   We also use the new answer
  // to see if any of the "generated answers" should change.  If so, these new generated
  // answers are also saved.
  const onAnswer = (q: Question, a: any) => {
    if (q.questionType === "generated_input") {
      // Don't resave this - it's saved using the below logic when other answers are
      // updated themselves.
      return;
    }

    // Pre-emptively update the answer map for re-generating the answer with
    // the new value.
    ui.answerMap[q.slug] = a;

    Object.keys(generatedAnswerQuestions).forEach(slug => {
      const generator = generatedAnswerQuestions[slug];
      const previous = ui.getAnswerValue(slug);
      const next = generator(ui.answerMap, ui.submission.toothData);

      if (next !== previous) {
        // Save the generated answer result.
        ui.saveAnswer(ui.form.questions[slug], next);
      }
    });

    ui.saveAnswer(q, a);
  };

  const snaked = snakedQuestion(q || {});
  if (
    [
      "dependent_choice",
      "dependent_multichoice",
      "dependent_multichoice_with_input",
    ].includes(q.questionType) &&
    q.choices
  ) {
    snaked.choices = calculateChoices(snaked, ui.getAnswerValue);
  }

  let display = true;
  if (Array.isArray(q.displayPredicates) && q.displayPredicates.length > 0) {
    // Should we render this question?
    display = calculateDisplayPredicate(q, ui.getAnswerValue);
  }

  return {
    key: q.id,
    submissionID: ui.submission.id,
    question: snaked,
    handleChange: (a: any) => onAnswer(q, a),
    handleBlur: (a: any) => onAnswer(q, a),
    disabled: isSubmitted,
    display,
    answer,
  };
};

type SnakedQuestion = Question & {
  default_placeholder: string;
  question_type: string;
  question_text: string;
  response_type: string;
};

// snakedQuestion adds snake_case question keys given a GQL Question.
export const snakedQuestion = (q: Question): SnakedQuestion => {
  return (
    q && {
      ...q,
      default_placeholder: "", // TODO: Get
      question_type: q.questionType,
      question_text: q.questionText,
      response_type: q.responseType,
    }
  );
};

// hardcodedDisplayPredicate replicates the logic used in the REST design for checking
// whether a question should be visible.
//
// TODO: This should be moved to display predicates returned by GQL in a further refactor.
export const hardcodedDisplayPredicate = (
  ui: FormUI,
  slugs: string[],
  matches: any[]
) => {
  return slugs.find(slug => {
    const answer = ui.getAnswerValue(slug);
    const value = matches.includes(answer);
    return value;
  });
};
