import React, { useState } from "react";
import { Input } from "antd";
import Button, { ButtonRow } from "src/shared/Button";

type Props = {
  objectKind: string;
  objectID: string;

  onCreate: (text: string) => Promise<any>;
};

const NewNote: React.FC<Props> = props => {
  const [value, setValue] = useState("");
  const [saving, setSaving] = useState(false);

  const onSave = async () => {
    setSaving(true);
    const result = await props.onCreate(value);
    if (result && result.id) {
      setValue("");
    }
    setSaving(false);
  };

  return (
    <div>
      <Input.TextArea
        disabled={saving}
        autosize={{ minRows: 2 }}
        value={value}
        onChange={e => setValue(e.target.value)}
        name="note"
        placeholder="Add a note"
        style={{ marginBottom: "12px" }}
      />

      <ButtonRow position="right">
        <Button kind="primary" disabled={saving} onClick={onSave}>
          Post
        </Button>
      </ButtonRow>
    </div>
  );
};

export default NewNote;
