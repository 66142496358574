// @flow
import React from "react";
import {
  compose,
  withProps,
  branch,
  renderComponent,
  lifecycle,
} from "recompose";
import { FormsEnhancer } from "src/state/Forms";
import Loading from "src/shared/Loading";
import NotFound from "src/shared/NotFound";
import NoSubmissions from "./NoSubmissions";
import ViewSubmission from "./Submission";
import FormHeader from "./FormHeader";

import { ConsentsContextProvider } from "src/scenes/PatientProfile/Consents/useConsentsContext";

// type PassedProps = {
//   patient: Patient,
//   params: {
//     formKey: string,
//     submissionId?: string,
//   },
// };

// type ModifierProps = {
//   form: Form,
//   formSubmissions: Array<Submission>,
//   submission: Submission,
// };

// type Props = FormProps & PassedProps & ModifierProps;

const modifiers = compose(
  FormsEnhancer,

  lifecycle({
    componentDidMount() {
      const { actions } = this.props;
      actions.getForms();
    },
  }),

  // If we're still loading forms show a loading indicator.
  branch(props => props.getFormsRequestState.loading, renderComponent(Loading)),

  // Find the current form in props.forms and map it to this.props.form.
  // It's okay if this returns undefined; that's handled next.
  withProps(props => {
    const id = Object.keys(props.forms).find(
      id => props.forms[id].slug === props.params.formKey
    );
    return {
      form: props.forms[id],
    };
  }),

  // Ensure we can find the form by name, else render a form not found.
  // TODO: Use form key, not name.
  branch(props => props.form === undefined, renderComponent(NotFound)),

  // Automatically fetch all submissions
  lifecycle({
    componentDidMount() {
      const { actions, form, patient, submissions } = this.props;

      // making sure that we don't fetch submissions multiple times
      if (
        submissions.length === 0 ||
        (submissions.length > 0 && submissions[0].patient_id !== patient.id)
      ) {
        actions.getPatientSubmissions(form.id, patient.id);
      }
    },
    componentWillReceiveProps(next) {
      if (
        next.form.id !== this.props.form.id ||
        next.patient.id !== this.props.patient.id
      ) {
        next.actions.getPatientSubmissions(next.form.id, next.patient.id);
      }
    },
  }),

  // Load only the submissions that are for this patient and this
  // form, before we render the form header, so that each branch below
  // gets at least an empty array.
  withProps(props => ({
    formSubmissions: props.submissions.filter(
      s => s.patient_id === props.patient.id && s.form_id === props.form.id
    ),
  })),

  // Show a loading component while we fetch the submissions for the patient.
  branch(
    props =>
      props.formSubmissions.length === 0 &&
      props.getSubmissionsRequestState.loading === true,
    renderComponent(props => (
      <div>
        <FormHeader {...props} />
        <Loading />
      </div>
    ))
  ),

  // And if there are no submissions, show the "no submissions" page.
  branch(
    props => props.formSubmissions.length === 0,
    renderComponent(props => (
      <div>
        <FormHeader {...props} />
        <NoSubmissions patientId={props.patient.id} formId={props.form.id} />
      </div>
    ))
  ),

  // Find the current submission passed in as the prop, or, if there's
  // no submission ID, use the first submission as it should be the latest.
  withProps(props => {
    if (!props.params.submissionId) {
      return {
        submission: props.formSubmissions[0],
      };
    }

    const s = props.formSubmissions.find(
      s => s.id === props.params.submissionId
    );
    return {
      submission: s,
    };
  }),

  // Ensure we have a submission in props.submission from finding it above.
  branch(props => props.submission === undefined, renderComponent(NotFound))
);

/**
 * ViewForm is the main component for viewing a form and its submissions.
 *
 * It takes the form ID and optional submission ID from the router and loads the
 * specific form and submission as the content.
 */
const ViewForm = props => {
  const { form, patient, submission } = props;

  return (
    <div>
      <ConsentsContextProvider patientId={patient.id}>
        <FormHeader {...props} />
        <ViewSubmission form={form} patient={patient} submission={submission} />
      </ConsentsContextProvider>
    </div>
  );
};

export default modifiers(ViewForm);
