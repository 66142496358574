import React, { useState, useEffect, useCallback } from "react";
import { useWindowScroll } from "react-use";
import { flatten, sortBy, toPairs, isEqual } from "lodash";

import Button, { ButtonRow } from "src/shared/Button";
import Modal from "src/shared/Modal";
import { DetailedPatient } from "src/types/api";
import Loading from "src/shared/Loading";

import { usePipelines } from "src/scenes/Tasks/queries";
import PipelineTable from "src/scenes/Tasks/PipelineTable/Table";
import NewPipeline from "src/scenes/Tasks/NewPipeline/NewPipeline";
import { PipelineFilter } from "src/scenes/Tasks/types";

type Props = {
  params: {
    userId: string;
  };
  patient: DetailedPatient;
};

const PIPELINES_PER_PAGE = 100;

const PatientPipelines: React.FC<Props> = props => {
  const { y } = useWindowScroll();
  const [page, setPage] = useState(1);
  const [pipelinesByPage, setPipelinesByPage] = useState({});
  const [showNew, setShowNew] = useState(false);
  const [uiFilters, setUIFilters] = useState<PipelineFilter>({});

  const filter = {
    userID: props.params.userId,
    includeSingleTasks: true,
    ...uiFilters,
  };

  const [{ error, data, fetching }] = usePipelines(
    filter,
    page,
    PIPELINES_PER_PAGE
  );

  const handleFilterChange = useCallback(
    newFilters => {
      if (!isEqual(uiFilters, newFilters)) {
        setPipelinesByPage({});
        setPage(1);
      }
      setUIFilters(newFilters);
    },
    [uiFilters]
  );

  useEffect(() => {
    if ((data && data.pipelines.pageResults.totalPages <= page) || fetching)
      return;

    const offset = Math.ceil(window.innerHeight + y) + 20;
    if (offset >= document.body.scrollHeight) {
      setPage(currPage => currPage + 1);
    }
  }, [y, data, fetching, page]);

  useEffect(() => {
    if (!data || !data.pipelines) return;
    setPipelinesByPage(currTasks => ({
      ...currTasks,
      [data.pipelines.pageResults.page]: data.pipelines.pipelines,
    }));
  }, [data]);

  const pipelines = flatten(
    sortBy(toPairs(pipelinesByPage), pair => parseInt(pair[0])).map(
      pair => pair[1]
    )
  );

  if (error) {
    return <p>Error loading pipelines</p>;
  }

  return (
    <>
      <ButtonRow position="right">
        <Button kind="primary" onClick={() => setShowNew(true)}>
          Create pipeline
        </Button>
      </ButtonRow>
      <PipelineTable
        pipelines={pipelines}
        routeFilter="all"
        urlPrefix={`/patients/${props.patient.id}/pipelines`}
        onUpdateFilter={handleFilterChange}
      />
      {fetching && <Loading />}
      {showNew && (
        <Modal onClose={() => setShowNew(false)}>
          <NewPipeline
            user={{
              ...props.patient,
              mobilePhoneNumber: props.patient.mobile_phone_number,
              preferredName: props.patient.preferred_name,
              firstName: props.patient.first_name,
              lastName: props.patient.last_name,
            }}
            onCancel={() => setShowNew(false)}
            onComplete={() => setShowNew(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default PatientPipelines;
