import React from "react";
import styled, { cx, css } from "react-emotion";
import { Tooltip } from "antd";
import { tooltipText } from "./util";
import ProximalFlags from "./ProximalFlags";

const classNames = (procedures, position) => {
  const classes = procedures.map(p => {
    switch (p.entry_type) {
      case "pontic":
        if (position === "wrapper") {
          return ponticClass;
        }
        break;
      case "extraction":
        if (position !== "wrapper") {
          return extractionClass;
        }
        return undefined;
      case "spacing":
        if (
          p.entry_data &&
          p.entry_data["position"].toLowerCase() === position
        ) {
          return spacingClass;
        }
        break;
      case "ipr":
        if (
          p.entry_data &&
          p.entry_data["position"].toLowerCase() === position
        ) {
          // Find whether we're showing an estimated, prescribed, or rendered
          // item.
          return iprClasses[p.entry_stage] || iprClasses.estimated;
        }
        break;
      case "add_attachment":
        if (
          p.entry_data &&
          p.entry_data["position"].toLowerCase() === position
        ) {
          return (
            attachmentClasses[p.entry_stage] || attachmentClasses.estimated
          );
        }
        break;
      case "remove_attachment":
        if (
          p.entry_data &&
          p.entry_data["position"].toLowerCase() === position
        ) {
          return (
            removeAttachmentClasses[p.entry_stage] ||
            removeAttachmentClasses.estimated
          );
        }
        break;
      case "add_powerarms":
        if (position === "buccal") {
          return powerarmsClasses[p.entry_stage] || powerarmsClasses.estimated;
        }
        break;
      case "remove_powerarms":
        if (position === "buccal") {
          return (
            removePowerarmsClasses[p.entry_stage] ||
            removePowerarmsClasses.estimated
          );
        }
        break;
      case "add_bracket":
        if (position === "buccal") {
          return bracketClass;
        }
        break;
      case "power_ridge":
        if (position === "buccal") {
          return powerRidgeClass;
        }
        break;
      case "direct_buildup":
      case "indirect_buildup":
        if (position === "occlusal") {
          return buildupClass;
        }
        break;
      case "disclusion":
        if (position === "occlusal") {
          return disclusionClass;
        }
        break;
      case "tray_cutouts":
      case "add_hook":
        if (
          p.entry_data &&
          p.entry_data["position"] &&
          p.entry_data["position"].toLowerCase() === position
        ) {
          return elasticsClass;
        }
        if (
          (!p.entry_data || !p.entry_data.position) &&
          position === "occlusal"
        ) {
          return elasticsClass;
        }
        break;
      // Antecedent
      case "bridge":
      case "caries":
      case "crown":
      case "deciduous":
      case "gum_disease":
      case "impacted":
      case "implant":
      case "missing":
      case "restoration":
      case "root_canal":
      case "veneer":
      case "attachment_loss":
        if (position === "wrapper") {
          return otherClass;
        }
        break;
      default:
        return undefined;
    }
    return undefined;
  });
  return cx(...classes);
};

const Tooth = props => {
  const onClick = position => {
    const { section, number, onClick } = props;
    // Call the click function passed from the parent tooth chart.
    // This is used in parity with the selected procedure type in order to
    // record an event for the tooth.
    onClick(section, number, position);
  };

  const { section, type, number, procedures: p, flagProcedures } = props;

  return (
    <Tooltip
      placement={["UL", "UR"].indexOf(section) > -1 ? "top" : "bottom"}
      title={
        p.length > 0
          ? p.map((item, n) => <Hint key={n}>{tooltipText(item)}</Hint>)
          : "-"
      }
    >
      <Wrapper className={getBackground(section, number)}>
        <div className={cx(classNames(p, "wrapper"))}>
          <Name type={type}>{number}</Name>
          <Proximal
            className={classNames(p, "left proximal")}
            onClick={() => {
              onClick("left proximal");
            }}
          />
          <Center type={type}>
            <Buccal
              className={classNames(p, "buccal")}
              onClick={() => onClick("buccal")}
            />
            <Occlusal
              className={classNames(p, "occlusal")}
              onClick={() => onClick("occlusal")}
            />
            <Lingual
              className={classNames(p, "lingual")}
              onClick={() => {
                onClick("lingual");
              }}
            />
          </Center>
          <Proximal
            className={classNames(p, "right proximal")}
            onClick={() => {
              onClick("right proximal");
            }}
          />

          <ProximalFlags
            procedures={flagProcedures}
            position={section.indexOf("U") > -1 ? "upper" : "lower"}
          />
        </div>
      </Wrapper>
    </Tooltip>
  );
};

export default Tooth;

const getBackground = (t, n) => {
  // take the tooth number;  the background is always upper-right-N with
  // transformations applied.
  const bg = `UR${n}`;
  let transform = "";

  switch (t) {
    case "UL":
      transform = "transform: scaleX(-1)";
      break;
    case "LL":
      transform = "transform: scale(-1, -1)";
      break;
    case "LR":
      transform = "transform: scaleY(-1)";
      break;
    default:
      transform = "";
  }

  return css`
    position: relative;

    &:before {
      position: absolute;
      display: block;
      content: "";
      opacity: 0.5;
      width: 100%;
      height: 35%;
      background: no-repeat url(/static/${bg}.svg) center center;
      background-size: contain;
      ${transform};
      top: ${t[0] === "U" ? "0" : "65%"};
    }

    > div {
      position: relative;
      top: ${t[0] === "U" ? "50%" : "0"};
      width: 100%;
      height: 50%;
      display: flex;
      flex: 1;
    }
  `;
};

const Hint = styled("p")`
  font-size: 0.7rem;
  margin: 0;
  text-align: center;
  color: white;
`;

const surface = css`
  flex: 1;
  border-radius: 3px;
  border: 1px solid #e4e4e4;
  position: relative;
  cursor: pointer;
  transition: 0.3s all;

  &:hover:after {
    opacity: 0.4;
  }

  &:after {
    display: block;
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -0.5rem;
    font-size: 0.7rem;
    letter-spacing: -0.4px;
    opacity: 0.2;
    text-align: center;
  }
`;

const Wrapper = styled("div")`
  display: flex;
  flex: 1;
  padding: 0.5rem 0.25rem;
  margin: 0.5rem 0;
  max-width: 5rem;
  position: relative;
  border-radius: 3px;
  padding-right: 2px;

  & + & {
    padding-left: 2px;
    border-left: 1px dotted #f5f5f5;
  }

  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
  }
`;

const Center = styled("div")`
  flex: 3;
  display: flex;
  margin: 0 4px;
  flex-direction: ${props => {
    return props.type === "maxillary" ? "column-reverse" : "column";
  }};
`;

const Proximal = styled("div")`
  ${surface} margin: 10px 0;
`;

const Occlusal = styled("div")`
  ${surface} margin: 4px 0;
`;

const Buccal = styled("div")`
  ${surface};
  &:after {
    content: "B";
  }
`;

const Lingual = styled("div")`
  ${surface} &:after {
    content: "L";
  }
`;

const Name = styled("span")`
  display: inline-block;
  width: 100%;
  left: 0;
  text-align: center;
  position: absolute;
  font-size: 0.7rem;
  color: #999;
  cursor: default;

  ${props =>
    props.type === "mandibular" ? `top: -1.1rem` : `bottom: -1.1rem`};
`;

const spacingClass = css`
  box-shadow: inset 0 0 0px 1px #f4f4f4;
  background: repeating-linear-gradient(
    45deg,
    #eca311,
    #f9ad18 0px,
    #fff 2px,
    #fff 5px
  );
`;

const iprClasses = {
  estimated: css`
    box-shadow: inset 0 0 0px 1px #f4f4f4;
    background: repeating-linear-gradient(
      45deg,
      rgb(6, 160, 211),
      rgb(6, 160, 211) 2px,
      #fff 2px,
      #fff 8px
    );
  `,
  prescribed: css`
    box-shadow: inset 0 0 0px 1px #f4f4f4;
    background: repeating-linear-gradient(
      45deg,
      rgb(6, 160, 211),
      rgb(6, 160, 211) 2px,
      #fff 2px,
      #fff 4px
    );
  `,
  rendered: css`
    box-shadow: inset 0 0 0px 1px #f4f4f4;
    background: rgb(6, 160, 211);
  `,
};

const attachmentClasses = {
  estimated: css`
    box-shadow: inset 0 0 0px 1px #f4f4f4;
    background: repeating-linear-gradient(
      45deg,
      #eba0ef,
      #eba0ef 2px,
      #fff 2px,
      #fff 8px
    );
  `,
  prescribed: css`
    box-shadow: inset 0 0 0px 1px #f4f4f4;
    background: repeating-linear-gradient(
      45deg,
      #eba0ef,
      #eba0ef 2px,
      #fff 2px,
      #fff 4px
    );
  `,
  rendered: css`
    box-shadow: inset 0 0 0px 1px #f4f4f4;
    background: #eba0ef;
  `,
};

const powerarmsClasses = {
  estimated: css`
    box-shadow: inset 0 0 0px 1px #f4f4f4;
    background: repeating-linear-gradient(
      45deg,
      rgb(255, 227, 172),
      rgb(255, 227, 172) 2px,
      #fff 2px,
      #fff 8px
    );
  `,
  prescribed: css`
    box-shadow: inset 0 0 0px 1px #f4f4f4;
    background: repeating-linear-gradient(
      45deg,
      rgb(255, 227, 172),
      rgb(255, 227, 172) 2px,
      #fff 2px,
      #fff 4px
    );
  `,
  rendered: css`
    box-shadow: inset 0 0 0px 1px #f4f4f4;
    background: rgb(255, 227, 172);
  `,
};

const removePowerarmsClasses = {
  estimated: css`
    box-shadow: inset 0 0 0px 1px #f4f4f4;
    background: repeating-linear-gradient(
      45deg,
      rgb(255, 227, 172),
      rgb(255, 227, 172) 2px,
      #fff 2px,
      #fff 8px
    );

    &:before {
      content: "R.P.";
      display: block;
      background: rgba(255, 255, 255, 0.6);
      position: absolute;
      top: 0;
      width: 100%;
      text-align: center;
      line-height: 1;
      font-size: 0.6rem;
      top: 50%;
      margin-top: -0.33rem;
      padding: 0.06rem 0;
    }
  `,
  prescribed: css`
    box-shadow: inset 0 0 0px 1px #f4f4f4;
    background: repeating-linear-gradient(
      45deg,
      rgb(255, 227, 172),
      rgb(255, 227, 172) 2px,
      #fff 2px,
      #fff 4px
    );

    &:before {
      content: "R.P.";
      background: rgba(255, 255, 255, 0.6);
      display: block;

      position: absolute;
      top: 0;
      width: 100%;
      text-align: center;
      line-height: 1;
      font-size: 0.6rem;
      top: 50%;
      margin-top: -0.33rem;
      padding: 0.06rem 0;
    }
  `,
  rendered: css`
    box-shadow: inset 0 0 0px 1px #f4f4f4;
    background: rgb(255, 227, 172);

    &:before {
      content: "R.A.";
      display: block;
      background: rgba(255, 255, 255, 0.6);
      position: absolute;
      top: 0;
      width: 100%;
      text-align: center;
      line-height: 1;
      font-size: 0.6rem;
      top: 50%;
      margin-top: -0.33rem;
      padding: 0.06rem 0;
    }
  `,
};

const removeAttachmentClasses = {
  estimated: css`
    box-shadow: inset 0 0 0px 1px #f4f4f4;
    background: repeating-linear-gradient(
      45deg,
      #eba0ef,
      #eba0ef 2px,
      #fff 2px,
      #fff 8px
    );

    &:before {
      content: "R.A.";
      display: block;
      background: rgba(255, 255, 255, 0.6);
      position: absolute;
      top: 0;
      width: 100%;
      text-align: center;
      line-height: 1;
      font-size: 0.6rem;
      top: 50%;
      margin-top: -0.33rem;
      padding: 0.06rem 0;
    }
  `,
  prescribed: css`
    box-shadow: inset 0 0 0px 1px #f4f4f4;
    background: repeating-linear-gradient(
      45deg,
      #eba0ef,
      #eba0ef 2px,
      #fff 2px,
      #fff 4px
    );

    &:before {
      content: "R.A.";
      background: rgba(255, 255, 255, 0.6);
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      text-align: center;
      line-height: 1;
      font-size: 0.6rem;
      top: 50%;
      margin-top: -0.33rem;
      padding: 0.06rem 0;
    }
  `,
  rendered: css`
    box-shadow: inset 0 0 0px 1px #f4f4f4;
    background: #eba0ef;

    &:before {
      content: "R.A.";
      display: block;
      background: rgba(255, 255, 255, 0.6);
      position: absolute;
      top: 0;
      width: 100%;
      text-align: center;
      line-height: 1;
      font-size: 0.6rem;
      top: 50%;
      margin-top: -0.33rem;
      padding: 0.06rem 0;
    }
  `,
};

const disclusionClass = css`
  box-shadow: inset 0 0 0px 1px #f4f4f4;
  background: repeating-linear-gradient(
    45deg,
    #a9ff00,
    #a9ff00 2px,
    #fff 2px,
    #fff 4px
  );
`;

const ponticClass = css`
  box-shadow: inset 0 0 0px 1px #f4f4f4;
  background: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5) 1px,
    #fff 1px,
    #fff 4px
  );
`;

const extractionClass = css`
  box-shadow: inset 0 0 0px 1px #f4f4f4;
  background: repeating-linear-gradient(
    45deg,
    rgb(255, 16, 16),
    rgb(255, 16, 16) 1px,
    #fff 1px,
    #fff 4px
  );
`;

const buildupClass = css`
  border: 2px dashed #6effa0;
`;

const elasticsClass = css`
  border: 2px dashed #045900;
`;

const bracketClass = css`
  box-shadow: inset 0 0 0px 1px #f4f4f4;
  background: repeating-linear-gradient(
    45deg,
    rgb(41, 181, 50),
    rgb(41, 181, 50) 2px,
    #fff 2px,
    #fff 4px
  );
`;

const powerRidgeClass = css`
  box-shadow: inset 0 0 0px 1px #f4f4f4;
  background: repeating-linear-gradient(
    45deg,
    rgb(107, 45, 198),
    rgb(107, 45, 198) 2px,
    #fff 2px,
    #fff 4px
  );
`;

const otherClass = css`
  box-shadow: inset 0 0 0px 1px #f4f4f4;
  background: repeating-linear-gradient(
    45deg,
    #ebffe9,
    #0b6500 1px,
    #fff 1px,
    #fff 10px
  );
`;
