import React from "react";
import { DateTime } from "luxon";
import { notification } from "antd";

import { OperationResult, useMutation } from "src/utils/http/gqlQuery";
import { ActionButton } from "src/shared/Table";
import {
  updateAppointment,
  checkInAppointment,
} from "src/scenes/SchedulingV2/queries";

const CheckInAndOutModal = props => {
  const { appointment } = props;
  const [, update] = useMutation(updateAppointment);
  const [, checkIn] = useMutation(checkInAppointment);

  if (!appointment) {
    return null;
  }

  const isCheckIn = !appointment.actualStartTime && !appointment.actualEndTime;

  const handleConfirm = async (time: DateTime) => {
    let updateParams = {};

    let result: OperationResult;

    if (isCheckIn) {
      result = await checkIn({
        appointmentID: appointment.id,
      });
    } else {
      updateParams = {
        id: appointment.id,
        actualEndTime: time,
      };
      result = await update({
        appt: updateParams,
      });
    }

    if (result.error) {
      return notification.error({ message: "Error checking in patient" });
    }

    if (props.handleSuccess) {
      props.handleSuccess();
    }
    notification.success({
      message: isCheckIn ? "Patient checked in" : "Patient checked out",
    });
  };

  const currentTime = DateTime.local();

  let buttonText = "";
  if (isCheckIn) {
    if (
      appointment.startTime &&
      currentTime
        .toUTC()
        .diff(DateTime.fromISO(appointment.endTime), "minutes")
        .valueOf() > 0
    ) {
      buttonText = props.hideNoShow ? "Check In" : "No show";
    } else {
      buttonText = "Check In";
    }
  } else if (!appointment.actualEndTime) {
    buttonText = "Check Out";
  } else {
    buttonText = "Completed";
  }

  return (
    <ActionButton
      style={props.styles}
      disabled={buttonText === "Completed"}
      type={buttonText === "No show" ? "warning" : "primary"}
      onClick={() => handleConfirm(currentTime.toUTC())}
      {...props}
    >
      {buttonText}
    </ActionButton>
  );
};

export default CheckInAndOutModal;
