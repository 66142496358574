import React from "react";

const Upload = () => {
  return (
    <svg
      width="51"
      height="34"
      viewBox="0 0 51 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.225 12.75C39.7375 5.525 33.3625 0 25.5 0C19.3375 0 14.025 3.4 11.475 8.5C4.8875 9.35 0 14.6625 0 21.25C0 28.2625 5.7375 34 12.75 34H40.375C46.325 34 51 29.325 51 23.375C51 17.85 46.5375 13.175 41.225 12.75ZM29.75 19.125V27.625H21.25V19.125H14.875L25.5 8.5L36.125 19.125H29.75Z"
        fill="#A2A9AD"
      />
    </svg>
  );
};

export default Upload;
