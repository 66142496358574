// @flow
import React from "react";
import styled, { css } from "react-emotion";
import { Link, browserHistory } from "react-router";

import { useSelfRoles } from "src/state/self";
import StyledButton from "src/shared/Button";
import {
  Breadcrumb,
  Button,
  Menu,
  Dropdown,
  Icon,
  Popconfirm,
  message,
} from "antd";
import {
  formatSubmissionLabel,
  sortBySubmitOrCreateTime,
} from "src/shared/submissions";
import {
  performDeleteSubmission,
  performEditSubmission,
  performApproveSubmission,
} from "src/scenes/PatientProfile/Forms/GQLForms/formActions";

// type Props = FormProps & {
//   patient: Patient,
//   form: Form,
//   formSubmissions: Array<Submission>,
//   submission?: Submission,
// };

export default function FormHeader(props) {
  const {
    isDoctor,
    isTreatmentPlanner,
    isInternalManufacturing,
  } = useSelfRoles();

  const makeSubmission = async () => {
    const hide = message.loading("Creating new response", 0);

    const { form, patient, actions } = props;
    const result = await actions.newSubmission({
      formId: form.id,
      patientId: patient.id,
    });

    // Hide the "Creating new response" message, as the request has now finished
    hide();

    if (!result.id) {
      // Errorred out.
      message.error(result.errors || "We couldn't make a response");
      return;
    }

    message.success("Taking you to your response");
    browserHistory.push(
      `/patients/${patient.id}/forms/${form.slug}/${result.id}`
    );
  };

  const editSubmission = async () => {
    const { form, patient, submission, actions } = props;
    if (
      await performEditSubmission(submission.id, actions.unsubmitSubmission)
    ) {
      browserHistory.push(`/patients/${patient.id}/forms/${form.slug}`);
    }
  };

  const deleteSubmission = async () => {
    const { form, patient, submission, actions } = props;
    if (
      await performDeleteSubmission(submission.id, actions.deleteSubmission)
    ) {
      browserHistory.push(`/patients/${patient.id}/forms/${form.slug}`);
    }
  };

  const approveSubmission = async () => {
    const { form, patient, submission, actions } = props;
    if (
      await performApproveSubmission(submission.id, actions.approveSubmission)
    ) {
      browserHistory.push(`/patients/${patient.id}/forms/${form.slug}`);
    }
  };

  const { form, patient, submission } = props;
  const isSubmitted = submission && !!submission.submitted_at;
  const isApproved = submission && !!submission.approved_at;
  const showEditButton =
    isSubmitted && (!isApproved || isDoctor) && form.slug === "treatment_setup";
  const showApproveButton =
    form.slug === "treatment_setup"
      ? (isTreatmentPlanner || isDoctor || isInternalManufacturing) &&
        isSubmitted &&
        !isApproved
      : isDoctor && isSubmitted && !isApproved;
  const showDeleteButton = submission && !isSubmitted;
  const formSubmissions = sortBySubmitOrCreateTime(props.formSubmissions);

  return (
    <PageHeader>
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={`/patients/${patient.id}`}>{patient.name}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/patients/${patient.id}/forms/${form.slug}`}>
              {form.name}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <h5>{form.name}</h5>
        <p style={{ opacity: 0.5 }}>{form.description}</p>
      </div>

      <Right>
        <p style={{ opacity: 0.5 }} />

        <Button
          size="small"
          style={{ marginLeft: "8px", marginBottom: "1rem", opacity: 0.75 }}
          onClick={makeSubmission}
          disabled={form.slug === "followup_visit"}
        >
          New response
        </Button>

        {showEditButton && (
          <Button
            size="small"
            style={{
              marginLeft: "8px",
              marginBottom: "1rem",
              opacity: 0.75,
            }}
            onClick={editSubmission}
          >
            Edit
          </Button>
        )}

        {showDeleteButton && (
          <Popconfirm
            title="Delete submission?"
            okText="Yes"
            cancelText="No"
            placement="bottom"
            onConfirm={() => {
              deleteSubmission();
            }}
          >
            <Button
              size="small"
              style={{
                marginLeft: "8px",
                marginBottom: "1rem",
                opacity: 0.75,
              }}
            >
              Delete
            </Button>
          </Popconfirm>
        )}

        {showApproveButton && (
          <StyledButton
            kind="primary"
            style={{
              marginLeft: "8px",
              marginBottom: "1rem",
            }}
            onClick={approveSubmission}
          >
            Approve
          </StyledButton>
        )}

        <Dropdown
          overlay={
            <Menu
              onClick={e => {
                browserHistory.push(
                  `/patients/${patient.id}/forms/${form.slug}/${e.key}`
                );
              }}
            >
              {formSubmissions.map((s, n) => (
                <Menu.Item
                  key={s.id}
                  style={{ fontSize: ".8rem" }}
                  className={
                    submission && s.id === submission.id ? activeCss : null
                  }
                >
                  {formatSubmissionLabel(s)}
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <Button style={{ marginLeft: 8 }}>
            {formSubmissions.length} response
            {formSubmissions.length !== 1 && "s"}
            {submission && ` (${formatSubmissionLabel(submission)})`}
            <Icon type="down" />
          </Button>
        </Dropdown>
      </Right>
    </PageHeader>
  );
}

const PageHeader = styled("div")`
  display flex;
  justify-content: space-between;
  background: #fff;
  border-bottom: 2px solid rgb(236, 236, 233);

  > div {
    padding: 1rem 2rem;
  }

  h2 {
    padding: 1rem 0 0;
    margin: 0;
  }
`;

const Right = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const activeCss = css`
  background: #e6f7ff;
`;
