import React from "react";
import styled from "react-emotion";
import textStyles from "src/styles/textStyles";
import EmptyFormIcon from "src/shared/Icons/EmptyForm";
import Button from "src/shared/Button";

type Props = {
  onNew: () => void;
  disabled?: boolean;
};

export default (props: Props) => {
  return (
    <Centered>
      <EmptyFormIcon />

      <LargeText>
        It looks like the patient doesn't have this form filled out.
      </LargeText>
      <Button
        kind="primary"
        style={{ width: "100px" }}
        onClick={props.onNew}
        disabled={props.disabled}
      >
        Start
      </Button>
    </Centered>
  );
};

const Centered = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: 124px 0 70px;
`;

const LargeText = styled.div`
  ${textStyles("xlarge")};
  font-weight: bold;
  margin: 32px 0 8px;
`;
