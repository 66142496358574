import { createContext, useContext } from "react";

// This module provides a context for adding
// decorative details to form questions that would
// either not make sense to put explicitly into
// the form schema, or that would be too much or too risky or short-term
// to be put into the form schema.

export interface FormDecorationsData {
  questionDecorations: Map<string, QuestionDecoration>;
  choiceDecorations: Map<string, ChoiceDecoration>;
}

export interface QuestionDecoration {
  hint?: string; // Parenthesized hint after the question title
  template?: string; // Template text for large textareas (ie: input_large)
}

export interface ChoiceDecoration {
  hint: string; // Parenthesized hint after the choice label
}

export const FormDecorationsContext = createContext<FormDecorationsData>(
  makeBlankDecorationsData()
);

export function useFormDecorations(): FormDecorationsData {
  return useContext(FormDecorationsContext);
}

export function getFormDecorations(formSlug: string): FormDecorationsData {
  const triggersPreTxTask = {
    hint: "triggers pre-tx task",
  };

  if (formSlug === "referral") {
    return {
      questionDecorations: new Map(),
      choiceDecorations: new Map([
        [
          "Operative restorative treatment (filling) - gross caries, fractured tooth, etc., requires new scan",
          triggersPreTxTask,
        ],
        ["Periodontal therapy - treat active disease", triggersPreTxTask],
        [
          "Periodontal therapy - treat active disease + calculus bridges, requires new scan",
          triggersPreTxTask,
        ],
        ["Pre-orthodontic periodontal grafting evaluation", triggersPreTxTask],
        ["TMD therapy", triggersPreTxTask],
      ]),
    };
  } else if (formSlug === "beginning_visit") {
    const referralRequired = {
      hint: "referral required",
    };
    return {
      questionDecorations: new Map([
        [
          "has_pending_dental_work_that_cannot_wait",
          { hint: "yes = referral required" },
        ],
        ["months_to_monitor_mixed_dentition", triggersPreTxTask],
      ]),
      choiceDecorations: new Map([
        ["Crepitus", referralRequired],
        ["Active disease present", referralRequired],
        ["Attachment loss beyond mucogingival junction", referralRequired],
        ["Gross caries (ICDAS 4+)", referralRequired],
        ["Bridges of Calculus are present", referralRequired],
        ["Endodontic Infection", referralRequired],
      ]),
    };
  } else if (formSlug === "treatment_option") {
    const summaryTemplate = [
      "Next Steps:",
      "Notes for Treatment planners:",
      "Anything critical and/or specific to this patients treatment, timeline, or needs to note?",
      "Other:",
    ].join("\n\n");

    return {
      questionDecorations: new Map([
        [
          "treatment_option_summary",
          {
            template: summaryTemplate,
          },
        ],
      ]),
      choiceDecorations: new Map([]),
    };
  }

  return makeBlankDecorationsData();
}

// Internal function, do not export.
function makeBlankDecorationsData(): FormDecorationsData {
  return {
    questionDecorations: new Map(),
    choiceDecorations: new Map(),
  };
}
