import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import PropTypes from "prop-types";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import DatePicker from "material-ui/DatePicker";
import IconAttachment from "material-ui/svg-icons/file/attachment";
import GalleryPhotos from "../gallery/gallery-photo";

import { ANY, LINE_BREAK, QUESTION_TYPE } from "src/config/constant";
/**
 * NOTE! unusual behavior here
 * We directly post in this component and then update the treatmentObj with Url
 * Needed to show progress bar for an upload. Progress is updated only in this app
 * rather than global state
 */
import superagent from "superagent";
import cookie from "cookie";

import {
  toggleItemInArray,
  getThumbnailUrl,
  isImage,
} from "src/utils/helperFn";

import styles from "./question-style";
import ModifyWearScheduleButton from "../modify-wear-schedule-button/modify-wear-schedule-button";
import SocialNote from "../../../../components/social-note/social-note";

// question types
const {
  SELECT,
  MODIFY_WEAR_SCHEDULE,
  MULTI_SELECT,
  TEXT,
  STL,
  PHOTO,
  DATE_PICKER,
  ALERT,
  SOCIAL_NOTE,
} = QUESTION_TYPE;

const CLINICAL_PHOTO = "clinical_photo";

const DEBOUNCE_TIME = 1000;

const evaluateDisplayCondition = (displayCondition, pathToQuestion, event) => {
  if (!displayCondition) return false;
  const { id, answer } = displayCondition;
  let pathToQuestionList = pathToQuestion.split("questionList");
  pathToQuestionList.pop();
  pathToQuestionList = pathToQuestionList.join("").concat("questionList");
  const questionList = _.get(event, pathToQuestionList);
  const conditionQuestion = _.find(questionList, { conditionId: id });
  if (!conditionQuestion) {
    return false;
  }
  const conditionAnswer =
    conditionQuestion.answerList || conditionQuestion.answer;
  if (answer === ANY) {
    // answer=true means we show for any value
    return _.isEmpty(conditionAnswer);
  }
  return _.isEmpty(_.intersection(answer, conditionAnswer)); // if there's an overlap, we dont hide.
};
const getFileUploadUrl = userId => {
  return `${process.env.REACT_APP_API_HOST}/api/v1/patients/${userId}/files`;
};
const shouldHide = (displayCondition, pathToQuestion, event) => {
  if (!displayCondition) return false;
  if (Array.isArray(displayCondition)) {
    // if we return false, we dont hide. so this is an OR case, so reverse logic for ORing on false
    return _.every(displayCondition, singleDisplayCondition => {
      return evaluateDisplayCondition(
        singleDisplayCondition,
        pathToQuestion,
        event
      );
    });
  }
  return evaluateDisplayCondition(displayCondition, pathToQuestion, event);
};
// specific to RRX

export default class extends Component {
  static propTypes = {
    hideQuestion: PropTypes.bool,
    pathToQuestion: PropTypes.string,
    event: PropTypes.object,
    updateEvent: PropTypes.func,
    patient: PropTypes.object,
  };

  constructor() {
    super();
    this.debouncedOnChange = _.debounce(this.handleUpdate, DEBOUNCE_TIME);
    this.state = {
      fileList: [],
    };
  }

  setFileList = ({ key, val, fileIndex }) => {
    const cloneFileList = _.clone(this.state.fileList);
    cloneFileList[fileIndex] = cloneFileList[fileIndex] || {};
    cloneFileList[fileIndex][key] = val;
    this.setState({ fileList: cloneFileList });
  };

  handleUpdate({ answer, fileIndex, fileResponse }) {
    const cloneEvent = _.cloneDeep(this.props.event);
    const cloneQuestion = _.get(cloneEvent, this.props.pathToQuestion);
    switch (cloneQuestion.type) {
      // both select and multiselect can handle undefined answerList. uses lodash to safely do so
      case SELECT:
        if (_.includes(cloneQuestion.answerList, answer)) {
          cloneQuestion.answerList = [];
        } else {
          cloneQuestion.answerList = [answer];
        }
        break;
      case MULTI_SELECT:
        cloneQuestion.answerList = toggleItemInArray(
          cloneQuestion.answerList,
          answer
        );
        break;
      case DATE_PICKER:
        cloneQuestion.answer = answer.toISOString();
        break;
      case STL:
      case PHOTO:
        if (answer) {
          cloneQuestion.answer = answer;
        }
        if (fileIndex >= 0) {
          cloneQuestion.answer[fileIndex] = fileResponse;
        }
        break;
      default:
        // TEXT
        cloneQuestion.answer = answer;
    }
    this.props.updateEvent(cloneEvent);
  }
  handleThrottledOnChange = event => {
    const answer = event.target.value;
    this.debouncedOnChange({ answer });
  };

  uploadFile = (file, fileIndex) => {
    // upload file code only runs on client side
    const authToken = cookie.parse(document.cookie).auth_token;

    superagent
      .post(getFileUploadUrl(this.props.patient.id))
      .set("Authorization", authToken)
      .field("user_id", this.props.patient.id)
      .field("uploader_id", "123456asdf") // TODO
      .field("file_type", CLINICAL_PHOTO)
      .field("details", {})
      .attach("file", file)
      .on("progress", ({ percent }) => {
        if (percent > 0) {
          this.setFileList({ key: "percent", val: percent, fileIndex });
        }
      })
      .end((err, res) => {
        if (err) {
        } else {
          this.handleUpdate({ fileResponse: res.body, fileIndex });
        }
      });
  };
  previewFile = (file, fileIndex) => {
    const self = this;
    const reader = new FileReader();
    reader.onload = e => {
      const previewUrl = e.target.result;
      self.setFileList({ key: "previewUrl", val: previewUrl, fileIndex });
    };
    reader.readAsDataURL(file);
  };

  handleFileListChange = event => {
    const questionType = _.get(
      this.props.event,
      `${this.props.pathToQuestion}.type`
    );
    const self = this;
    const fileList = event.target.files;

    self.handleUpdate({ answer: [] });
    self.setState({
      fileList: _.map(fileList, ({ name }) => {
        return { name };
      }),
    });

    _.each(fileList, (file, fileIndex) => {
      if (questionType === PHOTO) {
        this.previewFile(file, fileIndex);
      }
      this.uploadFile(file, fileIndex);
    });
  };

  handleDateChange = (event, date) => {
    this.handleUpdate({ answer: date });
  };

  renderFileList = question => {
    // this is for when uploading
    if (this.state.fileList.length > 0) {
      return _.map(this.state.fileList, (file, i) => {
        if (!file) {
          return <div />;
        }
        return (
          <div key={i} style={styles.uploadContainer}>
            {file.previewUrl ? (
              <GalleryPhotos
                thumbnailStyle={styles.imgThumbnail}
                images={[{ src: file.previewUrl, thumbnail: file.previewUrl }]}
              />
            ) : (
              <IconAttachment />
            )}
            <span style={styles.fileName}>{file.name}</span>
            <div
              style={{
                width: file.percent ? `${file.percent}%` : 0,
                height: 4,
                backgroundColor:
                  file.percent === 100
                    ? styles.V.colors.green
                    : styles.V.colors.secondary,
              }}
            />
          </div>
        );
      });
    }
    // This is for when displaying
    return _.map(question.answer, (fileObj, i) => {
      if (!fileObj) {
        return <div />;
      }
      const url = _.get(fileObj, "file.url");
      return (
        <div key={i} style={styles.uploadContainer}>
          {fileObj.file_type === CLINICAL_PHOTO && isImage(url) ? (
            <GalleryPhotos
              thumbnailStyle={styles.imgThumbnail}
              images={[{ src: url, thumbnail: getThumbnailUrl(url) }]}
            />
          ) : (
            <IconAttachment style={{ width: 100 }} />
          )}
          <div style={styles.fileName}>
            <div>
              <a href={url} style={styles.download}>
                {url && url.split("/").pop()}
              </a>
            </div>
            <div>
              Uploaded At:{" "}
              {moment(_.get(fileObj, "created_at")).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  renderSelectButtons = () => {
    const { event, disabled } = this.props;
    const question = _.get(event, this.props.pathToQuestion);
    const questionDisplayName = question.displayName;
    const disabledStyle = { display: "none" };

    return _.map(question.possibleAnswerList, (button, index) => {
      let buttonText = button;
      if (buttonText === LINE_BREAK) return <hr key={index} />;
      if (typeof button === "object") {
        // conditional display of answer
        if (
          shouldHide(button.displayCondition, this.props.pathToQuestion, event)
        )
          return null;
        buttonText = button.displayName;
      }
      const isActive = _.includes(question.answerList, buttonText);
      let buttonColor =
        styles.buttonBg[questionDisplayName] || styles.whiteButtonBg;
      let buttonStyle = styles.answerButton;
      if (isActive) {
        buttonColor = styles.V.colors.buttonPressedGreyOrange;
      } else if (disabled) {
        buttonStyle = disabledStyle;
      }
      return (
        <FlatButton
          value={buttonText}
          className="test-question-select-button"
          disabled={disabled}
          key={buttonText}
          label={buttonText}
          labelStyle={styles.answerButtonLabel}
          rippleColor={styles.V.colors.orange}
          hoverColor={buttonColor}
          style={buttonStyle}
          backgroundColor={buttonColor}
          onClick={this.handleUpdate.bind(this, { answer: buttonText })}
        />
      );
    });
  };
  render() {
    const { event, disabled } = this.props;
    const question = _.get(event, this.props.pathToQuestion);
    const questionDisplayName = question.displayName;
    const disabledStyle = { display: "none" };

    if (shouldHide(question.displayCondition, this.props.pathToQuestion, event))
      return null;

    switch (question.type) {
      case ALERT:
        return <div style={styles.alert}>{question.displayName}</div>;
      case TEXT:
        return (
          <div>
            <div>{questionDisplayName}</div>
            <TextField
              className="test-question-text-field"
              disabled={disabled}
              inputStyle={{ fontWeight: styles.V.fontWeightLight }}
              id={`ID_REQUIRED_FOR_SERVER_RENDER_${questionDisplayName.replace(
                /\s/g,
                "_"
              )}`}
              defaultValue={question.answer}
              onChange={this.handleThrottledOnChange}
              fullWidth
              multiLine
              rowsMax={6}
            />
          </div>
        );
      case STL:
      case PHOTO:
        return (
          <div>
            <div>
              {this.props.hideQuestionDisplayName ? "" : questionDisplayName}
            </div>
            <RaisedButton
              disabled={disabled}
              style={disabled ? disabledStyle : styles.photoButton}
              containerElement="label"
              label="Upload Files"
            >
              <input
                disabled={disabled}
                type="file"
                multiple
                style={{ display: "none" }}
                onChange={this.handleFileListChange}
              />
            </RaisedButton>
            {this.renderFileList(question)}
          </div>
        );
      case DATE_PICKER:
        return (
          <div>
            <div>
              {this.props.hideQuestionDisplayName ? "" : questionDisplayName}
            </div>
            <DatePicker
              disabled={disabled}
              value={question.answer ? new Date(question.answer) : null}
              hintText="Select Date"
              onChange={this.handleDateChange}
            />
          </div>
        );
      case MULTI_SELECT:
      case SELECT:
        return (
          <div style={{ ...styles.question, ...styles[questionDisplayName] }}>
            <div>
              {this.props.hideQuestionDisplayName ? "" : questionDisplayName}
            </div>
            {this.renderSelectButtons()}
          </div>
        );
      case MODIFY_WEAR_SCHEDULE:
        return (
          <div style={styles.modifyWearSchedule}>
            <ModifyWearScheduleButton
              disabled={disabled}
              patient={this.props.patient}
            />
          </div>
        );
      case SOCIAL_NOTE:
        return (
          <div>
            <div>{questionDisplayName}</div>
            <SocialNote disabled={disabled} patient={this.props.patient} />
          </div>
        );
      default:
        return null;
    }
  }
}
