import { useMutation } from "src/utils/http/gqlQuery";

type AddHoldInput = {
  userID: string;
  category: string;
  description?: string;
  dueAt?: string;
};

type Hold = {
  id: string;
  category: string;
  description?: string;
  createdAt: string;
};

export const useNewHold = () => {
  const [, execute] = useMutation<Hold, { input: AddHoldInput }>(mutation);
  return execute;
};

const mutation = `
  mutation AddHold($input: AddHoldInput!) {
    addHold(input: $input) {
      id
      category
      description
      createdAt
    }
  }
`;
