import React, { useEffect } from "react";
import styled from "react-emotion";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchPatientKits,
  getKitsForPatient,
  getIsKitsForPatientLoading,
} from "src/redux/modules/kit";
import KitItem from "./kit-item";
import { Kit } from "src/types/api";

type ParentProps = {
  patientId: string;
};

type Props = ParentProps & {
  kits: Array<Kit>;
  isLoading: boolean;
  actions: {
    fetchPatientKits: ({ patientId: string }) => void;
  };
};

const mapState = (state, props: ParentProps) => ({
  kits: getKitsForPatient(state, props),
  isLoading: getIsKitsForPatientLoading(state),
});

const mapActions = dispatch => ({
  actions: bindActionCreators({ fetchPatientKits }, dispatch),
});

const connected = connect(
  mapState,
  mapActions
);

const Wrapper = styled("div")`
  border-top: 1px dotted #eee;
  margin: 0.5rem 0;
  padding: 0.5rem 0;

  p {
    margin: 0 0 0.25rem;
  }
`;

const Kits = (props: Props) => {
  // Fetch the patient kits.
  const { patientId, actions } = props;
  useEffect(() => {
    actions.fetchPatientKits({ patientId });
  }, [actions, patientId]);

  const { isLoading, kits } = props;

  if (isLoading) {
    return (
      <div>
        <p>Loading kits. Hold on :)</p>
      </div>
    );
  }

  return (
    <Wrapper>
      {kits.length === 0 && <p>No kits yet</p>}
      {kits.map((k: Kit) => (
        <KitItem kit={k} key={k.id} />
      ))}
    </Wrapper>
  );
};

export default connected(Kits);
