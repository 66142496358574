import React from "react";
import Loading from "src/shared/Loading";
import Error from "src/shared/Error";
import { Task } from "src/scenes/Tasks/types";
import { useGetExternalSpecialists } from "src/scenes/Patient/ExternalCare/query";
import { Wrapper } from "./styles";

type Props = {
  task: Task;
};

const ExternalCare: React.FC<Props> = ({ task }) => {
  const [loading, error, specialists] = useGetExternalSpecialists({
    userID: task.user.id,
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  return (
    <Wrapper>
      {specialists.length === 0
        ? task.description
        : specialists.map(specialist => (
            <div key={specialist.id}>
              <div>
                <b>
                  {specialist.verifiedSpecialist.firstName}{" "}
                  {specialist.verifiedSpecialist.lastName}{" "}
                </b>
              </div>
              <div>{specialist.verifiedSpecialist.clinicName}</div>
              <div>
                {specialist.verifiedSpecialist.address.line1}
                {specialist.verifiedSpecialist.address.line2} <br />
                {specialist.verifiedSpecialist.address.city},{" "}
                {specialist.verifiedSpecialist.address.state}{" "}
                {specialist.verifiedSpecialist.address.postalCode}
              </div>
            </div>
          ))}
    </Wrapper>
  );
};

export default ExternalCare;
