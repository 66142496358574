import React, { useState } from "react";
import moment, { Moment as MomentType } from "moment";
import styled from "react-emotion";
import { isNil, omit, omitBy, isEmpty, get } from "lodash";
import { DatePicker, Switch, Input, Select, notification } from "antd";

import { useQuery, useMutation } from "src/utils/http/gqlQuery";
import color from "src/styles/color";
import Button from "src/shared/Button";
import Modal from "src/shared/Modal";
import Box from "src/shared/Box";
import Loading from "src/shared/Loading";
import { InsurancePolicy, Patient } from "src/types/gql";
import { policiesQuery, useAddInsuranceProvider } from "./query";

interface Props {
  patient: Patient;
  onClose: () => void;

  // The current insurance policy, if we're editing.
  current?: InsurancePolicy;
}

type FormValues = {
  id?: string;
  userId: string;
  insuranceProviderId: string | undefined;
  policyId: string | undefined;
  subscriberFirstName: string | undefined;
  subscriberLastName: string | undefined;
  subscriberMemberId: string | undefined;
  subscriberGroupNumber: string | undefined;
  subscriberDOB: string | undefined;
  dependentFirstName: string | undefined;
  dependentLastName: string | undefined;
  dependentMemberId: string | undefined;
  dependentDOB: string | undefined;
};

type FormUI = FormValues & {
  filter: string;
};

const providers = `
query SelectProviders {
  insuranceProviders {
    id,
    name,
    externalPayerID,
    gateway
  }
}
`;

const updatePolicy = `
mutation UpdateInsurancePolicy($id: ID!, $input: EditInsurancePolicy!, $deleteFields: [String]) {
  updateInsurancePolicy(id: $id, input: $input) @deleteFields(fields: $deleteFields) {
    id,
    state,
    policyId,
    policyName,
    policyType,
    subscriberMemberId,
    subscriberFirstName,
    subscriberLastName,
    subscriberGroupNumber,
    subscriberDOB,
    dependentMemberId,
    dependentFirstName,
    dependentLastName,
    dependentDOB,
    InsuranceProvider {
      name
    },
  }
}
`;

const createPolicy = `
mutation CreateInsurancePolicy($input: NewInsurancePolicy!) {
  createInsurancePolicy(input: $input) {
    id,
    state,
    policyId,
    policyName,
    policyType,
    subscriberMemberId,
    subscriberFirstName,
    subscriberLastName,
    subscriberGroupNumber,
    subscriberDOB,
    dependentMemberId,
    dependentFirstName,
    dependentLastName,
    dependentDOB,
    InsuranceProvider {
      name
    },
  }
}
`;

const requiredFields = [
  "insuranceProviderId",
  "subscriberFirstName",
  "subscriberLastName",
  "subscriberMemberId",
  "subscriberDOB",
];

const jsonToGolangField = {
  policyId: "PolicyID",
  subscriberGroupNumber: "SubscriberGroupNumber",
  dependentFirstName: "DependentFirstName",
  dependentLastName: "DependentLastName",
  dependentMemberId: "DependentMemberId",
  dependentDOB: "DependentDOB",
};

export const isPublicProvider = provider =>
  provider.gateway === "manual" || provider.gateway === "onederful";

const PolicyForm = ({ patient, onClose, current }: Props) => {
  const [{ fetching, data }] = useQuery({
    query: providers,
  });

  const [, refetch] = useQuery({
    query: policiesQuery,
    variables: { userId: patient.id },
    pause: true,
  });

  const hasDependent = Boolean(current && current.dependentDOB);
  const [showDependent, setShowDependent] = useState(hasDependent);
  const resetDependent = () => {
    setForm({
      ...form,
      dependentFirstName: undefined,
      dependentLastName: undefined,
      dependentMemberId: undefined,
      dependentDOB: undefined,
    });
  };

  const onToggle = hideDependent => {
    if (hideDependent) {
      resetDependent();
    }

    setShowDependent(!hideDependent);
  };

  // Are we creating or updating the policy?
  const isUpdate = current && current.id !== undefined;

  const [newInsuranceName, setNewInsuranceName] = useState("");
  const [form, setForm] = useState<FormUI>({
    filter: "",
    userId: patient.id,
    insuranceProviderId: undefined,
    policyId: undefined,
    subscriberFirstName: patient.firstName,
    subscriberLastName: patient.lastName,
    subscriberMemberId: undefined,
    subscriberDOB: patient.dateOfBirth,
    subscriberGroupNumber: undefined,
    dependentFirstName: undefined,
    dependentLastName: undefined,
    dependentMemberId: undefined,
    dependentDOB: undefined,
    // @ts-ignore some incompatibility issues here with null and undefined
    ...(current as FormUI),
  });

  const [, update] = useMutation(updatePolicy);
  const [, create] = useMutation(createPolicy);
  const createInsurancePolicy = useAddInsuranceProvider();

  const submit = async evt => {
    evt.preventDefault();

    const { filter, ...input } = form;
    let newProviderID;
    if (form.insuranceProviderId === "other") {
      const createRes = await createInsurancePolicy({
        providerName: newInsuranceName,
      });

      newProviderID = get(createRes, "data.addInsuranceProvider.id");
      if (createRes.error || isNil(newProviderID)) {
        notification.error({
          message: "Could not create new insurance policy.",
          // @ts-ignore null check above
          description: createRes.error.toString(),
        });
        return;
      }
    }

    let resp;
    if (isUpdate && current) {
      const formattedInput = omit(input, [
        "id",
        "userId",
        "InsuranceProvider",
        "Eligibilities",
        "__typename",
      ]);

      const deleteFields = Object.keys(formattedInput)
        .filter(
          fieldName =>
            isEmpty(formattedInput[fieldName]) && !isEmpty(current[fieldName])
        )
        .map(jsonField => jsonToGolangField[jsonField]);

      const changedInputs = omitBy(
        formattedInput,
        (value, fieldName) => isEmpty(value) || value === current[fieldName]
      );

      resp = await update({
        id: current.id,
        input: changedInputs,
        deleteFields,
      });
    } else {
      resp = await create({
        input: {
          ...input,
          insuranceProviderId:
            form.insuranceProviderId === "other"
              ? newProviderID
              : form.insuranceProviderId,
        },
      });
    }

    if (resp.error) {
      notification.error({
        message: "Unable to save",
        description: resp.error.toString(),
      });
      return;
    }

    notification.success({
      message: isUpdate ? "Policy updated" : "Policy created",
    });

    refetch();
    onClose();
  };

  if (fetching || !data) {
    return <Loading />;
  }

  const isInputValid = () => {
    if (form.insuranceProviderId === "other" && !Boolean(newInsuranceName))
      return false;

    if (showDependent) {
      if (
        !(
          form.dependentDOB &&
          form.dependentFirstName &&
          form.dependentLastName
        )
      ) {
        return false;
      }
    }

    return requiredFields.every(field => Boolean(form[field]));
  };

  let source = data.insuranceProviders
    .filter(
      ds => ds.name.toUpperCase().indexOf(form.filter.toUpperCase()) !== -1
    )
    .map(p => ({
      value: p.id,
      text: p.name,
    }));

  // add option to self-input
  source = [...source, { value: "other", text: "Other" }];
  // show old insurance provider as read-only
  if (
    current &&
    current.InsuranceProvider &&
    !isPublicProvider(current.InsuranceProvider)
  ) {
    source = [
      ...source,
      {
        value: current.InsuranceProvider.id,
        text: `[OLD] ${current.InsuranceProvider.name}`,
      },
    ];
  }

  const handleChangeFor = (field: string) => evt => {
    setForm({ ...form, [field]: evt.target.value });
  };

  return (
    <Modal onClose={onClose} ignoreOutOfBoundariesClick>
      <Box title={isUpdate ? "Update policy" : "New policy"}>
        <Container>
          <Form>
            <div style={{ marginBottom: "0.75rem" }}>
              <Label>Patient is the primary subscriber</Label>
              <Switch
                size="small"
                style={{ marginLeft: "1rem" }}
                checked={!showDependent}
                onChange={onToggle}
              />
            </div>
            <div style={{ marginBottom: "0.75rem" }}>
              <Label>
                NOTE: Submitting{" "}
                {isUpdate ? "an updated policy" : "a new policy"} will trigger a
                message in #insurance in Slack
              </Label>
            </div>
            <Grid>
              <div>
                <Label>Insurance company</Label>
                <Select
                  showSearch
                  defaultValue={form.insuranceProviderId}
                  onChange={val => {
                    setForm({ ...form, insuranceProviderId: val.toString() });
                  }}
                  filterOption={(input, option) => {
                    return (
                      option &&
                      // @ts-ignore
                      option.props.children
                        // @ts-ignore is definitely string here
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {source.map(provider => (
                    <Select.Option key={provider.value} value={provider.value}>
                      {provider.text}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              {form.insuranceProviderId === "other" ? (
                <div>
                  <Label>Insurance Provider Name</Label>
                  <Input
                    placeholder="e.g. Blue Cross Blue Shield of California "
                    value={newInsuranceName}
                    onChange={e => setNewInsuranceName(e.target.value)}
                  />
                </div>
              ) : (
                <div />
              )}
            </Grid>

            <Grid>
              <div>
                <Label>Subscriber first name</Label>
                <Input
                  placeholder="First name"
                  value={form.subscriberFirstName}
                  onChange={handleChangeFor("subscriberFirstName")}
                />
              </div>
              <div>
                <Label>Subscriber last name</Label>
                <Input
                  placeholder="Last name"
                  value={form.subscriberLastName}
                  onChange={handleChangeFor("subscriberLastName")}
                />
              </div>
              <div>
                <Label>Subscriber Member ID</Label>
                <Input
                  placeholder="Member ID (don't add dashes to SSNs: 5556667890)"
                  value={form.subscriberMemberId}
                  onChange={handleChangeFor("subscriberMemberId")}
                />
              </div>
              <div>
                <Label>Subscriber Group Number (Optional)</Label>
                <Input
                  placeholder="Group number"
                  value={form.subscriberGroupNumber}
                  onChange={handleChangeFor("subscriberGroupNumber")}
                />
              </div>
              <div>
                <Label>Subscriber date of birth</Label>
                <DatePicker
                  placeholder="Date of birth"
                  value={
                    form.subscriberDOB ? moment(form.subscriberDOB) : undefined
                  }
                  onChange={(date: MomentType | null) => {
                    date &&
                      setForm({
                        ...form,
                        subscriberDOB: date.format("YYYY-MM-DD"),
                      });
                  }}
                />
              </div>
              <div>
                <Label>Policy ID (Optional)</Label>
                <Input
                  placeholder="Policy ID"
                  onChange={handleChangeFor("policyId")}
                />
              </div>
            </Grid>

            {showDependent && (
              <Grid style={{ marginTop: "1rem" }}>
                <div>
                  <Label>Dependent first name</Label>
                  <Input
                    placeholder="First name"
                    value={form.dependentFirstName}
                    onChange={handleChangeFor("dependentFirstName")}
                  />
                </div>
                <div>
                  <Label>Dependent last name</Label>
                  <Input
                    placeholder="Last name"
                    value={form.dependentLastName}
                    onChange={handleChangeFor("dependentLastName")}
                  />
                </div>
                <div>
                  <Label>Dependent Member ID</Label>
                  <Input
                    placeholder="Member ID (don't add dashes to SSNs: 5556667890)"
                    value={form.dependentMemberId}
                    onChange={handleChangeFor("dependentMemberId")}
                  />
                </div>
                <div>
                  <Label>Dependent date of birth</Label>
                  <DatePicker
                    placeholder="Date of birth"
                    value={
                      form.dependentDOB ? moment(form.dependentDOB) : undefined
                    }
                    onChange={date => {
                      date &&
                        setForm({
                          ...form,
                          dependentDOB: date.format("YYYY-MM-DD"),
                        });
                    }}
                  />
                </div>
              </Grid>
            )}
          </Form>
          <ButtonRow>
            <Button type="default" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={submit} type="primary" disabled={!isInputValid()}>
              Save
            </Button>
          </ButtonRow>
        </Container>
      </Box>
    </Modal>
  );
};

export default PolicyForm;

const Container = styled.div`
  height: 450px;
  width: 650px;

  display: flex;
  flex-flow: column;
`;

const Form = styled("form")`
  padding: 20px 30px;
  overflow-y: scroll;

  flex: 1;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;

  padding: 10px 30px;
  border-top: 1px solid ${color.border};
`;

const Grid = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 1rem;
`;

const Label = styled("label")`
  color: #728389;
  line-height: 2;

  & + span {
    /* antd... */
    display: block !important;
  }
`;
