import React from "react";

export default () => (
  <svg
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 3.72727L4.52941 7L11 1"
      stroke="#333A3A"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
