import { QUESTION_FORM } from "../event-type";

const OPS_TEMPLATE = {
  postTimestamp: null,
  postUserName: null,
  postUserUUID: null,
  complete: false,
  formName: "ops", // this must match the name of the form in config/treatment-forms/index
  type: QUESTION_FORM,
  questionList: [
    {
      displayName: "What was fabricated?",
      type: "select",
      possibleAnswerList: [
        "Top and Bottom Aligners",
        "Top and Bottom Retainers",
        "Top Aligners Only",
        "Bottom Aligners Only",
        "Top Retainers Only",
        "Bottom Retainers Only",
      ],
    },
    {
      displayName: "Stages Shipped",
      type: "text",
    },
    {
      displayName: "Tracking Info",
      type: "text",
    },
    {
      displayName: "Please Note Additional Findings.",
      type: "text",
    },
  ],
};

export default OPS_TEMPLATE;
