import values from "lodash/values";
import { createSelector } from "reselect";
import { createAction } from "kea";
import { Task } from "src/types/api";
import { Action } from "redux";
import { request } from "src/shared/util";

const CREATE_KIT_PRE = "@@kit/create-pre";
const CREATE_KIT_SUCCESS = "@@kit/create-success";
const CREATE_KIT_FAIL = "@@kit/create-fail";
const FETCH_KIT_PRE = "@@kit/fetch-pre";
const FETCH_KIT_SUCCESS = "@@kit/fetch-success";
const FETCH_KIT_FAIL = "@@kit/fetch-fail";
const FETCH_PATIENT_KITS_PRE = "@@kit/fetch-patient-kits-pre";
const FETCH_PATIENT_KITS_SUCCESS = "@@kit/fetch-patient-kits-success";
const FETCH_PATIENT_KITS_FAIL = "@@kit/fetch-patient-kits-fail";
const SHIPPING_RATES_PRE = "@@kit/shipping-pre";
const SHIPPING_RATES_SUCCESS = "@@kit/shipping-success";
const SHIPPING_RATES_FAIL = "@@kit/shipping-fail";
const PURCHASE_SHIPPING_PRE = "@@kit/purchase-shipping-pre";
const PURCHASE_SHIPPING_SUCCESS = "@@kit/purchase-shipping-success";
const PURCHASE_SHIPPING_FAIL = "@@kit/purchase-shipping-fail";

const initialState = {
  // Object containing kit IDs to kit objects.
  kit: {},

  create: {
    lastCreatedId: null,
    isLoading: false,
    isError: false,
  },

  fetch: {
    isLoading: false,
    isError: false,
    error: null,
  },

  // stores data regarding the fetchPatientKits API call
  fetchPatientKits: {
    isLoading: false,
    isError: false,
    error: null,
  },

  shippingRates: {
    data: [],
    isLoading: false,
    isError: false,
    error: null,
  },

  // purchaseShippingRate is used when purchasing a shipping rate. This API
  // endpoint returns a newly updated Kit with embedded shipment information,
  // therefore all data from this operation is stored in state.kit above.
  purchaseShippingRate: {
    isLoading: false,
    isError: false,
    error: null, // null or a string representing the error.
  },
};

const modifiers = {
  [CREATE_KIT_PRE]: state => {
    return {
      ...state,
      create: {
        ...state.create,
        isLoading: true,
        isError: false,
      },
    };
  },
  [CREATE_KIT_SUCCESS]: (state, action) => {
    return {
      ...state,
      // Add the kit to the kit object
      kit: {
        ...state.kit,
        [action.result.id]: action.result,
      },
      // Update last created ID and set loading/error statuses to false
      create: {
        lastCreatedId: action.result.id,
        isLoading: false,
        isError: false,
      },
    };
  },
  [CREATE_KIT_FAIL]: state => {
    return {
      ...state,
      create: {
        ...state.create,
        isLoading: false,
        isError: true,
      },
    };
  },

  [FETCH_KIT_PRE]: state => {
    return {
      ...state,
      fetch: {
        isLoading: true,
        isError: false,
        error: null,
      },
    };
  },
  [FETCH_KIT_SUCCESS]: (state, action) => {
    return {
      ...state,
      kit: {
        ...state.kit,
        [action.result.id]: action.result,
      },
      fetch: {
        isLoading: false,
        isError: false,
        error: null,
      },
    };
  },
  [FETCH_KIT_FAIL]: (state, action) => {
    return {
      ...state,
      fetch: {
        isLoading: false,
        isError: true,
        error: action.error,
      },
    };
  },

  [FETCH_PATIENT_KITS_PRE]: state => ({
    ...state,
    fetchPatientKits: {
      isLoading: true,
      isError: false,
      error: null,
    },
  }),
  [FETCH_PATIENT_KITS_SUCCESS]: (state, action) => ({
    ...state,
    kit: {
      ...state.kit,
      ...action.result.reduce((obj, kit) => {
        // create an object containing new kit IDS to kits
        // and merge this into the previous state
        obj[kit.id] = kit; // eslint-disable-line no-param-reassign
        return obj;
      }, {}),
    },
    fetchPatientKits: {
      isLoading: false,
      isError: false,
      error: null,
    },
  }),

  [SHIPPING_RATES_PRE]: state => {
    return {
      ...state,
      shippingRates: {
        ...state.shippingRates,
        isLoading: true,
      },
    };
  },
  [SHIPPING_RATES_SUCCESS]: (state, action) => {
    return {
      ...state,
      shippingRates: {
        ...state.shippingRates,
        isLoading: false,
        isError: false,
        error: null,
        data: action.result,
      },
      purchaseShippingRate: {
        ...state.purchaseShippingRate,
        isLoading: false,
        isError: false,
        error: null,
      },
    };
  },
  [SHIPPING_RATES_FAIL]: (state, action) => {
    return {
      ...state,
      shippingRates: {
        ...state.shippingRates,
        isError: true,
        isLoading: false,
        error: action.error.error,
      },
    };
  },

  [PURCHASE_SHIPPING_PRE]: state => {
    return {
      ...state,
      purchaseShippingRate: {
        ...state.purchaseShippingRate,
        isLoading: true,
      },
    };
  },
  [PURCHASE_SHIPPING_SUCCESS]: (state, action) => {
    return state;
  },
  [PURCHASE_SHIPPING_FAIL]: (state, action) => {
    return {
      ...state,
      purchaseShippingRate: {
        ...state.purchaseShippingRate,
        isLoading: false,
        isError: true,
        error: action.error.error,
      },
    };
  },
};

export default function reducer(state = initialState, action = {} as Action) {
  if (typeof modifiers[action.type] === "function") {
    return modifiers[action.type](state, action);
  }
  return state;
}

export const completeCreateKitTask = createAction(
  "Called when a kit has been created via a task;  the API marks the task as closed.",
  (taskId, kitId) => ({ taskId, kitId })
);

/**
 * createKit creates a new kit for the patientId with the given stages.  Stages
 * must be an array of integers representing the stage numbers to include.
 * The contents of the kit can also be specified by sending an array of strings
 * as the `includes` parameter, eg. `['Gum (x2)']` will show that the kit
 * includes 2 packs of gum.
 *
 * NOTE: These strings in `includes` will be shown **verbatim** to the user!
 */
export const createKit = ({
  patientId,
  stages,
  includes,
  task = {} as Task,
}) => dispatch => {
  const promise = request(`/api/v1/patients/${patientId}/kits/`, {
    method: "POST",
    body: JSON.stringify({
      stages,
      includes,
      task_id: task.id,
    }),
  }).then(result => {
    if (task.id && result.id) {
      dispatch(completeCreateKitTask(task.id, result.id));
    }
    return result;
  });
  dispatch({
    type: CREATE_KIT_PRE,
  });
  return promise;
};

export const fetchKit = ({ patientId, kitId }) => ({
  types: [FETCH_KIT_PRE, FETCH_KIT_SUCCESS, FETCH_KIT_FAIL],
  promise: client => client.get(`/api/v1/patients/${patientId}/kits/${kitId}`),
});

export const fetchPatientKits = ({ patientId }) => ({
  types: [
    FETCH_PATIENT_KITS_PRE,
    FETCH_PATIENT_KITS_SUCCESS,
    FETCH_PATIENT_KITS_FAIL,
  ],
  promise: client => client.get(`/api/v1/patients/${patientId}/kits`),
});

export const loadKitShippingRates = ({ patientId, kitId, to }) => ({
  types: [SHIPPING_RATES_PRE, SHIPPING_RATES_SUCCESS, SHIPPING_RATES_FAIL],
  promise: client =>
    client.post(`/api/v1/patients/${patientId}/kits/${kitId}/get_rates`, {
      data: {
        to,
      },
    }),
});

export const purchaseShipping = createAction(
  "Called when shipping has been purchased for a kit",
  (taskId, kitId, shipment) => ({ taskId, kitId, shipment })
);

/**
 * This purchases the specified shipping rate for the patient's kit, updating
 * the purchase shipping subtask as complete.
 *
 * TODO: task refactor (api#270)
 */
export const purchaseShippingRate = ({
  patientId,
  kitId,
  rateId,
  shipmentId,
  provider,
  task = {} as Task,
}) => dispatch => {
  const promise = client =>
    client
      .post(`/api/v1/patients/${patientId}/kits/${kitId}/purchase_rate`, {
        data: {
          rate_id: rateId,
          shipment_id: shipmentId,
          provider,
          task_id: task.id, // this tells the API to close the "purchase shipping" subtask
        },
      })
      .then(response => {
        if (task.id && response.kit && response.shipment) {
          dispatch(
            purchaseShipping(task.id, response.kit.id, response.shipment)
          );
        }
        return response;
      });

  return dispatch({
    types: [
      PURCHASE_SHIPPING_PRE,
      PURCHASE_SHIPPING_SUCCESS,
      PURCHASE_SHIPPING_FAIL,
    ],
    promise,
  });
};

/**
 * Selectors
 */

export const getIsCreating = state => state.kit.create.isLoading;
export const getLastCreatedId = state => state.kit.create.lastCreatedId;

export const getShippingRateError = state => state.kit.shippingRates.error;
export const getShippingIsLoading = state => state.kit.shippingRates.isLoading;
export const getShippingRates = state => {
  return (state.kit.shippingRates.data || [])
    .slice()
    .sort((a, b) => a.cost - b.cost);
};

export const getPaidShippingRates = createSelector(
  [getShippingRates],
  rates => rates.filter(r => r.carrier !== "Custom")
);

export const getCheapestShippingRate = createSelector(
  [getPaidShippingRates],
  rates => rates.slice().shift() || {}
);
export const getFastestShippingRate = createSelector(
  [getPaidShippingRates],
  rates => {
    return (
      rates
        .slice()
        .sort((a, b) => a.delivery_days - b.delivery_days)
        .shift() || {}
    );
  }
);

export const getIsPurchaseRateLoading = state =>
  state.kit.purchaseShippingRate.isLoading;
export const getIsPurchaseRateError = state =>
  state.kit.purchaseShippingRate.isError;
export const getPurchaseRateError = state =>
  state.kit.purchaseShippingRate.error;

export const getIsFetchLoading = state => state.kit.fetch.isLoading;
export const getKitFromId = (state, props) => state.kit.kit[props.kitId] || {};

export const getKitsForPatient = (state, props: { patientId: string }) => {
  return (
    values(state.kit.kit).filter(kit => kit.user_id === props.patientId) || []
  );
};
export const getIsKitsForPatientLoading = state =>
  state.kit.fetchPatientKits.isLoading;
