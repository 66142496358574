import React, { useState } from "react";
import { Modal, Form, DatePicker, Checkbox } from "antd";
import {
  PromotionTypeList,
  PromoTypePercentageOff,
  PromoTypeEmployee,
  PromoTypeInfluencer,
} from "src/types/gql";
import moment from "moment";
import { AddPromotion } from "./queries";
import { css } from "emotion";

import Button from "src/shared/Button";
import Input from "src/shared/InputText";
import Dropdown from "src/shared/Dropdown";

const ButtonStyle = css`
  display: inline-block;
`;

const StyledFormItem = css`
  margin-bottom: 0;
`;

interface Props {
  onAdd: Function;
  setVisible: Function;
}

const defaultAddPromoObject: AddPromotion = {
  code: "",
  start: "",
  end: "",
  amount: 0,
  type: "fixed_amount",
  stackable: false,
};

const AddPromoModal = (props: Props) => {
  const [addPromotion, setAddPromotion] = useState<AddPromotion>(
    defaultAddPromoObject
  );

  const isValid = () => {
    return (
      addPromotion.code !== defaultAddPromoObject.code &&
      addPromotion.start !== "" &&
      addPromotion.end !== "" &&
      ([PromoTypeEmployee, PromoTypeInfluencer].includes(addPromotion.type) ||
        addPromotion.amount > 0)
    );
  };

  const adjustAmount = () => {
    switch (addPromotion.type) {
      case PromoTypeEmployee:
      case PromoTypeInfluencer:
        return 100;
      case PromoTypePercentageOff: {
        return addPromotion.amount;
      }
      default: {
        return addPromotion.amount * 100;
      }
    }
  };

  const onAdd = () => {
    if (isValid()) {
      props.onAdd({
        input: { ...addPromotion, amount: adjustAmount() },
      });
      props.setVisible(false);
    }
  };

  return (
    <Modal
      title="Add promotion"
      onOk={onAdd}
      onCancel={() => props.setVisible(false)}
      visible={true}
      destroyOnClose={true}
      footer={[
        <Button
          className={ButtonStyle}
          key="back"
          onClick={() => props.setVisible(false)}
        >
          Cancel
        </Button>,
        <Button
          className={ButtonStyle}
          key="submit"
          type="primary"
          disabled={!isValid()}
          loading={false}
          onClick={onAdd}
        >
          Add
        </Button>,
      ]}
    >
      <Form>
        <Form.Item required className={StyledFormItem} label="Code">
          <Input
            value={addPromotion.code}
            onChange={e => {
              setAddPromotion({
                ...addPromotion,
                code: e.target.value.toUpperCase(),
              });
            }}
          />
        </Form.Item>
        <Form.Item required className={StyledFormItem} label="Start date">
          <DatePicker
            defaultValue={
              addPromotion.start ? moment(addPromotion.start) : undefined
            }
            allowClear={false}
            onChange={(d, ds) =>
              setAddPromotion(
                Object.assign({}, addPromotion, {
                  start: d ? d.toISOString() : undefined,
                })
              )
            }
          />
        </Form.Item>
        <Form.Item required className={StyledFormItem} label="End date">
          <DatePicker
            defaultValue={
              addPromotion.end ? moment(addPromotion.end) : undefined
            }
            allowClear={false}
            onChange={(d, ds) =>
              setAddPromotion(
                Object.assign({}, addPromotion, {
                  end: d ? d.toISOString() : undefined,
                })
              )
            }
          />
        </Form.Item>
        <Form.Item required className={StyledFormItem} label="Promotion type">
          <Dropdown
            height={"32px"}
            options={PromotionTypeList}
            selected={addPromotion.type}
            onSelect={v =>
              setAddPromotion({
                ...addPromotion,
                type: v,
              })
            }
          />
        </Form.Item>
        {![PromoTypeEmployee, PromoTypeInfluencer].includes(
          addPromotion.type
        ) && (
          <Form.Item required className={StyledFormItem} label="Amount">
            <Input
              onChange={e =>
                setAddPromotion({ ...addPromotion, amount: e.target.value })
              }
            />
          </Form.Item>
        )}
        <Form.Item className={StyledFormItem} label="Stackable">
          <Checkbox
            checked={addPromotion.stackable}
            onChange={e =>
              setAddPromotion({ ...addPromotion, stackable: e.target.checked })
            }
          >
            Stackable
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddPromoModal;
