import { useState, useRef, useEffect } from "react";
import { usePrevious } from "react-use";

/*
Usage:

const transitions = {
  state0: {
    signal1: state1,
    signal2: state2,
  },

  state1: {
    signal3: state2,
    signal4: state0,
  },

  state2: {
    signal5: state0,
  },
};

const { state, nextState } = useFSMState("state0", transitions);

const handleButtonClick = () => {
  nextState("signal1");
};

const handleSuccess = () => {
  nextState("signal2");
};

...
*/

// --------------- Type helpers ---------------

export type GetStates<
  TransitionsObject extends object
> = keyof TransitionsObject;

export type GetSignals<TransitionsObject extends object> = KeysOfUnion<
  TransitionsObject[GetStates<TransitionsObject>]
>;

// --------------- Implementation ---------------

export const useFSMState = <States extends string, Signals extends string>(
  initialState: States,
  transitions: Record<States, Partial<Record<Signals, States>>>
) => {
  const [state, setState] = useState<States>(initialState);

  const nextState = (signal: Signals) => {
    const nextState = transitions[state][signal];

    if (nextState !== undefined) {
      setState(nextState as States);
    }
  };

  return { state, nextState };
};

// --------------- Helpers ---------------

export const useEffectOnceFSMState = <States extends string>(
  targetState: States,
  currentState: States,
  effectFn: VoidFunction
) => {
  const executionState = useRef(false);
  const previousState = usePrevious(currentState);

  useEffect(() => {
    const isExecuted = executionState.current;
    const isStatesMatch = targetState === currentState;
    const isStateChanged = currentState !== previousState;

    const execute = () => {
      executionState.current = true;
      return effectFn();
    };

    if (!isExecuted && isStatesMatch) {
      return execute();
    }

    if (isStateChanged) {
      executionState.current = false;

      if (isStatesMatch) {
        return execute();
      }
    }
  }, [currentState, previousState, targetState, effectFn]);
};
