import type { CheckinReducerState, Checkin } from "../../types";
import values from "lodash/values";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";

const FETCH_CHECKINS_PRE = "@@checkin/fetch-pre";
const FETCH_CHECKINS_SUCCESS = "@@checkin/fetch-success";
const FETCH_CHECKINS_FAIL = "@@checkin/fetch-fail";

const initialState: CheckinReducerState = {
  checkins: {},

  fetchByPatient: {
    loading: false,
    error: null,
    success: false,
  },
};

const modifiers: {
  [string]: (CheckinReducerState, Object) => CheckinReducerState,
} = {
  [FETCH_CHECKINS_PRE]: state => {
    return {
      ...state,
      fetchByPatient: {
        loading: true,
        error: null,
        success: false,
      },
    };
  },
  [FETCH_CHECKINS_SUCCESS]: (state: CheckinReducerState, action: Object) => {
    const items = (action.result: Array<Checkin>);
    const map = {};
    items.forEach((checkin: Checkin) => {
      map[checkin.id] = checkin;
    });

    return {
      ...state,
      checkins: {
        ...state.checkins,
        ...map,
      },
      fetchByPatient: {
        loading: false,
        error: null,
        success: true,
      },
    };
  },
  [FETCH_CHECKINS_FAIL]: state => {
    return {
      ...state,
      fetchByPatient: {
        loading: false,
        error: "Error",
        success: false,
      },
    };
  },
};

export default function reducer(
  state: CheckinReducerState = initialState,
  action: Object
): CheckinReducerState {
  if (typeof modifiers[action.type] === "function") {
    return modifiers[action.type](state, action);
  }

  return state;
}

export const fetchCheckinsForPatient = ({
  patientId,
}: {
  patientId: string,
}) => ({
  types: [FETCH_CHECKINS_PRE, FETCH_CHECKINS_SUCCESS, FETCH_CHECKINS_FAIL],
  promise: (client: Object) =>
    client.get(`/api/v1/patients/${patientId}/checkins`),
});

export const getCheckinsForPatientSelector = (
  state: Object,
  props: { patient: Object } = {}
): Array<Checkin> => {
  const checkinState = (state.checkin: CheckinReducerState);
  const patientId = props.patient && props.patient.id;

  const checkins = values(checkinState.checkins).filter((checkin: Checkin) => {
    return checkin.user_id === patientId;
  });
  return reverse(sortBy(checkins, "created_at"));
};
