import { notification } from "antd";

const primaryTeethMapping = {
  4: "A",
  5: "B",
  6: "C",
  7: "D",
  8: "E",
  9: "F",
  10: "G",
  11: "H",
  12: "I",
  13: "J",
  20: "K",
  21: "L",
  22: "M",
  23: "N",
  24: "O",
  25: "P",
  26: "Q",
  27: "R",
  28: "S",
  29: "T",
};

const universalMapping = {
  UR8: 1,
  UR7: 2,
  UR6: 3,
  UR5: 4,
  UR4: 5,
  UR3: 6,
  UR2: 7,
  UR1: 8,

  UL1: 9,
  UL2: 10,
  UL3: 11,
  UL4: 12,
  UL5: 13,
  UL6: 14,
  UL7: 15,
  UL8: 16,

  LL8: 17,
  LL7: 18,
  LL6: 19,
  LL5: 20,
  LL4: 21,
  LL3: 22,
  LL2: 23,
  LL1: 24,

  LR1: 25,
  LR2: 26,
  LR3: 27,
  LR4: 28,
  LR5: 29,
  LR6: 30,
  LR7: 31,
  LR8: 32,
};

export const isLeft = universal => {
  // Left universal numbers are between 9 and 24.
  return universal >= 9 && universal <= 24;
};

export const isRight = universal => !isLeft(universal);

export const getUniversal = palmer => {
  return universalMapping[palmer];
};

export const getPalmer = u => {
  return Object.keys(universalMapping).find(
    palmer => universalMapping[palmer] === u
  );
};

export const getPrimary = u => {
  return primaryTeethMapping[u];
};

// interface generateToothProceduresArgs {
//   section: ToothSection;
//   number: number;
//   position: ToothPosition;
//   // TODO: Types
//   entryType: string;
//   entryStage: string;
//   entryData: Object;
//   previousProcedures: Array<ToothOperation>;
// }

// This function takes the clicked tooth section, number, position,
// plus the toolbar stage, tool, and additional data selected - and also
// the previous procedures for the tooth - to generate an array of new
// tooth procedures to add to the tooth.
//
// It is used within ToothChart during the onClick handler to add items
// to each tooth.
export const generateToothProcedures = ({
  section,
  number,
  position,
  entryType,
  entryStage,
  entryData,
  previousProcedures,
}) => {
  const entry_date = new Date().toISOString().substr(0, 10);
  const name = toothName({ section, number });

  switch (entryType) {
    // When rendering IPR we take the amount and split it in half, assigning
    // half of the value to the selected tooth and the adjascent tooth.  This covers
    // 90% of a doctors typical prescriptions;  most of the time IPR is too fine to
    // attribute to one proximal side only so we assign it to both.
    //
    // For the times when a doctor really does want to assign IPR to a single proximal
    // tooth boundary, the doctor must manually remove IPR from the side they don't want.
    //
    // Overall, this saves clicks and mimics how a doctor thinks: "remove 0.2mm between
    // LR1 LR2".
    case "ipr":
      const direction = position === "right proximal" ? "right" : "left";
      const adjascent = getAdjascentTooth(section, number, direction);

      // If there's no adjascent teeth we shouldn't render IPR.
      if (!adjascent) {
        return [];
      }

      // Split the IPR in half and add half to each adjascent tooth.
      const amount = (parseIPR(entryData["amount"]) || 20) / 200;
      return [
        {
          tooth_name: toothName({ section, number }),
          entry_type: entryType,
          entry_stage: entryStage,
          entry_data: {
            amount: `${amount}mm`,
            position: `${direction} proximal`,
          },
          entry_date,
        },
        {
          tooth_name: toothName({
            section: adjascent.section,
            number: adjascent.number,
          }),
          entry_type: entryType,
          entry_stage: entryStage,
          entry_data: {
            amount: `${amount}mm`,
            // This is the opposite promxial side to the side selected, as it's on
            // the adjascent tooth. XXX do this better lol
            position: `${direction === "right" ? "left" : "right"} proximal`,
          },
          entry_date,
        },
      ];
    case "spacing":
      if (entryData["amount"] === undefined) {
        notification.warning({
          message: "Select an amount",
          description: "Spacing won't be added until you select an amount",
        });
        return [];
      }
      entryData = Object.assign({}, entryData, {
        position: position === "right proximal" ? position : "left proximal",
      });
      break;
    case "add_attachment":
      // Ensure we use the position of the tooth clicked on, if the setting hasn't
      // been chosen.
      if (entryData["position"] === undefined) {
        entryData = Object.assign({}, entryData, { position });
      }
      // Only allow attachments on buccal or lingual
      if (["buccal", "lingual"].indexOf(entryData["position"]) < 0) {
        return [];
      }
      break;
    case "remove_attachment":
      // Ensure we use the position of the tooth clicked on, if the setting hasn't
      // been chosen.
      if (entryData["position"] === undefined) {
        entryData = Object.assign({}, entryData, { position });
      }
      // Only allow attachments on buccal or lingual
      if (["buccal", "lingual"].indexOf(entryData["position"]) < 0) {
        return [];
      }

      // Only allow this to happen if previousProcedures contains an attachment on
      // this tooth that has not yet been removed.
      //
      // TODO: Build something that flattens all of these procedures into a "current"
      // view, ie. if add_attachment on UL1 & 2 and remove_attachment on UL2, only show
      // attachment on UL1.
      //
      // This will break if the attachment has already been removed, but we're SO
      // strapped for time.
      const addAttachment = previousProcedures.find(tr => {
        return (
          tr.entry_type === "add_attachment" &&
          tr.tooth_name === name &&
          tr.entry_data &&
          tr.entry_data["position"] === entryData["position"]
        );
      });

      if (addAttachment === undefined) {
        return [];
      }

      break;
    case "add_hook":
    case "tray_cutouts":
      // Ensure we use the position of the tooth clicked on, if the setting hasn't
      // been chosen.
      if (entryData["position"] === undefined) {
        entryData = Object.assign({}, entryData, { position });
      }
      if (["buccal", "lingual"].indexOf(entryData["position"]) < 0) {
        // Only allow elastics on buccal or lingual
        return [];
      }
      break;
    default:
      if (!entryType || !entryStage) {
        return [];
      }

      return [
        {
          tooth_name: name,
          entry_type: entryType,
          entry_stage: entryStage,
          entry_data: entryData,
          entry_date,
        },
      ];
  }

  return [
    {
      tooth_name: name,
      entry_type: entryType,
      entry_stage: entryStage,
      entry_data: entryData,
      entry_date,
    },
  ];
};

export const splitToothName = string => {};

export const toothName = ({ section, number }) => `${section}${number}`;

export const palmerToothName = name => {
  const section = name
    .toString()
    .substr(0, 2)
    .toUpperCase();
  const number = name.toString().substr(2, 1);

  switch (section) {
    case "UR":
      return `${number}┘`;
    case "UL":
      return `└${number}`;
    case "LR":
      return `${number}┐`;
    case "LL":
      return `┌${number}`;
    default:
      return "";
  }
};

// parseIPR returns the IPR amount as a number in hundredths of a millimeter,
// ie 0.2mm returns 20.
export const parseIPR = amount => {
  if (!amount) {
    return null;
  }

  const match = amount.match(/(0\.\d+)/);
  if (!match) {
    return null;
  }
  return Math.round(parseFloat(match[1]) * 100);
};

// getAdjascentTooth takes a tooth and direction (left/right), then returns the adjascent
// tooth as a section/number object.
//
// This ensures that we traverse from UL <> UR successfully, and ensures we don't go out
// of bounds between teeth 0-8.
const getAdjascentTooth = (section, number, direction) => {
  const universal = getUniversal(`${section}${number}`);

  // For lower teeth, the next position is reversed.
  const modifier = universal > 16 ? -1 : 1;
  const nextNumber =
    direction === "left" ? universal - modifier : universal + modifier;

  const next = getPalmer(nextNumber);

  if (!next) {
    return null;
  }

  return {
    section: next.substr(0, 2),
    number: parseInt(next.substr(2), 10),
  };
};

export const entryStages = {
  antecedent: "Pre-Existing",
  prescribed: "Rx",
  rendered: "Performed",
  spacing: "Spacing",
};

export const visibilityToggle = Object.assign({}, entryStages);

export const entryTypes = {
  add_attachment: "Add attachment",
  add_powerarms: "Add powerarms",
  add_bracket: "Add bracket",
  bracket_cutouts: "Bracket cutout",
  attachment_loss: "Attachment loss",
  indirect_buildup: "Indirect Buildup",
  direct_buildup: "Direct Buildup",
  bridge: "Bridge",
  caries: "Caries",
  crown: "Crown",
  deciduous: "Baby tooth",
  disclusion: "Add disclusion",
  pontic: "Pontic",
  power_ridge: "Power ridge",
  tray_cutouts: "Tray cutout",
  add_hook: "Add caplin hooks",
  extraction: "Extraction",
  gum_disease: "Gum disease",
  impacted: "Impacted",
  implant: "Implant",
  ipr: "IPR",
  missing: "Missing",
  remove_attachment: "Remove attachment",
  remove_powerarms: "Remove powerarms",
  remove_bracket: "Remove bracket",
  restoration: "Restoration",
  root_canal: "Root canal",
  veneer: "Veneer",
  spacing: "Spacing",
};

// Tooth chart data for submissions is stored in JSONB.  Some attribute names have
// been renamed since inception;  this serves as a mapping of old => new.
//
// When processing a tooth chart, we can map all attributes and change name from
// old to new to enforce consistency.
export const renamed = {
  tray_cutout: "tray_cutouts",
  elastics: "add_hook",
};

export const entryTypesByStage = {
  antecedent: [
    "deciduous",
    "bridge",
    "caries",
    "crown",
    "gum_disease",
    "impacted",
    "implant",
    "missing",
  ],
  estimated: [
    "add_attachment",
    "add_powerarms",
    "disclusion",
    "add_hook",
    "extraction",
    "indirect_buildup",
    "ipr",
  ],
  prescribed: [
    "add_attachment",
    "add_powerarms",
    "add_bracket",
    "disclusion",
    "add_hook",
    "power_ridge",
    "tray_cutouts",
    "bracket_cutouts",
    "pontic",
    "extraction",
    "indirect_buildup",
    "ipr",
    "remove_attachment",
    "remove_powerarms",
    "remove_bracket",
  ],
  rendered: [
    "add_attachment",
    "add_powerarms",
    "add_bracket",
    "extraction",
    "indirect_buildup",
    "direct_buildup",
    "ipr",
    "remove_attachment",
    "remove_powerarms",
    "remove_bracket",
  ],
  spacing: ["spacing"],
};

export const typeSpecificSettings = {
  spacing: {
    amount: [
      "0.1mm",
      "0.2mm",
      "0.3mm",
      "0.4mm",
      "0.5mm",
      "0.6mm",
      "0.7mm",
      "0.8mm",
      "0.9mm",
      ">= 1mm",
    ],
  },
  ipr: {
    amount: [
      "0.1mm",
      "0.2mm",
      "0.3mm",
      "0.4mm",
      "0.5mm",
      "0.6mm",
      "0.7mm",
      "0.8mm",
      "0.9mm",
      ">= 1mm",
    ],
  },
  add_attachment: {
    position: ["Buccal", "Lingual"],
  },
  remove_attachment: {
    position: ["Buccal", "Lingual"],
  },
  add_hook: {
    //TODO: How do we place elastics on surfaces of teeth?  How do they know which
    // teeth and surfaces they'll attach to?  They can go from any surface to any
    // surface.
    number: ["1", "2", "3", "4"],
    strength: ["BH", "BL", "SH", "SL"],
  },
};

export const tooltipText = item => {
  const primaryText = entryTypes[item.entry_type];
  const additional =
    item.entry_data &&
    Object.keys(item.entry_data)
      .filter(k => k !== "position")
      .map(k => `${k}: ${item.entry_data[k]}`)
      .join(", ");

  return `${primaryText} ${additional} (${item.entry_stage})`;
};
