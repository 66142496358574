import React from "react";
import { CrownProps, RootProps } from "./types";

export const Crown: React.SFC<CrownProps> = ({ fill, stroke, style }) => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M16.106 4.06034C11.7253 -0.846611 7.35712 2.63534 5.72063 4.98968C2.32178 8.70708 1 16.1418 1 20.2309C1 23.5022 2.5106 25.5592 3.2659 26.1788C5.34298 27.9135 10.2524 31.3459 13.2736 31.1972C16.2948 31.0485 19.0643 28.657 20.0713 27.4798C22.7904 24.8033 22.8408 19.9212 22.5261 17.8146C22.2114 15.2744 20.4868 8.96728 16.106 4.06034Z"
      fill={fill || "#fff"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />
  </svg>
);

// Root always contains the portion as if it attached as a Crown.
export const Root: React.SFC<RootProps> = ({
  hasRootCanal,
  style,
  fill,
  stroke,
}) => (
  <svg
    width="18"
    height="56"
    viewBox="0 0 18 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M2.24977 12.1827C2.40105 19.3668 1.94125 39.9468 1.5 50H7V55H12V50H17C16.2436 30.5149 11.3268 12.1827 9.43578 8.28571C7.54473 4.3887 4.51903 1 2.24977 1C0.434354 1 0.989063 4.27575 1.49334 5.91362C2.09848 7.40465 2.24977 10.7143 2.24977 12.1827Z"
      fill={fill || "#F1F4F5"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />

    {hasRootCanal && (
      <g transform="translate(3,1)">
        <path
          d="M0 0C6 14.5 3.66667 37.5 3 43L8.5 44.5C7 24 4.5 7.5 0 0Z"
          fill="#C270A7"
        />
      </g>
    )}
  </svg>
);
