import React, { PureComponent } from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";

/**
 * Dialog with action buttons. The actions are passed in as an array of React objects,
 * in this example [FlatButtons](/#/components/flat-button).
 *
 * You can also close this dialog by clicking outside the dialog, or with the 'Esc' key.
 */
export default class SingleEditAlert extends PureComponent {
  props: {|
    isOpen: boolean,
    onSave: () => void,
    onReset: () => void,
    onClose: () => void,
  |};

  render() {
    const actions = [
      <FlatButton label="Reset" onClick={this.props.onReset} primary />,
      <FlatButton
        label="Save"
        onClick={this.props.onSave}
        primary
        keyboardFocused
      />,
    ];

    return (
      <Dialog
        title="Edit already pending"
        actions={actions}
        modal={false}
        open={this.props.isOpen}
        onRequestClose={this.props.onClose}
      >
        There&quot;s already an unsaved edit pending. Save or reset the edit to
        continue.
      </Dialog>
    );
  }
}
