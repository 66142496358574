import { useState, useEffect } from "react";
import useInterval from "src/shared/useInterval";
import time from "src/shared/time";
import { startHour, endHour } from "src/shared/consts";

const useCurrentTimeTop = (timezone): number | null => {
  const { diffInHours, now, startOfDay } = time(timezone);
  const getCurrentHour = () => {
    const currentHour = diffInHours(now(), startOfDay(now()));
    return currentHour;
  };

  const getCurrentHourTop = () => {
    const currentHour = getCurrentHour();

    if (currentHour > endHour) {
      return null;
    }
    if (currentHour < startHour) {
      return null;
    }
    return ((currentHour - startHour) / (endHour - startHour)) * 100;
  };

  const thirtySeconds = 30 * 1000;

  const [currentHourTop, setCurrentHourTop] = useState(getCurrentHourTop());

  useEffect(() => {
    setCurrentHourTop(getCurrentHourTop());
  }, [timezone]); // eslint-disable-line

  useInterval(() => {
    setCurrentHourTop(getCurrentHourTop());
  }, thirtySeconds);

  return currentHourTop;
};

export default useCurrentTimeTop;
