import React, { useState } from "react";
import { Row } from "./styles";
import _ from "lodash";
import { Checkbox } from "antd";

const PATIENT_PROVIDER_STATUS_MAP = {
  chosen: false,
  recommended: false,
};
let PATIENT_PROVIDER_STATUS_MAP_WITH_STATE = {};

type Props = {
  state: string;
  updateState: (string) => void;
};

const ExternalProviderStateStatus: React.FC<Props> = ({
  state,
  updateState,
}) => {
  if (state) {
    PATIENT_PROVIDER_STATUS_MAP_WITH_STATE = {
      ...PATIENT_PROVIDER_STATUS_MAP,
      [state]: true,
    };
  }
  const [patientProviderStatusMap, setPatientProviderStatusMap] = useState(
    state ? PATIENT_PROVIDER_STATUS_MAP_WITH_STATE : PATIENT_PROVIDER_STATUS_MAP
  );

  const updateStatusProvider = updatedPatientProviderStatus => {
    updateState(updatedPatientProviderStatus);

    const newPatientProviderStatusMap = patientProviderStatusMap;

    _.forEach(newPatientProviderStatusMap, (_, key) => {
      key === updatedPatientProviderStatus
        ? (newPatientProviderStatusMap[key] = true)
        : (newPatientProviderStatusMap[key] = false);
    });
    setPatientProviderStatusMap(newPatientProviderStatusMap);
  };

  return (
    <Row>
      {_.map(patientProviderStatusMap, (checkedStatus, status) => (
        <Checkbox
          key={status}
          value={status}
          checked={checkedStatus}
          onChange={e => updateStatusProvider(e.target.value)}
        >
          {_.upperFirst(status)}
        </Checkbox>
      ))}
    </Row>
  );
};

export default ExternalProviderStateStatus;
