import {
  PromoTypeEmployee,
  PromoTypePercentageOff,
  PromoTypeInfluencer,
} from "src/types/gql";
import money from "./money";

export const getPromoCentAmount = (promo, subtotal) => {
  switch (promo.type) {
    case PromoTypeEmployee:
    case PromoTypeInfluencer:
      return subtotal;
    case PromoTypePercentageOff:
      return subtotal * promo.amount;
    default:
      return promo.amount;
  }
};

export const formatPromoAmount = (amount, promoType) => {
  switch (promoType) {
    case PromoTypeEmployee:
    case PromoTypeInfluencer:
    case PromoTypePercentageOff: {
      return `${amount}%`;
    }
    default: {
      return money(amount);
    }
  }
};
