import moment from "moment";
import React, { Component } from "react";
import PropTypes from "prop-types";

import { momentFormatDate, momentFormatTime } from "src/config/constant";
import DeleteUser from "../delete-user/delete-user";
import MarkAsSpam from "../mark-as-spam/mark-as-spam";
import AddAddress from "src/components/add-address/add-address";
import Avatar from "../../../../components/avatar/avatar";
import SocialNote from "../../../../components/social-note/social-note";
import UpdatePatientInfo from "../../../../components/update-patient-info/update-patient-info";

import styled from "react-emotion";
import { Menu, Dropdown, Icon } from "antd";

const PatientSummaryWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  font-weight: 300;
  background-color: #ffffff;
  z-index: 1;
  color: #616161;
  font-size: 14px;
  position: relative;
  text-align: center;
  line-height: 1.7em;
  max-width: 1040px;
  box-sizing: border-box;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 2;
  margin: 12px;
  height: 100%;
`;

const DropdownWrapper = styled("div")`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const DropdownIcon = styled(Icon)`
  font-size: 22px;
`;

const Name = styled("div")`
  font-size: 16;
  font-weight: strong;
  color: #262d2d;
  text-transform: "capitalize";
`;

const Phone = styled("a")`
  font-weight: 700;
  color: #262d2d;
`;

const SmallText = styled("div")`
  font-size: 11px;
  color: #616161;
`;

const Warning = styled("div")`
  fontweight: 500;
  color: #ff9f81;
`;

const StyledSocialNote = styled(SocialNote)`
  text-align: center;
  font-size: 14px;
`;

export default class extends Component {
  static propTypes = {
    patient: PropTypes.object,
    estimateTreatmentType: PropTypes.array,
    estimateDuration: PropTypes.array,
  };

  state = {
    deleteUserOpen: false,
    markAsSpamOpen: false,
    addAddressOpen: false,
    taskDialogOpen: false,
    updatePatientInfoOpen: false,
  };

  deleteUserOpen = () => this.setState({ deleteUserOpen: true });
  deleteUserClose = () => this.setState({ deleteUserOpen: false });
  markAsSpamOpen = () => this.setState({ markAsSpamOpen: true });
  markAsSpamClose = () => this.setState({ markAsSpamOpen: false });
  addAddressOpen = () => this.setState({ addAddressOpen: true });
  addAddressClose = () => this.setState({ addAddressOpen: false });
  updatePatientInfoOpen = () => this.setState({ updatePatientInfoOpen: true });
  updatePatientInfoClose = () =>
    this.setState({ updatePatientInfoOpen: false });

  render() {
    const { patient, estimateTreatmentType, estimateDuration } = this.props;
    const hasEstimate = estimateDuration || estimateTreatmentType;
    const DOB = moment(patient.date_of_birth).format(momentFormatDate);
    const age = moment().diff(patient.date_of_birth, "years");
    const signUpAt = moment(patient.created_at).format(momentFormatTime);
    const pushNotificationOn = !!patient.push_token;
    // if user does not have push notification on
    // and if the only time they've authenticated and logged in is on user creation. they most likely do not have the app
    // server time logs last seen about 4 secs after the created at.
    // const userLastSeenIsNotSignup = pushNotificationOn || isGreaterThanByTwoMin(patient.last_seen, patient.created_at)
    const SummaryMenu = (
      <Menu>
        <Menu.Item onClick={this.addAddressOpen}>
          <Icon type="edit" />
          Set Address
        </Menu.Item>
        <Menu.Item onClick={this.updatePatientInfoOpen}>
          <Icon type="edit" />
          Update Patient Info
        </Menu.Item>
        <Menu.Item onClick={this.markAsSpamOpen}>
          <Icon type="exclamation-circle" />
          Mark as spam
        </Menu.Item>
        <Menu.Item onClick={this.deleteUserOpen}>
          <Icon type="delete" />
          Delete
        </Menu.Item>
      </Menu>
    );

    return (
      <PatientSummaryWrapper>
        <DropdownWrapper>
          <Dropdown
            overlay={SummaryMenu}
            trigger={["click"]}
            placement="bottomLeft"
          >
            <DropdownIcon type="more" />
          </Dropdown>
        </DropdownWrapper>
        <Avatar size={120} patient={patient} />
        <hr />
        <Name>
          {patient.first_name} {patient.last_name}
        </Name>
        {!pushNotificationOn && <Warning>No App Notifications!</Warning>}
        <hr />
        <Phone href={`tel:${patient.mobile_phone_number}`}>
          Phone: {patient.mobile_phone_number}
        </Phone>
        <hr />
        <div>Age {age}</div>
        <div>DOB: {DOB}</div>
        <div>Email: {patient.email}</div>
        {hasEstimate && (
          <div>
            <hr />
            <div>
              <strong>Estimate:</strong>
            </div>
            <div>{estimateTreatmentType}</div>
            <div>{estimateDuration}</div>
          </div>
        )}
        {patient.shipping_address && (
          <div>
            <hr />
            <div>{patient.shipping_address.line_1}</div>
            <div>{patient.shipping_address.line_2}</div>
            <div>
              {patient.shipping_address.city}, {patient.shipping_address.state}
            </div>
            <div>{patient.shipping_address.postal_code}</div>
          </div>
        )}
        <hr />
        <SmallText>
          <div>{patient.id}</div>
          <div>Created: {signUpAt}</div>
          <div>
            Last active: {moment(patient.last_seen).format(momentFormatTime)}
          </div>
        </SmallText>
        <hr />
        <StyledSocialNote />
        <MarkAsSpam
          open={this.state.markAsSpamOpen}
          handleClose={this.markAsSpamClose}
        />
        <DeleteUser
          open={this.state.deleteUserOpen}
          handleClose={this.deleteUserClose}
        />
        <AddAddress
          open={this.state.addAddressOpen}
          handleClose={this.addAddressClose}
        />
        <UpdatePatientInfo
          open={this.state.updatePatientInfoOpen}
          handleClose={this.updatePatientInfoClose}
        />
      </PatientSummaryWrapper>
    );
  }
}
