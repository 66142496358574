import React from "react";
import Pipeline from "src/scenes/Tasks/Pipelines/Pipeline";
import { DetailedPatient } from "src/types/api";

type Props = {
  params: {
    userId: string;
    pipelineID: string;
    taskID?: string;
  };
  patient: DetailedPatient;
};

const PatientPipelines: React.FC<Props> = props => {
  return (
    <Pipeline
      backURL={`/patients/${props.patient.id}/pipelines`}
      params={{
        id: props.params.pipelineID,
        taskID: props.params.taskID,
        filter: "",
      }}
      hideSidebar
    />
  );
};

export default PatientPipelines;
