import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";
import {
  loadStructuredMessages,
  getStructuredMessages,
  getIsStructuredMessagesLoading,
} from "src/redux/modules/structured-messages";
import { ListItem } from "material-ui/List";
import template from "../../helpers/templating";
import S, { V } from "./wrapper-style";

const mapState = createStructuredSelector({
  isLoading: getIsStructuredMessagesLoading,
  messages: getStructuredMessages,
});

const mapActions = dispatch => ({
  actions: bindActionCreators({ loadStructuredMessages }, dispatch),
});

/*
// TODO (tonyhb) i wanna flow all the things... Move these into a separate type
// declaration file (types.js).
type ContentPartType = {|
  body: string,
  mime_type: string,
|};

type StructuredMessageContentType = {|
  id: string,
  structured_message_id: string,
  parts: Array<ContentPartType>,
  notification_title?: string,
  notification_body?: string,
|};

type StructuredMessageType = {|
  id: string,
  name: string,
  contents: Array<StructuredMessageContentType>,
|};
*/

/**
 * StructuredMessages lists all structured messages available in the API.
 */
const modifiers = connect(
  mapState,
  mapActions
);
class StructuredMessages extends PureComponent {
  /*
  props: {
    patient: Object,
    messages: Array<StructuredMessageType>,
    onSelect: (input: string, attachments: Array<ContentPartType>) => void,
    actions: {|
      loadStructuredMessages: () => void,
    |},
  };
  */

  componentWillMount() {
    this.props.actions.loadStructuredMessages();
  }

  render() {
    const { messages, onSelect, patient } = this.props;

    return (
      <div style={S.structuredMessageWrapper}>
        <p style={{ fontSize: V.fontSmall }}>Structured Messages</p>
        {messages.map((m, n) => (
          <Message message={m} key={n} onSelect={onSelect} patient={patient} />
        ))}
      </div>
    );
  }
}

class Message extends PureComponent {
  /*
  props: {
    patient: Object,
    message: StructuredMessageType,
    onSelect: (input: string, attachments: Array<ContentPartType>) => void,
  };
  */
  getText = () => {
    const { message } = this.props;
    const { contents } = message;
    let text = "";
    // only run templating to generate a preview if the message has contents
    if (Array.isArray(contents) && contents.length > 0) {
      text = template(contents[0].body, this.props.patient);
    }
    return text;
  };

  onSelect = () => {
    this.props.onSelect(this.getText());
    /*
    ***NOTE this section here was from the layer based chat message. Leaving commented/

    const { message } = this.props;
    let input = '';
    const attachments = [];
    // TODO: What happens when a structured message needs to send multiple messages?
    // We should probably merge here.
    // A structured message which is to be sent automatically via a bot may be
    // broken up into several messages (ie. contents.length > 1).  Here, a
    // doctor is sending the structured message.  Should we merge all of the
    // contents into one message?
    // NOTE: We don't have this scenario right now and will not for a while.
    // See db/migrate/20170615164306_create_structured_messages.rb for more info.
    message.contents[0].parts.forEach(part => {
      if (part.mime_type === 'text/plain') {
        input += `\n\n${part.body}`;
        return;
      }
      attachments.push(part);
    });
    this.props.onSelect(input, attachments);
    */
  };

  render() {
    const { message } = this.props;
    return (
      <ListItem
        onClick={this.onSelect}
        innerDivStyle={{ padding: V.spacingSmall }}
      >
        <p style={S.messageTitle}>{message.name}</p>
        <p style={S.messageText}>{this.getText()}</p>
      </ListItem>
    );
  }
}

export default modifiers(StructuredMessages);
