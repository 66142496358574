import React from "react";
import { Wrapper, One, Two, Three, Four } from "./styles";

const Loading = ({ color = "#000" }) => (
  <Wrapper>
    <One color={color} />
    <Two color={color} />
    <Three color={color} />
    <Four color={color} />
  </Wrapper>
);

export default Loading;
