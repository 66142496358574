import React, { useState } from "react";
import styled from "react-emotion";
import { notification } from "antd";
import color from "src/styles/color";
import Modal from "src/shared/Modal";
import Loading from "src/shared/Loading";
import Error from "src/shared/Error";
import Box from "src/shared/Box";
import Button, { ButtonRow } from "src/shared/Button";

import { usePaymentMethods } from "../../query";
import PaymentMethodList from "../../PaymentMethodList";
import { useUpdatePaymentPlan } from "../query";
import { PaymentMethod, PaymentPlan } from "src/types/gql";

type Props = {
  patientID: string;
  onClose: () => void;
  paymentPlan: PaymentPlan | undefined;
};

const Content = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 20px;
  height: 400px;
  width: 600px;
`;

const Buttons = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
`;

const ErrorWrapper = styled.div`
  color: ${color.red};
`;

const SwitchPaymentMethodModal: React.FC<Props> = ({
  patientID,
  onClose,
  paymentPlan,
}) => {
  const [fetchingPaymentMethods, , paymentMethods] = usePaymentMethods(
    patientID
  );
  const updatePaymentPlan = useUpdatePaymentPlan();
  const [pm, setPM] = useState<PaymentMethod | undefined>(
    paymentPlan && paymentPlan.paymentMethod
  );
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    if (!paymentPlan) return;

    setIsSubmitting(true);
    const res = await updatePaymentPlan({
      input: {
        id: paymentPlan.id,
        paymentMethodID: pm && pm.id,
      },
    });

    setIsSubmitting(false);
    if (res.error) {
      setError(res.error.message);
      return;
    }

    notification.success({ message: "Payment method updated!" });
    onClose();
  };

  if (!paymentPlan || fetchingPaymentMethods) {
    return <Loading />;
  }

  return (
    <Modal onClose={onClose}>
      <Box title="Switch payment method">
        <Content>
          {paymentMethods.length === 0 ? (
            <Error text="This patient has no payment methods." />
          ) : (
            <PaymentMethodList
              patientID={patientID}
              paymentMethods={paymentMethods}
              onSelect={setPM}
              selected={pm}
            />
          )}

          <Buttons>
            <ErrorWrapper>{error}</ErrorWrapper>
            <ButtonRow position="right">
              <Button kind="default" onClick={onClose}>
                Cancel
              </Button>
              <Button
                kind="primary"
                onClick={onSubmit}
                disabled={!Boolean(pm) || isSubmitting}
              >
                {isSubmitting ? "Loading..." : "Submit"}
              </Button>
            </ButtonRow>
          </Buttons>
        </Content>
      </Box>
    </Modal>
  );
};

export default SwitchPaymentMethodModal;
