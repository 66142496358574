import React, { useContext } from "react";
import styled from "react-emotion";

import TaskRow from "./TaskRow";
import { PatientStatus } from "src/types/gql";
import { TableHeader } from "../Filters/TableHeader/TableHeader";
import { FiltersContext } from "../Filters/FiltersContext/FiltersContext";
import { Task } from "../types";

type Props = {
  tasks: Task[];
  // map from user ID to `PatientStatus` GraphQL type
  currentPatientStatusesMap: Map<string, PatientStatus> | undefined;
  // urlPrefix, routeFilter is needed for proper linking
  urlPrefix: string;
  // If this is "my" tasks, this disallows filtering by assignee
  routeFilter?: string;
  // if any tasks are blocked, these will be rendered 50% transparent
  blockedIDs?: string[];
  // if trhe, shows completed at vs due date
  showCompletedAt?: boolean;
};
const Table: React.FC<Props> = ({
  tasks,
  currentPatientStatusesMap,
  routeFilter,
  urlPrefix,
  blockedIDs,
  showCompletedAt,
}) => {
  const { appliedFilters } = useContext(FiltersContext);

  // If we have a status selected, automatically filter out any pipelines on hold.
  const filteredTasks = appliedFilters.status
    ? tasks.filter(t => !t.pipeline || !t.pipeline.onHoldCategory)
    : tasks;

  return (
    <Wrapper>
      <TableHeader
        routeFilter={routeFilter}
        currentPatientStatusesMap={currentPatientStatusesMap}
        showCompletedAt={showCompletedAt}
      />

      {filteredTasks.map(t => (
        <TaskRow
          task={t}
          currentPatientStatus={
            currentPatientStatusesMap
              ? currentPatientStatusesMap.get(t.user.id)
              : undefined
          }
          key={t.id}
          urlPrefix={urlPrefix}
          blocked={(blockedIDs || []).includes(t.id)}
          showCompletedAt={showCompletedAt}
          showCurrentPatientStatus={Boolean(currentPatientStatusesMap)}
        />
      ))}
    </Wrapper>
  );
};

export default Table;

const Wrapper = styled.div`
  border-radius: 3px;
`;
