export const NOTE = "note";
export const QUESTION_FORM = "question-form";
export const ISSUE_LIST_FORM = "issue-list-form";
export const STATUS = "status";
export const FILE = "file";
export const CHECKIN = "checkin";
export const COMMENT = "comment";
export const SYSTEM_EVENT = "system-event";

const FORM = "form";
export const EVENT_FILTERS = [COMMENT, FILE, CHECKIN, FORM, NOTE, SYSTEM_EVENT];
