import React, { useState } from "react";
import styled, { css } from "react-emotion";
import { DateTime } from "luxon";
import { Input, notification } from "antd";

import useEditContext from "./useEditContext";
import LegacyModal from "src/shared/LegacyModal";
import Button from "src/shared/Button";
import Trash from "src/shared/Icons/Trash";
import color from "src/styles/color";
import { useQuery, useMutation } from "src/utils/http/gqlQuery";
import { templateGql, upsertTemplateGql } from "../queries";
import { templateToSlot } from "../types";
import WarningModal from "./WarningModal";

type Props = {
  clinicId: string;
  date: DateTime;
  close: () => void;
  onNewTemplate: (name: string) => void;
  onEditTemplate?: (id: string) => void;
  onUseTemplate?: (id: string) => void;
};

const ListWrapper = styled.div`
  border: 1px solid ${color.border};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const selectedCss = css`
  background: ${color.gray1};
`;

const Item = styled.div`
  padding: 2px 25px;
  cursor: pointer;
  &:hover {
    ${selectedCss}
  }
`;

const TemplateRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TemplateName = styled.p`
  font-weight: bold;
  padding-top: 10px;
`;

const None = styled.div`
  border: 1px solid ${color.border};
  flex: 1;
  text-align: center;
  padding: 4rem 0;
`;

const Actions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0 8px;
`;

const List = ({ selected, onSelect, names, onDelete }) => {
  const [deleteTemplateVisible, setDeleteTemplateVisible] = useState(false);

  if (names.length === 0) {
    return <None>No templates</None>;
  }

  return (
    <ListWrapper>
      {deleteTemplateVisible && (
        <WarningModal
          onOk={() => {
            onDelete();
            setDeleteTemplateVisible(false);
          }}
          warningText="Do you really want to delete this template?"
          closeModal={() => setDeleteTemplateVisible(false)}
        />
      )}
      {names.map(n => (
        <Item
          className={n === selected ? selectedCss : ""}
          key={n}
          onClick={() => onSelect(n)}
        >
          <TemplateRow>
            <TemplateName>{n || "(no name)"}</TemplateName>
            {n === selected && (
              <Button onClick={() => setDeleteTemplateVisible(true)}>
                <Trash fill={color.red} />
              </Button>
            )}
          </TemplateRow>
        </Item>
      ))}
    </ListWrapper>
  );
};

const TemplateModal = ({ date, clinicId, close, onNewTemplate }: Props) => {
  // For when we select a template to use
  const { onUpdateUnsaved } = useEditContext();

  // When creating a new template we want to ask for a name before closing
  // the modal.  This state toggles the name input.
  const [makeNew, setMakeNew] = useState(false);
  // Name stores the name of the new template, or the name of the template
  // selected.
  const [name, setName] = useState("");

  const [{ fetching, data }] = useQuery({
    query: templateGql,
    variables: { clinic: clinicId },
  });
  const templates =
    (data && Array.isArray(data.templates) && data.templates) || [];

  const names = Array.from(new Set(templates.map(t => t.group)));

  const [, upsertTemplate] = useMutation(upsertTemplateGql);

  const deleteSelected = async () => {
    const selectedTemplate = templates
      .filter(t => t.group === name)
      .map(t => t.id);

    if (!selectedTemplate) {
      return null;
    }

    const result = await upsertTemplate({
      new: [],
      update: [],
      delete: selectedTemplate,
    });

    if (result.error) {
      return notification.error({ message: "Error deleting template" });
    } else {
      notification.success({ message: "Template deleted" });
      close();
    }
  };

  if (makeNew) {
    return (
      <LegacyModal isOpen={true} openModal={() => {}} closeModal={close}>
        <LegacyModal.Title>Template manager</LegacyModal.Title>
        <LegacyModal.Content>
          <p>New template name</p>
          <Input
            onChange={e => setName(e.target.value)}
            value={name}
            autoFocus
          />
        </LegacyModal.Content>
        <LegacyModal.Footer>
          <Button
            onClick={() => onNewTemplate(name)}
            type="primary"
            disabled={!name}
          >
            Start editing
          </Button>
        </LegacyModal.Footer>
      </LegacyModal>
    );
  }

  return (
    <LegacyModal isOpen={true} openModal={() => {}} closeModal={close}>
      <LegacyModal.Title>Template manager</LegacyModal.Title>
      {fetching && (
        <LegacyModal.Content>
          <p>Loading</p>
        </LegacyModal.Content>
      )}
      <LegacyModal.Content>
        <List
          onSelect={setName}
          selected={name}
          names={names}
          onDelete={deleteSelected}
        />
      </LegacyModal.Content>
      <LegacyModal.Footer>
        <Actions>
          <Button onClick={() => setMakeNew(true)} type="secondary">
            Create new
          </Button>
          <Button
            disabled={!name}
            type="primary"
            onClick={() => {
              const tpls = templates.filter(t => t.group === name);
              onUpdateUnsaved(tpls.map(t => templateToSlot(t, date)));
              close();
            }}
          >
            Load
          </Button>
        </Actions>
      </LegacyModal.Footer>
    </LegacyModal>
  );
};

export default TemplateModal;
