import { V, S } from "src/shared-styles";

const styles = {
  V,
  S,
  td: {
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
  stage: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    transition: "0.3s all",
  },
  edited: {
    background: "#fbf6c8",
  },
  container: {
    ...S.container,
    ...S.materialUICard,
  },

  preview: {
    padding: ".25rem 1.5rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  previewText: {
    fontWeight: V.fontWeightBolder,
  },

  actions: {},
  placeholder: {
    color: V.colors.fontFaded,
    cursor: "pointer",
  },
  skipped: {
    fontWeight: V.fontWeightHeavy,
    cursor: "pointer",
  },
  skippedRow: {
    background: V.colors.whiteGreyBackground,
  },
  tcHead: {
    fontWeight: V.fontWeightHeavy,
    padding: V.spacing,
    lineHeight: `${V.fontHuge}px`,
  },
  tcHeadInactive: {
    padding: V.spacing,
    background: V.colors.whiteGreyBackground,
    lineHeight: `${V.fontHuge}px`,
  },
  today: {
    background: V.colors.veryLightOrange,
  },
};
styles.getTCHead = isActive => {
  if (isActive) {
    return styles.tcHead;
  }
  return styles.tcHeadInactive;
};

export default styles;
