import { useQuery, UseQueryResponse } from "src/utils/http/gqlQuery";

type Note = {
  id: string;
  author: { id: string; name: string };
  text: string;
  createdAt: string;
};

export const useNotes = (
  type: string,
  id: string
): UseQueryResponse<{ notes: Note[] }> => {
  return useQuery<{ notes: Note[] }>({
    query,
    variables: { type, id },
  });
};

const query = `
query InternalNotes($type: ModelType!, $id: ID!) {
  notes: internalNotes(modelType: $type, modelId: $id) {
    id
    author { id name }
    text
    createdAt
  }
}
`;
