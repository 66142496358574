import map from "lodash/fp/map";
import concat from "lodash/fp/concat";
import __ from "lodash/fp/placeholder";
import fromPairs from "lodash/fp/fromPairs";
import pipe from "lodash/fp/pipe";

import {
  patientStatusNames,
  segmentationTaskTypes,
  alignerManufacturingTaskTypes,
  shipmentTaskTypes,
  clinicalTaskTypes,
  manufacturingTaskTypes,
  clinicalPipelinesTypes,
  taskTypes,
  stlTaskTypes,
  taskLabels,
} from "./constants";
import { TaskTypes, PipelineTypes, TaskLabels } from "./types";
import { createTitlesDictionaryFromKeys } from "./utils";

export const humanReadablePatientStatusName = createTitlesDictionaryFromKeys(
  patientStatusNames
);

export const pipelineTypeToTaskTypeMap: Record<PipelineTypes, TaskTypes[]> = {
  beginning: manufacturingTaskTypes,
  refinement: manufacturingTaskTypes,
  reboot: manufacturingTaskTypes,
  retainer: [
    ...segmentationTaskTypes,
    ...stlTaskTypes,
    ...alignerManufacturingTaskTypes,
    ...shipmentTaskTypes,
  ],
  holding_stage: [
    ...segmentationTaskTypes,
    ...stlTaskTypes,
    ...alignerManufacturingTaskTypes,
    ...shipmentTaskTypes,
  ],
  reprint: [
    ...stlTaskTypes,
    ...alignerManufacturingTaskTypes,
    ...shipmentTaskTypes,
  ],

  // if it's not any of manufacturing pipeline types
  // then it's clinical pipeline type
  ...pipe(
    map(concat(__, [clinicalTaskTypes])),
    fromPairs
  )(clinicalPipelinesTypes),
};

export const taskTypeToTitleMap = Object.assign(
  createTitlesDictionaryFromKeys(taskTypes),
  {
    send_for_segmentation: "Send for Segmentation",
    receive_from_segmentation: "Receive from Segmentation",
    export_treatment_plan_files: "Export Treatment Plan",
    download_stl_files: "Download STLs",
    zip_stl_files: "Zip STLs",
    send_stl_files_for_aligner_manufacturing: "Send for Aligner Manufacturing",
    receive_aligners_from_manufacturing: "Receive from Aligner Manufacturing",
    qa_trays: "QA",
    create_kits_for_shipment: "Create Shipment",
    ship_kit_to_patient: "Ship Kit",
    upload_cbct: "Upload CBCT",
  } as Partial<Record<TaskTypes, string>>
);

export const taskLabelToTitleMap = Object.assign(
  createTitlesDictionaryFromKeys(taskLabels),
  {
    pending_pt_sim_approval: "Pending PT Sim Approval",
  } as Partial<Record<TaskLabels, string>>
);
