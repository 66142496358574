import React from "react";

import orderBy from "lodash/orderBy";
import { Patient } from "src/types/gql";
import { useSubmissions } from "src/scenes/PatientProfile/Forms/GQLForms/queries";
import { FormsContextProvider } from "src/scenes/PatientProfile/Forms/GQLForms/useFormsContext";
import { EmptyNotes, NoteHeader, NoteTitle } from "../styles";
import { formatDateForTimeline } from "../util";
import { NoteContentProps } from "./types";
import BeginningVisitForm from "./BeginningVisitForm";
import TreatmentSetupForm from "./TreatmentSetupForm";
import IndirectForm from "./IndirectForm";
import RefinementForm from "./RefinementForm";
import OutlierForm from "./OutlierForm";
import FollowupVisitForm from "./FollowupVisitForm";
import ReferralForm from "./ReferralForm";

export interface Props {
  patient: Patient;
}

export const NOTE_COMPONENT_LOOKUP = new Map([
  ["beginning_visit", BeginningVisitForm],
  ["treatment_setup", TreatmentSetupForm],
  ["indirect", IndirectForm],
  ["refinement", RefinementForm],
  ["outlier", OutlierForm],
  ["followup_visit", FollowupVisitForm],
  ["referral", ReferralForm],
]);

export function renderNoteContent({
  patient,
  submission,
  childSubmissions,
}: NoteContentProps & { patient: Patient }) {
  const NoteComponent = NOTE_COMPONENT_LOOKUP.get(submission.form.slug);
  if (!NoteComponent) {
    return null;
  }

  return (
    <FormsContextProvider patient={patient}>
      <NoteComponent
        submission={submission}
        childSubmissions={childSubmissions}
      />
    </FormsContextProvider>
  );
}

export default function NotesContent({ patient }: Props) {
  const [{ data }] = useSubmissions({ userID: patient.id }, false);
  if (!data) {
    return null;
  }
  const finishedSubmissions = data.submissions.filter(
    ({ approvedAt, parentID }) => !!approvedAt && !parentID
  );
  if (finishedSubmissions.length === 0) {
    return (
      <EmptyNotes>
        Notes are extracted from submitted forms, but there are no finished form
        submissions for this patient.
      </EmptyNotes>
    );
  }

  const sortedSubmissions = orderBy(
    finishedSubmissions,
    ["approvedAt"],
    ["desc"]
  );
  const content = sortedSubmissions.map(submission => {
    const childSubmissions = data.submissions.filter(
      it => it.parentID === submission.id
    );

    if (submission.form.slug === "treatment_setup") {
      return null;
    }

    const noteContent = renderNoteContent({
      patient,
      submission,
      childSubmissions,
    });
    if (!noteContent) {
      return null;
    }

    return (
      <div key={submission.id}>
        <NoteHeader>
          <NoteTitle>
            {submission.form.name} -{" "}
            {formatDateForTimeline(submission.approvedAt)}
          </NoteTitle>
        </NoteHeader>

        <div key="content">{noteContent}</div>
      </div>
    );
  });

  if (content) {
    return <>{content}</>;
  } else {
    return (
      <EmptyNotes>
        The completed form submissions for this patient do not have sufficient
        notes.
      </EmptyNotes>
    );
  }
}
