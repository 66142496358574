export default [
  {
    to: "consents",
    text: "Medical consents",
  },
  {
    to: "account",
    text: "My Account",
  },
  {
    to: "yourAppointments",
    text: "My Appointments",
  },
  {
    to: "appointment",
    text: "Schedule visit",
  },
  {
    to: "billing",
    text: "Payments",
  },
  {
    to: "questionnaire",
    text: "Medical and Dental history",
  },
  {
    to: "/nps",
    text: "Share your thoughts",
  },
];
