import { getData } from "country-list";
import { Address, UserAddress } from "src/types/gql";

// this was used before, but keeping around incase
// eslint-disable-next-line
export const getAddress = (address: UserAddress | undefined) => {
  if (!address) {
    return "--";
  }

  return formatAddressForPatientProfile(address && address.Address);
};

function formatAddressForPatientProfile(address) {
  if (!address) {
    return "--";
  }

  const { line1, line2, city, state, postalCode } = address;
  const format = (s, delimiter = ", ") => (s ? `${delimiter}${s}` : "");

  const result = [
    [line1, ""],
    [line2],
    [city],
    [state],
    [postalCode, state ? " " : ", "],
    // ky - below should be legal...
    // @ts-ignore
  ].map(input => format(...input));

  return result;
}

export const formatAddress = (a: Address) => {
  return [a.line1, a.line2, a.city, `${a.state} ${a.postalCode}`]
    .filter(Boolean)
    .join(", ");
};

export const DEFAULT_COUNTRY = {
  name: "United States of America",
  code: "US",
};

export const countryNames: Array<{ name: string; code: string }> = [
  DEFAULT_COUNTRY,
].concat(
  getData()
    .filter(a => a.name !== DEFAULT_COUNTRY.name)
    .sort((a, b) => a.name.localeCompare(b.name))
);

export const states: { [abbr: string]: string } = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};
