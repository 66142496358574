import { kea } from "kea";
import { request } from "src/shared/util";
import { ToothOperation } from "src/types/api";
import { ApiRequestState } from "src/types/local";

interface Modifiers {
  setTeeth(teeth: Array<ToothOperation>): Array<ToothOperation>;
  addTeeth(teeth: Array<ToothOperation>): Array<ToothOperation>;
  getTeethRequestState(state: ApiRequestState): ApiRequestState;
  createTeethOperationsRequestState(
    requestState: ApiRequestState
  ): ApiRequestState;
}

interface Thunks {
  getTeeth(patientId: string): Promise<any>;
  createTeethOperations(
    patientId: string,
    t: Array<ToothOperation>
  ): Promise<any>;
}

type Actions = Modifiers & Thunks;

interface ReducerProps {
  teeth: Array<ToothOperation>;
  getTeethRequestState: ApiRequestState;
  createTeethOperationsRequestState: ApiRequestState;
}

type Selectors = {};

export type Props = ReducerProps &
  Selectors & {
    actions: Actions;
  };

export const ToothEnhancer = kea({
  actions: (): Modifiers => ({
    setTeeth: (teeth: Array<ToothOperation>) => teeth,
    addTeeth: (teeth: Array<ToothOperation>) => teeth,

    getTeethRequestState: (a: ApiRequestState) => a,
    createTeethOperationsRequestState: (a: ApiRequestState) => a,
  }),

  reducers: ({ actions }: { actions: { [action: string]: string } }) => ({
    teeth: [
      [],
      {
        [actions.setTeeth]: (
          pre: Array<ToothOperation>,
          next: Array<ToothOperation>
        ) => next,
        [actions.addTeeth]: (
          pre: Array<ToothOperation>,
          next: Array<ToothOperation>
        ) => pre.concat(next),
      },
    ],

    getTeethRequestState: [
      { loading: false, error: false },
      {
        [actions.getTeethRequestState]: (
          pre: ApiRequestState,
          next: ApiRequestState
        ) => next,
      },
    ],

    createTeethOperationsRequestState: [
      { loading: false, error: false },
      {
        [actions.getTeethRequestState]: (
          pre: ApiRequestState,
          next: ApiRequestState
        ) => next,
      },
    ],
  }),

  thunks: ({ actions }: any) => ({
    getTeeth: async (patientId: string) => {
      // Empty the teeth collection.
      actions.setTeeth([]);
      actions.getTeethRequestState({ loading: true, error: false });

      const result = await request(`/api/v1/patients/${patientId}/teeth`);
      if (result instanceof Array) {
        actions.setTeeth(result);
        actions.getTeethRequestState({ loading: false, error: false });
        return result;
      }

      actions.getTeethRequestState({ loading: false, error: true });
      return result;
    },

    createTeethOperations: async (
      patientId: string,
      t: Array<ToothOperation>
    ) => {
      actions.createTeethOperationsRequestState({
        loading: true,
        error: false,
      });

      const result = await request(`/api/v1/patients/${patientId}/teeth`, {
        method: "POST",
        body: JSON.stringify({
          teeth: t,
        }),
      });

      if (result instanceof Array) {
        actions.setTeeth(result);
        actions.getTeethRequestState({ loading: false, error: false });
        actions.createTeethOperationsRequestState({
          loading: false,
          error: false,
        });
        return result;
      }

      actions.createTeethOperationsRequestState({
        loading: false,
        error: true,
      });
      return result;
    },
  }),
});
