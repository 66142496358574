import React from "react";
import { CrownProps, RootProps } from "./types";

export const Crown: React.SFC<CrownProps> = ({ fill, stroke, style }) => (
  <svg
    width="22"
    height="26"
    viewBox="0 0 22 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M13.7965 1.86611C11.7513 0.232804 9.67386 1.30931 8.68357 1.86611C1.81768 5.72665 0.863077 7.61978 1.01423 9.84701C1.2577 13.4353 2.58462 21.1316 5.94452 23.2104C9.30442 25.2891 13.3095 24.0765 14.8921 23.2104C19.092 21.1688 20.3702 13.7447 20.3702 9.84701C20.3702 5.94935 16.3529 3.90773 13.7965 1.86611Z"
      fill={fill || "#fff"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />
  </svg>
);

// Root always contains the portion as if it attached as a Crown.
export const Root: React.SFC<RootProps> = ({
  hasRootCanal,
  style,
  fill,
  stroke,
}) => (
  <svg
    width="19"
    height="54"
    viewBox="0 0 19 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M2.69353 18.6366C1.37931 9.55838 1.69579 7.3022 2.00001 6H7V1H13V6H17.5C16.0397 9.53455 16.2009 25.3336 16.0184 28.6822C15.8358 32.0307 13.4629 45.9829 9.44721 50.8196C6.23465 54.689 5.5532 50.4476 5.61405 47.8432C5.73573 47.099 5.9061 44.1226 5.61405 38.1697C5.32199 32.2167 3.54535 22.6672 2.69353 18.6366Z"
      fill={fill || "#F1F4F5"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />

    {hasRootCanal && (
      <g transform="translate(6,5)">
        <path
          d="M1 43.5C5.5 27 1.5 8 0 0.5L7 0C4.5 18.5 6 31 1 43.5Z"
          fill="#C270A7"
        />
      </g>
    )}
  </svg>
);
