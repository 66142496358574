import React from "react";
import styled from "react-emotion";
import color from "src/styles/color";
import { StageInfo } from "./TrayLogRow";
import { useSetInProgress } from "./query";
import { TimelineItem } from "src/state/useTimeline";
import { ArchType } from "src/types/gql";
import { titleCase } from "src/shared/util";
import { Treatment } from "src/scenes/TrayLogs/query";
import { notification } from "antd";
import { isPatientOnImpressTM } from "src/scenes/TrayLogs/util";

type Props = {
  userID: string;
  treatment: Treatment;
  status: "upcoming" | "missing" | "assumed_complete";
  timeline: TimelineItem;
  timelineArch: ArchType;
};

const TrayLogsUnworn: React.FC<Props> = props => {
  const { timeline, status, treatment } = props;
  const setInProgress = useSetInProgress();

  const onSubmit = () => {
    if (isPatientOnImpressTM(treatment)) {
      notification.warning({
        message:
          "Patient is using Impress Treatment Monitoring. Please make changes there.",
      });
      return;
    }

    setInProgress(timeline.trayNumber, props.userID, timeline.archType);
  };

  const rows = (() => {
    if (timeline.archType === "both") {
      return [fakeTrayLog(timeline, "upper"), fakeTrayLog(timeline, "lower")];
    }
    return [fakeTrayLog(timeline, timeline.archType)];
  })();

  /* in V2 of the designs we don't want to warn on missing trays :thinking-face: */
  return (
    <Wrapper>
      {rows.map(r => (
        <StageInfo
          userID={props.userID}
          treatment={props.treatment}
          {...r}
          timelineArch={props.timelineArch}
          status={titleCase(status === "missing" ? "assumed_complete" : status)}
          onChange={(_item: any) => {
            // Mark trays as in progress.  This is the only option available to unworn rows.
            onSubmit();
          }}
        />
      ))}
    </Wrapper>
  );
};

export default TrayLogsUnworn;

const fakeTrayLog = (t: TimelineItem, archType: ArchType) => {
  return {
    id: `upcoming-${t.trayNumber}-${archType}`,
    stage: t.trayNumber,
    archType,
    photos: [],
  };
};

const Wrapper = styled.div`
  transition: all 0.3s;
  background: #fafafa;
  padding: 10px;
  overflow: hidden;
  height: auto;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid ${color.border};
  padding: 10px 0 10px 60px;
`;
