import React, { useState } from "react";
import moment from "moment";
import { DatePicker, notification } from "antd";
import styled from "react-emotion";
import color from "src/styles/color";
import Button from "src/shared/Button";
import Dropdown from "src/shared/Dropdown";
import DeleteConfirmModal from "src/shared/DeleteConfirmModal";
import { url } from "src/shared/util";

import { getOnScheduleText } from "./util";
import Trash from "src/shared/Icons/Trash";
import TrayUpper from "src/shared/Icons/TrayUpper.svg";
import TrayLower from "src/shared/Icons/TrayLower.svg";
import TrayInfo from "./TrayInfo";
import Upload from "./Upload";
import PhotoModal from "./PhotoModal";
import {
  useUpdateTrayLogs,
  useSetInProgress,
  useDeleteTrayLogs,
} from "./query";
import { ArchType } from "src/types/gql";
import { Treatment } from "src/scenes/TrayLogs/query";
import { isPatientOnImpressTM } from "src/scenes/TrayLogs/util";

interface RowProps {
  userID: string;
  treatment: Treatment;
  status: string;
  trayLogID?: string;
  serialNumber?: string;
  startTime?: string;
  endTime?: string;
  stage: number;
  plannedEndTime?: string;
  archType: ArchType;
  timelineArch: ArchType;
  nextStage?: number;
  hasTemplate?: boolean;
  isWearable?: boolean;

  photos: Array<{
    id: string;
    file: {
      id: string;
      filename: string;
      type: string;
    };
  }>;
}

const Row: React.FC<RowProps> = props => {
  const { status, trayLogID, treatment } = props;
  const updateLogs = useUpdateTrayLogs();

  const onChange = async (field, newTime) => {
    if (!trayLogID) return;

    if (isPatientOnImpressTM(treatment)) {
      notification.warning({
        message:
          "Patient is using Impress Treatment Monitoring. Please make changes there.",
      });
      return;
    }

    const res = await updateLogs({
      updateTrayLogs: [{ id: trayLogID, [field]: newTime }],
    });

    if (res.error) {
      notification.error({
        message: "There was an error updating this tray log.",
      });
    } else {
      notification.success({ message: "Tray log updated!" });
    }
  };

  return (
    <RowContainer style={{ opacity: isPlanned(status) ? 0.6 : 1 }}>
      <StageInfo {...props} onChange={onChange} />
    </RowContainer>
  );
};

interface StageInfoProps extends RowProps {
  onChange: (string, any) => void;
}

const options = (status: string) => {
  switch (status) {
    case "Complete":
      return ["In progress", "Complete"];
    case "Upcoming":
      return ["In progress", "Upcoming"];
    case "In progress":
      return ["In progress", "Complete"];
    default:
      return Array.from(new Set(["In progress", status]));
  }
};

export const StageInfo: React.FC<StageInfoProps> = ({
  trayLogID,
  userID,
  treatment,
  stage,
  archType,
  timelineArch,
  serialNumber,
  startTime,
  endTime,
  plannedEndTime,
  status,
  onChange,
  hasTemplate,
  photos,
  isWearable = true,
}) => {
  const [showExpandedPhoto, setShowExpandedPhoto] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const setInProgress = useSetInProgress();
  const deleteTrayLogs = useDeleteTrayLogs();

  return (
    <StageInfoContainer>
      <Image>
        {photos.map(p => (
          <img
            alt=""
            src={url("/api/v2/files/" + p.file.id)}
            onError={e => {
              (e.target as HTMLImageElement).src =
                archType === "upper" ? TrayUpper : TrayLower;
            }}
            onClick={(e: React.SyntheticEvent<HTMLImageElement>) => {
              // TODO: Show larger image modal
              if ((e.target as HTMLImageElement).src.includes(".svg")) {
                // Show set upload as this image failed.
                setShowUpload(true);
                return;
              }
              setShowExpandedPhoto(true);
            }}
          />
        ))}
        {photos.length === 0 && (
          <img
            src={archType === "upper" ? TrayUpper : TrayLower}
            alt="Progress"
            style={{ cursor: "pointer" }}
            onClick={() => {
              // TODO: Show file upload modal
              setShowUpload(true);
            }}
          />
        )}
      </Image>
      <TrayInfo
        archType={archType}
        isWearable={isWearable}
        stage={stage}
        udid={serialNumber}
        hasTemplate={hasTemplate}
      />
      <DatePickerContainer>
        <DatePicker
          allowClear={false}
          value={startTime ? moment(startTime) : undefined}
          onChange={time => onChange("startTime", time)}
          disabled={isPlanned(status)}
          format={dateFormat}
        />
      </DatePickerContainer>
      <DatePickerContainer>
        <DatePicker
          value={endTime ? moment(endTime) : undefined}
          onChange={time => onChange("endTime", time)}
          placeholder="--"
          format={dateFormat}
          disabled={isPlanned(status)}
        />
      </DatePickerContainer>
      <DatePickerContainer>
        <DatePicker
          allowClear={false}
          value={plannedEndTime ? moment(plannedEndTime) : undefined}
          onChange={time => onChange("plannedEndTime", time)}
          disabled={isPlanned(status)}
          format={dateFormat}
        />
      </DatePickerContainer>
      <div style={{ paddingRight: "10px" }}>
        {getOnScheduleText(endTime, plannedEndTime)}
      </div>
      <div className="dropdown">
        <Dropdown
          selected={status}
          options={options(status)}
          onSelect={item => {
            // Change start time or end time depending on what choice was selected
            if (item === "In progress" && status !== item) {
              if (isPatientOnImpressTM(treatment)) {
                notification.warning({
                  message:
                    "Patient is using Impress Treatment Monitoring. Please make changes there.",
                });
                return;
              }

              setInProgress(stage, userID, timelineArch);
              return;
            }
            if (item === "Complete" && status !== item) {
              onChange("endTime", new Date().toISOString());
              return;
            }
          }}
        />
      </div>
      <div>
        {trayLogID && (
          <Button
            kind="invisible"
            onClick={() => {
              setShowDelete(true);
            }}
            style={{ padding: 0 }}
          >
            <Trash fill="#E24C4B" />
          </Button>
        )}
        {showDelete && (
          <DeleteConfirmModal
            text={`Are you sure you want to delete the following tray log? Tray ${stage} - ${archType} arch`}
            actionText="Delete"
            onConfirm={async () => {
              if (isPatientOnImpressTM(treatment)) {
                notification.warning({
                  message:
                    "Patient is using Impress Treatment Monitoring. Please make changes there.",
                });
                return;
              }

              if (!trayLogID) {
                setShowDelete(false);
                return;
              }
              await deleteTrayLogs(userID, [trayLogID]);
              setShowDelete(false);
            }}
            onClose={() => setShowDelete(false)}
          />
        )}
        {showUpload && (
          <Upload
            userID={userID}
            onClose={() => setShowUpload(false)}
            trayLogID={trayLogID}
          />
        )}
        {showExpandedPhoto && (
          <PhotoModal
            url={url("/api/v2/files/" + photos[0].file.id)}
            onClose={() => setShowExpandedPhoto(false)}
          />
        )}
      </div>
    </StageInfoContainer>
  );
};

export default Row;

const isPlanned = (status: string) =>
  status !== "In progress" && status !== "Complete";

const dateFormat = "ll"; // Mar 2, 2020

const StageInfoContainer = styled.div`
  display: grid;
  min-height: 64px;
  grid-template-columns: 84px 124px repeat(5, 1fr) 40px;
  padding: 15px 0;
  align-items: center;

  > .dropdown {
    padding-right: 20px;
  }
`;

const RowContainer = styled.div`
  background: #fafafa;

  & + & {
    border-top: 1px solid ${color.border};
  }
`;

const DatePickerContainer = styled.div`
  padding-right: 10px;
  text-align: center;
`;

const Image = styled.div`
  align-self: left;
  justify-self: center;

  width: 64px;
  height: 64px;
  overflow: hidden;
  display: flex;

  img {
    align-self: center;
    justify-self: center;
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
  }
`;
