import React from "react";
import styled from "react-emotion";
import { TaskCategory } from "./types";
import { titleCase } from "src/shared/util";

type Props = {
  category: TaskCategory;
  style?: any;
  className?: any;
  blocked?: boolean;
};

export const categories = [
  "form_review",
  "external_care",
  "comfort_check",
  "internal",
  "export",
  "segmentation",
  "treatment_plan",
  "manufacturing",
];

const Category: React.FC<Props> = ({ category, style, className, blocked }) => (
  <Span
    style={Object.assign(style || {}, { background: color(category, blocked) })}
    className={className}
  >
    {titleCase(category.replace(/_/, " "))}
  </Span>
);

export default Category;

const color = (name: TaskCategory | string, blocked?: boolean): string => {
  if (blocked) {
    return "#999";
  }

  return colors[name] || pipelineColors[name] || "#fff";
};

const pipelineColors = {
  segmentation: "#CE6E58",
  manufacturing: "#CE6E58",
  treatment_plan: "#59B8B8",
};

const colors: { [key in TaskCategory]: string } = {
  form_review: "#59B8B8",
  external_care: "#8168BB",
  comfort_check: "#E1A733",
  internal: "#CE6E58",
  export: "#98c76b",
  segmentation: "#C270A7",
  treatment_plan: "#5893CE",
  manufacturing: "#999999",
};

const Span = styled.span`
  padding: 4px 12px;
  border-radius: 50px;
  font-size: 10px;
  letter-spacing: 0.5px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
`;
