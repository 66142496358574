import styled from "react-emotion";
// styles
import color from "src/styles/color";
import textStyles from "src/styles/textStyles";
import LeftArrow from "src/shared/Icons/LeftArrow.svg";

export const Perimeter = styled.div`
  border-radius: 2px;
  height: 100px;
  max-width: 360px;
  width: 360px;
  margin-right: 24px;
  display: flex;

  cursor: ${({ theme: { disabled, kind } }) => {
    if (disabled) {
      return "not-allowed";
    } else if (kind === "success") {
      return "default";
    }
    return "pointer";
  }};

  position: relative;
  transition: all 0.3s ease;
  &:before {
    content: ${({ theme: { kind } }) =>
      kind === "success" ? "" : `url(${LeftArrow})`};
    color: ${color.gray3};
    padding-right: 0.5em;
    position: absolute;
    font-size: 24px;
    top: 50.5px;
    right: 0;
    transform: translateY(-50%) translateX(-8%);
    transition: all 0.3s ease;
  }

  &:hover {
    background: rgba(238, 238, 238, 0.3);
    border: ;
  }
  &:hover:before {
    transform: translateY(-50%) translateX(10%);
  }
`;

export const BorderLeft = styled.div`
  width: 8px;
  height: 100%;

  border-left: 8px solid ${({ theme: { color } }) => color};
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
`;

export const Border = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${color.border};
  border-left: none;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
`;

export const Title = styled.p`
  margin-bottom: 12px;
  ${textStyles("xlarge")};
  font-weight: bold;
`;

export const Subtitle = styled.p`
  color: ${color.gray3};
  ${textStyles("medium")};
`;

export const red = "#DB2F25";

export const kindMap = {
  success: color.green,
  warning: color.yellow,
  error: red,
};
