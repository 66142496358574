import { capitalize } from "lodash";
import { getSummaryForSlugs } from "./generatedSummaryUtil";

const getMolarCanineSummary = answers => {
  const rightMolarAnswer = answers["right_sagittal_angle_dx"];
  const rightCanineAnswer = answers["right_canine_relationship_angle_dx"];
  const leftMolarAnswer = answers["left_sagittal_angle_dx"];
  const leftCanineAnswer = answers["left_canine_relationship_angle_dx"];

  if (
    !leftMolarAnswer ||
    !rightMolarAnswer ||
    !rightCanineAnswer ||
    !leftCanineAnswer
  ) {
    return null;
  }

  let summaryArray: Array<string> = [];

  if (leftMolarAnswer === rightMolarAnswer) {
    summaryArray.push(`Class ${leftMolarAnswer} malocclusion`);
  }
  if (rightMolarAnswer !== "I" && leftMolarAnswer === "I") {
    summaryArray.push(`Class ${rightMolarAnswer} subdivision right`);
  }
  if (leftMolarAnswer !== "I" && rightMolarAnswer === "I") {
    summaryArray.push(`Class ${leftMolarAnswer} subdivision left`);
  }

  if (
    rightCanineAnswer !== rightMolarAnswer ||
    leftCanineAnswer !== leftMolarAnswer
  ) {
    if (rightCanineAnswer !== "I" && rightCanineAnswer === leftCanineAnswer) {
      summaryArray.push(`Class ${rightCanineAnswer} canine`);
    }
    if (
      (rightCanineAnswer !== "I" || leftCanineAnswer !== "I") &&
      rightCanineAnswer !== leftCanineAnswer
    ) {
      if (rightCanineAnswer !== "I") {
        summaryArray.push(`Class ${rightCanineAnswer} canine on the right`);
      }
      if (leftCanineAnswer !== "I") {
        summaryArray.push(`Class ${leftCanineAnswer} canine on the left`);
      }
    }
  }

  return summaryArray.length ? summaryArray.join(", ") : null;
};

const getExtraoralSummary = answers => {
  const summary = getSummaryForSlugs(
    ["lip_incompetence", "smile_line"],
    answers
  ).join(", ");

  if (summary) {
    return capitalize(summary.toLowerCase());
  }
  return null;
};

const upperMidline = answers => {
  const upperMidlineAnswer = answers["upper_midline_position"];
  const upperMidlineLenAnswer = answers["upper_midline_shift"];

  if (
    !upperMidlineAnswer ||
    !upperMidlineLenAnswer ||
    upperMidlineAnswer === "Normal"
  ) {
    return null;
  }
  return `Upper midline ${upperMidlineAnswer.toLowerCase()} by ${upperMidlineLenAnswer}mm`;
};

const lowerMidline = answers => {
  const lowerMidlineAnswer = answers["lower_midline_position"];
  const lowerMidlineLenAnswer = answers["lower_midline_shift"];

  if (
    !lowerMidlineAnswer ||
    !lowerMidlineLenAnswer ||
    lowerMidlineAnswer === "Normal"
  ) {
    return null;
  }
  return `Lower midline ${lowerMidlineAnswer.toLowerCase()} by ${lowerMidlineLenAnswer}mm`;
};

const upperArchType = answers => {
  const upperArchTypeAnswer = answers["upper_arch_type_dx"];
  const upperArchLenAnswer = answers["upper_arch_length_dx"];

  if (
    !upperArchTypeAnswer ||
    !upperArchLenAnswer ||
    upperArchTypeAnswer === "Normal"
  ) {
    return null;
  }
  return `Upper ${upperArchTypeAnswer.toLowerCase()}: ${upperArchLenAnswer}mm`;
};

const lowerArchType = answers => {
  const lowerArchTypeAnswer = answers["lower_arch_type_dx"];
  const lowerArchLenAnswer = answers["lower_arch_length_dx"];

  if (
    !lowerArchTypeAnswer ||
    !lowerArchLenAnswer ||
    lowerArchTypeAnswer === "Normal"
  ) {
    return null;
  }
  return `Lower ${lowerArchTypeAnswer.toLowerCase()}: ${lowerArchLenAnswer}mm`;
};

const crossbite = answers => {
  const crossbiteAnswer = answers["crossbite_dx"];
  const crossbiteTeethAnswer = answers["crossbite_teeth_picker"];

  if (
    !crossbiteAnswer ||
    !crossbiteTeethAnswer ||
    crossbiteTeethAnswer.length === 0 ||
    crossbiteAnswer === "Not Present"
  ) {
    return null;
  }
  return `Crossbite: #${crossbiteTeethAnswer.sort((a, b) => a - b).join(", ")}`;
};

export const generateDxSummary = answers => {
  const summaries = [
    getExtraoralSummary(answers),
    getSummaryForSlugs(
      [
        "which_tmj_issues_are_present",
        "periodontal_issues_present",
        "oral_habits",
      ],
      answers
    ).join("\n • "),
    getMolarCanineSummary(answers),
    getSummaryForSlugs(["overjet", "incisor_vertical_bite"], answers).join(
      "\n • "
    ),
    [upperMidline(answers), lowerMidline(answers)].filter(Boolean).join(", "),
    crossbite(answers),
    [upperArchType(answers), lowerArchType(answers)].filter(Boolean).join(", "),
    getSummaryForSlugs(["additional_sof_diagnosis"], answers),
  ].filter(Boolean);

  console.log(summaries.join("\n • "));
  if (!summaries) {
    return null;
  }

  const str = " • " + summaries.join("\n • ");
  // Remove double bullet points cuz some qs insert their own -.-
  return str.replace(" •  • ", " • ");
};
