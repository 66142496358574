import React, { useState, useEffect } from "react";
import styled from "react-emotion";
import { notification } from "antd";
import InputText from "src/shared/InputText";
import color from "src/styles/color";
import useSelf from "src/state/self";
import { useCreatePatient, useClinics } from "./query";
import { getErrorMessage } from "src/utils/error";

const Wrapper = styled.div`
  background: #fff;
  padding: 60px;
  min-height: calc(100vh - 64px);

  p {
    margin: 1.5rem 0 0.5rem;
  }
`;

export const Button = styled.button`
  height: 40px;
  padding-left: 40px;
  padding-right: 40px;
  color: ${color.white};
  background: ${color.primary};
  border: 1px ${color.primary} solid;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  margin: 40px 0 0;
  &:disabled {
    cursor: not-allowed;
  }
  &:focus {
    outline: ${color.primary} auto 5px;
  }
`;

const NewPatient = () => {
  const self = useSelf();
  const createPatient = useCreatePatient();
  // We always need to set a default home clinic ID when adding a user from the EMR
  const [, , clinics] = useClinics();

  const [status, setStatus] = useState({ saving: false, error: false });
  const [patient, setPatient] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    organizationId: "",
    homeClinicId: "",
  });

  useEffect(() => {
    if (self.organizations.length === 1) {
      // We only have one organization, so set the ID appropriately.
      setPatient({ ...patient, organizationId: self.organizations[0].id });
    }
    // eslint-disable-next-line
  }, [self]);

  useEffect(() => {
    if (clinics.length === 1) {
      setPatient({ ...patient, homeClinicId: clinics[0].id });
    }
    // eslint-disable-next-line
  }, [clinics.length]);

  const onCreate = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (status.saving) {
      return;
    }

    setStatus({ saving: true, error: false });
    const result = await createPatient({ input: patient });

    if (result.error) {
      setStatus({ saving: false, error: true });
      notification.error({
        message: "Error creating patient",
        description: getErrorMessage(result.error.message),
      });
      return;
    }

    setStatus({ saving: false, error: false });
    notification.success({
      message: "Patient created!",
      description: `${patient.firstName} ${
        patient.lastName
      } was added to the EMR`,
    });
  };

  return (
    <Wrapper>
      <h1>New patient</h1>

      <form onSubmit={onCreate}>
        {self.organizations.length > 1 && (
          <>
            <p>Organization</p>
            <select
              onChange={e =>
                setPatient({ ...patient, organizationId: e.target.value })
              }
            >
              <option value="">Choose an organization</option>
              {self.organizations.map(o => (
                <option key={o.id} value={o.id}>
                  {o.name}
                </option>
              ))}
            </select>
          </>
        )}

        <p>Clinic</p>
        <select
          value={patient.homeClinicId}
          onChange={e =>
            setPatient({ ...patient, homeClinicId: e.target.value })
          }
        >
          <option value="">Choose the patient's clinic</option>
          {clinics
            .filter(c => c.organizationId === patient.organizationId)
            .map(c => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
        </select>

        <p>First Name</p>
        <InputText
          value={patient.firstName}
          onChange={e => setPatient({ ...patient, firstName: e.target.value })}
        />

        <p>Last Name</p>
        <InputText
          value={patient.lastName}
          onChange={e => setPatient({ ...patient, lastName: e.target.value })}
        />

        <p>Email</p>
        <InputText
          value={patient.email}
          onChange={e => setPatient({ ...patient, email: e.target.value })}
        />

        <p>Phone number</p>
        <InputText
          value={patient.phone}
          onChange={e => setPatient({ ...patient, phone: e.target.value })}
        />

        <Button type="submit" disabled={status.saving}>
          Add new patient
        </Button>
      </form>
    </Wrapper>
  );
};

export default NewPatient;
