import React from "react";
import { Popover } from "antd";
import Button from "src/shared/Button";
import { Layout, Title, Buttons } from "./styles";

export interface Props {
  placement: string;
  visible: boolean;
  title: string;
  okText?: string;
  cancelText?: string;
  onConfirm(): void;
  onCancel(): void;
  setVisible(visible: boolean): void;
  children?: any;
}

const Popconfirm = (props: Props) => {
  const { title, cancelText, okText, setVisible } = props;

  const overlay = (
    <Layout>
      <Title>{title}</Title>
      <Buttons>
        <Button style={{ marginRight: "8px" }} onClick={props.onCancel}>
          {cancelText || "No"}
        </Button>
        <Button onClick={props.onConfirm} type="primary">
          {okText || "Yes"}
        </Button>
      </Buttons>
    </Layout>
  );

  return (
    <Popover
      content={overlay}
      placement={"bottomLeft" || props.placement}
      trigger="click"
      visible={props.visible}
      onVisibleChange={setVisible}
    >
      {props.children}
    </Popover>
  );
};

export default Popconfirm;
