import React, { Component } from "react";
import PropTypes from "prop-types";
import { InternalNotesEnhancer } from "src/state/InternalNote";

import NoteForm, { INTERNAL_NOTE } from "./note-form";

import styles from "./doctor-input-style";
import FileUpload from "../../../../components/file-upload/file-upload";

class Note extends Component {
  static contextTypes = {
    patient: PropTypes.object,
  };
  static propTypes = {
    addEvent: PropTypes.func,
  };
  postNote = values => {
    if (!values[INTERNAL_NOTE]) {
      return;
    }
    this.props.actions.createInternalNote({
      text: values[INTERNAL_NOTE], // No fucking idea why or how.
      model_id: this.props.clinicalRecordId,
      model_type: "ClinicalRecord",
    });
  };
  render() {
    return (
      <div style={styles.noteContainer}>
        <NoteForm onSubmit={this.postNote} />
        <FileUpload patientUUID={this.context.patient.id} />
      </div>
    );
  }
}

export default InternalNotesEnhancer(Note);
