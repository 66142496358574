import React, { PureComponent } from "react";
import { compose } from "recompose";
import { BuildsEnhancer } from "src/state/Builds";
import BuildTable from "./BuildTable";

const modifiers = compose(BuildsEnhancer);

class ReceivedBuilds extends PureComponent<*, *> {
  render() {
    return (
      <div>
        <BuildTable
          producers={this.props.producers}
          builds={this.props.receivedBuilds}
        />
      </div>
    );
  }
}

export default modifiers(ReceivedBuilds);
