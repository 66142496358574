import React from "react";
import styled from "react-emotion";
import { FormsContextProvider } from "./useFormsContext";
import { DetailedPatient } from "src/types/api";
import Form from "./Form";
import color from "src/styles/color";
import CustomForms from "../Forms";

type Props = {
  patient: DetailedPatient;
  params: {
    formKey?: string;
    submissionId: string | null;
  };
};

const customForms = ["beginning_visit", "treatment_setup", "followup_visit"];

// Forms initializes the FormsContextProvider, which loads all forms
// for the child components.
const Forms: React.FC<Props> = props => {
  // There are three forms that don't fit the generic style:
  // - Beginning, which is very custom
  // - Treatment setup, again very custom
  // - Followup, which is legacy.
  //
  // We still wrap these in a GQL FormsContextProvider to ease the migration
  // into GQL.
  if (customForms.includes(props.params.formKey || "")) {
    return (
      <FormsContextProvider patient={props.patient}>
        <CustomForms {...props} />
      </FormsContextProvider>
    );
  }

  return (
    <FormsContextProvider patient={props.patient}>
      <Container>
        {props.params.formKey && (
          <Form
            slug={props.params.formKey}
            patient={props.patient}
            submissionID={props.params.submissionId}
          />
        )}
      </Container>
    </FormsContextProvider>
  );
};

export default Forms;

const Container = styled.div`
  border: 1px solid ${color.border};
  background: ${color.white};
`;
