import React, { useState, useEffect } from "react";
import { Input, notification } from "antd";
import { useQuery } from "src/utils/http/gqlQuery";
import Counter from "./Counter";
import { request } from "src/shared/util";
import {
  Wrapper,
  disabled,
  Title,
  Inputs,
  Label,
  Header,
  Tray,
  Submit,
  RightPanel,
} from "./styles";
import { query } from "./queries";

type Props = {
  userID: string;
  onAfterCreate?: (result: any) => void;
};

type UIState = {
  first: null | number;
  last: null | number;
  selected: Build[];
  extras: { [item: string]: number };
  // unkown items in the box, custom shit.
  unknowns: { [item: string]: number };
  previous: boolean;
};

type Build = {
  id: string;
  archType: "upper" | "lower";
  isTemplate: boolean;
  serialNumber: string;
  wearTimelineStage: {
    id: string;
    stage: number;
    isWearable: boolean;
    kitID?: string | null;
  };
};

type CreateState = "loading" | "success" | "error" | "none";

const extraNames = [
  "Gum",
  "Carry Case",
  "Elastics",
  "Instructions",
  "Whitening Gel",
  "Flosser",
];

// KitForm renders the UI
const KitForm: React.FC<Props> = ({ userID, onAfterCreate }) => {
  const [uiState, setUIState] = useState<UIState>({
    first: null,
    last: null,
    selected: [],
    extras: {},
    unknowns: {},
    previous: false,
  });
  const [asyncState, setAsyncState] = useState<CreateState>("none");
  const [{ fetching, data }] = useQuery({
    query,
    variables: { userID },
  });
  const [unshipped, shipped] = traysFromBuild((data && data.builds) || []);

  useEffect(() => {
    const filtered = unshipped.filter(t => {
      const { stage } = t.wearTimelineStage;
      return stage >= (uiState.first || 0) && stage <= (uiState.last || 0);
    });
    setUIState({ ...uiState, selected: filtered });
    // eslint-disable-next-line
  }, [uiState.first, uiState.last]);

  if (fetching || !data) {
    return null;
  }

  const onCreateKit = async () => {
    setAsyncState("loading");

    const includedItems = { ...uiState.extras, ...uiState.unknowns };
    const includes = Object.keys(includedItems).map(item => {
      const count = includedItems[item];
      if (count === 1) {
        return item;
      }
      return `${item} (x${count})`;
    });

    const result = await request(`/api/v1/patients/${userID}/kits/`, {
      method: "POST",
      body: JSON.stringify({
        stages: uiState.selected.map(t => t.wearTimelineStage.id),
        includes,
      }),
    });

    if (result.id) {
      setAsyncState("success");
      onAfterCreate && onAfterCreate(result);
      return;
    }

    notification.error({
      message: "There was an error making your kit",
    });
  };

  return (
    <Wrapper className={asyncState === "success" ? disabled : ""}>
      <div>
        <Title>Enter unshipped tray numbers</Title>
        <Inputs>
          <Label>
            First tray
            <Input
              onChange={e =>
                setUIState({ ...uiState, first: parseInt(e.target.value, 10) })
              }
            />
          </Label>
          <Label>
            Last tray
            <Input
              onChange={e =>
                setUIState({ ...uiState, last: parseInt(e.target.value, 10) })
              }
            />
          </Label>
        </Inputs>

        <Title>Select unshipped builds</Title>

        <Header>
          <div />
          <div>Stage</div>
          <div>Arch</div>
          <div>Template?</div>
          <div>Wearable?</div>
          <div>Serial number</div>
        </Header>

        {unshipped.map(t => (
          <Tray
            onClick={() => {
              if (uiState.selected.includes(t)) {
                setUIState({
                  ...uiState,
                  selected: uiState.selected.filter(item => item !== t),
                });
                return;
              }
              setUIState({
                ...uiState,
                selected: uiState.selected.concat([t]),
              });
            }}
          >
            <div>
              <input type="checkbox" checked={uiState.selected.includes(t)} />
            </div>
            <div>{t.wearTimelineStage.stage}</div>
            <div>{t.archType}</div>
            <div>{t.isTemplate ? "Yes" : ""}</div>
            <div>{t.wearTimelineStage.isWearable ? "" : "No"}</div>
            <div>{t.serialNumber}</div>
          </Tray>
        ))}

        {shipped.length > 0 && (
          <Title>
            <a
              href="#previous"
              onClick={e => {
                e.preventDefault();
                setUIState({ ...uiState, previous: true });
              }}
            >
              Show previously shipped trays
            </a>
          </Title>
        )}

        {uiState.previous &&
          shipped.map(t => (
            <Tray
              onClick={() => {
                if (uiState.selected.includes(t)) {
                  setUIState({
                    ...uiState,
                    selected: uiState.selected.filter(item => item !== t),
                  });
                  return;
                }
                setUIState({
                  ...uiState,
                  selected: uiState.selected.concat([t]),
                });
              }}
            >
              <div>
                <input type="checkbox" checked={uiState.selected.includes(t)} />
              </div>
              <div>{t.wearTimelineStage.stage}</div>
              <div>{t.archType}</div>
              <div>{t.isTemplate ? "Yes" : ""}</div>
              <div>{t.wearTimelineStage.isWearable ? "" : "No"}</div>
              <div>{t.serialNumber}</div>
            </Tray>
          ))}
      </div>

      <RightPanel>
        <Title>This kit</Title>

        <p>Trays:</p>
        {uiState.selected.length === 0 && <small>None selected</small>}
        {uiState.selected
          .map(t => `${t.wearTimelineStage.stage} ${t.archType}`)
          .join(", ")}

        <p>Extras:</p>

        {extraNames.map(name => (
          <label>
            {name}
            <Counter
              onChange={(count: number) => {
                setUIState({
                  ...uiState,
                  extras: { ...uiState.extras, [name]: count },
                });
              }}
              value={uiState.extras[name] || 0}
            />
          </label>
        ))}

        {Object.keys(uiState.unknowns).map(name => (
          <label>
            <Input
              style={{ flex: 1 }}
              onChange={e => {
                const value = uiState.unknowns[name];
                const clone = Object.assign({}, uiState.unknowns);
                delete clone[name];
                setUIState({
                  ...uiState,
                  unknowns: { ...clone, [e.target.value]: value },
                });
              }}
            />
            <Counter
              onChange={(count: number) => {
                setUIState({
                  ...uiState,
                  unknowns: { ...uiState.unknowns, [name]: count },
                });
              }}
              value={uiState.unknowns[name] || 0}
            />
          </label>
        ))}

        <a
          href="#unknown"
          onClick={e => {
            e.preventDefault();
            setUIState({
              ...uiState,
              unknowns: { ...uiState.unknowns, "": 0 },
            });
          }}
        >
          Add something else
        </a>

        <Submit kind="primary" onClick={onCreateKit}>
          {asyncState === "success" ? "Kit created" : "Create kit"}
        </Submit>
      </RightPanel>
    </Wrapper>
  );
};

export default KitForm;

// returns unshipped and shipped trays from the build
const traysFromBuild = (builds: Array<any>): [Build[], Build[]] => {
  // Extract shipped and unshipped trays from builds
  const trays = builds
    .map(b => b.trays)
    .flat()
    // Some trays in a build may have "deleted" trays:  we reprint builds because
    // something fucked up.  Filter these out.
    .filter(t => !!t.wearTimelineStage)
    .sort((a, b) => {
      return a.wearTimelineStage.stage - b.wearTimelineStage.stage;
    });

  const unshipped = trays.filter(t => !t.wearTimelineStage.kitID);
  const shipped = trays.filter(t => !!t.wearTimelineStage.kitID);
  return [unshipped, shipped];
};
