import React from "react";

const ClaimFormSvg = () => (
  <svg
    width="112"
    height="116"
    viewBox="0 0 112 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M92.5322 4.82223C92.5322 2.16998 90.3691 0 87.7253 0H4.80687C2.16307 0 0 2.16998 0 4.82223V110.911C0 113.563 2.16307 115.733 4.80687 115.733H68.0171L92.5322 91.1399V4.82223Z"
      fill="#F2F2F2"
    />
    <path
      d="M92.788 91.1401H73.0798C70.436 91.1401 68.2729 93.3101 68.2729 95.9624V115.734L92.788 91.1401Z"
      fill="#CDD6E0"
    />
    <path
      d="M85.1893 26.2168H7.72266V31.8347H85.1893V26.2168Z"
      fill="#CDD6E0"
    />
    <path
      d="M85.1893 41.1978H7.72266V46.8156H85.1893V41.1978Z"
      fill="#CDD6E0"
    />
    <path
      d="M85.5778 56.8452H7.46631V62.3908H85.5778V56.8452Z"
      fill="#CDD6E0"
    />
    <path
      d="M85.5778 72.0352H7.46631V77.5808H85.5778V72.0352Z"
      fill="#CDD6E0"
    />
    <path
      d="M55.7592 87.2266H7.20996V92.7722H55.7592V87.2266Z"
      fill="#ACB3BA"
    />
    <path
      d="M55.7592 100.543H7.20996V106.089H55.7592V100.543Z"
      fill="#FF6E2C"
    />
    <path
      d="M109.116 104.642V105.124L103.828 114.768L103.347 115.492L102.867 115.01L97.5791 105.365V104.883C97.8195 101.99 97.8195 98.8552 97.5791 95.9618V95.7207H109.356V95.9618C108.875 98.855 108.875 101.748 109.116 104.642Z"
      fill="#FFD15C"
    />
    <path
      d="M103.347 104.884C104.277 104.884 105.03 104.128 105.03 103.196C105.03 102.264 104.277 101.508 103.347 101.508C102.418 101.508 101.665 102.264 101.665 103.196C101.665 104.128 102.418 104.884 103.347 104.884Z"
      fill="white"
    />
    <path
      d="M112 8.67992V25.5577H94.6953V8.67992C94.6953 8.43879 94.6953 7.95652 94.6953 7.71539C94.6953 6.99198 94.9357 6.26881 95.176 5.5454C96.3776 2.41112 99.5022 0 103.348 0C105.751 0 107.914 0.964539 109.596 2.65225C110.318 3.37565 111.038 4.5811 111.519 5.54564C111.76 6.26904 112 6.99221 112 7.71562C112 7.95675 112 8.43879 112 8.67992Z"
      fill="#54C0EB"
    />
    <path d="M112 25.5571H94.6953V92.8272H112V25.5571Z" fill="#84DBFF" />
    <path d="M112 61.0005H94.6953V80.2894H112V61.0005Z" fill="#54C0EB" />
    <path
      d="M110.558 92.8281H96.3779V95.4804H110.558V92.8281Z"
      fill="#40596B"
    />
    <path d="M112 10.1274H94.6953V12.2974H112V10.1274Z" fill="white" />
    <path
      d="M106.953 37.1308V8.19761C106.953 6.26877 105.27 4.58105 103.348 4.58105C101.425 4.58105 99.7427 6.26877 99.7427 8.19761V37.131H106.953V37.1308Z"
      fill="#40596B"
    />
    <path
      d="M58.2678 16.4316H55.7656C55.3314 16.4316 55.0005 16.0789 55.0005 15.664V8.25782C55.0005 7.82216 55.352 7.49023 55.7656 7.49023H58.2678C58.7021 7.49023 59.033 7.84291 59.033 8.25782V15.664C59.033 16.0996 58.7021 16.4316 58.2678 16.4316Z"
      fill="#FF6E2C"
    />
    <path
      d="M50.823 16.4316H48.3208C47.8865 16.4316 47.5557 16.0789 47.5557 15.664V8.25782C47.5557 7.82216 47.9072 7.49023 48.3208 7.49023H50.823C51.2573 7.49023 51.5882 7.84291 51.5882 8.25782V15.664C51.5882 16.0996 51.2573 16.4316 50.823 16.4316Z"
      fill="#FF6E2C"
    />
    <path
      d="M43.2748 19.7929H40.752C40.3177 19.7929 39.9868 19.4402 39.9868 19.0253V11.6191C39.9868 11.1835 40.3384 10.8516 40.752 10.8516H43.2542C43.6884 10.8516 44.0193 11.2042 44.0193 11.6191V19.0253C44.04 19.461 43.6884 19.7929 43.2748 19.7929Z"
      fill="#FF6E2C"
    />
    <path
      d="M35.9339 16.4316H33.4316C32.9974 16.4316 32.6665 16.0789 32.6665 15.664V8.25782C32.6665 7.82216 33.0181 7.49023 33.4316 7.49023H35.9339C36.3681 7.49023 36.699 7.84291 36.699 8.25782V15.664C36.7197 16.0996 36.3681 16.4316 35.9339 16.4316Z"
      fill="#FF6E2C"
    />
  </svg>
);

export default ClaimFormSvg;
