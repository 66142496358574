import React from "react";
import { Label, Input, Span, Slider } from "./styles";

type Props = {
  onChange: (Event) => void;
  checked?: boolean;
  disabled?: boolean;
};

const Switch = (props: Props) => (
  <Label>
    <Input
      type="checkbox"
      checked={props.checked}
      onChange={e => props.onChange(e.target.checked)}
      disabled={props.disabled === undefined ? false : props.disabled}
    />
    <Span className={Slider} />
  </Label>
);

export default Switch;
