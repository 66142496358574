import React from "react";
import { Task } from "../../types";
import CreateKit from "src/shared/TaskActions/CreateKit";

type Props = {
  task: Task;
};

const Content: React.FC<Props> = ({ task }) => {
  return <CreateKit patient={task.user} />;
};

export default Content;
