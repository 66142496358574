import React, { useState } from "react";
import styled from "react-emotion";

import ConfirmModal from "src/shared/ConfirmModal";

import OrderSummary from "./OrderSummary";
import InsuranceDetails from "./InsuranceDetails";
import PatientCharges from "./PatientCharges";

import color from "src/styles/color";
import { getOrderName } from "./util";
import {
  Order,
  Charge,
  Discount,
  Adjustment,
  InsuranceClaimsSubmission,
  Patient,
  DeleteRefund,
} from "src/types/gql";
import { useOrderPaymentPlan, useDeletePaymentPlan } from "./query";
import RefundModal from "./Refund";

import DeleteModal from "./Modals/DeleteModal";
import ChargeModal from "./Modals/ChargeModal";
import ApplyPromoModal from "./Modals/ApplyPromoModal";
import ReopenOrderModal from "./Modals/ReopenOrderModal";
import RemovePromoModal from "./Modals/RemovePromoModal";
import SwitchPaymentMethod from "./Modals/SwitchPaymentMethod";
import MarkdownModal from "./Modals/MarkdownModal";
import RemoveMarkdownModal from "./Modals/RemoveMarkdownModal";
import RecordInsurancePaymentModal from "./Modals/RecordInsurancePaymentModal";
import RecordInsuranceClaimModal from "./Modals/RecordInsuranceClaimModal";
import EditInsuranceClaimModal from "./Modals/EditInsuranceClaimModal";
import ChargeInsuranceCardModal from "./Modals/ChargeInsuranceCardModal";
import EditCareCreditPaymentModal from "./Modals/EditCareCreditPaymentModal";
import AddFinancingModal from "./Modals/AddFinancingModal";
import RefundCheckModal from "./Modals/RefundCheckModal";
import RemoveRefundModal from "./Modals/RemoveRefundModal";
import RecordPaymentModal from "./Modals/RecordPaymentModal";
import DeletePaymentRecordModal from "./Modals/DeletePaymentRecordModal";

import OrderSettings from "./OrderSettings/OrderSettings";

type Props = {
  patient: Patient;
  order: Order;
  refetchOrders?: () => void;
};

const Wrapper = styled.div`
  border-top: 1px solid ${color.gray3};
  border-bottom: 1px solid ${color.gray3};
  background: ${color.background};
  padding: 24px 30px 36px;

  h5 {
    margin: 6px 0;
  }
`;

const Header = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
`;

const ID = styled.div`
  color: ${color.gray3};
  margin-bottom: 7px;
`;

const PaymentPlanButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: ${color.red};
  font-weight: 600;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

type Modal =
  | { type: "none" }
  | { type: "delete-financing" }
  | { type: "cancelOrder" }
  | { type: "newCharge" }
  | { type: "promo" }
  | { type: "reopen" }
  | { type: "switchPaymentMethod" }
  | { type: "markdown" }
  | { type: "removePromo"; discount: Discount }
  | { type: "removeMarkdown"; adjustment: Adjustment }
  | { type: "refund"; charge: Charge }
  | { type: "editCareCredit"; charge: Charge }
  | { type: "recordInsurancePayment" }
  | { type: "recordInsuranceClaim" }
  | { type: "chargeInsuranceCard" }
  | { type: "addPaymentPlan" }
  | { type: "editInsuranceClaim"; claim: InsuranceClaimsSubmission }
  | { type: "refundCheck" }
  | { type: "removeRefund"; deleteRefund: DeleteRefund }
  | { type: "recordPayment" }
  | { type: "deletePaymentRecord"; charge: Charge };

const ExpandedOrder: React.FC<Props> = ({ order, patient, refetchOrders }) => {
  const [modal, setModal] = useState<Modal>({ type: "none" });
  const [, , pp] = useOrderPaymentPlan(patient.id, order.id);
  const deletePP = useDeletePaymentPlan();

  const onClose = () => setModalType({ type: "none" });

  const modalUI = (() => {
    switch (modal.type) {
      case "cancelOrder":
        return (
          <DeleteModal
            patientID={order.user.id}
            orderID={order.id}
            onClose={onClose}
          />
        );
      case "newCharge":
        return (
          <ChargeModal patient={patient} order={order} onClose={onClose} />
        );
      case "refund":
        return (
          <RefundModal
            onClose={onClose}
            patient={patient}
            order={order}
            charge={modal.charge}
          />
        );
      case "editCareCredit":
        return (
          <EditCareCreditPaymentModal
            onClose={onClose}
            charge={modal.charge}
            patientID={patient.id}
          />
        );
      case "delete-financing":
        return (
          <ConfirmModal
            type="delete"
            title="Are you sure?"
            text="Do you want to stop this in house financing plan?"
            actionText="Stop"
            onClose={onClose}
            onConfirm={() => {
              pp && deletePP({ id: pp.id });
              setModal({ type: "none" });
            }}
          />
        );
      case "promo":
        return <ApplyPromoModal orderID={order.id} onClose={onClose} />;
      case "reopen":
        return <ReopenOrderModal orderID={order.id} onClose={onClose} />;
      case "removePromo":
        return (
          <RemovePromoModal
            onClose={onClose}
            discount={modal.discount}
            patientID={patient.id}
          />
        );
      case "removeMarkdown":
        return (
          <RemoveMarkdownModal
            onClose={onClose}
            adjustment={modal.adjustment}
            patientID={patient.id}
          />
        );
      case "switchPaymentMethod":
        return (
          <SwitchPaymentMethod
            onClose={onClose}
            paymentPlan={pp}
            patientID={patient.id}
          />
        );
      case "markdown":
        return <MarkdownModal onClose={onClose} order={order} />;
      case "recordInsurancePayment":
        return (
          <RecordInsurancePaymentModal
            onClose={onClose}
            order={order}
            patientID={patient.id}
          />
        );
      case "recordInsuranceClaim":
        return (
          <RecordInsuranceClaimModal
            onClose={onClose}
            orderID={order.id}
            patientID={patient.id}
          />
        );
      case "editInsuranceClaim":
        return (
          <EditInsuranceClaimModal
            onClose={onClose}
            claim={modal.claim}
            patientID={patient.id}
          />
        );
      case "chargeInsuranceCard":
        return (
          <ChargeInsuranceCardModal
            orderID={order.id}
            patientID={patient.id}
            onClose={onClose}
            claims={order.claims}
          />
        );
      case "addPaymentPlan":
        return <AddFinancingModal onClose={onClose} order={order} />;
      case "refundCheck":
        return (
          <RefundCheckModal
            orderID={order.id}
            onClose={onClose}
            refetchOrders={refetchOrders}
          />
        );
      case "removeRefund":
        return (
          <RemoveRefundModal
            refund={modal.deleteRefund}
            onClose={onClose}
            refetchOrders={refetchOrders}
          />
        );
      case "recordPayment":
        return (
          <RecordPaymentModal
            orderID={order.id}
            onClose={onClose}
            refetchOrders={refetchOrders}
          />
        );
      case "deletePaymentRecord":
        return (
          <DeletePaymentRecordModal
            onClose={onClose}
            refetchOrders={refetchOrders}
            charge={modal.charge}
          />
        );
      default:
        return null;
    }
  })();

  const setModalType = type => setModal({ type });

  return (
    <Wrapper>
      {modalUI}
      <Header>
        <div>
          <h5>{getOrderName(order)}</h5>
          <ID>Order #{order.id}</ID>
          {pp && (
            <div>
              This patient is on a payment plan.{" "}
              <PaymentPlanButton
                onClick={() => {
                  setModal({ type: "delete-financing" });
                }}
              >
                Cancel plan
              </PaymentPlanButton>
            </div>
          )}
        </div>

        <OrderSettings
          patient={patient}
          order={order}
          setModalType={setModalType}
          isPaymentPlan={Boolean(pp)}
        />
      </Header>

      <OrderSummary order={order} setModal={setModal} paymentPlan={pp} />
      <InsuranceDetails order={order} />
      <PatientCharges
        patient={patient}
        order={order}
        paymentPlan={pp}
        setModal={setModal}
      />
    </Wrapper>
  );
};

type ClaimProps = {
  claim: InsuranceClaimsSubmission;
};

export default ExpandedOrder;
