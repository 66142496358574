import React from "react";
import styled, { css } from "react-emotion";
import Avatar from "src/shared/Avatar";
import HoverClear from "src/shared/Icons/HoverClear";
import { Staff } from "src/types/gql";

type Props = {
  staff: Staff;
  onClick?: (s: Staff) => void;
  hideName?: boolean;
};

const Assigned: React.FC<Props> = props => {
  return props.hideName ? (
    <AssignedWithoutName {...props} />
  ) : (
    <AssignedWithName {...props} />
  );
};

export default Assigned;

const AssignedWithName: React.FC<Props> = ({ staff, onClick, hideName }) => (
  <Wrapper>
    <Avatar name={staff.name} style={{ marginRight: hideName ? 0 : 10 }} />
    {staff.name}
    {onClick && (
      <HoverClear
        className={nameClearCSS}
        onClick={onClick ? () => onClick(staff) : undefined}
      />
    )}
  </Wrapper>
);

const AssignedWithoutName: React.FC<Props> = ({ staff, onClick, hideName }) => (
  <Wrapper>
    <Avatar name={staff.name} style={{ marginRight: hideName ? 0 : 10 }} />
    {onClick && (
      <HoverClear
        className={avatarClearCSS}
        onClick={
          onClick
            ? () => {
                onClick(staff);
              }
            : undefined
        }
      />
    )}
  </Wrapper>
);

const avatarClearCSS = css`
  position: absolute;
  top: -5px;
  right: 0;
  cursor: pointer;
`;

const nameClearCSS = css`
  float: right;
  margin: 5px 0 0 10px;
  cursor: pointer;
`;

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  padding: 0 7px;
  margin: 5px 0;

  .${avatarClearCSS}, .${nameClearCSS} {
    opacity: 0.4;
    transition: all 0.3s;
  }

  &:hover .${avatarClearCSS}, &:hover .${nameClearCSS} {
    opacity: 1;
    transition: all 0.3s;
  }
`;
