import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { VerifiedSpecialist } from "src/types/gql";
import color from "src/styles/color";
import { getReadableType } from "src/utils/externalProvider";

const TOKEN =
  "pk.eyJ1IjoidW5pZm9ybXRlZXRoIiwiYSI6ImNrZmZ0aWZneTA3cm8yeW5sbHM5c2x4dDAifQ.zPWwb-XB4AABGuKNFZvQzg";

const DEFAULT_MAPBOX_STYLE = "mapbox://styles/mapbox/streets-v11";

type Props = {
  defaultLat: number;
  defaultLong: number;
  defaultZoom: number;
  specialists: Array<VerifiedSpecialist>;
  selectedSpecialistID: string | undefined;
  setSelectedSpecialistID: (string) => void;
};

const getSpecialistInfoDiv = specialist =>
  `<div style="display:flex; flex-flow:column; min-width: 300px;">
    <div style="font-weight: 600; font-size: 14px">${specialist.firstName} ${
    specialist.lastName
  }</div>
    <div>${specialist.clinicName}</div>
    <div style="color: ${color.gray3}">${getReadableType(specialist.type)}</div>
    <div>Phone: ${specialist.phone}</div>
    <div>${specialist.address.line1} ${specialist.address.line2}</div>
    <div>${specialist.address.city}, ${specialist.address.state} ${
    specialist.address.postalCode
  }</div>
  </div>`;

const Mapbox: React.FC<Props> = ({
  defaultLat,
  defaultLong,
  defaultZoom,
  specialists = [],
  selectedSpecialistID,
  setSelectedSpecialistID,
}) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState<any>([]);

  useEffect(() => {
    // wait until mapbox container is initialized
    if (!mapContainer.current) {
      return;
    }

    // set our api token
    mapboxgl.accessToken = TOKEN;

    // initialize mapbox
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: DEFAULT_MAPBOX_STYLE,
      center: [defaultLong, defaultLat],
      zoom: defaultZoom,
    });

    setMap(map);

    map.addControl(
      new MapboxGeocoder({
        accessToken: TOKEN,
        mapboxgl: mapboxgl,
        zoom: defaultZoom,
      })
    );
  }, [defaultLat, defaultLong, defaultZoom, mapContainer]);

  useEffect(() => {
    if (!map) return;

    // remove existing markers on map
    markers.forEach(marker => marker.remove());

    // draw new markers
    const newMarkers = specialists.map((specialist: VerifiedSpecialist) => {
      const specialistInfoPopup = new mapboxgl.Popup().setHTML(
        getSpecialistInfoDiv(specialist)
      );

      specialistInfoPopup.on("open", () => {
        setSelectedSpecialistID(specialist.id);
      });

      const preferredProviderColor = specialist.preferredProvider
        ? color.blue
        : color.gray4;

      const marker = new mapboxgl.Marker({
        color:
          specialist.id === selectedSpecialistID
            ? color.red
            : preferredProviderColor,
      })
        .setLngLat([specialist.address.longitude, specialist.address.latitude])
        .setPopup(specialistInfoPopup)
        .addTo(map);

      if (specialist.id === selectedSpecialistID) {
        // @ts-ignore null check for map above
        map.easeTo({
          center: [specialist.address.longitude, specialist.address.latitude],
        });

        // on initial click, this component will cause selectedSpecialistID
        // to be updated, which re-draws all the markers on the map, and this
        // re-drawing will clear the popup.
        // we want to simulate another click so that it will display the popup
        marker.getElement().click();
      }
      return marker;
    });

    setMarkers(newMarkers);
    // eslint-disable-next-line
  }, [specialists, map, selectedSpecialistID]);

  return <div style={{ flex: 2 }} ref={mapContainer} />;
};

export default Mapbox;
