// @flow
import React from "react";
import { browserHistory } from "react-router";
import { compose, lifecycle, withState } from "recompose";
import { message } from "antd";

import { FormsEnhancer } from "src/state/Forms";
import { ToothEnhancer } from "src/state/Tooth";
import { WithEnhancers } from "src/shared/hocs";
import Generic from "./Submission/Generic";
import BeginningVisit from "./Submission/BeginningVisit";
import TreatmentSetup from "./Submission/TreatmentSetup";
import FollowupVisit from "./Submission/FollowupVisit";
import ChildSubmission from "./Submission/ChildSubmission";

// type PassedProps = {
//   form: Form,
//   patient: Patient,
//   submission: Submission,
// };

// type ModifierProps = {
//   // This allows us to save answers to local state for display predicates prior
//   // receiving the answer XHR response.
//   sessionAnswers: Array<Answer>,
//   setSessionAnswers: (Array<Answer>) => Array<Answer>,

//   toothData: FormToothData,
//   setToothData: FormToothData => FormToothData,
// };

// type Props = FormProps & PassedProps;

const modifiers = compose(
  // Get pre-existing tooth information for the tooth chart.
  ...WithEnhancers(ToothEnhancer, FormsEnhancer),
  // Stores all answers from the current session.
  // We merge this with submission answers so that display and choice predicates
  // work can work as expected.
  withState("sessionAnswers", "setSessionAnswers", []),
  withState("toothData", "setToothData", props => {
    return props.submission.tooth_data || [];
  }),

  lifecycle({
    componentWillMount() {
      this.props.actions.getTeeth(this.props.patient.id);
    },
    // Reset session answers each time the submission ID changes
    componentWillReceiveProps(next) {
      if (next.submission.id !== this.props.submission.id) {
        this.props.setSessionAnswers([]);
        this.props.setToothData(next.submission.tooth_data || []);
      }
    },
  })
);

/**
 * ViewSubmission renders all form questions with any answers that currently exist.
 *
 * The Form wrapper loads all submissions for the form, though this API response
 * lists a "short" version of submissions - ie. no answers.
 *
 * TODO: Webhook subscriptions to fetch answers from the team in real time.
 */
function ViewSubmission(props) {
  // TODO:  Store the local question answers
  const onAnswer = async (questionId, answer) => {
    const {
      submission,
      actions,
      form,
      sessionAnswers,
      setSessionAnswers,
    } = props;

    // By saving answers to local state before posting we can immediately use
    // these for display predicates.  The alternative means waiting for the save
    // HTTP request to complete, ie. lag between choosing an answer and displaying
    // questions based off of your answer.
    setSessionAnswers(
      sessionAnswers
        .filter(a => a.question_id !== questionId)
        .concat([
          {
            question_id: questionId,
            answer: answer,
          },
        ])
    );

    const result = await actions.postAnswer(submission.id, questionId, answer);

    if (result.errors) {
      message.error(JSON.stringify(result.errors));
      return;
    }

    // Remove the answers from session answers if this is a success.  The above
    // local state only stores the question id and answer, so we lose out on version
    // history.
    setSessionAnswers(sessionAnswers.filter(a => a.question_id !== questionId));

    // Get the question title for the success message.
    const q = form.forms_questions.find(q => q.id === questionId);
    if (q) {
      message.success(`'${q.question_text}' saved`);
    }
  };

  const onSubmit = async () => {
    const { actions, submission } = props;
    const hide = message.loading("Submitting response", 0);

    // Wait 1.5 seconds so that the onBlur of any input is called and data is saved.
    // TODO: Each time an input has changed, move it into a queue of answers to save.  When
    // the answer is made, remove it from the queue.  Then, when submitting a form, wait
    // until the queue is empty before saving.
    window.setTimeout(async () => {
      // When submitting a form send across tooth data.
      const result = await actions.submitSubmission(
        submission.id,
        props.toothData
      );
      hide();
      if (result.errors && result.errors.length > 0) {
        return message.error(result.errors.join(", "));
      }
      message.success("Response submitted");
      const isTreatmentSetup = submission.form_slug === "treatment_setup";
      if (!isTreatmentSetup) {
        browserHistory.push("/tasks");
      }
    }, 1500);
  };

  const onApprove = async () => {
    const { actions, submission } = props;
    const hide = message.loading("Approving response", 0);

    // Wait 1.5 seconds so that the onBlur of any input is called and data is saved.
    // TODO: Each time an input has changed, move it into a queue of answers to save.  When
    // the answer is made, remove it from the queue.  Then, when submitting a form, wait
    // until the queue is empty before saving.
    window.setTimeout(async () => {
      // When submitting a form send across tooth data.
      const result = await actions.approveSubmission(
        submission.id,
        props.toothData
      );
      hide();
      if (result.errors && result.errors.length > 0) {
        return message.error(result.errors.join(", "));
      }
      message.success("Approved");
      const isTreatmentSetup = submission.form_slug === "treatment_setup";
      if (!isTreatmentSetup) {
        browserHistory.push("/tasks");
      }
    }, 1500);
  };

  const onUnsubmit = async () => {
    const { actions, submission } = props;
    const hide = message.loading("Unsubmitting response", 0);

    // Wait 1.5 seconds so that the onBlur of any input is called and data is saved.
    // TODO: Each time an input has changed, move it into a queue of answers to save.  When
    // the answer is made, remove it from the queue.  Then, when submitting a form, wait
    // until the queue is empty before saving.
    window.setTimeout(async () => {
      // When submitting a form send across tooth data.
      const result = await actions.unsubmitSubmission(submission.id);
      hide();
      if (result.errors && result.errors.length > 0) {
        return message.error(result.errors.join(", "));
      }
      message.success("Response unsubmitted");
    }, 1500);
  };

  const onToothChartUpdateAsync = async toothData => {
    if (toothData === props.toothData) {
      return;
    }

    props.setToothData(toothData);
    await onSaveUpdates(toothData);
  };

  const onSaveUpdates = async toothData => {
    const { actions, submission } = props;
    const result = await actions.updateSubmission(submission.id, {
      tooth_data: toothData,
    });

    if (
      (result.errors && result.errors.length > 0) ||
      (result.status && result.status !== 200)
    ) {
      const error = result.errors || [result.error];
      return message.error(error && error.join(", "));
    }
  };

  const C = (() => {
    switch (props.form.slug) {
      case "beginning_visit":
        return BeginningVisit;
      case "treatment_setup":
        return TreatmentSetup;
      case "followup_visit":
        return FollowupVisit;
      case "treatment_option":
        return ChildSubmission;
      case "referral":
        if (props.submission.parent_id === null) {
          return Generic;
        }
        return ChildSubmission;
      default:
        return Generic;
    }
  })();

  const isSubmitted = !!props.submission.submitted_at;

  return (
    <C
      {...props}
      onSubmit={onSubmit}
      onUnsubmit={onUnsubmit}
      onApprove={onApprove}
      onToothChartUpdateAsync={onToothChartUpdateAsync}
      onAnswer={onAnswer}
      editable={!isSubmitted}
    />
  );
}

export default modifiers(ViewSubmission);
