import styled from "react-emotion";

export const Wrapper = styled("div")`
  background: #fff;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-weight: normal;
    font-size: 2rem;
    color: #aaa;
    padding-top: 25px;
  }

  p {
    color: #aaa;
  }
`;
