import { useState, useEffect } from "react";
import { request } from "src/shared/util";
import { InternalNote } from "src/types/api";

type State = {
  fetching: boolean;
  error: boolean;
  notes: Array<InternalNote>;
};

// useInternalNotes is a hook which returns all internal notes for a given model, plus
// a function for adding internal notes to a given model.
//
// It returns a function used to add internal notes as we insert the note into state
// automatically without refetching.
//
// XXX: This should be updated with a GraphQL endpoint.
const useInternalNotes = (kind: string, id: string) => {
  // TODO: Move internal notes into graphql
  const [state, setState] = useState<State>({
    fetching: false,
    error: false,
    notes: [],
  });

  const fetch = async () => {
    setState({ fetching: true, error: false, notes: [] });
    const resp = await request(
      `/api/v1/internal_notes/?model_type=${kind}&model_id=${id}`
    );

    if (!resp || !Array.isArray(resp)) {
      setState({
        fetching: false,
        error: true,
        notes: [],
      });
      return;
    }

    setState({
      fetching: false,
      error: false,
      notes: resp,
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [kind, id]);

  const onCreate = async (text: string) => {
    const result = await request(`/api/v1/internal_notes`, {
      method: "POST",
      body: JSON.stringify({
        model_type: kind,
        model_id: id,
        text,
      }),
    });

    if (result && result.id) {
      setState({ ...state, notes: [result].concat(state.notes) });
    }

    return result;
  };

  return { ...state, onCreate };
};

export default useInternalNotes;
