import { capitalize } from "src/helpers/utils";
import { routerShape } from "react-router/lib/PropTypes";
import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styles from "./event-card-style";
import { TRANSLATED_FORM_NAME } from "src/config/treatment-forms/index";
import { InternalNotesEnhancer } from "src/state/InternalNote";
import {
  STATUS,
  COMMENT,
  FILE,
  QUESTION_FORM,
  ISSUE_LIST_FORM,
  NOTE,
  SYSTEM_EVENT,
} from "src/config/event-type";

const enhance = InternalNotesEnhancer;
class EventCard extends Component {
  static contextTypes = {
    router: routerShape,
    patient: PropTypes.object,
  };
  static propTypes = {
    linkToSelfForm: PropTypes.bool,
    event: PropTypes.object,
    inEventStream: PropTypes.bool,
  };

  onClick = () => {
    if (this.props.linkToSelfForm) {
      this.context.router.push(
        `/treatment/${this.context.patient.id}/form/${
          this.props.event.formName
        }/${this.props.event.uuid}`
      );
    }
  };

  handlePinNote = e => {
    e.stopPropagation();
    // If we have a "pinned_at" time, remove it.
    const copy = Object.assign({}, this.props.event.data);
    copy.pinned_at = copy.pinned_at === null ? new Date() : null;
    this.props.actions.updateInternalNote(copy);
  };

  renderTitleRight = () => {
    const { event } = this.props;
    if (!event.postTimestamp) return null;

    return (
      <span style={styles.titleRight}>
        {event.postUserName}
        <span style={styles.S.verticalDivider} />
        {moment(event.postTimestamp).format("MM/DD/YY, h:mm a")}
      </span>
    );
  };

  render() {
    const { event, linkToSelfForm, children, inEventStream } = this.props;
    const linkToSelfFormStyle = linkToSelfForm ? styles.linkToSelfForm : {};
    const statusStyle = event.type === STATUS ? styles.status : {};
    const systemEventStyle =
      event.type === SYSTEM_EVENT ? styles.systemEvent : {};

    const patientCommentStyle =
      event.type === COMMENT && event.isPatient ? styles.patientComment : {};
    const fileStyle = event.type === FILE ? styles.file : {};
    const formStyle =
      [QUESTION_FORM, ISSUE_LIST_FORM].includes(event.type) && inEventStream
        ? styles.form
        : {};
    let staffCommentStyle =
      event.type === COMMENT && !event.isPatient ? styles.staffComment : {};
    if (event.type === COMMENT && !event.isPublic) {
      staffCommentStyle = styles.privateStaffComment;
    }
    return (
      <div
        style={{
          ...styles.eventCard,
          ...linkToSelfFormStyle,
          ...statusStyle,
          ...systemEventStyle,
          ...patientCommentStyle,
          ...staffCommentStyle,
          ...fileStyle,
          ...formStyle,
          ...this.props.style,
        }}
        onClick={this.onClick}
      >
        <div
          style={
            children ? styles.S.sectionTitle : styles.S.sectionTitleNoChildren
          }
        >
          {this.renderTitleRight()}
          <span style={styles.title} className="test-event-title">
            {capitalize(
              (event.formName && TRANSLATED_FORM_NAME[event.formName]) ||
                event.name ||
                event.type
            )}
            {event.formName && event.complete === false && (
              <span style={styles.incomplete}>Started - not submitted</span>
            )}
          </span>
          {event.type === NOTE && event.uuid && (
            <span style={styles.pin} onClick={this.handlePinNote}>
              <img src="/static/pin.svg" alt="pin" /> {event.pinned && "Pinned"}
            </span>
          )}
          {event.unseen && <span style={styles.unseen}>unseen</span>}
        </div>
        <div>{children}</div>
      </div>
    );
  }
}

export default enhance(EventCard);
