import React from "react";
import { Link } from "react-router";
import { DateTime } from "luxon";
import { User, Staff } from "src/types/gql";
import { getWeeksDistToISODateString } from "src/utils/dates";
import Row from "./Row";
import PipelineType from "../PipelineType";
import PipelineProgress from "../Shared/PipelineProgress";
import { Pipeline } from "../types";
import Assigned from "../Shared/Assigned";

type Props = {
  urlPrefix?: string;
  pipeline: Pipeline;
  routeFilter: string;
};

const PipelineRow: React.FC<Props> = ({ pipeline, routeFilter, urlPrefix }) => {
  // TODO: Progress: tasks complete vs tasks incomplete vs tasks in progress
  const assignees = ((pipeline.unblockedTasks || [])
    .map(t => t.assignedTo)
    .flat()
    .reduce<User[]>((acc, item) => {
      return acc.find(i => i.id === item.id) ? acc : acc.concat([item]);
    }, []) as unknown) as Staff[];

  const prefix = urlPrefix || `/pipelines/${routeFilter}`;
  const weeks = getWeeksDistToISODateString(pipeline.createdAt) + 1;

  return (
    <Link to={`${prefix}/${pipeline.id}`}>
      <Row>
        <div>{pipeline.user.name}</div>
        <div>{pipeline.user.homeClinic && pipeline.user.homeClinic.name}</div>
        <div>
          <PipelineType type={pipeline.type} />
        </div>
        <div>
          {assignees.map(a => (
            <Assigned key={a.id} staff={a} hideName />
          ))}
        </div>
        <div>{DateTime.fromISO(pipeline.createdAt).toLocaleString()}</div>
        <div>{weeks}</div>
        <div>
          <PipelineProgress pipeline={pipeline} />
        </div>
      </Row>
    </Link>
  );
};

export default PipelineRow;
