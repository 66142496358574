import React from "react";
import isString from "lodash/isString";
import isNil from "lodash/isNil";
import identity from "lodash/identity";
import { cx } from "react-emotion";
import { Popover, Tooltip } from "antd";

import ConfirmModal from "src/shared/ConfirmModal";

import { FiltersItemProps } from "./types";
import {
  Item,
  ItemData,
  ItemActions,
  ItemTitle,
  ItemMeta,
  MoreButton,
  MoreIcon,
  MoreActionsMenu,
  ItemAction,
  SaveButton,
  popupCss,
  Input,
} from "./styles";
import CheckIcon from "../../../Icons/Check";
import SaveIcon from "../../../Icons/Save";
import { useFilterItemSetup, useFiltersItemClick } from "./hooks";

const FiltersItem: React.FC<FiltersItemProps> = props => {
  const { id, sharedBy, title, date, state } = props;
  const {
    actions,
    componentState,
    titleInputRef,
    titleInputValue,
    handleInputChange,
    handleActionClick,
  } = useFilterItemSetup(props);
  const {
    filterClickDeadzoneRef,
    handleFiltersItemClick,
  } = useFiltersItemClick(props);

  const isNewFilter = isNil(id);
  const metaDataText = isString(sharedBy)
    ? `Shared by ${sharedBy} - ${date}`
    : date;
  const shouldDisplayInput = ["typing", "confirmingReplace"].includes(
    componentState
  );

  const handleSaveClick = () => handleActionClick("save");
  const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  return (
    <Item className={state} onClick={handleFiltersItemClick}>
      <ItemData>
        {shouldDisplayInput ? (
          <div ref={titleInputRef as React.RefObject<HTMLDivElement>}>
            <Input
              autoFocus
              value={titleInputValue}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          </div>
        ) : (
          <ItemTitle className={cx([isNewFilter && "unsaved", state])}>
            {title}
          </ItemTitle>
        )}
        <ItemMeta>{metaDataText}</ItemMeta>
      </ItemData>
      <ItemActions>
        {state === "selected" && <CheckIcon />}
        {isNewFilter ? (
          <Tooltip placement="bottom" title="Save Filter">
            <div ref={filterClickDeadzoneRef}>
              <SaveButton type="invisible" onClick={handleSaveClick}>
                <SaveIcon />
              </SaveButton>
            </div>
          </Tooltip>
        ) : (
          <Popover
            arrowPointAtCenter={false}
            trigger="click"
            placement="rightTop"
            content={
              <MoreActionsMenu>
                {actions.map(({ key, title, className }) => (
                  <li key={key}>
                    <ItemAction
                      className={className}
                      onClick={handleActionClick.bind(null, key)}
                    >
                      {title}
                    </ItemAction>
                  </li>
                ))}
              </MoreActionsMenu>
            }
            overlayClassName={popupCss}
            getPopupContainer={identity}
          >
            <div ref={filterClickDeadzoneRef}>
              <MoreButton type="invisible">
                <MoreIcon />
              </MoreButton>
            </div>
          </Popover>
        )}
      </ItemActions>

      {/* TODO to think: may be move this modal and logic to FiltersContext? */}
      {/* if no then just remove this comment */}
      {componentState === "confirmingReplace" && (
        <ConfirmModal
          type="warn"
          title="Replace existing filter?"
          text={`'${titleInputValue}' already exists. Replacing it will overwrite its existing contents.`}
          actionText="Replace"
          onClose={() => handleActionClick("cancel")}
          onConfirm={() => handleActionClick("confirm")}
        />
      )}
    </Item>
  );
};

export default FiltersItem;
