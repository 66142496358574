import {
  ToothLocation,
  Attribute,
  DisplayType,
  AttributeType,
  ToothNumber,
} from "./types";
import { isLeft, universalMapping, getPalmer } from "./consts";

export const attributesToV1 = (data: Array<Attribute>): V1[] => {
  return data.map(toV1);
};

export const attributesToV2 = (data: Array<V1 | Attribute>): Attribute[] => {
  return data.map(toV2);
};

// versioning provides legacy (v1) <> v2 data conversion utilities.
export const toV2 = (data: V1 | Attribute): Attribute => {
  if (isAttribute(data)) {
    return data;
  }

  const toothNumber = universalMapping[data.tooth_name] || 1;

  const location = (() => {
    if (!data.entry_data || !data.entry_data.position) {
      return;
    }
    return proximalToMesial(toothNumber, data.entry_data.position);
  })();

  // It is unclear what data is used for vs amount
  return {
    type: data.entry_type,
    stage: data.entry_stage,
    toothNumber,
    location,
    data: data && data.entry_data && data.entry_data.amount,
    amount:
      data && data.entry_data && data.entry_data.amount
        ? parseFloat(data.entry_data.amount)
        : undefined,
  };
};

export const toV1 = (data: Attribute): V1 => {
  return {
    tooth_name: getPalmer(data.toothNumber),
    entry_stage: data.stage,
    entry_type: data.type,
    entry_data: {
      amount: data.data && data.data,
      position: data.location, // do NOT backport mesial/distal to "left proximal"/"right proximal"
    },
  };
};

export const isAttribute = (data: V1 | Attribute): data is Attribute => {
  return (data as Attribute).type !== undefined;
};

export const isV1 = (data: V1 | Attribute): data is V1 => {
  return (data as V1).entry_type !== undefined;
};

type V1 = {
  entry_data?: {
    amount?: string;
    position?: string;
  };
  entry_type: AttributeType;
  tooth_name: string;
  entry_stage: DisplayType;
};

const proximalToMesial = (
  tooth: ToothNumber,
  position: string
): ToothLocation => {
  if (isLeft(tooth) && position === "left proximal") {
    return "distal";
  }
  if (isLeft(tooth) && position === "right proximal") {
    return "mesial";
  }
  if (position === "left proximal") {
    return "mesial";
  }
  if (position === "right proximal") {
    return "distal";
  }

  return position as ToothLocation;
};
