import React, { useState, useEffect } from "react";
import { Checkbox } from "antd";

import Button, { ButtonRow } from "src/shared/Button";
import InputText from "src/shared/InputText";
import { Mode } from "src/types/local";
import ExternalProviderStateStatus from "src/scenes/Patient/ExternalCare/ExternalProviderStateStatus/ExternalProviderStateStatus";

import { PreferredProviderIcon } from "../icons";
import VerifiedSpecialistSummary from "../VerifiedSpecialistSummary/VerifiedSpecialistSummary";
import {
  ContentWrapper,
  Content,
  LeftMenu,
  SearchBar,
  SearchResults,
  ResultsMeta,
  Footer,
  AddProviderButton,
} from "../styles";
import TypeFilter from "../TypeFilter";
import RightPanel from "../RightPanel";
import { ProviderContentProps } from "./types";

const ProviderContent: React.FC<ProviderContentProps> = ({
  mapView,
  defaultFilterPreferredProviders,
  defaultLat,
  defaultLong,
  pageMeta,
  verifiedSpecialists,
  onChange,
  onClose,
  onSubmit,
}) => {
  const [
    shouldFilterPreferredProviders,
    setShouldFilterPreferredProviders,
  ] = useState(defaultFilterPreferredProviders);
  const [search, setSearch] = useState("");
  const [typeFilter, setTypeFilter] = useState("all");
  const [mode, setMode] = useState<Mode>("view");
  const [selectedSpecialistID, setSelectedSpecialistID] = useState<
    null | string
  >(null);
  const [mapViewToggle, setMapView] = useState(mapView);
  const [patientProviderState, setPatientProviderState] = useState("");

  const selectedSpecialist =
    (verifiedSpecialists || []).find(
      specialist => specialist.id === selectedSpecialistID
    ) || null;
  const specialistsWithLatLong = (verifiedSpecialists || []).filter(
    ({ address }) => address && address.latitude && address.longitude
  );

  const handleSpecialistClick = (specialist: { id: string | null }) => () => {
    if (selectedSpecialist && specialist.id === selectedSpecialistID) return;

    setSelectedSpecialistID(specialist.id);
    setMode("view");
  };
  const handleAddProviderClick = () => {
    setMode("add");
    setSelectedSpecialistID(null);
    setMapView(false);
  };
  const handleSubmit = () => {
    onSubmit({
      selectedSpecialist,
      patientProviderState,
    });
  };

  useEffect(() => {
    onChange({
      search,
      typeFilter,
      shouldFilterPreferredProviders,
    });
  }, [search, typeFilter, shouldFilterPreferredProviders, onChange]);

  return (
    <ContentWrapper>
      <Content>
        <LeftMenu>
          <SearchBar>
            <InputText
              placeholder="Enter provider or clinic name"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            {mapViewToggle && (
              <TypeFilter onChange={setTypeFilter} value={typeFilter} />
            )}
            <div style={{ marginTop: 5 }}>
              <Checkbox
                checked={shouldFilterPreferredProviders}
                onChange={() => {
                  setShouldFilterPreferredProviders(state => !state);
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <div>Only show preferred providers</div>
                  <div style={{ marginTop: 5, transform: "scale(0.8,0.8)" }}>
                    <PreferredProviderIcon />
                  </div>
                </div>
              </Checkbox>
            </div>
            <div
              style={{
                display: "flex",
                paddingTop: 8,
                alignItems: "center",
              }}
            >
              Don't see your provider?{" "}
              <AddProviderButton onClick={handleAddProviderClick}>
                Add a new provider.
              </AddProviderButton>
            </div>
          </SearchBar>
          <SearchResults>
            <ResultsMeta>
              <b>
                {pageMeta.totalItems}{" "}
                {pageMeta.totalItems > 1 ? "results" : "result"}
              </b>
              {pageMeta.totalPages > 1 && (
                <b>, first {pageMeta.perPage} shown</b>
              )}
            </ResultsMeta>
            {verifiedSpecialists &&
              verifiedSpecialists.map(s => (
                <VerifiedSpecialistSummary
                  onClick={handleSpecialistClick(s)}
                  {...s}
                  key={s.id}
                  isSelected={selectedSpecialistID === s.id}
                />
              ))}
          </SearchResults>
        </LeftMenu>
        <RightPanel
          mapView={mapViewToggle}
          specialistsWithLatLong={specialistsWithLatLong}
          defaultLat={defaultLat}
          defaultLong={defaultLong}
          selectedSpecialist={selectedSpecialist}
          mode={mode}
          setMode={setMode}
          search={search}
          setSelectedSpecialistID={setSelectedSpecialistID}
        />
      </Content>
      <Footer>
        <ButtonRow position="right">
          {selectedSpecialist && (
            <ExternalProviderStateStatus
              state={patientProviderState}
              updateState={setPatientProviderState}
            />
          )}
          <Button kind="default" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!selectedSpecialist}
            kind="primary"
          >
            Select
          </Button>
        </ButtonRow>
      </Footer>
    </ContentWrapper>
  );
};

export default ProviderContent;
