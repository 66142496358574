import React, { useState } from "react";
import styled from "react-emotion";
import Box from "src/shared/Box";
import Button, { ButtonRow } from "src/shared/Button";
import InputText from "src/shared/InputText";

type Props = {
  onCancel: () => void;
  onSubmit: (cadence: number) => void;
  cadence: number;
};

const EditCadence: React.SFC<Props> = props => {
  const [cadence, setCadence] = useState(props.cadence);
  return (
    <Box title="Edit tray cadence">
      <Form>
        <div>
          <p>Please enter the new cadence, in days:</p>
          <InputText
            value={cadence === 0 ? "" : cadence}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.keyCode === 13) {
                props.onSubmit(cadence);
              }
            }}
            onChange={(e: React.KeyboardEvent<HTMLInputElement>) => {
              let target = (e.target as HTMLInputElement).value;
              const newCadence = parseInt(target);
              setCadence(isNaN(newCadence) ? 0 : newCadence);
            }}
          />
        </div>

        <ButtonRow position="right" style={{ padding: 20 }}>
          <Button onClick={() => props.onCancel()}>Cancel</Button>
          <Button kind="primary" onClick={() => props.onSubmit(cadence)}>
            Apply
          </Button>
        </ButtonRow>
      </Form>
    </Box>
  );
};

const Form = styled.div`
  padding: 20px 0 0 0;
  min-height: 200px;
  min-width: 400px;
  display: flex;
  flex-direction: column;

  > div:first-of-type {
    flex: 1;
    padding: 0 20px;
  }
`;

export default EditCadence;
