import { QUESTION_FORM } from "../event-type";
import { QUESTION_TYPE } from "../constant";
const { MODIFY_WEAR_SCHEDULE } = QUESTION_TYPE;

const VP_TEMPLATE = {
  doNotPrepopulate: true,
  postTimestamp: null,
  postUserName: null,
  postUserUUID: null,
  complete: false,
  formName: "vp", // this must match the name of the form in config/treatment-forms/index
  type: QUESTION_FORM,
  questionList: [
    {
      displayName: "How is the patient tracking?",
      type: "select",
      possibleAnswerList: ["Excellent", "Good", "Some Problems Noted"],
    },
    {
      displayName: "Concerns regarding patient?",
      type: "text",
    },
    {
      displayName: "Is the patient aligner wear on schedule?",
      type: "select",
      possibleAnswerList: ["On Schedule", "Behind", "Ahead"],
    },
    {
      displayName: "Please check wear schedule by clicking the button below",
      type: "alert",
    },
    {
      type: MODIFY_WEAR_SCHEDULE,
    },
    {
      displayName: "Reviewer Notes",
      type: "text",
    },
  ],
};

export default VP_TEMPLATE;
