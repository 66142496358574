import styled from "react-emotion";

export const Wrapper = styled.div`
  &,
  * {
    text-align: center;
  }

  button {
    height: 100%;
    border: 1px solid #eee;
    background: #fff;
    cursor: pointer;
    width: 40px;
    align-self: stretch;
    height: 30px;
  }

  input {
    width: 50px;
    height: 30px;
    margin: 0 2px;
    border: 1px solid #eee;
  }
`;
