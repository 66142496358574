import flatten from "lodash/fp/flatten";
import sortBy from "lodash/fp/sortBy";
import toPairs from "lodash/fp/toPairs";
import pipe from "lodash/fp/pipe";
import map from "lodash/fp/map";
import get from "lodash/fp/get";
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { browserHistory } from "react-router";
import { useToggle } from "react-use";

import Error from "src/shared/Error";
import styled from "react-emotion";
import Sidebar from "./Sidebar/Sidebar";
import color from "src/styles/color";
import Loading from "src/shared/Loading";
import { titleCase } from "src/shared/util";
import { PatientStatus } from "src/types/gql";
import ProfileIcon from "./Icons/Profile";
import TaskTable from "./TaskTable/Table";
import Task from "./Task/Task";
import { useCurrentPatientStatuses, useTasks } from "./queries";
import {
  Task as GraphQLTask,
  TaskAssigneesFilter,
  TaskFilter,
  TaskSort,
} from "./types";
import FiltersButton from "./Filters/FiltersButton/FiltersButton";
import FiltersPanel from "./Filters/FiltersPanel/FiltersPanel";
import FiltersList from "./Filters/FiltersList/FiltersList";
import {
  withFilters,
  FiltersContext,
} from "./Filters/FiltersContext/FiltersContext";

type Props = {
  params: {
    filter?: string;
    id?: string;
  };
};

const getSortOptsString = (sortOpts: Array<TaskSort>): string => {
  return sortOpts
    .map(
      (sortOpt, i) =>
        `&opt${i + 1}={&sortDesc=${sortOpt.sortDescending}&sortField=${
          sortOpt.sortField
        }}`
    )
    .join(",");
};

const getAssigneesFilterString = (
  assigneeFilter: TaskAssigneesFilter
): string => {
  const assigneeIds = assigneeFilter.assigneeIDs
    ? assigneeFilter.assigneeIDs.join(",")
    : "";
  const unassigned = String(assigneeFilter.unassigned);

  return `&assigneeIds=${assigneeIds}&unassigned=${unassigned}`;
};

const getFilterString = (taskFilter: TaskFilter) => {
  const {
    assignees,
    userID,
    categories,
    snoozed,
    sortOpts,
    status,
    taskTypes,
    authorID,
  } = taskFilter;
  const assigneesFilterString = assignees
    ? getAssigneesFilterString(assignees)
    : "";
  const cats = categories ? categories.join(",") : "";
  const types = taskTypes ? taskTypes.join(",") : "";
  const sorts = sortOpts ? getSortOptsString(sortOpts) : "";

  return (
    `assignees={${assigneesFilterString}}&categories=${cats}&userID=${userID}&snoozed=${snoozed}` +
    `&sortOpts={${sorts}}&status=${status}` +
    `&types=${types}&authorID=${authorID}`
  );
};

const TASKS_PER_PAGE = 100;
const Tasks = withFilters((props: Props) => {
  // If we're looking at a task the ID will be in props.params.id
  const { id, filter: routeFilterParam } = props.params;

  const { appliedFilters } = useContext(FiltersContext);

  const [page, setPage] = useState(1);
  const [tasksByPageByFilter, setTasksByPageByFilter] = useState({});
  const [currentPatientStatusesMap, setCurrentPatientStatusesMap] = useState<
    Map<string, PatientStatus>
  >(new Map<string, PatientStatus>());
  const [isFiltersPanelOpen, toggleFiltersPanel] = useToggle(false);

  const filterString = useMemo(() => getFilterString(appliedFilters), [
    appliedFilters,
  ]);

  const [
    { error: tasksError, data: tasksData, fetching: tasksFetching },
  ] = useTasks(appliedFilters, page, TASKS_PER_PAGE);

  const handleScroll = useCallback(() => {
    if (
      (tasksData && tasksData.tasks.pageResults.totalPages <= page) ||
      tasksFetching
    )
      return;

    const bottom =
      window.innerHeight + window.pageYOffset >= document.body.scrollHeight;
    if (bottom) {
      setPage(currPage => currPage + 1);
    }
  }, [tasksData, tasksFetching, page]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (!tasksData || !tasksData.tasks) return;
    setTasksByPageByFilter(currFilters => ({
      ...currFilters,
      [filterString]: {
        ...currFilters[filterString],
        [tasksData.tasks.pageResults.page]: tasksData.tasks.tasks,
      },
    }));
    // filterString changes whenever the uiFilters are changed,
    // we do not want to reinsert existing data under new params.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksData]);

  useEffect(() => {
    setPage(1);
  }, [filterString]);

  const tasks = pipe(
    toPairs,
    sortBy(
      pipe(
        get(0),
        parseInt
      )
    ),
    map(get(1)),
    flatten
  )(tasksByPageByFilter[filterString]);

  const userIDs: Array<string> = !tasks
    ? []
    : tasks.map((task: GraphQLTask) => task.user.id);
  const [
    { error: cpsError, data: cpsData, fetching: cpsFetching },
  ] = useCurrentPatientStatuses(userIDs);

  useEffect(() => {
    if (!cpsData || !cpsData.currentPatientStatuses) return;

    const map = new Map<string, PatientStatus>();
    for (const status of cpsData.currentPatientStatuses) {
      map.set(status.userID, status);
    }

    setCurrentPatientStatusesMap(map);
  }, [cpsData]);

  if (tasksError || cpsError) {
    return <Error />;
  }

  const pageResults = tasksData && tasksData.tasks.pageResults;
  return (
    <>
      <Wrapper>
        <Sidebar />

        <Main>
          <Header>
            <h1>
              <ProfileIcon size={20} /> {titleCase(routeFilterParam || "My")}{" "}
              Tasks
            </h1>
            <FiltersButton onCreateNew={toggleFiltersPanel} />
          </Header>
          <AppliedFilters>
            <FiltersList />
          </AppliedFilters>
          {pageResults && <h3>{pageResults.totalItems} tasks</h3>}
          <Content>
            <TableWrapper>
              <TaskTable
                tasks={tasks}
                currentPatientStatusesMap={currentPatientStatusesMap}
                showCompletedAt={appliedFilters.status === "complete"}
                urlPrefix={`/tasks/${routeFilterParam || "my"}`}
                routeFilter={routeFilterParam || "my"}
              />
            </TableWrapper>
            {(tasksFetching || cpsFetching) && <Loading />}
          </Content>
        </Main>
      </Wrapper>

      {id && (
        <Task
          id={id}
          onClose={() => {
            browserHistory.push(
              ["/tasks", routeFilterParam].filter(Boolean).join("/")
            );
          }}
        />
      )}

      <FiltersPanel isOpen={isFiltersPanelOpen} onClose={toggleFiltersPanel} />
    </>
  );
});

const Wrapper = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-columns: 240px auto auto;
  align-items: stretch;
  overflow: hidden;
  position: relative;

  > div:nth-of-type(2) {
    h1 {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      padding: 32px 0 8px;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      svg {
        margin-right: 16px;
      }
    }
  }

  h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    color: ${color.gray3};
    padding: 0 24px;
  }
`;

const TableWrapper = styled.div`
  height: 100%;
  width: fit-content;
  margin: 0 24px;
  border: 1px solid ${color.borderLight};
`;

const Content = styled.div`
  min-height: 100%;
  margin: 8px 0;
  overflow: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Main = styled.div`
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
`;

const AppliedFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 4px;
  padding: 0 24px 16px;
`;

export default Tasks;
