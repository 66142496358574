import React, { useEffect, useCallback, useState } from "react";
import TextArea from "./TextArea";
import { debounce } from "lodash";

const GeneratedInput = props => {
  const { placeholder, value, handleChange, style } = props;
  const [didMount, setDidMount] = useState(false);

  // to avoid saving the generated input on mount as most likely it needs to be saved
  // only after page is mount and new answers are added
  useEffect(() => setDidMount(true), []);

  const debounced = useCallback(debounce(handleChange, 1500), [handleChange]);

  useEffect(() => {
    if (didMount) {
      debounced(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <TextArea
      style={style}
      disabled={true} // user cannot interact with a generated input
      placeholder={placeholder}
      value={value || ""}
    />
  );
};

export default GeneratedInput;
