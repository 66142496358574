import React from "react";
import { getPhaseText } from "src/scenes/Form/Submission/TreatmentSetup/Summary";
import {
  Prewrap,
  SummaryField,
  SummaryFieldLabel,
  SummaryFieldContent,
} from "../styles";
import { getAnswer, parseToothData } from "../util";
import { NoteContentProps } from "./types";

export default function TreatmentSetupForm({ submission }: NoteContentProps) {
  const critical_attachments = getAnswer("critical_attachments", submission);
  const treatment_plan_summary = getAnswer(
    "treatment_plan_summary",
    submission
  );
  const manufacturing_notes = getAnswer("manufacturing_notes", submission);
  const toothData = parseToothData(submission.toothData);
  const phaseSummary = toothData.map(getPhaseText).join("\n");
  return (
    <>
      {critical_attachments && (
        <SummaryField key="critical_attachments">
          <SummaryFieldLabel>Critical attachments</SummaryFieldLabel>
          <SummaryFieldContent>
            <Prewrap>{critical_attachments}</Prewrap>
          </SummaryFieldContent>
        </SummaryField>
      )}

      {treatment_plan_summary && (
        <SummaryField key="treatment_plan_summary">
          <SummaryFieldLabel>Treatment plan summary</SummaryFieldLabel>
          <SummaryFieldContent>
            <Prewrap>{treatment_plan_summary}</Prewrap>
          </SummaryFieldContent>
        </SummaryField>
      )}

      {manufacturing_notes && (
        <SummaryField key="manufacturing_notes">
          <SummaryFieldLabel>Manufacturing notes</SummaryFieldLabel>
          <SummaryFieldContent>
            <Prewrap>{manufacturing_notes}</Prewrap>
          </SummaryFieldContent>
        </SummaryField>
      )}

      {phaseSummary && (
        <SummaryField key="phaseSummary">
          <SummaryFieldLabel>Generated summary</SummaryFieldLabel>
          <SummaryFieldContent>
            <Prewrap>{phaseSummary}</Prewrap>
          </SummaryFieldContent>
        </SummaryField>
      )}
    </>
  );
}
