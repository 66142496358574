import { Client } from "src/utils/http/gqlQuery";
import {
  submissionQuery,
  createSubmissionGQL,
  cloneSubmissionGQL,
  editSubmissionGQL,
  updateSubmissionGQL,
  deleteSubmissionGQL,
} from "src/scenes/PatientProfile/Forms/GQLForms/queries";
import { Submission } from "src/scenes/PatientProfile/Forms/GQLForms/types";

export const fetchSubmissions = (
  client: Client,
  formID: string,
  userID: string
) => {
  return client
    .query<{ submissions: Submission[] }>(submissionQuery, { formID, userID })
    .toPromise();
};

export type CreateSubmissionArgs = {
  formID: string;
  userID: string;
  parentID?: string;
  appointmentID?: string;
};

export const createSubmission = (
  client: Client,
  input: CreateSubmissionArgs
) => {
  return client
    .mutation<
      { createSubmission: Submission },
      { input: CreateSubmissionArgs }
    >(createSubmissionGQL, { input })
    .toPromise();
};

export const cloneSubmission = (client: Client, id: string) => {
  return client
    .mutation<{ cloneSubmission: Submission }, { id: string }>(
      cloneSubmissionGQL,
      { id }
    )
    .toPromise();
};

export const editSubmission = (client: Client, id: string) => {
  return client
    .mutation<{ editSubmission: Submission }, { id: string }>(
      editSubmissionGQL,
      { id }
    )
    .toPromise();
};

export type UpdateInput = {
  input: {
    id: string;
    toothData?: string;
    appointmentID?: string;
    addFiles?: string[];
    removeFiles?: string[];
  };
};

export const updateSubmission = (client: Client, input: UpdateInput) => {
  return client
    .mutation<{ updateSubmission: Submission }, UpdateInput>(
      updateSubmissionGQL,
      input
    )
    .toPromise();
};

export const deleteSubmission = (client: Client, id: string) => {
  return client
    .mutation<any, { submissionID: string }>(deleteSubmissionGQL, {
      submissionID: id,
    })
    .toPromise();
};
