// @flow
import React, { useEffect } from "react";
import { BuildsEnhancer } from "src/state/Builds";
/*
import type {
  Build,
  Pipeline,
  TaskResponse,
  Patient,
  TrayBuild,
} from "src/types/api";

type PassedProps = {
  pipeline: Pipeline,
  task: TaskResponse,
  patient: Patient,
};

type Props = PassedProps & BuildProps;
*/

const BuildInformation = props => {
  useEffect(() => {
    // This task should have a props.task.model_type of "Manufacturing::Build",
    // and the model_id of the build ID that was created.
    props.actions.getDetailedBuild(props.buildID);
  }, [props.actions, props.buildID]);

  const build = Object.values(props.detailedTrayBuilds).find(
    b => b.id === props.buildID
  );

  if (!build) {
    return <div>Loading build</div>;
  }

  const trays = new Set(build.tray_builds.map(tb => tb.tray.stage));

  return (
    <div style={{ textAlign: "center" }}>
      {trays.size} trays built via <b>{build.producer_identifier.source}</b>
      <br />
      <span style={{ opacity: 0.75 }}>
        Trays:{" "}
        {Array.from(trays)
          .sort((a, b) => a - b)
          .join(", ")}
      </span>
    </div>
  );
};

export default BuildsEnhancer(BuildInformation);
