// @flow
import React, { PureComponent } from "react";
import styled from "react-emotion";
import { withState, compose } from "recompose";
import { Row, Col, Form, InputNumber, Select, Tag, Button } from "antd";
import ToothChart from "src/shared/ToothChartV2/ToothChart";
import {
  attributesToV2,
  attributesToV1,
} from "src/shared/ToothChartV2/versioning";
import { palmerToothName, entryTypes } from "src/shared/ToothChart/util";
import { getIPRClauses, isIPR, procedureToUniversal } from "./getIPRText";
const { Option } = Select;

// type PassedProps = {
//   phase: Phase,
//   stageStart?: number,
//   index: number,
//   updatePhase: Phase => void,
//   removePhase: () => void,
//   disabled?: boolean,
// };

// type ModifierProps = {
//   showToothChart: boolean,
//   setShowToothChart: boolean => void,
// };

// type Props = PassedProps & ModifierProps;

const modifiers = compose(
  withState("showToothChart", "setShowToothChart", false)
);

class TreatmentPhase extends PureComponent {
  updatePhase = (field, val) => {
    this.props.updatePhase(
      Object.assign({}, this.props.phase, {
        [field]: val,
      })
    );
  };

  render() {
    const {
      phase,
      index,
      showToothChart,
      setShowToothChart,
      cumulativeCutoff,
      removePhase,
      disabled,
      stageStart,
      userID,
      submissionID,
      bvAttributes,
    } = this.props;

    const upper_stages =
      phase.upper_stages === undefined ? phase.stages : phase.upper_stages;
    const lower_stages =
      phase.lower_stages === undefined ? phase.stages : phase.lower_stages;

    const max = Math.max(upper_stages, lower_stages);

    return (
      <Wrapper>
        <PhaseHeader>
          <span>Phase {index + 1}</span>
          {!disabled && (
            <Button
              type="secondary"
              shape="circle"
              icon="stop"
              size="small"
              onClick={removePhase}
            />
          )}
        </PhaseHeader>

        {showToothChart && (
          <ToothChart
            disabled={disabled}
            userID={userID}
            submissionID={submissionID}
            cumulativeCutoff={cumulativeCutoff}
            defaultDisplayType={"prescribed"}
            availableDisplayTypes={["cumulative", "original", "prescribed"]}
            readOnlyDisplayTypes={["cumulative", "original"]}
            unsavedAttributes={attributesToV2(phase.procedures)}
            bvAttributes={bvAttributes}
            onChange={procedures => {
              this.updatePhase("procedures", attributesToV1(procedures));
            }}
          />
        )}

        <Row>
          <Col sm={{ span: 22, offset: 1 }} lg={{ span: 4, offset: 1 }}>
            <Form.Item label="Phase type" colon={false}>
              <Select
                value={phase.type}
                onChange={type => this.updatePhase("type", type)}
                style={{ width: "100%" }}
                disabled={disabled}
              >
                <Option value="active_retention">Active Retention</Option>
                <Option value="alignment">Alignment</Option>
                <Option value="bite_correction">Bite correction</Option>
                <Option value="expansion">Expansion</Option>
                <Option value="overcorrection">Overcorrection</Option>
                <Option value="passive">Passive</Option>
                <Option value="retainer">Retainer</Option>
                <Option value="space_closure">Space closure</Option>
                <Option value="doctors_adjustment">Doctors Adjustment</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col sm={{ span: 22, offset: 1 }} lg={{ span: 4, offset: 1 }}>
            <Form.Item label="Number of upper stages" colon={false}>
              <InputNumber
                value={upper_stages}
                onChange={stages => this.updatePhase("upper_stages", stages)}
                min={0}
                max={80}
                style={{ width: "100%" }}
                disabled={disabled}
              />
              {max > 0 && (
                <p>
                  (stages {stageStart}-{stageStart + max - 1})
                </p>
              )}
            </Form.Item>
            <Form.Item label="Number of lower stages" colon={false}>
              <InputNumber
                value={lower_stages}
                onChange={stages => this.updatePhase("lower_stages", stages)}
                min={0}
                max={80}
                style={{ width: "100%" }}
                disabled={disabled}
              />
              {max > 0 && (
                <p>
                  (stages {stageStart}-{stageStart + max - 1})
                </p>
              )}
            </Form.Item>
          </Col>
          <Col sm={{ span: 22, offset: 1 }} lg={{ span: 4, offset: 1 }}>
            <Form.Item label="Rx Procedures" colon={false}>
              <Procedures>
                <ProcedureList>
                  {phase.procedures
                    .filter(p => !isIPR(p))
                    .map((p, n) => (
                      <Tag key={`${n}-${JSON.stringify(p)}`}>
                        {entryTypes[p.entry_type]}{" "}
                        {palmerToothName(p.tooth_name)}{" "}
                        {p.entry_data &&
                          Object.keys(p.entry_data)
                            // $FlowFixMe
                            .map(
                              k => p.entry_data[k] && `${k}: ${p.entry_data[k]}`
                            )
                            .filter(Boolean)
                            .join(", ")}
                      </Tag>
                    ))}
                  {getIPRClauses(
                    phase.procedures.filter(isIPR).map(procedureToUniversal)
                  ).map(clause => (
                    <Tag key={`ipr-${clause}`}>IPR: {clause}</Tag>
                  ))}
                  {phase.procedures.length === 0 && "-"}
                </ProcedureList>
              </Procedures>
            </Form.Item>
          </Col>

          <Col sm={{ span: 22, offset: 1 }} lg={{ span: 2, offset: 4 }}>
            <Form.Item label=" " colon={false}>
              <Button onClick={() => setShowToothChart(!showToothChart)}>
                {showToothChart ? "Hide" : "Show"} tooth chart
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

export default modifiers(TreatmentPhase);

const Wrapper = styled("div")`
  padding-bottom: 1.5rem;
`;

const PhaseHeader = styled("div")`
  text-transform: uppercase;
  padding: 0.25rem 2rem;
  color: #999;
  font: 0.8rem/2 "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: 1px;
  border-top: 2px solid #ecece9;
  margin-bottom: 2rem;

  display: flex;
  justify-content: space-between;
`;

const Procedures = styled("div")`
  line-height: 2;
`;

// This display-table oddness ensures that tags are stacked, as if display block, but
// don't take up the full width.
const ProcedureList = styled("div")`
  .ant-tag {
    display: table;
  }
  .ant-tag + .ant-tag {
    margin-top: 2px;
  }
`;
