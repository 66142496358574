import React, { useState, useEffect } from "react";
import styled, { css } from "react-emotion";
import color from "src/styles/color";
import Table, { Header, Body } from "src/shared/Table";
import textStyles from "src/styles/textStyles";
import Edit from "src/scenes/Patient/BillingV2/Orders/OrderSettings/icons/edit.svg";
import Trash from "src/shared/Icons/Trash";
import { toISODate } from "src/shared/time";
import Loading from "src/shared/Loading";
import Dropdown from "src/shared/Dropdown";
import {
  useGetAvailablePromotions,
  useGetExpiredPromotions,
  useGetUpcomingPromotions,
  useDeletePromoCode,
  useAddNewPromotion,
  useUpdatePromotion,
} from "./useEMRAdminInfo";
import { Promotion, PromotionType } from "src/types/gql";
import { notification, Input } from "antd";
import { NewPromotion, UpdatePromotion } from "./queries";
import UpdatePromoModal from "./UpdatePromoModal";
import DeleteModal from "./DeleteModal";
import AddPromoModal from "./AddPromotionModal";
import Button from "src/shared/Button";
import { formatPromoAmount } from "src/utils/promotion";
import { gqlError } from "src/shared/util";

const Perimeter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${color.background};
`;

const Unit = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }
`;

const Label = styled.div`
  ${textStyles("normal")};
  font-weight: bold;
  margin: 0px 16px;
  padding: 12px 0;
`;

const PickerWrapper = styled.div`
  display: flex;
  width: 216px;
  align-items: center;
`;

const NUM_COLUMNS = 7;

const headerItemStyle = css`
  width: calc(100% / ${NUM_COLUMNS});
`;

const bodyItemStyle = css`
  width: calc(100% / ${NUM_COLUMNS});
  &:last-child {
    margin-right: 0;
    flex-direction: row;
  }
`;

const IconWrapper = styled.div`
  width: 16px;
  margin: 16px 8px;
  cursor: pointer;
`;

const StyledButton = styled.div`
  margin-left: 12px;
`;

const searchInput = css`
  width: 200px;
  margin-right: 12px;
`;

const AvailablePromotion = "available";
const ExpiredPromotion = "expired";
const UpcomingPromotion = "upcoming";

const PromotionStatuses = [
  AvailablePromotion,
  ExpiredPromotion,
  UpcomingPromotion,
];

const Promos = () => {
  const [selectedPromotions, setSelectedPromotions] = useState<
    Array<Promotion>
  >([]);

  const executeDelete = useDeletePromoCode();
  const executeAdd = useAddNewPromotion();
  const executeUpdate = useUpdatePromotion();

  const [
    fetchingAvailablePromos,
    ,
    availablePromos,
  ] = useGetAvailablePromotions();

  useEffect(() => {
    if (availablePromos) {
      setSelectedPromotions(availablePromos);
    }
  }, [availablePromos]);

  const [, , expiredPromos] = useGetExpiredPromotions();
  const [, , upcomingPromos] = useGetUpcomingPromotions();

  const [search, setSearch] = useState("");
  const [promotionStatus, setPromotionStatus] = useState<string>(
    PromotionStatuses[0]
  );

  const [promoTypeFilter, setPromoTypeFilter] = useState("all");
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [updatePromotionItem, setUpdatePromotionItem] = useState({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deletePromotionItem, setDeletePromotionItem] = useState({});
  const [addPromoVisible, setAddPromoVisible] = useState(false);

  if (fetchingAvailablePromos) {
    return <Loading />;
  }

  const promotionTypes = selectedPromotions
    .map(item => item.type)
    .filter((value, index, self) => self.indexOf(value) === index);
  promotionTypes.push("all" as PromotionType);

  let promotions = selectedPromotions;
  if (promoTypeFilter !== "all") {
    promotions = selectedPromotions.filter(v => v.type === promoTypeFilter);
  }

  if (search !== "") {
    promotions = promotions.filter(
      p => p.code && p.code.toLowerCase().includes(search.toLowerCase())
    );
  }

  const deletePromotion = async (promoId: string) => {
    if (promotionStatus === ExpiredPromotion) {
      notification.error({
        message: "Can't delete expired promotion",
      });
      return;
    }

    const result = await executeDelete(promoId);
    if (result.error) {
      notification.error({
        message: `There was an error deleting this promotion: ${gqlError(
          result.error
        )}`,
      });
      return;
    }
    notification.success({ message: "Promotion deleted" });
  };

  const addPromotion = async (newPromotion: NewPromotion) => {
    const result = await executeAdd(newPromotion);

    if (result.error) {
      notification.error({
        message: `There was an error adding a promotion: ${gqlError(
          result.error
        )}`,
      });
      return;
    }
    notification.success({ message: "Promotion added" });
  };

  const updatePromotion = async (updatePromotion: UpdatePromotion) => {
    const result = await executeUpdate(updatePromotion);

    if (result.error) {
      notification.error({
        message: `There was an error updating a promotion: ${gqlError(
          result.error
        )}`,
      });
      return;
    }
    notification.success({ message: "Promotion updated" });
  };

  return (
    <Perimeter>
      {updatePromotionItem && updateModalVisible && (
        <UpdatePromoModal
          promotion={updatePromotionItem as Promotion}
          onUpdate={updatePromotion}
          setVisible={isVisible => setUpdateModalVisible(isVisible)}
        />
      )}
      {deleteModalVisible && deletePromotionItem && (
        <DeleteModal
          id={(deletePromotionItem as Promotion).id}
          title="Expire promotion"
          description={`Are you sure you want to mark this promotion ${
            (deletePromotionItem as Promotion).code
          } as expired?`}
          onDelete={deletePromotion}
          onClose={() => {
            setDeleteModalVisible(false);
            setDeletePromotionItem({});
          }}
        />
      )}
      {addPromoVisible && (
        <AddPromoModal
          onAdd={addPromotion}
          setVisible={visible => setAddPromoVisible(visible)}
        />
      )}
      <Unit>
        <Input
          className={searchInput}
          placeholder="Search by code"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <PickerWrapper>
          <Label>Promotion Type: </Label>
          <Dropdown
            options={promotionTypes}
            selected={promoTypeFilter}
            onSelect={v => {
              setPromoTypeFilter(v);
            }}
          />
        </PickerWrapper>
        <PickerWrapper>
          <Label>Promotion status: </Label>
          <Dropdown
            options={PromotionStatuses}
            selected={promotionStatus}
            onSelect={status => {
              setPromotionStatus(status);
              switch (status) {
                case AvailablePromotion:
                  setSelectedPromotions(availablePromos);
                  break;
                case ExpiredPromotion:
                  setSelectedPromotions(expiredPromos);
                  break;
                case UpcomingPromotion:
                  setSelectedPromotions(upcomingPromos);
                  break;
                default:
                  break;
              }
            }}
          />
        </PickerWrapper>
        <StyledButton>
          <Button kind="primary" onClick={() => setAddPromoVisible(true)}>
            Add new
          </Button>
        </StyledButton>
      </Unit>
      <Table items={promotions}>
        <Header>
          <Header.Item css={headerItemStyle}>Promo code</Header.Item>
          <Header.Item css={headerItemStyle}>Promotion Type</Header.Item>
          <Header.Item css={headerItemStyle}>Amount</Header.Item>
          <Header.Item css={headerItemStyle}>Stackable</Header.Item>
          <Header.Item css={headerItemStyle}>Start</Header.Item>
          <Header.Item css={headerItemStyle}>End</Header.Item>
          <Header.Item css={headerItemStyle} />
        </Header>
        <Body>
          {item => (
            <Body.Row key={item.email}>
              <Body.Item css={bodyItemStyle}>{item.code}</Body.Item>
              <Body.Item css={bodyItemStyle}>{item.type}</Body.Item>
              <Body.Item css={bodyItemStyle}>
                {formatPromoAmount(item.amount, item.type)}
              </Body.Item>
              <Body.Item css={bodyItemStyle}>
                {item.stackable ? "Yes" : "No"}
              </Body.Item>
              <Body.Item css={bodyItemStyle}>
                {item.start ? toISODate(item.start) : ""}
              </Body.Item>
              <Body.Item css={bodyItemStyle}>
                {item.end ? toISODate(item.end) : ""}
              </Body.Item>
              <Body.Item css={bodyItemStyle}>
                <IconWrapper
                  onClick={() => {
                    setUpdateModalVisible(true);
                    setUpdatePromotionItem(item);
                  }}
                >
                  <img src={Edit} alt="Edit" />
                </IconWrapper>
                <IconWrapper
                  onClick={() => {
                    setDeletePromotionItem(item);
                    setDeleteModalVisible(true);
                  }}
                >
                  <Trash fill={color.red} />
                </IconWrapper>
              </Body.Item>
            </Body.Row>
          )}
        </Body>
      </Table>
    </Perimeter>
  );
};

export default Promos;
