import React from "react";
import { CrownProps, RootProps } from "./types";

export const Crown: React.SFC<CrownProps> = ({ fill, stroke, style }) => (
  <svg
    width="22"
    height="26"
    viewBox="0 0 22 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M14.9195 3.09792C10.5874 -1.11716 5.7699 2.55287 3.90264 4.91477C2.40884 6.4557 0.541576 12.7272 1.10175 16.1792C1.66193 19.6312 4.46282 21.9931 7.45044 23.6283C9.84053 24.9364 12.5543 24.5367 13.6124 24.1733C14.6083 23.81 17.0481 22.6109 18.8407 20.7213C20.6333 18.8318 20.9569 16.4215 20.8947 15.4525C20.708 13.0906 19.2515 7.31301 14.9195 3.09792Z"
      fill={fill || "#fff"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />
  </svg>
);

// Root always contains the portion as if it attached as a Crown.
export const Root: React.SFC<RootProps> = ({
  hasRootCanal,
  style,
  fill,
  stroke,
}) => (
  <svg
    width="15"
    height="52"
    viewBox="0 0 15 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M1 46C2.1762 40.28 2.05667 27.8947 2.17919 20.9891C2.26478 17.3225 1.62784 10.9058 1.62784 7.60581C1.62784 4.30581 1.44406 1.18917 3.09809 1.00584C4.42132 0.859174 5.85481 3.51139 6.40615 4.85583C13.1693 26.5625 14.1126 40.3778 13.5 46H10V51H5V46H1Z"
      fill={fill || "#F1F4F5"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />

    {hasRootCanal && (
      <g transform="translate(3.5,1.5)">
        <path
          d="M0 0C1.6 13.2 1.66667 33.5 1 39L5.5 40C3 20.5 1.16667 4.33333 0 0Z"
          fill="#C270A7"
        />
      </g>
    )}
  </svg>
);
