import { sortBy, findIndex, map, filter } from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./patient-task-list-style";
import { Stepper } from "material-ui/Stepper";
import {
  loadPatientTask,
  getPatientTaskList,
} from "src/redux/modules/task-list";

// for actions and redux
import { connect } from "react-redux";
import PatientTask from "./patient-task";
import { TASK_TYPE } from "../../../../config/constant";

const REPOLL_DELAY = 4000;
const enhance = connect(
  (state, props) => ({
    taskList: sortBy(getPatientTaskList(state, props), "due_date"),
  }),
  { loadPatientTask }
);

class PatientTaskList extends Component {
  static propTypes = {
    manufacturing: PropTypes.bool,
    patientId: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      activeStep: findIndex(this.props.taskList, { is_complete: false }),
    };
  }
  componentDidMount() {
    this.props.loadPatientTask({
      queryParams: { user_id: this.props.patientId, is_complete: "any" },
    });
    setTimeout(() => {
      this.props.loadPatientTask({
        queryParams: { user_id: this.props.patientId, is_complete: "any" },
      });
    }, REPOLL_DELAY);
  }
  handleStepClick = activeStep => {
    this.setState({ activeStep });
  };
  render() {
    const { manufacturing } = this.props;
    let { taskList } = this.props;
    if (manufacturing)
      taskList = filter(taskList, { task_type: TASK_TYPE.MANUFACTURING });
    const { activeStep } = this.state;
    return (
      <div style={styles.container}>
        <div style={styles.S.sectionTitle}>Tasks</div>
        <Stepper
          style={styles.stepper}
          activeStep={activeStep}
          linear={false}
          orientation="vertical"
        >
          {map(taskList, (task, index) => {
            return (
              <PatientTask
                key={task.id}
                task={task}
                index={index}
                handleStepClick={this.handleStepClick}
                active={activeStep === index}
              />
            );
          })}
        </Stepper>
      </div>
    );
  }
}

export default enhance(PatientTaskList);
