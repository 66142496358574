import React from "react";

const DCM = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1342 0H4.55023C4.08852 0 3.71423 0.374286 3.71423 1.10057V31.4286C3.71423 31.6257 4.08852 32 4.55023 32H27.4497C27.9114 32 28.2857 31.6257 28.2857 31.4286V7.416C28.2857 7.01829 28.2325 6.89029 28.1388 6.796L21.4897 0.146857C21.3954 0.0531429 21.2674 0 21.1342 0Z"
      fill="#E9E9E0"
    />
    <path
      d="M15.826 8.896L15.8228 13.2128C15.4968 13.2466 15.0875 13.422 14.9614 14.2835C14.746 15.785 14.3152 17.7143 13.6691 17.7143C13.0076 17.7143 12.7798 16.9235 12.6384 16.4158C12.4592 15.7265 12.3367 15.0237 12.2723 14.3143C12.1804 13.6552 11.962 13.0199 11.6291 12.4436C11.5184 12.2436 11.3985 12.0497 11.2784 11.8589V11.8559C11.0107 10.819 11.0754 9.63135 11.8906 8.87135C12.9307 7.90222 14.789 7.97901 15.826 8.896Z"
      fill="#BDBDBD"
    />
    <path
      d="M20.3705 11.8589C20.3675 11.8621 20.3675 11.8621 20.3675 11.8652C20.2474 12.0559 20.1305 12.2467 20.0229 12.4436C19.6887 13.0194 19.4702 13.6548 19.3798 14.3143C19.3143 15.0237 19.1908 15.7266 19.0106 16.4158C18.8722 16.9235 18.6444 17.7143 17.9798 17.7143C17.3368 17.7143 16.903 15.785 16.6876 14.2835C16.5614 13.422 16.1521 13.2466 15.826 13.2128V8.896C16.863 7.97901 18.7213 7.90222 19.7583 8.87135C20.5767 9.63135 20.6382 10.8221 20.3705 11.8589Z"
      fill="#BDBDBD"
    />
    <path
      d="M15.826 6.56684V8.896C14.7891 7.97901 12.9307 7.90222 11.8907 8.87135C11.0754 9.63135 11.0107 10.819 11.2784 11.8559C11.0505 11.5138 10.8528 11.1524 10.6876 10.776C10.143 9.42522 10.0169 7.61603 11.1768 6.53607C12.4045 5.39144 14.6014 5.48062 15.826 6.56684Z"
      fill="#D7D7D7"
    />
    <path
      d="M20.9613 10.776C20.797 11.1538 20.5993 11.5162 20.3705 11.8589C20.6382 10.8221 20.5767 9.63135 19.7583 8.87135C18.7213 7.90222 16.863 7.97901 15.826 8.896V6.56684C17.0506 5.48062 19.2475 5.39144 20.4721 6.53607C21.6351 7.61603 21.5059 9.42522 20.9613 10.776Z"
      fill="#D7D7D7"
    />
    <path
      d="M21.4285 0.0862846V6.85714H28.1993L21.4285 0.0862846Z"
      fill="#D9D7CA"
    />
    <path
      d="M27.4497 32H4.55023C4.08852 32 3.71423 31.6257 3.71423 31.164V22.2857H28.2857V31.164C28.2857 31.6257 27.9114 32 27.4497 32Z"
      fill="#A2A9AD"
    />
    <path
      d="M7.29344 30.2857H9.17344C10.7174 30.2857 12.0694 29.3897 12.0694 27.4857C12.0694 25.5737 10.7174 24.6857 9.17344 24.6857H7.29344V30.2857ZM8.49344 29.2457V25.7177H9.05344C9.97344 25.7177 10.8534 26.1657 10.8534 27.4857C10.8534 28.8057 9.97344 29.2457 9.05344 29.2457H8.49344ZM15.4238 30.3497C16.8558 30.3497 17.8878 29.5417 17.9918 28.3417H16.7918C16.6958 28.9177 16.1438 29.3097 15.4238 29.3097C14.5198 29.3097 13.8238 28.6857 13.8238 27.4857C13.8238 26.2857 14.5198 25.6537 15.4238 25.6537C16.1278 25.6537 16.6798 26.0457 16.7598 26.6297H18.0158C17.8638 25.4217 16.8318 24.6217 15.4238 24.6217C13.9278 24.6217 12.6158 25.5977 12.6158 27.4857C12.6158 29.3737 13.9038 30.3497 15.4238 30.3497ZM18.7388 30.2857H19.8588V26.8697L21.2828 30.2857H22.1708L23.5868 26.8697V30.2857H24.7068V24.6857H23.3628L21.7228 28.5977L20.0908 24.6857H18.7388V30.2857Z"
      fill="white"
    />
  </svg>
);
export default DCM;
