import { map, partial } from "lodash";
import React, { PureComponent } from "react";
import { RaisedButton, Popover, Menu, MenuItem } from "material-ui";
import AppLinkConfig from "src/config/app-links";

export default class AppLinks extends PureComponent {
  state = {
    open: false,
  };
  thisSelect = appLink => {
    this.props.onSelect(appLink);
  };
  handleClick = e => {
    e.preventDefault();
    this.setState({
      open: true,
      anchorEl: e.currentTarget,
    });
  };
  handleRequestClose = () => {
    this.setState({ open: false });
  };
  render() {
    return (
      <div
        style={{ marginBottom: 12, marginRight: 12, display: "inline-block" }}
      >
        <RaisedButton label="App Links" onClick={this.handleClick} />
        <Popover
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          targetOrigin={{ horizontal: "left", vertical: "top" }}
          onRequestClose={this.handleRequestClose}
        >
          <Menu>
            {map(AppLinkConfig, appLink => (
              <MenuItem
                key={appLink.text}
                primaryText={appLink.text}
                onClick={partial(this.props.onSelect, appLink)}
              />
            ))}
          </Menu>
        </Popover>
      </div>
    );
  }
}
