import React from "react";
import { Task } from "../../types";
import BuildInfo from "src/shared/TaskActions/BuildInformation";

type Props = {
  referenceID: string;
  task: Task;
};

const Content: React.FC<Props> = ({ task, referenceID }) => {
  const build = task.references.find(r => r.id === referenceID);

  if (!build) {
    return <p>No build found</p>;
  }

  return <BuildInfo buildID={build.id} />;
};

export default Content;
