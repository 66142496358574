import React from "react";
import gql from "graphql-tag";
import { notification } from "antd";

import { useMutation } from "src/utils/http/gqlQuery";
import useSchedulingContext from "src/scenes/SchedulingV2/useSchedulingContext";
import StaffAssignmentModal from "./StaffAssignmentModal";

interface Props {
  appointmentIds: Array<string>;
  clinicId: string;
  closeModal: () => void;
}

const setAppointmentStaff = gql`
  mutation UpdateAppointment($id: ID!, $staff: [ID!]) {
    setStaff: updateAppointmentStaff(appointmentId: $id, staff: $staff) {
      id
      staff {
        id
        name
        roles {
          role
        }
      }
    }
  }
`;

const AssistantAssignmentModal = (props: Props) => {
  const { staffOptions } = useSchedulingContext();
  const { appointmentIds } = props;
  const [, updateAppointmentStaff] = useMutation(setAppointmentStaff);

  const onSubmit = (staffId: string) => {
    appointmentIds.forEach(async appontmentId => {
      const result = await updateAppointmentStaff({
        id: appontmentId,
        staff: [staffId],
      });
      if (result.error) {
        notification.error({
          message: `There was an error assigning this assistant`,
        });
      }
    });

    notification.info({
      message: `Finished assigning assistant`,
    });
  };

  return (
    <StaffAssignmentModal
      staffType="assistant"
      onSubmit={onSubmit}
      staffOptions={staffOptions}
      {...props}
    />
  );
};

export default AssistantAssignmentModal;
