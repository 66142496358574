import difference from "lodash/difference";
import isEmpty from "lodash/isEmpty";
import React from "react";
import Popover from "src/shared/Popover";
import SelectList from "src/shared/SelectList";
import CustomTag from "src/scenes/Tasks/CustomTagsList/CustomTag";
import { PipelineTags } from "src/scenes/Tasks/types";

type Props = {
  value: PipelineTags[];
  onClick: (p: PipelineTags) => void;
};

export const pipelineTagsOptions: PipelineTags[] = [
  "virtual_refinement",
  "rush",
];

const PipelineTagsPopover: React.FC<Props> = ({ children, value, onClick }) => {
  const optionsToShow = difference(pipelineTagsOptions, value);

  if (isEmpty(optionsToShow)) {
    return null;
  }

  const Content = (
    <SelectList<PipelineTags>
      values={optionsToShow}
      value={[]}
      onClick={(p?: PipelineTags) => p && onClick(p)}
      render={(p: PipelineTags) => <CustomTag tag={p} key={p} />}
    />
  );

  return <Popover content={Content}>{children}</Popover>;
};

export default PipelineTagsPopover;
