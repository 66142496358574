import { get, intersection, last } from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./tooth-chart-style";
import FlatButton from "material-ui/FlatButton";
import Tooth from "./tooth";
import { routerShape } from "react-router/lib/PropTypes";
import { connect } from "react-redux";
import { putToothChart } from "src/redux/modules/clinical-record";
/**
 * Teeth are indexed from this diagram
 * http://www.americantooth.com/downloads/instructions/Dental_Sys_Permanent_Teeth.pdf
 * Right and Left are Stage Right and left from the perspective of the patient.
 * So the right label is actually on the left of the screen
 */
const TEETH_QUADRANT_NUMBERING_LEFT = [1, 2, 3, 4, 5, 6, 7, 8];
const TEETH_QUADRANT_NUMBERING_RIGHT = [8, 7, 6, 5, 4, 3, 2, 1];
const ACTIONS = ["Caries", "Gum Disease", "IPR", "Attachments", "Elastics"];
const STATUSES = ["Rx", "Perf."];
const ACTIONS_WITH_STATUS = ["IPR", "Attachments", "Elastics"];
const IPR_TYPE_LIST = ["L", "M", "H"];
const ELASTIC_LABEL_LIST = ["1", "2", "3", "4"];
const ELASTIC_TYPE_LIST = ["BH", "BL", "SH", "SL"];

const enhance = connect(
  (state, ownProps) => ({
    patientId: get(state, "clinicalRecord.data.user_id"),
    toothChart:
      ownProps.toothChart ||
      get(state, "clinicalRecord.data.dental_chart.eventStream"),
  }),
  { putToothChart }
);

class ToothChart extends Component {
  static contextTypes = {
    router: routerShape,
  };
  static propTypes = {
    toothChart: PropTypes.array,
    patientId: PropTypes.string.isRequired,
    embedded: PropTypes.bool,
    readOnly: PropTypes.bool,
    putToothChart: PropTypes.func.isRequired,
  };
  state = {
    activeIPRType: "M",
    activeStatus: intersection(
      this.context.router.location.pathname.split("/"),
      ["piv", "pda"]
    ).length
      ? "Perf."
      : "Rx",
    activeElasticLabel: "1",
    activeElasticType: "BH",
    minimized: false,
    close: true,
    activeAction: "",
    toothActionEventStream: this.props.toothChart || [], // undo by popping
  };
  componentDidMount = () => {
    this.routerChangeUnListen = this.context.router.listen(location => {
      // listen returns an unListen function
      this.setState({
        activeStatus: intersection(location.pathname.split("/"), ["piv", "pda"])
          .length
          ? "Perf."
          : "Rx",
      });
    });
  };
  componentWillUnmount = () => {
    this.routerChangeUnListen();
  };
  synchToothActionEventStream = [];
  toggleActiveStatus = activeStatus => {
    this.setState({ activeStatus });
  };
  toggleActiveElasticLabel = activeElasticLabel => {
    this.setState({ activeElasticLabel });
  };
  toggleActiveElasticType = activeElasticType => {
    this.setState({ activeElasticType });
  };
  toggleActiveIPRType = activeIPRType => {
    this.setState({ activeIPRType });
  };
  saveActionToEventStream = actionEvent => {
    this.synchToothActionEventStream.push(actionEvent);
    this.setState({ toothActionEventStream: this.synchToothActionEventStream });
    this.props.putToothChart(
      this.props.patientId,
      this.synchToothActionEventStream
    );
  };

  toggleActiveAction = action => {
    if (this.state.activeAction === action) {
      this.setState({ activeAction: "" });
    } else {
      this.setState({ activeAction: action });
    }
  };
  toggleMinimize = () => {
    this.setState({ minimized: !this.state.minimized });
  };
  undo = () => {
    const actionToUndo = this.synchToothActionEventStream.pop();
    if (actionToUndo.action === "IPR") {
      const tail = last(this.synchToothActionEventStream);
      if (get(tail, "isSynthClick")) {
        this.synchToothActionEventStream.pop(); // pop both off
      }
    }
    this.setState({ toothActionEventStream: this.synchToothActionEventStream });
    this.props.putToothChart(
      this.props.patientId,
      this.synchToothActionEventStream
    );
  };

  renderTooth = (quadrantLabel, toothDisplayNum) => {
    return (
      <Tooth
        key={`${this.props.patientId}-${quadrantLabel}-${toothDisplayNum}`}
        quadrantLabel={quadrantLabel}
        toothDisplayNum={toothDisplayNum}
        allToothActionEventStream={this.state.toothActionEventStream}
        saveActionToEventStream={this.saveActionToEventStream}
        activeAction={this.state.activeAction}
        activeStatus={this.state.activeStatus}
        activeElasticType={this.state.activeElasticType}
        activeElasticLabel={this.state.activeElasticLabel}
        activeIPRType={this.state.activeIPRType}
      />
    );
  };
  renderButton = (activeIndicator, toggle, label) => {
    const isActive = activeIndicator === label;
    const buttonColor = isActive
      ? styles.action.buttonBg[label] || styles.V.colors.secondary
      : "";
    const labelStyle = isActive
      ? { ...styles.action.label, ...styles.action.activeLabel }
      : styles.action.label;
    return (
      <FlatButton
        key={label}
        style={styles.action.root}
        labelStyle={labelStyle}
        label={label}
        hoverColor={buttonColor}
        backgroundColor={buttonColor}
        onClick={toggle.bind(this, label)}
      />
    );
  };
  render() {
    const { readOnly, embedded } = this.props;
    this.synchToothActionEventStream = this.state.toothActionEventStream;
    let minimized = this.state.minimized;
    let close = this.state.close;
    let embeddedStyle = {};
    if (embedded) {
      close = false;
      minimized = false;
      embeddedStyle = { position: "inherit", width: "100%", marginTop: 6 };
    }
    return (
      <div style={{ ...styles.toothChartWrapper, ...embeddedStyle }}>
        {!embedded && (
          <FlatButton
            style={styles.toothChartLabel}
            onClick={() => {
              this.setState({ close: false });
            }}
          >
            Tooth Chart
          </FlatButton>
        )}
        {!close && (
          <div style={{ ...styles.container, ...embeddedStyle }}>
            <div style={styles.maxContainerWidth}>
              {
                <div style={styles.controlContainer}>
                  {!minimized && !readOnly && (
                    <FlatButton label="Undo" onClick={this.undo} />
                  )}
                  {!embedded && (
                    <FlatButton
                      style={styles.closeButton}
                      label="Close"
                      onClick={() => {
                        this.setState({ close: true });
                      }}
                    />
                  )}
                  {!embedded && (
                    <FlatButton
                      style={styles.minimizeButton}
                      label={
                        this.state.minimized ? "Expand tooth chart" : "Minimize"
                      }
                      onClick={this.toggleMinimize}
                    />
                  )}
                </div>
              }
              {!minimized && (
                <div style={styles.toothListContainer}>
                  <div style={styles.upperRightContainer} id="UR">
                    {TEETH_QUADRANT_NUMBERING_RIGHT.map(
                      this.renderTooth.bind(this, "UR")
                    )}
                  </div>
                  <div style={styles.upperLeftContainer} id="UL">
                    {TEETH_QUADRANT_NUMBERING_LEFT.map(
                      this.renderTooth.bind(this, "UL")
                    )}
                  </div>
                  <div style={styles.lowerRightContainer} id="LR">
                    {TEETH_QUADRANT_NUMBERING_RIGHT.map(
                      this.renderTooth.bind(this, "LR")
                    )}
                  </div>
                  <div style={styles.lowerLeftContainer} id="LL">
                    {TEETH_QUADRANT_NUMBERING_LEFT.map(
                      this.renderTooth.bind(this, "LL")
                    )}
                  </div>
                  <div style={styles.label.right}>Right</div>
                  <div style={styles.label.left}>Left</div>
                  {/* <div style={styles.label.maxillary}>Maxillary</div> */}
                  <div style={styles.label.lingual}>
                    <span style={styles.label.span}>Lingual</span>
                  </div>
                  {/* <div style={styles.label.mandibular}>Mandibular</div> */}
                </div>
              )}
              {!minimized && !readOnly && (
                <div style={styles.buttonContainer}>
                  {ACTIONS.map(
                    this.renderButton.bind(
                      this,
                      this.state.activeAction,
                      this.toggleActiveAction
                    )
                  )}
                  <span style={styles.S.verticalDivider} />
                  {ACTIONS_WITH_STATUS.includes(this.state.activeAction) &&
                    STATUSES.map(
                      this.renderButton.bind(
                        this,
                        this.state.activeStatus,
                        this.toggleActiveStatus
                      )
                    )}
                  {this.state.activeAction === "Elastics" && [
                    <span style={styles.S.verticalDivider} key="1" />,
                    <span style={styles.elasticLabel} key="Elastic Number">
                      Elastic Number
                    </span>,
                    ELASTIC_LABEL_LIST.map(
                      this.renderButton.bind(
                        this,
                        this.state.activeElasticLabel,
                        this.toggleActiveElasticLabel
                      )
                    ),
                    <span style={styles.S.verticalDivider} key="2" />,
                    <span style={styles.elasticLabel} key="Strength">
                      Strength
                    </span>,
                    ELASTIC_TYPE_LIST.map(
                      this.renderButton.bind(
                        this,
                        this.state.activeElasticType,
                        this.toggleActiveElasticType
                      )
                    ),
                  ]}
                  {this.state.activeAction === "IPR" && [
                    <span style={styles.S.verticalDivider} key="1" />,
                    IPR_TYPE_LIST.map(
                      this.renderButton.bind(
                        this,
                        this.state.activeIPRType,
                        this.toggleActiveIPRType
                      )
                    ),
                  ]}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default enhance(ToothChart);
