import styled from "react-emotion";

export const Layout = styled.div`
  min-width: 450px;
  padding: 8px 0;
`;

export const Title = styled.div`
  font-weight: bold;
  padding-bottom: 16px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-items: flex-start;
`;
