const initialState = {
  errorList: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      if (action.error) {
        let error = action.error.error;
        if (typeof error === "string") {
          error = [`${action.error.status} - ${error}`];
        }
        return {
          errorList: error,
        };
      }
      return state;
  }
}

// export function updateUI(data) {
//   return {
//     type: UPDATE_UI,
//     data,
//   }
// }
