import React from "react";
import { DateTime } from "luxon";
import { Avatar } from "antd";
import { Wrapper, Content, Meta, Text } from "./styles";

type Props = {
  note: {
    author: { name: string };
    created_at: string;
    text: string;
  };
};

const NoteItem: React.SFC<Props> = ({ note }) => (
  <Wrapper>
    <Avatar size="default" style={{ marginRight: "1rem" }}>
      {note.author.name}
    </Avatar>
    <Content>
      <Meta>
        <span>{note.author.name}</span>
        <span>{DateTime.fromISO(note.created_at).toFormat("h:mma, DD")}</span>
      </Meta>
      <Text>{note.text}</Text>
    </Content>
  </Wrapper>
);

export default NoteItem;
