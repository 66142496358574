// @flow
import React, { PureComponent } from "react";
import { Collapse, message } from "antd";
import Button, { ButtonRow } from "src/shared/Button";
import { Context as UrqlContext } from "src/utils/http/gqlQuery";
import { fetchSubmissions } from "src/state/submissions/queries";
import { sortBySubmitOrCreateTime } from "src/shared/submissions";
import Modal from "src/shared/Modal";
import { attributesToV2 } from "src/shared/ToothChartV2/versioning";
import { getTXP } from "src/utils/download";
import ViewFormQuestion from "./FormQuestion";
import PhaseComponent from "./TreatmentSetup/Phase";
import Summary from "./TreatmentSetup/Summary";
import { Content, toothChartCss } from "./Shared";
import SubmissionWrapper from "./SubmissionWrapper";
import FileModal from "./TreatmentSetup/FileModal";
import { parseTXP, emrPhaseType } from "src/utils/txp/txp";
import { getSubmissionAnswer } from "./answers";

// addPreviousProcedures iterates through each phase and cascades procedures
// from one phase to the next.
//
// This means that if phase 1 has IPR, phase 2 should also include that IPR
// in the "previousProcedures" key.
const addPreviousProcedures = phases => {
  let procedureSum = [];
  return phases.reduce((newPhases, p) => {
    const copy = newPhases.concat(
      Object.assign({}, p, { previousProcedures: procedureSum.slice() })
    );
    procedureSum = procedureSum.concat(p.procedures);
    return copy;
  }, []);
};

async function fetchBVAttributes(patientId, forms, urqlClient) {
  let bvForm = null;
  for (const form of Object.values(forms)) {
    if (form.slug === "beginning_visit") {
      bvForm = form;
      break;
    }
  }
  if (!bvForm) {
    return [];
  }

  const bvResult = await fetchSubmissions(urqlClient, bvForm.id, patientId);
  if (!bvResult.data) {
    return [];
  }
  const bvSubmissions = sortBySubmitOrCreateTime(bvResult.data.submissions);
  const bvLatest = bvSubmissions.find(it => !!it.approvedAt);
  if (!bvLatest || !bvLatest.toothData) {
    return [];
  }
  try {
    const bvToothData = JSON.parse(bvLatest.toothData);
    return attributesToV2(bvToothData);
  } catch (err) {
    console.error("bvLatest.toothData parse error", err);
    return [];
  }
}

class TreatmentSetup extends PureComponent {
  constructor() {
    super();
    this.state = {
      modal: false,
      downloading: false,
      bvAttributes: [],
    };
  }

  componentDidMount = () => {
    this._fetchBVAttributes();
  };

  async _fetchBVAttributes() {
    const bvAttributes = await fetchBVAttributes(
      this.props.patient.id,
      this.props.forms,
      this.props.urqlClient
    );
    this.setState({
      bvAttributes,
    });
  }

  onAddPhase = () => {
    const index = this.props.toothData.length;
    this.onUpdatePhase(index, {
      type: "",
      upper_stages: 0,
      lower_stages: 0,
      procedures: [],
      previousProcedures: [],
    });
  };

  onUpdatePhase = (index, phase) => {
    const copy = this._toothDataWithUpdatedPhase(
      index,
      phase,
      this.props.toothData
    );
    void this._saveToothData(copy);
  };

  _toothDataWithUpdatedPhase = (index, phase, toothData) => {
    return [...toothData.slice(0, index), phase, ...toothData.slice(index + 1)];
  };

  _saveToothData = async newToothData => {
    await this.props.onToothChartUpdateAsync(
      addPreviousProcedures(newToothData)
    );
  };

  onRemovePhase = index => {
    const copy = this.props.toothData.slice();
    copy.splice(index, 1);
    void this._saveToothData(copy);
  };

  onSelectFile = async id => {
    let blob;
    try {
      this.setState({ modal: false, downloading: true });
      blob = await getTXP(id);
    } catch (err) {
      message.error(`There was an error loading the .txp file: ${err.message}`);
      this.setState({ modal: false, downloading: false });
      throw err;
    }

    let txp;

    try {
      txp = await parseTXP(blob);
    } catch (err) {
      message.error(`There was an error parsing the .txp file`);
      this.setState({ modal: false, downloading: false });
      throw err;
    }

    if (txp && !txp.phases) {
      message.error(`There was an error parsing the .txp file`);
      this.setState({ modal: false, downloading: false });
      throw new Error(`There was an error parsing the .txp file`);
    }

    // Run through all the phases and accrue changes in `tempToothData`
    let tempToothData = this.props.toothData.slice();
    let tempToothDataIndex = 0;
    txp.phases
      // Create each phase in the tooth data of tx setup form.
      .forEach((phase, index) => {
        if (
          phase.type === "Refinement Preview" ||
          phase.type === "Doctor's Adjustment"
        )
          return;
        tempToothData = this._toothDataWithUpdatedPhase(
          tempToothDataIndex,
          {
            // Normalize the phase types from tx planner format to ours
            type: emrPhaseType(phase.type),
            upper_stages: phase.mxKeyframes - 1,
            lower_stages: phase.mnKeyframes - 1,
            procedures: txp.toothChart(phase),
            previousProcedures: [],
          },
          tempToothData
        );
        tempToothDataIndex += 1;
      });

    try {
      await this._saveToothData(tempToothData);
      message.success("Treatment plan pre-filled");
    } catch {
      message.error(`There was a problem pre-filling the form, try again.`);
    } finally {
      this.setState({ modal: false, downloading: false });
    }
  };

  render() {
    const {
      submission,
      form,
      sessionAnswers,
      editable,
      // Phase specific
      toothData,
      patient,
    } = this.props;

    const isSubmitted = !!submission.submitted_at;

    // stageCount lets us take a total stage count over each phase, adding the stage range
    // for each phase component.
    let stageCount = 0;

    return (
      <SubmissionWrapper {...this.props}>
        {this.state.modal && (
          <Modal
            onClose={() => {
              this.setState({ modal: false });
            }}
          >
            <FileModal
              userID={this.props.patient.id}
              onSelect={this.onSelectFile}
            />
          </Modal>
        )}

        {/* Allow users to select or upload a TXP file */}
        <ButtonRow position="center">
          <Button
            kind="primary"
            disabled={this.state.downloading}
            onClick={() => {
              this.setState({ modal: true });
            }}
          >
            {this.state.downloading
              ? "Downloading and processing treatment plan..."
              : "Select a treatment planning file"}
          </Button>
        </ButtonRow>

        <Collapse bordered={false} defaultActiveKey={["1"]}>
          <Collapse.Panel
            header="Phase setup"
            className={toothChartCss}
            key="1"
          >
            {toothData.map((p, n) => {
              // Is this a phase?  If not, return null.
              // TODO: Why does tooth data carry over here from BV form?
              if (p.tooth_name) {
                return null;
              }

              const component = (
                <PhaseComponent
                  userID={this.props.patient.id}
                  submissionID={submission.id}
                  cumulativeCutoff={submission.submitted_at}
                  disabled={isSubmitted}
                  index={n}
                  phase={p}
                  stageStart={stageCount + 1}
                  updatePhase={phase => this.onUpdatePhase(n, phase)}
                  removePhase={() => this.onRemovePhase(n)}
                  bvAttributes={this.state.bvAttributes}
                  key={n}
                />
              );

              const upper_stages =
                p.upper_stages === undefined ? p.stages : p.upper_stages;
              const lower_stages =
                p.lower_stages === undefined ? p.stages : p.lower_stages;

              const max = Math.max(upper_stages, lower_stages);

              stageCount += max;
              return component;
            })}
            <Button onClick={this.onAddPhase}>Add phase</Button>
          </Collapse.Panel>
        </Collapse>

        <Content>
          {form.forms_questions.map((q, n) => {
            return (
              <ViewFormQuestion
                allAnswers={sessionAnswers.concat(submission.answers || [])}
                answer={getSubmissionAnswer({
                  answers: submission.answers,
                  questionID: q.id,
                  submissionID: submission.id,
                })}
                onChange={value => this.props.onAnswer(q.id, value)}
                editable={editable}
                key={q.id}
                q={q}
                submission={submission}
                patient={patient}
              />
            );
          })}

          <Summary data={toothData} />
        </Content>
      </SubmissionWrapper>
    );
  }
}

const WrappedTreatmentSetup = props => (
  <UrqlContext.Consumer>
    {urqlClient => {
      return <TreatmentSetup urqlClient={urqlClient} {...props} />;
    }}
  </UrqlContext.Consumer>
);

export default WrappedTreatmentSetup;
