import isNil from "lodash/isNil";

import {
  FiltersItemActionData,
  FiltersItemActions,
  FiltersItemProps,
  FilterItemSignals,
} from "./types";
import { FiltersContextValue } from "../../FiltersContext/types";

const actions: FiltersItemActionData[] = [
  { key: "rename", title: "Rename" },
  { key: "share", title: "Share" },
  { key: "accept", title: "Accept" },
  { key: "delete", title: "Delete" },
];

export const buildActionsList = (
  props: FiltersItemProps
): FiltersItemActionData[] => {
  const { id, state } = props;

  const isUnsaved = isNil(id);
  const isAccepted = state === "accepted";
  const isWaiting = state === "waiting";

  const actionsEnabled: Partial<Record<FiltersItemActions, boolean>> = {
    rename: isAccepted,
    // TODO remove false when implementing these actions logic
    share: isAccepted && false,
    accept: isWaiting && false,
    delete: !isUnsaved && false,
  };

  return actions.filter(item => actionsEnabled[item.key]);
};

export const getInputValue = (elem: HTMLElement | null) => {
  if (!elem) {
    return "";
  }

  const input = elem.querySelector("input");

  return input ? input.value : "";
};

export const createFilterHandler = (data: {
  title: string;
  context: FiltersContextValue;
  nextState: UnaryFn<FilterItemSignals, void>;
}) => {
  const { title, nextState, context } = data;
  const { createFilter, resetFilterPanelFilters } = context;

  if (!title.length) {
    return nextState("error");
  }

  createFilter({ title })
    .then(() => {
      nextState("success");
      resetFilterPanelFilters();
    })
    .catch(() => nextState("error"));
};

export const replaceFilterHandler = (data: {
  id: string;
  context: FiltersContextValue;
  nextState: UnaryFn<FilterItemSignals, void>;
}) => {
  const { id, context, nextState } = data;
  const { replaceFilter, resetFilterPanelFilters } = context;

  replaceFilter({ id })
    .then(() => {
      resetFilterPanelFilters();
      nextState("success");
    })
    .catch(() => nextState("error"));
};

export const renameFilterHandler = (data: {
  id?: string;
  newTitle: string;
  context: FiltersContextValue;
  nextState: UnaryFn<FilterItemSignals, void>;
}) => {
  const { id, newTitle, context, nextState } = data;
  const { renameFilter, resetFilterPanelFilters } = context;

  if (!newTitle.length || !id) {
    return nextState("error");
  }

  renameFilter({ id, newTitle })
    .then(() => {
      resetFilterPanelFilters();
      nextState("success");
    })
    .catch(() => nextState("error"));
};
