import React, { PureComponent } from "react";
import {
  compose,
  lifecycle,
  withProps,
  branch,
  renderComponent,
} from "recompose";
import styled from "react-emotion";
import { Button } from "antd";
import { BuildsEnhancer } from "src/state/Builds";
import Loading from "src/shared/Loading";
import TrayBuilds from "./TrayBuilds";
import type { Build } from "src/types/api";

type PassedProps = {
  build: Build,
};

type Props = PassedProps;

const Actions = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled("div")`
  display: flex;

  > button {
    cursor: pointer;
    margin-left: 1rem;
  }
`;

const modifiers = compose(
  BuildsEnhancer,
  lifecycle({
    componentDidMount() {
      this.props.actions.getDetailedBuild(this.props.build.id);
    },
  }),
  withProps((props: Props) => {
    return {
      detailedBuild: props.detailedTrayBuilds[props.build.id],
    };
  }),
  branch(props => !props.detailedBuild, renderComponent(Loading))
);

class BuildInfo extends PureComponent<Props, void> {
  onMarkSent = () => this.onUpdate({ sent_at: new Date().toISOString() });

  onMarkReceived = () =>
    this.onUpdate({ actual_receipt_at: new Date().toISOString() });

  onUpdate = (props: Object) => {
    const { build, actions } = this.props;
    actions.updateBuild(build.id, {
      build: props,
    });
  };

  render() {
    const { build, detailedBuild } = this.props;

    return (
      <div>
        <Actions>
          <div>
            <p>Build: {build.id}</p>
            <p>Arches: {build.tray_builds.length}</p>
          </div>
          <Buttons>
            {!build.sent_at && (
              <Button onClick={this.onMarkSent}>Mark sent</Button>
            )}
            {!build.actual_receipt_at && (
              <Button onClick={this.onMarkReceived}>Mark received</Button>
            )}
          </Buttons>
        </Actions>

        <TrayBuilds builds={detailedBuild.tray_builds} />
      </div>
    );
  }
}

export default modifiers(BuildInfo);
