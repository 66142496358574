import React, { useEffect, useState, useContext } from "react";
import styled, { css } from "react-emotion";

import Popover from "src/shared/Popover";
import SelectList from "src/shared/SelectList";
import PatientSearchPopover from "src/shared/Popovers/PatientSearch";
import TaskTypePopover from "src/shared/Popovers/TaskTypePopover";
import StaffPopover from "src/shared/Popovers/StaffPopover";
import PipelineTypePopover from "src/shared/Popovers/PipelineTypePopover";
import HomeClinicPopover from "src/shared/Popovers/HomeClinicPopover";
import PatientStatusNamePopover from "src/shared/Popovers/PatientStatusNamePopover";
import DownArrow from "src/shared/Icons/DownArrow";
import { BaseUser } from "src/shared/PatientSearch/PatientSearch";
import { Staff } from "src/types/gql";
import { useClinics } from "src/scenes/NewPatient/query";
import color from "src/styles/color";
import { titleCase, toggle } from "src/shared/util";

import { Filters } from "../FiltersContext/types";
import {
  PipelineTypes,
  TaskStatus,
  TaskTypes,
  TreatmentLevel,
} from "../../types";
import { treatmentLevelTypes } from "../../constants";
import { Row, RowContent } from "../../Shared/Row";
import { FiltersContext } from "../FiltersContext/FiltersContext";
import { TableHeaderProps } from "./types";

export const TableHeader: React.FC<TableHeaderProps> = props => {
  const { currentPatientStatusesMap, routeFilter, showCompletedAt } = props;
  const { setTableHeaderFilters } = useContext(FiltersContext);

  const [assignedStaff, setAssignedStaff] = useState<Staff[]>([] as Staff[]);
  const [patient, setPatient] = useState<BaseUser | undefined>();
  const [taskTypes, setTaskTypes] = useState<Defined<Filters["taskTypes"]>>([]);
  const [status, setStatus] = useState<TaskStatus | undefined>(
    "unblocked" as TaskStatus
  );
  const [pipelineTypes, setPipelineTypes] = useState<PipelineTypes[]>([]);
  const [patientStatusNames, setPatientStatusNames] = useState<string[]>(
    [] as string[]
  );
  const [homeClinicIDs, setHomeClinicIds] = useState<string[]>([] as string[]);
  const [treatmentLevels, setTreatmentLevels] = useState<
    Defined<Filters["treatmentLevels"]>
  >([]);
  const [, , clinics] = useClinics();
  const allHomeClinics = new Map<string, string>();
  clinics.forEach(clinic => {
    allHomeClinics.set(clinic.id, clinic.name);
  });

  useEffect(() => {
    const filter = {} as Filters;

    if (patient) {
      filter.userID = patient.id;
    }
    if (taskTypes.length > 0) {
      filter.taskTypes = taskTypes;
    }
    if (assignedStaff.length > 0) {
      filter.assignees = {
        assigneeIDs: assignedStaff.map(s => s.id),
        unassigned: false,
      };
    }
    if (status) {
      filter.status = status;
    }
    if (pipelineTypes.length > 0) {
      filter.pipelineTypes = pipelineTypes;
    }

    if (homeClinicIDs.length > 0) {
      filter.homeClinicIDs = homeClinicIDs;
    }

    if (treatmentLevels.length > 0) {
      filter.treatmentLevels = treatmentLevels;
    }

    if (patientStatusNames.length > 0) {
      filter.patientStatusNames = patientStatusNames;
    }

    setTableHeaderFilters(filter);
    // eslint-disable-next-line
  }, [
    assignedStaff,
    patient,
    taskTypes,
    status,
    pipelineTypes,
    homeClinicIDs,
    treatmentLevels,
    patientStatusNames,
  ]);

  return (
    <>
      <Row className={headerCSS}>
        <RowContent>
          <PatientSearchPopover value={patient} onClick={setPatient}>
            <Inner>
              Patient{" "}
              <DownArrow fill={!!patient ? color.primary : color.gray2} />
            </Inner>
          </PatientSearchPopover>
        </RowContent>
        <RowContent>
          <Popover
            content={
              <SelectList<TreatmentLevel | undefined>
                values={treatmentLevelTypes.slice()}
                value={treatmentLevels}
                onClick={treatmentLevel => {
                  if (!treatmentLevel) {
                    return;
                  }
                  setTreatmentLevels(toggle(treatmentLevels, treatmentLevel));
                }}
                render={(c: string | undefined) => titleCase(c || "")}
              />
            }
          >
            <Inner>
              Treatment Levels
              <DownArrow
                fill={treatmentLevels.length > 0 ? color.primary : color.gray2}
              />
            </Inner>
          </Popover>
        </RowContent>
        <RowContent>
          <HomeClinicPopover
            onClick={homeClinicId => {
              if (!homeClinicId) {
                return;
              }
              setHomeClinicIds(toggle(homeClinicIDs, homeClinicId));
            }}
            filterHomeClinicIds={homeClinicIDs}
            allHomeClinics={allHomeClinics}
          >
            <Inner>
              Home Clinic{" "}
              <DownArrow
                fill={homeClinicIDs.length > 0 ? color.primary : color.gray2}
              />
            </Inner>
          </HomeClinicPopover>
        </RowContent>
        <RowContent>
          <PipelineTypePopover
            onClick={t => {
              setPipelineTypes(
                toggle<PipelineTypes>(pipelineTypes, t as PipelineTypes)
              );
            }}
            value={pipelineTypes}
          >
            Pipeline Type{" "}
            <DownArrow
              fill={pipelineTypes.length > 0 ? color.primary : color.gray2}
            />
          </PipelineTypePopover>
        </RowContent>
        <RowContent>
          <TaskTypePopover
            onClick={taskType => {
              setTaskTypes(toggle<TaskTypes>(taskTypes, taskType as TaskTypes));
            }}
            filteredTaskTypes={taskTypes}
          >
            Task{" "}
            <DownArrow
              fill={taskTypes.length > 0 ? color.primary : color.gray2}
            />
          </TaskTypePopover>
        </RowContent>
        <RowContent>
          {routeFilter !== "my" ? (
            <StaffPopover
              onClick={(staff?: Staff) => {
                if (!staff) {
                  return;
                }
                setAssignedStaff(toggle(assignedStaff, staff));
              }}
              value={assignedStaff}
            >
              <Inner>
                Assignee{" "}
                <DownArrow
                  fill={assignedStaff.length > 0 ? color.primary : color.gray2}
                />
              </Inner>
            </StaffPopover>
          ) : (
            <Inner>Assignee</Inner>
          )}
        </RowContent>
        <RowContent>
          {showCompletedAt ? "Completed at" : "Task due date"}
        </RowContent>
        <RowContent>
          Weeks <DownArrow />
        </RowContent>
        <RowContent>
          <Popover
            content={
              <SelectList<TaskStatus | undefined>
                values={["unstarted", "in_progress", "unblocked"]}
                value={[status] as Array<TaskStatus | undefined>}
                onClick={val => {
                  if (val === status) {
                    val = undefined;
                  }
                  setStatus(val);
                }}
                render={(c: TaskStatus | undefined) => titleCase(c || "")}
              />
            }
          >
            <Inner>
              Status
              <DownArrow fill={!!status ? color.primary : color.gray2} />
            </Inner>
          </Popover>
        </RowContent>
        {currentPatientStatusesMap && (
          <RowContent>
            <PatientStatusNamePopover
              onClick={n => {
                setPatientStatusNames(
                  toggle<string>(patientStatusNames, n as string)
                );
              }}
              value={patientStatusNames}
            >
              Patient Status Name{" "}
              <DownArrow
                fill={
                  patientStatusNames.length > 0 ? color.primary : color.gray2
                }
              />
            </PatientStatusNamePopover>
          </RowContent>
        )}
      </Row>
    </>
  );
};

const Inner = styled.div`
  cursor: pointer;

  svg {
    margin: 0 0 0 6px;
  }
`;

const headerCSS = css`
  font-weight: bold;
  border-top: 0;
`;
