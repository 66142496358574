import React from "react";
import style from "./style";

// TODO: Logo
const Item = ({ patient, text }) => (
  <div style={style.item}>
    <div style={style.logo}>
      <img src="/label-logo.png" style={style.logoImg} alt="label-logo" />
    </div>
    <div style={style.content}>
      <p style={style.text}>{patient}</p>
      <p style={style.text}>{text}</p>
    </div>
  </div>
);

export default Item;
