import React from "react";
import BVModal from "./BVModal";

interface Props {
  patientID: string;
  onClose: () => void;
  refetch: () => void;
}

const SOF = (props: Props) => (
  <BVModal
    buttonText="Generate SOF"
    generatedMessage="SOF generated"
    getURL={(id: string) => `/api/v2/sofs/${id}/pdf`}
    onClose={props.onClose}
    patientID={props.patientID}
    refetch={props.refetch}
    title="Select a BV for the new SOF"
  />
);

export default SOF;
