import React from "react";
import styled from "react-emotion";
import { phaseMapping } from "src/shared/util";
import color from "src/styles/color";

type PhaseHeaderProps = {
  phase: string;
  index: number;
  status: string;
  hideOptions?: boolean;
  isWearable?: boolean;
  // used when setting wearable status
  wtsIDs: string[];
  selected?: boolean;
  onToggle?: () => void;
};

const PhaseHeader: React.FC<PhaseHeaderProps> = ({
  phase,
  isWearable,
  selected,
  onToggle,
}) => {
  return (
    <Container>
      <div>
        <input
          type="checkbox"
          checked={selected}
          onChange={() => onToggle && onToggle()}
        />
      </div>
      {phaseMapping[phase] || "Unknown phase"} {!isWearable && " - Unwearable"}
    </Container>
  );
};

export const Container = styled.div`
  padding: 10px;
  background-color: ${color.background3};
  font-weight: bold;
  border-top: 1px solid ${color.border};
  border-bottom: 1px solid ${color.border};

  display: flex;

  > div:first-of-type {
    padding: 0 15px;
    display: flex;
    align-items: center;
  }
`;

export default PhaseHeader;
