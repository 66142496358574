import React, { createContext } from "react";
import noop from "lodash/noop";

import ConfirmModal from "src/shared/ConfirmModal";

import { FiltersProps, FiltersContextValue } from "./types";
import { useFiltersContextValue, useUnsavedChangesWarning } from "./hooks";

export const FiltersContext = createContext<FiltersContextValue>({
  appliedFilters: {},
  allFilters: {
    accepted: [],
    waiting: [],
  },
  hasUnsavedFilter: false,

  setFilterPanelFilters: noop,
  setTableHeaderFilters: noop,
  setSelectedFilter: noop,
  resetFilterPanelFilters: noop,
  getSelectedFilter: noop,

  createFilter: Promise.reject.bind(Promise),
  replaceFilter: Promise.reject.bind(Promise),
  renameFilter: Promise.reject.bind(Promise),
});

export const Filters: React.FC<FiltersProps> = props => {
  const { children } = props;
  const value = useFiltersContextValue(props);
  const {
    isUnsavedFilterConfirmOpen,
    handleConfirm,
    handleClose,
  } = useUnsavedChangesWarning(props, value);

  return (
    <>
      <FiltersContext.Provider value={value}>
        {children}
      </FiltersContext.Provider>
      {isUnsavedFilterConfirmOpen && (
        <ConfirmModal
          type="warn"
          title="Unsaved Filter?"
          text={
            "Are you sure you want to leave this page? Your filter will be lost."
          }
          actionText="Yes"
          onClose={handleClose}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
};

export const withFilters = <P extends FiltersProps>(
  Component: React.ComponentType<P>
) => {
  return (props: P) => (
    <Filters {...props}>
      <Component {...props} />
    </Filters>
  );
};
