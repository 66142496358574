import React from "react";
import { CrownProps, RootProps } from "./types";

export const Crown: React.SFC<CrownProps> = ({ fill, stroke, style }) => (
  <svg
    width="33"
    height="24"
    viewBox="0 0 33 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M17.0866 19.6185C16.4826 19.0288 15.9541 19.4956 15.7653 19.8028C14.8216 21.0927 10.2917 24.7783 4.6294 21.4613C0.0995207 18.8077 1.60948 11.8788 2.93069 8.74606C5.76187 3.21772 9.31384 2.70116 10.6692 2.48061C11.8017 2.29633 14.507 2.60346 15.7653 2.84916C16.4574 2.97201 18.068 3.0703 18.974 2.48061C19.88 1.89092 21.1131 1.7435 21.6164 1.7435C27.845 1.55922 31.6199 9.85174 31.4312 15.1958C31.2424 20.5399 27.845 22.1984 24.2589 22.7512C20.6727 23.304 17.8415 20.3556 17.0866 19.6185Z"
      fill={fill || "#fff"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />
  </svg>
);

// Root always contains the portion as if it attached as a Crown.
export const Root: React.SFC<RootProps> = ({
  hasRootCanal,
  style,
  fill,
  stroke,
}) => (
  <svg
    width="30"
    height="50"
    viewBox="0 0 30 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M7.9912 5.81174C8.14289 8.01896 7.29593 12.2495 6.85349 14.0888L14.4382 31.1947H20.3164V19.9747L19.7476 13.7209C18.357 10.9006 14.8933 4.63456 12.1628 2.13305C8.74967 -0.993831 7.80158 3.05273 7.9912 5.81174Z"
      fill={fill || "#C4D0D5"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />
    <path
      d="M5.5 35.5C6 38.5 6 40.8374 6 43H13V49H19V43H28C27.0963 35.2887 28.7001 27.3469 29.0766 22.8363C29.3779 16.4597 26.1899 10.7875 24.5582 8.74844C21.0439 5.41184 14.8059 1.07426 17.9688 10.4167C21.1317 19.7592 18.2826 27.1615 16.4627 29.6949C11.3418 20.9456 7.30032 10.6021 5.91969 6.52404C5.35489 4.29964 2.71913 3.18744 1.77779 6.52404C0.83645 9.86064 0.459914 15.0509 2.34259 21.3534C4.22527 27.6558 4.95147 32.2088 5.5 35.5Z"
      fill={fill || "#F1F4F5"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />

    {hasRootCanal && (
      <g transform="translate(3,2.5)">
        <path
          d="M0.999979 0C-1 14 8 35.5 6 37H20.5C24.674 22.8286 18.8261 4.92857 16 0.5C22.5 14 20.9131 27.0476 17 30C13.8695 32.3619 11.2818 30.6255 10.5 30C5.5 26 0.5 12 0.999979 0Z"
          fill="#C270A7"
        />
      </g>
    )}
  </svg>
);
