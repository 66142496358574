import React, { useState } from "react";
import styled from "react-emotion";
import { DateTime } from "luxon";
import { notification } from "antd";
// shared
import color from "src/styles/color";
import Button, { ButtonRow } from "src/shared/Button";
import SelectList from "src/shared/SelectList";
import { BaseUser } from "src/shared/PatientSearch/PatientSearch";
import Popover from "src/shared/Popover";
import { titleCase } from "src/shared/util";
import InputText from "src/shared/InputText";
// local
import Label from "../Shared/Label";
import AssignPatientIcon from "../Icons/AssignPatient";
import DueDateIcon from "../Icons/DueDate";
import DatePopover from "src/shared/Popovers/Date";
import PipelineType from "../PipelineType";
import PatientSearchPopover from "src/shared/Popovers/PatientSearch";
import InvisibleButton from "./InvisibleButton";
import { PipelineTemplate } from "../types";
import { useMultiTaskPipelines, useNewPipeline } from "../usePipelineTemplates";
import { useNewHold } from "../useHolds";
import { holdPipelineTypes, holdCategories } from "../holds";

type Props = {
  onCancel: () => void;
  onComplete: () => void;

  // user represents the pre-filled user, if applicable.
  user?: BaseUser;
};

// you can either select a pipeline template or "hold", at which point you have to select the
// actual template after.
type HoldItem = { id: "hold"; type: "Pre-tx task" };
type SelectItem = PipelineTemplate | HoldItem;

const NewTask: React.FC<Props> = ({ onCancel, onComplete, user }) => {
  const [{ data }, execPipelineQuery] = useMultiTaskPipelines();
  const create = useNewPipeline();
  const createHold = useNewHold();

  const nonHolds = data
    ? data.pipelines.filter(p => !holdPipelineTypes.includes(p.type))
    : [];

  // task states
  const [pipeline, setPipeline] = useState<PipelineTemplate | undefined>();
  const [patient, setPatient] = useState<BaseUser | undefined>(user);
  const [dueDate, setDueDate] = useState<string | undefined>();
  const [description, setDescription] = useState<string | undefined>();
  // hold information
  const [isHold, setIsHold] = useState(false);
  const [holdCategory, setHoldCategory] = useState<string | undefined>();

  const isPipelineValid = !!patient && (!!pipeline || !!holdCategory);

  const onSave = async () => {
    if (!isPipelineValid || !patient || (!pipeline && !holdCategory)) {
      return;
    }

    let execFunction;
    if (isHold && holdCategory) {
      execFunction = () =>
        createHold({
          input: {
            userID: patient.id,
            category: holdCategory,
            description,
            dueAt: dueDate,
          },
        });
    }

    if (pipeline) {
      execFunction = () =>
        create({
          input: {
            userID: patient.id,
            pipelineTemplateID: pipeline.id,
            pipelineOpts: {
              dueAt: dueDate,
            },
            opts: [],
          },
        });
    }

    if (!execFunction) {
      return;
    }

    notification.info({ message: "Creating pipeline" });

    const result = await execFunction();

    if (result.error) {
      notification.error({
        message: `Error creating pipeline: ${result.error}`,
      });
      return;
    }

    notification.success({ message: `Pipeline created` });

    execPipelineQuery();

    onComplete();
  };

  const availablePipelines = (data
    ? nonHolds.filter(p => !p.hidden)
    : []
  ).concat([
    {
      id: "hold",
      type: "Pre-tx task",
      hidden: false,
      taskTemplates: [],
      taskEdgeTemplates: [],
    },
  ]);

  return (
    <>
      <Wrapper>
        <div>
          <Popover
            content={
              <SelectList<SelectItem>
                onClick={item => {
                  if (item && item.id === "hold") {
                    setPipeline(undefined);
                    setIsHold(true);
                    return;
                  }
                  setIsHold(false);
                  setPipeline(item as PipelineTemplate | undefined);
                }}
                values={availablePipelines}
                render={pt => (
                  <span key={pt.id}>
                    <PipelineType type={pt.type} />
                  </span>
                )}
              />
            }
          >
            <h1>
              {isHold &&
                !holdCategory &&
                "Pre-tx task (Prevents aligner production)"}
              {isHold &&
                holdCategory &&
                `Pre-tx task: ${titleCase(holdCategory)}`}
              {pipeline && titleCase(pipeline.type.replace(/_/g, " "))}
              {!isHold && !pipeline && <span>Choose pipeline</span>}
            </h1>
          </Popover>

          <Label style={{ marginTop: "2rem" }}>
            <span>Patient</span>
            <PatientSearchPopover value={patient} onClick={setPatient}>
              <InvisibleButton>
                <AssignPatientIcon />
                {patient ? (
                  <b>
                    {patient.firstName}{" "}
                    {patient.preferredName
                      ? `(${patient.preferredName})`
                      : null}{" "}
                    {patient.lastName}{" "}
                    {patient.pronouns ? `(${patient.pronouns})` : null}
                  </b>
                ) : (
                  "Assign patient"
                )}
              </InvisibleButton>
            </PatientSearchPopover>
          </Label>

          {isHold && (
            <Label>
              <span>Task type</span>
              <Popover
                content={
                  <SelectList<string>
                    onClick={item => {
                      setHoldCategory(item);
                    }}
                    values={holdCategories.filter(
                      category => category !== "treatment_terminated"
                    )}
                    render={category => (
                      <span key={category}>{titleCase(category)}</span>
                    )}
                  />
                }
              >
                {holdCategory ? titleCase(holdCategory) : "Select task type"}
              </Popover>
            </Label>
          )}

          <Label>
            <span>
              {holdCategory === "observation" ? "Recall date" : "Due date"}
            </span>
            <DatePopover onClick={setDueDate} value={dueDate}>
              <InvisibleButton>
                {dueDate ? (
                  <Date>{DateTime.fromISO(dueDate).toLocaleString()}</Date>
                ) : (
                  <>
                    <DueDateIcon />
                    {holdCategory === "observation"
                      ? "Set recall date"
                      : "Set due date"}
                  </>
                )}
              </InvisibleButton>
            </DatePopover>
          </Label>

          {holdCategory === "other" && (
            <Label style={{ marginTop: "2rem" }}>
              <span>Details</span>
              <InputText
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Label>
          )}
        </div>

        <div>
          <ButtonRow position="right">
            <Button onClick={onCancel}>Cancel</Button>
            <Button kind="primary" disabled={!isPipelineValid} onClick={onSave}>
              Create
            </Button>
          </ButtonRow>
        </div>
      </Wrapper>
    </>
  );
};

export default NewTask;

const Wrapper = styled.div`
  > div {
    padding: 24px 32px;
  }

  > div + div {
    border-top: 1px solid ${color.border};
  }

  h1 span {
    opacity: 0.6;
  }

  width: 600px;
`;

const Date = styled.div`
  color: ${color.gray5};
`;
