import React, { useState } from "react";
import styled, { css } from "react-emotion";
import Modal from "src/shared/Modal";
import Box from "src/shared/Box";
import Button, { ButtonRow } from "src/shared/Button";
import { InsurancePolicy } from "src/types/gql";
import { useDelete } from "./query";

type Props = {
  policy: InsurancePolicy;
  onClose: () => void;
};

const DeleteModal: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const execDelete = useDelete();

  const onDelete = async () => {
    setLoading(true);
    await execDelete({ id: props.policy.id });
    setLoading(false);
    props.onClose();
  };

  return (
    <Modal onClose={props.onClose}>
      <Box title="Confirm deletion">
        <Inner>
          <p>Are you sure you want to delete this policy?</p>

          <ButtonRow
            position="center"
            className={css`
              padding: 16px 0 0;
            `}
          >
            <Button onClick={props.onClose}>Cancel</Button>
            <Button onClick={onDelete} disabled={loading} kind="warning">
              {loading ? "Deleting..." : "Delete"}
            </Button>
          </ButtonRow>
        </Inner>
      </Box>
    </Modal>
  );
};

export default DeleteModal;

const Inner = styled.div`
  padding: 30px;
`;
