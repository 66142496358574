import React from "react";
import styled from "react-emotion";
import Button from "src/shared/Button";
import color from "src/styles/color";
import textStyles from "src/styles/textStyles";
import LegacyModal from "src/shared/LegacyModal";

interface Props {
  closeModal: () => void;
  onOk: () => void;
  okLoading?: boolean;
  warningText: string;
}

const Actions = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
`;

const Warning = styled("h3")`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 3px solid #ddd;
  border-color: ${color.primary};
  color: ${color.primary};
  margin: 40px 0 20px;
  text-align: center;
`;

const Text = styled("p")`
  color: ${color.gray3};
  ${textStyles("medium")};
  margin-bottom: 2px;
`;

const WarningModal = (props: Props) => {
  return (
    <LegacyModal
      isOpen={true}
      width={320}
      openModal={() => {}}
      closeModal={props.closeModal}
    >
      <LegacyModal.Content>
        <Content>
          <Warning>X</Warning>
          <h5>Are you sure?</h5>

          <Text>{props.warningText}</Text>
          <Text>This process cannot be undone.</Text>
        </Content>
      </LegacyModal.Content>

      <LegacyModal.Footer>
        <Actions>
          <Button onClick={props.closeModal}>Cancel</Button>
          <Button
            type="primary"
            onClick={() => props.onOk()}
            loading={props.okLoading}
          >
            Confirm
          </Button>
        </Actions>
      </LegacyModal.Footer>
    </LegacyModal>
  );
};

export default WarningModal;
