import React from "react";
import styled, { css } from "react-emotion";
import Child from "./Child";
import color from "src/styles/color";

interface Props {
  active: boolean;
  onClick: () => void;

  className?: string;
  children: React.ReactNode;
  icon: React.ReactNode;
  activeIcon?: React.ReactNode;
}

const mainDiam = 64;
const mainRadii = mainDiam / 2;

const childDiam = 40;
const childRadii = childDiam / 2;
const minMargin = 30;

const offset = mainRadii - childRadii;

const Wrapper = styled.div`
  position: relative;
  height: ${mainDiam}px;
  width: ${mainDiam}px;
`;

const Button = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${mainDiam}px;
  width: ${mainDiam}px;
  border-radius: 100rem;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border: 1px solid ${color.primary};
  background: ${color.white};
  z-index: 2;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-3px);
  }
`;

const Inner = styled.div`
  > div {
    transition: all 0.3s;
    z-index: 1;
  }
`;

const hidden = css`
  ${Button} {
    background: ${color.primary};
  }

  ${Inner} > div {
    top: ${offset}px !important;
    left: ${offset}px !important;
    opacity: 0;
    z-index: 0;
  }
`;

const circleWidth = (count: number) => {
  // Each child is positioned on the circumference of an expanding circle outside
  // of the parent button.
  //
  // The size of this circle depends on the number of children - the greater the #
  // of children, the greater the size of the expanding circle for positioning to
  // fit each item.
  //
  // The base circle size is the radii of the main button plus the size of the min margin.
  const baseRadii = minMargin + childRadii;
  const baseDiameter = baseRadii * 2;

  // We then calculate the minimum width of the diameter we need to equally space the
  // items out on the quarter circumference we have.
  //
  // This may be larger than the base circle if there are many children to show.
  const calcDiam = ((count - 1) * childDiam + (count - 1) * minMargin) * 4;
  const calcRadii = calcDiam / 2;

  return Math.max(baseDiameter, calcRadii);
};

const renderChild = (
  item: React.ReactNode,
  index: number,
  count: number,
  radii: number
) => {
  if (!item) {
    return null;
  }
  // We know that we're positioning an item on a 90 degree section of the outer circle.
  // To calculate exactly where, we divide 90 by the total number of children to give us the
  // angle at which the two radii intersect at the corner of a given triangle.
  const angle = (90 / (count - 1)) * index;
  const y = radii * Math.sin(angle * (Math.PI / 180));
  const x = radii * Math.cos(angle * (Math.PI / 180));

  const style = {
    // We need to add the main button diameter and subtract the child diameter to get
    // the fully correct positioning.
    left: -x + offset,
    top: -y + offset,
  };

  return React.cloneElement(item as React.ReactElement<any>, { style });
};

const PopoutButton = ({
  active,
  onClick,
  icon,
  activeIcon,
  children,
}: Props) => {
  const filteredChildren = Array.from(children as Array<any>).filter(Boolean);
  const count = filteredChildren.length;
  const radii = circleWidth(count / 2);

  return (
    <Wrapper className={active ? "" : hidden}>
      <Button onClick={onClick}>{active ? activeIcon || icon : icon}</Button>

      <Inner>
        {filteredChildren.map((n, i) => renderChild(n, i, count, radii))}
      </Inner>
    </Wrapper>
  );
};

PopoutButton.Child = Child;

export default PopoutButton;
