import { Link } from "react-router";
import styled from "react-emotion";

export const PHOTOS_DROPDOWN_WIDTH = "270px";
export const TIMELINE_ITEM_SECTION_MAX_WIDTH = "200px";
export const TIMELINE_ITEM_BORDER_RADIUS = "10px";
export const TIMELINE_ITEM_ICON_SIZE = "32px";
export const TIMELINE_ITEM_LINE_ICON_WIDTH = "18px";
export const TIMELINE_ITEM_SECTION_HOVER_BG = "#F9F9F9";
export const HEADER_HEIGHT = "40px";
export const GRID_AREA_PADDING_X = "15px";
export const GRID_AREA_PADDING_Y = "10px";
export const GRID_BORDER_COLOR = "hsl(0,0%,85%)";
export const TAB_SEPARATOR_HEIGHT = "15px";
export const FONT_SIZE_LARGE = "16px";
export const FONT_SIZE = "14px";
export const PADDING = "20px";
export const PADDING_2X = "40px";
export const PADDING_HALF = "10px";
export const SELECTED_COLOR = "#FC5F22";
export const LINK_TEXT_COLOR = "#007AFF";
export const TIMELINE_BODY_BG = "#F9F9F9";
export const TIMELINE_RAIL_COLOR = "#D7D7D7";
export const TIMELINE_RAIL_WIDTH = "4px";
export const FAINT_TEXT_COLOR = "hsl(0, 0%, 60%)";
export const SUMMARY_FIELD_LABEL_COLOR = FAINT_TEXT_COLOR;
export const SUMMARY_FIELD_LABEL_WIDTH = "150px";
export const SELECTED_BORDER_SIZE = "4px";

export const LayoutGrid = styled.div`
  display: grid;
  grid-template-areas:
    "timeline-header   tablist-row"
    "timeline-body     selected-content";
  grid-template-columns: auto 1fr;
  grid-template-rows: ${HEADER_HEIGHT} 1fr;
  border: solid 1px ${GRID_BORDER_COLOR};
`;

export const TimelineHeader = styled.div`
  grid-area: timeline-header;
  padding: ${GRID_AREA_PADDING_Y} ${GRID_AREA_PADDING_X};
  border-bottom: solid 1px ${GRID_BORDER_COLOR};
  border-right: solid 1px ${GRID_BORDER_COLOR};
  display: flex;
  align-items: center;
  background: white;
  font-weight: bold;
`;

export const TimelineBody = styled.div`
  grid-area: timeline-body;
  border-right: solid 1px ${GRID_BORDER_COLOR};
  background: ${TIMELINE_BODY_BG};
  position: relative;

  padding: ${GRID_AREA_PADDING_Y} ${GRID_AREA_PADDING_X};

  /* Experimental
  width: calc(
    ${GRID_AREA_PADDING_X}*2 + ${TIMELINE_ITEM_ICON_SIZE} +
      ${GRID_AREA_PADDING_X} + ${PADDING}*2 + ${TIMELINE_ITEM_SECTION_MAX_WIDTH} +
      ${GRID_AREA_PADDING_X}
  );
  */
`;

export const TimelineScroll = styled.div`
  /* Experimental
  position: absolute;
  inset: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${GRID_AREA_PADDING_Y} ${GRID_AREA_PADDING_X};
  */
`;

export const TimelineBodyContent = styled.div`
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: calc(${TIMELINE_ITEM_ICON_SIZE} / 2 - ${TIMELINE_RAIL_WIDTH} / 2);
    top: calc(${TIMELINE_ITEM_ICON_SIZE} / 2);
    bottom: 0;
    width: ${TIMELINE_RAIL_WIDTH};
    background: ${TIMELINE_RAIL_COLOR};
  }
`;

export const TablistRow = styled.div`
  grid-area: tablist-row;
  border-bottom: solid 1px ${GRID_BORDER_COLOR};
  display: flex;
  align-items: stretch;
  background: white;
`;

export const TabBase = `
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 ${GRID_AREA_PADDING_X};
  user-select: none;
  color: inherit;

  /* Separator */
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - ${TAB_SEPARATOR_HEIGHT} / 2);
    height: ${TAB_SEPARATOR_HEIGHT};
    right: 0;
    width: 1px;
    background: ${GRID_BORDER_COLOR};
  }
  &:last-child::after {
    display: none;
  }

  /* Use transparent border of same size to match the height of ActiveTab */
  border-bottom: solid ${SELECTED_BORDER_SIZE} transparent;
`;

export const Tab = styled(Link)`
  ${TabBase}

  &:hover {
    color: ${SELECTED_COLOR};
  }
`;

export const ActiveTab = styled.div`
  ${TabBase}

  border-bottom: solid ${SELECTED_BORDER_SIZE} ${SELECTED_COLOR};
`;

export const SelectedContent = styled.div`
  grid-area: selected-content;
  padding: ${GRID_AREA_PADDING_Y} ${GRID_AREA_PADDING_X};
  background: white;
  overflow: hidden;
`;

export const TimelineItemIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: calc(${TIMELINE_ITEM_BORDER_RADIUS} / 2);
`;

const CLIP_TEXT = `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TimelineItemLineClip = `
  box-sizing: border-box;
  max-width: ${TIMELINE_ITEM_SECTION_MAX_WIDTH};
  ${CLIP_TEXT};
`;

export const TimelineItemTitle = styled.div`
  padding-bottom: ${PADDING_HALF};
  font-weight: bold;
  font-size: ${FONT_SIZE_LARGE};
  display: flex;
  gap: 10px;
  align-items: center;

  ${TimelineItemLineClip}
`;

export const TimelineItemLine = styled.div`
  padding: ${PADDING_HALF} 0;
  font-size: ${FONT_SIZE};

  ${TimelineItemLineClip}

  display: flex;
  align-items: center;
  gap: ${PADDING_HALF};

  &:first-child {
    padding-top: 0;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export const ClipText = styled.div`
  ${CLIP_TEXT};
`;

export const TimelineItemLineLinkText = styled(ClipText)`
  color: ${LINK_TEXT_COLOR};
`;

export const TimelineItemLineIconSpacer = styled.div`
  width: ${TIMELINE_ITEM_LINE_ICON_WIDTH};
`;
export const TimelineItemLineIconWrapper = styled.div`
  width: ${TIMELINE_ITEM_LINE_ICON_WIDTH};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TimelineItemLineFaint = styled.div`
  padding: 0 ${PADDING_HALF} 0 ${PADDING_HALF} 0;
  font-size: ${FONT_SIZE};
  color: ${FAINT_TEXT_COLOR};

  ${TimelineItemLineClip}
`;

export const TimelineItemCard = styled.div`
  flex: 1;
  position: relative;
  background: white;
  border: solid 1px ${GRID_BORDER_COLOR};
  border-radius: ${TIMELINE_ITEM_BORDER_RADIUS};
`;

export const TimelineItemSectionBase = `
  display: block;
  padding: ${PADDING};

  border-top: solid 1px ${GRID_BORDER_COLOR};

  &:first-child {
    border-top-left-radius: ${TIMELINE_ITEM_BORDER_RADIUS};
    border-top-right-radius: ${TIMELINE_ITEM_BORDER_RADIUS};
    border-top: 0;
  }
  &:last-child {
    border-bottom-left-radius: ${TIMELINE_ITEM_BORDER_RADIUS};
    border-bottom-right-radius: ${TIMELINE_ITEM_BORDER_RADIUS};
  }
  &:hover {
    background: ${TIMELINE_ITEM_SECTION_HOVER_BG};
  }
`;

export const TimelineItemSectionPathLink = styled(Link)`
  ${TimelineItemSectionBase};
`;

export const TimelineItemSectionAnchor = styled.a`
  ${TimelineItemSectionBase};
`;

export const TimelineItemBase = styled.div`
  position: relative;
  margin: ${PADDING} 0;
  display: flex;
  gap: ${GRID_AREA_PADDING_X};
  align-items: flex-start;

  &:last-child::before {
    content: "";
    position: absolute;
    left: 0;
    top: calc(${TIMELINE_ITEM_ICON_SIZE} / 2);
    right: 0;
    bottom: 0;
    background: ${TIMELINE_BODY_BG};
  }
`;

export const TimelineItemDefault = styled(TimelineItemBase)`
  ${TimelineItemIconWrapper} {
    background: ${TIMELINE_BODY_BG};
  }
  ${TimelineItemIconWrapper} img {
    opacity: 0.4;
  }
`;

export const TimelineItemSelected = styled(TimelineItemBase)`
  ${TimelineItemCard} {
    &::after {
      content: "";
      position: absolute;
      top: calc(${TIMELINE_ITEM_BORDER_RADIUS} / 2);
      bottom: calc(${TIMELINE_ITEM_BORDER_RADIUS} / 2);
      left: 0;
      width: ${SELECTED_BORDER_SIZE};
      background: ${SELECTED_COLOR};
      border-radius: ${TIMELINE_ITEM_BORDER_RADIUS};
    }
  }
`;

export const SummaryHeaderMargins = styled.div`
  border-top: solid 1px ${GRID_BORDER_COLOR};
  margin: ${PADDING_2X} -${PADDING} ${PADDING} -${PADDING};
  padding: ${PADDING};

  &:first-child {
    border-top: 0;
    margin-top: ${PADDING};
    padding-top: 0;
  }
`;

export const SummaryHeader = styled(SummaryHeaderMargins)`
  font-size: ${FONT_SIZE_LARGE};
  font-weight: bold;
`;

export const SummaryHeaderRow = styled(SummaryHeaderMargins)`
  display: flex;
  align-items: center;

  > :nth-child(1) {
    flex: 1;
  }

  > :nth-child(2) {
    width: auto;
  }
`;

export const SummaryHeaderPhotosPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${PADDING_HALF};

  button[disabled] {
    opacity: 0.4;
  }
`;

export const SummaryHeaderText = styled.div`
  font-size: ${FONT_SIZE_LARGE};
  font-weight: bold;
`;

export const SummaryField = styled.div`
  gap: ${PADDING};
  margin: ${PADDING} 0;
  display: grid;
  grid-template-areas: "label   content";
  grid-template-columns: ${SUMMARY_FIELD_LABEL_WIDTH} 1fr;
`;

export const SummaryFieldLabel = styled.div`
  color: ${SUMMARY_FIELD_LABEL_COLOR};
  white-space: nowrap;
  grid-area: label;
`;

export const SummaryFieldContent = styled.div`
  grid-area: content;
  line-height: 1.5;
`;

export const SummarySubheader = styled.div`
  font-size: ${FONT_SIZE_LARGE};
  margin: ${PADDING_2X} 0 ${PADDING} 0;
  padding-bottom: ${PADDING_HALF};
  border-bottom: solid 1px ${GRID_BORDER_COLOR};

  &:first-child {
    margin-top: 0;
  }
`;

export const NoteHeader = styled.div`
  margin: ${GRID_AREA_PADDING_Y} -${GRID_AREA_PADDING_X};
  position: relative;
  display: grid;
  grid-template-areas: "pad-left title pad-right";
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: ${GRID_BORDER_COLOR};
  }
`;

export const NoteTitle = styled.div`
  grid-area: title;
  padding: 0 ${GRID_AREA_PADDING_X};
  background: white;
  position: relative;
  color: ${FAINT_TEXT_COLOR};
`;

export const EmptyNotes = styled.div`
  margin: ${PADDING};
  color: ${FAINT_TEXT_COLOR};
  text-align: center;
`;

export const Prewrap = styled.div`
  white-space: pre-wrap;
`;

export const Embed = styled.div`
  overflow: hidden;
  margin: ${PADDING} 0;
  min-height: 300px;
`;

export const XRayPhoto = styled.img`
  display: block;
  width: 100%;
  margin-bottom: ${PADDING_HALF};
  cursor: pointer;
`;
