import React from "react";
import {
  Prewrap,
  SummaryField,
  SummaryFieldLabel,
  SummaryFieldContent,
} from "../styles";
import { getAnswer } from "../util";
import { NoteContentProps } from "./types";

export default function RefinementForm({ submission }: NoteContentProps) {
  const current_experience = getAnswer("current_experience", submission);
  const additional_findings = getAnswer("additional_findings", submission);
  const notes = getAnswer("notes", submission);
  return (
    <>
      {current_experience && (
        <SummaryField key="current_experience">
          <SummaryFieldLabel>Current experience</SummaryFieldLabel>
          <SummaryFieldContent>
            <Prewrap>{current_experience}</Prewrap>
          </SummaryFieldContent>
        </SummaryField>
      )}

      {additional_findings && (
        <SummaryField key="additional_findings">
          <SummaryFieldLabel>Additional findings</SummaryFieldLabel>
          <SummaryFieldContent>
            <Prewrap>{additional_findings}</Prewrap>
          </SummaryFieldContent>
        </SummaryField>
      )}

      {notes && (
        <SummaryField key="notes">
          <SummaryFieldLabel>Assessment notes</SummaryFieldLabel>
          <SummaryFieldContent>
            <Prewrap>{notes}</Prewrap>
          </SummaryFieldContent>
        </SummaryField>
      )}
    </>
  );
}
