import { get } from "lodash";

import { CombinedError, useQuery, useMutation } from "src/utils/http/gqlQuery";
import { PaymentMethod, TreatmentOption } from "src/types/gql";

export const useActiveTreatment = (
  userID: string
): [boolean, CombinedError | undefined, TreatmentOption] => {
  const [{ fetching, error, data }] = useQuery<{
    treatmentOption: TreatmentOption;
  }>({ query: activeOrLastTreatmentGQL, variables: { userID } });

  return [
    fetching,
    error,
    get(data, "activeOrLastTreatment.activeOption") || ({} as TreatmentOption),
  ];
};

export const usePaymentMethods = (
  userID: string
): [boolean, CombinedError | undefined, PaymentMethod[]] => {
  const [{ fetching, error, data }] = useQuery<{
    paymentMethods: PaymentMethod[];
  }>({ query: paymentMethodGQL, variables: { userID } });

  return [
    fetching,
    error,
    (data && data.paymentMethods) || ([] as PaymentMethod[]),
  ];
};

const activeOrLastTreatmentGQL = `
  query ActiveTreatment($userID: ID!) {
    activeOrLastTreatment(userID: $userID) {
      activeOption {
        isActive
        estimatedUpperBound
      }
    }
  }
`;

const paymentMethodGQL = `
  query PaymentMethods($userID: ID!) {
    paymentMethods(userID: $userID) {
      id externalId type gateway
      cardDetails {
        id brand funding country expiryMonth expiryYear lastFour
      }
    }
  }
`;

const addToken = `
  mutation AddToken($input: NewPaymentMethod!) {
    addPaymentMethod(input: $input) {
      id
      externalId
      type
      gateway
      cardDetails {
        id
        lastFour
        expiryMonth
        expiryYear
        brand
        funding
        country
      }
    }
  }
`;

export const useAddToken = () => {
  const [, execute] = useMutation(addToken);
  return execute;
};

const deletePaymentMethodGql = `
  mutation DeletePaymentMethod($id: ID!) {
    deletePaymentMethod(id: $id) {
      ids
    }
  }
`;

// useDeletePaymentMethod returns a utility function which executes the delete
// payment method endpoint and refetches all payment methods, removing the deleted
// item from the cache.
//
// XXX: In the future we should build an urql exchange which allows us to remove
// items from urql cache without re-requesting
export const useDeletePaymentMethod = (userID: string) => {
  const [, refetch] = useQuery({
    query: paymentMethodGQL,
    variables: { userID },
    pause: true,
  });

  const [, execute] = useMutation<undefined, { id: string }>(
    deletePaymentMethodGql
  );

  const onDelete = async (id: string) => {
    const result = await execute({ id });
    refetch();
    return result;
  };

  return onDelete;
};

const createFinancingPlanGql = `
  mutation CreateFinancingPlan($input: NewFinancingPlan!) {
    createFinancingPlan(input: $input) {
      id 
    }
  }
`;

export const useCreateFinancingPlan = () => {
  const [, execute] = useMutation(createFinancingPlanGql);
  return execute;
};
