import styled from "react-emotion";
import Button from "src/shared/Button";

export const Perimeter = styled.div`
  display: flex;
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
`;
