import React from "react";
import { notification } from "antd";

import { useQuery } from "src/utils/http/gqlQuery";
import ConfirmModal from "src/shared/ConfirmModal";
import { Adjustment } from "src/types/gql";
import { useRemoveOrderAdjustment } from "../query";
import { orderGql } from "../query";

type Props = {
  onClose: () => void;
  adjustment: Adjustment;
  patientID: string;
};

const RemoveMarkdownModal: React.FC<Props> = ({
  onClose,
  adjustment,
  patientID,
}) => {
  const [, refetch] = useQuery({
    query: orderGql,
    variables: { userID: patientID },
    pause: true,
  });

  const removeAdjustment = useRemoveOrderAdjustment();

  const onClick = async () => {
    const result = await removeAdjustment({
      id: adjustment.id,
    });

    if (result.error) {
      notification.error({
        message: "There was an error removing this adjustment.",
      });
      return;
    }
    notification.success({ message: "Order adjustment removed" });

    // refetch all orders and clear the cache
    refetch();
    onClose();
  };

  return (
    <ConfirmModal
      onClose={onClose}
      onConfirm={onClick}
      type="delete"
      title="Are you sure?"
      text="Do you really want to remove this order adjustment?"
      actionText="Remove"
    />
  );
};

export default RemoveMarkdownModal;
