import React, { useState } from "react";
import { Link } from "react-router";
import styled from "react-emotion";
import { browserHistory } from "react-router";
import { notification } from "antd";
import { DateTime } from "luxon";
import { Tabs } from "src/shared/Tabs";
import Button from "src/shared/Button";
import Box from "src/shared/Box";
import Add from "src/shared/Icons/Add";
import { gqlError } from "src/shared/util";
import DeleteConfirmModal from "src/shared/DeleteConfirmModal";
import InternalNotes from "src/shared/InternalNotes";
import { getWeeksDistToISODateString } from "src/utils/dates";
import PipelineProgress from "../Shared/PipelineProgress";
import Sidebar from "../Sidebar/Sidebar";
import color from "src/styles/color";
import Assigned from "../Shared/Assigned";
import AssignStaffIcon from "../Icons/AssignStaff";
import PipelineType from "../PipelineType";
import TaskTable from "../TaskTable/Table";
import Task from "../Task/Task";
import {
  usePipeline,
  useDeletePipeline,
  useAddPipelineCustomTag,
} from "../queries";
import { Staff } from "src/types/gql";
import CustomTagsList from "../CustomTagsList/CustomTagsList";

type Props = {
  // backURL, if specified, is the URL that the back button returns to.
  // This is used in the Patient component to render a specific pipeline,
  // with the back button returning to the patient pipeline list.
  backURL?: string;
  hideSidebar?: boolean;
  params: {
    filter: string;
    id: string; // pipeline ID
    taskID?: string;
  };
};

const Pipelines = (props: Props) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showNewNote, setShowNewNote] = useState(false);
  const execute = useDeletePipeline();
  const addPipelineCustomTag = useAddPipelineCustomTag();

  const { hideSidebar, params } = props;
  const { id, filter, taskID } = params;

  const backURL =
    props.backURL || ["/pipelines", filter].filter(Boolean).join("/");

  const [{ error, data }] = usePipeline(id);

  if (!data || error) {
    return (
      <>
        <Wrapper>
          <Sidebar />
          <div />
        </Wrapper>
      </>
    );
  }

  const { pipeline } = data;
  const assignees = ((pipeline.unblockedTasks || [])
    .map(t => t.assignedTo)
    .flat() as unknown) as Staff[];

  const blockedIDs = (pipeline.tasks || [])
    .filter(t => {
      return (
        !t.completedAt &&
        !(pipeline.unblockedTasks || []).find(u => u.id === t.id)
      );
    })
    .map(t => t.id);
  const weeks = getWeeksDistToISODateString(pipeline.createdAt) + 1;

  const onDelete = async () => {
    const result = await execute({ id });
    if (result.error) {
      return notification.error({
        message: `Error deleting pipeline: ${gqlError(result.error)}`,
      });
    }
    notification.success({ message: "Pipeline deleted" });
    browserHistory.push(backURL);
  };

  const onAddCustomTag = async (tag: string) => {
    const result = await addPipelineCustomTag({
      input: {
        pipelineID: id,
        newCustomTag: tag,
      },
    });
    if (result.error) {
      return notification.error({
        message: `Error adding new tag: ${gqlError(result.error)}`,
      });
    }
    notification.success({ message: `New tag ${tag} added` });
  };

  // !!!
  // This is a pretty hacky way of guaranteeting ordering given the backend sometimes creates
  // dags with incorrect edges.
  const ordered = (() => {
    const tasks = pipeline.tasks || [];
    const complete = tasks.filter(t => !!t.completedAt);
    // filter out compelted taks from unblocked
    const completeIDs = complete.map(t => t.id);
    const next = (pipeline.unblockedTasks || []).filter(
      t => !completeIDs.includes(t.id)
    );
    // add blocked
    const blocked = tasks.filter(
      t => !t.completedAt && blockedIDs.includes(t.id)
    );
    return complete.concat(next, blocked);
  })();

  const content = (
    <div>
      <Back to={backURL}>&lsaquo; Back</Back>

      <PipelineInfo>
        <Menu>
          <Tabs>
            <button className="active">Overview</button>
            <a href="#tasks">Tasks</a>
            <a href="#comments">Comments</a>
          </Tabs>
          <DeleteButton kind="invisible" onClick={() => setShowDelete(true)}>
            Delete pipeline
          </DeleteButton>
        </Menu>
        <Header>
          <PipelineType large type={pipeline.type} />
          <Patient>
            <div>
              <Link to={`/patients/${pipeline.user.id}`}>
                {pipeline.user.name}
              </Link>
            </div>
            <div>{pipeline.user.mobilePhoneNumber}</div>
            <div>{pipeline.user.email}</div>
          </Patient>
        </Header>

        <InfoRow style={{ padding: "16px 24px 0" }}>
          <div>Current assignee</div>
          <div>
            {assignees.map(a => (
              <Assigned key={a.id} staff={a} hideName />
            ))}
            {assignees.length === 0 && <AssignStaffIcon />}
          </div>
        </InfoRow>

        <InfoRow>
          <div>Start clinic</div>
          <div>{pipeline.user.homeClinic && pipeline.user.homeClinic.name}</div>
        </InfoRow>

        <InfoRow>
          <div>Pipeline start date</div>
          <div>{DateTime.fromISO(pipeline.createdAt).toLocaleString()}</div>
        </InfoRow>

        <InfoRow>
          <div>Weeks</div>
          <div>{weeks}</div>
        </InfoRow>

        <InfoRow>
          <div>Tags</div>
          <CustomTagsList
            tags={data.pipeline.customTags || []}
            onChange={onAddCustomTag}
          />
        </InfoRow>

        <InfoRow>
          <div>Progress</div>
          <div style={{ maxWidth: "300px" }}>
            <PipelineProgress pipeline={pipeline} />
          </div>
        </InfoRow>
      </PipelineInfo>

      {
        // eslint-disable-next-line
        <a id="tasks" />
      }
      <Box title="Tasks">
        <TaskTable
          tasks={ordered}
          currentPatientStatusesMap={undefined}
          urlPrefix={`${backURL}/${pipeline.id}`}
          routeFilter={filter || "my"}
          blockedIDs={blockedIDs}
        />
      </Box>

      {
        // eslint-disable-next-line
        <a id="comments" />
      }
      <CommentBox title="Comments">
        <div style={{ padding: "24px" }}>
          <InternalNotes
            objectKind="PipelineV2"
            objectID={pipeline.id}
            hideNewNote={!showNewNote}
            onCreate={() => setShowNewNote(false)}
            chronological
            max={3}
          />
          {!showNewNote && (
            <Button onClick={() => setShowNewNote(true)} kind="link-blue">
              <Add fill={color.blue} size={12} /> Add comment
            </Button>
          )}
        </div>
      </CommentBox>
    </div>
  );

  return (
    <>
      {hideSidebar ? (
        content
      ) : (
        <Wrapper>
          <Sidebar />
          {content}
        </Wrapper>
      )}

      {taskID && (
        <Task
          id={taskID}
          pipeline={pipeline}
          onClose={() => {
            browserHistory.push(`${backURL}/${pipeline.id}`);
          }}
        />
      )}

      {showDelete && (
        <DeleteConfirmModal
          text="Are you sure you want to delete this pipeline?"
          actionText="Delete"
          onConfirm={onDelete}
          onClose={() => setShowDelete(false)}
        />
      )}
    </>
  );
};

const Wrapper = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-columns: 240px auto;
  align-items: stretch;

  > div:last-of-type {
    padding: 0 24px;

    h1 {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      padding: 32px 0 10px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 16px;
      }
    }
  }

  h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    color: ${color.gray3};
  }
`;

const Back = styled(Link)`
  display: inline-block;
  color: ${color.gray3};
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  margin: 24px 0 0;
`;

const PipelineInfo = styled.div`
  margin: 24px 0;
  border: 1px solid ${color.border};
  background: #fff;
`;

const Header = styled.div`
  padding: 30px 24px;
  border-bottom: 1px solid ${color.borderLight};
`;

export const Menu = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${color.borderLight};
`;

const Patient = styled.div`
  margin: 24px 0 0;
  display: flex;

  > div {
    margin-right: 24px;
  }
`;

const InfoRow = styled.div`
  display: grid;
  padding: 16px 24px;
  grid-template-columns: 120px auto;
  grid-gap: 32px;
  align-items: center;

  &:last-of-type {
    padding-bottom: 24px;
  }

  > div:first-of-type {
    font-weight: bold;
    color: ${color.gray3};
  }
`;

const CommentBox = styled(Box)`
  margin: 24px 0 32px;
`;

const DeleteButton = styled(Button)`
  color: ${color.red};
  padding: 0 16px;
  font-size: 12px;
`;

export default Pipelines;
