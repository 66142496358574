// @flow
import React, { useState } from "react";
import debounce from "lodash/debounce";
import { compose, withProps } from "recompose";
import { css } from "react-emotion";
import {
  Button,
  Icon,
  Modal,
  Popconfirm,
  Popover,
  Table,
  InputNumber,
  DatePicker,
  Tag,
} from "antd";
import Moment from "moment";
// For use when creating builds
import { useFetchProducers } from "src/state/hooks/producerIdentifiers";
import { TreatmentCyclesEnhancer } from "src/state/TreatmentCycles";
import { BuildsEnhancer } from "src/state/Builds";
import { WithEnhancers } from "src/shared/hocs";
import useSelf from "src/state/self";
/*
import type {
  Patient,
  Pipeline,
  TrayResponse,
  TreatmentCycleResponse,
  ProducerIdentifier,
} from "src/types/api";

// This type records which upper/lower trays have been selected from our UI.
type SelectedTrays = {
  [id: string]: {
    upper: boolean,
    lower: boolean,
  },
};

type PassedProps = {
  tc: TreatmentCycleResponse,
  patient: Patient,

  pipeline?: Pipeline,

  actions?: boolean,
  editingDisabled?: boolean,
  buildsEnabled?: boolean,
};

// This is a locally used Tray type generated by withProps modifier
type ModifiedTray = TrayResponse & {
  cadence: number,
};

type ModifierProps = {
  trays: Array<ModifiedTray>,
};

type Props = PassedProps & ModifierProps & TCProps & BuildProps;

*/

const modifiers = compose(
  ...WithEnhancers(TreatmentCyclesEnhancer, BuildsEnhancer),
  // Map all props needed for displaying the table.
  withProps(props => {
    // This returns an array of trays to render as a single table row.
    const trays = props.tc.wear_schedule.map(t => {
      const start = new Moment(t.expected_start_date);
      const end = new Moment(t.expected_end_date);
      return {
        ...t,
        key: t.id,
        expected_start_date: start.format("YYYY-MM-DD"),
        expected_end_date: end.format("YYYY-MM-DD"),
        cadence: end.diff(start, "day"),
      };
    });

    return {
      trays,
    };
  })
);

const columns = (props, selected, setSelected) => {
  return [
    {
      title: "Stage",
      dataIndex: "stage",
      width: 100,
    },
    {
      title: "Arch",
      dataIndex: "arch_type",
      width: 100,
    },
    {
      title: "Template",
      dataIndex: "is_template",
      width: 100,
      render: (template, t) => {
        return template ? <Icon type="check" /> : <div />;
      },
    },
    {
      title: "Start",
      dataIndex: "expected_start_date",
      render: (text, t) => {
        return (
          <DatePicker
            value={new Moment(text)}
            disabled={props.editingDisabled}
            suffixIcon={null}
            allowClear={false}
            style={{ border: 0 }}
            onChange={date => {
              props.actions.updateTray({
                ...t,
                expected_start_date: date.format("YYYY-MM-DD"),
              });
            }}
          />
        );
      },
    },
    {
      title: "End",
      dataIndex: "expected_end_date",
      render: (text, t) => {
        return (
          <DatePicker
            value={new Moment(text)}
            disabled={props.editingDisabled}
            suffixIcon={null}
            allowClear={false}
            style={{ border: 0 }}
            onChange={date => {
              props.actions.updateTray({
                ...t,
                expected_end_date: date.format("YYYY-MM-DD"),
              });
            }}
          />
        );
      },
    },
    {
      title: "Cadence",
      dataIndex: "cadence",
      render: (text, t) => {
        return (
          <InputNumber
            disabled={props.editingDisabled}
            onChange={debounce(cadence => {
              props.actions.updateTrayCadence(t, cadence);
            }, 500)}
            value={text}
          />
        );
      },
    },
    {
      title: "Phase",
      dataIndex: "phase",
      width: 160,
    },
    {
      title: "Wearable?",
      dataIndex: "is_wearable",
      width: 100,
      render: (wearable, t) => {
        return wearable ? (
          "Yes"
        ) : (
          <Tag color="red">
            <b>No</b>
          </Tag>
        );
      },
    },
  ];
};

const TrayTable = props => {
  const [selected, setSelected] = useState({});
  const [selectManufacturerVisible, setSelectManufacturerVisible] = useState(
    false
  );

  // Load producers for manufacturer selection
  const [producers] = useFetchProducers();

  // Store whether we're showing the "conflict" modal, if a build already exists
  // for this pipeline.
  const [existingBuildIds, setExistingBuildIds] = useState([]);

  // producerId is local state that is stored when there's a conflict;  we show
  // a modal that will call createBuild again below after user actiosn and need to
  // record which producer we clicked on.
  const [producerId, setProducerId] = useState();

  const self = useSelf();

  if (props.trays.length === 0) {
    return null;
  }

  const now = new Moment().format("YYYY-MM-DD");

  const createBuild = async (producerId, force) => {
    // hardcoded beta test for manufacturing v2 for Sophie to test
    if (self.id === "fefacd2c-9618-4041-b16f-bd0ceaeafe4b") {
      const result = await props.actions.createBuildV2({
        build: {
          producer_identifier_id: producerId,
          user_id: props.tc.user_id,
          pipeline_id: props.pipelineID,
        },
        upper_tray_ids: Object.keys(selected).filter(id => selected[id].upper),
        lower_tray_ids: Object.keys(selected).filter(id => selected[id].lower),
        force: force,
        pipeline_id: props.pipelineID,
      });

      if (result.status === 409) {
        // Conflicts occur when a build for this pipeline already exists.
        // In this case we show a dialog asking the user if they'd like to
        // delete the existing builds, or force create this build.
        setProducerId(producerId);
        setExistingBuildIds(result.build_ids);
      }

      return;
    }

    const result = await props.actions.createBuild({
      build: {
        producer_identifier_id: producerId,
        user_id: props.tc.user_id,
        pipeline_id: props.pipelineID,
      },
      upper_tray_ids: Object.keys(selected).filter(id => selected[id].upper),
      lower_tray_ids: Object.keys(selected).filter(id => selected[id].lower),
      force: force,
      pipeline_id: props.pipelineID,
    });

    if (result.status === 409) {
      // Conflicts occur when a build for this pipeline already exists.
      // In this case we show a dialog asking the user if they'd like to
      // delete the existing builds, or force create this build.
      setProducerId(producerId);
      setExistingBuildIds(result.build_ids);
    }
  };

  return (
    <>
      <Modal
        title="Conflicting builds"
        visible={existingBuildIds && existingBuildIds.length > 0}
        footer={[
          <Button onClick={() => setExistingBuildIds([])}>Cancel</Button>,
          <Button
            type="danger"
            onClick={async () => {
              for (let i = 0; i < existingBuildIds.length; i++) {
                await props.actions.deleteBuild(existingBuildIds[i]);
              }
              createBuild(producerId, false);
              setExistingBuildIds([]);
            }}
          >
            Delete previous builds
          </Button>,
          <Button
            onClick={() => {
              createBuild(producerId, true);
              setExistingBuildIds([]);
            }}
          >
            Make new build
          </Button>,
        ]}
      >
        Builds already exist for this pipeline, and may have been manufactured.
        What would you like to do?
      </Modal>

      <div
        className={css`
          display: ${props.actions !== false ? "flex" : "none"};
          justify-content: flex-end;
          margin: 1rem 0;
        `}
      >
        <Popover
          title="Choose a manufacturer"
          trigger="click"
          visible={
            selectManufacturerVisible && Object.keys(selected).length > 0
          }
          onVisibleChange={visible => setSelectManufacturerVisible(visible)}
          content={
            <>
              {producers.map(p => {
                return (
                  <Popconfirm
                    key={p.id}
                    title={`Produce via ${p.source}?`}
                    icon={<Icon type="question-circle-o" />}
                    onConfirm={() => {
                      createBuild(p.id, false);
                    }}
                  >
                    <p style={{ cursor: "pointer" }}>{p.source}</p>
                  </Popconfirm>
                );
              })}
            </>
          }
        >
          <Button
            disabled={Object.keys(selected).length === 0}
            style={{ marginRight: "1rem" }}
          >
            Manufacture selected trays
          </Button>
        </Popover>
      </div>
      <Table
        rowClassName={t => {
          return t.expected_start_date <= now && t.expected_end_date >= now
            ? current
            : "";
        }}
        pagination={false}
        size="small"
        rowSelection={{
          selectedRowKeys: Object.keys(selected),
          onChange: (keys, trays) => {
            const state = {};
            trays.forEach(t => {
              state[t.id] = { [t.arch_type]: true };
            });
            setSelected(state);
          },
        }}
        columns={columns(props, selected, setSelected)}
        dataSource={props.trays}
      />
    </>
  );
};

export default modifiers(TrayTable);

const current = css`
  background: #ffeb0033;
`;
