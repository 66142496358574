import React, { useState, useEffect, useMemo } from "react";
import styled, { css } from "react-emotion";
import {
  flowRight,
  get,
  has,
  map,
  keys,
  keyBy,
  groupBy,
  mapValues,
  sortBy,
} from "lodash";
import { Link } from "react-router";
import gql from "graphql-tag";
import { Checkbox, notification, Menu, Dropdown } from "antd";
import dayjs from "dayjs";

import { useSchedulingRestrictions } from "src/state/self";
import { useMutation } from "src/utils/http/gqlQuery";
import Table, { Header, Body, EmptyBody } from "src/shared/Table";
import color from "src/styles/color";
import textStyles from "src/styles/textStyles";
import Appointment from "src/shared/Icons/Appointment";
import Button from "src/shared/Button";
import Edit from "src/shared/Icons/Edit";
import Trash from "src/shared/Icons/Trash";
import time from "src/shared/time";
import { updateAppointment } from "src/scenes/SchedulingV2/queries";
// local
import CheckInAndOutModal from "./CheckInAndOutModal";
import WarningModal from "./WarningModal";
import useCalendarViewContext from "./useCalendarViewContext";
import AssistantAssignmentModal from "../AssignmentModals/AssistantAssignmentModal";
import DoctorAssignmentModal from "../AssignmentModals/DoctorAssignmentModal";
import { getAppointmentName } from "src/shared/util";

const Perimeter = styled.div`
  margin: 24px 0 10px;
  width: 100%;
`;

const PatientName = styled.div`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Availability = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const AvailabilityText = styled.div`
  flex: 1;
`;

const AvailabilityIconBase = `
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

const AvailabilityAvailableIcon = styled.div`
  ${AvailabilityIconBase}
  background: #5FCB8D;
`;

const AvailabilityBookedIcon = styled.div`
  ${AvailabilityIconBase}
  background: #D7D7D7;
`;

const AvailabilityConvertibleIcon = styled.div`
  ${AvailabilityIconBase}
  background: #F7B632;
`;

const GrayText = styled.div`
  color: ${color.gray3};
`;

const H6 = styled.h6`
  font-weight: bold;
  margin-top: 24px;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-left: 20px !important;
  &:first-child {
    margin-left: 0;
  }
`;

const StyleMenu = styled(Menu)`
  min-width: 160px;

  & .ant-dropdown-menu-item:hover {
    background-color: ${color.gray1};
  }
`;

const CHECKBOX_WIDTH = 40;
const ACTIONS_WIDTH = 117;

const GroupBodyItem = styled(Body.Item)`
  ${textStyles("large")};
  font-weight: bold;
  overflow: visible;
  background: ${color.gray1};
  flex: 1;
`;

const ActionHeaderItem = styled(Header.Item)`
  justify-content: flex-end;
  padding-right: 16px;
  width: ${ACTIONS_WIDTH}px;
`;

const headerItemStyle = css`
  width: calc((100% - ${CHECKBOX_WIDTH + ACTIONS_WIDTH}px) / 6);
`;
const bodyItemStyle = css`
  width: calc((100% - ${CHECKBOX_WIDTH + ACTIONS_WIDTH}px) / 6);
`;

const confirmedButtonStyle = {
  border: "none",
  color: "#27AE60",
  background: "rgba(95, 203, 141, 0.4)",
  borderRadius: "4px",
};

const pendingButtonStyle = {
  border: "none",
  color: "#979A9A",
  background: "#F3F3F3",
  borderRadius: "4px",
};

const cancelGql = gql`
  mutation CancelAppointment($id: ID!) {
    appt: cancelAppointment(appointmentId: $id) {
      id
    }
  }
`;

type StaffType = "assistant" | "doctor";

const ListView = () => {
  const { isScheduleManager, canCancelBV } = useSchedulingRestrictions();
  const { appointments, selectedDoctorId, clinic } = useCalendarViewContext();
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [selectedGroup, setSelectedGroup] = useState({});
  const [selectedAll, setSelectedAll] = useState(false);
  const [bulkDeleteVisible, setBulkDeleteVisible] = useState(false);

  const [bulkAssignModal, setBulkAssignModal] = useState<StaffType | null>(
    null
  );

  const [, cancelApointment] = useMutation(cancelGql);

  const selectAppointment = (id, selected, item) => () => {
    setSelectedAppointment({
      ...selectedAppointment,
      [id]: !selected,
    });
    if (selected) {
      setSelectedAll(false);
    }
  };

  const getSelectedAppointmentIds = () => {
    let selectedAppointments: any = [];
    Object.keys(selectedAppointment).forEach(appt => {
      if (selectedAppointment[appt]) {
        selectedAppointments.push(appt);
      }
    });

    return selectedAppointments;
  };

  const deleteSelectedApointments = async () => {
    let selectedAppointments = getSelectedAppointmentIds();

    let cancellingErrors: any = [];
    selectedAppointments.forEach(async appontmentId => {
      const result = await cancelApointment({ id: appontmentId });
      if (result.error) {
        cancellingErrors.push(result.error);
        notification.error({
          message: `There was an error cancelling selected appointment`,
        });
      }
    });

    setBulkDeleteVisible(false);

    const deselectAll = {};
    appointments.forEach(appt => {
      deselectAll[appt.id] = false;
    });
    setSelectedAppointment(deselectAll);
    setSelectedAll(false);

    if (cancellingErrors.length === 0) {
      notification.info({
        message: "Finished cancelling selected appointments",
      });
    }
  };

  const selectGroup = (id, selected) => {
    const appointments = group[id]
      .filter(it => {
        return it.appointmentType === "beginning" ? canCancelBV : true;
      })
      .reduce(
        (acc, current) => ({
          ...acc,
          [current.id]: !selected,
        }),
        {}
      );

    setSelectedAppointment({
      ...selectedAppointment,
      ...appointments,
    });
    setSelectedGroup({
      ...selectedGroup,
      [id]: !selected,
    });
  };

  const toggleSelectAll = () => {
    if (selectedAll) {
      const deselectAll = {};
      appointments.forEach(appt => {
        deselectAll[appt.id] = false;
      });
      setSelectedAppointment(deselectAll);
      setSelectedAll(false);
      setSelectedGroup(() => {
        return keys(group).reduce((acc, current) => {
          return {
            ...acc,
            [current]: false,
          };
        }, {});
      });
    } else {
      const selectAll = {};
      appointments
        .filter(appt => {
          return appt.appointmentType.name === "beginning" ? canCancelBV : true;
        })
        .forEach(appt => {
          selectAll[appt.id] = true;
        });
      setSelectedAppointment(selectAll);
      setSelectedAll(true);
      setSelectedGroup(() => {
        return keys(group).reduce((acc, current) => {
          return {
            ...acc,
            [current]: true,
          };
        }, {});
      });
    }
  };

  const allAppointments = useMemo(() => {
    const { toHour } = time(clinic.timezone);

    const formatAppointments = apts =>
      map(apts, appointment => {
        // id
        const id = appointment.id ? appointment.id : appointment.clientId;
        const roomName = get(appointment, "bookableResource.name");

        // appointmentTime
        const startTime = toHour(get(appointment, "startTime")) || "";
        const endTime = toHour(get(appointment, "endTime")) || "";
        const appointmentTime = `${startTime} - ${endTime}`;

        // appointmentTime
        const actualStartTime = toHour(get(appointment, "actualStartTime"));
        const actualEndTime = toHour(get(appointment, "actualEndTime"));

        // doctorTime
        const docStartTime = toHour(get(appointment, "doctorStartTime")) || "";
        const docEndTime = toHour(get(appointment, "doctorEndTime")) || "";
        const doctorTime = `${docStartTime} - ${docEndTime}`;

        // confirmedAt
        const confirmedAt = get(appointment, "confirmedAt");

        // patient
        const patient = has(appointment, "user.firstName")
          ? `${
              get(appointment, "user.preferredName")
                ? get(appointment, "user.preferredName")
                : get(appointment, "user.firstName")
            } ${get(appointment, "user.lastName").charAt(0)}`
          : "";

        // appointment type
        const appointmentType = get(appointment, "appointmentType.name");
        const appointmentSubtype = get(appointment, "appointmentSubtype");

        // doctor name
        const doctorFirstName = get(appointment, "doctor.firstName", "");
        const doctorLastName = get(appointment, "doctor.lastName", "");
        const doctorName = `${doctorFirstName} ${doctorLastName}`;

        // staffs
        const staff = get(appointment, "staff") || [];

        // sortTime
        const sortTime =
          get(appointment, "startTime") || get(appointment, "doctorStartTime");

        return {
          id,
          appointmentTime,
          actualStartTime,
          actualEndTime,
          confirmedAt,
          doctorTime,
          patientId: (appointment || {}).userId,
          patient,
          doctorName,
          appointmentType,
          appointmentSubtype,
          staff,
          sortTime,
          roomName,
        };
      });

    const filteredAppointments = appointments
      .filter(
        ({ doctorId }) =>
          selectedDoctorId === "all" || doctorId === selectedDoctorId
      )
      .filter(appt => appt.clinic && appt.clinic.id === clinic.id);

    const allAppointments = formatAppointments(filteredAppointments);
    return allAppointments;
  }, [selectedDoctorId, clinic.id, appointments, clinic.timezone]);

  useEffect(() => {
    const deselectAll = flowRight(
      appointments => mapValues(appointments, () => false),
      appointments => keyBy(appointments, "id")
    )(appointments);
    setSelectedAppointment(deselectAll);

    setSelectedAll(false);
    setSelectedGroup({});
  }, [allAppointments, appointments, clinic.timezone]);

  const appointmentMap = useMemo(() => {
    return keyBy(allAppointments, "id");
  }, [allAppointments]);

  const group = useMemo(() => {
    const { startOfDay } = time(clinic.timezone);
    return groupBy(allAppointments, ({ sortTime }) => startOfDay(sortTime));
  }, [clinic.timezone, allAppointments]);

  const formattedAppointments = useMemo(() => {
    const { toMillis, toWeekday, toDateYear } = time(clinic.timezone);
    const toFormat = isoDate => `${toWeekday(isoDate)}, ${toDateYear(isoDate)}`;

    const sortedGroupKey = sortBy(keys(group), toMillis);

    const formattedAppointments: any = [];
    for (const key of sortedGroupKey) {
      formattedAppointments.push({
        id: key,
        label: toFormat(key),
        groupItem: true,
      });
      const appointmentGroup = group[key].map(({ id }) => appointmentMap[id]);
      formattedAppointments.push(...appointmentGroup);
    }

    return formattedAppointments;
  }, [appointmentMap, clinic.timezone, group]);

  const handleDelete = () => {
    setBulkDeleteVisible(true);
  };

  const [, update] = useMutation(updateAppointment);
  const [, setError] = useState("");

  const handleConfirmedAtToggle = async item => {
    if (item.confirmedAt === undefined || item.confirmedAt === null) {
      let result = await update({
        appt: {
          id: item.id,
          confirmedAt: true,
        },
      });

      if (result.error) {
        return notification.error({
          message: "Error updating patient confirmation",
        });
      }

      notification.success({
        message: `Appointment confirmed! ${
          item.patient
        } is confirmed for ${dayjs(item.startTime).format("dddd MMMM DD")}.`,
      });
    } else {
      let result = await update({
        appt: {
          id: item.id,
          clearConfirmedAt: true,
        },
      });

      if (result.error) {
        return notification.error({
          message: "Error updating patient confirmation",
        });
      }

      notification.success({
        message: `Appointment confirmation reverted!`,
      });
    }

    setError("");
  };

  const EditMenu = (
    <StyleMenu>
      <Menu.Item onClick={() => setBulkAssignModal("assistant")}>
        Assign to assistant
      </Menu.Item>
      <Menu.Item onClick={() => setBulkAssignModal("doctor")}>
        Assign to doctor
      </Menu.Item>
    </StyleMenu>
  );

  const renderAvailability = appt => {
    if (appt.patient) {
      return (
        <Availability>
          <AvailabilityBookedIcon />
          <AvailabilityText>Booked</AvailabilityText>
        </Availability>
      );
    }

    // TODO: Determine convertible according to guardrails
    const isConvertible = false;

    if (isConvertible) {
      return (
        <Availability>
          <AvailabilityConvertibleIcon />
          <AvailabilityText>Convertible</AvailabilityText>
        </Availability>
      );
    }

    return (
      <Availability>
        <AvailabilityAvailableIcon />
        <AvailabilityText>Available</AvailabilityText>
      </Availability>
    );
  };

  const now = new Date();
  const getRowOpacity = appt => {
    const time = new Date(appt.sortTime);
    if (time < now) {
      return 0.5;
    }

    return 1;
  };

  return (
    <Perimeter>
      {bulkDeleteVisible && (
        <WarningModal
          onOk={deleteSelectedApointments}
          warningText="Do you really want to cancel these appointments?"
          closeModal={() => setBulkDeleteVisible(false)}
        />
      )}

      {bulkAssignModal === "assistant" && (
        <AssistantAssignmentModal
          appointmentIds={getSelectedAppointmentIds()}
          closeModal={() => setBulkAssignModal(null)}
          clinicId={appointments && appointments[0].clinic.id}
        />
      )}
      {bulkAssignModal === "doctor" && (
        <DoctorAssignmentModal
          appointmentIds={getSelectedAppointmentIds()}
          closeModal={() => setBulkAssignModal(null)}
          clinicId={appointments && appointments[0].clinic.id}
        />
      )}

      <Table items={formattedAppointments}>
        <Header>
          {isScheduleManager && (
            <Header.Item
              css={`
                width: ${CHECKBOX_WIDTH}px;
              `}
            >
              <Checkbox checked={selectedAll} onChange={toggleSelectAll} />
            </Header.Item>
          )}
          <Header.Item css={headerItemStyle}>Patient</Header.Item>
          <Header.Item css={headerItemStyle}>Availability</Header.Item>
          <Header.Item css={headerItemStyle}>Appt. Type</Header.Item>
          <Header.Item css={headerItemStyle}>Appt. Sub-Type</Header.Item>
          <Header.Item css={headerItemStyle}>Location</Header.Item>
          <Header.Item css={headerItemStyle}>Doctor</Header.Item>
          <Header.Item css={headerItemStyle}>Assistant</Header.Item>
          <ActionHeaderItem>
            <ActionWrapper>
              {isScheduleManager && (
                <Dropdown
                  overlay={EditMenu}
                  trigger={["click"]}
                  placement="bottomRight"
                  disabled={getSelectedAppointmentIds().length === 0}
                >
                  <StyledButton type="clear">
                    <Edit />
                  </StyledButton>
                </Dropdown>
              )}
              {isScheduleManager && (
                <StyledButton
                  type="clear"
                  onClick={handleDelete}
                  disabled={getSelectedAppointmentIds().length === 0}
                >
                  <Trash fill={color.red} />
                </StyledButton>
              )}
            </ActionWrapper>
          </ActionHeaderItem>
        </Header>

        <Body>
          {item =>
            item.groupItem ? (
              <Body.Row css="height: 40px;" key={item.id}>
                {isScheduleManager && (
                  <Body.Item
                    key="select_group"
                    css={`
                      width: ${CHECKBOX_WIDTH}px;
                      background: ${color.gray1};
                    `}
                  >
                    <Checkbox
                      checked={selectedGroup[item.id]}
                      onChange={() =>
                        selectGroup(item.id, selectedGroup[item.id])
                      }
                    />
                  </Body.Item>
                )}
                <GroupBodyItem>{item.label}</GroupBodyItem>
              </Body.Row>
            ) : (
              <Body.Row key={item.id} style={{ opacity: getRowOpacity(item) }}>
                {isScheduleManager && (
                  <Body.Item
                    key="select_item"
                    css={`
                      width: ${CHECKBOX_WIDTH}px;
                    `}
                  >
                    <Checkbox
                      checked={selectedAppointment[item.id]}
                      disabled={
                        item.appointmentType === "beginning" && !canCancelBV
                      }
                      onChange={selectAppointment(
                        item.id,
                        selectedAppointment[item.id],
                        item
                      )}
                    />
                  </Body.Item>
                )}
                <Body.Item key="patient_name" css={bodyItemStyle}>
                  <PatientName>
                    {item.patient ? (
                      <div>
                        {item.confirmedAt ? (
                          <button
                            onClick={() => {
                              handleConfirmedAtToggle(item);
                            }}
                            style={confirmedButtonStyle}
                          >
                            C
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              handleConfirmedAtToggle(item);
                            }}
                            style={pendingButtonStyle}
                          >
                            P
                          </button>
                        )}{" "}
                        <Link to={`/patients/${item.patientId}`}>
                          {item.patient}
                        </Link>
                      </div>
                    ) : (
                      "Unbooked"
                    )}
                  </PatientName>
                  <GrayText>{item.appointmentTime}</GrayText>
                </Body.Item>
                <Body.Item key="availability" css={bodyItemStyle}>
                  {renderAvailability(item)}
                </Body.Item>
                <Body.Item key="appointment_type" css={bodyItemStyle}>
                  {getAppointmentName(item.appointmentType)}
                </Body.Item>
                <Body.Item key="appointment_subtype" css={bodyItemStyle}>
                  {getAppointmentName(item.appointmentSubtype)}
                </Body.Item>
                <Body.Item key="room" css={bodyItemStyle}>
                  {item.roomName}
                </Body.Item>
                <Body.Item key="doctor" css={bodyItemStyle}>
                  <PatientName>Dr. {item.doctorName}</PatientName>
                  <GrayText>{item.doctorTime}</GrayText>
                </Body.Item>
                <Body.Item key="staff" css={bodyItemStyle}>
                  {item.staff.map(({ name }) => (
                    <PatientName key={name}>{name}</PatientName>
                  ))}
                </Body.Item>
                <Body.Item
                  key="actions"
                  css={css`
                    width: ${ACTIONS_WIDTH}px;
                  `}
                >
                  <ActionWrapper>
                    <CheckInAndOutModal
                      appointment={appointments.find(
                        appt => appt.id === item.id
                      )}
                    />
                  </ActionWrapper>
                </Body.Item>
              </Body.Row>
            )
          }
        </Body>
        <EmptyBody>
          <Appointment />
          <H6>No Appointments</H6>
          <GrayText css="font-size: 16px; font-weight: 100;">
            There are no appointments today
          </GrayText>
        </EmptyBody>
      </Table>
    </Perimeter>
  );
};

export default ListView;
