import React from "react";
import color from "src/styles/color";

interface Props {
  fill?: string;
}

const PatientNotes = ({ fill = color.gray3 }: Props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.06089 21.8412C9.13446 21.8741 9.2122 21.8901 9.28945 21.8901C9.44599 21.8901 9.59739 21.8242 9.70887 21.7044L9.70889 21.7044L12.7537 18.4293H13.3082C13.6429 18.4293 13.8959 18.1409 13.8959 17.8085C13.8959 17.4761 13.6428 17.1877 13.3082 17.1877H13.3081H13.308H13.3079H13.3075L13.3057 17.1877L13.2988 17.1877L13.2727 17.1877L13.1794 17.1878L12.8958 17.1881L12.6104 17.1886L12.5148 17.1889L12.4868 17.1891L12.4781 17.1892L12.4742 17.1893L12.4711 17.1894C12.4711 17.1894 12.4665 17.1896 12.4611 17.1902L12.4774 17.3393L12.4664 17.1897C12.3271 17.1998 12.1916 17.2617 12.0877 17.3735L12.0877 17.3735L9.87706 19.7513L9.87665 18.9541L9.87597 18.1412L9.87553 17.8714L9.87529 17.7944L9.87516 17.7731L9.87508 17.7663L9.875 17.7629L9.87489 17.7599L9.87472 17.7567L9.87449 17.7543L9.87453 17.7543C9.84921 17.4461 9.60562 17.1877 9.28931 17.1877H8.04478C8.04332 17.1877 8.03974 17.1873 8.03492 17.1822C8.02995 17.1768 8.0254 17.1677 8.0254 17.1555V9.12375C8.0254 9.11156 8.02995 9.10246 8.03492 9.09712C8.03974 9.09194 8.04333 9.09156 8.04478 9.09156H20.4911C20.4926 9.09156 20.4961 9.09194 20.5009 9.09711C20.5059 9.10246 20.5105 9.11156 20.5105 9.12375V12.2052C20.5105 12.5376 20.7635 12.826 21.0982 12.826C21.4328 12.826 21.6858 12.5376 21.6858 12.2052V9.12375C21.6858 8.43175 21.1601 7.85 20.4911 7.85H8.04478C7.37572 7.85 6.85 8.43175 6.85 9.12375V17.1555C6.85 17.8475 7.37571 18.4293 8.04478 18.4293H8.70184V21.2693C8.70184 21.514 8.83898 21.7423 9.06089 21.8412ZM9.06089 21.8412L9.12203 21.7043L9.06098 21.8413C9.06095 21.8413 9.06092 21.8413 9.06089 21.8412Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.3"
    />
    <path
      d="M20.4717 16.85C20.1372 16.85 19.8841 17.1385 19.8841 17.4708C19.8841 17.803 20.1372 18.0915 20.4717 18.0915C20.8063 18.0915 21.0594 17.803 21.0594 17.4708C21.0594 17.1385 20.8063 16.85 20.4717 16.85Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.3"
    />
    <path
      d="M13.3082 14.0752H10.2851C9.95044 14.0752 9.69741 14.3635 9.69741 14.6959C9.69741 15.0283 9.95048 15.3167 10.2851 15.3167H13.3082C13.6429 15.3167 13.8959 15.0283 13.8959 14.6959C13.8959 14.3635 13.6429 14.0752 13.3082 14.0752Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.3"
    />
    <path
      d="M24.0526 20.8421V24L21.6842 20.8421H16V14H25V20.8421H24.0526Z"
      stroke={fill}
      strokeWidth="1.3"
      strokeLinejoin="round"
    />
    <path
      d="M18.251 10.9625H10.2851C9.95044 10.9625 9.69741 11.2509 9.69741 11.5833C9.69741 11.9157 9.95049 12.204 10.2851 12.204H18.251C18.5857 12.204 18.8387 11.9157 18.8387 11.5833C18.8387 11.2509 18.5857 10.9625 18.251 10.9625Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.3"
    />
    <path
      d="M22.5052 16.85C22.1706 16.85 21.9175 17.1385 21.9175 17.4708C21.9175 17.803 22.1706 18.0915 22.5052 18.0915C22.8398 18.0915 23.0929 17.803 23.0929 17.4708C23.0929 17.1385 22.8397 16.85 22.5052 16.85Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.3"
    />
    <path
      d="M18.4377 16.85C18.1032 16.85 17.85 17.1385 17.85 17.4708C17.85 17.803 18.1032 18.0915 18.4377 18.0915C18.7723 18.0915 19.0254 17.803 19.0254 17.4708C19.0254 17.1386 18.7723 16.85 18.4377 16.85Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.3"
    />
  </svg>
);

export default PatientNotes;
