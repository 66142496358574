import * as React from "react";
import styled from "react-emotion";
import { DetailedPatient } from "src/types/api";
import Insurance from "./Insurance";
import OrderList from "src/components/order-list/order-list";
import CreateOrder from "src/components/create-order/create-order";
import Kits from "src/containers/treatment/components/kits/kits";
import { Divider } from "antd";

interface Props {
  patient: DetailedPatient;
}

const Column = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const Row = styled("div")`
  display: flex;
  padding: 16px;
  justify-content: space-between;
`;

export const Billing = (props: Props) => {
  return (
    <Column>
      <Divider orientation="left">Insurance</Divider>
      <Row>
        <Insurance patient={props.patient} />
      </Row>

      <Divider orientation="left">Orders</Divider>

      <Row>
        <OrderList patientId={props.patient.id} />
        <CreateOrder patient={props.patient} />
      </Row>

      <Divider orientation="left">Kits</Divider>

      <Kits patientId={props.patient.id} />
    </Column>
  );
};

export default Billing;
