import React, { useState, useCallback, useEffect } from "react";
import styled from "react-emotion";
import { debounce, difference, head } from "lodash";
import { Radio } from "antd";
import color from "src/styles/color";
import InputText from "src/shared/InputText";
import { useFormDecorations } from "../../Forms/formDecorations";
import { getRenderedOptions } from "./renderUtil";

const Perimeter = styled.div`
  & .ant-radio-wrapper {
    margin-top: 10px;
  }
  & .ant-radio-group {
    display: flex;
    flex-direction: column;
  }
  & .ant-radio-inner::after {
    background-color: ${color.primary};
  }
  & .ant-radio-checked .ant-radio-inner {
    border-color: ${color.primary};
  }
  & .ant-radio:hover .ant-radio-inner {
    border-color: ${color.primary};
  }
`;

const Choice = props => {
  const {
    options,
    value,
    handleChange,
    placeholder,
    withInput,
    disabled,
  } = props;

  const formDecorations = useFormDecorations();
  const [inputTextValue, setInputTextValue] = useState(
    difference(options, value)[0]
  );
  useEffect(() => {
    setInputTextValue(head(difference(options, value)));
  }, [value, options]);
  const debounced = useCallback(debounce(handleChange, 1500), [handleChange]);
  return (
    <Perimeter>
      <Radio.Group
        disabled={disabled}
        value={value}
        onChange={evt => handleChange(evt.target.value)}
      >
        {getRenderedOptions(options, formDecorations).map(
          ({ value, label }) => (
            <Radio key={value} value={value}>
              {label}
            </Radio>
          )
        )}
      </Radio.Group>
      {withInput && (
        <div style={{ marginTop: "16px" }}>
          <InputText
            disabled={disabled}
            style={{ maxWidth: "272px" }}
            placeholder={placeholder}
            value={inputTextValue}
            onChange={evt => setInputTextValue(evt.target.value)}
            onBlur={evt => evt.target.value && debounced(evt.target.value)}
          />
        </div>
      )}
    </Perimeter>
  );
};

export default Choice;
