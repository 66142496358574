import React, { useState } from "react";
import CircleCross from "src/shared/Icons/CircleCross.svg";
import CircleWarning from "src/shared/Icons/CircleWarning.svg";
import Modal from "src/shared/Modal";
import Button, { ButtonRow } from "src/shared/Button";
import { Content, icon, Title, Text } from "./styles";

type ConfirmModalProps = {
  title?: string;
  text?: string;
  actionText?: string;
  hidden?: boolean;
  onClose: () => void;
  onConfirm: () => void | Promise<void>;
};

type ModalType = "delete" | "warn" | "text-only";

interface Props extends ConfirmModalProps {
  type: ModalType;
  // allow an optional middle button.  The parent controls this button and
  // therefore it's onClick handler.
  middleButton?: React.ReactNode;
}

const getConfirmIcon = type => {
  if (type === "text-only") return null;
  if (type === "warn") return CircleWarning;

  return CircleCross;
};
const ConfirmModal: React.FC<Props> = ({
  hidden,
  onClose,
  type,
  title = "Are you sure?",
  text,
  actionText = "Confirm",
  onConfirm,
  middleButton,
}) => {
  const [confirmed, setConfirmed] = useState(false);
  const ConfirmIcon = getConfirmIcon(type);
  return (
    <Modal hidden={hidden} onClose={onClose}>
      <Content>
        {ConfirmIcon && (
          <img className={icon} src={ConfirmIcon} alt="Warning" />
        )}
        <Title>{title}</Title>
        <Text>{text}</Text>

        <ButtonRow position="center">
          <Button kind="default" onClick={onClose}>
            Cancel
          </Button>
          {middleButton || null}
          <Button
            kind="primary"
            disabled={confirmed}
            onClick={() => {
              if (confirmed) {
                return;
              }
              setConfirmed(true);
              onConfirm();
            }}
          >
            {actionText}
          </Button>
        </ButtonRow>
      </Content>
    </Modal>
  );
};

export default ConfirmModal;
