import styled from "react-emotion";
import color from "src/styles/color";
import textStyles from "src/styles/textStyles";
import { slotWidth, cellHeight, endHour, startHour } from "src/shared/consts";

export const TimeMarks = styled.div`
  flex: 0;
  display: flex;
  flex-direction: column;
  width: 80px;
  position: relative;
  height: ${(endHour - startHour) * cellHeight}px;
`;

export const TimeMark = styled.div`
  background: ${color.white};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${cellHeight}px;
  min-width: 80px;
  border-left: 1px solid ${color.border};
  border-right: 1px solid ${color.border};
  border-bottom: 1px solid ${color.border};
  ${textStyles("small")};
`;

export const Perimeter = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  border-top: 1px solid ${color.border};
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  max-height: calc(100vh - 260px);

  // Firefox has no overflow: overlay, so default to scroll
  overflow: scroll;
  overflow: overlay;
  border-bottom: 1px solid ${color.border};
`;

export const Columns = styled.div``;

export const Header = styled.div`
  display: flex;
  width: 100%;
  background: ${color.white};
`;

export const EmptyColumnsItem = styled.div`
  width: 80px;
  border-right: 1px solid ${color.border};
  border-bottom: 1px solid ${color.border};
  border-left: 1px solid ${color.border};
`;

export const ColumnsItem = styled.div`
  flex: 1;
  line-height: 2;
  padding: 10px 0;
  min-width: ${slotWidth}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${color.border};
  border-bottom: 1px solid ${color.border};
  ${textStyles("large")};
  font-weight: bold;
`;

export const ColumnHeader = styled.div`
  display: flex;
  width: 100%;

  .ant-checkbox-wrapper {
    margin: 0 0 0 1rem;
  }

  > span {
    margin: 0 3rem 0 1rem;
    text-align: center;
    flex: 1;
  }
`;

export const Schedule = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: white;
  position: relative;
  height: ${(endHour - startHour) * cellHeight}px;
`;

export const Popup = styled.div``;
export const Slots = styled.div``;

export const RightTriangle = styled.div`
  position: absolute;
  right: 0;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid ${color.primary};
  z-index: 5;
  margin-top: -9px;
`;

export const CurrentTimeline = styled.div`
  position: absolute;
  background: ${color.primary};
  height: 2px;
  width: 100%;
  z-index: 5;
  opacity: 0.6;
`;
