import React from "react";
import { states } from "src/utils/address";

type Props = {
  onChange: (country: string) => void;
  value: string;
  className?: string;
  style?: any;
};

// TODO: clear console warning:  Use the `defaultValue` or `value` props on <select> instead of setting `selected` on <option>

const StateSelect: React.FC<Props> = ({ onChange, value, ...rest }) => {
  const handleChange = (e: React.FormEvent<HTMLSelectElement>) =>
    onChange(e.currentTarget.value);

  const hasValue = Object.keys(states).includes(value);

  return (
    <select value={value} onChange={handleChange} {...rest}>
      {/* Placeholder */}
      <option value="" disabled selected={!hasValue}>
        State
      </option>
      {Object.keys(states).map(abbr => (
        <option key={abbr} value={abbr}>
          {states[abbr]}
        </option>
      ))}
    </select>
  );
};

export default StateSelect;
