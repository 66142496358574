import { V, S } from "src/shared-styles";
const styles = {
  V,
  S,
  fileContainer: {
    ...S.contentBlock,
    width: "100%",
    height: "100vh",
    marginLeft: 0,
    marginRight: 0,
  },
};

export default styles;
