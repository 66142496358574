const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: "1.5rem",
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  addressForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  addressField: {},
  addressButton: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
};

export default styles;
