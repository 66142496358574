import React, { useState } from "react";
import styled from "react-emotion";

import PipelineTagsPopover, {
  pipelineTagsOptions,
} from "src/shared/Popovers/PipelineTags";
import AddCustomTagConfirmModal from "src/shared/AddCustomTagConfirmModal";

import CustomTag from "./CustomTag";
import InvisibleButton from "../NewTask/InvisibleButton";
import { PipelineTags } from "../types";

type Props = {
  tags: PipelineTags[];
  onChange: (tags: PipelineTags) => void;
};

const CustomTagsList: React.FC<Props> = ({ tags, onChange }) => {
  const [
    tagWaitingConfirm,
    setTagWaitingConfirm,
  ] = useState<PipelineTags | null>(null);

  const isTagsListEmpty = tags.length === 0;
  const isTagsListFull = tags.length === pipelineTagsOptions.length;
  const buttonText = isTagsListFull
    ? ""
    : isTagsListEmpty
    ? "Select Tag"
    : "+ Add tag";

  const handleClick = (tag: PipelineTags) => {
    setTagWaitingConfirm(tag);
  };
  const handleConfirm = () => {
    onChange(tagWaitingConfirm as PipelineTags);
    setTagWaitingConfirm(null);
  };
  const handleCancel = () => {
    setTagWaitingConfirm(null);
  };

  return (
    <Wrapper>
      <List hide={isTagsListEmpty}>
        {tags.map((tag, index) => (
          <Item key={tag}>
            <CustomTag tag={tag} />
            {index === tags.length - 1 ? "" : ", "}
          </Item>
        ))}
      </List>
      <PipelineTagsPopover value={tags} onClick={handleClick}>
        <InvisibleButton>{buttonText}</InvisibleButton>
      </PipelineTagsPopover>
      {tagWaitingConfirm && (
        <AddCustomTagConfirmModal
          onClose={handleCancel}
          onConfirm={handleConfirm}
        />
      )}
    </Wrapper>
  );
};

export default CustomTagsList;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const List = styled.ul<{
  hide: boolean;
}>`
  display: ${({ hide }) => (hide ? "none" : "flex")};
  align-items: center;
  gap: 8px;
  padding: 0;
  margin: 0;
  margin-right: 12px;
  list-style-type: none;
`;

const Item = styled.li`
  display: inline-block;
  padding: 0;
  margin: 0;

  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 500;
`;
