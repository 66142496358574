import React from "react";
import { CrownProps, RootProps } from "./types";

export const Crown: React.SFC<CrownProps> = ({ fill, stroke, style }) => (
  <svg
    width="21"
    height="30"
    viewBox="0 0 21 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M12.6816 1.565C10.8211 0.400359 9.64038 1.20108 8.03033 2.47488L2.66354 7.93415C-0.0198594 11.0277 1.11313 17.7002 2.12686 20.8544C3.558 24.5546 7.56521 31.1906 12.1449 28.1335C17.8695 24.312 18.9428 17.9428 19.4795 15.9411C20.0162 13.9394 20.0162 9.02601 18.4061 6.8423C16.7961 4.65859 15.0072 3.02081 12.6816 1.565Z"
      fill={fill || "#fff"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />
  </svg>
);

// Root always contains the portion as if it attached as a Crown.
export const Root: React.SFC<RootProps> = ({
  hasRootCanal,
  style,
  fill,
  stroke,
}) => (
  <svg
    width="16"
    height="56"
    viewBox="0 0 16 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M2.5 25.318C1.9 18.5683 1.25 10.4437 1 7H5V1H11V7H15C14.8125 8.37749 15.25 12.7829 14.5 27.3843C13.5625 45.6361 7.1875 53.0401 3.625 54.762C0.775 56.1395 1.6875 51.2034 2.5 48.5632C2.6875 47.1284 3.0625 43.6731 3.0625 41.3314C3.0625 38.4042 3.25 33.7552 2.5 25.318Z"
      fill={fill || "#F1F4F5"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />

    {hasRootCanal && (
      <g transform="translate(4,8)">
        <path
          d="M0 44C4.5 27.5 3.5 8.5 2 1L8.5 0C6 18.5 5 31.5 0 44Z"
          fill="#C270A7"
        />
      </g>
    )}
  </svg>
);
