import React from "react";
import { Input } from "antd";

const TextArea = props => {
  const { placeholder, onBlur, value, onChange, style, disabled } = props;

  return (
    <Input.TextArea
      disabled={disabled === true}
      autosize={{ minRows: 4, maxRows: 12 }}
      placeholder={placeholder}
      onBlur={onBlur}
      value={value}
      onChange={onChange}
      style={style}
    />
  );
};

export default TextArea;
