import styled, { css } from "react-emotion";
import color from "src/styles/color";

export const Wrapper = styled.div`
  display: flex;
`;

export const buttonStyles = isActive => css`
  width: 56px;
  height: 30px;
  border-color: ${color.border} !important;
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    border-radius: 2px 0 0 2px !important;
    border-left: 1px solid ${color.border};
  }

  &:last-child {
    border-radius: 0 2px 2px 0 !important;
  }

  border-left: none;
  border-right: 1px solid ${color.border};
  border-radius: 2px;

  transition-duration: 0.25s !important;

  & > span {
    line-height: 1;
  }

  &:hover {
    background: ${color.gray1} !important;

    &,
    & > span {
      color: ${color.font} !important;
    }
  }

  ${isActive &&
    css`
      font-weight: bold;
      & {
        background: ${color.primary} !important;

        &,
        & > span {
          color: ${color.white} !important;
        }
      }
      &:hover {
        background: ${color.primaryHover} !important;

        &,
        & > span {
          color: ${color.white} !important;
        }
      }
    `}
`;
