import React from "react";
import { notification } from "antd";
import ConfirmModal from "src/shared/ConfirmModal";
import { useReopenOrder } from "../query";

type Props = {
  orderID: string;
  onClose: () => void;
};

const ReopenModal: React.FC<Props> = ({ orderID, onClose }) => {
  const reopenOrder = useReopenOrder();

  const onClick = async () => {
    const result = await reopenOrder({ id: orderID });
    if (result.error) {
      notification.error({
        message: "There was an error opening the order",
      });
      return;
    }

    notification.success({ message: "Order opened" });
    onClose();
  };

  return (
    <ConfirmModal
      onClose={onClose}
      onConfirm={onClick}
      type="warn"
      title="Reopen this order?"
      text="Do you really want to reopen this order?"
      actionText="Reopen"
    />
  );
};

export default ReopenModal;
