import React from "react";
import styled from "react-emotion";
import { isNil } from "lodash";

import { Treatment, Patient, HoldForTreatment } from "src/types/gql";
import { titleCase } from "src/shared/util";
import color from "src/styles/color";

type Props = {
  treatment: Treatment;
  patient: Patient;
};

const getTrayInformation = (treatment: Treatment) => {
  if (isNil(treatment.currentTrayNumber))
    return "The patient is not currently wearing trays.";

  return `The patient is on tray ${treatment.currentTrayNumber}`;
};

const getHumanReadableHoldDescription = (hold: HoldForTreatment) => {
  switch (hold.category) {
    case "external_provider": {
      return "Next step: Pre-tx task - patient needs to visit an External Provider. Aligner production is paused until complete.";
    }
    case "rescan": {
      return "Next step: Pre-tx task - patient needs a Rescan. Aligner production is paused until complete.";
    }
    case "observation": {
      return `Next step: Pre-tx task - patient needs a Restart appointment${
        hold.pipeline.dueAt
          ? ` on or after ${new Date(hold.pipeline.dueAt).toLocaleDateString()}`
          : ""
      }. Aligner production is paused until complete.`;
    }
    case "treatment_terminated": {
      return "Next step: None - treatment has been terminated.";
    }
    case "other": {
      return `Next step: ${
        hold.description ? hold.description : "no description provided"
      }. Aligner production is paused until complete.`;
    }
    default: {
      return `Next step: ${[titleCase(hold.category), hold.description]
        .filter(Boolean)
        .join(": ")}`;
    }
  }
};

const TreatmentDetails: React.FC<Props> = ({ treatment, patient }) => {
  const holds = (treatment ? treatment.holds : []).filter(h => !h.completedAt);

  if (!treatment) {
    return null;
  }

  const ongoingReferral =
    treatment.referrals &&
    treatment.referrals.find(ref => {
      return ref.completedAt === null;
    });

  return (
    <TreatmentDetailsWrapper>
      <SubHeader>Treatment Details</SubHeader>
      {treatment && <div>{getTrayInformation(treatment)}</div>}
      {patient.currentAppVersion && (
        <div>Current App Version: {patient.currentAppVersion}</div>
      )}
      {holds.map(h => (
        <WarningPanel>{getHumanReadableHoldDescription(h)}</WarningPanel>
      ))}

      {ongoingReferral && (
        <InfoPanel>
          Patient has an ongoing referral for {ongoingReferral.referralType}
        </InfoPanel>
      )}
    </TreatmentDetailsWrapper>
  );
};

export default React.memo(TreatmentDetails);

const SubHeader = styled("div")`
  width: 100%;
  background: #fff;
  margin-bottom: 1rem;
  color: #da7509;
  font-weight: bold;
`;

const TreatmentDetailsWrapper = styled("div")`
  border: 1px solid ${color.border};
  padding: 1rem;
  background: white;
  margin-bottom: 12px;
`;

const WarningPanel = styled("div")`
  margin: 1rem 0rem 1rem 0;
  background-color: #ef130a;
  line-height: 3.5;
  vertical-align: middle;
  color: white;
  border-radius: 4px;
  padding-left: 1rem;
`;

const InfoPanel = styled("div")`
  margin: 1rem 0rem 1rem 0;
  background-color: #fafafa;
  line-height: 3.5;
  vertical-align: middle;
  border-radius: 4px;
  padding-left: 1rem;
  border: 1px solid #d9d9d9;
`;
