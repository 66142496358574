import React, { useState, useEffect } from "react";
import styled from "react-emotion";

import { useSelfRoles } from "src/state/self";
// shared
import TodoBox from "src/shared/TodoBox";
import Button from "src/shared/Button";
// local
import { Section, SectionTitle } from "../styledComponents";
import TreatmentTimelineModal from "./TreatmentTimelineModal";
import GeneralDentistModal from "./GeneralDentistModal";
import UniformTeethAppModal from "./UniformTeethAppModal";
import TreatmentCostModal from "./TreatmentCostModal";
import useFormUI, {
  FormUI,
} from "src/scenes/PatientProfile/Forms/GQLForms/useFormUI";
import { renderTitlegroupQuestions } from "../consts";
import { notification } from "antd";

type Props = {
  ui: FormUI;
  patientID: string;
};

const Conclusion: React.FC<Props> = props => {
  const { isDoctor } = useSelfRoles();
  const { ui, patientID } = props;
  const isSubmitted = ui.submission && !!ui.submission.submittedAt;
  const isApproved = ui.submission && !!ui.submission.approvedAt;

  // Whether we're saving a submission
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [selectedModal, setSelectedModal] = useState();
  const [hasAtLeastOneReferral, setHasAtLeastOneReferral] = useState(false);
  const referralUI = useFormUI({
    slug: "referral",
    userID: patientID,
    submissionID: null,
    parentSubmissionID: ui.submission.id,
  });

  const referrals = referralUI ? referralUI.submissions : [];
  useEffect(() => {
    if (referrals.length > 0) {
      setHasAtLeastOneReferral(true);
    }
  }, [referrals, hasAtLeastOneReferral]);

  const closeModal = () => setSelectedModal(null);

  const getKind = (slug: string) => {
    return ui.getAnswerValue(slug) ? "success" : "warning";
  };

  const handleDone = (slug: string) => {
    const q = (ui as FormUI).form.questions[slug];
    q && ui.saveAnswer(q, true);
    closeModal();
  };

  const hasAdditionalOralHealthConcern = (): boolean => {
    const answer = ui.getAnswerValue("additional_oral_health_concerns");
    if (answer === null || answer === undefined) {
      return false;
    }

    if (!Array.isArray(answer)) {
      return false;
    }

    return answer.some(concern => concern !== "None");
  };

  const hasActiveDisease = (): boolean => {
    const answer = ui.getAnswerValue("periodontal_issues_present");
    if (answer === null || answer === undefined) {
      return false;
    }

    if (!Array.isArray(answer)) {
      return false;
    }

    return answer.includes("Active disease present");
  };

  const hasAttachmentLossBeyondMucogingivalJunction = (): boolean => {
    const answer = ui.getAnswerValue("periodontal_issues_present");
    if (answer === null || answer === undefined) {
      return false;
    }

    if (!Array.isArray(answer)) {
      return false;
    }

    return answer.includes("Attachment loss beyond mucogingival junction");
  };

  const hasPendingDentalWorkThatCannotWait = (): boolean => {
    const answer = ui.getAnswerValue(
      "has_pending_dental_work_that_cannot_wait"
    );
    if (answer === null || answer === undefined) {
      return false;
    }

    return answer === "Yes";
  };

  const hasCrepitus = (): boolean => {
    const answer = ui.getAnswerValue("which_tmj_issues_are_present");
    if (answer === null || answer === undefined) {
      return false;
    }

    if (!Array.isArray(answer)) {
      return false;
    }

    return answer.includes("Crepitus");
  };

  const handleSubmit = async () => {
    // TODO: Convert to GQL
    if (isSubmitting) {
      return;
    }
    if (
      (hasAdditionalOralHealthConcern() ||
        hasActiveDisease() ||
        hasAttachmentLossBeyondMucogingivalJunction() ||
        hasPendingDentalWorkThatCannotWait() ||
        hasCrepitus()) &&
      !hasAtLeastOneReferral
    ) {
      notification.error({
        message: "Error submitting submission",
        description:
          "Based on the answers to the BV form, at least one Referral form is required. Please create and fill out at least one Referral form.",
      });
      return;
    }
    setIsSubmitting(true);
    await ui.submitSubmission();
    setIsSubmitting(false);
  };

  let buttonText = isSubmitted ? "Submitted" : "Submit beginning visit form";
  if (isSubmitting) {
    buttonText = "Submitting...";
  }

  const handleApprove = async () => {
    if (isApproving) {
      return;
    }
    setIsApproving(true);
    await ui.approveSubmission();
    setIsApproving(false);
  };

  return (
    <Wrapper>
      <Section>
        <TreatmentTimelineModal
          isOpen={selectedModal === "timeline"}
          handleClose={closeModal}
          handleDone={() => handleDone("treatment_timeline")}
        />
        <GeneralDentistModal
          isOpen={selectedModal === "general"}
          handleClose={closeModal}
          handleDone={() => handleDone("gen_dentist_ppointment_overview")}
        />
        <TreatmentCostModal
          isOpen={selectedModal === "cost"}
          handleClose={closeModal}
          handleDone={() => handleDone("treatment_cost_overview")}
        />
        <UniformTeethAppModal
          isOpen={selectedModal === "app"}
          handleClose={closeModal}
          handleDone={() => handleDone("mobile_app_overview")}
        />

        <SectionTitle>
          Final things to review with patient{" "}
          <span role="img" aria-label="flag">
            🏁
          </span>
        </SectionTitle>
        <div style={{ display: "flex" }}>
          <TodoBox
            onClick={() => setSelectedModal("timeline")}
            kind={getKind("treatment_timeline")}
            disabled={isSubmitted}
          >
            <TodoBox.Title>Treatment Timeline</TodoBox.Title>
            <TodoBox.Subtitle>
              Review the patient’s treatment timeline and answer any questions
              they may have.
            </TodoBox.Subtitle>
          </TodoBox>

          <TodoBox
            onClick={() => setSelectedModal("general")}
            kind={getKind("gen_dentist_ppointment_overview")}
            disabled={isSubmitted}
          >
            <TodoBox.Title>General Dentist Appointment</TodoBox.Title>
            <TodoBox.Subtitle>
              Schedule an appointment with the patient’s general dentist if
              needed.
            </TodoBox.Subtitle>
          </TodoBox>
        </div>

        <div style={{ display: "flex", marginTop: "24px" }}>
          <TodoBox
            onClick={() => setSelectedModal("cost")}
            kind={getKind("treatment_cost_overview")}
            disabled={isSubmitted}
          >
            <TodoBox.Title>Treatment Cost</TodoBox.Title>
            <TodoBox.Subtitle>
              Review the pricing structure and any discounts with the patient.
            </TodoBox.Subtitle>
          </TodoBox>

          <TodoBox
            onClick={() => setSelectedModal("app")}
            kind={getKind("mobile_app_overview")}
            disabled={isSubmitted}
          >
            <TodoBox.Title>Uniform Teeth App</TodoBox.Title>
            <TodoBox.Subtitle>
              Walk through the Uniform Teeth App with the patient and answer any
              questions.
            </TodoBox.Subtitle>
          </TodoBox>
        </div>
      </Section>

      <Section>
        <SectionTitle>Patient Feedback</SectionTitle>
        {renderTitlegroupQuestions(ui, "Patient Feedback")}
      </Section>

      <Section style={{ marginTop: "40px" }}>
        {(isSubmitted && isDoctor) || isApproved ? (
          <Button
            kind="primary"
            style={{ height: "40px" }}
            onClick={() => {
              handleApprove();
            }}
            disabled={isApproved || isApproving}
          >
            {isApproved ? "Approved" : "Approve"}
          </Button>
        ) : (
          <Button
            kind="primary"
            style={{ height: "40px" }}
            onClick={handleSubmit}
            disabled={isSubmitted || isSubmitting}
          >
            {buttonText}
          </Button>
        )}
      </Section>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-right: 32px;
`;

export default Conclusion;
