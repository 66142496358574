import styled from "react-emotion";

import color from "src/styles/color";

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  min-height: 32px;

  border-top: 1px solid ${color.borderLight};
  padding: 12px 24px;

  background: #fff;
`;

export const RowContent = styled.div`
  width: 170px;
`;
