import React from "react";
import { sum, isEmpty, get } from "lodash";
import { DateTime } from "luxon";

import { claimAmount, getInsuranceCharges } from "src/utils/insurance";
import money from "src/utils/money";

import {
  Container,
  SectionTitle,
  OrderGrid,
  OrderGridTitle,
  Line,
} from "./OrderSummary";

const InsuranceDetails = ({ order }) => {
  if (isEmpty(order.claims)) return null;

  const insuranceCharges = getInsuranceCharges(order);

  const totalInsuranceAmount = sum(
    insuranceCharges.map(charge => charge.amount)
  );
  const totalClaimAmount = sum(order.claims.map(claimAmount));

  // for now, assume patient only has 1 claim and we use that
  // to display the insurance provider name
  // TO-DO: query for insurance charges and the insurance policy
  // they are linked to
  const claim = order.claims[0];
  const insuranceProvider = get(
    claim,
    "insurancePolicy.InsuranceProvider.name"
  );
  return (
    <Container>
      <SectionTitle>Insurance claim breakdown</SectionTitle>
      <OrderGridTitle>
        <div>Date</div>
        <div>Status</div>
        <div>Provider</div>
        <div>Amount</div>
      </OrderGridTitle>
      {insuranceCharges.map(charge => (
        <OrderGrid key={charge.id}>
          <div>
            {DateTime.fromISO(charge.createdAt).toFormat("LLLL d, yyyy")}
          </div>
          <div>Completed</div>
          <div>{insuranceProvider}</div>
          <div>{money(charge.amount)}</div>
        </OrderGrid>
      ))}
      <Line />
      <OrderGrid>
        <div />
        <div />
        <div>
          <b>Total Amount Paid by Insurance</b>
        </div>
        <div>
          <b>{money(totalInsuranceAmount)}</b>
        </div>
      </OrderGrid>
      <OrderGrid>
        <div />
        <div />
        <div>Claim Amount</div>
        <div>{money(totalClaimAmount)}</div>
      </OrderGrid>
      <OrderGrid>
        <div />
        <div />
        <div>Insurance Balance</div>
        <div>{money(totalClaimAmount - totalInsuranceAmount)}</div>
      </OrderGrid>
    </Container>
  );
};

export default InsuranceDetails;
