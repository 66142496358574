import React, { useState } from "react";
import color from "src/styles/color";
import InputText from "src/shared/InputText";
import Button from "src/shared/Button";
import ExternalProviderTypeSelect from "../VerifiedSpecialistSummary/ExternalProviderTypeSelect";
import StateSelect from "src/shared/StateSelect";
import { BaseVerifiedSpecialist } from "src/shared/VerifiedSpecialistSearch/VerifiedSpecialistSearch";

import {
  DetailsContainer,
  DetailsTitle,
  NameContainer,
  DetailsSubtitle,
  DetailsRow,
} from "../styles";
import VerifiedSpecialistSearch from "../../VerifiedSpecialistSearch";

const EditMode = ({
  specialist: {
    firstName,
    lastName,
    type,
    email,
    phone,
    phone2,
    clinicName,
    address,
    preferredProvider,
  },
  onSubmit,
}) => {
  const [details, setDetails] = useState({
    firstName,
    lastName,
    type,
    email,
    phone,
    phone2,
    clinicName,
    address,
    preferredProvider,
  });

  const onSave = () => {
    onSubmit(details);
  };

  const onChange = (fieldName, maybeOptions?) => e => {
    const options = maybeOptions || {};
    if (options.isAddress) {
      setDetails({
        ...details,
        address: {
          ...details.address,
          [fieldName]: options.isSelect ? e : e.target.value,
        },
      });
      return;
    }

    setDetails({
      ...details,
      [fieldName]: options.isSelect ? e : e.target.value,
    });
  };

  const onChangeVerifiedSpecialistSearch = (s: BaseVerifiedSpecialist) => {
    setDetails(currentState => ({
      ...currentState,
      firstName: s.firstName,
      lastName: s.lastName,
      type: s.type,
      email: s.email,
      phone: s.phone,
      phone2: s.phone2,
      clinicName: s.clinicName,
      address: {
        ...currentState.address,
        line1: s.address.line1,
        line2: s.address.line2,
        city: s.address.city,
        postalCode: s.address.postalCode,
        state: s.address.state,
      },
    }));
  };

  return (
    <DetailsContainer>
      <VerifiedSpecialistSearch
        style={{ width: "376px", marginBottom: "7px", marginTop: "-6px" }}
        onChange={onChangeVerifiedSpecialistSearch}
      />
      <div style={{ marginBottom: "7px" }}>
        Telephone numbers must conform to the{" "}
        <a
          href="https://www.twilio.com/docs/glossary/what-e164"
          target="_blank"
          rel="noopener noreferrer"
        >
          E.164
        </a>{" "}
        format.
      </div>
      <DetailsTitle>
        <NameContainer>
          <InputText
            value={details.firstName}
            placeholder="First name"
            onChange={onChange("firstName")}
          />
          <InputText
            value={details.lastName}
            placeholder="Last name"
            onChange={onChange("lastName")}
          />
        </NameContainer>
        <div
          style={{ fontSize: "14px", fontWeight: "normal", color: color.gray3 }}
        >
          <Button kind="primary" onClick={onSave}>
            Save
          </Button>
        </div>
      </DetailsTitle>
      <DetailsSubtitle>
        <ExternalProviderTypeSelect
          style={{ borderColor: color.border }}
          onChange={onChange("type", { isSelect: true })}
          value={details.type}
        />
      </DetailsSubtitle>
      <hr />
      <DetailsRow>
        <div>Email</div>
        <div>
          <InputText
            value={details.email}
            placeholder="Email"
            onChange={onChange("email")}
          />
        </div>
      </DetailsRow>
      <hr />
      <DetailsRow>
        <div>Phone</div>
        <div>
          <InputText
            value={details.phone}
            placeholder="Phone number"
            onChange={onChange("phone")}
          />
        </div>
      </DetailsRow>
      <hr />
      <DetailsRow>
        <div>Phone&nbsp;2</div>
        <div>
          <InputText
            value={details.phone2}
            placeholder="Phone number 2"
            onChange={onChange("phone2")}
          />
        </div>
      </DetailsRow>
      <hr />
      <DetailsRow>
        <div>Clinic</div>
        <div>
          <InputText
            value={details.clinicName}
            placeholder="Clinic name"
            onChange={onChange("clinicName")}
          />
        </div>
      </DetailsRow>
      <hr />
      <DetailsRow style={{ lineHeight: "25px" }}>
        <div>Address</div>
        <div>
          <InputText
            value={details.address.line1}
            placeholder="Line 1"
            onChange={onChange("line1", { isAddress: true })}
          />
          <br />
          <InputText
            value={details.address.line2}
            placeholder="Line 2"
            onChange={onChange("line2", { isAddress: true })}
          />
          <br />
          <div style={{ display: "flex" }}>
            <InputText
              value={details.address.city}
              placeholder="City"
              onChange={onChange("city", { isAddress: true })}
            />
            <StateSelect
              style={{ width: 200, marginLeft: 10, cursor: "pointer" }}
              value={details.address.state}
              onChange={onChange("state", { isAddress: true, isSelect: true })}
            />
          </div>
          <br />
          <InputText
            value={details.address.postalCode}
            placeholder="Zip code"
            onChange={onChange("postalCode", { isAddress: true })}
          />
        </div>
      </DetailsRow>
    </DetailsContainer>
  );
};

export default EditMode;
