import React from "react";
import last from "lodash/last";
import {
  TimelineItemSelected,
  TimelineItemDefault,
  TimelineItemIconWrapper,
  TimelineItemTitle,
  TimelineItemCard,
  TimelineItemLine,
  TimelineItemLineFaint,
  ClipText,
  TimelineItemSectionAnchor,
  TimelineItemSectionPathLink,
  TimelineItemLineLinkText,
  TimelineItemLineIconWrapper,
  TimelineItemLineIconSpacer,
} from "../styles";
import { TimelineFileData } from "../queries";
import AppointmentIcon from "../icons/appointment.svg";
import SubmissionIcon from "../icons/submission.svg";
import SubmitterIcon from "../icons/submitter.svg";
import HealthIcon from "../icons/health.svg";
import LocationIcon from "../icons/location.svg";
import ClockIcon from "../icons/clock.svg";
import CubeIcon from "../icons/cube.svg";
import FormIcon from "../icons/form.svg";
import { TimelineEntry, formatDateForTimeline } from "../util";

export interface Props {
  patientID: string;
  selected?: boolean;
  entry: TimelineEntry;
  sortedFiles: Array<TimelineFileData>;
  onClickSubmission: (submission: TimelineEntry["submission"]) => void;
}

export default function({
  patientID,
  selected,
  entry,
  sortedFiles,
  onClickSubmission,
}: Props) {
  const { appointment, submission } = entry;
  if (!appointment && !submission) {
    return null;
  }
  const Container = selected ? TimelineItemSelected : TimelineItemDefault;
  const latestTxpFile = last(
    sortedFiles.filter(file => file.type === "TreatmentPlanZip")
  );
  const isTreatmentSetup =
    submission && submission.form.slug === "treatment_setup";
  const getAddressText = () => {
    if (!appointment) {
      return "";
    }
    return `${appointment.clinicName} - ${appointment.address}, ${
      appointment.city
    }`;
  };
  return (
    <Container>
      {appointment ? (
        <TimelineItemIconWrapper>
          <img src={AppointmentIcon} alt="" />
        </TimelineItemIconWrapper>
      ) : (
        <TimelineItemIconWrapper>
          <img src={SubmissionIcon} alt="" />
        </TimelineItemIconWrapper>
      )}

      <TimelineItemCard>
        {appointment && (
          <TimelineItemSectionPathLink
            key="appointment"
            title={appointment.title}
            to={entry.pathUrl}
          >
            <TimelineItemTitle>{appointment.title}</TimelineItemTitle>
            {appointment.formattedDate && (
              <TimelineItemLine key="date">
                <TimelineItemLineIconWrapper>
                  <img src={ClockIcon} alt="" />
                </TimelineItemLineIconWrapper>
                <ClipText>{appointment.formattedDate}</ClipText>
              </TimelineItemLine>
            )}
            <TimelineItemLine key="address">
              <TimelineItemLineIconWrapper>
                <img src={LocationIcon} alt="" />
              </TimelineItemLineIconWrapper>
              <ClipText title={getAddressText()}>{getAddressText()}</ClipText>
            </TimelineItemLine>
            <TimelineItemLine key="doctor">
              <TimelineItemLineIconWrapper>
                <img src={HealthIcon} alt="" />
              </TimelineItemLineIconWrapper>
              <ClipText>Doctor: {appointment.doctor}</ClipText>
            </TimelineItemLine>
            {appointment.assistant && (
              <TimelineItemLine key="assistant" style={{ paddingTop: 0 }}>
                <TimelineItemLineIconSpacer />
                <ClipText>Assistant: {appointment.assistant}</ClipText>
              </TimelineItemLine>
            )}
          </TimelineItemSectionPathLink>
        )}

        {submission && (
          <TimelineItemSectionPathLink
            key="submission"
            title={submission.title}
            to={entry.pathUrl}
            onClick={() => onClickSubmission(submission)}
          >
            <TimelineItemTitle>
              <ClipText>{submission.title}</ClipText>
              <img src={FormIcon} alt="" />
            </TimelineItemTitle>
            {submission.doctor && (
              <TimelineItemLine key="doctor">
                Doctor: {submission.doctor}
              </TimelineItemLine>
            )}
            {submission.formattedApprovedAt ? (
              <>
                {submission.approver !== submission.doctor && (
                  <TimelineItemLine key="approver">
                    <TimelineItemLineIconWrapper>
                      <img src={SubmitterIcon} alt="" />
                    </TimelineItemLineIconWrapper>
                    <ClipText>{submission.approver}</ClipText>
                  </TimelineItemLine>
                )}
                <TimelineItemLineFaint key="approvedAt">
                  Approved {submission.formattedApprovedAt}
                </TimelineItemLineFaint>
              </>
            ) : (
              <>
                {submission.submitter &&
                  submission.submitter !== submission.doctor && (
                    <TimelineItemLine key="submitter">
                      <TimelineItemLineIconWrapper>
                        <img src={SubmitterIcon} alt="" />
                      </TimelineItemLineIconWrapper>
                      <ClipText>{submission.submitter}</ClipText>
                    </TimelineItemLine>
                  )}
                {submission.formattedSubmittedAt ? (
                  <TimelineItemLineFaint key="submittedAt">
                    Submitted {submission.formattedSubmittedAt}
                  </TimelineItemLineFaint>
                ) : (
                  <TimelineItemLineFaint key="createdAt">
                    Created {submission.formattedCreatedAt}
                  </TimelineItemLineFaint>
                )}
              </>
            )}
          </TimelineItemSectionPathLink>
        )}

        {isTreatmentSetup && latestTxpFile && (
          <TimelineItemSectionAnchor
            key="upload"
            title={latestTxpFile.filename}
            target="_blank"
            href={`/txp/${latestTxpFile.id}`}
          >
            <TimelineItemLine key="link">
              <TimelineItemLineIconWrapper>
                <img src={CubeIcon} alt="" />
              </TimelineItemLineIconWrapper>
              <TimelineItemLineLinkText>
                {[latestTxpFile.description, latestTxpFile.filename].join(
                  " - "
                )}
              </TimelineItemLineLinkText>
            </TimelineItemLine>
            <TimelineItemLineFaint key="uploadedAt">
              Uploaded {formatDateForTimeline(latestTxpFile.createdAt)}
            </TimelineItemLineFaint>
          </TimelineItemSectionAnchor>
        )}
      </TimelineItemCard>
    </Container>
  );
}
