import React, { useState } from "react";
import { notification } from "antd";
import styled from "react-emotion";

import color from "src/styles/color";
import Box from "src/shared/Box";
import Button, { ButtonRow } from "src/shared/Button";
import Modal from "src/shared/Modal";
import { useCreateCheckRefund } from "../query";
import DueDateIcon from "src/scenes/Tasks/Icons/DueDate";
import MoneyInput from "src/shared/MoneyInput";
import { RefundTypeCheck } from "../util";

import { DateTime } from "luxon";
import DatePopover from "src/shared/Popovers/Date";

type Props = {
  onClose: () => void;
  orderID: string;
  refetchOrders?: () => void;
};

const RefundCheckModal: React.FC<Props> = ({
  orderID,
  onClose,
  refetchOrders,
}) => {
  const [issueDate, setIssueDate] = useState<string | undefined>();
  const [refundAmount, setRefundAmount] = useState<number>(0);
  const [checkNumber, setCheckNumber] = useState<string>("");
  const createRefund = useCreateCheckRefund();

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!issueDate || !refundAmount || !checkNumber) return;

    const result = await createRefund({
      input: {
        type: RefundTypeCheck,
        orderId: orderID,
        amount: refundAmount,
        checkNumber: checkNumber,
        issueDate: issueDate,
      },
    });

    if (!result || result.error) {
      notification.error({
        message: "Something went wrong creating the refund",
      });
      return;
    }

    notification.success({ message: "Refund check added successfully" });

    if (refetchOrders) {
      refetchOrders();
    }

    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <Box title="Refund Check">
        <Content>
          <InputContainer>
            <Title>Refund Amount</Title>
            <MoneyInput value={refundAmount} onChange={setRefundAmount} />
          </InputContainer>
          <InputContainer>
            <Title>Check Number</Title>
            <InputBox
              type="text"
              value={checkNumber}
              onChange={e => setCheckNumber(e.currentTarget.value)}
            />
          </InputContainer>
          <div>
            <Title>Issue Date</Title>
            <DatePopover onClick={setIssueDate} value={issueDate}>
              <InvisibleButton>
                <DueDateIcon />
                {issueDate ? (
                  <Date>{DateTime.fromISO(issueDate).toLocaleString()}</Date>
                ) : (
                  <>Set Issue date</>
                )}
              </InvisibleButton>
            </DatePopover>
          </div>
          <ButtonRow position="right">
            <Button kind="default" onClick={onClose}>
              Cancel
            </Button>
            <Button
              kind="primary"
              onClick={submit}
              disabled={!refundAmount && !checkNumber && !issueDate}
            >
              Save
            </Button>
          </ButtonRow>
        </Content>
      </Box>
    </Modal>
  );
};

export default RefundCheckModal;

const Content = styled.div`
  padding: 32px;
  display: flex;
  flex-flow: column;
  flex: 1;

  height: 400px;
  width: 600px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 16px;
`;

const InputBox = styled.input`
  border: 1px solid ${color.border};
  padding: 10px 16px;
`;

const Date = styled.div`
  color: ${color.gray5};
`;

const InvisibleButton = styled.div`
  display: flex;
  align-items: center;
  border: 0;
  background: #fff;
  cursor: pointer;
  color: ${color.gray5};
  opacity: 1;
  transition: all 0.3s;

  &:focus {
    outline: 0 none;
  }

  b {
    color: ${color.gray5};
  }

  svg {
    margin-right: 8px;
  }
`;
