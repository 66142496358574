import React, { useState } from "react";
import { AutoComplete } from "antd";
import InputText from "src/shared/InputText";
import { filetypes, FiletypeStrings } from "../file";

interface Props {
  uncontrolled?: boolean;
  value?: string;
  placeholder?: string;
  onChange: (val: FiletypeStrings) => void;
  allowOthers?: boolean;
}

const filetypeData = Object.keys(filetypes).map(k => ({
  value: k,
  text: filetypes[k],
}));

const FiletypeFilter = ({
  value,
  placeholder,
  uncontrolled,
  onChange,
  allowOthers,
}: Props) => {
  const [filter, setFilter] = useState("");

  const filtered = filetypeData.filter(t => {
    return t.text.toLowerCase().indexOf(filter) >= 0;
  });

  return (
    <AutoComplete
      value={uncontrolled ? filter : value}
      placeholder={placeholder || "Add a file type"}
      onSearch={val => setFilter((val as string).toLowerCase())}
      style={{ width: "100%" }}
      onChange={value => {
        !uncontrolled && onChange(value as FiletypeStrings);
      }}
      onSelect={value => {
        onChange(value as FiletypeStrings);
      }}
      dataSource={filtered}
    >
      <InputText
        style={{ padding: "8px 12px" }}
        onKeyDown={evt => {
          if (allowOthers && evt.keyCode === 13 && evt.target.value !== "") {
            onChange(evt.target.value as FiletypeStrings);
          }
        }}
      />
    </AutoComplete>
  );
};

export default FiletypeFilter;
