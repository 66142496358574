import moment from "moment";
import React, { Component } from "react";
import {
  TextField,
  DatePicker,
  SelectField,
  MenuItem,
  RaisedButton,
} from "material-ui";

import { Field, formValues, reduxForm } from "redux-form";
import styles from "./task-dialog-style";
import {
  TASK_ASSIGNED_ROLE,
  TASK_TYPE,
  MANUFACTURING_TASK_CATEGORY,
  CLINICAL_TASK_CATEGORY,
  PRIORITY,
} from "../../config/constant";
import { isEmpty, map } from "lodash";
const TRUE = true;
const FALSE = false;
const FORM_NAME = "task";
const TASK_TYPE_FIELD_LABEL = "task_type";
const CATEGORY_FIELD_LABEL = "category";
const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    hintText={label}
    floatingLabelText={label}
    errorText={touched && error}
    {...input}
    {...custom}
  />
);
const renderDateField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => {
  let date = input.value;
  // to handle the issue with the input being a string like '2017-12-31' converting to GMT time which is off by 1 day
  // string will only come from redux-form
  // also to handle empty string on default
  if (typeof date === "string") {
    if (!isEmpty(date)) {
      date = moment(date).toDate();
    } else {
      date = new Date();
    }
  }
  return (
    <DatePicker
      hintText={label}
      floatingLabelText={label}
      errorText={touched && error}
      {...input}
      {...custom}
      value={date}
      onChange={(event, value) => {
        input.onChange(value);
      }}
    />
  );
};
const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <SelectField
    hintText={label}
    floatingLabelText={label}
    errorText={touched && error}
    {...input}
    {...custom}
    onChange={(event, index, value) => {
      input.onChange(value);
    }}
  />
);

const form = reduxForm({
  form: FORM_NAME,
});
const values = formValues(TASK_TYPE_FIELD_LABEL);

class TaskForm extends Component {
  handleTaskTypeChange = () => {
    this.props.change(CATEGORY_FIELD_LABEL, null);
  };
  render() {
    const categoryList =
      this.props[TASK_TYPE_FIELD_LABEL] === TASK_TYPE.MANUFACTURING
        ? MANUFACTURING_TASK_CATEGORY
        : CLINICAL_TASK_CATEGORY;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div style={styles.form}>
          <Field
            id="taskTitle"
            name="title"
            label="Title *"
            fullWidth
            component={renderTextField}
          />
          <Field
            id="taskNote"
            name="note"
            label="Note"
            fullWidth
            component={renderTextField}
          />
          <div style={styles.half}>
            <Field
              id="taskDueDate"
              name="due_date"
              label="Due Date *"
              component={renderDateField}
            />
          </div>
          <div style={styles.half}>
            <Field
              id="taskAssignedRole"
              name="assigned_role"
              label="Assigned Role *"
              component={renderSelectField}
            >
              <MenuItem
                value={TASK_ASSIGNED_ROLE.SALES_MANAGER}
                primaryText={TASK_ASSIGNED_ROLE.SALES_MANAGER}
              />
              <MenuItem
                value={TASK_ASSIGNED_ROLE.ORTHODONTIST}
                primaryText={TASK_ASSIGNED_ROLE.ORTHODONTIST}
              />
            </Field>
          </div>
          <div style={styles.half}>
            <Field
              id="taskType"
              name={TASK_TYPE_FIELD_LABEL}
              label="Task Type *"
              component={renderSelectField}
              onChange={this.handleTaskTypeChange}
            >
              <MenuItem
                value={TASK_TYPE.MANUFACTURING}
                primaryText={TASK_TYPE.MANUFACTURING}
              />
              <MenuItem
                value={TASK_TYPE.CLINICAL}
                primaryText={TASK_TYPE.CLINICAL}
              />
            </Field>
          </div>
          <div style={styles.half}>
            <Field
              id="category"
              name={CATEGORY_FIELD_LABEL}
              label="Category"
              component={renderSelectField}
            >
              {map(categoryList, category => (
                <MenuItem
                  key={category}
                  value={category}
                  primaryText={category}
                />
              ))}
            </Field>
          </div>
          <div style={styles.half}>
            <Field
              id="isComplete"
              name="is_complete"
              label="Is Complete"
              component={renderSelectField}
            >
              <MenuItem value={TRUE} primaryText="true" />
              <MenuItem value={FALSE} primaryText="false" />
            </Field>
          </div>
          <div style={styles.half}>
            <Field
              id="priority"
              name="priority"
              label="Priority"
              component={renderSelectField}
            >
              <MenuItem value={null} primaryText="normal" />
              <MenuItem value={PRIORITY.URGENT} primaryText="urgent" />
            </Field>
          </div>
        </div>
        <div>
          <RaisedButton
            style={styles.S.floatRight}
            type="submit"
            label="Post"
            primary
          />
        </div>
      </form>
    );
  }
}

export default form(values(TaskForm));
