import React from "react";
import Trays from "src/scenes/Trays/Trays";
import TrayTable from "./TrayTable";

/*
type Props = {
  pipeline: Pipeline,
  task: TaskResponse,
  patient: Patient,
};
*/

const ChooseManufacturer = ({ patient, pipelineID }) => {
  return (
    <>
      <Trays
        editingDisabled={true}
        buildsEnabled={true}
        patient={patient}
        pipelineID={pipelineID}
        tableComponent={TrayTable}
      />
    </>
  );
};

export default ChooseManufacturer;
