import { useState, useEffect } from "react";
import { LocalZone } from "luxon";
import time from "src/shared/time";

// useDateRange is a custom hook which stores a to and from time in local state,
// allowing us to view date ranges within the UI.
//
// If no timezone is provided, the local time zone will be used.  This ensures
// that a given "date" will always work correctly.  For example, requesting
// "2020-01-01" for the "America/Los_Angeles" timezone **when you're in EST**
// will return 2019-01-01 past 3pm.
//
// Therefore, it's always much safer to specify no timezone.
const useDateRange = ({ isoDate, selectedView, timezone = null }) => {
  const tz = timezone === null ? new LocalZone().name : timezone;

  const { startOfDay, endOfDay } = time(tz);
  const [from, setFrom] = useState(startOfDay(isoDate));
  const [to, setTo] = useState(endOfDay(isoDate));

  useEffect(() => {
    const { startOfWeek, startOfDay, endOfDay, addDays } = time(tz);

    switch (selectedView) {
      case "List":
      case "Week": {
        setFrom(startOfWeek(isoDate));
        setTo(addDays(startOfWeek(isoDate), 6));
        break;
      }
      default: {
        setFrom(startOfDay(isoDate));
        setTo(endOfDay(isoDate));
      }
    }
  }, [isoDate, selectedView, tz]);

  return { to, from };
};

export default useDateRange;
