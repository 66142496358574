import React, { useState, useEffect } from "react";
import { browserHistory } from "react-router";
import AddIcon from "src/shared/Icons/Add";
import Button from "src/shared/Button";
import Modal from "src/shared/Modal";
import NewTask from "../NewTask/NewTask";
import NewPipeline from "../NewPipeline/NewPipeline";
import ProfileIcon from "../Icons/Profile";
import SnoozedIcon from "../Icons/Snooze";
import ListIcon from "../Icons/List";
import PencilIcon from "../Icons/Pencil";
import DoneIcon from "../Icons/Done";
import { SidebarState } from "./types";
import { Wrapper, NewDropdown, Menu, SectionTitle } from "./styles";
import { useCounters } from "./hooks";
import Tab from "./Tab";

const Sidebar: React.FC = () => {
  const [showNew, setShowNew] = useState<SidebarState>(null);
  const counters = useCounters();

  useEffect(() => {
    // Hide the dropdown any time the router changes.  The dropdown
    // is hidden by default when clicking on a button, but we want to hide
    // if you click away also.
    return browserHistory.listen(() => {
      // Just in case the component has unmounted
      try {
        setShowNew(null);
      } catch (e) {}
    });
  }, []);

  return (
    <Wrapper>
      <div>
        <h1>Tasks</h1>
        <Button kind="primary" onClick={() => setShowNew("dropdown")}>
          <AddIcon /> Create new
        </Button>

        <NewDropdown className={showNew === "dropdown" ? "visible" : ""}>
          <Button onClick={() => setShowNew("task")}>New task</Button>
          <Button onClick={() => setShowNew("pipeline")}>Pipeline</Button>
        </NewDropdown>
      </div>
      <Menu>
        <SectionTitle>Tasks</SectionTitle>
        <Tab
          route="/tasks"
          title="My tasks"
          counter={counters.tasksUser}
          icon={<ProfileIcon />}
        />
        <Tab
          route="/tasks/all"
          title="All tasks"
          counter={counters.tasksAll}
          icon={<ListIcon />}
        />
        <Tab
          route="/tasks/authored"
          title="Tasks I've created"
          counter={counters.tasksCreatedByUser}
          icon={<PencilIcon />}
        />
        <Tab
          route="/tasks/snoozed"
          title="My snoozed tasks"
          counter={counters.tasksSnoozed}
          icon={<SnoozedIcon />}
        />
        <Tab
          route="/tasks/completed"
          title="My recently completed"
          counter={counters.tasksCompleted}
          icon={<DoneIcon />}
        />
      </Menu>
      <Menu>
        <SectionTitle>Pipelines</SectionTitle>
        <Tab
          route="/pipelines"
          title="My pipelines"
          counter={counters.pipelinesUser}
          icon={<ProfileIcon />}
        />
        <Tab
          route="/pipelines/all"
          title="All pipelines"
          counter={counters.pipelinesAll}
          icon={<ListIcon />}
        />
        <Tab
          route="/pipelines/completed"
          title="Recently completed"
          counter={counters.pipelinesCompleted}
          icon={<DoneIcon />}
        />
      </Menu>

      {showNew === "pipeline" && (
        <Modal onClose={() => setShowNew(null)}>
          <NewPipeline
            onCancel={() => setShowNew(null)}
            onComplete={() => setShowNew(null)}
          />
        </Modal>
      )}
      {showNew === "task" && (
        <Modal onClose={() => setShowNew(null)}>
          <NewTask
            onCancel={() => setShowNew(null)}
            onComplete={() => setShowNew(null)}
          />
        </Modal>
      )}
    </Wrapper>
  );
};

export default Sidebar;
