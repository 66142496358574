export const query = `
query UserBuilds($userID: ID!) {
  builds: manufacturingBuilds(userId: $userID) {
    id
    producerID
    createdAt
    trays {
      id
      archType
      description
      serialNumber
      isTemplate

      wearTimelineStage {
        id
        stage
        isWearable
        archType
        kitID
      }

      defects {
        resolvedBy
        resolutionNotes
        issue {
          id category subcategory name description
        }
      }
    }
  }
}
`;
