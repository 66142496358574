import styled from "react-emotion";
import color from "src/styles/color";

export const Perimeter = styled.div`
  width: 226px;
  padding-top: 28px;
  margin: 0 0 12px 6px;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const TxStatus = styled.div`
  text-align: center;
`;

export const StatusText = styled.span`
  font-size: 12px;
`;

export const StatusDate = styled.span`
  color: ${color.primary};
  font-size: 12px;
  font-weight: bold;
`;

export const OnHoldStatus = styled.div`
  font-size: 12px;
  margin-top: 6px;
  border-radius: 2px;
  font-weight: bold;
  line-height: 10px;
  padding: 8px;
  text-align: center;
  background: ${color.red};
  color: ${color.white};
`;
