import { useState } from "react";
import { browserHistory } from "react-router";

export const useQueryState = (
  name: string,
  blank: string
): [string, (string) => void] => {
  const s = new URLSearchParams(window.location.search);
  // Get the current value from query parameters, defaulting to "blank" when empty.
  const current = s.get(name) || blank;
  const [val, setter] = useState(current);

  // If the current URL value doesn't match our local state, update our local state.  This ensures
  // that back buttons work as expected.
  if (current !== val) {
    setter(current);
  }

  // Updating state locally should trigger a browser URL update.  This should happen _before_
  // triggering a state update internally to a react component.
  const update = (to: string) => {
    s.set(name, to);
    browserHistory.push({
      pathname: window.location.pathname,
      search: `?${s.toString()}`,
    });
    // Update our react state.
    setter(to);
  };

  return [current, update];
};
