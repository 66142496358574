import React, { useState } from "react";
import { Avatar, Menu } from "antd";
import { capitalize, isEmpty } from "lodash";
// shared
import Modal from "src/shared/Modal";
import Popconfirm from "src/shared/Popconfirm";
import { PatientConfirm } from "src/shared/PatientConfirm/PatientConfirm";
import TreatmentEdit from "src/scenes/PatientProfile/TreatmentEdit";
import NewHold from "src/scenes/Tasks/NewHold/NewHold";
// Icons
import Birthday from "src/shared/Icons/Birthday";
import Phone from "src/shared/Icons/Phone";
import Email from "src/shared/Icons/Email";
import Address from "src/shared/Icons/Address";
import Skeleton from "src/shared/Skeleton";
import Settings from "src/shared/Icons/Settings";
// local
import parsePhoneNumber from "src/utils/parsePhoneNumber";
import { getAddress } from "src/utils/address";
import HeaderConsents from "src/scenes/PatientProfile/Consents/HeaderConsents";
import PatientNotesIcon from "src/shared/Icons/PatientNotes";
import ImpressTMIcon from "src/shared/Icons/ImpressTM";
import { useSendPasswordReset } from "src/state/hooks/patients";
import PatientEdit from "src/scenes/PatientProfile/PatientEdit";
import PatientStatusEdit from "src/scenes/PatientProfile/PatientStatusEdit";
import ShippingAddressEdit from "src/scenes/PatientProfile/ShippingAddressEdit";
import { useDeletePatient } from "src/state/usePatientInfo";
import MarkAsSpam from "src/scenes/PatientProfile/MarkAsSpam";
import CompleteHoldConfirmation from "./CompleteHoldConfirmation";
import HeaderButton from "./HeaderButton";
import SocialNotes from "./SocialNotes";
import { toFullDateYear, getCurrentAge } from "./util";
import { isPatientOnImpressTM } from "src/scenes/TrayLogs/util";

import {
  StyleMenu,
  Perimeter,
  WhiteBackground,
  Row,
  Photo,
  Top,
  PatientNameAndStatus,
  PatientName,
  PatientPronoun,
  PatientStatus,
  RightButtons,
  Bottom,
  Col,
} from "./styles";

const PatientProfileHeader = props => {
  const {
    patientAddress,
    clinicalRecord,
    patient,
    treatment,
    patientStatuses,
    refetch,
  } = props;

  const deletePatient = useDeletePatient();

  // XXX: refactor these into a single useState with enum
  const [markAsSpam, setMarkAsSpam] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [editPatient, setEditPatient] = useState(false);
  const [editPatientStatus, setEditPatientStatus] = useState(false);
  const [editShippingAddress, setEditShippingAddress] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const sendPasswordReset = useSendPasswordReset();
  const [treatmentEditVisible, setTreatmentEditVisible] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [addHoldVisible, setAddHoldVisible] = useState(false);
  const [completeHoldConfirmation, setCompleteHoldConfirmation] = useState(
    false
  );

  const holds = (treatment ? treatment.holds : []).filter(h => !h.completedAt);

  const OptionsMenu = (
    <StyleMenu>
      <Menu.Item onClick={() => setEditPatient(true)}>
        Edit Patient Details
      </Menu.Item>
      <Menu.Item onClick={() => setEditPatientStatus(true)}>
        Edit Patient Status
      </Menu.Item>
      <Menu.Item onClick={() => setEditShippingAddress(true)}>
        Edit Shipping Address
      </Menu.Item>
      <Menu.Item onClick={() => setTreatmentEditVisible(true)}>
        Edit Treatment
      </Menu.Item>
      <Menu.Item onClick={() => setAddHoldVisible(true)}>
        Add Pre-tx task
      </Menu.Item>
      <Menu.Item
        disabled={holds.length === 0}
        onClick={() => setCompleteHoldConfirmation(true)}
      >
        Complete Pre-tx task
      </Menu.Item>
      <Menu.Item onClick={() => setPasswordReset(true)}>
        Send password reset
      </Menu.Item>
      <Menu.Item onClick={() => setMarkAsSpam(true)}>Mark as spam</Menu.Item>
      <Menu.Item onClick={() => setDeleteUser(true)}>Delete patient</Menu.Item>
    </StyleMenu>
  );

  if (!patient || isEmpty(patientStatuses)) {
    return (
      <Perimeter>
        <Skeleton.Circle
          width="132px"
          margin="0 0 0 32px"
          style={{ position: "absolute", zIndex: 2 }}
        />
        <WhiteBackground />
        <Top>
          <Skeleton width="286px" height="24px" dark />
          <Row>
            <Skeleton.Circle />
            <Skeleton.Circle margin="0 24px 0" />
            <Skeleton.Circle margin="0 32px 0 0" />
          </Row>
        </Top>
        <Bottom>
          <Skeleton width="160px" height="12px" margin="12px 0 0" />
        </Bottom>
      </Perimeter>
    );
  }

  const {
    firstName,
    lastName,
    preferredName,
    pronouns,
    dateOfBirth,
    mobilePhoneNumber,
  } = patient;

  return (
    <Perimeter>
      <PatientEdit
        patient={patient}
        visible={editPatient}
        setVisible={setEditPatient}
      />
      {editPatientStatus && (
        <PatientStatusEdit
          patient={patient}
          patientStatuses={patientStatuses}
          onClose={() => setEditPatientStatus(false)}
        />
      )}
      <MarkAsSpam
        patient={patient}
        visible={markAsSpam}
        setVisible={setMarkAsSpam}
      />
      {completeHoldConfirmation && (
        <CompleteHoldConfirmation
          holds={holds}
          userID={patient.id}
          onClose={() => {
            setCompleteHoldConfirmation(false);
          }}
        />
      )}
      {addHoldVisible && (
        <Modal onClose={() => setAddHoldVisible(false)}>
          <NewHold
            user={patient}
            onCancel={() => setAddHoldVisible(false)}
            onComplete={() => {
              // XXX: here we re-fetch the treatment to ensure holds are captured if it's the
              // first hold for a patient.  To fix, we need to update to urql's denormalized caching.
              refetch();
              setAddHoldVisible(false);
            }}
          />
        </Modal>
      )}
      {treatment && (
        <TreatmentEdit
          treatment={treatment}
          visible={treatmentEditVisible}
          setVisible={setTreatmentEditVisible}
        />
      )}
      <ShippingAddressEdit
        patientId={patient.id}
        patientAddress={patientAddress}
        visible={editShippingAddress}
        setVisible={setEditShippingAddress}
      />
      <PatientConfirm
        patient={patient}
        visible={deleteUser}
        setVisible={setDeleteUser}
        content="Type patient's full name to delete"
        onConfirm={() => deletePatient({ id: patient.id })}
      />
      {clinicalRecord && (
        <SocialNotes
          isOpen={openNotes}
          clinicalRecord={clinicalRecord}
          onHide={() => {
            setOpenNotes(false);
          }}
        />
      )}
      <Photo>
        <Avatar icon="user" src={patient.avatarURL} size={124} />
      </Photo>
      <Top>
        <PatientNameAndStatus>
          <PatientName>
            {firstName} {preferredName ? `(${preferredName})` : null} {lastName}
          </PatientName>

          {pronouns ? <PatientPronoun>({pronouns})</PatientPronoun> : null}

          <PatientStatus>
            {patient.statuses.length > 0 &&
              capitalize(patient.statuses[0].name.replaceAll("_", " "))}
          </PatientStatus>
        </PatientNameAndStatus>
        <RightButtons>
          {isPatientOnImpressTM(treatment) ? (
            <HeaderButton
              dropdownMenu={null}
              tooltip="Impress Treatment Monitoring"
              onClick={e => {
                e.preventDefault();
                // if prod
                if (
                  window.location
                    .toString()
                    .includes("https://doctor.uniformteeth.com")
                ) {
                  // else staging
                  window.open(
                    `https://backoffice.smile2impress.com/patient-care/patient/${
                      patient.impressID
                    }`,
                    "_blank"
                  );
                } else {
                  // else staging
                  window.open(
                    `https://backoffice-prelive.smile2impress.com/patient-care/patient/${
                      patient.impressID
                    }`,
                    "_blank"
                  );
                }
              }}
              theme={{
                showBadge: false,
              }}
            >
              <ImpressTMIcon />
            </HeaderButton>
          ) : null}

          <HeaderButton
            dropdownMenu={null}
            tooltip="Social notes"
            onClick={() => setOpenNotes(true)}
            theme={{
              showBadge: false,
            }}
          >
            <PatientNotesIcon />
          </HeaderButton>

          <HeaderConsents patient={patient} clinicalRecord={clinicalRecord} />

          <HeaderButton
            dropdownMenu={OptionsMenu}
            tooltip="Settings"
            theme={{
              showBadge: false,
            }}
          >
            <Settings />
          </HeaderButton>
          <Popconfirm
            placement="topLeft"
            visible={passwordReset}
            setVisible={setPasswordReset}
            title="Send reset password link?"
            onConfirm={() => {
              setPasswordReset(false);
              sendPasswordReset(patient.email);
            }}
            onCancel={() => setPasswordReset(false)}
          />
        </RightButtons>
      </Top>
      <Bottom>
        <Col>
          <Birthday />
          {dateOfBirth && (
            <span>
              {toFullDateYear(dateOfBirth)} (age {getCurrentAge(dateOfBirth)})
            </span>
          )}
          {!dateOfBirth && " -- "}
        </Col>
        <Col>
          <Phone /> {parsePhoneNumber(mobilePhoneNumber)}
        </Col>
        <Col>
          <Email /> {patient.email}
        </Col>
        <Col>
          <Address /> {getAddress(patientAddress)}
        </Col>
      </Bottom>
    </Perimeter>
  );
};

export default PatientProfileHeader;
