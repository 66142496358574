import React from "react";
import styled from "react-emotion";
// shared
import LegacyModal from "src/shared/LegacyModal";
import Button from "src/shared/Button";
// styles
import textStyles from "src/styles/textStyles";
import color from "src/styles/color";
// local
import ClaimFormSvg from "./ClaimFormSvg";

const Title = styled.div`
  ${textStyles("xlarge")};
  font-weight: bold;
`;

const Perimeter = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LargeText = styled.div`
  ${textStyles("xxlarge")};
  font-weight: bold;
  margin: 32px 0 16px;
`;

const LightText = styled.div`
  ${textStyles("large")};
  color: ${color.gray3};
  margin-bottom: 40px;
  max-width: 270px;
`;

const InsuranceClaimFormModal = props => {
  return (
    <LegacyModal isOpen={true} closeModal={props.handleClose}>
      <LegacyModal.Title>
        <Title>Insurance Claim Form</Title>
      </LegacyModal.Title>
      <LegacyModal.Content>
        <Perimeter>
          <ClaimFormSvg />
          <LargeText>Did the patient sign the claim form</LargeText>
          <LightText>
            Please make sure the claim form is signed. Once signed, select
            "Done" to continue
          </LightText>
          <Button
            kind="primary"
            onClick={props.handleDone}
            style={{ width: "100px", height: "36px" }}
          >
            Done
          </Button>
        </Perimeter>
      </LegacyModal.Content>
    </LegacyModal>
  );
};

export default InsuranceClaimFormModal;
