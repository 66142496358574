import React from "react";
import { useQuery } from "src/utils/http/gqlQuery";
import { fromPairs, isEmpty, pick } from "lodash";
import { VerifiedSpecialist } from "src/types/gql";
import { notification } from "antd";
import {
  topLevelVSFields,
  useUpdateVerifiedSpecialist,
  useCreateVerifiedSpecialist,
  addressFields,
} from "src/scenes/EMRAdmin/useEMRAdminInfo";
import { getVerifiedSpecialists } from "src/scenes/EMRAdmin/queries";
import { DEFAULT_COUNTRY } from "src/utils/address";
import EditIcon from "src/scenes/Patient/BillingV2/Orders/OrderSettings/icons/edit.svg";
import {
  DetailsContainer,
  DetailsTitle,
  NameContainer,
  EditContainer,
  DetailsSubtitle,
  DetailsRow,
  icon,
} from "../styles";
import { getReadableType } from "src/utils/externalProvider";
import EditMode from "./EditMode";
import { Mode } from "src/types/local";

const emptySpecialist = {
  firstName: "",
  lastName: "",
  type: "general_dentist",
  email: "",
  phone: "",
  phone2: "",
  clinicName: "",
  preferredProvider: false,
  address: {
    line1: "",
    line2: null,
    city: "",
    state: null,
    postalCode: "",
    country: DEFAULT_COUNTRY.code,
  },
};

type SpecialistDetailsProps = {
  specialist: VerifiedSpecialist;
  mode: Mode;
  setMode: (Mode) => void;
  search: string;
  setSelectedSpecialistID: (string) => void;
};

const getChanges = (old, updated, fields) => {
  const changedFields = fields.filter(field => old[field] !== updated[field]);
  if (isEmpty(changedFields)) return null;

  return fromPairs(changedFields.map(field => [field, updated[field]]));
};

const getChangedTopLevelFields = (specialist, newSpecialist) =>
  getChanges(specialist, newSpecialist, topLevelVSFields);
const getChangedAddressFields = (address, newAddress) =>
  getChanges(address, newAddress, addressFields);

const SpecialistDetails: React.FC<SpecialistDetailsProps> = ({
  specialist,
  mode,
  setMode,
  search,
  setSelectedSpecialistID,
}) => {
  const updateSpecialist = useUpdateVerifiedSpecialist();
  const createSpecialist = useCreateVerifiedSpecialist();

  const [, refetch] = useQuery({
    query: getVerifiedSpecialists,
    variables: { filter: { search } },
    pause: true,
  });

  const onCreate = async newSpecialist => {
    const topLevelFields = pick(newSpecialist, topLevelVSFields);
    const createAddress = newSpecialist.address;

    const input = {
      ...topLevelFields,
      createAddress,
    };

    const resp = await createSpecialist({ input });
    if (resp.error) {
      notification.error({
        message: resp.error.message,
      });
      return;
    }

    setSelectedSpecialistID(resp.data.createVerifiedSpecialist.id);

    notification.success({ message: "Verified provider added!" });
    refetch();
    setMode("view");
  };

  const onUpdate = async newSpecialist => {
    const changedFields = getChangedTopLevelFields(specialist, newSpecialist);
    const changedAddress = getChangedAddressFields(
      specialist.address,
      newSpecialist.address
    );

    if (!changedFields && !changedAddress) {
      setMode("view");
      return;
    }

    const input = {
      id: specialist.id,
      ...changedFields,
      ...(changedAddress
        ? {
            updateAddress: { id: specialist.address.id, ...changedAddress },
          }
        : {}),
    };

    const resp = await updateSpecialist({
      input,
    });

    if (resp.error) {
      notification.error({
        message: resp.error.message,
      });
      return;
    }

    notification.success({ message: "Verified provider updated!" });
    refetch();
    setMode("view");
  };

  if (mode === "edit") {
    return <EditMode onSubmit={onUpdate} specialist={specialist} />;
  }

  if (mode === "add") {
    return <EditMode onSubmit={onCreate} specialist={emptySpecialist} />;
  }

  const {
    firstName,
    lastName,
    type,
    email,
    phone,
    phone2,
    clinicName,
    address,
  } = specialist;

  return (
    <DetailsContainer>
      <DetailsTitle>
        <NameContainer>
          {firstName} {lastName}
        </NameContainer>
        <EditContainer>
          <button onClick={() => setMode("edit")}>
            <img className={icon} src={EditIcon} alt="Edit" />
          </button>
        </EditContainer>
      </DetailsTitle>
      <DetailsSubtitle>{getReadableType(type)}</DetailsSubtitle>
      <hr />
      <DetailsRow>
        <div>Email</div>
        <div>{email}</div>
      </DetailsRow>
      <hr />
      <DetailsRow>
        <div>Phone</div>
        <div>{phone}</div>
      </DetailsRow>
      <hr />
      <DetailsRow>
        <div>Phone&nbsp;2</div>
        <div>{phone2}</div>
      </DetailsRow>
      <hr />
      <DetailsRow>
        <div>Clinic</div>
        <div>{clinicName}</div>
      </DetailsRow>
      <hr />
      <DetailsRow style={{ lineHeight: "25px" }}>
        <div>Address</div>
        <div>
          {address.line1}
          <br />
          {address.line2 && (
            <>
              {address.line2}
              <br />
            </>
          )}
          {address.city}, {address.state} {address.postalCode}
        </div>
      </DetailsRow>
    </DetailsContainer>
  );
};

export default SpecialistDetails;
