import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";
import {
  loadKitShippingRates,
  purchaseShippingRate,
  // selectors
  getShippingRateError,
  getShippingIsLoading,
  getShippingRates,
  getCheapestShippingRate,
  getFastestShippingRate,
  getIsPurchaseRateLoading,
  getPurchaseRateError,
} from "src/redux/modules/kit";
import Error from "src/components/error/error";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import Address from "./address";
import styles from "./styles";

const mapActions = dispatch => ({
  actions: bindActionCreators(
    {
      loadKitShippingRates,
      purchaseShippingRate,
    },
    dispatch
  ),
});

const mapState = createStructuredSelector({
  // rate options
  isRatesLoading: getShippingIsLoading,
  loadError: getShippingRateError,
  rates: getShippingRates,
  cheapest: getCheapestShippingRate,
  fastest: getFastestShippingRate,
  // purchase options
  isPurchaseLoading: getIsPurchaseRateLoading,
  purchaseError: getPurchaseRateError,
});

const enhance = connect(
  mapState,
  mapActions
);

class ShippingForm extends PureComponent {
  static propTypes = {
    // from parent
    patientId: PropTypes.string,
    kitId: PropTypes.string,
    task: PropTypes.object,
    // from @connect
    isRatesLoading: PropTypes.bool,
    loadError: PropTypes.string,
    rates: PropTypes.array,
    cheapest: PropTypes.object,
    fastest: PropTypes.object,
    isPurchaseLoading: PropTypes.bool,
    purchaseError: PropTypes.string,
  };

  state = {
    showAddressForm: false,
    address: {},
  };

  componentWillMount() {
    const { patientId, kitId } = this.props;
    this.props.actions.loadKitShippingRates({ patientId, kitId });
  }

  onShowAddressForm = () => {
    this.setState({
      showAddressForm: true,
    });
  };

  onSubmitAddress = address => {
    const { patientId, kitId } = this.props;
    this.setState({ address, showAddressForm: false });
    this.props.actions.loadKitShippingRates({ patientId, kitId, to: address });
  };

  onPurchaseShipping = ({ shipmentId, rateId, provider }) => () => {
    const { patientId, kitId, task } = this.props;

    this.props.actions
      .purchaseShippingRate({
        patientId,
        kitId,
        shipmentId,
        rateId,
        task,
        provider,
      })
      .then(resp => {
        if (this.props.closeForm) {
          return this.props.closeForm(resp);
        }
      });
  };

  render() {
    const {
      loadError,
      isRatesLoading,
      rates,
      cheapest,
      fastest,
      isPurchaseLoading,
      purchaseError,
    } = this.props;

    // If we couldn't load shipping rates show an error and allow the doctor
    // to add a custom address to find shipping.
    // Same with purchase errors.
    if (loadError || purchaseError) {
      return (
        <div>
          <Error error={loadError || purchaseError} />
          <Address
            onSubmit={this.onSubmitAddress}
            onHandDelivery={this.onPurchaseShipping({
              shipmentId: "",
              rateId: "N/A",
              provider: "custom",
            })}
          />
        </div>
      );
    }

    if (this.state.showAddressForm) {
      return (
        <Address
          onSubmit={this.onSubmitAddress}
          onHandDelivery={this.onPurchaseShipping({
            shipmentId: "",
            rateId: "N/A",
            provider: "custom",
          })}
        />
      );
    }

    if (isRatesLoading) {
      return <p style={styles.center}>Loading</p>;
    }

    if (isPurchaseLoading) {
      return <p style={styles.center}>Purchasing...</p>;
    }

    return (
      <div>
        <h2 style={styles.center}>Purchase shipping</h2>

        <div style={styles.wrapper}>
          <FlatButton
            label="Use alternate address"
            style={{ marginRight: "1rem" }}
            onClick={this.onShowAddressForm}
          />
          <RaisedButton
            label="Use Cheapest USPS"
            style={{ marginRight: "1rem" }}
            onClick={this.onPurchaseShipping({
              shipmentId: cheapest.shipment_id,
              rateId: cheapest.rate_id,
              provider: cheapest.provider,
            })}
          />
          <RaisedButton
            label="Use Fastest USPS"
            onClick={this.onPurchaseShipping({
              shipmentId: fastest.shipment_id,
              rateId: fastest.rate_id,
              provider: fastest.provider,
            })}
          />
        </div>

        <Table selectable={false}>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableHeaderColumn>Carrier</TableHeaderColumn>
              <TableHeaderColumn>Delivery type</TableHeaderColumn>
              <TableHeaderColumn>Cost</TableHeaderColumn>
              <TableHeaderColumn>Days</TableHeaderColumn>
              <TableHeaderColumn style={styles.right}>
                Purchase
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            {rates.map(rate => (
              <TableRow key={rate.delivery_type}>
                <TableRowColumn>{rate.carrier}</TableRowColumn>
                <TableRowColumn>{rate.delivery_type}</TableRowColumn>
                <TableRowColumn>{rate.cost}</TableRowColumn>
                <TableRowColumn>{rate.delivery_days}</TableRowColumn>
                <TableRowColumn style={styles.right}>
                  <RaisedButton
                    label={rate.cost === 0 ? "Use" : "Purchase"}
                    onClick={this.onPurchaseShipping({
                      shipmentId: rate.shipment_id,
                      rateId: rate.rate_id,
                      provider: rate.provider,
                    })}
                    primary
                  />
                </TableRowColumn>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default enhance(ShippingForm);
