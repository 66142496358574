// @flow
import React, { useState, useEffect, useContext } from "react";
import debounce from "lodash/debounce";
import { useStore, useActions } from "easy-peasy";
import { compose, withProps } from "recompose";
import { css } from "react-emotion";
import {
  Button,
  Icon,
  Popconfirm,
  Popover,
  Table,
  InputNumber,
  DatePicker,
  Tag,
} from "antd";
import Moment from "moment";
// For use when creating builds
import { PipelineContext, getPipeline } from "src/state/usePipelines";
import { TreatmentCyclesEnhancer } from "src/state/TreatmentCycles";
import { BuildsEnhancer } from "src/state/Builds";
import { WithEnhancers } from "src/shared/hocs";
import useSelf from "src/state/self";
/*
import type {
  Patient,
  Pipeline,
  TrayResponse,
  TreatmentCycleResponse,
  ProducerIdentifier,
} from "src/types/api";

type PassedProps = {
  tc: TreatmentCycleResponse,
  patient: Patient,

  pipeline?: Pipeline,

  actions?: boolean,
  editingDisabled?: boolean,
  buildsEnabled?: boolean,
};

// This is a locally used Tray type generated by withProps modifier
type ModifiedTray = TrayResponse & {
  cadence: number,
};

type ModifierProps = {
  trays: Array<ModifiedTray>,
};

type Props = PassedProps & ModifierProps & TCProps & BuildProps;
*/

const modifiers = compose(
  ...WithEnhancers(TreatmentCyclesEnhancer, BuildsEnhancer),
  // Map all props needed for displaying the table.
  withProps(props => {
    // This returns an array of trays to render as a single table row.
    const trays = props.tc.wear_schedule.map(t => {
      const start = new Moment(t.expected_start_date);
      const end = new Moment(t.expected_end_date);
      return {
        ...t,
        key: t.id,
        expected_start_date: start.format("YYYY-MM-DD"),
        expected_end_date: end.format("YYYY-MM-DD"),
        cadence: end.diff(start, "day"),
      };
    });

    return {
      trays,
    };
  })
);

const columns = props => {
  return [
    {
      title: "Stage",
      dataIndex: "stage",
      width: 80,
    },
    {
      title: "Arch",
      dataIndex: "arch_type",
      width: 80,
    },
    {
      title: "Template",
      dataIndex: "is_template",
      width: 80,
      render: (template, t) => {
        return template ? <Icon type="check" /> : <div />;
      },
    },
    {
      title: "Start",
      dataIndex: "expected_start_date",
      render: (text, t) => {
        return (
          <DatePicker
            value={new Moment(text)}
            disabled={props.editingDisabled}
            suffixIcon={null}
            allowClear={false}
            style={{ border: 0 }}
            onChange={date => {
              props.actions.updateTray({
                ...t,
                expected_start_date: date.format("YYYY-MM-DD"),
              });
            }}
          />
        );
      },
    },
    {
      title: "End",
      dataIndex: "expected_end_date",
      render: (text, t) => {
        return (
          <DatePicker
            value={new Moment(text)}
            disabled={props.editingDisabled}
            suffixIcon={null}
            allowClear={false}
            style={{ border: 0 }}
            onChange={date => {
              props.actions.updateTray({
                ...t,
                expected_end_date: date.format("YYYY-MM-DD"),
              });
            }}
          />
        );
      },
    },
    {
      title: "Cadence",
      dataIndex: "cadence",
      render: (text, t) => {
        return (
          <InputNumber
            disabled={props.editingDisabled}
            onChange={debounce(cadence => {
              props.actions.updateTrayCadence(t, cadence);
            }, 500)}
            value={text}
          />
        );
      },
    },
    {
      title: "Phase",
      dataIndex: "phase",
      width: 160,
    },
    {
      title: "Wearable?",
      dataIndex: "is_wearable",
      width: 80,
      render: (wearable, t) => {
        return wearable ? (
          "Yes"
        ) : (
          <Tag color="red">
            <b>No</b>
          </Tag>
        );
      },
    },
    {
      title: "Deleted?",
      dataIndex: "deleted_at",
      width: 80,
      render: (deleted_at, t) => {
        return deleted_at ? (
          <Tag color="red">
            <b>Yes</b>
          </Tag>
        ) : (
          "No"
        );
      },
    },
  ];
};

const TrayTable = props => {
  const [selected, setSelected] = useState([]);
  const [selectManufacturerVisible, setSelectManufacturerVisible] = useState(
    false
  );

  // If we make a build, we want to use the pipeline context to complete the
  // "create build" task for the current pipeline.
  const { setPipelines, pipelines } = useContext(PipelineContext) || {};

  // Load producers for manufacturer selection
  const producers = useStore(state => state.producerIdentifiers);
  const fetch = useActions(actions => actions.producerIdentifiers.fetch);
  useEffect(() => {
    fetch();
  }, [fetch]);

  const self = useSelf();

  if (props.trays.length === 0) {
    return null;
  }

  const now = new Moment().format("YYYY-MM-DD");

  const betaManV2Request = async (props, p, selected) => {
    const result = await props.actions.createBuildV2({
      build: {
        producer_identifier_id: p.id,
        user_id: props.pipeline.user_id,
        pipeline_id: props.pipeline.id,
      },
      upper_tray_ids: selected,
      lower_tray_ids: selected,
    });
    if (result.id && props.pipeline) {
      // Update the pipeline because this modifies future tasks.
      getPipeline(props.pipeline.id, pipelines, setPipelines);
    }
  };

  return (
    <>
      <div
        className={css`
          display: ${props.actions !== false ? "flex" : "none"};
          justify-content: flex-end;
          margin: 1rem 0;
          padding: 0 18px;
        `}
      >
        {!!props.buildsEnabled && props.pipeline && (
          <Popover
            title="Choose a manufacturer"
            trigger="click"
            visible={selectManufacturerVisible && selected.length > 0}
            onVisibleChange={visible => setSelectManufacturerVisible(visible)}
            content={
              <>
                {producers.data.map(p => {
                  return (
                    <Popconfirm
                      title={`Produce via ${p.source}?`}
                      icon={<Icon type="question-circle-o" />}
                      onConfirm={async () => {
                        if (
                          self.id === "fefacd2c-9618-4041-b16f-bd0ceaeafe4b"
                        ) {
                          betaManV2Request(props, p, selected);
                          return;
                        }

                        const result = await props.actions.createBuild({
                          build: {
                            producer_identifier_id: p.id,
                            user_id: props.pipeline.user_id,
                            pipeline_id: props.pipeline.id,
                          },
                          upper_tray_ids: selected,
                          lower_tray_ids: selected,
                        });
                        if (result.id && props.pipeline) {
                          // Update the pipeline because this modifies future tasks.
                          getPipeline(
                            props.pipeline.id,
                            pipelines,
                            setPipelines
                          );
                        }
                      }}
                    >
                      <p style={{ cursor: "pointer" }}>{p.source}</p>
                    </Popconfirm>
                  );
                })}
              </>
            }
          >
            <Button
              disabled={selected.length === 0}
              style={{ marginRight: "1rem" }}
            >
              Manufacture selected trays
            </Button>
          </Popover>
        )}
        <Button
          disabled={props.editingDisabled || selected.length === 0}
          className={css`
            margin-right: 1rem;
          `}
          onClick={() => {
            const trays = props.trays
              .filter(t => selected.indexOf(t.id) > -1)
              .map(t => ({
                ...t,
                deleted_at: t.deleted_at ? null : new Date().toISOString(),
              }));
            props.actions.updateTrays(trays);
          }}
        >
          Toggle deleted
        </Button>
        <Button
          onClick={() => {
            const trays = props.trays
              .filter(t => selected.indexOf(t.id) > -1)
              .map(t => ({
                ...t,
                is_wearable: !t.is_wearable,
              }));
            props.actions.updateTrays(trays);
          }}
          disabled={props.editingDisabled || selected.length === 0}
        >
          Toggle wearable
        </Button>
      </div>
      <Table
        rowClassName={t => {
          return t.expected_start_date <= now && t.expected_end_date >= now
            ? current
            : "";
        }}
        pagination={false}
        size="small"
        rowSelection={{
          selectedRowKeys: selected,
          onChange: setSelected,
        }}
        columns={columns(props)}
        dataSource={props.trays}
      />
    </>
  );
};

export default modifiers(TrayTable);

const current = css`
  background: #ffeb0033;
`;
