import React from "react";
import styled from "react-emotion";

import color from "src/styles/color";
import textStyles from "src/styles/textStyles";
import Doctor from "src/shared/Icons/Doctor";
import Location from "src/shared/Icons/Location";
import Time from "src/shared/Icons/Time";
import Button from "src/shared/Button";
import CheckInAndOutModal from "src/scenes/SchedulingV2/CalendarView/CheckInAndOutModal";
import { AppointmentWithOffset } from "./types";
import { StyledLink } from "./Styled";
import {
  formatApptValuesForDisplay,
  useAppointmentDetailPosition,
} from "./util";
import { getAppointmentName } from "src/shared/util";

const Perimeter = styled.div`
  position: absolute;
  width: 240px;
  z-index: 10;
  background: ${color.white};
  border-radius: 2px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  ${textStyles("medium")};
`;

const Header = styled.div`
  margin-bottom: 16px;
`;

const Content = styled.div`
  padding: 8px;
`;

const UserName = styled.div`
  font-weight: bold;
`;

const LightText = styled.div`
  display: flex;
  align-items: center;
  color: ${color.gray3};
  margin-bottom: 8px;
`;

const Space = styled.span`
  margin-left: 8px;
  color: ${color.gray3};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.05);
  border-top: 1px solid ${color.border};
  padding: 8px;
`;

type Props = {
  appt: AppointmentWithOffset;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
  height: string;
  left: string;
  openDrawer: () => void;
  toHour: (isoDate?: string) => string;
  top: string;
  width: string;
};

const AppointmentDetail = ({
  appt,
  handleMouseEnter,
  handleMouseLeave,
  height,
  left,
  openDrawer,
  toHour,
  top,
  width,
}: Props) => {
  const position = useAppointmentDetailPosition({ left, height, top, width });
  const displayValues = formatApptValuesForDisplay(appt, toHour);

  const userName = displayValues.ptName ? (
    <StyledLink to={`/patients/${appt.userId}`}>
      {displayValues.ptName}
    </StyledLink>
  ) : (
    "Unbooked"
  );

  const assistingStaff =
    appt.staff &&
    appt.staff.map(assistant => (
      <LightText css="margin-left: 18px">{assistant.name}</LightText>
    ));

  return (
    <Perimeter
      css={{
        left: position.left,
        top: position.top,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      innerRef={position.ref}
    >
      <Content>
        <Header>
          <UserName>{userName}</UserName>
          <LightText>{getAppointmentName(displayValues.apptName)}</LightText>
        </Header>
        <LightText>
          <Time />
          <Space>{displayValues.apptTime}</Space>
        </LightText>
        <LightText css="margin-bottom: 8px">
          <Location />
          <Space>{displayValues.location}</Space>
        </LightText>
        <LightText css="margin-bottom: 8px; font-weight: bold">
          <Doctor />
          <Space
            css={`
              color: ${color.font};
            `}
          >
            {displayValues.drName}
          </Space>
        </LightText>
        <LightText css="margin-left: 18px">{displayValues.drTime}</LightText>
        {assistingStaff}
      </Content>
      <Footer>
        <Button onClick={openDrawer} type="secondaryInvert">
          Edit
        </Button>
        {appt.user && <CheckInAndOutModal appointment={appt} />}
      </Footer>
    </Perimeter>
  );
};

export default AppointmentDetail;
