import styled from "react-emotion";
import color from "src/styles/color";

const maxSize = 28;
const eventLabelWidth = 100;
export const eventLabelTopOffset = 86;

export const Perimeter = styled.div`
  padding-right: 32px;
  width: 100%;
  margin-bottom: 12px;
  margin-top: 38px;
`;

export const Row = styled.div`
  height: ${maxSize}px;
  padding: 0 ${({ theme: { size } }) => size / 2}px;
`;

export const InnerRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const EventLabel = styled.div`
  width: ${eventLabelWidth}px;
  position: absolute;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  background: ${color.white};

  &:hover {
    z-index: 9;

    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;

export const Label = styled.div`
  color: #59b8b8;
  font-size: 12px;
  font-weight: bold;
`;

export const Date = styled.div`
  font-size: 12px;
  color: ${color.gray3};
`;

export const Line = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${color.border};
`;

export const ProgressBar = styled.div`
  height: 4px;
  background-color: #59b8b8;
  position: absolute;
`;
