import React from "react";
import color from "src/styles/color";
import { Perimeter, Circle } from "./styles";

const Skeleton = props => {
  const {
    width = "64px",
    height = "12px",
    dark,
    margin = "0",
    ...rest
  } = props;
  const textColor = dark ? color.border : color.gray2;

  // @ky - 12/31/2019
  // TODO: come up with a more elegant way of handling full width
  return (
    <Perimeter theme={{ width, height, color: textColor, margin }} {...rest} />
  );
};

Skeleton.Circle = props => {
  const { width = "16px", dark, margin, ...rest } = props;
  const color = dark ? "#C9C9C9" : "#E3E3E3";
  return <Circle theme={{ width, color, margin }} {...rest} />;
};

export default Skeleton;
