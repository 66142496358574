import { map, includes } from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import RaisedButton from "material-ui/RaisedButton";
import { connect } from "react-redux";
import {
  createPatientManufacturingTask,
  createPatientWearSchedule,
} from "src/redux/modules/treatment-cycle";

import {
  setPatientOfficeSyncFolder,
  createAnotherPatientRecordFolder,
} from "src/redux/modules/patient";
import { load as loadClinicalRecord } from "src/redux/modules/clinical-record";
import { put as putTask } from "src/redux/modules/task-list";

import { FORM_NAME } from "src/config/treatment-forms/index";
import Gallery from "../../components/gallery/gallery";
import AdverseHistory from "../../components/adverse-history/adverse-history";
import Checkin from "src/containers/treatment/components/checkin/checkin";

import styles from "./form-style";
import { routerShape } from "react-router/lib/PropTypes";
import {
  fetchCheckinsForPatient,
  getCheckinsForPatientSelector,
} from "src/redux/modules/checkin";
import { DropDownMenu, MenuItem } from "material-ui";
import { OFFICE_SYNC_FOLDERS } from "src/config/constant";
import {
  getSelfieSelector,
  loadFiles,
} from "../../../../redux/modules/fileList";
import OutcomeScore from "../../../../components/outcome-score/outcome-score";
import { request } from "src/shared/util";

const SHOW_OFFICE_SYNC_SIDE_BAR = [
  FORM_NAME.PIV,
  FORM_NAME.PIVRECORD,
  FORM_NAME.PRA,
  FORM_NAME.PDA,
];
const SHOW_OUTCOME_SCORE = [FORM_NAME.RRX, FORM_NAME.OUTCOME];

const enhance = connect(
  (state, props) => ({
    checkins: getCheckinsForPatientSelector(state, props),
    selfie: getSelfieSelector(state),
  }),
  {
    createPatientManufacturingTask,
    createPatientWearSchedule,
    putTask,
    setPatientOfficeSyncFolder,
    loadClinicalRecord,
    createAnotherPatientRecordFolder,
    fetchCheckinsForPatient,
    loadSelfie: loadFiles,
  }
);

class FormSidebar extends Component {
  static propTypes = {
    staffSubmittedData: PropTypes.array,
    patient: PropTypes.object,
    syncFolderPath: PropTypes.string,
    formName: PropTypes.string,
    formData: PropTypes.object,
  };
  static contextTypes = {
    router: routerShape,
    patient: PropTypes.object,
  };
  constructor() {
    super();
    this.state = {
      localOfficeSyncFolder: OFFICE_SYNC_FOLDERS[0],
      waitingForFolderToCreate: false,
    };
  }

  componentDidMount = () => {
    this.props.loadSelfie(this.props.patient.id);
    this.props.fetchCheckinsForPatient({ patientId: this.props.patient.id });

    // this code is to auto set the folder to 999 sutter on PIV
    // MUST change this when we add a new office in the future.
    if (!this.props.syncFolderPath && this.props.formName === FORM_NAME.PIV) {
      this.setOfficeSyncFolder();
    }
  };

  setOfficeSyncFolder = () => {
    if (!this.state.localOfficeSyncFolder) return;
    this.setState({ waitingForFolderToCreate: true });
    this.props
      .setPatientOfficeSyncFolder({
        uuid: this.props.patient.id,
        officeName: this.state.localOfficeSyncFolder,
      })
      .then(() => {
        this.setState({ waitingForFolderToCreate: false });
        this.props.loadClinicalRecord(this.props.patient.id);
      });
  };
  createAnotherPatientRecordFolder = () => {
    this.setState({ waitingForFolderToCreate: true });
    this.props
      .createAnotherPatientRecordFolder(this.props.patient.id)
      .then(() => {
        this.setState({ waitingForFolderToCreate: false });
      });
  };
  saveOfficeFolderToState = (event, index, text) => {
    this.setState({ localOfficeSyncFolder: text });
  };
  linkToConsentForm = () => {
    this.context.router.push(
      `/treatment/${this.props.patient.id}/consent-form/financial`
    );
  };
  linkToPrivacyPolicy = () => {
    this.context.router.push(
      `/treatment/${this.props.patient.id}/consent-form/privacy`
    );
  };
  render() {
    const { patient, selfie, syncFolderPath, formName } = this.props;
    const disabledSyncFolderButton = this.state.waitingForFolderToCreate;
    const showOfficeFolder = includes(SHOW_OFFICE_SYNC_SIDE_BAR, formName);
    const showOutcome = includes(SHOW_OUTCOME_SCORE, formName);
    return (
      <div style={styles.sidePanel}>
        <div>
          {showOutcome && (
            <OutcomeScore staffSubmittedData={this.props.staffSubmittedData} />
          )}
          {showOfficeFolder && (
            <div>
              <div style={styles.S.contentBlock}>
                {syncFolderPath ? (
                  <div>
                    <div style={styles.S.boldLabel}>
                      Office folder path already set to:
                    </div>
                    <div style={styles.S.longSmallText}>{syncFolderPath}</div>
                    <RaisedButton
                      style={styles.officeSyncButton}
                      disabled={disabledSyncFolderButton}
                      label="+ record folder"
                      onClick={this.createAnotherPatientRecordFolder}
                    />
                  </div>
                ) : (
                  <div>
                    <DropDownMenu
                      value={this.state.localOfficeSyncFolder}
                      onChange={this.saveOfficeFolderToState}
                    >
                      {map(OFFICE_SYNC_FOLDERS, name => (
                        <MenuItem value={name} key={name} primaryText={name} />
                      ))}
                    </DropDownMenu>
                    <div>
                      <RaisedButton
                        disabled={disabledSyncFolderButton}
                        secondary
                        label="Set Office"
                        onClick={this.setOfficeSyncFolder}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {formName === "vp" && (
            <div>
              <div style={styles.S.contentBlock}>
                <div style={styles.S.sectionTitle}>Checkins</div>
                {this.props.checkins.map(c => (
                  <Checkin key={c.id} checkin={c} />
                ))}
              </div>
            </div>
          )}
          {formName === "piv" && (
            <div>
              <div style={styles.patientConsentContainer}>
                <RaisedButton
                  style={styles.patientConsent}
                  label="Privacy Policy"
                  secondary
                  onClick={this.linkToPrivacyPolicy}
                />
              </div>
              <div style={styles.patientConsentContainer}>
                <RaisedButton
                  style={styles.patientConsent}
                  label="Consent Form"
                  secondary
                  onClick={this.linkToConsentForm}
                />
              </div>
              <div style={styles.patientConsentContainer}>
                <RaisedButton
                  style={styles.patientConsent}
                  label="Medical and Dental Form"
                  secondary
                  onClick={() => {
                    request(
                      `/api/v1/patients/${
                        this.props.patient.id
                      }/redirect_to_medical_form`
                    ).then(resp => {
                      window.open(resp.url, "_blank");
                    });
                  }}
                />
              </div>
            </div>
          )}
          <div style={styles.S.contentBlock}>
            <div style={styles.S.sectionTitle}>
              <strong>Selfie</strong>
            </div>
            <Gallery fileList={selfie} />
          </div>
          <AdverseHistory patient={patient} />
        </div>
      </div>
    );
  }
}

export default enhance(FormSidebar);
