import React, { useState } from "react";
import { Modal, Form } from "antd";
import { PromoUserTypeList } from "src/types/gql";
import { AddPromoUser } from "./queries";
import { css } from "emotion";

import Button from "src/shared/Button";
import Input from "src/shared/InputText";
import Dropdown from "src/shared/Dropdown";

const buttonStyle = css`
  display: inline-block;
`;

const formItemStyle = css`
  margin-bottom: 0;
`;

interface Props {
  onAdd: Function;
  setVisible: Function;
}

const defaultAddPromoUserObject: AddPromoUser = {
  email: "",
  type: "",
};

const AddPromoUserModal = (props: Props) => {
  const [addPromoUser, setAddPromoUser] = useState<AddPromoUser>(
    defaultAddPromoUserObject
  );

  const isValid = () => {
    return addPromoUser.email !== "" && addPromoUser.type !== "";
  };

  const onAdd = () => {
    if (isValid()) {
      props.onAdd({
        input: addPromoUser,
      });
      props.setVisible(false);
    }
  };

  return (
    <Modal
      title="Add free treatment user"
      onOk={onAdd}
      onCancel={() => props.setVisible(false)}
      visible={true}
      destroyOnClose={true}
      footer={[
        <Button
          className={buttonStyle}
          key="back"
          onClick={() => props.setVisible(false)}
        >
          Cancel
        </Button>,
        <Button
          className={buttonStyle}
          key="submit"
          type="primary"
          disabled={!isValid()}
          loading={false}
          onClick={onAdd}
        >
          Add
        </Button>,
      ]}
    >
      <Form>
        <Form.Item required className={formItemStyle} label="Email">
          <Input
            onChange={e =>
              setAddPromoUser({ ...addPromoUser, email: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item required className={formItemStyle} label="Type">
          <Dropdown
            height={"32px"}
            options={PromoUserTypeList}
            selected={addPromoUser.type}
            onSelect={v => setAddPromoUser({ ...addPromoUser, type: v })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddPromoUserModal;
