import { css } from "react-emotion";

export const buttonCss = css`
  padding-right: 32px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 0.5px;
    height: 100%;

    position: absolute;
    top: 0;
    right: 24px;

    background: white;
  }
`;

export const triangleCss = css`
  display: inline-block;
  border-top: 5px solid white;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: 0 solid transparent;

  position: absolute;
  top: 50%;
  right: 8px;

  transform: translateY(-50%);
`;

export const selectCss = css`
  width: 110px;
  padding: 3px 1px;
  margin: 0;
  border: 2px;
`;

export const itemCss = css`
  padding: 0;
`;

export const itemButtonCss = css`
  display: block;
  width: 100%;
  border: none;
  padding: 5px 7px;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #333a3a;
  text-align: left;

  background: none;
  cursor: pointer;

  &:hover {
    background: #f8f8f8;
  }

  &:active,
  &:focus {
    outline: none;
  }
`;
