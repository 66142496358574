import React, { useState } from "react";
import { useUpdatePatient, UpdateUserType } from "src/state/usePatientInfo";
import { Patient } from "src/types/gql";
import Input from "src/shared/InputText";
import { PatientConfirm } from "src/shared/PatientConfirm/PatientConfirm";

interface Props {
  patient: Patient;
  visible: boolean;
  setVisible(visible: boolean): void;
}

const MarkAsSpam = (props: Props) => {
  const { patient, setVisible, visible } = props;

  // Mutation
  const updatePatient = useUpdatePatient();

  const [updatedPatient, setUpdatedPatient] = useState<UpdateUserType>({
    patientId: patient.id,
    spamReason: patient.spamReason,
  });

  const markPatientAsSpam = async () => {
    await updatePatient({
      updateRequest: { ...updatedPatient, isSpam: true },
    });
    setVisible(false);
  };

  return (
    <PatientConfirm
      patient={patient}
      visible={visible}
      setVisible={setVisible}
      content="Are you sure you want to mark this user as spam?"
      onConfirm={() => {
        markPatientAsSpam();
      }}
    >
      <Input
        placeholder="Optional reason"
        value={updatedPatient.spamReason}
        autosize={{ minRows: 2, maxRows: 6 }}
        onChange={e =>
          setUpdatedPatient({
            ...updatedPatient,
            spamReason: e.target.value,
          })
        }
      />
    </PatientConfirm>
  );
};

export default MarkAsSpam;
