import React, { useState, useCallback } from "react";
import { notification } from "antd";

import Modal from "src/shared/Modal";
import Box from "src/shared/Box";
import { useUpdateExternalSpecialist } from "src/scenes/Patient/ExternalCare/query";

import ProviderContent, {
  SearchParameters as SpecialistSearchParameters,
  SubmitParameters,
  ProviderContentProps,
} from "./ProviderContent";
import { useGetFilteredVerifiedSpecialists } from "./queries";

const NOB_HILL_LAT = 37.7880317;
const NOB_HILL_LONG = -122.4166611;

const ExternalProviderModal = ({
  onClose,
  onChange,
  mapView = false,
  defaultLat = NOB_HILL_LAT,
  defaultLong = NOB_HILL_LONG,
  defaultFilterPreferredProviders = false,
  boxDisplay = false,
}) => {
  const [specialistsRequestParams, setSpecialistsRequestParams] = useState<
    SpecialistSearchParameters
  >({
    search: "",
    typeFilter: "all",
    shouldFilterPreferredProviders: defaultFilterPreferredProviders,
  });
  const [, , response] = useGetFilteredVerifiedSpecialists(
    1,
    specialistsRequestParams.search,
    specialistsRequestParams.typeFilter,
    specialistsRequestParams.shouldFilterPreferredProviders
  );
  const updateExternalSpecialist = useUpdateExternalSpecialist();

  const { pageResults = {}, verifiedSpecialists = [] } = response || {};
  const Child = boxDisplay ? ProviderContent : ModalContainer;

  const onProviderContentChange = useCallback(
    (value: SpecialistSearchParameters) => {
      setSpecialistsRequestParams(value);
    },
    [setSpecialistsRequestParams]
  );
  const onSubmit = async (value: SubmitParameters) => {
    const { selectedSpecialist, patientProviderState } = value;

    if (!selectedSpecialist) return;

    const externalSpecialistId = patientProviderState
      ? await onChange(selectedSpecialist.id, patientProviderState)
      : await onChange(selectedSpecialist.id);

    if (patientProviderState && externalSpecialistId) {
      const result = await updateExternalSpecialist({
        updateExternalSpecialist: {
          id: externalSpecialistId,
          state: patientProviderState,
        },
      });

      if (!result || result.error) {
        notification.error({
          message: "Unable to update External Specialist Status",
        });
        return;
      }

      notification.success({
        message: "External Specialist Status Added",
      });
    }
    onClose();
  };

  return (
    <Child
      mapView={mapView}
      defaultFilterPreferredProviders={defaultFilterPreferredProviders}
      defaultLat={defaultLat}
      defaultLong={defaultLong}
      pageMeta={pageResults}
      verifiedSpecialists={verifiedSpecialists}
      onChange={onProviderContentChange}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

const ModalContainer: React.FC<ProviderContentProps> = props => (
  <Modal ignoreOutOfBoundariesClick onClose={props.onClose}>
    <Box title="Add external provider">
      <ProviderContent {...props} />
    </Box>
  </Modal>
);

export default ExternalProviderModal;
