import React from "react";
import { Kit } from "src/types/api";
const styles = {
  link: {
    textDecoration: "underline",
    fontWeight: 600,
    cursor: "pointer",
  },
};

interface Props {
  kit: Kit;
}

const KitItem = (props: Props) => {
  const getTrayRange = () => {
    const { kit } = props;
    const trays = kit.wear_timeline_stages;
    if (!trays || trays.length === 0) {
      return <p>No trays included</p>;
    }

    if (trays.length === 1) {
      return <p>Includes tray {trays[0].stage} only</p>;
    }

    const last = trays[trays.length - 1];
    return (
      <p>
        Includes trays{" "}
        <b>
          {trays[0].stage} through {last.stage}
        </b>
      </p>
    );
  };

  const openTrackingWindow = label => {
    window.open(
      `https://tools.usps.com/go/TrackConfirmAction?tLabels=${label}`,
      "newwindow",
      "width=800,height=800"
    );
  };
  const getDeliveryStatus = () => {
    const { shipment } = props.kit;

    if (!shipment) {
      return <p>Shipment info unavailable - has one been made?</p>;
    }

    return (
      <div>
        <div>
          <div>
            {shipment.carrier} {shipment.delivery_type}
            <br />
            {shipment.tracking_number && (
              <div>
                Tracking #:{" "}
                {(
                  <div
                    style={styles.link}
                    onClick={() => {
                      openTrackingWindow(shipment.tracking_number);
                    }}
                  >
                    {shipment.tracking_number}
                  </div>
                ) || "NA"}
              </div>
            )}
            {shipment.status && <span>Status: {shipment.status}</span>}
          </div>

          {shipment.estimated_delivery_date && (
            <p>Est delivery: {shipment.estimated_delivery_date}</p>
          )}
          {shipment.actual_delivery_date && (
            <p>Est delivery: {shipment.actual_delivery_date}</p>
          )}
        </div>
      </div>
    );
  };

  const { kit } = props;
  return (
    <div>
      <p>
        <b>Kit {kit.kit_number}</b>
      </p>
      <div style={{ fontSize: ".8rem" }}>
        {getTrayRange()}
        {getDeliveryStatus()}
        <p style={{ opacity: 0.6 }}>
          Includes {kit.includes && kit.includes.join(", ")}
        </p>
      </div>
    </div>
  );
};

export default KitItem;
