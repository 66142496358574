// @flow
import React from "react";
import { Table, Tag, Icon } from "antd";

/*
type Props = {
  build: Build,
  selected: Array<number>,
  setSelected: (Array<number>) => void,
};
*/

const columns = (selectedDefects, setSelectedDefects) => {
  return [
    {
      title: "Tray",
      width: 150,
      render: (_, tb) => {
        return `${tb.tray.stage} ${tb.arch_type}`;
      },
    },
    {
      title: "Serial #",
      width: 150,
      dataIndex: "serial_number",
    },
    {
      title: "Template",
      dataIndex: "is_template",
      width: 100,
      render: (template, tb) => {
        return template ? <Icon type="check" /> : <div />;
      },
    },
    {
      title: "Defects",
      render: (_, tb) => {
        // TODO: Render defects for each tray build
        return (
          <>
            {tb.tray_defects.map(defect => {
              // Tags are only checkable if they're unresolved
              const C = !!defect.resolved_at ? Tag : Tag.CheckableTag;
              return (
                <C
                  checked={!!selectedDefects[defect.id]}
                  onChange={() => {
                    const copy = Object.assign({}, selectedDefects);
                    if (selectedDefects[defect.id]) {
                      delete copy[defect.id];
                    } else {
                      copy[defect.id] = true;
                    }
                    setSelectedDefects(copy);
                  }}
                >
                  {!!defect.resolved_at && (
                    <Icon type="check" style={{ marginRight: ".5rem" }} />
                  )}
                  {defect.issue.description}
                </C>
              );
            })}
          </>
        );
      },
    },
  ];
};

export const TrayTable = ({
  build,
  selected,
  setSelected,
  selectedDefects,
  setSelectedDefects,
}) => {
  const sorted = build.tray_builds
    .sort((a, b) => {
      return a.tray.stage - b.tray.stage;
    })
    .map(t => Object.assign({}, t, { key: t.id }));

  return (
    <Table
      pagination={false}
      size="small"
      rowSelection={{ selectedRowKeys: selected, onChange: setSelected }}
      dataSource={sorted}
      columns={columns(selectedDefects, setSelectedDefects)}
    />
  );
};
