import React from "react";
import { Avatar as AntdAvatar } from "antd";

const Avatar = ({ patient, style, onClick, size }) => {
  const { avatar_url, first_name, last_name } = patient;
  const firstInitial = first_name && first_name.charAt(0).toUpperCase();
  const lastInitial = last_name && last_name.charAt(0).toUpperCase();
  return (
    <AntdAvatar
      size={size}
      src={avatar_url}
      alt={`${firstInitial} ${lastInitial}`}
    >
      {firstInitial}
      {lastInitial}
    </AntdAvatar>
  );
};

export default Avatar;
