import React, { useState } from "react";
import { Select, Input } from "antd";
import { fromPairs, isEmpty } from "lodash";
import styled, { css } from "react-emotion";
import { notification } from "antd";

import { useQuery } from "src/utils/http/gqlQuery";
import Modal from "src/shared/Modal";
import Box from "src/shared/Box";
import { Staff } from "src/types/gql";
import Loading from "src/shared/Loading";
import Button, { ButtonRow } from "src/shared/Button";

import textStyles from "src/styles/textStyles";
import Error from "src/shared/Error";
import {
  useGetAllClinics,
  useUpdateStaffDetails,
  useAddUserRoles,
  useDeleteUserRoles,
  staffUsers,
  useAddClinicsForStaff,
  useRemoveClinicsForStaff,
  allRoles,
} from "./query";

const Content = styled.div`
  display: flex;
  flex-flow: column;

  height: 450px;
  width: 600px;
  padding: 32px;
  overflow-y: overlay;
`;

const InputContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
`;

const Label = styled.div`
  ${textStyles("large")};
  font-weight: bold;
  margin-bottom: 5px;
`;

const selectedStyle = css`
  margin-bottom: 10px;
`;

type Props = {
  staff: Staff;
  organizationID: string;
  onClose: () => void;
};

type StaffTextFields = {
  firstName: string;
  lastName: string;
  email: string;
  mobilePhoneNumber: string;
};

const fieldNameToLabel = {
  firstName: "First name",
  lastName: "Last name",
  email: "Email",
  mobilePhoneNumber: "Phone number",
};

const InputWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-flow: column;
`;

const getChangedFields = (original, updated) => {
  const changedFields = Object.keys(fieldNameToLabel).filter(
    field => original[field] !== updated[field]
  );
  return fromPairs(changedFields.map(field => [field, updated[field]]));
};

const getAddedUserRoles = (originalRoles, updatedRoles) => {
  return updatedRoles.filter(role => !originalRoles.includes(role));
};

const getDeletedUserRoleIDs = (staffUserRoles, updatedRoles) => {
  return staffUserRoles
    .filter(userRole => !updatedRoles.includes(userRole.role))
    .map(userRole => userRole.id);
};

const getClinicId = (allClinics, name) => {
  const clinic = allClinics.find(clinic => clinic.name === name);
  return clinic && clinic.id;
};

const getAddedClinics = (
  allClinics,
  originalClinicNames,
  updatedClinicNames
) => {
  const addedClinicNames = updatedClinicNames.filter(
    name => !originalClinicNames.includes(name)
  );
  return addedClinicNames.map(name => getClinicId(allClinics, name));
};

const getRemovedClinics = (
  allClinics,
  originalClinicNames,
  updatedClinicNames
) => {
  const removedClinicNames = originalClinicNames.filter(
    name => !updatedClinicNames.includes(name)
  );
  return removedClinicNames.map(name => getClinicId(allClinics, name));
};

const EditStaffModal: React.FC<Props> = ({
  onClose,
  staff,
  organizationID,
}) => {
  const [values, setValues] = useState<StaffTextFields>({
    firstName: staff.firstName,
    lastName: staff.lastName,
    email: staff.email,
    mobilePhoneNumber: staff.mobilePhoneNumber,
  });
  const originalRoles = staff.roles.map(role => role.role);
  const [roles, setRoles] = useState<string[]>(originalRoles);
  const originalClinics = staff.clinics.map(clinic => clinic.name);
  const [clinics, setClinics] = useState<string[]>(originalClinics);
  const [error, setError] = useState<string | null>(null);

  const [cFetching, cErr, allClinics] = useGetAllClinics(organizationID);
  const updateDetails = useUpdateStaffDetails();
  const addUserRoles = useAddUserRoles();
  const deleteUserRoleIDs = useDeleteUserRoles();
  const addClinics = useAddClinicsForStaff();
  const removeClinics = useRemoveClinicsForStaff();

  const [, refetchStaff] = useQuery({
    query: staffUsers,
    variables: { organizationID },
  });

  if (cFetching) return <Loading />;
  if (cErr) return <Error />;

  const onValueChange = fieldName => e => {
    setValues({
      ...values,
      [fieldName]: e.target.value,
    });
  };

  const submit = async () => {
    const changedFields = getChangedFields(staff, values);
    if (!isEmpty(changedFields)) {
      const result = await updateDetails({
        input: {
          staffID: staff.id,
          ...changedFields,
        },
      });

      if (result.error) {
        setError(result.error.message);
        return;
      }
    }

    const addedUserRoles = getAddedUserRoles(originalRoles, roles);
    if (!isEmpty(addedUserRoles)) {
      const rolesRes = await addUserRoles({
        input: {
          userID: staff.id,
          organizationID,
          roles: getAddedUserRoles(originalRoles, roles),
        },
      });

      if (rolesRes.error) {
        setError(rolesRes.error.message);
        return;
      }
    }

    const deletedRoles = getDeletedUserRoleIDs(staff.roles, roles);
    if (!isEmpty(deletedRoles)) {
      const deletedRolesRes = await deleteUserRoleIDs({
        input: {
          roleIDs: deletedRoles,
        },
      });

      if (deletedRolesRes.error) {
        setError(deletedRolesRes.error.message);
        return;
      }
    }

    const addedClinicIDs = getAddedClinics(
      allClinics,
      originalClinics,
      clinics
    );

    if (!isEmpty(addedClinicIDs)) {
      const addedClinicRes = await addClinics({
        input: {
          staffID: staff.id,
          clinicIDs: addedClinicIDs,
        },
      });

      if (addedClinicRes.error) {
        setError(addedClinicRes.error.message);
        return;
      }
    }

    const removedClinicIDs = getRemovedClinics(
      allClinics,
      originalClinics,
      clinics
    );

    if (!isEmpty(removedClinicIDs)) {
      const removedClinicRes = await removeClinics({
        input: {
          staffID: staff.id,
          clinicIDs: removedClinicIDs,
        },
      });

      if (removedClinicRes.error) {
        setError(removedClinicRes.error.message);
        return;
      }
    }

    refetchStaff();
    notification.success({
      message: "Staff details updated!",
    });
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <Box title={`Edit ${staff.name}`}>
        <Content>
          <InputContainer>
            {error && <div>{error}</div>}
            <div>
              <InputWrapper>
                <Label>First name</Label>
                <Input
                  value={values.firstName}
                  onChange={onValueChange("firstName")}
                />
              </InputWrapper>
              <InputWrapper>
                <Label>Last name</Label>
                <Input
                  value={values.lastName}
                  onChange={onValueChange("lastName")}
                />
              </InputWrapper>
              <InputWrapper>
                <Label>Email</Label>
                <Input value={values.email} onChange={onValueChange("email")} />
              </InputWrapper>
              <InputWrapper>
                <Label>Phone</Label>
                <Input
                  value={values.mobilePhoneNumber}
                  onChange={onValueChange("mobilePhoneNumber")}
                />
              </InputWrapper>
            </div>
            <Label>User roles</Label>
            <Select
              className={selectedStyle}
              mode="multiple"
              placeholder="Select roles"
              onChange={setRoles}
              optionLabelProp="value"
              value={roles}
            >
              {allRoles.map(role => (
                <Select.Option key={role} value={role}>
                  <div>{role}</div>
                </Select.Option>
              ))}
            </Select>
            <Label>Clinics</Label>
            <Select
              className={selectedStyle}
              mode="multiple"
              placeholder="Select clinics"
              onChange={setClinics}
              optionLabelProp="value"
              value={clinics}
            >
              {allClinics.map(({ name }) => (
                <Select.Option key={name} value={name}>
                  <div>{name}</div>
                </Select.Option>
              ))}
            </Select>
          </InputContainer>
          <ButtonRow position="right">
            <Button kind="default" onClick={onClose}>
              Cancel
            </Button>
            <Button kind="primary" onClick={submit}>
              Save
            </Button>
          </ButtonRow>
        </Content>
      </Box>
    </Modal>
  );
};

export default EditStaffModal;
