import pipe from "lodash/fp/pipe";
import map from "lodash/fp/map";
import curry from "lodash/fp/curry";
import flatten from "lodash/fp/flatten";
import omitBy from "lodash/fp/omitBy";
import keys from "lodash/fp/keys";
import cond from "lodash/fp/cond";
import isEmpty from "lodash/fp/isEmpty";
import constant from "lodash/fp/constant";
import stubTrue from "lodash/fp/stubTrue";
import identity from "lodash/fp/identity";
import get from "lodash/fp/get";
import __ from "lodash/fp/placeholder";
import negate from "lodash/fp/negate";
import uniq from "lodash/fp/uniq";
import intersection from "lodash/fp/intersection";

import { CheckboxGroupValue } from "src/shared/CheckboxGroup/types";
import { Staff } from "src/types/gql";
import { Option } from "src/shared/Select/types";

import {
  Clinic,
  PipelineCategories,
  PipelineTypes,
  TaskTypes,
} from "../../types";
import { pipelineTypeToTaskTypeMap, taskTypeToTitleMap } from "../../mappings";
import {
  usStates,
  pipelineCategoryToPipelineTypeMap,
  allPipelineTypesTitles,
  pipelineCategoryCheckboxes,
} from "./constants";
import { FormValues, FiltersValue } from "./types";

export const prepareClinicsForSelect = (clinics: Clinic[]) => {
  const groupedClinics: Record<
    string,
    Option<string, { isFlagship: boolean }>[]
  > = {};

  clinics.forEach(({ id, name, type, state: stateCode }) => {
    const clinic = {
      id,
      value: name,
      payload: { isFlagship: type === "flagship" },
    };
    const state = usStates[stateCode.toLowerCase()];

    if (Array.isArray(groupedClinics[state])) {
      groupedClinics[state].push(clinic);
    } else {
      groupedClinics[state] = [clinic];
    }
  });

  return Object.entries(groupedClinics).map(([state, options]) => ({
    group: state,
    options,
  }));
};

export const prepareKeysArrayForSelect = <Keys extends string>(
  keys: Keys[],
  titles?: Record<Keys, string>
) => {
  return keys.map(key => ({
    id: key,
    value: titles ? titles[key] : key,
    payload: null,
  }));
};

const defaultsToIfEmpty = curry((defaultValue, value) =>
  cond([[isEmpty, constant(defaultValue)], [stubTrue, identity]])(value)
);

const getPipelineCategoriesOptions: UnaryFn<
  CheckboxGroupValue<PipelineCategories[]>,
  PipelineCategories[]
> = pipe(
  omitBy(negate(Boolean)),
  keys,
  defaultsToIfEmpty(pipelineCategoryCheckboxes)
);

const getPipelineTypesByPipelineCategoriesOptions: UnaryFn<
  PipelineCategories[],
  PipelineTypes[]
> = pipe(
  map(get(__, pipelineCategoryToPipelineTypeMap)),
  flatten
);

export const getTaskTypesByPipelineTypes: UnaryFn<
  PipelineTypes[],
  TaskTypes[]
> = pipe(
  map(get(__, pipelineTypeToTaskTypeMap)),
  flatten,
  uniq
);

export const preparePipelineTypesForSelect: UnaryFn<
  CheckboxGroupValue<PipelineCategories[]>,
  Option<TaskTypes>[]
> = pipe(
  getPipelineCategoriesOptions,
  getPipelineTypesByPipelineCategoriesOptions,
  curry(prepareKeysArrayForSelect)(__, allPipelineTypesTitles)
);

export const prepareTaskTypesForSelect = (
  pipelineTypesOptions: PipelineTypes[],
  selectedPipelineTypesOptions: PipelineTypes[]
): Option<TaskTypes>[] => {
  return pipe(
    // gets selected only pipelineTypes or
    // all pipelineTypes if none selected
    defaultsToIfEmpty(pipelineTypesOptions),
    getTaskTypesByPipelineTypes,
    map(taskType => ({
      id: taskType,
      value: taskTypeToTitleMap[taskType],
      payload: null,
    }))
  )(selectedPipelineTypesOptions);
};

export const prepareStaffForSelect = (
  staff: Pick<Staff, "id" | "firstName" | "lastName">[]
) => {
  return staff.map(({ id, firstName, lastName }) => ({
    id,
    value: `${firstName} ${lastName}`.trim(),
    payload: null,
  }));
};

// filters pipeline types depending on
// controlling fields' values
export const filterPipelineTypes = (values: FormValues): PipelineTypes[] => {
  return pipe(
    getPipelineCategoriesOptions,
    getPipelineTypesByPipelineCategoriesOptions,
    intersection(values.pipelineTypes)
  )(values.pipelineCategories);
};

// filters task types depending on
// controlling fields' values
export const filterTaskTypes = (values: FormValues): TaskTypes[] => {
  return pipe(
    getPipelineCategoriesOptions,
    getPipelineTypesByPipelineCategoriesOptions,
    // gets selected pipelineTypes or pipelineTypes
    // narrowed down by controlling fields
    defaultsToIfEmpty(__, values.pipelineTypes),
    getTaskTypesByPipelineTypes,
    // gets only those selected taskTypes
    // which present in narrowed taskTypes
    intersection(__, values.taskTypes)
  )(values.pipelineCategories);
};

export const prepareFilterValue: UnaryFn<
  FormValues,
  FiltersValue
> = formValues => {
  const values = omitBy(
    (value, key) => isEmpty(value) || ["pipelineCategories"].includes(key),
    formValues
  );
  const { assignees, treatmentLevels } = values as FiltersValue;
  const selectedPipelineCategories = getPipelineCategoriesOptions(
    formValues.pipelineCategories
  );

  if (assignees) {
    values.assignees = {
      assigneeIDs: assignees,
      unassigned: false,
    };
  }

  if (treatmentLevels) {
    values.treatmentLevels = keys(omitBy(negate(Boolean), treatmentLevels));
  }

  if (
    !isEmpty(selectedPipelineCategories) &&
    !values.pipelineTypes &&
    !values.taskTypes
  ) {
    values.pipelineTypes = getPipelineTypesByPipelineCategoriesOptions(
      selectedPipelineCategories
    );
  }

  return values;
};
