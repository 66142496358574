import { V } from "src/shared-styles";
const S = {
  container: {
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "calc(100vh - 60px)",
    width: "calc(100% - 250px)",
  },
  header: {
    padding: "1rem",
    textAlign: "center",
    fontWeight: V.fontWeightHeavy,
    borderBottom: `1px solid ${V.colors.borderColor}`,
    background: V.colors.grey100,
  },
  footer: {
    display: "flex",
    background: V.colors.grey100,
    borderTop: `1px solid ${V.colors.borderColor}`,
  },
  send: {
    position: "absolute",
    right: 25,
    top: "50%",
    marginTop: -20,
  },
  input: {
    flex: 1,
  },
  buttonWrapper: {
    width: V.spacingHuge,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  bottomWrapper: {
    padding: V.spacingSmall,
    display: "flex",
    flexWrap: "wrap",
    maxHeight: 300,
    overflow: "auto",
  },
  structuredMessageWrapper: {
    padding: V.spacingSmall,
    maxHeight: 300,
    overflow: "scroll",
  },
  chip: {
    marginRight: V.spacingSmall,
  },
  link: {
    textDecoration: "underline",
    color: V.colors.fontPrimary,
  },
  messageTitle: {
    marginBottom: V.spacingTiny,
    marginTop: V.spacingTiny,
  },
  messageText: {
    fontSize: V.fontSmall,
    opacity: 0.5,
  },
  attachments: {
    display: "flex",
    flexWrap: "wrap",
    padding: V.spacingSmall + V.spacingTiny,
    marginLeft: 86,
  },
  status: {
    color: V.colors.fontLight,
    fontWeight: V.fontWeightLight,
    marginLeft: V.spacing,
    marginRight: V.spacing,
  },
  sending: {
    position: "absolute",
    top: 0,
    left: 0,
    width: `calc(100% - ${V.spacingTiny}px)`,
    background: V.colors.green,
    color: "#fff",
    fontWeight: "bold",
    padding: V.spacingSmall,
    margin: V.spacingTiny,
    textAlign: "center",
  },
  error: {
    position: "absolute",
    top: 0,
    left: 0,
    width: `calc(100% - ${V.spacingTiny}px)`,
    background: V.colors.error,
    color: "#fff",
    fontWeight: "bold",
    padding: V.spacingSmall,
    margin: V.spacingTiny,
    textAlign: "center",
  },
  notificationOff: {
    fill: V.colors.orange,
  },
};

export default S;
export { V };
