import styled from "react-emotion";
import color from "src/styles/color";
import { Link } from "react-router";
import Button from "src/shared/Button";

export const Perimeter = styled.div`
  height: 64px;
  background: ${color.white};
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  position: relative;
  border-bottom: 1px solid ${color.border};
  position: sticky;
  top: 0;
`;

export const MenuLink = styled(Link)`
  position: relative;
  font-size: 15px;
  color: #000000;
  font-weight: 500;
`;

export const StyledButton = styled(Button)`
  margin-left: 10px;

  &.ant-popover-open > div {
    background: ${color.gray1} !important;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
`;
