import orderBy from "lodash/orderBy";
import { DateTime } from "luxon";
import { Submission as GQLSubmission } from "src/scenes/PatientProfile/Forms/GQLForms/types";
import { Submission as APISubmission } from "src/types/api";

type SubmissionData =
  | Pick<GQLSubmission, "approvedAt" | "submittedAt" | "createdAt">
  | Pick<APISubmission, "approved_at" | "submitted_at" | "created_at">;

// Sorts by the latest submitted or created date
export function sortBySubmitOrCreateTime<T extends SubmissionData>(
  submissions: Array<T>
): Array<T> {
  return orderBy(
    submissions,
    (s: any) =>
      // `s: any` is not ideal, but the otherwise complex typing mechanics are not worth the effort

      // GQLSubmission
      s.approvedAt ||
      s.submittedAt ||
      s.createdAt ||
      // APISubmission
      s.approved_at ||
      s.submitted_at ||
      s.created_at,
    "desc"
  );
}

export function formatSubmissionDateTime(timestamp: string): string {
  return DateTime.fromISO(timestamp).toLocaleString(DateTime.DATETIME_SHORT);
}

export function formatSubmissionLabel<T extends SubmissionData>(
  submission: T
): string {
  const s = submission as any;

  const approvedAt = (s.approvedAt || s.approved_at) as string;
  if (approvedAt) {
    return `Approved ${formatSubmissionDateTime(approvedAt)}`;
  }

  const submittedAt = (s.submittedAt || s.submitted_at) as string;
  if (submittedAt) {
    return `Submitted ${formatSubmissionDateTime(submittedAt)}`;
  }

  const createdAt = (s.createdAt || s.created_at) as string;
  return `Created ${formatSubmissionDateTime(createdAt)}`;
}
