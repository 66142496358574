import React from "react";

type Props = {
  fill?: string;
  size?: number;
};

const Add: React.SFC<Props> = ({ size, fill = "#fff" }: Props) => (
  <svg
    width={size || 14}
    height={size || 14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9203 5.62197H8.3763V0.0779724H6.0243V5.62197H0.508301V7.97397H6.0243V13.49H8.3763V7.97397H13.9203V5.62197Z"
      fill={fill}
    />
  </svg>
);

export default Add;
