import React from "react";
import styled from "react-emotion";
// shared
import LegacyModal from "src/shared/LegacyModal";
import Button from "src/shared/Button";
// styles
import textStyles from "src/styles/textStyles";
import color from "src/styles/color";
import money from "src/utils/money";
import { useTreatmentProducts } from "src/scenes/Patient/BillingV2/Orders/query";

const Title = styled.div`
  ${textStyles("xlarge")};
  font-weight: bold;
`;

const Perimeter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OrangeText = styled.div`
  ${textStyles("xlarge")};
  font-weight: bold;
  color: ${color.primary};
  padding-bottom: 12px;
  border-bottom: 1px solid ${color.border};
  width: 100%;
  margin-top: 12px;
  &:first-child {
    margin-top: 0;
  }
`;

const Label = styled.div`
  ${textStyles("large")};
  font-weight: bold;
`;

const Cost = styled.div`
  ${textStyles("large")};
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${color.border};
  padding: 12px 0;
  &:last-child {
    margin-bottom: 12px;
  }
`;

const TreatmentCostModal = props => {
  const [, , products = []] = useTreatmentProducts();
  return (
    <LegacyModal
      isOpen={props.isOpen}
      closeModal={props.handleClose}
      width="440px"
    >
      <LegacyModal.Title>
        <Title>Treatment Cost</Title>
      </LegacyModal.Title>
      <LegacyModal.Content>
        <Perimeter style={{ marginBottom: "16px" }}>
          <OrangeText>Treatment Plan Cost</OrangeText>
          {products.map(({ id, name, price }) => (
            <Row key={id}>
              <Label>{name}</Label>
              <Cost>{money(price)}</Cost>
            </Row>
          ))}
        </Perimeter>
        <Perimeter>
          <Button
            kind="primary"
            onClick={props.handleDone}
            style={{ width: "100px", height: "36px" }}
          >
            Done
          </Button>
        </Perimeter>
      </LegacyModal.Content>
    </LegacyModal>
  );
};

export default TreatmentCostModal;
