import React, { useState } from "react";
import styled from "react-emotion";
import { browserHistory } from "react-router";
import { Popconfirm } from "antd";

import { useSelfRoles } from "src/state/self";
import Button from "src/shared/Button";
import Loading from "src/shared/Loading";
import ExternalProviderModal from "src/shared/ExternalProvider/ExternalProviderModal";
import VerifiedProviderCard from "../BeginningVisit/VerifiedProviderCard";
import Dropdown from "src/shared/Dropdown";
import { DetailedPatient } from "src/types/api";
import textStyles from "src/styles/textStyles";
import color from "src/styles/color";
import useFormUI from "./useFormUI";
import NoSubmissions from "./NoSubmissions";
import { calculateChoices, calculateDisplayPredicate } from "./consts";
import PatientInformation from "./PatientInformation";
import CustomTitlegroupContent from "./Custom/CustomTitlegroup";
import CustomQuestionContent from "./Custom/CustomQuestion";
import { getSections } from "./Custom/CustomSections";
import AppointmentInfo from "./Custom/AppointmentInfo";
import Question from "src/scenes/PatientProfile/Forms/Question";
import ClinicalPhotoUpload from "src/shared/ClinicalPhotos/Upload";
import ClinicalPhotoView from "src/shared/ClinicalPhotos/View";
import { formatSubmissionLabel } from "src/shared/submissions";
import { performDeleteSubmission } from "./formActions";
import { forEach, map } from "lodash";
import {
  ProviderBox,
  VerifiedProviderContainer,
} from "src/scenes/PatientProfile/Forms/BeginningVisit/Referrals/ReferralForm/styles";
import { ReferralFormReferralProvider } from "src/scenes/PatientProfile/Forms/BeginningVisit/Referrals/ReferralForm/ReferralForm";
import { questionProps } from "src/scenes/PatientProfile/Forms/BeginningVisit/consts";
import { FormDecorationsContext, getFormDecorations } from "../formDecorations";

type Props = {
  patient: DetailedPatient;
  slug: string;
  submissionID: string | null;
};

export const OTHER_UUID = "00000000-0000-0000-0000-000000000000";

const Form: React.FC<Props> = ({ slug, patient, submissionID }) => {
  const { isDoctor } = useSelfRoles();
  const [externalProviderQuestion, setExternalProviderQuestion] = useState<any>(
    null
  );
  const [showExternalProviderModal, setShowExternalProviderModal] = useState(
    false
  );
  const ui = useFormUI({
    slug,
    userID: patient.id,
    submissionID,
    parentSubmissionID: null,
  });

  if (!ui || ui.fetching) {
    return <Loading />;
  }

  const {
    form,
    section,
    setSectionIndex,
    sectionQuestions,
    sectionTitlegroups,
    submission,
    submissions,
    createSubmission,
    submitSubmission,
    approveSubmission,
    saveToothData,
    saveAnswer,
    getAnswerValue,
  } = ui;

  const handleAnswerChange = (
    question,
    { newAnswerValue, currentAnswerValue }
  ) => {
    if (question.responseType === "text") {
      currentAnswerValue = (currentAnswerValue || "").trim();
      newAnswerValue = (newAnswerValue || "").trim();
    }
    const changed = newAnswerValue !== currentAnswerValue;
    if (changed) {
      saveAnswer(question, newAnswerValue);
    }
  };

  if (!submission) {
    return (
      <>
        <FormTitle className="h1">{form.name}</FormTitle>
        <NoSubmissions onNew={() => createSubmission(patient.id)} />
      </>
    );
  }

  const sectionIndex = getSections(form).findIndex(s => s === section);
  const isLastSection = sectionIndex === getSections(form).length - 1;
  const isSubmitted = !!submission.submittedAt;
  const isApproved = !!submission.approvedAt;
  const showEditButton = isSubmitted && (!isApproved || isDoctor);
  const showApproveButton = isDoctor && isSubmitted && !isApproved;

  const handleApprove = () => {
    approveSubmission();
  };

  return (
    <FormDecorationsContext.Provider value={getFormDecorations(slug)}>
      <FormTitle>
        <span className="h1">{form.name}</span>
        <div style={{ display: "flex" }}>
          {showEditButton && (
            <Popconfirm
              title="Edit submission?"
              okText="Yes"
              cancelText="No"
              placement="bottom"
              onConfirm={async () => {
                await ui.editSubmission(submission.id);
              }}
            >
              <Button kind="secondaryInvert" style={{ marginRight: "8px" }}>
                Edit
              </Button>
            </Popconfirm>
          )}
          {!isSubmitted && (
            <Popconfirm
              title="Delete submission?"
              okText="Yes"
              cancelText="No"
              placement="bottom"
              onConfirm={() =>
                performDeleteSubmission(submission.id, ui.deleteSubmission)
              }
            >
              <Button kind="secondaryInvert" style={{ marginRight: "8px" }}>
                Delete
              </Button>
            </Popconfirm>
          )}

          <Button
            kind="secondaryInvert"
            style={{ marginRight: "8px" }}
            onClick={() => createSubmission(patient.id)}
          >
            New
          </Button>
          <Dropdown
            selected={submission.id}
            options={submissions.map(s => ({
              value: s.id,
              label: formatSubmissionLabel(s),
            }))}
            onSelect={({ value }) => {
              browserHistory.push(
                `/patients/${patient.id}/forms/${form.slug}/${value}`
              );
            }}
            width="auto"
          />

          {showApproveButton && (
            <Button
              kind="primary"
              style={{ marginLeft: "8px" }}
              onClick={() => handleApprove()}
            >
              Approve
            </Button>
          )}
        </div>
      </FormTitle>
      <Wrapper>
        <SideNav>
          <div>
            {getSections(form).map((name, n) => (
              <button
                key={name}
                onClick={e => {
                  e.preventDefault();
                  setSectionIndex(n);
                }}
                className={section === name ? "active" : ""}
              >
                {name || "Appointment"}
              </button>
            ))}
          </div>
        </SideNav>

        <div>
          <Questions>
            {/* In the first section, we should always show a "Patient Review" item */}
            {sectionIndex === 0 && (
              <Section>
                <SectionTitle>Patient Review</SectionTitle>
                <PatientInformation
                  patient={patient}
                  isIndirect={slug.indexOf("indirect") > -1}
                />
              </Section>
            )}

            {/*
                    If this section is 'clinical photos' and there are no clinical photos, render the
                    clinical photo template question
                  */}
            {section === "Clinical photos" &&
              Array.from(sectionTitlegroups).length === 0 && (
                <Section>
                  <SectionTitle>Clinical photos</SectionTitle>
                  <div style={{ marginTop: "1rem" }}>
                    {isSubmitted ? (
                      <ClinicalPhotoView
                        patientID={patient.id}
                        files={submission.files || []}
                      />
                    ) : (
                      <ClinicalPhotoUpload
                        patientID={patient.id}
                        submissionID={submission.id}
                        files={submission.files || []}
                        formName={form.name}
                        editable
                      />
                    )}
                  </div>
                </Section>
              )}

            {Array.from(sectionTitlegroups).map(tg => {
              // If this is the "Before we get started" titlegroup, render that
              // custom component directly.
              if (tg === "Before we get started") {
                return (
                  <Section key={tg}>
                    <AppointmentInfo patientID={ui.submission.userID} ui={ui} />
                  </Section>
                );
              }

              const qs = sectionQuestions.filter(q => q.titlegroup === tg);
              const NOB_HILL_LAT = 37.7880317;
              const NOB_HILL_LONG = -122.4166611;
              const handleReferralProviderChange = (
                verifiedSpecialistID: string,
                state: string,
                action: string
              ) => {
                const newReferralProviderArray: Array<
                  ReferralFormReferralProvider
                > = [];

                forEach(
                  referralProviderArray,
                  (referralProvider: ReferralFormReferralProvider) => {
                    if (
                      action === "delete" &&
                      verifiedSpecialistID ===
                        referralProvider.verifiedSpecialistID
                    )
                      return;

                    newReferralProviderArray.push(referralProvider);
                  }
                );

                if (!action) {
                  newReferralProviderArray.push({
                    verifiedSpecialistID: verifiedSpecialistID,
                    state: state,
                  });
                }

                questionProps(ui, "referral_providers").handleBlur(
                  newReferralProviderArray
                );
              };
              const referralProviderArray = ui.getAnswerValue(
                "referral_providers"
              ) as Array<ReferralFormReferralProvider>;

              return (
                <Section key={tg}>
                  <SectionTitle>{tg || "Appointment"}</SectionTitle>

                  <CustomTitlegroupContent
                    patient={patient}
                    section={section}
                    titlegroup={tg || "Appointment"}
                    position="start"
                    submission={submission}
                    formSlug={slug}
                    formID={form.id}
                    saveToothData={saveToothData}
                  />

                  {externalProviderQuestion && (
                    <ExternalProviderModal
                      onClose={() => setExternalProviderQuestion(null)}
                      onChange={providerID =>
                        saveAnswer(externalProviderQuestion, providerID)
                      }
                      mapView
                      defaultFilterPreferredProviders
                    />
                  )}

                  {showExternalProviderModal && (
                    <ExternalProviderModal
                      onClose={() => setShowExternalProviderModal(false)}
                      onChange={handleReferralProviderChange}
                      mapView
                      defaultLat={NOB_HILL_LAT}
                      defaultLong={NOB_HILL_LONG}
                      defaultFilterPreferredProviders
                    />
                  )}

                  {qs.map(q => {
                    const show = calculateDisplayPredicate(
                      q,
                      ui.getAnswerValue
                    );
                    if (!show) {
                      return null;
                    }

                    // TODO: The Forms/Question/Question element expects things in snake_case,
                    // as it uses JSON from the V1 ruby API.  Once we redo BVs we can remove
                    // all REST form responses and use camelCase properly.
                    const snaked = {
                      ...q,
                      default_placeholder: "", // TODO: Get
                      question_type: q.questionType,
                      question_text: q.questionText,
                      response_type: q.responseType,
                    };

                    // Note that the Question component has no access to answers (and shouldn't ever),
                    // so we need to filter choices based on their predicates and previous answers.
                    if (
                      [
                        "dependent_choice",
                        "dependent_multichoice",
                        "dependent_multichoice_with_input",
                      ].includes(q.questionType) &&
                      q.choices
                    ) {
                      snaked.choices = calculateChoices(
                        snaked,
                        ui.getAnswerValue
                      );
                    }

                    if (snaked.slug === "referral_providers") {
                      const providerButton = (text: string, key: any) => (
                        <ProviderBox>
                          <Button
                            key={key}
                            kind="primary"
                            disabled={isSubmitted}
                            onClick={() => setShowExternalProviderModal(true)}
                          >
                            {text}
                          </Button>
                        </ProviderBox>
                      );

                      if (!referralProviderArray) {
                        return providerButton("Select a provider", slug);
                      }

                      const VerifiedProviderCards = map(
                        referralProviderArray,
                        (referralProvider: ReferralFormReferralProvider) => (
                          <VerifiedProviderCard
                            key={slug}
                            disabled={true}
                            specialistID={referralProvider.verifiedSpecialistID}
                            openModal={() => setShowExternalProviderModal(true)}
                            removeCard={handleReferralProviderChange}
                            state={referralProvider.state}
                          />
                        )
                      );
                      return (
                        <>
                          <VerifiedProviderContainer>
                            {VerifiedProviderCards}
                          </VerifiedProviderContainer>
                          {providerButton("Add a Provider", slug)}
                        </>
                      );
                    }

                    if (snaked.slug === "general_dentist_id") {
                      const providerID = getAnswerValue(q.slug);
                      if (providerID && providerID !== OTHER_UUID) {
                        return (
                          <VerifiedProviderCard
                            key={snaked.slug}
                            disabled={isSubmitted}
                            specialistID={providerID}
                            openModal={() => setExternalProviderQuestion(q)}
                          />
                        );
                      }
                      return isSubmitted ? null : (
                        <Button
                          key={snaked.slug}
                          style={{ maxWidth: 200 }}
                          onClick={() => setExternalProviderQuestion(q)}
                          kind="primary"
                        >
                          Select a provider
                        </Button>
                      );
                    }

                    const currentAnswerValue = getAnswerValue(q.slug);

                    return (
                      <React.Fragment key={q.id}>
                        <Question
                          question={snaked}
                          answer={currentAnswerValue}
                          handleChange={(newAnswerValue: any) =>
                            handleAnswerChange(q, {
                              newAnswerValue,
                              currentAnswerValue,
                            })
                          }
                          disabled={isSubmitted}
                          submissionID={submission.id}
                        />

                        <CustomQuestionContent
                          patient={patient}
                          formSlug={slug}
                          formID={form.id}
                          submission={submission}
                          question={snaked}
                          answer={getAnswerValue(q.slug)}
                          saveToothData={saveToothData}
                        />
                      </React.Fragment>
                    );
                  })}
                  <CustomTitlegroupContent
                    patient={patient}
                    section={section}
                    titlegroup={tg || "Appointment"}
                    position="end"
                    submission={submission}
                    formSlug={slug}
                    formID={form.id}
                    saveToothData={saveToothData}
                  />
                </Section>
              );
            })}

            <Section
              style={{ justifyContent: "flex-end", flexDirection: "row" }}
            >
              {/* If the last section is visible, show a submit/approve button. Else, show a next button */}

              {isLastSection &&
                ((isSubmitted && isDoctor) || isApproved ? (
                  <Button
                    kind="primary"
                    style={{ height: "40px" }}
                    onClick={() => {
                      handleApprove();
                    }}
                    disabled={isApproved}
                  >
                    {isApproved ? "Approved" : "Approve"}
                  </Button>
                ) : (
                  <Button
                    kind="primary"
                    style={{ height: "40px" }}
                    onClick={() => {
                      !isSubmitted && submitSubmission();
                    }}
                    disabled={isSubmitted}
                  >
                    {isSubmitted ? "Submitted" : "Submit"}
                  </Button>
                ))}
              {!isLastSection && (
                <Button
                  kind="primary"
                  style={{ height: "40px" }}
                  onClick={() => {
                    setSectionIndex(sectionIndex + 1);
                  }}
                >
                  {isLastSection ? "Submit" : "Continue"}
                </Button>
              )}
            </Section>
          </Questions>
        </div>
      </Wrapper>
    </FormDecorationsContext.Provider>
  );
};

export default Form;

const FormTitle = styled.div`
  height: 56px;
  padding: 0 24px 0 32px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #f9f9f9;
  border-bottom: 1px solid ${color.border};
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 200px auto;
`;

const SideNav = styled.div`
  border-right: 1px solid ${color.border};

  > div {
    position: sticky;
    top: 50px;
  }

  button {
    border: 0;
    outline: 0;
    background: #fff;
    display: flex;
    align-items: center;
    height: 24px;
    margin-top: 12px;
    margin-bottom: 12px;
    padding-left: 32px;
    border-left: 4px solid transparent;
    color: #a2a9ad;
    cursor: pointer;

    &.active {
      border-left: 4px solid ${color.primary};
      color: ${color.font};
      font-weight: bold;
    }
  }
`;

const Questions = styled.div`
  padding: 32px 32px 0 32px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;

  & + & {
    padding-top: 24px;
    border-top: 1px solid ${color.border};
  }
`;

const SectionTitle = styled.div`
  ${textStyles("xxlarge")};
  font-weight: bold;
  color: ${color.primary};
  margin-bottom: 8px;
`;
