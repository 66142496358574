import styled from "react-emotion";
import color from "src/styles/color";
import Button from "src/shared/Button";
import { Header, Body } from "./Table";

export const StyledTable = styled.table`
  background: ${color.white};
  width: 100%;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  border-bottom: 1px solid ${color.border};
`;

Header.Item = styled.th`
  height: 40px;
  padding: 0 10px;
  display: flex;
  align-items: center;
`;

export const HeaderRow = styled.tr`
  display: flex;
  width: 100%;
  &:first-child {
    border-left: 1px solid ${color.border};
  }
  &:last-child {
    border-right: 1px solid ${color.border};
  }
`;

export const Tbody = styled.tbody`
  display: block;
  overflow: auto;
  max-height: calc(100vh - 265px);
  width: 100%;
`;

Body.Row = styled.tr`
  height: 60px;
  border: 1px solid ${color.border};
  display: flex;
  width: 100%;

  & + & {
    border-top: 0 none;
  }
`;

Body.Item = styled.td`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px 0;
`;

// ETC
export const ActionButton = styled(Button)`
  font-weight: bold;
  width: 96px;
  height: 32px;
  display: flex;
  justify-content: center;
`;

export const EmptyBody = styled.div`
  width: 100%;
  height: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${color.white};
  border: 1px solid ${color.border};
  border-top: none;
`;
