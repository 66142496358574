import { useMutation } from "src/utils/http/gqlQuery";

// useCreateOrder is a helper which returns just the executor for the create
// order mutation
export const useCreateOrder = () => {
  const [, execute] = useMutation<any, { input: NewOrder }>(createOrderGql);
  return execute;
};

type NewOrder = {
  userId: string;
  items: { id: string }[]; // Product IDs
  discounts: [];
};

type CreateOrderResponse = {
  id: string;
  total: number;
  subtotal: number;
  user: {
    id: string;
  };
};

const createOrderGql = `
  mutation CreateOrder($input: NewOrder!) {
    createOrder(input: $input) {
      id total subtotal
      charges { id externalId amount }
      user { id }
    }
  }
`;
