import React from "react";
import {
  Perimeter,
  BorderLeft,
  kindMap,
  Border,
  Title,
  Subtitle,
} from "./styles";
import SolidCheck from "src/shared/Icons/SolidCheck";
import SolidWarning from "src/shared/Icons/SolidWarning";
import SolidError from "src/shared/Icons/SolidError";

const IconSelector = ({ kind }) => {
  switch (kind) {
    case "success":
      return <SolidCheck />;
    case "warning":
      return <SolidWarning />;
    case "error":
    default:
      return <SolidError />;
  }
};

const TodoBox = props => (
  <Perimeter
    onClick={props.onClick}
    theme={{ kind: props.kind, disabled: props.disabled }}
  >
    <BorderLeft theme={{ color: kindMap[props.kind] }} />
    <Border>
      <div style={{ margin: "0 16px" }}>
        <IconSelector kind={props.kind} />
      </div>
      <div style={{ marginRight: "32px" }}>{props.children}</div>
    </Border>
  </Perimeter>
);

TodoBox.Title = Title;
TodoBox.Subtitle = Subtitle;

export default TodoBox;
