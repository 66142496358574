import { FormContextType } from "../useFormsContext";

export const getSections = (f: FormContextType): string[] => {
  const sections = Array.from(f.sections);

  if (f.slug === "refinement") {
    // Intro, dental findings
    const first = sections.slice(0, 2);
    return first
      .concat(["Clinical photos"])
      .concat(sections.slice(2))
      .filter(Boolean) as string[];
  }

  if (f.slug === "outlier") {
    // Intro
    const first = sections.slice(0, 1);
    return first
      .concat(["Clinical photos"])
      .concat(sections.slice(1))
      .filter(Boolean) as string[];
  }

  return sections;
};
