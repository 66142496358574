import React from "react";
import { Wrapper } from "./styles";

export function NotFound() {
  return (
    <Wrapper>
      <img src="/not-found.png" width={500} alt="Something went wrong!" />
      <h1>Not found!</h1>
      <p>Boo. Something bad must have happened.</p>
    </Wrapper>
  );
}

export default NotFound;
