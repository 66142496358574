import React from "react";
import color from "src/styles/color";

interface Props {
  fill?: string;
}

const Consent = ({ fill = color.gray3 }: Props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4004 23.3989L17.3915 23.4348M17.3915 23.4348L20 23.0001L24 19.5001C24.3333 19.1667 24.8 18.3001 24 17.5001C23.2 16.7001 22.3333 17.1667 22 17.5001L18 21L17.3915 23.4348Z"
      stroke={fill}
      strokeWidth="1.3"
      strokeLinejoin="round"
    />
    <path
      d="M21 18L23 20"
      stroke={fill}
      strokeWidth="1.3"
      strokeLinejoin="round"
    />
    <path
      d="M20.4129 14.875C20.4129 14.4972 20.073 14.2 19.6655 14.2H12.4825C12.0751 14.2 11.7352 14.4972 11.7352 14.875C11.7352 15.2528 12.0751 15.55 12.4825 15.55H19.6655C20.073 15.55 20.4129 15.2528 20.4129 14.875Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.1"
    />
    <path
      d="M12.4825 16.7C12.0751 16.7 11.7352 16.9972 11.7352 17.375C11.7352 17.7528 12.0751 18.05 12.4825 18.05H16.845C17.2525 18.05 17.5924 17.7528 17.5924 17.375C17.5924 16.9972 17.2525 16.7 16.845 16.7H12.4825Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.1"
    />
    <path
      d="M14.1219 22.6999H11.7895C11.0428 22.6999 10.4448 22.1565 10.4448 21.4999V10.5C10.4448 9.84336 11.0428 9.29999 11.7895 9.29999H20.3634C21.1101 9.29999 21.7081 9.84336 21.7081 10.5V14.3437C21.7081 14.7215 22.048 15.0187 22.4555 15.0187C22.863 15.0187 23.2029 14.7215 23.2029 14.3437V10.5C23.2029 9.08887 21.9238 7.95 20.3634 7.95H11.7895C10.2291 7.95 8.95 9.08887 8.95 10.5V21.4999C8.95 22.911 10.2291 24.0499 11.7895 24.0499H14.1219C14.5293 24.0499 14.8692 23.7527 14.8692 23.3749C14.8692 22.9971 14.5293 22.6999 14.1219 22.6999Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.1"
    />
    <path
      d="M19.6655 11.7H12.4825C12.075 11.7 11.7352 11.9972 11.7352 12.375C11.7352 12.7528 12.075 13.05 12.4825 13.05H19.6655C20.073 13.05 20.4129 12.7528 20.4129 12.375C20.4129 11.9972 20.073 11.7 19.6655 11.7Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.1"
    />
  </svg>
);

export default Consent;
