import React from "react";

const Zip = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1342 0H4.55023C4.08852 0 3.71423 0.374286 3.71423 1.10057V31.4286C3.71423 31.6257 4.08852 32 4.55023 32H27.4497C27.9114 32 28.2857 31.6257 28.2857 31.4286V7.416C28.2857 7.01829 28.2325 6.89029 28.1388 6.796L21.4897 0.146857C21.3954 0.0531429 21.2674 0 21.1342 0Z"
      fill="#E9E9E0"
    />
    <path
      d="M21.4285 0.0862846V6.85714H28.1993L21.4285 0.0862846Z"
      fill="#D9D7CA"
    />
    <path
      d="M27.4497 32H4.55023C4.08852 32 3.71423 31.6257 3.71423 31.164V22.2857H28.2857V31.164C28.2857 31.6257 27.9114 32 27.4497 32Z"
      fill="#A2A9AD"
    />
    <path
      d="M16.2856 13.7143V12.5714H17.4285V11.4286H16.2856V10.2857H17.4285V9.14286H16.2856V8H17.4285V6.85714H16.2856V5.71429H17.4285V4.57143H16.2856V3.42857H15.1428V4.57143H13.9999V5.71429H15.1428V6.85714H13.9999V8H15.1428V9.14286H13.9999V10.2857H15.1428V11.4286H13.9999V12.5714H15.1428V13.7143H12.8571V16.5714C12.8571 18.1469 14.1388 19.4286 15.7142 19.4286C17.2896 19.4286 18.5713 18.1469 18.5713 16.5714V13.7143H16.2856ZM17.4285 16.5714C17.4285 17.5166 16.6593 18.2857 15.7142 18.2857C14.7691 18.2857 13.9999 17.5166 13.9999 16.5714V14.8571H17.4285V16.5714Z"
      fill="#A2A9AD"
    />
    <path
      d="M15.1427 17.1429H16.2856C16.601 17.1429 16.857 16.8874 16.857 16.5714C16.857 16.2554 16.601 16 16.2856 16H15.1427C14.8273 16 14.5713 16.2554 14.5713 16.5714C14.5713 16.8874 14.8273 17.1429 15.1427 17.1429Z"
      fill="#A2A9AD"
    />
    <path
      d="M10.274 30.2857H14.602V29.2617H11.626L14.594 25.7097V24.6857H10.426V25.7097H13.25L10.274 29.2617V30.2857ZM15.3364 30.2857H16.5444V24.6857H15.3364V30.2857ZM17.5864 30.2857H18.7944V28.2217H19.8904C21.0824 28.2217 21.7784 27.4777 21.7784 26.4537C21.7784 25.4217 21.0824 24.6857 19.8904 24.6857H17.5864V30.2857ZM18.7944 27.2217V25.6857H19.8184C20.3784 25.6857 20.6184 26.0457 20.6184 26.4537C20.6184 26.8617 20.3784 27.2217 19.8184 27.2217H18.7944Z"
      fill="white"
    />
  </svg>
);

export default Zip;
