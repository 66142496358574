import React, { useState } from "react";
import styled from "react-emotion";
import Modal from "src/shared/Modal";
import Box from "src/shared/Box";
import Button, { ButtonRow } from "src/shared/Button";
import { useGetAuditLogs } from "../../../scenes/EMRAdmin/queries";

const Content = styled.div`
  display: flex;
  flex-flow: column;

  height: 450px;
  width: 600px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 32px;
  padding-bottom: 10px;
  overflow-y: overlay;
`;

const DetailProperty = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

const DetailValue = styled.span`
  font-weight: normal;
  margin-left: 10px;
`;

type Props = {
  requestID: String | null;
  onClose: () => void;
};

const DetailedAuditModal: React.FC<Props> = ({ onClose, requestID }) => {
  // const [searchStaffIDs, setSearchStaffIDs] = useState<Staff[]>([] as Staff[]);
  // const [searchIDs, setSearchIDs] = useState<String[]>([] as String[]);
  const [searchRequestIDs] = useState<String[]>(
    requestID ? [requestID] : ([] as String[])
  );
  // const [searchPatientIDs, setSearchPatientIDs] = useState<User[]>([] as User[]);
  // const [searchMethods, setMethods] = useState<String[]>([] as String[]);
  // const [searchJobs, setJobs] = useState<String[]>([] as String[]);
  // const [searchServices, setServices] = useState<String[]>([] as String[]);
  // const [searchURLs, setURLs] = useState<String[]>([] as String[]);

  var filter = {
    ids: [] as String[],
    staffIds: [] as String[],
    patientIds: [] as String[],
    clinicIds: [] as String[],
    requestIds: searchRequestIDs,
    methods: [] as String[],
    urls: [] as String[],
    jobs: [] as String[],
    services: [] as String[],
  };

  const [, , data] = useGetAuditLogs(1, filter);

  const createButtons = (lastIndex, index, requestId, service) => {
    if (index === lastIndex) {
      var loghref = "";
      var tracehref = "";
      var bugsnaghref = "";
      if (
        window.location.toString().includes("https://doctor.uniformteeth.com")
      ) {
        // prod
        loghref = `https://console.cloud.google.com/logs/query;query=logName%3D%2528%22projects%2Fut-emr%2Flogs%2Femr-prod%22%20OR%20%22projects%2Fut-emr%2Flogs%2Femr-prod_errors%22%20OR%20%22projects%2Fut-emr%2Flogs%2Fgoworker-develop%22%20OR%20%22projects%2Fut-emr%2Flogs%2Fgoworker-develop_errors%22%2529%0Aseverity%3D%2528EMERGENCY%20OR%20ALERT%20OR%20CRITICAL%20OR%20ERROR%20OR%20WARNING%20OR%20NOTICE%20OR%20INFO%20OR%20DEBUG%20OR%20DEFAULT%2529%0Alabels.req_id%3D%22${requestId}%22;timeRange=P7D;summaryFields=labels%252Fhttp_method,labels%252Foperation,labels%252Ftotal_time,labels%252Fstatus,labels%252Furi:true:32:end;cursorTimestamp=2022-08-10T16:06:06Z?project=ut-emr&pli=1`;
        tracehref = `https://console.cloud.google.com/traces/list?project=ut-emr&pageState=(%22traceFilter%22:(%22chips%22:%22%255B%257B_22k_22_3A_22LABEL_3Aenvironment_22_2C_22t_22_3A10_2C_22v_22_3A_22_5C_22production_5C_22_22%257D_2C%257B_22k_22_3A_22LABEL_3Areq_id_22_2C_22t_22_3A10_2C_22v_22_3A_22_5C_22${requestId}_5C_22_22%257D%255D%22),%22traceIntervalPicker%22:(%22groupValue%22:%22P7D%22,%22customValue%22:null))&pli=1`;
        bugsnaghref =
          service === "emr"
            ? "https://app.bugsnag.com/cvstom/emr/timeline?filters[event.since]=7d&filters[error.status]=open&pivot_tab=error"
            : "https://app.bugsnag.com/cvstom/uniform-api/timeline?filters[event.since]=7d&filters[error.status]=open&sort=last_seen&pivot_tab=error";
      } else if (
        window.location
          .toString()
          .includes("https://develop.doctor.uniformteeth.com/")
      ) {
        // staging
        loghref = `https://console.cloud.google.com/logs/query;query=logName%3D%2528%22projects%2Fut-emr%2Flogs%2Femr-staging%22%20OR%20%22projects%2Fut-emr%2Flogs%2Femr-staging_errors%22%20OR%20%22projects%2Fut-emr%2Flogs%2Fgoworker-develop%22%20OR%20%22projects%2Fut-emr%2Flogs%2Fgoworker-develop_errors%22%2529%0Aseverity%3D%2528EMERGENCY%20OR%20ALERT%20OR%20CRITICAL%20OR%20ERROR%20OR%20WARNING%20OR%20NOTICE%20OR%20INFO%20OR%20DEBUG%20OR%20DEFAULT%2529%0Alabels.req_id%3D%22${requestId}%22;timeRange=P7D;summaryFields=labels%252Fhttp_method,labels%252Foperation,labels%252Ftotal_time,labels%252Fstatus,labels%252Furi:true:32:end;cursorTimestamp=2022-08-10T16:06:06Z?project=ut-emr&pli=1`;
        tracehref = `https://console.cloud.google.com/traces/list?project=ut-emr&pageState=(%22traceFilter%22:(%22chips%22:%22%255B%257B_22k_22_3A_22LABEL_3Aenvironment_22_2C_22t_22_3A10_2C_22v_22_3A_22_5C_22staging_5C_22_22%257D_2C%257B_22k_22_3A_22LABEL_3Areq_id_22_2C_22t_22_3A10_2C_22v_22_3A_22_5C_22${requestId}_5C_22_22%257D%255D%22),%22traceIntervalPicker%22:(%22groupValue%22:%22P7D%22,%22customValue%22:null))&pli=1`;
        bugsnaghref =
          service === "emr"
            ? "https://app.bugsnag.com/cvstom/emr/timeline?filters[event.since]=7d&filters[error.status]=open&pivot_tab=error"
            : "https://app.bugsnag.com/cvstom/uniform-api/timeline?filters[event.since]=7d&filters[error.status]=open&sort=last_seen&pivot_tab=error";
      } else {
        // local
        loghref = `https://console.cloud.google.com/logs/query;query=logName%3D%2528%22projects%2Fut-emr%2Flogs%2Femr-develop%22%20OR%20%22projects%2Fut-emr%2Flogs%2Femr-develop_errors%22%20OR%20%22projects%2Fut-emr%2Flogs%2Fgoworker-develop%22%20OR%20%22projects%2Fut-emr%2Flogs%2Fgoworker-develop_errors%22%2529%0Aseverity%3D%2528EMERGENCY%20OR%20ALERT%20OR%20CRITICAL%20OR%20ERROR%20OR%20WARNING%20OR%20NOTICE%20OR%20INFO%20OR%20DEBUG%20OR%20DEFAULT%2529%0Alabels.req_id%3D%22${requestId}%22;timeRange=P7D;summaryFields=labels%252Fhttp_method,labels%252Foperation,labels%252Ftotal_time,labels%252Fstatus,labels%252Furi:true:32:end;cursorTimestamp=2022-08-10T16:06:06Z?project=ut-emr&pli=1`;
        tracehref = `https://console.cloud.google.com/traces/list?project=ut-emr&pageState=(%22traceFilter%22:(%22chips%22:%22%255B%257B_22k_22_3A_22LABEL_3Aenvironment_22_2C_22t_22_3A10_2C_22v_22_3A_22_5C_22develop_5C_22_22%257D_2C%257B_22k_22_3A_22LABEL_3Areq_id_22_2C_22t_22_3A10_2C_22v_22_3A_22_5C_22${requestId}_5C_22_22%257D%255D%22),%22traceIntervalPicker%22:(%22groupValue%22:%22P7D%22,%22customValue%22:null))&pli=1`;
        bugsnaghref =
          service === "emr"
            ? "https://app.bugsnag.com/cvstom/emr/timeline?filters[event.since]=7d&filters[error.status]=open&pivot_tab=error"
            : "https://app.bugsnag.com/cvstom/uniform-api/timeline?filters[event.since]=7d&filters[error.status]=open&sort=last_seen&pivot_tab=error";
      }

      return (
        <ButtonRow position="center">
          <Button kind="default" style={{ background: "aliceblue" }}>
            <a
              style={{ color: "black" }}
              target="_blank"
              rel="noopener noreferrer"
              href={loghref}
            >
              GCP Log
            </a>
          </Button>
          <Button kind="default" style={{ background: "aliceblue" }}>
            <a
              style={{ color: "black" }}
              target="_blank"
              rel="noopener noreferrer"
              href={tracehref}
            >
              GCP Trace
            </a>
          </Button>
          <Button kind="default" style={{ background: "aliceblue" }}>
            <a
              style={{ color: "black" }}
              target="_blank"
              rel="noopener noreferrer"
              href={bugsnaghref}
            >
              Bug Snags
            </a>
          </Button>
          <Button kind="default" onClick={() => onClose()}>
            Close
          </Button>
        </ButtonRow>
      );
    }

    return null;
  };

  return (
    <Modal onClose={onClose}>
      <Box title={`Request ID ${requestID}`}>
        {data && data.auditLogs.length
          ? data.auditLogs.reverse().map((log, i) => {
              return (
                <Content key={log.id}>
                  <DetailProperty>
                    ID: <DetailValue>{log.id}</DetailValue>
                  </DetailProperty>
                  <DetailProperty>
                    RequestID: <DetailValue>{log.requestId}</DetailValue>
                  </DetailProperty>
                  <DetailProperty>
                    HTTP Method: <DetailValue>{log.method}</DetailValue>
                  </DetailProperty>
                  <DetailProperty>
                    URL: <DetailValue>{log.url}</DetailValue>
                  </DetailProperty>
                  <DetailProperty>
                    Job: <DetailValue>{log.job}</DetailValue>
                  </DetailProperty>
                  <DetailProperty>
                    Service: <DetailValue>{log.service}</DetailValue>
                  </DetailProperty>
                  <DetailProperty>
                    Created At: <DetailValue>{log.createdAt}</DetailValue>
                  </DetailProperty>
                  {log.staff ? (
                    <DetailProperty>
                      Staff:{" "}
                      <DetailValue>
                        {log.staff.firstName} {log.staff.lastName}
                      </DetailValue>
                    </DetailProperty>
                  ) : null}
                  {log.patient ? (
                    <DetailProperty>
                      Patient:{" "}
                      <DetailValue>
                        {log.patient.preferredName
                          ? log.patient.preferredName
                          : log.patient.firstName}{" "}
                        {log.patient.lastName}
                      </DetailValue>
                    </DetailProperty>
                  ) : null}
                  --------------------------------------------------------------------
                  {createButtons(
                    data.auditLogs.length - 1,
                    i,
                    log.requestId,
                    log.service
                  )}
                </Content>
              );
            })
          : null}
      </Box>
    </Modal>
  );
};

export default DetailedAuditModal;
