import React from "react";
import styled from "react-emotion";
import dayjs from "dayjs";
import { Icon, message } from "antd";
import { InternalNote } from "src/types/api";

interface Props {
  note: InternalNote;
  updateInternalNote?(InternalNote): Promise<any> | undefined;
  authorName: string | undefined;
  unpinnable?: boolean | undefined;
}

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 30px 8rem;

  p {
    font-size: 0.8rem;
    padding: 0.5rem;
    margin: 0;
    color: #777;
  }
`;

const Top = styled("div")`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  i {
    cursor: pointer;
  }
`;

const Content = styled("div")`
  flex: 1;
  padding: 1rem;
  background: #f8f8f8;
  border: 1px solid #eee;
  white-space: pre-wrap;
`;

// TODO: Pinning.
const Note = ({ note, authorName, updateInternalNote, unpinnable }: Props) => {
  const togglePin = async () => {
    if (!updateInternalNote) {
      return;
    }

    const verb = note.pinned_at ? "Unpinning" : "Pinning";
    const hide = message.loading(`${verb} note`);
    await updateInternalNote(
      Object.assign({}, note, {
        pinned_at: note.pinned_at ? null : new Date().toISOString(),
      })
    );
    hide();
  };

  return (
    <Wrapper>
      <Top>
        <p>
          {note.pinned_at ? <b>Pinned internal note</b> : "Internal note"}{" "}
          {authorName && `by ${authorName}`} at{" "}
          {dayjs(note.created_at).format("MM/DD/YYYY h:mma")}
        </p>
        <div>
          {!unpinnable && (
            <Icon
              type="pushpin"
              theme={note.pinned_at ? "filled" : "outlined"}
              onClick={togglePin}
            />
          )}
        </div>
      </Top>
      <Content>{note.text}</Content>
    </Wrapper>
  );
};

export default Note;
