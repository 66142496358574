// @flow
import { useEffect } from "react";
import { Action, Thunk, thunk, action } from "easy-peasy";
import { useStore, useActions } from "../hooks";
import { request } from "src/shared/util";
import { ApiRequestState } from "src/types/local";

export interface IssueState {
  issues: Array<object>;
  fetchIssueRequest: ApiRequestState;
  trayDefects: object;
  createDefectRequest: ApiRequestState;

  fetchIssues: Thunk<IssueState, null>;
  setFetchIssueRequest: Action<IssueState, ApiRequestState>;
  setIssues: Action<IssueState, Array<object>>;
}

// issues represents the entire issue state, including:
// - Data
// - Actions
// - Side effects
export const issues: IssueState = {
  // issues represent issue templates that you file as a "defect" or a
  // single instance issue
  issues: [],
  fetchIssueRequest: { loading: false, error: false },

  // trayDefects represents tray defects that have been created or loaded
  // for given builds.
  trayDefects: {},
  createDefectRequest: { loading: false, error: false },

  // Actions:  these modify state
  setFetchIssueRequest: action((state, payload) => {
    state.fetchIssueRequest = payload;
  }),
  setIssues: action((state, payload) => {
    state.issues = payload;
  }),

  // Async functions perform requests which have side effects, such as
  // fetching issues.
  fetchIssues: thunk(async (actions, payload) => {
    actions.setFetchIssueRequest({ loading: true, error: false });

    const result = await request(`/api/v1/issues`);
    if (Array.isArray(result)) {
      actions.setIssues(result);
      actions.setFetchIssueRequest({ loading: false, error: false });
      return;
    }
    actions.setFetchIssueRequest({ loading: false, error: true });
  }),
};

export const useIssues = () => {
  const issueState = useStore(store => store.issues);
  // Get the bound action which can trigger updates to the state above.
  const fetchIssues = useActions(actions => {
    return actions.issues.fetchIssues;
  });

  useEffect(() => {
    fetchIssues(null);
  }, [fetchIssues]);

  return [issueState.issues, issueState.fetchIssueRequest];
};

interface CreateDefectArgs {
  trayIds: Array<string>;
  issueId: string;
  note: string;
}

export const createDefects = async ({
  trayIds,
  issueId,
  note,
}: CreateDefectArgs) => {
  const body = {
    tray_defects: trayIds.map(id => ({
      tray_build_id: id,
      issue_id: issueId,
      defect_notes: note,
    })),
  };

  const result = await request(`/api/v1/manufacturing/tray_defects`, {
    method: "POST",
    body: JSON.stringify(body),
  });

  return result;
};

export const resolveDefects = async ({
  defectIds,
  note,
}: {
  defectIds: Array<string>;
  note: string;
}) => {
  const body = {
    tray_defects: defectIds.map(id => ({
      id,
      resolution_notes: note,
    })),
  };

  const result = await request(`/api/v1/manufacturing/tray_defects`, {
    method: "PATCH",
    body: JSON.stringify(body),
  });

  return result;
};
