import React, { useEffect } from "react";
import { get } from "lodash";
import orderBy from "lodash/orderBy";
import { pluralize } from "src/shared/util";
import { titleCase } from "src/shared/util";
import Skeleton from "src/shared/Skeleton";
import { Treatment, Patient } from "src/types/gql";
import { useForms } from "src/scenes/PatientProfile/Forms/GQLForms/queries";
import { useSubmissionContext } from "src/state/submissions/useSubmissions";
import { useOrders } from "src/scenes/Patient/BillingV2/Orders/query";
import { Perimeter, Row, Col, Label, Value, Line } from "./styles";

const CHIEF_CONCERN_QUESTION = "what_would_you_like_to_change";
const TREATMENT_TYPE_QUESTION = "treatment_type";

const getTrayNumberStatus = (currentTrayNumber: number | undefined) => {
  return currentTrayNumber ? `Tray ${currentTrayNumber}` : " -- ";
};

const getDoctorLabel = doctor => {
  return doctor ? `Dr. ${doctor.name}` : " -- ";
};

const format = str => {
  const s = JSON.parse(str);
  return Array.isArray(s) ? s.join(", ") : s;
};

type Props = {
  treatment: Treatment | null;
  loadingTreatment: boolean;
  patient: Patient;
};

const TreatmentInfo = (props: Props) => {
  const { treatment, loadingTreatment, patient } = props;
  const ctx = useSubmissionContext();

  const [{ data: formData }] = useForms();
  const [fetchingOrders, , orders] = useOrders(props.patient.id);
  let paidTreatmentLevel = "";
  if (orders) {
    orderBy(orders, ["createdAt"], ["desc"]).find(({ status, orderItems }) => {
      if (status === "draft" || status === "closed") {
        return false;
      }
      const orderItem = orderItems.find(
        ({ product }) => product.type === "treatment"
      );
      if (orderItem) {
        paidTreatmentLevel = orderItem.product.name;
        return true;
      }
      return false;
    });
  }

  const beginningVisitForm = formData
    ? formData.forms.find(f => f.slug === "beginning_visit")
    : null;
  const treatmentOptionForm = formData
    ? formData.forms.find(f => f.slug === "treatment_option")
    : null;

  // When answering questions on a form it will trigger refetching submissions
  // because of how urql caches.  Prevent this.
  useEffect(() => {
    if (beginningVisitForm) {
      ctx.helpers.fetchSubmissions(beginningVisitForm.id, patient.id);
    }

    if (treatmentOptionForm) {
      ctx.helpers.fetchSubmissions(treatmentOptionForm.id, patient.id);
    }

    // eslint-disable-next-line
  }, [beginningVisitForm, treatmentOptionForm, patient.id]);

  const beginningVisitSubmissions = beginningVisitForm
    ? orderBy(
        ctx.helpers.useSubmissions(beginningVisitForm.id, patient.id),
        ["approvedAt", "submittedAt", "createdAt"],
        ["desc"]
      )
    : [];

  const latestBeginningVisitSubmission = beginningVisitSubmissions.find(
    s => !!s.approvedAt
  );

  let treatmentOptionsSummary = "";
  if (latestBeginningVisitSubmission) {
    const treatmentOptionSubmissions = treatmentOptionForm
      ? ctx.helpers
          .useSubmissions(treatmentOptionForm.id, patient.id)
          .filter(
            ({ parentID }) => parentID === latestBeginningVisitSubmission.id
          )
      : [];
    const levels = treatmentOptionSubmissions
      .map(({ answers }) => {
        const ans = answers.find(
          ({ questionSlug }) => questionSlug === TREATMENT_TYPE_QUESTION
        );
        return ans && ans.answer ? format(ans.answer) : null;
      })
      .filter(it => !!it);
    const count = levels.length;
    if (count > 0) {
      treatmentOptionsSummary = `${count} ${pluralize(
        "Option",
        count
      )}: ${levels.map(titleCase).join(", ")}`;
    }
  }

  const patientChiefConcern = latestBeginningVisitSubmission
    ? latestBeginningVisitSubmission.answers.find(
        ({ questionSlug }) => questionSlug === CHIEF_CONCERN_QUESTION
      )
    : null;

  const homeClinicName =
    patient && patient.homeClinic
      ? `${get(patient, "homeClinic.name")}, ${get(patient, "homeClinic.city")}`
      : " -- ";

  if (!patient || loadingTreatment) {
    return (
      <Perimeter>
        <Row>
          <Skeleton width="160px" height="12px" />
        </Row>
      </Perimeter>
    );
  }

  return (
    <Perimeter>
      <Row>
        <Col>
          <Label>Concern:</Label>
          <Value>
            {patientChiefConcern && patientChiefConcern.answer
              ? format(patientChiefConcern.answer)
              : " -- "}
          </Value>
        </Col>
        <Line />
        <Col>
          <Label>Treatment:</Label>
          <Value>
            {fetchingOrders ? (
              " -- "
            ) : (
              <div>
                {paidTreatmentLevel || treatmentOptionsSummary || " -- "}
              </div>
            )}
          </Value>
        </Col>
        <Line />
        <Col>
          <Label>Tray:</Label>
          <Value>
            {treatment
              ? getTrayNumberStatus(treatment.currentTrayNumber)
              : " -- "}
          </Value>
        </Col>
        <Line />
        <Col>
          <Label>Doctor:</Label>
          <Value>
            {treatment ? getDoctorLabel(treatment.beginningDoctor) : " -- "}
          </Value>
        </Col>
        <Line />
        <Col>
          <Label>Clinic:</Label>
          <Value>{homeClinicName}</Value>
        </Col>
      </Row>
    </Perimeter>
  );
};

export default TreatmentInfo;
