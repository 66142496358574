import cookie from "src/utils/CUSTOM-cookie";
import { get, includes, lowerCase } from "lodash";

// important this is a fail safe incase we try to load the patient list and fails.
// and auth token can expire and not give us the patient list while still passing self
import { PATIENT_LIST_LOAD_FAIL } from "./patient-list";

const LOAD_SUCCESS = "login/LOAD_SUCCESS";
const LOAD_FAIL = "login/LOAD_FAIL";

const RESET_PASSWORD_API = "/api/v1/doctors/reset_password";

const initialState = {
  authToken: "",
  email: "",
  id: "",
  roleType: "",
};
const generateAuthCookieWithExpiration = authToken => {
  const twoWeeksInMs = 12096e5;
  const expirationDate = new Date(+new Date() + twoWeeksInMs);
  // IE does not support max age. must use expires
  // return `auth_token=${authToken}; expires=${expirationDate.toUTCString()}; path=/`

  return cookie.serialize("auth_token", authToken, {
    expires: expirationDate,
    path: "/",
  });
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS:
      document.cookie = generateAuthCookieWithExpiration(
        action.result.auth_token
      );
      window.localStorage.setItem("auth_token", action.result.auth_token);
      return {
        ...state,
        authToken: action.result.auth_token,
        name: action.result.name,
        email: action.result.email,
        roleType: action.result.role_type,
        id: action.result.id,
        error: null,
      };
    case LOAD_FAIL:
      return {
        ...state,
        // ...initialState,
        error: action.error,
      };
    case PATIENT_LIST_LOAD_FAIL:
      /**
       * this is a fail safe for the case where /self passes and renders a blank page with no data.
       * We load patient list on the client side for the top bar. If this call returns a 401 then we need to
       * re-authorize the call.
       **/
      if (includes(get(action, "error.error"), "not auth")) {
        return {
          ...state,
          authToken: undefined,
          error: action.error,
        };
      }
      return state;
    default:
      if (
        action.error &&
        includes(lowerCase(action.error.error), "authorized")
      ) {
        return {
          ...state,
          authToken: undefined,
          error: action.error,
        };
      }
      return state;
  }
}

export function resetPassword({ oldPassword, newPassword }) {
  return {
    types: [null, null, LOAD_FAIL],
    promise: client =>
      client.put(RESET_PASSWORD_API, {
        data: { old_password: oldPassword, new_password: newPassword },
      }),
  };
}

export function loadSuccess(result) {
  return {
    type: LOAD_SUCCESS,
    result,
  };
}
