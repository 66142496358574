import React, { useEffect, useMemo, useState } from "react";
import styled from "react-emotion";
import { Checkbox, Card } from "antd";
import { useChatForPatient } from "src/state/hooks/chat";
import { useFilesForPatient } from "src/state/hooks/files";
import {
  InternalNotesEnhancer,
  Props as NoteProps,
} from "src/state/InternalNote";
import { AutoloadStaff, Props as StaffProps } from "src/state/Staff";
import {
  ClinicalRecord,
  DetailedPatient,
  InternalNote,
  File,
  Message,
  // Staff,
} from "src/types/api";
import Item from "./Item";
import FileItem from "./File";
import Note from "./InternalNote";
// TODO:  Convert this from old > new
import Actions from "src/containers/treatment/components/doctor-input/doctor-input";
import color from "src/styles/color";

interface PassedProps {
  patient: DetailedPatient;
  clinicalRecord: ClinicalRecord;
}

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const ChatStream = styled("div")`
  background: white;
  border: 1px solid ${color.border};
`;

const StyledCard = styled(Card)`
  border: 1px solid ${color.border};
`;

const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-wrap: wrap;
`;

const StyledCheckbox = styled(Checkbox)`
  flex: 0 1 auto;
  margin: 0 2rem 0 0 !important;
`;

const StyledFileBox = styled("div")`
  margin-left: 8rem;
`;

type StreamItem = Message | File | InternalNote;

type Stream = Array<StreamItem>;

const isMessage = (i: any): i is Message => {
  return i.sent_at !== undefined;
};

const isFile = (i: any): i is File => {
  return typeof i.file === "object";
};

const isInternalNote = (i: any): i is InternalNote => {
  return i.text !== undefined;
};

const messageToNote = (m: Message): InternalNote => {
  return {
    id: m.id,
    text: m.body,
    author_id: m.author_id || "",
    author: {
      id: m.author_id || "",
      name: m.author_name,
    },
    pinned_at: null,
    parent_id: null,
    model_type: "",
    model_id: "",
    created_at: m.sent_at,
  };
};

export const Chat = (props: PassedProps & StaffProps & NoteProps) => {
  const { patient, actions } = props;
  const messages = useChatForPatient(patient.id);
  const files = useFilesForPatient(patient.id);

  const streamTypes = ["sms", "message", "email", "checkin", "files", "notes"];
  const [filters, setFilters] = useState(streamTypes);

  useEffect(() => {
    if (props.clinicalRecord) {
      props.actions.getInternalNotes({
        model_id: props.clinicalRecord.id,
        model_type: "ClinicalRecord",
      });
    }
  }, [props.actions, props.clinicalRecord, props.patient.id]);

  // Transform the array of authors into a map so that we can do
  // constant time lookups for internal note authors
  // const authors: { [id: string]: Staff } = useMemo(() => {
  //   const map = {};
  //   props.staff.forEach(s => {
  //     map[s.id] = s;
  //   });
  //   return map;
  // }, [props.staff]);

  // Merge internal notes, messages, and file uploads into a single sorted
  // array for traversing and rendering them in reverse-chronological order.
  //
  // This is memoized for speed.
  const stream = useMemo(() => {
    const patientNotes = props.notes.filter(
      n => n.model_id === (props.clinicalRecord && props.clinicalRecord.id)
    );
    // Filter all pinned notes out of the stream so we render these first.
    const pinnedNotes = patientNotes
      .filter(n => !!n.pinned_at)
      .sort((a, b) => (a.created_at < b.created_at ? 1 : -1));

    const unpinnedNotes = patientNotes.filter(n => !n.pinned_at);

    const data: Stream = ([] as Stream).concat(unpinnedNotes, messages, files);
    data.sort((a, b) => {
      let aSort = a.created_at;
      let bSort = b.created_at;
      if (isMessage(a)) {
        aSort = a.sent_at;
      }
      if (isMessage(b)) {
        bSort = b.sent_at;
      }
      return aSort < bSort ? 1 : -1;
    });

    return (pinnedNotes as Stream).concat(data);
  }, [props.notes, props.clinicalRecord, messages, files]);

  return (
    <Wrapper>
      <Actions patient={patient} clinicalRecord={props.clinicalRecord} />
      <StyledCard title="Filters">
        <Checkbox
          indeterminate={
            filters.length !== streamTypes.length && filters.length > 0
          }
          onChange={e =>
            e.target.checked
              ? setFilters(streamTypes)
              : setFilters([] as string[])
          }
          checked={filters.length === streamTypes.length}
        >
          All
        </Checkbox>
        <CheckboxGroup
          onChange={values => setFilters(values as string[])}
          defaultValue={streamTypes}
          value={filters}
        >
          {streamTypes.map(streamType => {
            return (
              <StyledCheckbox
                key={streamType}
                value={streamType}
                checked={filters.includes(streamType)}
              >
                {streamType}
              </StyledCheckbox>
            );
          })}
        </CheckboxGroup>
      </StyledCard>

      <ChatStream>
        {stream.map(item => {
          if (
            isMessage(item) &&
            filters.includes(item.comment_type) &&
            item.is_public
          ) {
            return (
              <Item message={item} isPatient={item.author_id === patient.id} />
            );
          }
          if (filters.includes("notes") && isMessage(item) && !item.is_public) {
            return (
              <Note
                note={messageToNote(item)}
                authorName={item.author_name}
                unpinnable
              />
            );
          }
          if (filters.includes("notes") && isInternalNote(item)) {
            return (
              <Note
                note={item}
                authorName={item.author.name}
                updateInternalNote={actions.updateInternalNote}
              />
            );
          }
          if (isFile(item) && filters.includes("files")) {
            return (
              <StyledFileBox>
                <FileItem file={item} />
              </StyledFileBox>
            );
          }
          return null;
        })}
      </ChatStream>
    </Wrapper>
  );
};

export default AutoloadStaff(InternalNotesEnhancer(Chat));
