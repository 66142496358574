import React from "react";
import color from "src/styles/color";

interface Props {
  fill?: string;
}

const Edit = ({ fill = color.gray3 }: Props) => (
  <svg
    width="15"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1875 0.9375H7.41968C7.2262 0.391846 6.70483 0 6.09375 0C5.48267 0 4.9613 0.391846 4.76782 0.9375H0V16H12.1875V0.9375ZM3.75 1.875H5.625V1.40625C5.625 1.14783 5.83533 0.9375 6.09375 0.9375C6.35217 0.9375 6.5625 1.14783 6.5625 1.40625V1.875H8.4375V2.8125H3.75V1.875ZM11.25 15.0625H0.9375V1.875H2.8125V3.75H9.375V1.875H11.25V15.0625Z"
      fill={fill}
    />
    <path
      d="M8.51758 5.02451L6.33472 7.20749L2.65063 10.8916L1.65625 13.8746L4.6394 12.8802L10.5063 7.01328L8.51758 5.02451ZM4.13293 12.0609L3.13855 12.3923L3.46997 11.3979L6.99756 7.87033L7.66052 8.53329L4.13293 12.0609ZM7.79309 7.34006L7.66052 7.20749L8.51758 6.35031L9.18042 7.01328L8.32336 7.87033L7.79309 7.34006Z"
      fill={fill}
    />
  </svg>
);

export default Edit;
