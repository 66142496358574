import { V, S } from "src/shared-styles";
export default {
  V,
  S,
  paymentContainer: isSelected => ({
    position: "relative",
    display: "flex",
    borderBottom: isSelected ? `3px solid ${V.colors.primary}` : V.border,
    marginBottom: V.spacing,
    paddingBottom: V.spacingSmall,
    marginTop: V.spacingSmall,
    marginLeft: 30,
  }),
  paymentDetails: isSelected => ({
    textAlign: "left",
    flex: 1,
    lineHeight: "36px",
    fontWeight: isSelected ? V.fontWeightHeavy : V.fontWeightNormal,
  }),
  verified: {
    position: "absolute",
    left: -30,
    fontSize: 30,
    zIndex: 1,
    top: 0,
    color: "green",
  },
  stripeContainer: {
    marginLeft: 30,
    position: "relative",
  },
};
