import styled, { css } from "react-emotion";

import color from "src/styles/color";

export const POPOVER_STYLES = `
  border: 0.5px solid ${color.border};
  box-shadow: 0px 2px 2px 0px #0000003d, 0px 0px 2px 0px #0000001f;
  background: white;
`;

export const dropdownButtonCss = css`
  padding: 2px 8px;
  line-height: 20px;

  .anticon {
    margin-left: 4px !important;
  }

  &:focus,
  &:hover {
    border-color: ${color.border};
    background: ${color.background3};
`;

export const Menu = styled.div`
  ${POPOVER_STYLES}
  padding-bottom: 10px;
`;

export const Title = styled.h3`
  margin: 16px 16px 11px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${color.gray2};

  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.003em;
`;

export const SubTitle = styled.h4`
  margin: 16px 16px 3px;

  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.003em;
`;
