import * as React from "react";
import styled, { css } from "react-emotion";
import { pick } from "lodash";
import { AppointmentType } from "src/types/gql";
import { extractChildrenByType } from "src/shared/subcomponents";
import textStyles from "src/styles/textStyles";
import color from "src/styles/color";
import InProgress from "src/shared/Icons/InProgress";
import Completed from "src/shared/Icons/Completed";
import NoShow from "src/shared/Icons/NoShow";
import stripe from "./stripe.svg";
import { EntryWithOffset } from "./types";
import useSearchFiltersContext from "../../OptimizedScheduling/useSearchFiltersContext";

export const AppointmentContainer = styled.div`
  cursor: pointer;
  z-index: 2;
  position: absolute;
  background: #b0d4d2;
  border-radius: 4px;
  border: 1px solid ${color.white};
  transition: all 0.1s;

  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme: { isStaged } }) =>
    isStaged &&
    css`
      opacity: 0.5;
    `}
`;

export const DoctorContainer = styled.div`
  cursor: pointer;
  z-index: 3;
  position: absolute;
  background: ${color.gray5};
  border-radius: 4px;
  border: 1px solid ${color.white};
  transition: all 0.1s;
  ${({ theme: { isStaged } }) =>
    isStaged &&
    css`
      opacity: 0.5;
    `}
`;

const Text = styled.div`
  cursor: pointer;
  z-index: 5;
  position: absolute;
  padding-left: 8px;
  ${textStyles("medium")};
  padding-top: 4px;
  pointer-events: none;
  ${({ theme: { isStaged } }) =>
    isStaged &&
    css`
      opacity: 0.5;
    `}
`;

const SvgStripe = styled.div`
  cursor: pointer;
  border-radius: 4px;
  z-index: 4;
  transition: all 0.1s;
  width: 100%;
  height: 100%;
  background: url(${stripe});
  position: absolute;
`;

const NoStripe = styled.div`
  cursor: pointer;
  border-radius: 4px;
  z-index: 4;
  transition: all 0.1s;
  width: 100%;
  height: 100%;
  position: absolute;
`;

interface Props {
  appointment: EntryWithOffset;
  stripe?: "appointment" | "doctor";
  unbooked?: boolean;
  width: string;
  left: string;
  appointmentStyle: {
    height: string;
    top: string;
  };
  doctorStyle: {
    height: string;
    top: string;
  };
  children: React.ReactElement | React.ReactElement[];
  viewDoctorOnly?: boolean;
  onClick?: any;
  onMouseEnter?: any;
  onMouseMove?: any;
  onMouseLeave?: any;
  isStaged?: boolean;
  status?: "inprogress" | "noshow" | "completed" | undefined;

  selected?: boolean;
}

const colorMap = ({
  appointmentType,
}: {
  appointmentType: AppointmentType;
}) => {
  let appointmentColor;
  let doctorColor;

  switch (appointmentType.name) {
    case "beginning":
      appointmentColor = "#D9F5C9";
      doctorColor = "#65B139";
      break;
    case "followup":
      appointmentColor = "#D6EFFB";
      doctorColor = "#3E8FB6";
      break;
    case "staff_only":
      // appointmentColor = "#EBE3FC";
      appointmentColor = "#6746B0";
      doctorColor = "#6746B0";
      break;
    case "misc_short":
    case "misc_long":
      appointmentColor = "#FCEABC";
      doctorColor = "#DFA50D";
      break;
  }

  return {
    appointmentColor,
    doctorColor,
  };
};

const Slot = (props: Props) => {
  const {
    appointment,
    width,
    left,
    appointmentStyle,
    doctorStyle,
    stripe,
    viewDoctorOnly,
    isStaged,
    status,
    unbooked,
    selected,
  } = props;

  const stripeDimension =
    stripe === "appointment"
      ? appointmentStyle
      : stripe === "doctor"
      ? doctorStyle
      : appointmentStyle;

  const stripeStyle = {
    width,
    left,
    ...stripeDimension,
  };

  const stripeProps = pick(props, [
    "onClick",
    "onMouseEnter",
    "onMouseLeave",
    "onMouseMove",
  ]);

  const { appointmentColor, doctorColor } = colorMap(appointment);

  const finalAppointmentStyle = {
    width,
    left,
    ...appointmentStyle,
  };

  const finalDoctorStyle = {
    width,
    left,
    ...doctorStyle,
  };

  const { isSearchHit } = useSearchFiltersContext();
  const displayModeStyle = {
    filter: "grayscale(0)",
  };
  if (!isSearchHit(appointment)) {
    displayModeStyle.filter = "grayscale(1)";
  }

  return (
    <>
      {stripe ? (
        <SvgStripe css={stripeStyle} {...stripeProps} />
      ) : (
        <NoStripe css={stripeStyle} {...stripeProps} />
      )}
      {!viewDoctorOnly && (
        <>
          <AppointmentContainer
            css={{
              ...finalAppointmentStyle,
              background: appointmentColor,
              ...displayModeStyle,
            }}
            theme={{ isStaged }}
            style={{
              opacity: unbooked ? 0.7 : 1,
              boxShadow: selected ? `0 0 1px 3px ${doctorColor}` : `none`,
            }}
          >
            <div css={{ position: "absolute", top: 8, right: 8 }}>
              {status === "inprogress" ? (
                <InProgress />
              ) : status === "completed" ? (
                <Completed />
              ) : (
                status === "noshow" && <NoShow />
              )}
            </div>
          </AppointmentContainer>
          <Text css={finalAppointmentStyle} theme={{ isStaged }}>
            {extractChildrenByType(AppointmentContainer, props.children)}
          </Text>
        </>
      )}
      <DoctorContainer
        css={{
          ...finalDoctorStyle,
          background: doctorColor,
          ...displayModeStyle,
        }}
        style={{
          opacity: unbooked ? 0.7 : 1,
          boxShadow:
            selected && viewDoctorOnly ? `0 0 1px 3px ${doctorColor}` : `none`,
        }}
        theme={{ isStaged }}
      />
      <Text css={finalDoctorStyle} theme={{ isStaged }}>
        {extractChildrenByType(DoctorContainer, props.children)}
      </Text>
    </>
  );
};

Slot.AppointmentContainer = AppointmentContainer;
Slot.DoctorContainer = DoctorContainer;

export default Slot;
