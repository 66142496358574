import { css } from "react-emotion";

export const arrow = css`
  width: 8px;
  height: 16px;

  transition: 0.2s ease;
`;

export const expandedArrow = css`
  ${arrow}

  transform: rotate(90deg);
  transition: 0.2s ease;
`;
