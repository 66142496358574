// @flow
import React, { useState } from "react";
import styled from "react-emotion";
import { Popover, Switch, Button } from "antd";
import { visibilityToggle } from "./util";

const Visibility = ({ onChange, visible }) => {
  const onToggle = stage => {
    const idx = visible.findIndex(s => s === stage);
    if (idx > -1) {
      // The stage was visible, so remove it.
      return onChange(visible.filter(s => s !== stage));
    }
    // Add the stage to visible
    return onChange(visible.concat([stage]));
  };

  const [showing, setShowing] = useState(false);

  const content = (
    <Wrapper>
      {Object.keys(visibilityToggle).map(stage => (
        <div key={stage}>
          <Switch
            size="small"
            key={stage}
            checked={visible.indexOf(stage) > -1}
            onChange={() => onToggle(stage)}
          />
          <span>{visibilityToggle[stage]}</span>
        </div>
      ))}
    </Wrapper>
  );

  return (
    <Wrapper>
      <Popover
        content={content}
        title="Show/hide stages"
        trigger="click"
        visible={showing}
        onVisibleChange={setShowing}
      >
        <Button type="secondary">Toggle visibility</Button>
      </Popover>
    </Wrapper>
  );
};

export default Visibility;

const Wrapper = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin: 0 0 1rem 0;

  > div {
    display: inline;
  }

  span {
    display: inline-block;
    margin: 0 1rem 0 0.3rem;
  }
`;
