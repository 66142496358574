import { createTypedHooks } from "easy-peasy";
import { AppointmentState } from "./hooks/appointments";
import { ClinicalRecordState } from "./hooks/clinicalRecords";
import { ProducerIdentifierState } from "./hooks/producerIdentifiers";
import { IssueState } from "./hooks/issues";
import { PatientState } from "./hooks/patients";
import { AddressState } from "./hooks/addresses";
import { ChatState } from "./hooks/chat";
import { FileState } from "./hooks/files";
import { ClinicState } from "./hooks/clinics";
import { TreatmentState } from "./hooks/treatments";

export interface StoreModel {
  appointments: AppointmentState;
  producerIdentifiers: ProducerIdentifierState;
  issues: IssueState;
  clinicalRecords: ClinicalRecordState;
  patients: PatientState;
  addresses: AddressState;
  chat: ChatState;
  files: FileState;
  clinics: ClinicState;
  treatments: TreatmentState;
}

export const { useActions, useStore, useDispatch } = createTypedHooks<
  StoreModel
>();
