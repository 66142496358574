import { find, each } from "lodash";
import { QUESTION_FORM } from "src/config/event-type";
import { FORM_NAME } from "../config/treatment-forms";

/**
 * grab the data from any question form.
 * only RRX & outcome is not a question form. That is a issuelistForm
 * @param eventStream
 * @param formName
 * @param dataKey
 * @returns {*}
 */
const questionFormParser = (
  eventStream: Array<{}>,
  formName: string,
  dataKey: string
) => {
  const mostRecentForm = find(eventStream, { formName, type: QUESTION_FORM });
  if (!mostRecentForm) return undefined;
  const questionList = mostRecentForm.questionList;
  const question = find(questionList, { dataKey });
  if (!question) return undefined;
  return question.answer || question.answerList;
};

/**
 * grab the data key from issueListForm. (rrx and outcome)
 * @param event
 * @param dataKey
 * @returns {*}
 */

const issueListFormParser = (event: {}, dataKey: string) => {
  if (!event) {
    return false;
  }

  const issueList = event.issueList;
  let answer = null;
  each(issueList, issue => {
    const questionList = issue.questionList;
    const question = find(questionList, { dataKey });
    if (question) {
      answer =
        question.answer || (question.answerList && question.answerList[0]);
      return false; // break
    }
  });
  return answer;
};

const getEstimateDuration = (staffSubmittedData: Array<{}>) => {
  const fromPIV = questionFormParser(staffSubmittedData, "piv", "duration");
  const rrxForm = find(staffSubmittedData, { formName: FORM_NAME.RRX });
  const fromRRX = issueListFormParser(rrxForm, "duration");
  const fromEstimate = questionFormParser(
    staffSubmittedData,
    "estimate",
    "duration"
  );
  return fromPIV || fromRRX || fromEstimate;
};

const getTreatmentType = (staffSubmittedData: Array<{}>) => {
  const fromPIV = questionFormParser(
    staffSubmittedData,
    "piv",
    "treatmentType"
  );
  const rrxForm = find(staffSubmittedData, { formName: FORM_NAME.RRX });
  const fromRRX = issueListFormParser(rrxForm, "treatmentType");
  const fromEstimate = questionFormParser(
    staffSubmittedData,
    "estimate",
    "treatmentType"
  );
  return fromPIV || fromRRX || fromEstimate;
};

export {
  getEstimateDuration,
  getTreatmentType,
  questionFormParser,
  issueListFormParser,
};
