import styled, { css } from "react-emotion";

export const Container = styled.div`
  display: flex;
  flex-flow: row;
`;

export const mainButton = css`
  margin-right: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  border-top-right-radius: 0;
  padding: 8px 12px;

  &:hover {
    transform: none;
    box-shadow: none;
  }

  &:focus {
    border-bottom-right-radius: 0;
    border-right: none;
    border-top-right-radius: 0;
    outline: none;
  }
`;

export const secondButton = css`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 8px;

  &:focus {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-width: 1px;
    outline: none;
  }

  &:hover {
    transform: none;
    box-shadow: none;
  }

  > svg {
    margin-right: 0;
  }
`;
