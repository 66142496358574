import React from "react";
import { TypeFilterContainer } from "../styles";
import ExternalProviderTypeSelect from "../VerifiedSpecialistSummary/ExternalProviderTypeSelect";

const TypeFilter = ({ onChange, value }) => {
  return (
    <TypeFilterContainer>
      Filtering by:
      <ExternalProviderTypeSelect
        hasAllOption
        onChange={onChange}
        value={value}
      />
    </TypeFilterContainer>
  );
};

export default TypeFilter;
