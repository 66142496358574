import React, { useState, useEffect } from "react";
import styled, { css } from "react-emotion";
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  injectStripe,
  ReactStripeElements,
} from "react-stripe-elements";
import { Patient } from "src/types/gql";
import InputText from "src/shared/InputText";
import Button, { ButtonRow } from "src/shared/Button";
import color from "src/styles/color";
import CountrySelect from "src/shared/CountrySelect";
import StateSelect from "src/shared/StateSelect";
import {
  useBillingAddress,
  useCreateUserAddress,
} from "src/state/useAddresses";
import { DEFAULT_COUNTRY } from "src/utils/address";
import { useAddToken } from "./query";

type Props = {
  patient: Patient;
  onClose: () => void;
};

type WrappedProps = Props & ReactStripeElements.InjectedStripeProps;

type AsyncState = {
  state: "none" | "loading" | "error";
  error?: string | undefined;
};

type AddressFields = {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
};

type AddressState = {
  changed: boolean;
  userAddressID: string | undefined; // ID of the default loaded address, cleared when address is changed
  address: AddressFields;
};

const NewPaymentMethod: React.FC<WrappedProps> = props => {
  // Prevent double submissions
  const [status, setStatus] = useState<AsyncState>({ state: "none" });
  // Fetch default billing address
  const [fetching, , addr] = useBillingAddress(props.patient.id);
  // Mutations
  const createUserAddress = useCreateUserAddress();
  const addToken = useAddToken();
  // Store form state
  const [name, setName] = useState("");
  const [addrState, setAddress] = useState<AddressState>({
    address: {
      line1: "",
      city: "",
      state: "",
      postalCode: "",
      country: DEFAULT_COUNTRY.code,
    },
    changed: false,
    userAddressID: undefined,
  });
  // Form setters
  const onChangeAddress = field => (e: React.SyntheticEvent) => {
    setAddressField(field, (e.target as HTMLInputElement).value);
  };
  const setAddressField = (field: string, value: string) => {
    setAddress({
      changed: true,
      userAddressID: undefined,
      address: { ...addrState.address, [field]: value },
    });
  };
  // When billing addresses have finished loading, use the default billing address
  // within the form.
  useEffect(() => {
    if (!fetching && addr) {
      // remove __typename gql internal from field
      const address = Object.assign({}, addr.Address);
      delete (address as any).__typename;

      setAddress({
        changed: false,
        address,
        userAddressID: addr.id,
      });
    }
  }, [fetching, addr]);

  // Tokenization logic
  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!props.stripe || status.state === "loading") {
      // No double submissions
      return;
    }

    setStatus({ state: "loading" });

    // If we need to add a new address, wait for the result as we need the ID
    // when tokenizing the card.
    let userAddressID = addrState.userAddressID;
    if (addrState.changed) {
      const addrResult = await createUserAddress({
        input: {
          userID: props.patient.id,
          type: "billing",
          ...addrState.address,
        },
      });
      if (addrResult.error) {
        setStatus({ state: "error", error: addrResult.error.message });
        return;
      }
      if (!addrResult.data) {
        setStatus({
          state: "error",
          error: "Something went wrong saving the address",
        });
        return;
      }
      userAddressID = addrResult.data.createUserAddress.id;
    }

    // If nothing was changed on the address form and we have no address ID, error.
    if (!userAddressID) {
      setStatus({ state: "error", error: "Please add an address" });
      return;
    }

    // Tokenize the card and add the card to our backend.
    const token = await props.stripe.createToken({
      type: "card",
      name: "name",
    });
    if (!token || !token.token) {
      setStatus({
        state: "error",
        error: "There was an issue adding your card",
      });
      return;
    }

    const result = await addToken({
      input: {
        userId: props.patient.id,
        billingAddressId: userAddressID,
        token: token.token.id,
        type: "card",
        gateway: "stripe",
      },
    });
    if (result.error) {
      setStatus({ state: "error", error: result.error.message });
      return;
    }

    setStatus({ state: "none" });
    props.onClose();
  };

  return (
    <Inner>
      <Title>Card information</Title>

      <label>
        Name on card
        <InputText
          placeholder="Name on card"
          onChange={e => setName(e.target.value)}
          value={name}
        />
      </label>

      <label>
        Card number
        <CardNumberElement className={input} style={stripe} />
      </label>
      <Split>
        <label>
          Expiration date
          <CardExpiryElement className={input} style={stripe} />
        </label>
        <label>
          CVC
          <CardCvcElement style={stripe} className={input} />
        </label>
      </Split>

      <hr />

      <Title>Billing address</Title>

      <label>
        Country
        <CountrySelect
          value={addrState.address.country}
          onChange={(country: string) => setAddressField("country", country)}
        />
      </label>
      <label>
        Line 1
        <InputText
          placeholder="Street address"
          onChange={onChangeAddress("line1")}
          value={addrState.address.line1}
        />
      </label>
      <label>
        Line 2
        <InputText
          placeholder="Apt, building, suite..."
          onChange={onChangeAddress("line2")}
          value={addrState.address.line2}
        />
      </label>
      <label>
        City
        <InputText
          placeholder="City"
          onChange={onChangeAddress("city")}
          value={addrState.address.city}
        />
      </label>
      <Split>
        <label>
          State
          {addrState.address.country === "US" && (
            <StateSelect
              value={addrState.address.state}
              onChange={(state: string) => setAddressField("state", state)}
            />
          )}
          {addrState.address.country !== "US" && (
            <InputText
              placeholder="State"
              onChange={onChangeAddress("state")}
              value={addrState.address.state}
            />
          )}
        </label>
        <label>
          Zip
          <InputText
            placeholder="Zip"
            onChange={onChangeAddress("postalCode")}
            value={addrState.address.postalCode}
          />
        </label>
      </Split>

      <hr />

      {status.error && (
        <p style={{ color: color.red, textAlign: "right" }}>{status.error}</p>
      )}

      <ButtonRow position="right">
        <Button type="default" onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={onSubmit}
          disabled={status.state !== "none"}
        >
          {status.state === "loading" ? "Saving..." : "Save"}
        </Button>
      </ButtonRow>
    </Inner>
  );
};

const WrappedNewPaymentMethod = injectStripe(NewPaymentMethod);

const Wrapper: React.FC<Props> = props => (
  <Elements>
    <WrappedNewPaymentMethod {...props} />
  </Elements>
);

const Title = styled.p`
  font-weight: bold;
`;

const Inner = styled.div`
  padding: 36px 30px;
  min-width: 600px;
  max-height: 450px;
  overflow-y: scroll;

  label {
    display: block;
    font-size: 14px;
    color: ${color.gray5};
    margin: 18px 0 0 0;

    input,
    select,
    > div {
      margin-top: 4px;
    }
  }

  hr {
    margin: 18px 0;
  }

  select {
    display: block;
    height: 34px;
    width: 100%;
  }
`;

const Split = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
`;

// Necessary for stripe elements
const input = css`
  width: 100%;
  font-size: 16px;
  font-family: "Roobert";
  border: 1px ${color.border} solid !important;
  border-radius: 2px;
  padding: 6px 12px;
  height: 34px;
  box-shadow: none;
  &:focus,
  &:active {
    border: 1px ${color.primary} solid !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;

// stripe styles
const stripe = {
  base: {
    border: "0 none",
    fontFamily:
      "'Roobert', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', sans-serif",
    fontSize: "16px",
    fontSmoothing: "antialiased",
    ":focus": {
      color: "#424770",
    },
  },
};

export default Wrapper;
