import React from "react";

const Reference = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.63295 12.195L4.7476 14.0803C3.96597 14.8619 2.70019 14.8619 1.91921 14.0805C1.13808 13.2993 1.13808 12.0334 1.91906 11.2525L5.69042 7.4811C6.47139 6.7001 7.7373 6.7001 8.51828 7.4811C8.77862 7.74144 9.20075 7.74144 9.46109 7.4811C9.72144 7.22075 9.72144 6.79863 9.46109 6.53828C8.1594 5.23659 6.0493 5.23659 4.7476 6.53828L0.97627 10.3096C-0.325423 11.6113 -0.325423 13.7214 0.97627 15.0231C2.27781 16.3255 4.38807 16.3255 5.69045 15.0231L7.5758 13.1378C7.83615 12.8774 7.83615 12.4553 7.5758 12.1949C7.31546 11.9346 6.8933 11.9346 6.63295 12.195Z"
      fill="#A2A9AD"
    />
    <path
      d="M15.0236 0.97627C13.7219 -0.325423 11.6111 -0.325423 10.3094 0.97627L8.04738 3.23828C7.78703 3.49863 7.78703 3.92075 8.04738 4.1811C8.30772 4.44144 8.72985 4.44144 8.99019 4.1811L11.2522 1.91909C12.0332 1.13808 13.2997 1.13808 14.0808 1.91909C14.8617 2.70006 14.8617 3.96597 14.0808 4.74695L9.93273 8.895C9.15173 9.676 7.88585 9.676 7.10487 8.895C6.84453 8.63465 6.4224 8.63465 6.16206 8.895C5.90171 9.15534 5.90171 9.57747 6.16206 9.83781C7.46375 11.1395 9.57385 11.1395 10.8755 9.83781L15.0236 5.68979C16.3253 4.3881 16.3253 2.27796 15.0236 0.97627Z"
      fill="#A2A9AD"
    />
  </svg>
);

export default Reference;
