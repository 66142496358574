import { map, get } from "lodash";
import React, { useState } from "react";
import {
  getAllOrdersForPatient,
  getNonce,
  closeOrder,
} from "src/redux/modules/order";
import styled from "react-emotion";
import { connect } from "react-redux";
import styles from "./order-style";
import { momentFormatTime } from "../../config/constant";
import moment from "moment";
import CreateOrderSegment from "../create-order-segment/create-order-segment";
import { Button, Popconfirm } from "antd";
import OrderSegment from "./order-segment";

const RECEIPT_API = "/api/v1/billing/receipts/";

const Wrapper = styled("div")`
  color: "rgba(0, 0, 0, 0.87)",
  backgroundColor: "#ffffff",
  transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
  boxSizing: "border-box",
  boxShadow: "0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12)",
  borderRadius: 2,
  zIndex: 1,
  margin: 12px,
  padding: 12px 24px,
    overflow: auto,
`;

const connected = connect(
  null,
  { getAllOrdersForPatient, getNonce, closeOrder }
);

const Order = props => {
  const [showCreateOrderSegment, setShowCreateOrderSegment] = useState(false);

  const handleComplete = () => {
    props.getAllOrdersForPatient(props.patientId);
    setShowCreateOrderSegment(false);
  };

  // getAuthNonce asks the server for an auth nonce - a single use auth token - for using
  // when redirecting to the PDF URL.
  //
  // We need to do this because the auth token is not stored in a cookie, and we need a
  // method of sending authentication in the URL when we view the PDF.
  //
  // Using cookies would open us up to CSRF attacks.
  const downloadPdf = async e => {
    e.preventDefault();
    const nonce = await props.getNonce(props.patientId);
    window.open(
      `${process.env.REACT_APP_API_HOST}${RECEIPT_API}${
        props.order.id
      }.pdf?token=${nonce.nonce}`,
      "_blank"
    );
  };

  const renderList = () => {
    return (
      <div style={styles.orderListContainer}>
        {map(props.order.order_segments, orderSegment => (
          <OrderSegment
            patientId={props.patientId}
            key={orderSegment.id}
            orderSegment={orderSegment}
            orderId={props.order.id}
          />
        ))}
      </div>
    );
  };
  const order = props.order;
  const balance = get(order, "charged_amounts.balance");

  return (
    <Wrapper>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={props.onClose}>Back to list</Button>

        <Popconfirm
          title={
            balance < order.final_amount
              ? "This will refund all charges"
              : "Are you sure?"
          }
          okText="Yes"
          cancelText="No"
          onConfirm={() => {
            props.closeOrder(props.patientId, props.order.id);
            props.onClose();
          }}
        >
          <Button type="danger">
            Close {balance < order.final_amount && " and refund "} order
          </Button>
        </Popconfirm>
      </div>

      <div style={styles.orderDetails}>
        <div>Order #{order.id}</div>
        <div>
          Created on: {moment(order.created_at).format(momentFormatTime)}
        </div>
        <div>{order.description}</div>
        <div>List Amount: ${(order.list_amount / 100).toFixed(2)}</div>
        <div>Final Amount: ${(order.final_amount / 100).toFixed(2)}</div>
        <div style={styles.amount}>Balance: ${(balance / 100).toFixed(2)}</div>
      </div>

      <hr />

      {renderList()}

      {showCreateOrderSegment ? (
        <CreateOrderSegment
          order={props.order}
          handleComplete={handleComplete}
          patientId={props.patientId}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <Button onClick={downloadPdf}>Download Receipt PDF</Button>

          <Button
            type="primary"
            onClick={() => setShowCreateOrderSegment(true)}
          >
            Add Order Segment
          </Button>
        </div>
      )}
    </Wrapper>
  );
};

export default connected(Order);
