import React from "react";
import styled from "react-emotion";
import color from "src/styles/color";

const Svg = styled.svg`
  &:hover {
    rect {
      fill: ${({ theme: { hoverBackground } }) => hoverBackground};
    }

    path {
      fill: ${({ theme: { hoverFill } }) => hoverFill};
    }
  }
`;

type Props = {
  fill?: string;
  background?: string;
  hoverFill?: string;
  hoverBackground?: string;
};

const Builds = ({
  fill = color.gray5,
  background = color.white,
  hoverFill = color.gray5,
  hoverBackground = color.gray1,
}: Props) => (
  <Svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    theme={{
      hoverFill,
      hoverBackground,
    }}
  >
    <rect width="64" height="64" fill={background} />
    <path
      d="M24.9165 15.3233C24.2262 15.3233 23.6665 15.8833 23.6665 16.5741V19.9094H24.4998V16.5741C24.4998 16.3438 24.6864 16.1572 24.9165 16.1572H34.9165V15.3233H24.9165Z"
      fill={fill}
    />
    <path
      d="M24.9165 27.8309C24.6864 27.8309 24.4998 27.6442 24.4998 27.4139V23.2448H23.6665V27.4139C23.6665 28.1047 24.2262 28.6647 24.9165 28.6647H29.0832V27.8309H24.9165Z"
      fill={fill}
    />
    <path
      d="M35.2112 28.9061L25.092 18.7808C24.9293 18.618 24.6656 18.618 24.5028 18.7808L22.122 21.1631C21.9593 21.3259 21.9593 21.5898 22.122 21.7526L32.2412 31.8778C32.3193 31.956 32.4253 32 32.5358 32C32.6463 31.9999 32.7522 31.956 32.8304 31.8778L35.2112 29.4956C35.3739 29.3328 35.3739 29.0688 35.2112 28.9061ZM32.5358 30.9936L23.0057 21.4578L24.7974 19.6651L34.3275 29.2008L32.5358 30.9936Z"
      fill={fill}
    />
    <path
      d="M40.3333 27.8309H34.0833V28.6647H38.6666V30.7493C38.6666 30.9795 38.8532 31.1662 39.0833 31.1662H40.3333C41.2537 31.1662 42 30.4196 42 29.4985C42 28.5775 41.2537 27.8309 40.3333 27.8309ZM40.3333 30.3324H39.5V28.6647H40.3333C40.7935 28.6647 41.1666 29.038 41.1666 29.4985C41.1666 29.9591 40.7935 30.3324 40.3333 30.3324Z"
      fill={fill}
    />
    <path
      d="M40.3333 15.3233H38.25V16.1572H40.3333C40.7936 16.1572 41.1667 16.5305 41.1667 16.991V29.4985H42V16.991C42 16.07 41.2538 15.3233 40.3333 15.3233Z"
      fill={fill}
    />
    <path
      d="M29.6222 23.7837L28.3721 25.0338L28.9613 25.6231L30.2114 24.373L29.6222 23.7837Z"
      fill={fill}
    />
    <path
      d="M27.9554 22.1161L26.7053 23.3662L27.2946 23.9554L28.5447 22.7053L27.9554 22.1161Z"
      fill={fill}
    />
    <path
      d="M31.2879 25.4514L30.0378 26.7015L30.6271 27.2908L31.8772 26.0407L31.2879 25.4514Z"
      fill={fill}
    />
    <path
      d="M32.9554 27.1199L31.7053 28.37L32.2946 28.9593L33.5447 27.7092L32.9554 27.1199Z"
      fill={fill}
    />
    <path
      d="M26.2877 20.4484L25.0376 21.6985L25.6268 22.2877L26.8769 21.0376L26.2877 20.4484Z"
      fill={fill}
    />
    <path
      d="M39.9725 12.9252L39.3966 12.349C38.9094 11.8837 38.1426 11.8837 37.6553 12.349L31.3195 18.6887C31.1568 18.8515 31.1568 19.1154 31.3195 19.2782L33.0474 21.0072C33.1254 21.0857 33.2315 21.1296 33.342 21.1293C33.4525 21.1293 33.5585 21.0854 33.6366 21.0072L39.9725 14.6675C39.9727 14.6673 39.9729 14.6671 39.9731 14.6669C40.4536 14.1858 40.4533 13.406 39.9725 12.9252ZM39.3837 14.0776L33.342 20.1229L32.2033 18.9834L38.245 12.9385C38.4023 12.7881 38.6501 12.7881 38.8075 12.9385L39.3837 13.5143C39.3838 13.5144 39.3838 13.5144 39.3839 13.5145C39.5392 13.67 39.5391 13.9221 39.3837 14.0776Z"
      fill={fill}
    />
    <path
      d="M33.2081 20.3172L31.4098 20.918L32.0102 19.1156L31.2186 18.8517L30.3544 21.4453C30.3402 21.4879 30.333 21.5324 30.333 21.5772C30.333 21.8074 30.5196 21.9941 30.7498 21.994C30.7939 21.9942 30.8377 21.9874 30.8798 21.974L33.4718 21.1093L33.2081 20.3172Z"
      fill={fill}
    />
    <path
      d="M37.0922 13.5016L36.5032 14.091L38.2306 15.8195L38.8197 15.23L37.0922 13.5016Z"
      fill={fill}
    />
    <path d="M37 21.1602H30.75V21.9935H37V21.1602Z" fill={fill} />
    <path d="M38.6666 21.1602H37.8333V21.9935H38.6666V21.1602Z" fill={fill} />
    <path d="M40.3333 21.1602H39.5V21.9935H40.3333V21.1602Z" fill={fill} />
    <path
      d="M18.7926 48H21.6526C23.1326 48 24.1526 47.24 24.1526 45.91C24.1526 44.99 23.6126 44.43 22.9726 44.18C23.3826 43.96 23.7926 43.46 23.7926 42.8C23.7926 41.63 22.8426 41 21.5726 41H18.7926V48ZM20.2726 43.67V42.19H21.4326C22.1026 42.19 22.4026 42.46 22.4026 42.93C22.4026 43.4 22.1026 43.67 21.4626 43.67H20.2726ZM20.2726 46.7V44.94H21.5226C22.3526 44.94 22.7226 45.26 22.7226 45.83C22.7226 46.4 22.3526 46.7 21.5226 46.7H20.2726ZM29.7907 42.96H28.3907V45.72C28.3907 46.36 27.9607 46.84 27.3507 46.84C26.7807 46.84 26.3807 46.4 26.3807 45.79V42.96H24.9907V46.1C24.9907 47.25 25.7307 48.08 26.8607 48.08C27.5507 48.08 28.1107 47.78 28.3907 47.3V48H29.7907V42.96ZM30.7708 40.77V42.24H32.2508V40.77H30.7708ZM30.8108 48H32.2108V42.96H30.8108V48ZM33.3127 48H34.7027V40.77H33.3127V48ZM37.921 48.08C38.501 48.08 39.021 47.84 39.301 47.45V48H40.691V40.77H39.301V43.49C39.021 43.12 38.531 42.88 37.901 42.88C36.441 42.88 35.541 43.91 35.541 45.48C35.541 47.05 36.441 48.08 37.921 48.08ZM38.151 46.88C37.411 46.88 36.921 46.32 36.921 45.48C36.921 44.63 37.411 44.07 38.151 44.07C38.891 44.07 39.381 44.63 39.381 45.48C39.381 46.32 38.891 46.88 38.151 46.88ZM43.6273 48.08C44.8073 48.08 45.5873 47.45 45.5873 46.49C45.5873 44.21 42.8273 45.38 42.8273 44.35C42.8273 44.03 43.0973 43.86 43.4873 43.86C43.8673 43.86 44.2573 44.09 44.3173 44.52H45.5273C45.4773 43.53 44.6573 42.88 43.4473 42.88C42.4173 42.88 41.6473 43.51 41.6473 44.4C41.6473 46.52 44.3573 45.51 44.3573 46.54C44.3573 46.82 44.0573 47.03 43.6273 47.03C43.1073 47.03 42.7573 46.75 42.7073 46.3H41.5073C41.5573 47.39 42.3973 48.08 43.6273 48.08Z"
      fill={fill}
    />
  </Svg>
);

export default Builds;
