import { ExternalSpecialistType } from "src/types/gql";
import { DEFAULT_COUNTRY } from "src/utils/address";

export const typeToReadable: {
  [specialist in ExternalSpecialistType]: string
} = {
  general_dentist: "General Dentist",
  endodontist: "Endodontist",
  periodontist: "Periodontist",
  tmj_specialist: "TMJ Specialist",
  prosthodontist: "Prosthodontist",
  oral_surgeon: "Oral Surgeon",
};

export const getReadableType = (type: ExternalSpecialistType) => {
  return typeToReadable[type] || type;
};

export const topLevelFields = [
  "firstName",
  "lastName",
  "type",
  "email",
  "clinicName",
  "phone",
  "phone2",
  "preferredProvider",
];

export const emptySpecialist = {
  firstName: "",
  lastName: "",
  type: "general_dentist",
  email: "",
  phone: "",
  clinicName: "",
  preferredProvider: false,
  address: {
    line1: "",
    line2: null,
    city: "",
    state: null,
    postalCode: "",
    country: DEFAULT_COUNTRY.code,
  },
};
