import { useEffect } from "react";
import { injectGlobal } from "react-emotion";

const useOverflowHidden = () => {
  // scrolling
  // ky - 09/26/2019
  // NOTE: below is necessary to make shared/CaledarView to scroll properly
  // In the near future, we will have to modify containers/auth/logged-in-container
  // to properly handle scrolling
  useEffect(() => {
    injectGlobal`
      html {
        overflow: hidden;
      }
    `;
    return () => {
      injectGlobal`
        html {
          overflow: auto;
        }
      `;
    };
  }, []);
};

export default useOverflowHidden;
