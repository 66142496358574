import React, { useState } from "react";
import { DateTime } from "luxon";
import { isEmpty } from "lodash";
import { notification } from "antd";
import styled, { css } from "react-emotion";

import color from "src/styles/color";
import Check from "src/shared/Icons/Check";
import {
  getEmail,
  getReadableType,
  getLastSendEvent,
} from "src/scenes/Patient/ExternalCare/utils";
import { useEmailExternalSpecialist } from "./query";
import Modal from "src/shared/Modal";
import Button, { ButtonRow } from "src/shared/Button";
import Box from "src/shared/Box";

const ManyEmailExternalSpecialist = ({
  verifiedSpecialists,
  documents,
  onClose,
  patientID,
}) => {
  const [docsToSend, setDocsToSend] = useState<Array<string>>([]);
  const [specialistToSend, setSpecialistToSend] = useState<string | null>(null);
  const [isSelectingDocument, setIsSelectingDocument] = useState(true);
  const emailSpecialist = useEmailExternalSpecialist();

  const onDocumentClick = documentID => {
    if (docsToSend.includes(documentID)) {
      setDocsToSend(docsToSend.filter(id => id !== documentID));
      return;
    }
    setDocsToSend([...docsToSend, documentID]);
  };

  const onSubmit = async () => {
    if (isEmpty(docsToSend) || isEmpty(specialistToSend)) return;

    const resp = await emailSpecialist({
      opts: {
        externalSpecialistID: specialistToSend,
        fileIDs: docsToSend,
      },
    });

    if (resp.error) {
      notification.error({
        message: "Error sending email to External Specialist & Patient",
      });
      return;
    }

    notification.success({
      message: "Email sent to External Specialist & Patient!",
    });

    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <Box
        title={
          isSelectingDocument
            ? "Select document(s)"
            : "Select external provider"
        }
      >
        {isSelectingDocument && (
          <DocumentSelectionScreen
            documents={documents}
            selectedDocuments={docsToSend}
            onClose={onClose}
            onClick={onDocumentClick}
            onNext={() => setIsSelectingDocument(false)}
          />
        )}
        {!isSelectingDocument && (
          <EmailSelectionScreen
            verifiedSpecialists={verifiedSpecialists}
            selectedVerifiedSpecialist={specialistToSend}
            onNext={onSubmit}
            onClose={onClose}
            onSelect={setSpecialistToSend}
          />
        )}
      </Box>
    </Modal>
  );
};

const EmailSelectionScreen = ({
  verifiedSpecialists,
  selectedVerifiedSpecialist,
  onSelect,
  onNext,
  onClose,
}) => {
  return (
    <Content>
      <SelectionScreen>
        {verifiedSpecialists.map(s => {
          const vs = s.verifiedSpecialist;
          return (
            <Item
              style={{
                backgroundColor:
                  selectedVerifiedSpecialist === s.id
                    ? color.background
                    : undefined,
              }}
              onClick={() => onSelect(s.id)}
              key={s.id}
            >
              <FileTitle>
                <div>
                  {vs.firstName} {vs.lastName}
                </div>
                {selectedVerifiedSpecialist === s.id && (
                  <div style={{ color: color.green, fontWeight: "normal" }}>
                    <Check fill={color.green} /> Selected
                  </div>
                )}
              </FileTitle>
              <div>{vs.clinicName}</div>
              <div>{vs.email}</div>
              <div>{vs.address.line1}</div>
              {vs.address.line2 && <div>{vs.address.line2}</div>}
              <div>
                {vs.address.city}, {vs.address.state} {vs.address.postalCode}
              </div>
            </Item>
          );
        })}
      </SelectionScreen>
      <ButtonRow className={buttonRow} position="right">
        <Button kind="default" onClick={onClose}>
          Cancel
        </Button>
        <Button
          kind="primary"
          onClick={onNext}
          disabled={!selectedVerifiedSpecialist}
        >
          Email
        </Button>
      </ButtonRow>
    </Content>
  );
};

const DocumentSelectionScreen = ({
  documents,
  selectedDocuments,
  onClick,
  onNext,
  onClose,
}) => {
  return (
    <Content>
      <SelectionScreen>
        {documents.map(doc => {
          const isSelected = selectedDocuments.includes(doc.id);
          const e = getLastSendEvent(doc.emailEvents);
          return (
            <Item
              style={{
                backgroundColor: isSelected ? color.background : undefined,
              }}
              key={doc.id}
              onClick={() => onClick(doc.id)}
            >
              <FileTitle>
                {getReadableType(doc.type)} - {doc.filename}
                {isSelected && (
                  <div style={{ color: color.green, fontWeight: "normal" }}>
                    <Check fill={color.green} /> Selected
                  </div>
                )}
              </FileTitle>
              <FileSubtitle>
                {e &&
                  `Submitted: ${DateTime.fromISO(e.createdAt).toFormat(
                    "LLLL d, yyyy"
                  )} to ${getEmail(e)}`}
                {!e && "Not yet submitted"}
              </FileSubtitle>
            </Item>
          );
        })}
      </SelectionScreen>
      <ButtonRow className={buttonRow} position="right">
        <Button kind="default" onClick={onClose}>
          Cancel
        </Button>
        <Button
          kind="primary"
          onClick={onNext}
          disabled={isEmpty(selectedDocuments)}
        >
          Select
        </Button>
      </ButtonRow>
    </Content>
  );
};

const Content = styled.div`
  height: 450px;
  width: 550px;

  display: flex;
  flex-flow: column;
`;

const Item = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-flow: column;
  border-bottom: 1px solid ${color.border};

  &:hover {
    cursor: pointer;
  }
`;

const buttonRow = css`
  padding: 16px 24px;
  border-top: 1px solid ${color.border};
`;

const FileTitle = styled.div`
  font-weight: 600;
  margin-bottom: 8px;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

const FileSubtitle = styled.div`
  color: ${color.gray3};
`;

const SelectionScreen = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  overflow: auto;
`;

export default ManyEmailExternalSpecialist;
