import React, { PureComponent } from "react";
import Moment from "moment";
import Table from "antd/lib/table";
import Input from "antd/lib/input";
import BuildInfo from "./BuildInfo";
import type { Build, ProducerIdentifier } from "src/types/api";

type PassedProps = {
  builds: Array<Build>,
  producers: Array<ProducerIdentifier>,
};

type Props = PassedProps;

export default class BuildTable extends PureComponent<Props, void> {
  render() {
    const { producers } = this.props;

    const columns = [
      {
        title: "Patient",
        dataIndex: "user.name",
        onFilter: (value: string, record: Build) => {
          return record.id.toLowerCase().includes(value.toLowerCase());
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => {
          return (
            <div className="custom-filter-dropdown">
              <Input
                onChange={e => setSelectedKeys([e.currentTarget.value])}
                onPressEnter={confirm}
              />
            </div>
          );
        },
      },
      {
        title: "Producer",
        dataIndex: "producer_identifier_id",
        width: 200,
        sorter: (a: Build, b: Build) =>
          a.producer_identifier_id < b.producer_identifier_id,
        render: (id: string) => {
          const p =
            producers.find((p: ProducerIdentifier) => p.id === id) || {};
          return p.source;
        },
      },
      {
        title: "Created",
        dataIndex: "created_at",
        width: 200,
        defaultSortOrder: "ascend",
        sorter: (a: Build, b: Build) => {
          return (
            new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
          );
        },
        render: (date: ?string) => {
          if (!date) {
            return "-";
          }
          return new Moment(date).format("Do MMM YYYY");
        },
      },
      {
        title: "Sent",
        dataIndex: "sent_at",
        width: 200,
        sorter: (a: Build, b: Build) => {
          return (
            new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf()
          );
        },
        render: (date: ?string) => {
          if (!date) {
            return "-";
          }
          return new Moment(date).format("Do MMM YYYY");
        },
      },
      {
        title: "Received",
        dataIndex: "actual_receipt_at",
        width: 200,
        sorter: (a: Build, b: Build) => {
          return (
            new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf()
          );
        },
        render: (date: ?string) => {
          if (!date) {
            return "-";
          }
          return new Moment(date).format("Do MMM YYYY");
        },
      },
    ];

    const { builds } = this.props;

    return (
      <Table
        columns={columns}
        dataSource={builds}
        size="medium"
        expandedRowRender={(item: Build) => <BuildInfo build={item} />}
        pagination={{
          defaultPageSize: 25,
        }}
      />
    );
  }
}
