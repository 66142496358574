import React from "react";
import Skeleton from "src/shared/Skeleton";
import color from "src/styles/color";
import { Row } from "./BeginningVisit";

const Loading = () => (
  <>
    <Row>
      <Skeleton width="286px" height="24px" dark />
    </Row>
    <div style={{ display: "flex", height: "100%", background: "white" }}>
      <div
        style={{
          width: "200px",
          minWidth: "200px",
          padding: "0 0 0 32px",
          height: "100%",
          borderRight: `1px solid ${color.border}`,
        }}
      >
        <Skeleton width="120px" height="16px" margin="24px 0 0" />
        <Skeleton width="120px" height="16px" margin="24px 0 0" />
        <Skeleton width="120px" height="16px" margin="24px 0 0" />
      </div>
      <div style={{ padding: "24px 0 32px 32px" }}>
        <Skeleton dark width="286px" height="24px" margin="0 0 24px" />
        <Skeleton width="286px" height="16px" margin="0 0 16px" />
        <Skeleton width="143px" height="16px" />
      </div>
    </div>
  </>
);

export default Loading;
