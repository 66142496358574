import { cloneDeep, find, each, get } from "lodash";
import formList from "src/config/treatment-forms/index";
import { ISSUE_LIST_FORM, QUESTION_FORM } from "src/config/event-type";
import uuid from "uuid/v4";

export const getNewEmptyForm = formName => {
  const newFormEvent = cloneDeep(formList[formName]);
  newFormEvent.uuid = uuid();
  return newFormEvent;
};

// The below questions are only for copying answers into a new form.
// we only copy answers there there is an exact match in the question displayName
const loopQuestionListToClone = (questionList, newQuestionList) => {
  each(questionList, question => {
    const newQuestion = find(newQuestionList, {
      displayName: question.displayName,
    });
    if (newQuestion) {
      newQuestion.answerList = question.answerList;
      newQuestion.answer = question.answer;
    }
  });
};
const loopIssueQuestionListToClone = (issueList, newIssueList) => {
  each(issueList, issue => {
    const newIssueQuestionList = get(
      find(newIssueList, { displayName: issue.displayName }),
      "questionList"
    );
    if (newIssueQuestionList) {
      loopQuestionListToClone(issue.questionList, newIssueQuestionList);
    }
  });
};
const copyAnswersIntoNewForm = (inputEvent, newFormEvent) => {
  if (inputEvent.type === ISSUE_LIST_FORM) {
    loopIssueQuestionListToClone(inputEvent.issueList, newFormEvent.issueList);
  } else if (inputEvent.type === QUESTION_FORM) {
    loopQuestionListToClone(inputEvent.questionList, newFormEvent.questionList);
  }
  return newFormEvent;
};

export { copyAnswersIntoNewForm };
