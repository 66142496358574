import React, { useCallback } from "react";
import styled, { css } from "react-emotion";
import { get, debounce } from "lodash";
import color from "src/styles/color";
import Button from "src/shared/Button";

const Perimeter = styled.div`
  position: relative;
  display: inline-block;
  max-width: 400px;
  width: 100%;
`;

const MiniBtn = styled(Button)`
  width: 16px;
  height: 16px;
  font-size: 10px;
  color: ${color.border};
  padding: 0;
  &:focus {
    outline: none;
  }
  ${({ theme: { isActive } }) =>
    isActive &&
    css`
      background: ${color.primary};
      color: ${color.white};
      &:hover {
        background: ${color.primaryHover};
        color: ${color.white};
      }
    `}
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const Column = styled.div`
  width: calc(100% - 8px);
  display: flex;
  flex-direction: column;
  align-items: stretch;

  button {
    margin: 0 !important;
  }

  ${({ theme: { first } }) =>
    first &&
    css`
      margin-right: 8px;
    `}

  ${({ theme: { last } }) =>
    last &&
    css`
      margin-left: 8px;
    `}
`;

const InnerRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.div`
  font-weight: bold;
  color: ${color.gray3};
  width: 24px;
  text-align: center;
`;

const VerticalLine = styled.div`
  border-left: 1px solid ${color.gray3};
  position: absolute;
  left: 50%;
  height: 100%;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Line = styled.div`
  border-bottom: 1px solid ${color.gray3};
  width: 100%;
  margin: 0 16px;
  height: 1px;
`;

const Bold = styled.div`
  font-weight: bold;
  color: ${color.gray3};
`;

const Horizontal = () => (
  <Box>
    <Bold>right</Bold>
    <Line />
    <Bold>left</Bold>
  </Box>
);

const TeethPickerWithLB = props => {
  const { value, setValue, handleChange, disabled } = props;

  const debounced = useCallback(debounce(handleChange, 1500), [handleChange]);

  const data = {
    URL: ["1L", "2L", "3L", "4L", "5L", "6L", "7L", "8L"],
    URB: ["1B", "2B", "3B", "4B", "5B", "6B", "7B", "8B"],
    ULL: ["9L", "10L", "11L", "12L", "13L", "14L", "15L", "16L"],
    ULB: ["9B", "10B", "11B", "12B", "13B", "14B", "15B", "16B"],
    LRB: ["32B", "31B", "30B", "29B", "28B", "27B", "26B", "25B"],
    LRL: ["32L", "31L", "30L", "29L", "28L", "27L", "26L", "25L"],
    LLB: ["24B", "23B", "22B", "21B", "20B", "19B", "18B", "17B"],
    LLL: ["24L", "23L", "22L", "21L", "20L", "19L", "18L", "17L"],
  };

  const onChange = v => () => {
    const checked = !value.includes(v);
    const newValue = checked ? value.concat([v]) : value.filter(i => i !== v);
    debounced(newValue);
    setValue(newValue);
  };

  return (
    <Perimeter>
      <VerticalLine />
      <Row style={{ marginBottom: "10px" }}>
        <Column theme={{ first: true }}>
          <InnerRow>
            {get(data, "URL").map((v, idx, arr) => (
              <Label key={v}>{arr.length - idx}</Label>
            ))}
          </InnerRow>
          <InnerRow style={{ margin: "10px 0 12px" }}>
            {get(data, "URB").map((v, idx) => (
              <MiniBtn
                key={v}
                theme={{ isActive: value.includes(v) }}
                onClick={onChange(v)}
                disabled={disabled}
              >
                B
              </MiniBtn>
            ))}
          </InnerRow>
          <InnerRow>
            {get(data, "URL").map((v, idx) => (
              <MiniBtn
                key={v}
                theme={{ isActive: value.includes(v) }}
                onClick={onChange(v)}
                disabled={disabled}
              >
                L
              </MiniBtn>
            ))}
          </InnerRow>
        </Column>

        <Column theme={{ last: true }}>
          <InnerRow>
            {get(data, "ULL").map((v, idx) => (
              <Label key={v}>{idx + 1}</Label>
            ))}
          </InnerRow>
          <InnerRow style={{ margin: "10px 0 12px" }}>
            {get(data, "ULB").map((v, idx) => (
              <MiniBtn
                key={v}
                theme={{ isActive: value.includes(v) }}
                onClick={onChange(v)}
                disabled={disabled}
              >
                B
              </MiniBtn>
            ))}
          </InnerRow>
          <InnerRow>
            {get(data, "ULL").map((v, idx) => (
              <MiniBtn
                key={v}
                theme={{ isActive: value.includes(v) }}
                onClick={onChange(v)}
                disabled={disabled}
              >
                L
              </MiniBtn>
            ))}
          </InnerRow>
        </Column>
      </Row>

      <Horizontal />

      <Row style={{ marginTop: "10px" }}>
        <Column theme={{ first: true }}>
          <InnerRow>
            {get(data, "LRL").map((v, idx) => (
              <MiniBtn
                key={v}
                theme={{ isActive: value.includes(v) }}
                onClick={onChange(v)}
                disabled={disabled}
              >
                L
              </MiniBtn>
            ))}
          </InnerRow>
          <InnerRow style={{ margin: "12px 0 10px" }}>
            {get(data, "LRB").map((v, idx) => (
              <MiniBtn
                key={v}
                theme={{ isActive: value.includes(v) }}
                onClick={onChange(v)}
                disabled={disabled}
              >
                B
              </MiniBtn>
            ))}
          </InnerRow>
          <InnerRow>
            {get(data, "LRL").map((v, idx, arr) => (
              <Label key={v}>{arr.length - idx}</Label>
            ))}
          </InnerRow>
        </Column>

        <Column theme={{ last: true }}>
          <InnerRow>
            {get(data, "LLL").map((v, idx) => (
              <MiniBtn
                key={v}
                theme={{ isActive: value.includes(v) }}
                onClick={onChange(v)}
                disabled={disabled}
              >
                L
              </MiniBtn>
            ))}
          </InnerRow>
          <InnerRow style={{ margin: "12px 0 10px" }}>
            {get(data, "LLB").map((v, idx) => (
              <MiniBtn
                key={v}
                theme={{ isActive: value.includes(v) }}
                onClick={onChange(v)}
                disabled={disabled}
              >
                B
              </MiniBtn>
            ))}
          </InnerRow>
          <InnerRow>
            {get(data, "LLL").map((v, idx) => (
              <Label key={v}>{idx + 1}</Label>
            ))}
          </InnerRow>
        </Column>
      </Row>
    </Perimeter>
  );
};

export default TeethPickerWithLB;
