import React, { useState } from "react";
import { css } from "react-emotion";
import { Patient } from "src/types/gql";
import Box from "src/shared/Box";
import BlankSlate from "src/shared/BlankSlate";
import Modal from "src/shared/Modal";
import Button, { ButtonRow } from "src/shared/Button";
import Loading from "src/shared/Loading";
import Error from "src/shared/Error";
import { usePaymentMethods } from "../query";
import PaymentMethodList from "../PaymentMethodList";
import NewPaymentMethod from "../NewPaymentMethod";

type Props = {
  patient: Patient;
};

const title = "Payment information";

const PaymentInformation: React.FC<Props> = props => {
  const [showModal, setShowModal] = useState(false);
  const [fetching, error, data] = usePaymentMethods(props.patient.id);

  if (fetching) {
    return (
      <Box title={title}>
        <Loading />
      </Box>
    );
  }

  if (error) {
    return (
      <Box title={title}>
        <Error />
      </Box>
    );
  }

  return (
    <Box title={title}>
      <Modal
        hidden={!showModal}
        ignoreOutOfBoundariesClick
        onClose={() => setShowModal(false)}
      >
        <Box title="New payment method">
          <NewPaymentMethod
            patient={props.patient}
            onClose={() => setShowModal(false)}
          />
        </Box>
      </Modal>

      {data.length === 0 && (
        <BlankSlate
          title="No payment information"
          text="The patient has no purchase history."
        >
          <Button kind="link" onClick={() => setShowModal(true)}>
            Add a new payment method
          </Button>
        </BlankSlate>
      )}

      {data.length > 0 && (
        <div>
          <PaymentMethodList
            patientID={props.patient.id}
            paymentMethods={data}
            closeDeleteModal={() => setShowModal(false)}
          />

          <ButtonRow
            position="center"
            className={css`
              margin: 20px 0;
            `}
          >
            <Button kind="link" onClick={() => setShowModal(true)}>
              Add a new payment method
            </Button>
          </ButtonRow>
        </div>
      )}
    </Box>
  );
};

export default PaymentInformation;
