import React, { useState } from "react";
import styled from "react-emotion";
import { useQuery } from "src/utils/http/gqlQuery";
import usePagination from "src/shared/usePagination";
import gql from "graphql-tag";
import time from "src/shared/time";
import { Clinic } from "src/types/gql";
import useSchedulingContext from "../useSchedulingContext";
import { Link } from "react-router";
import { Staff } from "src/types/gql";
import { toggle } from "src/shared/util";
import StaffPopover from "src/shared/Popovers/StaffPopover";
import DownArrow from "src/shared/Icons/DownArrow";
import color from "src/styles/color";
import DatePicker from "src/shared/DatePicker";
import PatientSearch from "src/shared/PatientSearch";
import { BaseUser } from "src/shared/PatientSearch/PatientSearch";
import { get } from "lodash";

import { useSchedulingRestrictions } from "src/state/self";

import Table, { Header, Body } from "src/shared/Table";

const scheduleURLs = [
  "/api/gql/OpenAvailability",
  "/api/gql/UpdateAppointment",
  "/api/gql/BookAppointment",
  "/api/gql/CheckInAppointment",
  "/api/gql/CancelAppointment",
  "/api/gql/deleteAppointment",
  "/api/v2/webhooks/impress#bookAppointment",
  "/api/v2/webhooks/impress#updateAppointment",
  "/api/v2/webhooks/impress#deleteAppointment",
];

const Perimeter = styled.div`
  margin: 24px 0 10px;
  width: 100%;
`;

const Inner = styled.div`
  cursor: pointer;

  svg {
    margin: 0 0 0 6px;
  }
`;

const FilterTag = styled.span`
  fontweight: normal;
`;

const FilterButton = styled.button`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${color.background};
  margin-bottom: 10px;
`;

type Props = {
  clinic: Clinic;
};

const CalendarLogs: React.FC<Props> = ({ clinic }) => {
  const [page, setPage] = useState(1);

  const { toISODate } = time(get("timezone", clinic));

  const { isScheduleManager } = useSchedulingRestrictions();

  const { types } = useSchedulingContext();

  const [searchStaffIDs, setSearchStaffIDs] = useState<Staff[]>([] as Staff[]);
  const [searchPatientIDs, setSearchPatientIDs] = useState<BaseUser[]>(
    [] as BaseUser[]
  );
  const [appointmentDate, setAppointmentDate] = useState<string | undefined>(
    undefined
  );
  const [useFilter, setUseFilter] = useState<Object | null>(null);
  const [dateFilteredLogs, setDateFilteredLogs] = useState<any[]>([]);

  const apptTypeMap = {};
  types.forEach(type => (apptTypeMap[type.id] = type.name));

  const [loading, , data] = useGetScheduleFeed(useFilter, page, clinic);

  const query = `schedule`;
  const auditLogs = usePagination(
    query,
    data && data.pageResults,
    data && data.auditLogs,
    loading,
    page,
    setPage
  );

  if (!isScheduleManager) {
    return null;
  }

  const staffFilter = (staff, clinic) => {
    if (staff.length) {
      return (
        <FilterTag>
          staff:{" "}
          {staff.map(member => (
            <button
              key={member.id}
              onClick={() => {
                setSearchStaffIDs(toggle(searchStaffIDs, member));
                if (useFilter !== null) {
                  setUseFilter({
                    ids: [] as String[],
                    staffIds: searchStaffIDs.map(staff => staff.id),
                    patientIds: searchPatientIDs.map(patient => patient.id),
                    clinicIds: [clinic.id],
                    requestIds: [] as String[],
                    methods: [] as String[],
                    urls: scheduleURLs,
                    jobs: [] as String[],
                    services: [] as String[],
                  });
                }
              }}
            >
              {member.firstName} {member.lastName}
            </button>
          ))}
        </FilterTag>
      );
    }
    return null;
  };

  const patientFilter = (patients, clinic) => {
    if (patients.length) {
      return (
        <FilterTag>
          patients:{" "}
          {patients.map(patient => (
            <button
              key={patient.id}
              onClick={() => {
                setSearchPatientIDs(toggle(searchPatientIDs, patient));
                if (useFilter !== null) {
                  setUseFilter({
                    ids: [] as String[],
                    staffIds: searchStaffIDs.map(staff => staff.id),
                    patientIds: searchPatientIDs.map(patient => patient.id),
                    clinicIds: [clinic.id],
                    requestIds: [] as String[],
                    methods: [] as String[],
                    urls: scheduleURLs,
                    jobs: [] as String[],
                    services: [] as String[],
                  });
                }
              }}
            >
              {patient.preferredName
                ? patient.preferredName
                : patient.firstName}{" "}
              {patient.lastName}
            </button>
          ))}
        </FilterTag>
      );
    }
    return null;
  };

  const timeFilter = time => {
    if (time) {
      return (
        <FilterTag>
          appointment date:{" "}
          <button
            onClick={() => {
              setAppointmentDate(undefined);
              setDateFilteredLogs([]);
            }}
          >
            {time}
          </button>
        </FilterTag>
      );
    }
    return null;
  };

  const getAction = item => {
    if (item.url === "/api/gql/OpenAvailability") {
      return `created a ${
        apptTypeMap[item.appointment.appointmentTypeID]
      } appointment slot`;
    } else if (item.url === "/api/gql/BookAppointment") {
      return `booked a ${
        apptTypeMap[item.appointment.appointmentTypeID]
      } appointment`;
    } else if (item.url === "/api/gql/UpdateAppointment") {
      return `updated a ${
        apptTypeMap[item.appointment.appointmentTypeID]
      } appointment`;
    } else if (item.url === "/api/gql/CheckInAppointment") {
      return `checked in a ${
        apptTypeMap[item.appointment.appointmentTypeID]
      } appointment`;
    } else if (item.url === "/api/gql/CancelAppointment") {
      return `cancelled ${
        apptTypeMap[item.appointment.appointmentTypeID]
      } appointment`;
    } else if (item.url === "/api/gql/deleteAppointment") {
      return `deleted ${
        apptTypeMap[item.appointment.appointmentTypeID]
      } appointment`;
    } else if (item.url === "/api/gql/impress#bookAppointment") {
      return `booked an impress ${
        apptTypeMap[item.appointment.appointmentTypeID]
      } appointment`;
    } else if (item.url === "/api/gql/impress#updateAppointment") {
      return `updated an impress ${
        apptTypeMap[item.appointment.appointmentTypeID]
      } appointment`;
    } else if (item.url === "/api/gql/impress#deleteAppointment") {
      return `deleted an impress ${
        apptTypeMap[item.appointment.appointmentTypeID]
      } appointment`;
    }

    return "";
  };

  return (
    <Perimeter>
      <h1>Filters:</h1>
      <div>
        {staffFilter(searchStaffIDs, clinic)}{" "}
        {patientFilter(searchPatientIDs, clinic)}
        {timeFilter(appointmentDate)}
      </div>
      <FilterButton
        style={{ background: useFilter ? "lightgreen" : "gainsboro" }}
        onClick={() => {
          if (useFilter === null) {
            setUseFilter({
              ids: [] as String[],
              staffIds: searchStaffIDs.map(staff => staff.id),
              patientIds: searchPatientIDs.map(patient => patient.id),
              clinicIds: [clinic.id],
              requestIds: [] as String[],
              methods: [] as String[],
              urls: scheduleURLs,
              jobs: [] as String[],
              services: [] as String[],
            });
          } else {
            setUseFilter(null);
          }
        }}
      >
        Run filters
      </FilterButton>
      <Table
        items={
          useFilter && dateFilteredLogs.length ? dateFilteredLogs : auditLogs
        }
      >
        <Header>
          <Header.Item css={{ width: "20%" }}>Time</Header.Item>
          <Header.Item css={{ width: "15%" }}>
            <StaffPopover
              onClick={(s?: Staff) => {
                if (!s) {
                  return;
                }
                setSearchStaffIDs(toggle(searchStaffIDs, s));
              }}
              value={searchStaffIDs}
            >
              <Inner>
                Staff{" "}
                <DownArrow
                  fill={searchStaffIDs.length > 0 ? color.primary : color.gray2}
                />
              </Inner>
            </StaffPopover>
          </Header.Item>
          <Header.Item css={{ width: "20%" }}>Action</Header.Item>
          <Header.Item css={{ width: "20%" }}>
            Appt Date:{" "}
            <DatePicker
              date={appointmentDate}
              onChange={d => {
                const iso = toISODate(d);
                const filtered = auditLogs.filter(log => {
                  if (
                    log.appointment &&
                    log.appointment.startTime &&
                    log.appointment.startTime.includes(iso)
                  ) {
                    return log;
                  }
                  return null;
                });
                setAppointmentDate(toISODate(d));
                setDateFilteredLogs(filtered);
              }}
              timezone={clinic.timezone}
              style={{ marginLeft: "10px" }}
            />
          </Header.Item>
          <Header.Item css={{ width: "15%" }}>
            Patient{" "}
            <PatientSearch
              view="basic"
              style={{ width: "300px", marginLeft: "10px" }}
              onChange={p => {
                setSearchPatientIDs(toggle(searchPatientIDs, p));
              }}
            />
          </Header.Item>
          <Header.Item css={{ width: "10%" }}>Log</Header.Item>
        </Header>

        <Body>
          {item =>
            item ? (
              <Body.Row key={item.id}>
                <Body.Item css={{ width: "20%" }}>
                  {new Date(item.createdAt).toLocaleString()}
                </Body.Item>
                <Body.Item css={{ width: "15%" }}>
                  {item.staff
                    ? `${item.staff.firstName} ${item.staff.lastName}`
                    : null}
                </Body.Item>
                <Body.Item css={{ width: "20%" }}>
                  {item.appointment ? getAction(item) : null}
                </Body.Item>
                <Body.Item css={{ width: "20%" }}>
                  {item.appointment && item.appointment.startTime
                    ? new Date(item.appointment.startTime).toLocaleString(
                        "en-US",
                        { timeZone: item.clinic.timezone }
                      )
                    : null}
                </Body.Item>
                <Body.Item css={{ width: "15%" }}>
                  {item.patient
                    ? `for ${
                        item.patient.preferredName
                          ? item.patient.preferredName
                          : item.patient.firstName
                      } ${item.patient.lastName}`
                    : null}
                </Body.Item>
                <Body.Item css={{ width: "10%" }}>
                  <Link
                    to={`emr-admin/audit-logs?request_id=${item.requestId}`}
                  >
                    log
                  </Link>
                </Body.Item>
              </Body.Row>
            ) : null
          }
        </Body>
      </Table>
    </Perimeter>
  );
};

export const getScheduleFeed = gql`
  query AuditLogs($filter: AuditLogFilters!, $page: Int) {
    auditLogs(filter: $filter) @paginated(page: $page) {
      auditLogs {
        id
        staffId
        patientId
        requestId
        method
        url
        job
        service
        createdAt

        appointment {
          startTime
          appointmentTypeID
        }
        patient {
          id
          firstName
          lastName
          preferredName
        }
        staff {
          id
          firstName
          lastName
        }
        clinic {
          id
          name
          nickname
          timezone
        }
      }
      pageResults {
        page
        perPage
        totalItems
        totalPages
      }
    }
  }
`;

export const useGetScheduleFeed = (filter, page, clinic) => {
  if (filter === null) {
    filter = {
      ids: [] as String[],
      staffIds: [] as String[],
      patientIds: [] as String[],
      clinicIds: [clinic.id],
      requestIds: [] as String[],
      methods: [] as String[],
      urls: scheduleURLs,
      jobs: [] as String[],
      services: [] as String[],
    };
  }

  const [{ fetching, error, data }] = useQuery({
    query: getScheduleFeed,
    variables: {
      filter,
      page,
    },
  });
  return [fetching, error, (data && data.auditLogs) || undefined];
};

export default CalendarLogs;
