import React, { useState } from "react";
import last from "lodash/last";
import { url } from "src/shared/util";
import ImageModal from "src/components/image-modal/ImageModal";
import { SelectedObj } from "src/types/types";
import {
  Embed,
  XRayPhoto,
  SummaryHeaderRow,
  SummaryHeaderText,
} from "../styles";
import { formatDateForTimeline } from "../util";
import { TimelineFileData } from "../queries";
import SummaryHeaderDropdown from "./SummaryHeaderDropdown";

export const IMAGE_FILE = /[.](jpg|jpeg|png|gif|bmp|webp)$/;

export interface Props {
  sortedFiles: Array<TimelineFileData>;
}

export interface ViewSettings {
  groups: Map<string, TimelineFileData>;
  file: TimelineFileData;
}

export function getViewSettings(
  sortedFiles: Array<TimelineFileData>,
  preferredFileID: string | null
): ViewSettings | null {
  const isXRayPhoto = ({ type }: TimelineFileData) => type === "PanoPreview";
  const eligibleFiles = sortedFiles.filter(isXRayPhoto);
  const foundFile = eligibleFiles.find(({ id }) => id === preferredFileID);
  const lastFile = last(eligibleFiles);
  const groups = new Map(eligibleFiles.map(file => [file.id, file]));
  if (groups.size === 0) {
    return null;
  }

  if (foundFile) {
    return { groups, file: foundFile };
  }

  if (lastFile) {
    return { groups, file: lastFile };
  }

  return null;
}

export function getDropdownOptions(
  sortedFiles: Array<TimelineFileData>,
  groups: Map<string, TimelineFileData>
): Array<SelectedObj> {
  return sortedFiles
    .filter(({ id }) => groups.has(id))
    .map(({ id, createdAt, filename, description }) => {
      const date = formatDateForTimeline(createdAt);
      const label = `${description || filename} - ${date}`;
      return { value: id, label };
    });
}

export default function XRayPhotos({
  sortedFiles,
}: Props): React.ReactElement | null {
  const [showModal, setShowModal] = useState(false);
  const [selectedFileID, setSelectedFileID] = useState<string | null>(null);
  const viewSettings = getViewSettings(sortedFiles, selectedFileID);
  if (!viewSettings) {
    return null;
  }
  const { groups, file } = viewSettings;
  const options = getDropdownOptions(sortedFiles, groups);

  const fileURL = url(`/api/v2/files/${file.id}`);

  return (
    <>
      <SummaryHeaderRow>
        <SummaryHeaderText>Panoramic X-Ray</SummaryHeaderText>

        <SummaryHeaderDropdown
          options={options}
          selected={file.id}
          onSelect={value => setSelectedFileID(value)}
        />
      </SummaryHeaderRow>

      <Embed key={file.id}>
        {IMAGE_FILE.test(file.filename) && (
          <XRayPhoto src={fileURL} onClick={() => setShowModal(true)} />
        )}

        <div>
          <a target="_" href={fileURL}>
            Download {file.filename}
          </a>
        </div>
      </Embed>

      {showModal && (
        <ImageModal imageURL={fileURL} onClose={() => setShowModal(false)} />
      )}
    </>
  );
}
