import React from "react";
import { Perimeter } from "./styles";

const Accordion = props => {
  return (
    <Perimeter
      className={props.className}
      style={props.style}
      theme={{
        isOpen: props.isOpen,
      }}
    >
      {props.children}
    </Perimeter>
  );
};

export default Accordion;
