import { V, S } from "src/shared-styles";

export default {
  V,
  S,
  titleRight: {
    ...S.floatRight,
    fontWeight: V.fontWeightLight,
    fontSize: V.fontSmall,
    color: V.colors.fontFaded,
  },
  title: {},
  expandableButton: {
    bottom: V.spacing,
    right: V.spacing,
    position: "absolute",
  },
  eventCard: {
    ...S.contentBlock,
    position: "relative",
  },
  linkToSelfForm: {
    cursor: "pointer",
  },
  status: {
    backgroundColor: V.colors.whiteGreyBackground,
    boxShadow: "none",
  },
  systemEvent: {
    backgroundColor: V.colors.whiteGreyBackground,
    boxShadow: "none",
    color: V.colors.fontFaded,
    fontWeight: V.fontWeightLight,
  },
  patientComment: {
    ...V.materialUIShallowCard,
    borderRadius: 8,
    backgroundColor: V.colors.reallyWhiteGreyBackground,
    marginRight: V.spacingLarge,
  },
  staffComment: {
    ...V.materialUIShallowCard,
    borderRadius: 8,
    backgroundColor: V.colors.lightBlue,
    marginLeft: V.spacingLarge,
  },
  privateStaffComment: {
    borderRight: `4px solid ${V.colors.lightBlue}`,
  },
  file: {
    ...V.materialUIShallowCard,
    backgroundColor: V.colors.reallyWhiteGreyBackground,
    display: "inline-block",
    borderRadius: 8,
  },
  form: {
    // ...V.materialUIDeepCard,
    // backgroundColor: V.colors.veryLightOrange,
    borderLeft: `4px solid ${V.colors.primary}`,
  },
  pin: {
    marginLeft: V.spacing,
  },
  unseen: {
    float: "right",
    color: V.colors.fontFaded,
    fontWeight: V.fontWeightNormal,
  },
  incomplete: {
    marginLeft: V.spacing,
    fontWeight: V.fontWeightLight,
  },
};
