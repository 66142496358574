import { QUESTION_FORM } from "../event-type";

const VFC_TEMPLATE = {
  doNotPrepopulate: true,
  postTimestamp: null,
  postUserName: null,
  postUserUUID: null,
  complete: false,
  formName: "vfc", // this must match the name of the form in config/treatment-forms/index
  type: QUESTION_FORM,
  questionList: [
    {
      dataKey: "conclusion",
      displayName: "Doctor Comfort Conclusion",
      type: "select",
      possibleAnswerList: [
        "Cleared for Debond",
        "Major Improvements Needed",
        "Education/Motivation Needed",
        "Minor Improvements Needed",
        "Needs Bite Settling",
        "Graduate",
      ], // Graduate ties to form action
    },
    {
      displayName: "Refer To",
      type: "text",
    },
    {
      displayName: "Refer To Email Address",
      type: "text",
    },
    {
      displayName: "Referral Reason",
      type: "text",
    },
    {
      displayName: "Notes",
      type: "text",
    },
  ],
};

export default VFC_TEMPLATE;
