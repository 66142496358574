import React from "react";

const Referrals = () => (
  <svg
    width="171"
    height="131"
    viewBox="0 0 171 131"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M171 89.5596V126.086C171 127.516 169.841 128.675 168.412 128.675H102.765C101.332 128.675 100.175 127.516 100.175 126.086V89.5596C100.175 79.5481 108.29 71.4313 118.301 71.4313H152.872C154.646 71.4313 156.357 71.6856 157.975 72.1588C165.502 74.3647 171 81.3191 171 89.5596Z"
      fill="#5CE4D9"
    />
    <path
      d="M155.324 96.7164V128.675H151.44V96.7164C151.44 95.6443 152.307 94.7745 153.382 94.7745C154.453 94.7745 155.324 95.6443 155.324 96.7164Z"
      fill="#09D6C2"
    />
    <path
      d="M171 89.5597V126.086C171 127.516 169.84 128.675 168.411 128.675H125.446C138.35 125.824 146.453 119.533 151.44 111.978C153.037 109.56 154.314 107.013 155.324 104.408C159.888 92.6619 159.086 79.7347 157.974 72.1588C165.501 74.3647 171 81.3192 171 89.5597Z"
      fill="#09D6C2"
    />
    <path
      d="M116.248 96.7164V128.675H112.364V96.7164C112.364 95.6443 113.234 94.7745 114.305 94.7745C115.377 94.7745 116.248 95.6443 116.248 96.7164Z"
      fill="#09D6C2"
    />
    <path
      d="M131.61 81.0993C128.455 81.0993 125.897 78.5419 125.897 75.3877V59.4908H137.323V75.3877C137.323 78.5419 134.765 81.0993 131.61 81.0993Z"
      fill="#FFB38C"
    />
    <path
      d="M114.647 32.7175C114.647 36.7455 111.381 40.0116 107.353 40.0116C103.324 40.0116 100.059 36.7455 100.059 32.7175C100.059 28.6886 103.324 25.4234 107.353 25.4234C111.381 25.4234 114.647 28.6886 114.647 32.7175Z"
      fill="#FFB38C"
    />
    <path
      d="M151.947 21.1137V39.9812C151.947 41.3674 151.84 42.7261 151.635 44.0524C149.679 56.7077 138.735 66.3962 125.532 66.3962C115.6 66.3962 107.55 58.3461 107.55 48.4171V21.1137H151.947Z"
      fill="#FECAA8"
    />
    <path
      d="M125.572 48.1549H120.62C120.049 48.1549 119.508 47.9046 119.139 47.4707C118.771 47.0368 118.611 46.4625 118.703 45.9L120.487 34.9961C120.66 33.9378 121.659 33.2202 122.717 33.3939C123.775 33.5667 124.492 34.5651 124.32 35.6234L122.904 44.2713H125.572C126.644 44.2713 127.513 45.1411 127.513 46.2131C127.513 47.2851 126.644 48.1549 125.572 48.1549Z"
      fill="#FE9D7F"
    />
    <path
      d="M151.635 44.0524C149.679 56.7076 138.735 66.3961 125.532 66.3961C125.036 66.3961 124.542 66.3755 124.057 66.3333C114.815 65.5843 107.55 57.8494 107.55 48.4171V44.0514H151.635V44.0524Z"
      fill="#93F9E7"
    />
    <path
      d="M151.635 44.0524C149.678 56.7076 138.735 66.3961 125.532 66.3961C125.035 66.3961 124.542 66.3755 124.057 66.3333C137.828 60.8887 140.706 50.4364 141.093 44.0514H151.635V44.0524Z"
      fill="#5CE4D9"
    />
    <path
      d="M151.948 10.5583V26.5661H119.391C108.348 26.5661 99.3979 17.6158 99.3979 6.57355V2.97459C99.3979 1.3312 100.729 0 102.374 0H141.39C142.408 0 143.388 0.142348 144.318 0.411338C148.724 1.68265 151.948 5.74498 151.948 10.5583Z"
      fill="#C87C5B"
    />
    <path
      d="M151.948 10.5584V26.5662H135.069C144.636 19.3398 144.903 6.79938 144.318 0.411377C148.724 1.68269 151.948 5.74502 151.948 10.5584Z"
      fill="#A46243"
    />
    <path
      d="M149.417 21.7645C143.968 21.7645 133.887 21.4759 124.535 19.6548C123.482 19.4497 122.794 18.4306 123 17.3773C123.205 16.3249 124.225 15.6386 125.277 15.8428C138.238 18.3668 153.158 17.8279 153.307 17.823C154.373 17.7827 155.28 18.6162 155.322 19.6882C155.363 20.7602 154.527 21.6624 153.456 21.7037C153.267 21.7105 151.776 21.7645 149.417 21.7645Z"
      fill="#CDE8E3"
    />
    <path
      d="M159.313 32.7175C159.313 36.7455 156.047 40.0116 152.019 40.0116C147.99 40.0116 144.725 36.7455 144.725 32.7175C144.725 28.6886 147.99 25.4234 152.019 25.4234C156.047 25.4234 159.313 28.6886 159.313 32.7175Z"
      fill="#FECAA8"
    />
    <path
      d="M152.025 41.9584C150.982 41.9584 149.936 41.7787 148.923 41.4194C147.913 41.0601 147.385 39.9498 147.744 38.9386C148.105 37.9285 149.215 37.4013 150.225 37.7596C151.572 38.2387 153.025 38.1651 154.316 37.5515C155.607 36.9379 156.583 35.858 157.062 34.5111C158.05 31.7299 156.593 28.6631 153.812 27.6745C152.466 27.1954 151.013 27.27 149.721 27.8826C148.43 28.4971 147.455 29.577 146.976 30.9239C146.616 31.9341 145.506 32.4613 144.496 32.103C143.485 31.7437 142.958 30.6334 143.317 29.6222C144.144 27.2975 145.825 25.4342 148.054 24.3759C150.282 23.3167 152.789 23.189 155.113 24.0156C159.912 25.7218 162.427 31.0143 160.721 35.8119C159.894 38.1366 158.211 39.9999 155.983 41.0591C154.725 41.657 153.378 41.9584 152.025 41.9584Z"
      fill="#FFB38C"
    />
    <path
      d="M133.411 36.7337C133.405 36.7337 133.4 36.7337 133.396 36.7337H133.363C132.29 36.7259 131.428 35.8492 131.436 34.7772C131.445 33.7042 132.316 32.8442 133.394 32.8501L133.426 32.851C134.498 32.8589 135.361 33.7356 135.352 34.8076C135.343 35.8747 134.475 36.7337 133.411 36.7337Z"
      fill="#A46243"
    />
    <path
      d="M115.189 36.7337C115.184 36.7337 115.178 36.7337 115.173 36.7337H115.141C114.069 36.7259 113.206 35.8492 113.213 34.7772C113.222 33.7042 114.093 32.8442 115.171 32.8501L115.203 32.851C116.275 32.8589 117.138 33.7346 117.13 34.8076C117.122 35.8747 116.254 36.7337 115.189 36.7337Z"
      fill="#A46243"
    />
    <path
      d="M126.478 17.7484C126.478 24.2817 121.182 29.578 114.649 29.578C108.116 29.578 102.819 24.2817 102.819 17.7484C102.819 11.2161 108.116 5.91974 114.649 5.91974C121.182 5.91974 126.478 11.2161 126.478 17.7484Z"
      fill="#5CE4D9"
    />
    <path
      d="M118.884 17.7484C118.884 20.0878 116.989 21.9845 114.649 21.9845C112.31 21.9845 110.413 20.0878 110.413 17.7484C110.413 15.41 112.31 13.5133 114.649 13.5133C116.989 13.5133 118.884 15.41 118.884 17.7484Z"
      fill="#FFE98A"
    />
    <path
      d="M144.723 69.6083C143.65 69.6083 142.781 70.4781 142.781 71.5501V83.5947C142.781 87.5128 139.593 90.6994 135.676 90.6994H127.346C123.429 90.6994 120.243 87.5118 120.243 83.5947V71.4323C120.243 70.3593 119.373 69.4905 118.301 69.4905C117.229 69.4905 116.359 70.3593 116.359 71.4323V83.5947C116.359 88.9912 120.27 93.4875 125.404 94.4073V113.991C125.404 118.607 129.16 122.362 133.775 122.362C138.391 122.362 142.147 118.607 142.147 113.991V106.53C142.147 105.457 141.277 104.589 140.205 104.589C139.133 104.589 138.263 105.457 138.263 106.53V113.991C138.263 116.465 136.25 118.478 133.775 118.478C131.302 118.478 129.288 116.464 129.288 113.991V94.5821H135.677C141.735 94.5821 146.664 89.6529 146.664 83.5947V71.5501C146.663 70.4781 145.795 69.6083 144.723 69.6083Z"
      fill="#628FB1"
    />
    <path
      d="M145.947 105.896C145.947 109.032 143.404 111.574 140.27 111.574C137.134 111.574 134.591 109.032 134.591 105.896C134.591 102.761 137.134 100.219 140.27 100.219C143.404 100.219 145.947 102.761 145.947 105.896Z"
      fill="#CDE8E3"
    />
    <path
      d="M155.324 104.408V128.675H151.44V111.978C153.038 109.56 154.314 107.013 155.324 104.408Z"
      fill="#00BCC1"
    />
    <path
      d="M-6.10352e-05 50.917C-6.10352e-05 58.966 6.5244 65.4895 14.5725 65.4895C22.6206 65.4895 29.145 58.966 29.145 50.917C29.145 42.8689 22.6206 36.3454 14.5725 36.3454C6.5244 36.3454 -6.10352e-05 42.8689 -6.10352e-05 50.917Z"
      fill="#A46243"
    />
    <path
      d="M11.8066 57.4591C11.9843 57.4591 12.1639 57.4345 12.3436 57.3835C17.2855 55.9659 19.0634 51.6945 19.1361 51.5128C19.5386 50.5184 19.0575 49.3874 18.064 48.9849C17.0735 48.5854 15.9475 49.0586 15.541 50.0442C15.4821 50.1816 14.3355 52.7724 11.2725 53.651C10.2418 53.9465 9.64585 55.0215 9.94135 56.0523C10.1858 56.9044 10.9623 57.4591 11.8066 57.4591Z"
      fill="#8C482B"
    />
    <path
      d="M-3.8147e-05 50.9179C-3.8147e-05 58.966 6.52344 65.4895 14.5715 65.4895C17.1956 65.4895 19.6607 64.7964 21.7881 63.5791C21.0547 63.691 20.3067 63.7509 19.5429 63.7509C11.4948 63.7509 4.96939 57.2245 4.96939 49.1764C4.96939 43.7583 7.92827 39.0284 12.3185 36.5182C5.34048 37.601 -3.8147e-05 43.6356 -3.8147e-05 50.9179Z"
      fill="#8C482B"
    />
    <path
      d="M4.32624 89.6976V119.904C4.32624 124.038 7.67682 127.388 11.8108 127.388H65.8403V89.6976C65.8403 80.0503 58.021 72.231 48.3737 72.231H21.7929C20.0857 72.231 18.4374 72.4754 16.8764 72.9329C9.62454 75.0563 4.32624 81.7585 4.32624 89.6976Z"
      fill="#5CE4D9"
    />
    <path
      d="M14.1847 96.5921V127.388H18.0684V96.5921C18.0684 95.8048 17.5991 95.1264 16.9266 94.824C16.6832 94.7131 16.4112 94.6503 16.1265 94.6503C15.0525 94.6503 14.1847 95.5201 14.1847 96.5921Z"
      fill="#09D6C2"
    />
    <path
      d="M4.32538 89.6976V119.904C4.32538 124.039 7.67793 127.389 11.8109 127.389H48.2227C29.6595 123.288 21.4082 111.803 18.0684 99.9064C17.5913 98.2129 17.2162 96.5126 16.9266 94.8241C15.4659 86.4079 16.0932 78.2832 16.8776 72.9319C9.62565 75.0573 4.32538 81.7575 4.32538 89.6976Z"
      fill="#09D6C2"
    />
    <path
      d="M14.1847 96.5921V127.388H18.0684V99.9064C17.5912 98.2129 17.2162 96.5126 16.9266 94.824C16.6832 94.7131 16.4112 94.6512 16.1265 94.6512C15.0525 94.6503 14.1847 95.5201 14.1847 96.5921Z"
      fill="#00BCC1"
    />
    <path
      d="M35.5497 81.5454C38.5901 81.5454 41.0542 79.0813 41.0542 76.0419V60.7242H30.0453V76.0419C30.0463 79.0813 32.5104 81.5454 35.5497 81.5454Z"
      fill="#FFB38C"
    />
    <path
      d="M51.8955 34.9268C51.8955 38.8085 55.0419 41.9549 58.9236 41.9549C62.8053 41.9549 65.9517 38.8085 65.9517 34.9268C65.9517 31.0451 62.8053 27.8978 58.9236 27.8978C55.0419 27.8978 51.8955 31.0451 51.8955 34.9268Z"
      fill="#8C482B"
    />
    <path
      d="M15.9538 20.5035V41.9254C15.9538 43.2606 16.0569 44.5702 16.2552 45.8484C18.1401 58.0432 28.6846 67.3783 41.4067 67.3783C50.9774 67.3783 58.7339 59.6218 58.7339 50.055V20.5035H15.9538Z"
      fill="#FECAA8"
    />
    <path
      d="M41.3685 49.892H46.1406C46.71 49.892 47.2509 49.6417 47.6201 49.2077C47.9892 48.7729 48.1492 48.1985 48.0569 47.636L46.338 37.1297C46.1642 36.0715 45.1658 35.3538 44.1075 35.5276C43.0492 35.7004 42.3316 36.6988 42.5054 37.7571L43.8552 46.0083H41.3685C40.2955 46.0083 39.4267 46.8772 39.4267 47.9502C39.4267 49.0222 40.2955 49.892 41.3685 49.892Z"
      fill="#FE9D7F"
    />
    <path
      d="M16.2552 45.8484C18.1401 58.0432 28.6847 67.3783 41.4067 67.3783C41.8857 67.3783 42.3609 67.3587 42.8282 67.3184C51.7333 66.5969 58.7339 59.1437 58.7339 50.055V45.8484H16.2552Z"
      fill="#93F9E7"
    />
    <path
      d="M16.2551 45.8484C18.14 58.0432 28.6846 67.3783 41.4066 67.3783C41.8857 67.3783 42.3608 67.3587 42.8281 67.3184C29.5583 62.0722 26.785 52.0008 26.4119 45.8484H16.2551Z"
      fill="#5CE4D9"
    />
    <path
      d="M33.8148 38.8674C33.8197 38.8674 33.8246 38.8674 33.8305 38.8674H33.8619C34.9339 38.8586 35.7969 37.9819 35.788 36.9099C35.7792 35.8428 34.9113 34.9838 33.8462 34.9838H33.8305L33.7991 34.9847C32.727 34.9926 31.8641 35.8693 31.8729 36.9413C31.8818 38.0084 32.7496 38.8674 33.8148 38.8674Z"
      fill="#8C482B"
    />
    <path
      d="M51.3729 38.8674C51.3788 38.8674 51.3837 38.8674 51.3886 38.8674H51.42C52.493 38.8586 53.355 37.9829 53.3461 36.9099C53.3383 35.8428 52.4704 34.9838 51.4053 34.9838C51.3994 34.9838 51.3945 34.9838 51.3896 34.9847H51.3582C50.2851 34.9936 49.4232 35.8693 49.432 36.9423C49.4399 38.0084 50.3077 38.8674 51.3729 38.8674Z"
      fill="#8C482B"
    />
    <path
      d="M12.5873 18.225V29.3242C12.5873 29.3242 18.9743 30.2843 26.5335 30.0154C34.5865 29.7307 43.9638 28.049 48.3747 22.3315C48.3747 22.3315 48.8056 25.417 52.922 27.5581C55.0277 28.6537 57.646 29.0798 59.5966 29.2408C61.1065 29.3655 62.3994 28.1756 62.3994 26.6608V18.225C62.3994 11.9273 57.2935 6.82141 50.9958 6.82141H23.9929C22.7834 6.82141 21.6181 7.00892 20.5235 7.35841C15.9202 8.82705 12.5873 13.1358 12.5873 18.225Z"
      fill="#A46243"
    />
    <path
      d="M12.5875 18.225V29.3242C12.5875 29.3242 18.9745 30.2843 26.5337 30.0153C15.1851 24.6169 17.9938 13.3527 20.5236 7.3584C15.9204 8.82704 12.5875 13.1358 12.5875 18.225Z"
      fill="#8C482B"
    />
    <path
      d="M8.85693 34.9268C8.85693 38.8085 12.0033 41.9549 15.885 41.9549C19.7667 41.9549 22.9131 38.8085 22.9131 34.9268C22.9131 31.0451 19.7667 27.8978 15.885 27.8978C12.0033 27.8978 8.85693 31.0451 8.85693 34.9268Z"
      fill="#FECAA8"
    />
    <path
      d="M15.8938 43.8977C16.8892 43.8977 17.9004 43.7308 18.8909 43.3784C19.9021 43.0191 20.4293 41.9088 20.07 40.8976C19.7107 39.8874 18.5994 39.3603 17.5902 39.7186C14.9464 40.6591 12.0327 39.2729 11.0922 36.6311C10.1527 33.9883 11.5379 31.0736 14.1807 30.1341C16.8234 29.1936 19.7381 30.5798 20.6776 33.2216C21.0369 34.2328 22.1473 34.7609 23.1584 34.4006C24.1686 34.0413 24.6968 32.931 24.3365 31.9208C22.6793 27.2606 17.5391 24.8181 12.8799 26.4743C10.622 27.2773 8.81266 28.9119 7.78383 31.0756C6.75499 33.2393 6.6313 35.6749 7.43434 37.9319C8.73903 41.6025 12.2045 43.8967 15.8938 43.8977Z"
      fill="#FFB38C"
    />
    <path
      d="M24.3782 91.7562V127.388H55.8862V91.7562C55.8862 90.3788 54.769 89.2617 53.3917 89.2617H26.8727C25.4944 89.2617 24.3782 90.3788 24.3782 91.7562Z"
      fill="#E5FCF9"
    />
    <path
      d="M24.3779 91.7562V127.388H45.7026C33.6502 117.514 31.3755 100.907 31.4727 89.2617H26.8724C25.4941 89.2617 24.3779 90.3788 24.3779 91.7562Z"
      fill="#CDE8E3"
    />
    <path
      d="M8.85389 122.353C8.85389 126.796 13.9028 130.397 20.1289 130.397C22.7598 130.397 25.1798 129.752 27.098 128.675C29.7182 127.202 31.4019 124.918 31.4019 122.353C31.4019 117.91 26.3529 114.309 20.1289 114.309C17.9279 114.309 15.8771 114.76 14.1434 115.536C10.9665 116.958 8.85389 119.48 8.85389 122.353Z"
      fill="#FECAA8"
    />
    <path
      d="M47.4618 122.353C47.4618 126.796 52.5078 130.397 58.7338 130.397C60.9348 130.397 62.9856 129.947 64.7203 129.17C67.8971 127.746 70.0068 125.227 70.0068 122.353C70.0068 117.91 64.9579 114.309 58.7338 114.309C57.1189 114.309 55.5835 114.553 54.1934 114.989C50.2293 116.235 47.4618 119.063 47.4618 122.353Z"
      fill="#FECAA8"
    />
    <path
      d="M8.854 122.353C8.854 126.796 13.9029 130.397 20.129 130.397C22.76 130.397 25.1799 129.752 27.0981 128.675C17.5088 126.661 14.7904 120.279 14.1435 115.536C10.9666 116.958 8.854 119.48 8.854 122.353Z"
      fill="#FFB38C"
    />
    <path
      d="M47.4617 122.353C47.4617 126.796 52.5077 130.397 58.7337 130.397C60.9347 130.397 62.9855 129.947 64.7202 129.17C53.7191 125.489 53.1576 119.119 54.1933 114.99C50.2292 116.235 47.4617 119.063 47.4617 122.353Z"
      fill="#FFB38C"
    />
  </svg>
);

export default Referrals;
