import { get } from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";

import { Dialog, FlatButton } from "material-ui";
import { connect } from "react-redux";
import TaskForm from "./task-form";
import { post, put, destroy } from "src/redux/modules/task-list";
import styles from "./task-dialog-style";
import { momentFormatTime, TASK_ASSIGNED_ROLE } from "../../config/constant";
import moment from "moment";

const modifiers = connect(
  null,
  { post, put, destroy }
);
class TaskDialog extends Component {
  static contextTypes = {
    patient: PropTypes.object,
    auth: PropTypes.object,
  };

  static propTypes = {
    open: PropTypes.bool,
    task: PropTypes.object,
    handleClose: PropTypes.func,
  };
  handleSubmit = task => {
    const ogTaskDetails = get(this.props, "task.details");
    if (!task.title) {
      return false;
    } // title is required. sanity check
    if (!task.due_date) {
      return false;
    } // duedate is required. sanity check
    if (!task.assigned_role) {
      return false;
    } // task type is required
    if (!task.task_type) {
      return false;
    } // task type is required
    const newTask = {
      id: task.id,
      due_date: task.due_date && task.due_date.toISOString(),
      assigned_role: task.assigned_role,
      task_type: task.task_type,
      category: task.category,
      is_complete: task.is_complete,
      title: task.title,
      priority: task.priority,
      details: { ...ogTaskDetails, note: task.note },
    };
    if (this.props.task) {
      // EDIT existing task
      this.props.put(newTask);
    } else {
      // if were creating, we need the context of the patient in scope
      newTask.user_id = get(this.context, "patient.id");
      newTask.author_id = get(this.context, "auth.id");
      this.props.post(newTask);
    }
    this.props.handleClose();
  };
  duplicate = () => {
    const task = this.props.task;
    const newTask = {
      ...task,
      id: null,
      user_id: task.patient.id,
      author_id: this.context.auth.id,
    };
    this.props.post(newTask);
    this.props.handleClose();
  };

  handleDelete = () => {
    this.props.destroy(this.props.task.id);
    this.props.handleClose();
  };

  render() {
    const task = this.props.task;
    // default all task to TC
    // default new tasks to be due today.
    // we can not have unassigned tasks otherwise they'll get lost and no one will do it. TC is first line of triage
    const initialValues = {
      ...task,
      due_date:
        (task && task.due_date && new Date(task.due_date)) || new Date(),
      note: get(task, "details.note"),
      assigned_role:
        (task && task.assigned_role) || TASK_ASSIGNED_ROLE.SALES_MANAGER,
      is_complete: (task && task.is_complete) || false,
    };
    return (
      <Dialog
        title="Task"
        autoScrollBodyContent
        open={this.props.open}
        onRequestClose={this.props.handleClose}
        contentClassName="full-width-dialog-content"
      >
        <div>
          {task && task.author && (
            <div>Last updated by: {task.author.name}</div>
          )}
          {task && task.updated_at !== task.created_at && (
            <div>
              Updated At: {moment(task.updated_at).format(momentFormatTime)}
            </div>
          )}
          {task && task.created_at && (
            <div>
              Created At: {moment(task.created_at).format(momentFormatTime)}
            </div>
          )}
          {task && task.completion_time && (
            <div>
              Completion Time:{" "}
              {moment(task.completion_time).format(momentFormatTime)}
            </div>
          )}
        </div>
        <TaskForm initialValues={initialValues} onSubmit={this.handleSubmit} />
        {task && (
          <div>
            <FlatButton
              style={styles.deleteButton}
              label="Delete"
              onClick={this.handleDelete}
            />
            <FlatButton label="Duplicate" onClick={this.duplicate} />
            <FlatButton
              style={styles.S.floatRight}
              label="Cancel"
              onClick={this.props.handleClose}
            />
          </div>
        )}
      </Dialog>
    );
  }
}

export default modifiers(TaskDialog);
