import { QUESTION_FORM } from "../event-type";
import { TREATMENT_TYPE_COMPREHENSIVE, RETAINER } from "src/config/constant";

// shaped like event

const ESTIMATE_TEMPLATE = {
  postTimestamp: null,
  postUserName: null,
  postUserUUID: null,
  complete: false,
  formName: "estimate", // this must match the name of the form in config/treatment-forms/index
  type: QUESTION_FORM,
  questionList: [
    {
      dataKey: "duration",
      displayName: "Estimated Duration of treatment",
      type: "select",
      possibleAnswerList: [
        "4-5 months",
        "6-8 months",
        "8-10 months",
        "10-12 months",
        "12-18 months",
        "over 18 months",
      ],
    },
    {
      dataKey: "additionalMessage",
      displayName: "Additional message to add to offer",
      type: "text",
    },
    {
      dataKey: "treatmentType",
      displayName: "Treatment Type",
      type: "select",
      possibleAnswerList: [TREATMENT_TYPE_COMPREHENSIVE, RETAINER],
    },
  ],
};

export default ESTIMATE_TEMPLATE;
