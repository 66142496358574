import { useEffect } from "react";
import { Action, Thunk, thunk, action } from "easy-peasy";
import { useStore, useActions } from "../hooks";
import { request } from "src/shared/util";
import { Appointment } from "src/types/api";
import { ApiRequestState } from "src/types/local";

export interface AppointmentState {
  data: { [id: string]: Appointment };

  fetchRequest: ApiRequestState;
  fetchByPatientRequest: ApiRequestState;

  setAppointments: Action<AppointmentState, Array<Appointment>>;
  addAppointment: Action<AppointmentState, Appointment>;
  setFetchRequest: Action<AppointmentState, ApiRequestState>;
  setFetchByPatientRequest: Action<AppointmentState, ApiRequestState>;

  fetch: Thunk<AppointmentState, string>;
  fetchByPatient: Thunk<AppointmentState, string>;
}

export const appointments: AppointmentState = {
  // Appointments keyed by ID.
  data: {},
  fetchRequest: { loading: false, error: false },
  fetchByPatientRequest: { loading: false, error: false },

  // Actions
  setAppointments: action((state, appts) => {
    appts.forEach(a => {
      state.data[a.id] = a;
    });
  }),

  addAppointment: action((state, appt) => {
    state.data[appt.id] = appt;
  }),

  setFetchRequest: action((state, to) => {
    state.fetchRequest = to;
  }),

  setFetchByPatientRequest: action((state, to) => {
    state.fetchByPatientRequest = to;
  }),

  // Effects

  // fetch returns a single appointment by ID
  fetch: thunk(async (actions, id) => {
    actions.setFetchRequest({ loading: true, error: false });
    const result = await request(`/api/v1/appointments/${id}`);

    if (result.error) {
      actions.setFetchRequest({
        loading: false,
        error: result.error,
      });
      return result.error;
    }

    actions.addAppointment(result);
    actions.setFetchRequest({ loading: false, error: false });
    return result;
  }),

  // return all appointments for a patient
  fetchByPatient: thunk(async (actions, patientId) => {
    actions.setFetchByPatientRequest({
      loading: true,
      error: false,
    });
    const result = await request(`/api/v1/appointments/patient/${patientId}`);

    if (result.error) {
      actions.setFetchByPatientRequest({
        loading: false,
        error: result.error,
      });
      return result.error;
    }

    actions.setAppointments(result);
    actions.setFetchByPatientRequest({
      loading: false,
      error: false,
    });
    return result;
  }),
};

export const usePatientAppointments = (
  patientId: string
): [Array<Appointment> | null, ApiRequestState] => {
  const appts = useStore(state => state.appointments);
  const fetchByPatient = useActions(
    actions => actions.appointments.fetchByPatient
  );
  useEffect(() => {
    fetchByPatient(patientId);
  }, [fetchByPatient, patientId]);

  // Find the clinical record that matches the given user ID and return it
  // as a tuple.
  const values = Object.values(appts.data).filter(a => a.user_id === patientId);
  return [values, appts.fetchByPatientRequest];
};
