import React, { useState } from "react";
import { DateTime } from "luxon";
import { capitalize, isEmpty, toPairs } from "lodash";
import Box from "src/shared/Box";
import { Select, DatePicker, notification } from "antd";
import moment from "moment";

import Modal from "src/shared/Modal";
import { Patient, WrappedPatientStatus } from "src/types/gql";
import { useAddPatientStatus, useUpdatePatientStatuses } from "./queries";
import Button, { ButtonRow } from "src/shared/Button";
import Table, { Header, Body } from "src/shared/Table";
import {
  ButtonStyle,
  headerItemStyle,
  bodyItemStyle,
  bodyRowStyle,
  NewStatus,
  NewStatusSelects,
  Container,
  Content,
  Footer,
  addNewButton,
} from "./styles";

type AsyncState = {
  state: "none" | "loading" | "error";
  error?: string | undefined;
};

interface Props {
  patient: Patient;
  patientStatuses: WrappedPatientStatus[];
  onClose: () => void;
}

const getAvailableTransitions = (patient, statuses) => {
  if (
    isEmpty(patient.statuses) ||
    isEmpty(statuses.filter(s => s.name === patient.statuses[0].name))
  )
    return ["new_patient"];

  return statuses
    .filter(s => s.name === patient.statuses[0].name)[0]
    .transitions.filter(value => value !== "not_eligible");
};

const PatientStatusEdit = (props: Props) => {
  const { patient, patientStatuses, onClose } = props;

  const availableTransitions = getAvailableTransitions(
    patient,
    patientStatuses
  );

  const addPatientStatus = useAddPatientStatus();
  const updatePatientStatuses = useUpdatePatientStatuses();

  const [isAddingNew, setIsAddingNew] = useState(false);

  const [newPatientStatus, setNewPatientStatus] = useState({
    name: isEmpty(availableTransitions) ? null : availableTransitions[0],
    start: moment(new Date()),
  });

  const setNewStatusStart = time => {
    setNewPatientStatus(status => ({ ...status, start: time }));
  };

  const setNewStatusName = name => {
    setNewPatientStatus(status => ({ ...status, name }));
  };

  const [statusesToUpdate, setStatusesToUpdate] = useState({}); // mapping of status ID to moment object

  const updateStatusTime = statusID => time => {
    setStatusesToUpdate(statuses => ({
      ...statuses,
      [statusID]: time,
    }));
  };

  const [error, setError] = useState<string | null>(null);

  const onOk = async () => {
    setError(null);

    if (isAddingNew) {
      const result = await addPatientStatus({
        input: {
          name: newPatientStatus.name,
          userID: patient.id,
          start: newPatientStatus.start.toISOString(),
        },
      });

      if (result.error) {
        setError(result.error.message);
        return;
      }
    }

    if (!isEmpty(statusesToUpdate)) {
      const statusPairs = toPairs(statusesToUpdate);
      const newStatuses = statusPairs.map(([id, start]) => ({
        id,
        start: start.toISOString(),
      }));

      const res = await updatePatientStatuses({
        input: {
          userID: patient.id,
          opts: newStatuses,
        },
      });

      if (res.error) {
        setError(res.error.message);
        return;
      }
    }

    notification.success({ message: "Status updated" });
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <Box title="Edit patient status">
        <Container>
          <Content>
            {isAddingNew && (
              <NewStatus>
                <b>Add new status</b>
                <br />
                <NewStatusSelects>
                  <Select
                    style={{ width: "200px" }}
                    defaultValue={newPatientStatus.name}
                    onChange={setNewStatusName}
                  >
                    {availableTransitions.map(s => (
                      <Select.Option key={s} value={s}>
                        {capitalize(s.replace("_", " "))}
                      </Select.Option>
                    ))}
                  </Select>
                  <DatePicker
                    value={newPatientStatus.start}
                    allowClear={false}
                    // @ts-ignore input will never be null since we don't allow clear
                    onChange={setNewStatusStart}
                  />
                </NewStatusSelects>
              </NewStatus>
            )}
            {!isAddingNew && (
              <Button
                className={addNewButton}
                kind="primary"
                onClick={() => setIsAddingNew(true)}
              >
                Add new status
              </Button>
            )}
            <b>Previous statuses</b>
            <br />
            <Table items={patient.statuses.slice().reverse()}>
              <Header>
                <Header.Item css={headerItemStyle}>Status</Header.Item>
                <Header.Item css={headerItemStyle}>Start</Header.Item>
                <Header.Item css={headerItemStyle} />
              </Header>
              <Body>
                {item => (
                  <Body.Row key={item.id} css={bodyRowStyle}>
                    <Body.Item css={bodyItemStyle}>
                      <div>{capitalize(item.name.replaceAll("_", " "))}</div>
                    </Body.Item>
                    <Body.Item css={bodyItemStyle}>
                      <DatePicker
                        value={statusesToUpdate[item.id] || moment(item.start)}
                        allowClear={false}
                        // @ts-ignore input will never be null since we don't allow clear
                        onChange={updateStatusTime(item.id)}
                      />
                    </Body.Item>
                    <Body.Item css={bodyItemStyle}>
                      <div>
                        {statusesToUpdate[item.id]
                          ? DateTime.fromISO(
                              statusesToUpdate[item.id].toISOString()
                            ).toRelative()
                          : DateTime.fromISO(item.start).toRelative()}
                      </div>
                    </Body.Item>
                  </Body.Row>
                )}
              </Body>
            </Table>
          </Content>
          <Footer>
            {error && <div>{error}</div>}
            <ButtonRow position="right">
              <Button className={ButtonStyle} key="back" onClick={onClose}>
                Cancel
              </Button>
              <Button
                className={ButtonStyle}
                key="submit"
                type="primary"
                onClick={onOk}
                disabled={isEmpty(statusesToUpdate) && !isAddingNew}
              >
                Update
              </Button>
            </ButtonRow>
          </Footer>
        </Container>
      </Box>
    </Modal>
  );
};

export default PatientStatusEdit;
