import React, { useState } from "react";
import styled, { css } from "react-emotion";
import color from "src/styles/color";
import Table, { Header, Body } from "src/shared/Table";
import { useGetAuditLogs } from "./queries";
import usePagination from "src/shared/usePagination";
import { Staff } from "src/types/gql";
import DownArrow from "src/shared/Icons/DownArrow";
import { toggle } from "src/shared/util";
import StaffPopover from "src/shared/Popovers/StaffPopover";
import { User } from "@/types/gql";
import { useSchedulingRestrictions } from "src/state/self";
import DetailedAuditModal from "../SchedulingV2/CalendarView/DetailedAuditLog";

const Perimeter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${color.background};
`;

const Inner = styled.div`
  cursor: pointer;

  svg {
    margin: 0 0 0 6px;
  }
`;

const FilterTag = styled.span`
  fontweight: normal;
`;

const NUM_COLUMNS = 4;

const headerItemStyle = css`
  width: calc(95% / ${NUM_COLUMNS});
`;

const bodyItemStyle = css`
  width: calc(95% / ${NUM_COLUMNS});
`;

const detailColumn = css`
  width: 5%;
`;

type Props = {};

const AuditLogs: React.FC<Props> = () => {
  const [page, setPage] = useState(1);
  const { isAdmin } = useSchedulingRestrictions();

  const [selectedAuditLog, setSelectedAuditLog] = useState<String | null>(null);
  const [toggleDetailedModal, setToggleDetailedModel] = useState<Boolean>(
    false
  );

  const [searchStaffIDs, setSearchStaffIDs] = useState<Staff[]>([] as Staff[]);
  const [searchIDs, setSearchIDs] = useState<String[]>([] as String[]);
  const [searchRequestIDs, setSearchRequestIDs] = useState<String[]>(
    [] as String[]
  );
  const [searchPatientIDs, setSearchPatientIDs] = useState<User[]>(
    [] as User[]
  );
  const [searchMethods] = useState<String[]>([] as String[]);
  const [searchJobs] = useState<String[]>([] as String[]);
  const [searchServices] = useState<String[]>([] as String[]);
  const [searchURLs, setURLs] = useState<String[]>([] as String[]);
  const [useFilter, setUseFilter] = useState<Object | null>(null);
  const [init, setInit] = useState<Boolean>(false);

  const [loading, , data] = useGetAuditLogs(page, useFilter);

  const query = `logs`;
  const auditLogs = usePagination(
    query,
    data && data.pageResults,
    data && data.auditLogs,
    loading,
    page,
    setPage
  );

  if (!isAdmin) {
    return null;
  }

  if (!init && window.location.search.length) {
    const id = window.location.search.split("=")[1];
    setSearchRequestIDs([id]);
    setSelectedAuditLog(id);
    setToggleDetailedModel(true);
    setInit(true);
  }

  const idFilter = ids => {
    if (ids.length) {
      return (
        <FilterTag>
          ids:{" "}
          {ids.map(id => (
            <button
              key={id}
              onClick={() => setSearchIDs(toggle(searchIDs, id))}
            >
              {id}
            </button>
          ))}
        </FilterTag>
      );
    }
    return null;
  };

  const requestIDFilter = ids => {
    if (ids.length) {
      return (
        <FilterTag>
          reqIDs:{" "}
          {ids.map(id => (
            <button
              key={id}
              onClick={() => setSearchRequestIDs(toggle(searchRequestIDs, id))}
            >
              {id}
            </button>
          ))}
        </FilterTag>
      );
    }
    return null;
  };

  const staffFilter = staff => {
    if (staff.length) {
      return (
        <FilterTag>
          staff:{" "}
          {staff.map(member => (
            <button
              key={member.id}
              onClick={() => setSearchStaffIDs(toggle(searchStaffIDs, member))}
            >
              {member.firstName} {member.lastName}
            </button>
          ))}
        </FilterTag>
      );
    }
    return null;
  };

  const patientFilter = patients => {
    if (patients.length) {
      return (
        <FilterTag>
          patients:{" "}
          {patients.map(patient => (
            <button
              key={patient.id}
              onClick={() =>
                setSearchPatientIDs(toggle(searchPatientIDs, patient))
              }
            >
              {patient.preferredName
                ? patient.preferredName
                : patient.firstName}{" "}
              {patient.lastName}
            </button>
          ))}
        </FilterTag>
      );
    }
    return null;
  };

  const urlFilter = urls => {
    if (urls.length) {
      return (
        <FilterTag>
          urls:{" "}
          {urls.map(url => (
            <button key={url} onClick={() => setURLs(toggle(searchURLs, url))}>
              {url}
            </button>
          ))}
        </FilterTag>
      );
    }
    return null;
  };

  const closeDetailedView = () => {
    setToggleDetailedModel(false);
    setSelectedAuditLog(null);
  };

  return (
    <Perimeter>
      {toggleDetailedModal ? (
        <DetailedAuditModal
          requestID={selectedAuditLog}
          onClose={closeDetailedView}
        />
      ) : null}
      <h1>Filters:</h1>
      <div>
        {idFilter(searchIDs)} {requestIDFilter(searchRequestIDs)}{" "}
        {staffFilter(searchStaffIDs)} {urlFilter(searchURLs)}{" "}
        {patientFilter(searchPatientIDs)}
      </div>
      <button
        style={{ background: useFilter ? "lightgreen" : "gainsboro" }}
        onClick={() => {
          if (useFilter === null) {
            setUseFilter({
              ids: searchIDs,
              staffIds: searchStaffIDs.map(staff => staff.id),
              patientIds: searchPatientIDs.map(patient => patient.id),
              clinicIds: [],
              requestIds: searchRequestIDs,
              methods: searchMethods,
              urls: searchURLs,
              jobs: searchJobs,
              services: searchServices,
            });
          } else {
            setUseFilter(null);
          }
        }}
      >
        Run filters
      </button>
      <Table items={auditLogs}>
        <Header>
          {/* <Header.Item css={headerItemStyle}>ID</Header.Item> */}
          <Header.Item css={headerItemStyle}>
            <StaffPopover
              onClick={(s?: Staff) => {
                if (!s) {
                  return;
                }
                setSearchStaffIDs(toggle(searchStaffIDs, s));
              }}
              value={searchStaffIDs}
            >
              <Inner>
                Staff{" "}
                <DownArrow
                  fill={searchStaffIDs.length > 0 ? color.primary : color.gray2}
                />
              </Inner>
            </StaffPopover>
          </Header.Item>
          <Header.Item css={headerItemStyle}>Patient</Header.Item>
          <Header.Item css={headerItemStyle}>URL</Header.Item>
          <Header.Item css={headerItemStyle}>Created At</Header.Item>
          <Header.Item css={detailColumn}>Detail</Header.Item>
        </Header>
        <Body>
          {log => (
            <Body.Row key={log.id}>
              <Body.Item
                css={bodyItemStyle}
                onClick={() =>
                  log.staff
                    ? setSearchStaffIDs(toggle(searchStaffIDs, log.staff))
                    : null
                }
              >
                {log.staffId
                  ? `${log.staff.firstName} ${log.staff.lastName}`
                  : log.staffId}
              </Body.Item>
              <Body.Item
                css={bodyItemStyle}
                onClick={() =>
                  log.patient
                    ? setSearchPatientIDs(toggle(searchPatientIDs, log.patient))
                    : null
                }
              >
                {log.patientId
                  ? `${
                      log.patient.preferredName
                        ? log.patient.preferredName
                        : log.patient.firstName
                    } ${log.patient.lastName}`
                  : log.patientId}
              </Body.Item>
              <Body.Item
                css={bodyItemStyle}
                onClick={() =>
                  log.service === "goworker"
                    ? setURLs(toggle(searchURLs, log.job))
                    : setURLs(toggle(searchURLs, log.url))
                }
              >
                {log.service === "goworker" ? `worker: ${log.job}` : log.url}
              </Body.Item>
              <Body.Item css={bodyItemStyle}>
                {new Date(log.createdAt).toLocaleString()}
              </Body.Item>
              <Body.Item
                css={detailColumn}
                onClick={() => {
                  setSelectedAuditLog(log.requestId);
                  setToggleDetailedModel(true);
                }}
              >
                <button>info</button>
              </Body.Item>
            </Body.Row>
          )}
        </Body>
      </Table>
    </Perimeter>
  );
};

export default AuditLogs;
