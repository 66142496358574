import React, { useState } from "react";
import useEditContext from "src/scenes/SchedulingV2/CalendarView/useEditContext";
import { isFunction } from "lodash";
import { Wrapper, Quarter, pointer } from "./styles";

type Props = {
  scheduleItem: any;
  rowIndex: number;
  renderPopup: (any) => any;
  updateSchedule: (boolean) => void;
};

/**
 * A slot represents an hour block for a given room.  This means that we have a specific
 * resource ID and offset for a slot, where the offset represents a time from 7am-6pm.
 *
 */
const ScheduleTile = ({
  scheduleItem,
  rowIndex,
  renderPopup,
  updateSchedule,
}: Props) => {
  // Each slot has four "quarter" elements that you can use to add an appointment.
  // These pre-fill the minutes to the nearest quarter picked.
  const [quarter, setQuarter] = useState<number | null>(null);
  const { editable, onUpdateEditable } = useEditContext();

  return (
    <Wrapper>
      <Quarter
        onClick={() => {
          onUpdateEditable(true);
          setQuarter(0);
          updateSchedule(true);
        }}
        className={editable ? pointer : ""}
      />
      <Quarter
        onClick={() => {
          onUpdateEditable(true);
          setQuarter(1);
          updateSchedule(true);
        }}
        className={editable ? pointer : ""}
      />
      <Quarter
        onClick={() => {
          onUpdateEditable(true);
          setQuarter(2);
          updateSchedule(true);
        }}
        className={editable ? pointer : ""}
      />
      <Quarter
        onClick={() => {
          onUpdateEditable(true);
          setQuarter(3);
          updateSchedule(true);
        }}
        className={editable ? pointer : ""}
      />

      {quarter !== null &&
        isFunction(renderPopup) &&
        renderPopup({ scheduleItem, rowIndex, quarter, setQuarter })}
    </Wrapper>
  );
};

export default ScheduleTile;
