import React from "react";
import { Button } from "antd";
import { Wrapper, buttonStyles } from "./styles";

type Props = {
  value: string;
  onChange: (EventTarget) => void;
  children: React.ReactElement[];
};

const ButtonGroup = (props: Props) => (
  <Wrapper>
    {props.children.map((element, idx) => {
      const isActive = element.props.value === props.value;
      return React.cloneElement(element, {
        key: idx,
        className: buttonStyles(isActive),
        onClick: () => props.onChange(element.props.value),
      });
    })}
  </Wrapper>
);

ButtonGroup.Button = Button;

export default ButtonGroup;
