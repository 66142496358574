import React, { useState, useCallback } from "react";
import styled from "react-emotion";
import { get } from "lodash";
import { Drawer } from "antd";
import color from "src/styles/color";
import time from "src/shared/time";
import { notification } from "antd";
import dayjs from "dayjs";
// local
import useCalendarViewContext from "../useCalendarViewContext";
import { getLabel } from "../util";
import EditScheduledSlot from "./EditScheduledSlot";
import { AppointmentWithOffset } from "./types";
import AppointmentDetail from "./AppointmentDetail";
import Slot from "./Slot";
import { getAppointmentName } from "src/shared/util";
import { useMutation } from "src/utils/http/gqlQuery";
import { updateAppointment } from "src/scenes/SchedulingV2/queries";

const Overflow = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${color.white};
`;

const Strong = styled.strong`
  color: ${color.white};
`;

const StyledDrawer = styled(Drawer)`
  & .ant-drawer-body {
    padding: 0;
  }
`;

const confirmedButtonStyle = {
  border: "none",
  color: "#27AE60",
  background: "rgba(95, 203, 141, 0.4)",
  borderRadius: "4px",
};

const pendingButtonStyle = {
  border: "none",
  color: "#979A9A",
  background: "#F3F3F3",
  borderRadius: "4px",
};

type Props = {
  appt: AppointmentWithOffset;
  width: string;
  left: string;
  height: string;
  top: string;
  getTopHeight: { top: string; height: string };
  isoToDateTime: Function;
  selected?: boolean;
  calendarView: string | undefined;
};

const specialType = ["staff_only", "misc_short", "misc_long"];

// Appt renders a single Appt appointment.
const ScheduledSlot = (props: Props) => {
  const { appt } = props;
  const [, update] = useMutation(updateAppointment);
  const [, setError] = useState("");
  const [edit, setEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [timeoutId, setTimeoutId] = useState([]);
  const { clinic, viewDoctorOnly } = useCalendarViewContext();
  const { doctorStartTime, doctorEndTime, startTime, endTime } = appt;
  const { toHour, now, toUTC, diffInSeconds } = time(clinic.timezone);

  const { top, height } = props.getTopHeight;

  const handleMouseEnter = useCallback(() => {
    timeoutId.map(t => clearTimeout(t));
    setTimeout(() => {
      setIsOpen(true);
    }, 100);
  }, [timeoutId]);

  const handleMouseLeave = () => {
    const id = setTimeout(() => {
      setTimeoutId([]);
      setIsOpen(false);
    }, 100);
    const newIds = [...timeoutId, id];
    // @ts-ignore
    setTimeoutId(newIds);
  };

  const openDrawer = () => setEdit(true);

  const nowTime = toUTC(now());

  const appointmentInProgress =
    appt.startTime &&
    appt.endTime &&
    diffInSeconds(nowTime, appt.startTime) > 0 &&
    diffInSeconds(nowTime, appt.endTime) < 0;
  const unbookedAppointment = !appt.startTime && !appt.endTime;
  const pastDoctorTime =
    appt.doctorEndTime && diffInSeconds(nowTime, appt.doctorEndTime) > 0;
  const unbookedNoShow = unbookedAppointment && pastDoctorTime;
  const pastAppointment =
    appt.endTime && diffInSeconds(nowTime, appt.endTime) > 0;
  const checkedIn = appt.actualStartTime && appt.actualEndTime;

  let status;
  if (appointmentInProgress) {
    status = "inprogress";
  } else if (unbookedNoShow) {
    status = "noshow";
  } else if (pastAppointment) {
    if (checkedIn) {
      status = "completed";
    } else {
      status = "noshow";
    }
  }

  const handleConfirmedAtToggle = async appt => {
    if (appt.confirmedAt === undefined || appt.confirmedAt === null) {
      let result = await update({
        appt: {
          id: appt.id,
          confirmedAt: true,
        },
      });

      if (result.error) {
        return notification.error({
          message: "Error updating patient confirmation",
        });
      }

      notification.success({
        message: `Appointment confirmed! ${appt.user.firstName} ${
          appt.user.lastName
        } is confirmed for ${dayjs(appt.startTime).format("dddd MMMM DD")} at ${
          appt.clinic.nickname
        }`,
      });
    } else {
      let result = await update({
        appt: {
          id: appt.id,
          clearConfirmedAt: true,
        },
      });

      if (result.error) {
        return notification.error({
          message: "Error updating patient confirmation",
        });
      }

      notification.success({
        message: `Appointment confirmation reverted!`,
      });
    }

    setError("");
  };

  const createConfirmationButton = (appt, calendarView) => {
    if (appt.userId) {
      if (calendarView === "Day") {
        if (appt.confirmedAt) {
          return (
            <button
              style={confirmedButtonStyle}
              onClick={() => handleConfirmedAtToggle(appt)}
            >
              Confirmed
            </button>
          );
        } else {
          return (
            <button
              style={pendingButtonStyle}
              onClick={() => handleConfirmedAtToggle(appt)}
            >
              Pending
            </button>
          );
        }
      } else if (calendarView === "Week") {
        if (appt.confirmedAt) {
          return (
            <button
              style={confirmedButtonStyle}
              onClick={() => handleConfirmedAtToggle(appt)}
            >
              C
            </button>
          );
        } else {
          return (
            <button
              style={pendingButtonStyle}
              onClick={() => handleConfirmedAtToggle(appt)}
            >
              P
            </button>
          );
        }
      }
    }

    return null;
  };

  return (
    <>
      <Slot
        appointment={appt}
        unbooked={!appt.userId}
        stripe={
          !appt.doctorId && appt.doctorDuration > 0 ? "doctor" : undefined
        }
        viewDoctorOnly={viewDoctorOnly}
        width={props.width}
        left={props.left}
        status={status}
        appointmentStyle={{
          top: props.top,
          height: props.height,
        }}
        doctorStyle={{
          top,
          height,
        }}
        onClick={openDrawer}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        selected={props.selected || edit}
      >
        <Slot.AppointmentContainer>
          {specialType.includes(appt.appointmentType.name) ? null : (
            <>
              <Overflow>
                <Strong style={{ color: color.font }}>
                  {createConfirmationButton(appt, props.calendarView)}{" "}
                  {appt.appointmentSubtype
                    ? getAppointmentName(appt.appointmentSubtype.name)
                    : getAppointmentName(appt.appointmentType.name)}{" "}
                  -{" "}
                  {appt.userId
                    ? `${
                        get(appt, "user.preferredName")
                          ? get(appt, "user.preferredName")
                          : get(appt, "user.firstName")
                      } ${get(appt, "user.lastName").charAt(0)}`
                    : "Unbooked"}
                </Strong>{" "}
              </Overflow>
              <Overflow style={{ color: color.font }}>
                {startTime ? `${toHour(startTime)} - ${toHour(endTime)}` : ""}
              </Overflow>
            </>
          )}
        </Slot.AppointmentContainer>
        <Slot.DoctorContainer>
          {specialType.includes(appt.appointmentType.name) ? (
            <>
              <strong>
                {appt.appointmentSubtype
                  ? appt.appointmentSubtype.name
                  : getLabel(appt.appointmentType)}
              </strong>{" "}
              <Overflow>
                <Strong>
                  {appt.doctorId
                    ? `Dr ${get(appt, "doctor.firstName")} ${get(
                        appt,
                        "doctor.lastName"
                      )}`
                    : "No doctor assigned"}
                </Strong>
              </Overflow>
              <Overflow>
                {`${toHour(doctorStartTime)} - ${toHour(doctorEndTime)}`}
              </Overflow>
            </>
          ) : (
            <>
              <Overflow>
                <Strong>
                  {appt.doctorId
                    ? `Dr ${get(appt, "doctor.firstName")} ${get(
                        appt,
                        "doctor.lastName"
                      )}`
                    : "No doctor assigned"}
                </Strong>
              </Overflow>
              <Overflow>
                {`${toHour(doctorStartTime)} - ${toHour(doctorEndTime)}`}
              </Overflow>
            </>
          )}
        </Slot.DoctorContainer>
      </Slot>

      {isOpen && (
        <AppointmentDetail
          appt={appt}
          top={props.top}
          width={props.width}
          height={props.height}
          left={props.left}
          toHour={toHour}
          openDrawer={() => {
            openDrawer();
            setIsOpen(false);
          }}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
        />
      )}

      <StyledDrawer
        width={650}
        closable={true}
        visible={edit}
        onClose={e => {
          e && e.stopPropagation();
          setEdit(false);
        }}
      >
        <EditScheduledSlot
          appt={appt}
          close={(e?: React.SyntheticEvent | undefined) => {
            // Prevent the parent wrapper from also receiving our event, if this
            // came from a click.
            e && e.stopPropagation();
            setEdit(false);
          }}
        />
      </StyledDrawer>
    </>
  );
};

export default ScheduledSlot;
