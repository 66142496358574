import React from "react";
import { cx } from "react-emotion";
import RightArrow from "src/shared/Icons/RightArrow";
import { arrow, expandedArrow } from "./styles";

const ExpandArrow = ({ expanded }) => (
  <RightArrow className={cx([arrow, expanded && expandedArrow])} />
);

export default ExpandArrow;
