import React from "react";
import styled from "react-emotion";
// shared
import LegacyModal from "src/shared/LegacyModal";
import Button from "src/shared/Button";
// styles
import textStyles from "src/styles/textStyles";
import TreatmentTimeline from "./TreatmentTimelineSvg";

const Title = styled.div`
  ${textStyles("xlarge")};
  font-weight: bold;
`;

const Perimeter = styled.div`
  display: flex;
  flex-direction: column;
  justify-contenet: space-between;
`;

const TreatmentTimelineModal = props => (
  <LegacyModal
    isOpen={props.isOpen}
    closeModal={props.handleClose}
    width="1040px"
  >
    <LegacyModal.Title>
      <Title>Treatment Timeline</Title>
    </LegacyModal.Title>
    <LegacyModal.Content>
      <Perimeter>
        <div style={{ marginBottom: "40px" }}>
          <TreatmentTimeline />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            kind="primary"
            onClick={props.handleDone}
            style={{ width: "100px", height: "36px" }}
          >
            Done
          </Button>
        </div>
      </Perimeter>
    </LegacyModal.Content>
  </LegacyModal>
);

export default TreatmentTimelineModal;
