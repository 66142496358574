import React from "react";
import { CrownProps, RootProps } from "./types";

export const Crown: React.SFC<CrownProps> = ({ fill, stroke, style }) => (
  <svg
    width="15"
    height="26"
    viewBox="0 0 15 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M11.8739 1H4.31745C1.81093 1 1.06143 2.92693 1 3.89039V9.67117C1 16.6081 2.72016 20.6306 3.58024 21.7747C4.87036 23.6414 8.15094 26.327 10.9523 22.136C13.7537 17.9449 14.2084 12.0798 14.0855 9.67117V3.16779C14.0855 1.43356 12.6111 1 11.8739 1Z"
      fill={fill || "#fff"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />
  </svg>
);

// Root always contains the portion as if it attached as a Crown.
export const Root: React.SFC<RootProps> = ({
  hasRootCanal,
  style,
  fill,
  stroke,
}) => (
  <svg
    width="13"
    height="55"
    viewBox="0 0 13 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M2.82733 25.5866C2.52726 22.3369 1.43761 11.2316 1 6.00006H3.5V1.00006H9.5V6.00006H12C12.5626 13.0165 10.5168 46.8205 7.51602 51.8059C4.51526 56.7912 4.32771 49.4055 4.32771 48.2977C4.32771 47.1898 3.20243 29.6488 2.82733 25.5866Z"
      fill={fill || "#F1F4F5"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />

    {hasRootCanal && (
      <g transform="translate(5,3)">
        <path
          d="M1 48.5C2.6 35.3 0.666667 6.5 0 1L3.5 0C2.7 16.8 2.16667 44.1667 1 48.5Z"
          fill="#C270A7"
        />
      </g>
    )}
  </svg>
);
