import styled from "react-emotion";
import color from "src/styles/color";
import Skeleton from "src/shared/Skeleton";

export const smSize = 16;
const maxSize = 28;

export const SkeletonRow = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  margin-top: 16px;
`;

export const SkeletonCircle = styled(Skeleton.Circle)`
  width: ${smSize}px;
  position: absolute;
  z-index: 2;
  left: ${({ theme: { percent } }) => `calc(${percent}% - ${smSize / 2}px)`};
`;

export const WhiteCircle = styled.div`
  width: ${smSize}px;
  height: ${smSize}px;
  border-radius: ${smSize}px;
  position: absolute;
  background-color: ${color.white};
  z-index: 1;
  left: ${({ theme: { percent } }) => `calc(${percent}% - ${smSize / 2}px)`};
`;

export const Perimeter = styled.div`
  padding-right: 32px;
  width: 100%;
  margin-bottom: 12px;
  margin-top: 38px;
`;

export const Row = styled.div`
  height: ${maxSize}px;
  padding: 0 ${({ theme: { size } }) => size / 2}px;
`;

export const InnerRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;
