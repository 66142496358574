import React from "react";
import styled from "react-emotion";
// shared
import LegacyModal from "src/shared/LegacyModal";
import AdverseHistory from "src/containers/treatment/components/adverse-history/adverse-history";
import textStyles from "src/styles/textStyles";

const Title = styled.div`
  ${textStyles("xlarge")};
  font-weight: bold;
`;

const QuestionnaireModal = ({ patient, handleClose }) => {
  return (
    <LegacyModal isOpen={true} closeModal={handleClose}>
      <LegacyModal.Title>
        <Title>Medical and Dental Form</Title>
      </LegacyModal.Title>
      <LegacyModal.Content>
        <AdverseHistory patient={patient} />
      </LegacyModal.Content>
    </LegacyModal>
  );
};

export default QuestionnaireModal;
