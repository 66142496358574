import React from "react";
import { CrownProps, RootProps } from "./types";

export const Crown: React.SFC<CrownProps> = ({ fill, stroke, style }) => (
  <svg
    width="30"
    height="20"
    viewBox="0 0 30 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M18.11 3.32829C16.1634 4.88537 14.0547 4.21299 13.2436 3.68217C10.6945 1.91277 5.00549 -0.316667 2.64181 4.92076C0.278136 10.1582 2.81561 15.0064 4.37981 16.7758C6.88252 19.8899 10.8683 19.3709 12.5484 18.7221C13.5333 18.1913 16.4067 17.342 20.0218 18.1913C24.5406 19.2529 27.8428 15.8911 28.538 13.237C29.2332 10.5828 29.407 4.74382 27.1476 2.26665C24.8882 -0.21051 20.5432 1.38195 18.11 3.32829Z"
      fill={fill || "#fff"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />
  </svg>
);

// Root always contains the portion as if it attached as a Crown.
export const Root: React.SFC<RootProps> = ({
  hasRootCanal,
  style,
  fill,
  stroke,
}) => (
  <svg
    width="31"
    height="47"
    viewBox="0 0 31 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M5.46247 26.3736C6.25059 20.1282 5.43784 10.7062 5 6H14V1H20V6H28.5C26.2013 11.8136 29.2701 15.0787 29.7626 22.8855C30.2552 30.6923 26.1504 38.0008 24.5085 40.3262C22.8666 42.6516 19.5828 44.6448 18.5977 42.4855C17.8096 40.758 19.3639 36.6719 20.2396 34.8448C21.0058 32.7409 22.1442 27.7689 20.568 24.7126C18.5977 20.8922 16.299 27.3702 15.6423 28.5329C14.9855 29.6956 8.74628 42.9838 3.65638 44.977C-0.415535 46.5716 1.08407 40.9906 2.34286 38.0008C3.05435 36.7273 4.67436 32.619 5.46247 26.3736Z"
      fill={fill || "#F1F4F5"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />

    {hasRootCanal && (
      <g transform="translate(2,3.5)">
        <path
          d="M0 42.5C9.5 31 9.16667 10.6667 8.5 2.5L24.5 0C23.02 18.5001 25.5615 29.7273 19.6193 38.4304C19.1584 39.1828 18.622 39.878 18 40.5C18.6 39.825 19.1375 39.136 19.6193 38.4304C24.4932 30.4729 20.9061 16.1243 20.5 14.5C20 12.5 18.9 9.5 16.5 9.5C14.1 9.5 12.8333 11.8333 12.5 13C7.3 26.2 7 34.5 0 42.5Z"
          fill="#C270A7"
        />
      </g>
    )}
  </svg>
);
