import { testPredicate } from "src/utils/optionality";
import { Predicate as OptionalityPredicate } from "src/utils/optionality/types";
import { Question, SnakedQuestion, Predicate } from "./types";

type GetAnswerFunc = (slug: string) => string | undefined;

export const calculateDisplayPredicate = (
  snaked: Question,
  getAnswer: GetAnswerFunc
) => {
  return (snaked.displayPredicates || []).every((p: Predicate) => {
    const answer = getAnswer(p.slug);
    return testPredicate(p as OptionalityPredicate, answer);
  });
};

export const calculateChoices = (
  snaked: SnakedQuestion,
  getAnswer: GetAnswerFunc
) => {
  return (snaked.choices || [])
    .filter(group => {
      if (typeof group === "string" || typeof group === "number") {
        return true;
      }

      // There may be one or more predicates, each for a different answer.
      const predicates = Array.isArray(group.predicates)
        ? group.predicates
        : [group.predicates];

      return predicates.every(p => {
        // Find the answer for this predicate
        const answer = getAnswer(p.slug);
        return testPredicate(p as OptionalityPredicate, answer);
      });
    })
    .map(group => {
      if (typeof group === "string" || typeof group === "number") {
        return true;
      }
      return group.choices;
    })
    .flat();
};
