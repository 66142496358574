import React from "react";
import { css } from "emotion";
import styled from "react-emotion";
import Tooth from "./Tooth";
import { ToothNumber } from "./types";
import color from "src/styles/color";

type Props = {
  editable: boolean;
};

const Chart: React.FC<Props> = props => {
  return (
    <ChartScroll>
      <ChartContent>
        <Upper>
          {[...new Array(16)].map((_, n) => {
            return (
              <Tooth
                key={n}
                toothNumber={(n + 1) as ToothNumber}
                editable={props.editable}
                ipr
                restrictToDisplay
              />
            );
          })}
        </Upper>
        <Lower>
          {[...new Array(16)].map((_, n) => {
            return (
              <Tooth
                key={n}
                toothNumber={(n + 17) as ToothNumber}
                editable={props.editable}
                ipr
                restrictToDisplay
              />
            );
          })}
        </Lower>
        <Top>Buccal</Top>
        <Bottom>Buccal</Bottom>
        <Left>Left</Left>
        <Right>Right</Right>
      </ChartContent>
    </ChartScroll>
  );
};

export default Chart;

const ChartScroll = styled.div`
  overflow: auto;
  position: relative;
  text-align: center;
`;

const ChartContent = styled.div`
  position: relative;
  background: #fff;
  text-align: left;

  /* Ensures items are centered if the grid is too small */
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`;

const gridCSS = css`
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  padding: 15px;
  background: #fff;
  position: relative;

  &:after {
    display: block;
    content: "";
    width: 1px;
    height: 215px;
    height: 100%;
    position: absolute;
    left: 50%;
    background: ${color.borderLight};
  }
`;

const Upper = styled.div`
  position: relative;
  ${gridCSS};

  border-bottom: 1px solid ${color.borderLight};
`;

const Lower = styled.div`
  ${gridCSS};
  direction: rtl;
`;

const surfaceCSS = `
  background: #fff;
  width: 80px;
  position: absolute;
  z-index: 1;
  font-size: .8rem;
  text-align: center;
  color: #72817C;
  text-transform: uppercase;
  text-align: center;
  display: block;
  padding: 3px 0;
`;

const Top = styled.span`
  top: 0;
  left: 50%;
  margin-left: -40px;
  ${surfaceCSS};
`;

const Bottom = styled.span`
  bottom: 0;
  left: 50%;
  margin-left: -40px;
  ${surfaceCSS};
`;

const Left = styled.span`
  top: 50%;
  margin-top: -13px;
  right: 0;
  ${surfaceCSS};
`;

const Right = styled.span`
  top: 50%;
  margin-top: -13px;
  left: 0;
  ${surfaceCSS};
`;
