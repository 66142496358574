import React from "react";
import { get } from "lodash";

export const findByType = (type, children) => {
  const element = React.Children.toArray(children).find(
    child => child.type === type
  );

  return element || null;
};

export const extractChildrenByType = (type, children) => {
  const element = findByType(type, children);

  return get(element, "props.children") || null;
};
