import React from "react";
import Dropdown from "src/shared/Dropdown";
import { Clinic } from "src/types/gql";
import { SelectedObj } from "src/types/types";
import orderBy from "lodash/orderBy";
import FlagIcon from "./icons/flag.svg";

type Props = {
  selected: string | null;
  onChange: (id: string) => void;
  clinics: Array<Clinic>;
  clearOption?: string;
  className?: string;
};

const REGION_GROUPS = new Map([
  ["san_francisco_bay_area", "California"],
  ["chicago", "Chicago"],
  ["seattle_bellevue", "Seattle"],
]);

const WEBSITE_CLINICS = new Set([
  // California
  "Financial District",
  "Smilecraft RWC",
  "Oakland Uptown",
  "Santa Clara",

  // Washington
  "Bellevue",
  "South Lake Union",

  // Chicago
  "Gold Coast",
  "The Loop",
  "Fresh Dental",
  "Fulton Market",
  "Wicker Park",
  "Evanston Downtown",

  // New York
  "Flatiron",
]);

const ClinicPicker = ({
  selected,
  onChange,
  clinics,
  clearOption,
  className,
}: Props) => {
  let clinicOptions: Array<SelectedObj> = orderBy(
    clinics.map((c: any) => {
      const label = c.nickname || c.name;
      let group = (REGION_GROUPS.get(c.region) || c.region).toUpperCase();
      if (!WEBSITE_CLINICS.has(label)) {
        group = "-ARCHIVED";
      }
      return {
        group,
        label,
        value: c.id,
        icon: c.type === "flagship" ? <img src={FlagIcon} alt="" /> : undefined,
      };
    }),
    ["label"]
  );

  if (clearOption) {
    clinicOptions = [{ label: clearOption, value: clearOption }].concat(
      clinicOptions
    );
  }

  return (
    <Dropdown
      className={className}
      menuMaxHeight="410px"
      options={clinicOptions}
      selected={clinicOptions.find(e => e.value === selected)}
      onSelect={e => {
        onChange(e.value);
      }}
    />
  );
};

export default ClinicPicker;
