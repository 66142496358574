import React from "react";
import styled from "react-emotion";

import { OperationResult, useClient } from "src/utils/http/gqlQuery";
import {
  useLocallyUnsyncedData,
  useLocalDeadLetter,
  useBackgroundSync,
} from "./hooks";
import {
  fetchSubmissions,
  createSubmission,
  cloneSubmission,
  editSubmission,
  updateSubmission,
  deleteSubmission,
  CreateSubmissionArgs,
  UpdateInput,
} from "./queries";
import { Submission as GQLSubmission } from "src/scenes/PatientProfile/Forms/GQLForms/types";

// Context
// =======
//
// Context provides a top level wrapper for our submission reducer so that we only
// have a single reducer for all pages.

type Context = {
  state: State;
  dispatch: (a: Action) => void;
  helpers: {
    setViewingSubmissions: (viewing: boolean) => void;
    newAnswer: (a: UnsyncedAnswer) => Promise<void>;
    newToothData: (td: UnsyncedToothData) => Promise<void>;

    // async gql stuff
    fetchSubmissions: (formID: string, userID: string) => Promise<void>;
    useSubmissions: (formID: string, userID: string) => GQLSubmission[];
    updateSubmission: (
      args: UpdateInput
    ) => Promise<OperationResult<{ updateSubmission: GQLSubmission }>>;
    createSubmission: (
      args: CreateSubmissionArgs
    ) => Promise<OperationResult<{ createSubmission: GQLSubmission }>>;
    cloneSubmission: (
      id: string
    ) => Promise<OperationResult<{ cloneSubmission: GQLSubmission }>>;
    editSubmission: (
      id: string
    ) => Promise<OperationResult<{ editSubmission: GQLSubmission }>>;
    deleteSubmission: (id: string) => Promise<any>;

    // get answer from locally unsynced then GQL stuff
    getAnswer: (submissionID: string, questionID: string) => string | undefined;
  };
};

// Create a new context for sharing Pipeline state modifiers.
export const SubmissionContext = React.createContext({} as Context);

// SubmissionWrapper should wrap all pages of the EMR to provide the submission state
// and reducer.
export const SubmissionWrapper: React.FC<{}> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, defaultState);
  const client = useClient();

  // load previous unsynced data from localstorage
  useLocallyUnsyncedData(dispatch);

  // Fetch previous dead letter items so that we do not overwrite.
  useLocalDeadLetter(dispatch);

  const toSync = useBackgroundSync(state, dispatch);

  const helpers = {
    setViewingSubmissions: (to: boolean) => {
      dispatch({ type: "viewing", to });
    },

    // useNewAnswer
    newAnswer: async (a: UnsyncedAnswer) => {
      dispatch({ type: "setUnsyncedAnswer", data: a });
    },

    // useNewToothData updates new tooth data.
    newToothData: async (td: UnsyncedToothData) => {
      dispatch({ type: "setUnsyncedToothData", data: td });
    },

    fetchSubmissions: async (formID: string, userID: string) => {
      dispatch({ type: "fetching", formID, to: true });
      const result = await fetchSubmissions(client, formID, userID);
      dispatch({ type: "fetching", formID, to: false });

      if (result.error || !result.data) {
        return;
      }
      dispatch({ type: "fetchSubmissions", data: result.data.submissions });
    },

    useSubmissions: (formID: string, userID: string): GQLSubmission[] => {
      const subs: GQLSubmission[] = [];
      state.submissions.forEach(s => {
        if (s.userID === userID && s.formID === formID) {
          subs.push(s);
        }
      });
      // Sort in order of latest => earliest
      return subs.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
    },

    getAnswer: (submissionID: string, questionID: string) => {
      // Is this unsynced?  If so, return that.
      const unsynced = state.unsynced.answers[submissionID];
      if (unsynced && unsynced.get(questionID)) {
        return (unsynced.get(questionID) as UnsyncedAnswer).answer;
      }

      const submission = state.submissions.get(submissionID);
      if (!submission) {
        return;
      }
      const answer = submission.answers.find(a => a.questionID === questionID);
      return answer && answer.answer;
    },

    createSubmission: async (args: CreateSubmissionArgs) => {
      dispatch({ type: "creating", formID: args.formID, value: true });
      const result = await createSubmission(client, args);
      dispatch({ type: "creating", formID: args.formID, value: false });

      if (result.error || !result.data) {
        return result;
      }
      dispatch({
        type: "fetchSubmissions",
        data: [result.data.createSubmission],
      });
      return result;
    },

    cloneSubmission: async (formID: string) => {
      dispatch({ type: "fetching", formID, to: true });
      const result = await cloneSubmission(client, formID);
      dispatch({ type: "fetching", formID, to: false });

      if (result.error || !result.data) {
        return result;
      }
      dispatch({
        type: "fetchSubmissions",
        data: [result.data.cloneSubmission],
      });
      return result;
    },

    editSubmission: async (formID: string) => {
      dispatch({ type: "fetching", formID, to: true });
      const result = await editSubmission(client, formID);
      dispatch({ type: "fetching", formID, to: false });

      if (result.error || !result.data) {
        return result;
      }

      dispatch({
        type: "fetchSubmissions",
        data: [result.data.editSubmission],
      });
      return result;
    },

    updateSubmission: async (input: UpdateInput) => {
      // NOTE: Do not bother dispatching `fetching` for updates,
      // causes jarring page reloads each time a form value is changed.
      const result = await updateSubmission(client, input);

      if (result.error || !result.data) {
        return result;
      }
      dispatch({
        type: "fetchSubmissions",
        data: [result.data.updateSubmission],
      });
      return result;
    },

    deleteSubmission: async (id: string) => {
      const result = await deleteSubmission(client, id);

      if (result.error || !result.data) {
        return result;
      }
      dispatch({
        type: "deleteSubmission",
        id,
      });
      return result;
    },
  };

  return (
    <SubmissionContext.Provider value={{ state, dispatch, helpers }}>
      {children}
      {(state.viewingSubmissions || toSync.length > 0) && (
        <SyncStatus>
          {toSync.length === 0 && <p>No answers to sync</p>}
          {toSync.length > 0 && (
            <p>
              Syncing 1 of {toSync.length} response{toSync.length > 1 && "s"}
            </p>
          )}
        </SyncStatus>
      )}
    </SubmissionContext.Provider>
  );
};

export const useSubmissionContext = () => React.useContext(SubmissionContext);

export default useSubmissionContext;

// Reducer
// =======

const defaultState: State = {
  submissions: new Map(),
  unsynced: {
    answers: {},
    toothData: {},
  },
  deadletter: [],

  syncing: {},
  fetching: {},
  creating: {},
  viewingSubmissions: false,
};

const reducer = (s: State, a: Action): State => {
  const newState = (() => {
    switch (a.type) {
      case "viewing":
        return { ...s, viewingSubmissions: a.to };
      case "fetchUnsynced":
        return { ...s, unsynced: a.data };
      case "setUnsyncedAnswer":
        const map = new Map(s.unsynced.answers[a.data.submissionID]);
        map.set(a.data.questionID, a.data);
        return {
          ...s,
          unsynced: {
            ...s.unsynced,
            answers: { ...s.unsynced.answers, [a.data.submissionID]: map },
          },
        };
      case "setUnsyncedToothData":
        return {
          ...s,
          unsynced: {
            ...s.unsynced,
            toothData: {
              ...s.unsynced.toothData,
              [a.data.submissionID]: a.data,
            },
          },
        };

      case "fetchSubmissions": {
        const map = new Map(s.submissions);
        a.data.forEach(s => map.set(s.id, s));
        return {
          ...s,
          submissions: map,
          loadingSubmissions: false,
        };
      }

      case "answerSynced": {
        const map = new Map(s.submissions);
        const submission = map.get(a.unsynced.submissionID);
        // unshift the answer to add it to the start of the answers list;
        // this will be the first answer returned from .find for the slug.
        if (submission) {
          if (typeof a.synced !== "undefined") {
            submission.answers.unshift(a.synced);
          }
          map.set(submission.id, submission);
        }
        const answers = new Map(s.unsynced.answers[a.unsynced.submissionID]);
        answers.delete(a.unsynced.questionID);

        return {
          ...s,
          submissions: map,
          unsynced: {
            ...s.unsynced,
            answers: {
              ...s.unsynced.answers,
              [a.unsynced.submissionID]: answers,
            },
          },
        };
      }

      case "toothDataSynced": {
        const map = new Map(s.submissions);
        const submission = map.get(a.unsynced.submissionID);
        if (!submission) {
          return s;
        }
        // unshift the answer to add it to the start of the answers list;
        // this will be the first answer returned from .find for the slug.
        submission.toothData = a.synced.data;
        map.set(submission.id, submission);

        const toothData = { ...s.unsynced.toothData };
        delete toothData[a.unsynced.submissionID];

        return {
          ...s,
          submissions: map,
          unsynced: {
            ...s.unsynced,
            toothData,
          },
        };
      }

      case "deleteSubmission": {
        const map = new Map(s.submissions);
        map.delete(a.id);
        return {
          ...s,
          submissions: map,
        };
      }

      case "syncing": {
        return { ...s, syncing: { ...s.syncing, [a.id]: a.syncing } };
      }

      case "fetching": {
        return { ...s, fetching: { ...s.fetching, [a.formID]: a.to } };
      }

      case "fetchDeadLetter": {
        return { ...s, deadletter: a.data };
      }

      case "deadletter": {
        return { ...s, deadletter: s.deadletter.concat(a.data) };
      }

      case "creating": {
        return { ...s, creating: { ...s.creating, [a.formID]: a.value } };
      }

      default:
        return s;
    }
  })();

  // Update localstorage for each action ran.
  if (window && window.localStorage && window.localStorage.setItem) {
    window.localStorage.setItem(
      "unsynced-submissions",
      JSON.stringify(newState.unsynced)
    );
    window.localStorage.setItem(
      "deadletter",
      JSON.stringify(newState.deadletter)
    );
  }

  return newState;
};

export type Action =
  | { type: "viewing"; to: boolean }
  | { type: "fetching"; formID: string; to: boolean }
  | { type: "fetchUnsynced"; data: UnsyncedData }
  | { type: "fetchDeadLetter"; data: SyncItem[] }
  | { type: "fetchSubmissions"; data: GQLSubmission[] }
  | { type: "deleteSubmission"; id: string }
  | { type: "setUnsyncedAnswer"; data: UnsyncedAnswer }
  | { type: "setUnsyncedToothData"; data: UnsyncedToothData }
  | { type: "answerSynced"; unsynced: UnsyncedAnswer; synced?: SyncedAnswer }
  | { type: "syncing"; id: string; syncing: boolean }
  | { type: "deadletter"; data: SyncItem }
  | { type: "creating"; formID: string; value: boolean }
  | {
      type: "toothDataSynced";
      unsynced: UnsyncedToothData;
      synced: SyncedToothData;
    };

export type Dispatch = (a: Action) => void;

export type State = {
  submissions: Map<ID, GQLSubmission>;
  // unsynced stores all unsynced data for all submissions.
  unsynced: UnsyncedData;
  syncing: {
    [localID: string]: boolean;
  };

  deadletter: SyncItem[];

  fetching: {
    [formID: string]: boolean;
  };

  creating: {
    [formID: string]: boolean;
  };

  viewingSubmissions: boolean;
};

export type UnsyncedData = {
  answers: {
    [submissionID: string]: Map<QuestionID, UnsyncedAnswer>;
  };
  toothData: {
    [submissionID: string]: UnsyncedToothData;
  };
};

export type ID = string;
export type QuestionSlug = string;
export type QuestionID = string;

export type SyncItem =
  | { type: "answer"; data: UnsyncedAnswer }
  | { type: "toothdata"; data: UnsyncedToothData };

// SyncedAnswer represents an answer that has been synced (saved) to the backend.
export type SyncedAnswer = {
  id: string;
  authorID: string;
  questionID: string;
  questionSlug: string;
  answer: string;
};

export type UnsyncedAnswer = {
  localID: string;
  attempts?: number;
  lastAttemptAt?: number; // unix epoch, ms

  submissionID: string; // denormalized for processing
  questionSlug: string; // denormalized for processing
  questionID: string; // denormalized;  required to create new answer via GQL
  answer: string;
  createdAt: string;
};

export type Answer = {
  // synced stores answers received from the GQL endpoint
  synced?: SyncedAnswer;

  // unsynced stores new answers from mutating the forms client side.  These
  // are stored in state so that we can use the new answers across the UI,
  // but have yet to be saved to the server.
  unsycned?: UnsyncedAnswer;
};

// ToothData stores synced and unsynced tooth data, with the created at time
// so that we can compare and show the latest changes.
export type ToothData = {
  synced?: SyncedToothData;
  unsynced?: UnsyncedToothData;
};

export type SyncedToothData = {
  data: string;
};

export type UnsyncedToothData = {
  localID: string;
  attempts?: number;
  lastAttemptAt?: number; // unix epoch, ms

  submissionID: string; // denormalized for removal
  data: string;
  createdAt: string; // local
};

const SyncStatus = styled.div`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.12);
  padding: 20px;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  background: #fff;
  border-radius: 3px;

  p {
    margin: 0;
  }
`;
