import isNil from "lodash/isNil";
import React from "react";
import { cx } from "react-emotion";

import { CheckboxProps } from "./types";
import { Wrapper, CheckboxView, HiddenInput, Label } from "./styles";

export const Checkbox: React.FC<CheckboxProps> = props => {
  const { label, value, onChange } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    onChange(isChecked);
  };

  return (
    <Wrapper>
      <CheckboxView className={cx([value && "checked"])} />
      <Label className={cx([isNil(label) && "hidden"])}>{label}</Label>
      <HiddenInput type="checkbox" checked={value} onChange={handleChange} />
    </Wrapper>
  );
};
