import React, { useState } from "react";
import { notification } from "antd";
import { DateTime } from "luxon";

import { useMutation } from "src/utils/http/gqlQuery";
import { Task, TaskReference, ReferenceManufacturingBuild } from "../../types";
import Button from "src/shared/Button";
import { Item, Wrapper, Row } from "./styled";

type Props = {
  task: Task;
  onChangeContent: (to: any) => void;
};

const supported = ["surecure", "denmat-test"];

const SendToManufacturer = props => {
  return (
    <Wrapper>
      {props.task.references.map(b => {
        return <Build r={b} onChangeContent={props.onChangeContent} />;
      })}
    </Wrapper>
  );
};

const Build = ({
  r,
  onChangeContent,
}: {
  r: TaskReference;
  onChangeContent: (to: any) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [, execute] = useMutation(sendGQL);

  const model = r.model as ReferenceManufacturingBuild;
  const { producer, createdAt } = model;

  const onSend = async () => {
    if (loading) {
      return;
    }
    setLoading(true);

    const result = await execute({ id: model.id });
    if (result.error) {
      notification.error({
        message: "There was an error starting the job to send this to the CM",
      });
      return;
    }

    notification.success({
      message: "Background job started to send to CM",
    });
  };

  if (supported.includes(producer.source.toLowerCase())) {
    return (
      <Row>
        <Button
          kind="invisible"
          onClick={() => onChangeContent(["build", model.id])}
        >
          <Item>
            {model.producer.source}: (created{" "}
            {DateTime.fromISO(createdAt).toFormat("DDD, t")})
          </Item>
        </Button>

        <Button type="primary" onClick={onSend}>
          Send to manufacturer
        </Button>
      </Row>
    );
  }

  return (
    <Wrapper>
      <Item>
        {producer.source}: (created{" "}
        {DateTime.fromISO(createdAt).toFormat("DDD, t")})
      </Item>
    </Wrapper>
  );
};

export default SendToManufacturer;

const sendGQL = `
mutation sendBuild($id: ID!) {
  sendBuild(id: $id) {
    id
  }
}
`;
