import { useEffect } from "react";
import { Action, Thunk, thunk, action } from "easy-peasy";
import { request } from "src/shared/util";
import { Clinic } from "src/types/api";
import { useActions, useStore } from "../hooks";

export interface ClinicState {
  data: Array<Clinic>;
  setData: Action<ClinicState, Array<Clinic>>;
  getClinics: Thunk<ClinicState>;
}

export const clinics: ClinicState = {
  data: [],

  setData: action((state, data: Array<Clinic>) => {
    state.data = data;
  }),

  getClinics: thunk(async actions => {
    const data = await request(`/api/v1/clinics`);
    if (Array.isArray(data)) {
      actions.setData(data as Array<Clinic>);
    }
  }),
};

export const useAutoFetchClinics = (): Array<Clinic> => {
  const fetch = useActions(actions => actions.clinics.getClinics);
  const clinics = useStore(state => state.clinics.data);
  useEffect(() => {
    fetch();
  }, [fetch]);
  return clinics;
};
