import { V, S } from "src/shared-styles";

const styles = {
  V,
  S,
  container: {
    ...S.contentBlock,
    overflow: "visible",
  },
  content: {
    fontSize: V.fontSmall,
    margin: "0 0 0 -26px",
    border: "none",
    padding: 0,
  },
  dueDate: {
    textAlign: "right",
    position: "absolute",
    top: 18,
    left: -18,
  },
  stepper: {
    paddingLeft: V.spacing,
  },
  step: {
    position: "relative",
  },
  stepButton: {
    textAlign: "left",
    cursor: "pointer",
  },
  completeButton: {
    padding: 0,
  },
  completeLabel: {
    fontSize: V.fontTiny,
    padding: 0,
  },
  titleTextField: {
    maxWidth: "100%",
  },
};

export default styles;
