import React from "react";
import color from "src/styles/color";

type Props = {
  fill?: string;
};

const Doctor = ({ fill = color.gray3 }) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4.37311V5.62689C0 6.08873 0.374462 6.46316 0.836341 6.46316H3.5365V9.16373C3.5365 9.62557 3.91096 10 4.37284 10H5.62735C6.08923 10 6.46369 9.62557 6.46369 9.16373V6.46339H9.16366C9.62554 6.46339 10 6.08896 10 5.62712V4.37334C10 3.9115 9.62554 3.53707 9.16366 3.53707H6.46369V0.836267C6.46373 0.374429 6.08927 0 5.62739 0H4.37288C3.911 0 3.53654 0.374429 3.53654 0.836267V3.5368H0.83638C0.374462 3.53684 0 3.91127 0 4.37311Z"
      fill={fill}
    />
  </svg>
);

export default Doctor;
