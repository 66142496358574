import set from "lodash/set";
import get from "lodash/get";

const LOAD = "clinicalRecord/LOAD";
const LOAD_SUCCESS = "clinicalRecord/LOAD_SUCCESS";
const LOAD_FAIL = "clinicalRecord/LOAD_FAIL";
const PUT_STAFF = "clinicalRecord/PUT";
const PUT_STAFF_SUCCESS = "clinicalRecord/PUT_SUCCESS";
const PUT_STAFF_FAIL = "clinicalRecord/PUT_FAIL";
const PUT_TOOTH = "clinicalRecord/PUT_TOOTH";
const PUT_TOOTH_SUCCESS = "clinicalRecord/PUT_TOOTH_SUCCESS";
const PUT_TOOTH_FAIL = "clinicalRecord/PUT_TOOTH_FAIL";
const POST_FORM_SUBMIT = "clinicalRecord/POST_FORM_SUBMIT";
const POST_FORM_SUBMIT_SUCCESS = "clinicalRecord/POST_FORM_SUBMIT_SUCCESS";
const POST_FORM_SUBMIT_FAIL = "clinicalRecord/POST_FORM_SUBMIT_FAIL";

const getClinicalRecordAPI = userId => {
  return `/api/v1/patients/${userId}/clinical_record`;
};

const initialState = {
  data: {},
};

export const selectSocialNote = state => {
  return get(state, "clinicalRecord.data.social_notes") || "";
};

// Note - calling this dataObj because we already have a data column to avoid confusion
export default function reducer(state = initialState, action = {}) {
  const newState = { ...state };
  switch (action.type) {
    case LOAD:
      break;
    case LOAD_SUCCESS:
      newState.data = action.result;
      newState.error = null;
      break;
    case LOAD_FAIL:
      newState.error = action.error;
      newState.data = null;
      break;
    case PUT_STAFF: // local state
      set(
        newState,
        "data.staff_submitted_data.eventStream",
        action.eventStream
      );
      break;
    case PUT_TOOTH: // local state
      set(newState, "data.dental_chart.eventStream", action.eventStream);
      break;
    default:
  }
  return newState;
}

export function load(userId) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => client.get(getClinicalRecordAPI(userId)),
  };
}

export function putToothChart(userId, eventStream) {
  return {
    types: [PUT_TOOTH, PUT_TOOTH_SUCCESS, PUT_TOOTH_FAIL],
    eventStream,
    promise: client =>
      client.put(getClinicalRecordAPI(userId), {
        data: { dental_chart: { eventStream } },
      }),
  };
}
export function putSocialNote(userId, social_notes) {
  return {
    types: [PUT_TOOTH, PUT_TOOTH_SUCCESS, PUT_TOOTH_FAIL],
    promise: client =>
      client.put(getClinicalRecordAPI(userId), { data: { social_notes } }),
  };
}
export function putStaffSubmittedData(userId, eventStream) {
  return {
    types: [PUT_STAFF, PUT_STAFF_SUCCESS, PUT_STAFF_FAIL],
    eventStream,
    promise: client =>
      client.put(getClinicalRecordAPI(userId), {
        data: { staff_submitted_data: { eventStream } },
      }),
  };
}

export function putClinicalRecord(userId, data) {
  return {
    types: [null, null, null],
    promise: client => client.put(getClinicalRecordAPI(userId), { data }),
  };
}

export function postFormSubmit(event) {
  return {
    types: [POST_FORM_SUBMIT, POST_FORM_SUBMIT_SUCCESS, POST_FORM_SUBMIT_FAIL],
    promise: client => client.post("/api/v1/form_submit", { data: event }),
  };
}

// Selectors

export const getPatientClinicalDataSelector = state => {
  return get(state, "clinicalRecord.data.patient_submitted_data", {});
};

export const getPatientQuestionnairesSelector = state => {
  return get(
    state,
    "clinicalRecord.data.patient_submitted_data.questionnaires",
    {}
  );
};
