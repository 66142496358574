import React from "react";
import gql from "graphql-tag";
import { notification } from "antd";

import { useMutation } from "src/utils/http/gqlQuery";
import useSchedulingContext from "src/scenes/SchedulingV2/useSchedulingContext";
import StaffAssignmentModal from "./StaffAssignmentModal";
interface Props {
  appointmentIds: Array<string>;
  clinicId: string;
  closeModal: () => void;
}

const setAppointmentDoctor = gql`
  mutation UpdateAppointment($input: UpdateAppointment!) {
    updateAppointment(appointment: $input) {
      id
      doctor {
        id
      }
    }
  }
`;

const DoctorAssignmentModal = (props: Props) => {
  const { doctorOptions } = useSchedulingContext();
  const [, updateAppointmentDoctor] = useMutation(setAppointmentDoctor);
  const { appointmentIds } = props;

  const onSubmit = (doctorId: string) => {
    appointmentIds.forEach(async appontmentId => {
      const result = await updateAppointmentDoctor({
        input: {
          id: appontmentId,
          doctorId,
        },
      });

      if (result.error) {
        notification.error({
          message: `There was an error assigning this doctor`,
        });
      }
    });

    notification.info({
      message: `Finished assigning doctor`,
    });
  };

  return (
    <StaffAssignmentModal
      staffType="doctor"
      onSubmit={onSubmit}
      staffOptions={doctorOptions}
      {...props}
    />
  );
};

export default DoctorAssignmentModal;
