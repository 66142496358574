import React from "react";
import color from "src/styles/color";
import Check from "src/shared/Icons/Check";
import {
  GreenRing,
  WhiteRing,
  InnerCircle,
  GrayCircle,
  SmWhiteCircle,
} from "./styles";

const smallerCircleSize = 20;

export const CheckCircle = props => (
  <WhiteRing {...props} theme={{ useShadow: true }}>
    <InnerCircle>
      <Check fill={color.white} />
    </InnerCircle>
  </WhiteRing>
);

export const TrayCircle = ({ children, ...rest }) => (
  <GreenRing {...rest}>
    <WhiteRing>
      <InnerCircle>{children}</InnerCircle>
    </WhiteRing>
  </GreenRing>
);

export const EmptyCircle = props => (
  <GrayCircle {...props}>
    <SmWhiteCircle />
  </GrayCircle>
);

export const EmptyFilledCircle = props => (
  <GreenRing {...props} theme={{ size: smallerCircleSize }}>
    <SmWhiteCircle />
  </GreenRing>
);
