// @flow
import React, { PureComponent } from "react";
import { Tabs, Radio } from "antd";
import styled, { css } from "react-emotion";
import {
  entryStages,
  entryTypes,
  entryTypesByStage,
  typeSpecificSettings,
} from "./util";
const { TabPane } = Tabs;

// type Props = {
//   stagesVisible?: Array<string>,
//   selectedType: ?string,
//   selectedStage: ?string,
//   onTypeSelect: (stage?: string, type?: string) => void,
//   onTypeSettingsSelect: (setting: Object) => void,
// };

export default class Toolbar extends PureComponent {
  render() {
    const {
      selectedStage,
      selectedType,
      onTypeSelect,
      stagesVisible,
    } = this.props;
    // Are there any settings for this "type" of procedure?  If so, we'll render
    // an option group for the settings.
    const settings = selectedType && typeSpecificSettings[selectedType];

    return (
      <Wrapper>
        <Tabs
          size="small"
          onChange={stage => {
            onTypeSelect(stage);
          }}
        >
          {// Iterate through each stage and render a tab pane for that stage.
          // This allows us to show "tools" for the tooth chart relating to whether
          // you're adding an antecedent (pre-existing) bridge, or a prescribed
          // attachment
          Object.keys(entryStages)
            .map(stage => {
              if (
                Array.isArray(stagesVisible) &&
                stagesVisible.indexOf(stage) === -1
              ) {
                return null;
              }
              // Get all types for this entry, ie. ensuring we don't show "root canal"
              // etc for a prescribed action.
              const types = entryTypesByStage[stage] || [];

              const buttons = types.map(type => (
                <Radio.Button
                  value={type}
                  key={type}
                  checked={selectedStage === stage && selectedType === type}
                >
                  {entryTypes[type]}
                </Radio.Button>
              ));

              return (
                <TabPane
                  tab={entryStages[stage]}
                  key={stage}
                  className={tabContent}
                  animated={false}
                  type="card"
                >
                  <Radio.Group
                    value={(selectedStage === stage && selectedType) || ""}
                    onChange={e => {
                      onTypeSelect(stage, e.target.value);
                    }}
                  >
                    {buttons}
                  </Radio.Group>

                  <div>
                    {settings &&
                      Object.keys(settings).map(item => {
                        const settingsButtons = settings[item].map(option => (
                          <Radio.Button value={option} key={option}>
                            {option}
                          </Radio.Button>
                        ));

                        return (
                          <Radio.Group
                            key={item}
                            className={settingsClass}
                            onChange={e => {
                              // Update the settings.
                              this.props.onTypeSettingsSelect({
                                [item]: e.target.value,
                              });
                            }}
                          >
                            {settingsButtons}
                          </Radio.Group>
                        );
                      })}
                  </div>
                </TabPane>
              );
            })
            .filter(Boolean)}
        </Tabs>
      </Wrapper>
    );
  }
}

const Wrapper = styled("div")`
  position: relative;
  margin: 1.5rem 0 0;

  @media (min-width: 1290px) {
    max-width: 1290px;
    margin: 1rem auto;

    &:after {
      content: "";
      position: absolute;
      display: block;
      top: 35px;
      left: 0;
      height: 1px;
      background: #e8e8e8;
    }
  }
`;

const tabContent = css`
  padding: 0 0 0.75rem;
  display: flex;
  justify-content: space-between;
`;

const settingsClass = css`
  & + & {
    margin-left: 1rem;
  }
`;
