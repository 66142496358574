import { combineReducers } from "redux";

import { reducer as form } from "redux-form";
import patientList from "./patient-list";
import taskList from "./task-list";
import clinicalRecord from "./clinical-record";
import wearSchedule from "./wear-schedule";
import auth from "./auth";
import chat from "./chat";
import serverConfig from "./server-config";
import questionnaire from "./questionnaire";
import structuredMessages from "./structured-messages";
import UI from "./UI";
import fileList from "./fileList";
import consent from "./consent";
import patient from "./patient";
import kit from "./kit";
import checkin from "./checkin";
import invoiceItem from "./invoice-item";
import order from "./order";
import globalError from "./global-error";
import insurance from "./insurance";
import { keaReducer } from "kea";

export default combineReducers({
  kea: keaReducer("kea"),
  scenes: keaReducer("scenes"),
  form,
  patientList,
  clinicalRecord,
  auth,
  chat,
  taskList,
  wearSchedule,
  serverConfig,
  questionnaire,
  structuredMessages,
  UI,
  fileList,
  consent,
  patient,
  kit,
  checkin,
  invoiceItem,
  order,
  globalError,
  insurance,
});
