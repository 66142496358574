import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import {
  useCreateUserAddress,
  useUpdateUserAddress,
} from "src/state/useAddresses";
import { DEFAULT_COUNTRY } from "src/utils/address";
import OldStateSelect from "src/shared/OldStateSelect";
import CountrySelect from "src/shared/CountrySelect";
import color from "src/styles/color";
import { UserAddress } from "src/types/gql";
import Input from "src/shared/InputText";
import Button from "src/shared/Button";
import { ButtonStyle } from "./styles";

type AddressFields = {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
};

type AsyncState = {
  state: "none" | "loading" | "error";
  error?: string | undefined;
};

type AddressState = {
  changed: boolean;
  userAddressID: string | undefined;
  address: AddressFields;
};

interface Props {
  patientId: string;
  visible: boolean;
  setVisible(visible: boolean): void;
  patientAddress: UserAddress;
}

const ShippingAddressEdit = (props: Props) => {
  const { patientId, setVisible, patientAddress: addr } = props;

  // Mutations
  const updateUserAddress = useUpdateUserAddress();
  const createUserAddress = useCreateUserAddress();

  const [status, setStatus] = useState<AsyncState>({ state: "none" });
  const [addrState, setAddress] = useState<AddressState>({
    address: {
      line1: "",
      city: "",
      state: "",
      postalCode: "",
      country: DEFAULT_COUNTRY.code,
    },
    changed: false,
    userAddressID: undefined,
  });
  // Form setters
  const onChangeAddress = field => (e: React.SyntheticEvent) => {
    setAddressField(field, (e.target as HTMLInputElement).value);
  };
  const setAddressField = (field: string, value: string) => {
    setAddress({
      changed: true,
      userAddressID: addrState.userAddressID,
      address: { ...addrState.address, [field]: value },
    });
  };

  useEffect(() => {
    if (addr) {
      const address = Object.assign({}, addr.Address);
      delete (address as any).__typename;

      setAddress({
        changed: false,
        address,
        userAddressID: addr.id,
      });
    }
  }, [addr]);

  const onOk = async () => {
    if (status.state === "loading") {
      return;
    }

    if (addrState.changed) {
      setStatus({ state: "loading" });

      if (addrState.userAddressID) {
        const addrResult = await updateUserAddress({
          input: {
            id: addrState.userAddressID,
            ...addrState.address,
          },
        });
        if (addrResult.error || !addrResult.data) {
          setStatus({
            state: "error",
            error: addrResult.error && addrResult.error.message,
          });
          return;
        }
      } else {
        const addrResult = await createUserAddress({
          input: {
            userID: patientId,
            type: "shipping",
            ...addrState.address,
          },
        });
        if (addrResult.error) {
          setStatus({
            state: "error",
            error: addrResult.error && addrResult.error.message,
          });
          return;
        }
        setStatus({ state: "none", error: undefined });
      }
      setVisible(false);
    }
  };

  return (
    <Modal
      visible={props.visible}
      destroyOnClose={true}
      onCancel={() => setVisible(false)}
      footer={[
        <Button
          className={ButtonStyle}
          key="back"
          onClick={() => setVisible(false)}
        >
          Cancel
        </Button>,
        <Button
          className={ButtonStyle}
          key="submit"
          type="primary"
          loading={status.state === "loading"}
          onClick={onOk}
        >
          Update
        </Button>,
      ]}
    >
      <Form>
        Shipping Address
        <Form.Item label="Line 1">
          <Input
            defaultValue={addrState.address.line1}
            onChange={onChangeAddress("line1")}
          />
        </Form.Item>
        <Form.Item label="Line 2">
          <Input
            defaultValue={addrState.address.line2}
            onChange={onChangeAddress("line2")}
          />
        </Form.Item>
        <Form.Item label="City">
          <Input
            defaultValue={addrState.address.city}
            onChange={onChangeAddress("city")}
          />
        </Form.Item>
        <Form.Item label="State">
          {addrState.address.country === "US" && (
            <OldStateSelect
              value={addrState.address.state}
              onChange={(state: string) => setAddressField("state", state)}
            />
          )}
          {addrState.address.country !== "US" && (
            <Input
              placeholder="State"
              onChange={onChangeAddress("state")}
              value={addrState.address.state}
            />
          )}
        </Form.Item>
        <Form.Item label="Postal Code">
          <Input
            defaultValue={addrState.address.postalCode}
            onChange={onChangeAddress("postalCode")}
          />
        </Form.Item>
        <Form.Item label="Country">
          <CountrySelect
            value={addrState.address.country}
            onChange={(country: string) => setAddressField("country", country)}
          />
        </Form.Item>
        {status.error && (
          <p style={{ color: color.red, textAlign: "right" }}>{status.error}</p>
        )}
      </Form>
    </Modal>
  );
};

export default ShippingAddressEdit;
