import React, { useState } from "react";
import styled from "react-emotion";
import { flowRight, filter } from "lodash";
import { useSchedulingRestrictions } from "src/state/self";
// Icons
import Save from "src/shared/Icons/Save";
import Template from "src/shared/Icons/Template";
import Add from "src/shared/Icons/Add";
import { Checkbox } from "antd";
import Cross from "src/shared/Icons/Cross";
import Edit from "src/shared/Icons/Edit";
import { toISODate } from "src/shared/time";
import { toggle } from "src/shared/util";
import PopoutButton from "src/shared/PopoutButton/PopoutButton";
import color from "src/styles/color";
// calendar
import Calendar, {
  Columns,
  ColumnsItem,
  ColumnHeader,
  Slots,
  Popup,
} from "src/shared/Calendar";
// local
import useEditContext from "./useEditContext";
import useCalendarViewContext from "./useCalendarViewContext";
import NewAppointment from "./NewAppointment";
import BaseSlots from "./Slots/Slots";
import TemplateModal from "./TemplateModal";
import AllAssignmentModal from "../AssignmentModals/AllAssignmentModal";
import { isAppt } from "./util";
import { startHour } from "./consts";

type Props = {
  calendarView: string | undefined;
};

const Fixed = styled.div`
  position: fixed;
  z-index: 100;
  right: 20px;
  bottom: 20px;
`;

const filterByDate = date => appointments =>
  filter(appointments, appointment => {
    if (isAppt(appointment)) {
      const { doctorStartTime } = appointment;
      return date.toISODate() === toISODate(doctorStartTime);
    }
    return date.toISODate() === appointment.date.toISODate();
  });

const filterByRoom = roomId => appointments =>
  filter(appointments, appointment => {
    if (isAppt(appointment)) {
      return roomId === appointment.bookableResource.id;
    }
    return roomId === appointment.bookableResourceId;
  });

const filterByDoctor = doctorId => appointments =>
  filter(appointments, appointment => appointment.doctorId === doctorId);

const DayView = (props: Props) => {
  const { isScheduleManager } = useSchedulingRestrictions();
  const {
    date,
    clinic,
    appointments,
    selectedDoctorId,
  } = useCalendarViewContext();
  const {
    state,
    onSave,
    showModal,
    setShowModal,
    onUpdateEditable,
    onNewTemplate,
  } = useEditContext();
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    [] as string[]
  );
  const [showAssignModal, setShowAssignModal] = useState(false);

  const columns = clinic.BookableResources.map(r => ({
    label: r.name,
    id: r.id,
  }));

  const filterAppointments = (roomId, appointments) =>
    flowRight(
      selectedDoctorId === "all" ? i => i : filterByDoctor(selectedDoctorId),
      filterByRoom(roomId),
      filterByDate(date)
    )(appointments);

  // If we're editing a template, the appointments should be only those that are
  // in the template. Similarly, for new templates show nothing on the cal.
  const renderable = state => {
    switch (state.editMode) {
      case "availability":
        return appointments;
      case "new-template":
        return [];
      case "edit-template":
        // TODO
        return [];
    }
    return [];
  };

  const schedule = columns.map(r => ({
    id: r.id,
    label: r.label,
    unsaved: filterAppointments(r.id, state.unsaved),
    appointments: filterAppointments(r.id, renderable(state)),
    selected: selectedColumns.includes(r.label),
  }));

  const selectedIDs = schedule
    .filter(s => selectedColumns.includes(s.label))
    .map(a => a.appointments)
    .flat()
    .map(a => a.id);

  return (
    <>
      {isScheduleManager && (
        <Fixed>
          <PopoutButton
            onClick={() => onUpdateEditable(!state.editable)}
            active={state.editable}
            icon={<Add />}
            activeIcon={<Cross fill={color.primary} />}
          >
            {selectedColumns.length > 0 && (
              <PopoutButton.Child
                key="assign"
                text="Assign"
                icon={<Edit fill="#fff" />}
                onClick={() => setShowAssignModal(true)}
              />
            )}
            <PopoutButton.Child
              key="template"
              text="Template"
              icon={<Template />}
              onClick={() => setShowModal(!showModal)}
            />
            <PopoutButton.Child
              key="save"
              text={
                state.editMode === "availability" ? "Save" : "Save template"
              }
              icon={<Save />}
              onClick={onSave}
            />
          </PopoutButton>
        </Fixed>
      )}

      {showAssignModal && (
        <AllAssignmentModal
          appointmentIds={selectedIDs}
          clinicId={clinic.id}
          closeModal={() => setShowAssignModal(false)}
        />
      )}
      {showModal && (
        <TemplateModal
          clinicId={clinic.id}
          date={date}
          onNewTemplate={(name: string) => onNewTemplate(name)}
          close={() => setShowModal(false)}
        />
      )}

      <Calendar
        columns={columns}
        schedule={schedule}
        dates={[date.toUTC().toISODate()]}
        timezone={clinic.timezone}
      >
        <Columns>
          {({ label }) => (
            <ColumnsItem key={label}>
              <ColumnHeader>
                {isScheduleManager && (
                  <Checkbox
                    checked={selectedColumns.includes(label)}
                    onChange={() => {
                      setSelectedColumns(toggle(selectedColumns, label));
                    }}
                  />
                )}
                <span>{label}</span>
              </ColumnHeader>
            </ColumnsItem>
          )}
        </Columns>
        <Slots>
          {({ unsaved, appointments, selected }) => (
            <BaseSlots
              unsaved={unsaved}
              appointments={appointments}
              selected={selected}
              calendarView={props.calendarView}
            />
          )}
        </Slots>
        {isScheduleManager && (
          <Popup>
            {({ scheduleItem, quarter, setQuarter, rowIndex }) =>
              state.editable ? (
                <NewAppointment
                  base={{
                    bookableResourceId: scheduleItem.id || "",
                    offset:
                      (rowIndex + startHour) * 60 * 60 + quarter * 3 * 5 * 60,
                    date,
                  }}
                  onHide={() => setQuarter(null)}
                  style={{ top: `calc(${quarter * 25}% + 5px)` }}
                />
              ) : null
            }
          </Popup>
        )}
      </Calendar>
    </>
  );
};

export default DayView;
