import React, { useState, useEffect } from "react";
import styled, { css } from "react-emotion";
import { get } from "lodash";
import { Tooltip, Tag, Modal } from "antd";
import textStyles from "src/styles/textStyles";
import color from "src/styles/color";
import InputText from "src/shared/InputText";
import Dropdown from "src/shared/Dropdown";
import DatePicker from "src/shared/DatePicker";
import Choice from "./Choice";
import ChoiceWithButtons from "./ChoiceWithButtons";
import MultiChoice from "./MultiChoice";
import TextArea from "./TextArea";
import TeethPicker from "./TeethPicker";
import TeethPickerWithLB from "./TeethPickerWithLB";
import GeneratedInput from "./GeneratedInput";
import InsuranceProviderPicker from "./InsuranceProviderPicker";
import PreviousAnswers from "src/scenes/Form/Submission/PreviousAnswers";
import { useFormsContext } from "../GQLForms/useFormsContext";
import { useFormDecorations } from "../formDecorations";

export const QuestionLabelText = styled.div`
  ${textStyles("large")};
  font-weight: bold;
  ${({ theme: { lightColor } }) =>
    lightColor &&
    css`
      color: ${color.gray3};
    `}
  margin-top: 16px;
  margin-bottom: 12px;
`;

export const QuestionLabel = props => {
  const { answer, question, submissionID } = props;
  const hasAnswer = typeof answer !== "undefined";
  const { patient } = useFormsContext();
  const [showPreviousAnswers, setShowPreviousAnswers] = useState(false);
  const labelStyle = {
    fontSize: question.question_type === "text" ? "16px" : undefined,
  };
  return (
    <>
      <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
        <QuestionLabelText
          theme={{ lightColor: props.lightLabel }}
          style={labelStyle}
        >
          {props.children}
        </QuestionLabelText>

        {question.question_type === "input_large" && hasAnswer && (
          <div>
            <Tag color="#2db7f5" onClick={() => setShowPreviousAnswers(true)}>
              View previous answers
            </Tag>
          </div>
        )}
      </div>

      {showPreviousAnswers && (
        <Modal
          title={"Previous Answers"}
          visible
          footer={null}
          onCancel={() => setShowPreviousAnswers(false)}
          width={675}
        >
          <PreviousAnswers
            submissionID={submissionID}
            q={question}
            patient={patient}
          />
        </Modal>
      )}
    </>
  );
};

const InputSelector = props => {
  const {
    question: {
      slug,
      question_type,
      default_placeholder: placeholder,
      choices: options,
    },
    answer,
    disabled,
    // submissionID is used to remove
    submissionID,
  } = props;
  const handleChange = props.handleChange || props.handleBlur;
  const formDecorations = useFormDecorations();
  const decoration = formDecorations.questionDecorations.get(slug);

  // value stores the updated value after editing from the submission itself.  we store
  // this in state so that we can always show the updated value without worrying about
  // the graphql cache showing old values as the post is in process.
  //
  // this is specifically important with checkboxes and buttons :)
  const [value, setValue] = useState();

  useEffect(() => {
    // when the submission changes, clear any edited values from state so that we show
    // only answers.
    setValue(undefined);
  }, [submissionID]);

  switch (question_type) {
    case "date": {
      return (
        <DatePicker
          disabled={disabled}
          allowBlank
          date={value || answer}
          onChange={value => {
            setValue(value);
            handleChange(value);
          }}
          timezone={""}
        />
      );
    }
    case "dependent_choice":
    case "choice": {
      // Note that dependent_choice has choices which change depending on their predicates.
      // Because this Question component has no access to answers (else everything would rerender
      // on change), the parent component should filter choices already.
      //
      // TODO: When we use a reducer to store all answers, this can grab the answers
      // from the reducer instead of relying on GQL caching.
      const minWidth = options.length > 5 ? "10px" : undefined;
      return (
        <ChoiceWithButtons
          disabled={disabled}
          options={options}
          value={value === undefined ? answer || "" : value}
          minWidth={minWidth}
          handleChange={v => {
            setValue(v);
            handleChange(v);
          }}
        />
      );
    }
    case "choice_with_input": {
      return (
        <Choice
          disabled={disabled}
          options={options}
          value={value === undefined ? answer || "" : value}
          withInput
          handleChange={v => {
            setValue(v);
            handleChange(v);
          }}
        />
      );
    }
    case "multichoice_with_input":
    case "dependent_multichoice_with_input":
      return (
        <MultiChoice
          withInput
          options={options}
          placeholder={placeholder}
          handleChange={handleChange}
          value={value === undefined ? answer : value}
          setValue={setValue}
          disabled={disabled}
        />
      );
    case "dependent_multichoice":
    case "multichoice":
      return (
        <MultiChoice
          type={question_type}
          options={options}
          placeholder={placeholder}
          handleChange={handleChange}
          value={value === undefined ? answer : value}
          setValue={setValue}
          disabled={disabled}
        />
      );
    case "input":
      return (
        <InputText
          style={{ maxWidth: "272px" }}
          placeholder={placeholder}
          onBlur={evt => {
            if (props.question.response_type === "integer") {
              handleChange(parseInt(evt.target.value, 10));
              return;
            }
            handleChange(evt.target.value);
          }}
          onChange={evt => {
            setValue(evt.target.value);
          }}
          value={value === undefined ? answer || "" : value}
          disabled={disabled}
        />
      );
    case "input_large": {
      let textareaValue = value === undefined ? answer || "" : value;
      if (!textareaValue.trim() && decoration && decoration.template) {
        textareaValue = decoration.template;
      }
      return (
        <TextArea
          style={{ maxWidth: "600px" }}
          placeholder={placeholder}
          onBlur={evt => handleChange(evt.target.value)}
          onChange={evt => setValue(evt.target.value)}
          value={textareaValue}
          disabled={disabled}
        />
      );
    }
    case "generated_input":
      return (
        <GeneratedInput
          style={{ maxWidth: "600px" }}
          placeholder={placeholder}
          handleChange={handleChange}
          value={value || answer}
        />
      );
    case "text":
      return null;
    case "dropdown":
      return (
        <Dropdown
          height="32px"
          width="272px"
          options={options}
          onSelect={s => {
            setValue(s);
            handleChange(s);
          }}
          selected={value || answer}
          placeholder={placeholder}
          disabled={disabled}
        />
      );
    case "teeth_picker":
      return (
        <TeethPicker
          options={options}
          value={value === undefined ? answer || [] : value}
          setValue={setValue}
          handleChange={handleChange}
          disabled={disabled}
        />
      );
    case "teeth_picker_with_lingual_buccal":
      return (
        <TeethPickerWithLB
          options={options}
          value={value === undefined ? answer || [] : value}
          setValue={setValue}
          handleChange={handleChange}
          disabled={disabled}
        />
      );
    case "dynamic_choice_dropdown":
      if (slug === "insurance_id") {
        return (
          <InsuranceProviderPicker
            value={answer}
            disabled={disabled}
            onChange={handleChange}
          />
        );
      } else {
        // rendering will be handled by separate component
        return null;
      }
    default:
      return <div>{question_type}</div>;
  }
};

const Question = props => {
  const formDecorations = useFormDecorations();

  if (!props.question) {
    return null;
  }

  if (props.question.isDeprecated && !props.answer) {
    return null;
  }

  const inputProps = { ...props };
  if (props.question.isDeprecated) {
    inputProps.disabled = true;
  }

  const style = {
    margin:
      get(props, "question.question_type") === "text" ? "16px 0 0" : "0 0 8px",
  };
  const { question } = props;

  if (
    !question ||
    question.display === false ||
    (["dependent_multichoice", "dependent_choice"].includes(
      props.question.question_type
    ) &&
      props.question.choices.length === 0)
  ) {
    return null;
  }

  const decoration = formDecorations.questionDecorations.get(question.slug);
  const questionText = question.question_text || question.questionText;
  const renderedTitle = (
    <QuestionLabel
      submissionID={props.submissionID}
      question={question}
      answer={props.answer}
      lightLabel={props.lightLabel}
    >
      {question.isRequired ? (
        <>
          <strong style={{ fontSize: "150%", color: color.primary }}>*</strong>
          {questionText}
        </>
      ) : (
        questionText
      )}
      {decoration && decoration.hint && (
        <>
          {" "}
          <strong>{`(${decoration.hint})`}</strong>
        </>
      )}
    </QuestionLabel>
  );
  return (
    <div style={style}>
      {props.tooltip ? (
        <Tooltip placement="topLeft" title={props.tooltip}>
          {renderedTitle}
        </Tooltip>
      ) : (
        renderedTitle
      )}

      <InputSelector {...inputProps} />
    </div>
  );
};

Question.defaultProps = {
  handleBlur: () => {
    console.error("no handleChange is set");
  },
};

export default Question;
