import React, { useState } from "react";
import styled from "react-emotion";
import Error from "src/shared/Error";
import Button, { ButtonRow } from "src/shared/Button";
import NewNote from "src/shared/NewNote";
import { useNotes } from "./queries";
import Note from "src/shared/Note";

type Props = {
  objectKind: string;
  objectID: string;

  hideNewNote?: boolean;
  onCreate?: () => void;

  chronological?: boolean;
  max?: number;
};

const InternalNotes: React.FC<Props> = props => {
  const [showAll, setShowAll] = useState(!props.max);
  const [{ fetching, error, data }, refetch] = useNotes(
    props.objectKind,
    props.objectID
  );

  if (fetching) {
    return null;
  }

  if (error || !data || !data.notes) {
    return <Error />;
  }

  const onCreate = () => {
    refetch();
    props.onCreate && props.onCreate();
  };

  const notes =
    props.max && !showAll ? data.notes.slice(0, props.max) : data.notes;

  return (
    <Wrapper
      style={{
        flexDirection: props.chronological ? "column-reverse" : "column",
      }}
    >
      {!props.hideNewNote && <NewNote {...props} onCreate={onCreate} />}

      <Notes
        style={{
          flexDirection: props.chronological ? "column-reverse" : "column",
        }}
      >
        {notes.map(n => (
          <Note note={n} key={n.id} />
        ))}
      </Notes>

      {props.max && !showAll && (
        <ButtonRow position="left">
          <Button
            kind="link-blue"
            onClick={() => setShowAll(true)}
            style={{ marginLeft: "-8px" }}
          >
            Show earlier comments
          </Button>
        </ButtonRow>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Notes = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
`;

export default InternalNotes;
