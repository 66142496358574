import React, { useState } from "react";
import Popover from "src/shared/Popover";
import { Staff } from "src/types/gql";
import StaffSearch from "./StaffSearch";

type Props = {
  value?: Staff[];
  onClick: (s?: Staff) => void;

  position?: "left" | "right" | "top" | "bottom" | undefined;
  align?: "end" | "center" | "start" | undefined;
};

const StaffPopover: React.FC<Props> = ({
  children,
  value,
  onClick,
  align,
  position,
}) => {
  const [visible, setVisible] = useState(false);

  const Content = (
    <StaffSearch value={value} onSelect={(s: Staff) => onClick(s)} />
  );

  return (
    <Popover
      content={Content}
      visible={visible}
      close={() => setVisible(false)}
      align={align}
      position={position}
    >
      <div
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setVisible(true);
        }}
      >
        {children}
      </div>
    </Popover>
  );
};

export default StaffPopover;
