import React, { useState } from "react";
import styled, { css } from "react-emotion";
import Modal from "src/shared/Modal";
import InputText from "src/shared/InputText";
import Box from "src/shared/Box";
import Button, { ButtonRow } from "src/shared/Button";
import { notification } from "antd";
import { InsurancePolicy, Patient } from "src/types/gql";
import { useCreate } from "./query";

type Props = {
  patient: Patient;
  policy: InsurancePolicy;
  onClose: () => void;
};

type FormState = {
  insurancePolicyId: string;
  lifetimeMax: number | null;
  lifetimeMaxRemaining: number | null;
  percent: number;
};

const NewEligibilityModal: React.FC<Props> = ({ policy, onClose }) => {
  const [lifetimeMax, setLifetimeMax] = useState<string | null>(null);
  const [lifetimeMaxRemaining, setLifetimeMaxRemaining] = useState<
    string | null
  >(null);
  const [percent, setPercent] = useState<number>(0);

  const create = useCreate();

  const onCreate = async evt => {
    evt.preventDefault();
    const resp = await create({
      input: {
        insurancePolicyId: policy.id,
        lifetimeMax:
          lifetimeMax &&
          parseFloat(lifetimeMax.toString().replace(/\$/, "")) * 100,
        lifetimeMaxRemaining:
          lifetimeMaxRemaining &&
          parseFloat(lifetimeMaxRemaining.toString().replace(/\$/, "")) * 100,
        percent,
      },
    });

    if (resp.error) {
      notification.error({
        message: "Unable to save",
        description: resp.error.toString(),
      });
      return;
    }

    notification.success({
      message: "Eligibility created",
    });
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <Box title="Save eligibility information">
        <InputContainer>
          <div>
            <Label>Lifetime maximum</Label>
            <InputText
              placeholder="Amount, in $"
              value={lifetimeMax}
              onChange={e => setLifetimeMax(e.target.value)}
            />
          </div>
          <div>
            <Label>Lifetime remaining</Label>
            <InputText
              placeholder="Amount, in $"
              value={lifetimeMaxRemaining}
              onChange={e => setLifetimeMaxRemaining(e.target.value)}
            />
          </div>
          <div>
            <Label>Percent</Label>
            <InputText
              placeholder="e.g. 50"
              onChange={e =>
                setPercent(parseInt(e.target.value.toString(), 10))
              }
            />
          </div>
        </InputContainer>

        <ButtonRow
          position="center"
          className={css`
            padding: 0 0 16px;
          `}
        >
          <Button onClick={onClose}>Cancel</Button>
          <Button htmlType="submit" type="primary" onClick={onCreate}>
            Save
          </Button>
        </ButtonRow>
      </Box>
    </Modal>
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-flow: column;
  padding: 30px;
`;

const Label = styled("label")`
  color: #728389;
  line-height: 2;
`;

export default NewEligibilityModal;
