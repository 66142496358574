export interface FileData {
  // each upload must have a local ID for deduplicating
  id: string;

  userID: string;
  file: File;
  filetype?: FiletypeStrings;
  subtype?: string;
  description?: string;
  internal?: boolean;

  uploadPercent?: number;

  onComplete?: (f: any) => void;
}

export enum Filetypes {
  Unknown,
  UpperScan,
  LowerScan,
  BiteScan,
  ClinicalPhoto,
  CBCTZip,
  CBCTDicom,
  CBCTPreview,
  PanoPreview,
  TreatmentPlanZip,
  Segmentation,
  UpperTray,
  LowerTray,
  TrayZips,
  Selfie,
  PatientConsent,
  DoctorSignature,
}

export type FiletypeStrings = keyof typeof Filetypes;

// filetypes stores a mapping of filetypes to their human counterparts
export const filetypes: { [key in FiletypeStrings]: string } = {
  Unknown: "Other/misc",
  UpperScan: "Upper scan",
  LowerScan: "Lower scan",
  BiteScan: "Bite scan",
  ClinicalPhoto: "Clinical Photo",
  CBCTZip: "CBCT Zip (zip of x-ray)",
  CBCTDicom: "CBCT Dicom file",
  CBCTPreview: "CBCT Photo",
  PanoPreview: "Panoramic image",
  TreatmentPlanZip: "Treatment plan zip",
  Segmentation: "Segmentation",
  UpperTray: "Upper tray STL",
  LowerTray: "Lower tray STL",
  TrayZips: "Tray zips (zip of STLs)",
  Selfie: "Selfie or Checkin",
  PatientConsent: "Patient consent PDF",
  DoctorSignature: "Doctor Signature",
};

export const validateFileData = (file: FileData): boolean => {
  if (!file.filetype) {
    return false;
  }
  return true;
};

// detectFiletype returns the most likely filetype for a given filename.
// This is not clever - we take the most common filenames in box and build
// a basic mapping, just to remove how often people need to manually add
// filetypes in batch uploads.
//
// One day when we can apply some nice things we can do something smarter
// like a trained model on all filetypes vs filenames.
export const detectFiletype = (
  filename: string
): FiletypeStrings | undefined => {
  const f = filename.toLowerCase();
  const ext = f.split(".").pop();

  switch (ext) {
    case "txp":
      // txp is our file extension for a treatment plan or segmentation
      if (f.match(/segment/i)) {
        return "Segmentation";
      }
      return "TreatmentPlanZip";
    case "dcm":
      // Typically, if this ends in .dcm it'll either be a scan or an x-ray file.
      // Person_foo_27062019_134439_001.dcm, or FILE_0001.dcm.
      if (f.match(/\d.\.dcm$/) !== null) {
        return "CBCTDicom";
      }
      // Unknown type.
      return;
    case "stl":
      // If the file ends in STL, it's either a scan or a tray. Let's figure out which
      // filetype it is.
      if (f.includes("scan")) {
        // Detect whether this is upper or lower
        if (f.includes("bite")) {
          return "BiteScan";
        }
        if (f.includes("maxillary") || f.includes("upper")) {
          return "UpperScan";
        }
        if (f.includes("mandibular") || f.includes("lower")) {
          return "LowerScan";
        }
        // Unknown
        return;
      }
      if (f.includes("maxillary")) {
        return "UpperTray";
      }
      if (f.includes("mandibular")) {
        return "LowerTray";
      }
      // Unknown
      return;
  }

  if (f.includes("pano")) {
    return "PanoPreview";
  }

  if (f.includes("consent")) {
    return "PatientConsent";
  }

  return;
};
