import React, { useEffect, useState, useContext } from "react";
import styled from "react-emotion";
import gql from "graphql-tag";
import { AutoComplete } from "antd";
import { pipe, subscribe } from "wonka";
import { debounce } from "lodash";

import { Context, createRequest } from "src/utils/http/gqlQuery";
import color from "src/styles/color";
import Item from "./Item";

type Props = {
  onChange: (p: BaseVerifiedSpecialist) => void;
  query?: any;

  style?: any;
  className?: string;
};

export type BaseVerifiedSpecialist = {
  id: string;
  type: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phone2: string;
  clinicName: string;
  address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
};

type SearchState = {
  fetching: boolean;
  error: boolean;
  data: Array<BaseVerifiedSpecialist>;
};

const Perimeter = styled.div`
  position: relative;

  svg {
    position: absolute;
    z-index: 1;
    top: 50%;
    margin-top: -6px;
    left: 12px;
  }
`;

const StyledAutoComplete = styled(AutoComplete)`
  height: 100%;
  width: 100%;
  autocorrect: "off";
  autocapitalize: "off";
  spellcheck: "false";

  & input {
    padding-left: 32px;
    border-radius: 2px !important;

    &:hover,
    &:focus {
      border-color: ${color.blue} !important;
    }

    &::placeholder {
      color: ${color.gray2};
    }
  }
`;

const defaultQuery = gql`
  query VerifiedSpecialists(
    $filter: VerifiedSpecialistFilter!
    $page: Int
    $perPage: Int
  ) {
    verifiedSpecialists(filter: $filter)
      @paginated(page: $page, perPage: $perPage) {
      verifiedSpecialists {
        id
        type
        firstName
        lastName
        email
        phone
        phone2
        clinicName
        address {
          id
          line1
          line2
          city
          state
          postalCode
          country
        }
      }
    }
  }
`;

const doSearch = debounce((input, results, setResults, client, query) => {
  if (input === "") {
    return;
  }

  setResults({ fetching: true, error: false, data: results.data });

  const request = createRequest(query, {
    filter: { search: input },
    page: 1,
    perPage: 25,
  });
  const { unsubscribe } = pipe(
    client.executeQuery(request),
    subscribe((data: any) => {
      if (
        data &&
        data.data &&
        data.data.verifiedSpecialists &&
        data.data.verifiedSpecialists.verifiedSpecialists
      ) {
        setResults({
          fetching: false,
          error: false,
          data: data.data.verifiedSpecialists.verifiedSpecialists.slice(0, 25),
        });
      }
    })
  );
  return unsubscribe;
}, 200);

const VerifiedSpecialistSearch = ({
  onChange,
  style,
  className,
  query,
}: Props) => {
  const client = useContext(Context);
  const [value, setValue] = useState("");

  const [results, setResults] = useState<SearchState>({
    fetching: false,
    data: [],
    error: false,
  });

  useEffect(() => {
    return doSearch(value, results, setResults, client, query || defaultQuery);
  }, [client, query, results, value]);

  return (
    <Perimeter style={style} className={className}>
      <StyledAutoComplete
        style={{ width: "100%" }}
        value={value}
        onChange={id => setValue(id as string)}
        optionLabelProp="title"
        onSelect={id => {
          const verifiedSpecialist = results.data.find(p => p.id === id);
          if (verifiedSpecialist) {
            onChange(verifiedSpecialist);
          }
        }}
        placeholder={"Enter name and click on the item to fill the form"}
      >
        {results.data.map(s => (
          <AutoComplete.Option key={s.id} value={s.id} title={s.clinicName}>
            <Item verifiedSpecialist={s} />
          </AutoComplete.Option>
        ))}
      </StyledAutoComplete>
    </Perimeter>
  );
};

export default VerifiedSpecialistSearch;
