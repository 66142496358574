import React, { useState } from "react";
import styled from "react-emotion";
import { notification, DatePicker, Popover } from "antd";
import moment from "moment";

import { useQuery } from "src/utils/http/gqlQuery";
import Box from "src/shared/Box";
import Button, { ButtonRow } from "src/shared/Button";
import MoneyInput from "src/shared/MoneyInput";
import Modal from "src/shared/Modal";
import { orderGql } from "src/scenes/Patient/BillingV2/Orders/query";
import {
  useEditInsuranceClaimsSubmission,
  useDeleteInsuranceClaimGql,
} from "../query";
import { InsuranceClaimsSubmission } from "src/types/gql";
import { claimAmount } from "src/utils/insurance";
import {
  InsuranceClaimStatusSelect,
  getAmountLabel,
} from "./RecordInsuranceClaimModal";

const Content = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;

  height: 400px;
  width: 600px;
`;

const InputLabel = styled.div`
  font-weight: bold;
  margin-top: 16px;
`;

const Footer = styled.div`
  padding: 16px 32px 32px 32px;
`;

const InputContainer = styled.div`
  padding: 16px 32px;
  flex: 1;
`;

const DateWrapper = styled.div`
  margin-top: 12px;
`;

const DisabledButton = styled(Button)`
  cursor: not-allowed;
  opacity: 0.5;
`;

type Props = {
  onClose: () => void;
  claim: InsuranceClaimsSubmission;
  patientID: string;
};

const Inner: React.FC<Props> = ({ onClose, claim, patientID }) => {
  const editClaim = useEditInsuranceClaimsSubmission();
  const deleteClaim = useDeleteInsuranceClaimGql();

  const [amount, setAmount] = useState(claimAmount(claim));
  const [status, setStatus] = useState(claim.status);
  const [dateSubmitted, setDateSubmitted] = useState<string | null>(
    claim.dateSubmitted
  );

  const [, refetch] = useQuery({
    query: orderGql,
    variables: { userID: patientID },
    pause: true,
  });

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const res = await editClaim({
      input: {
        claimID: claim.id,
        amount,
        dateSubmitted,
        status,
      },
    });

    if (res.error) {
      notification.error({
        message: "There was an error editing this insurance claim.",
      });
    } else {
      notification.success({ message: "Insurance claim edited!" });
      refetch();
      onClose();
    }
  };

  const onDelete = async () => {
    const res = await deleteClaim({ claimID: claim.id });
    if (res.error) {
      notification.error({
        message: "There was an error deleting this claim.",
      });
    } else {
      notification.success({ message: "Insurance claim deleted!" });
      refetch();
      onClose();
    }
  };

  return (
    <Content>
      <InputContainer>
        <div>
          <InputLabel>Status</InputLabel>
          <InsuranceClaimStatusSelect value={status} onChange={setStatus} />
          {status !== "denied" && (
            <>
              <InputLabel>{getAmountLabel(status)}</InputLabel>
              <MoneyInput value={amount} onChange={setAmount} />
            </>
          )}
          {status !== "unsubmitted" && (
            <DateWrapper>
              <InputLabel>Date submitted:</InputLabel>
              <DatePicker
                defaultValue={
                  claim.dateSubmitted ? moment(claim.dateSubmitted) : undefined
                }
                allowClear
                onChange={(_, dateString) => setDateSubmitted(dateString)}
              />
            </DateWrapper>
          )}
        </div>
      </InputContainer>
      <Footer>
        <ButtonRow position="right">
          {claim.chargesTotal > 0 ? (
            <Popover content="You cannot delete this claim because there are associated charges.">
              <DisabledButton kind="default">Delete claim</DisabledButton>
            </Popover>
          ) : (
            <Button kind="default" onClick={onDelete}>
              Delete claim
            </Button>
          )}

          <Button kind="default" onClick={onClose}>
            Cancel
          </Button>
          <Button kind="primary" onClick={submit}>
            Submit
          </Button>
        </ButtonRow>
      </Footer>
    </Content>
  );
};

const EditInsuranceClaimModal: React.FC<Props> = props => {
  return (
    <Modal onClose={props.onClose}>
      <Box title="Edit insurance claim">
        <Inner {...props} />
      </Box>
    </Modal>
  );
};

export default EditInsuranceClaimModal;
