import React from "react";

export default ({ size = 16, fill = "#A2A9AD" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1622 0H3.83779C1.72142 0 0 1.72142 0 3.83779V12.1622C0 14.2786 1.72142 16 3.83779 16H12.1622C14.2786 16 16 14.2786 16 12.1622V3.83836C16 1.722 14.278 0 12.1622 0ZM3.83779 14.8491C3.48314 14.8491 3.14634 14.7766 2.83603 14.6511C2.83142 14.5613 2.82106 14.4726 2.82106 14.3816C2.82106 12.5313 3.83606 10.8208 5.42507 9.90249C6.10903 10.5185 7.01004 10.8973 8.00029 10.8973C8.99111 10.8973 9.89212 10.5185 10.5755 9.90249C12.1651 10.8213 13.1795 12.5313 13.1795 14.3822C13.1795 14.4732 13.1703 14.5613 13.1663 14.6511C12.8554 14.7766 12.518 14.8497 12.1628 14.8497H3.83779V14.8491ZM7.99971 9.74531C6.50916 9.74531 5.29668 8.53284 5.29668 7.04228C5.29668 5.55173 6.50916 4.33867 7.99971 4.33867C9.49027 4.33867 10.7027 5.55115 10.7027 7.04228C10.7027 8.53284 9.49027 9.74531 7.99971 9.74531ZM14.8485 12.1622C14.8485 12.7696 14.6384 13.324 14.2976 13.7742C14.1053 11.811 12.9947 10.032 11.3182 8.99399C11.6567 8.42057 11.8548 7.75503 11.8548 7.04228C11.8548 4.9167 10.1253 3.18722 8.00029 3.18722C5.87528 3.18722 4.1458 4.9167 4.1458 7.04228C4.1458 7.75503 4.34385 8.42057 4.68238 8.99399C3.00644 10.0314 1.89529 11.8116 1.703 13.7742C1.36217 13.324 1.15203 12.7696 1.15203 12.1622V3.83836C1.15203 2.35702 2.35702 1.15203 3.83836 1.15203H12.1628C13.6441 1.15203 14.8491 2.35702 14.8491 3.83836V12.1622H14.8485Z"
      fill={fill}
    />
  </svg>
);
