import React, { useState } from "react";
import styled from "react-emotion";
import textStyles from "src/styles/textStyles";
import color from "src/styles/color";
// Local
import NewAppointment from "../NewAppointment";
import { getLabel } from "../util";
import { EmptySlotWithOffset } from "./types";
import Slot from "./Slot";

const Edit = styled.div`
  cursor: pointer;
  z-index: 4;
  position: absolute;
  border-radius: 4px;
  border: 1px solid ${color.white};
  transition: all 0.1s;
  ${textStyles("medium")};
  background: transparent;
  ${textStyles("large")};
`;

const Text = styled.div`
  color: ${color.white};
`;

interface Props {
  appt: EmptySlotWithOffset;
  height: string;
  top: string;
  width: string;
  left: string;
  drTop: string;
  drHeight: string;
}

// TODO: ???
const specialType = ["staff_only", "misc_short", "misc_long"];

const StagedSlot = (props: Props) => {
  const { appt } = props;
  const [edit, showEdit] = useState(false);

  return (
    <>
      <Slot
        appointment={appt}
        width={props.width}
        left={props.left}
        appointmentStyle={{
          top: props.top,
          height: props.height,
        }}
        doctorStyle={{
          top: props.drTop,
          height: props.drHeight,
        }}
        stripe="appointment"
        isStaged
        onClick={() => showEdit(true)}
      >
        <Slot.AppointmentContainer>
          {specialType.includes(appt.appointmentType.name) ? null : (
            <strong>{getLabel(appt.appointmentType)}</strong>
          )}
        </Slot.AppointmentContainer>
        <Slot.DoctorContainer>
          {specialType.includes(appt.appointmentType.name) ? (
            <>
              <strong>{getLabel(appt.appointmentType)}</strong>
              <Text>
                {appt.doctorId
                  ? "Doctor time"
                  : "Doctor time (no doctor assigned)"}
              </Text>
            </>
          ) : (
            <Text>
              {appt.doctorId
                ? "Doctor time"
                : "Doctor time (no doctor assigned)"}
            </Text>
          )}
        </Slot.DoctorContainer>
      </Slot>
      {edit && (
        <Edit
          css={{
            height: props.height,
            top: props.top,
            width: props.width,
            left: props.left,
          }}
        >
          <NewAppointment existing={appt} onHide={() => showEdit(false)} />
        </Edit>
      )}
    </>
  );
};

export default StagedSlot;
