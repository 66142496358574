import React, { Component } from "react";
import PropTypes from "prop-types";

import { Dialog, FlatButton, RaisedButton } from "material-ui";
import WearScheduleList from "../wear-schedule-list/wear-schedule-list";

export default class ModifyWearScheduleButton extends Component {
  static propTypes = {
    patient: PropTypes.object,
  };
  static contextTypes = {
    patient: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
    };
  }
  handleOpen = () => {
    this.setState({ showDialog: true });
  };

  handleClose = () => {
    this.setState({ showDialog: false });
  };

  render() {
    return (
      <div>
        <RaisedButton
          disabled={this.props.disabled}
          secondary
          label="Modify Wear Schedule"
          onClick={this.handleOpen}
        />
        <Dialog
          title="Modify Wear Schedule"
          actions={<FlatButton label="Done" onClick={this.handleClose} />}
          modal={false}
          open={this.state.showDialog}
          onRequestClose={this.handleClose}
          autoScrollBodyContent
          contentClassName="full-width-dialog-content"
        >
          <WearScheduleList patientId={this.props.patient.id} />
        </Dialog>
      </div>
    );
  }
}
