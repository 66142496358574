import React from "react";
import { countryNames } from "src/utils/address";

type Props = {
  onChange: (country: string) => void;
  value: string;
  className?: string;
};

const CountrySelect: React.FC<Props> = ({ onChange, value, ...rest }) => {
  const handleChange = (e: React.FormEvent<HTMLSelectElement>) =>
    onChange(e.currentTarget.value);

  return (
    <select value={value} onChange={handleChange} {...rest}>
      {countryNames.map(({ name, code }) => (
        <option key={name} value={code}>
          {name}
        </option>
      ))}
    </select>
  );
};

export default CountrySelect;
