import React from "react";
import { get } from "lodash";
import BeginningVisit from "./BeginningVisit/index";
import ViewForm from "src/scenes/Form/Form";
import styled from "react-emotion";
import color from "src/styles/color";

const Container = styled.div`
  border: 1px solid ${color.border};
  background: ${color.white};
`;

const Forms = props => {
  const submissionId = get(props, "params.submissionId");

  if (get(props, "params.formKey") === "beginning_visit") {
    return (
      <Container>
        <BeginningVisit
          key={submissionId}
          patient={props.patient}
          patientID={props.patient.id}
          submissionId={submissionId}
        />
      </Container>
    );
  }

  return <ViewForm {...props} />;
};

export default Forms;
