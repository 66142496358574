import React from "react";
import { Blank, BlankTitle } from "./styles";

type BlankSlateProps = {
  title: string;
  text?: string;
};

const BlankSlate: React.SFC<BlankSlateProps> = props => (
  <Blank>
    <BlankTitle>{props.title}</BlankTitle>
    {props.text && <span>{props.text}</span>}

    <div>{props.children}</div>
  </Blank>
);

export default BlankSlate;
