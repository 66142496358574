import React, { useState, useRef } from "react";
import useForm from "react-hook-form";
import { get } from "lodash";
import { connect } from "react-redux";
import ApiClient from "src/helpers/ApiClient";
import { loadSuccess } from "src/redux/modules/auth";
import { notification } from "antd";
import {
  Perimeter,
  FormWrapper,
  Form,
  InputText,
  Button,
  SecondaryButton,
  ClearButton,
  Error,
} from "./styledComponents";
import LoadingIndicator from "./LoadingIndicator";

const LOGIN_API = "/api/v1/doctors/2fa_sign_in";
const SMS_AUTHY_API = "/api/v1/doctors/request_2fa_sms_token";
const VALIDATE_PASSWORD_API = "/api/v1/doctors/validate_password";
const RESET_PASSWORD_API = "/api/v1/users/password";

interface Client {
  post: (string, any) => any;
}

const ResetPassword = props => {
  const [isPending, setIsPending] = useState(false);
  const {
    handleSubmit,
    register,
    errors,
    getValues,
    clearError: clearFormError,
  } = useForm({
    defaultValues: {
      email: props.email,
    },
  });
  const [error, setError] = useState("");
  const clearError = () => {
    error && setError("");
    clearFormError();
  };

  const onResetPassword = async data => {
    setIsPending(true);
    const { email } = data;

    const result = await props.client.post(RESET_PASSWORD_API, {
      data: { email },
    });

    if (get(result, "message") === "Password reset instructions were sent") {
      notification.success({
        message: "Reset link sent",
        description: "Check your email or SMS",
      });
      props.clearPassword();
      props.handleGoBack();
    } else if (result.error) {
      setError(result.error);
    } else {
      // @ky -12/13/2019
      // NOTE: I don't think this else ever gets triggered
      notification.error({
        message: "Reset error",
        description: "Please try again",
      });
    }

    setIsPending(false);
  };

  const emptyEmailError = get(errors, "email") && !getValues().email;

  return (
    <Form onSubmit={handleSubmit(onResetPassword)} onChange={clearError}>
      <InputText
        name="email"
        type="email"
        innerRef={register({ required: true })}
        error={error || emptyEmailError}
        label="Email"
        autoFocus
      />
      {error ? (
        <Error>{error}</Error>
      ) : (
        emptyEmailError && <Error>Email can't be empty</Error>
      )}
      <Button type="submit">
        {isPending ? <LoadingIndicator /> : "Reset Password"}
      </Button>
      <SecondaryButton onClick={props.handleGoBack}>Back</SecondaryButton>
    </Form>
  );
};

const enhance = connect(
  null,
  { loadSuccess }
);

const AuthyForm = enhance(props => {
  const {
    credentials: { email, password },
    client,
    loadSuccess,
  } = props;
  const inputRef = useRef(null);
  const { handleSubmit, register } = useForm();
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState("");
  const clearError = () => error && setError("");

  const handleLogin = async data => {
    setIsPending(true);
    clearError();

    const { authy_token } = data;
    const result = await client.post(LOGIN_API, {
      data: { email, password, authy_token },
    });

    if (result.error) {
      setError(result.error);
    } else {
      loadSuccess(result);
      const resetPassword = get(result, "reset_expired_password");
      const redirectRoute = get(props, "location.query.redirect") || "/";
      const route = resetPassword ? "/reset-password" : redirectRoute;
      props.router.push(route);
    }

    setIsPending(false);
  };

  const handleSendToken = async () => {
    clearError();
    const result = await client.post(SMS_AUTHY_API, {
      data: { email, password },
    });
    if (result.error) {
      setError(result.error);
    } else {
      notification.success({
        message: "SMS sent",
        description: "Check your phone for Authy App token",
      });
      (inputRef as any).current.focus();
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleLogin)} onChange={clearError}>
      <InputText
        name="authy_token"
        type="text"
        inputmode="numeric"
        pattern="[0-9]*"
        autocomplete="one-time-code"
        error={error}
        label="Authy App Token"
        innerRef={e => {
          register(e, { required: true });
          inputRef.current = e;
        }}
        autoFocus
      />
      {error && <Error>{error}</Error>}
      <Button type="submit">
        {isPending ? <LoadingIndicator /> : "Login"}
      </Button>
      <ClearButton type="button" onClick={handleSendToken}>
        Send Authy App token via SMS
      </ClearButton>
    </Form>
  );
});

const Login = props => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    errors,
    clearError: clearFormError,
  } = useForm();
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [error, setError] = useState("");
  // @ts-ignore
  const [client]: [Client] = useState(new ApiClient());

  const clearError = () => {
    error && setError("");
    clearFormError();
  };
  const clearPassword = () => setValue("password", "");
  const onPasswordSubmit = async data => {
    setIsPending(true);
    clearError();

    const { email, password } = data;
    const result = await client.post(VALIDATE_PASSWORD_API, {
      data: { email, password },
    });

    if (result.error) {
      setIsPasswordValid(false);
      setError(result.error);
    } else {
      setCredentials({ email, password });
      setIsPasswordValid(true);
    }

    setIsPending(false);
  };

  const handleForgotPassword = () => {
    setResetPassword(true);
    const { email } = getValues();
    setCredentials({ email, password: "" });
  };

  const emptyEmailError = get(errors, "email") && !getValues().email;
  const emptyPasswordError = get(errors, "password") && !getValues().password;

  return (
    <Perimeter>
      <FormWrapper>
        {resetPassword ? (
          <ResetPassword
            client={client}
            handleGoBack={() => setResetPassword(false)}
            clearPassword={clearPassword}
            email={credentials.email}
          />
        ) : isPasswordValid ? (
          <AuthyForm
            credentials={credentials}
            client={client}
            location={props.location}
            router={props.router}
          />
        ) : (
          <Form onSubmit={handleSubmit(onPasswordSubmit)} onChange={clearError}>
            <InputText
              name="email"
              type="email"
              innerRef={register({ required: true })}
              disabled={isPending}
              autoFocus
              error={error || emptyEmailError}
              label="Email"
            />
            <InputText
              name="password"
              type="password"
              innerRef={register({ required: true })}
              disabled={isPending}
              error={error || emptyPasswordError}
              label="Password"
            />
            {error ? (
              <Error>{error}</Error>
            ) : emptyEmailError ? (
              <Error>Email can't be empty</Error>
            ) : (
              emptyPasswordError && <Error>Password can't be empty</Error>
            )}
            <Button type="submit" disabled={isPending}>
              {isPending ? <LoadingIndicator /> : "Login"}
            </Button>
            <ClearButton type="button" onClick={handleForgotPassword}>
              Forgot your password?
            </ClearButton>
          </Form>
        )}
      </FormWrapper>
    </Perimeter>
  );
};

export default Login;
