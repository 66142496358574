import React from "react";

type Props = {
  fill?: string;
};

const Template = ({ fill = "#fff" }: Props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.625"
      y="0.625"
      width="14.75"
      height="14.75"
      stroke={fill}
      strokeWidth="1.25"
    />
    <rect
      x="4.625"
      y="0.625"
      width="10.75"
      height="6.75"
      stroke={fill}
      strokeWidth="1.25"
    />
    <rect
      x="4.625"
      y="7.625"
      width="10.75"
      height="7.75"
      stroke={fill}
      strokeWidth="1.25"
    />
  </svg>
);

export default Template;
