import styled from "react-emotion";
import color from "src/styles/color";

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color.red};
`;
