import React from "react";
import styled from "react-emotion";
import { css, cx } from "emotion";
import { ToothNumber } from "../types";
import { uiAttributes } from "../consts";
import { ToothProps } from "./types";
import implant from "./implant.svg";
import LA from "./LA.svg";
import LB from "./LB.svg";
import LC from "./LC.svg";
import LD from "./LD.svg";
import LE from "./LE.svg";
import UA from "./UA.svg";
import UB from "./UB.svg";
import UC from "./UC.svg";
import UD from "./UD.svg";
import UE from "./UE.svg";
import * as U8 from "./U8";
import * as U7 from "./U7";
import * as U6 from "./U6";
import * as U5 from "./U5";
import * as U4 from "./U4";
import * as U3 from "./U3";
import * as U2 from "./U2";
import * as U1 from "./U1";
import * as L8 from "./L8";
import * as L7 from "./L7";
import * as L6 from "./L6";
import * as L5 from "./L5";
import * as L4 from "./L4";
import * as L3 from "./L3";
import * as L2 from "./L2";
import * as L1 from "./L1";

// Include root, crown, and crown icon types;
type P = ToothProps & {
  toothNumber: ToothNumber;
  isBaby: boolean;
  hideRoot?: boolean | undefined;
  onClick: () => void;
};

const ToothIcon: React.SFC<P> = props => {
  const rootAttribute = uiAttributes.find(a => a.type === props.root);
  const crownAttribute = uiAttributes.find(a => a.type === props.crown);

  const C = Object.values(components).find(c =>
    c.teeth.includes(props.toothNumber)
  );

  if (!C) {
    return <Wrapper />;
  }

  if (props.isBaby && C.Baby) {
    return (
      <Wrapper
        onClick={props.onClick}
        className={cx([
          props.impacted && impactedCSS,
          props.toothNumber >= 9 && props.toothNumber <= 24 && flippedCSS,
          props.toothNumber >= 17 && lowerCSS,
        ])}
      >
        <img src={C.Baby} alt="Baby tooth" />
      </Wrapper>
    );
  }

  let preferredRootFill = rootAttribute ? rootAttribute.color : undefined;
  let preferredRootStroke = rootAttribute
    ? rootAttribute.stroke || rootAttribute.color
    : undefined;
  const hasRootCanal = props.root === "root_canal";
  if (hasRootCanal) {
    preferredRootFill = undefined;
    preferredRootStroke = undefined;
  }

  return (
    <Wrapper
      onClick={props.onClick}
      className={cx([
        props.impacted && impactedCSS,
        props.toothNumber >= 9 && props.toothNumber <= 24 && flippedCSS,
        props.toothNumber >= 17 && lowerCSS,
      ])}
    >
      {!props.hideRoot &&
        (props.root === "implant" ? (
          <img
            src={implant}
            style={{ ...(C.implantStyle || {}), zIndex: 1 }}
            alt="Implant"
          />
        ) : (
          <C.Root
            hasRootCanal={hasRootCanal}
            fill={preferredRootFill}
            stroke={preferredRootStroke}
            style={Object.assign({}, C.rootStyle, {
              zIndex: props.crown === "crown" ? 3 : 1,
            })}
          />
        ))}
      <C.Crown
        fill={crownAttribute ? crownAttribute.color : undefined}
        stroke={
          crownAttribute
            ? crownAttribute.stroke || crownAttribute.color
            : undefined
        }
        style={C.crownStyle}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 0;
  cursor: pointer;
  z-index: 2;

  height: 62px;

  > svg {
    position: relative;
    z-index: 2;
  }
`;

const impactedCSS = css`
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -12px;
    left: -12px;
    right: -12px;
    bottom: -12px;
    border: 2px solid #e24c4b;
    border-radius: 500px;
    pointer-events: none;
  }

  svg {
    filter: drop-shadow(0 0 4px #fc5f22);
  }
`;

const flippedCSS = css`
  transform: scale(-1, 1);
`;

const lowerCSS = css`
  flex-direction: column-reverse;
`;

const components = {
  U8: {
    teeth: [1, 16],
    Crown: U8.Crown,
    Root: U8.Root,
    Baby: null,
    crownStyle: { marginLeft: 1 },
    rootStyle: { marginBottom: "-14px" },
    implantStyle: { marginBottom: "-14px" },
  },
  U7: {
    teeth: [2, 15],
    Crown: U7.Crown,
    Root: U7.Root,
    Baby: null,
    crownStyle: { marginLeft: 4 },
    rootStyle: { marginBottom: "-12px" },
    implantStyle: { marginBottom: "-12px", marginLeft: 4 },
  },
  U6: {
    teeth: [3, 14],
    Crown: U6.Crown,
    Root: U6.Root,
    Baby: null,
    crownStyle: { marginLeft: 3 },
    rootStyle: { marginBottom: "-14px" },
    implantStyle: { marginBottom: "-14px", marginLeft: 4 },
  },
  U5: {
    teeth: [4, 13],
    Crown: U5.Crown,
    Root: U5.Root,
    Baby: UE,
    crownStyle: { marginLeft: 3 },
    rootStyle: { marginBottom: "-14px" },
    implantStyle: { marginBottom: "-14px", marginLeft: 4 },
  },
  U4: {
    teeth: [5, 12],
    Crown: U4.Crown,
    Root: U4.Root,
    Baby: UD,
    crownStyle: { marginLeft: 3 },
    rootStyle: { marginBottom: "-14px" },
    implantStyle: { marginBottom: "-14px", marginLeft: 4 },
  },
  U3: {
    teeth: [6, 11],
    Crown: U3.Crown,
    Root: U3.Root,
    Baby: UC,
    crownStyle: { marginLeft: 3 },
    rootStyle: { marginBottom: "-14px" },
    implantStyle: { marginBottom: "-14px", marginLeft: 4 },
  },
  U2: {
    teeth: [7, 10],
    Crown: U2.Crown,
    Root: U2.Root,
    Baby: UB,
    crownStyle: { marginLeft: 3 },
    rootStyle: { marginBottom: "-14px" },
    implantStyle: { marginBottom: "-14px", marginLeft: 4 },
  },
  U1: {
    teeth: [8, 9],
    Crown: U1.Crown,
    Root: U1.Root,
    Baby: UA,
    crownStyle: {},
    rootStyle: { marginBottom: "-14px" },
    implantStyle: { marginBottom: "-14px", marginLeft: 1 },
  },

  L1: {
    teeth: [25, 24],
    Crown: L1.Crown,
    Root: L1.Root,
    Baby: LA,
    crownStyle: {},
    rootStyle: { marginTop: "-14px" },
    implantStyle: { marginTop: "-14px", marginLeft: 4 },
  },
  L2: {
    teeth: [26, 23],
    Crown: L2.Crown,
    Root: L2.Root,
    Baby: LB,
    crownStyle: {},
    rootStyle: { marginTop: "-14px" },
    implantStyle: { marginTop: "-14px", marginLeft: 4 },
  },
  L3: {
    teeth: [27, 22],
    Crown: L3.Crown,
    Root: L3.Root,
    Baby: LC,
    crownStyle: {},
    rootStyle: { marginTop: "-14px" },
    implantStyle: { marginTop: "-14px", marginLeft: 4 },
  },
  L4: {
    teeth: [28, 21],
    Crown: L4.Crown,
    Root: L4.Root,
    Baby: LD,
    crownStyle: {},
    rootStyle: { marginTop: "-14px" },
    implantStyle: { marginTop: "-14px", marginLeft: 4 },
  },
  L5: {
    teeth: [29, 20],
    Crown: L5.Crown,
    Root: L5.Root,
    Baby: LE,
    crownStyle: {},
    rootStyle: { marginTop: "-14px" },
    implantStyle: { marginTop: "-14px", marginLeft: 4 },
  },
  L6: {
    teeth: [30, 19],
    Crown: L6.Crown,
    Root: L6.Root,
    Baby: null,
    crownStyle: { marginLeft: 3 },
    rootStyle: { marginTop: "-11px" },
    implantStyle: { marginTop: "-11px", marginLeft: 4 },
  },
  L7: {
    teeth: [31, 18],
    Crown: L7.Crown,
    Root: L7.Root,
    Baby: null,
    crownStyle: {},
    rootStyle: { marginTop: "-9px" },
    implantStyle: { marginTop: "-9px", marginLeft: 4 },
  },
  L8: {
    teeth: [32, 17],
    Crown: L8.Crown,
    Root: L8.Root,
    Baby: null,
    crownStyle: { marginLeft: 8 },
    rootStyle: { marginTop: "-10px" },
    implantStyle: { marginTop: "-10px", marginLeft: 4 },
  },
};

export default ToothIcon;
