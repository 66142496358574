import React, { useState } from "react";
import { DateTime } from "luxon";
import { useGetUserFiles } from "../query";
import Box from "src/shared/Box";
import { File } from "src/types/gql";
import DocumentViewer from "../DocumentViewer";
import DropdownButton from "src/shared/DropdownButton";
import ManyEmailExternalSpecialist from "../ManyEmailExternalSpecialist";
import SentHistory from "../SentHistory";
import SOF from "../SOF";
import Clearance from "../Clearance";
import Referrals from "../Referral";
import DeleteDocuments from "../DeleteDocuments";
import { useGetExternalSpecialists } from "../query";
import {
  getReadableType,
  getEmail,
  DOC_TYPES,
} from "src/scenes/Patient/ExternalCare/utils";
import {
  Content,
  FileItem,
  FileTitle,
  FileSubtitle,
  contentWrapper,
} from "./styles";
import { sortBy, concat, forEach, reverse } from "lodash";

const sortEmailedEvents = (files: File[]) => {
  if (!files) return;

  let allEmailedEvents = [];
  forEach(files, file => {
    forEach(file.emailEvents, event => {
      const eventWithFile = {
        ...event,
        file,
      };

      allEmailedEvents = concat(allEmailedEvents, eventWithFile);
    });
  });

  // latest document will be at the top of of the list
  return reverse(sortBy(allEmailedEvents, event => event.createdAt));
};

const Documents = ({ patientID }) => {
  const [, , files, refetchFiles] = useGetUserFiles(patientID, DOC_TYPES);
  const [fileToShow, setFileToShow] = useState<File | null>(null);
  const [modal, setModal] = useState<
    | "create-clearance"
    | "delete"
    | "email"
    | "history"
    | "recreate-referral"
    | "recreate-sof"
    | null
  >(null);
  const [, , verifiedSpecialists] = useGetExternalSpecialists({
    userID: patientID,
  });

  const allSortedEmailedEvents = sortEmailedEvents(files);

  return (
    <Box
      style={{ display: "flex", flexFlow: "column" }}
      contentClassName={contentWrapper}
      title="Documents"
      actions={
        <DropdownButton
          mainAction={{ label: "Send", onClick: () => setModal("email") }}
          dropdownActions={[
            {
              label: "Show Send History",
              onClick: () => setModal("history"),
            },
            {
              label: "Re-create SOF",
              onClick: () => setModal("recreate-sof"),
            },
            {
              label: "Re-create referral",
              onClick: () => setModal("recreate-referral"),
            },
            {
              label: "Create clearance",
              onClick: () => setModal("create-clearance"),
            },
            {
              label: "Delete Forms",
              onClick: () => setModal("delete"),
            },
          ]}
        />
      }
    >
      {modal === "email" && (
        <ManyEmailExternalSpecialist
          documents={files}
          patientID={patientID}
          onClose={() => setModal(null)}
          verifiedSpecialists={verifiedSpecialists}
        />
      )}
      {modal === "create-clearance" && (
        <Clearance
          patientID={patientID}
          onClose={() => setModal(null)}
          refetch={() => refetchFiles()}
        />
      )}
      {modal === "recreate-referral" && (
        <Referrals
          patientID={patientID}
          onClose={() => setModal(null)}
          refetch={() => refetchFiles()}
        />
      )}
      {modal === "recreate-sof" && (
        <SOF
          patientID={patientID}
          onClose={() => setModal(null)}
          refetch={() => refetchFiles()}
        />
      )}
      {modal === "history" && (
        <SentHistory documents={files} onClose={() => setModal(null)} />
      )}
      {modal === "delete" && (
        <DeleteDocuments
          patientID={patientID}
          documents={files}
          onClose={() => setModal(null)}
        />
      )}
      {fileToShow && (
        <DocumentViewer
          patientID={patientID}
          onClose={() => setFileToShow(null)}
          file={fileToShow}
        />
      )}
      <Content>
        {allSortedEmailedEvents.map((event, index) => {
          return (
            <FileItem key={index} onClick={() => setFileToShow(event.file)}>
              <FileTitle>
                {getReadableType(event.file.type)} - {event.file.filename}
              </FileTitle>
              <FileSubtitle>
                {event &&
                  `Submitted: ${DateTime.fromISO(event.createdAt).toFormat(
                    "LLLL d, yyyy"
                  )} to ${getEmail(event)}`}
                {!event && "Not yet submitted"}
              </FileSubtitle>
            </FileItem>
          );
        })}
      </Content>
    </Box>
  );
};

export default Documents;
