import React, { PureComponent, SyntheticEvent } from "react";
import { connect } from "react-redux";
import { getNonce } from "src/redux/modules/insurance";
import { Dialog, TextField, FlatButton } from "material-ui";
import styled from "react-emotion";
import { Nonce } from "src/types/api";

type ProviderProps = {
  patientId: string;
  provider: any;
  getNonce(patientId: string): Nonce;
};

type ProviderState = {
  open: boolean;
  answers: { [id: string]: string };
};

const ProviderName = styled("h2")`
  cursor: pointer;
  font-weight: normal;
  font-size: 1.125rem;
`;

const modifiers = connect(
  null,
  { getNonce }
);

class InsuranceProvider extends PureComponent<ProviderProps, ProviderState> {
  form: HTMLFormElement | null = null;
  tokenEl: HTMLInputElement | null = null;
  state = {
    open: false,
    answers: {},
  };

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  setAnswer = (id: string, val: string) => {
    this.setState({
      answers: Object.assign({}, this.state.answers, {
        [id]: val,
      }),
    });
  };

  // getAuthNonce asks the server for an auth nonce - a single use auth token - for using
  // when redirecting to the PDF URL.
  //
  // We need to do this because the auth token is not stored in a cookie, and we need a
  // method of sending authentication in the URL when we view the PDF.
  //
  // Using cookies would open us up to CSRF attacks.
  getAuthNonce = async e => {
    const form = this.form as HTMLFormElement;
    const tokenEl = this.tokenEl as HTMLInputElement;
    e.preventDefault();
    const nonce = await this.props.getNonce(this.props.patientId);
    form.action = `${form.action}?token=${nonce.nonce}`;
    tokenEl.value = nonce.nonce;
    form.submit();
  };

  render() {
    const { provider, patientId } = this.props;
    const { open, answers } = this.state;

    // This renders _another_ dialog because Material UI has a bug in which creating
    // toggle content within a modal screws up the offset and scrollability of the
    // modal content.  So, for now, use _another_ modal for the forms.
    //
    // When we come to redesigning the flow of the patient profile we can revisit this.
    //
    // NOTE:  This uses an old-school form with a POST action because downloading files
    // from an AJAX request is disabled by default.  We'd have to make a blob, then reconstruct
    // the PDF, filename, generate a link etc. (as described here:
    // http://www.alexhadik.com/blog/2016/7/7/l8ztp8kr5lbctf5qns4l8t3646npqh)
    //
    // A single form works as expected, and doesn't redirect the user.
    return (
      <div>
        <ProviderName onClick={this.toggle}>{provider.provider}</ProviderName>
        <Dialog open={open} onRequestClose={this.toggle} autoScrollBodyContent>
          <ProviderName>{provider.provider}</ProviderName>

          <form
            ref={el => (this.form = el)}
            action={`${
              process.env.REACT_APP_API_HOST
            }/api/v1/patients/${patientId}/claims_form`}
            method="post"
          >
            <input type="hidden" name="provider" value={provider.provider} />
            <input type="hidden" name="token" ref={el => (this.tokenEl = el)} />
            {provider.questions.map(q => (
              <TextField
                name={`sensitive_data[${q.id}]`}
                value={
                  answers[q.id] === undefined ? q.default_answer : answers[q.id]
                }
                onChange={(evt: SyntheticEvent<HTMLInputElement>) =>
                  this.setAnswer(q.id, evt.currentTarget.value)
                }
                key={q.id}
                rows={1}
                rowsMax={4}
                hintText={`${q.form_number}: ${q.question}`}
                fullWidth
                multiLine
                autocomplete="off"
              />
            ))}
          </form>
          <FlatButton type="submit" onClick={this.getAuthNonce}>
            Generate PDF
          </FlatButton>
        </Dialog>
      </div>
    );
  }
}

export default modifiers(InsuranceProvider);
