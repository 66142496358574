import { V, S } from "src/shared-styles";

export default {
  V,
  S,
  half: {
    width: `calc(50% - ${V.spacingSmall}px)`,
    marginRight: V.spacingSmall,
    display: "inline-block",
    overflow: "hidden",
  },
  form: {
    marginBottom: V.spacing,
  },
  deleteButton: {
    color: V.colors.error,
    marginRight: V.spacing,
  },
};
