import React from "react";
import DocumentsForSignature from "src/components/consent-request/DocumentsForSignature";
import { DetailedPatient } from "src/types/api";
import ToothChart from "src/shared/ToothChartV2/ToothChart";
import {
  attributesToV2,
  attributesToV1,
} from "src/shared/ToothChartV2/versioning";

type Props = {
  formSlug: string;
  formID: string;
  patient: DetailedPatient;
  submission: any;
  saveToothData: any;

  // Question represents the question that this component is being rendered after
  question: any;
  // answer represents the answer for the question this component is being rendered after
  answer: any;
};

// CustomQuestionContent renders items after a specific question.  This allows us
// more flexibility than titlegroups, as we can run custom UI predicates to show
// components.
const CustomQuestionContent: React.FC<Props> = props => {
  return <>{customDisplays.map(f => f(props)).filter(Boolean)}</>;
};

// renderDebondConsent is only shown if the pt is ready to debond, after selecting "Yes".
const renderDebondConsent = (props: Props) => {
  const { patient, question, answer } = props;

  if (question.slug === "can_debond" && answer === "Yes") {
    return (
      <DocumentsForSignature
        patient={patient}
        visibleConsents={["debond", "media"]}
      />
    );
  }

  return null;
};

const renderCovidConsent = (props: Props) => {
  const { patient, question, answer } = props;

  if (question.slug === "covid_external_test" && answer === "No") {
    return (
      <DocumentsForSignature patient={patient} visibleConsents={["covid"]} />
    );
  }
  return null;
};

const renderRefinementSpacing = (props: Props) => {
  const { formSlug, question, patient, submission } = props;
  const isSubmitted = !!submission.submittedAt;

  if (question.slug !== "spacing_reminder" || formSlug !== "refinement") {
    return null;
  }

  return (
    <ToothChart
      userID={patient.id}
      submissionID={submission.id}
      defaultDisplayType={"spacing"}
      availableDisplayTypes={["spacing"]}
      unsavedAttributes={attributesToV2(submission.toothData || ([] as any))}
      disabled={isSubmitted}
      onChange={(a: any) => {
        const attrs = attributesToV1(a);
        props.saveToothData(attrs);
      }}
    />
  );
};

// customDisplays represents an array of functions that render custom content.
const customDisplays = [
  renderDebondConsent,
  renderCovidConsent,
  renderRefinementSpacing,
];

export default CustomQuestionContent;
