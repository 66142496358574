import React from "react";
import Loading from "src/shared/Loading";
import Error from "src/shared/Error";
import useInternalNotes from "src/state/useInternalNotes";
import NewNote from "src/shared/InternalNotes/V1Rest/NewNote";
import Note from "src/shared/InternalNotes/V1Rest/Note";

type Props = {
  objectKind: string;
  objectID: string;

  hideNewNote?: boolean;
};

const InternalNotes: React.FC<Props> = props => {
  const { fetching, error, notes, onCreate } = useInternalNotes(
    props.objectKind,
    props.objectID
  );

  if (fetching) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <div>
      {!props.hideNewNote && <NewNote {...props} onCreate={onCreate} />}
      {notes.map(n => (
        <Note note={n} key={n.id} />
      ))}
    </div>
  );
};

export default InternalNotes;
