import React from "react";

export default () => (
  <svg width="16" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM2.17 2a3.001 3.001 0 0 1 5.66 0H15a1 1 0 1 1 0 2H7.83a3.001 3.001 0 0 1-5.66 0H1a1 1 0 0 1 0-2h1.17ZM11 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM8.17 8a3.001 3.001 0 0 1 5.66 0H15a1 1 0 1 1 0 2h-1.17a3.001 3.001 0 0 1-5.66 0H1a1 1 0 1 1 0-2h7.17Z"
      fill="#fff"
    />
  </svg>
);
