import React, { createContext, useContext, useMemo, useReducer } from "react";
import { useForms } from "./queries";
import { Question, Answer } from "./types";

/**
 * useFormsContext contains hooks for accessing form-specific data.
 *
 * Form context stores all forms and their questions for accessing from any child component.
 *
 * It also manages state for locally mutated answers (optimistic UI).  This is for a specific
 * nuance with react rendering:
 *
 *  - We want to show the latest answer as soon as you press the button
 *  - This latest answer should also be visible in other components for things like auto generated
 *    answers, and showing other questions based off of the answer immediately
 *  - Because this answer must be visible elsewhere, it needs to be in some higher state
 *  - This was originally done within `useFormsUI`, but that state is re-initialized when a component
 *    is removed and re-rendered.
 *  - Forms can be removed and re-rendered due to GQL caching.
 *
 * Therefore, in order to maintain *any* local state persisting across re-renders, we must use context.
 *
 */

interface PatientWithID {
  id: string;
}

// ContextType represents the entire contect type accesible from sub-components.
type ContextType = {
  patient: PatientWithID;
  forms: FormContextType[];

  // localAnswers stores a map of locally updated answers so that the entire UI can use
  // these values immediately across the form.
  localAnswers: LocalAnswers;
  setLocalAnswer: (
    submissionID: string,
    questionSlug: string,
    a: Answer
  ) => void;
};

type LocalAnswers = {
  [submissionID: string]: {
    [questionSlug: string]: Answer;
  };
};

const FormsContext = createContext<ContextType>({} as ContextType);

// FormContextType represents a single form within FormContext.  It has its data
// modified from the GQL response such that it's easier to work with in the UI.
export type FormContextType = {
  id: string;
  name: string;
  description: string;
  slug: string;
  questions: { [slug: string]: Question }; // for o1 lookup
  titlegroupQuestionSlugs: { [titlegroup: string]: string[] }; // BV form: list of question slus in each titlegroup
  sections: Set<string>; // sections in the left hand list, from questions
};

// useFormsContext allows any component to get forms context in the shape of ContextType.
export const useFormsContext = () => useContext(FormsContext);

export default useFormsContext;

export const useForm = (slug: string): FormContextType | undefined => {
  const { forms } = useFormsContext();
  return useMemo(() => forms.find(f => f.slug === slug), [forms, slug]);
};

type Action = {
  type: "newAnswer";
  submissionID: string;
  slug: string;
  answer: Answer;
};

const answerReducer = (s: any, a: Action) => {
  switch (a.type) {
    case "newAnswer":
      const copy = { ...s };
      copy[a.submissionID] = {
        ...(s[a.submissionID] || {}),
        [a.slug]: a.answer,
      };
      return copy;
  }
  return s;
};

interface Props {
  patient: PatientWithID;
}

// FormsContextProvider wraps FormsContext.Provider to autoload data from the
// GQL API.
export const FormsContextProvider: React.FC<Props> = props => {
  const [{ data }] = useForms();
  const [localAnswers, dispatch] = useReducer(answerReducer, {});

  const forms = useMemo(() => {
    return normalizeFormData(data ? data.forms : []);
  }, [data]);

  const setLocalAnswer = (
    submissionID: string,
    slug: string,
    answer: Answer
  ) => {
    dispatch({ type: "newAnswer", submissionID, slug, answer });
  };

  return (
    <FormsContext.Provider
      value={{ forms, localAnswers, setLocalAnswer, patient: props.patient }}
    >
      {props.children}
    </FormsContext.Provider>
  );
};

// normalizeFormData converts the graphql form response into the type for use within
// context.  Sections are pulled out and deduplicated, and questions are mapped by slug
// for lookup.
const normalizeFormData = (forms: any[]): FormContextType[] => {
  return forms.filter(Boolean).map(f => {
    const questions = {};
    const sections = new Set();

    const titlegroupQuestionSlugs = {};

    f.questions.forEach(q => {
      // Unmarshal choices and display predicates from JSON here.  This ensures that
      // it's handled only once for all components.
      try {
        q.displayPredicates = JSON.parse(q.displayPredicates);
        q.choices = JSON.parse(q.choices);
      } catch (e) {}

      questions[q.slug] = q;
      sections.add(q.section);

      if (!Array.isArray(titlegroupQuestionSlugs[q.titlegroup])) {
        titlegroupQuestionSlugs[q.titlegroup] = [];
      }
      titlegroupQuestionSlugs[q.titlegroup].push(q.slug);
    });

    return {
      ...f,
      questions,
      sections,
      titlegroupQuestionSlugs,
    };
  });
};
