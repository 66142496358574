import React, { PureComponent } from "react";
import RaisedButton from "material-ui/RaisedButton";
import styles from "./wear-schedule-style";
import type { Stage } from "types";

type Props = {
  isShowingPreview: boolean,
  modifiedStage: Stage,
  actions: {|
    cancelPreview: () => void,
    updateSchedule: (updated: Stage) => void,
  |},
};

export default class PreviewBar extends PureComponent {
  props: Props;

  render() {
    const { isShowingPreview } = this.props;

    if (!isShowingPreview) {
      return null;
    }

    return (
      <div style={styles.preview}>
        <p style={styles.previewText}>Previewing edits</p>

        <div style={styles.actions}>
          <RaisedButton
            onClick={this.props.onReset}
            label="reset"
            style={{ marginRight: styles.V.spacingSmall }}
          />
          <RaisedButton onClick={this.props.onSave} label="save" primary />
        </div>
      </div>
    );
  }
}
