import { useQuery, UseQueryResponse } from "src/utils/http/gqlQuery";
import { getPalmer, isLeft } from "src/shared/ToothChartV2/consts";

// useAttributes fetches pre-existing, prescribed, and performed attributes for the user
// from GraphQL
export const useAttributes = (userID: string): UseQueryResponse<Response> => {
  return useQuery({
    query,
    variables: { userID },
    requestPolicy: "cache-first",
  });
};

export const useAttributesForToothChart = (userID: string): any[] => {
  const [{ data, error }] = useAttributes(userID);

  if (!data || error) {
    return [];
  }

  // The tooth chart uses a different object structure to represent attributes.
  // This comes from a fast refactor of the original tooth chart in JSON forms;
  // we'll need to amend the tooth chart and all forms to work with both new and
  // old data.
  const attributes = [] as any[];

  data.existing.forEach(a => {
    attributes.push({
      user_id: userID,
      tooth_name: getPalmer(a.toothNumber as any),
      entry_type: a.attribute.type,
      entry_stage: "antecedent", // "existing", the old word for it. fancy!
    });
  });

  data.prescribed.forEach(a => {
    attributes.push({
      user_id: userID,
      phase_id: a.phaseID,
      tooth_name: getPalmer(a.toothNumber as any),
      entry_type: a.attribute.type,
      entry_stage: "prescribed", // "existing", the old word for it. fancy!
      entry_data: {
        amount: `${a.amount}mm`, // Previously recorded amount as a string
        position: locationToProximal(a.toothNumber, a.location), // mesial vs distal
      },
    });
  });

  data.performed.forEach(a => {
    const entryStage = a.attribute.type === "spacing" ? "spacing" : "performed";
    attributes.push({
      user_id: userID,
      tooth_name: getPalmer(a.toothNumber as any),
      entry_type: a.attribute.type,
      entry_stage: entryStage,
      entry_data: {
        amount: `${a.amount}mm`,
        position: locationToProximal(a.toothNumber, a.location), // mesial vs distal
      },
    });
  });

  return attributes;
};

// locationToProximal converts "mesial" and "distal" to left/right proximal,
// as necessary for the current tooth chart.
const locationToProximal = (toothNumber: number, location: Location) => {
  if (location !== "mesial" && location !== "distal") {
    return location;
  }

  if (isLeft(toothNumber as any)) {
    // If the tooth is on the left side, mesial - pointing to the middle of the jaw -
    // is the right proximal surface.
    if (location === "mesial") {
      return "right proximal";
    }
    return "left proximal";
  }

  // This must be a right tooth, in which the mesial is the left surface.
  if (location === "mesial") {
    return "left proximal";
  }
  return "right proximal";
};

type Response = {
  existing: AttributeExisting[];
  prescribed: AttributePrescribed[];
  performed: AttributePerformed[];
};

type Attribute = {
  id: string;
  type: string;
  requiresVisit: boolean;
  requiresTemplate: boolean;
  requiresDebond: boolean;
  requiresExternalCoordination: boolean;
  requiresMaintenance: boolean;
  visitDuration: number | null;
  maintenanceCadence: number | null;
};

type AttributeExisting = {
  id: string;
  toothNumber: number;
  createdAt: string;
  attribute: Attribute;
};

type AttributePrescribed = {
  id: string;
  toothNumber: number;
  createdAt: string;
  attribute: Attribute;

  phaseID: string; // the phase ID that this is prescribed on
  location: Location; // location for ipr/attachment
  amount: number; // amount of IPR to be performed
};

type Location = "buccal" | "lingual" | "mesial" | "distal" | null;

type AttributePerformed = {
  id: string;
  toothNumber: number;
  createdAt: string;
  attribute: Attribute;

  appointmentID: string; // The appointment that this was placed
  formSubmissionID: string;
  location: "buccal" | "lingual" | "mesial" | "distal" | null;
  amount: number;
};

const attributeProps = `
  id
  type
  requiresVisit
  requiresTemplate
  requiresDebond
  requiresExternalCoordination
  requiresMaintenance
  visitDuration
  maintenanceCadence
`;

const query = `
query UserToothAttributes($userID: ID!) {
  existing: attributesExisting(userID: $userID) {
    id
    toothNumber
    createdAt
    attribute {
      ${attributeProps}
    }
  }
  prescribed: attributesPrescribed(userID: $userID) {
    id
    phaseID
    toothNumber
    location
    amount
    createdAt
    attribute {
      ${attributeProps}
    }
  }
  performed: attributesPerformed(userID: $userID) {
    id
    toothNumber
    appointmentID
    formSubmissionID
    location
    amount
    createdAt
    attribute {
      ${attributeProps}
    }
  }
}
`;
