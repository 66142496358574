import { filterAnswers } from "./generatedSummaryUtil";

const taskSlugSummaryMap = {
  which_tmj_issues_are_present: answers => {
    const filteredAnswers = filterAnswers(answers);
    if (!filteredAnswers) {
      return null;
    }

    if (Array.isArray(answers) && answers.includes("Crepitus")) {
      return "Task: Meet with a TMJ specialist to resolve or manage jaw pain before starting treatment.\n";
    }

    return null;
  },
  periodontal_issues_present: answers => {
    const filteredAnswers = filterAnswers(answers);
    if (!filteredAnswers) {
      return null;
    }

    let tasks = "";

    if (Array.isArray(answers) && answers.includes("Active disease present")) {
      if (tasks.length) {
        tasks += "• ";
      }
      tasks +=
        "Task: Get a first cleaning for periodontal maintenance from a general dentist before starting treatment. Continue receiving periodontal maintenance care throughout orthodontic treatment until the issue is fully resolved.\n\n";
    }

    if (
      Array.isArray(answers) &&
      answers.includes("Attachment loss beyond mucogingival junction")
    ) {
      if (tasks.length) {
        tasks += "• ";
      }
      tasks +=
        "Task: See a general dentist and/or specialist to get necessary gum grafts to resolve concerns with recession. We will take a new intra-oral scan after.\n";
    }

    if (tasks.length === 0) {
      return null;
    }

    return tasks;
  },
  months_to_monitor_mixed_dentition: answer => {
    if (!filterAnswers(answer, ["N/A"])) {
      return null;
    }

    return `Task: Contact us to book a Restart appointment  in ${answer} months from today to observe any changes in your teeth.\n`;
  },
  has_pending_dental_work_that_cannot_wait: answer => {
    if (!filterAnswers(answer)) {
      return null;
    }

    if (answer === "Yes") {
      return "Task: See a general dentist to complete pending non-elective dental work. We will take a new intra-oral scan after.\n";
    }

    return null;
  },
  additional_oral_health_concerns: answers => {
    if (!filterAnswers(answers)) {
      return null;
    }

    let tasks = "";

    if (Array.isArray(answers) && answers.includes("Gross caries (ICDAS 4+)")) {
      if (tasks.length) {
        tasks += "• ";
      }
      tasks +=
        "Task: Get a cleaning and exam from a general dentist, plus necessary fillings, to resolve cavities. We will take a new intra-oral scan after.\n\n";
    }

    if (
      Array.isArray(answers) &&
      answers.includes("Bridges of Calculus are present")
    ) {
      if (tasks.length) {
        tasks += "• ";
      }
      tasks +=
        "Task: Get a cleaning and exam from a general dentist to remove buildup. We will take a new intra-oral scan after.\n\n";
    }

    if (Array.isArray(answers) && answers.includes("Endodontic Infection")) {
      if (tasks.length) {
        tasks += "• ";
      }
      tasks +=
        "Task: See a general dentist and/or specialist to get necessary root canals to resolve endodontic infection. We will take a new intra-oral scan after.\n\n";
    }

    if (tasks.length === 0) {
      return null;
    }

    return tasks;
  },
};

export const generateNextStepTasksSummary = answers => {
  const summaries = [
    getTaskSummaryForSlugs(
      [
        "which_tmj_issues_are_present",
        "periodontal_issues_present",
        "months_to_monitor_mixed_dentition",
        "has_pending_dental_work_that_cannot_wait",
        "additional_oral_health_concerns",
      ],
      answers
    ).join("\n • "),
  ].filter(Boolean);

  if (!summaries) {
    return null;
  }

  const str = " • " + summaries.join("\n • ");
  // Remove double bullet points cuz some qs insert their own -.-
  return str.replace(" •  • ", " • ");
};

export const getTaskSummaryForSlugs = (slugs, answers) => {
  return slugs
    .map(slug => {
      const getSummary = taskSlugSummaryMap[slug];
      if (!getSummary) {
        return null;
      }
      return getSummary(answers[slug]);
    })
    .filter(Boolean);
};
