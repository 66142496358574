// @flow
import React from "react";
import {
  compose,
  lifecycle,
  withProps,
  branch,
  renderComponent,
} from "recompose";
import { TreatmentCyclesEnhancer } from "src/state/TreatmentCycles";
import { FormsEnhancer } from "src/state/Forms";
import { WithEnhancers } from "src/shared/hocs";
import PhaseComponent from "../TreatmentSetup/Phase";

// Add the current tray information for the patient.
const modifiers = compose(
  ...WithEnhancers(TreatmentCyclesEnhancer, FormsEnhancer),
  lifecycle({
    componentDidMount() {
      // Request all submissions for the treatment setup form.
      this.props.actions.getPatientSubmissions(
        "treatment_setup",
        this.props.patient.id
      );
    },
    componentWillReceiveProps(next) {
      if (next.patient.id !== this.props.patient.id) {
        next.actions.getPatientSubmissions("treatment_setup", next.patient.id);
      }
    },
  }),

  // Get the first treatment plan that was made before the current form submission;
  // this ensures that when we look at old forms it shows the appropriate treatment plan
  // for the current submission.
  withProps(props => {
    // Include the patient's trays, and find the current tray they're on
    const now = new Date();
    const trays = props.treatmentCycles.map(tc => tc.wear_schedule).flatten();
    const currentTray = trays.find(t => {
      return (
        new Date(t.expected_end_date) > now &&
        new Date(t.expected_start_date) < now
      );
    });

    const followupCreatedAt = new Date(props.submission.created_at);

    // Find the form ID for treatment setup form, so we can filter submissions to only
    // TPs.
    const tp = Object.values(props.forms).find(
      f => f.slug === "treatment_setup"
    );

    return {
      // props.submissions is ordered by created at descending, so find the first treatment
      // plan for this patient that's older than the followup visit.
      treatmentPlan: props.submissions.find(s => {
        return (
          s.form_id === tp.id &&
          s.patient_id === props.submission.patient_id &&
          new Date(s.created_at) < followupCreatedAt
        );
      }),

      // And include the patient's trays
      trays,
      currentTray,
    };
  }),

  // And if we can't find a submitted treatment plan show a warning. And if we can't find a submitted treatment plan show a warning.
  branch(
    props => !props.treatmentPlan,
    renderComponent(() => {
      return (
        <p>
          This person does not have a treatment plan submitted before the
          followup visit
        </p>
      );
    })
  )
);

const Current = props => {
  // stageCount lets us take a total stage count over each phase, adding the stage range
  // for each phase component.
  let stageCount = 0;

  // TODO: Show treatment plan phases.  Alternatively, if this patient doesn't have a
  // treatment plan in the new format, render a "treatment plan not found".
  return (
    <div>
      {!props.currentTray && <p>Unsure what tray the patient is wearing</p>}
      {props.currentTray && (
        <p>
          Current stage: <b>{props.currentTray.stage}</b>
        </p>
      )}

      <p>Treatment plan</p>
      {props.treatmentPlan.tooth_data &&
        props.treatmentPlan.tooth_data.map((p, n) => {
          // Is this a phase?  If not, return null.
          // TODO: Why does tooth data carry over here from BV form?
          if (p.tooth_name) {
            return null;
          }

          const component = (
            <PhaseComponent
              userID={props.patient.id}
              submissionID={props.treatmentPlan.id}
              cumulativeCutoff={props.submission.submitted_at}
              disabled
              index={n}
              phase={p}
              stageStart={stageCount + 1}
              key={n}
            />
          );

          stageCount += p.stages;

          return component;
        })}
    </div>
  );
};

export default modifiers(Current);
