import { useState, useEffect } from "react";

import { CombinedError, useQuery } from "src/utils/http/gqlQuery";
import { request } from "src/shared/util";
import { Kit } from "src/types/gql";
import { Kit as KitRest } from "src/types/api";

const query = `
  query PatientKits($userId: ID!) {
    kits(userId: $userId) {
      id
      userId
      kitNumber
      checkinTime
      createdAt
      includes
      shipment {
        id
        status
        trackingNumber
        carrier
        deliveredAt
        externalShipmentId
      }
    }
  }
`;

// useKits uses the graphql endpoint to fetch kits for a patient
// TODO: Expose trays in graphql;  GQL does not let you know which trays
//       are in a kit.
export const useKits = (
  userId: string
): [boolean, CombinedError | undefined, Kit[]] => {
  const [{ fetching, error, data }] = useQuery<{
    kits: Kit[];
  }>({ query, variables: { userId } });

  const kits = ((data && data.kits) || ([] as Kit[])).filter(o => {
    return o.userId === userId;
  });

  return [fetching, error, kits];
};

// useKits is a React effect that fetches all kits for a patient, returning the
// kits and request status.
export const useKitsRest = (
  patientId: string
): [KitRest[], { loading: boolean; error: boolean }] => {
  const [kits, setKits] = useState([]);
  const [status, setStatus] = useState({ loading: false, error: false });

  useEffect(() => {
    (async () => {
      setStatus({ loading: true, error: false });
      const resp = await request(`/api/v1/patients/${patientId}/kits`);

      if (!resp || resp.error) {
        setStatus({
          loading: false,
          error: (resp && resp.error) || "error making request",
        });
        return;
      }

      setKits(resp);
      setStatus({ loading: false, error: false });
    })();
  }, [patientId]);

  return [kits, status];
};

export default useKitsRest;
