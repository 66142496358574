import React from "react";
import { DateTime } from "luxon";
import { useFormState } from "react-use-form-state";
// shared
import DatePopover from "src/shared/Popovers/Date";
import { Staff } from "src/types/gql";
import { getWeeksDistToISODateString } from "src/utils/dates";
// local
import Label from "../Shared/Label";
import DueDateIcon from "../Icons/DueDate";
import Assignees from "../Shared/Assignees";
import CustomLabelsList from "../CustomLabelsList/CustomLabelsList";
import InvisibleButton from "../NewTask/InvisibleButton";
import { Task, TaskOption } from "../types";
import ExternalCare from "../Task/Description/ExternalCare";
import { TaskTypeNameAddGeneralDentistToSendNewPatientReferralTo } from "src/scenes/Tasks/Task/consts";

type Props = {
  task: Task;
  onChange: (t: TaskOption, deleteFields?: string[]) => Promise<any>;
};

const Details: React.FC<Props> = ({ task, onChange }) => {
  const [, { textarea }] = useFormState({
    description: task.description,
  });
  const weeks =
    task.pipeline && task.pipeline.createdAt
      ? getWeeksDistToISODateString(task.pipeline.createdAt) + 1
      : 0;

  return (
    <div>
      <Label>
        <span>Assignees</span>
        <div>
          <Assignees
            value={(task.assignedTo as unknown) as Staff[]}
            onChange={s => {
              // Get the difference between the two sets to get unassigned IDs
              const unassignIDs = task.assignedTo
                .filter(assigned => {
                  return s.find(item => item.id === assigned.id) === undefined;
                })
                .map(s => s.id);

              const assignedToIDs = s
                .filter(assigned => {
                  return (
                    task.assignedTo.find(item => item.id === assigned.id) ===
                    undefined
                  );
                })
                .map(s => s.id);

              onChange({ unassignIDs, assignedToIDs });
            }}
          />
        </div>
      </Label>

      <Label>
        <span>Start date</span>
        <DatePopover
          onClick={d => onChange({ startedAt: d })}
          value={task.startedAt}
        >
          <InvisibleButton>
            {task.startedAt ? (
              <span>{DateTime.fromISO(task.startedAt).toLocaleString()}</span>
            ) : (
              <>
                <DueDateIcon />
                Set start date
              </>
            )}
          </InvisibleButton>
        </DatePopover>
      </Label>

      <Label>
        <span>Task due date</span>
        {task.pipelineDueAt ? (
          <span>{DateTime.fromISO(task.pipelineDueAt).toLocaleString()}</span>
        ) : (
          <></>
        )}
      </Label>

      <Label>
        <span>Weeks</span>
        <span>{weeks}</span>
      </Label>

      <Label>
        <span>Status</span>
        <InvisibleButton
          onClick={() => {
            if (!task.startedAt) {
              onChange({ startedAt: new Date().toISOString() });
            }
          }}
        >
          {task.completedAt &&
            `Completed at ${DateTime.fromISO(
              task.completedAt
            ).toLocaleString()} ${task.completedBy &&
              " by " + task.completedBy.name}`}
          {!task.completedAt &&
            task.startedAt &&
            `Started at ${DateTime.fromISO(task.startedAt).toLocaleString()}`}
          {!task.completedAt && !task.startedAt && "Not started"}
        </InvisibleButton>
      </Label>

      <Label>
        <span>Label</span>
        <CustomLabelsList
          labels={task.customLabels || []}
          onChange={newValue => {
            onChange({
              customLabels: {
                clearCurrentValue: false,
                newValue,
              },
            });
          }}
        />
      </Label>

      <Label>
        <span>Description</span>
        {task.taskType.name ===
        TaskTypeNameAddGeneralDentistToSendNewPatientReferralTo ? (
          <ExternalCare task={task} />
        ) : (
          <textarea
            {...textarea({
              name: "description",
              onBlur: e => onChange({ description: e.target.value }),
            })}
          />
        )}
      </Label>
    </div>
  );
};

export default Details;
