import React, { Component } from "react";
import PropTypes from "prop-types";

import { Dialog } from "material-ui";
import { connect } from "react-redux";
import AddressForm from "./address-form";
import { loadPatient, setAddressForPatient } from "src/redux/modules/patient";

const connected = connect(
  null,
  { setAddressForPatient, loadPatient }
);
export class AddAddress extends Component {
  static contextTypes = {
    patient: PropTypes.object,
  };
  static propTypes = {
    handleClose: PropTypes.func,
    loadPatient: PropTypes.func,
  };
  handleSubmit = address => {
    const setAddress = this.props.setAddressForPatient(
      this.context.patient.id,
      address
    );
    setAddress.then(() => {
      this.props.loadPatient(this.context.patient.id);
      this.props.handleClose();
    });
    return setAddress;
  };

  render() {
    return (
      <div>
        <Dialog
          title="Set Address"
          modal={false}
          open={this.props.open}
          onRequestClose={this.props.handleClose}
          contentClassName="full-width-dialog-content"
        >
          <AddressForm onSubmit={this.handleSubmit} />
        </Dialog>
      </div>
    );
  }
}

export default connected(AddAddress);
