import React from "react";
import color from "src/styles/color";

const RightArrow = ({ stroke = color.gray5, ...rest }) => (
  <svg
    width="5"
    height="8"
    viewBox="0 0 5 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M1 7.42856L4 3.99999L1 0.571415"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RightArrow;
