import React from "react";
import { IndexRedirect, IndexRoute, Route, Redirect } from "react-router";

import App from "src/containers/app/app";

import PatientView from "src/containers/treatment/containers/patient-view/patient-view";
import Treatment from "src/containers/treatment/treatment-wrapper";
import Form from "src/containers/treatment/containers/form/form";
import Login from "src/scenes/Login";
import ResetPassword from "src/containers/auth/reset-password/reset-password";
import EnsureLoggedIn from "src/containers/auth/ensure-logged-in";
import LoggedInContainer from "src/containers/auth/logged-in-container";
import ConsentForm from "src/containers/treatment/containers/consent-form/consent-form";
import Settings from "src/containers/settings/Settings";
import Labels from "src/containers/labels/labels";
import BoxFileExplorer from "src/containers/treatment/containers/file-explorer/file-explorer";

import PatientTrays from "src/scenes/Trays/Trays";
import TrayLogs from "src/scenes/TrayLogs/TrayLogs";
import Builds from "src/scenes/Builds/Builds";
import ViewForm from "src/scenes/Form/Form";
import PatientPipelines from "src/scenes/Patient/Pipelines/Pipelines";
import PatientPipeline from "src/scenes/Patient/Pipelines/Pipeline";
import NewPatient from "src/scenes/NewPatient/NewPatient";
import PatientForms from "src/scenes/PatientProfile/Forms/GQLForms/Forms";
import PatientProfile from "src/scenes/PatientProfile";
import Billing from "src/scenes/Patient/Billing/Billing";
import BillingV2 from "src/scenes/Patient/BillingV2/Billing";
import Files from "src/scenes/Patient/Files/Files";
import Chat from "src/scenes/Patient/Chat/Chat";
import OptimizedScheduling from "src/scenes/SchedulingV2/OptimizedScheduling/Page";
import ExternalCareOverview from "src/scenes/Patient/ExternalCare/Overview";
import EMRAdmin from "src/scenes/EMRAdmin/Overview";
import Tasks from "src/scenes/Tasks/Tasks";
import Pipelines from "src/scenes/Tasks/Pipelines/Pipelines";
import Pipeline from "src/scenes/Tasks/Pipelines/Pipeline";
import Status from "src/scenes/Tasks/Status/Status";
import Txp from "src/scenes/Txp/Txp";
import EasypostDashboard from "src/scenes/Manufacturing/EasypostDashboard";
import PatientTreatmentOverviewV2 from "src/scenes/PatientTreatmentOverviewV2"; // TODO: Add this back

import NotFound from "src/shared/NotFound";

export const Routes = {
  LOGIN: "login",
  RESET_PASSWORD: "reset-password",
  PATIENT: "patient",
  FORM: "form/:formName",
  SPECIFIC_FORM: "form/:formName/:formUUID",
  ALL_CONVERSATIONS: "conversations",
  CONVERSATION: ":conversationId",
  MANUFACTURING: "manufacturing",
  CLINICAL: "clinical",
  CONSENT_FORM: "consent-form/:formName",
  TASK_TYPE: ":taskType",
  WEAR_SCHEDULE: "wear-schedule",
  SETTINGS: "settings",
  PRINT_LABELS: "print-labels",
  TREATMENT_CYCLE_LIST: "treatment-cycle-list",
  NON_COMPLIANT_PATIENT_LIST: "non-compliant-patient-list",
  PIPELINES: "pipelines",
};

export default () => {
  /**
   * Please keep routes in alphabetical order
   */
  return (
    <Route path="/" component={App}>
      <Route path={Routes.PRINT_LABELS} component={Labels} />
      {/* Auth */}
      <Route path={Routes.LOGIN} component={Login} />
      {/* Home (main) route */}
      {/* Routes */}
      <Route component={EnsureLoggedIn}>
        <Route path="/txp/:fileID" component={Txp} />

        <Route component={LoggedInContainer}>
          <IndexRedirect to="/scheduling" />

          {/* Patient profile used to be called treatment for some reason */}
          <Redirect from="/treatment/:userId" to="/patients/:userId" />
          <Redirect from="/treatment/:userId/patient" to="/patients/:userId" />
          <Redirect
            from="/treatment/:userId/:other"
            to="/patients/:userId/:other"
          />
          <Redirect
            from="/treatment/:userId/form/:form"
            to="/patients/:userId/form/:form"
          />
          <Redirect
            from="/treatment/:userId/form/:formName/:formUUID"
            to="/patients/:userId/form/:formName/:formUUID"
          />
          <Redirect
            from="/treatment/:userId/consent-form/:form"
            to="/patients/:userId/consent-form/:form"
          />

          <Route path="/emr-admin" component={EMRAdmin}>
            <Route path="staff-members" component={EMRAdmin} />
            <Route path="promo-codes" component={EMRAdmin} />
            <Route path="free-treatment-users" component={EMRAdmin} />
            <Route path="verified-specialists" component={EMRAdmin} />
            <Route path="audit-logs" component={EMRAdmin} />
          </Route>

          <Route path="/easypost" component={EasypostDashboard} />

          <Route path="/patients/new" component={NewPatient} />

          <Route
            path="/patients/:userId/about/:timelineEntryType/:timelineEntryID"
            component={PatientProfile}
          />
          <Route
            path="/patients/:userId/about/:timelineEntryType/:timelineEntryID/:selectedTab"
            component={PatientProfile}
          />
          <Route path="/patients/:userId" component={PatientProfile}>
            <Route path="forms" component={PatientForms}>
              <Route path=":formKey" component={PatientForms} />
              <Route path=":formKey/:submissionId" component={PatientForms} />
            </Route>
            <Route path="chat" component={Chat} />
            <Route path="billing-old" component={Billing} />
            <Route path="billing" component={BillingV2} />
            <Route path="pipelines/:pipelineID" component={PatientPipeline} />
            <Route
              path="pipelines/:pipelineID/:taskID"
              component={PatientPipeline}
            />
            <Route path="pipelines" component={PatientPipelines} />
            <Route path="files" component={Files} />
            <Route path="files/box" component={BoxFileExplorer} />
            <Route path="wear-schedule" component={PatientTrays} />
            <Route path="tray-logs" component={TrayLogs} />
            <Route path="external-care" component={ExternalCareOverview} />
          </Route>

          <Route
            path="/patients/new-patient-treatment-overview/:userId"
            component={PatientTreatmentOverviewV2}
          >
            <Route path="forms" component={PatientForms}>
              <Route path=":formKey" component={PatientForms} />
              <Route path=":formKey/:submissionId" component={PatientForms} />
            </Route>
            <Route path="chat" component={Chat} />
            <Route path="billing-old" component={Billing} />
            <Route path="billing" component={BillingV2} />
            <Route path="pipelines/:pipelineID" component={PatientPipeline} />
            <Route
              path="pipelines/:pipelineID/:taskID"
              component={PatientPipeline}
            />
            <Route path="pipelines" component={PatientPipelines} />
            <Route path="files" component={Files} />
            <Route path="files/box" component={BoxFileExplorer} />
            <Route path="wear-schedule" component={PatientTrays} />
            <Route path="tray-logs" component={TrayLogs} />
            <Route path="external-care" component={ExternalCareOverview} />
          </Route>

          <Route path="/patients-old/:userId" component={Treatment}>
            <IndexRoute component={PatientView} />

            {/* old forms */}
            <Route path={Routes.FORM} component={Form} />
            <Route path={Routes.SPECIFIC_FORM} component={Form} />
            <Route path={Routes.CONSENT_FORM} component={ConsentForm} />

            {/* View forms */}
            <Route path="forms/:formKey" component={ViewForm} />
            <Route path="forms/:formKey/:submissionId" component={ViewForm} />

            {/* Kenzan stuff, legacy */}
            <Route path={Routes.CONSENT_FORM} component={ConsentForm} />
            <Route path={Routes.WEAR_SCHEDULE} component={PatientTrays} />

            {/* Legacy form handlers */}
            <Route path={Routes.SPECIFIC_FORM} component={Form} />
            <Route path={Routes.FORM} component={Form} />
          </Route>

          <Route path={Routes.SETTINGS} component={Settings} />

          <Route path="/scheduling" component={OptimizedScheduling} />

          <Route path={Routes.RESET_PASSWORD} component={ResetPassword} />

          <Route path="/builds" component={Builds} />

          <Route path="/tasks" component={Tasks} />
          <Route path="/tasks/:filter" component={Tasks} />
          <Route path="/tasks/:filter/:id" component={Tasks} />
          <Route path="/pipelines" component={Pipelines} />
          <Route path="/pipelines/:filter" component={Pipelines} />
          <Route path="/pipelines/:filter/:id" component={Pipeline} />
          <Route path="/pipelines/:filter/:id/:taskID" component={Pipeline} />
          <Route path="/status" component={Status} />

          <Route path="*" component={NotFound} status={404} />
        </Route>
      </Route>

      {/* Catch all route */}
      <Route path="*" component={NotFound} status={404} />
    </Route>
  );
};
