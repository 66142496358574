import React from "react";
import { Link } from "react-router";

import color from "src/styles/color";
import Trash from "src/shared/Icons/Trash.svg";
import EditPencil from "src/shared/Icons/EditPencil.svg";
import parsePhoneNumber from "src/utils/parsePhoneNumber";
import {
  SpecialistItem,
  TitleRow,
  VerifiedSpecialistNameAndState,
  SpecialistState,
  IconActions,
  EditButton,
  DeleteButton,
} from "./styles";
import { ExternalSpecialist } from "@/types/gql";

const SpecialistRow = ({
  specialist: { userID, referral, state: specialistState, verifiedSpecialist },
  onEditClick,
  onDeleteClick,
}: {
  specialist: ExternalSpecialist;
  onEditClick: any;
  onDeleteClick: any;
}) => (
  <SpecialistItem>
    <TitleRow>
      <VerifiedSpecialistNameAndState>
        <>{verifiedSpecialist.firstName} </>
        <>{verifiedSpecialist.lastName}</>
        <SpecialistState
          style={
            specialistState === "chosen"
              ? { color: `${color.primary}` }
              : { color: `${color.gray3}` }
          }
        >
          {specialistState && specialistState.toUpperCase()}
        </SpecialistState>
      </VerifiedSpecialistNameAndState>
      <IconActions>
        <EditButton onClick={onEditClick} type="button">
          <img src={EditPencil} alt="Update External Provider" />
        </EditButton>
        <DeleteButton onClick={onDeleteClick} type="button">
          <img src={Trash} alt="Remove External Provider" />
        </DeleteButton>
      </IconActions>
    </TitleRow>
    <div>{verifiedSpecialist.clinicName}</div>
    <div>{verifiedSpecialist.email}</div>
    <div>{parsePhoneNumber(verifiedSpecialist.phone)}</div>
    <div>{parsePhoneNumber(verifiedSpecialist.phone2)}</div>
    <div>{verifiedSpecialist.address.line1}</div>
    {verifiedSpecialist.address.line2 && (
      <div>{verifiedSpecialist.address.line2}</div>
    )}
    <div>
      {verifiedSpecialist.address.city}, {verifiedSpecialist.address.state}{" "}
      {verifiedSpecialist.address.postalCode}
    </div>
    <div>
      {"Link to Referral: "}
      {referral ? (
        <Link
          to={`/patients/${userID}/forms/referral/${referral.formSubmissionID}`}
        >
          {"Click Here"}
        </Link>
      ) : (
        "No Associated Referral"
      )}
    </div>
  </SpecialistItem>
);

export default SpecialistRow;
