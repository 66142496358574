/**
 * @fileoverview
 * We use serialized JSON for feature flags.
 * Example: '["v2"]'
 * To set a feature flag: localStorage.setItem('emr-feature-flags', '["v2"]')
 * To remove a feature flag: localStorage.removeItem('emr-feature-flags')
 */

const EMR_FEATURE_FLAGS_KEY = "emr-feature-flags";

type FeatureFlag = "newPatient";

const readFromLocalStorage = (): string[] | null => {
  const emrFeatureFlags = localStorage.getItem(EMR_FEATURE_FLAGS_KEY);

  if (!emrFeatureFlags) {
    return null;
  }

  try {
    return JSON.parse(emrFeatureFlags);
  } catch {
    return null;
  }
};

export const hasFeatureFlag = (featureFlag: FeatureFlag): boolean => {
  const result = readFromLocalStorage();

  return result ? result.includes(featureFlag) : true; // Change to false if you want to disable it
};

export const addFeatureFlag = (featureFlag: FeatureFlag): void => {
  const allFeatureFlags = readFromLocalStorage();

  if (!allFeatureFlags) {
    localStorage.setItem(EMR_FEATURE_FLAGS_KEY, JSON.stringify([featureFlag]));

    return;
  }

  if (!allFeatureFlags.includes(featureFlag)) {
    localStorage.setItem(
      EMR_FEATURE_FLAGS_KEY,
      JSON.stringify([...allFeatureFlags, featureFlag])
    );

    return;
  }
};

export const removeFeatureFlag = (featureFlag: FeatureFlag): void => {
  const allFeatureFlags = readFromLocalStorage();

  if (!allFeatureFlags) {
    return;
  }

  if (allFeatureFlags.includes(featureFlag)) {
    const newSetOfFeatureFlags = allFeatureFlags.filter(
      item => item !== featureFlag
    );

    localStorage.setItem(
      EMR_FEATURE_FLAGS_KEY,
      JSON.stringify(newSetOfFeatureFlags)
    );
  }
};
