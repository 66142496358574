// Add array flat polyfill for microsoft edge/IE: needed for some logic.
// This should be rmeoved once IE is better.  So, probably, never.
import "array-flat-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import "src/styles/global";

ReactDOM.render(<App />, document.getElementById("root"));
