import React, { useState } from "react";
import { Input, message } from "antd";
import Button, { ButtonRow } from "src/shared/Button";
import { useCreateNote } from "./queries";

type Props = {
  objectKind: string;
  objectID: string;

  onCreate?: () => void;
};

const NewNote: React.FC<Props> = props => {
  const [value, setValue] = useState("");
  const [saving, setSaving] = useState(false);
  const execute = useCreateNote();

  const onSave = async () => {
    if (saving) {
      return;
    }
    setSaving(true);
    const result = await execute({
      note: {
        modelType: props.objectKind,
        modelId: props.objectID,
        text: value,
      },
    });
    setSaving(false);

    if (result.error) {
      message.error("Error saving note");
      return;
    }

    setValue("");
    message.success("Note created");

    props.onCreate && props.onCreate();
  };

  return (
    <div>
      <Input.TextArea
        disabled={saving}
        autosize={{ minRows: 2 }}
        value={value}
        onChange={e => setValue(e.target.value)}
        name="note"
        placeholder="Add a note"
        style={{ marginBottom: "12px" }}
      />

      <ButtonRow position="right">
        <Button kind="primary" disabled={saving} onClick={onSave}>
          Post
        </Button>
      </ButtonRow>
    </div>
  );
};

export default NewNote;
