import React from "react";
import styled from "react-emotion";
import { Button } from "antd";
import { Link } from "react-router";

import { Patient } from "src/types/gql";
import Box from "src/shared/Box";
import BlankSlate from "src/shared/BlankSlate";
import { useKitsRest } from "src/state/useKits";
// TODO: Refactor this once design changes
import KitItem from "src/containers/treatment/components/kits/kit-item";

type Props = {
  patient: Patient;
};

const ContentWrapper = styled.div`
  max-height: 400px;
  overflow-y: scroll;
`;

const Kits: React.FC<Props> = ({
  patient: { id, firstName, lastName, mobilePhoneNumber },
}) => {
  // Use REST right now as GQL doesn't expose which trays are in kit
  const [kits] = useKitsRest(id);
  const linkToLabels = `/print-labels?name=${firstName} ${lastName}&phone=${mobilePhoneNumber}`;

  return (
    <Box
      title="Kits"
      actions={
        <Link to={linkToLabels}>
          <Button>Print labels</Button>
        </Link>
      }
    >
      <ContentWrapper>
        {kits.length === 0 && (
          <BlankSlate title="No kits" text="The patient has no kits" />
        )}
        <div style={{ padding: "20px" }}>
          {kits.map(k => (
            <KitItem key={k.id} kit={k} />
          ))}
        </div>
      </ContentWrapper>
    </Box>
  );
};

export default Kits;
