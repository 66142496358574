import React, { useState, useMemo } from "react";
import styled from "react-emotion";
import { Menu } from "antd";
import color from "src/styles/color";

import Popover from "src/shared/Popover";
import Box from "src/shared/Box";
import Modal from "src/shared/Modal";
import DeleteConfirmModal from "src/shared/DeleteConfirmModal";
import Loading from "src/shared/Loading";
import Button from "src/shared/Button";
import { Patient } from "src/types/api";
import { toggle } from "src/shared/util";
import { Link } from "react-router";
import { isPatientOnImpressTM } from "src/scenes/TrayLogs/util";

import {
  useData,
  useFormattedTimeline,
  useUpdateCadence,
  useDeleteStages,
  useUpdateWearable,
} from "./query";
import EmptyIcon from "./icons/notreatmentsetup.svg";
import PhaseHeader from "./PhaseHeader";
import TimelineRow from "./TimelineRow";
import Warning from "./Warning";
import ImpressWarning from "./ImpressTMWarning";
import EditCadence from "./EditCadence";

type Props = {
  patient: Patient;
};

const TrayLogs: React.FC<Props> = ({ patient }) => {
  const [{ fetching, data }] = useData(patient.id);
  const updateWearable = useUpdateWearable();
  const updateCadence = useUpdateCadence();
  const deleteStages = useDeleteStages();

  const [selected, setSelected] = useState<number[]>([]);
  const [editCadence, setEditCadence] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const { timeline, trayLogs, treatment } = data || {
    timeline: [],
    trayLogs: [],
    treatment: { id: "", treatmentCycles: [] },
  };
  const groups = useFormattedTimeline(timeline, treatment);

  // allTrayNumbers is a set which contains all available tray numbers from the timeline.
  const allTrayNumbers = useMemo(() => {
    const set = new Set<number>();
    (timeline || []).forEach(t => set.add(t.trayNumber));
    return Array.from(set);
  }, [timeline]);

  // trayNumberMapping is a mapping of tray numbers to wear timeline stage IDs.  We need this
  // when deleting the underlying wear timeline stage from the menu..
  const trayNumberMapping = useMemo(() => {
    const result = {};
    (treatment.treatmentCycles || []).forEach(tc => {
      tc.phases.forEach(phase => {
        phase.wts.forEach(wts => {
          if (result[wts.stage] === undefined) {
            result[wts.stage] = [wts.id];
          }
          result[wts.stage].push(wts.id);
        });
      });
    });
    return result;
    // eslint-disable-next-line
  }, [timeline]);

  // getSelectedStageIDS returns all wear timeline stage IDs for the selected tray numbers.
  const getSelectedStageIDS = () =>
    selected.map(num => trayNumberMapping[num] || []).flat();

  if (fetching) return <Loading />;

  if (timeline.length === 0) {
    return (
      <BlankState>
        <img src={EmptyIcon} alt="No treatment" />
        <div>
          Uh oh! It looks like the patient doesn{"'"}t have any treatments set
          up yet.
        </div>
      </BlankState>
    );
  }

  const groupTrays = groups.map((g, _) => {
    const set = new Set<number>();
    g.timeline.forEach(t => set.add(t.trayNumber));
    return Array.from(set);
  });

  const Menu = () => {
    // allWearable is true if every selected tray is wearable.
    const allWearable = selected.every(n => {
      return (timeline.find(t => t.trayNumber === n) || { isWearable: false })
        .isWearable;
    });

    return (
      <StyledMenu>
        <StyledMenuItem onClick={() => setEditCadence(true)}>
          Edit tray cadence
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            updateWearable(getSelectedStageIDS(), !allWearable);
          }}
          disabled={selected.length === 0}
        >
          Mark as {allWearable ? "non-wearable" : "wearable"}
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            setShowDelete(true);
          }}
          disabled={selected.length === 0}
        >
          Delete
        </StyledMenuItem>
      </StyledMenu>
    );
  };

  return (
    <>
      {trayLogs.length === 0 && timeline[0].status !== "upcoming" && (
        <Warning patientID={patient.id} />
      )}
      {isPatientOnImpressTM(treatment) ? <ImpressWarning /> : null}
      {editCadence && (
        <Modal onClose={() => setEditCadence(false)}>
          <EditCadence
            onCancel={() => setEditCadence(false)}
            onSubmit={(cadence: number) => {
              updateCadence(patient.id, cadence);
              setEditCadence(false);
            }}
            cadence={
              treatment.treatmentCycles.length > 0
                ? treatment.treatmentCycles[0].cadence
                : 7
            }
          />
        </Modal>
      )}
      {showDelete && (
        <DeleteConfirmModal
          onClose={() => setShowDelete(false)}
          text="Are you sure you want to delete these stages?  They will be removed from the timelime permanently."
          actionText="Delete"
          onConfirm={async () => {
            await deleteStages(patient.id, getSelectedStageIDS());
            setShowDelete(false);
          }}
        />
      )}
      <Box
        title={`Timeline`}
        actions={
          <Popover content={<Menu />} align="end">
            {isPatientOnImpressTM(treatment) ? null : (
              <Button>Edit trays</Button>
            )}
            <Link to={`/patients/${patient.id}/wear-schedule`}>
              <Button>Wear Schedule</Button>
            </Link>
          </Popover>
        }
      >
        <TableHeader>
          <div>
            <input
              type="checkbox"
              readOnly
              checked={selected.length === allTrayNumbers.length}
              onClick={() => {
                if (selected.length === allTrayNumbers.length) {
                  setSelected([]);
                } else {
                  setSelected(allTrayNumbers);
                }
              }}
            />
          </div>
          <div>Tray</div>
          <div>Start date</div>
          <div>End date</div>
          <div>Planned end date</div>
          <div>On schedule?</div>
          <div>Status</div>
          <div />
        </TableHeader>
        {groups.map((g, n) => {
          const isSelected = groupTrays[n].every(n => selected.includes(n));
          return (
            <React.Fragment key={n}>
              <PhaseHeader
                phase={g.phase}
                index={n + 1}
                status={g.status}
                isWearable={g.isWearable}
                wtsIDs={g.wtsIDs}
                selected={isSelected}
                onToggle={() => {
                  if (isSelected) {
                    setSelected(
                      selected.filter(t => !groupTrays[n].includes(t))
                    );
                  } else {
                    setSelected(
                      Array.from(new Set(selected.concat(groupTrays[n])))
                    );
                  }
                }}
              />

              {g.timeline.map((t, n) => (
                <TimelineRow
                  key={n}
                  timeline={t}
                  userID={patient.id}
                  treatment={treatment}
                  selected={selected.includes(t.trayNumber)}
                  onToggle={() => {
                    setSelected(toggle(selected, t.trayNumber));
                  }}
                />
              ))}
            </React.Fragment>
          );
        })}
      </Box>
    </>
  );
};

const BlankState = styled.div`
  background-color: ${color.white};
  border: 1px solid ${color.border};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 50px;

  > img {
    margin-bottom: 24px;
  }

  > div {
    max-width: 277px;
    color: ${color.gray5};
    text-align: center;
    font-size: 16px;
  }
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 58px 210px repeat(5, 1fr) 40px;

  > div:first-of-type {
    padding: 12px 15px 12px 25px;
    display: flex;
    align-items: center;
  }

  > div {
    padding: 10px 0 12px;
    font-weight: bold;
  }
`;

const StyledMenu = styled(Menu)`
  min-width: 160px;
  & .ant-dropdown-menu-item:hover {
    background-color: ${color.gray1};
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  display: flex;
  flex-flow: row;
  align-items: center;
  > div {
    width: 16px;
    margin-right: 8px;
  }
`;

export default TrayLogs;
