import { set, get } from "lodash";
const LOAD_PATIENT_SUCCESS = "patient/LOAD_PATIENT_SUCCESS";
const DELETE_SUCCESS = "patient/DELETE_SUCCESS";
const MARK_AS_SPAM_SUCCESS = "patient/MARK_AS_SPAM_SUCCESS";
const BOX_FOLDER_DATA_LOAD_SUCCESS = "patientBoxFolderData/LOAD_SUCCESS";
const SET_ADDRESS_SUCCESS = "@@patient/SET_ADDRESS_SUCCESS";
const UPDATE_PATIENT_INFO_SUCCESS = "@@patient/UPDATE_PATIENT_INFO_SUCCESS";
const PATIENT_API = "/api/v1/patients";

const CREATE_MESSAGE_SUCCESS = "patient/CREATE_MESSAGE_SUCCESS";
const LOAD_PATIENT_STATUS_SUCCESS = "patient/LOAD_PATIENT_STATUS_SUCCESS";
const LOAD_PATIENT_CHAT_SUCCESS = "patient/LOAD_PATIENT_CHAT_SUCCESS";
const POST_PATIENT_STATUS_SUCCESS = "patient/POST_PATIENT_STATUS_SUCCESS";

const PATIENT_STATUS_API = "/api/v1/patient_statuses";
const getPatientStatus = uuid => `/api/v1/patients/${uuid}/statuses`;

const LOAD_PATIENT_SYSTEM_EVENT_SUCCESS = "patient/LOAD_PATIENT_EVENTS_SUCCESS";
const getPatientSystemEvents = patientId =>
  `api/v1/patients/${patientId}/events`;

const getPatient = uuid => `/api/v1/patients/${uuid}`;
const getPatientOfficeSyncFolderUrl = uuid =>
  `/api/v1/patients/${uuid}/initialize_box`;
const getCreateAnotherPatientRecordFolderUrl = uuid =>
  `/api/v1/patients/${uuid}/create_another_patient_record_folder`;
const getPatientBoxFolderDataUrl = uuid =>
  `/api/v1/patients/${uuid}/box_token_and_patient_folder_id`;
const patientChatUrl = uuid => `/api/v2/compatibility/v1/chat/patients/${uuid}`;
const resetPasswordUrl = "/api/v1/users/password";
const setAddressForPatientURL =
  "/api/v1/addresses/create_shipping_address_for_patient";

const initialState = {
  patient: {},
  patientMap: {},
};

export default function reducer(state = initialState, action = {}) {
  const newState = { ...state };
  switch (action.type) {
    case UPDATE_PATIENT_INFO_SUCCESS:
    case LOAD_PATIENT_SUCCESS:
      newState.patient = action.result;
      return newState;
    case BOX_FOLDER_DATA_LOAD_SUCCESS:
      const map = Object.assign({}, newState.patientMap[action.uuid] || {});
      // XXX: These were both being set before - I don't quite know why.  So I've
      // left the first assignment here commented but... do we need `box_token`?
      // map.boxToken = action.result.box_token;
      map.boxToken = action.result.patient_folder_id;
      newState.patientMap[action.uuid] = map;
      return newState;
    case LOAD_PATIENT_STATUS_SUCCESS:
      set(newState, `patientMap[${action.uuid}].status`, action.result);
      return newState;
    case LOAD_PATIENT_SYSTEM_EVENT_SUCCESS:
      set(
        newState,
        `patientMap[${action.patientId}].systemEvent`,
        action.result
      );
      return newState;
    case LOAD_PATIENT_CHAT_SUCCESS:
      set(newState, `patientMap[${action.uuid}].chat`, action.result);
      return newState;
    case CREATE_MESSAGE_SUCCESS:
      set(
        newState,
        `patientMap[${action.uuid}].chat`,
        get(newState, `patientMap[${action.uuid}].chat`, []).concat(
          action.result
        )
      );
      return newState;
    default:
      return newState;
  }
}

export function loadPatientStatus(uuid) {
  return {
    types: [null, LOAD_PATIENT_STATUS_SUCCESS, null],
    uuid,
    promise: client => client.get(getPatientStatus(uuid)),
  };
}

export function postPatientStatus(patientId, statusName) {
  return {
    types: [null, POST_PATIENT_STATUS_SUCCESS, null],
    patientId,
    statusName,
    promise: client =>
      client.post(`${PATIENT_STATUS_API}`, {
        data: { user_id: patientId, name: statusName },
      }),
  };
}

export function loadPatientSystemEvents(patientId) {
  return {
    types: [null, LOAD_PATIENT_SYSTEM_EVENT_SUCCESS, null],
    patientId,
    promise: client => client.get(getPatientSystemEvents(patientId)),
  };
}

export function loadPatient(uuid) {
  return {
    types: [null, LOAD_PATIENT_SUCCESS, null],
    uuid,
    promise: client => client.get(getPatient(uuid)),
  };
}
export function loadPatientChat(uuid) {
  return {
    types: [null, LOAD_PATIENT_CHAT_SUCCESS, null],
    uuid,
    promise: client => client.get(patientChatUrl(uuid)),
  };
}

export function createPatientMessage({ uuid, body, is_public, attachments }) {
  return {
    types: [null, CREATE_MESSAGE_SUCCESS, null],
    uuid,
    promise: client =>
      client.post(patientChatUrl(uuid), {
        data: { body, is_public, attachments },
      }),
  };
}

export function isLoaded(globalState, uuid) {
  return (
    globalState.patient.patient && globalState.patient.patient.uuid === uuid
  );
}

export function setAddressForPatient(patientId, address) {
  return {
    types: [null, SET_ADDRESS_SUCCESS, null],
    promise: client =>
      client.post(setAddressForPatientURL, {
        data: {
          user_id: patientId,
          address,
        },
      }),
  };
}
export function sendResetPasswordEmail(email) {
  return {
    types: [null, null, null],
    promise: client =>
      client.post(resetPasswordUrl, {
        data: {
          email,
        },
      }),
  };
}

export function updatePatientInfo(patientId, data) {
  return {
    types: [null, UPDATE_PATIENT_INFO_SUCCESS, null],
    promise: client =>
      client.put(getPatient(patientId), {
        data,
      }),
  };
}
export function setPatientOfficeSyncFolder({ uuid, officeName }) {
  return {
    types: [null, null, null],
    uuid,
    promise: client =>
      client.post(getPatientOfficeSyncFolderUrl(uuid), {
        data: { user_id: uuid, office_name: officeName },
      }),
  };
}

export function createAnotherPatientRecordFolder(uuid) {
  return {
    types: [null, null, null],
    uuid,
    promise: client =>
      client.post(getCreateAnotherPatientRecordFolderUrl(uuid), {
        data: { user_id: uuid },
      }),
  };
}

export function destroy(id) {
  return {
    types: [null, DELETE_SUCCESS, null],
    id,
    promise: client => client.del(`${PATIENT_API}/${id}`),
  };
}

export function markPatientAsSpam(id, reason) {
  return {
    types: [null, MARK_AS_SPAM_SUCCESS, null],
    id,
    promise: client =>
      client.put(`${PATIENT_API}/${id}`, {
        data: {
          is_spam: true,
          spam_reason: reason,
        },
      }),
  };
}

/**
 * This function fetches both the box folder Id and the "App User" token. The API user is the only app user.
 * The app user token allows full access to everything the app user can see. this is all folders that we add it to.
 * However the UI only allows access and view to just that specific patient's files that are relevant to that user.
 * In practice an employee should only be able to see the files related to that one patient that they are allowed to see.
 */

export function getPatientBoxFolderData(uuid) {
  return {
    types: [null, BOX_FOLDER_DATA_LOAD_SUCCESS, null],
    uuid,
    promise: client => client.get(getPatientBoxFolderDataUrl(uuid)),
  };
}

export function patientAsyncConnectHandler(
  routerParams,
  { store: { dispatch, getState } }
) {
  const globalState = getState();
  const { userId } = routerParams;
  if (!isLoaded(globalState, userId)) {
    return dispatch(loadPatient(userId));
  }
  return Promise.resolve();
}

export const getPatientSelector = state => get(state, "patient.patient") || {};
