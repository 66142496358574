import React, { Fragment, useState } from "react";
import { notification } from "antd";

import { useQuery } from "src/utils/http/gqlQuery";
import ConfirmModal from "src/shared/ConfirmModal";
import Loading from "src/shared/Loading";
import Error from "src/shared/Error";
import Button from "src/shared/Button";
import Box from "src/shared/Box";
import ExternalProviderModal from "src/shared/ExternalProvider/ExternalProviderModal";
import EditExternalProviderSpecialistModal from "src/scenes/Patient/ExternalCare/EditExternalProviderSpecialistModal";
import { TaskTypeNameAddGeneralDentistToSendNewPatientReferralTo } from "src/scenes/Tasks/Task/consts";
import { ExternalSpecialist } from "src/types/gql";
import SpecialistRow from "./SpecialistRow";
import { Content, contentWrapper } from "./styles";

import {
  useGetExternalSpecialists,
  useDeleteExsternalSpecialist,
  externalSpecialistsQuery,
  useAddExternalSpecialist,
} from "../query";

type Props = {
  patientID: string;
  boxDisplay?: boolean;
  TaskName?: string;
};

const ExternalSpecialists: React.FC<Props> = ({
  patientID,
  boxDisplay,
  TaskName,
}) => {
  const [loading, error, specialists] = useGetExternalSpecialists({
    userID: patientID,
  });

  const [, refetch] = useQuery({
    query: externalSpecialistsQuery,
    variables: { filter: { userID: patientID } },
    pause: true,
  });

  const [
    specialistToEdit,
    setSpecialistToEdit,
  ] = useState<ExternalSpecialist | null>(null);

  const deleteSpecialist = useDeleteExsternalSpecialist();
  const [
    specialistToDelete,
    setSpecialistToDelete,
  ] = useState<ExternalSpecialist | null>(null);
  const [showNewSpecialistModal, setShowNewSpecialistModal] = useState(false);
  const addSpecialist = useAddExternalSpecialist();
  const displayBox = boxDisplay ? true : false;

  if (loading) return <Loading />;
  if (error) return <Error />;

  const onAddSpecialist = async verifiedSpecialistID => {
    const isTaskAddGeneralDentist: boolean =
      TaskName &&
      TaskName === TaskTypeNameAddGeneralDentistToSendNewPatientReferralTo
        ? true
        : false;

    const resp = await addSpecialist({
      input: {
        userID: patientID,
        verifiedSpecialistID,
        isTaskAddGeneralDentist,
      },
    });
    // TODO: fix this when this story, Story ID 10418, is worked on
    if (
      resp.error &&
      resp.error.message ===
        "[GraphQL] No Summary of Findings PDF exists, please create one"
    ) {
      notification.success({
        message: "Provider added",
      });
      refetch();
      return;
    }

    const externalSpecialistId = resp.data.addExternalSpecialist.id;

    if (resp.error) {
      notification.error({
        message: "Error adding provider and sending Summary of Findings",
      });
      return;
    }

    notification.success({
      message: "Provider added and Summary of Findings sent",
    });

    return externalSpecialistId;
  };

  const onDelete = async specialist => {
    const resp = await deleteSpecialist({ id: specialist.id });
    if (resp.error) {
      notification.error({
        message: "Error removing provider",
      });
      return;
    }

    notification.success({
      message: "Provider removed",
    });

    refetch();
    setSpecialistToDelete(null);
  };

  return (
    <Box
      style={{ display: "flex", flexFlow: "column" }}
      title="External Providers"
      contentClassName={contentWrapper}
      actions={
        !showNewSpecialistModal &&
        !specialistToEdit && (
          <Button
            onClick={() => setShowNewSpecialistModal(true)}
            kind="default"
          >
            Add provider
          </Button>
        )
      }
    >
      {specialistToEdit && (
        <EditExternalProviderSpecialistModal
          specialistId={specialistToEdit.id}
          state={specialistToEdit.state}
          specialist={specialistToEdit.verifiedSpecialist}
          refetch={() => refetch}
          onClose={() => setSpecialistToEdit(null)}
          boxDisplay={displayBox}
        />
      )}

      {specialistToDelete && (
        <ConfirmModal
          type="delete"
          title="Are you sure?"
          text="Do you want to remove this external provider?"
          actionText="Remove"
          onClose={() => setSpecialistToDelete(null)}
          onConfirm={() => onDelete(specialistToDelete)}
        />
      )}
      {showNewSpecialistModal && (
        <ExternalProviderModal
          onClose={() => setShowNewSpecialistModal(false)}
          onChange={onAddSpecialist}
          mapView
          boxDisplay={displayBox}
        />
      )}
      {((!showNewSpecialistModal && !specialistToEdit) || !boxDisplay) && (
        <Content>
          {specialists.map(specialist => (
            <Fragment key={specialist.id}>
              <SpecialistRow
                onEditClick={() => setSpecialistToEdit(specialist)}
                onDeleteClick={() => setSpecialistToDelete(specialist)}
                specialist={specialist}
              />
              <hr />
            </Fragment>
          ))}
        </Content>
      )}
    </Box>
  );
};

export default ExternalSpecialists;
