import React from "react";

export default () => (
  <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.726 11.969a.921.921 0 00-.649-.255h-.923v-.643a.997.997 0 00-.339-.756A1.156 1.156 0 0019 10h-.461c-.318 0-.59.105-.815.315a.996.996 0 00-.34.757v.642h-2.768v-.643a.996.996 0 00-.34-.756 1.155 1.155 0 00-.814-.315H13c-.317 0-.589.105-.815.315a.997.997 0 00-.339.757v.642h-.923a.922.922 0 00-.649.255.796.796 0 00-.274.603v8.57c0 .233.091.434.274.604.183.17.399.254.65.254h10.153c.25 0 .466-.085.649-.255a.796.796 0 00.274-.602V12.57a.796.796 0 00-.274-.602zm-3.418-.897a.2.2 0 01.065-.154.234.234 0 01.165-.06H19c.067 0 .122.02.166.06.043.04.065.091.065.154V13a.201.201 0 01-.065.154.235.235 0 01-.166.06h-.462a.234.234 0 01-.165-.06.2.2 0 01-.065-.154v-1.928zm-5.539 0a.2.2 0 01.065-.154.234.234 0 01.166-.06h.462c.067 0 .122.02.165.06.044.04.065.091.065.154V13a.2.2 0 01-.065.154.235.235 0 01-.165.06H13a.234.234 0 01-.166-.06.201.201 0 01-.065-.154v-1.928zm8.308 10.07H10.923v-6.856h10.154v6.857z"
      fill="#5d6161"
    />
    <circle cx="16" cy="16" r="15.5" stroke="#5d6161" strokeDasharray="2 2" />
  </svg>
);
