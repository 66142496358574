import React, { useState, useContext } from "react";
import { uuid } from "src/shared/util";
import { UploadContext } from "src/shared/Uploader";
import { FileData, validateFileData, detectFiletype } from "src/shared/file";

export type UseUploaderProps = {
  list: Array<FileData>;
  onChange: (e: React.SyntheticEvent) => void;
  onDrop: (e: React.SyntheticEvent) => void;
  updateList: (list: Array<FileData>) => void;

  dragging: boolean;
  setDragging: (d: boolean) => void;

  // upload begins uploading the list
  upload: (files?: FileData[]) => void;
};

type InputProps = {
  userID: string;
  onStartUpload?: (valid: FileData[], invalid: FileData[]) => void;
};

const UseUploader = ({
  userID,
  onStartUpload,
}: InputProps): UseUploaderProps => {
  const [dragging, setDragging] = useState(false);
  const [list, updateList] = useState<Array<FileData>>([]);
  const { push } = useContext(UploadContext);

  const addFiles = files => {
    const mapped = files.map(
      (file: File): FileData => ({
        id: uuid(),
        userID,
        file,
        filetype: detectFiletype(file.name),
        // Default to internal, which is less dangerous than accidentally
        // exposing files to a patient
        internal: true,
      })
    );
    updateList(list.concat(mapped));
  };

  const onChange = e => {
    addFiles(Array.from<File>(e.target.files));
  };

  const onDrop = e => {
    setDragging(false);
    e.preventDefault();
    addFiles(Array.from<File>(e.dataTransfer.files));
  };

  const upload = (files?: FileData[]) => {
    const valid: FileData[] = [],
      invalid: FileData[] = [];

    // Only upload valid files - the invalid ones we'll keep in the queue.
    console.log(files, list, files || list);

    (files || list).forEach(f => {
      if (validateFileData(f)) {
        valid.push(f);
      } else {
        invalid.push(f);
      }
    });

    push(valid);
    updateList(invalid);

    onStartUpload && onStartUpload(valid, invalid);
  };

  return {
    onChange,
    onDrop,
    upload,
    list,
    updateList,
    dragging,
    setDragging,
  };
};

export default UseUploader;
