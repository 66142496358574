import React, { useState } from "react";
import { isEmpty, pick } from "lodash";
import { notification } from "antd";
import styled, { css } from "react-emotion";

import color from "src/styles/color";
import Loading from "src/shared/Loading";
import InputText from "src/shared/InputText";
import Table, { Header, Body } from "src/shared/Table";
import Switch from "src/shared/Switch";
import Button from "src/shared/Button";
import usePagination from "src/shared/usePagination";

import Edit from "src/scenes/Patient/BillingV2/Orders/OrderSettings/icons/edit.svg";
import parsePhoneNumber from "src/utils/parsePhoneNumber";
import {
  getReadableType,
  emptySpecialist,
  topLevelFields,
} from "src/utils/externalProvider";
import { useGetFilteredVerifiedSpecialists } from "src/shared/ExternalProvider/queries";
import { VerifiedSpecialist } from "src/types/gql";

import {
  useCreateVerifiedSpecialist,
  useUpdateVerifiedSpecialist,
} from "./useEMRAdminInfo";
import EditVerifiedSpecialistModal from "./EditVerifiedSpecialistModal";

const headerItemStyle = css`
  flex: 1;
  max-width: 250px;
`;

const editItemStyle = css`
  width: 80px;
`;

const Container = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${color.background};

  tbody {
    max-height: none;
  }
`;

const AddProviderButton = styled(Button)`
  width: 200px;
  margin-bottom: 10px;
`;

const EditButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const TopBar = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

const searchbar = css`
  width: 300px;
  margin-right: 10px;
`;

const addressFields = ["id", "line1", "line2", "city", "state", "postalCode"];
const typeFilter = "all";

const VerifiedSpecialists = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [preferredProviderOnly, setPreferredProviderOnly] = useState(false);

  const [loading, , data] = useGetFilteredVerifiedSpecialists(
    page,
    search,
    typeFilter,
    preferredProviderOnly
  );

  const query = `search=${search}&type=${typeFilter}&partnerOnly=${preferredProviderOnly}`;
  const specialists = usePagination(
    query,
    data && data.pageResults,
    data && data.verifiedSpecialists,
    loading,
    page,
    setPage
  );

  const createSpecialist = useCreateVerifiedSpecialist();
  const updateSpecialist = useUpdateVerifiedSpecialist();
  const [showAddModal, setShowAddModal] = useState(false);
  const [
    specialistToEdit,
    setSpecialistToEdit,
  ] = useState<VerifiedSpecialist | null>(null);

  const onAddSpecialist = async specialist => {
    const resp = await createSpecialist({
      input: {
        ...pick(specialist, topLevelFields),
        createAddress: specialist.address,
      },
    });

    if (resp.error) {
      notification.error({
        message: "Error adding provider",
      });

      return;
    }

    notification.success({
      message: "Provider added!",
    });

    setShowAddModal(false);
  };

  const onUpdateSpecialist = async specialist => {
    if (!specialistToEdit) return;

    // TODO: Only send fields that have changed
    const resp = await updateSpecialist({
      input: {
        ...pick(specialist, topLevelFields),
        id: specialistToEdit.id,
        updateAddress: pick(specialist.address, addressFields),
      },
    });

    if (resp.error) {
      notification.error({
        message: "Error updating provider",
      });

      return;
    }

    notification.success({
      message: "Provider updated!",
    });

    setSpecialistToEdit(null);
  };

  const onSearchChange = e => {
    setPage(1);
    setSearch(e.target.value);
  };

  return (
    <Container>
      <TopBar>
        <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
          <InputText
            className={searchbar}
            value={search}
            placeholder="Search by provider or clinic name"
            onChange={onSearchChange}
          />
          <Switch
            checked={preferredProviderOnly}
            onChange={checked => setPreferredProviderOnly(checked)}
          />
          &nbsp;&nbsp;Show preferred providers only
        </div>
        <AddProviderButton onClick={() => setShowAddModal(true)} kind="primary">
          Add provider
        </AddProviderButton>
      </TopBar>
      {showAddModal && (
        <EditVerifiedSpecialistModal
          specialist={emptySpecialist}
          onSubmit={onAddSpecialist}
          onClose={() => setShowAddModal(false)}
          title="Add new provider"
        />
      )}
      {specialistToEdit && (
        <EditVerifiedSpecialistModal
          specialist={specialistToEdit}
          onSubmit={onUpdateSpecialist}
          onClose={() => setSpecialistToEdit(null)}
          title="Update provider information"
        />
      )}
      {!isEmpty(specialists) && (
        <Table items={specialists}>
          <Header>
            <Header.Item css={headerItemStyle}>Type</Header.Item>
            <Header.Item css={headerItemStyle}>Name</Header.Item>
            <Header.Item css={headerItemStyle}>Clinic Name</Header.Item>
            <Header.Item css={headerItemStyle}>Email</Header.Item>
            <Header.Item css={headerItemStyle}>Phone</Header.Item>
            <Header.Item css={editItemStyle} />
          </Header>
          <Body>
            {specialist => (
              <Body.Row key={specialist.id}>
                <Body.Item css={headerItemStyle}>
                  {getReadableType(specialist.type)}
                </Body.Item>
                <Body.Item css={headerItemStyle}>
                  {specialist.firstName} {specialist.lastName}
                </Body.Item>
                <Body.Item css={headerItemStyle}>
                  {specialist.clinicName}
                </Body.Item>
                <Body.Item css={headerItemStyle}>{specialist.email}</Body.Item>
                <Body.Item css={headerItemStyle}>
                  {parsePhoneNumber(specialist.phone)}
                </Body.Item>
                <Body.Item css={editItemStyle}>
                  <EditButton
                    type="button"
                    onClick={() => setSpecialistToEdit(specialist)}
                  >
                    <img src={Edit} alt="Edit" />
                  </EditButton>
                </Body.Item>
              </Body.Row>
            )}
          </Body>
        </Table>
      )}
      {loading && <Loading />}
    </Container>
  );
};

export default VerifiedSpecialists;
