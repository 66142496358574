import React from "react";
import isEqual from "react-fast-compare";
import Check from "src/shared/Icons/Check";
import color from "src/styles/color";
import { List, ListHeading } from "./styles";

interface ValuesWithHeading<T> {
  heading: string;
  values: Array<T>;
}

interface Props<T> {
  values: Array<T> | Array<ValuesWithHeading<T>>;
  // whether values is of type ValuesWithHeading and to display headings differently
  headings?: boolean;
  render: (arg: T) => React.ReactNode;
  onClick: (clicked?: T) => void;
  // value represents the selected value(s).  Even if only one is
  // selectable, an array is required to maintain consistency in the API.
  value?: Array<T>;
  allowNone?: boolean;
  className?: any;
}

const SelectList = <T extends any>({
  values,
  headings,
  value,
  render,
  onClick,
  allowNone,
  className,
}: Props<T>) => {
  const renderValues = (values: Array<T>) => {
    return values.map((v, n) => {
      const selected = (value || []).find(selected => {
        // Remove the __typename fields here;  we only care about values.  This is specific
        // to tasks:  we assign __typename of "Staff" but the API responds with __typename of "User".
        let [a, b] = [selected, v];
        if (typeof a === "object" && typeof b === "object") {
          a = Object.assign({}, selected, { __typename: null });
          b = Object.assign({}, v, { __typename: null });
        }
        return isEqual(a, b);
      });
      return (
        <div
          key={n}
          className={selected ? "checked" : ""}
          onClick={() => onClick(v)}
        >
          {selected && <Check fill={color.green} />}
          {render(v)}
        </div>
      );
    });
  };

  return (
    <List className={className}>
      {allowNone && (
        <div className={!value ? "checked" : ""} onClick={() => onClick}>
          {!value && <Check fill={color.green} />}
          <span>None</span>
        </div>
      )}
      {isGroupedHeading<T>(values)
        ? ((values as any) as ValuesWithHeading<T>[]).map(group => (
            <>
              <ListHeading>{group.heading}</ListHeading>
              {renderValues(group.values)}
            </>
          ))
        : renderValues(values as T[])}
    </List>
  );
};

const isGroupedHeading = function<T>(
  input: T[] | ValuesWithHeading<T>[]
): input is ValuesWithHeading<T>[] {
  return (
    input[0] &&
    typeof (input[0] as ValuesWithHeading<T>).heading === "string" &&
    Array.isArray((input[0] as ValuesWithHeading<T>).values)
  );
};

export default SelectList;
