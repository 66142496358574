import React from "react";
import { notification } from "antd";

import { useQuery } from "src/utils/http/gqlQuery";
import DeleteConfirmModal from "src/shared/DeleteConfirmModal";
import { useDeleteOrder } from "../query";
import { orderGql } from "../query";

type Props = {
  patientID: string;
  orderID: string;
  onClose: () => void;
};

const DeleteModal: React.FC<Props> = ({ orderID, patientID, onClose }) => {
  const [, refetch] = useQuery({
    query: orderGql,
    variables: { userID: patientID },
    pause: true,
  });
  const deleteOrder = useDeleteOrder();

  const onDelete = async () => {
    const result = await deleteOrder({ id: orderID });
    if (result.error) {
      notification.error({
        message: "There was an error closing the order",
      });
      return;
    }
    notification.success({ message: "Order closed" });
    // We now refetch all orders and clear the cache.
    refetch();
    onClose();
  };

  return <DeleteConfirmModal onClose={onClose} onConfirm={onDelete} />;
};

export default DeleteModal;
