import { QUESTION_FORM } from "../event-type";

const VBT_TEMPLATE = {
  postTimestamp: null,
  postUserName: null,
  postUserUUID: null,
  complete: false,
  formName: "vbt", // this must match the name of the form in config/treatment-forms/index
  type: QUESTION_FORM,
  questionList: [
    {
      displayName: "Did the patient begin treatment?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      displayName: "If yes, what day did the patient begin treatment?",
      type: "datepicker",
    },
  ],
};

export default VBT_TEMPLATE;
