import map from "lodash/map";
import React, { Component } from "react";
import PropTypes from "prop-types";
import WearSchedule from "./wear-schedule";
import {
  getPatientTreatmentCycles,
  loadTreatmentCycles,
} from "src/redux/modules/wear-schedule";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const mapState = (state: Object, props: Object) => ({
  treatmentCycles: getPatientTreatmentCycles(state, props), //requires props.patient to get TC
});

const mapActions = dispatch => ({
  actions: bindActionCreators(
    {
      loadTreatmentCycles,
    },
    dispatch
  ),
});

const enhance = connect(
  mapState,
  mapActions
);

class WearScheduleList extends Component {
  static propTypes = {
    patientId: PropTypes.string.isRequired,
  };
  componentDidMount() {
    this.props.actions.loadTreatmentCycles(this.props.patientId);
  }

  render() {
    return (
      <div>
        {map(this.props.treatmentCycles, treatmentCycle => {
          return (
            <WearSchedule
              key={treatmentCycle.id}
              treatmentCycle={treatmentCycle}
            />
          );
        })}
      </div>
    );
  }
}

export default enhance(WearScheduleList);
