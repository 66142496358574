import { CombinedError, useQuery, useMutation } from "src/utils/http/gqlQuery";
import { Staff, Clinic } from "src/types/gql";

export const staffUsers = `
  query StaffUsers($organizationID: ID!) {
    staffUsers(organizationID: $organizationID) {
      id
      firstName
      lastName
      name
      email
      mobilePhoneNumber
      roles {
        id
        role
      }
      clinics {
        id
        name
        timezone
        line1
        line2
        city
        state
        postalCode
      }
    }
  }
`;

const allClinics = `{
  clinics {
    id
    name
    nickname
  }
}
`;

export const useGetAllStaff = (
  organizationID: string
): [boolean, CombinedError | undefined, Staff[]] => {
  const [{ fetching, error, data }] = useQuery<{
    staffUsers: Staff[];
  }>({ query: staffUsers, variables: { organizationID } });

  return [fetching, error, (data && data.staffUsers) || ([] as Staff[])];
};

export const useGetAllClinics = (
  organizationID: string
): [boolean, CombinedError | undefined, Clinic[]] => {
  const [{ fetching, error, data }] = useQuery<{
    clinics: Clinic[];
  }>({ query: allClinics, variables: { organizationID } });

  return [fetching, error, (data && data.clinics) || ([] as Clinic[])];
};

const updateStaffDetails = `
  mutation UpdateStaffDetails($input: UpdateStaff!) {
    updateStaffDetails(updateStaff: $input) {
      id
      firstName
      lastName
      name
      email
      mobilePhoneNumber
    }
  }
`;

export const useUpdateStaffDetails = () => {
  const [, execute] = useMutation(updateStaffDetails);
  return execute;
};

const addUserRoles = `
  mutation AddUserRoles($input: AddUserRoles!) {
    addUserRoles(addUserRoles: $input) {
      id
      role
    }
  }
`;

export const useAddUserRoles = () => {
  const [, execute] = useMutation(addUserRoles);
  return execute;
};

const deleteUserRoles = `
  mutation DeleteUserRoles($input: DeleteUserRoles!) {
    deleteUserRoles(deleteUserRoles: $input) {
      ids
    }
  }
`;

export const useDeleteUserRoles = () => {
  const [, execute] = useMutation(deleteUserRoles);
  return execute;
};

const addClinicsForStaff = `
  mutation AddClinicsForStaff($input: AddClinicsForStaff!) {
    addClinicsForStaff(addClinicsForStaff: $input) {
      id
      name
      clinics {
        id
        name
      }
    }
  }
`;

export const useAddClinicsForStaff = () => {
  const [, execute] = useMutation(addClinicsForStaff);
  return execute;
};

const removeClinicsForStaff = `
  mutation RemoveClinicsForStaff($input: RemoveClinicsForStaff!) {
    removeClinicsForStaff(removeClinicsForStaff: $input) {
      id
      name
      clinics {
        id
        name
      }
    }
  }
`;

export const useRemoveClinicsForStaff = () => {
  const [, execute] = useMutation(removeClinicsForStaff);
  return execute;
};

export const allRoles = [
  "orthodontist",
  "ortho_assistant",
  "sales_manager",
  "treatment_planner",
  "internal_manufacturing",
  "care_team",
  "admin",
  "refund_manager",
  "schedule_manager",
  "user_manager",
  "promotions_manager",
  "free_treatment_users_manager",
  "payment_plan_manager",
  "clinic_coordinator",
  "operations_associate",
];
