import { FieldNode, OperationDefinitionNode } from "graphql";
import { filter, map, merge, pipe, share } from "wonka";
import { Exchange, Operation, OperationResult } from "@urql/core";

// Scenario: In a few places, mostly at page load, we query for a
// superset of results, such as looking for all form submissions
// for a given patient. Later, often immediately afterwards, independent
// UI components will query for just a subset of that data, usually by
// specifying more restrictive variables in the query, which causes extra network
// fetches; the data is already there, it's just hidden inside of `urql` cache.
// `cachedSubsetExchange` allows us to intercept subsequent, more restrictive queries,
// and just pluck a subset from the superset that's already available.
//
// NOTE: This should be used sparingly, on a case by case basis, for slow queries.
// See `getCachedFormSubmissionsSubset` for a concrete example.
const cachedSubsetExchange: Exchange = ({ client, forward }) => {
  // Data from `filter()` is temporarily stored in `subsetLookup` to be used in `map()`
  const subsetLookup = new Map<number, OperationResult>();

  const filterCached = (op: Operation): boolean => {
    const { key, kind, context } = op;

    // Allow `network-only` to force a cache miss
    if (context.requestPolicy === "network-only" || kind !== "query") {
      return false;
    }

    // For now, only optimizing for form submissions.
    // Can add more variations down the road, here.
    const subset = getCachedFormSubmissionsSubset(client, op);

    if (subset) {
      subsetLookup.set(key, subset);
      return true;
    }

    return false;
  };

  return ops$ => {
    const sharedOps$ = share(ops$);

    return merge([
      pipe(
        sharedOps$,
        filter(filterCached),
        map(op => {
          const subset = subsetLookup.get(op.key);
          subsetLookup.delete(op.key);
          return subset!;
        })
      ),
      forward(
        pipe(
          sharedOps$,
          filter(op => !filterCached(op))
        )
      ),
    ]);
  };
};

// If `op` is querying for `Submission(userID, formID)`, we look for
// any cached `Submissions(userID)` results, which will already
// have a list of all submissions for that patient.
// If the superset result is found, just plucks the relevant
// subset of those submissions that are for `formID`
function getCachedFormSubmissionsSubset(
  client,
  op: Operation
): OperationResult | undefined {
  const formID = op.variables && op.variables.formID;
  const defs = op.query && op.query.definitions;
  if (!defs || defs.length === 0) {
    return;
  }
  const firstDef = defs[0] as OperationDefinitionNode;
  const opName = firstDef.name && firstDef.name.value;
  const sels = firstDef.selectionSet && firstDef.selectionSet.selections;

  if (!formID || opName !== "Submissions") {
    return;
  }

  // Play it safe: ignore batched queries and multiple selections
  if (!sels || sels.length !== 1 || (!defs || defs.length !== 1)) {
    return;
  }

  // This is almost always "submissions", but grab from the
  // query definition in case a different name is used.
  const firstSel = sels[0] as FieldNode;
  const selName = firstSel.name.value;

  const superset = client.readQuery(
    op.query,
    {
      userID: op.variables.userID,
    },
    {
      // NOTE: Important to use `cache-only` here,
      // we don't want `readQuery()` to perform another fetch,
      // as that would defeat the purpose of this whole exchange.
      requestPolicy: "cache-only",
    }
  );

  const submissions =
    superset &&
    superset.data &&
    superset.data[selName] &&
    superset.data[selName].filter(s => s.formID === formID);
  if (submissions) {
    return {
      ...superset,
      data: {
        [selName]: submissions,
      },

      // NOTE: Make sure to override `operation:`
      // Otherwise, `urql` would not consider `op` to have ever finished,
      // because `superset.key !== op.key`
      operation: op,
    };
  }
}

export default cachedSubsetExchange;
