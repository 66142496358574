const UPDATE_UI = "UI/UPDATE_UI";

const initialState = {
  patientSort: "created_at",
  patientShowAll: false,
  statusFilter: [],
  eventStreamFilter: [],
  showProgressCheck: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_UI:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}

export function updateUI(data) {
  return {
    type: UPDATE_UI,
    data,
  };
}
