import isEqual from "react-fast-compare";
import { Task, Tray } from "src/types/api";
import { TreeNode } from "src/types/local";
import differenceInDays from "date-fns/difference_in_days";
import { startHour, endHour } from "./consts";

export const host = () => process.env.REACT_APP_API_HOST || "/api";
export const url = (path: string): string => `${host()}${path}`;

export const titleCase = (words: string) => {
  return words
    .replace(/_/g, " ")
    .split(" ")
    .map(word => {
      return word ? `${word[0].toUpperCase()}${word.substr(1)}` : "";
    })
    .join(" ");
};

export const pluralize = (word, count) =>
  Math.abs(count) === 1 ? word : `${word}s`;

export const gqlError = (error: any): string => {
  if (error.graphQLErrors) {
    return error.graphQLErrors.map(e => e.message).join(", ");
  }
  return error.message || "There was an error";
};

export const phaseMapping = {
  active_retention: "Active Retention",
  alignment: "Alignment",
  bite_correction: "Bite correction",
  expansion: "Expansion",
  overcorrection: "Overcorrection",
  passive: "Passive",
  retainer: "Retainer",
  space_closure: "Space closure",
};

// This maps appointment types and subtypes to human names
export const appointmentTypeAndSubtypeMapping = {
  beginning: "Beginning",
  followup: "Followup",

  ortho_consult: "Orthodontics Consult",

  indirect: "Indirect Delivery",
  indirect_extended: "Extended Indirect Delivery",
  indirect_hybrid: "Hybrid Indirect Delivery",
  maintenance: "Maintenance",

  refinement: "Refinement Visit",
  debond: "Debond Visit",

  restart: "Restart",
  records: "Records Only",
  virtual_comfort: "Virtual Comfort",
  unplanned: "Unplanned",

  hygiene: "Hygiene",
  odontology_simple: "Odontology Simple",
  odontology_complex: "Odontology Complex",

  // Unbookable
  admin: "Admin",
  meeting: "Meeting",
  lunch: "Lunch",

  // Legacy types now unused
  ref_grad: "Ref. or Grad.",
  rescan: "Rescan",
  thirty_staff_only: "Staff only (30m)",
  thirty: "Doctor only (30m)",
  sixty: "Doctor only (60m)",

  // other Appointment Types
  outlier: "Outlier",
  block: "Block",
  staff_only: "Staff Only",
  misc_long: "Miscellaneous Long",
  misc_short: "Miscellaneous Short",
};

export const getAppointmentName = (
  apptType: { name: string } | string | null | undefined
): string => {
  const key =
    apptType && typeof apptType !== "string" ? apptType.name : apptType;

  if (!key) {
    return "--";
  }

  if (appointmentTypeAndSubtypeMapping[key]) {
    return appointmentTypeAndSubtypeMapping[key];
  }

  return key;
};

export const request = async (
  path: string,
  opts: { [key: string]: any } = {}
): Promise<any> => {
  const mergedOpts = Object.assign({}, opts);
  const token = window.localStorage.getItem("auth_token");

  mergedOpts.headers = Object.assign({}, opts.headers || {});

  if (
    mergedOpts.headers["content-type"] === undefined &&
    opts.body instanceof FormData === false
  ) {
    mergedOpts.headers["content-type"] = "application/json";
  }

  if (token && token !== "undefined") {
    mergedOpts.headers["Authorization"] = `Bearer ${token}`;
  }

  return window
    .fetch(url(path), mergedOpts)
    .catch(() => {
      // Render an object that fulfils the Response.JSON interface, allowing this
      // error message to be shown.
      return {
        status: 400,
        json: async () => ({
          code: 999,
          type: "fetch_fail",
          error: "Please check your internet connection and try again",
        }),
      };
    })
    .then(async resp => {
      if (resp.status === 204) {
        return {};
      }

      if (resp.status >= 200 && resp.status <= 299) {
        // With successful statuses, always return the JSON or an empty object
        return resp ? resp.json().catch(() => ({})) : {};
      }
      // For invalid responses, attempt to parse the JSON and return it.  If
      // this fails, return a parse error, and if there's no response return a
      // default "no response" error.
      let error;
      try {
        error = await resp.json().catch(e => ({
          code: 998,
          error: "parse_error",
          message: e.message,
        }));
      } catch (e) {
        error = { error: "response_error", message: "no response" };
      }

      return Object.assign({ status: resp && resp.status }, error);
    });
};

/**
 * buildTrees takes an array of items - any object type with the keys containing "previous_task_id" -
 * and builds trees by walking from child nodes to the parents.
 *
 * There may be more than one tree returned.
 *
 * If the "lookup" ID is provided, this will return that node as a root.  For example,
 * we may show a child node's details in the right hand panel.  We also want to show
 * which tasks depend on this child node.
 *
 * Instead of searching for the node in the tree, we only return a subset of the tree
 * where "lookup" is the parent.
 */
export function buildTrees(values: Array<Task>): Array<TreeNode<Task>> {
  const unassigned: Array<TreeNode<Task>> = [];

  // Store a map of parents to their children.  This is all nodes, not just root
  // nodes.
  const map: { [key: string]: TreeNode<Task> } = {};

  values.forEach((item: Task) => {
    // create a TreeNode for this item.
    const node = {
      item,
      children: [],
    };

    map[item.id] = node;

    if (!item.previous_task_id) {
      // This is a root node.  We can skip attempting to find its parent.
      return;
    }

    if (map[item.previous_task_id] === undefined) {
      // We haven't yet added the parent root node to the lookup table.
      unassigned.push(node);
      return;
    }

    map[item.previous_task_id].children.push(node);
  });

  unassigned.forEach((node: TreeNode<Task>) => {
    if (
      !node.item.previous_task_id ||
      map[node.item.previous_task_id] === undefined
    ) {
      // This is a child node with no parent.  This may be because we only
      // loaded part of the tree via the API.  In this case, the child should
      // be considered a root node.
      return;
    }
    map[node.item.previous_task_id].children.push(node);
  });

  // Return all natural tree roots
  const result: Array<TreeNode<Task>> = [];

  Object.keys(map).forEach((id: string) => {
    const node = map[id];
    const { previous_task_id } = node.item;
    if (
      previous_task_id === undefined ||
      (previous_task_id !== null && map[previous_task_id] === undefined)
    ) {
      // If this is a natural root node (no parent ID) or we have no parent, this
      // add it to the result - which is an array of roots.
      result.push(node);
    }
  });

  return result;
}

export function getChildCount<T>(root?: TreeNode<T>): number {
  if (!root) {
    return 0;
  }

  let count = root.children.length;
  root.children.forEach(child => {
    count += getChildCount(child);
  });
  return count;
}

export function treeIter<T>(
  root: TreeNode<T>,
  func: (node: TreeNode<T>) => any
): Array<any> {
  if (!root) {
    return [];
  }

  let result: Array<TreeNode<T>> = [];

  // First, iterate through all direct children.
  root.children.forEach(child => {
    result.push(func(child));
  });

  // Then, iterate through children's children
  root.children.forEach(child => {
    result = result.concat(treeIter(child, func));
  });

  return result;
}

export function buildTree(values: Array<Task>): TreeNode<Task> {
  const tasks = buildTrees(values);
  if (Array.isArray(tasks)) {
    return tasks[0];
  }
  return tasks;
}

// formatPhoneNumber formats a phone number for display.
export function formatPhoneNumber(str: string): string {
  const cleaned = str.replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "";
}

// toQueryString takes an object and returns the query string
// for appending to a given URI, inclusive of the '?' marker if
// a non-falsy object is supplied.
export function toQueryString(obj?: { [key: string]: any }): string {
  // Allow the use of passing a falsy value here so that e can call
  // this function from string interpolation directly.
  if (!obj) {
    return "";
  }

  const keys = Object.keys(obj);
  if (keys.length === 0) {
    return "";
  }

  const params = new URLSearchParams();
  keys.forEach((k: string) => {
    params.set(k, obj[k]);
  });

  return `?${params.toString()}`;
}

// Returns the number of days to (positive int) or from (negative int) their CLOSEST
// birthday, whether that's the current year, last year, or next year.
export function daysUntilBirthday(dateOfBirth: string, now?: string): number {
  const comparator = now
    ? new Date(`${now}T00:00:00.000Z`)
    : new Date(Date.now());

  // This is used multiple times depending on the proximity to their birthday,
  // so it's a utility function that returns a diff given a current date.
  const getDiff = (date: string): number => {
    // Parse the birthday as UTC.
    const birthday = new Date(`${date}T00:00:00.000Z`);

    // Compare the date of the birthday this year to the date now.  This gives us
    // two possibilities:  >= 0, which means we're close to their next birthday, or
    // < 0, which means we're past their birthday.
    return differenceInDays(birthday, comparator);
  };

  // Replace the YYYY with the current year.  That'll let us know how close to
  // the birthday we are _this_ year when we compare dates in days, as opposed
  // to how many days we are from the original birthday.  Then we can (sort of)
  // ignore leap years.
  const diff = getDiff(`${comparator.getFullYear()}-${dateOfBirth.substr(5)}`);

  // If the diff is <= -182 days (half a year ago), check how long until their
  // next birthday.
  if (diff <= -182) {
    return getDiff(`${comparator.getFullYear() + 1}-${dateOfBirth.substr(5)}`);
  }

  // If the diff is >= 182 days (half a year ago), check how long until their
  // past birthday.  Maybe we could ask them about their past birthday if it
  // was, say, last week.
  if (diff >= 182) {
    return getDiff(`${comparator.getFullYear() - 1}-${dateOfBirth.substr(5)}`);
  }

  // The birthday is some time this year.
  return diff;
}

type PhaseInfo = {
  name: string;
  index: number;
  date: string;
  count: number;
};

export function getPhases(trays: Array<Tray>): { [id: string]: PhaseInfo } {
  return trays.reduce(
    (phases: { [name: string]: PhaseInfo }, t: Tray, i: number) => {
      if (i === 0) {
        phases[t.phase] = {
          name: t.phase,
          count: 1,
          index: 0,
          date: t.expected_start_date,
        };
        return phases;
      }

      if (!t.phase) {
        return phases;
      }

      const prev = trays[i - 1];

      if (prev.phase && t.phase && prev.phase !== t.phase) {
        // Add the number of trays in each phase
        phases[prev.phase].count = i - phases[prev.phase].index;
        phases[t.phase] = {
          name: phaseMapping[t.phase] || "Trays",
          count: 1,
          index: i,
          date: t.expected_start_date,
        };
      }

      if (i === trays.length - 1) {
        phases[t.phase].count = trays.length - phases[t.phase].index;
      }

      return phases;
    },
    {}
  );
}

export const uuid = (userId?: string) => {
  let entropy = 0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    const r = (entropy + Math.random() * 16) % 16 | 0;
    entropy = Math.floor(entropy / 16);
    // We need to replace 'x' wholly and 'y' with the UUID variant
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const toggle = <T extends any>(input: Array<T>, item: T): Array<T> => {
  if (!input) {
    return [] as Array<T>;
  }

  // Remove the __typename fields here;  we only care about values.  This is specific
  // to tasks:  we assign __typename of "Staff" but the API responds with __typename of "User".
  let a = item;
  if (typeof a === "object") {
    a = Object.assign({}, item, { __typename: null });
  }

  // these may be different objects with the same value under the hood,
  // as the search component hits GQL and creates a new map every time.
  //
  // Therefore, use react-fast-equal to find our index
  const idx = input.findIndex(b => {
    if (typeof b === "object") {
      return isEqual(a, Object.assign({}, b, { __typename: null }));
    }
    return isEqual(a, b);
  });

  if (idx > -1 && input.length > 1) {
    const copy = input.slice(0);
    copy.splice(idx, 1);
    return copy;
  }

  if (idx > -1 && input.length === 1) {
    return [] as Array<T>;
  }

  return input.concat([item]);
};

// Calendar utils
export const getLabel = (name: string) => {
  switch (name) {
    case "beginning":
      return "Beginning";
    case "followup":
      return "Followup";
    default:
      return "";
  }
};

export const minutes = [
  "00",
  "05",
  "10",
  "15",
  "20",
  "25",
  "30",
  "35",
  "40",
  "45",
  "50",
  "55",
];

export const hours = new Array(endHour - startHour)
  .fill(null)
  .map((_, n) => {
    const h = n + startHour;
    return h >= 12 ? `${h % 12 || 12}:00 PM` : `${h}:00 AM`;
  })
  .map(h => ({ label: h }));
