import styled from "react-emotion";
import color from "src/styles/color";

export const Wrapper = styled.div`
  border: 1px solid ${color.border};
  border-radius: 3px;
`;

export const Title = styled.div`
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${color.background};
  border-bottom: 1px solid ${color.border};
  padding: 0 24px;

  > div:nth-child(1) {
    font-size: 20px;
    line-height: 1;
  }

  button {
    font-weight: normal;
  }
`;

export const Content = styled.div`
  background: ${color.white};
`;
