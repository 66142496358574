import React from "react";
import styled, { css } from "react-emotion";
import { message } from "antd";
import { Link } from "react-router";
import { DateTime } from "luxon";
import { Staff } from "src/types/gql";
import { getWeeksDistToISODateString } from "src/utils/dates";
import { Row, RowContent } from "../Shared/Row";
import Assignees from "../Shared/Assignees";
import PipelineType from "../PipelineType";
import { Task, TaskOption } from "../types";
import { humanReadablePatientStatusName } from "../mappings";
import { useUpdateTasks } from "../queries";
import Assigned from "../Shared/Assigned";
import color from "src/styles/color";
import { titleCase } from "src/shared/util";
import { PatientStatus } from "@/types/gql";

type Props = {
  task: Task;
  currentPatientStatus: PatientStatus | undefined;
  urlPrefix: string;
  blocked?: boolean;
  showCompletedAt?: boolean;
  showCurrentPatientStatus?: boolean;
};

const TaskRow: React.FC<Props> = ({
  task,
  currentPatientStatus,
  urlPrefix,
  blocked,
  showCompletedAt,
  showCurrentPatientStatus = true,
}) => {
  const update = useUpdateTasks();

  const onChange = async (opt: TaskOption) => {
    const result = await update({
      input: [{ id: task.id, opt }],
      deleteFields: [],
    });
    if (result.error) {
      message.error(`Error updating task: ${result.error}`);
      return;
    }
    message.success(`Task updated`);
  };

  const taskHomeClinicName = task.user.homeClinic
    ? task.user.homeClinic.name
    : "N/A";

  const treatmentTypeValue =
    task.pipeline &&
    task.pipeline.treatment &&
    task.pipeline.treatment.treatmentType
      ? titleCase(task.pipeline.treatment.treatmentType)
      : "N/A";

  const weeks =
    task.pipeline && task.pipeline.createdAt
      ? getWeeksDistToISODateString(task.pipeline.createdAt) + 1
      : 0;

  return (
    <Link to={`${urlPrefix}/${task.id}`} className={blocked ? blockedCSS : ""}>
      <Row>
        <RowContent>{task.user.name}</RowContent>
        <RowContent>{treatmentTypeValue}</RowContent>
        <RowContent>{taskHomeClinicName}</RowContent>
        <RowContent>
          {task.pipeline && task.pipeline.type && (
            <PipelineType type={task.pipeline.type} />
          )}
        </RowContent>
        <RowContent>{task.name}</RowContent>
        <RowContent>
          {task.assignedTo.map(a => (
            <Assigned key={a.id} staff={(a as unknown) as Staff} hideName />
          ))}
          {(!task.assignedTo || task.assignedTo.length === 0) && (
            <Assignees
              value={(task.assignedTo as unknown) as Staff[]}
              onChange={s => {
                const assignedToIDs = s
                  .filter(assigned => {
                    return (
                      task.assignedTo.find(item => item.id === assigned.id) ===
                      undefined
                    );
                  })
                  .map(s => s.id);
                onChange({ assignedToIDs });
              }}
            />
          )}
        </RowContent>
        <RowContent>
          {showCompletedAt && task.completedAt
            ? DateTime.fromISO(task.completedAt).toLocaleString()
            : task.pipelineDueAt &&
              DateTime.fromISO(task.pipelineDueAt).toLocaleString()}
        </RowContent>
        <RowContent>{weeks}</RowContent>
        <RowContent>
          {task.pipeline && task.pipeline.onHoldCategory ? (
            <Hold>
              Hold: {task.pipeline.onHoldReason || task.pipeline.onHoldCategory}
            </Hold>
          ) : (
            <>
              {task.completedAt && "Completed"}
              {!task.completedAt && task.startedAt && "Started"}
              {!task.startedAt && !task.completedAt && "-"}
            </>
          )}
        </RowContent>
        {showCurrentPatientStatus && (
          <RowContent>
            {currentPatientStatus
              ? humanReadablePatientStatusName[currentPatientStatus.name]
              : "-"}
          </RowContent>
        )}
      </Row>
    </Link>
  );
};

const blockedCSS = css`
  > div {
    opacity: 0.4;
  }
`;

const Hold = styled.span`
  color: ${color.red};
`;

export default TaskRow;
