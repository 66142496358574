import React from "react";
import { Menu, Icon } from "antd";
import isNil from "lodash/isNil";
import groupBy from "lodash/groupBy";
import {
  StyledBaseDropdown,
  Inner,
  Label,
  Placeholder,
  MenuGroupHeader,
  MenuGroupHeaderArchived,
} from "./styles";
import { Selected, SelectedObj } from "src/types/types";

export type Props = {
  options: Array<string> | Array<SelectedObj>;
  selected?: Selected;
  onSelect: (Selected) => void;
  triggerOnClick: boolean;
  disabled?: boolean;
  menuMaxHeight?: string;
  height?: string;
  width?: string;
  placeholder?: string;
  className?: string;
};

const Dropdown = (props: Props) => {
  const isStringType =
    typeof props.options[0] === "string" ||
    typeof props.options[0] === "number";
  const propsOptions = props.options as any;
  const options: Array<SelectedObj> = isStringType
    ? propsOptions.map(s => ({ group: "default", label: s, value: s }))
    : propsOptions.map(option => ({
        ...option,
        group: option.group || "default",
      }));

  const groupedOptions = groupBy(options, "group");
  const isArchived = name => name[0] === "-";
  const allGroupNames = Object.keys(groupedOptions)
    .filter(name => name !== "default")
    .sort();
  const groupNames = allGroupNames.filter(name => !isArchived(name));
  const archivedGroupNames = allGroupNames.filter(isArchived);
  const orderedGroups = ["default", ...groupNames, ...archivedGroupNames];
  const overlay = (
    <Menu
      style={{ maxHeight: props.menuMaxHeight || "300px", overflow: "auto" }}
    >
      {orderedGroups.map(group => {
        const items = groupedOptions[group];
        if (!items) return null;

        const archived = isArchived(group);
        const groupLabel = archived ? group.substr(1) : group;

        // `Menu` styles get messed up with we use a fragment, so use an array instead.
        return [
          group !== "default" ? (
            archived ? (
              <MenuGroupHeaderArchived key={group}>
                {groupLabel}
              </MenuGroupHeaderArchived>
            ) : (
              <MenuGroupHeader key={group}>{groupLabel}</MenuGroupHeader>
            )
          ) : null,
          ...items.map((item, idx) => {
            const { label, icon } = item;
            const selectionValue = isStringType ? item.value : item;
            return (
              <Menu.Item
                key={label + idx}
                onClick={() => props.onSelect(selectionValue)}
              >
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <div style={{ flex: 1 }}>{label}</div>
                  {icon}
                </div>
              </Menu.Item>
            );
          }),
        ];
      })}
    </Menu>
  );

  // If we're passed a selected value, see if it matches one of the options.  This ensures
  // that the parent component can pass an ID as the selected value, and we show the correct
  // label as appropriate.
  //
  // EG:
  //   options = [{ value: "1", label: "foobar"}]
  //   selected = "1"
  //
  // We show "foobar"
  const s = props.selected;
  const found =
    s &&
    (typeof s === "string"
      ? options.find(o => o.value === s)
      : options.find(o => o.value === s.value));
  const selected = !found ? props.selected : found;

  const trigger = props.triggerOnClick ? "click" : "hover";

  const { placeholder = "Select an item" } = props;

  return (
    <StyledBaseDropdown
      overlay={overlay}
      trigger={[trigger]}
      disabled={props.disabled}
      theme={{
        height: props.height,
        width: props.width,
        disabled: props.disabled,
      }}
      className={props.className}
    >
      <Inner theme={{ disabled: props.disabled }}>
        <Label>
          {!isNil(selected) ? (
            typeof selected === "object" ? (
              selected.label
            ) : (
              selected
            )
          ) : (
            <Placeholder>{placeholder}</Placeholder>
          )}{" "}
        </Label>
        <Icon type="down" />
      </Inner>
    </StyledBaseDropdown>
  );
};

Dropdown.defaultProps = {
  triggerOnClick: true,
};

export default Dropdown;
