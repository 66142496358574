// Minimum cell height
export const cellHeight = 120;

// Minimum slot width
export const slotWidth = 100;

// This is used when calculating offsets for rendering appointments in the calendar.
// We only ever start the calendar at 7AM.
export const startHour = 7;
export const endHour = 21;
