import styled from "react-emotion";
import color from "src/styles/color";

export const Content = styled.div`
  width: 400px;

  h1 {
    margin: 1rem;
  }
`;

export const HoldItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid ${color.borderLight};

  &:first-of-type {
    border-top: 1px solid ${color.borderLight};
  }

  p {
    margin: 0;
  }
`;
