const initialize = (
  fileURL,
  canvas,
  onLoadSuccess,
  setError,
  setToothInfo,
  setSelectedTooth,
  setLoadProgress,
  setSpacingVisible,
  setAxialLowBound,
  setAxialLowValue,
  setAxialHighValue,
  setAxialHighBound,
  setSagittalLowBound,
  setSagittalLowValue,
  setSagittalHighValue,
  setSagittalHighBound,
  setCoronalLowBound,
  setCoronalLowValue,
  setCoronalHighValue,
  setCoronalHighBound,
  setVolumeHardBonesLevel,
  setVolumeSoftBonesLevel,
  setVolumeHardBonesOpacity,
  setVolumeSoftBonesOpacity,
  setSurfaceBonesLevel,
  setSurfaceOpacity,
  setSurfaceReduction,
  setJawAlignment,
  setSurfaceMode,
  setCBCTAvailable
) => {
  // @ts-ignore should be a global var
  if (!vtkApp) {
    throw new Error(
      "Webassembly.js file not loaded. Did someone remove the import from index.html?"
    );
  }

  if (!canvas) return;

  const Module = {
    canvas: canvas,
    onRuntimeInitialized: () => {
      if (canvas) {
        canvas.focus();
      }
      const WebSignalsImpl = {
        onDownloaded: (isSuccess, fileName) => {
          if (isSuccess) {
            // @ts-ignore
            Module.webOpenProject(fileName, () => {
              console.log("Module.webOpenProject -> project is opened");
            });
            // this event makes canvas resize to full screen
            window.dispatchEvent(new Event("resize"));
          } else {
            setError(`Error downloading file ${fileName}`);
          }
        },
        onProjectIsOpened: () => {
          onLoadSuccess();
        },
        onSelectTooth: (tooth: string) => {
          setSelectedTooth(tooth);
          if (!tooth) {
            setToothInfo(null);
            return;
          }

          setToothInfo({
            toothNum: tooth,
            // @ts-ignore
            ...JSON.parse(Module.webGetToothMovement(tooth)),
          });
        },
        onLoadingProgress: (progress, details) => {
          setLoadProgress(`${progress}% - ${details}`);
        },
        onSpacingIsVisibleChanged: isVisible => {
          setSpacingVisible(isVisible);
        },
        onArticulatorCompleted: function(angle, distance) {
          console.log(
            "onArticulatorCompleted angle:" + angle + " distance:" + distance
          );
        },
        onErrorMessage: function(message, critical) {
          console.log(
            "onErrorMessage message:" + message + " critical:" + critical
          );
        },
        onXminChanged: function(value) {
          console.log("onXminChanged: " + value);
          setSagittalLowValue(value);
        },
        onXmaxChanged: function(value) {
          console.log("onXmaxChanged: " + value);
          setSagittalHighValue(value);
        },
        onYminChanged: function(value) {
          console.log("onYminChanged: " + value);
          setCoronalLowValue(value);
        },
        onYmaxChanged: function(value) {
          console.log("onYmaxChanged: " + value);
          setCoronalHighValue(value);
        },
        onZminChanged: function(value) {
          console.log("onZminChanged: " + value);
          setAxialLowValue(value);
        },
        onZmaxChanged: function(value) {
          console.log("onZmaxChanged: " + value);
          setAxialHighValue(value);
        },
        onXBoundLowChanged: function(value) {
          console.log("onXBoundLowChanged: " + value);
          setSagittalLowBound(value);
        },
        onXBoundHighChanged: function(value) {
          console.log("onXBoundHighChanged: " + value);
          setSagittalHighBound(value);
        },
        onYBoundLowChanged: function(value) {
          console.log("onYBoundLowChanged: " + value);
          setCoronalLowBound(value);
        },
        onYBoundHighChanged: function(value) {
          console.log("onYBoundHighChanged: " + value);
          setCoronalHighBound(value);
        },
        onZBoundLowChanged: function(value) {
          console.log("onZBoundLowChanged: " + value);
          setAxialLowBound(value);
        },
        onZBoundHighChanged: function(value) {
          console.log("onZBoundHighChanged :" + value);
          setAxialHighBound(value);
        },
        onVolumeHardBonesLevelChanged: function(value) {
          console.log("onVolumeHardBonesLevelChanged :" + value);
          setVolumeHardBonesLevel(value);
        },
        onVolumeSoftBonesLevelChanged: function(value) {
          console.log("onVolumeSoftBonesLevelChanged :" + value);
          setVolumeSoftBonesLevel(value);
        },
        onVolumeHardBonesOpacityChanged: function(value) {
          console.log("onVolumeHardBonesOpacityChanged :" + value);
          setVolumeHardBonesOpacity(value * 100);
        },
        onVolumeSoftBonesOpacityChanged: function(value) {
          console.log("onVolumeSoftBonesOpacityChanged :" + value);
          setVolumeSoftBonesOpacity(value * 100);
        },
        onSurfaceBonesLevelChanged: function(value) {
          console.log("onSurfaceBonesLevelChanged :" + value);
          setSurfaceBonesLevel(value);
        },
        onSurfaceOpacityChanged: function(value) {
          console.log("onSurfaceOpacityChanged :" + value);
          setSurfaceOpacity(value * 100);
        },
        onSurfaceReductionChanged: function(value) {
          console.log("onSurfaceReductionChanged :" + value);
          setSurfaceReduction(value * 100);
        },
        onJawAlignmentChanged: function(alignMandible) {
          console.log("onJawAlignmentChanged: " + alignMandible);
          setJawAlignment(alignMandible);
        },
        onSurfaceModeChanged: function(surfaceMode) {
          console.log("onSurfaceModeChanged: " + surfaceMode);
          setSurfaceMode(surfaceMode);
        },
        onCBCTAvailabilityChanged: function(available) {
          console.log("onCBCTAvailabilityChanged: " + available);
          setCBCTAvailable(available);
        },
      };

      // @ts-ignore
      Module.WebSignals.implement(WebSignalsImpl);

      // @ts-ignore
      Module.webDownloadFile(fileURL, "temp.txp");
    },
  };

  // @ts-ignore global var
  vtkApp(Module);
  return Module;
};

export default initialize;
