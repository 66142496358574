export default function parsePhoneNumber(number) {
  if (number === null) {
    return "";
  }

  const isUSNumberLong = number =>
    number.slice(0, 2) === "+1" && number.length === 12;
  const isUSNumberShort = number =>
    Number.isInteger(number) || number.length === 10;

  if (isUSNumberLong(number)) {
    return `${number.slice(0, 2)} (${number.slice(2, 5)}) ${number.slice(
      5,
      8
    )}-${number.slice(8)}`;
  } else if (isUSNumberShort(number)) {
    return `+1 (${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(
      6
    )}`;
  }

  return number;
}
