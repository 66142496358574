import React from "react";

const Visa = () => {
  return (
    <svg width="64" height="20" viewBox="0 0 64 20" fill="none">
      <path
        d="M23.4667 18.8952L26.7175 0.60952H31.7968L28.6476 18.8952H23.4667Z"
        fill="#3C58BF"
      />
      <path
        d="M23.4667 18.8952L27.6317 0.60952H31.7968L28.6476 18.8952H23.4667Z"
        fill="#293688"
      />
      <path
        d="M47.0349 0.812697C46.019 0.406348 44.3936 0 42.3619 0C37.2825 0 33.6254 2.53968 33.6254 6.19682C33.6254 8.93968 36.165 10.3619 38.1968 11.2762C40.2285 12.1905 40.8381 12.8 40.8381 13.6127C40.8381 14.8317 39.2127 15.4413 37.7904 15.4413C35.7587 15.4413 34.6412 15.1365 32.9142 14.4254L32.2031 14.1206L31.492 18.2857C32.7111 18.7936 34.946 19.3016 37.2825 19.3016C42.6666 19.3016 46.2222 16.7619 46.2222 12.9016C46.2222 10.7683 44.9015 9.14285 41.8539 7.82222C40.0254 6.90793 38.9079 6.4 38.9079 5.48571C38.9079 4.67301 39.8222 3.86032 41.8539 3.86032C43.5809 3.86032 44.8 4.16508 45.7142 4.57143L46.2222 4.7746L47.0349 0.812697Z"
        fill="#3C58BF"
      />
      <path
        d="M47.0349 0.812697C46.019 0.406348 44.3936 0 42.3619 0C37.2825 0 34.5396 2.53968 34.5396 6.19682C34.5396 8.93968 36.165 10.3619 38.1968 11.2762C40.2285 12.1905 40.8381 12.8 40.8381 13.6127C40.8381 14.8317 39.2127 15.4413 37.7904 15.4413C35.7587 15.4413 34.6412 15.1365 32.9142 14.4254L32.2031 14.1206L31.492 18.2857C32.7111 18.7936 34.946 19.3016 37.2825 19.3016C42.6666 19.3016 46.2222 16.7619 46.2222 12.9016C46.2222 10.7683 44.9015 9.14285 41.8539 7.82222C40.0254 6.90793 38.9079 6.4 38.9079 5.48571C38.9079 4.67301 39.8222 3.86032 41.8539 3.86032C43.5809 3.86032 44.8 4.16508 45.7142 4.57143L46.2222 4.7746L47.0349 0.812697Z"
        fill="#293688"
      />
      <path
        d="M55.7714 0.60952C54.5524 0.60952 53.6381 0.711108 53.1302 1.93016L45.5111 18.8952H50.9968L52.0127 15.8476H58.5143L59.1238 18.8952H64L59.7333 0.60952H55.7714ZM53.4349 12.8C53.7397 11.8857 55.4667 7.41587 55.4667 7.41587C55.4667 7.41587 55.873 6.29841 56.1778 5.5873L56.4826 7.31428C56.4826 7.31428 57.4984 11.8857 57.7016 12.9016H53.4349V12.8Z"
        fill="#3C58BF"
      />
      <path
        d="M56.9905 0.60952C55.7714 0.60952 54.8572 0.711108 54.3492 1.93016L45.5111 18.8952H50.9968L52.0127 15.8476H58.5143L59.1238 18.8952H64L59.7333 0.60952H56.9905ZM53.4349 12.8C53.8413 11.7841 55.4667 7.41587 55.4667 7.41587C55.4667 7.41587 55.873 6.29841 56.1778 5.5873L56.4826 7.31428C56.4826 7.31428 57.4984 11.8857 57.7016 12.9016H53.4349V12.8Z"
        fill="#293688"
      />
      <path
        d="M14.1206 13.4095L13.6127 10.7683C12.6984 7.72063 9.75235 4.36826 6.50156 2.74286L11.073 18.9968H16.5587L24.7873 0.711113H19.3016L14.1206 13.4095Z"
        fill="#3C58BF"
      />
      <path
        d="M14.1206 13.4095L13.6127 10.7683C12.6984 7.72063 9.75235 4.36826 6.50156 2.74286L11.073 18.9968H16.5587L24.7873 0.711113H20.3174L14.1206 13.4095Z"
        fill="#293688"
      />
      <path
        d="M0 0.60952L0.914286 0.812695C7.41587 2.3365 11.8857 6.19682 13.6127 10.7682L11.7841 2.13333C11.4794 0.914282 10.5651 0.60952 9.44762 0.60952H0Z"
        fill="#FFBC00"
      />
      <path
        d="M0 0.60952C6.50159 2.13333 11.8857 6.09524 13.6127 10.6667L11.8857 3.45397C11.581 2.23492 10.5651 1.52381 9.44762 1.52381L0 0.60952Z"
        fill="#F7981D"
      />
      <path
        d="M0 0.60952C6.50159 2.13333 11.8857 6.09524 13.6127 10.6667L12.3937 6.70476C12.0889 5.48571 11.6825 4.26666 10.2603 3.75873L0 0.60952Z"
        fill="#ED7C00"
      />
      <path
        d="M19.2 12.8L15.746 9.346L14.1206 13.2063L13.7143 10.6666C12.8 7.61901 9.85394 4.26663 6.60315 2.64124L11.1746 18.8952H16.6603L19.2 12.8Z"
        fill="#051244"
      />
      <path
        d="M28.6476 18.8952L24.2794 14.4254L23.4667 18.8952H28.6476Z"
        fill="#051244"
      />
      <path
        d="M40.3302 12.5969C40.7365 13.0032 40.9397 13.308 40.8381 13.7143C40.8381 14.9334 39.2127 15.5429 37.7905 15.5429C35.7587 15.5429 34.6413 15.2381 32.9143 14.527L32.2032 14.2223L31.4921 18.3873C32.7111 18.8953 34.946 19.4032 37.2825 19.4032C40.5333 19.4032 43.1746 18.4889 44.6984 16.8635L40.3302 12.5969Z"
        fill="#051244"
      />
      <path
        d="M46.2223 18.8952H50.9969L52.0128 15.8476H58.5144L59.1239 18.8952H64.0001L62.2731 11.4793L56.1778 5.58728L56.4826 7.21268C56.4826 7.21268 57.4985 11.7841 57.7017 12.8H53.435C53.8413 11.7841 55.4667 7.41585 55.4667 7.41585C55.4667 7.41585 55.8731 6.29839 56.1778 5.58728"
        fill="#051244"
      />
    </svg>
  );
};

export default Visa;
