import styled, { css } from "react-emotion";
import color from "src/styles/color";
import Button from "src/shared/Button";

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 500px;
  width: 800px;
  overflow-y: overlay;
`;

export const Content = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  overflow: hidden;
  border-bottom: 1px solid ${color.border};
`;

export const Footer = styled.div`
  height: 64px;
  padding: 16px;
`;

export const LeftMenu = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1.5;
`;

export const SearchBar = styled.div`
  padding: 10px 16px;
  border-bottom: 1px solid ${color.border};
`;

export const SearchResults = styled.div`
  overflow: auto;
  display: flex;
  flex-flow: column;
  width: 380px;

  ::selection {
    background: none;
  }

  --antd-wave-shadow-color: none;
`;

export const ResultsMeta = styled.div`
  padding: 10px 16px;
  border-bottom: 1px solid ${color.border};
  color: ${color.gray3};

  > b {
    color: ${color.gray3};
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex: 2;
  border-left: 1px solid ${color.border};
  padding: 16px 24px;
  overflow: auto;

  hr {
    color: ${color.border};
    width: 100%;
  }
`;

export const DetailsTitle = styled.div`
  font-weight: 600;
  font-size: 20px;

  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
`;

export const DetailsSubtitle = styled.div`
  margin: 8px 0;
  color: ${color.gray3};
`;

export const DetailsRow = styled.div`
  display: flex;
  flex-flow: row;
  padding: 10px 0;

  > div:nth-child(1) {
    color: ${color.gray3};
    font-weight: 600;
    margin-right: 27px;
    width: 40px;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  flex-flow: row;

  > div {
    width: 150px;
    margin-right: 10px;
  }
`;

export const EditContainer = styled.div`
  > button {
    border: none;
    background: none;
    height: 34px;
    width: 34px;
    margin: 0;
    padding: 0;
  }

  > button:hover {
    background-color: ${color.gray1};
    border-radius: 5px;
  }
`;

export const VSContainer = styled(Button)`
  display: flex;
  flex-flow: column;
  padding: 16px;
  border: none;
  border-bottom: 1px solid ${color.border};
  background: none;
  align-items: flex-start;
  line-height: 1.6;
  margin-left: 0;
  border-radius: 0;
  overflow: visible;
  transition: none;

  &:hover {
    cursor: pointer;
    box-shadow: none;
    transform: none;
    border-radius: 0;
  }

  // override default Button styles
  &:focus {
    outline: none;
  }

  &:active {
    border: none;
    border-bottom: 1px solid ${color.border};
  }

  + button {
    margin-left: 0;
  }
`;

export const icon = css`
  width: 18px;
  height: 18px;
  margin: 8px;

  cursor: pointer;
`;

export const SummaryLine = styled.div`
  text-align: left;
  white-space: normal;
`;

export const SpecialistHeader = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  font-weight: bold;
  align-self: stretch;
  align-items: center;
  gap: 10px;
`;

export const SpecialistTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const AddProviderButton = styled.button`
  background: none;
  border: none;
  color: ${color.blue};
  font-weight: 600;

  &:hover {
    color: ${color.gray3};
    cursor: pointer;
  }
`;

export const TypeFilterContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-flow: row;
  align-items: center;

  > .ant-dropdown-trigger {
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: ${color.gray3};
    font-weight: 600;
    margin-left: 5px;
  }
`;
