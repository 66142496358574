import React from "react";
import { DateTime, Duration } from "luxon";
// local
import { startHour, endHour } from "../consts";
import useCalendarViewContext from "../useCalendarViewContext";
import ScheduledSlot from "./ScheduledSlot";
import StagedSlot from "./StagedSlot";
// types
import { EmptySlot } from "src/types/api";
import { Appointment as AppointmentType } from "src/types/gql";
import { isAppt } from "../util";
import time from "src/shared/time";
import {
  EmptySlotWithOffset,
  AppointmentWithOffset,
  EntryWithOffset,
} from "./types";

type Props = {
  unsaved: Array<EmptySlot>;
  appointments: Array<AppointmentType>;
  // whether this entire column is selected, used to change style
  selected?: boolean;
  calendarView: string | undefined;
};

const isApptWithOffset = (
  input: EntryWithOffset
): input is AppointmentWithOffset => {
  return (input as EmptySlotWithOffset).clientId === undefined;
};

const hourToMinute = (n: number) => n * 60 * 60;

// Given a set of entries and a single item, determine whether we have
// any overlapping appointments.
//
// We filter out all appointments that end before the current offset,
// or that start after the end of the appt (offset + duration).
const overlapping = (set: Array<EntryWithOffset>, item: EntryWithOffset) =>
  set.filter(a => {
    if (a.offset + a.duration <= item.offset) {
      return false;
    }
    if (a.offset >= item.offset + item.duration) {
      return false;
    }
    return true;
  });

const Slots: React.FC<Props> = ({
  unsaved,
  appointments,
  selected,
  calendarView,
}) => {
  const { timezone } = useCalendarViewContext();
  const isoToDateTime = iso => DateTime.fromISO(iso).setZone(timezone);
  const toDuration = seconds => Duration.fromObject({ seconds });

  const { diffInSeconds, startOfDay } = time(timezone);

  // @ts-ignore
  const coll = unsaved.concat(appointments) as Array<
    EmptySlot | AppointmentType
  >;

  // We also have to check how many appointments overlap with this single
  // unsaved appointment.  If two appointments overlap and are rendered in the same
  // column, we need to show them at half width.
  //
  // In order to do this, we concatenate all items to be rendered and we map
  // them to an array containing actual start and end offsets (we use offsets because,
  // again, times may not be know).
  const withOffsets = coll.map(
    (item): EntryWithOffset => {
      if (isAppt(item)) {
        // The appointment actually has a concrete time for the doctor's start time.
        // We need to convert this start time to the offset from midnight, then subtract
        // the "largest patient buffer" to get the minimum appointment offset.
        //
        // To do this, we need the timezone as graphql will respond *without a timezone*,
        // ie. with the appointment date in UTC.
        const drStart = isoToDateTime(item.doctorStartTime);
        const drEnd = isoToDateTime(item.doctorEndTime);

        // Get the start time of the appointment, as offset to midnight.
        // Booked appointments have a defined start time and we can use this as the offset;
        // unbooked appointments have variable types - we must use the largest patient buffer
        // in this case.
        const offset = item.startTime
          ? toDuration(
              diffInSeconds(item.startTime, startOfDay(item.startTime))
            )
          : drStart
              .diff(drStart.startOf("day"), "seconds")
              .minus(toDuration(item.appointmentType.largestPatientBuffer));

        // Get the length of the appointment.
        const duration = item.endTime
          ? toDuration(diffInSeconds(item.endTime, item.startTime))
          : drEnd
              .diff(drStart, "seconds")
              .plus(
                toDuration(
                  item.appointmentType.largestRemainingDuration +
                    item.appointmentType.largestPatientBuffer
                )
              );

        // Doctor duration is simply end - start of doctor time.
        const doctorDuration = drEnd.diff(drStart, "seconds");

        // The offset can be easily calculated by taking its offset minus the start time offset.
        const doctorOffset = drStart
          .diff(drStart.startOf("day"), "seconds")
          .minus(offset);

        return {
          ...item,
          duration: duration.as("seconds"),
          doctorDuration: doctorDuration.as("seconds"),
          offset: offset.as("seconds"),
          doctorOffset: doctorOffset.as("seconds"),
        };
      }

      // This must be a slot.
      return {
        ...item,
        offset: item.offset,
        duration:
          item.appointmentType.largestPatientBuffer +
          item.appointmentType.largestRemainingDuration +
          item.appointmentType.doctorDuration,
        doctorOffset: item.appointmentType.largestPatientBuffer,
        doctorDuration: item.appointmentType.doctorDuration,
      };
    }
  );

  // Now, we want to sort by "offset" ascending.  This will let us easily determine
  // whether there are overlapping appointments and whether we need to render the appt
  // at 100% width (no overlaps) or (100 / N)% width.
  const ordered = withOffsets.sort((a, b) => a.offset - b.offset);

  // Get the number of seconds that the entire column spans.
  const columnDuration = hourToMinute(endHour - startHour);

  const getOffsets = ({ offset, duration }) => {
    // The offset is set to mindnight, whilst the column starts at startHour.
    // Subtract N minutes from offset so that we can calculate percentage accurately.
    const startOffset = offset - hourToMinute(startHour);
    return {
      top: `${(startOffset / columnDuration) * 100}%`,
      height: `${(duration / columnDuration) * 100}%`,
    };
  };

  return (
    <>
      {ordered.map((item: EntryWithOffset) => {
        const { offset, duration, doctorOffset, doctorDuration } = item;

        const overlaps = overlapping(ordered, item);
        const index = overlaps.findIndex(o => o === item);
        const width = `calc((100% - 16px) / ${overlaps.length})`;
        const left = `calc((100% - 16px) / ${overlaps.length} * ${index})`;

        if (isApptWithOffset(item)) {
          return (
            <ScheduledSlot
              key={item.id}
              appt={item}
              {...getOffsets({ offset, duration })}
              width={width}
              left={left}
              getTopHeight={getOffsets({
                offset: doctorOffset + offset,
                duration: doctorDuration,
              })}
              isoToDateTime={isoToDateTime}
              selected={selected}
              calendarView={calendarView}
            />
          );
        }

        const { top: drTop, height: drHeight } = getOffsets({
          offset: doctorOffset + offset,
          duration: doctorDuration,
        });

        return (
          <StagedSlot
            key={item.clientId}
            appt={item}
            {...getOffsets({ offset, duration })}
            width={width}
            left={left}
            drTop={drTop}
            drHeight={drHeight}
          />
        );
      })}
    </>
  );
};

export default Slots;
