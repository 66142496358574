const LOAD_AVAILABLE_INVOICE_ITEMS =
  "@@invoice-item/LOAD_AVAILABLE_INVOICE_ITEMS";

const INVOICE_ITEM_API = "/api/v1/billing/invoice_items";

const initialState = {
  loaded: false,
  invoiceItems: [
    // {
    //   id: 1,
    //   item_name: 'Orthodontic Treatment (mock)',
    //   item_key: 'orthodontic_treatment',
    //   description: 'Comprehensive orthodontic treatment of the adult kind',
    //   price: 350000, // in cents
    // },
  ],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_AVAILABLE_INVOICE_ITEMS:
      return {
        ...state,
        invoiceItems: action.result,
      };
    default:
      return state;
  }
}

export function loadAvailableInvoiceItems() {
  return {
    types: [null, LOAD_AVAILABLE_INVOICE_ITEMS, null],
    promise: client => client.get(INVOICE_ITEM_API),
  };
}
