import React from "react";
import { StyledUserButton, Initials } from "./styles";
import useSelf from "src/state/self";

const HeaderButton = props => {
  const self = useSelf();
  const initials = self.name
    .split(" ")
    .map(i => i.charAt(0))
    .join("");

  return (
    <StyledUserButton>
      <Initials>{initials}</Initials>
    </StyledUserButton>
  );
};

export default HeaderButton;
