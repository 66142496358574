import React from "react";
import Cross from "src/shared/Icons/Cross";
import styled from "react-emotion";

const ImpressWarning = () => (
  <WarningBox>
    <div>
      <Cross size={30} />
    </div>
    <div>
      <p>
        <b>Warning: Impress Treatment Monitoring Patient</b>
      </p>

      <p>
        This patient is using Impress Treatment Monitoring for their care.
        Please use the tray icon in the top right of the patient profile header
        to view the Impress Treatment Monitoring page.
      </p>
    </div>
  </WarningBox>
);

export default ImpressWarning;

const WarningBox = styled.div`
  background: #ffdedc;
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  border-radius: 3px;

  > div:first-of-type {
    background: #ff3b30;
    flex: 0;
    display: flex;
    align-items: center;
    align-self: stretch;
  }

  > div:last-of-type {
    padding: 20px;

    p,
    b {
      color: #e24c4b;
      font-size: 16px;
    }
  }
`;
