import { Submission, Form, FormQuestion, Answer } from "src/types/api";

// Use an explicit args interface, to avoid confusion with `question_id` and `submission_id`
export interface GetSubmissionAnswerArgs {
  answers: Answer[];
  questionID: string;
  submissionID: string;
}

export function getSubmissionAnswer({
  answers,
  questionID,
  submissionID,
}: GetSubmissionAnswerArgs): Answer | null {
  return (
    answers.find(
      a => a.question_id === questionID && a.form_submission_id === submissionID
    ) || null
  );
}

export const getAnswer = (form: Form, s: Submission, questionSlug: string) => {
  const question = form.forms_questions.find((q: FormQuestion) => {
    return q.slug === questionSlug;
  });
  if (!question) {
    return null;
  }

  return getSubmissionAnswer({
    answers: s.answers,
    questionID: question.id,
    submissionID: s.id,
  });
};
