import React from "react";
import { Popover } from "antd";
import { browserHistory } from "react-router";
import { logout } from "src/utils/auth";
import Logotype from "src/shared/Icons/Logotype";
import PatientSearch from "src/shared/PatientSearch";
import SettingsIcon from "src/shared/Icons/Settings";
import { Perimeter, Right, MenuLink, StyledButton } from "./styles";
import { hasFeatureFlag } from "src/utils/featureFlag";

const TopNav = () => (
  <Perimeter>
    <Logotype />

    <Right>
      <PatientSearch
        view="basic"
        style={{ width: "300px" }}
        onChange={p => {
          browserHistory.push({ pathname: `/patients/${p.id}` });
        }}
      />
      <Popover
        placement="bottomLeft"
        trigger="click"
        content={
          <div>
            {hasFeatureFlag("newPatient") && (
              <p>
                <MenuLink to="/patients/new">New patient</MenuLink>
              </p>
            )}
            <p>
              <MenuLink to="/reset-password">Password</MenuLink>
            </p>
            <p>
              <MenuLink to="/settings">Settings</MenuLink>
            </p>
            <p>
              <MenuLink to="" onClick={logout}>
                Logout
              </MenuLink>
            </p>
          </div>
        }
      >
        <StyledButton type="clear">
          <SettingsIcon />
        </StyledButton>
      </Popover>
    </Right>
  </Perimeter>
);

export default TopNav;
