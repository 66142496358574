import React from "react";
import InputText from "src/shared/InputText";

type Props = {
  max?: number;
  value: number;
  onChange: (amount: number) => void;
  className?: string;
};

const moneyFormat = /(^\d+\.?\d{0,2})/;

// MoneyInput renders a label containing an input for typing monetary
// amounts.
//
// It handles the quirk of our designs having centered text, maintaining
// the `input type="number"` keyboard whilst still showing a dollar sign
// to the left of the text.
//
// It also handles currency formatting as you type.
const MoneyInput: React.FC<Props> = props => {
  const { max, value, onChange, className } = props;
  return (
    <InputText
      className={className}
      type="text"
      inputMode="decimal"
      value={`$${value / 100}`}
      onChange={e => {
        // Clean the value to a float with precision 2, defaulting to 0
        const [val] = e.target.value.replace("$", "").match(moneyFormat) || [
          "0",
        ];

        const amt = Math.round((parseFloat(val) || 0) * 100);
        if (amt === value) {
          // If the value matches the previous stete, we have a keydown
          // that shouldn't count. EG. we may type "519.00.11".  At this
          // point, setState will be a no-op as the cleaned value is the
          // same, so we actually modify the input el directly to ensure
          // it has the same state as react.
          //
          // This must be set to val as we need to ensure periods are tacked
          // onto the end so that a user can continue to type floats.
          const target = e.target;
          target.value = `$${val}`;
          // There's also a race condition here, so we want to ensure that
          // this is set correctly on the next tick.  This can happen when
          // react fiber has not yet rendered the update for the input box
          // from a previous state, causing the above target.value to be
          // overwritten by react in the current tick.
          window.setTimeout(() => (target.value = `$${val}`), 1);
          return;
        }

        const newVal = max ? Math.min(amt, max) : amt;
        onChange(newVal);

        if (amt > newVal) {
          // If amt > newVal setting state is a no-op (value is already max),
          // meaning the textbox will update with a total > max.  Ensure
          // that the textbox matches the max val typed in.
          e.target.value = `$${value / 100}`;
        }
      }}
    />
  );
};

export default MoneyInput;
