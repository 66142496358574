import React from "react";
import styled from "react-emotion";
import { css, cx } from "emotion";
import Modal from "src/shared/Modal";
import Tooth from "./Tooth";
import { LegendItem } from "./Legend";
import { useStateContext } from "./state";
import { UIAttribute } from "./types";
import { useToothAttributes, getPalmer } from "./consts";

type Props = {};

const Selected: React.FC<Props> = () => {
  const [state, dispatch] = useStateContext();
  const { selectedTooth } = state;

  const { uiAttributes } = useToothAttributes(state, selectedTooth || 1, false);
  if (!selectedTooth) {
    return null;
  }

  const isBottom = selectedTooth > 16;
  const onClose = () => {
    dispatch({
      type: "selectTooth",
      selectedTooth: null,
    });
  };

  const rx = uiAttributes.filter(
    a => a.attribute && a.attribute.stage === "prescribed"
  );
  const performed = uiAttributes.filter(
    a => a.attribute && a.attribute.stage === "performed"
  );
  const original = uiAttributes.filter(
    a => a.attribute && a.attribute.stage === "original"
  );

  const name = (a: UIAttribute): string => {
    const items = [a.displayName];
    if (!a.attribute) {
      return items.join(" ");
    }

    if (a.attribute.amount) {
      items.push(`(amount: ${a.attribute.amount})`);
    }

    if (a.attribute.location) {
      items.push(`(location: ${a.attribute.location})`);
    }
    if (a.attribute.data) {
      items.push(`(${a.attribute.data})`);
    }

    return items.join(" ");
  };

  return (
    <Modal onClose={onClose}>
      <Wrapper className={cx([isBottom && bottomCSS, !isBottom && topCSS])}>
        <div>
          {/* Editable makes no sense for this display */}
          <Tooth toothNumber={selectedTooth} ipr={false} editable={false} />
        </div>
        <div>
          <h3>{getPalmer(selectedTooth)}</h3>

          {original.length > 0 && (
            <>
              <Title>Original</Title>
              {original.map(a => (
                <LegendItem color={a.color} name={name(a)} />
              ))}
            </>
          )}

          {rx.length > 0 && (
            <>
              <Title>Prescribed</Title>
              {rx.map(a => (
                <LegendItem color={a.color} name={name(a)} />
              ))}
            </>
          )}

          {performed.length > 0 && (
            <>
              <Title>Performed</Title>
              {performed.map(a => (
                <LegendItem color={a.color} name={name(a)} />
              ))}
            </>
          )}
        </div>
      </Wrapper>
    </Modal>
  );
};

export default Selected;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 125px 300px;

  > div:last-of-type {
    background: #f8f8f8;
    padding: 20px;
  }

  h3 {
    font-size: 20px;
  }
`;

const Title = styled.p`
  font-weight: bold;
  margin: 1rem 0 0.25rem;
`;

const topCSS = css`
  > div:first-of-type {
    padding-bottom: 20px;
  }
`;

const bottomCSS = css`
  > div:first-of-type {
    padding-top: 20px;
  }
`;
