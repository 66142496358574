import React, { Component } from "react";
import styled from "react-emotion";
import PropTypes from "prop-types";
import TopNav from "src/shared/TopNav";
import SideNav from "src/shared/SideNav";
// load the current user from graphql
import { SelfProvider } from "src/state/self";

const Perimeter = styled.div`
  display: flex;
  flex-flow: column;
  min-height: 100%;
`;

const Bottom = styled.div`
  display: flex;
  position: relative;
  flex: 1;
`;

const Content = styled.div`
  margin-left: 64px;
  flex: 1;
  overflow: auto;
  overflow: visible;
`;

export default class LoggedInContainer extends Component {
  static contextTypes = {
    auth: PropTypes.object,
  };
  render() {
    const isNewTreatmentOverview = this.props.router.location.pathname.includes(
      "new-patient-treatment-overview"
    );
    return (
      <SelfProvider>
        <Perimeter>
          {isNewTreatmentOverview ? <></> : <TopNav />}
          <Bottom>
            <SideNav location={this.props.router.location} />
            <Content>{this.props.children}</Content>
          </Bottom>
        </Perimeter>
      </SelfProvider>
    );
  }
}
