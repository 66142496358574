import React, { useState } from "react";
import { Patient } from "src/types/gql";
import Box from "src/shared/Box";
import BlankSlate from "src/shared/BlankSlate";
import Button from "src/shared/Button";
import Loading from "src/shared/Loading";
import Error from "src/shared/Error";
import NewOrderModal from "./NewOrder/NewOrder";
import OrderList from "./OrderList";
import { useOrders } from "./query";

type Props = {
  patient: Patient;
};

const Orders: React.FC<Props> = props => {
  const [showNew, setShowNew] = useState(false);
  const [fetching, error, orders, refetch] = useOrders(props.patient.id);

  if (fetching) {
    return (
      <Box title="Orders">
        <Loading />
      </Box>
    );
  }

  if (error) {
    return (
      <Box title="Orders">
        <Error />
      </Box>
    );
  }

  return (
    <Box
      title="Orders"
      actions={
        <Button onClick={() => setShowNew(true)}>Create new order</Button>
      }
    >
      {orders.length === 0 && (
        <BlankSlate
          title="No orders"
          text="The patient has no purchase history."
        >
          <Button kind="link" onClick={() => setShowNew(true)}>
            Create a new order
          </Button>
        </BlankSlate>
      )}
      <OrderList
        orders={orders}
        patient={props.patient}
        refetchOrders={refetch}
      />
      {showNew && (
        <NewOrderModal
          patient={props.patient}
          onClose={() => {
            setShowNew(false);
            // Refetch orders
            refetch();
          }}
        />
      )}
    </Box>
  );
};

export default Orders;
