// @flow
import React, { useState } from "react";
import styled from "react-emotion";
import useKits from "src/state/useKits";
import Loading from "src/shared/Loading";
import KitItem from "./Kit";
// Use the old shipping form for now.
import ShippingForm from "src/components/shipping-form/form";

/*
type Props = {
  patientID: string,
};
*/

const Ship = ({ patientID }) => {
  const [kits, status] = useKits(patientID);
  const [selectedKit, setSelectedKit] = useState(null);

  if (status.loading) {
    return <Loading />;
  }

  if (selectedKit && !selectedKit.shipment) {
    return (
      <Wrapper>
        <p>Purchase shipping</p>
        <ShippingForm
          patientId={patientID}
          kitId={selectedKit.id}
          closeForm={response => {
            selectedKit.shipment = response.shipment;
            setSelectedKit(selectedKit);
          }}
        />
      </Wrapper>
    );
  }

  // Show only kits without a shipment.
  return (
    <Wrapper>
      <h3>
        <b>Unshipped Kits</b>
      </h3>

      {kits
        .filter(k => !k.shipment)
        .map(k => {
          return (
            <KitItem key={k.id} kit={k} onSelect={() => setSelectedKit(k)} />
          );
        })}

      <h3 style={{ marginTop: "2rem" }}>
        <b>Kits with shipping information</b>
      </h3>
      {kits
        .filter(k => Boolean(k.shipment))
        .map(k => {
          return (
            <KitItem key={k.id} kit={k} onSelect={() => setSelectedKit(k)} />
          );
        })}
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export default Ship;
