import { TaskSortField, UpdateFilterRequest } from "../../types";
import { Filters } from "./types";

export const initialState = {
  filterPanelFilters: null,
  tableHeaderFilters: null,
  hasUnsavedFilter: false,
  selectedFilter: null,
};

export const defaultFilters: Filters = {
  status: "incomplete",
  sortOpts: [
    {
      sortField: TaskSortField.PipelineDueAt,
      sortDescending: false,
    },
  ],
};

const clearableUpdateFilterRequestKeys: Exclude<
  Keys<PickByType<UpdateFilterRequest, object | undefined>>,
  undefined
>[] = [
  "taskNameSubstringSearch",
  "taskTypeCategories",
  "taskAssignees",
  "taskAuthorID",
  "taskSnoozed",
  "taskTypeNames",
  "taskCustomLabels",
  "pipelineTypes",
  "patientID",
  "patientHomeClinicIDs",
  "patientStatusNames",
  "treatmentTypes",
  "predefinedTaskFilters",
  "sortOpts",
];

export const defaultUpdateFiltersRequestValue = clearableUpdateFilterRequestKeys.reduce<
  Omit<UpdateFilterRequest, "taskFilterSettingID" | "customName">
>((acc, key) => {
  acc[key] = { clearCurrentValue: true };

  return acc;
}, {});

export const UNSAVED_FILTER_TITLE = "Unsaved filter";
