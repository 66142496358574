import React from "react";
import Skeleton from "src/shared/Skeleton";
import {
  SkeletonRow,
  SkeletonCircle,
  WhiteCircle,
  Perimeter,
  Row,
  InnerRow,
  smSize,
} from "./styles";

const TreatmentTimelineSkeleton = () => (
  <Perimeter>
    <Row theme={{ size: smSize }}>
      <InnerRow>
        <Skeleton width="100%" height="4px" />
        <SkeletonCircle theme={{ percent: 0 }} />
        <WhiteCircle theme={{ percent: 0 }} />
        <SkeletonCircle theme={{ percent: 25 }} />
        <WhiteCircle theme={{ percent: 25 }} />
        <SkeletonCircle theme={{ percent: 50 }} />
        <WhiteCircle theme={{ percent: 50 }} />
        <SkeletonCircle theme={{ percent: 75 }} />
        <WhiteCircle theme={{ percent: 75 }} />
        <SkeletonCircle theme={{ percent: 100 }} />
        <WhiteCircle theme={{ percent: 100 }} />
      </InnerRow>
    </Row>
    <SkeletonRow>
      <Skeleton
        width="64px"
        height="16px"
        style={{ position: "absolute", left: 0 }}
      />
      <Skeleton
        width="64px"
        height="16px"
        style={{ position: "absolute", left: "calc(25% - 28px)" }}
      />
      <Skeleton
        width="64px"
        height="16px"
        style={{ position: "absolute", left: "calc(50% - 32px)" }}
      />
      <Skeleton
        width="64px"
        height="16px"
        style={{ position: "absolute", left: "calc(75% - 36px)" }}
      />
      <Skeleton
        width="64px"
        height="16px"
        style={{ position: "absolute", left: "calc(100% - 64px)" }}
      />
    </SkeletonRow>
  </Perimeter>
);

export default TreatmentTimelineSkeleton;
