import React, { useState } from "react";
import styled from "react-emotion";
import { notification, Input } from "antd";
import { isEmpty } from "lodash";

import { useQuery } from "src/utils/http/gqlQuery";
import Box from "src/shared/Box";
import Button, { ButtonRow } from "src/shared/Button";
import MoneyInput from "src/shared/MoneyInput";
import Modal from "src/shared/Modal";
import { useRecordInsurancePayment } from "../query";
import { Order } from "src/types/gql";
import { orderGql } from "src/scenes/Patient/BillingV2/Orders/query";

const Content = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;

  height: 400px;
  width: 600px;
`;

const InputLabel = styled.div`
  font-weight: bold;
  margin-top: 16px;
`;

const Footer = styled.div`
  padding: 16px 32px 32px 32px;
`;

const InputContainer = styled.div`
  padding: 16px 32px;
  flex: 1;
`;

type Props = {
  onClose: () => void;
  order: Order;
  patientID: string;
};

const PAYMENT_TYPES = [
  {
    label: "Check",
    value: "insurance_check",
  },
  { label: "Electronic Funds Transfer", value: "insurance_eft" },
];

const PaymentTypeSelect = ({ value, onChange }) => {
  const handleChange = (e: React.FormEvent<HTMLSelectElement>) =>
    onChange(e.currentTarget.value);

  return (
    <select value={value} onChange={handleChange}>
      {PAYMENT_TYPES.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
};

const RecordInsurancePaymentModal: React.FC<Props> = ({
  onClose,
  order,
  patientID,
}) => {
  const recordPayment = useRecordInsurancePayment();
  const [type, setType] = useState("insurance_check");
  const [amount, setAmount] = useState(0);
  const [description, setDescription] = useState("");
  const { claims } = order;
  const [, refetch] = useQuery({
    query: orderGql,
    variables: { userID: patientID },
    pause: true,
  });

  const onDescChange = e => setDescription(e.target.value);
  const isValid = () => Boolean(amount) && Boolean(description);

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const res = await recordPayment({
      input: {
        insuranceClaimID: claims[0].id,
        amount,
        description,
        type,
      },
    });

    if (res.error) {
      notification.error({
        message: "There was an error recording the payment.",
      });
    } else {
      notification.success({ message: "Insurance payment recorded!" });
      refetch();
      onClose();
    }
  };

  return (
    <Modal onClose={onClose}>
      <Box title="Record insurance payment">
        <Content>
          <InputContainer>
            {isEmpty(claims) && (
              <div>Patient has no submitted insurance claims</div>
            )}
            {!isEmpty(claims) && (
              <div>
                <InputLabel>Type</InputLabel>
                <PaymentTypeSelect value={type} onChange={setType} />
                <InputLabel>Amount</InputLabel>
                <MoneyInput
                  value={amount}
                  onChange={setAmount}
                  max={order.total}
                />
                <InputLabel>Description (required)</InputLabel>
                <Input
                  placeholder="e.g. Bank of America check no. 12355"
                  onChange={onDescChange}
                />
              </div>
            )}
          </InputContainer>
          <Footer>
            <ButtonRow position="right">
              <Button kind="default" onClick={onClose}>
                Cancel
              </Button>
              <Button
                kind="primary"
                onClick={submit}
                disabled={isEmpty(claims) || !isValid()}
              >
                Submit
              </Button>
            </ButtonRow>
          </Footer>
        </Content>
      </Box>
    </Modal>
  );
};

export default RecordInsurancePaymentModal;
