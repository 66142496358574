import React, { useState } from "react";
import { Link } from "react-router";
import { isEmpty } from "lodash";
import { DatePicker } from "antd";
import ReactJson from "react-json-view";
import moment from "moment";
import styled, { css } from "react-emotion";
import color from "src/styles/color";
import ExpandArrow from "src/shared/ExpandArrow";
import InputText from "src/shared/InputText";

import Loading from "src/shared/Loading";

import { useGetEasypostShipments } from "./queries";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

const GOOGLE_LOGS_URL =
  "https://console.cloud.google.com/logs/viewer?authuser=1&folder=true&organizationId=223748546004&project=ut-emr&pli=1&minLogLevel=0&expandAll=false&timestamp=2021-01-08T19:49:18.890000000Z&customFacets=&limitCustomFacetWidth=true&interval=CUSTOM&resource=project&scrollTimestamp=2021-04-01T15:39:02.000000000Z&dateRangeStart=2021-03-24T12:49:19.000Z&dateRangeEnd=2021-04-01T15:43:19.000Z&filters=text:easypost";

const EasypostDashboard = () => {
  const [search, setSearch] = useState<string>("");
  const [dateRange, setDateRange] = useState([
    moment().subtract(2, "days"),
    moment(),
  ]);

  const onDateRangeChange = newDates => setDateRange(newDates);

  const [fetching, , easypostShipments] = useGetEasypostShipments(
    dateRange[0].toISOString(),
    dateRange[1].toISOString()
  );

  const isPassFilter = shipment => {
    return (
      shipment.externalShipmentID.startsWith(search) ||
      (shipment.user &&
        shipment.user.name.toLowerCase().startsWith(search.toLowerCase())) ||
      (shipment.user && shipment.user.id.startsWith(search))
    );
  };

  const filteredShipments = !search
    ? easypostShipments
    : easypostShipments.filter(isPassFilter);

  const onSearchChange = e => setSearch(e.target.value);

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
        }}
      >
        <h1>Easypost Dashboard</h1>
        <a href={GOOGLE_LOGS_URL}>View logs</a>
      </div>
      <div style={{ display: "flex", flexFlow: "row" }}>
        <RangePicker
          // @ts-ignore dont have access to their custom type here
          defaultValue={dateRange}
          className={datepickerStyle}
          format={dateFormat}
          onChange={onDateRangeChange}
          allowClear={false}
        />
        <InputText
          className={inputStyle}
          value={search}
          placeholder="Search by shipment ID, patient ID, or patient name"
          onChange={onSearchChange}
        />
      </div>
      {fetching && <Loading />}
      {!fetching && isEmpty(easypostShipments) && "No shipments found"}
      {!fetching && !isEmpty(easypostShipments) && (
        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              fontSize: "14px",
              color: color.gray3,
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            {filteredShipments.length} Shipments
          </div>
          <EasypostTable shipments={filteredShipments} />
        </div>
      )}
    </Container>
  );
};

const EasypostTable = ({ shipments }) => {
  return (
    <Table>
      <Grid className={header}>
        <div>Date</div>
        <div>Patient</div>
        <div>Tracking URL</div>
        <div>Status</div>
      </Grid>
      {shipments.map(shipment => (
        <ShipmentItem
          key={`${shipment.createdAt}-${shipment.externalShipmentID}`}
          easypostShipment={shipment}
        />
      ))}
    </Table>
  );
};

const getUserComponent = user => {
  if (!user) return <span style={{ color: color.gray3 }}>Unknown</span>;

  return (
    <Link to={`/patients/${user.id}`}>
      {user.firstName} {user.lastName}
    </Link>
  );
};

const ShipmentItem = ({ easypostShipment }) => {
  const { createdAt, trackingURL, user, status } = easypostShipment;
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Grid onClick={() => setExpanded(!expanded)} className={pointer}>
        <div>{moment(createdAt).format("ll")}</div>
        <div>{getUserComponent(user)}</div>
        <div>{trackingURL}</div>
        <div>{status}</div>
        <ArrowContainer>
          <ExpandArrow expanded={expanded} />
        </ArrowContainer>
      </Grid>
      {expanded && <ExpandedShipment easypostShipment={easypostShipment} />}
    </>
  );
};

const getAddressComponent = address => {
  return (
    <div>
      <div>{address.line1}</div>
      {address.line2 && <div>{address.line2}</div>}
      <div>
        {address.city}, {address.state} {address.postalCode}
      </div>
      <div>{address.country}</div>
    </div>
  );
};
const ExpandedShipment = ({
  easypostShipment: { ToAddress, externalShipmentID, data },
}) => {
  return (
    <ExpandedShipmentContainer>
      <ReactJson
        style={{ backgroundColor: "#fff" }}
        src={JSON.parse(data)}
        displayDataTypes={false}
        displayObjectSize={false}
        quotesOnKeys={false}
        name="shipment"
      />
      <div>
        <div style={{ fontWeight: "bold", fontSize: "16px" }}>
          {externalShipmentID}
        </div>
        <br />
        <br />
        <span style={{ fontWeight: "bold", color: color.gray3 }}>To:</span>
        <br />
        <br />
        {getAddressComponent(ToAddress)}
      </div>
    </ExpandedShipmentContainer>
  );
};

const ExpandedShipmentContainer = styled.div`
  border-top: 1px solid ${color.gray3};
  border-bottom: 1px solid ${color.gray3};
  background: ${color.background};
  padding: 24px 30px 36px;

  display: flex;
  flex-flow: row;

  > div:nth-child(1) {
    flex: 1;
    margin-right: 20px;
    padding: 20px;
    border: 1px solid ${color.blue};
    border-radius: 4px;
  }
`;

const inputStyle = css`
  > input {
    border-radius: 4px;
    height: 32px;
  }
`;

const Table = styled.div`
  background: #fff;
  border: 1px solid ${color.border};
`;

const pointer = css`
  cursor: pointer;
`;

const datepickerStyle = css`
  margin-right: 10px;
`;

const Container = styled.div`
  padding: 25px;
`;

const header = css`
  font-size: 14px;
  font-weight: bold;
  padding: 12px 30px;
  > div {
    color: ${color.gray3};
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  padding: 20px 30px;
  border-top: 1px solid ${color.border};
  grid-template-columns:
    minmax(100px, 140px)
    minmax(100px, 140px)
    auto
    minmax(50px, 80px)
    30px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
`;

export default EasypostDashboard;
