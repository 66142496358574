import React, { useState } from "react";
import { titleCase } from "src/shared/util";
import Button, { ButtonRow } from "src/shared/Button";
import { message, notification } from "antd";
import Modal from "src/shared/Modal";

import { Content, HoldItem } from "./styles";
import { useCompleteHold } from "./queries";

type Props = {
  userID: string;
  holds: Array<{ id: string; category: string }>;
  onClose: () => void;
};

const CompleteHoldConfirmation: React.FC<Props> = ({
  userID,
  holds,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const completeHold = useCompleteHold();

  // If we only have one hold, show a confirmation box.  Else, show a list of holds to complete.
  const onConfirm = async (holdID: string) => {
    if (loading) {
      return;
    }

    setLoading(true);
    const hide = message.loading("Completing Pre-tx task pipeline...", 0);
    const result = await completeHold({ holdID });
    hide();
    setLoading(false);

    if (result.error) {
      notification.error({
        message: `Error completing Pre-tx task pipeline: ${result.error}`,
      });
      return;
    }

    notification.success({ message: `Pre-tx task completed.` });
    onClose();
  };

  return (
    <>
      <Modal onClose={onClose}>
        <Content>
          <h1>Complete a Pre-tx task</h1>
          {holds.map(h => (
            <HoldItem key={h.id}>
              <p>{titleCase(h.category)}</p>
              <Button kind="primary" onClick={() => onConfirm(h.id)}>
                Complete
              </Button>
            </HoldItem>
          ))}
        </Content>
        <ButtonRow position="left" style={{ padding: "1rem" }}>
          <Button kind="warning" onClick={onClose}>
            Cancel
          </Button>
        </ButtonRow>
      </Modal>
    </>
  );
};

export default CompleteHoldConfirmation;
