import styled from "react-emotion";
import color from "src/styles/color";

export const Wrapper = styled.div`
  display: flex;
  margin: 1rem 0;

  .content {
    flex: 1;
  }
`;

export const Meta = styled.div`
  display: flex;
  font-size: 0.85rem;
  margin: 0 0 0.3rem 0;

  > span {
    color: ${color.gray3};
  }

  > span:first-of-type {
    color: ${color.gray5};
    margin-right: 1rem;
    font-weight: bold;
  }
`;

export const Content = styled.div`
  flex: 1;
`;

export const Text = styled.div`
  color: #111;
  white-space: pre-line;
`;
