import gql from "graphql-tag";
import { useQuery } from "src/utils/http/gqlQuery";
import { Treatment, Patient } from "src/types/gql";
import { TimelineItem } from "src/state/useTimeline";
import { Submission } from "src/types/types";

export const query = gql`
  query PatientData($userID: ID!) {
    user(userId: $userID) {
      id
      impressID
      firstName
      lastName
      preferredName
      pronouns
      email
      mobilePhoneNumber
      dateOfBirth
      avatarURL
      createdAt

      statuses {
        id
        name
        start
      }

      appointments {
        id
        startTime
        endTime
        actualStartTime
        doctorStartTime
        bookableResource {
          name
        }
        clinic {
          nickname
          line1
          line2
          city
          timezone
        }
        appointmentType {
          name
        }
        appointmentSubtype {
          id
          name
        }
        doctor {
          name
        }
        staff {
          name
        }
      }

      homeClinic {
        name
        line1
        line2
        city
        state
        timezone
        postalCode
      }

      currentAppVersion
    }

    trayTimeline(userID: $userID) {
      trayNumber
      archType
      phase
      status
      start
      end
      plannedEnd
      appointmentSubtypeID
      appointmentSubtype {
        name
      }
      isWearable
    }

    activeOrLastTreatment(userID: $userID) {
      id
      treatmentType
      dismissedById
      dismissalReason

      holds {
        id
        pipelineID
        authorID
        category
        description
        createdAt
        completedAt

        pipeline {
          id
          type
          dueAt
        }
      }

      beginningDoctor {
        name
      }

      referrals {
        id
        referralType
        referralReason
        completedAt
      }

      startDate
      endDate

      onHoldCategory
      onHoldDate
      onHoldReason
      currentTrayNumber

      activeOption {
        id
        isActive
        estimatedUpperBound
      }

      treatmentCycles {
        endType
        startsAt
        maxCount
        impressTreatmentPlanID
        phases {
          phaseType
          upperCount
          startsAt
          appointmentSubtype {
            name
          }
        }
      }
    }
  }
`;

export const useTreatment = (userID: string) => {
  return useQuery<{
    user: Patient;
    submissions: Submission[];
    activeOrLastTreatment: Treatment;
    trayTimeline: TimelineItem[];
  }>({ query, variables: { userID }, requestPolicy: "cache-first" });
};
