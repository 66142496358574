import React from "react";

type Props = {
  patient: {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    preferredName: string;
    pronouns: string;
    email: string;
    mobilePhoneNumber: string;
  };
};

const Name = ({ patient }: Props) => {
  return (
    <div>
      <span style={{ display: "block" }}>
        {patient.firstName}{" "}
        {patient.preferredName ? `(${patient.preferredName})` : null}{" "}
        {patient.lastName} {patient.pronouns ? `(${patient.pronouns})` : null}
      </span>
      <small>
        Tel: {patient.mobilePhoneNumber}, email: {patient.email}
      </small>
    </div>
  );
};

export default Name;
