import React, { useState } from "react";
import styled from "react-emotion";
import { AutoComplete, Button, Modal, Input, message } from "antd";
import Loading from "src/shared/Loading";
import { useIssues, createDefects } from "src/state/hooks/issues";
// import { Pipeline, Patient, TaskResponse, Build } from "src/types/api";
import { BuildsEnhancer } from "src/state/Builds";

/*
type Props = {
  task: TaskResponse,
  patient: Patient,
  pipeline: Pipeline,
  build: Build,
};
*/

const Label = styled("p")`
  margin: 1rem 0 0.5rem;
`;

const AddButton = styled(Button)`
  margin: 1rem 1rem 1rem 0;
`;

const AddDefect = props => {
  const [issues, requestState] = useIssues();
  const [showDefects, setShowDefects] = useState(false);
  const [search, updateSearch] = useState("");
  // Form values
  const [defectID, setDefectID] = useState();
  const [description, setDescription] = useState();

  const autocompleteValues = issues
    .filter(issue => issue.category === "manufacturing")
    .filter(
      issue =>
        issue.description.toLowerCase().indexOf(search.toLowerCase()) > -1
    )
    .map(issue => ({ value: issue.id, text: issue.description }))
    .sort((a, b) => a.text.localeCompare(b.text));

  const addIssue = async () => {
    if (!defectID) {
      message.error("Please select an issue type");
      return;
    }

    const hide = message.loading("Tracking issues");
    const res = await createDefects({
      trayIds: props.selected,
      issueId: defectID,
      note: description,
    });
    hide();

    if (Array.isArray(res)) {
      message.success("Issues tracked");
      setShowDefects(false);

      // TODO: This is fkn hacky. Refetch the API to update the UI.
      // Move Builds into a hook and share state across defects/builds.
      props.actions.getDetailedBuild(props.build.id);
      return;
    }
  };

  if (requestState.loading || requestState.error) {
    return <Loading />;
  }

  return (
    <>
      <AddButton
        disabled={props.selected.length === 0}
        onClick={() => {
          setShowDefects(true);
        }}
      >
        Add defect
      </AddButton>

      <Modal
        closable={false}
        title="Add defects"
        visible={showDefects}
        onCancel={() => setShowDefects(false)}
        onOk={addIssue}
      >
        <Label style={{ margin: "0 0 0.5rem" }}>Issue type:</Label>
        <AutoComplete
          placeholder="Select issue"
          onSearch={updateSearch}
          defaultValue={defectID}
          onSelect={id => {
            // As this is an input the ID gets typecast to a string
            setDefectID(parseInt(id, 10));
          }}
          dataSource={autocompleteValues}
          style={{ width: "100%" }}
        />
        <Label>Issue description:</Label>
        <Input.TextArea
          style={{ width: "100%" }}
          onChange={e => {
            setDescription(e.target.value);
          }}
          placeholder="Issue description"
        />
      </Modal>
    </>
  );
};

export default BuildsEnhancer(AddDefect);
