export const PRESCRIPTION = "Prescription";
export const GOAL = "Goal";

export const TEXT = "text";
export const STL = "stl";
export const PHOTO = "photo";

// form question
export const ANY = "any";
export const LINE_BREAK = "line_break";

export const momentFormatTime = "MMM Do YYYY, h:mma";
export const momentFormatTimeShort = "MM/DD/YYYY, h:mma";
export const momentFormatHour = "h:mma";
export const momentFormatDate = "MMM Do, YYYY";
export const momentFormatDateWithDay = "dddd MMM Do, YYYY";

// status
export const REGISTRATION_START = "registration_start";
export const CONSENTED = "consented";
export const CONSIDERING_TREATMENT = "considering_treatment";

export const MANUFACTURING_TASK_CATEGORY = [
  "prep",
  "segment",
  "print",
  "fabricate",
  "ship",
];

export const CLINICAL_TASK_CATEGORY = [
  "estimate",
  "rrx",
  "piv",
  "start_billing",
  "treatment_plan",
  "vbt",
  "vp",
  "screen_checkin",
  "vc",
  "pra",
  "vfc",
];

export const CONSENT_FORM_FINANCIAL = "financial";
export const CONSENT_FORM_PRIVACY = "privacy";
export const NOTICE_OF_PRIVACY_PRACTICE_FORM_TYPES = [
  "notice_of_privacy_practice",
];
export const TREATMENT_FORM_TYPES = ["financial", "treatment"];

export const MIME_TYPE_PLAIN_TEXT = "text/plain";
export const MIME_TYPE_IMAGE_JPEG = "image/jpeg";
export const MIME_TYPE_RESPONSE = "application/vnd.cvstom.1.response";
export const MIME_TYPE_LINK = "application/vnd.cvstom.1.link";

export const TREATMENT_TYPE_LIMITED = "Limited";
export const RETAINER = "Retainer";
export const TREATMENT_TYPE_COMPREHENSIVE = "Comprehensive";

export const READY_FOR_RETAINER = "Ready for retainers";
export const RETAINERS = "retainers";

/**
 * Links are app links which can be sent as attachments to messages
 */
export const COMPANION_APP_LINKS = {
  FAQs: { link: "faqs" },
  "My account": { link: "account" },
  "Edit account/address": { link: "editAccount" },
  Billing: { link: "billing" },
  "Update payment card": { link: "editPayment" },
  "Schedule appointment": { link: "appointment" },
  "Your appointments & reschedule": { link: "yourAppointments" },
  Consents: { link: "consents" },
  "Medical history": {
    link: "questionnaire",
    state: { type: "medical_history" },
  },
  "Dental history": {
    link: "questionnaire",
    state: { type: "dental_history" },
  },
};

export const OFFICE_SYNC_FOLDERS = [
  "San Francisco - 999 Sutter",
  "Staging - 001 Stage",
];

export const TASK_LIST_LIMIT = 30;
export const TASK_LIST_HISTORICAL_LIMIT = 40;

export const APPOINTMENT_TYPE = {
  REFINEMENT: "refinement",
  BEGINNING_VISIT: "beginning_visit",
};

export const TASK_TYPE = {
  MANUFACTURING: "manufacturing",
  CLINICAL: "clinical",
};

export const TASK_ASSIGNED_ROLE = {
  SALES_MANAGER: "sales_manager",
  ORTHODONTIST: "orthodontist",
};

export const QUESTION_TYPE = {
  SELECT: "select",
  MODIFY_WEAR_SCHEDULE: "modifyWearSchedule",
  MULTI_SELECT: "multiselect",
  TEXT: "text",
  STL: "stl",
  PHOTO: "photo",
  DATE_PICKER: "datepicker",
  ALERT: "alert",
  SOCIAL_NOTE: "socialNote",
};

export const PRIORITY = {
  URGENT: 2,
};
export const PRIORITY_NUM_TO_NAME = {
  2: "URGENT",
};
