import { Patient } from "src/types/gql";
import { CombinedError, useQuery, useMutation } from "src/utils/http/gqlQuery";

export const useUpdatePatient = () => {
  const [, execute] = useMutation<
    { updatePatient: Patient },
    { updateRequest: UpdateUserType }
  >(updatePatient);
  return execute;
};

export const useDeletePatient = () => {
  const [, execute] = useMutation(deletePatient);
  return execute;
};

export const usePatient = (
  userID: string
): [boolean, CombinedError | undefined, Patient] => {
  const [{ fetching, error, data }] = useQuery({
    variables: { userId: userID },
    query: findPatient,
  });
  return [fetching, error, data && data.user];
};

export const updatePatient = `
  mutation UpdatePatient($updateRequest: UpdatePatient!) {
    updatePatientDetails(updateRequest:$updateRequest) {
      id
      firstName
      lastName
      preferredName
      pronouns
      name
      dateOfBirth
      email
      mobilePhoneNumber
      isSpam
      isSpam
      spamReason
      homeClinicID
      avatarURL
      statuses {
        id
        name
        start
      }
      homeClinic {
        id
        name
        city
      }
    }
  }
`;

export const deletePatient = `
  mutation deletePatient($id: ID!) {
    deletePatient(id: $id) {
      ids
    }
  }
`;

const findPatient = `
query findPatient($userId: ID!) {
  user(userId: $userId) {
    id
    firstName
    lastName
    name
    dateOfBirth
    email
    mobilePhoneNumber
    isSpam
    isSpam
    spamReason
    homeClinicID
    avatarURL
    statuses {
      id
      name
      start
    }
    homeClinic {
      id
      name
      city
    }
  }
}
`;

export type UpdateUserType = {
  patientId: string;
  homeClinicId?: string;
  mobilePhoneNumber?: string;
  firstName?: string;
  lastName?: string;
  preferredName?: string;
  pronouns?: string;
  email?: string;
  dateOfBirth?: string;
  isSpam?: boolean;
  spamReason?: string;
  patientStatus?: string;
};
