import React, { useRef, useState } from "react";
import { useClickAway } from "react-use";

import { useFiltersList } from "../../queries";

export const useFilters = () => {
  const [{ data }] = useFiltersList();

  if (!data) {
    return [];
  }

  return data.taskFilterSettings.map(({ id, customName }) => ({
    id,
    title: customName,
  }));
};

export const useDropdown = () => {
  const menuRef = useRef<HTMLDivElement | undefined>();

  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  useClickAway(menuRef as React.RefObject<HTMLElement>, () => {
    setIsOpen(false);
  });

  return {
    isOpen,
    menuRef,
    handleButtonClick,
  };
};
