import React from "react";

export default () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.973 2.498c0-.669-.262-1.297-.735-1.769a2.505 2.505 0 00-3.537.004L1.632 10.843a2.997 2.997 0 00-.723 1.171l-.01.029L0 16l3.968-.888.03-.01c.44-.146.845-.396 1.173-.724l10.07-10.11a2.485 2.485 0 00.732-1.77zM2.2 14.228l-.421-.422.304-1.336 1.456 1.457-1.34.3zM14.357 3.385L4.58 13.2 2.81 11.43l6.697-6.724 1.02 1.02.883-.883-1.022-1.022.95-.954 1.024 1.024.883-.883-1.025-1.026.366-.367a1.254 1.254 0 012.138.884c0 .335-.13.65-.367.887zM6.57 14.737h9.402v1.25H5.326l1.245-1.25z"
      fill="#A2A9AD"
    />
  </svg>
);
