import React from "react";

type Props = {
  checked?: boolean;
};

const Radio: React.SFC<Props> = ({ checked }) => {
  if (checked) {
    return (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.00017 3.66662C5.1335 3.66662 3.66683 5.13329 3.66683 6.99995C3.66683 8.86662 5.1335 10.3333 7.00017 10.3333C8.86683 10.3333 10.3335 8.86662 10.3335 6.99995C10.3335 5.13329 8.86683 3.66662 7.00017 3.66662ZM7.00016 0.333328C3.3335 0.333328 0.333496 3.33333 0.333496 6.99999C0.333496 10.6667 3.3335 13.6667 7.00016 13.6667C10.6668 13.6667 13.6668 10.6667 13.6668 6.99999C13.6668 3.33333 10.6668 0.333328 7.00016 0.333328ZM7.00017 12.3333C4.06683 12.3333 1.66683 9.93329 1.66683 6.99995C1.66683 4.06662 4.06683 1.66662 7.00017 1.66662C9.9335 1.66662 12.3335 4.06662 12.3335 6.99995C12.3335 9.93329 9.9335 12.3333 7.00017 12.3333Z"
          fill="#EA6938"
        />
      </svg>
    );
  }

  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        opacity="0.54"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.00016 0.333336C3.3335 0.333336 0.333496 3.33334 0.333496 7C0.333496 10.6667 3.3335 13.6667 7.00016 13.6667C10.6668 13.6667 13.6668 10.6667 13.6668 7C13.6668 3.33334 10.6668 0.333336 7.00016 0.333336ZM7.00017 12.3333C4.06683 12.3333 1.66683 9.9333 1.66683 6.99996C1.66683 4.06663 4.06683 1.66663 7.00017 1.66663C9.9335 1.66663 12.3335 4.06663 12.3335 6.99996C12.3335 9.9333 9.9335 12.3333 7.00017 12.3333Z"
        fill="#D7D7D7"
      />
    </svg>
  );
};

export default Radio;
