import React, { useState } from "react";
import { isEmpty, isNil } from "lodash/fp";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";
import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import DocumentsForSignature from "src/components/consent-request/DocumentsForSignature";
// shared
import TodoBox from "src/shared/TodoBox";
import Skeleton from "src/shared/Skeleton";
import ExternalProviderModal from "src/shared/ExternalProvider/ExternalProviderModal";
import { request } from "src/shared/util";
// local
import Question from "src/scenes/PatientProfile/Forms/Question";
import UniformTeethDifferencesModal from "./UniformTeethDifferencesModal";
import InsuranceClaimFormModal from "./InsuranceClaimFormModal";
import QuestionnaireModal from "./QuestionnaireModal";
import VerifiedProviderCard from "./VerifiedProviderCard";
import { FormUI } from "src/scenes/PatientProfile/Forms/GQLForms/useFormUI";
import { questionProps, renderTitlegroupQuestions } from "./consts";

import {
  load as loadClinicalRecord,
  getPatientQuestionnairesSelector,
} from "src/redux/modules/clinical-record";
import AppointmentInfo from "../GQLForms/Custom/AppointmentInfo";

import {
  Section,
  SectionTitle,
  Divider,
  Row,
  Column,
  Continue,
} from "./styledComponents";

const ModalSelector = ({ openModal, ui, closeModal, patient }) => {
  const handleAnswer = (slug: string, value: any) => {
    const q = (ui as FormUI).form.questions[slug];
    q && ui.saveAnswer(q, value);
  };

  switch (openModal) {
    case "differences":
      return (
        <UniformTeethDifferencesModal
          handleClose={closeModal}
          handleDone={() => {
            handleAnswer("review_how_we_are_different", true);
            closeModal();
          }}
        />
      );
    case "insurance":
      return (
        <InsuranceClaimFormModal
          handleClose={closeModal}
          handleDone={() => {
            handleAnswer("sign_insurance_claim_form_reminder", true);
            closeModal();
          }}
        />
      );
    case "questionnaire":
      return <QuestionnaireModal patient={patient} handleClose={closeModal} />;
    default:
      return null;
  }
};

const mapState = createStructuredSelector({
  responses: getPatientQuestionnairesSelector,
});

const mapActions = dispatch => ({
  actions: bindActionCreators(
    {
      loadClinicalRecord,
    },
    dispatch
  ),
});

const enhance = compose(
  connect(
    mapState,
    mapActions
  ),
  lifecycle({
    componentDidMount() {
      // @ts-ignore
      const { patientID, actions } = this.props;
      actions.loadClinicalRecord(patientID);
    },
  })
);

const Introduction = props => {
  const { ui, patientID, patient } = props;

  const [openModal, setOpenModal] = useState();
  const [showExternalProviderModal, setShowExternalProviderModal] = useState(
    false
  );
  const onCloseExternalProviderModal = () =>
    setShowExternalProviderModal(false);

  // TODO: This is rest, and non BV stuff.  Migrate.
  const hasFilledOutQuestionnaire = !isEmpty(props.responses);
  const handleMedicalDentalHistoryClick = async () => {
    if (!hasFilledOutQuestionnaire) {
      const resp = await request(
        `/api/v1/patients/${patientID}/redirect_to_medical_form`
      );
      window.location = resp.url;
    } else {
      setOpenModal("questionnaire");
    }
  };

  if (!ui) {
    return (
      <div style={{ padding: "0 0 0 32px" }}>
        <Skeleton dark width="286px" height="24px" margin="0 0 24px" />
        <Skeleton width="286px" height="16px" margin="0 0 16px" />
        <Skeleton width="143px" height="16px" />

        <Skeleton dark width="286px" height="24px" margin="32px 0 24px" />
        <Skeleton width="286px" height="16px" margin="0 0 16px" />
        <Skeleton width="143px" height="16px" />
      </div>
    );
  }

  const handleSpecialistChange = specialistID => {
    questionProps(ui, "general_dentist_id").handleBlur(specialistID);
  };

  const onHasGeneralDentistChange = value => {
    if (value === "Yes") {
      setShowExternalProviderModal(true);
    }
    questionProps(ui, "has_general_dentist").handleBlur(value);
  };

  const hasGeneralDentist = ui.getAnswerValue("has_general_dentist") === "Yes";
  const generalDentistID = ui.getAnswerValue("general_dentist_id");

  return (
    <>
      <Section>
        <AppointmentInfo {...props} />
      </Section>
      <Divider />

      <Section>
        <SectionTitle>Welcome the patient</SectionTitle>
        {renderTitlegroupQuestions(ui, "Welcome the patient")}
      </Section>
      <Divider />

      <Section>
        <SectionTitle>Review with the patient</SectionTitle>
        <ModalSelector
          openModal={openModal}
          patient={props.patient}
          closeModal={() => setOpenModal(null)}
          ui={ui}
        />
        <div style={{ display: "flex" }}>
          <TodoBox
            disabled={props.disabled}
            onClick={() => setOpenModal("differences")}
            kind={
              ui.getAnswerValue("review_how_we_are_different")
                ? "success"
                : "warning"
            }
          >
            <TodoBox.Title>Uniform Teeth's Differences</TodoBox.Title>
            <TodoBox.Subtitle>
              Review the differences that set Uniform apart from its
              competitors.
            </TodoBox.Subtitle>
          </TodoBox>
          <TodoBox
            disabled={props.disabled}
            onClick={() => setOpenModal("insurance")}
            kind={
              ui.getAnswerValue("sign_insurance_claim_form_reminder")
                ? "success"
                : "warning"
            }
          >
            <TodoBox.Title>Insurance Claim Form</TodoBox.Title>
            <TodoBox.Subtitle>
              Important reminder to get the insurance claim form signed.
            </TodoBox.Subtitle>
          </TodoBox>
        </div>
        <DocumentsForSignature
          patient={patient}
          visibleConsents={["treatment", "financial"]}
        />
        <div style={{ display: "flex", marginTop: "24px" }}>
          <TodoBox
            disabled={props.disabled}
            onClick={handleMedicalDentalHistoryClick}
            kind={hasFilledOutQuestionnaire ? "success" : "warning"}
          >
            <TodoBox.Title>Medical and Dental Form</TodoBox.Title>
            <TodoBox.Subtitle>
              This helps out doctors better understand the patient for the
              appointment.
            </TodoBox.Subtitle>
          </TodoBox>
        </div>
      </Section>
      <Divider />

      <Section>
        <SectionTitle>Other important items</SectionTitle>
        <Row>
          {showExternalProviderModal && (
            <ExternalProviderModal
              onChange={handleSpecialistChange}
              onClose={onCloseExternalProviderModal}
            />
          )}
          <Column>
            <Question
              {...questionProps(ui, "has_general_dentist")}
              handleChange={onHasGeneralDentistChange}
              disabled={props.disabled}
            />
          </Column>
          <Column>
            {hasGeneralDentist && !isNil(generalDentistID) && (
              <VerifiedProviderCard
                disabled={props.disabled}
                specialistID={generalDentistID}
                openModal={() => setShowExternalProviderModal(true)}
              />
            )}
          </Column>
        </Row>
        {renderTitlegroupQuestions(ui, "Other important items", [
          "has_general_dentist",
          "general_dentist_id",
          "dentist_last_visit",
          "dentist_first_name",
          "dentist_last_name",
          "dentist_clinic_name",
          "dentist_phone_number",
          "dentist_email",
        ])}
      </Section>

      <Continue next={props.next} />
    </>
  );
};

export default enhance(Introduction);
