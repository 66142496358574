import React from "react";
import ToothChart from "src/shared/ToothChartV2/ToothChart";
import {
  attributesToV2,
  attributesToV1,
} from "src/shared/ToothChartV2/versioning";
import DocumentsForSignature from "src/components/consent-request/DocumentsForSignature";
import { DetailedPatient } from "src/types/api";

type Props = {
  formSlug: string;
  formID: string;
  patient: DetailedPatient;

  // What section is this component being rendered into (sidebar container)
  section: string;
  // What titlegroup is this component being rendered underneath?
  titlegroup: string;
  // Is this being rendered at the start or end of a titlegroup?
  position: "start" | "end";

  submission: any;

  saveToothData: any;
};

// CustomTitlegroup renders items within a specific titlegroup, either at the start
// or end of the contents.
const CustomTitlegroup: React.FC<Props> = props => {
  return <>{customDisplays.map(f => f(props)).filter(Boolean)}</>;
};

const renderCovidConsent = (props: Props) => {
  const { formSlug, titlegroup, position, patient } = props;
  if (
    formSlug === "covid_results" &&
    titlegroup === "COVID-19 Test Results" &&
    position === "end"
  ) {
    return (
      <DocumentsForSignature patient={patient} visibleConsents={["covid"]} />
    );
  }
  return null;
};

// renderToothChart renders the tooth chart on a specific "tooth chart" section of forms.
const renderToothChart = (props: Props) => {
  const { formSlug, titlegroup, position, patient, submission } = props;
  const isSubmitted = !!submission.submittedAt;
  if (titlegroup.toLowerCase() !== "tooth chart" || position !== "start") {
    return null;
  }

  const { readonly, available, defaultDisplay } = (() => {
    switch (formSlug.toLowerCase()) {
      // some forms may need to show something else on dental findings (eg. bv, when transferred
      // to this GQL setup).
      default:
        return {
          readonly: ["cumulative"],
          available: ["cumulative"],
          defaultDisplay: "cumulative",
        };
    }
  })();

  return (
    <ToothChart
      userID={patient.id}
      submissionID={submission.id}
      defaultDisplayType={defaultDisplay as any}
      availableDisplayTypes={(available || []) as any}
      readOnlyDisplayTypes={(readonly || []) as any}
      unsavedAttributes={attributesToV2(submission.toothData || ([] as any))}
      disabled={isSubmitted}
      onChange={(a: any) => {
        const attrs = attributesToV1(a);
        props.saveToothData(attrs);
      }}
    />
  );
};

// Renders the editable tooth chart under "plan" which shows cumulative, perscribed, performed
// for the ref, indirect, and outlier forms.
const renderNonBVPlanPerformedToothChart = (props: Props) => {
  const { formSlug, titlegroup, position, patient, submission } = props;
  const isSubmitted = !!submission.submittedAt;
  const forms = ["refinement", "indirect", "outlier"];
  if (
    titlegroup.toLowerCase() !== "plan" ||
    !forms.includes(formSlug) ||
    position !== "start"
  ) {
    return null;
  }

  const dt = ["cumulative", "prescribed", "performed"];

  if (formSlug === "outlier") {
    dt.push("spacing");
  }

  return (
    <ToothChart
      userID={patient.id}
      submissionID={submission.id}
      defaultDisplayType={"performed"}
      availableDisplayTypes={dt as any}
      readOnlyDisplayTypes={["prescribed"]}
      unsavedAttributes={attributesToV2(submission.toothData || ([] as any))}
      disabled={isSubmitted}
      onChange={(a: any) => {
        const attrs = attributesToV1(a);
        props.saveToothData(attrs);
      }}
    />
  );
};

const customDisplays = [
  renderCovidConsent,
  renderToothChart,
  renderNonBVPlanPerformedToothChart,
];

export default CustomTitlegroup;
