import React from "react";
import time from "src/shared/time";
import { Patient } from "src/types/gql";
import { ClinicalRecord } from "src/types/api";
import PatientConsents from "src/components/patient-consents/PatientConsents";
import {
  SummaryHeader,
  SummaryField,
  SummaryFieldLabel,
  SummaryFieldContent,
} from "../styles";
import { TimelineAppointment } from "../util";
import useConsentsContext from "src/scenes/PatientProfile/Consents/useConsentsContext";

export interface Props {
  patient: Patient;
  clinicalRecord?: ClinicalRecord;
  appointment: TimelineAppointment;
  children?: any;
}

export default function GenericAppointment({
  patient,
  clinicalRecord,
  appointment,
  children,
}: Props) {
  const { toHour } = time(appointment.timezone);
  const { consents, signConsent } = useConsentsContext();

  return (
    <div>
      <SummaryHeader>{appointment.title}</SummaryHeader>

      <div style={{ float: "right", marginTop: -10 }}>
        <PatientConsents
          patient={patient}
          clinicalRecord={clinicalRecord}
          consents={consents}
          signConsent={signConsent}
        />
      </div>

      <SummaryField key="room">
        <SummaryFieldLabel>Room</SummaryFieldLabel>
        <SummaryFieldContent>{appointment.room}</SummaryFieldContent>
      </SummaryField>

      <SummaryField key="date">
        <SummaryFieldLabel>Appointment date</SummaryFieldLabel>
        <SummaryFieldContent>{appointment.formattedDate}</SummaryFieldContent>
      </SummaryField>

      <SummaryField key="timezone">
        <SummaryFieldLabel>Timezone</SummaryFieldLabel>
        <SummaryFieldContent>{appointment.timezone}</SummaryFieldContent>
      </SummaryField>

      <SummaryField key="startTime">
        <SummaryFieldLabel>Start time</SummaryFieldLabel>
        <SummaryFieldContent>
          {toHour(appointment.sortableDate)}
        </SummaryFieldContent>
      </SummaryField>

      <SummaryField key="doctorStartTime">
        <SummaryFieldLabel>Doctor start time</SummaryFieldLabel>
        <SummaryFieldContent>
          {toHour(appointment.doctorStartTime)}
        </SummaryFieldContent>
      </SummaryField>

      {appointment.checkinTime && (
        <SummaryField key="checkinTime">
          <SummaryFieldLabel>Checked-in</SummaryFieldLabel>
          <SummaryFieldContent>
            {toHour(appointment.checkinTime)}
          </SummaryFieldContent>
        </SummaryField>
      )}

      {children}

      <div style={{ clear: "both" }} />
    </div>
  );
}
