import React, { useState } from "react";
import { Modal } from "antd";
import Button from "src/shared/Button";
import { useUpdateClinicalRecord } from "src/state/hooks/clinicalRecords";
import { ClinicalRecord } from "src/types/api";
import { Label, ButtonStyle, StyledTextArea } from "./styles";

type Props = {
  isOpen: boolean;
  onHide: Function;
  clinicalRecord: any;
};

const SocialNotes = (props: Props) => {
  const { clinicalRecord } = props;
  const [currentNotes, setCurrentNotes] = useState(clinicalRecord.social_notes);
  const updateClinicalRecord = useUpdateClinicalRecord();

  const handleOnUpdate = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setCurrentNotes(e.target.value);
  };

  return (
    <Modal
      key="social_notes"
      visible={props.isOpen}
      title={<Label>Social Notes</Label>}
      centered={true}
      onCancel={() => props.onHide()}
      footer={[
        <Button
          className={ButtonStyle}
          disabled={false}
          onClick={() => props.onHide(true)}
        >
          Cancel
        </Button>,
        <Button
          className={ButtonStyle}
          disabled={
            !currentNotes || currentNotes === clinicalRecord.social_notes
          }
          type="primary"
          onClick={() => {
            const updatedCR: ClinicalRecord = {
              ...clinicalRecord,
              social_notes: currentNotes,
            };
            updateClinicalRecord(updatedCR);
            props.onHide(true);
          }}
        >
          Update
        </Button>,
      ]}
    >
      <StyledTextArea
        defaultValue={currentNotes}
        autosize={{ minRows: 2, maxRows: 6 }}
        onChange={handleOnUpdate}
        onBlur={handleOnUpdate}
      />
    </Modal>
  );
};

export default SocialNotes;
