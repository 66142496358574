/* eslint camelcase: 0 */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import styles from "./styles";

/**
 * AddressForm allows the user to enter a custom address for shipment, as
 * opposed to using the patient's default address.
 *
 */
export default class AddressForm extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func,
  };

  state = {
    name: "",
    line_1: "",
    line_2: "",
    city: "",
    state: "",
    postal_code: "",
  };

  onSubmit = () => {
    this.props.onSubmit(this.state);
  };

  onChange = field => (_, value) => {
    this.setState({
      ...this.state,
      [field]: value,
    });
  };

  render() {
    const { name, line_1, line_2, city, state, postal_code } = this.state;
    return (
      <div style={styles.addressForm}>
        <RaisedButton
          onClick={this.props.onHandDelivery}
          label="Deliver by Hand"
        />

        <TextField
          name="name"
          value={name}
          floatingLabelText="Recipient name"
          onChange={this.onChange("name")}
          style={styles.addressField}
        />
        <TextField
          name="line_1"
          value={line_1}
          floatingLabelText="Line 1"
          onChange={this.onChange("line_1")}
          style={styles.addressField}
        />
        <TextField
          name="line_2"
          value={line_2}
          floatingLabelText="Line 2"
          onChange={this.onChange("line_2")}
          style={styles.addressField}
        />
        <TextField
          name="city"
          value={city}
          floatingLabelText="City"
          onChange={this.onChange("city")}
          style={styles.addressField}
        />
        <TextField
          name="state"
          value={state}
          floatingLabelText="State"
          onChange={this.onChange("state")}
          style={styles.addressField}
        />
        <TextField
          name="zip"
          value={postal_code}
          floatingLabelText="Zip"
          onChange={this.onChange("postal_code")}
          style={styles.addressField}
        />
        <RaisedButton
          label="Find rates"
          onClick={this.onSubmit}
          fullWidth
          style={styles.addressButton}
        />
      </div>
    );
  }
}
