import styled from "react-emotion";
import color from "src/styles/color";

export const StyledHeaderButton = styled.div`
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;

  ${props =>
    props.theme.showBadge &&
    `
  &:after {
    content: "";
    position: absolute;
    background: #ff3b30;
    width: 12px;
    height: 12px;
    top: 28px;
    right: 44px;
    border-radius: 50%;
    border: 2px solid #ffffff;
  }
`}

  &:hover {
    background: ${color.gray2};
  }

  > div:hover {
    background: ${color.gray2};
  }
`;
