import { DateTime } from "luxon";

export const now = () => {
  return DateTime.fromJSDate(new Date());
};

export const getWeeksDistToISODateString = (date: string) => {
  const isoDateTime = DateTime.fromISO(date);
  const weeks = now()
    .diff(isoDateTime)
    .as("weeks");

  return Math.floor(Math.abs(weeks));
};
