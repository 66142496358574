import styled from "react-emotion";
import color from "src/styles/color";

export const StyledHeaderButton = styled.div`
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;

  ${props =>
    props.theme.showBadge &&
    `
  &:after {
    content: "";
    position: absolute;
    background: ${color.red};
    width: 12px;
    height: 12px;
    top: 30px;
    right: 312px;
    border-radius: 50%;
    border: 2px solid ${color.white};
    z-index: 1;

    @media (max-width: 1280px) {
      top: 14px;

    }
  }
`}

  &:hover {
    background: ${color.gray2};
  }

  > div:hover {
    background: ${color.gray2};
  }
`;
