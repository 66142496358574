import React from "react";

const InstallAppSvg = () => (
  <svg
    width="112"
    height="112"
    viewBox="0 0 112 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path d="M95.9288 90.7585H49.584V112H95.9288V90.7585Z" fill="#C8D2D6" />
      <path
        d="M80.4805 112H49.584V90.7585H67.7859C74.7975 90.7585 80.4805 96.4416 80.4805 103.453V112Z"
        fill="#E7ECED"
      />
      <path
        d="M67.9292 106.207C70.5954 106.207 72.7567 104.046 72.7567 101.379C72.7567 98.7131 70.5954 96.5518 67.9292 96.5518C65.2629 96.5518 63.1016 98.7131 63.1016 101.379C63.1016 104.046 65.2629 106.207 67.9292 106.207Z"
        fill="#7383BF"
      />
      <path
        d="M64.8455 20.6659C67.2265 19.6193 68.893 17.248 68.893 14.4827C68.893 10.7501 65.867 7.72412 62.1343 7.72412C59.3672 7.72412 56.9959 9.3906 55.9512 11.7716L64.8455 20.6659Z"
        fill="#FBCE9D"
      />
      <path
        d="M74.5008 30.3212C76.8818 29.2746 78.5482 26.9033 78.5482 24.138C78.5482 20.4053 75.5223 17.3794 71.7896 17.3794C69.0224 17.3794 66.6511 19.0459 65.6064 21.4268L74.5008 30.3212Z"
        fill="#FBCE9D"
      />
      <path
        d="M84.1561 39.9762C86.537 38.9296 88.2035 36.5583 88.2035 33.793C88.2035 30.0604 85.1776 27.0344 81.4449 27.0344C78.6777 27.0344 76.3064 28.7009 75.2617 31.0819L84.1561 39.9762Z"
        fill="#FBCE9D"
      />
      <path
        d="M93.8113 49.6315C96.1923 48.5849 97.8588 46.2136 97.8588 43.4483C97.8588 39.7156 94.8329 36.6897 91.1002 36.6897C88.333 36.6897 85.9617 38.3562 84.917 40.7371L93.8113 49.6315Z"
        fill="#FBCE9D"
      />
      <path
        d="M94.2595 60.9492L72.7864 82.4223C71.0562 84.1525 68.2504 84.1525 66.5221 82.4223L47.8258 63.7241H35.1022C33.9861 64.2706 30.9409 62.299 32.1091 63.3012L52.9875 84.0985C53.1304 84.2414 53.2964 84.3437 53.4471 84.4731V90.7586H92.0678V82.0496C95.5803 79.406 97.8609 75.2176 97.8609 70.4827C97.8589 66.8273 96.4937 63.4982 94.2595 60.9492Z"
        fill="#F7B563"
      />
      <path
        d="M49.7618 5.57861L19.7207 35.6197L34.3811 50.2801C34.6225 50.255 34.8562 50.2067 35.1014 50.2067H54.4117C58.129 50.2067 61.1704 53.2481 61.1704 56.9654C61.1704 60.6826 58.129 63.724 54.4117 63.724H47.825L60.0967 75.9957L90.1359 45.9546L49.7618 5.57861Z"
        fill="#A2A9AD"
      />
      <path
        d="M49.7605 5.57876L45.4794 1.29766C43.7492 -0.432552 40.9434 -0.432552 39.2151 1.29766L15.4383 25.0726C13.7081 26.8028 13.7081 29.6086 15.4383 31.3388L19.7194 35.6199L49.7605 5.57876Z"
        fill="#E7ECED"
      />
      <path
        d="M96.5614 52.3813L90.1349 45.9548L60.0957 75.9959L66.5203 82.4205C68.2505 84.1507 71.0563 84.1507 72.7845 82.4205L96.5594 58.6456C98.2916 56.9173 98.2916 54.1115 96.5614 52.3813Z"
        fill="#E7ECED"
      />
      <path
        d="M79.5144 68.5518C81.6474 68.5518 83.3765 66.8227 83.3765 64.6897C83.3765 62.5567 81.6474 60.8276 79.5144 60.8276C77.3815 60.8276 75.6523 62.5567 75.6523 64.6897C75.6523 66.8227 77.3815 68.5518 79.5144 68.5518Z"
        fill="white"
      />
      <path
        d="M30.2723 21.2415C29.778 21.2415 29.2836 21.0522 28.9071 20.6757C28.1521 19.9206 28.1521 18.7002 28.9071 17.9452L32.7692 14.0831C33.5242 13.3281 34.7446 13.3281 35.4996 14.0831C36.2547 14.8382 36.2547 16.0586 35.4996 16.8136L31.6376 20.6757C31.261 21.0522 30.7667 21.2415 30.2723 21.2415Z"
        fill="#424A60"
      />
      <path
        d="M65.1687 34.9436L63.6348 36.4775C63.3686 36.7437 62.9499 36.7306 62.6958 36.4766L58.1614 31.9421C57.8946 31.6754 57.9069 31.2567 58.1604 31.0031L59.6943 29.4692C59.9606 29.203 60.3793 29.2161 60.6333 29.4701L65.1678 34.0046C65.4345 34.2714 65.4349 34.6774 65.1687 34.9436Z"
        fill="white"
      />
      <path
        d="M60.6043 39.5071L59.0704 41.041C58.8042 41.3072 58.3854 41.2941 58.1314 41.0401L53.5969 36.5056C53.3302 36.2389 53.3425 35.8201 53.596 35.5666L55.1299 34.0327C55.3961 33.7665 55.8149 33.7796 56.0689 34.0336L60.6034 38.5681C60.8701 38.8348 60.8705 39.2409 60.6043 39.5071Z"
        fill="white"
      />
      <path
        d="M58.0349 46.1919L56.4884 47.7385C56.2221 48.0047 55.8034 47.9916 55.5493 47.7376L51.0149 43.2031C50.7481 42.9364 50.7604 42.5176 51.014 42.2641L52.5479 40.7302C52.8141 40.464 53.2328 40.4771 53.4869 40.7311L58.0213 45.2656C58.3007 45.5197 58.2885 45.9384 58.0349 46.1919Z"
        fill="white"
      />
      <path
        d="M51.4773 48.6345L49.9434 50.1684C49.6772 50.4346 49.2585 50.4215 49.0044 50.1675L44.47 45.633C44.2032 45.3663 44.2155 44.9476 44.469 44.694L46.0029 43.1601C46.2692 42.8939 46.6879 42.907 46.9419 43.1611L51.4764 47.6955C51.7558 47.9496 51.7435 48.3683 51.4773 48.6345Z"
        fill="white"
      />
      <path
        d="M54.4117 63.724H35.1014C31.3842 63.724 28.3428 60.6826 28.3428 56.9654C28.3428 53.2482 31.3842 50.2068 35.1014 50.2068H54.4117C58.129 50.2068 61.1704 53.2482 61.1704 56.9654C61.1704 60.6826 58.129 63.724 54.4117 63.724Z"
        fill="#FBCE9D"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="112" height="112" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default InstallAppSvg;
