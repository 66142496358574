import React from "react";

const NavLogo = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_804_43170)">
      <path
        d="M3.96873 11.65H0.97359C0.439468 11.65 0 11.1929 0 10.6214V1.02857C0 0.457143 0.439468 0 0.97359 0H3.96873C4.50961 0 4.94232 0.457143 4.94232 1.02857V10.6286C4.94232 11.1929 4.50961 11.65 3.96873 11.65Z"
        fill="#FC5F22"
      />
      <path
        d="M12.988 16H9.99288C9.45876 16 9.01929 15.5429 9.01929 14.9715V5.37147C9.01929 4.80718 9.45199 4.3429 9.99288 4.3429H12.988C13.5221 4.3429 13.9616 4.80004 13.9616 5.37147V14.9715C13.9616 15.5358 13.5221 16 12.988 16Z"
        fill="#FC5F22"
      />
      <path
        d="M22.0073 11.65H19.0122C18.478 11.65 18.0386 11.1929 18.0386 10.6214V1.02857C18.0386 0.464286 18.4713 0 19.0122 0H22.0073C22.5414 0 22.9809 0.457143 22.9809 1.02857V10.6286C22.9809 11.1929 22.5414 11.65 22.0073 11.65Z"
        fill="#FC5F22"
      />
      <path
        d="M31.0263 11.65H28.0312C27.4971 11.65 27.0576 11.1929 27.0576 10.6214V1.02857C27.0576 0.464286 27.4903 0 28.0312 0H31.0263C31.5605 0 31.9999 0.457143 31.9999 1.02857V10.6286C31.9999 11.1929 31.5605 11.65 31.0263 11.65Z"
        fill="#FC5F22"
      />
    </g>
    <defs>
      <clipPath id="clip0_804_43170">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default NavLogo;
