import React from "react";
import { get } from "lodash";
import {
  Container,
  CardContent,
  DetailsTitle,
  NameContainer,
  DetailsSubtitle,
  DetailsRow,
  SelectProviderButton,
  SpecialistState,
} from "./styles";
import { useQuery } from "src/utils/http/gqlQuery";
import Loading from "src/shared/Loading";
import { getVerifiedSpecialists } from "src/scenes/EMRAdmin/queries";
import { getReadableType } from "src/utils/externalProvider";
import Cross from "src/shared/Icons/Cross";
import color from "src/styles/color";
import parsePhoneNumber from "src/utils/parsePhoneNumber";

type VerifiedProviderCardProps = {
  removeCard?: (id: any, state: any, action: any) => void;
  state?: string;
  specialistID: any;
  openModal: any;
  disabled: any;
};

const VerifiedProviderCard = (props: VerifiedProviderCardProps) => {
  const { removeCard, state, specialistID, openModal, disabled } = props;
  const [{ fetching, error, data }] = useQuery({
    query: getVerifiedSpecialists,
    variables: { filter: { ids: [specialistID] } },
  });

  if (fetching) {
    return <Loading />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const specialist = get(data, "verifiedSpecialists.verifiedSpecialists[0]");
  if (!specialist) return <div>Could not find general dentist information</div>;

  const {
    id,
    firstName,
    lastName,
    type,
    email,
    phone,
    clinicName,
    address,
  } = specialist;

  return (
    <Container>
      <CardContent>
        <DetailsTitle>
          <NameContainer>
            {firstName} {lastName}
            <div>
              {state && (
                <SpecialistState
                  style={
                    state === "chosen"
                      ? { color: `${color.primary}` }
                      : { color: `${color.gray3}` }
                  }
                >
                  {state.toUpperCase()}
                </SpecialistState>
              )}
            </div>
          </NameContainer>

          <Cross
            size={20}
            fill={color.gray3}
            onClick={() => removeCard && removeCard(id, "", "delete")}
          />
        </DetailsTitle>
        <DetailsSubtitle>{getReadableType(type)}</DetailsSubtitle>
        <hr />
        <DetailsRow>
          <div>Email</div>
          <div>{email}</div>
        </DetailsRow>
        <hr />
        <DetailsRow>
          <div>Phone</div>
          <div>{parsePhoneNumber(phone)}</div>
        </DetailsRow>
        <hr />
        <DetailsRow>
          <div>Clinic</div>
          <div>{clinicName}</div>
        </DetailsRow>
        <hr />
        <DetailsRow style={{ lineHeight: "25px" }}>
          <div>Address</div>
          <div>
            {address.line1}
            <br />
            {address.line2 && (
              <>
                {address.line2}
                <br />
              </>
            )}
            {address.city}, {address.state} {address.postalCode}
          </div>
        </DetailsRow>
      </CardContent>
      {!disabled && (
        <SelectProviderButton onClick={openModal}>
          Select new provider
        </SelectProviderButton>
      )}
    </Container>
  );
};

export default VerifiedProviderCard;
