import styled from "react-emotion";

export const FlexDiv = styled.div`
  display: flex;
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0;
  height: 100%;
`;

export const Text = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 34px;
  margin-bottom: 24px;
`;
