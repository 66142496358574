const getCreateManufacturingPatientAPI = userId =>
  `api/v1/patients/${userId}/create_manufacturing_tasks`;
const getCreateWearSchedulePatientAPI = userId =>
  `api/v1/patients/${userId}/create_wear_schedule`;

const getCreateTreatmentCycleAPI = userId =>
  `api/v1/patients/${userId}/treatment_cycles`;

const getCreateManufacturingAPI = id =>
  `api/v1/treatment_cycles/${id}/create_manufacturing_tasks`;
const getCreateWearScheduleAPI = id =>
  `api/v1/treatment_cycles/${id}/create_wear_schedule`;

const endTreatmentOnStageAPI = id =>
  `api/v1/treatment_cycles/${id}/end_treatment_cycle_on_stage`;
const endTreatmentOnStagePatientAPI = userId =>
  `api/v1/patients/${userId}/end_treatment_cycle_on_stage`;

const initialState = {};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function createPatientManufacturingTask(data) {
  return {
    types: [null, null, null],
    promise: client =>
      client.post(getCreateManufacturingPatientAPI(data.user_id), { data }),
  };
}
export function createPatientWearSchedule(data) {
  return {
    types: [null, null, null],
    promise: client =>
      client.post(getCreateWearSchedulePatientAPI(data.user_id), { data }),
  };
}
export function createTreatmentCycle(data) {
  return {
    types: [null, null, null],
    promise: client =>
      client.post(getCreateTreatmentCycleAPI(data.user_id), { data }),
  };
}

export function createManufacturingTask(data) {
  return {
    types: [null, null, null],
    promise: client =>
      client.post(getCreateManufacturingAPI(data.id), { data }),
  };
}
export function createWearSchedule(data) {
  return {
    types: [null, null, null],
    promise: client => client.post(getCreateWearScheduleAPI(data.id), { data }),
  };
}

export function endTreatmentOnStage({
  treatment_cycle_id,
  end_stage_num,
  user_id,
}) {
  const url = treatment_cycle_id
    ? endTreatmentOnStageAPI(treatment_cycle_id)
    : endTreatmentOnStagePatientAPI(user_id); // eslint-disable-line
  return {
    types: [null, null, null],
    promise: client => client.put(url, { data: { end_stage_num } }),
  };
}
