import styled, { css } from "react-emotion";

export const Content = styled("div")`
  flex: 1;
  padding: 2rem;
  margin-top: 1.5rem;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);

  & div:first-child {
    padding-top: 0;
  }
`;

export const ToothChartActions = styled("div")`
  border-top: 1px solid #e8e8e8;
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;

export const Separator = styled("div")`
  display: block;
  border: 1px dotted #ddd;
  margin: 1.5rem 0 0;
`;

export const stepCss = css`
  margin: 0 auto;
  max-width: 400px;

  .ant-steps-item-content,
  .ant-steps-icon-dot {
    cursor: pointer;
  }
`;

export const toothChartCss = css`
  border: 0;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  margin: 1.5rem 0;
`;

export const appointmentCss = css`
  margin: 1.5rem 0 1.5rem 0;
`;
