import React from "react";
import { CrownProps, RootProps } from "./types";

export const Crown: React.SFC<CrownProps> = ({ fill, stroke, style }) => (
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M12.85 2.05888C10.6623 0.141147 8.41385 1.25982 7.56308 2.05888C2.82308 5.37802 1 6.48443 1 12.0163C1 16.4419 3.55231 19.884 4.82846 21.0518C6.10461 22.3426 9.56846 24.4078 13.2146 22.3426C17.7723 19.761 19.2308 12.3851 19.2308 9.61918C19.2308 6.85322 15.5846 4.45604 12.85 2.05888Z"
      fill={fill || "#fff"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />
  </svg>
);

// Root always contains the portion as if it attached as a Crown.
export const Root: React.SFC<RootProps> = ({
  hasRootCanal,
  style,
  fill,
  stroke,
}) => (
  <svg
    width="16"
    height="52"
    viewBox="0 0 16 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M1.58463 39.2197C2.74073 30.7357 1.96341 14.1061 1 6H5V1H11V6H15C15 26.6614 7.00384 48.3619 4.47488 50.5561C1.94591 52.7502 0.139511 49.8247 1.58463 39.2197Z"
      fill={fill || "#F1F4F5"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />

    {hasRootCanal && (
      <g transform="translate(3.5,3.5)">
        <path
          d="M0 44.5C4.5 28 3.5 9 2 1.5L8.5 0.5C6 19 5 32 0 44.5Z"
          fill="#C270A7"
        />
      </g>
    )}
  </svg>
);
