import React from "react";
import LegacyModal from "src/shared/LegacyModal";
import ModalLarge from "src/shared/ModalLarge";

const width = 750;

interface Props {
  userId: string;
  onClose: Function;
}

const UploadFileModal = (props: Props) => {
  return (
    <LegacyModal
      isOpen={true}
      closeModal={() => props.onClose()}
      width={width + 48}
    >
      <LegacyModal.Content>
        <ModalLarge
          userID={props.userId}
          onStartUpload={(_, invalid) =>
            invalid.length === 0 && props.onClose()
          }
        />
      </LegacyModal.Content>
    </LegacyModal>
  );
};

export default UploadFileModal;
