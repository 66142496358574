import React, { Component } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { Link } from "react-router";
import { connect } from "react-redux";

import PatientSummary from "../../components/patient-summary/patient-summary";
import DoctorInput from "../../components/doctor-input/doctor-input";
import PatientTaskList from "../../components/patient-task-list/patient-task-list";
import UpdateStatus from "../../components/update-status/update-status";
import AdverseHistory from "../../components/adverse-history/adverse-history";
import Kits from "../../components/kits/kits";
import EventList from "./event/event-list";
import OrderUtils from "../../components/order-utils/order-utils";
import styles from "./patient-view-style";
import { fetchCheckinsForPatient } from "src/redux/modules/checkin";
import Consent from "../../components/consent/consent";
import {
  loadPatientStatus,
  loadPatientChat,
  loadPatientSystemEvents,
} from "../../../../redux/modules/patient";
import { loadFiles } from "../../../../redux/modules/fileList";
import {
  getEstimateDuration,
  getTreatmentType,
} from "../../../../utils/form-parser";

const TICKET_PULLING_INTERVAL = 10000;

const enhance = connect(
  state => ({
    appointmentList: [],
  }),
  {
    loadPatientChat,
    loadPatientStatus,
    loadPatientSystemEvents,
    fetchCheckinsForPatient,
    loadSelfie: loadFiles,
  }
);

class PatientView extends Component {
  static propTypes = {
    fileList: PropTypes.array,
    patient: PropTypes.object,
    staffSubmittedData: PropTypes.array,
    addEvent: PropTypes.func,
  };

  componentDidMount() {
    const patientId = this.props.patient.id;
    this.props.fetchCheckinsForPatient({ patientId });
    this.props.loadPatientStatus(patientId);
    this.props.loadPatientChat(patientId);
    this.props.loadSelfie(patientId);
    this.props.loadPatientSystemEvents(patientId);

    this.ticketPullInterval = setInterval(() => {
      this.props.loadPatientChat(patientId);
    }, TICKET_PULLING_INTERVAL);
  }
  componentWillUnmount() {
    clearInterval(this.ticketPullInterval);
  }

  renderMainContent() {
    const { patient } = this.props;
    return (
      <div style={styles.S.mainPanel} key={patient.id}>
        <DoctorInput
          {...this.props}
          clinicalRecord={this.props.clinicalRecord.data}
        />
        <EventList patient={patient} />
        {/*<Gallery fileList={fileList} thumbnailStyle={{ width: '33%' }} />*/}
      </div>
    );
  }

  renderSidePanel() {
    const { patient, consent, syncFolderPath, staffSubmittedData } = this.props;
    const estimateDuration = getEstimateDuration(staffSubmittedData);
    const estimateTreatmentType = getTreatmentType(staffSubmittedData);

    return (
      <div style={styles.S.sidePanel}>
        <div>
          <PatientSummary
            patient={patient}
            syncFolderPath={syncFolderPath}
            estimateDuration={estimateDuration}
            estimateTreatmentType={estimateTreatmentType}
          />
        </div>
        <UpdateStatus patient={patient} />
        <OrderUtils />
        <div style={styles.S.contentBlock}>
          <Link
            to={`/print-labels?name=${patient.name}&phone=${
              patient.mobile_phone_number
            }`}
          >
            <div style={styles.S.sectionTitle}>Print labels →</div>
          </Link>
        </div>
        <Kits patientId={patient.id} />
        <PatientTaskList patientId={patient.id} />
        <AdverseHistory patient={patient} />
        <Consent consent={consent} />
      </div>
    );
  }

  render() {
    return (
      <div style={styles.S.container}>
        <Helmet title="Patient" />
        <div style={styles.S.content}>
          {this.renderMainContent()}
          {this.renderSidePanel()}
        </div>
      </div>
    );
  }
}

export default enhance(PatientView);
