import fromPairs from "lodash/fp/fromPairs";
import zip from "lodash/fp/zip";
import fill from "lodash/fp/fill";

import {
  ManufacturingPipelineTypeKeys,
  ClinicalPipelineTypeKeys,
  PipelineCategories,
} from "../../types";
import { createTitlesDictionaryFromKeys } from "../../utils";
import {
  manufacturingPipelinesTypes,
  clinicalPipelinesTypes,
  treatmentLevelTypes,
  pipelineCategories,
} from "../../constants";

const makeCheckboxesInitialValues = <Keys extends ReadonlyArray<string>>(
  labels: Keys
): Record<Keys[number], false> =>
  fromPairs(zip(labels, fill(labels.slice(), false)));

export const treatmentLevelCheckboxes = treatmentLevelTypes.slice(0, 4);
export const treatmentLevelTitles = createTitlesDictionaryFromKeys(
  treatmentLevelCheckboxes
);
export const treatmentLevelInitialValue = makeCheckboxesInitialValues(
  treatmentLevelCheckboxes
);

export const pipelineCategoryCheckboxes = pipelineCategories;
export const pipelineCategoryTitles = createTitlesDictionaryFromKeys(
  pipelineCategoryCheckboxes
);
export const pipelineCategoryInitialValue = makeCheckboxesInitialValues(
  pipelineCategoryCheckboxes
);

export const allPipelineTypesTitles = createTitlesDictionaryFromKeys([
  ...manufacturingPipelinesTypes,
  ...clinicalPipelinesTypes,
]);

export const pipelineCategoryToPipelineTypeMap: Record<
  PipelineCategories,
  ReadonlyArray<ManufacturingPipelineTypeKeys | ClinicalPipelineTypeKeys>
> = {
  manufacturing: manufacturingPipelinesTypes,
  clinical: clinicalPipelinesTypes,
};

export const initialFormValues = {
  homeClinicIDs: [],
  treatmentLevels: treatmentLevelInitialValue,
  pipelineCategories: pipelineCategoryInitialValue,
  pipelineTypes: [],
  taskTypes: [],
  taskCustomLabels: [],
  assignees: [],
};

// gist from https://gist.github.com/marshallswain/88f377c71aa88aceaf660b157f6d8f46
export const usStates: Record<string, string> = [
  { name: "ALABAMA", abbreviation: "AL" },
  { name: "ALASKA", abbreviation: "AK" },
  { name: "AMERICAN SAMOA", abbreviation: "AS" },
  { name: "ARIZONA", abbreviation: "AZ" },
  { name: "ARKANSAS", abbreviation: "AR" },
  { name: "CALIFORNIA", abbreviation: "CA" },
  { name: "COLORADO", abbreviation: "CO" },
  { name: "CONNECTICUT", abbreviation: "CT" },
  { name: "DELAWARE", abbreviation: "DE" },
  { name: "DISTRICT OF COLUMBIA", abbreviation: "DC" },
  { name: "FEDERATED STATES OF MICRONESIA", abbreviation: "FM" },
  { name: "FLORIDA", abbreviation: "FL" },
  { name: "GEORGIA", abbreviation: "GA" },
  { name: "GUAM", abbreviation: "GU" },
  { name: "HAWAII", abbreviation: "HI" },
  { name: "IDAHO", abbreviation: "ID" },
  { name: "ILLINOIS", abbreviation: "IL" },
  { name: "INDIANA", abbreviation: "IN" },
  { name: "IOWA", abbreviation: "IA" },
  { name: "KANSAS", abbreviation: "KS" },
  { name: "KENTUCKY", abbreviation: "KY" },
  { name: "LOUISIANA", abbreviation: "LA" },
  { name: "MAINE", abbreviation: "ME" },
  { name: "MARSHALL ISLANDS", abbreviation: "MH" },
  { name: "MARYLAND", abbreviation: "MD" },
  { name: "MASSACHUSETTS", abbreviation: "MA" },
  { name: "MICHIGAN", abbreviation: "MI" },
  { name: "MINNESOTA", abbreviation: "MN" },
  { name: "MISSISSIPPI", abbreviation: "MS" },
  { name: "MISSOURI", abbreviation: "MO" },
  { name: "MONTANA", abbreviation: "MT" },
  { name: "NEBRASKA", abbreviation: "NE" },
  { name: "NEVADA", abbreviation: "NV" },
  { name: "NEW HAMPSHIRE", abbreviation: "NH" },
  { name: "NEW JERSEY", abbreviation: "NJ" },
  { name: "NEW MEXICO", abbreviation: "NM" },
  { name: "NEW YORK", abbreviation: "NY" },
  { name: "NORTH CAROLINA", abbreviation: "NC" },
  { name: "NORTH DAKOTA", abbreviation: "ND" },
  { name: "NORTHERN MARIANA ISLANDS", abbreviation: "MP" },
  { name: "OHIO", abbreviation: "OH" },
  { name: "OKLAHOMA", abbreviation: "OK" },
  { name: "OREGON", abbreviation: "OR" },
  { name: "PALAU", abbreviation: "PW" },
  { name: "PENNSYLVANIA", abbreviation: "PA" },
  { name: "PUERTO RICO", abbreviation: "PR" },
  { name: "RHODE ISLAND", abbreviation: "RI" },
  { name: "SOUTH CAROLINA", abbreviation: "SC" },
  { name: "SOUTH DAKOTA", abbreviation: "SD" },
  { name: "TENNESSEE", abbreviation: "TN" },
  { name: "TEXAS", abbreviation: "TX" },
  { name: "UTAH", abbreviation: "UT" },
  { name: "VERMONT", abbreviation: "VT" },
  { name: "VIRGIN ISLANDS", abbreviation: "VI" },
  { name: "VIRGINIA", abbreviation: "VA" },
  { name: "WASHINGTON", abbreviation: "WA" },
  { name: "WEST VIRGINIA", abbreviation: "WV" },
  { name: "WISCONSIN", abbreviation: "WI" },
  { name: "WYOMING", abbreviation: "WY" },
].reduce(
  (acc, { name, abbreviation }) =>
    Object.assign(acc, {
      [abbreviation.toLocaleLowerCase()]: name,
    }),
  {}
);
