import styled from "react-emotion";
import color from "src/styles/color";

export const Wrapper = styled.div`
  position: fixed;
  width: 50vw;
  left: 25vw;
  bottom: 2rem;
  background: ${color.gray1};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08), 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: scroll;
  max-height: 160px;
`;

export const Item = styled.div`
  display: flex;
  position: relative;
  padding: 8px 10px 14px;
`;

export const Name = styled.div`
  flex: 1;
`;

export const Percent = styled.div``;

export const Bar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background: #27ae60;
`;
