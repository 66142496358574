import React from "react";

import { Checkbox } from "src/shared/Checkbox";

import { CheckboxGroupProps } from "./types";

// Assumptions:
// 1. All checkboxes in the group have a label
// 2. All checkboxes labels are unique
// Otherwise this component doesn't make sense
// hence you don't need it
export const CheckboxGroup = <Keys extends ReadonlyArray<string>>(
  props: CheckboxGroupProps<Keys>
) => {
  const { order, titles, value, onChange } = props;

  const handleChange = (label: string) => (newCheckboxValue: boolean) => {
    const newValue = { ...value, [label]: newCheckboxValue };

    onChange(newValue);
  };

  return (
    <>
      {order.map(label => (
        <Checkbox
          key={label}
          label={titles[label]}
          value={value[label]}
          onChange={handleChange(label)}
        />
      ))}
    </>
  );
};
