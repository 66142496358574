import React from "react";

import { Select } from "src/shared/Select";

import { Flag } from "./styles";
import { HomeClinicSelectProps } from "./types";

export const HomeClinicSelect: React.FC<HomeClinicSelectProps> = props => {
  const { options, value, onChange } = props;

  return (
    <Select
      placeholder="Select clinic"
      options={options}
      value={value}
      onChange={onChange}
      renderOption={({ value, payload }) => (
        <>
          {value}
          {payload.isFlagship && <Flag>⚐</Flag>}
        </>
      )}
    />
  );
};
