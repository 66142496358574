import React, { useState, useEffect, Fragment } from "react";
import styled from "react-emotion";
import Dropdown from "src/shared/Dropdown";

import color from "src/styles/color";
import Modal from "src/shared/Modal";
import Box from "src/shared/Box";
import Switch from "src/shared/Switch";
import Loading from "src/shared/Loading";

const getIPRString = ({ direction, pid, value }: IPRInfo) => {
  return `${pid}, ${direction} = ${formatDecimal(value)}`;
};

export const formatDecimal = (value: number) => {
  if (Math.abs(value) < 0.01) value = 0;
  return value.toFixed(2);
};

const getStyle = (value, colorHex) => {
  return {
    color: Math.abs(value) < 0.01 ? color.gray2 : undefined,
    backgroundColor: colorHex,
  };
};

const csModeApex = "CS Mode Apex";
const csModeCrown = "CS Mode Crown";
const csModeOcclusal = "CS Mode Occlusal";

const availableTeethCSMode = [csModeApex, csModeCrown, csModeOcclusal];

type IPRInfo = {
  pid: string;
  direction: string;
  value: number;
};

type TeethTransform = {
  buccolingual: number;
  mesiodistal: number;
  rotation: number;
  torque: number;
  tip: number;
  extrusion: number;
};

type ToothVelocities = {
  mesiodistalVelocity: string;
  buccolingualVelocity: string;
  extrusionVelocity: string;
  torqueVelocity: string;
  tipVelocity: string;
  rotationVelocity: string;
};

type TeethMovementInfo = {
  [toothNum: string]: {
    name: string;
    transform: TeethTransform;
    ipr: IPRInfo[];
    velocities: ToothVelocities;
  };
};

const TeethMovementsModal = ({ onClose, wasmModule, phase, frame }) => {
  const [isEntireTreatment, setIsEntireTreatment] = useState(false);
  const [csModeSelected, setCSModeSelected] = useState(csModeApex);
  const [treatmentInfo, setTreatmentInfo] = useState<TeethMovementInfo | null>(
    null
  );

  useEffect(() => {
    const data = JSON.parse(
      wasmModule.webGetTeethMovement(
        isEntireTreatment
          ? wasmModule.TeethMovementOverview_ReportType.entireTreatment
          : wasmModule.TeethMovementOverview_ReportType.currentFrame,
        csModeSelected === csModeApex
          ? wasmModule.Enums_TeethMovementCSMode.CSModeApex
          : csModeSelected === csModeCrown
          ? wasmModule.Enums_TeethMovementCSMode.CSModeCrown
          : wasmModule.Enums_TeethMovementCSMode.CSModeOcclusal
      )
    );

    setTreatmentInfo(data);
  }, [wasmModule, isEntireTreatment, csModeSelected]);

  if (!treatmentInfo) return <LoadingModal onClose={onClose} />;

  const orderedKeys = Object.keys(treatmentInfo).sort(
    // @ts-ignore canonical way to sort string int keys doesnt agree with typescript
    (a: string, b: string) => a - b
  );

  return (
    <Modal onClose={onClose}>
      <Box
        title={
          frame &&
          phase &&
          `${frame.stageNum === "-" ? "Default" : frame.stageNum}@${phase.name}`
        }
        actions={
          <SwitchesRow>
            <Dropdown
              options={availableTeethCSMode}
              selected={csModeSelected}
              onSelect={setCSModeSelected}
            />
            <div style={{ whiteSpace: "nowrap" }}>
              <Switch
                checked={isEntireTreatment}
                onChange={setIsEntireTreatment}
              />
              &nbsp;Entire treatment
            </div>
          </SwitchesRow>
        }
      >
        <Content>
          <Row style={{ fontWeight: "bold" }}>
            <div>Tooth</div>
            <div>Inclination</div>
            <div>Angulation</div>
            <div>Rotation</div>
            <div>Mesiodistal</div>
            <div>Buccolingual</div>
            <div>Occlusogingival</div>
            <div>IPR</div>
          </Row>
          {orderedKeys.map(toothNum => {
            const {
              name,
              transform: {
                buccolingual,
                mesiodistal,
                rotation,
                torque,
                tip,
                extrusion,
              },
              ipr,
              velocities: {
                mesiodistalVelocity,
                buccolingualVelocity,
                extrusionVelocity,
                torqueVelocity,
                tipVelocity,
                rotationVelocity,
              },
            } = treatmentInfo[toothNum];
            return (
              <Row key={toothNum}>
                <div>{name}</div>
                <div style={getStyle(torque, torqueVelocity)}>
                  {formatDecimal(torque)}°
                </div>
                <div style={getStyle(tip, tipVelocity)}>
                  {formatDecimal(tip)}°
                </div>
                <div style={getStyle(rotation, rotationVelocity)}>
                  {formatDecimal(rotation)}°
                </div>
                <div style={getStyle(mesiodistal, mesiodistalVelocity)}>
                  {formatDecimal(mesiodistal)}mm
                </div>
                <div style={getStyle(buccolingual, buccolingualVelocity)}>
                  {formatDecimal(buccolingual)}mm
                </div>
                <div style={getStyle(extrusion, extrusionVelocity)}>
                  {formatDecimal(extrusion)}mm
                </div>
                <div>
                  {ipr.map(a => (
                    <Fragment key={`${a.pid}${a.direction}${a.value}`}>
                      {getIPRString(a)}
                      <br />
                    </Fragment>
                  ))}
                </div>
              </Row>
            );
          })}
        </Content>
      </Box>
    </Modal>
  );
};

const LoadingModal = ({ onClose }) => {
  return (
    <Modal onClose={onClose}>
      <Box title="Movements">
        <Content>
          <Loading />
        </Content>
      </Box>
    </Modal>
  );
};

const Content = styled.div`
  height: 550px;
  width: 900px;

  overflow-y: auto;
  display: flex;
  flex-flow: column;

  font-size: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-flow: row;

  border-bottom: 1px solid ${color.gray3};

  > div {
    flex: 1;
    padding: 5px;
    overflow: overlay;
  }

  // less room for tooth name cell
  > div:first-child {
    flex: 0.5;
  }

  // extra room for IPR cell
  > div:last-child {
    flex: 2;
  }

  > div + div {
    border-left: 1px solid ${color.gray3};
  }
`;

const SwitchesRow = styled.div`
  display: flex;
  flex-flow: row;
  gap: 10px;
  align-items: center;
`;

export default TeethMovementsModal;
