import React from "react";
import { titleCase } from "src/shared/util";
import useFormUI from "src/scenes/PatientProfile/Forms/GQLForms/useFormUI";
import { getTreatmentOptionAboutTabSummary } from "src/scenes/PatientProfile/Forms/BeginningVisit/TxGoalsAndOptions";
import {
  Prewrap,
  SummaryField,
  SummaryFieldLabel,
  SummaryFieldContent,
  SummarySubheader,
} from "../styles";
import { getAnswer } from "../util";
import { NoteContentProps } from "./types";

export default function BeginningVisitForm({
  submission,
  childSubmissions,
}: NoteContentProps) {
  const chosen_treatment = childSubmissions.find(
    it => getAnswer("is_chosen_option", it) === "Yes"
  );
  const bvUI = useFormUI({
    slug: "beginning_visit",
    userID: submission.userID,
    submissionID: submission.id,
    parentSubmissionID: null,
  });
  const txUI = useFormUI({
    slug: "treatment_option",
    userID: submission.userID,
    submissionID: chosen_treatment ? chosen_treatment.id : null,
    parentSubmissionID: submission.id,
  });
  const findings_summary = getAnswer("generated_findings_summary", submission);
  const dx_summary = getAnswer("generated_dx_summary", submission);
  const treatment_type =
    chosen_treatment && getAnswer("treatment_type", chosen_treatment);
  const treatment_arch =
    chosen_treatment && getAnswer("arch_to_treat_rx", chosen_treatment);
  const treatment_length_lower =
    chosen_treatment &&
    getAnswer("rx_estimated_length_option_lower_bound", chosen_treatment);
  const treatment_length_upper =
    chosen_treatment &&
    getAnswer("rx_estimated_length_option_upper_bound", chosen_treatment);
  const generatedSummary =
    bvUI && txUI ? getTreatmentOptionAboutTabSummary(txUI, bvUI) : null;
  const treatment_goals_summary =
    chosen_treatment &&
    getAnswer("treatment_goals_generated_summary", chosen_treatment);
  return (
    <>
      {generatedSummary && (
        <SummaryField key="generatedSummary">
          <SummaryFieldLabel>Generated summary</SummaryFieldLabel>
          <SummaryFieldContent>
            <Prewrap>{generatedSummary}</Prewrap>
          </SummaryFieldContent>
        </SummaryField>
      )}

      {findings_summary && (
        <SummaryField key="findings_summary">
          <SummaryFieldLabel>Findings summary</SummaryFieldLabel>
          <SummaryFieldContent>
            <Prewrap>{findings_summary}</Prewrap>
          </SummaryFieldContent>
        </SummaryField>
      )}

      {dx_summary && (
        <SummaryField key="dx_summary">
          <SummaryFieldLabel>Diagnosis summary</SummaryFieldLabel>
          <SummaryFieldContent>
            <Prewrap>{dx_summary}</Prewrap>
          </SummaryFieldContent>
        </SummaryField>
      )}

      <SummarySubheader>Chosen treatment option</SummarySubheader>

      {!chosen_treatment && <div key="no_chosen_treatment">Not specified</div>}

      {treatment_type && (
        <SummaryField key="treatment_type">
          <SummaryFieldLabel>Type</SummaryFieldLabel>
          <SummaryFieldContent>{titleCase(treatment_type)}</SummaryFieldContent>
        </SummaryField>
      )}

      {treatment_arch && (
        <SummaryField key="treatment_arch">
          <SummaryFieldLabel>Arches</SummaryFieldLabel>
          <SummaryFieldContent>{treatment_arch}</SummaryFieldContent>
        </SummaryField>
      )}

      {(treatment_length_lower || treatment_length_upper) && (
        <SummaryField key="treatment_length">
          <SummaryFieldLabel>Tx length</SummaryFieldLabel>
          <SummaryFieldContent>
            {[treatment_length_lower, treatment_length_upper].join("-")}
          </SummaryFieldContent>
        </SummaryField>
      )}

      {treatment_goals_summary && (
        <SummaryField key="treatment_goals_summary">
          <SummaryFieldLabel>Goals</SummaryFieldLabel>
          <SummaryFieldContent>
            <Prewrap>{treatment_goals_summary}</Prewrap>
          </SummaryFieldContent>
        </SummaryField>
      )}
    </>
  );
}
