import styled, { css } from "react-emotion";
import color from "src/styles/color";

export const Perimeter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  width: 100%;
  display: flex;
  border: 1px ${color.border} solid;
  border-radius: 2px;
  height: 34px;
  padding: 6px 11px;

  &:focus,
  &:active {
    border: 1px ${color.blue} solid;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  ${({ theme: { error } }) =>
    error &&
    css`
      border-color: ${color.red};
    `}

  &:hover {
    border-color: ${color.blue};
  }
`;
