import React from "react";
import { isNil, sum, groupBy, partition } from "lodash";
import { DateTime } from "luxon";
import styled, { css } from "react-emotion";

import money from "src/utils/money";
import color from "src/styles/color";
import { claimAmount, claimTitle } from "src/utils/insurance";
import { isRefundManager } from "src/utils/roles";
import { getPromoCentAmount } from "src/utils/promotion";

import useSelf from "src/state/self";
import { Promotion, Order, OrderItem } from "src/types/gql";

export const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

export const OrderGrid = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr) 0.5fr 70px;
  padding: 10px 0;
  align-items: center;

  > div:nth-child(5) {
    text-align: right;
  }
`;

export const OrderGridTitle = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr) 0.5fr 70px;
  padding-bottom: 10px;
  align-items: center;
  border-bottom: 1px solid ${color.border};
  font-weight: 600;

  > div {
    color: ${color.gray3};
  }

  > div:nth-of-child(5) {
    text-align: right;
  }
`;

export const SectionTitle = styled.h6`
  padding: 24px 0 12px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
`;

const RemovePromoButton = styled.button`
  color: ${color.red};
  font-weight: 600;
  background: none;
  border: none;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const InsuranceClaimLink = styled.button`
  color: ${color.blue};
  background: none;
  border: none;
  padding: 0;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Line = styled.div`
  height: 1px;
  border-bottom: 1px solid ${color.border};
  margin: 5px 0;
`;

export const PartialLine = styled.div`
  height: 1px;
  border-bottom: 1px solid ${color.border};
  margin: 5px 0;
  width: 48%;
  align-self: flex-end;
`;

const deletedPromoCss = css`
  opacity: 0.6;
  font-style: italic;
`;

const DENIED_INSURANCE_CLAIM_STATUS = "denied";

const formatDiscountAmount = (p: Promotion) => {
  switch (p.type) {
    case "percentage_off":
      return `${p.amount}%`;
    default:
      return money(p.amount);
  }
};

const orderItemsByQuantity = (o: Order) => {
  const byProductName: { [productName: string]: OrderItem[] } = groupBy(
    o.orderItems,
    oi => oi.product.name
  );
  return Object.entries(byProductName).map(([, items]) => ({
    ...items[0],
    quantity: items.length,
    totalPrice: items[0].product.price * items.length,
  }));
};

const getDiscountsPartition = (discounts, paymentPlan) => {
  return partition(discounts, d => d.createdAt < paymentPlan.createdAt);
};

const OrderSummary = ({ order, setModal, paymentPlan }) => {
  const self = useSelf();
  const totalInterest = sum(order.interests.map(interest => interest.amount));
  const isPaymentPlan = Boolean(paymentPlan);

  const [discounts, postSaleDiscounts] = isPaymentPlan
    ? getDiscountsPartition(order.discounts, paymentPlan)
    : [order.discounts, []];

  const sumPostSaleDiscounts = sum(
    postSaleDiscounts
      .filter(discount => isNil(discount.deletedAt))
      .map(d => getPromoCentAmount(d.promotion, order.subtotal))
  );

  const [adjustments, postSaleAdjustments] = isPaymentPlan
    ? getDiscountsPartition(order.adjustments, paymentPlan)
    : [order.adjustments, []];
  const sumPostSaleAdjustments = sum(
    postSaleAdjustments.filter(a => isNil(a.deletedAt)).map(a => a.amount)
  );

  const [preSaleCharges, postSaleCharges] = isPaymentPlan
    ? partition(
        order.charges,
        charge => charge.createdAt < paymentPlan.createdAt
      )
    : [[], order.charges];

  const [preSaleRefunds, postSaleRefunds] = isPaymentPlan
    ? partition(
        order.refunds,
        refund => refund.createdAt < paymentPlan.createdAt
      )
    : [[], order.refunds];

  const deposit =
    sum(preSaleCharges.map(c => c.amount)) -
    sum(preSaleRefunds.map(r => r.amount));

  const postSalePayments =
    sum(postSaleCharges.map(c => c.amount)) -
    sum(postSaleRefunds.map(r => r.amount));

  const insuranceClaims = sum(order.claims.map(c => claimAmount(c)));

  return (
    <Container>
      <SectionTitle>Order summary</SectionTitle>
      <OrderGridTitle>
        <div>Date</div>
        <div>Product</div>
        <div>Quantity</div>
        <div>Amount</div>
      </OrderGridTitle>
      {orderItemsByQuantity(order).map(oi => (
        <OrderGrid key={oi.id}>
          <div>
            {DateTime.fromISO(order.createdAt).toFormat("LLLL d, yyyy")}
          </div>
          <div>{oi.product.name}</div>
          <div>{oi.quantity}</div>
          <div>{money(oi.totalPrice)}</div>
        </OrderGrid>
      ))}
      <Line />
      <OrderGrid>
        <div />
        <div />
        <div>Order Subtotal</div>
        <div>{money(order.subtotal)}</div>
      </OrderGrid>
      {isPaymentPlan && (
        <OrderGrid>
          <div />
          <div />
          <div>Deposit</div>
          <div>{money(deposit)}</div>
        </OrderGrid>
      )}

      {adjustments.map(adjustment => (
        <OrderGrid
          key={adjustment.id}
          className={adjustment.deletedAt && deletedPromoCss}
        >
          <div />
          <div />
          <div>Adjustment</div>
          <div>-{money(adjustment.amount)}</div>
          {!adjustment.deletedAt && isRefundManager(self) && (
            <div>
              <RemovePromoButton
                onClick={() => setModal({ type: "removeMarkdown", adjustment })}
              >
                Remove
              </RemovePromoButton>
            </div>
          )}
        </OrderGrid>
      ))}
      {discounts.map(d => (
        <OrderGrid key={d.id} className={d.deletedAt && deletedPromoCss}>
          <div />
          <div />
          <div>
            Promo Code: {d.promotion.code} {d.deletedAt && " (Removed)"}
          </div>
          <div>-{formatDiscountAmount(d.promotion)}</div>
          {!d.deletedAt && (
            <div>
              <RemovePromoButton
                onClick={() => setModal({ type: "removePromo", discount: d })}
              >
                Remove
              </RemovePromoButton>
            </div>
          )}
        </OrderGrid>
      ))}
      {order.claims.map(c => {
        if (c.status !== DENIED_INSURANCE_CLAIM_STATUS) {
          return (
            <OrderGrid key={c.id}>
              <div />
              <div />
              <div>{claimTitle(c)} (Details below)</div>
              <div>-{money(claimAmount(c))}</div>
              <div>
                <InsuranceClaimLink
                  onClick={() =>
                    setModal({ type: "editInsuranceClaim", claim: c })
                  }
                >
                  Edit claim
                </InsuranceClaimLink>
              </div>
            </OrderGrid>
          );
        }
        return <></>;
      })}
      <PartialLine />
      <OrderGrid>
        <div />
        <div />
        <div>Order Total</div>
        {/* The order total displayed here should be the total _at the time_ of sale aka
            at the time that the payment plan is created. Any post-sale adjustments should
            only be displayed later. The backend includes interest in order.total, but we should
            display that separately on the UI
          */}
        <div>
          {money(
            order.total +
              sumPostSaleDiscounts +
              sumPostSaleAdjustments -
              totalInterest -
              deposit -
              insuranceClaims
          )}
        </div>
      </OrderGrid>
      {order.interests.map(i => (
        <OrderGrid key={i.id}>
          <div />
          <div />
          <div>
            Interest{" "}
            {paymentPlan && (
              <span>({paymentPlan.financingProduct.rate * 100}%)</span>
            )}
          </div>
          <div>{money(i.amount)}</div>
        </OrderGrid>
      ))}
      <PartialLine />
      <OrderGrid>
        <div />
        <div />
        <div>Patient Responsibility</div>
        <div>
          {money(
            order.total +
              sumPostSaleDiscounts +
              sumPostSaleAdjustments -
              deposit -
              insuranceClaims
          )}
        </div>
      </OrderGrid>
      <OrderGrid>
        <div />
        <div />
        <div>Patient Payments</div>
        {/* For payment plan patients, this should be all payments minus refunds _after_ post of sale */}
        <div>{money(postSalePayments)}</div>
      </OrderGrid>
      {order.claims.map(c => {
        if (c.status === DENIED_INSURANCE_CLAIM_STATUS) {
          return (
            <OrderGrid key={c.id}>
              <div />
              <div />
              <div>{claimTitle(c)} (Details below)</div>
              <div>{money(claimAmount(c))}</div>
              <div>
                <InsuranceClaimLink
                  onClick={() =>
                    setModal({ type: "editInsuranceClaim", claim: c })
                  }
                >
                  Edit claim
                </InsuranceClaimLink>
              </div>
            </OrderGrid>
          );
        }

        return <></>;
      })}
      {postSaleAdjustments
        .filter(a => isNil(a.deletedAt))
        .map(adjustment => (
          <OrderGrid key={adjustment.id}>
            <div />
            <div />
            <div>Post-Sale Adjustment</div>
            <div>-{money(adjustment.amount)}</div>
            {isRefundManager(self) && (
              <div>
                <RemovePromoButton
                  onClick={() =>
                    setModal({ type: "removeMarkdown", adjustment })
                  }
                >
                  Remove
                </RemovePromoButton>
              </div>
            )}
          </OrderGrid>
        ))}
      {postSaleDiscounts
        .filter(d => isNil(d.deletedAt))
        .map(d => (
          <OrderGrid key={d.id}>
            <div />
            <div />
            <div>Post-Sale Promo Code: {d.promotion.code}</div>
            <div>-{formatDiscountAmount(d.promotion)}</div>
            <div>
              <RemovePromoButton
                onClick={() => setModal({ type: "removePromo", discount: d })}
              >
                Remove
              </RemovePromoButton>
            </div>
          </OrderGrid>
        ))}
      <PartialLine />
      {postSaleRefunds.map(refund => (
        <OrderGrid key={refund.id}>
          <div />
          <div />
          <div>
            Check Refund (
            {DateTime.fromISO(refund.createdAt).toFormat("LLL d, yyyy")})
          </div>
          <div>-{money(refund.amount)}</div>
          <div>
            <RemovePromoButton
              onClick={() =>
                setModal({
                  type: "removeRefund",
                  deleteRefund: {
                    id: refund.id,
                    type: refund.details.__typename,
                  },
                })
              }
            >
              Remove
            </RemovePromoButton>
          </div>
        </OrderGrid>
      ))}

      <OrderGrid>
        <div />
        <div />
        <div>
          <b>Balance</b>
        </div>
        <div>
          <b>{money(order.balance)}</b>
        </div>
      </OrderGrid>
    </Container>
  );
};

export default OrderSummary;
