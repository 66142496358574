import { Input } from "antd";
import styled, { css } from "react-emotion";
import color from "src/styles/color";

export const Label = styled.div`
  font-weight: bold;
`;

export const ButtonStyle = css`
  display: inline-block;
`;

export const StyledTextArea = styled(Input.TextArea)`
  ant-input:hover {
    border: 1px ${color.primary} solid !important;
  }
  &:focus,
  &:active {
    border: 1px ${color.gray2} solid !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  &:hover {
    border-color: ${color.gray2};
  }
`;
