import React, { useState } from "react";
import ExternalPopover from "react-tiny-popover";
import { Background, wrapperCSS } from "./styles";

interface Props {
  // visible, if provided, allows the parent to control visibility
  visible?: boolean;
  // if this is a controlled component
  close?: () => void;

  position?: "left" | "right" | "top" | "bottom" | undefined;
  align?: "end" | "center" | "start" | undefined;

  persistOnClick?: boolean;
  onShow?: () => void;

  // This callback should return `true` to indicate that the popover should be opened afterwards.
  onClickWhenClosed?: () => boolean;

  content: JSX.Element;
  children: React.ReactNode;
}

const Popover = (props: Props) => {
  // We always need to render the same number of hooks, but visibility may be controlled
  // by the parent.
  //
  // ESlint also thinks we're calling a hook in a callback;  ignore it.
  // eslint-disable-next-line
  const [ownVisible, setOwnVisible] = useState(false);

  const controlled = props.visible !== undefined;
  const visible = controlled ? props.visible : ownVisible;

  // There can be some race conditions which cause Popover to retain in the
  // DOM under some circumstances.  It's rare, but happened during testing.
  // Don't render popover whenever it's hidden, at all.
  if (!visible) {
    return (
      <div
        onClick={e => {
          if (props.onClickWhenClosed) {
            e.stopPropagation();
            const shouldShow = props.onClickWhenClosed();
            setOwnVisible(shouldShow);
            if (shouldShow && props.onShow) {
              props.onShow();
            }
          } else if (!controlled) {
            e.preventDefault();
            e.stopPropagation();
            setOwnVisible(true);
            if (props.onShow) {
              props.onShow();
            }
          }
        }}
        className={wrapperCSS}
      >
        {props.children}
      </div>
    );
  }

  return (
    <>
      <ExternalPopover
        isOpen={visible}
        position={props.position || "bottom"}
        align={props.align || "start"}
        content={
          <div
            onClick={() =>
              !controlled && !props.persistOnClick && setOwnVisible(false)
            }
          >
            {props.content}
          </div>
        }
        onClickOutside={() => !controlled && setOwnVisible(false)}
        containerStyle={{
          boxShadow: "0 4px 14px rgba(0, 0, 0, 0.2)",
          zIndex: "9999",
          background: "#fff",
        }}
      >
        <div
          onClick={() => !controlled && setOwnVisible(true)}
          className={wrapperCSS}
        >
          {props.children}
        </div>
      </ExternalPopover>
      <Background
        onClick={e => {
          // controlled wrappers have an onClick handler which sets visible to true;
          // prevent default and stop propagation prevent this from firing.
          e.preventDefault();
          e.stopPropagation();
          props.close ? props.close() : setOwnVisible(false);
        }}
      />
    </>
  );
};

export default Popover;
