import React, { useReducer, useContext } from "react";
import {
  PropsDefaultState,
  State,
  DisplayType,
  AttributeType,
  ToothNumber,
  ToothLocation,
  Attribute,
} from "./types";
import { toggleAttribute, addAttribute, removeAttribute } from "./consts";

type Dispatch = (_: Action) => void;

export type AddAction = {
  toothNumber: ToothNumber;
  type: "addAttribute";
  attribute: AttributeType;
  location: ToothLocation;
  data?: string | null;
};

export type ToggleAction = {
  toothNumber: ToothNumber;
  type: "toggleAttribute";
  attribute: AttributeType;
  location: ToothLocation;
  data?: string | null;
};

export type RemoveAction = {
  toothNumber: ToothNumber;
  type: "removeAttribute";
  attribute: AttributeType;
  location: ToothLocation;
};

type Action =
  | { type: "display"; display: DisplayType }
  | { type: "toggleTool"; selectedTool: AttributeType; data?: string }
  | { type: "selectTooth"; selectedTooth: ToothNumber | null }
  | { type: "replaceUnsavedAttributes"; unsavedAttributes: Attribute[] }
  | { type: "replaceCumulativeAttributes"; attributes: Attribute[] }
  | ToggleAction
  | AddAction
  | RemoveAction;

const reducer = (state: State, a: Action): State => {
  switch (a.type) {
    case "display":
      return { ...state, display: a.display, selectedTool: null };
    case "toggleTool":
      if (
        state.selectedTool &&
        state.selectedTool.type === a.selectedTool &&
        state.selectedTool.data === a.data
      ) {
        return { ...state, selectedTool: null };
      }
      return { ...state, selectedTool: { type: a.selectedTool, data: a.data } };
    case "selectTooth":
      return { ...state, selectedTooth: a.selectedTooth };
    case "replaceUnsavedAttributes":
      return { ...state, unsavedAttributes: a.unsavedAttributes };
    case "toggleAttribute":
      return toggleAttribute(state, a);
    case "addAttribute":
      return addAttribute(state, a);
    case "removeAttribute":
      return removeAttribute(state, a);
    case "replaceCumulativeAttributes":
      return { ...state, cumulativeAttributes: a.attributes };
    default:
      return state;
  }
};

const defaultDisplayTypes: DisplayType[] = [
  "cumulative",
  "prescribed",
  "performed",
  "original",
];

const defaultState = (defaults?: PropsDefaultState): State => {
  return {
    display: (defaults && defaults.defaultDisplayType) || "cumulative",
    availableDisplayTypes:
      (defaults && defaults.availableDisplayTypes) || defaultDisplayTypes,
    selectedTool: null,
    selectedTooth: null,
    cumulativeAttributes: [],
    cumulativeCutoff: defaults && defaults.cumulativeCutoff,
    unsavedAttributes: (defaults && defaults.unsavedAttributes) || [],
    readOnlyDisplayTypes: (defaults && defaults.readOnlyDisplayTypes) || [],
  };
};

// useState sets up the initial reducer
const useState = (defaults?: PropsDefaultState) => {
  return useReducer(reducer, defaults, defaultState);
};

// # Context
//
// Context provides access to the above state/reducer through all tooth chart components.
// Import the "useStateContext" hook to access state/dispatch in any subcomponent.

const StateContext = React.createContext<[State, Dispatch]>([
  defaultState(),
  (_: Action) => {},
]);

export const useStateContext = () => useContext(StateContext);

export const Provider: React.FC<PropsDefaultState> = props => {
  const [state, dispatch] = useState(props);

  return (
    <StateContext.Provider value={[state, dispatch]}>
      {props.children}
    </StateContext.Provider>
  );
};
