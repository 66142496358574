import { V, S } from "src/shared-styles";
export default {
  V,
  S,
  invoiceItemContainer: {
    ...S.materialUICard,
    padding: V.spacing,
    display: "flex",
  },
  invoiceItem: {
    fontSize: V.fontLarge,
  },
  invoiceItemDetails: {
    color: V.colors.fontFaded,
  },
  icon: {
    cursor: "pointer",
    display: "inline-block",
    width: V.fontHuge,
    height: V.fontHuge,
  },
  rightControlContainer: {
    float: "right",
  },
  countContainer: {
    minWidth: 115,
    textAlign: "right",
  },
  count: {
    marginLeft: V.spacingTiny,
    marginRight: V.spacingTiny,
    position: "relative",
    bottom: 8,
    fontSize: V.fontLarge,
    display: "inline-block",
  },
  price: {
    fontSize: V.fontLarge,
  },
  subTotal: {
    marginRight: V.spacingSmall,
  },
  summary: {
    textAlign: "right",
    fontSize: V.fontLarge,
    color: V.colors.fontPrimary,
  },
  notice: {
    ...S.label,
  },
};
