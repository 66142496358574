import React from "react";

export default () => (
  <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="15.5" stroke="#5d6161" stroke-dasharray="2 2" />
    <path
      d="M20.243 17.757a5.98 5.98 0 00-2.28-1.43A3.467 3.467 0 0016 10a3.467 3.467 0 00-1.963 6.327 5.978 5.978 0 00-2.28 1.43A5.96 5.96 0 0010 22h.938A5.068 5.068 0 0116 16.937 5.068 5.068 0 0121.063 22H22c0-1.603-.624-3.11-1.757-4.243zM16 16a2.534 2.534 0 01-2.531-2.531A2.534 2.534 0 0116 10.938a2.534 2.534 0 012.531 2.53A2.534 2.534 0 0116 16z"
      fill="#5d6161"
    />
  </svg>
);
