export const transitions = {
  idle: {
    save: "typing",
    rename: "typing",
    share: "idle",
    accept: "idle",
    delete: "idle",
  },

  // Save and rename scenario
  typing: {
    titleExist: "confirmingReplace",
    titleNew: "creating",
    titleUpdate: "renaming",
  },
  confirmingReplace: {
    cancel: "typing",
    confirm: "replacing",
  },
  creating: {
    success: "idle",
    error: "idle",
  },
  replacing: {
    success: "idle",
    error: "idle",
  },
  renaming: {
    success: "idle",
    error: "idle",
  },
} as const;
