import React, { useContext } from "react";
import { EmptySlot } from "src/types/api";
import { Appointment } from "src/types/gql";

export type FilterableEntry = EmptySlot | Appointment;

export interface SearchFiltersContextData {
  isSearchHit(entry: FilterableEntry): boolean;
}

export const SearchFiltersContext = React.createContext<
  SearchFiltersContextData
>({
  isSearchHit: () => true,
});

export default function useSearchFiltersContext() {
  return useContext(SearchFiltersContext);
}
