import { WrappedPatientStatus } from "src/types/gql";
import { CombinedError, useQuery } from "src/utils/http/gqlQuery";

export const usePatientStatus = (): [
  boolean,
  CombinedError | undefined,
  WrappedPatientStatus[]
] => {
  const [{ fetching, error, data }] = useQuery({
    query: getPatientStatuses,
    requestPolicy: "cache-first",
  });
  return [
    fetching,
    error,
    (data && data.patientStatuses) || ([] as WrappedPatientStatus[]),
  ];
};

const getPatientStatuses = `
query getPatientStatuses {
  patientStatuses {
    name
    transitions
  }
}
`;

export type UpdateUserType = {
  patientId: string;
  homeClinicID?: string;
  mobilePhoneNumber?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  dateOfBirth?: string;
  isSpam?: boolean;
  spamReason?: string;
  status?: string;
};
