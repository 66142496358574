import React from "react";

const Misc = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 26 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1342 0H1.55023C1.08852 0 0.714233 0.374286 0.714233 1.10057V31.4286C0.714233 31.6257 1.08852 32 1.55023 32H24.4497C24.9114 32 25.2857 31.6257 25.2857 31.4286V7.416C25.2857 7.01829 25.2325 6.89029 25.1388 6.796L18.4897 0.146857C18.3954 0.0531429 18.2674 0 18.1342 0Z"
      fill="#E9E9E0"
    />
    <path
      d="M18.4285 0.0862846V6.85714H25.1993L18.4285 0.0862846Z"
      fill="#D9D7CA"
    />
    <path
      d="M24.4497 32H1.55023C1.08852 32 0.714233 31.6257 0.714233 31.164V22.2857H25.2857V31.164C25.2857 31.6257 24.9114 32 24.4497 32Z"
      fill="#A2A9AD"
    />
    <path
      d="M8.25756 15.6429H9.21756V14.4929H8.94756C8.58756 14.4929 8.47756 14.3729 8.47756 14.0329V12.6229C8.47756 11.9629 8.18756 11.7229 7.72756 11.6429C8.18756 11.5629 8.47756 11.3229 8.47756 10.6629V9.25286C8.47756 8.90286 8.58756 8.78286 8.94756 8.78286H9.21756V7.64286H8.25756C7.63756 7.64286 7.21756 8.04286 7.21756 8.65286V10.3529C7.21756 10.9129 6.99756 11.1229 6.43756 11.1229V12.1629C6.99756 12.1629 7.21756 12.3729 7.21756 12.9329V14.6329C7.21756 15.2429 7.63756 15.6429 8.25756 15.6429ZM12.4961 10.1729V11.6129H13.9961V10.1729H12.4961ZM11.8061 15.6629V16.8129H12.3961C13.3261 16.8129 13.9561 16.1629 13.9561 15.1929V13.7029H12.5361V15.0429C12.5361 15.4129 12.3261 15.6629 12.0261 15.6629H11.8061ZM17.7433 15.6429C18.3733 15.6429 18.7833 15.2429 18.7833 14.6329V12.9329C18.7833 12.3729 19.0033 12.1629 19.5733 12.1629V11.1229C19.0033 11.1229 18.7833 10.9129 18.7833 10.3529V8.65286C18.7833 8.04286 18.3733 7.64286 17.7433 7.64286H16.7933V8.78286H17.0633C17.4133 8.78286 17.5233 8.90286 17.5233 9.25286V10.6629C17.5233 11.3229 17.8133 11.5629 18.2733 11.6429C17.8133 11.7229 17.5233 11.9629 17.5233 12.6229V14.0329C17.5233 14.3729 17.4133 14.4929 17.0633 14.4929H16.7933V15.6429H17.7433Z"
      fill="#BDBDBD"
    />
  </svg>
);

export default Misc;
