import React, { PureComponent } from "react";
import { withStateHandlers, withState, compose } from "recompose";
import Tooth from "./Tooth";
import styled from "react-emotion";
import Toolbar from "./Toolbar";
import Visibility from "./Visibility";
import { generateToothProcedures, toothName, renamed } from "./util";
import { ToothEnhancer } from "src/state/Tooth";

// type PassedProps {
//   className?: any;

//   // Stages which we show in the toolbar, ie. only allow "rx" entries.
//   stagesVisible?: Array<string>;

//   // previousProcedures lists all procedures that have previously been rendered.
//   // These are typically immutable (TBD how we delete them if they're mistakes)
//   previousProcedures: Array<ToothOperation>;

//   // procedures lists procedures edited during the current session.
//   procedures: Array<ToothOperation>;

//   // setProcedures updates all tooth chart procedures with the given
//   // array.
//   setProcedures(procedures: Array<ToothOperation>): any;

//   disabled?: boolean;
// }

// type ModifierProps = {
//   selectedType: string,
//   selectedStage: string,
//   typeSettings: Object,
//   onTypeSelect: (stage: string, type?: string) => void,
//   onTypeSettingsSelect: (settings: Object) => void
// };

// type Props = PassedProps & ModifierProps;

const modifiers = compose(
  // Load the existing tooth information for the patient.
  ToothEnhancer,
  // State for selecting tools to create new tooth operation entries.
  withStateHandlers(
    {
      selectedType: "",
      selectedStage: "",
      typeSettings: {},
    },
    {
      // Select a new procedure type and stage.  Stage refers to the tab type:
      // whether this is antecedent etc.
      onTypeSelect: state => (stage, type) =>
        Object.assign({}, state, {
          // If this is selecting "spacing" stage, there's only one type which is
          // also "spacing", so auto-select it.
          selectedType: stage === "spacing" ? "spacing" : type,
          selectedStage: stage,
          // Ensure we reset the settings
          typeSettings: {},
        }),
      // Set specific settings for the procedure type, ie. IPR amount
      onTypeSettingsSelect: state => settings =>
        Object.assign({}, state, {
          // Merge the individual setting into what previously existed.
          typeSettings: Object.assign({}, state.typeSettings, settings),
        }),
    }
  ),
  // State for toggling which previous tooth entries, based off of stage, are visible
  // using the toggle switches.
  //
  // The defaults show anteedent, prescribed, rendered, and the current form.
  withState("previousStagesVisible", "setPreviousStagesVisible", [
    "antecedent",
    "prescribed",
    "rendered",
  ])
);

class ToothChart extends PureComponent {
  // onToothClick is called when a user clicks on a tooth.  We use the given tooth
  // information plus the selected type/stage/settings from Compose's state to add
  // procedures for a specific tooth.
  //
  // TODO: Position enum type.
  onToothClick = (section, number, position) => {
    const {
      disabled,
      selectedType,
      selectedStage,
      typeSettings,
      setProcedures,
      procedures,
      previousProcedures,
    } = this.props;

    if (disabled) {
      return;
    }

    // TODO: IS this an elastic?  Elastics need a start tooth and an end tooth, and can
    // chain up to four buccal/lingual surfaces for attaching.

    // Create an array of procedures to add for the given tooth click.  This ensures that
    // all entry_data in the array returned is properly formed - which we need to do
    // comparisons to check if we're toggling a procedure.
    //
    // Note that the first item in the array is always the primary tooth click;  subsequent
    // elements are secondary items such as the adjascent tooth for IPR.
    const newProcedures = generateToothProcedures({
      section,
      number,
      position,
      entryType: selectedType || "",
      entryStage: selectedStage || "",
      entryData: typeSettings,
      previousProcedures,
    });

    if (newProcedures.length === 0) {
      // End early.
      return;
    }

    const primary = newProcedures[0];
    const secondary = newProcedures[1];

    // Does this procedure already exist for this tooth type?  If so, remove it as if
    // clicking a tooth toggles.
    const index = this.props.procedures.findIndex(p => {
      if (p.tooth_name !== primary.tooth_name) {
        return false;
      }
      if (p.entry_type !== primary.entry_type) {
        return false;
      }

      // If there's a position specified for this tooth, ensure we're clicking on
      // the same position.  This allows us to add lingual and buccal attachments
      // to the same tooth (otherwise attempting to add the 2nd attachment would
      // remove the 1st)
      if (
        p.entry_data &&
        primary.entry_data &&
        p.entry_data["position"] !== primary.entry_data["position"]
      ) {
        return false;
      }
      return true;
    });

    if (index > -1) {
      const copy = procedures.slice();
      copy.splice(index, 1);
      setProcedures(copy);
      return;
    }

    // When adding IPR, we may already have IPR for the adjascent tooth.  Check for
    // this and, if so, remove it.
    if (secondary) {
      const copy = procedures
        .filter(p => {
          return (
            p.tooth_name !== secondary.tooth_name ||
            p.entry_type !== secondary.entry_type ||
            (p.entry_data &&
              secondary.entry_data &&
              p.entry_data["position"] !== secondary.entry_data["position"])
          );
        })
        .concat(newProcedures);
      setProcedures(copy);
      return;
    }

    setProcedures(procedures.concat(newProcedures));
  };

  proceduresForTooth = (section, number) => {
    const {
      procedures,
      previousProcedures,
      previousStagesVisible,
    } = this.props;

    const all = procedures.concat(
      // only show previous procedures if we have the stage toggled as visible
      // in the toggle visibility toolbar
      previousProcedures.filter(t => {
        return previousStagesVisible.indexOf(t.entry_stage) > -1;
      })
    );

    // Filter the items by tooth name
    return all
      .filter(p => p.tooth_name === toothName({ section, number }))
      .map(item => {
        const renamedType = renamed[item.entry_type];
        if (renamedType) {
          item.entry_type = renamedType;
        }
        return item;
      });
  };

  // flagProcedures takes the procedures for the right proximal of the current tooth
  // and the left proximal of the tooth to the right, combining both proximal surfaces
  // into a single list.
  flagProcedures = (section, number) => {
    const tooth = this.proceduresForTooth(section, number).filter(p => {
      if (p.entry_type !== "ipr" && p.entry_type !== "spacing") {
        return false;
      }
      return p.entry_data["position"] === "right proximal";
    });

    let adjascent = [];

    // Is this the right side?  We decrease the number for right teeth and increase
    // for left teeth.
    const isRight = section === "UR" || section === "LR";

    if (isRight) {
      adjascent = this.proceduresForTooth(section, number - 1);
    } else {
      adjascent = this.proceduresForTooth(section, number + 1);
    }

    // Swap to the left section.
    if (isRight && number === 1) {
      adjascent = this.proceduresForTooth(`${section.substr(0, 1)}L`, 1);
    }

    adjascent = adjascent.filter(p => {
      if (p.entry_type !== "ipr" && p.entry_type !== "spacing") {
        return false;
      }
      return p.entry_data["position"] === "left proximal";
    });

    return tooth.concat(adjascent);
  };

  render() {
    const {
      selectedType,
      selectedStage,
      onTypeSettingsSelect,
      onTypeSelect,
      className,
      disabled,
    } = this.props;

    return (
      <Wrapper className={className}>
        <Visibility
          visible={this.props.previousStagesVisible}
          onChange={this.props.setPreviousStagesVisible}
        />
        <Teeth>
          <Mandibular>
            <Right>
              {new Array(8).fill(null).map((_, i) => (
                <Tooth
                  key={`UR${i}`}
                  section="UR"
                  number={i + 1}
                  type="mandibular"
                  onClick={this.onToothClick}
                  procedures={this.proceduresForTooth("UR", i + 1)}
                  flagProcedures={this.flagProcedures("UR", i + 1)}
                />
              ))}
            </Right>
            <Left>
              {new Array(8).fill(null).map((_, i) => (
                <Tooth
                  key={`UL${i}`}
                  section="UL"
                  number={i + 1}
                  type="mandibular"
                  onClick={this.onToothClick}
                  procedures={this.proceduresForTooth("UL", i + 1)}
                  flagProcedures={this.flagProcedures("UL", i + 1)}
                />
              ))}
            </Left>
          </Mandibular>
          <Maxillary>
            <Right>
              {new Array(8).fill(null).map((_, i) => (
                <Tooth
                  key={`LR${i}`}
                  section="LR"
                  number={i + 1}
                  type="maxillary"
                  onClick={this.onToothClick}
                  procedures={this.proceduresForTooth("LR", i + 1)}
                  flagProcedures={this.flagProcedures("LR", i + 1)}
                />
              ))}
            </Right>
            <Left>
              {new Array(8).fill(null).map((_, i) => (
                <Tooth
                  key={`LL${i}`}
                  section="LL"
                  number={i + 1}
                  type="maxillary"
                  onClick={this.onToothClick}
                  procedures={this.proceduresForTooth("LL", i + 1)}
                  flagProcedures={this.flagProcedures("LL", i + 1)}
                />
              ))}
            </Left>
          </Maxillary>
        </Teeth>
        {!disabled && (
          <Toolbar
            stagesVisible={this.props.stagesVisible}
            selectedStage={selectedStage}
            selectedType={selectedType}
            onTypeSelect={onTypeSelect}
            onTypeSettingsSelect={onTypeSettingsSelect}
          />
        )}
      </Wrapper>
    );
  }
}

export default modifiers(ToothChart);

const Wrapper = styled("div")`
  width: 100%;
  min-width: 830px;
  position: relative;
  margin-bottom: 1rem;
`;

const Teeth = styled("div")`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 28rem;

  &:before,
  &:after {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -0.45rem;
    line-height: 1;
    background: #fff;
    padding: 0 5px;
    color: #e4e4e4;
    font-size: 0.8rem;
  }

  &:after {
    content: "left";
    right: 0;
  }

  &:before {
    content: "right";
    left: 0;
  }
`;

const Mandibular = styled("div")`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  border-bottom: 1px solid #e4e4e4;

  &:after {
    display: block;
  }
`;

const Maxillary = styled("div")`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const Right = styled("div")`
  flex: 1;
  display: flex;
  padding-right: calc(0.25rem + 2px);
  flex-direction: row-reverse;

  border-right: 1px solid #e4e4e4;
`;

const Left = styled("div")`
  display: flex;
  flex: 1;
  padding-left: 0.25rem;
`;
