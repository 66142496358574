import styled, { css } from "react-emotion";
import color from "src/styles/color";

const maxSize = 28;
const phaseSize = 16;
const ringWidth = 4;

const shadow = css`
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
`;

export const GreenRing = styled.div`
  position: absolute;
  width: ${({ theme }) => (theme.size ? `${theme.size}px` : `${maxSize}px`)};
  height: ${({ theme }) => (theme.size ? `${theme.size}px` : `${maxSize}px`)};
  border-radius: ${maxSize}px;
  background: #59b8b8;
  display: flex;
  justify-content: center;
  align-items: center;
  ${shadow};
`;

export const Diamond = styled.div`
  position: absolute;
  width: ${phaseSize / 2}px;
  height: ${phaseSize / 2}px;
  transform: rotate(45deg);
  background: ${({ theme }) => (theme.filled ? "#59b8b8" : `${color.border}`)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WhiteRing = styled.div`
  position: absolute;
  width: ${maxSize - ringWidth}px;
  height: ${maxSize - ringWidth}px;
  border-radius: ${maxSize - ringWidth}px;
  background: ${color.white};
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme: { useShadow } }) => useShadow && shadow};
`;

export const InnerCircle = styled.div`
  width: ${maxSize - ringWidth * 2}px;
  height: ${maxSize - ringWidth * 2}px;
  border-radius: ${maxSize - ringWidth * 2}px;
  font-size: 10px;
  line-height: 10px;
  color: ${color.white};
  background-color: #59b8b8;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GrayCircle = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: ${color.border};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SmWhiteCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: ${color.white};
`;
