import styled, { css } from "react-emotion";
import color from "src/styles/color";

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`;

export const contentWrapper = css`
  flex: 1;
`;

export const FileItem = styled.button`
  border: none;
  background: none;
  cursor: pointer;

  display: flex;
  flex-flow: column;
  align-items: flex-start;
  text-align: left;

  padding: 16px 24px;
  border-bottom: 1px solid ${color.border};

  &:hover {
    background-color: ${color.gray1};
  }

  &:focus {
    outline: none;
  }
`;

export const FileTitle = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`;

export const FileSubtitle = styled.div`
  color: ${color.gray3};
`;
