import React from "react";
import { notification } from "antd";
import ConfirmModal from "src/shared/ConfirmModal";
import { DeleteRefund } from "@/types/gql";
import { useDeleteCheckRefund } from "../query";
import { CheckRefundDetails, RefundTypeCheck } from "../util";

type Props = {
  refund: DeleteRefund;
  onClose: () => void;
  refetchOrders?: () => void;
};

const RemoveRefundModal: React.FC<Props> = ({
  refund,
  onClose,
  refetchOrders,
}) => {
  const deleteRefund = useDeleteCheckRefund();

  const onClick = async () => {
    if (!refund) return;

    let refundType;
    if (refund.type === CheckRefundDetails) {
      refundType = RefundTypeCheck;
    } else {
      notification.error({
        message: "There was an error removing this refund",
      });
      return;
    }

    const result = await deleteRefund({
      input: {
        type: refundType,
        id: refund.id,
      },
    });

    if (!result || result.error) {
      notification.error({
        message: "There was an error removing this refund",
      });
      return;
    }
    notification.success({ message: "Refund removed successfully" });

    if (refetchOrders) {
      refetchOrders();
    }

    onClose();
  };

  return (
    <ConfirmModal
      onClose={onClose}
      onConfirm={onClick}
      type="delete"
      title="Are you sure?"
      text="Do you really want to delete this refund? This process cannt be undone."
      actionText="Delete"
    />
  );
};

export default RemoveRefundModal;
