import React, { useMemo } from "react";
import {
  State,
  DisplayType,
  Attribute,
  UIAttribute,
  AttributeType,
  ToothNumber,
  AttributeCategory,
} from "./types";
import { filterAttrsForDisplay } from "./attrsUtil";
import { ToggleAction, RemoveAction, AddAction } from "./state";

import Baby from "./icons/baby.svg";
import Attachment from "./icons/attachment.svg";
import Bracket from "./icons/bracket.svg";
import Bridge from "./icons/bridge.svg";
import ButtonCutout from "./icons/buttonCutout.svg";
import BracketCutout from "./icons/bracketCutout.svg";
import Buildup from "./icons/buildup.svg";
import Carriere from "./icons/carriere.svg";
import Caries from "./icons/caries.svg";
import Chipped from "./icons/chipped.svg";
import Crown from "./icons/crown.svg";
import Disclusion from "./icons/disclusion.svg";
import Extraction from "./icons/extraction.svg";
import GumDisease from "./icons/gumDisease.svg";
import Hook from "./icons/hook.svg";
import Impacted from "./icons/impacted.svg";
import Implant from "./icons/implant.svg";
import Infection from "./icons/infection.svg";
import IPR from "./icons/ipr.svg";
import LingualRetainer from "./icons/lingualRetainer.svg";
import Missing from "./icons/missing.svg";
import Pontic from "./icons/pontic.svg";
import PowerArm from "./icons/powerarm.svg";
import PowerRidge from "./icons/powerRidge.svg";

export const uiAttributes: UIAttribute[] = [
  // NOT shown: 'supernumerary', 'veneer', 'attachment_loss', 'restoration'

  {
    type: "missing",
    displayCategory: "Dental findings",
    displayName: "Missing",
    availableOn: ["original", "cumulative"],
    color: "#A2A9AD",
    icon: <img src={Missing} alt="missing" />,

    rootPriority: 0,
  },
  {
    type: "lingual_retainer",
    displayName: "Lingual retainer",
    displayCategory: "Dental findings",
    availableOn: ["original"],
    locations: ["lingual"],
    color: "#194F5B",
    stroke: "#194F5B",
    icon: <img src={LingualRetainer} alt="missing" />,
    connects: true,
    surfaceDisplay: {
      borderOnly: true,
      entireTooth: false,
      fill: true,
    },
  },
  {
    type: "deciduous",
    displayName: "Baby Tooth",
    displayCategory: "Dental findings",
    availableOn: ["original"],
    availableTeeth: [
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
    ],
    color: "#59B8B8",
    stroke: "#59B8B8",
    icon: <img src={Baby} alt="baby" />,
    surfaceDisplay: {
      borderOnly: true,
      entireTooth: true,
    },
  },
  {
    type: "crown",
    displayName: "Crown",
    displayCategory: "Dental findings",
    availableOn: ["original"],
    stroke: "#E1A733",
    color: "#FCDE9E",
    icon: <img src={Crown} alt="crown" />,
    surfaceDisplay: {
      borderOnly: true,
      entireTooth: true,
    },
    rootPriority: 1,
    crownPriority: 0,
  },
  {
    type: "bridge",
    displayName: "Bridge",
    displayCategory: "Dental findings",
    availableOn: ["original"],
    surfaceDisplay: {
      borderOnly: true,
      entireTooth: true,
    },
    stroke: "#8168BB",
    color: "#E2DCEF",
    icon: <img src={Bridge} alt="bridge" />,
    rootPriority: 2,
    connects: true,
  },
  {
    type: "implant",
    displayName: "Implant",
    displayCategory: "Dental findings",
    availableOn: ["original"],
    color: "#8168BB",
    stroke: "#8168BB",
    icon: <img src={Implant} alt="implant" />,

    rootPriority: 0,
    surfaceDisplay: {
      borderOnly: true,
      entireTooth: true,
    },
  },
  {
    type: "impacted",
    displayName: "Impacted",
    displayCategory: "Dental findings",
    availableOn: ["original"],
    color: "#FC5F22",
    icon: <img src={Impacted} alt="impacted" />,
    surfaceDisplay: {
      borderOnly: true,
      entireTooth: true,
    },
  },
  {
    type: "root_canal",
    displayName: "Root Canal",
    displayCategory: "Dental findings",
    availableOn: ["original"],
    color: "#C270A7",
    stroke: "#C270A7",
    icon: <img src={Infection} alt="root canal" />,
    surfaceDisplay: {
      borderOnly: true,
      entireTooth: true,
    },
  },
  {
    type: "gum_disease",
    displayName: "Gum Disease",
    displayCategory: "Dental findings",
    availableOn: ["original"],
    color: "#A8C7E6",
    stroke: "#5893CE",
    icon: <img src={GumDisease} alt="gum disease" />,
    surfaceDisplay: {
      borderOnly: true,
      entireTooth: true,
    },
  },
  {
    type: "chipped",
    displayName: "Chipped",
    displayCategory: "Dental findings",
    availableOn: ["original"],
    locations: ["lingual", "buccal", "occlusal", "mesial", "distal"],
    color: "#D9EAC8",
    stroke: "#99C76B",
    icon: <img src={Chipped} alt="chipped" />,
    surfaceDisplay: {
      borderOnly: true,
      entireTooth: false,
      fill: true,
    },
  },
  {
    type: "caries",
    displayName: "Caries",
    displayCategory: "Dental findings",
    availableOn: ["original"],
    locations: ["lingual", "buccal", "occlusal", "mesial", "distal"],
    color: "#F5C1C0",
    stroke: "#E24C4B",
    icon: <img src={Caries} alt="caries" />,
    surfaceDisplay: {
      borderOnly: true,
      entireTooth: false,
      fill: true,
    },
  },

  {
    type: "add_attachment",
    displayName: "Add Attachment",
    displayCategory: "Physical procedure",
    availableOn: ["estimated", "prescribed", "performed"],
    locations: ["lingual", "buccal"],
    color: "#5FCB8D",
    icon: <img src={Attachment} alt="add attachment" />,
  },
  {
    type: "remove_attachment",
    displayName: "Remove Attachment",
    displayCategory: "Physical procedure",
    availableOn: ["performed"],
    locations: ["lingual", "buccal"],
    color: "#5FCB8D",
    icon: <img src={Attachment} alt="remove attachment" />,
  },
  {
    type: "add_bracket",
    displayName: "Add Bracket",
    displayCategory: "Physical procedure",
    availableOn: ["estimated", "prescribed", "performed"],
    locations: ["buccal"],
    color: "#FC5F22",
    stroke: "#FC5F22",
    icon: <img src={Bracket} alt="add bracket" />,
    // dropdown: ["018", "022"], // <= these are the bracket sizes
    //  the below are wire gauges, so technically not brackets
    dropdown: [
      {
        heading: "SS series",
        values: ["21 x 25", "16 x 22", "020"],
      },
      {
        heading: "Niti series",
        values: ["19x25", "16x22", "018", "016", "014"],
      },
    ],
    connects: true,
  },
  {
    type: "remove_bracket",
    displayName: "Remove Bracket",
    displayCategory: "Physical procedure",
    availableOn: ["performed"],
    locations: ["buccal"],
    color: "#FC5F22",
    icon: <img src={Bracket} alt="remove bracket" />,
  },
  {
    type: "buildup",
    displayName: "Buildup",
    displayCategory: "Physical procedure",
    availableOn: ["performed", "estimated", "prescribed"],
    locations: ["occlusal"],
    color: "#F7B632",
    icon: <img src={Buildup} alt="buildup" />,
  },
  {
    type: "add_hook",
    displayName: "Add Hook",
    displayCategory: "Physical procedure",
    availableOn: ["estimated", "prescribed", "performed"],
    locations: ["buccal", "lingual"],
    color: "#5893CE",
    icon: <img src={Hook} alt="add hook" />,
  },
  {
    type: "remove_hook",
    displayName: "Remove Hook",
    displayCategory: "Physical procedure",
    availableOn: ["performed"],
    locations: ["buccal", "lingual"],
    color: "#5893CE",
    icon: <img src={Hook} alt="remove hook" />,
  },
  {
    type: "add_carriere",
    displayName: "Add Carriere",
    displayCategory: "Physical procedure",
    availableOn: ["estimated", "prescribed", "performed"],
    locations: ["buccal"],
    color: "#59B8B8",
    stroke: "#59B8B8",
    icon: <img src={Carriere} alt="add carriere" />,
    connects: true,
  },
  {
    type: "remove_carriere",
    displayName: "Remove Carriere",
    displayCategory: "Physical procedure",
    availableOn: ["performed"],
    locations: ["buccal"],
    color: "#59B8B8",
    icon: <img src={Carriere} alt="remove carriere" />,
  },

  {
    type: "ipr",
    displayName: "IPR",
    displayCategory: "Physical procedure",
    availableOn: ["estimated", "prescribed", "performed"],
    locations: ["mesial", "distal"],
    dropdown: [
      "0.05",
      "0.10",
      "0.15",
      "0.20",
      "0.25",
      "0.30",
      "0.40",
      "0.50",
      "0.60",
    ],
    color: "#D7D7D7",
    icon: <img src={IPR} alt="ipr" />,
  },

  {
    type: "add_powerarms",
    displayName: "Add Powerarm",
    displayCategory: "Physical procedure",
    availableOn: ["estimated", "prescribed", "performed"],
    locations: ["buccal"],
    color: "#194F5B",
    icon: <img src={PowerArm} alt="add powerarms" />,
  },
  {
    type: "remove_powerarms",
    displayName: "Remove Powerarm",
    displayCategory: "Physical procedure",
    availableOn: ["performed"],
    locations: ["buccal"],
    color: "#194F5B",
    icon: <img src={PowerArm} alt="remove powerarms" />,
  },

  {
    type: "extraction",
    displayName: "Extraction",
    displayCategory: "Physical procedure",
    availableOn: [],
    // TODO: All/entire surface
    locations: ["buccal", "lingual", "mesial", "distal", "occlusal"],
    color: "#A2A9AD",
    icon: <img src={Extraction} alt="extraction" />,
  },

  {
    type: "bracket_cutouts",
    displayName: "Bracket Cutout",
    displayCategory: "Tray modifier",
    availableOn: ["estimated", "prescribed"],
    locations: ["buccal"],
    color: "#8168BB",
    icon: <img src={BracketCutout} alt="cutout" />,
  },
  {
    type: "tray_cutouts",
    displayName: "Button Cutout",
    displayCategory: "Tray modifier",
    availableOn: ["estimated", "prescribed"],
    locations: ["buccal", "lingual"],
    color: "#8168BB",
    icon: <img src={ButtonCutout} alt="cutout" />,
  },
  // TODO: Tray cutout => button vs bracket cutout
  {
    type: "disclusion",
    displayName: "Disclusion",
    displayCategory: "Tray modifier",
    availableOn: ["estimated", "prescribed"],
    locations: ["occlusal"],
    color: "#99C76B",
    icon: <img src={Disclusion} alt="disclusion" />,
  },
  {
    type: "pontic",
    displayName: "Pontic Shade",
    displayCategory: "Tray modifier",
    availableOn: ["estimated", "prescribed"],
    locations: ["buccal"],
    color: "#72817C",
    icon: <img src={Pontic} alt="pontic" />,
    dropdown: [
      "A1",
      "A2",
      "A3",
      "A3.5",
      "A4",
      "B1",
      "B2",
      "B3",
      "B4",
      "C1",
      "C2",
      "C3",
      "C4",
      "D2",
      "D3",
      "D4",
    ],
  },
  {
    type: "power_ridge",
    displayName: "Power Ridge",
    displayCategory: "Tray modifier",
    availableOn: ["estimated", "prescribed"],
    locations: ["buccal"],
    color: "#C270A7",
    icon: <img src={PowerRidge} alt="power ridge" />,
  },

  // TODO: TrimLine, BracketCutout

  {
    type: "spacing",
    displayName: "spacing",
    displayCategory: "" as AttributeCategory, // not shown
    availableOn: [],
    locations: ["mesial", "distal"],
    color: "#fff",
    icon: null,
  },
];

// uiAttributesForDisplay returns an object keyed by displayCategory to UI attributes
export const uiAttributesForDisplay = (
  t: DisplayType
): { [c in AttributeCategory]: UIAttribute[] } => {
  if (t === "cumulative") {
    // all are visible on the cumulative tooth chart.
    return {
      "Dental findings": uiAttributes.filter(
        a => a.displayCategory === "Dental findings"
      ),
      "Physical procedure": uiAttributes.filter(
        a => a.displayCategory === "Physical procedure"
      ),
      "Tray modifier": uiAttributes.filter(
        a => a.displayCategory === "Tray modifier"
      ),
    };
  }

  return {
    "Dental findings": uiAttributes.filter(
      a => a.availableOn.includes(t) && a.displayCategory === "Dental findings"
    ),
    "Physical procedure": uiAttributes.filter(
      a =>
        a.availableOn.includes(t) && a.displayCategory === "Physical procedure"
    ),
    "Tray modifier": uiAttributes.filter(
      a => a.availableOn.includes(t) && a.displayCategory === "Tray modifier"
    ),
  };
};

export const findUIAttribute = (at: AttributeType): UIAttribute | undefined => {
  return uiAttributes.find(t => t.type === at);
};

type ToothAttributes = {
  cumulative: Attribute[];
  unsaved: Attribute[];
  uiAttributes: UIAttribute[];
};

// useUIAttributes returns UI attributes for each cumulative (saved) and unsaved
// attribute on the tooth chart.
export const useUIAttributes = (s: State) => {
  const { display, cumulativeAttributes, unsavedAttributes } = s;

  return useMemo(() => {
    return {
      cumulative: cumulativeAttributes,
      unsaved: unsavedAttributes,
      uiAttributes: makeUIAttributes({
        unsaved: unsavedAttributes,
        cumulative: cumulativeAttributes,
      }),
    };
    // eslint-disable-next-line
  }, [display, cumulativeAttributes, unsavedAttributes]);
};

// useUIAttributes returns UI attributes for each cumulative (saved) and unsaved
// attribute on the tooth chart **for a given tooth**.
export const useToothAttributes = (
  s: State,
  tn: ToothNumber,
  restrict: boolean
): ToothAttributes => {
  const {
    display,
    cumulativeAttributes,
    unsavedAttributes,
    cumulativeCutoff,
  } = s;

  return useMemo(() => {
    const eqTooth = (it: Attribute) => it.toothNumber === tn;
    const filteredCumulative = filterAttrsForDisplay(
      display,
      restrict,
      cumulativeCutoff || null,
      cumulativeAttributes.filter(eqTooth)
    );
    const filteredUnsaved = filterAttrsForDisplay(
      display,
      restrict,
      cumulativeCutoff || null,
      unsavedAttributes.filter(eqTooth)
    );

    return {
      cumulative: filteredCumulative,
      unsaved: filteredUnsaved,
      uiAttributes: makeUIAttributes({
        unsaved: filteredUnsaved,
        cumulative: filteredCumulative,
      }),
    };
    // eslint-disable-next-line
  }, [display, cumulativeAttributes, unsavedAttributes]);
};

export function makeUIAttributes({
  unsaved,
  cumulative,
}: {
  unsaved: Attribute[];
  cumulative: Attribute[];
}): UIAttribute[] {
  // Unsaved should take precedence over cumulative
  const uiAttributesDeduped: Map<string, UIAttribute> = new Map();

  const makeDedupKey = ({ type, toothNumber, location }: Attribute): string => {
    return `${type}-${toothNumber}-${location}`;
  };

  const makeUIAttr = (attribute: Attribute, saved: boolean): UIAttribute => {
    const attrInfo = { attribute, saved };
    const base = findUIAttribute(attribute.type);
    if (base) {
      return { ...base, ...attrInfo };
    }
    return attrInfo as UIAttribute;
  };

  for (const attribute of unsaved) {
    if (!attribute) {
      continue;
    }

    uiAttributesDeduped.set(
      makeDedupKey(attribute),
      makeUIAttr(attribute, false)
    );
  }

  for (const attribute of cumulative) {
    if (!attribute) {
      continue;
    }

    const dedupeKey = makeDedupKey(attribute);
    if (uiAttributesDeduped.has(dedupeKey)) {
      continue;
    }

    uiAttributesDeduped.set(dedupeKey, makeUIAttr(attribute, true));
  }

  return Array.from(uiAttributesDeduped.values());
}

export const removeAttribute = (s: State, a: RemoveAction): State => {
  const uiAttribute = findUIAttribute(a.attribute);
  if (!uiAttribute) {
    console.warn(`${a.attribute}: ui attribute not found`);
    return s;
  }

  // Does the incoming attribute need a location?
  //
  // If it does, and we only have one location provided in the UI attribute OR
  // the UI attribute is marked as the entire tooth, we can auto-correct to either
  // that location or add to all.
  const { locations } = uiAttribute;
  if (locations && !locations.includes(a.location)) {
    console.warn(`${a.location} is not valid for ${a.attribute}`);
    return s;
  }

  const previous = s.unsavedAttributes.find(e => {
    return (
      a.toothNumber === e.toothNumber &&
      e.type === a.attribute &&
      (locations ? e.location === a.location : true) // only match location if we care
    );
  });
  const without = s.unsavedAttributes.filter(e => e !== previous);

  return { ...s, unsavedAttributes: without };
};

export const addAttribute = (s: State, a: AddAction): State => {
  return addOrToggleAttribute(s, a, false);
};

export const toggleAttribute = (s: State, a: ToggleAction): State => {
  return addOrToggleAttribute(s, a, true);
};

export const addOrToggleAttribute = (
  s: State,
  a: ToggleAction | AddAction,
  toggle: boolean
): State => {
  const uiAttribute = findUIAttribute(a.attribute);
  if (!uiAttribute) {
    console.warn(`${a.attribute}: ui attribute not found`);
    return s;
  }

  // Does the incoming attribute need a location?
  //
  // If it does, and we only have one location provided in the UI attribute OR
  // the UI attribute is marked as the entire tooth, we can auto-correct to either
  // that location or add to all.

  const { locations, availableTeeth } = uiAttribute;

  if (
    availableTeeth &&
    availableTeeth.length > 0 &&
    !availableTeeth.includes(a.toothNumber)
  ) {
    console.warn(
      `${a.toothNumber} is not an allowed tooth number for ${a.attribute}`
    );
    return s;
  }

  // TODO: entire-tooth.  EG. extraction doesn't have a surface and shows on
  // entire totoh.
  if (locations && !locations.includes(a.location)) {
    console.warn(`${a.location} is not valid for ${a.attribute}`);
    return s;
  }

  const previous = s.unsavedAttributes.find(e => {
    return (
      a.toothNumber === e.toothNumber &&
      e.type === a.attribute &&
      (locations ? e.location === a.location : true) // only match location if we care
    );
  });
  const without = s.unsavedAttributes.filter(e => e !== previous);

  // If we have a previous unsaved attribute for this tooth, we need to see if the
  // associated data (eg. spacing, IPR, etc) is the same.  If so, we're removing entirely.
  if (previous && toggle) {
    return { ...s, unsavedAttributes: without };
  }

  // Add the new attribute to 'without', which serves as updating the IPR
  // amount.
  const attr: Attribute = {
    stage: a.attribute === "spacing" ? "spacing" : s.display,
    toothNumber: a.toothNumber,
    type: a.attribute,
    location: locations ? a.location : undefined,
    data: a.data ? a.data : undefined,
  };
  return { ...s, unsavedAttributes: without.concat([attr]) };
};

export const universalMapping = {
  UR8: 1,
  UR7: 2,
  UR6: 3,
  UR5: 4,
  UR4: 5,
  UR3: 6,
  UR2: 7,
  UR1: 8,

  UL1: 9,
  UL2: 10,
  UL3: 11,
  UL4: 12,
  UL5: 13,
  UL6: 14,
  UL7: 15,
  UL8: 16,

  LL8: 17,
  LL7: 18,
  LL6: 19,
  LL5: 20,
  LL4: 21,
  LL3: 22,
  LL2: 23,
  LL1: 24,

  LR1: 25,
  LR2: 26,
  LR3: 27,
  LR4: 28,
  LR5: 29,
  LR6: 30,
  LR7: 31,
  LR8: 32,
};

export const getPalmer = (n: ToothNumber) => {
  return (
    Object.keys(universalMapping).find(
      palmer => universalMapping[palmer] === n
    ) || ""
  );
};

export const isLeft = (n: ToothNumber) => {
  return n >= 9 && n <= 24;
};

export const getName = (n: ToothNumber, isBaby: boolean): string => {
  const palmer = parseInt(getPalmer(n)[2], 10) || 1;

  // Baby teeth change the name from palmer notation to an E-A range, depending
  // on the toot #.
  const babyTeeth = ["A", "B", "C", "D", "E"];
  if (isBaby) {
    return babyTeeth[palmer - 1] || palmer.toString();
  }

  return palmer.toString();
};

// getMesialToothNumber returns the number of the tooth mesial to the given tooth number.
//
// This is annoying and depends on what quadrant you're looking at.  Top right, the mesial
// tooth is the tooth next to it on the "patient left" side (increasing number).  Top left, the
// mesial tooth is enxt to it is on the "patient right" (decreasing).
export const getMesialNumber = (t: ToothNumber): ToothNumber => {
  const lhs = t >= 9 && t <= 24;
  const upper = t <= 16;
  return ((lhs && upper) || (!lhs && !upper) ? t - 1 : t + 1) as ToothNumber;
};

export const getDistalNumber = (t: ToothNumber): ToothNumber => {
  const lhs = t >= 9 && t <= 24;
  const upper = t <= 16;
  return ((lhs && upper) || (!lhs && !upper) ? t + 1 : t - 1) as ToothNumber;
};
