import { QUESTION_FORM } from "../event-type";
import { QUESTION_TYPE } from "../constant";
const { MODIFY_WEAR_SCHEDULE } = QUESTION_TYPE;

const VC_TEMPLATE = {
  doNotPrepopulate: true,
  postTimestamp: null,
  postUserName: null,
  postUserUUID: null,
  complete: false,
  formName: "vc", // this must match the name of the form in config/treatment-forms/index
  type: QUESTION_FORM,
  questionList: [
    {
      displayName: "Doctor Comfort Conclusion",
      type: "select",
      possibleAnswerList: [
        "Dispense Next Stage",
        "Cleared For Final Check Review",
        "Weartime Education/Motivation/Cheerleading Needed",
        "Refinement Needed",
        "Doctor-led Discontinuation",
        "Patient-led Discontinuation",
        "Cleared for Final Check",
      ],
    },
    {
      displayName: "Refer To",
      type: "text",
    },
    {
      displayName: "Refer To Email Address",
      type: "text",
    },
    {
      displayName: "Referral Reason",
      type: "text",
    },
    {
      type: MODIFY_WEAR_SCHEDULE,
    },
    {
      displayName: "Additional Notes",
      type: "text",
    },
  ],
};

export default VC_TEMPLATE;
