import React from "react";
import orderBy from "lodash/orderBy";
import { Patient } from "src/types/gql";
import { ClinicalRecord } from "src/types/api";
import { useSubmissions } from "src/scenes/PatientProfile/Forms/GQLForms/queries";
import { TimelineEntry } from "../util";
import { TimelineFileData } from "../queries";
import { renderNoteContent } from "../notes-content/NotesContent";
import ClinicalPhotos from "./ClinicalPhotos";
import XRayPhotos from "./XRayPhotos";
import DentalFindings from "./DentalFindings";
import GenericForm from "./GenericForm";
import GenericAppointment from "./GenericAppointment";
import TreatmentGoals from "./TreatmentGoals";

export interface Props {
  timelineEntry: TimelineEntry;
  sortedFiles: Array<TimelineFileData>;
  patient: Patient;
  clinicalRecord?: ClinicalRecord;
}

export default function SummaryContent({
  patient,
  clinicalRecord,
  timelineEntry,
  sortedFiles,
}: Props) {
  const [{ data }] = useSubmissions({ userID: patient.id }, false);

  const submissionID = timelineEntry.submission
    ? timelineEntry.submission.id
    : null;

  let appointmentContent: React.ReactElement | undefined;
  if (timelineEntry.appointment) {
    appointmentContent = (
      <GenericAppointment
        appointment={timelineEntry.appointment}
        patient={patient}
        clinicalRecord={clinicalRecord}
      />
    );
  }

  let formContent: React.ReactElement | undefined;
  if (data && submissionID) {
    const submission = data.submissions.find(it => it.id === submissionID);
    const childSubmissions = data.submissions.filter(
      it => it.parentID === submissionID
    );
    if (submission) {
      formContent = (
        <GenericForm submission={submission}>
          {renderNoteContent({ patient, submission, childSubmissions })}
        </GenericForm>
      );
    }
  }

  const sortedSubmissions = data
    ? orderBy(data.submissions, [
        ({ approvedAt, submittedAt, createdAt }) =>
          approvedAt || submittedAt || createdAt,
      ])
    : [];

  // NOTE: Use `key=` selectively ensure stateful content inside children are reset when `timelineEntry` changes.
  const clinicalPhotosContent = (
    <ClinicalPhotos
      key={`${timelineEntry.key}-clinical-photos`}
      patientID={patient.id}
      sortedSubmissions={sortedSubmissions}
      focusedSubmissionID={submissionID}
    />
  );

  const dentalFindingsContent = (
    <DentalFindings
      key={`${timelineEntry.key}-dental-findings`}
      patientID={patient.id}
      sortedSubmissions={sortedSubmissions}
      focusedSubmissionID={submissionID}
    />
  );

  // NOTE: Omit `key=` for X-Rays because the selected photo does not need to reset if `timelineEntry` changes.
  const xrayPhotosContent = <XRayPhotos sortedFiles={sortedFiles} />;

  return (
    <div>
      <div key={`${timelineEntry.key}-general`}>
        {appointmentContent}
        <TreatmentGoals sortedSubmissions={sortedSubmissions} />
        {formContent}
      </div>

      {clinicalPhotosContent}
      {xrayPhotosContent}
      {dentalFindingsContent}
    </div>
  );
}
