import React from "react";
import color from "src/styles/color";
import {
  VSContainer,
  SpecialistHeader,
  SpecialistTitle,
  SummaryLine,
} from "../styles";
import { PreferredProviderIcon } from "../icons";
import ExternalProviderTypePill from "./ExternalProviderTypePill";

const VerifiedSpecialistSummary = ({
  firstName,
  lastName,
  clinicName,
  address,
  type,
  onClick,
  isSelected,
  preferredProvider,
}) => {
  const fullName = `${firstName} ${lastName}`.trim();

  return (
    <VSContainer
      onClick={onClick}
      style={{ backgroundColor: isSelected ? color.gray1 : undefined }}
    >
      <SpecialistHeader>
        <div
          style={{
            fontSize: 16,
            display: "flex",
            alignItems: "center",
            gap: 8,
          }}
        >
          <SpecialistTitle>{fullName || clinicName}</SpecialistTitle>
          {preferredProvider && (
            <div style={{ marginTop: 5 }}>
              <PreferredProviderIcon />
            </div>
          )}
        </div>
        <ExternalProviderTypePill specialistType={type} />
      </SpecialistHeader>

      {fullName && <SummaryLine>{clinicName}</SummaryLine>}
      <SummaryLine>{address.line1}</SummaryLine>
      <SummaryLine>{address.line2}</SummaryLine>
      <SummaryLine>
        {address.city}, {address.state} {address.postalCode}
      </SummaryLine>
    </VSContainer>
  );
};

export default VerifiedSpecialistSummary;
