import React, { useState } from "react";
import { notification } from "antd";
import styled from "react-emotion";

import Box from "src/shared/Box";
import Button, { ButtonRow } from "src/shared/Button";
import Modal from "src/shared/Modal";
import { useRecordPayment } from "../query";
import MoneyInput from "src/shared/MoneyInput";
import { RecordPaymentTypes } from "../util";
import Dropdown from "src/shared/Dropdown";

type Props = {
  onClose: () => void;
  orderID: string;
  refetchOrders?: () => void;
};

const RecordPaymentModal: React.FC<Props> = ({
  orderID,
  onClose,
  refetchOrders,
}) => {
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const [paymentType, setPaymentType] = useState<string>();
  const recordPayment = useRecordPayment();

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!paymentType || !paymentAmount) return;

    const result = await recordPayment({
      input: {
        type: paymentType,
        amount: paymentAmount,
        orderID,
      },
    });

    if (!result || result.error) {
      notification.error({
        message: "Something went wrong recording the payment",
      });
      return;
    }

    notification.success({
      message: "Payment amount successfully recorded",
    });

    if (refetchOrders) {
      refetchOrders();
    }

    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <Box title="Record Payment">
        <Content>
          <InputContainer>
            <Title>Payment Method</Title>
            <Dropdown
              options={Object.values(RecordPaymentTypes)}
              selected={paymentType}
              onSelect={paymentType => {
                paymentType && setPaymentType(paymentType);
              }}
            />
          </InputContainer>
          <InputContainer>
            <Title>Payment Amount</Title>
            <MoneyInput value={paymentAmount} onChange={setPaymentAmount} />
          </InputContainer>
          <ButtonRow position="right">
            <Button kind="default" onClick={onClose}>
              Cancel
            </Button>
            <Button kind="primary" onClick={submit} disabled={!paymentAmount}>
              Save
            </Button>
          </ButtonRow>
        </Content>
      </Box>
    </Modal>
  );
};

export default RecordPaymentModal;

const Content = styled.div`
  padding: 32px;
  display: flex;
  flex-flow: column;
  flex: 1;
  height: 400px;
  width: 600px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 16px;
`;
