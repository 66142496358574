import React, { PureComponent } from "react";
import type { Checkin, StageCheckin } from "types";
import { getThumbnailUrl } from "src/utils/helperFn";
import GalleryPhoto from "src/containers/treatment/components/gallery/gallery-photo";

type Props = {
  checkin: Checkin,
};

export default class StageCheckinView extends PureComponent<Props> {
  render() {
    const { checkin } = this.props;
    const data = (checkin.data: StageCheckin);

    // TODO: This is ripped from gallery.js
    const photos =
      checkin.photos &&
      checkin.photos.map(p => ({
        src: p.file.url,
        thumbnail: getThumbnailUrl(p.file.url),
        caption: "",
      }));

    return (
      <div>
        <p>
          {data.is_healthy ? <b>Healthy </b> : <b>Unhealthy </b>}
          checkin for {checkin.date}.
        </p>
        <p>
          Is the stage fitting correctly?{" "}
          <b>{data.is_fitting_correctly ? "Yes" : "No"}</b>.<br />
          How long was the stage tight? <b>{data.stage_tightness}</b>.<br />
          How does the stage feel now? <b>{data.stage_current_fit}.</b>
        </p>

        <GalleryPhoto images={photos} />
      </div>
    );
  }
}
