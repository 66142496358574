import React, { useState, useEffect } from "react";
import styled, { css } from "react-emotion";
import SelectList from "src/shared/SelectList";
import InputText from "src/shared/InputText";
import Popover from "src/shared/Popover";
import { titleCase } from "src/shared/util";
import { patientStatusNames } from "src/scenes/Tasks/constants";

type Props = {
  value?: string[];
  onClick: (selected?: string) => void;
};

const PatientStatusNamePopover: React.FC<Props> = ({
  children,
  value,
  onClick,
}) => {
  const [filter, setFilter] = useState("");
  const [filtered, setFiltered] = useState<string[]>([] as string[]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const f = filter.toLowerCase();

    const newFiltered = patientStatusNames.filter(psn => {
      return titleCase(psn)
        .toLowerCase()
        .includes(f);
    });

    setFiltered(newFiltered);
  }, [filter]);

  const Content = (
    <Wrapper>
      <InputText
        style={{ width: "100%" }}
        onChange={e => setFilter(e.target.value)}
      />

      <SelectList<string>
        values={filtered}
        value={value}
        onClick={val => onClick(val)}
        render={(psn: string | undefined) => titleCase(psn || "")}
        className={selectCSS}
      />
    </Wrapper>
  );

  return (
    <Popover
      content={Content}
      visible={visible}
      close={() => setVisible(false)}
    >
      <div onClick={() => setVisible(true)}>{children}</div>
    </Popover>
  );
};

export default PatientStatusNamePopover;

const Wrapper = styled.div`
  background: #fff;
  padding: 12px;
`;

const selectCSS = css`
  max-height: 16rem;
`;
