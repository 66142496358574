import React from "react";

type Props = {
  patient: {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    preferredName: string;
    pronouns: string;
  };
};

const Small = ({ patient }: Props) => {
  return (
    <div>
      <span>
        {patient.firstName}{" "}
        {patient.preferredName ? `(${patient.preferredName})` : null}{" "}
        {patient.lastName} {patient.pronouns ? `(${patient.pronouns})` : null}
      </span>
    </div>
  );
};

export default Small;
