import "babel-polyfill";
import React from "react";
import { fromPairs } from "lodash";
import { StoreProvider } from "easy-peasy";
import { Provider } from "react-redux";

import {
  Provider as UrqlProvider,
  createClient,
  dedupExchange,
  cacheExchange,
  fetchExchange,
} from "src/utils/http/gqlQuery";
import logoutExchange from "src/utils/logoutExchange";
import cachedSubsetExchange from "src/utils/cachedSubsetExchange";
import { Router } from "react-router";
import { store } from "./redux/create";
import getRoutes from "./routes";
import hookStore from "./state/hookStore";
import Uploader from "src/shared/Uploader";
import { SubmissionWrapper } from "src/state/submissions/useSubmissions.tsx";
import "src/utils/matomo";
// From old entrypoint
import { browserHistory } from "react-router";
import { pdfjs } from "react-pdf";

browserHistory.listen(location => {
  const uuidRegex = /([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})/i;
  const normalizedPathname = location.pathname.replace(uuidRegex, "[id]");
  if (window._paq) {
    window._paq.push(["setCustomUrl", normalizedPathname]);
    window._paq.push(["trackPageView"]);
  }
});

// https://github.com/wojtekmaj/react-pdf/issues/280#issuecomment-450407812
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

const getAuthToken = () => {
  const { auth_token } = fromPairs(
    document.cookie.split("; ").map(c => {
      const [key, ...v] = c.split("=");
      return [key, v.join("=")];
    })
  );

  return auth_token;
};

// Get the auth token from our cookie
// TODO: This should never happen; our auth cookie should be HTTP only and the
// API should allow us to auth via cookie≥
// const history = syncHistoryWithStore(browserHistory, store);

const urqlClient = createClient({
  exchanges: [
    dedupExchange,
    cacheExchange,
    cachedSubsetExchange,
    logoutExchange,
    fetchExchange,
  ],
  url: `${process.env.REACT_APP_API_HOST}/api/gql`,
  requestPolicy: "cache-and-network",
  fetchOptions: () => {
    const authToken = getAuthToken();

    if (authToken) {
      return {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
    }

    return {
      headers: {},
    };
  },
});

const App = () => (
  <UrqlProvider value={urqlClient}>
    <StoreProvider store={hookStore}>
      <Provider store={store} key="provider">
        <Uploader>
          <SubmissionWrapper>
            <Router history={browserHistory}>{getRoutes(store)}</Router>
          </SubmissionWrapper>
        </Uploader>
      </Provider>
    </StoreProvider>
  </UrqlProvider>
);

export default App;
