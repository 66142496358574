import React from "react";

export default ({ fill = "#c4c4c4" }) => (
  <svg width="10" height="8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.822 6.814a1 1 0 01-1.644 0L.891 2.069A1 1 0 011.713.5h6.574a1 1 0 01.822 1.57L5.822 6.813z"
      fill={fill}
    />
  </svg>
);
