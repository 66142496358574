import { useQuery } from "src/utils/http/gqlQuery";
import { getVerifiedSpecialists } from "src/scenes/EMRAdmin/queries";

export const useGetFilteredVerifiedSpecialists = (
  page,
  searchQuery,
  typeFilter,
  defaultFilterPreferredProviders
) => {
  const [{ fetching, error, data }] = useQuery({
    query: getVerifiedSpecialists,
    variables: {
      page,
      filter: {
        search: searchQuery,
        type: typeFilter === "all" ? null : typeFilter,
        ...(defaultFilterPreferredProviders ? { preferredProvider: true } : {}),
      },
    },
  });
  return [fetching, error, (data && data.verifiedSpecialists) || undefined];
};
