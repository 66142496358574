import React from "react";
import { typeToReadable } from "src/utils/externalProvider";
import Dropdown from "src/shared/Dropdown";

const options = Object.keys(typeToReadable).map(type => ({
  value: type,
  label: typeToReadable[type],
}));

const ExternalProviderTypeSelect = ({
  onChange,
  value,
  hasAllOption = false,
  ...rest
}) => {
  const dropdownOptions = hasAllOption
    ? [
        {
          label: "All Types",
          value: "all",
        },
        ...options,
      ]
    : options;
  return (
    <Dropdown
      width="140px"
      options={dropdownOptions}
      selected={value}
      onSelect={option => onChange(option.value)}
    />
  );
};

export default ExternalProviderTypeSelect;
