import { V, S } from "src/shared-styles";

const col = {
  paddingTop: V.spacingSmall,
  paddingBottom: V.spacingSmall,
  whiteSpace: "normal",
  fontSize: V.fontSmall,
};
export default {
  V,
  S,
  issueList: {
    container: {},
    col,
    headerCol: {
      fontSize: V.fontMed,
    },
  },
  questionList: {
    lastHeaderCol: {
      fontSize: V.fontMed,
      textAlign: "right",
    },
    lastCol: {
      ...col,
      textAlign: "right",
    },
  },
  filterButton: {
    float: "right",
    marginRight: V.spacingSmall,
  },
  filterContainer: {
    width: "100%",
    display: "inline-block",
  },
  comment: {
    sms: {
      position: "absolute",
      right: V.spacing,
    },
    email: {
      position: "absolute",
      right: V.spacingLarge,
    },
  },
  publicComment: {
    whiteSpace: "pre-line",
  },
  privateComment: {
    background: "red",
    whiteSpace: "pre-line",
  },
  appLink: {
    padding: V.spacingTiny,
    border: V.border,
    borderColor: V.colors.secondary,
    borderRadius: V.spacingTiny,
    display: "inline-block",
    marginTop: V.spacing,
    color: V.colors.secondary,
  },
  attachment: {
    color: V.colors.secondary,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "block",
    marginTop: V.spacingSmall,
    fontWeight: V.fontWeightBolder,
  },
};
