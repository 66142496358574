import React from "react";
import styled from "react-emotion";
import color from "src/styles/color";
import TemplateIcon from "./icons/template.svg";
import { ArchType } from "src/types/gql";

const Container = styled.div`
  display: flex;
  flex-flow: column;
  font-weight: bold;
`;

const TrayBuild = styled.div`
  padding-right: 30px;
  font-weight: normal;
  color: ${color.gray3};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 8px;
`;

const Icon = styled.img`
  margin-right: 8px;
`;

const Template = styled.div`
  margin-top: 8px;
  font-weight: normal;
`;

type Props = {
  stage: number;
  archType: ArchType;
  udid?: string;
  hasTemplate?: boolean;
  isWearable?: boolean;
};

const TrayInfo: React.FC<Props> = ({
  stage,
  archType,
  udid,
  hasTemplate,
  isWearable = true,
}) => (
  <Container>
    <div>
      Tray {stage} {archType} {!isWearable && " - Non-wearable"}
    </div>
    <TrayBuild>UDID: {udid}</TrayBuild>
    {hasTemplate && (
      <Template>
        <Icon src={TemplateIcon} alt="Tray" />
        Includes template
      </Template>
    )}
  </Container>
);

export default TrayInfo;
