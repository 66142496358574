import React from "react";
import { CrownProps, RootProps } from "./types";

export const Crown: React.SFC<CrownProps> = ({ fill, stroke, style }) => (
  <svg
    width="32"
    height="21"
    viewBox="0 0 32 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M8.10943 3.79848C7.31406 4.47614 6.56288 4.30672 6.28671 4.13731C4.69595 3.59519 3.30406 4.25025 2.80696 4.64554C-0.341384 7.52558 1.48134 14.8104 2.80696 17.6904C4.13258 20.5704 11.7549 20.0622 12.9148 20.0622C13.8427 20.0622 14.6271 19.6104 14.9032 19.3845C15.3451 18.7634 16.8917 17.7243 19.5429 18.5375C22.1941 19.3507 24.293 18.9892 25.0111 18.7069C29.9821 16.5045 30.8107 10.0668 30.9764 8.37265C31.1421 6.67851 30.4792 2.78198 27.6623 1.42667C24.8454 0.0713611 21.0342 2.27374 20.2057 3.45964C19.5429 4.40836 18.7144 4.08082 18.383 3.79847C17.5876 2.84975 16.6155 2.27375 16.2288 2.10434C13.0805 0.410203 9.10365 2.95141 8.10943 3.79848Z"
      fill={fill || "#fff"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />
  </svg>
);

// Root always contains the portion as if it attached as a Crown.
export const Root: React.SFC<RootProps> = ({
  hasRootCanal,
  style,
  fill,
  stroke,
}) => (
  <svg
    width="31"
    height="47"
    viewBox="0 0 31 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M5.46247 26.3736C6.25059 20.1282 5.43784 10.7062 5 6H14V1H20V6H28.5C26.2013 11.8136 29.2701 15.0787 29.7626 22.8855C30.2552 30.6923 26.1504 38.0008 24.5085 40.3262C22.8666 42.6516 19.5828 44.6448 18.5977 42.4855C17.8096 40.758 19.3639 36.6719 20.2396 34.8448C21.0058 32.7409 22.1442 27.7689 20.568 24.7126C18.5977 20.8922 16.299 27.3702 15.6423 28.5329C14.9855 29.6956 8.74628 42.9838 3.65638 44.977C-0.415535 46.5716 1.08407 40.9906 2.34286 38.0008C3.05435 36.7273 4.67436 32.619 5.46247 26.3736Z"
      fill={fill || "#F1F4F5"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />

    {hasRootCanal && (
      <g transform="translate(2,3.5)">
        <path
          d="M0 42.5C9.5 31 9.16667 10.6667 8.5 2.5L24.5 0C23.02 18.5001 25.5615 29.7273 19.6193 38.4304C19.1584 39.1828 18.622 39.878 18 40.5C18.6 39.825 19.1375 39.136 19.6193 38.4304C24.4932 30.4729 20.9061 16.1243 20.5 14.5C20 12.5 18.9 9.5 16.5 9.5C14.1 9.5 12.8333 11.8333 12.5 13C7.3 26.2 7 34.5 0 42.5Z"
          fill="#C270A7"
        />
      </g>
    )}
  </svg>
);
