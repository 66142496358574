import React from "react";
import styled from "react-emotion";
import { StyledOtherComponent } from "create-emotion-styled";
// local
import StripeProvider from "./StripeProvider";
import Insurance from "./Insurance/Insurance";
import InsuranceEligibilityHistory from "./Insurance/InsuranceEligibilityHistory";
import PaymentDetails from "./PaymentDetails/PaymentDetails";
import Orders from "./Orders/Orders";
import Kits from "./Kits/Kits";
import { Patient } from "src/types/gql";
import color from "src/styles/color";

type Props = {
  patient: Patient;
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 24px;
  align-items: stretch;
  grid-template-areas:
    "payment insurance"
    "orders orders"
    "eligibilityhistory eligibilityhistory"
    "kits .";
`;

type Div = StyledOtherComponent<{}, {}, {}>;

enum PlacerTypes {
  Payment,
  Insurance,
  Orders,
  Kits,
  EligibilityHistory,
}

type PlacerString = keyof typeof PlacerTypes;

const placers: PlacerString[] = [
  "Payment",
  "Insurance",
  "Orders",
  "Kits",
  "EligibilityHistory",
] as PlacerString[];

const Placer: { [name in PlacerString]: Div } = (() => {
  const map = {} as { [name in PlacerString]: Div };
  placers.forEach(
    n =>
      (map[n] = styled.div`
        grid-area: ${n.toLowerCase()};
        background: ${color.white};
        > div {
          height: 100%;
        }
      `)
  );
  return map;
})();

const Billing: React.FC<Props> = props => {
  return (
    <StripeProvider>
      <Grid>
        <Placer.Payment>
          <PaymentDetails patient={props.patient} />
        </Placer.Payment>

        <Placer.Insurance>
          <Insurance patient={props.patient} />
        </Placer.Insurance>

        <Placer.Orders>
          <Orders patient={props.patient} />
        </Placer.Orders>

        <Placer.EligibilityHistory>
          <InsuranceEligibilityHistory patientID={props.patient.id} />
        </Placer.EligibilityHistory>

        <Placer.Kits>
          <Kits patient={props.patient} />
        </Placer.Kits>
      </Grid>
    </StripeProvider>
  );
};

export default Billing;
