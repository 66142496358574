// @flow
import React, { useMemo, PureComponent } from "react";
import Moment from "moment";
import styled from "react-emotion";
import {
  compose,
  lifecycle,
  withProps,
  renderComponent,
  branch,
} from "recompose";
import useSelf from "src/state/self";
import { useQuery } from "src/utils/http/gqlQuery";
import { FormsEnhancer } from "src/state/Forms";
import Loading from "src/shared/Loading";

// type PassedProps = {
//   q: FormQuestion,
//   patient: Patient,
//   submissionID: string,
// };

// type Props = PassedProps & FormProps;

const authorsQuery = `
  query Authors($orgID: ID!) {
    authors: staffUsers(organizationID: $orgID) {
      id
      name
    }
  }
`;

const modifiers = compose(
  FormsEnhancer,
  // TODO: Load the answers for the current question here.
  lifecycle({
    componentDidMount() {
      const { patient, q } = this.props;
      this.props.actions.getPreviousAnswers(q.slug, patient.id);
    },
  }),
  withProps(props => {
    const previousAnswers = Object.values(props.previousAnswers).filter(
      a =>
        a.question_id === props.q.id &&
        a.patient_id === props.patient.id &&
        a.form_submission_id === props.submissionID
    );
    const dedupedAnswers = [];
    let prev;
    for (const entry of previousAnswers) {
      const { answer: ans } = entry;
      if (typeof ans === "string") {
        const ansTrimmed = ans.trim();
        if (ansTrimmed !== prev) {
          dedupedAnswers.push(entry);
        }
        prev = ansTrimmed;
      } else {
        dedupedAnswers.push(entry);
      }
    }

    return {
      previousAnswers: dedupedAnswers

        // Sort all answers so that the most recent is on top.
        .sort(
          (a, b) =>
            new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
        ),
    };
  }),

  branch(props => props.previousAnswers.length === 0, renderComponent(Loading))
);

class PreviousAnswers extends PureComponent {
  render() {
    const { previousAnswers, patient } = this.props;

    // TODO: Turn author IDs into user names.  For this we need to fetch doctor information.
    return (
      <div>
        {previousAnswers.map(a => (
          <ViewAnswer key={a.id} answer={a} patient={patient} />
        ))}
      </div>
    );
  }
}

const Intro = styled("p")`
  opacity: 0.5;
  margin-bottom: 0;

  & + p {
    white-space: pre-line;
  }
`;

// type AnswerProps = {
//   answer: Answer,
//   patient: Patient,
// };

const ViewAnswer = props => {
  const self = useSelf();
  const org = self.organizations.find(o => o.name === "Uniform Teeth");
  const [{ data: authorsData }] = useQuery({
    query: authorsQuery,
    requestPolicy: "cache-first",
    variables: {
      orgID: org ? org.id : "",
    },
  });

  const authorNames = useMemo(() => {
    if (authorsData) {
      return new Map(authorsData.authors.map(({ id, name }) => [id, name]));
    }
    return new Map();
  }, [authorsData]);

  const { answer, patient } = props;
  const authorName = authorNames.get(answer.author_id);
  let authorLine = "";
  if (answer.author_id === patient.id) {
    authorLine = "by the patient";
  } else if (authorName) {
    authorLine = `by ${authorName}`;
  }

  return (
    <div>
      <Intro>
        Answered on {new Moment(answer.created_at).format("MMM Do YYYY, H:mma")}{" "}
        {authorLine}
      </Intro>
      <p>
        {Array.isArray(answer.answer)
          ? answer.answer.join(", ")
          : answer.answer}
      </p>
    </div>
  );
};

export default modifiers(PreviousAnswers);
