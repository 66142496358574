import { css } from "react-emotion";

const textStyles = options => {
  switch (options) {
    case "xxlarge":
      return css`
        font-size: 20px;
        line-height: 1.2;
      `;
    case "xlarge":
      return css`
        font-size: 16px;
        line-height: 1.214285714;
      `;
    case "large":
      return css`
        font-size: 14px;
        line-height: 1.214285714;
      `;
    case "small":
      return css`
        font-size: 10px;
        line-height: 1.2;
      `;
    case "medium":
    default:
      return css`
        font-size: 12px;
        line-height: 1.166666667;
      `;
  }
};

export default textStyles;
