import { useEffect, useMemo } from "react";
import { Action, Thunk, thunk, action } from "easy-peasy";
import { request } from "src/shared/util";
import { Message } from "src/types/api";
import { useActions, useStore } from "../hooks";

type SetData = {
  patientId: string;
  messages: Array<Message>;
};

export interface ChatState {
  data: {
    [userId: string]: Array<Message>;
  };

  setData: Action<ChatState, SetData>;
  addMessage: Action<ChatState, Message>;
  fetch: Thunk<ChatState, string>;
}

export const chat: ChatState = {
  data: {},

  setData: action((state, data: SetData) => {
    // TODO: pagination should fix this.
    //
    // Reset the state so that if we fetch a user's messages twice we don't duplicate.
    state.data[data.patientId] = [];

    data.messages.forEach((m: Message) => {
      state.data[data.patientId].push(m);
    });
  }),

  addMessage: action((state, m: Message) => {
    if (Array.isArray(state.data[m.user_id])) {
      state.data[m.user_id].push(m);
    } else {
      state.data[m.user_id] = [m];
    }
  }),

  fetch: thunk(async (actions, patientId: string) => {
    const result = await request(`/api/v1/patients/${patientId}/chat`);
    if (Array.isArray(result)) {
      actions.setData({ patientId, messages: result as Array<Message> });
    }
  }),
};

export const useAddMessage = () => {
  const addMessage = useActions(actions => {
    return actions.chat.addMessage;
  });
  return (message: Message) => {
    addMessage(message);
  };
};

export const useChatForPatient = (patientId: string) => {
  const fetch = useActions(actions => {
    return actions.chat.fetch;
  });
  const chatData = useStore(state => {
    return state.chat.data;
  });

  useEffect(() => {
    fetch(patientId);
  }, [fetch, patientId]);

  return useMemo(() => {
    const msgs = chatData[patientId] || ([] as Array<Message>);
    return msgs.sort((a, b) => (a.sent_at < b.sent_at ? 1 : -1));
  }, [chatData, patientId]);
};
