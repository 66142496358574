import styled, { css, keyframes } from "react-emotion";

const fade = keyframes`
from, to {
  opacity: 1;
}
50% {
  opacity: 0.5;
}
`;

const fadeAnimation = css`
  animation: ${fade} 1.4s ease-in infinite;
`;

export const Perimeter = styled.div`
  ${({ theme: { width, height, color, margin } }) => css`
    ßwidth: ${width};
    background-color: ${color};
    height: ${height};
    margin: ${margin};
    border-radius: 2px;
  `}
  ${fadeAnimation};
`;

export const Circle = styled.div`
  ${({ theme: { width, color, margin } }) => css`
    width: ${width};
    height: ${width};
    border-radius: ${width};
    background-color: ${color};
    margin: ${margin};
  `}
  ${fadeAnimation};
`;
