import React from "react";
import { Modal as BaseModal } from "antd";
import { findByType, extractChildrenByType } from "src/shared/subcomponents";
import { Title, Content, Footer } from "./styles";

type Props = {
  children: any;
  isOpen: boolean;
  width?: string | number;
  openModal?: () => void;
  closeModal: () => void;
  className?: string;
  style?: any;
};

const LegacyModal = (props: Props) => {
  const title = extractChildrenByType(Title, props.children);

  return (
    <BaseModal
      className={props.className}
      style={props.style}
      title={title}
      visible={props.isOpen}
      onCancel={props.closeModal}
      footer={findByType(Footer, props.children)}
      width={props.width}
    >
      {findByType(Content, props.children)}
    </BaseModal>
  );
};

LegacyModal.Title = Title;
LegacyModal.Content = Content;
LegacyModal.Footer = Footer;

export default LegacyModal;
