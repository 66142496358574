import React, { useState } from "react";
import styled, { css } from "react-emotion";
import color from "src/styles/color";
import Table, { Header, Body } from "src/shared/Table";
import Dropdown from "src/shared/Dropdown";
import textStyles from "src/styles/textStyles";
import Trash from "src/shared/Icons/Trash";
import {
  useAddNewPromoUser,
  useGetPromoUsers,
  useDeletePromoUser,
} from "./useEMRAdminInfo";
import Loading from "src/shared/Loading";
import DeleteModal from "./DeleteModal";
import { PromoUser } from "src/types/gql";
import { notification, Input } from "antd";
import AddPromoUserModal from "./AddPromoUserModal";
import Button from "src/shared/Button";
import { NewPromoUser } from "./queries";

const Perimeter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${color.background};
`;

const Unit = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }
  padding-bottom: 10px;
`;

const StyledButton = styled.div`
  margin-left: 12px;
`;

const Label = styled.div`
  ${textStyles("normal")};
  font-weight: bold;
  margin-right: 16px;
`;

const PickerWrapper = styled.div`
  display: flex;
  width: 200px;
  align-items: center;
`;

const searchInput = css`
  width: 200px;
  margin-right: 12px;
`;

const NUM_COLUMNS = 3;

const headerItemStyle = css`
  width: calc(100% / ${NUM_COLUMNS});
`;

const bodyItemStyle = css`
  width: calc(100% / ${NUM_COLUMNS});
`;

const IconWrapper = styled.div`
  width: 16px;
  margin: 16px 8px;
  cursor: pointer;
`;

const PromoUsers = () => {
  const [fetching, , items] = useGetPromoUsers();
  const [typeFilter, setTypeFilter] = useState("all");
  const deletePromoUser = useDeletePromoUser();
  const [addPromoVisible, setAddPromoVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<PromoUser | undefined>();
  const executeAdd = useAddNewPromoUser();

  const onDelete = async () => {
    if (!openDeleteModal) {
      return;
    }
    const result = await deletePromoUser((itemToDelete as PromoUser).id);
    if (result.error) {
      notification.error({
        message: "There was an error deleting this promo user",
      });
      return;
    }
    notification.success({ message: "Promo user deleted" });
  };

  const addPromoUser = async (addPromoUser: NewPromoUser) => {
    const result = await executeAdd(addPromoUser);

    if (result.error) {
      notification.error({
        message: "There was an error adding the free treatment user",
      });
      return;
    }
    notification.success({ message: "User added" });
  };

  if (fetching) {
    return <Loading />;
  }

  const types = items
    .map(item => item.type)
    .filter((value, index, self) => self.indexOf(value) === index);
  types.push("all");

  let filteredItems =
    typeFilter === "all"
      ? items
      : items.filter(item => item.type === typeFilter);

  if (search !== "") {
    filteredItems = filteredItems.filter(
      p => p.email && p.email.toLowerCase().includes(search.toLowerCase())
    );
  }

  return (
    <Perimeter>
      <Unit>
        <Input
          className={searchInput}
          placeholder="Search by email"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <PickerWrapper>
          <Label>Type: </Label>
          <Dropdown
            options={types}
            selected={typeFilter}
            onSelect={v => {
              setTypeFilter(v);
            }}
          />
        </PickerWrapper>
        <StyledButton>
          <Button kind="primary" onClick={() => setAddPromoVisible(true)}>
            Add new
          </Button>
        </StyledButton>
      </Unit>
      {itemToDelete && openDeleteModal && (
        <DeleteModal
          id={(itemToDelete as PromoUser).id}
          title="Delete promotion user"
          description={`Are you sure you want to delete this user's ${
            (itemToDelete as PromoUser).email
          } free treatment?`}
          onDelete={onDelete}
          onClose={() => {
            setOpenDeleteModal(false);
            setItemToDelete(undefined);
          }}
        />
      )}
      {addPromoVisible && (
        <AddPromoUserModal
          onAdd={addPromoUser}
          setVisible={visible => setAddPromoVisible(visible)}
        />
      )}
      <Table items={filteredItems}>
        <Header>
          <Header.Item css={headerItemStyle}>Email</Header.Item>
          <Header.Item css={headerItemStyle}>Type</Header.Item>
          <Header.Item css={headerItemStyle} />
        </Header>
        <Body>
          {item => (
            <Body.Row key={item.email}>
              <Body.Item css={bodyItemStyle}>{item.email}</Body.Item>
              <Body.Item css={bodyItemStyle}>{item.type}</Body.Item>
              <Body.Item css={bodyItemStyle}>
                <IconWrapper
                  onClick={() => {
                    setItemToDelete(item);
                    setOpenDeleteModal(true);
                  }}
                >
                  <Trash fill={color.red} />
                </IconWrapper>
              </Body.Item>
            </Body.Row>
          )}
        </Body>
      </Table>
    </Perimeter>
  );
};

export default PromoUsers;
