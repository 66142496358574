import React, { useState, useEffect } from "react";
import styled from "react-emotion";
import { includes } from "lodash";
import Cross from "src/shared/Icons/Cross";
import ConfirmModal from "src/shared/ConfirmModal";
import Question, {
  QuestionLabelText,
} from "src/scenes/PatientProfile/Forms/Question";
import TextArea from "src/scenes/PatientProfile/Forms/Question/TextArea";
import Accordion from "src/shared/Accordion";
import Loading from "src/shared/Loading";
import Button from "src/shared/Button";
import {
  Section,
  SectionTitle,
  Row,
  Column,
  Divider,
  Continue,
} from "./styledComponents";
import color from "src/styles/color";
import useFormUI, {
  FormUI,
} from "src/scenes/PatientProfile/Forms/GQLForms/useFormUI";
import { FormDecorationsContext, getFormDecorations } from "../formDecorations";
import { performDeleteSubmission } from "../GQLForms/formActions";
import { questionProps, hardcodedDisplayPredicate } from "./consts";

interface Props {
  ui: FormUI; // BV form UI
  patientID: string;
  next: () => void; // from BV form parent.
}

export function getTreatmentOptionAboutTabSummary(
  ui: FormUI,
  parentUI: FormUI
): string {
  const summary: Array<string> = [];

  const { appointment } = parentUI.submission;
  if (appointment && appointment.doctor) {
    const { questionText } = parentUI.form.questions["doctor_id"];
    summary.push(`${questionText} ${appointment.doctor.name}`);
  }
  if (appointment && appointment.staff.length > 0) {
    const staffNames = appointment.staff.map(it => it.name);
    const { questionText } = parentUI.form.questions["staff_id"];
    summary.push(`${questionText} ${staffNames.join(", ")}`);
  }

  const concerns: Array<string> = [];
  const patientConcernsAnswer = parentUI.getAnswerValue("patient_concerns");
  if (patientConcernsAnswer) {
    const { questionText } = parentUI.form.questions["patient_concerns"];
    concerns.push(`${questionText}\n${patientConcernsAnswer}`);
  }
  const concernsCategoryAnswer = parentUI.getAnswerValue(
    "what_would_you_like_to_change"
  );
  if (concernsCategoryAnswer && concernsCategoryAnswer.length > 0) {
    const { questionText } = parentUI.form.questions[
      "what_would_you_like_to_change"
    ];
    concerns.push(`${questionText}\n${concernsCategoryAnswer.join(", ")}`);
  }
  if (concerns.length > 0) {
    summary.push("== Patient concerns ==");
    summary.push(concerns.join("\n\n"));
  }

  const dentalFindings: Array<string> = [];
  const sofAnswer = parentUI.getAnswerValue("additional_sof_findings");
  if (sofAnswer) {
    const { questionText } = parentUI.form.questions["additional_sof_findings"];
    dentalFindings.push(`${questionText}\n${sofAnswer}`);
  }
  if (dentalFindings.length > 0) {
    summary.push("== Dental findings ==");
    summary.push(dentalFindings.join("\n\n"));
  }

  const preTx: Array<string> = [];
  const nextSteps = parentUI.getAnswerValue("generated_next_steps");
  if (nextSteps) {
    const { questionText } = parentUI.form.questions["generated_next_steps"];
    preTx.push(`${questionText}\n${nextSteps}`);
  }
  if (preTx.length > 0) {
    summary.push("== Pre-tx tasks ==");
    summary.push(preTx.join("\n\n"));
  }

  const discussed: Array<string> = [];
  const YES = /^yes$/i;
  [
    "treatment_option_discussed_attachments",
    "treatment_option_discussed_ipr",
    "treatment_option_discussed_auxiliaries",
    "treatment_option_discussed_referrals",
    "treatment_option_patient_consent",
  ].forEach(questionSlug => {
    const answer = ui.getAnswerValue(questionSlug);
    if (!YES.test(answer)) {
      return;
    }
    const { questionText } = ui.form.questions[questionSlug];
    discussed.push(`- ${questionText}: Yes`);
  });

  if (discussed.length > 0) {
    summary.push("== Discussed with patient ==");
    summary.push(discussed.join("\n"));
  }

  const optionSummary = ui.getAnswerValue("treatment_option_summary");
  if (optionSummary) {
    summary.push("== Additional notes ==");
    summary.push(optionSummary);
  }

  return summary.join("\n\n").replace(/\n\n\n+/g, "\n\n");
}

// First, we need to find all treatment option submissions for this patient.  We'll then narrow
// this down to a list of all submissions with the BV as the parent.
//
// props.ui is the beginning visit forms UI context.
const OptionsSelector = (props: Props) => {
  // activeSubmissionID stores which treatment option is currently active.
  const [activeSubmissionID, setActiveSubmissionID] = useState<string | null>(
    null
  );
  const [isAsync, setIsAsync] = useState(false); // Whether we're saving or deleting
  const [deleteID, setDeleteID] = useState<string | undefined>();

  const { ui: parentUI, patientID } = props;

  // Create a new UI object for manipulating the active treatment option form.
  const parentSubmissionID = parentUI.submission.id;
  const optionUI = useFormUI({
    slug: "treatment_option",
    userID: patientID,
    submissionID: activeSubmissionID,
    parentSubmissionID,
  });

  const options = optionUI ? optionUI.submissions : [];

  // Ensure that we select the first option as active.
  useEffect(() => {
    if (!activeSubmissionID && options.length > 0) {
      // TODO: We should go through each option and find which one was marked as "active" via the
      // is active question.
      setActiveSubmissionID(options[0].id);
    }
    if (!options.find(s => s.id === activeSubmissionID) && options.length > 0) {
      // When a given option is deleted, set the ID of the first option here.
      setActiveSubmissionID(options[0].id);
    }
    // eslint-disable-next-line
  }, [options, activeSubmissionID]);

  if (!optionUI) {
    return <Loading />;
  }

  // newSubmission creates a new tx option, preventing any other asnc button from being hit
  const newSubmission = async () => {
    if (isAsync) {
      return;
    }
    setIsAsync(true);
    const result = await optionUI.createSubmission(
      patientID,
      parentSubmissionID
    );
    setIsAsync(false);

    result.data && setActiveSubmissionID(result.data.createSubmission.id);
  };

  const cloneSubmission = async () => {
    if (isAsync) {
      return;
    }
    if (!activeSubmissionID) {
      return;
    }
    setIsAsync(true);
    const result = await optionUI.cloneSubmission(activeSubmissionID);
    setIsAsync(false);

    result.data && setActiveSubmissionID(result.data.cloneSubmission.id);
  };

  // onDelete deletes the form submission, preventing any other asnc button from being hit
  const onDelete = async (id: string) => {
    if (isAsync) {
      return;
    }
    setIsAsync(true);
    await performDeleteSubmission(id, optionUI.deleteSubmission);
    setIsAsync(false);
  };

  return (
    <div>
      <Section>
        <div style={{ display: "flex" }}>
          {options.map((submission, index) => (
            <Button
              key={index}
              kind={
                submission.id === activeSubmissionID
                  ? "primary"
                  : "secondaryInvert"
              }
              onClick={() => setActiveSubmissionID(submission.id)}
            >
              Option {index + 1}
              <div
                style={{ marginLeft: 10 }}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setDeleteID(submission.id);
                }}
              >
                <Cross
                  size={13}
                  fill={
                    submission.id === activeSubmissionID ? "#fff" : color.gray3
                  }
                />
              </div>
            </Button>
          ))}
          {options.length > 0 && (
            <>
              <Line />
              <Button onClick={cloneSubmission}>Clone +</Button>
            </>
          )}
          <Button onClick={newSubmission}>New blank +</Button>
        </div>
      </Section>

      {optionUI && optionUI.submission && activeSubmissionID && (
        <TreatmentOptionSubmission
          ui={optionUI}
          parentUI={parentUI}
          getTooltip={(slug: string) => {
            const answer = parentUI.getAnswerValue(slug);
            return answer && answer.toString();
          }}
        />
      )}

      <Continue next={props.next} />

      {deleteID && (
        <ConfirmModal
          type="delete"
          text={
            "Do you really want to delete option " +
            (options.findIndex(s => s.id === deleteID) + 1) +
            "?"
          }
          onClose={() => setDeleteID(undefined)}
          onConfirm={async () => {
            await onDelete(deleteID);
            setDeleteID(undefined);
          }}
        />
      )}
    </div>
  );
};

export default OptionsSelector;

type TreatmentOptionSubmissionProps = {
  ui: FormUI; // props for specific treatment option form, not the BV.
  parentUI: FormUI; // ui for parent, ie: BV
  getTooltip: (slug: string) => string | undefined;
};

const TreatmentOptionSubmission = (props: TreatmentOptionSubmissionProps) => {
  const { ui, parentUI, getTooltip } = props;
  const generatedSummaryForAboutTab = getTreatmentOptionAboutTabSummary(
    ui,
    parentUI
  );

  return (
    <FormDecorationsContext.Provider
      value={getFormDecorations("treatment_option")}
    >
      <div>
        <Section>
          <SectionTitle>Extraoral</SectionTitle>
          <Row>
            <Column>
              <Question {...questionProps(ui, "facial_imbalances_rx")} />
            </Column>
            <Column>
              <Accordion
                isOpen={hardcodedDisplayPredicate(
                  ui,
                  ["facial_imbalances_rx"],
                  ["Improve"]
                )}
              >
                <Question
                  {...questionProps(ui, "extraoral_treatment_mechanics")}
                />
              </Accordion>
            </Column>
          </Row>
        </Section>

        <Divider />

        <Section>
          <SectionTitle>Intraoral</SectionTitle>
          <Row>
            <Column>
              {[
                "right_molar_classification_rx",
                "right_canine_classification_rx",
                "left_molar_classification_rx",
                "left_canine_classification_rx",
              ].map(slug => (
                <Question
                  key={slug}
                  {...questionProps(ui, slug)}
                  tooltip={getTooltip(slug)}
                />
              ))}
            </Column>
            <Column>
              <Accordion
                isOpen={hardcodedDisplayPredicate(
                  ui,
                  [
                    "right_molar_classification_rx",
                    "right_canine_classification_rx",
                    "left_molar_classification_rx",
                    "left_canine_classification_rx",
                  ],
                  ["Improve", "Improve to class I"]
                )}
              >
                <Question
                  {...questionProps(
                    ui,
                    "anterior_posterior_occlusion_mechanics"
                  )}
                />
              </Accordion>
            </Column>
          </Row>
          <Row>
            <Column>
              <Question
                {...questionProps(ui, "overjet_rx")}
                tooltip={getTooltip("overjet_rx")}
              />
            </Column>
            <Column>
              <Accordion
                isOpen={hardcodedDisplayPredicate(
                  ui,
                  ["overjet_rx"],
                  ["Reduce", "Increase", "Improve"]
                )}
              >
                <Question
                  {...questionProps(ui, "overjet_treatment_mechanics")}
                />
              </Accordion>
            </Column>
          </Row>
          <Row>
            <Column>
              <Question
                {...questionProps(ui, "vertical_bite_rx")}
                tooltip={getTooltip("vertical_bite_rx")}
              />
            </Column>
            <Column>
              <Accordion
                isOpen={hardcodedDisplayPredicate(
                  ui,
                  ["vertical_bite_rx"],
                  ["Reduce", "Increase", "Improve"]
                )}
              >
                <Question
                  {...questionProps(ui, "vertical_bite_rx_mechanics")}
                />
              </Accordion>
            </Column>
          </Row>

          <Question {...questionProps(ui, "transverse_rx_label")} />

          <Row>
            <Column>
              <Question
                {...questionProps(ui, "crossbite_treatment_option_rx")}
                tooltip={getTooltip("crossbite_treatment_option_rx")}
              />
            </Column>
            <Column>
              <Accordion
                isOpen={hardcodedDisplayPredicate(
                  ui,
                  ["crossbite_treatment_option_rx"],
                  ["Improve"]
                )}
              >
                <Question
                  {...questionProps(ui, "crossbite_treatment_mechanics")}
                />
              </Accordion>
            </Column>
          </Row>

          <Question
            {...questionProps(ui, "midlines_rx")}
            tooltip={getTooltip("midlines_rx")}
          />
          <Question {...questionProps(ui, "curve_of_wilson_rx")} />
          <Question {...questionProps(ui, "crowding_spacing_rx_label")} />

          <Row>
            <Column>
              <Question {...questionProps(ui, "upper_ald_rx")} />
            </Column>
            <Column>
              <Accordion
                isOpen={hardcodedDisplayPredicate(
                  ui,
                  ["upper_ald_rx"],
                  ["Improve"]
                )}
              >
                <Question
                  {...questionProps(ui, "upper_ald_treatment_mechanics")}
                />
                <Question
                  {...questionProps(
                    ui,
                    "upper_ald_treatment_mechanics_teeth_picker"
                  )}
                />
              </Accordion>
            </Column>
          </Row>

          <Row>
            <Column>
              <Question {...questionProps(ui, "lower_ald_rx")} />
            </Column>
            <Column>
              <Accordion
                isOpen={hardcodedDisplayPredicate(
                  ui,
                  ["lower_ald_rx"],
                  ["Improve"]
                )}
              >
                <Question
                  {...questionProps(ui, "lower_ald_treatment_mechanics")}
                />
                <Question
                  {...questionProps(
                    ui,
                    "lower_ald_treatment_mechanics_teeth_picker"
                  )}
                />
              </Accordion>
            </Column>
          </Row>

          <Accordion
            isOpen={
              (hardcodedDisplayPredicate(ui, ["upper_ald_rx"], ["Improve"]) &&
                includes(
                  ui.getAnswerValue("upper_ald_treatment_mechanics"),
                  "IPR"
                )) ||
              (hardcodedDisplayPredicate(ui, ["lower_ald_rx"], ["Improve"]) &&
                includes(
                  ui.getAnswerValue("lower_ald_treatment_mechanics"),
                  "IPR"
                )) ||
              (hardcodedDisplayPredicate(
                ui,
                ["overjet_rx"],
                ["Improve", "Increase", "Reduce"]
              ) &&
                includes(
                  ui.getAnswerValue("overjet_treatment_mechanics"),
                  "IPR"
                ))
            }
          >
            <Row style={{ background: "#F9F9F9" }}>
              <Column>
                <Question {...questionProps(ui, "upper_ipr_rx_label")} />
                <Question {...questionProps(ui, "upper_ipr_amount_rx")} />
                <Question
                  {...questionProps(ui, "upper_ipr_estimated_locations_rx")}
                />
              </Column>
              <Column>
                <Question {...questionProps(ui, "lower_ipr_rx_label")} />
                <Question {...questionProps(ui, "lower_ipr_amount_rx")} />
                <Question
                  {...questionProps(ui, "lower_ipr_estimated_locations_rx")}
                />
              </Column>
            </Row>
          </Accordion>

          <Row>
            <Column>
              <Question {...questionProps(ui, "are_attachments_required_rx")} />
            </Column>
            <Column>
              <Accordion
                isOpen={hardcodedDisplayPredicate(
                  ui,
                  ["are_attachments_required_rx"],
                  ["Yes"]
                )}
              >
                <Question {...questionProps(ui, "attachments_rx")} />
              </Accordion>
            </Column>
          </Row>

          {/* TODO: Tooth chart right here */}

          <Row>
            <Column>
              <Question {...questionProps(ui, "uneven_wear")} />
            </Column>
            <Column>
              <Accordion
                isOpen={hardcodedDisplayPredicate(ui, ["uneven_wear"], ["Yes"])}
              >
                <Question {...questionProps(ui, "uneven_wear_mechanics")} />
              </Accordion>
            </Column>
          </Row>

          <Row>
            <Column>
              <Question {...questionProps(ui, "teeth_variation")} />
            </Column>
            <Column>
              <Accordion
                isOpen={hardcodedDisplayPredicate(
                  ui,
                  ["teeth_variation"],
                  ["Yes"]
                )}
              >
                <Question {...questionProps(ui, "teeth_variation_mechanics")} />
              </Accordion>
            </Column>
          </Row>

          <Question {...questionProps(ui, "auxilaries_rx")} />
          <Row>
            <Column>
              <Question {...questionProps(ui, "spaces_rx")} />
            </Column>
            <Column>
              <Accordion
                isOpen={hardcodedDisplayPredicate(ui, ["spaces_rx"], ["Yes"])}
              >
                <Question
                  {...questionProps(ui, "teeth_to_maintail_space_for")}
                />
              </Accordion>
            </Column>
          </Row>

          <Row>
            <Column>
              <Question {...questionProps(ui, "is_pontic_required_rx")} />
            </Column>
            <Column>
              <Accordion
                isOpen={hardcodedDisplayPredicate(
                  ui,
                  ["is_pontic_required_rx"],
                  ["Yes"]
                )}
              >
                <Question {...questionProps(ui, "pontic_teeth_picker")} />
                <Question {...questionProps(ui, "pontic_share_color_rx")} />
              </Accordion>
            </Column>
          </Row>

          <Row>
            <Column>
              <Question
                {...questionProps(ui, "teeth_that_should_not_be_moved_rx")}
              />
            </Column>
            <Column>
              <Accordion
                isOpen={hardcodedDisplayPredicate(
                  ui,
                  ["teeth_that_should_not_be_moved_rx"],
                  ["Yes"]
                )}
              >
                <Question
                  {...questionProps(
                    ui,
                    "teeth_that_should_not_be_moved_picker"
                  )}
                />
              </Accordion>
            </Column>
          </Row>

          <Question {...questionProps(ui, "arch_to_treat_rx")} />

          <Row>
            <Column>
              <Question {...questionProps(ui, "potential_tissue_windows")} />
            </Column>
            <Column>
              <Accordion
                isOpen={hardcodedDisplayPredicate(
                  ui,
                  ["potential_tissue_windows"],
                  ["Yes"]
                )}
              >
                <Question {...questionProps(ui, "tissue_windows_mechanics")} />
              </Accordion>
            </Column>
          </Row>

          {[
            "treatment_type",
            "rx_estimated_length_option_lower_bound",
            "rx_estimated_length_option_upper_bound",
            "is_chosen_option",
          ].map(slug => {
            const params = questionProps(ui, slug);
            if (
              slug === "rx_estimated_length_option_lower_bound" ||
              slug === "rx_estimated_length_option_upper_bound"
            ) {
              const answer = ui.getAnswerValue(slug);
              const choices = params.question && params.question.choices;
              if (params.question && choices) {
                const treatmentType = ui.getAnswerValue("treatment_type");
                let min = choices[0];
                let max = choices[choices.length - 1];
                if (treatmentType === "level_1") {
                  min = 1;
                  max = 6;
                } else if (treatmentType === "level_2") {
                  min = 6;
                  max = 10;
                } else if (treatmentType === "level_3") {
                  min = 11;
                  max = 18;
                } else if (treatmentType === "level_4") {
                  min = 11;
                  max = 24;
                }
                params.question.choices = choices.filter(value => {
                  return value === answer || (value >= min && value <= max);
                });
              }
            }
            return <Question key={slug} {...params} />;
          })}

          <Row>
            <Column>
              <Question {...questionProps(ui, "eights_included")} />
            </Column>
            <Column>
              <Accordion
                isOpen={hardcodedDisplayPredicate(
                  ui,
                  ["eights_included"],
                  ["Yes"]
                )}
              >
                <Question {...questionProps(ui, "eights_specifics")} />
              </Accordion>
            </Column>
          </Row>

          <Question {...questionProps(ui, "tray_height_considerations")} />
        </Section>
        <Divider />

        <Section>
          <SectionTitle>Discussed with patient:</SectionTitle>
          {[
            "treatment_option_discussed_attachments",
            "treatment_option_discussed_ipr",
            "treatment_option_discussed_auxiliaries",
            "treatment_option_discussed_referrals",
            "treatment_option_patient_consent",
          ].map(slug => (
            <Question key="slug" {...questionProps(ui, slug)} />
          ))}
        </Section>
        <Divider />

        <Section>
          <SectionTitle>Summary and notes</SectionTitle>

          <div key="generated_about_tab_summary" style={{ marginBottom: 8 }}>
            <QuestionLabelText>
              Generated summary: for About Tab Notes
            </QuestionLabelText>
            <TextArea
              disabled
              value={generatedSummaryForAboutTab}
              style={{ maxWidth: "600px" }}
            />
          </div>

          {[
            "treatment_option_summary",
            "treatment_goals_generated_summary",
            "treatment_option_public_notes",
          ].map(slug => (
            <Question key="slug" {...questionProps(ui, slug)} />
          ))}
        </Section>
      </div>
    </FormDecorationsContext.Provider>
  );
};

const Line = styled.div`
  width: 1px;
  height: 38px;
  background: #ddd;
  margin: 0 20px;
`;
