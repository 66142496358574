import styled from "react-emotion";
import color from "src/styles/color";

export const Perimeter = styled.div`
  padding-top: 16px;
  padding-left: 188px;
  margin-bottom: 8px;
`;

export const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

export const Col = styled.div`
  display: flex;
  font-size: 12px;
  padding-right: 16px;
  &:first-child {
    padding-left: 0;
  }
  margin-top: 5px;
`;

export const Label = styled.div`
  color: #59b8b8;
  font-weight: bold;
  margin-right: 4px;
`;

export const Value = styled.div``;

export const Line = styled.div`
  border-left: 1px solid ${color.gray5};
  height: 12px;
  padding-right: 16px;
  margin-top: 5px;
`;
