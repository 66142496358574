import { isNil } from "lodash";
import { InsuranceClaimsSubmission, Charge, Order } from "src/types/gql";

const INSURANCE_PAYMENT_TYPES = [
  "insurance_card",
  "insurance_check",
  "insurance_eft",
];

export const claimAmount = (c: InsuranceClaimsSubmission): number =>
  // @ts-ignore doesnt recognize null check
  isNil(c.expectedPayoutAmount) ? c.submittedAmount : c.expectedPayoutAmount;

export const getInsuranceCharges = (order: Order): Array<Charge> => {
  return order.charges.filter(charge =>
    INSURANCE_PAYMENT_TYPES.includes(charge.paymentMethod.type)
  );
};

export const getNonInsuranceCharges = (order: Order): Array<Charge> => {
  return order.charges.filter(
    charge => !INSURANCE_PAYMENT_TYPES.includes(charge.paymentMethod.type)
  );
};

export const claimTitle = (c: InsuranceClaimsSubmission) => {
  switch (c.status) {
    case "submitted":
      return "Expected insurance";
    case "unsubmitted":
      return "Unsubmitted insurance";
    case "approved":
      return "Approved insurance";
  }

  return "Insurance claim";
};
