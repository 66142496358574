// @flow
import React, { useState, useEffect, useCallback } from "react";
import styled from "react-emotion";
import { FormsEnhancer } from "src/state/Forms";
import { Button, message } from "antd";
import ViewSubmission from "src/scenes/Form/Submission";
import { Separator } from "../Shared";

// type Props = FormProps & {
//   patient: Patient,
//   beginningVisit: Submission
// };

const Title = styled("p")`
  font-weight: bold;
  margin: 1rem 0;
`;

const Goals = props => {
  const isSubmitted = props.beginningVisit.submitted_at;

  // We need to find the treatment option form to render each of
  // the goals/options.
  const treatmentOptionForm = Object.values(props.forms).find(f => {
    return f.slug === "treatment_option";
  });

  const goals = props.submissions.filter(
    s =>
      s.parent_id === props.beginningVisit.id &&
      s.form_id === treatmentOptionForm.id
  );

  // This records whether we're adding a submission so that we can disable the "Add"
  // button while waiting for our response
  const [adding, setAdding] = useState(false);

  const addTreatmentOptionSubmission = useCallback(async () => {
    if (adding) {
      return;
    }
    const hide = message.loading("Adding a treatment option...");
    setAdding(true);
    await props.actions.newSubmission({
      formId: "treatment_option",
      patientId: props.patient.id,
      parentId: props.beginningVisit.id,
    });
    setAdding(false);
    hide();
  }, [adding, props.actions, props.beginningVisit.id, props.patient.id]);

  useEffect(() => {
    if (goals.length === 0) {
      addTreatmentOptionSubmission();
    }
  }, [goals, addTreatmentOptionSubmission]);

  return (
    <div>
      <Button
        disabled={adding || isSubmitted}
        onClick={async () => await addTreatmentOptionSubmission()}
      >
        Add a treatment option
      </Button>

      {goals.reverse().map((g, n) => {
        return (
          <>
            <Title>Option {n + 1}</Title>
            <ViewSubmission
              form={treatmentOptionForm}
              submission={g}
              patient={props.patient}
            />
            <Separator />
          </>
        );
      })}
    </div>
  );
};

export default FormsEnhancer(Goals);
