import React from "react";
import { sum } from "lodash";
import { notification } from "antd";
import { DateTime } from "luxon";
import styled, { css } from "react-emotion";

import color from "src/styles/color";
import money from "src/utils/money";
import autoDownload from "src/utils/download";
import { getCardIcon } from "src/utils/paymentMethod";

import { getNonInsuranceCharges } from "src/utils/insurance";
import {
  Charge,
  Refund,
  PaymentPlan,
  PaymentMethod,
  Patient,
} from "src/types/gql";
import AffirmLogo from "./OrderSettings/icons/affirm.png";
import CareCreditFull from "./carecredit_full_logo.png";

import {
  Container,
  SectionTitle,
  OrderGrid,
  OrderGridTitle,
  Line,
} from "./OrderSummary";

import { RecordPaymentTypes } from "./util";

const NoChargeWrapper = styled.div`
  padding: 30px;
  text-align: center;
  background: ${color.background2};
`;

const RefundButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  font-weight: 600;
  color: ${color.orange};

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const affirm = css`
  height: 20px;
`;

const UpcomingChargeRow = styled(OrderGrid)`
  opacity: 30%;
  min-height: 50px;
`;

const CardItem = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  > svg {
    height: 14px;
    width: auto;
    margin: 0 10px 0 0;
  }
`;

const NoCharges = () => <NoChargeWrapper>No charges</NoChargeWrapper>;

type ChargeProps = {
  patient: Patient;
  charge: Charge;
  refunds: Refund[];
  onRefund: () => void;
  onEditCareCredit: () => void;
};

const ChargeItem: React.FC<ChargeProps> = ({
  charge,
  refunds,
  onRefund,
  onEditCareCredit,
  patient,
}) => {
  const onReceipt = () => {
    try {
      autoDownload(
        `api/v2/charges/${charge.id}/receipt`,
        "",
        `${patient.firstName}${patient.lastName}_charge_receipt.pdf`
      );
    } catch (_) {
      notification.error({ message: "Error downloading charge receipt" });
    }
  };

  const refundSum = refunds.reduce(
    (sum: number, r: Refund) => (sum += r.amount),
    0
  );
  return (
    <>
      <OrderGrid>
        <div>
          <ChargeReceiptButton type="button" onClick={onReceipt}>
            {DateTime.fromISO(charge.createdAt).toFormat("LLLL d, yyyy")}
          </ChargeReceiptButton>
        </div>
        <div>Completed {/*Status is always completed */}</div>
        <div>{chargeMethod(charge.paymentMethod)}</div>
        <div>
          {refunds.length > 0 ? (
            <span>
              <s>{money(charge.amount)}</s> {money(charge.amount - refundSum)}
            </span>
          ) : (
            money(charge.amount)
          )}
        </div>
        <div>
          {charge.paymentMethod.type !== "care_credit" && (
            <RefundButton onClick={onRefund}>Refund</RefundButton>
          )}
          {charge.paymentMethod.type === "care_credit" && (
            <RefundButton onClick={onEditCareCredit}>Edit</RefundButton>
          )}
        </div>
      </OrderGrid>
      {refunds.map(r => (
        <OrderGrid>
          <div>{DateTime.fromISO(r.createdAt).toFormat("LLLL d, yyyy")}</div>
          <div />
          <div />
          <div>Refunded: {money(r.amount)}</div>
          <div />
        </OrderGrid>
      ))}
    </>
  );
};

type UpcomingChargeProps = {
  paymentPlan: PaymentPlan;
};

const UpcomingCharge: React.FC<UpcomingChargeProps> = ({ paymentPlan }) => (
  <UpcomingChargeRow>
    <div>
      {paymentPlan.nextPaymentAt &&
        DateTime.fromISO(paymentPlan.nextPaymentAt).toFormat("LLLL d, yyyy")}
    </div>
    <div>Next charge</div>
    <div>{chargeMethod(paymentPlan.paymentMethod)}</div>
    <div>{money(paymentPlan.financingProduct.monthlyAmount)}</div>
    <div />
  </UpcomingChargeRow>
);

const ChargeReceiptButton = styled.button`
  color: ${color.blue};
  background: none;
  border: none;
  padding: 0;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const PatientCharges = ({ patient, order, paymentPlan, setModal }) => {
  const patientCharges = getNonInsuranceCharges(order);
  const refunds = sum(order.refunds.map(refund => refund.amount));
  return (
    <Container>
      <SectionTitle>Patient Payment breakdown</SectionTitle>
      <OrderGridTitle>
        <div>Date</div>
        <div>Status</div>
        <div>Method</div>
        <div>Amount</div>
      </OrderGridTitle>
      {patientCharges.length === 0 && <NoCharges />}
      {patientCharges.map(c => (
        <ChargeItem
          patient={patient}
          key={c.id}
          charge={c}
          refunds={order.refunds.filter(r => r.chargeId === c.id)}
          onRefund={() => {
            if (
              Object.values(RecordPaymentTypes).includes(c.paymentMethod.type)
            ) {
              setModal({ type: "deletePaymentRecord", charge: c });
              return;
            }
            setModal({ type: "refund", charge: c });
          }}
          onEditCareCredit={() =>
            setModal({ type: "editCareCredit", charge: c })
          }
        />
      ))}
      {paymentPlan && paymentPlan.nextPaymentAt && (
        <UpcomingCharge paymentPlan={paymentPlan} />
      )}
      <Line />
      <OrderGrid>
        <div />
        <div />
        <div>
          <b>Total Amount Paid by Patient</b>
        </div>
        <div>
          {money(sum(patientCharges.map(charge => charge.amount)) - refunds)}
        </div>
      </OrderGrid>
    </Container>
  );
};

const methodTypeToReadable = {
  insurance_check: "Insurance check",
  insurance_card: "Insurance card",
  insurance_eft: "Insurance EFT",
  walnut: "Walnut",
  cherry: "Cherry",
  prima_health: "PrimaHealth",
  split_it: "Split it",
  patient_fi: "PatientFi",
  choice_pays: "ChoicePays",
  i_credit: "iCredit",
};

const chargeMethod = (paymentMethod: PaymentMethod): React.ReactNode => {
  if (paymentMethod.type === "affirm") {
    return <img className={affirm} src={AffirmLogo} alt="Affirm" />;
  }
  if (paymentMethod.type === "card" && paymentMethod.cardDetails) {
    return (
      <CardItem>
        {getCardIcon(paymentMethod.cardDetails.brand)}
        {paymentMethod.cardDetails.lastFour}
      </CardItem>
    );
  }
  if (paymentMethod.type === "care_credit") {
    return (
      <img
        style={{ width: "120px" }}
        src={CareCreditFull}
        alt="Care credit logo"
      />
    );
  }
  return (
    <div>{methodTypeToReadable[paymentMethod.type] || paymentMethod.type}</div>
  );
};

export default PatientCharges;
