import React from "react";

export default () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 2H4.005a1 1 0 000 2h9.997a1 1 0 100-2zM2.005 3a1 1 0 00-2 0 .851.851 0 000 .195.901.901 0 00.15.36.915.915 0 00.275.274c.053.039.11.07.17.095.067.035.137.06.21.075.065.007.13.007.195 0a1 1 0 00.925-.615.928.928 0 00.075-.19.85.85 0 000-.194zM2 8a.855.855 0 000-.196.81.81 0 00-.076-.185 1 1 0 00-.22-.324 1 1 0 00-1.54 1.26.96.96 0 00.13.15.73.73 0 00.15.124.85.85 0 00.36.15.83.83 0 00.196.02c.064.007.13.007.195 0a.812.812 0 00.185-.055.835.835 0 00.174-.095.73.73 0 00.15-.125.73.73 0 00.125-.15.804.804 0 00.095-.17.846.846 0 00.075-.21A.827.827 0 002 8zM2 13a.858.858 0 000-.196.85.85 0 00-.076-.19 1.162 1.162 0 00-.095-.17 1 1 0 00-1.66 0c-.035.054-.067.111-.094.17a.818.818 0 00-.055.19 1 1 0 00.275.9c.044.048.095.09.15.125a.849.849 0 00.36.15.83.83 0 00.195.02c.064.008.13.008.195 0a.812.812 0 00.185-.055.829.829 0 00.175-.095.727.727 0 00.15-.125.73.73 0 00.124-.15.798.798 0 00.095-.17.848.848 0 00.075-.21A.827.827 0 002 13zM14.995 7H3.999a1 1 0 000 2h10.996a1 1 0 000-2zM10.496 12H4a1 1 0 000 2h6.497a1 1 0 000-2z"
      fill="#A2A9AD"
    />
  </svg>
);
