import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";

export default class SystemEvent extends Component {
  static propTypes = {
    event: PropTypes.object,
  };

  render() {
    const { event } = this.props;
    // we are skipping the details here as there is a separate event for this
    if (event.name.includes("status updated")) return null;
    return (
      <div>
        {_.map(event.data, (value, k) => {
          return (
            <div key={k}>
              {k}: {typeof value === "object" ? JSON.stringify(value) : value}
            </div>
          );
        })}
      </div>
    );
  }
}
