import styled, { css } from "react-emotion";

export const wrapperCSS = css`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

export const Background = styled.div`
  z-index: 1000;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.02);
`;
