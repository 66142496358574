import React, { useReducer, useEffect } from "react";
import { DateTime } from "luxon";
import styled, { css } from "react-emotion";
import { uuid } from "src/shared/util";
import Dropdown from "src/shared/Dropdown";
import Button from "src/shared/Button";
import useCalendarViewContext from "./useCalendarViewContext";
import useSchedulingContext from "src/scenes/SchedulingV2/useSchedulingContext";
import { useSchedulingRestrictions } from "src/state/self";
import useEditContext from "./useEditContext";
import OffsetPicker from "./OffsetPicker";
import { EmptySlot, AppointmentType } from "src/types/api";
import { getLabel } from "./util";

type Props = {
  // The parent dictates the offset of this component via a style attribute.
  style?: object;
  onHide: () => void;

  base?: {
    bookableResourceId: string;
    offset: number;
    date: DateTime;
  };

  // Optional existing empty slot that we're editing
  existing?: EmptySlot;
};

const Hide = styled.div`
  z-index: 2;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
`;

const Wrapper = styled.div`
  height: 100%;
`;

const Form = styled.div`
  z-index: 6;
  position: absolute;
  background: #fff;
  width: 400px;
  border-radius: 4px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.05);
  left: 6px;
  padding: 1rem;

  h2 {
    margin: 0 0 1.5rem 0;
  }

  transition: all 0.3s;
`;

const hasType = css`
  left: 6rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 0.75rem 0;

  > div:first-child {
    flex: 1;
    font-size: 0.85rem;
  }

  > div {
    flex: 2;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1.5rem 0 0 0;
`;

const Right = styled.div`
  display: flex;

  div {
    margin: 0 0.25rem;
  }
`;

type State = {
  // predefined
  clientId: string;
  bookableResourceId: string;
  date: DateTime;
  // configurabele but predefined
  offset: number;
  // configurable and undefined by default
  appointmentType: AppointmentType | undefined;
  doctorId: string | undefined;
};

type Action =
  | { type: "offset"; offset: number }
  | { type: "doctorId"; doctorId: string }
  | { type: "type"; appointmentType: AppointmentType | undefined };

const reducer = (s: State, a: Action): State => {
  switch (a.type) {
    case "offset":
      return { ...s, offset: a.offset };
    case "doctorId":
      return { ...s, doctorId: a.doctorId };
    case "type":
      return { ...s, appointmentType: a.appointmentType as AppointmentType };
  }
  return s;
};

export const NewAppointment = ({
  style,
  onHide,

  base,
  existing,
}: Props) => {
  const { canCancelBV } = useSchedulingRestrictions();
  // TODO: CLEAN CONTEXT
  const { types } = useCalendarViewContext();
  const { editMode, unsaved, onUpdateUnsaved } = useEditContext();
  const { doctorOptions } = useSchedulingContext();

  // Editing templates doesn't require a doctor; only assigning availability
  // requires a doctor.
  const requiresDoctor = editMode === "availability";

  const [state, dispatch] = useReducer(reducer, {
    // Create a client ID that represents this unsaved appointment added to the unsaved list.
    // We should add this empty slot to the unsaved list as soon as the appointment type is
    // chosen;  this means that we don't have to handle rendering the slot in this component
    // and the calendar can do this for us.
    clientId: uuid(),

    doctorId: undefined,
    appointmentType: undefined,

    // Add all of the base attributes, if this came from clicking on a Slot
    ...(base || {}),

    // Add all of the "existing" attributes if we're editing an existing slot.
    // If we're not, this will not overwrite the above.
    ...(existing || {}),
  } as State);

  const typeOptions = types
    .filter(t => {
      return t.name === "beginning" ? canCancelBV : true;
    })
    .filter(e => e.enabled)
    .map(t => ({ value: t.id, label: getLabel(t) }));

  useEffect(() => {
    // Remove the old version of this slot from the list.
    const copy = unsaved.filter(u => u.clientId !== state.clientId);
    // Only add this to unsaved if it's valid.
    if (!state.appointmentType || (requiresDoctor && !state.doctorId)) {
      return;
    }
    copy.push(state as EmptySlot);
    onUpdateUnsaved && onUpdateUnsaved(copy);
    // ky - 09/25/2019
    // NOTE: when adding all required dependencies, app crashes via infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const cancel = () => {
    const copy = unsaved.filter(u => u.clientId !== state.clientId);
    onUpdateUnsaved && onUpdateUnsaved(copy);
    onHide();
  };

  return (
    <>
      <Hide onClick={() => (!existing ? cancel() : onHide())} />
      <Wrapper>
        <Form style={style} className={state.appointmentType && hasType}>
          <h5>
            {editMode === "availability"
              ? "Open a new appointment"
              : "Add slot to template"}
          </h5>

          <Row>
            <div>Start time</div>
            <OffsetPicker
              value={state.offset}
              onChange={offset => dispatch({ type: "offset", offset })}
            />
          </Row>

          <Row>
            <div>Appointment Type</div>
            <Right>
              <Dropdown
                selected={
                  state.appointmentType && getLabel(state.appointmentType)
                }
                options={typeOptions}
                onSelect={n => {
                  const type = types.find(t => t.id === n.value);
                  dispatch({ type: "type", appointmentType: type });
                }}
              />
            </Right>
          </Row>

          {requiresDoctor && (
            <Row>
              <div>Doctor</div>
              <Right>
                <Dropdown
                  options={doctorOptions}
                  selected={state.doctorId}
                  onSelect={option => {
                    dispatch({ type: "doctorId", doctorId: option.value });
                  }}
                />
              </Right>
            </Row>
          )}

          <Actions>
            <Button onClick={cancel}>{!existing ? "Cancel" : "Remove"}</Button>
            <Button
              type="primary"
              onClick={onHide}
              disabled={
                !state.appointmentType || (requiresDoctor && !state.doctorId)
              }
            >
              {!existing ? "Add" : "Update"}
            </Button>
          </Actions>
        </Form>
      </Wrapper>
    </>
  );
};

export default NewAppointment;
