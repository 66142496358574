import styles from "./create-order-style";
import { times, map, partial, each } from "lodash";
import React, { Component } from "react";
import {
  Dialog,
  RaisedButton,
  TextField,
  SelectField,
  MenuItem,
} from "material-ui";
import { getInvoiceItemList } from "src/selectors/invoice-item";
import { loadAvailableInvoiceItems } from "src/redux/modules/invoice-item";

import { createOrder } from "src/redux/modules/order";

import { connect } from "react-redux";
import AddIcon from "material-ui/svg-icons/content/add-circle";
import SubtractIcon from "material-ui/svg-icons/content/remove-circle";

const initialState = {
  showDialog: false,
  orderedItems: {},
  waiting: false,
  category: "",
  finalAmount: "",
};
const connected = connect(
  state => ({
    availableInvoiceItems: getInvoiceItemList(state),
  }),
  { loadAvailableInvoiceItems, createOrder } // these are called actions
);

class CreateOrder extends Component {
  state = initialState;

  handleOpen = () => {
    this.props.loadAvailableInvoiceItems();
    this.setState({ showDialog: true });
  };

  handleClose = () => {
    this.setState(initialState);
  };

  postOrderToAPI = () => {
    const orderedItems = this.state.orderedItems;
    const invoiceItemList = this.props.availableInvoiceItems;

    const invoiceItemsIds = [];
    each(orderedItems, (itemNum, id) => {
      times(itemNum, () => invoiceItemsIds.push(id));
    });

    // copied from below in render (RENDER?!)
    let subTotal = 0;
    each(invoiceItemList, invoiceItem => {
      if (orderedItems[invoiceItem.id]) {
        subTotal += orderedItems[invoiceItem.id] * invoiceItem.price;
      }
    });

    // If the final amount is less than the subtotal, we're adding a discount.
    // Figure out the discount amount.

    const orderProps = {
      invoice_item_ids: invoiceItemsIds,
      user_id: this.props.patient.id,
    };

    const finalAmount = this.state.finalAmount * 100;

    if (finalAmount < subTotal) {
      orderProps.discount = {
        amount: subTotal - finalAmount,
        category: this.state.category || "other",
      };
    }

    if (finalAmount > subTotal) {
      orderProps.list_amount = finalAmount;
    }

    this.setState({ waiting: true });
    this.props.createOrder(orderProps).then(this.handleClose);
  };

  addInvoiceItemToOrder = invoiceItemId => {
    const orderedItems = { ...this.state.orderedItems };
    orderedItems[invoiceItemId] = orderedItems[invoiceItemId] || 0;
    orderedItems[invoiceItemId] += 1;
    this.setState({ orderedItems });
  };

  removeInvoiceItemFromOrder = invoiceItemId => {
    const orderedItems = { ...this.state.orderedItems };
    if (!orderedItems[invoiceItemId]) return; // either 0 or undefined
    orderedItems[invoiceItemId] -= 1;
    this.setState({ orderedItems });
  };
  handleFinalAmountChange = (e, val) => {
    this.setState({ finalAmount: val });
  };
  handleCategoryChange = category => {
    this.setState({ category });
  };
  render() {
    const orderedItems = this.state.orderedItems;
    const invoiceItemList = this.props.availableInvoiceItems;
    let subTotal = 0;
    each(invoiceItemList, invoiceItem => {
      if (orderedItems[invoiceItem.id]) {
        subTotal += orderedItems[invoiceItem.id] * invoiceItem.price;
      }
    });

    const canSubmit =
      !this.state.waiting &&
      subTotal > 0 &&
      parseInt(this.state.finalAmount, 10) > 0;
    return (
      <div>
        <RaisedButton label="Create New Order" onClick={this.handleOpen} />
        <Dialog
          title="Create Order (Only 1 Item Per Order)"
          modal={false}
          open={this.state.showDialog}
          onRequestClose={this.handleClose}
          autoScrollBodyContent
          contentClassName="full-width-dialog-content"
        >
          {map(invoiceItemList, invoiceItem => {
            const count = this.state.orderedItems[invoiceItem.id] || 0;
            return (
              <div key={invoiceItem.id} style={styles.invoiceItemContainer}>
                <div>
                  <div style={styles.invoiceItem}>{invoiceItem.item_name}</div>
                  <div style={styles.invoiceItemDetails}>
                    {invoiceItem.description}
                  </div>
                </div>
                <div style={styles.price}>
                  ${(invoiceItem.price / 100).toFixed(2)}
                </div>
                <div style={styles.countContainer} color="red">
                  <SubtractIcon
                    color={
                      count
                        ? styles.V.colors.secondary
                        : styles.V.colors.disabled
                    }
                    style={styles.icon}
                    onClick={partial(
                      this.removeInvoiceItemFromOrder,
                      invoiceItem.id
                    )}
                  />
                  <div style={styles.count}>{count}</div>
                  <AddIcon
                    color={styles.V.colors.secondary}
                    style={styles.icon}
                    onClick={partial(
                      this.addInvoiceItemToOrder,
                      invoiceItem.id
                    )}
                  />
                </div>
              </div>
            );
          })}
          <div style={styles.summary}>
            <div style={styles.subTotal}>
              Subtotal: ${(subTotal / 100).toFixed(2)}{" "}
            </div>
            <div>
              <TextField
                value={this.state.finalAmount}
                floatingLabelText="Confirm final amount"
                hintText={`${(subTotal / 100).toFixed(2)}`}
                onChange={this.handleFinalAmountChange}
                type="number"
              />
            </div>
            <div>
              <SelectField
                value={this.state.category}
                hintText="Reason for discount"
                onChange={(...args) => {
                  this.handleCategoryChange(args[2]); // eslint-disable-line
                }}
              >
                <MenuItem value="start_today" primaryText="Same day discount" />
                <MenuItem
                  value="corporate_discount"
                  primaryText="Corporate Discount"
                />
                <MenuItem
                  value="friends_and_family"
                  primaryText="Friends and Family"
                />
                <MenuItem value="marketing" primaryText="Marketing" />
                <MenuItem value="partnership" primaryText="Partnership" />
                <MenuItem value="comp" primaryText="Comp" />
                <MenuItem
                  value="service_failure"
                  primaryText="Service Failure/Good Will"
                />
                <MenuItem value="other" primaryText="Other" />
              </SelectField>
            </div>
            <div>
              <RaisedButton
                disabled={!canSubmit}
                primary
                label="Submit Order"
                onClick={this.postOrderToAPI}
              />
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default connected(CreateOrder);
