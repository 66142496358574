import _ from "lodash";
import superagent from "superagent";
import cookie from "src/utils/CUSTOM-cookie";

const methods = ["get", "post", "put", "patch", "del"];

export function formatUrl(path) {
  const adjustedPath = path[0] !== "/" ? `/${path}` : path;
  return process.env.REACT_APP_API_HOST + adjustedPath;
}

export default class ApiClient {
  constructor(req) {
    methods.forEach(
      (
        method // eslint-disable-line
      ) =>
        (this[method] = (path, { params, data, file, fields, headers } = {}) =>
          new Promise((resolve, reject) => {
            let authToken;
            const formattedUrl = formatUrl(path);
            const request = superagent[method](formattedUrl);
            request.withCredentials();
            request.accept("json");
            _.each(headers, (val, key) => {
              request.set(key, val);
            });
            if (params) {
              request.query(params);
            }
            if (file) {
              request.attach("file", file);
              // fields are only applicable in multipart file uploads
              if (fields) {
                _.each(fields, (val, key) => {
                  request.field(key, val);
                });
              }
            }
            authToken = cookie.parse(document.cookie).auth_token;
            request.set("Authorization", authToken);

            if (data) {
              request.send(data);
            }
            request.end((err, res = {}) => {
              if (err) {
                return reject(res.body || err);
              }
              return resolve(res.body);
            });
          }).catch(error => error))
    );
  }
  /*
   * There's a V8 bug where, when using Babel, exporting classes with only
   * constructors sometimes fails. Until it's patched, this is a solution to
   * "ApiClient is not defined" from issue #14.
   * https://github.com/erikras/react-redux-universal-hot-example/issues/14
   *
   * Relevant Babel bug (but they claim it's V8): https://phabricator.babeljs.io/T2455
   *
   * Remove it at your own risk.
   */
  empty() {}
}
