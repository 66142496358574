import { Task, TaskReference } from "../../types";
import { titleCase } from "src/shared/util";

export const name = (r: TaskReference) => {
  switch (r.model.__typename) {
    case "Submission":
      return `${titleCase(r.model.form.name)}`;
    case "InternalNote":
      return r.model.text;
    default:
      return r.model.__typename;
  }
};

export const link = (t: Task, r: TaskReference): string | undefined => {
  switch (r.model.__typename) {
    case "Submission":
      return `/patients/${t.user.id}/forms/${r.model.form.slug}/${r.model.id}`;
  }
};
