import { CombinedError, useQuery, useMutation } from "src/utils/http/gqlQuery";
import { Order, PaymentPlan, Refund, Charge } from "src/types/gql";

export const useOrders = (
  userID: string
): [
  boolean,
  CombinedError | undefined,
  Order[],
  (opts?: undefined) => void
] => {
  const [{ fetching, error, data }, execute] = useQuery<{
    orders: Order[];
  }>({ query: orderGql, variables: { userID } });

  // The cache of orders will be filled when any order is created, so here
  // we must always filter for orders by user ID
  const orders = ((data && data.orders) || ([] as Order[])).filter(o => {
    return o.user.id === userID;
  });

  return [fetching, error, orders, execute];
};

export const orderGql = `
  query Orders($userID: ID!) {
    orders(userID: $userID) {
      id
      status
      total
      subtotal
      balance
      createdAt
      user { id }
      orderItems {
        id
        insuranceCode
        description
        product {
          id
          type
          name
          price
          description
        }
      }
      discounts {
        id
        promotion {
          id
          type
          amount
          stackable
          code
        }
        createdAt
        deletedAt
      }
      interests {
        id
        amount
      }
      adjustments {
        id
        reason
        amount
        orderID
        createdAt
        deletedAt
      }
      claims {
        id
        submittedAmount
        expectedPayoutAmount
        status
        createdAt
        dateSubmitted
        chargesTotal
        insurancePolicy {
          id
          InsuranceProvider {
            name
          }
        }
      }
      charges {
        id
        amount
        createdAt
        paymentMethod {
          id
          type
          cardDetails {
            id
            lastFour
            brand
            funding
            expiryMonth
            expiryYear
          }
        }
      }
      refunds {
        id
        amount
        chargeId
        createdAt

        details {
          __typename
          ... on CheckRefundDetails {
            id
            issueDate
          }
        }
      }
    }
  }
`;

export const useCreatePaymentPlan = () => {
  const [, execute] = useMutation<
    { pp: PaymentPlan },
    { input: NewPaymentPlan }
  >(createPaymentPlanGql);
  return execute;
};

const createPaymentPlanGql = `
  mutation CreatePaymentPlan($input: NewPaymentPlan!) {
    pp: createPaymentPlan(input: $input) {
      id start
      paymentMethod {
        id
        cardDetails {
          id
          lastFour
          brand
          funding
          expiryMonth
          expiryYear
        }
      }
      order {
        id
      }
    }
  }
`;

type NewPaymentPlan = {
  orderId: string;
  productId: string;
  start: string;
  paymentMethodID: string;
};

export const useRefund = () => {
  const [, execute] = useMutation<
    { refund: Refund },
    { newRefund: { chargeId: string; amount: number } }
  >(refundGql);
  return execute;
};

const refundGql = `
mutation Refund($newRefund: NewRefund!) {
  refund(newRefund: $newRefund) {
    id chargeId amount createdAt
  }
}
`;

export const useCreateCheckRefund = () => {
  const [, execute] = useMutation<
    { createRefund: Refund },
    {
      input: {
        type: string;
        orderId: string;
        amount: number;
        checkNumber: string;
        issueDate: string;
      };
    }
  >(createRefundGql);
  return execute;
};

const createRefundGql = `
mutation CreateRefund($input: CreateRefundInput!) {
  createRefund(input: $input) {
    id
  }
}
`;

export const useDeleteCheckRefund = () => {
  const [, execute] = useMutation<
    { deleteRefund: [string] },
    {
      input: {
        type: string;
        id: string;
      };
    }
  >(deleteRefundGql);
  return execute;
};

const deleteRefundGql = `
mutation DeleteRefund($input: DeleteRefundInput!) {
  deleteRefund(input: $input) {
    ids
  }
}
`;

export const useRecordPayment = () => {
  const [, execute] = useMutation<
    { recordPayment: Charge },
    {
      input: {
        type: string;
        orderID: string;
        amount: number;
      };
    }
  >(recordPaymentGql);
  return execute;
};

const recordPaymentGql = `
  mutation RecordPayment($input: RecordPaymentInput!) {
    recordPayment(input: $input) {
      id
    }
  }
`;

export const useDeletePaymentRecord = () => {
  const [, execute] = useMutation<
    { deletePaymentRecord: [string] },
    {
      input: {
        type: string;
        chargeID: string;
      };
    }
  >(deletePaymentRecordGql);
  return execute;
};

const deletePaymentRecordGql = `
  mutation DeletePaymentRecord($input: DeletePaymentRecordInput!) {
    deletePaymentRecord(input: $input) {
      ids
    }
  }
`;

export type Product = {
  id: string;
  price: number;
  type: string;
  name: string;
  available: boolean;
  description: string;
};

export const useProducts = (): [
  boolean,
  CombinedError | undefined,
  Product[]
] => {
  const [{ fetching, error, data }] = useQuery<{
    availableProducts: Product[];
  }>({ query: productGQL });
  return [
    fetching,
    error,
    (data && data.availableProducts) || ([] as Product[]),
  ];
};

export const useAvailableProducts = (): [
  boolean,
  CombinedError | undefined,
  Product[]
] => {
  const [fetching, error, products] = useProducts();
  return [fetching, error, products.filter(p => p.available === true)];
};

// Once the backend separates financing products from addable products, we can remove this
// and use useAvailableProducts
export const useAddableProducts = (): [
  boolean,
  CombinedError | undefined,
  Product[]
] => {
  const [fetching, error, products] = useProducts();
  return [fetching, error, products.filter(p => p.type !== "financing")];
};

export const useTreatmentProducts = (): [
  boolean,
  CombinedError | undefined,
  Product[]
] => {
  const [fetching, error, products] = useProducts();
  return [fetching, error, products.filter(p => p.type === "treatment")];
};

const productGQL = `
  query Products {
    availableProducts {
      id price type name available description
    }
  }
`;

// useFinancingProduct returns the first available financing product
// We will need to remove the find if we ever want to support different types of financing products
export const useFinancingProduct = (): [
  boolean,
  CombinedError | undefined,
  FinancingProduct | undefined
] => {
  const [{ fetching, error, data }] = useQuery<{
    availableFinancingProducts: FinancingProduct[];
  }>({ query: availableFinancingProductGQL });
  return [
    fetching,
    error,
    data &&
      data.availableFinancingProducts &&
      data.availableFinancingProducts.find(p => p.available === true),
  ];
};

export type FinancingProduct = {
  id: string;
  rate: number;
  monthlyAmount: number;
  deposit: number;
  available: boolean;
};

const availableFinancingProductGQL = `
  query availableFinancingProducts {
    availableFinancingProducts {
    id rate monthlyAmount deposit available
  }
}
`;

export const useOrderPaymentPlan = (
  userID: string,
  orderID: string
): [boolean, CombinedError | undefined, PaymentPlan | undefined] => {
  const [{ fetching, error, data }] = useQuery<{
    paymentPlans: PaymentPlan[];
  }>({ query: paymentPlansGql, variables: { userID } });

  if (!data) {
    return [fetching, error, undefined];
  }

  return [
    fetching,
    error,
    data.paymentPlans.find(pp => pp.order.id === orderID),
  ];
};

const paymentPlansGql = `
  query PaymentPlans($userID: ID!) {
    paymentPlans(userID: $userID) {
      id
      start
      order { id }
      paymentMethod {
        id
        type
        cardDetails {
          id
          lastFour
          brand
          funding
          expiryMonth
          expiryYear
        }
      }
      financingProduct {
        monthlyAmount
        rate
        deposit
      }
      nextPaymentAt
      createdAt
    }
  }
`;

const createChargeGql = `
  mutation Charge($orderId: ID!, $newCharges: [NewCharge!]!) {
  charge(orderId: $orderId, newCharges: $newCharges) {
      id
      amount
      createdAt
      paymentMethod {
        id
        cardDetails {
          id
          lastFour
          brand
          funding
          expiryMonth
          expiryYear
        }
      }
    }
  }
`;

type CreateChargeParams = {
  orderId: string;
  newCharges: Array<{
    amount: number;
    paymentMethodId: string;
    insuranceClaimId?: string;
  }>;
};

export const useCreateCharge = () => {
  const [, execute] = useMutation<{ charge: Charge }, CreateChargeParams>(
    createChargeGql
  );
  return execute;
};

const closeOrderGql = `
  mutation CloseOrder($id: ID!) {
    closeOrder(id: $id) {
      ids
    }
  }
`;

export const useDeleteOrder = () => {
  const [, execute] = useMutation<undefined, { id: string }>(closeOrderGql);
  return execute;
};

const reopenOrderGql = `
  mutation ReopenOrder($id: ID!) {
    reopenOrder(id: $id) {
      id
      status
    }
  }
`;

export const useReopenOrder = () => {
  const [, execute] = useMutation<undefined, { id: string }>(reopenOrderGql);
  return execute;
};

const removeDiscount = `
  mutation RemoveDiscount($input: RemoveDiscount!) {
    removeDiscount(input: $input) {
  		ids
    }
  }
`;

export const useRemoveDiscount = () => {
  const [, execute] = useMutation(removeDiscount);
  return execute;
};

export const applyPromoCode = `
  mutation ApplyPromotionCode(
    $input: ApplyDiscount!
  ) {
    applyDiscount(input: $input) {
      id
    }
  }
`;

export const getPromoInput = (orderID: string, code: string) => ({
  input: {
    orderID,
    promoCode: code,
  },
});

const updatePaymentPlan = `
  mutation UpdatePaymentPlan(
    $input: UpdatePaymentPlan!
  ) {
    updatePaymentPlan(input: $input) {
      id
      order {
        id
      }
      paymentMethod {
        id
      }
      nextPaymentAt
      createdAt
    }
  }
`;

export const useUpdatePaymentPlan = () => {
  const [, execute] = useMutation(updatePaymentPlan);
  return execute;
};

const applyOrderAdjustment = `
  mutation ApplyAdjustment(
    $input: ApplyAdjustment!
  ) {
    applyAdjustment(input: $input) {
      id
      total
      subtotal
      balance
    }
  }
`;

export const useApplyOrderAdjustment = () => {
  const [, execute] = useMutation(applyOrderAdjustment);
  return execute;
};

const removeOrderAdjustment = `
  mutation RemoveAdjustment($id: ID!) {
    removeAdjustment(id: $id) {
      ids
    }
  }
`;

export const useRemoveOrderAdjustment = () => {
  const [, execute] = useMutation(removeOrderAdjustment);
  return execute;
};

const deletePaymentPlanGql = `
  mutation DeletePaymentPlan($id: ID!) {
    deletePaymentPlan(id: $id) {
      ids
    }
  }
`;
export const useDeletePaymentPlan = () => {
  const [, del] = useMutation(deletePaymentPlanGql);
  return del;
};

const recordInsuranceClaimGql = `
  mutation RecordInsuranceClaimsSubmission($input: RecordInsuranceClaimsSubmission!) {
    recordInsuranceClaimsSubmission(input: $input) {
      id
      orderID
      insurancePolicyID
      expectedPayoutAmount
      submittedAmount
      status
      dateSubmitted
    }
  }
`;

export const useRecordInsuranceClaimsSubmission = () => {
  const [, record] = useMutation(recordInsuranceClaimGql);
  return record;
};

const editInsuranceClaimGql = `
mutation EditInsuranceClaimsSubmission($input: EditInsuranceClaimsSubmission!) {
  editInsuranceClaimsSubmission(input: $input) {
    id
    orderID
    insurancePolicyID
    expectedPayoutAmount
    submittedAmount
    status
    dateSubmitted
  }
}
`;

export const useEditInsuranceClaimsSubmission = () => {
  const [, record] = useMutation(editInsuranceClaimGql);
  return record;
};

const recordInsurancePaymentGql = `
  mutation RecordInsurancePayment($input: RecordInsurancePayment!) {
    recordInsurancePayment(input: $input) {
      id
      amount
    }
  }
`;

const deleteInsuranceClaimGql = `
  mutation DeleteInsuranceClaimsSubmission($claimID: ID!) {
    deleteInsuranceClaimsSubmission(claimID: $claimID) {
      ids
    }
  }
`;

export const useDeleteInsuranceClaimGql = () => {
  const [, deleteClaim] = useMutation(deleteInsuranceClaimGql);
  return deleteClaim;
};

export const useRecordInsurancePayment = () => {
  const [, record] = useMutation(recordInsurancePaymentGql);
  return record;
};

const recordCareCreditPaymentGql = `
  mutation RecordCareCreditPayment($input: RecordCareCreditPayment!) {
    recordCareCreditPayment(input: $input) {
      id
      amount
    }
  }
`;

export const useRecordCareCreditPayment = () => {
  const [, record] = useMutation(recordCareCreditPaymentGql);
  return record;
};

const editCareCreditPaymentGql = `
  mutation UpdateCareCreditPayment($input: UpdateCareCreditPayment!) {
    updateCareCreditPayment(input: $input) {
      id
      amount
    }
  }
`;

export const useEditCareCreditPayment = () => {
  const [, update] = useMutation(editCareCreditPaymentGql);
  return update;
};

const deleteCareCreditGql = `
  mutation DeleteCareCreditPayment($chargeID: ID!) {
    deleteCareCreditPayment(chargeID: $chargeID) {
      ids
    }
  }
`;

export const useDeleteCareCredit = () => {
  const [, deleteCareCredit] = useMutation(deleteCareCreditGql);
  return deleteCareCredit;
};
