import styled, { css } from "react-emotion";
import color from "src/styles/color";
import { Link } from "react-router";

// Tab styles
const DownTriangle = styled.div`
  width: 0px;
  height: 0px;
  border: 4px solid;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: ${color.gray3};
  border-bottom-color: transparent;
  margin-top: 6px;
  margin-left: 4px;
`;

export const Line = styled.div`
  border-left: 1px solid ${color.border};
  height: 16px;
`;

export const TabLink = styled(Link)`
  color: ${color.gray3};
`;

export const TabTitle = styled.span`
  color: ${color.gray3};
`;

export const Tab = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 32px;
  color: ${color.gray3};
  font-weight: bold;
  cursor: pointer;

  &:hover ${DownTriangle} {
    border-top-color: ${color.primary};
  }
  &:hover ${TabTitle} {
    color: ${color.primary};
  }
  &:hover ${TabLink} {
    color: ${color.primary};
  }

  ${({ theme: { active } }) =>
    active &&
    css`
      ${DownTriangle} {
        border-top-color: ${color.primary};
      }
      color: ${color.primary};
      border-bottom: 4px solid ${color.primary};
      ${TabTitle} {
        color: ${color.primary};
      }
      ${TabLink} {
        color: ${color.primary};
      }
    `}
`;

// Tabs styles
export const Tabs = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: stretch;

  > a,
  > button {
    cursor: pointer;
    outline: 0 none;
    border: 0;
    padding: 14px 24px;
    background: #fff;
    color: inherit;

    &.active {
      color: ${color.primary} !important;
      font-weight: bold;
      border-bottom: 4px solid ${color.primary};
    }
  }
`;
