import React, { useEffect, useRef, useState } from "react";
import styled from "react-emotion";
import Popover from "src/shared/Popover";
import { cx, css } from "emotion";
import { ToothLocation, ToothNumber, UIAttribute, State } from "./types";
import { useToothAttributes, getMesialNumber, getDistalNumber } from "./consts";
import { useStateContext } from "./state";
import color from "src/styles/color";

type Props = {
  // whether this el is before or after the circle elements.
  position: "before" | "after";
  surface: "mesial" | "distal";
  toothNumber: ToothNumber;
  restrictToDisplay?: boolean;
  editable: boolean;
};

const Spacing: React.FC<Props> = ({
  toothNumber,
  restrictToDisplay,
  surface,
  position,
  editable,
}) => {
  const [state, dispatch] = useStateContext();

  const spacing = useAggregateSpacing(state, toothNumber, !!restrictToDisplay);
  const { distalSpacing, mesialSpacing, neighbors } = spacing;

  const { oppositeTooth, oppositeSurface } = React.useMemo(() => {
    if ([8, 9, 24, 25].includes(toothNumber) && surface === "mesial") {
      return {
        oppositeTooth: getMesialNumber(toothNumber),
        oppositeSurface: "mesial",
      };
    }
    return {
      oppositeTooth:
        surface === "mesial"
          ? getMesialNumber(toothNumber)
          : getDistalNumber(toothNumber),
      oppositeSurface: surface === "mesial" ? "distal" : "mesial",
    };
  }, [toothNumber, surface]);

  // Note that spacing attributes with no data is used to indicate
  // "tooth without contacts": teeth that have some spacing that has not
  // yet been measured.
  //
  // Doctors do a first pass with floss to see which teeth they need to measure;
  // we need to indicate which teeth have some amount of unknown spacing.

  // Entirely remove spacing from the tooth, even if there's no data saved.
  const onDelete = () => {
    if (!editable) {
      return;
    }

    // Remove spacing from slected surface.
    dispatch({
      toothNumber,
      type: "removeAttribute",
      attribute: "spacing",
      location: surface,
    });
    dispatch({
      toothNumber: oppositeTooth,
      type: "removeAttribute",
      attribute: "spacing",
      location: oppositeSurface as ToothLocation,
    });
  };

  const onChange = (amount: string | "unknown") => {
    if (!editable) {
      return;
    }

    // delete all spacing first.
    onDelete();

    let data: string | undefined;
    if (amount !== "unknown") {
      const float = parseFloat(amount);
      if (float === 0) {
        return;
      }

      // find tooth number, spread amount between mesial/distal, remove existing spacing
      // and add new spacing
      data = (float / 2).toFixed(3);
    }

    dispatch({
      toothNumber,
      type: "addAttribute",
      attribute: "spacing",
      location: surface,
      data,
    });
    dispatch({
      toothNumber: oppositeTooth,
      type: "addAttribute",
      attribute: "spacing",
      location: oppositeSurface as ToothLocation,
      data,
    });
  };

  // The mesial surfaces of the 8<>9 and 24<>25 face each other, so we check whether the
  // tooth number is one of these and the neighboring mesial has a value, OR that the distal
  // has a value in every other case, as normally mesial faces a distal.
  if (
    ([8, 9, 24, 25].includes(toothNumber) &&
      surface === "mesial" &&
      neighbors.mesial.mesialSpacing.length > 0) ||
    (![8, 9, 24, 25].includes(toothNumber) &&
      surface === "mesial" &&
      neighbors.mesial.distalSpacing.length > 0)
  ) {
    // Merge two together
    const amounts = [8, 9, 24, 25].includes(toothNumber)
      ? mesialSpacing.concat(neighbors.mesial.mesialSpacing)
      : mesialSpacing.concat(neighbors.mesial.distalSpacing);
    return (
      <Display
        toothNumber={toothNumber}
        surface={surface}
        position={position}
        attributes={amounts}
        onChange={onChange}
        onDelete={onDelete}
        editable={editable}
      />
    );
  }

  if (surface === "distal" && neighbors.distal.mesialSpacing.length > 0) {
    // Merge two together
    const amounts = distalSpacing.concat(neighbors.distal.mesialSpacing);
    return (
      <Display
        toothNumber={toothNumber}
        surface={surface}
        position={position}
        attributes={amounts}
        onChange={onChange}
        onDelete={onDelete}
        editable={editable}
      />
    );
  }

  // No spacing - show a blank diamond
  return (
    <Display
      toothNumber={toothNumber}
      surface={surface}
      position={position}
      attributes={[]}
      onChange={onChange}
      onDelete={onDelete}
      editable={editable}
    />
  );
};

export default Spacing;

type DisplayProps = {
  surface: "mesial" | "distal";
  // whether this el is before or after the circle elements.
  position: "before" | "after";
  toothNumber: number;
  attributes: UIAttribute[];
  onChange: (amount: string) => void;
  onDelete: () => void;
  editable: boolean;
};

const LEADING_ZEROES_PATTERN = /^0+/;
const SPACING_AMOUNT_INCREMENT = 0.05;
const DECREMENT_LABEL = "-";
const INCREMENT_LABEL = "+";

function formatSpacingAmount(amount: number, fallback: string): string {
  if (amount <= 0 || isNaN(amount)) {
    return fallback;
  }
  return amount.toFixed(2).replace(LEADING_ZEROES_PATTERN, "");
}

interface SpacingAmountProps {
  amount: number;
  editable: boolean;
  onChange: (newValue: number) => void;
}

const SpacingAmount: React.FC<SpacingAmountProps> = props => {
  const [editingTextAmount, setEditingTextAmount] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (editingTextAmount && inputRef.current) {
      inputRef.current.select();
    }
  }, [editingTextAmount, inputRef]);

  return (
    <div>
      <AmountRow>
        {editingTextAmount ? (
          <input
            type="text"
            className={AMOUNT_INPUT_CSS}
            ref={inputRef}
            defaultValue={formatSpacingAmount(props.amount, "")}
            onKeyDown={e => {
              if (e.key === "Enter") {
                e.preventDefault();
                setEditingTextAmount(false);
              }
            }}
            onChange={e => props.onChange(parseFloat(e.target.value))}
            onBlur={() => setEditingTextAmount(false)}
          />
        ) : (
          <AmountDisplay
            onClick={
              props.editable
                ? e => {
                    e.stopPropagation();
                    setEditingTextAmount(true);
                  }
                : undefined
            }
          >
            {formatSpacingAmount(props.amount, "0")}
          </AmountDisplay>
        )}
      </AmountRow>
      <MeasurementRow>Measurement (mm)</MeasurementRow>
      {props.editable && (
        <Buttons>
          <button
            key="decrement"
            type="button"
            disabled={props.amount <= 0}
            onClick={() =>
              props.onChange(props.amount - SPACING_AMOUNT_INCREMENT)
            }
          >
            {DECREMENT_LABEL}
          </button>
          <button
            key="increment"
            type="button"
            onClick={() =>
              props.onChange(props.amount + SPACING_AMOUNT_INCREMENT)
            }
          >
            {INCREMENT_LABEL}
          </button>
        </Buttons>
      )}
    </div>
  );
};

// Half flag, for a mesial or distal surface for a single tooth only
const Display: React.FC<DisplayProps> = props => {
  const [popupHidden, setPopupHidden] = useState<boolean>(true);

  let amount = 0;

  props.attributes.forEach(a => {
    if (!a.attribute || !a.attribute.data) {
      return;
    }
    amount += parseFloat(a.attribute.data);
  });

  const classes = cx([
    props.position === "before" && beforeFullCSS,
    props.position === "after" && afterFullCSS,
  ]);

  const hasSpacing = props.attributes.length > 0;

  const changeSpacingTo = (inputValue: number) => {
    const finalValue = isNaN(inputValue) ? 0 : Math.max(0, inputValue);
    props.onChange(finalValue.toFixed(2));
  };

  const popoverContent = (
    <AdjustWrapper>
      <SpacingAmount
        amount={amount}
        editable={props.editable}
        onChange={changeSpacingTo}
      />
      <Bottom>
        <button
          key="delete"
          type="button"
          className={cx([TEXT_BUTTON_CSS, DELETE_BUTTON_CSS])}
          onClick={() => {
            props.onDelete();
            setPopupHidden(true);
          }}
        >
          Delete
        </button>
        <button
          key="close"
          type="button"
          className={cx([TEXT_BUTTON_CSS, CLOSE_BUTTON_CSS])}
          onClick={() => setPopupHidden(true)}
        >
          OK
        </button>
      </Bottom>
    </AdjustWrapper>
  );

  // NOTE: use `undefined` to enable Popover's built-in behavior.
  const popupVisible = popupHidden ? false : undefined;

  return (
    <PopoverWrapper
      css={classes}
      style={props.toothNumber > 16 ? { top: 70 } : { bottom: 70 }}
    >
      <Popover
        position="bottom"
        align="center"
        content={popoverContent}
        persistOnClick={true}
        visible={popupVisible}
        onClickWhenClosed={() => {
          if (amount <= 0) {
            props.onChange("unknown");
          }
          setPopupHidden(false);
          return hasSpacing;
        }}
      >
        <FullWrapper className={cx([hasSpacing && HAS_SPACING_CSS])}>
          <span>{formatSpacingAmount(amount, "")}</span>
        </FullWrapper>
      </Popover>
    </PopoverWrapper>
  );
};

const useAggregateSpacing = (s: State, t: ToothNumber, restrict: boolean) => {
  // TODO: Get mesial tooth
  const spacing = useSpacing(s, t, restrict);

  // On the mesial neighbour, we want the distal (the surface matching the current tooth).
  const mesial = useSpacing(s, getMesialNumber(t), restrict);
  const distal = useSpacing(s, getDistalNumber(t), restrict);

  return {
    ...spacing,
    neighbors: {
      // mesial neighboring tooth
      mesial,
      // distal neighboring tooth
      distal,
    },
  };
};

const useSpacing = (s: State, toothNumber: ToothNumber, restrict: boolean) => {
  const { uiAttributes } = useToothAttributes(s, toothNumber, restrict);
  const distalSpacing = uiAttributes.filter(
    a =>
      a.type === "spacing" && a.attribute && a.attribute.location === "distal"
  );
  const mesialSpacing = uiAttributes.filter(
    a =>
      a.type === "spacing" && a.attribute && a.attribute.location === "mesial"
  );
  return { toothNumber, distalSpacing, mesialSpacing };
};

const PopoverWrapper = styled.div`
  position: absolute;
  height: 24px;
  width: 24px;
`;

const FullWrapper = styled.div`
  z-index: 1;
  height: 24px;
  width: 24px;
  transform: rotate(45deg);

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  letter-spacing: -1px;
  line-height: 1;
  cursor: pointer;

  border: 1px solid #e9e9e9;
  border-radius: 2px;
  background: #fafafa;

  &:hover:before {
    background: #f1f4f5;
  }

  span {
    transform: rotate(-45deg);
  }
`;

const HAS_SPACING_CSS = css`
  border: 1px solid #a2a9ad !important;
  background: #dedede !important;
`;

const beforeFullCSS = `
  left: -12px;
`;

const afterFullCSS = `
  right: -12px;
`;

const AdjustWrapper = styled.div`
  padding: 20px;
  text-align: center;

  span {
    font-weight: bold;
    color: ${color.gray3};
  }
`;

const Buttons = styled.div`
  display: flex;
  padding: 15px 0;

  button {
    flex: 1;
    cursor: pointer;
    display: block;
    border: 1px solid ${color.borderLight};
    padding: 6px 0 7px;
    background: #fff;
    font-weight: bold;

    &:hover {
      background: ${color.gray1};
    }

    &:first-of-type {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      border-right: 0;
    }

    &:last-of-type {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    &:disabled {
      cursor: not-allowed;
      color: ${color.gray2};
    }
  }
`;

const AmountRow = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
`;

const AmountDisplay = styled.div`
  padding: 3px 20px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: ${color.background3};
  }
`;

const AMOUNT_INPUT_CSS = css`
  display: block;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  width: 80px;
  text-align: center;
`;

const MeasurementRow = styled.div`
  color: ${color.gray3};
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;

  button:hover {
    text-decoration: underline;
  }
`;

const TEXT_BUTTON_CSS = css`
  font-family: inherit;
  font-size: inherit;
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
`;

const DELETE_BUTTON_CSS = css`
  color: ${color.gray4};
`;

const CLOSE_BUTTON_CSS = css`
  color: ${color.orange};
  font-weight: bold;
`;
