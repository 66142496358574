import React, { useState } from "react";
import styled from "react-emotion";
import { Link } from "react-router";
import { DateTime } from "luxon";

import Avatar from "src/shared/Avatar";
import Switch from "src/shared/Switch";
import Slider, { Range } from "rc-slider";
import color from "src/styles/color";
import PlusMinusButton from "./PlusMinusButton";
import ToothTransform from "./ToothTransform";

import CameraViewBottom from "./icons/camera_view_bottom.svg";
import CameraViewCenter from "./icons/camera_view_center.svg";
import CameraViewLeft from "./icons/camera_view_left.svg";
import CameraViewRight from "./icons/camera_view_right.svg";
import CameraViewTop from "./icons/camera_view_top.svg";
import Button from "../../shared/Button";

const ControlPanel = ({
  wasmModule,
  patient,
  toothInfo,
  toothRotationStep,
  toothTranslationStep,
  setShowTeethMovements,
  setShowSaveProjectModal,
  addAdjPhaseButtonEnabled,
  submitChangedButtonEnabled,
  spacingVisible,
  cbctAxialLowBound,
  cbctAxialLowValue,
  cbctAxialHighValue,
  cbctAxialHighBound,
  cbctSagittalLowBound,
  cbctSagittalLowValue,
  cbctSagittalHighValue,
  cbctSagittalHighBound,
  cbctCoronalLowBound,
  cbctCoronalLowValue,
  cbctCoronalHighValue,
  cbctCoronalHighBound,
  cbctVolumeHardBonesLevel,
  cbctVolumeSoftBonesLevel,
  cbctVolumeHardBonesOpacity,
  cbctVolumeSoftBonesOpacity,
  cbctSurfaceBonesLevel,
  cbctSurfaceOpacity,
  cbctSurfaceReduction,
  cbctJawAlignment,
  cbctSurfaceMode,
  cbctAvailable,
}) => {
  // defaults determined by treatment planning program
  const [settings, setSettings] = useState({
    isOpenJaws: false,
    isShowTeethLabels: false,
    isShowOcclusalCollisions: false,
    isShowInterproximalCollisions: false,
    isShowBlockouts: false,
    isShowAttachments: true,
    useToothColorForAttachments: false,
    isShowGums: false,
    isArticulator: false,
    isShowHingeAxis: false,
    isSnapToFit: false,
    isShowCBCT: false,
  });

  const setSetting = (setting: string, value: any) => {
    setSettings(settings => ({
      ...settings,
      [setting]: value,
    }));
  };

  if (!wasmModule || !patient) return null;

  const onToggleJaws = checked => {
    setSetting("isOpenJaws", checked);
    wasmModule.webOpenMouthView(checked);
  };

  const onToggleTeethLabels = checked => {
    setSetting("isShowTeethLabels", checked);
    wasmModule.webShowTeethLabels(checked);
  };

  const onToggleInterproximalCollisions = checked => {
    setSetting("isShowInterproximalCollisions", checked);
    wasmModule.webShowCollisions(checked);
  };

  const onToggleOcclusalCollisions = checked => {
    setSetting("isShowOcclusalCollisions", checked);
    wasmModule.webShowOcclusalCollisions(checked);
  };

  const onToggleBlockouts = checked => {
    setSetting("isShowBlockouts", checked);
    wasmModule.webShowBlockouts(checked);
  };

  const onToggleAttachments = checked => {
    setSetting("isShowAttachments", checked);
    wasmModule.webShowAttachmets(checked);
  };

  const onToggleAttachmentsToothColor = checked => {
    setSetting("useToothColorForAttachments", checked);
    wasmModule.webUseToothColorForAttachment(checked);
  };

  const onToggleCBCT = checked => {
    setSetting("isShowCBCT", checked);
    wasmModule.webShowCBCT(checked);
  };

  const onToggleCBCTSurfaceMode = checked => {
    wasmModule.webSetSurfaceMode(true);
  };

  const onToggleCBCTVolumeMode = checked => {
    wasmModule.webSetSurfaceMode(false);
  };

  const onToggleCBCTAlignMandible = checked => {
    wasmModule.webSetAlignment(true);
  };

  const onToggleCBCTAlignMaxilla = checked => {
    wasmModule.webSetAlignment(false);
  };

  const onToggleSpacing = checked => {
    wasmModule.webShowSpacing(checked);
  };

  const onToggleGums = checked => {
    setSetting("isShowGums", checked);
    wasmModule.webShowGums(checked);
  };

  const onToggleHingeAxis = checked => {
    setSetting("isShowHingeAxis", checked);
    wasmModule.webArticulatorHingeAxis(checked);
  };

  const onToggleArticulator = checked => {
    setSetting("isArticulator", checked);
    wasmModule.webArticulatorEnable(checked);
  };

  const onToggleSnapToFit = checked => {
    setSetting("isSnapToFit", checked);
    wasmModule.webArticulatorSnapToFitMode(checked);
  };

  const onChangeCBCTAxial = values => {
    wasmModule.webSetZmin(values[0]);
    wasmModule.webSetZmax(values[1]);
  };

  const onChangeCBCTSagittal = values => {
    wasmModule.webSetXmin(values[0]);
    wasmModule.webSetXmax(values[1]);
  };

  const onChangeCBCTCoronal = values => {
    wasmModule.webSetYmin(values[0]);
    wasmModule.webSetYmax(values[1]);
  };

  const onChangeCBCTSurfaceOpacity = value => {
    wasmModule.webSetSurfaceOpacity(value / 100.0);
  };

  const onChangeCBCTSurfaceReduction = value => {
    wasmModule.webSetSurfaceReduction(value / 100.0);
  };

  const onChangeCBCTSurfaceBonesLevel = value => {
    wasmModule.webSetSurfaceBonesLevel(value);
  };

  const onChangeCBCTVolumeHardBonesLevel = value => {
    wasmModule.webSetVolumeHardBonesLevel(value);
  };

  const onChangeCBCTVolumeSoftBonesLevel = value => {
    wasmModule.webSetVolumeSoftBonesLevel(value);
  };

  const onChangeCBCTVolumeHardBonesOpacity = value => {
    wasmModule.webSetVolumeHardBonesOpacity(value / 100.0);
  };

  const onChangeCBCTVolumeSoftBonesOpacity = value => {
    wasmModule.webSetVolumeSoftBonesOpacity(value / 100.0);
  };

  return (
    <Controls>
      <ViewControls wasmModule={wasmModule} />
      <ToggleControls>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Link to={`/patients/${patient.id}`}>
            <Avatar name={patient.name} url={patient.avatarURL} size={70} />
          </Link>
          <div style={{ marginLeft: "20px" }}>
            <Link to={`/patients/${patient.id}`}>
              <PatientName>{patient.name}</PatientName>
            </Link>
            DOB: {DateTime.fromISO(patient.dateOfBirth).toFormat("DDD")}
          </div>
        </div>
        <hr style={{ width: "100%" }} />
        <Toggles>
          <ToggleRow>
            <div>
              <Switch checked={settings.isOpenJaws} onChange={onToggleJaws} />
              &nbsp;&nbsp;Open jaws
            </div>
            <div>
              <Switch
                checked={settings.isShowTeethLabels}
                onChange={onToggleTeethLabels}
              />
              &nbsp;&nbsp;Teeth labels
            </div>
          </ToggleRow>
          <ToggleRow>
            <div>
              <Switch
                checked={settings.isShowInterproximalCollisions}
                onChange={onToggleInterproximalCollisions}
              />
              &nbsp;&nbsp;Collisions
            </div>
            <div>
              <Switch
                checked={settings.isShowOcclusalCollisions}
                onChange={onToggleOcclusalCollisions}
              />
              &nbsp;&nbsp;Occlusal
            </div>
          </ToggleRow>
          <ToggleRow>
            <div>
              <Switch checked={settings.isShowGums} onChange={onToggleGums} />
              &nbsp;&nbsp;Gums
            </div>
            <div>
              <Switch
                checked={settings.isArticulator}
                onChange={onToggleArticulator}
              />
              &nbsp;&nbsp;Articulator
            </div>
          </ToggleRow>
          <ToggleRow>
            <div>
              <Switch
                checked={settings.isShowAttachments}
                onChange={onToggleAttachments}
              />
              &nbsp;&nbsp;Attachments
            </div>
            <div>
              <Switch
                checked={settings.useToothColorForAttachments}
                onChange={onToggleAttachmentsToothColor}
              />
              &nbsp;&nbsp;Natural Color
            </div>
          </ToggleRow>
          <ToggleRow>
            <div>
              <Switch
                checked={settings.isShowBlockouts}
                onChange={onToggleBlockouts}
              />
              &nbsp;&nbsp;Blockouts
            </div>
            <div>
              <Switch checked={spacingVisible} onChange={onToggleSpacing} />
              &nbsp;&nbsp;Spacing
            </div>
          </ToggleRow>
          <ToggleRow>
            <div>
              <Switch
                checked={settings.isShowCBCT}
                onChange={onToggleCBCT}
                disabled={!cbctAvailable}
              />
              &nbsp;&nbsp;3D XRay
            </div>
          </ToggleRow>

          <ModalButton
            type="button"
            onClick={() => setShowTeethMovements(true)}
            style={{ margin: "10px 0 0 0" }}
          >
            Teeth Movement Overview
          </ModalButton>

          <Button
            kind="primary"
            onClick={() => {
              wasmModule.webAdjustTeethPosition();
            }}
            style={{ margin: "20px 0 0 0" }}
            disabled={!addAdjPhaseButtonEnabled}
          >
            Add Adjustment Phase
          </Button>

          <Button
            kind="primary"
            onClick={() => setShowSaveProjectModal(true)}
            style={{ margin: "10px 0 0 0" }}
            disabled={!submitChangedButtonEnabled}
          >
            Submit Edits for Restaging
          </Button>

          {settings.isShowCBCT && (
            <CBCTToggles>
              <hr style={{ width: "100%", margin: "15px 0 0 0" }} />
              <CBCTTitle>CBCT</CBCTTitle>
              <ToggleRow>
                <div>
                  <Switch
                    checked={cbctSurfaceMode}
                    onChange={onToggleCBCTSurfaceMode}
                  />
                  &nbsp;&nbsp;Surface
                </div>
                <div>
                  <Switch
                    checked={!cbctSurfaceMode}
                    onChange={onToggleCBCTVolumeMode}
                  />
                  &nbsp;&nbsp;Volume
                </div>
              </ToggleRow>
              <CBCTAlignmentTitle>Alignment</CBCTAlignmentTitle>
              <ToggleRow style={{ margin: "5px 0" }}>
                <div>
                  <Switch
                    checked={cbctJawAlignment}
                    onChange={onToggleCBCTAlignMandible}
                  />
                  &nbsp;&nbsp;Mandible
                </div>
                <div>
                  <Switch
                    checked={!cbctJawAlignment}
                    onChange={onToggleCBCTAlignMaxilla}
                  />
                  &nbsp;&nbsp;Maxilla
                </div>
              </ToggleRow>
              <CBCTCuttingSliders>
                <div style={{ margin: "5px 0" }}>
                  <div>Axial</div>
                  <Range
                    style={{ width: "65%" }}
                    allowCross={false}
                    min={cbctAxialLowBound}
                    max={cbctAxialHighBound}
                    value={[cbctAxialLowValue, cbctAxialHighValue]}
                    onChange={onChangeCBCTAxial}
                  />
                </div>
                <div style={{ margin: "5px 0" }}>
                  <div>Sagittal</div>
                  <Range
                    style={{ width: "65%" }}
                    allowCross={false}
                    min={cbctSagittalLowBound}
                    max={cbctSagittalHighBound}
                    value={[cbctSagittalLowValue, cbctSagittalHighValue]}
                    onChange={onChangeCBCTSagittal}
                  />
                </div>
                <div style={{ margin: "5px 0" }}>
                  <div>Coronal</div>
                  <Range
                    style={{ width: "65%" }}
                    allowCross={false}
                    min={cbctCoronalLowBound}
                    max={cbctCoronalHighBound}
                    value={[cbctCoronalLowValue, cbctCoronalHighValue]}
                    onChange={onChangeCBCTCoronal}
                  />
                </div>
              </CBCTCuttingSliders>
            </CBCTToggles>
          )}

          {settings.isShowCBCT && !cbctSurfaceMode && (
            <CBCTToggles>
              <hr style={{ width: "100%" }} />
              <CBCTAlignmentTitle>CBCT Volume</CBCTAlignmentTitle>
              <CBCTCuttingSliders>
                <div style={{ margin: "5px 0" }}>
                  <div>Soft bones level</div>
                  <PlusMinusButton
                    text={"-"}
                    onClick={() =>
                      onChangeCBCTVolumeSoftBonesLevel(
                        cbctVolumeSoftBonesLevel - 50
                      )
                    }
                  />
                  <div>{cbctVolumeSoftBonesLevel}</div>
                  <PlusMinusButton
                    text={"+"}
                    onClick={() =>
                      onChangeCBCTVolumeSoftBonesLevel(
                        cbctVolumeSoftBonesLevel + 50
                      )
                    }
                  />
                </div>
                <div style={{ margin: "5px 0" }}>
                  <div>Hard bones level</div>
                  <PlusMinusButton
                    text={"-"}
                    onClick={() =>
                      onChangeCBCTVolumeHardBonesLevel(
                        cbctVolumeHardBonesLevel - 50
                      )
                    }
                  />
                  <div>{cbctVolumeHardBonesLevel}</div>
                  <PlusMinusButton
                    text={"+"}
                    onClick={() =>
                      onChangeCBCTVolumeHardBonesLevel(
                        cbctVolumeHardBonesLevel + 50
                      )
                    }
                  />
                </div>
                <div style={{ margin: "5px 0" }}>
                  <div>Opacity soft</div>
                  <Slider
                    style={{ width: "55%" }}
                    allowCross={false}
                    min={0}
                    max={100}
                    value={cbctVolumeSoftBonesOpacity}
                    onChange={value => {
                      onChangeCBCTVolumeSoftBonesOpacity(value);
                    }}
                  />
                </div>
                <div style={{ margin: "5px 0" }}>
                  <div>Opacity hard</div>
                  <Slider
                    style={{ width: "55%" }}
                    allowCross={false}
                    min={0}
                    max={100}
                    value={cbctVolumeHardBonesOpacity}
                    onChange={value => {
                      onChangeCBCTVolumeHardBonesOpacity(value);
                    }}
                  />
                </div>
              </CBCTCuttingSliders>
            </CBCTToggles>
          )}

          {settings.isShowCBCT && cbctSurfaceMode && (
            <CBCTToggles>
              <hr style={{ width: "100%" }} />
              <CBCTAlignmentTitle>CBCT Surface</CBCTAlignmentTitle>
              <CBCTCuttingSliders>
                <div style={{ margin: "5px 0" }}>
                  <div>Bones Level</div>
                  <PlusMinusButton
                    text={"-"}
                    onClick={() =>
                      onChangeCBCTSurfaceBonesLevel(cbctSurfaceBonesLevel - 50)
                    }
                  />
                  <div>{cbctSurfaceBonesLevel}</div>
                  <PlusMinusButton
                    text={"+"}
                    onClick={() =>
                      onChangeCBCTSurfaceBonesLevel(cbctSurfaceBonesLevel + 50)
                    }
                  />
                </div>
                <div style={{ margin: "5px 0" }}>
                  <div>Opacity</div>
                  <Slider
                    style={{ width: "65%" }}
                    allowCross={false}
                    min={0}
                    max={100}
                    value={cbctSurfaceOpacity}
                    onChange={value => {
                      onChangeCBCTSurfaceOpacity(value);
                    }}
                  />
                </div>
                <div style={{ margin: "5px 0" }}>
                  <div>Reduction</div>
                  <Slider
                    style={{ width: "65%" }}
                    allowCross={false}
                    min={0}
                    max={100}
                    value={cbctSurfaceReduction}
                    onChange={value => {
                      onChangeCBCTSurfaceReduction(value);
                    }}
                  />
                </div>
              </CBCTCuttingSliders>
            </CBCTToggles>
          )}

          {settings.isArticulator && (
            <ArticulatorToggles>
              <hr style={{ width: "100%", margin: "15px 0 0 0" }} />
              <ArticulatorTitle>Articulator</ArticulatorTitle>
              <ToggleRow>
                <div>
                  <Switch
                    checked={settings.isShowHingeAxis}
                    onChange={onToggleHingeAxis}
                  />
                  &nbsp;&nbsp;Hinge axis
                </div>
                <div>
                  <Switch
                    checked={settings.isSnapToFit}
                    onChange={onToggleSnapToFit}
                  />
                  &nbsp;&nbsp;Snap to fit
                </div>
              </ToggleRow>
            </ArticulatorToggles>
          )}

          {toothInfo && (
            <ToothTransform
              wasmModule={wasmModule}
              toothInfo={toothInfo}
              rotationStep={toothRotationStep}
              translationStep={toothTranslationStep}
            />
          )}
        </Toggles>
      </ToggleControls>
    </Controls>
  );
};

type ViewType = "center" | "left" | "right" | "top" | "bottom";

const ViewControls = ({ wasmModule }) => {
  const [view, setView] = useState<ViewType>("center");

  const onClickChangeCameraViewLeft = () => {
    setView("left");
    wasmModule.webSwitchView(wasmModule.VIEWID.VIEW_LEFT);
  };

  const onClickChangeCameraViewRight = () => {
    setView("right");
    wasmModule.webSwitchView(wasmModule.VIEWID.VIEW_RIGHT);
  };

  const onClickChangeCameraViewBottom = () => {
    setView("bottom");
    wasmModule.webSwitchView(wasmModule.VIEWID.VIEW_BOTTOM);
  };

  const onClickChangeCameraViewTop = () => {
    setView("top");
    wasmModule.webSwitchView(wasmModule.VIEWID.VIEW_TOP);
  };

  const onClickChangeCameraViewCenter = () => {
    setView("center");
    wasmModule.webSwitchView(wasmModule.VIEWID.VIEW_CENTER);
  };

  return (
    <VControls>
      <div>
        <VControlButton
          selected={view === "bottom"}
          onClick={onClickChangeCameraViewBottom}
          imgSrc={CameraViewBottom}
          alt="Bottom view"
        />
      </div>
      <div>
        <VControlButton
          selected={view === "right"}
          onClick={onClickChangeCameraViewRight}
          imgSrc={CameraViewRight}
          alt="Right view"
        />
        <VControlButton
          selected={view === "center"}
          onClick={onClickChangeCameraViewCenter}
          imgSrc={CameraViewCenter}
          alt="Center view"
        />
        <VControlButton
          selected={view === "left"}
          onClick={onClickChangeCameraViewLeft}
          imgSrc={CameraViewLeft}
          alt="Left view"
        />
      </div>
      <div>
        <VControlButton
          selected={view === "top"}
          onClick={onClickChangeCameraViewTop}
          imgSrc={CameraViewTop}
          alt="Top view"
        />
      </div>
    </VControls>
  );
};

const VControlButton = ({ selected, imgSrc, alt, onClick }) => {
  return (
    <VControlButtonContainer
      style={{ backgroundColor: selected ? "#4a3626" : undefined }}
      type="button"
      onClick={onClick}
    >
      <img src={imgSrc} alt={alt} />
    </VControlButtonContainer>
  );
};

const PatientName = styled.div`
  font-weight: bold;
  color: white;
  &:hover {
    color: ${color.blue};
  }
`;

const ToggleRow = styled.div`
  display: flex;
  flex-flow: row;

  > div {
    flex: 1;
  }
`;

const VControls = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-right: 15px;

  > div {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    margin-bottom: 5px;
  }
`;

const VControlButtonContainer = styled.button`
  cursor: pointer;
  background-color: #333;
  opacity: 0.6;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  height: 45px;
  width: 45px;
  border-radius: 4px;
  border: none;

  &:hover {
    background-color: #4a3626;
  }

  &:focus {
    outline: none;
    background-color: #4a3626;
  }

  & + & {
    margin-left: 5px;
  }

  // when moving the image around in canvas,
  // sometimes these images get selected, which is v annoying
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const Controls = styled.div`
  position: absolute;
  right: 20px;
  top: 35px;
  color: black;
  z-index: 1;
  max-height: calc(100vh - 200px);

  border-radius: 5px;

  display: flex;
  flex-flow: row;

  div {
    color: white;
  }
`;

const Toggles = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  align-self: stretch;
  margin-top: 20px;
  font-size: 13px;

  > div + div {
    margin-top: 10px;
  }
`;

const ToggleControls = styled.div`
  background-color: #333;
  padding: 30px 15px 15px 20px;
  opacity: 0.6;
  display: flex;
  flex-flow: column;
  align-items: center;
  min-width: 280px;
  border-radius: 4px;
  overflow: auto;
  overflow-y: overlay;
`;

const ArticulatorToggles = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
`;

const ArticulatorTitle = styled.div`
  margin: 10px 0;
  align-self: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
`;

const CBCTToggles = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
`;

const CBCTTitle = styled.div`
  margin: 10px 0;
  align-self: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
`;

const CBCTAlignmentTitle = styled.div`
  margin: 10px 0;
  align-self: center;
  font-size: 12px;
  font-weight: bold;
`;

const CBCTCuttingSliders = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;

  > div {
    display: flex;
    justify-content: space-between;
  }
`;

const ModalButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin-top: 10px;
  align-self: center;

  font-weight: bold;
  color: white;
  text-decoration: underline;

  &:hover {
    color: ${color.blue};
    cursor: pointer;
  }
`;

export default ControlPanel;
