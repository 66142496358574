import React, { Component } from "react";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";

import { Field, reduxForm } from "redux-form";

const FORM_NAME = "add-address";

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    hintText={label}
    floatingLabelText={label}
    errorText={touched && error}
    {...input}
    {...custom}
  />
);
const reduxedForm = reduxForm({
  form: FORM_NAME,
});
export class AddressForm extends Component {
  onSubmit = values => {
    const { handleSubmit } = this.props;
    handleSubmit(values);
  };

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <div>
          <div>
            <Field
              id="line_1"
              name="line_1"
              hintText="Address Line 1"
              component={renderTextField}
              required
            />
          </div>
          <div>
            <Field
              id="line_2"
              name="line_2"
              hintText="Address Line 2"
              component={renderTextField}
            />
          </div>
          <div>
            <Field
              id="city"
              name="city"
              hintText="City"
              component={renderTextField}
              required
            />
          </div>
          <div>
            <Field
              id="state"
              name="state"
              hintText="State"
              component={renderTextField}
              required
            />
          </div>
          <div>
            <Field
              id="country"
              name="country"
              hintText="Country"
              component={renderTextField}
              required
            />
          </div>
          <div>
            <Field
              id="postal_code"
              name="postal_code"
              hintText="Zip Code"
              component={renderTextField}
              required
            />
          </div>
          <div>
            <RaisedButton
              disabled={this.props.submitting}
              type="submit"
              label="Set Address"
              primary
            />
          </div>
        </div>
      </form>
    );
  }
}

export default reduxedForm(AddressForm);
