/* eslint-disable */
// use the export feature in QUIP to generate the html
// escape \ backslashes with another \\
export default `
<!doctype html>
<html><head><title>Uniform Notice of Privacy Practices</title><meta charset="UTF-8"><link rel="stylesheet" type="text/css" href="http://fonts.googleapis.com/css?family=Crimson+Text:400,400italic,700,700italic|Roboto:400,700,700italic,400italic"><style>/*
 * Copyright 2014 Quip
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License. You may obtain
 * a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations
 * under the License.
 */

body {
    font-size: 15px;
    color: #333;
    background: #fff;
    padding: 60px 95px;
    max-width: 900px;
    margin: 0 auto;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern";
    font-kerning: normal;
    -moz-font-feature-settings: "kern";
    -webkit-font-feature-settings: "kern";
}

/* Headings */
h1, h2, h3, th {
    font-family: Roboto, sans-serif;
    font-weight: 700;
    margin: 0;
    margin-top: 1.25em;
    margin-bottom: 0.75em;
}

h1 {
    font-size: 35px;
    line-height: 42px;
}

h1:first-child {
    margin-top: 0;
}

h2 {
    font-size: 18px;
    line-height: 22px;
}

h3 {
    text-transform: uppercase;
    font-size: 13px;
    line-height: 16px;
}

/* Body text */
body, p, ul, ol, td {
    font-family: 'Crimson Text', serif;
    font-size: 16px;
    line-height: 20px;
}

blockquote, q {
    display: block;
    margin: 1em 0;
    font-style: italic;
}

blockquote a, q a {
    text-decoration: underline;
}

blockquote {
    padding-left: 10px;
    border-left: 4px solid #a6a6a6;
}

q {
    color: #a6a6a6;
    line-height: 40px;
    font-size: 24px;
    text-align: center;
    quotes: none;
}

q a {
    color: #a6a6a6;
}

code, pre {
    font-family: Consolas, "Liberation Mono", Menlo, "Courier Prime Web", Courier, monospace;
    background: #f3f3f3;
}

code {
    padding: 1px;
    margin: 0 -1px;
    border-radius: 3px;
}

pre {
    display: block;
    line-height: 20px;
    text-shadow: 0 1px white;
    padding: 5px 5px 5px 30px;
    white-space: nowrap;
    position: relative;
    margin: 1em 0;
}

pre:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    border-left: solid 1px #dadada;
}

/* Lists */
div[data-section-style="5"],
div[data-section-style="6"],
div[data-section-style="7"] {
    margin: 12px 0;
}

ul {
    padding: 0 0 0 40px;
}

ul li {
    margin-bottom: 0.4em;
}

/* Bulleted list */
div[data-section-style="5"] ul {
    list-style-type: disc;
}
div[data-section-style="5"] ul ul {
    list-style-type: circle;
}
div[data-section-style="5"] ul ul ul {
    list-style-type: square;
}
div[data-section-style="5"] ul ul ul ul {
    list-style-type: disc;
}
div[data-section-style="5"] ul ul ul ul ul {
    list-style-type: circle;
}
div[data-section-style="5"] ul ul ul ul ul ul {
    list-style-type: square;
}

/* Numbered list */
div[data-section-style="6"] ul {
    list-style-type: decimal;
}
div[data-section-style="6"] ul ul {
    list-style-type: lower-alpha;
}
div[data-section-style="6"] ul ul ul {
    list-style-type: lower-roman;
}
div[data-section-style="6"] ul ul ul ul {
    list-style-type: decimal;
}
div[data-section-style="6"] ul ul ul ul ul {
    list-style-type: lower-alpha;
}
div[data-section-style="6"] ul ul ul ul ul ul {
    list-style-type: lower-roman;
}

/* Checklist */
div[data-section-style="7"] ul {
    list-style-type: none;
}

div[data-section-style="7"] ul li:before {
    content: "\\2610";
    position: absolute;
    display: inline;
    margin-right: 1.2em;
    margin-left: -1.2em;
}

div[data-section-style="7"] ul li.parent:before {
    content: "";
}

div[data-section-style="7"] ul li.parent {
    font-weight: bold;
}

div[data-section-style="7"] ul li.checked {
    text-decoration: line-through;
}

div[data-section-style="7"] ul li.checked:before {
    content: "\\2611";
    text-decoration: none;
}

/* Tables */
div[data-section-style="8"] {
    margin: 12px 0;
}

table {
    border-spacing: 0;
    border-collapse: separate;
    border: solid 1px #7c7c7c;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .25);
    table-layout: fixed;
    position: relative;
}

table th, table td {
    padding: 2px 2px 0;
    min-width: 1.5em;
    word-wrap: break-word;
}

table th {
    border-bottom: 1px solid #ccc;
    background: #f0f0f0;
    font-weight: bold;
    vertical-align: bottom;
    color: #3a4449;
    text-align: center;
}

table td {
    padding-top: 0;
    border-left: 1px solid #e1e1e1;
    border-top: 1px solid #e1e1e1;
    vertical-align: top;
}

table td.bold {
    font-weight: bold;
}

table td.italic {
    font-style: italic;
}

table td.underline {
    text-decoration: underline;
}

table td.strikethrough {
    text-decoration: line-through;
}

table td.underline.strikethrough {
    text-decoration: underline line-through;
}

table td:first-child {
    border-left: hidden;
}

table tr:first-child td {
    border-top: hidden;
}

/* Images */
div[data-section-style="11"] {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

div[data-section-style="11"][data-section-float="0"] {
    clear: both;
    text-align: center;
}

div[data-section-style="11"][data-section-float="1"] {
    float: left;
    clear: left;
    margin-right: 20px;
}

div[data-section-style="11"][data-section-float="2"] {
    float: right;
    clear: right;
    margin-left: 20px;
}

div[data-section-style="11"] img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: auto;
}

hr {
    width: 70px;
    margin: 20px auto;
}
</style></head><body><h1 id='LfXACAt7j1X'>Uniform Notice of Privacy Practices</h1>

<p id='LfXACA3ShIL'>This notice describes how your health information may be used and disclosed and how you can get access to this information. Please review it carefully. The privacy of your health information is important to us.</p>

<h2 id='LfXACA3QQnW'><b>Our Legal Duty</b></h2>

<p id='LfXACAmRseZ'>Federal and state laws require us to maintain the privacy of your health information. We are also required to provide this notice about our office’s privacy practices, our legal duties and your rights regarding your health information. We are required to follow the practices that are outlined in this notice while it is in effect This notice takes effect June 26, 2017 and will remain in effect until we replace it.</p>

<p id='LfXACApOch6'>We reserve the right to change our privacy practices and the terms of this notice at any time, provided such changes are permitted by applicable law. We reserve the right to make changes in our privacy practices and the new terms of our notice effective for all health information that we maintain, including health information we created or received before we made the changes. Before we make a significant change in our privacy practices, we will change this notice and make the new notice available upon request. For more information about our privacy practices or additional copies of this notice, please contact us (contact information below).</p>

<h2 id='LfXACAYATV5'><b>Uses and Disclosures of Health Information</b></h2>

<p id='LfXACA4NgSO'>We use and disclose health information about you for treatment, payment and health care operations.</p>

<p id='LfXACACJ2B6'>For example:</p>

<p id='LfXACABTzsG'><b>Treatment</b></p>

<p id='LfXACAHs2S6'>We disclose medical information to our employees and others who are involved in providing the care you need. We may use or disclose your health information to another dentist or other health care providers providing treatment that we do not provide. We may also share your health information with a pharmacist in order to provide you with a prescription or with a laboratory that performs tests or fabricates dental prostheses or orthodontic appliances.</p>

<p id='LfXACAfxcAu'><b>Payment</b></p>

<p id='LfXACAQlbHf'>We may use and disclose your health information to obtain payment for services we provide to you, unless you request that we restrict such disclosure when you have paid out-of-pocket and in full for services rendered.</p>

<p id='LfXACAQH95X'><b>Health</b><b> C</b><b>are Operations</b></p>

<p id='LfXACAmcrh2'>We may use and disclose your health information in connection with our health care operations. Health care operations include, but are not limited to, quality assessment and improvement activities, reviewing the competence or qualifications of health care professionals, evaluating practitioner and provider performance, conducting training programs, accreditation, certification, licensing or credentialing activities.</p>

<p id='LfXACAiqRju'><b>Your Authorization</b></p>

<p id='LfXACAko8dH'>In addition to our use of your health information for treatment, payment or health care operations, you may give us written authorization to use your health information or to disclose it to anyone for any purpose. If you give us an authorization, you may revoke it in writing at any time. Your revocation will not affect any use or disclosures permitted by your authorization while it is in effect. Unless you give us a written authorization, we cannot use or disclose your health information for any reason except those described in this notice.</p>

<p id='LfXACAy6xkm'>Notice of Privacy Practices (continued)</p>

<p id='LfXACAtvsIu'><b>To Your Family and Friends</b></p>

<p id='LfXACAACt8K'>We must disclose your health information to you, as described in the Patient Rights section of this notice. You have the right to request restrictions on disclosure to family members, other relatives, close personal friends or any other person identified by you.</p>

<p id='LfXACAGypKl'><b>Unsecured Emails</b></p>

<p id='LfXACAMnXiX'>We will not send you unsecured emails pertaining to your health information without your prior authorization. If you do authorize communications via unsecured email, you have the right to revoke the authorization at any time.</p>

<p id='LfXACAWV0If'><b>Persons Involved in Care</b></p>

<p id='LfXACAKCvSn'>We may use or disclose health information to notify, or assist in the notification of (including identifying or locating) a family member, your personal representative or another person responsible for your care, of your location, your general condition or your death. If you are present, then prior to use or disclosure of your health information, we will provide you with an opportunity to object to such uses or disclosures. In the event of your incapacity or emergency circumstances, we will disclose health information based on a determination using our professional judgment disclosing only health information that is directly relevant to the person’s involvement in your health care. We will also use our professional judgment and our experience with common practice to make reasonable inferences of your best interest in allowing a person to pick up filled prescriptions, medical supplies, X-rays or other similar forms of health information.</p>

<p id='LfXACA6JNlu'><b>Marketing Health-Related Services</b></p>

<p id='LfXACAwL2O3'>We may contact you about products or services related to your treatment, case management or care coordination or to propose other treatments or health-related benefits and services in which you may be interested. We may also encourage you to purchase a product or service when you visit our office. We will not otherwise use or disclose your health information for marketing purposes without your written authorization. We will disclose whether we receive payments for marketing activity you have authorized.</p>

<p id='LfXACA5c936'><b>Change of Ownership</b></p>

<p id='LfXACAq2XC8'>If this dental practice is sold or merged with another practice or organization, your health records will become the property of the new owner. However, you may request that copies of your health information be transferred to another dental practice.</p>

<p id='LfXACAVrHeT'><b>Required by Law</b></p>

<p id='LfXACASn7Aj'>We may use or disclose your health information when we are required to do so by law.</p>

<p id='LfXACAG8u6E'><b>Public Health</b></p>

<p id='LfXACAAWnXe'>We may, and are sometimes legally obligated to, disclose your health information to public health agencies for purposes related to preventing or controlling disease, injury or disability; reporting abuse or neglect; reporting domestic violence; reporting to the Food and Drug Administration problems with products and reactions to medications; and reporting disease or infection exposure. Upon reporting suspected elder or dependent adult abuse or domestic violence, we will promptly inform you or your personal representative unless we believe the notification would place you at risk of harm or would require informing a personal representative we believe is responsible for the abuse or harm.</p>

<p id='LfXACAVi8yg'>Notice of Privacy Practices (continued)</p>

<p id='LfXACAO8cG1'><b>Abuse or Neglect</b></p>

<p id='LfXACAO3m9F'>We may disclose your health information to appropriate authorities if we reasonably believe that you are a possible victim of abuse, neglect or domestic violence or the possible victim of other crimes. We may disclose your health information to the extent necessary to avert a serious threat to your health or safety or the health or safety of others.</p>

<p id='LfXACAGz1B2'><b>National Security</b></p>

<p id='LfXACA3oQDY'>We may disclose to military authorities the health information of Armed Forces personnel under certain circumstances. We may disclose to authorized federal officials health information required for lawful intelligence, counterintelligence and other national security activities. We may disclose to correctional institutions or law enforcement officials having lawful custody of protected health information of inmates or patients under certain circumstances.</p>

<p id='LfXACAKroFb'><b>Appointment Reminders</b></p>

<p id='LfXACA4AgZy'>We may contact you to provide you with appointment reminders via the mobile application, text messaging, voicemail. We may also leave a message with the person answering the phone if you are not available.</p>

<p id='LfXACAJr5is'><b>Sign</b><b>-</b><b>In Sheet and Announcement</b></p>

<p id='LfXACARgmVd'>Upon arriving at our office, we may use and disclose medical information about you by asking that you sign an intake sheet at our front desk. We may also announce your name when we are ready to see you.</p>

<h2 id='LfXACAaCVln'>Patient Rights</h2>

<p id='LfXACAF9akd'><b>Access</b></p>

<p id='LfXACAUrorN'>You have the right to look at or get copies of your health information, with limited exceptions. You may request that we provide copies in a format other than photocopies. We will use the format you request unless we cannot practicably do so. You must make a request in writing to obtain access to your health information. You may obtain a form to request access by contacting our office. We will charge you a reasonable cost-based fee for expenses such as copies and staff time. You may also request access by sending us a letter. If you request copies, there may be a charge for time spent. If you request an alternate format, we will charge a cost-based fee for providing your health information in that format. If you prefer, your licensed healthcare provider will prepare a summary or an explanation of your health information for a fee. Contact us for a full explanation of our fee structure.</p>

<p id='LfXACAvGTdl'><b>Disclosure Accounting</b></p>

<p id='LfXACAzme4Q'>You have a right to receive a list of instances in which we disclosed your health information for purposes other than treatment, payment, health care operations and certain other activities for the last six years. If you request this accounting more than once in a 12-month period, we may charge you a reasonable cost-based fee for responding to these additional requests.</p>

<p id='LfXACAcfh2e'><b>Restriction</b></p>

<p id='LfXACAVC9MP'>You have the right to request that we place additional restrictions on our use or disclosure of your health information. We are not required to agree to these additional restrictions, but if we do, we will abide by our agreement (except in emergency). </p>

<p id='LfXACAS0YN0'><b>Alternative Communication</b></p>

<p id='LfXACA3MTO6'>You have the right to request that we communicate with you about your health information by alternative means or to alternative locations. You must make your request in writing. Your request must specify the alternative means or location and provide satisfactory explanation of how payments will be handled under the alternative means or location you request.</p>

<p id='LfXACApyn8u'><b>Breach Notification</b></p>

<p id='LfXACAlTg1r'>In the event your unsecured protected health information is breached, we will notify you as required by law. In some situations, you may be notified by our business associates.</p>

<p id='LfXACA5miYT'><b>Amendment</b></p>

<p id='LfXACAwtBxk'>You have the right to request that we amend your health information. (Your request must be in writing, and it must explain why the information should be amended). We may deny your request under certain circumstances.</p>

If you want more information about our privacy practices or have questions or concerns, please contact us at:<br/>

<br>Contact: Kjeld Aamodt DDS MS<br/>

<p id='LfXACAdExYA'>Telephone: (415) 212-8039</p>

<p id='LfXACAscvyX'>Email: careteam@uniformteeth.com</p>

<p id='LfXACActaBx'>Address: 999 Sutter Street, Suite 102, San Francisco, CA 94109</p>

<p id='LfXACAhhh7H'>If you are concerned that we may have violated your privacy rights, or you disagree with a decision we made about access to your health information or in response to a request you made to amend or restrict the use or disclosure of your health information or to have us communicate with you by alternative means or at alternative locations, you may send a written complaint to our office or to the U.S. Department of Health and Human Services, Office of Civil Rights. We will not retaliate against you for filing a complaint.</p>

<p id='LfXACAx180v'><b>Language Assistance</b></p>

<i>English:</i><br/>

Our dental practice will provide language assistance services free-of-charge to individuals who do not speak English well enough to discuss the dental care we are providing.<br/>

<br/>

<i>Spanish:</i><br/>

Nuestro consultorio dental les proporcionará servicios de asistencia lingüística gratuitos a los individuos que no hablen inglés con suficiente fluidez para discutir la atención dental que proporcionamos.<br/>

<br/>

<i>Chinese:</i><br/>

我们的牙科业务将为英语不太流利的人士提供免费的语言协助服务，以方便讨论我们提供的牙齿护理服务。<br/>

<br/>

<i>Vietnamese:</i><br/>

Thực hành nha khoa của chúng tôi sẽ cung cấp các dịch vụ hỗ trợ ngôn ngữ miễn phí cho những người không có khả năng nói tiếng Anh đủ tốt để thảo luận việc chăm sóc răng miệng mà chúng tôi đang cung cấp.<br/>

<br/>

<i>Tagalog:</i><br/>

Ang aming dental na kasanayan ay magbibigay ng walang bayad na mga serbisyong tulong na wika sa mga indibidwal na hindi nakakapagsalita ng maayos na Ingles upang talakayin ang ibinibigay naming dental na pangangalaga.<br/>

<br/>

<i>Korean:</i><br/>

저희 치과는 저희가 제공하는 치과 치료에 대해 영어로 논의하기가 불편하신 분들을 위해 무료 언어 지원 서비스를 제공할 것입니다.<br/>

<br/>

<i>Armenian:</i><br/>

Մեր ատամնաբուժական պրակտիկան կտրամադրի անվճար լեզվական ծառայություններ բոլոր այն անձանց ովքեր անգլերենին բավարար չեն տիրապետում մեր կողմից տրամադրվող ատամնաբուժական խնամքի շուրջ հարցեր քննարկելու:<br/>

<br/>

<i>Russian:</i><br/>

Наша стоматологическая клиника бесплатно предоставляет клиентам, которые не достаточно хорошо говорят на английском языке, услуги переводчика, чтобы помочь им обсудить предоставляемую нами стоматологическую помощь.<br/>

<br/>

<i>Japanese:</i><br/>

当社の歯科治療では提供している歯科ケアに関して話し合える程度の英語力のない方に無料で言語サポートサービスを提供していま。<br/>

<br/>

<i>Arabic:</i><br/>

سوف تقدم عيادة طب الأسنان مساعدة لغوية مجانية لأولئك الذين لا يجيدون الإنكليزية من أجل مناقشة خدمات العناية بالأسنان التي نقدمها.<br/>

<br/>

<i>Punjabi:</i><br/>

ਉਰ ਡੈਂਟਲ ਪ੍ਰੈਕਟਿਸ ਵਿਲ ਪ੍ਰੋਵੀਦੇ ਲੈਂਗੂਏਜ ਅੱਸੀਸਟੈਂਸ ਸਰਵਿਸਜ਼ ਫ੍ਰੀ-ਓਫ-ਚਾਰਜ ਤੋਂ ਇੰਡਿਵਿਦੁਲਸ ਹੂ ਦੋ ਨ ਸਪੈੱਕ ਇੰਗਲਿਸ਼ ਵੈੱਲ ਏਨੌਘ ਤੋਂ ਡਿਸਕਸ ਥੇ ਡੈਂਟਲ ਚਾਰੇ ਵੀ ਰੇ ਪ੍ਰੋਵੀਡੀਨਗ.<br/>

<br/>

<i>Mon-Khmer:</i><br/>

គ្លីនិកធ្មេញយើងខ្ញុំនឹងផ្ដល់នូវសេវាជំនួយផ្នែកភាសាដោយឥតគិតថ្លៃជូនដល់អតិថិជនម្នាក់ៗដែលមិនចេះនិយាយភាសាអង់ច្បាស់លាស់ ដើម្បីពិគ្រោះពិភាក្សាគ្នាអំពីបញ្ហាសេវាតំហែទាំធ្មេញដែលយើងខ្ញុំកំពុងផ្ដល់ជូន។<br/>

<br/>

<i>Hmong:</i><br/>

Ang aming pagsasanay ukol sa ngipin o dental practice ay magbibigay ng libreng mga serbisyong tulong sa mga indibiduwal na hindi masyadong nakakapagsalita ng Ingles upang talakayin ang pangangalaga sa ngipin na aming ibinibigay.<br/>

<br/>

<i>Hindi:</i><br/>

हमारे दंत चिकिच्छालय के प्रभारी, जो व्यक्ती अच्छी तरह ईंग्लीश बोल नही सकते है उनको, हम जो दंत चिकिच्छा देखभाल प्रदान कर रहे है उसके बारेमें बात करनेके लिये बीना कोई फ़ीस भाषा सहायता सेवाएं प्रदान करेंगे |<br/>

<br/>

<i>Thai:</i><br/>

แนวปฏิบัติด้านทันตกรรมของเราจะให้บริการช่วยเหลือด้านภาษาฟรีแก่บุคคลที่พูดภาษาอังกฤษไม่ชำนาญเพียงพอที่จะหารือเกี่ยวกับบริการทันตกรรมของเรา<br/>

<br/>

<h2 id='LfXACATr6Up'><b>Acknowledgement of Receipt of Notice of Privacy Practices</b></h2>

<p id='LfXACA6EqZg'><i>You May Refuse to Sign This Acknowledgement</i></p>

<b><i>By clicking 'Continue'  I certify to have read and I understand the Uniform Notice of Privacy Practices.</i></b><br/>

</body></html>
`;
