import React from "react";
import color from "src/styles/color";

type Props = {
  fill?: string;
};

const Time = ({ fill = color.gray3 }) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.02425 5.88236H4.74051C4.39671 5.88236 4.11768 5.55682 4.11768 5.15571V2.49135C4.11768 2.09025 4.39671 1.76471 4.74051 1.76471C5.08432 1.76471 5.36335 2.09025 5.36335 2.49135V4.42907H7.02425C7.36806 4.42907 7.64709 4.75461 7.64709 5.15571C7.64709 5.55682 7.36806 5.88236 7.02425 5.88236Z"
      fill={fill}
    />
    <path
      d="M5 10C2.24308 10 0 7.75692 0 5C0 2.24308 2.24308 0 5 0C7.75692 0 10 2.24308 10 5C10 7.75692 7.75692 10 5 10ZM5 1.15385C2.87923 1.15385 1.15385 2.87923 1.15385 5C1.15385 7.12077 2.87923 8.84615 5 8.84615C7.12077 8.84615 8.84615 7.12077 8.84615 5C8.84615 2.87923 7.12077 1.15385 5 1.15385Z"
      fill={fill}
    />
  </svg>
);

export default Time;
