import React, { useState } from "react";
import { DateTime } from "luxon";
import styled, { css, cx } from "react-emotion";

import { useQuery } from "src/utils/http/gqlQuery";
import Button, { ButtonRow } from "src/shared/Button";

type Props = {
  userID: string;
  onSelect: (id: string) => void;
};

const query = `
query GetPatientFiles($userID: ID!) {
  files: files(userId: $userID, types: ["TreatmentPlanZip"]) {
    id filename type description size internal createdAt
  }
}
`;

const FileModal: React.FC<Props> = ({ userID, onSelect }) => {
  const [selected, setSelected] = useState<{ id: string } | null>(null);
  const [{ data }] = useQuery({
    query,
    variables: { userID },
  });

  const files = (data ? data.files : []).sort((a, b) =>
    b.createdAt.localeCompare(a.createdAt)
  );

  return (
    <Wrapper>
      <div>
        <h5>Pick a file</h5>
        <p>Choose a treatment planning file to pre-fill the form</p>
      </div>

      <Grid>
        <div>
          <small>Filename</small>
        </div>
        <div>
          <small>Description</small>
        </div>
        <div>
          <small>Uploaded at</small>
        </div>
      </Grid>
      {files.map(f => (
        <Grid
          key={f.id}
          className={cx([hoverableCss, f === selected && selectedCss])}
          onClick={() => setSelected(f)}
        >
          <div>{f.filename}</div>
          <div>{f.description}</div>
          <div>{DateTime.fromISO(f.createdAt).toLocaleString()}</div>
        </Grid>
      ))}

      <ButtonRow position="center" style={{ marginTop: 40 }}>
        <Button
          kind="primary"
          disabled={!selected}
          onClick={() => selected && onSelect(selected.id)}
        >
          Pre-fill
        </Button>
      </ButtonRow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 800px;
  padding: 40px;

  h5 {
    margin: 0;
  }

  > div:first-of-type {
    text-align: center;
    padding: 0 0 40px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  transition: all 0.3s;
  border-radius: 3px;

  > div {
    padding: 10px;
  }
`;

const hoverableCss = css`
  cursor: pointer;
  &:hover {
    background: #f4f4f4;
  }
`;

const selectedCss = css`
  background: #f4f4f4;
  font-weight: bold;
`;

export default FileModal;
