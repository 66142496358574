import pipe from "lodash/fp/pipe";
import split from "lodash/fp/split";
import map from "lodash/fp/map";
import join from "lodash/fp/join";
import capitalize from "lodash/fp/capitalize";
import fromPairs from "lodash/fp/fromPairs";
import zip from "lodash/fp/zip";
import mergeWith from "lodash/fp/mergeWith";
import merge from "lodash/fp/merge";
import uniq from "lodash/fp/uniq";

import { TaskFilter } from "./types";
import { FiltersValue } from "./Filters/FiltersPanel/types";

export const transformKeyToTitle: Identity<string> = pipe(
  split("_"),
  map(capitalize),
  join(" ")
);

export const createTitlesDictionaryFromKeys = <Keys extends string>(
  keys: ReadonlyArray<Keys>
): Record<Keys, string> => fromPairs(zip(keys, map(transformKeyToTitle, keys)));

export const mergeFilters = (
  uiFilters: TaskFilter,
  filterPanelValue: FiltersValue,
  routeFilter: TaskFilter
) => {
  const customizer = (
    left: TaskFilter[keyof TaskFilter],
    right: TaskFilter[keyof TaskFilter]
  ) => {
    Array.isArray(left) && Array.isArray(right)
      ? uniq([...left, ...right])
      : merge(left, right);
  };

  return mergeWith(
    { ...uiFilters, ...routeFilter },
    filterPanelValue,
    customizer
  );
};
