import React, { useState } from "react";
import { isNil } from "lodash";
import { DateTime } from "luxon";
import styled from "react-emotion";
import { notification } from "antd";

import { useQuery } from "src/utils/http/gqlQuery";
import color from "src/styles/color";
import Box from "src/shared/Box";
import money from "src/utils/money";
import { formatPromoAmount } from "src/utils/promotion";
import Radio from "src/shared/Icons/Radio";
import { Order, PaymentMethod } from "src/types/gql";
import Button, { ButtonRow } from "src/shared/Button";
import Modal from "src/shared/Modal";

import { usePaymentMethods, useCreateFinancingPlan } from "../../query";
import { orderGql } from "../query";
import PaymentMethodSelect from "../../PaymentMethodSelect";
import { claimAmount, claimTitle } from "src/utils/insurance";
import {
  PAYMENT_PLANS,
  calculateDepsoitAlreadyMet,
  calculateOrderTotal,
  calculateInsuranceClaims,
} from "src/shared/calculateOrder";

const Content = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;

  height: 500px;
  width: 800px;
`;

const PaymentPlansContainer = styled.div`
  width: 500px;
  display: flex;
  flex-flow: column;
  border-right: 1px solid ${color.border};
  padding: 24px;
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
`;

const Footer = styled.div`
  padding: 24px;
  border-top: 1px solid ${color.border};
`;

const SummaryContent = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  overflow-y: scroll;
  overflow: -moz-scrollbars-vertical;
  padding: 24px;

  hr {
    width: 100%;
    border-bottom: 1px solid ${color.border};
    margin-top: 24px;
  }
`;

type Props = {
  onClose: () => void;
  order: Order;
};

const AddFinancingModal: React.FC<Props> = ({ onClose, order }) => {
  const [screen, setScreen] = useState<"plans" | "PMs">("plans");
  const [plan, setPlan] = useState<string | null>(null);
  const [pm, setPM] = useState<PaymentMethod | null>(null);
  const [disablePlanButton, setDisablePlanButton] = useState(false);
  const [fetchingPaymentMethods, , paymentMethods] = usePaymentMethods(
    order.user.id
  );

  const [, refetch] = useQuery({
    query: orderGql,
    variables: { userID: order.user.id },
    pause: true,
  });

  const startDate = DateTime.local().plus({ days: 30 });
  const createPlan = useCreateFinancingPlan();

  const depositAlreadyMet = calculateDepsoitAlreadyMet(order);
  const orderTotal = calculateOrderTotal(order);

  const cards = fetchingPaymentMethods
    ? []
    : paymentMethods.filter(
        method => method.type === "card" && method.cardDetails
      );
  const chosenPlan = PAYMENT_PLANS.find(p => p.name === plan);

  const onContinue = async () => {
    if (screen === "plans") {
      setScreen("PMs");
      return;
    }

    // onSubmit stuff here
    if (!chosenPlan || !pm) {
      return;
    }

    setDisablePlanButton(true);

    const res = await createPlan({
      input: {
        orderID: order.id,
        start: startDate.toISO(),
        paymentMethodID: pm.id,
        rate: chosenPlan.rate,
        monthlyAmount: chosenPlan.monthlyAmount(orderTotal),
        deposit: chosenPlan.deposit(orderTotal),
      },
    });

    if (res.error) {
      setDisablePlanButton(false);

      notification.error({
        message: `There was an error creating the payment plan: ${
          res.error.message
        }`,
      });
      return;
    }

    notification.success({ message: "Payment plan added" });
    refetch();
    onClose();
  };

  const amountFinanced = chosenPlan
    ? order.total -
      chosenPlan.deposit(orderTotal) -
      calculateInsuranceClaims(order)
    : 0;

  const orderInterestTotal = chosenPlan ? amountFinanced * chosenPlan.rate : 0;

  const isPlanButtonDisabled =
    disablePlanButton || screen === "plans" ? isNil(plan) : isNil(pm);

  return (
    <Modal onClose={onClose}>
      <Box title="Payment Plans">
        <Content>
          {screen === "plans" && (
            <PaymentPlans selected={plan} onChange={setPlan} />
          )}
          {screen === "PMs" && (
            <PaymentMethods cards={cards} selected={pm} onChange={setPM} />
          )}
          <SummaryContainer>
            <SummaryContent>
              <SummaryTitle>Summary</SummaryTitle>
              <LineItem>
                <div>Order Subtotal</div>
                <div>{money(order.subtotal)}</div>
              </LineItem>
              {depositAlreadyMet !== 0 && (
                <LineItem>
                  <div>Existing Deposit</div>
                  <div>{money(depositAlreadyMet)}</div>
                </LineItem>
              )}
              {order.discounts.map(
                ({ promotion: { id, code, type, amount } }) => (
                  <LineItem key={id}>
                    <div>Promo Code</div>
                    <div>-{formatPromoAmount(amount, type)}</div>
                  </LineItem>
                )
              )}
              {order.adjustments.map(({ id, amount, reason }) => (
                <LineItem key={id}>
                  <div>Order Adjustment</div>
                  <div>-{money(amount)}</div>
                </LineItem>
              ))}
              {order.claims.map(c => (
                <LineItem key={c.id}>
                  <div>{claimTitle(c)}</div>
                  <div>-{money(claimAmount(c))}</div>
                </LineItem>
              ))}
              <hr />
              {chosenPlan && (
                <>
                  <LineItem>
                    <div>Order Total</div>
                    <div>{money(orderTotal)}</div>
                  </LineItem>
                  <LineItem>
                    <div>Deposit due today</div>
                    <div>
                      {chosenPlan &&
                        money(
                          Math.max(
                            0,
                            chosenPlan.deposit(orderTotal) - depositAlreadyMet
                          )
                        )}
                    </div>
                  </LineItem>
                  <LineItem>
                    <div>Amount Financed</div>
                    <div>{money(amountFinanced)}</div>
                  </LineItem>
                  {chosenPlan.rate > 0 && (
                    <LineItem>
                      <div>
                        Interest ({(chosenPlan.rate * 100).toFixed(2)}%)
                      </div>
                      <div>{money(orderInterestTotal)}</div>
                    </LineItem>
                  )}
                  <hr />
                  <LineItem>
                    <div>Patient Responsibility</div>
                    <div>{money(orderTotal + orderInterestTotal)}</div>
                  </LineItem>
                </>
              )}
              <BoldLineItem>
                <div>Total due today</div>
                <div>
                  {chosenPlan &&
                    money(
                      Math.max(
                        0,
                        chosenPlan.deposit(orderTotal) - depositAlreadyMet
                      )
                    )}
                </div>
              </BoldLineItem>
              <BoldLineItem>
                <div>Monthly payment</div>
                <div>
                  {chosenPlan && money(chosenPlan.monthlyAmount(orderTotal))}
                </div>
              </BoldLineItem>
              <div style={{ margin: "24px 0", color: color.gray3 }}>
                Patients will be charged on a monthly basis beginning{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: color.gray3,
                  }}
                >
                  {startDate.toFormat("LLLL d, yyyy")}
                </span>
                .
              </div>
            </SummaryContent>

            <Footer>
              <ButtonRow position="right">
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  kind="primary"
                  onClick={onContinue}
                  disabled={isPlanButtonDisabled}
                >
                  {screen === "plans" ? "Continue" : "Add Plan"}
                </Button>
              </ButtonRow>
            </Footer>
          </SummaryContainer>
        </Content>
      </Box>
    </Modal>
  );
};

const SummaryTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 24px;
`;

const LineItem = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  & + & {
    margin-top: 8px;
  }

  hr + & {
    margin-top: 24px;
  }
`;

const BoldLineItem = styled(LineItem)`
  font-weight: bold;
  margin-top: 8px;

  hr + & {
    margin-top: 24px;
  }
`;

const PaymentPlanOption = styled.button`
  display: flex;
  flex-flow: row;
  padding: 16px 18px;
  background: none;
  border: 1px solid ${color.border};
  border-radius: 2px;
  cursor: pointer;

  & + & {
    margin-top: 14px;
  }

  &:hover {
    background: ${color.background};
  }
`;

const PaymentPlanText = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-left: 18px;
`;

const BoldText = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
  text-align: left;
`;

const GrayText = styled.div`
  color: ${color.gray3};
  text-align: left;
`;

type PaymentPlansProps = {
  selected: string | null;
  onChange: (string) => void;
};

const PaymentPlans: React.FC<PaymentPlansProps> = ({ selected, onChange }) => {
  return (
    <PaymentPlansContainer>
      <BoldText>Choose Payment Plan</BoldText>
      {PAYMENT_PLANS.map(({ name, description }) => (
        <PaymentPlanOption
          type="button"
          key={name}
          onClick={() => onChange(name)}
        >
          <Radio checked={selected === name} />
          <PaymentPlanText>
            <BoldText>{name}</BoldText>
            <GrayText>{description}</GrayText>
          </PaymentPlanText>
        </PaymentPlanOption>
      ))}
    </PaymentPlansContainer>
  );
};

const PaymentMethodsContainer = styled.div`
  width: 500px;
  display: flex;
  flex-flow: column;
  border-right: 1px solid ${color.border};
`;

const PMTitle = styled.div`
  font-weight: bold;
  padding: 16px;
  border-bottom: 1px solid ${color.border};
`;

const PaymentMethods = ({ selected, onChange, cards }) => {
  return (
    <PaymentMethodsContainer>
      <PMTitle>Select Payment Method</PMTitle>
      <PaymentMethodSelect
        onSelect={onChange}
        selected={selected}
        cards={cards}
      />
    </PaymentMethodsContainer>
  );
};

export default AddFinancingModal;
