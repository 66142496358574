import { V, S } from "src/shared-styles";
import {
  actionColor as toothChartActionColor,
  toothHeight,
  gumBackgroundColor,
} from "./tooth-chart-style";
const selectRegion = {
  zIndex: 2,
  position: "absolute",
  height: "50%",
  width: "50%",
  // boxSizing: 'border-box',
  // border: '1px solid purple',
  // background: 'rgba(100, 255, 255, .3)',
};
const displayRegion = {
  position: "absolute",
  zIndex: 1,
  borderRadius: 0,
  border: `1px solid ${V.colors.borderColor}`,
  background: V.colors.white,
  boxSizing: "border-box",
};
const toothDisplayNum = {
  position: "absolute",
  zIndex: 1,
  height: 8,
  width: "100%",
  fontSize: 8,
  textAlign: "center",
};

const display = {
  centerLeft: "30%",
  centerHeight: "20%",
  centerWidth: "40%",
  sideHeight: "40%",
  sideWidth: "20%",
  sideTop: "30%",
};
export const actionColor = toothChartActionColor;

export default {
  V,
  S,
  actionColor,
  toothWrapper: {
    width: `${100 / 8}%`,
    height: toothHeight,
    display: "inline-block",
    position: "relative",
  },
  displayContainer: {
    background: gumBackgroundColor,
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    borderLeft: `1px solid ${V.colors.white}`,
    borderRight: `1px solid ${V.colors.white}`,
  },
  selectableRegionsContainer: {},
  selectRegion1: {
    ...selectRegion,
    top: 0,
    left: 0,
  },
  selectRegion2: {
    ...selectRegion,
    top: 0,
    right: 0,
  },
  selectRegion3: {
    ...selectRegion,
    bottom: 0,
    left: 0,
  },
  selectRegion4: {
    ...selectRegion,
    bottom: 0,
    right: 0,
  },
  tooth: {
    upper: {
      ...displayRegion,
      top: "14%",
      left: display.centerLeft,
      width: display.centerWidth,
      height: display.centerHeight,
    },
    middle: {
      ...displayRegion,
      top: "40%",
      left: display.centerLeft,
      width: display.centerWidth,
      height: display.centerHeight,
    },
    lower: {
      ...displayRegion,
      bottom: "14%",
      left: display.centerLeft,
      width: display.centerWidth,
      height: display.centerHeight,
    },
    left: {
      ...displayRegion,
      height: display.sideHeight,
      width: display.sideWidth,
      top: display.sideTop,
      left: "5%",
    },
    right: {
      ...displayRegion,
      height: display.sideHeight,
      width: display.sideWidth,
      top: display.sideTop,
      right: "5%",
    },
    action: {
      caries: {
        width: "70%",
        height: "70%",
        top: "15%",
        left: "15%",
        position: "absolute",
        borderRadius: "40%",
        background: actionColor.Caries,
      },
      gumDisease: {
        position: "absolute",
        width: "calc(100% - 2px)",
        height: "100%",
        background: actionColor["Gum Disease"],
        zIndex: 1,
      },
      attachments: {
        width: "80%",
        height: "60%",
        top: "17%",
        left: "10%",
        position: "absolute",
        borderRadius: 2,
        background: actionColor.Attachments,
        zIndex: 1,
      },
      IPR: {
        width: "70%",
        height: "90%",
        top: "5%",
        left: "15%",
        position: "absolute",
        borderRadius: 2,
        background: actionColor.IPR,
      },
      Elastics: {
        position: "absolute",
        width: "100%",
        height: "100%",
        border: `2px ${actionColor.Elastics}`,
        borderStyle: "solid",
        boxSizing: "border-box",
        textAlign: "center",
        fontSize: 12,
        zIndex: 2,
        color: actionColor.Elastics,
        fontWeight: V.fontWeightHeavy,
      },
    },
  },
  toothDisplayNum: {
    U: {
      ...toothDisplayNum,
      top: 3,
    },
    L: {
      ...toothDisplayNum,
      bottom: 3,
    },
  },
};
