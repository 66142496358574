import React from "react";
import { createStructuredSelector } from "reselect";
import { compose, lifecycle, branch, renderComponent } from "recompose";
import { bindActionCreators, Action as ReduxAction } from "redux";
import { connect } from "react-redux";
import { InsuranceProvider } from "src/types/api";
import { HTTPClient, InsuranceProps } from "src/types/local";

type Reducer = {
  fetchProvidersRequest: { loading: boolean; error: boolean };
  providers: Array<InsuranceProvider>;
};

interface Action extends ReduxAction {
  result: Array<InsuranceProvider>;
}

const initial = {
  fetchProvidersRequest: { loading: false, error: false },
  providers: [],
};

const FETCH_PROVIDERS_PRE = "@@cvstom/FETCH_PROVIDERS_PRE";
const FETCH_PROVIDERS_SUCCESS = "@@cvstom/FETCH_PROVIDERS_SUCCESS";
const FETCH_PROVIDERS_ERROR = "@@cvstom/FETCH_PROVIDERS_ERROR";

const modifiers = {
  [FETCH_PROVIDERS_PRE]: (prev: Reducer): Reducer => {
    return Object.assign({}, prev, {
      fetchProvidersRequest: { loading: true, error: false },
    });
  },
  [FETCH_PROVIDERS_ERROR]: (prev: Reducer): Reducer => {
    return Object.assign({}, prev, {
      fetchProvidersRequest: { loading: false, error: true },
    });
  },
  [FETCH_PROVIDERS_SUCCESS]: (prev: Reducer, action: Action): Reducer => {
    return Object.assign({}, prev, {
      fetchProvidersRequest: { loading: false, error: false },
      providers: action.result,
    });
  },
};

export default function reducer(
  state: Reducer = initial,
  action: Action
): Reducer {
  if (typeof modifiers[action.type] === "function") {
    return modifiers[action.type](state, action);
  }
  return state;
}

interface Thunks {
  fetchProviders(patientId: string);
}

/**
 * Actions
 */
const fetchProviders = (patientId: string) => ({
  types: [FETCH_PROVIDERS_PRE, FETCH_PROVIDERS_SUCCESS, FETCH_PROVIDERS_ERROR],
  promise: (client: HTTPClient) =>
    client.get(`/api/v1/patients/${patientId}/claims_form`),
});

export const getNonce = (patientId: string) => ({
  types: [null, null, null],
  promise: (client: HTTPClient) =>
    client.post(`/api/v1/patients/${patientId}/claims_form/nonce`),
});

/**
 * HOCs
 */

export const WithInsuranceActions = connect(
  null,
  dispatch => ({
    actions: bindActionCreators(
      {
        fetchProviders,
      },
      dispatch
    ),
  })
);

/**
 * Returns the reducer as props to a component using memoized selectors
 */
export const WithProviders = connect(
  createStructuredSelector({
    providers: (state: any) => state.insurance.providers || [],
    fetchProvidersRequest: (state: any) =>
      state.insurance.fetchProvidersRequest || {},
  })
);

interface AutoloadProvidersProps extends InsuranceProps {
  actions?: Thunks;
}

/**
 * AutoloadProviders calls fetchProviders before a component is mounted,
 * rendering a loading spinner whilst the API call is pending
 */
export const AutoloadProviders = compose<
  AutoloadProvidersProps,
  AutoloadProvidersProps
>(
  WithInsuranceActions,
  WithProviders,
  lifecycle({
    componentDidMount() {
      const { patientId, actions } = this.props as AutoloadProvidersProps;
      if (actions) {
        actions.fetchProviders(patientId);
      }
    },
  }),
  branch(
    (props: Reducer) => props.fetchProvidersRequest.loading,
    renderComponent(() => <p>Loading</p>)
  )
);
