import React, { createContext, useContext, useState, useEffect } from "react";
import HelloSign from "hellosign-embedded";
import { notification } from "antd";

import { PatientConsent } from "src/types/api";
import { useMutation, useQuery } from "src/utils/http/gqlQuery";
import { createConsentRequestGql, getConsentsGql } from "./queries";

export interface LocalConsent {
  // these are local values that we will create even if the API doesn't return anything
  title: string;
  key: string;
  signedAt?: string;
  // this params belong to a Patient Consent that we might receive from the API
  id?: string;
  userId?: string;
  documentName?: string;
  providerType?: string;
  signUrl?: string;
  signatureWasRequested?: boolean;
  completedAt?: string;
}

type Context = {
  consents: Array<LocalConsent>;
  fetchingConsents: boolean;
  signConsent: Function;
};

// we are creating local consents as API returns only initiated/signed consents
// if a user never signed any consent - API will return nothing
const localConsents: Array<LocalConsent> = [
  {
    key: "debond",
    title: "Debond Consent",
  },
  {
    key: "covid",
    title: "COVID Consent",
  },
  {
    key: "treatment",
    title: "Treatment Consent",
  },
  {
    key: "financial",
    title: "Financial Consent",
  },
  {
    key: "media",
    title: "Clinical Media Release",
  },
];

const ConsentsContext = createContext<Context>({
  consents: [],
  fetchingConsents: false,
  signConsent: () => {},
});

type ProviderProps = {
  patientId: string;
  children: any;
};

const helloSignClientID = "ebb3b921894d808afbd661db5f6cfbb2";
const helloSignStagingClientID = "1f0216f1fb2c60fd04bb76f4162f52a0";

const isProduction = () => {
  return (
    process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "production"
  );
};

export const ConsentsContextProvider = (props: ProviderProps) => {
  const [consentRequestsState, executeQuery] = useQuery({
    query: getConsentsGql,
    variables: { userId: props.patientId },
  });

  // These ones are local consents combined with the API response
  const [consents, setConsents] = useState<Array<LocalConsent>>([]);

  // These ones we recieve from the API
  const consentRequests =
    consentRequestsState.data && consentRequestsState.data.consents;

  const refresh = async () => {
    if (consentRequestsState && consentRequestsState.fetching) {
      return;
    }
    executeQuery();
  };

  // combining local consents with the API response
  useEffect(() => {
    const combinedLocalAndApiConsents = localConsents.map(c => {
      const cR: PatientConsent =
        consentRequests && consentRequests.find(r => r.documentName === c.key);
      return { ...c, ...cR, signedAt: cR && cR.completedAt };
    });
    setConsents(combinedLocalAndApiConsents);
  }, [consentRequests]);

  const client = new HelloSign({
    clientId: isProduction() ? helloSignClientID : helloSignStagingClientID,
  });

  const [, doCreateConsentRequest] = useMutation(createConsentRequestGql);

  // This will create a signate consent object on the backend
  const createConsentRequest = async (documentName: string) => {
    const input = {
      userId: props.patientId,
      documentName: documentName,
      signatureWasRequested: true,
    };
    const result = await doCreateConsentRequest({
      input: input,
    });
    if (result.error) {
      notification.error({
        message: `There was an error creating a signature request`,
      });
      return null;
    }
    return result.data.createSignatureConsentRequest;
  };

  const signConsent = async (documentName: string) => {
    if (consentRequestsState && consentRequestsState.fetching) {
      return;
    }

    // looking for a local consent
    var consentRequest: LocalConsent = consents.find(
      c => c.key === documentName
    ) as LocalConsent;

    // if there is no ID it means that we don't have this consent on the BE
    if (!consentRequest.id) {
      // creating a consent on the BE
      const cR = await createConsentRequest(documentName);
      // updating local consent with a BE response
      consentRequest = { ...consentRequest, ...cR };
      // updating state
      const updatedConsents = consents.map(c => {
        if (c.key === documentName) {
          return consentRequest;
        }
        return c;
      });
      setConsents(updatedConsents);
    }

    // this shouldn't happen
    if (!consentRequest) {
      return;
    }

    if (
      consentRequest.signUrl &&
      !consentRequest.completedAt &&
      !consentRequest.signedAt
    ) {
      client.open(consentRequest.signUrl, {
        skipDomainVerification: !isProduction(),
      });

      client.on("sign", data => {
        // not sending this to BE as we'll update these fields when we receive a file in a webhook
        const updatedConsents: Array<LocalConsent> = consents.map(c => {
          if (c.key === documentName) {
            c.signedAt = Date.now().toString();
          }
          return c;
        });
        setConsents(updatedConsents);
      });

      client.on("close", () => {
        // We only need to refresh if the window was closed and doc wasn't signed
        // as we need to refresh signUrl from the BE
        const c = consents.find(c => c.key === documentName);
        if (c && !c.signedAt) {
          refresh();
        }
      });

      client.on("finish", () => {
        client.off("sign");
        client.off("finish");
      });
    }
  };

  const value = {
    consents: consents,
    signConsent: signConsent,
    fetchingConsents: consentRequestsState && consentRequestsState.fetching,
  };
  return (
    <ConsentsContext.Provider value={value}>
      {props.children}
    </ConsentsContext.Provider>
  );
};

export default function useConsentsContext() {
  return useContext(ConsentsContext);
}
