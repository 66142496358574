import React from "react";
import { TimelineBody, TimelineScroll, TimelineBodyContent } from "../styles";
import { TimelineEntry } from "../util";
import TimelineItem, { Props as TimelineItemProps } from "./TimelineItem";
import { TimelineFileData } from "../queries";

export interface Props {
  entries: Array<TimelineEntry>;
  selectedEntry: TimelineEntry;
  patientID: string;
  sortedFiles: Array<TimelineFileData>;
  onClickSubmission: TimelineItemProps["onClickSubmission"];
}

export default function({
  entries,
  selectedEntry,
  sortedFiles,
  patientID,
  onClickSubmission,
}: Props) {
  return (
    <TimelineBody>
      <TimelineScroll>
        <TimelineBodyContent>
          {entries.map(entry => {
            const isSelected = selectedEntry === entry;
            return (
              <TimelineItem
                key={entry.key}
                patientID={patientID}
                selected={isSelected}
                entry={entry}
                sortedFiles={sortedFiles}
                onClickSubmission={onClickSubmission}
              />
            );
          })}
        </TimelineBodyContent>
      </TimelineScroll>
    </TimelineBody>
  );
}
