const errorToReadable: { [err: string]: string } = {
  "must have an associated clinic id":
    "You aren't checked in to a clinic! Please contact your treatment coordinator.",

  invalid_missing_subscriber_insured_id:
    "Onederful error: Invalid member ID. Please double check to verify that all insurance details are correct.",
};

const ERROR_STRINGS = Object.keys(errorToReadable);

export const getErrorMessage = (message: string): string => {
  if (!message) return "";

  const err = ERROR_STRINGS.find(err => message.includes(err));
  if (err) return errorToReadable[err];

  return message.replace("[GraphQL] ", "");
};
