// @ky - 12/13/2019
// NOTE: taken and modified https://dribbble.com/shots/2834791-Dot-Loader
import React from "react";
import styled, { keyframes } from "react-emotion";
import color from "src/styles/color";

const Perimeter = styled.div`
  display: flex;
  justify-content: center;
`;

const bouncedelay = keyframes`
  from, 33.33%, 66.66%, to {
    transform: translate3D(0, 0, 0);
  }
  16.66%, 50%, 83.33% {
    transform: translate3D(0, -6.8px, 0);
  }
`;

const Dot1 = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: ${color.white};
  margin-right: 4px;
  &:last-child {
    margin-right: 0;
  }
  animation: ${bouncedelay} 2.6s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99)
    both;
`;

const Dot2 = styled(Dot1)`
  animation-delay: 0.08s;
`;

const Dot3 = styled(Dot1)`
  animation-delay: 0.16s;
`;

const LoadingIndicator = () => (
  <Perimeter>
    <Dot1 />
    <Dot2 />
    <Dot3 />
  </Perimeter>
);

export default LoadingIndicator;
