import React from "react";
import { LocalConsent } from "src/scenes/PatientProfile/Consents/useConsentsContext";
import TodoBox from "src/shared/TodoBox";

interface Props {
  oldConsentPresent: boolean;
  userId: string;
  consent: LocalConsent;
  signConsent: Function;
}

const getTitle = documentName => {
  switch (documentName) {
    case "media":
      return "Clinical Media Release";
    case "treatment":
      return "Treatment Consent (Required)";
    case "financial":
      return "Financial Consent (Required)";
    case "covid":
      return "COVID Results Consent";
    case "debond":
      return "Debond Consent (Required)";
    default:
      return "";
  }
};

const getSubtitle = documentName => {
  switch (documentName) {
    case "media":
      return "Allows us to use the clinical photos as examples";
    case "treatment":
      return "Clearly outlines the orthodontic care agreement.";
    case "financial":
      return "Clearly outlines the financial policy agreement.";
    case "covid":
      return "A patient signature for COVID tests.";
    case "debond":
      return "Outlines the debond agreement when ending treatment.";
    default:
      return "";
  }
};

const SignDocument = (props: Props) => {
  let kind = "error";
  if (props.oldConsentPresent) {
    kind = "warning";
  }
  if (props.consent.signedAt) {
    kind = "success";
  }

  return (
    <TodoBox onClick={() => props.signConsent(props.consent.key)} kind={kind}>
      <TodoBox.Title>{getTitle(props.consent.key)}</TodoBox.Title>
      <TodoBox.Subtitle>
        {getSubtitle(props.consent.key)} This is{" "}
        {props.consent.key !== "media" && (
          <span style={{ fontWeight: "bold" }}>mandatory.</span>
        )}
      </TodoBox.Subtitle>
    </TodoBox>
  );
};

export default SignDocument;
