import { get, sortBy, filter } from "lodash";

const APPEND_FILE = "fileList/APPEND_FILE";
const LOAD = "fileList/LOAD";
const LOAD_SUCCESS = "fileList/LOAD_SUCCESS";
const LOAD_FAIL = "fileList/LOAD_FAIL";

const getPatientFilesApi = userId => `/api/v1/patients/${userId}/files`;

const initialState = {
  fileList: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        fileList: action.userId === state.userId ? state.result : [],
        userId: action.userId,
        error: null,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        userId: action.userId,
        fileList: action.result,
        error: null,
      };
    case APPEND_FILE:
      return {
        ...state,
        fileList: state.fileList.concat(action.file),
      };
    case LOAD_FAIL:
      return {
        ...state,
        userId: action.userId,
        error: action.error,
        fileList: [],
      };
    default:
      return state;
  }
}
export function appendFile(file) {
  return {
    type: APPEND_FILE,
    file,
  };
}
export function loadFiles(userId) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    userId,
    promise: client => client.get(getPatientFilesApi(userId)),
  };
}

export const getFileListSelector = state => {
  return sortBy(get(state, "fileList.fileList"), "created_at").reverse();
};

export const getSelfieSelector = state => {
  return sortBy(
    filter(get(state, "fileList.fileList"), { file_type: "selfie" }),
    "created_at"
  ).reverse();
};
