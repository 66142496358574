import {
  white,
  red200,
  grey50,
  grey100,
  grey200,
  grey300,
  grey500,
  blueGrey400,
  grey700,
} from "../node_modules/material-ui/styles/colors";

const BLACK = "rgb(36,45,45)";
const ORANGE = "rgb(255,159,129)";
const LIGHT_ORANGE = "rgb(242, 207, 193)";
const VERY_LIGHT_ORANGE = "rgb(255, 244, 239)";
const BLUE = "rgb(97,175,241)";
const GREEN = "rgb(0,119,50)";
const LIGHT_BLUE = "rgb(234, 246, 255)";
const GREY_DARK_RED = "rgb(221, 201, 201)";

export const V = {
  // Variables
  colors: {
    primary: ORANGE,
    secondary: BLUE,
    disabled: grey500,
    fontFaded: grey500,
    fontLight: grey700,
    fontPrimary: "rgb(38,45,45)",
    borderColor: grey300,
    borderColorDark: grey500,
    borderColorLight: grey200,
    darkBackground: blueGrey400,
    lightBackground: "rgb(229,232,236)",
    reallyWhiteGreyBackground: grey50,
    whiteGreyBackground: grey100,
    lightGreyBackground: grey200,
    buttonPressedGreyOrange: LIGHT_ORANGE,
    buttonPressedGreyOrangeHover: "rgb(255, 246, 242)",
    buttonSecondaryGrey: "rgb(247, 251, 255)",
    buttonTertiaryGrey: "rgb(244, 249, 247)",
    black: BLACK,
    orange: ORANGE,
    blue: BLUE,
    lightBlue: LIGHT_BLUE,
    lightOrange: LIGHT_ORANGE,
    veryLightOrange: VERY_LIGHT_ORANGE,
    green: GREEN,
    red: red200,
    error: "#d40000",
    errorButtonBg: GREY_DARK_RED,
    white,
  },
  topBarHeight: 48,
  tabsHeight: 30,
  filterWidth: 300,
  filterMargin: 32,
  mainMinWidth: 400,
  mainWidth: 700,
  sideWidth: 340,
  sideMinWidth: 200,
  containerMaxWidth: 1040,
  fontTiny: 11,
  fontSmall: 14,
  fontMed: 16,
  fontLarge: 20,
  fontXLarge: 26,
  fontHuge: 36,
  spacingTiny: 6,
  spacingSmall: 12,
  spacing: 24,
  spacingLarge: 48,
  spacingHuge: 96,
  fontWeightHeavy: 700,
  fontWeightBolder: 500,
  fontWeightNormal: 400,
  fontWeightLight: 300,
  listImageHeight: 180,
  listImageWidth: 240,
  listImageHeightSmall: 135,
  listImageWidthSmall: 180,
  iconLarge: 32,
};
V.border = `1px solid ${V.colors.borderColor}`;
V.borderDark = `1px solid ${V.colors.borderColorDark}`;

const materialUICard = {
  color: "rgba(0, 0, 0, 0.87)",
  backgroundColor: "#ffffff",
  transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
  boxSizing: "border-box",
  zIndex: 1,
  marginBottom: V.spacingSmall,
};
const materialUIShallowCard = {
  ...materialUICard,
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.07)",
};
const materialUIDeepCard = {
  ...materialUICard,
  boxShadow: "0 1px 10px rgba(0, 0, 0, 0.20), 0 1px 10px rgba(0, 0, 0, 0.20)",
};
V.materialUICard = materialUICard;
V.materialUIShallowCard = materialUIShallowCard;
V.materialUIDeepCard = materialUIDeepCard;

export const S = {
  // styles
  error: {
    color: V.colors.error,
    fontWeight: V.fontWeightBolder,
  },
  warning: {
    color: V.colors.orange,
    fontWeight: V.fontWeightBolder,
  },
  header: {
    fontSize: V.fontLarge,
    fontWeight: V.fontWeightHeavy,
  },
  inlineBlock: {
    display: "inline-block",
  },
  imgThumbnail: {
    maxHeight: 100,
    maxWidth: 100,
    margin: V.spacingSmall,
  },
  lineThrough: { textDecoration: "line-through" },
  bordered: { border: V.border },
  contentBlock: {
    ...materialUICard,
    paddingLeft: V.spacing,
    paddingRight: V.spacing,
    paddingBottom: V.spacingSmall,
    paddingTop: V.spacingSmall,
    overflow: "auto",
  },
  materialUICard,
  container: {
    margin: "0 auto",
  },
  content: {
    display: "flex",
  },
  mainPanel: {
    width: V.mainWidth,
    minWidth: V.mainMinWidth,
    position: "relative",
  },
  sidePanel: {
    width: V.sideWidth,
    flexGrow: 2,
    minWidth: V.sideMinWidth,
  },
  sectionTitle: {
    fontWeight: V.fontWeightBolder,
    borderBottom: V.border,
    paddingBottom: V.spacingTiny,
    marginBottom: V.spacingTiny,
    display: "inline-block",
    width: "100%",
  },
  sectionTitleNoChildren: {
    fontWeight: V.fontWeightBolder,
    display: "inline-block",
    width: "100%",
  },
  floatRight: {
    float: "right",
  },
  verticalDivider: {
    marginLeft: V.spacingSmall,
    marginRight: V.spacingSmall,
    borderLeft: V.border,
  },
  label: {
    color: V.colors.fontLight,
    fontSize: V.fontTiny,
  },
  boldLabel: {
    fontWeight: V.fontWeightHeavy,
  },
  longSmallText: {
    color: V.colors.fontLight,
    fontSize: V.fontTiny,
  },
  selectedBottomBorder: selected => ({
    borderBottom: selected ? `3px solid ${V.colors.primary}` : "none",
  }),
};
S.tab = {
  fillBackground: {
    background: V.colors.secondary,
    zIndex: 3,
    width: "100%",
  },
  buttonLabel: {
    color: V.colors.white,
  },
  getTabStyle: (selected, equalWidthNumOfTabs) => {
    const style = {
      position: "relative",
      borderRadius: 0,
      maxWidth: 200,
      ...S.selectedBottomBorder(selected),
    };
    if (equalWidthNumOfTabs) {
      style.width = `${100 / equalWidthNumOfTabs}%`;
    }
    return style;
  },
};

// export const M = { // Media queries
//   smallMin: '@media (minWidth: 480px)',
//   smallMax: '@media (containerMaxWidth: 480px)',
//   mediumMin: '@media (minWidth: 768px)',
//   mediumMax: '@media (containerMaxWidth: 768px)', // 768 is default ipad width in vertical hold
// }

export default { S, V };
