import React, { useEffect, useRef } from "react";
import styled from "react-emotion";
import { Provider, useStateContext } from "./state";
import {
  PropsDefaultState,
  AttributeType,
  Attribute,
  ToothNumber,
  ToothLocation,
} from "./types";
import debounce from "lodash/debounce";
import filter from "lodash/filter";
import { useAttributes } from "src/state/useAttributes";
import Toolbar from "./Toolbar";
import Chart from "./Chart";
import Legend from "./Legend";
import Selected from "./Selected";

type Props = PropsDefaultState & {
  userID: string;

  // Require that `submissionID=null` is specified explicitly,
  // so that we know exactly where the submissionID is purposely omitted.
  submissionID: string | null;

  // onChange, if provided, allows the tooth chart to be editable and will be
  // called when the tooth chart's state changes.
  //
  // If not provided, this tooth chart is _not_ editable.
  onChange?: (_: Attribute[]) => void;
  // disabled forces the tooth chart to be uneditable even if onChange is provided.
  disabled?: boolean;
  className?: any;

  // Used to show original tooth data from the latest submitted BV forms,
  // unused if `useAttributes()` returns populated `data.existing`
  bvAttributes?: Attribute[];
};

const debounceMS = 2000;
const noop = () => {};

// ToothChart is the main component for rendering read-only or editable tooth charts anywhere
// within our system.  It is rendered from the wrapper which provides the state context.
//
// At a high level, it renders four components:  the toolbar, the teeth, the legend,
// and the "selected tooth" modal.
const ToothChart: React.FC<Props> = props => {
  const { bvAttributes } = props;
  const [{ fetching, error, data }] = useAttributes(props.userID);

  const onChange = useRef(debounce(props.onChange || noop, debounceMS));

  const [state, dispatch] = useStateContext();
  const editable = !props.disabled && props.onChange !== undefined;
  const { display, selectedTooth, unsavedAttributes } = state;
  const hasPrescribed =
    props.availableDisplayTypes &&
    props.availableDisplayTypes.indexOf("prescribed") >= 0;
  const forTreatmentPlanning =
    hasPrescribed &&
    (!props.readOnlyDisplayTypes ||
      props.readOnlyDisplayTypes.indexOf("prescribed") < 0);

  useEffect(() => {
    if (fetching || error || !data) {
      return;
    }

    // add fetched attributes as cumulative items to state.
    let items: Attribute[] = [];

    data.existing.forEach(item => {
      items.push({
        type: item.attribute.type as AttributeType,
        stage: "original",
        toothNumber: item.toothNumber as ToothNumber,
        createdAt: item.createdAt,
      });
    });

    if (data.existing.length === 0 && bvAttributes) {
      bvAttributes.forEach(attr => {
        items.push({
          type: attr.type as AttributeType,
          stage: "original",
          toothNumber: attr.toothNumber as ToothNumber,
          createdAt: undefined,
        });
      });
    }

    if (!forTreatmentPlanning) {
      data.prescribed.forEach(item => {
        items.push({
          type: item.attribute.type as AttributeType,
          stage: "prescribed",
          toothNumber: item.toothNumber as ToothNumber,
          location: item.location as ToothLocation,
          amount: item.amount,
          createdAt: item.createdAt,
        });
      });
    }

    const sID = props.submissionID;
    let performed = data.performed;
    if (sID && display !== "cumulative") {
      performed = filter(data.performed, { formSubmissionID: sID });
    }
    performed.forEach(item => {
      items.push({
        type: item.attribute.type as AttributeType,
        stage: "performed",
        toothNumber: item.toothNumber as ToothNumber,
        location: item.location as ToothLocation,
        amount: item.amount,
        createdAt: item.createdAt,
      });
    });

    dispatch({
      type: "replaceCumulativeAttributes",
      attributes: items,
    });
    // eslint-disable-next-line
  }, [fetching, error, data, bvAttributes, display]);

  // There are sometimes >= 2 tooth charts rendered within the same tree for a single
  // form.  This means that both tooth charts affect unsavedAttributes.
  //
  // When one tooth chart changes, we must update the internal state of the other tooth chart.
  const unsavedProps = JSON.stringify(props.unsavedAttributes);
  const unsavedLocal = JSON.stringify(unsavedAttributes);
  useEffect(() => {
    if (unsavedProps === unsavedLocal) {
      return;
    }
    dispatch({
      type: "replaceUnsavedAttributes",
      unsavedAttributes: props.unsavedAttributes || [],
    });
    // only call this on unsavedProps changing.  This ensures that we only update
    // local state.
    // eslint-disable-next-line
  }, [unsavedProps]);

  useEffect(() => {
    // Only send the onChange signal to the parent if unsavedAttributes doesn't
    // match the props passed in to the parent.
    if (unsavedProps === unsavedLocal) {
      return;
    }
    // When the local unsavedAttributes variable changes, call onChange.
    props.onChange && onChange.current(unsavedAttributes || []);
    // Only clal this when local unsaved state changes, not when props change
    // eslint-disable-next-line
  }, [unsavedLocal]);

  return (
    <Wrapper key={display} className={props.className}>
      <Toolbar editable={editable} />
      <Chart editable={editable} />
      <Legend />
      {/* Render the selected tooth modal */}
      {selectedTooth && <Selected />}
    </Wrapper>
  );
};

// ContextWrapper initializes the state reducer and prodives a tooth chart context for any
// subcomponent within the tooth chat.
const ContextWrapper: React.FC<Props> = props => (
  <Provider {...props}>
    <ToothChart {...props} />
  </Provider>
);

export default ContextWrapper;

const Wrapper = styled.div`
  position: relative;
  background: #fff;
`;
