import { useEffect } from "react";
import { Action, Thunk, action, thunk } from "easy-peasy";
import { message } from "antd";
import { DetailedPatient, Patient } from "src/types/api";
import { ApiRequestState } from "src/types/local";
import { request } from "src/shared/util";
import { useActions, useStore } from "../hooks";

export interface PatientState {
  patients: { [id: string]: Patient };
  detailedPatients: { [id: string]: DetailedPatient };
  fetchPatientsRequest: ApiRequestState;
  fetchPatientRequest: ApiRequestState;

  // Actions
  setPatients: Action<PatientState, Array<Patient>>;
  setPatient: Action<PatientState, DetailedPatient>;
  setFetchPatientsRequest: Action<PatientState, ApiRequestState>;
  setFetchPatientRequest: Action<PatientState, ApiRequestState>;

  // Effects
  fetchAllPatients: Thunk<PatientState, null>;
  fetchPatient: Thunk<PatientState, string>;
  updatePatient: Thunk<PatientState, DetailedPatient>;
  deletePatient: Thunk<PatientState, string>;
  sendPasswordReset: Thunk<PatientState, string>;
}

export const patients: PatientState = {
  patients: {},
  detailedPatients: {},
  fetchPatientsRequest: { loading: false, error: false },
  fetchPatientRequest: { loading: false, error: false },

  setPatients: action((state, patients) => {
    patients.forEach((p: Patient) => (state.patients[p.id] = p));
  }),
  setPatient: action((state, patient) => {
    state.detailedPatients[patient.id] = patient;
  }),
  setFetchPatientsRequest: action((state, req) => {
    state.fetchPatientsRequest = req;
  }),
  setFetchPatientRequest: action((state, req) => {
    state.fetchPatientRequest = req;
  }),

  fetchAllPatients: thunk(async (actions, _) => {
    // TODO: Return limited data when fetching all patients so that this isn't a huge
    // request.  Unfortunately this is how "search" works now...
    actions.setFetchPatientsRequest({ loading: true, error: false });
    const result = await request(`/api/v1/patients`);
    if (!Array.isArray(result)) {
      actions.setFetchPatientsRequest({ loading: false, error: true });
      return;
    }
    actions.setPatients(result);
    actions.setFetchPatientsRequest({ loading: false, error: false });
  }),

  fetchPatient: thunk(async (actions, id) => {
    actions.setFetchPatientRequest({ loading: true, error: false });
    const result = await request(`/api/v1/patients/${id}`);
    if (result.error) {
      actions.setFetchPatientRequest({ loading: false, error: true });
      return;
    }
    actions.setPatient(result);
    actions.setFetchPatientRequest({ loading: false, error: false });
  }),

  updatePatient: thunk(async (actions, p: DetailedPatient) => {
    actions.setFetchPatientRequest({ loading: true, error: false });
    const result = await request(`/api/v1/patients/${p.id}`, {
      method: "PUT",
      body: JSON.stringify({
        first_name: p.first_name,
        last_name: p.last_name,
        email: p.email,
        mobile_phone_number: p.mobile_phone_number,
        date_of_birth: p.date_of_birth,
        is_spam: p.is_spam,
        spam_reason: p.spam_reason,
      }),
    });
    if (result.error) {
      message.error("Something went wrong saving changes");
      return;
    }

    actions.setPatient(result);
    actions.setFetchPatientRequest({ loading: false, error: false });
    message.success("Saved!");
  }),

  deletePatient: thunk(async (actions, id) => {
    const result = await request(`/api/v1/patients/${id}`, {
      method: "DELETE",
    });
    if (result.error) {
      message.error("Something went wrong saving changes");
      return;
    }
    message.success("Deleted!");
  }),

  sendPasswordReset: thunk(async (actions, email) => {
    const result = await request(`/api/v1/users/password`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
    });
    if (result.error) {
      message.error("Something went wrong sending reset link");
      return;
    }
    message.success("Sent!");
  }),
};

export const useFetchPatient = (
  id: string
): [DetailedPatient | null, ApiRequestState] => {
  const patients = useStore(state => {
    return state.patients;
  });
  const fetchPatient = useActions(actions => {
    return actions.patients.fetchPatient;
  });

  useEffect(() => {
    fetchPatient(id);
  }, [fetchPatient, id]);

  const pt = patients.detailedPatients[id];

  return [pt, patients.fetchPatientRequest];
};

export const useUpdatePatient = () => {
  const updatePatient = useActions(actions => {
    return actions.patients.updatePatient;
  });
  return (p: DetailedPatient) => {
    updatePatient(p);
  };
};

export const useDeletePatient = () => {
  const deletePatient = useActions(actions => {
    return actions.patients.deletePatient;
  });
  return (id: string) => {
    deletePatient(id);
  };
};

export const useSendPasswordReset = () => {
  const sendPasswordReset = useActions(actions => {
    return actions.patients.sendPasswordReset;
  });
  return (email: string) => {
    sendPasswordReset(email);
  };
};
