declare global {
  interface Window {
    _paq: Array<string[]>;
  }
}

const _paq = window._paq || [];
// tracker methods like "setCustomDimension" should be called before "trackPageView"
_paq.push(["trackPageView"]);
_paq.push(["enableLinkTracking"]);

(function() {
  const u = process.env.REACT_APP_MATOMO_URL || "//localhost:1234/";
  _paq.push(["setTrackerUrl", u + "matomo.php"]);
  _paq.push(["setSiteId", "1"]);
  const d = document,
    g = d.createElement("script");
  g.type = "text/javascript";
  g.async = true;
  g.defer = true;
  g.src = `${u}matomo.js`;
  d.body.appendChild(g);
})();

export default _paq;
