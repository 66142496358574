import React, { useState, useEffect } from "react";
import ReferralsIcon from "src/shared/Icons/Referrals";
import Button from "src/shared/Button";
import { FlexDiv, Box, Text } from "./styles";
import ReferralForm from "./ReferralForm";
import { Continue } from "src/scenes/PatientProfile/Forms/BeginningVisit/styledComponents";
import useFormUI, {
  FormUI,
} from "src/scenes/PatientProfile/Forms/GQLForms/useFormUI";
import { performDeleteSubmission } from "src/scenes/PatientProfile/Forms/GQLForms/formActions";
import { Tabs } from "antd";

const TAB_ACTION_ADD = "add";
const TAB_ACTION_REMOVE = "remove";

interface Props {
  ui: FormUI; // BV form UI
  patientID: string;
  next: () => void; // from BV form parent.
}

const Referral: React.FC<Props> = props => {
  const [isAsync, setIsAsync] = useState(false); // Whether we're saving or deleting
  const [activeSubmissionID, setActiveSubmissionID] = useState<string | null>(
    null
  ); // activeSubmissionID stores which referral form is currently active.

  const { ui: parentUI, patientID } = props;

  const parentSubmissionID = parentUI.submission.id;
  const referralUI = useFormUI({
    slug: "referral",
    userID: patientID,
    submissionID: activeSubmissionID,
    parentSubmissionID,
  });

  const referrals = referralUI ? referralUI.submissions : [];

  // Ensure that we select the first option as active.
  useEffect(() => {
    if (!activeSubmissionID && referrals.length > 0) {
      setActiveSubmissionID(referrals[0].id);
    }
    if (
      !referrals.find(s => s.id === activeSubmissionID) &&
      referrals.length > 0
    ) {
      // When a given option is deleted, set the ID of the first option here.
      setActiveSubmissionID(referrals[0].id);
    }
    // eslint-disable-next-line
  }, [referrals, activeSubmissionID]);

  if (!referralUI) {
    return null;
  }

  // TODO: newSubmission, cloneSubmission, deleteSubmission are used in other forms. We
  // export these in somewhere in src/scenes/PatientProfile/Forms instead of rewriting
  // these in seperate forms.

  const newSubmissionForm = async () => {
    if (isAsync) {
      return;
    }
    setIsAsync(true);
    const result = await referralUI.createSubmission(
      patientID,
      parentSubmissionID
    );
    setIsAsync(false);

    if (result.data) {
      setActiveSubmissionID(result.data.createSubmission.id);
    }
  };

  const deleteSubmissionForm = async (id: string) => {
    if (isAsync) {
      return;
    }

    setIsAsync(true);
    await performDeleteSubmission(id, referralUI.deleteSubmission);
    setIsAsync(false);
  };

  const editReferralTab = async (targetKey, action) => {
    if (action === TAB_ACTION_ADD) {
      return newSubmissionForm();
    }

    if (action === TAB_ACTION_REMOVE) {
      return await deleteSubmissionForm(targetKey);
    }
  };

  const setActiveReferralTab = activeKey => {
    setActiveSubmissionID(activeKey);
  };

  if (referrals.length === 0) {
    return (
      <Box>
        <ReferralsIcon />
        <Text>Do you want to refer this patient to a specialist?</Text>
        <FlexDiv>
          <Button
            style={{ width: "100px", marginRight: "16px" }}
            onClick={props.next}
          >
            No
          </Button>
          <Button
            style={{ width: "100px" }}
            kind="primary"
            onClick={newSubmissionForm}
          >
            Yes
          </Button>
        </FlexDiv>
      </Box>
    );
  }

  return (
    <div>
      {referralUI && referralUI.submission && activeSubmissionID && (
        <Tabs
          onChange={setActiveReferralTab}
          activeKey={activeSubmissionID}
          type="editable-card"
          onEdit={editReferralTab}
        >
          {referrals.map((submission, index) => {
            return (
              <Tabs.TabPane tab={`Referral ${index + 1}`} key={submission.id}>
                <ReferralForm patientID={patientID} ui={referralUI} />
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      )}

      <Continue next={props.next} />
    </div>
  );
};

export default Referral;
