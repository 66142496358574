import React from "react";
import color from "src/styles/color";
import Scheduling from "src/shared/Icons/Scheduling";
import Tasks from "src/shared/Icons/Tasks";
import Builds from "src/shared/Icons/Builds";
import Admin from "src/shared/Icons/Admin";
import SvgFilter from "src/shared/Icons/SvgFilter";
import NavLogo from "src/shared/Icons/NavLogo";
import useSelf, { hasFeature } from "src/state/self";
import { Perimeter, StyledLink, Logo } from "./styles";

type Props = {
  location: any;
};

const SideNav = (props: Props) => {
  const { pathname } = props.location;
  const self = useSelf();

  const activeStyles = route => {
    if (pathname.includes(route)) {
      return {
        fill: color.white,
        background: color.primary,
        hoverFill: color.white,
        hoverBackground: color.primaryHover,
      };
    }
    return {};
  };

  const isActive = route => pathname.includes(route);
  const isNewTreatmentOverview = pathname.includes(
    "new-patient-treatment-overview"
  );

  return (
    <Perimeter>
      {isNewTreatmentOverview ? (
        <Logo>
          <NavLogo />
        </Logo>
      ) : (
        <></>
      )}
      <StyledLink to="/scheduling">
        <SvgFilter active={isActive("/scheduling")}>
          <Scheduling {...activeStyles("/scheduling")} />
        </SvgFilter>
      </StyledLink>
      {hasFeature("manufacturing", self) && (
        <>
          <StyledLink to="/tasks">
            <SvgFilter active={isActive("/tasks") || isActive("/pipelines")}>
              <Tasks
                {...activeStyles("/tasks")}
                {...activeStyles("/pipelines")}
              />
            </SvgFilter>
          </StyledLink>
          <StyledLink to="/builds">
            <SvgFilter active={isActive("/builds")}>
              <Builds {...activeStyles("/builds")} />
            </SvgFilter>
          </StyledLink>
          <StyledLink to="/emr-admin">
            <SvgFilter active={isActive("/emr-admin")}>
              <Admin {...activeStyles("/emr-admin")} />
            </SvgFilter>
          </StyledLink>
        </>
      )}
    </Perimeter>
  );
};

export default SideNav;
