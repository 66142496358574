import React, { Component } from "react";
import PropTypes from "prop-types";
import Lightbox from "react-images";

import styles from "./gallery-style";

/**
 * IMPORTANT! This file and its corresponding style is almost full straight
 * copy pasted from React-Images example code
 * https://github.com/jossmac/react-images/blob/master/examples/src/components/Gallery.js
 *
 * Very minor alterations made so that it fits within our app.
 */

export default class GalleryPhoto extends Component {
  constructor() {
    super();

    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
    };

    this.closeLightbox = this.closeLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoImage = this.gotoImage.bind(this);
    this.handleClickImage = this.handleClickImage.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
  }

  openLightbox(index, event) {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }

  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  gotoImage(index) {
    this.setState({
      currentImage: index,
    });
  }

  handleClickImage() {
    if (this.state.currentImage === this.props.images.length - 1) return;

    this.gotoNext();
  }

  renderGallery() {
    const { images } = this.props;

    if (!images) return <div />;

    const gallery = images.map((obj, i) => {
      return (
        <a
          href={obj.src}
          style={{ ...styles.thumbnail, ...this.props.thumbnailStyle }}
          key={i}
          onClick={e => this.openLightbox(i, e)}
        >
          <img src={obj.thumbnail} style={styles.source} alt="thumbnail" />
        </a>
      );
    });

    return <div style={styles.gallery}>{gallery}</div>;
  }

  render() {
    return (
      <div className="section" style={styles.galleryContainer}>
        {this.props.heading && <h2>{this.props.heading}</h2>}
        {this.props.subheading && <p>{this.props.subheading}</p>}
        {this.renderGallery()}
        <Lightbox
          currentImage={this.state.currentImage}
          images={this.props.images}
          isOpen={this.state.lightboxIsOpen}
          onClickImage={this.handleClickImage}
          onClickNext={this.gotoNext}
          onClickPrev={this.gotoPrevious}
          onClickThumbnail={this.gotoImage}
          onClose={this.closeLightbox}
          showThumbnails={this.props.showThumbnails}
          theme={this.props.theme}
          backdropClosesModal
        />
      </div>
    );
  }
}

GalleryPhoto.displayName = "Gallery";
GalleryPhoto.propTypes = {
  heading: PropTypes.string,
  patientPhotos: PropTypes.array,
  showThumbnails: PropTypes.bool,
  subheading: PropTypes.string,
  thumbnailStyle: PropTypes.object,
};
