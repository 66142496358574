import React, { useState } from "react";
import last from "lodash/last";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import { Submission } from "src/scenes/PatientProfile/Forms/GQLForms/types";
import ToothChart from "src/shared/ToothChartV2/ToothChart";
import { Attribute } from "src/shared/ToothChartV2/types";
import { attributesToV2 } from "src/shared/ToothChartV2/versioning";
import { SelectedObj } from "src/types/types";
import { Embed, SummaryHeaderRow, SummaryHeaderText } from "../styles";
import { formatDateForTimeline, parseToothData } from "../util";
import SummaryHeaderDropdown from "./SummaryHeaderDropdown";

export interface Props {
  patientID: string;
  sortedSubmissions: Array<Submission>;
  focusedSubmissionID: string | null;
}

export interface ViewSettings {
  groups: Map<string, Array<Attribute>>;
  submissionID?: string;
}

export const DEFAULT_DISPLAY_TYPES = ["original", "cumulative", "prescribed"];

export function getViewSettings(
  sortedSubmissions: Array<Submission>,
  preferredSubmissionID: string | null
): ViewSettings {
  const attributesLookup: Map<string, Array<Attribute>> = new Map(
    sortedSubmissions.map(({ id, form, toothData }) => {
      let attributes: Array<Attribute> = [];
      if (form.slug !== "treatment_setup" && toothData) {
        attributes = attributesToV2(parseToothData(toothData));
      }
      return [id, attributes];
    })
  );
  const hasToothData = ({ id }: Submission) =>
    !isEmpty(attributesLookup.get(id));
  const eligibleSubmissions = sortedSubmissions.filter(hasToothData);
  const foundSubmission = eligibleSubmissions.find(
    ({ id }) => id === preferredSubmissionID
  );
  const lastSubmission = last(eligibleSubmissions);
  const groups = new Map(
    eligibleSubmissions.map(({ id, toothData }) => [
      id,
      attributesLookup.get(id) as Array<Attribute>,
    ])
  );

  if (foundSubmission) {
    return { groups, submissionID: foundSubmission.id };
  }

  if (lastSubmission) {
    return { groups, submissionID: lastSubmission.id };
  }

  return { groups };
}

export function getDropdownOptions(
  sortedSubmissions: Array<Submission>,
  groups: Map<string, Array<Attribute>>
): Array<SelectedObj> {
  const options = sortedSubmissions
    .filter(({ id }) => groups.has(id))
    .map(({ id, approvedAt, submittedAt, createdAt, form }) => {
      const date = formatDateForTimeline(
        approvedAt || submittedAt || createdAt
      );
      const label = `${form.name} - ${date}`;
      return { value: id, label };
    });

  return options;
}

export default function DentalFindings({
  patientID,
  sortedSubmissions,
  focusedSubmissionID,
}: Props): React.ReactElement | null {
  const [selectedSubmissionID, setSelectedSubmissionID] = useState<
    string | null
  >(null);
  const viewSettings = getViewSettings(
    sortedSubmissions,
    selectedSubmissionID || focusedSubmissionID
  );
  if (!viewSettings) {
    return null;
  }
  const { groups, submissionID } = viewSettings;
  const options = getDropdownOptions(sortedSubmissions, groups);

  let displayTypes = DEFAULT_DISPLAY_TYPES;
  if (submissionID) {
    displayTypes = displayTypes.concat(map(groups.get(submissionID), "stage"));
  }

  return (
    <>
      <SummaryHeaderRow>
        <SummaryHeaderText>Dental Findings</SummaryHeaderText>

        <SummaryHeaderDropdown
          options={options}
          selected={submissionID}
          onSelect={value => setSelectedSubmissionID(value)}
        />
      </SummaryHeaderRow>

      <Embed key={submissionID}>
        <ToothChart
          userID={patientID}
          submissionID={submissionID || null}
          defaultDisplayType={last(displayTypes)}
          availableDisplayTypes={uniq(displayTypes)}
          readOnlyDisplayTypes={["prescribed"]}
          unsavedAttributes={
            submissionID ? groups.get(submissionID) : undefined
          }
          disabled
        />
      </Embed>
    </>
  );
}
