import React from "react";
import color from "src/styles/color";

type Props = {
  fill?: string;
};

const InProgress = ({ fill = color.yellow }: Props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2387 9.41178H7.58467C7.03458 9.41178 6.58813 8.89092 6.58813 8.24915V3.98618C6.58813 3.34441 7.03458 2.82355 7.58467 2.82355C8.13476 2.82355 8.58121 3.34441 8.58121 3.98618V7.08652H11.2387C11.7887 7.08652 12.2352 7.60738 12.2352 8.24915C12.2352 8.89092 11.7887 9.41178 11.2387 9.41178Z"
      fill={fill}
    />
    <path
      d="M8 16C3.58892 16 0 12.4111 0 8C0 3.58892 3.58892 0 8 0C12.4111 0 16 3.58892 16 8C16 12.4111 12.4111 16 8 16ZM8 1.84615C4.60677 1.84615 1.84615 4.60677 1.84615 8C1.84615 11.3932 4.60677 14.1538 8 14.1538C11.3932 14.1538 14.1538 11.3932 14.1538 8C14.1538 4.60677 11.3932 1.84615 8 1.84615Z"
      fill={fill}
    />
  </svg>
);

export default InProgress;
