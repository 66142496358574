import styled from "react-emotion";
import color from "src/styles/color";

export const Blank = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 32px;

  span {
    color: ${color.gray3};
  }

  > div {
    margin: 20px 0 0;
  }
`;

export const BlankTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  margin: 0 0 0.5rem 0;
`;
