import { useMemo } from "react";
import { DateTime } from "luxon";
import { Treatment, Appointment } from "src/types/gql";
import { TimelineItem } from "src/state/useTimeline";

// export this?
export type Props = {
  treatment: Treatment;
  appointments: Array<Appointment>;
  timeline: TimelineItem[];
  loadingTreatment: boolean;
};

// useKeyDates returns memoized dates for specific key events to generate the timeline.
export const useKeyDates = (props: Props) => {
  const { treatment, appointments, timeline } = props;

  // Get date of BV
  const bv = useMemo(() => {
    const appt = appointments
      .filter(a => a.appointmentType.name === "beginning")
      .pop();
    return appt && DateTime.fromISO(appt.startTime as string);
  }, [appointments]);

  // Get date of first tray, AKA actual start date.
  const firstTrayStart = useMemo(() => {
    const tl = timeline.find(item =>
      ["complete", "assumed_complete"].includes(item.status)
    );
    return tl && DateTime.fromISO(tl.start as string);
  }, [timeline]);

  // Get date of first tray + original treatment est.
  const estimatedEnd = useMemo(() => {
    const est =
      treatment &&
      treatment.activeOption &&
      treatment.activeOption.estimatedUpperBound;
    // We can't have an end date until we have a timeline
    return firstTrayStart && est && firstTrayStart.plus({ months: est });
    // eslint-disable-next-line
  }, [timeline, treatment]);

  // Get date of last planned tray
  const lastTrayEnd = useMemo(() => {
    const last = timeline[timeline.length - 1];
    return last && DateTime.fromISO(last.plannedEnd as string);
  }, [timeline]);

  // Get date of current tray
  const currentTrayStart = useMemo(() => {
    const current = timeline.find(t => t.status === "in_progress");
    if (current) {
      return DateTime.fromISO(current.start as string);
    }

    // With no current tray, use the date of the first upcoming.
    const upcoming = timeline.find(t => t.status === "upcoming");
    if (upcoming) {
      return DateTime.fromISO(upcoming.start as string);
    }

    // With no current and no upcoming trays, use the end date of the last timeline.
    // This is typically a graduated patient.
    return lastTrayEnd;
    // eslint-disable-next-line
  }, [timeline]);

  // Some handy calculations:
  // - end is either the estimated or last tray end, whichever is greater
  // - totalDays is end - bv date in days.
  const [end, totalDays] = useMemo(() => {
    if (!bv && (!estimatedEnd && !lastTrayEnd)) {
      return [undefined, undefined];
    }

    if (bv && (!estimatedEnd && !lastTrayEnd)) {
      // Only had a BV, nothing else.
      const end = DateTime.fromISO(appointments[appointments.length - 1]
        .startTime as string);
      const days = end.diff(bv).as("days");
      return [end, days];
    }

    if (bv && estimatedEnd && !lastTrayEnd) {
      // est. end, but no last tray
      const end = estimatedEnd;
      const days = end.diff(bv).as("days");
      return [end, days];
    }

    if (bv && lastTrayEnd && !estimatedEnd) {
      // last tray, but no est.  this must be an old patient with no tx opt filled out.
      const end = lastTrayEnd;
      const days = end.diff(bv).as("days");
      return [end, days];
    }

    const end = estimatedEnd
      ? DateTime.max(estimatedEnd, lastTrayEnd)
      : lastTrayEnd;
    const days = bv && end.diff(bv).as("days");
    return [end, days];
    // eslint-disable-next-line
  }, [bv, estimatedEnd, lastTrayEnd]);

  return {
    bv,
    firstTrayStart,
    currentTrayStart,
    estimatedEnd,
    lastTrayEnd,
    end,
    totalDays,
  };
};
