import React from "react";
import { css } from "react-emotion";
import ClinicalPhotoUpload from "src/shared/ClinicalPhotos/Upload";
import ClinicalPhotoView from "src/shared/ClinicalPhotos/View";
import { Section, SectionTitle, Continue } from "./styledComponents";
import { FormUI } from "src/scenes/PatientProfile/Forms/GQLForms/useFormUI";

type Props = {
  ui: FormUI;
  patient: { id: string };
  patientID: string;
  next: () => void;
  disabled: boolean;
};

const ClinicalPhotos: React.FC<Props> = props => {
  const { ui } = props;
  const isSubmitted = ui.submission && !!ui.submission.submittedAt;

  return (
    <>
      <Section>
        <SectionTitle>Clinical Photos</SectionTitle>

        {isSubmitted ? (
          <div className={uploadCSS}>
            <ClinicalPhotoView
              patientID={props.patient.id}
              files={(ui.submission && ui.submission.files) || []}
            />
          </div>
        ) : (
          <ClinicalPhotoUpload
            className={uploadCSS}
            patientID={props.patient.id}
            submissionID={ui.submission.id}
            files={(ui.submission && ui.submission.files) || []}
            editable
            formName={"Beginning Visit"}
          />
        )}

        <Continue next={props.next} />
      </Section>
    </>
  );
};

export default ClinicalPhotos;

const uploadCSS = css`
  margin: 1rem 2rem 0 0;
`;
