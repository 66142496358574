// @flow
import React from "react";
import { compose, withState } from "recompose";
import ToothChart from "src/shared/ToothChart/ToothChart";
import { Collapse, Steps } from "antd";
import SubmissionWrapper from "./SubmissionWrapper";
import { getSubmissionAnswer } from "./answers";
import ViewFormQuestion from "./FormQuestion";
import Current from "./FollowupVisit/Current";
import { Content, stepCss, toothChartCss } from "./Shared";

// The followup visit has unique properties that make it more complex:
//
// Data:
//
// 1. Orthos need to see the patient's current tray to see where the pt.
//    is at in treatment
// 2. Orthos need to see the last treatment plan made, including the phases
//    so we can see what is prescribed.
//
// Actions:
//
// Along with answering questions, we need to:
//
// 1. Add "Rendered" procedures to the tooth chart
// 2. Record spacing in between teeth.  We typically expand teeth in the first
//    phase and want to record the amount of space created, so that we can properly
//    accommodate for the spacing at the end of tx.  You might think that we know
//    spacing as we design expansion during TP... unfortunately teeth don't move as
//    planned and we overcorrect *and* overexpand.

const sections = [
  // Intake
  { min: 0, max: 5 },
  // TP form
  { min: -1, max: -1 },
  // Doctor questions
  { min: 5, max: Infinity },
];

const modifiers = compose(withState("section", "setSection", 0));

const FollowupVisit = props => {
  const {
    submission,
    form,
    sessionAnswers,
    section,
    setSection,
    teeth,
    editable,
    patient,
  } = props;
  const isSubmitted = !!submission.submitted_at;

  return (
    <SubmissionWrapper {...props}>
      <Steps className={stepCss} current={section} progressDot>
        <Steps.Step
          title="TC: Intake"
          onClick={() => {
            setSection(0);
          }}
        />
        <Steps.Step
          title="Current Rx/Tx"
          onClick={() => {
            setSection(1);
          }}
        />
        <Steps.Step
          title="Appointment"
          onClick={() => {
            setSection(2);
          }}
        />
      </Steps>

      <Content>
        {section === 1 && (
          // If this is the first section we need to render the current tray and
          // wear timeline.
          <Current submission={submission} patient={patient} />
        )}

        {form.forms_questions.map((q, n) => {
          // Are we showing these questions in the section?
          const { min, max } = sections[section];
          if (typeof min === "number" && n < min) {
            return null;
          }
          if (typeof max === "number" && n > max) {
            return null;
          }

          return (
            <ViewFormQuestion
              patient={patient}
              allAnswers={sessionAnswers.concat(submission.answers || [])}
              answer={getSubmissionAnswer({
                answers: submission.answers,
                questionID: q.id,
                submissionID: submission.id,
              })}
              onChange={value => props.onAnswer(q.id, value)}
              editable={editable}
              key={q.id}
              q={q}
              submission={submission}
            />
          );
        })}
      </Content>

      <Collapse bordered={false} defaultActiveKey={["1"]}>
        <Collapse.Panel key="1" header="Tooth chart" className={toothChartCss}>
          <ToothChart
            stagesVisible={["rendered", "estimated", "spacing"]}
            previousProcedures={isSubmitted ? props.toothData : teeth}
            disabled={!editable}
            procedures={
              // If the form has been submitted, we can't edit the tooth chart.
              // So don't pass in toothData as editable procedures.
              !isSubmitted ? props.toothData : []
            }
            setProcedures={props.onToothChartUpdateAsync}
          />
        </Collapse.Panel>
      </Collapse>
    </SubmissionWrapper>
  );
};

export default modifiers(FollowupVisit);
