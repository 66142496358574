import React, { useState } from "react";
import styled, { css, cx } from "react-emotion";
import LegacyModal from "src/shared/LegacyModal";
import money from "src/utils/money";
import RadioIcon from "src/shared/Icons/Radio";
import Button, { ButtonRow } from "src/shared/Button";
import color from "src/styles/color";
import InputMoney from "src/shared/MoneyInput";
import { useRefund } from "./query";
import { Order, Charge, Refund, Patient } from "src/types/gql";

type Props = {
  patient: Patient;
  order: Order;
  charge: Charge;
  onClose: () => void;
};

type AsyncState = {
  state: "none" | "loading" | "error";
  error?: string | undefined;
};

const RefundFC: React.FC<Props> = props => {
  const { charge, order } = props;
  const [status, setStatus] = useState<AsyncState>({ state: "none" });
  const createRefund = useRefund();

  const alreadyRefunded = order.refunds
    .filter(r => r.chargeId === charge.id)
    .reduce((sum: number, r: Refund) => sum + r.amount, 0);
  // The max we can refund
  const max = charge.amount - alreadyRefunded;
  const [amount, setAmount] = useState(max);
  const full = amount === max;

  const onSubmit = async () => {
    if (status.state === "loading") {
      // No double submissions
      return;
    }
    setStatus({ state: "loading" });

    const result = await createRefund({
      newRefund: { chargeId: charge.id, amount: amount },
    });
    if (result.error) {
      setStatus({ state: "error", error: result.error.message });
      return;
    }
    if (!result) {
      setStatus({
        state: "error",
        error: "Something went wrong creating the refund",
      });
      return;
    }
    props.onClose();
  };

  return (
    <LegacyModal width={600} isOpen closeModal={props.onClose}>
      <LegacyModal.Title>Refund</LegacyModal.Title>
      <LegacyModal.Content>
        <Item className={cx([full && selected])} onClick={() => setAmount(max)}>
          <RadioIcon checked={full} />
          <div>
            <Title>Full refund</Title>
            <span>Issue a full refund for {money(max)}</span>
          </div>
        </Item>
        <Item className={cx([!full && selected])}>
          <RadioIcon checked={!full} />
          <div>
            <Title>Partial refund</Title>
            <label>
              Amount to refund:
              <InputMoney value={amount} onChange={setAmount} max={max} />
            </label>
          </div>
        </Item>

        {status.error && (
          <p style={{ color: color.red, textAlign: "right" }}>{status.error}</p>
        )}

        <ButtonRow position="right">
          <Button
            type="primary"
            onClick={onSubmit}
            disabled={status.state === "loading"}
          >
            {status.state === "loading" ? "Refunding..." : "Issue refund"}
          </Button>
        </ButtonRow>
      </LegacyModal.Content>
    </LegacyModal>
  );
};

const Item = styled.div`
  border: 1px solid ${color.border};
  border-radius: 3px;
  margin: 24px 0;
  padding: 18px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 30px auto;
  align-items: center;

  &:hover {
    background: ${color.background};
  }

  input {
    margin: 4px 0 0 0;
  }

  > sgv {
    display: block;
    margin: 0 18px 0 0;
  }
`;

const Title = styled.p`
  font-weight: bold;
  margin: 0;
`;

const selected = css`
  border: 1px solid ${color.primary};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
`;

export default RefundFC;
