import estimate from "./estimate";
import piv from "./piv";
import rrx from "./rrx";
import tp from "./tp";
import vbt from "./vbt";
import vp from "./vp";
import vc from "./vc";
import vfc from "./vfc";
import followupPrescription from "./followup-prescription";
import pra from "./pra"; // this is the followup visit.
import ops from "./ops";
import outcome from "./outcome";

export const FORM_NAME = {
  ESTIMATE: "estimate",
  RRX: "rrx",
  OUTCOME: "outcome",
  PIV: "piv",
  TP: "tp",
  VBT: "vbt",
  VP: "vp",
  VC: "vc",
  VFC: "vfc",
  PRA: "pra",
  OPS: "ops",
  FOLLOWUP_PRESCRIPTION: "followupPrescription",
};

export const TRANSLATED_FORM_NAME = {
  estimate: "Estimate",
  rrx: "Prescription",
  outcome: "Outcome",
  piv: "Beginning Visit",
  tp: "Treatment Setup",
  vbt: "Started Tx",
  vp: "Progress Check",
  vc: "Comfort Check",
  vfc: "Final Check",
  followupPrescription: "Followup Prescription",
  pra: "Followup Visit",
};

// used for display of forms
export const ORDERED_FORM_LIST = [
  "estimate",
  "rrx",
  "piv",
  "tp",
  "vbt",
  "vp",
  "vc",
  "vfc",
  "followupPrescription",
  "pra",
  "outcome",
];

const forms = {
  ORDERED_FORM_LIST,
  FORM_NAME,
  TRANSLATED_FORM_NAME,
  estimate,
  rrx,
  piv,
  tp,
  treatment_plan: tp,
  vbt,
  vp,
  vc,
  vfc,
  followupPrescription,
  pra,
  ops,
  outcome,
};

export default forms;
