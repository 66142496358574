import React, { useState } from "react";
import { extractChildrenByType } from "src/shared/subcomponents";
import time from "src/shared/time";
// local
import useCurrentTimeTop from "src/shared/useCurrentTimeTop";
import ScheduleTile from "src/shared/ScheduleTile";
import {
  TimeMarks,
  TimeMark,
  Columns,
  Popup,
  Slots,
  Perimeter,
  Header,
  EmptyColumnsItem,
  Body,
  RightTriangle,
  Schedule,
  CurrentTimeline,
} from "./styles";
import { hours } from "src/shared/util";
import { EmptySlot } from "src/types/api";
import { Appointment as AppointmentType } from "src/types/gql";

type Columns = Array<any>;

// Schedule represents the schedule for a single column (bookable resource).
type Schedule = Array<{
  id: string; // resource ID
  unsaved: EmptySlot[];
  appointments: AppointmentType[];
  selected?: boolean;
}>;

type ISO = string;

type Props = {
  columns: Columns;
  schedule: Schedule;
  dates: Array<ISO>;
  timezone: string;
};

const Calendar = props => {
  const renderColumn = extractChildrenByType(Columns, props.children);
  const renderPopup = extractChildrenByType(Popup, props.children);
  const renderSlots = extractChildrenByType(Slots, props.children);
  const currentTimeTop = useCurrentTimeTop(props.timezone);
  const [, shouldUpdateSchedule] = useState(false);

  const { localIsoDate } = time(props.timezone);
  const todayUTC = localIsoDate();

  let isTodayArray = [];
  let isToday;
  let showAllCurrentTime = false;
  // Week View
  if (props.dates.length > 1) {
    isTodayArray = props.dates.map(date => date === todayUTC);
    isToday = isTodayArray.reduce(
      (acc, current) => (acc ? acc : current ? current : false),
      false
    );

    // Day View
  } else {
    isToday = props.dates[0] === todayUTC;
    showAllCurrentTime = isToday;
  }

  return (
    <Perimeter>
      <Header>
        <EmptyColumnsItem />
        {props.columns.map(renderColumn)}
      </Header>
      <Body>
        <TimeMarks>
          {isToday && currentTimeTop && (
            <RightTriangle
              style={{
                top: `${currentTimeTop}%`,
              }}
            />
          )}
          {hours.map(({ label }, idx) => (
            <TimeMark key={label}>{label}</TimeMark>
          ))}
        </TimeMarks>

        {props.schedule.map((scheduleItem, colIdx) => (
          <Schedule key={colIdx}>
            {(showAllCurrentTime || isTodayArray[colIdx]) && currentTimeTop && (
              <CurrentTimeline
                style={{
                  top: `${currentTimeTop}%`,
                }}
              />
            )}
            {hours.map(({ label }, idx) => (
              <ScheduleTile
                key={label}
                renderPopup={renderPopup}
                rowIndex={idx}
                scheduleItem={scheduleItem}
                updateSchedule={shouldUpdateSchedule}
              />
            ))}
            {renderSlots(scheduleItem, `${colIdx}`)}
          </Schedule>
        ))}
      </Body>
    </Perimeter>
  );
};

export default Calendar;
