import { useMutation } from "src/utils/http/gqlQuery";

const mutation = `
  mutation CompleteHold($holdID: ID!) {
    completeHold(holdID: $holdID) {
      id
      category
      completedAt
    }
  }
`;

export const useCompleteHold = () => {
  const [, execute] = useMutation<{ userID: string }>(mutation);
  return execute;
};
