import React from "react";
import { AutoloadProviders } from "src/redux/modules/insurance";
import { Dialog } from "material-ui";
import InsuranceProvider from "./insurance-provider";
import { InsuranceProps as Props } from "src/types/local";

const InsuranceDialog = (props: Props) => {
  const { patientId, providers, open, onClose } = props;

  return (
    <Dialog
      open={open}
      onRequestClose={onClose}
      autoScrollBodyContent
      repositionOnUpdate
    >
      {providers &&
        providers.map((p: any) => (
          <InsuranceProvider
            key={p.provider}
            provider={p}
            patientId={patientId}
          />
        ))}
    </Dialog>
  );
};

export default AutoloadProviders(InsuranceDialog);
