import React from "react";
import { flatten } from "lodash";
import styled, { css } from "react-emotion";
import { DateTime } from "luxon";

import Modal from "src/shared/Modal";
import Button, { ButtonRow } from "src/shared/Button";
import Box from "src/shared/Box";
import color from "src/styles/color";

const sortBySendDate = (docA, docB) =>
  docB.createdAt.localeCompare(docA.createdAt);

const SentHistory = ({ documents, onClose }) => {
  const allEmailEvents = flatten(documents.map(doc => doc.emailEvents)).sort(
    sortBySendDate
  );

  return (
    <Modal onClose={onClose}>
      <Box title="Sent history">
        <Container>
          <Content>
            {allEmailEvents.map(event => (
              <Item key={event.id}>
                {event.eventData.files.map(f => (
                  <FileTitle key={f.id}>{f.filename}</FileTitle>
                ))}
                <div>
                  Submitted:{" "}
                  {DateTime.fromISO(event.createdAt).toFormat("LLLL d, yyyy")}
                </div>
                <div>
                  To:{" "}
                  {event.eventData.externalSpecialist.verifiedSpecialist.email}
                </div>
              </Item>
            ))}
          </Content>
          <ButtonRow className={buttonRow} position="right">
            <Button kind="primary" onClick={onClose}>
              Close
            </Button>
          </ButtonRow>
        </Container>
      </Box>
    </Modal>
  );
};

const Container = styled.div`
  height: 450px;
  width: 550px;

  display: flex;
  flex-flow: column;
  overflow: hidden;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  overflow: auto;
`;

const buttonRow = css`
  padding: 16px 24px;
  border-top: 1px solid ${color.border};
`;

const Item = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-flow: column;
  border-bottom: 1px solid ${color.border};
  line-height: 20px;
`;

const FileTitle = styled.div`
  font-weight: 600;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

export default SentHistory;
