import React from "react";
import { Perimeter, Input } from "./styles";

const InputText = React.forwardRef((props: any, ref) => {
  const { error, className, ...rest } = props;
  return (
    <Perimeter className={className}>
      <Input {...rest} type="text" theme={{ error }} ref={ref} />
    </Perimeter>
  );
});

export default InputText;
