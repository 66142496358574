import React, { PureComponent } from "react";
import type { Checkin } from "types";
import PeriodicCheckin from "./periodic";
import StageCheckin from "./stage";

type Props = {
  checkin: Checkin,
};

/**
 * Checkin renders either a PeriodiCheckin or a StageCheckin depending
 * on the checkin passed as a prop.
 *
 * This allows parent components to render a single Checkin component for all
 * checkins without having to check the type.
 *
 */
export default class CheckinParent extends PureComponent<Props> {
  render() {
    const { checkin } = this.props;
    switch (checkin.checkin_type) {
      case "periodic":
        return <PeriodicCheckin checkin={checkin} />;
      case "stage":
        return <StageCheckin checkin={checkin} />;
      default:
        // this is an exhaustive match and will not hit given types
        // are correct.
        return null;
    }
  }
}
