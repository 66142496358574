import { V, S } from "src/shared-styles";

const quadrantBorder = `1px solid ${V.colors.borderColorDark}`;
export const toothHeight = 100;
const quadrantContainer = {
  display: "inline-block",
  width: "50%",
  boxSizing: "border-box",
  height: toothHeight,
  overflow: "hidden",
};
const toothDisplayNum = {
  position: "absolute",
  zIndex: 1,
  height: 8,
  width: "100%",
  fontSize: 8,
  textAlign: "center",
};
export const actionColor = {
  Caries: "rgb(114,114,114)",
  "Gum Disease": "rgb(154,154,154)",
  IPR: "rgb(85, 244, 228)",
  Attachments: "rgb(235, 160, 239)",
  Elastics: "rgb(4, 89, 0)",
  L: "rgb(85, 244, 228)",
  M: "rgb(6, 160, 211)",
  H: "rgb(1, 62, 109)",
};
export const gumBackgroundColor = "rgb(255, 249, 249)";
export default {
  V,
  S,
  container: {
    position: "fixed",
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 5,
  },
  maxContainerWidth: {
    ...S.contentBlock,
    width: "100%",
    maxWidth: V.containerMaxWidth - 2 * V.spacingSmall,
    padding: V.spacingTiny,
    margin: "0 auto",
  },
  toothChartWrapper: {
    display: "inline-block",
  },
  minimizeButton: {
    float: "right",
  },
  closeButton: {
    float: "right",
  },
  toothChartLabel: {
    color: V.colors.white,
  },
  toothListContainer: {
    fontSize: 0, // remove inline-block spacing
    position: "relative",
    paddingBottom: V.spacingSmall,
  },
  upperRightContainer: {
    ...quadrantContainer,
    borderBottom: quadrantBorder,
    borderRight: quadrantBorder,
  },
  upperLeftContainer: {
    ...quadrantContainer,
    borderBottom: quadrantBorder,
    borderLeft: quadrantBorder,
  },
  lowerRightContainer: {
    ...quadrantContainer,
    borderTop: quadrantBorder,
    borderRight: quadrantBorder,
  },
  lowerLeftContainer: {
    ...quadrantContainer,
    borderTop: quadrantBorder,
    borderLeft: quadrantBorder,
  },
  toothDisplayNum: {
    U: {
      ...toothDisplayNum,
      top: 2,
    },
    L: {
      ...toothDisplayNum,
      bottom: 2,
    },
  },
  // Action Buttons
  action: {
    root: {
      border: V.border,
      marginTop: V.spacingSmall,
      marginBottom: V.spacingTiny,
      marginRight: V.spacingTiny,
      minWidth: 20,
    },
    label: {
      textTransform: "none",
      paddingLeft: V.spacingTiny,
      paddingRight: V.spacingTiny,
    },
    activeLabel: {
      color: V.colors.white,
    },
    buttonBg: actionColor,
  },
  label: {
    right: {
      zIndex: 4,
      color: V.colors.fontFaded,
      fontSize: V.fontTiny,
      position: "absolute",
      left: -7,
      top: 94,
      transform: "rotate(270deg)",
      background: gumBackgroundColor,
    },
    left: {
      color: V.colors.fontFaded,
      zIndex: 4,
      fontSize: V.fontTiny,
      position: "absolute",
      right: -4,
      top: 94,
      transform: "rotate(90deg)",
      background: gumBackgroundColor,
    },
    maxillary: {
      color: V.colors.fontFaded,
      zIndex: 4,
      fontSize: V.fontTiny,
      position: "absolute",
      width: "100%",
      textAlign: "center",
      top: -12,
    },
    lingual: {
      color: V.colors.fontFaded,
      zIndex: 4,
      fontSize: V.fontTiny,
      position: "absolute",
      width: "100%",
      textAlign: "center",
      top: 94,
    },
    mandibular: {
      color: V.colors.fontFaded,
      zIndex: 4,
      fontSize: V.fontTiny,
      position: "absolute",
      width: "100%",
      textAlign: "center",
      bottom: -3,
    },
    span: {
      background: gumBackgroundColor,
    },
  },
  elasticLabel: {
    position: "absolute",
    fontSize: V.fontTiny,
    color: V.colors.fontLight,
  },
};
