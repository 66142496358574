import { kea } from "kea";
import { request } from "src/shared/util";
import { compose, lifecycle } from "recompose";
import { Staff } from "src/types/api";

export interface Thunks {
  getStaff(): Promise<any>;
}

export type Actions = Thunks;

export interface ReducerProps {
  staff: Array<Staff>;
}

export type Props = ReducerProps & {
  actions: Actions;
};

export const StaffEnhancer = kea({
  actions: () => ({
    setStaff: a => a,
    getStaffStart: () => null,
    getStaffError: () => null,
    getStaffEnd: () => null,
  }),

  reducers: ({ actions }) => ({
    staff: [
      [],
      {
        [actions.setStaff]: (prev, result) => result,
      },
    ],

    staffRequestState: [
      { loading: true, error: false },
      {
        [actions.getStaffStart]: () => ({ loading: true, error: false }),
        [actions.getStaffError]: () => ({ loading: false, error: true }),
        [actions.getStaffEnd]: () => ({ loading: false, error: false }),
      },
    ],
  }),

  thunks: ({ actions }) => ({
    getStaff: async () => {
      // const result = await request(`/api/v1/staff`);
      // if (Array.isArray(result)) {
      //   actions.setStaff(result);
      // }
      // TODO: ApiRequestState actions.
    },

    assignStaffToAppointment: async (staffId, apptId) => {
      await request(`/api/v1/appointments/${apptId}/staff/${staffId}`, {
        method: "POST",
      });
    },
  }),
});

export const AutoloadStaff = compose(
  StaffEnhancer,
  lifecycle({
    componentDidMount() {
      const { actions } = this.props as Props;
      actions.getStaff();
    },
  })
);
