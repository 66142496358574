import React from "react";
import styled from "react-emotion";
import { upperFirst, isEmpty } from "lodash";

import color from "src/styles/color";
import { Eligibility } from "src/types/gql";

const Wrapper = styled.div`
  border-top: 1px solid ${color.gray3};
  border-bottom: 1px solid ${color.gray3};
  background: ${color.background};
  padding: 24px 30px 36px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: ${color.gray4};
`;

const BlankText = styled.div`
  color: ${color.gray3};
`;

type Props = {
  eligibility: Eligibility;
};

type LimitationProps = {
  l: string;
};

const getQuantityQualifier = quantityQualifier => {
  if (quantityQualifier === "age_high_value") return "years max";
  if (quantityQualifier === "age_low_value") return "years min";

  return quantityQualifier;
};

const Limitation = ({ l }) => {
  const lim = JSON.parse(l);

  const hasLimitation = lim.limitation && lim.limitation !== "NA";
  const hasQuantity = Boolean(lim.quantity);

  if (lim.disclaimers && lim.disclaimers.length > 1) {
    return (
      <div>
        <div>
          {lim.disclaimers.map(disclaimer => (
            <div>{disclaimer}</div>
          ))}
          {(hasLimitation || hasQuantity) && (
            <>
              {lim.category && <span>{upperFirst(lim.category)}: </span>}
              {!lim.category && lim.service_type && (
                <span>{upperFirst(lim.service_type)}: </span>
              )}
              {lim.procedure_code && <span>{lim.procedure_code}: </span>}
            </>
          )}
          {hasLimitation && <span>{lim.limitation} </span>}
          {lim.quantity} {getQuantityQualifier(lim.quantity_qualifier)}
        </div>
      </div>
    );
  }

  if (lim.service_type === "noncovered_orthodontic_services") {
    return (
      <div>
        Not covered:{" "}
        {lim.limitation_applies_to && lim.limitation_applies_to.join(", ")}
      </div>
    );
  }

  return (
    <div>
      <div>
        {!isEmpty(lim.disclaimers) && <span>{lim.disclaimers[0]} </span>}
        {!isEmpty(lim.disclaimers) && lim.quantity && " - "}
        {(hasLimitation || hasQuantity) && (
          <>
            {lim.category && <span>{upperFirst(lim.category)}: </span>}
            {!lim.category && lim.service_type && (
              <span>{upperFirst(lim.service_type)}: </span>
            )}
            {lim.procedure_code && <span>{lim.procedure_code}: </span>}
          </>
        )}
        {hasLimitation && <span>{lim.limitation} </span>}
        {lim.quantity} {getQuantityQualifier(lim.quantity_qualifier)}
      </div>
    </div>
  );
};

const ExpandedInsuranceEligibility: React.FC<Props> = ({ eligibility }) => {
  return (
    <Wrapper>
      <Title>Additional information</Title>
      {isEmpty(eligibility.coinsuranceDisclaimers) && (
        <BlankText>N/A</BlankText>
      )}
      {eligibility.coinsuranceDisclaimers.map(disclaimer => (
        <div key={disclaimer}>{disclaimer}</div>
      ))}
      <Title style={{ marginTop: "20px" }}>Limitations</Title>
      {isEmpty(eligibility.limitations) && (
        <BlankText>No known limitations</BlankText>
      )}
      {eligibility.limitations.map(l => {
        return <Limitation l={l} key={l} />;
      })}
    </Wrapper>
  );
};

export default ExpandedInsuranceEligibility;
