import React from "react";
import gql from "graphql-tag";
import Dropdown from "src/shared/Dropdown";
import { useQuery } from "src/utils/http/gqlQuery";
import { OTHER_UUID } from "src/scenes/PatientProfile/Forms/GQLForms/Form";
import Loading from "src/shared/Loading";
import orderBy from "lodash/orderBy";

const GetInsuranceProviders = gql`
  query GetInsuranceProviders {
    insuranceProviders {
      id
      name
    }
  }
`;

// A very basic insurance provider picker.
export default function InsuranceProviderPicker({ value, disabled, onChange }) {
  const [{ fetching, error, data }] = useQuery({
    query: GetInsuranceProviders,
  });
  const providers = data ? data.insuranceProviders : [];
  const other = {
    value: OTHER_UUID,
    label: "Other",
  };
  const sortedOptions = orderBy(
    providers.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
    [({ label }) => label.toLowerCase()]
  );
  if (fetching) {
    return <Loading />;
  }
  if (error) {
    return <em>Error: failed to load insurance providers.</em>;
  }
  return (
    <Dropdown
      placeholder="Select an insurance provider"
      disabled={disabled}
      selected={value}
      options={[other].concat(sortedOptions)}
      onSelect={({ value }) => onChange(value)}
      width="272px"
    />
  );
}
