import { CombinedError, useQuery, useMutation } from "src/utils/http/gqlQuery";
import { ExternalSpecialist, PageResults, File } from "src/types/gql";

export const topLevelFields = [
  "type",
  "firstName",
  "lastName",
  "email",
  "phone",
  "clinicName",
  "lastVisit",
];

export const fields = [
  ["type"],
  ["lastVisit"],
  ["firstName", "lastName"],
  ["email", "phone"],
  ["clinicName"],
  ["line1"],
  ["line2"],
  ["city", "state", "postalCode"],
  ["country"],
];

export const externalSpecialistsQuery = `
  query ExternalSpecialists($filter: ExternalSpecialistFilter!) {
    externalSpecialists(filter: $filter) {
      externalSpecialists {
        id
        lastVisit
        userID
        state
        referral {
          id
          formSubmissionID
        }
        verifiedSpecialist {
          id
          type
          firstName
          lastName
          email
          phone
          phone2
          clinicName
          preferredProvider
          address {
            id
            line1
            line2
            city
            state
            postalCode
            country
            latitude
            longitude
          }
      }

      }
      pageResults {
        page
        perPage
        totalItems
        totalPages
      }
    }
  }
`;

const compareSpecialists = (a, b) => {
  const nameA = `${a.firstName} ${a.lastName}`;
  const nameB = `${b.firstName} ${b.lastName}`;
  return nameA.localeCompare(nameB);
};

const getSorted = (data): ExternalSpecialist[] => {
  if (!data || !data.externalSpecialists) return [];
  return [...data.externalSpecialists.externalSpecialists].sort(
    compareSpecialists
  );
};

type ExternalSpecialistFilter = {
  userID: string;
};

export const useGetExternalSpecialists = (
  filter: ExternalSpecialistFilter
): [boolean, CombinedError | undefined, ExternalSpecialist[]] => {
  const [{ fetching, error, data }] = useQuery<{
    externalSpecialists: {
      externalSpecialists: Array<ExternalSpecialist>;
      pageResults: PageResults;
    };
  }>({ query: externalSpecialistsQuery, variables: { filter } });
  return [fetching, error, getSorted(data)];
};

const updateExternalSpecialistGql = `
  mutation UpdateExternalSpecialist($updateExternalSpecialist : UpdateExternalSpecialist!) {
    updateExternalSpecialist(updateExternalSpecialist: $updateExternalSpecialist) {
      id
    }  
  }
`;

export const useUpdateExternalSpecialist = () => {
  const [, execute] = useMutation<
    { updateExternalSpecialist: ExternalSpecialist },
    {
      updateExternalSpecialist: {
        id: string;
        state: string;
      };
    }
  >(updateExternalSpecialistGql);
  return execute;
};

const addExternalSpecialist = `
  mutation AddExternalSpecialist($input: AddExternalSpecialist!) {
    addExternalSpecialist(addExternalSpecialist: $input) {
      id
      userID
      lastVisit
      verifiedSpecialist {
        id
        firstName
        lastName
        clinicName
        phone
      }
    }
  }
`;

const emailExternalSpecialist = `
  mutation EmailExternalSpecialist($opts: NewEmailExternalSpecialist!) {
    emailExternalSpecialist(opts: $opts) {
      id
    }
  }
`;

export const useEmailExternalSpecialist = () => {
  const [, execute] = useMutation(emailExternalSpecialist);
  return execute;
};

export const useAddExternalSpecialist = () => {
  const [, execute] = useMutation(addExternalSpecialist);
  return execute;
};

const deleteExternalSpecialist = `
  mutation DeleteExternalSpecialist($id: ID!) {
    deleteExternalSpecialist(id: $id) {
      ids
    }
  }
`;

export const useDeleteExsternalSpecialist = () => {
  const [, execute] = useMutation(deleteExternalSpecialist);
  return execute;
};

export const filesQuery = `
  query Files($id: ID!, $types: [FileType!]) {
    files(userId: $id, types: $types) {
      id
      filename
      type
      description
      createdAt
      subtype
      emailEvents {
        id
        name
        createdAt
        eventData {
        	... on EmailExternalSpecialist {
            files {
              id
              filename
            }
          	externalSpecialist {
  						verifiedSpecialist {
                email
              }
            }
          }

          ... on EmailPatientDocuments {
            files {
              id
              filename
            }
            user {
              email
            }
          }
        }
      }
    }
  }

`;

export const useGetUserFiles = (
  userID: string,
  types: string[] = []
): [boolean, CombinedError | undefined, File[], (_?: any) => void] => {
  const [{ fetching, error, data }, refetch] = useQuery<{
    files: File[];
  }>({ query: filesQuery, variables: { id: userID, types } });
  return [fetching, error, (data && data.files) || [], refetch];
};
