import { get } from "lodash";
import React, { Component } from "react";
import { markPatientAsSpam } from "src/redux/modules/patient";
import PropTypes from "prop-types";

import { Dialog, FlatButton, TextField } from "material-ui";
import { connect } from "react-redux";
import { routerShape } from "react-router";

/**
 * This is straight copied from delete-user.  I hate the coding conventions here...
 * The hygeine is poor.  The if statements are poor.  All of it.
 */
const connected = connect(
  null,
  { markPatientAsSpam }
);
export class MarkAsSpam extends Component {
  static contextTypes = {
    patient: PropTypes.object,
    router: routerShape,
  };

  state = {
    reason: "",
  };

  handleTextChange = (e, value) => {
    this.setState({ reason: value });
  };

  handleMarkAsSpam = () => {
    this.props
      .markPatientAsSpam(this.context.patient.id, this.state.reason)
      .then(() => {
        this.context.router.push("/");
      });
  };

  render() {
    if (!get(this, "context.patient.first_name")) return null;

    const actions = [
      <FlatButton label="Cancel" onClick={this.handleClose} />,
      <FlatButton
        label="Mark as Spam"
        primary
        keyboardFocused
        onClick={this.handleMarkAsSpam}
      />,
    ];

    return (
      <Dialog
        title="Are you sure you want to mark this user as spam?"
        actions={actions}
        modal={false}
        open={this.props.open}
        onRequestClose={this.props.handleClose}
      >
        <h3>Optionally add a reason for marking the user as spam:</h3>
        <TextField
          id="textfield-delete-user"
          onChange={this.handleTextChange}
        />
      </Dialog>
    );
  }
}

export default connected(MarkAsSpam);
