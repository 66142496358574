import React from "react";
import ConsentIcon from "src/shared/Icons/Consent";
import PatientConsents from "src/components/patient-consents/PatientConsents";

import useConsentsContext from "./useConsentsContext";
import HeaderButton from "src/scenes/PatientProfile/PatientProfileHeader/HeaderButton";

import styled from "react-emotion";

const StyleMenu = styled.div`
  min-width: 160px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  padding: 4px 0;
  background: white;
`;

const HeaderConsents = props => {
  const { patient, clinicalRecord } = props;
  const { consents, signConsent } = useConsentsContext();
  const outstandingConsents = consents && consents.filter(c => !c.signedAt);

  return (
    <HeaderButton
      dropdownMenu={
        <StyleMenu>
          <PatientConsents
            patient={patient}
            clinicalRecord={clinicalRecord}
            consents={consents}
            signConsent={signConsent}
          />
        </StyleMenu>
      }
      tooltip="Patient forms"
      theme={{
        showBadge: outstandingConsents && outstandingConsents.length,
      }}
    >
      <ConsentIcon />
    </HeaderButton>
  );
};

export default HeaderConsents;
