import React from "react";

const Amex = () => (
  <svg width="64" height="20" viewBox="0 0 64 20" fill="none">
    <path
      d="M10.3201 3L4 16.982H11.566L12.504 14.7527H14.6479L15.5859 16.982H23.9139V15.2805L24.656 16.982H28.9639L29.706 15.2446V16.982H47.026L49.1321 14.8106L51.1041 16.982L60 17L53.66 10.03L60 3H51.242L49.1919 5.13129L47.282 3H28.4401L26.8221 6.60888L25.1662 3H17.616V4.64359L16.7761 3C16.7761 3 10.3201 3 10.3201 3ZM11.784 4.98546H15.472L19.6641 14.4667V4.98546H23.7042L26.9421 11.7835L29.9262 4.98546H33.9461V15.0185H31.5001L31.4801 7.15667L27.9141 15.0185H25.726L22.14 7.15667V15.0185H17.108L16.154 12.7692H11.0001L10.0481 15.0165H7.35196L11.784 4.98546ZM36.184 4.98546H46.13L49.172 8.27045L52.312 4.98546H55.3541L50.732 10.0281L55.3541 15.0126H52.1741L49.1321 11.6894L45.976 15.0126H36.184V4.98546ZM13.5781 6.683L11.8801 10.6899H15.2742L13.5781 6.683ZM38.6401 7.06281V8.89433H44.0661V10.9357H38.6401V12.9352H44.7261L47.554 9.99013L44.8461 7.06106H38.6401V7.06281Z"
      fill="#26A6D1"
    />
  </svg>
);

export default Amex;
