/* eslint-disable */
// use the export feature in QUIP to generate the html
// escape \ backslashes with another \
export default `<!doctype html>
<html><head><title>Uniform Financial Policy &amp; Treatment Consent</title><meta charset="UTF-8"><link href="http://fonts.googleapis.com/css?family=Crimson+Text:400,400italic,700,700italic|Roboto:400,700,700italic,400italic" rel="stylesheet" type="text/css"><style>/*
 * Copyright 2014 Quip
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License. You may obtain
 * a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations
 * under the License.
 */

body {
    font-size: 15px;
    color: #333;
    background: #fff;
    padding: 60px 95px;
    max-width: 900px;
    margin: 0 auto;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern";
    font-kerning: normal;
    -moz-font-feature-settings: "kern";
    -webkit-font-feature-settings: "kern";
}

/* Headings */
h1, h2, h3, th {
    font-family: Roboto, sans-serif;
    font-weight: 700;
    margin: 0;
    margin-top: 1.25em;
    margin-bottom: 0.75em;
}

h1 {
    font-size: 35px;
    line-height: 42px;
}

h1:first-child {
    margin-top: 0;
}

h2 {
    font-size: 18px;
    line-height: 22px;
}

h3 {
    text-transform: uppercase;
    font-size: 13px;
    line-height: 16px;
}

/* Body text */
body, p, ul, ol, td {
    font-family: 'Crimson Text', serif;
    font-size: 16px;
    line-height: 20px;
}

blockquote, q {
    display: block;
    margin: 1em 0;
    font-style: italic;
}

blockquote a, q a {
    text-decoration: underline;
}

blockquote {
    padding-left: 10px;
    border-left: 4px solid #a6a6a6;
}

q {
    color: #a6a6a6;
    line-height: 40px;
    font-size: 24px;
    text-align: center;
    quotes: none;
}

q a {
    color: #a6a6a6;
}

code, pre {
    font-family: Consolas, "Liberation Mono", Menlo, "Courier Prime Web", Courier, monospace;
    background: #f3f3f3;
}

code {
    padding: 1px;
    margin: 0 -1px;
    border-radius: 3px;
}

pre {
    display: block;
    line-height: 20px;
    text-shadow: 0 1px white;
    padding: 5px 5px 5px 30px;
    white-space: nowrap;
    position: relative;
    margin: 1em 0;
}

pre:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    border-left: solid 1px #dadada;
}

/* Lists */
div[data-section-style="5"],
div[data-section-style="6"],
div[data-section-style="7"] {
    margin: 12px 0;
}

ul {
    padding: 0 0 0 40px;
}

ul li {
    margin-bottom: 0.4em;
}

/* Bulleted list */
div[data-section-style="5"] ul {
    list-style-type: disc;
}
div[data-section-style="5"] ul ul {
    list-style-type: circle;
}
div[data-section-style="5"] ul ul ul {
    list-style-type: square;
}
div[data-section-style="5"] ul ul ul ul {
    list-style-type: disc;
}
div[data-section-style="5"] ul ul ul ul ul {
    list-style-type: circle;
}
div[data-section-style="5"] ul ul ul ul ul ul {
    list-style-type: square;
}

/* Numbered list */
div[data-section-style="6"] ul {
    list-style-type: decimal;
}
div[data-section-style="6"] ul ul {
    list-style-type: lower-alpha;
}
div[data-section-style="6"] ul ul ul {
    list-style-type: lower-roman;
}
div[data-section-style="6"] ul ul ul ul {
    list-style-type: decimal;
}
div[data-section-style="6"] ul ul ul ul ul {
    list-style-type: lower-alpha;
}
div[data-section-style="6"] ul ul ul ul ul ul {
    list-style-type: lower-roman;
}

/* Checklist */
div[data-section-style="7"] ul {
    list-style-type: none;
}

div[data-section-style="7"] ul li:before {
    content: "\\2610";
    position: absolute;
    display: inline;
    margin-right: 1.2em;
    margin-left: -1.2em;
}

div[data-section-style="7"] ul li.parent:before {
    content: "";
}

div[data-section-style="7"] ul li.parent {
    font-weight: bold;
}

div[data-section-style="7"] ul li.checked {
    text-decoration: line-through;
}

div[data-section-style="7"] ul li.checked:before {
    content: "\\2611";
    text-decoration: none;
}

/* Tables */
div[data-section-style="8"] {
    margin: 12px 0;
}

table {
    border-spacing: 0;
    border-collapse: separate;
    border: solid 1px #bbb;
    table-layout: fixed;
    position: relative;
}

table th, table td {
    padding: 2px 2px 0;
    min-width: 1.5em;
    word-wrap: break-word;
}

table th {
    border-bottom: 1px solid #e1e1e1;
    background: #f4f4f4;
    font-weight: bold;
    vertical-align: bottom;
    color: #3a4449;
    text-align: center;
}

table td {
    padding-top: 0;
    border-left: 1px solid #e1e1e1;
    border-top: 1px solid #e1e1e1;
    vertical-align: top;
}

table td.bold {
    font-weight: bold;
}

table td.italic {
    font-style: italic;
}

table td.underline {
    text-decoration: underline;
}

table td.strikethrough {
    text-decoration: line-through;
}

table td.underline.strikethrough {
    text-decoration: underline line-through;
}

table td:first-child {
    border-left: hidden;
}

table tr:first-child td {
    border-top: hidden;
}

/* Images */
div[data-section-style="11"] {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

div[data-section-style="11"][data-section-float="0"] {
    clear: both;
    text-align: center;
}

div[data-section-style="11"][data-section-float="1"] {
    float: left;
    clear: left;
    margin-right: 20px;
}

div[data-section-style="11"][data-section-float="2"] {
    float: right;
    clear: right;
    margin-left: 20px;
}

div[data-section-style="11"] img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: auto;
}

hr {
    width: 70px;
    margin: 20px auto;
}

/* Apps */
div[data-section-style="19"].placeholder {
    margin: 0.8em auto;
    padding: 4px 0;
    display: block;
    color: #29b6f2;
    text-align: center;
    border: 1px solid rgba(41, 182, 242, 0.2);
    border-radius: 3px;
    background: #e9f8fe;
    font-family: Roboto, sans-serif;
}

div[data-section-style="19"].first-party-element {
    margin-bottom: 10px;
    background-repeat: no-repeat;
    background-size: contain;
}

div[data-section-style="19"].first-party-element.kanban {
    background-image: url("https://d2i1pl9gz4hwa7.cloudfront.net/nK0hSyhsb4jrLIL2s5Ma-g");
    height: 166px;
}

div[data-section-style="19"].first-party-element.calendar {
    background-image: url("https://d2i1pl9gz4hwa7.cloudfront.net/OYujqLny03RILxcLIiyERg");
    height: 244px;
}

div[data-section-style="19"].first-party-element.poll {
    background-image: url("https://d2i1pl9gz4hwa7.cloudfront.net/fbIiFrcKGv__4NB7CBfxoA");
    height: 116px;
}

div[data-section-style="19"].first-party-element.countdown {
    background-image: url("https://d2i1pl9gz4hwa7.cloudfront.net/3bPhykD2dBei9sSjCWteTQ");
    height: 96px;
}

div[data-section-style="19"].first-party-element.process_bar {
    background-image: url("https://d2i1pl9gz4hwa7.cloudfront.net/ybQlHnHEIIBLog5rZmYs_w");
    height: 36px;
}

div[data-section-style="19"].first-party-element.project_tracker {
    background-image: url("https://d2i1pl9gz4hwa7.cloudfront.net/OFQU087b4Mxzz1ZaHwtjXA");
    height: 164px;
}

div[data-section-style="19"] img {
    margin: 0.5em;
}

div[data-section-style="19"] img.masked-image {
    margin: 0;
    transform-origin: top left;
}

div[data-section-style="19"] .image-mask {
    position: relative;
    overflow: hidden;
}
</style></head><body><h1 id='GGaACAkkDDs'>Uniform Financial Policy &amp; Treatment Consent</h1>

<h1 id='GGaACAUiPe3'><b><u>Financial Policy</u></b></h1>

Payment is due at the time services are rendered unless other arrangements are made in advance. Our office accepts FSA, HSA, most major credit cards, and select financing options. If you have dental insurance, as a courtesy, we will assist you in processing your insurance claims. However, your payment is due when services are rendered. All incurred charges are ultimately the responsibility of the patient (or legal guardian) regardless of insurance coverage. We must emphasize that as a dental care provider, our relationship is with you, our patient, and not with your insurance company. Our office is not a party to that contract or any possible restrictions.<br/>

<br/>

If financial arrangements have been made in advance, they must be secured by a credit card. By signing this agreement you consent to automatic debiting of your credit card as indicated in this document. You are responsible for notifying CVSTOM Co, which has contracted with Uniform Teeth (Dental Practice) to provide billing and collection services, immediately if there are any changes to the status of the card we have on file, including change of billing address and card expiration date.<br/>

<br/>

This treatment fee includes one set of retainers and the follow-up care, diagnostic records, emergency appointments and appliances recommended in this treatment plan. The fee remains unchanged whether treatment takes more or less time than originally estimated, provided we are receiving the expected amount of cooperation. If appliances need to be replaced due to loss, excessive breakage, misuse or careless handling, there will be additional charges applied. Our services may be discontinued only due to lack of cooperation (patient compliance), multiple failed appointments or failure to adhere to financial obligations. The fee quoted is valid for 90 days.<br/>

<br/>

<b><b>Treatment Fee:</b></b> The maximum cost of your treatment is $4,750 and is based on case complexity. Please note that additional interest may apply depending on the specific terms of the financing option you select. (For example, if you choose an upfront payment option, 0% interest will apply to the treatment fee; however, if you choose a financing option with APR, you are responsible for the treatment fee and additional interest from the APR).<br/>

 <br/>

<b><b>Discounts: </b></b>Further discounts may be awarded at the discretion of Uniform Teeth (Dental Practice).<br><br/>

<b>Contract: </b>Monthly remittance is not a monthly fee. Payments continue during vacations and absences. Payment of all fees under this contract is the primary responsibility of the patient. In case of a minor, payment of all fees under this contract is the primary responsibility of the patient’s parent or legal guardian. In a divorce situation, the parent who brings the child in for treatment will sign the contract, make the payments and be totally responsible for the entire fee. Treatment may sometimes take longer due to circumstances beyond the control of the patient or the doctor.<br/>

<br/>

<b>Insurance: </b>We are not currently credentialed with any insurance providers. If you have dental insurance, as a courtesy, we will assist you in processing your insurance claims.<br><br/>

<b>Collection: </b>Our services will be discontinued upon failure to adhere to the financial obligations. Recurring payments are withdrawn on the 1st of each month. There will be a monthly $25 late fee for any payment not paid according to the terms above. If not paid within 45 days, the full amount will be due and an attempt will be made to charge to the above credit card. If this is unsuccessful for any reason, accounts more than 90 days past due may be subject to termination of treatment and the account balance turned over to a collection agency.<br><br/>

<b>Transfer: </b>In the event that treatment cannot be completed in this office, the fee will be adjusted for services rendered to date. Any adjustments will be based solely on the judgment of the Doctor. Additional charges may incur at the receiving office.<br><br/>

<b><i>By clicking ‘<b><i>Continue</i></b>’ below I certify to have read and I understand the above financial policy and billing terms set forth in this contract.</i></b><br/>

<br/>

<h1 id='GGaACAV7ji6'>Treatment Consent</h1>

<b><u>Uniform Dental Practice</u></b><br/>

<i>a CVSTOM Co affiliate doctor</i><br/>

<br/>

<i>Orthodontic treatment is most successful when the orthodontist and the patient agree on the goals of treatment and understand the limitations and risks involved. We have recommended a series of clear aligners for your orthodontic treatment.</i><br/>

<br/>

<i>Although orthodontic treatment can lead to healthy teeth and provide important benefits, such as an attractive smile, you should also be aware that orthodontic treatment (including orthodontic treatment with clear aligners) has limitations and potential risks that you should consider before undergoing treatment. We want you to understand these risks so you can feel confident about your choice to proceed with orthodontic care.</i><br/>

<br/>

<i>Please read this information carefully, and ask the doctor to explain anything you do not fully understand. Ensure you know what is expected of you as the patient during treatment. After learning about the limits and risks you should also consider the option of not receiving orthodontic care. We want you to have all questions answered that may come to mind before you agree to proceed.</i><br/>

<br><br/>

<b><u>Contents</u></b><br/>

<p id='GGaACAPjVtJ'><b><b>Orthodontic Care Agreement</b></b></p>

<p id='GGaACAA4338'><b><b>Treatment Risks</b></b></p>

<p id='GGaACAbOAM0'><b><b>Treatment Consent</b></b></p>

<b><b>Use of Records Consent</b></b><br/>

<br/>

<h2 id='GGaACANWw3i'><b><u>Orthodontic Care Agreement:</u></b></h2>

<h3 id='GGaACA2Qvk6'><b><u>Device Description</u></b></h3>

“Clear aligners” are a series of clear plastic, removable appliances that move your teeth in small increments. Clear aligner products combine the doctor’s diagnosis and prescription with sophisticated computer technology to develop a treatment plan, which specifies the desired movements of your teeth during the course of your treatment. Upon approval of a treatment plan developed by your doctor, a series of customized clear aligners are produced specifically for you.<br/>

<h3 id='GGaACACxt3A'><b><u>Procedure</u></b></h3>

This section will describe to you the procedures you are likely to experience for clear aligner treatment. Any risks associated with these procedures are explained further in the next sections below.<br/>

<br><br/>

We will take special three-dimensional scans of your teeth to send them along with a prescription to the laboratory. Dental technicians will follow the doctor’s prescription to create a software model of your prescribed treatment. Upon approval of the treatment plan by the doctor, the dental lab will produce and mail a series of customized aligners to the clinic. The total number of aligners will depend on the complexity of the plan of treatment.<br/>

<br><br/>

The aligners are individually numbered. The doctor will dispense them to you with instructions. Typically you will wear your aligners for approximately 20 to 22 hours per day, removing them only to eat, brush, and floss for no longer than 30 minutes at a time. As directed by your doctor, you will switch to the next aligner in the series between every one between three weeks. Telehealth check-in appointments are usually 4-12 weeks apart.<br/>

<br><br/>

<b>Attachments</b>: You will probably have some small aesthetic bumps temporarily bonded onto your teeth during treatment called “attachments” or “engagers.” These facilitate specific dental movements that could not be easily achieved without them. In some instances, these are necessary to ensure that the clear aligners don't easily fall away from the teeth during normal usage. Attachments will be removed after treatment is completed.<br/>

<br><br/>

<b>Inter-Proximal Reduction (IPR):</b> The dental professional may advise that part of the orthodontic plan requires your teeth to be adjusted in order to provide space for the desired tooth movement to occur. This is called Inter-Proximal Reduction (IPR) and involves lightly polishing away small amounts of enamel from the sides of one or more teeth to make room for tooth movement. This procedure has several common names including interproximal reduction (IPR), reproximation, stripping, and enamelplasty. It is performed by the dental professional with a special polishing instrument. Other alternative methods for making room are: (1) extractions of teeth or (2) expanding the dental archform. There is a best choice for each case. If recommended for your case, the amount of room provided by IPR will be adequate and the least complex choice.<br/>

<br><br/>

<b>Elastics</b>: In more complex cases, the doctor may ask you to wear some elastics from the upper to the lower teeth. These elastics are designed to improve the relationship of the upper teeth to the lower teeth so that they fit together better. The way the elastics are worn depends on the complexity and particular arrangement of the teeth and jaws.<br/>

<br><br/>

<b>Additional Aligners:</b> Patients often receive a refinement set of additional aligners after the first basic set of aligners. If any aligners are lost by the patient during treatment, they may be replaced if necessary at a small fee.<br/>

<br><br/>

<b>Retainers</b>: Teeth tend to move gradually throughout one's lifetime regardless of whether they have had orthodontic treatment. Therefore, a retainer is required to be worn in order to maintain the position of teeth. Moreover, teeth move back to their original position at a faster rate during orthodontic treatment and for a period of time after being corrected orthodontically. The dental professional will give you more specific instructions for how to maintain the alignment of your teeth after your treatment is complete.<br/>

<br><br/>

<b><i>By clicking ‘I agree’ below, </i></b><b>I certify to have read and understood the procedures described above.</b><br/>

<br><b><u>2. Risks of Treatment:</u></b><br/>

Like other orthodontic treatments, the use of the clear aligner product may involve some of the risks outlined below:<br/>

<br><br/>

<b>Results and Length of Treatment: </b>We will set an estimated date of completion of the active treatment, based on our evaluation of the goals and steps of the treatment plan. As we proceed through the treatment, we may need to revise this estimate. Failure to wear the appliances for the required number of hours per day, not using the products as directed by your doctor, or missing your appointments can reduce the likelihood of achieving the desired outcome.<br/>

<br><br/>

<b>Discomfort:</b> Dental tenderness is normal after switching to the next aligner in the series. At first, gums, cheeks or lips may be scratched or irritated. This improves after the first week wearing aligners.<br/>

<br><br/>

<b>Rebound:</b> Teeth shift position and may shift after treatment. We will provide removable retainers after the active treatment to restrain this tendency.<br/>

<br><br/>

<b>Decalcification and Tooth Decay</b> Tooth decay, periodontal disease, inflammation of the gums, or permanent markings (e.g. decalcification) may occur if patients consume foods or beverages containing sugar, do not brush and floss their teeth properly, or do not use proper oral hygiene and preventative maintenance;<br/>

<br><br/>

<b>Temporary Side Effects: </b>The aligners may temporarily affect speech and may result in a lisp, although any speech impediment caused by the clear aligner products should disappear within one or two weeks if aligners are worn consistently while speaking. Aligners may also cause a temporary increase in salivation or mouth dryness and certain medications can heighten this effect.<br/>

<br/>

<br/>

<b>Periodontal (Gum and Bone) Disease</b>: During orthodontic treatment it is possible to experience some gum or bone loss, due to many factors, but the most likely is lack of adequate hygiene or professional monitoring by your dentist or periodontist. If your dentist has discovered some bone loss prior to orthodontic treatment, more frequent visits with your dentist may be advisable. If periodontal disease becomes active during treatment, we may advise revising the goals of treatment.<br/>

<br><br/>

<b>Jaw Surgery:</b> In some cases, oral surgery is necessary to correct jaw imbalances that are present prior to treatment. If oral surgery is required, risks associated with anesthesia and proper healing will be discussed prior to treatment.<br/>

<br><br/>

<b>Tooth Nerve:</b> A tooth that has been previously traumatized or significantly restored may become sick during the treatment. If this happens, the tooth may require additional dental treatment such as endodontic or restorative work, or, the tooth may be even be lost.<br/>

<br><br/>

<b>Root Resorption:</b> It is rare, but the length of the roots of one or more teeth may be shortened during orthodontic treatment, which may threaten the useful life of the affected tooth. A short tooth may be more difficult to position properly.<br/>

<br><br/>

<b>Injury from the Orthodontic Appliances: </b>Please avoid activities or food that could damage the appliances. Orthodontic appliances or parts thereof may be accidentally swallowed or aspirated. Existing dental restorations (e.g. crowns) may become dislodged and require re-cementation or revised restoration on the tooth. Breakage of the Aligner tray is more probable in cases with multiple missing teeth.<br/>

<br><br/>

<b>Jaw Joint (TMJ) dysfunction:</b> In rare instances, patients could experience jaw joint pain (TMJ), causing pain, headaches or ear problems. Many conditions predispose TMJ dysfunction, including history of injury, arthritis, family history, tooth grinding or clenching, and medical conditions. Please report any jaw symptoms, such as pain, popping, or limitation in jaw movement.<br/>

<br><br/>

<b>Bite Adjustment (equilibration): </b>We may recommend fine-tuning the final fit of your teeth by adjusting the surface of the chewing surfaces. The enamel of the teeth may also require recontouring or slenderizing in order to create space to allow tooth movement to occur.<br/>

<br><br/>

<b>Third Molars (wisdom teeth):</b> If wisdom teeth do not have room to grow in, this can damage the adjacent teeth or the bone around the adjacent tooth. If removal of these teeth is recommended, a consultation with an Oral Maxillofacial Surgeon will be arranged.<br/>

<br><br/>

<b>Impacted, ankylosed, or unerupted teeth:</b> We cannot anticipate that a tooth may be stuck under the gum (impacted), or fused to the bone (ankylosed). We will make a recommendation for remedying if we encounter this condition.<br/>

<br><br/>

<b>Allergies:</b> Occasionally, some patients have reported allergies to some of the materials used in clear aligner appliances. If this is discovered, please alert the dental professional. Changes in the treatment procedures may be required.<br/>

<br><br/>

<b>General Health/Medications:</b> Bone, blood, endocrine conditions, or certain medications can affect the speed or the level of risk of orthodontic treatment. For example, length of treatment may be affected in patients who are now taking, or have taken in the past, medications known as “bisphosphonates,” which can inhibit tooth movement during orthodontics. The effects of these medications may be severe enough to stop tooth movement. Please advise your orthodontist of any change in your medical condition.<br/>

<br><br/>

<b>Attachments:</b> Attachments may fall off and require replacement. Also, they may discolor during treatment. Avoiding the use of dark beverages and foods like coffee and wine while the attachments are present reduces the likelihood of discoloration. Discoloration of this kind is easily removed at the end of treatment.<br/>

<br><br/>

<b>IPR: </b>The risks are generally small, but incidents have been reported. The most common side effect is that the side surface of the tooth did not achieve proper smoothness whereby a ledge in the enamel can result. This can be remedied. In patients with a tendency to dental decay, this tendency can be increased. Gums, lips, or tongue could receive a minor cut resulting in minor bleeding of the gums during the procedure. Usually this is not painful. These occurrences are of a minor nature and have no lasting effects. This is an elective procedure, but the only alternatives are to extract one or more teeth or expand the dental arch into an unstable position. The alternatives to IPR may not be available through the Uniform Dental Practice treatment offering.<br/>

<br/>

<b><i>By clicking ‘I Agree’ below, </i></b><b>I certify to have read and understood the possible risks to the procedures as described above.</b><br><br><br/>

<b><u>3. Consent to Treatment:</u></b><br/>

I have been given adequate time to read and have read the preceding information describing orthodontic treatment with clear aligners using IPR and/or Attachments as recommended by the dental health professional. I understand the benefits, risks and inconveniences associated with treatment. I have been sufficiently informed and have had the opportunity to ask questions and discuss concerns about orthodontic treatment with clear aligner products with the dental professional from whom I intend to receive treatment.<br/>

<br><br/>

Due to the fact that orthodontics is not an exact science, I acknowledge that neither my doctor nor the dental laboratory have made any guarantees or assurances concerning the outcome of my treatment. I understand that the dental laboratory is not a provider of medical, dental or health care services and does not and cannot practice medicine, dentistry or give medical advice. No assurances or guarantees of any kind have been made to me by my doctor or by the dental laboratory, its representatives, successors, assigns, and agents concerning any specific outcome of my treatment.<br/>

<br><br/>

<b><i>By clicking ‘I agree’ below, </i></b><b>I hereby consent to orthodontic treatment, including treatment via telehealth practices as further detailed in the previously signed Telehealth Consent with CVSTOM Co and Uniform Dental Practice, with clear aligner products that have been prescribed by my dental professional.</b><br/>

<br><br/>

<b><u>4. Use of Records Consent: </u></b><br/>

<br><br/>

I authorize my doctor to release my medical records, including, but not be limited to, radiographs (x-rays), reports, charts, medical history, photographs, findings, plaster models or impressions of teeth, prescriptions, diagnosis, medical testing, test results, billing, and other treatment records in my doctor’s possession (“Medical Records”) to other licensed dentists or orthodontists and organizations employing licensed dentists and orthodontists and to the dental laboratory, its representatives, employees, successors, assigns, and agents for the purposes of investigating and reviewing my medical history as it pertains to orthodontic treatment. I understand and give permission to my doctor to use my records for educational and research purposes.<br/>

<br><br/>

I understand that use of my Medical Records may result in disclosure of my “individually identifiable health information” as defined by the Health Insurance Portability and Accountability Act (“HIPAA”).<br/>

<br><br/>

<b><i>By clicking ‘I agree’ below I certify to have read, understand and hereby consent to the disclosures as set forth above. I will not, nor shall anyone on my behalf seek legal, equitable or monetary damages or remedies for such disclosure. I acknowledge that use of my Medical Records is without compensation and that I will not nor shall anyone on my behalf have any right of approval, claim of compensation, or seek or obtain legal, equitable or monetary damages or remedies arising out of any use such that comply with the terms of this Consent.</i></b><br/>

<br><br/>

<b><i>A photostatic copy of this Consent shall be considered as effective and valid as an original. I have read, understand and agree to the terms set forth in this Consent as indicated by my signature below.</i></b><br/>

</body></html>
`;
