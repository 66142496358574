import React, { useState } from "react";
import styled from "react-emotion";

import color from "src/styles/color";
import Modal from "src/shared/Modal";
import Button from "src/shared/Button";
import InputText from "src/shared/InputText";
import { url } from "src/shared/util";
import { getDecodedJWT, isAuthExpired } from "src/utils/auth";

const SaveProjectModal = ({
  onClose,
  wasmModule,
  patient,
  txpFileDetails,
  changeFileName,
  submitChangedButtonEnabled,
}) => {
  const [fileName, setFileName] = useState(
    wasmModule.webEmrIntegrationNewFileName(txpFileDetails.filename)
  );
  const [fileDescription, setFileDescription] = useState(
    wasmModule.webEmrIntegrationNewFileDescription(txpFileDetails.description)
  );
  const [progress, setProgress] = useState(0);
  const [progressDetails, setProgressDetails] = useState("");
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [unauthorizedError, setUnauthorizedError] = useState(false);
  const [errorState, setErrorState] = useState(false);

  return (
    <Modal
      ignoreOutOfBoundariesClick={true}
      style={{ width: "300px" }}
      onClose={onClose}
    >
      <Content>
        <div>File name</div>
        <InputText
          style={{
            width: "100%",
            margin: "5px 0 0 0",
          }}
          onChange={evt => setFileName(evt.target.value)}
          value={fileName}
          disabled={savingInProgress}
        />
        <div style={{ margin: "20px 0 0 0" }}>File description</div>
        <InputText
          style={{
            width: "100%",
            margin: "5px 0 0 0",
          }}
          onChange={evt => setFileDescription(evt.target.value)}
          value={fileDescription}
          disabled={savingInProgress}
        />
        <div style={{ margin: "20px 0 0 0" }}>Progress</div>
        <Progress>
          <Completed
            style={{
              width: `${progress}%`,
              background: errorState ? color.red : "#7cc993",
            }}
          />
        </Progress>
        {!errorState && (
          <ProgressDetails>
            {Math.round(progress)}% {progressDetails}
          </ProgressDetails>
        )}
        {errorState && <ErrorDetails>{progressDetails}</ErrorDetails>}
        <ButtonContainer>
          <Button disabled={savingInProgress} onClick={onClose}>
            Close
          </Button>
          <Button
            onClick={() => {
              window.open("/login", "_blank", "noopener,noreferrer");
            }}
            style={{
              display: unauthorizedError ? "flex" : "none",
            }}
          >
            Login
          </Button>
          <Button
            disabled={savingInProgress}
            type="primary"
            style={{
              margin: "0 0 0 20px",
              display:
                !submitChangedButtonEnabled || unauthorizedError
                  ? "none"
                  : "flex",
            }}
            onClick={() => {
              const authToken = getDecodedJWT();
              if (authToken !== null && isAuthExpired(authToken)) {
                setErrorState(true);
                setProgressDetails(
                  "Unauthorized. Press login to authorize in another tab, then try again"
                );
                setUnauthorizedError(true);
                return;
              }

              setUnauthorizedError(false);
              setErrorState(false);
              setSavingInProgress(true);
              setProgress(10);
              setProgressDetails("preparing a txp file");
              wasmModule.webSaveTxpProject(
                (errorDetails, __buffer, bufferSha256) => {
                  if (errorDetails.length > 0) {
                    setErrorState(true);
                    setProgressDetails(errorDetails);
                    setSavingInProgress(false);
                    return;
                  }
                  setProgress(50);
                  setProgressDetails("uploading to server");

                  const form = new FormData();
                  form.append("description", fileDescription);
                  form.append("internal", "true");
                  form.append("filetype", "TreatmentPlanZip");
                  form.append(
                    "content",
                    new Blob([new Uint8Array(__buffer)], {
                      type: " application/octet-stream",
                    }),
                    fileName.endsWith(".txp") ? fileName : fileName + ".txp"
                  );

                  const xhr = new XMLHttpRequest();
                  xhr.open(
                    "POST",
                    url(`/api/v2/patients/${patient.id}/files`),
                    true
                  );
                  const token = window.localStorage.getItem("auth_token");
                  xhr.setRequestHeader("Authorization", `Bearer ${token}`);
                  xhr.upload.onprogress = e => {
                    const percent = Math.round((e.loaded / e.total) * 48);
                    setProgress(50 + percent);
                    console.log("upload progress:", percent);
                  };
                  xhr.onreadystatechange = () => {
                    if (xhr.readyState === XMLHttpRequest.DONE) {
                      setSavingInProgress(false);
                      try {
                        if (xhr.status !== 201)
                          throw new Error(
                            "reply status:" +
                              xhr.status +
                              " " +
                              xhr.statusText +
                              " " +
                              xhr.response
                          );
                        const responseJson = JSON.parse(xhr.response);
                        if (
                          responseJson.sha256.toUpperCase() !==
                          bufferSha256.toUpperCase()
                        )
                          throw new Error(
                            "hash code does not match, plase try again"
                          );
                      } catch (e) {
                        if (xhr.status === 401) {
                          setErrorState(true);
                          setProgressDetails(
                            "Unauthorized. Press login to authorize in another tab, then try again"
                          );
                          setUnauthorizedError(true);
                        } else {
                          setErrorState(true);
                          setProgressDetails(e.toString());
                        }
                        return;
                      }
                      setProgress(100);
                      setProgressDetails("Upload complete");
                      changeFileName(fileName, fileDescription);
                      wasmModule.webSaveDoctorsAdjustments();
                    }
                  };
                  xhr.send(form);
                }
              );
            }}
          >
            Save
          </Button>
        </ButtonContainer>
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  width: 400px;
  margin: 20px;

  overflow-y: auto;
  display: flex;
  flex-flow: column;

  font-size: 14px;
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row;
  margin: 30px 0 0 0;
`;

const Progress = styled.div`
  display: flex;
  height: 12px;
  width: 100%;
  align-items: stretch;
  background: ${color.gray2};
  border-radius: 3px;
  overflow: hidden;
  margin: 5px 0 0 0;
`;

const ProgressDetails = styled.div`
  font-size: 12px;
  font-weight: normal;
  color: ${color.gray2};
  margin: 5px 0 0 0;
`;

const ErrorDetails = styled.div`
  font-size: 12px;
  font-weight: normal;
  color: ${color.red};
  margin: 5px 0 0 0;
`;

const Completed = styled.div`
  background: #7cc993;
`;

export default SaveProjectModal;
