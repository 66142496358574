import React, { useState } from "react";
import styled from "react-emotion";
import { notification } from "antd";

import { useQuery } from "src/utils/http/gqlQuery";
import Modal from "src/shared/Modal";
import color from "src/styles/color";
import Box from "src/shared/Box";
import MoneyInput from "src/shared/MoneyInput";
import Button, { ButtonRow } from "src/shared/Button";
import {
  useEditCareCreditPayment,
  orderGql,
  useDeleteCareCredit,
} from "../query";

const EditCareCreditPaymentModal = ({ onClose, charge, patientID }) => {
  const [amount, setAmount] = useState(charge.amount);
  const editPayment = useEditCareCreditPayment();
  const deletePayment = useDeleteCareCredit();

  const [, refetch] = useQuery({
    query: orderGql,
    variables: { userID: patientID },
    pause: true,
  });

  const onSubmit = async () => {
    const result = await editPayment({
      input: { chargeID: charge.id, amount },
    });

    if (result.error) {
      notification.error({
        message: result.error.message,
      });
      return;
    }
    notification.success({ message: "CareCredit payment updated" });

    // We now refetch all orders and clear the cache.
    refetch();
    onClose();
  };

  const onDelete = async () => {
    const result = await deletePayment({ chargeID: charge.id });
    if (result.error) {
      notification.error({
        message: result.error.message,
      });
      return;
    }
    notification.success({ message: "CareCredit payment deleted" });

    refetch();
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <Box title="Edit CareCredit Payment">
        <Content>
          <div style={{ flex: 1, padding: "16px 24px" }}>
            Edit amount
            <MoneyInput value={amount} onChange={setAmount} />
          </div>
          <Footer>
            <Button kind="warning" onClick={onDelete}>
              Delete Payment
            </Button>
            <ButtonRow position="right">
              <Button kind="default" onClick={onClose}>
                Cancel
              </Button>
              <Button
                kind="primary"
                onClick={onSubmit}
                disabled={!amount || amount === charge.amount}
              >
                Confirm
              </Button>
            </ButtonRow>
          </Footer>
        </Content>
      </Box>
    </Modal>
  );
};

const Content = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;

  height: 450px;
  width: 600px;
`;

const Footer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 16px;
  border-top: 1px solid ${color.border};
`;

export default EditCareCreditPaymentModal;
