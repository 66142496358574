import React, { useState, useRef } from "react";
import styled from "react-emotion";
import { InputNumber } from "antd";

import money from "src/utils/money";
import color from "src/styles/color";
import Check from "src/shared/Icons/Check";
import { Product } from "../query";
import { AddProduct } from "src/types/gql";

import Level1 from "./images/level1.svg";
import Level2 from "./images/level2.svg";
import Level3 from "./images/level3.svg";
import Level4 from "./images/level4.svg";
import RetainerOnly from "./images/retainer_only.png";
import AttachmentRemoval from "./images/attachment_removal.png";
import ReplacementAligners from "./images/replacement_aligners.png";
import ReplacementRetainers from "./images/replacement_retainers.png";
import Simulation from "./images/simulation.png";
import Logo from "./images/logo.svg";

type ListProps = {
  products: Product[] | undefined;
  selected: AddProduct[];
  onChange: (to: AddProduct[]) => void;
  allowQuantity?: boolean;
};

const ListWrapper = styled.div`
  border-top: 1px solid ${color.border};
`;

const sortProducts = (a: Product, b: Product) => {
  return a.name.localeCompare(b.name);
};

export const LineItemList: React.SFC<ListProps> = ({
  products,
  selected,
  onChange,
  allowQuantity,
}) => {
  const sortedProducts = [...(products || [])].sort(sortProducts);

  return (
    <ListWrapper>
      {sortedProducts.map(p => {
        return (
          <LineItem
            product={p}
            key={p.id}
            selected={selected}
            onChange={onChange}
            allowQuantity={allowQuantity}
          />
        );
      })}
    </ListWrapper>
  );
};

const FullWidthContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Wrapper = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 80px auto 80px;
  grid-gap: 20px;
  padding: 15px 10px;
  margin: 0 25px;

  img {
    border-radius: 4px;
    height: 80px;
  }

  border-bottom: 0.5px solid ${color.border};
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 3px 0;
  margin: 0;
  font-weight: bold;
`;

const Price = styled.div`
  margin: 0;
  display: inline;
  text-align: right;

  svg {
    display: inline;
    margin: 0 0 0 5px;
  }
`;

const Desc = styled.p`
  color: ${color.gray3};
  font-size: 14px;
`;

const getProductImage = productName => {
  switch (productName) {
    case "Level 1":
      return Level1;
    case "Level 2":
      return Level2;
    case "Level 3":
      return Level3;
    case "Level 4":
      return Level4;
    case "Replacement aligners":
      return ReplacementAligners;
    case "Replacement retainers":
      return ReplacementRetainers;
    case "Retainer only":
      return RetainerOnly;
    case "Simulation":
      return Simulation;
    case "Attachment removal":
      return AttachmentRemoval;
    default:
      return Logo;
  }
};

type LineItemProps = {
  product: Product;
  selected: AddProduct[];
  onChange: (to: AddProduct[]) => void;
  allowQuantity?: boolean;
};

const LineItem: React.SFC<LineItemProps> = ({
  product,
  onChange,
  selected,
  allowQuantity = false,
}) => {
  const quantityRef = useRef(null);
  const [showQuantity, setShowQuantity] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const isSelected = selected.some(s => s.id === product.id);
  const handleClick = e => {
    if (
      quantityRef &&
      quantityRef.current &&
      // @ts-ignore doesn't recognize null checks above
      quantityRef.current.contains(e.target)
    ) {
      return;
    }

    setShowQuantity(allowQuantity && !isSelected);

    if (isSelected) {
      onChange(selected.filter(s => s.id !== product.id));
    } else {
      onChange(selected.concat([{ id: product.id, quantity }]));
    }
  };

  const onQuantityChange = value => {
    setQuantity(value);

    const newSelected = selected
      .filter(s => s.id !== product.id)
      .concat([{ id: product.id, quantity: value }]);
    onChange(newSelected);
  };

  return (
    <FullWidthContainer
      onClick={handleClick}
      style={{ backgroundColor: isSelected ? color.background : "inherit" }}
    >
      <Wrapper>
        <img src={getProductImage(product.name)} alt="Aligners on table" />
        <Info>
          <Title>{product.name}</Title>
          <Desc>{product.description}</Desc>
          {isSelected && showQuantity && (
            <div ref={quantityRef}>
              <Title>Quantity</Title>
              <InputNumber
                min={1}
                value={quantity}
                onChange={onQuantityChange}
              />
            </div>
          )}
        </Info>
        <Price>
          {money(product.price)}
          {isSelected && <Check fill={color.green} />}
        </Price>
      </Wrapper>
    </FullWidthContainer>
  );
};

export default LineItem;
