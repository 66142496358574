import React from "react";
import { Task } from "../../types";
import Ship from "src/shared/TaskActions/Ship/Ship";

type Props = {
  task: Task;
};

const Content: React.FC<Props> = ({ task }) => {
  return <Ship patientID={task.user.id} />;
};

export default Content;
