import { Select as AntSelect } from "antd";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import React from "react";

import { OptionText, selectCss, optionCss, dropdownCss } from "./styles";
import { SelectProps, AllowedIds, GroupedOption, Option } from "./types";
import { defaultRenderOption } from "./utils";

export const Select = <
  Grouped extends boolean,
  Id extends AllowedIds,
  OptionPayload
>(
  props: SelectProps<Grouped, Id, OptionPayload>
) => {
  const {
    placeholder,
    options,
    value,
    onChange,
    renderOption = defaultRenderOption,
  } = props;

  const isGrouped = !isEmpty(options) && has(options[0], "group");

  return (
    <AntSelect
      showArrow
      mode="multiple"
      placeholder={placeholder}
      optionLabelProp="title"
      optionFilterProp="title"
      className={selectCss}
      dropdownClassName={dropdownCss}
      value={value}
      onChange={onChange}
    >
      {isGrouped &&
        (options as GroupedOption<Id, OptionPayload>[]).map(option => (
          <AntSelect.OptGroup key={option.group} label={option.group}>
            {option.options.map(innerOption => (
              <AntSelect.Option
                key={innerOption.id}
                value={innerOption.id}
                title={innerOption.value}
                className={optionCss}
              >
                <OptionText>{renderOption(innerOption)}</OptionText>
              </AntSelect.Option>
            ))}
          </AntSelect.OptGroup>
        ))}

      {!isGrouped &&
        (options as Option<Id, OptionPayload>[]).map(option => (
          <AntSelect.Option
            key={option.id}
            value={option.id}
            title={option.value}
            className={optionCss}
          >
            <OptionText>{renderOption(option)}</OptionText>
          </AntSelect.Option>
        ))}
    </AntSelect>
  );
};
