import React, { useState, useMemo } from "react";
import styled, { css } from "react-emotion";
import { browserHistory, Link } from "react-router";

import { useSelfRoles } from "src/state/self";
// styles
import color from "src/styles/color";
import textStyles from "src/styles/textStyles";
// shared
import EmptyFormIcon from "src/shared/Icons/EmptyForm";
import Button from "src/shared/Button";
import Dropdown from "src/shared/Dropdown";
import { DetailedPatient } from "src/types/api";
import { formatSubmissionLabel } from "src/shared/submissions";
// local
import Introduction from "./Introduction";
import DentalFindings from "./DentalFindings";
import ClinicalPhotos from "./ClinicalPhotos";
import Diagnosis from "./Diagnosis";
import TxGoalsAndOptions from "./TxGoalsAndOptions";
import Referrals from "./Referrals";
import Conclusion from "./Conclusion";
import Loading from "./Loading";
import { Popconfirm } from "antd";
import { performDeleteSubmission } from "../GQLForms/formActions";
import { FormDecorationsContext, getFormDecorations } from "../formDecorations";

// GQL migration
import useFormUI, {
  FormUI,
} from "src/scenes/PatientProfile/Forms/GQLForms/useFormUI";
import { Submission } from "src/scenes/PatientProfile/Forms/GQLForms/types";

export const Row = styled.div`
  height: 56px;
  padding: 0 24px 0 32px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #f9f9f9;
  border-bottom: 1px solid ${color.border};
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const SideNav = styled.div`
  width: 200px;
  min-width: 200px;
  background: ${color.white};
  position: sticky;
  top: 28px;
  height: 100%;
`;

const SideNavItem = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
  padding-left: 32px;
  border-left: 4px solid transparent;
  color: #a2a9ad;
  cursor: pointer;

  ${({ theme: { isActive } }) =>
    isActive &&
    css`
      border-left: 4px solid ${color.primary};
      color: ${color.font};
      font-weight: bold;
    `}
`;

const Content = styled.div`
  width: calc(100% - 200px);
  background: ${color.white};
  padding-top: 24px;
  border-left: 1px solid ${color.border};
`;

const Centered = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: 124px 0 70px;
`;

const LargeText = styled.div`
  ${textStyles("xlarge")};
  font-weight: bold;
  margin: 32px 0 8px;
`;

export const StyledLink = styled(Link)`
  position: relative;
  color: ${color.primary};
  text-decoration: underline;

  &:hover {
    color: ${color.primary};
    text-decoration: underline;
  }
`;

interface contentProps {
  ui: FormUI;
  patientID: string;
  patient: DetailedPatient; // required for introduction (signatures, modals)modals).
  next: () => void;
  disabled: boolean;
}

// BV form content controller stuff
const order: {
  label: string;
  form: string;
  component: React.FC<contentProps>;
}[] = [
  {
    label: "Introduction",
    form: "beginning_visit",
    // @ky - 2020/03/13
    // NOTE: typescript is getting way too complex with HOCs. Moving on for now.
    // @ts-ignore
    component: Introduction,
  },
  {
    label: "Clinical Photos",
    form: "beginning_visit",
    component: ClinicalPhotos as React.FC<contentProps>,
  },
  {
    label: "Dental Findings",
    form: "beginning_visit",
    component: DentalFindings,
  },
  {
    label: "Diagnosis",
    form: "beginning_visit",
    component: Diagnosis,
  },
  {
    label: "Tx Goals and Options",
    form: "treatment_option",
    component: TxGoalsAndOptions,
  },
  {
    label: "Referrals",
    form: "referral",
    component: Referrals,
  },
  {
    label: "Conclusion",
    form: "beginning_visit",
    component: Conclusion,
  },
];

const BeginningVisit = props => {
  const { isDoctor } = useSelfRoles();
  const ui = useFormUI({
    slug: "beginning_visit",
    userID: props.patientID,
    submissionID: props.submissionId,
    parentSubmissionID: null,
  });
  const submissions = ui ? ui.submissions : [];

  const submissionOptions = useMemo(() => {
    return submissions.map((item: Submission) => {
      return {
        value: item.id,
        label: formatSubmissionLabel(item),
      };
    });
  }, [submissions]);

  // Whether we're creating a new submission
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNewBVSubmission = async () => {
    if (!ui) {
      return;
    }
    setIsSubmitting(true);
    await ui.createSubmission(props.patientID);
    setIsSubmitting(false);
  };

  const [active, setActive] = useState(0);

  // render loading screen
  if (!ui || ui.fetching) {
    return <Loading />;
  }

  // render submission by id not found
  if (!ui.submission && submissions.length > 0) {
    return (
      <div style={{ background: "white" }}>
        <Row>
          <Link to={`/patients/${props.patientID}/forms/${ui.form.slug}`}>
            <Title>Beginning Visit Form</Title>
          </Link>
        </Row>
        <Centered>
          <EmptyFormIcon />
          <LargeText>Oops! We can't find that form.</LargeText>
          <LargeText style={{ marginTop: "0" }}>
            You can view the latest version{" "}
            <StyledLink
              to={"/patients/" + props.patientID + "/forms/" + ui.form.slug}
            >
              here
            </StyledLink>
            .
          </LargeText>
        </Centered>
      </div>
    );
  }

  // render empty submission screen
  if (submissions.length === 0) {
    return (
      <div style={{ background: "white" }}>
        <Row>
          <Title>Beginning Visit Form</Title>
        </Row>
        <Centered>
          <EmptyFormIcon />

          <LargeText>
            It looks like the patient doesn't have this form filled out.
          </LargeText>
          <Button
            kind="primary"
            style={{ width: "100px" }}
            onClick={handleNewBVSubmission}
            disabled={isSubmitting}
          >
            Start
          </Button>
        </Centered>
      </div>
    );
  }

  const { component: C } = order[active];

  const isSubmitted = !!ui.submission.submittedAt;
  const isApproved = !!ui.submission.approvedAt;
  const showApproveButton = isDoctor && isSubmitted && !isApproved;
  const showEditButton = isSubmitted && (!isApproved || isDoctor);

  const handleApprove = () => {
    ui.approveSubmission();
  };

  return (
    <FormDecorationsContext.Provider
      value={getFormDecorations("beginning_visit")}
    >
      <Row>
        <Title>Beginning Visit Form</Title>

        <div style={{ display: "flex" }}>
          {showEditButton && (
            <Popconfirm
              title="Edit submission?"
              okText="Yes"
              cancelText="No"
              placement="bottom"
              onConfirm={async () => {
                await ui.editSubmission(ui.submission.id);
              }}
            >
              <Button kind="secondaryInvert" style={{ marginRight: "8px" }}>
                Edit
              </Button>
            </Popconfirm>
          )}
          {ui.submission && !isSubmitted && (
            <Popconfirm
              title="Delete submission?"
              okText="Yes"
              cancelText="No"
              placement="bottom"
              onConfirm={() => {
                performDeleteSubmission(ui.submission.id, ui.deleteSubmission);
              }}
            >
              <Button kind="secondaryInvert" style={{ marginRight: "8px" }}>
                Delete
              </Button>
            </Popconfirm>
          )}
          <Button
            kind="secondaryInvert"
            style={{ marginRight: "8px" }}
            onClick={handleNewBVSubmission}
            disabled={isSubmitting}
          >
            New
          </Button>
          <Dropdown
            disabled={isSubmitting}
            options={submissionOptions}
            onSelect={({ value }) => {
              browserHistory.push(
                `/patients/${props.patientID}/forms/${ui.form.slug}/${value}`
              );
            }}
            selected={ui.submission.id}
            width="auto"
          />

          {showApproveButton && (
            <Button
              kind="primary"
              style={{ marginLeft: "8px" }}
              onClick={() => handleApprove()}
            >
              Approve
            </Button>
          )}
        </div>
      </Row>
      <div style={{ display: "flex" }}>
        <SideNav>
          {order.map(({ label }, idx) => (
            <SideNavItem
              key={label}
              theme={{ isActive: idx === active }}
              onClick={() => setActive(idx)}
            >
              {label}
            </SideNavItem>
          ))}
        </SideNav>
        <Content>
          <C
            ui={ui}
            patientID={props.patientID}
            patient={props.patient}
            next={() => setActive(active + 1)}
            disabled={isSubmitted}
          />
        </Content>
      </div>
    </FormDecorationsContext.Provider>
  );
};

export default BeginningVisit;
