import React, { useState } from "react";
import moment from "moment";
import { Modal, Form, DatePicker } from "antd";
import { fromPairs } from "lodash";
import { useUpdatePatient, UpdateUserType } from "src/state/usePatientInfo";
import { Patient } from "src/types/gql";
import color from "src/styles/color";
import Input from "src/shared/InputText";
import Button from "src/shared/Button";
import Dropdown from "src/shared/Dropdown";
import { useClinics } from "./queries";
import { ButtonStyle } from "./styles";

type AsyncState = {
  state: "none" | "loading" | "error";
  error?: string | undefined;
};

interface Props {
  patient: Patient;
  visible: boolean;
  setVisible(visible: boolean): void;
}

const fields = [
  "mobilePhoneNumber",
  "firstName",
  "lastName",
  "preferredName",
  "pronouns",
  "email",
  "dateOfBirth",
  "homeClinicId",
];

const getChangedFields = (patient, updatedPatient) => {
  const changedFields = fields.filter(field => {
    if (field === "homeClinicId")
      return patient.homeClinicID !== updatedPatient.homeClinicId;

    return patient[field] !== updatedPatient[field];
  });
  return fromPairs(changedFields.map(field => [field, updatedPatient[field]]));
};

const PatientEdit = (props: Props) => {
  const { patient, setVisible, visible } = props;

  // Get the list of clinics
  const [, , clinics] = useClinics();
  // Mutations
  const updatePatient = useUpdatePatient();

  const [status, setStatus] = useState<AsyncState>({ state: "none" });
  const [updatedPatient, setUpdatedPatient] = useState<UpdateUserType>({
    patientId: patient.id,
    mobilePhoneNumber: patient.mobilePhoneNumber,
    firstName: patient.firstName,
    lastName: patient.lastName,
    preferredName: patient.preferredName,
    pronouns: patient.pronouns,
    email: patient.email,
    dateOfBirth: patient.dateOfBirth,
    homeClinicId: patient.homeClinicID,
  });

  const onOk = async () => {
    if (status.state === "loading") {
      return;
    }
    setStatus({ state: "loading" });
    const result = await updatePatient({
      updateRequest: {
        patientId: patient.id,
        ...getChangedFields(patient, updatedPatient),
      },
    });
    if (result.error) {
      setStatus({
        state: "error",
        error: result.error && result.error.message,
      });
      return;
    }
    setStatus({ state: "none", error: undefined });
    setVisible(false);
  };

  let clinicOptions = clinics.map((c: any) => ({
    label: c.nickname || c.name,
    value: c.id,
  }));

  return (
    <Modal
      visible={visible}
      destroyOnClose={true}
      onCancel={() => setVisible(false)}
      footer={[
        <Button
          className={ButtonStyle}
          key="back"
          onClick={() => setVisible(false)}
        >
          Cancel
        </Button>,
        <Button
          className={ButtonStyle}
          key="submit"
          type="primary"
          loading={status.state === "loading"}
          onClick={onOk}
        >
          Update
        </Button>,
      ]}
    >
      <Form>
        <Form.Item label="First Name">
          <Input
            defaultValue={updatedPatient.firstName}
            onChange={e =>
              setUpdatedPatient({
                ...updatedPatient,
                firstName: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Last Name">
          <Input
            defaultValue={updatedPatient.lastName}
            onChange={e =>
              setUpdatedPatient({
                ...updatedPatient,
                lastName: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Preferred Name">
          <Input
            defaultValue={updatedPatient.preferredName}
            onChange={e =>
              setUpdatedPatient({
                ...updatedPatient,
                preferredName: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Pronouns">
          <Input
            defaultValue={updatedPatient.pronouns}
            onChange={e =>
              setUpdatedPatient({
                ...updatedPatient,
                pronouns: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Email">
          <Input
            defaultValue={updatedPatient.email}
            onChange={e =>
              setUpdatedPatient({
                ...updatedPatient,
                email: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Mobile Phone Number">
          <Input
            defaultValue={updatedPatient.mobilePhoneNumber}
            onChange={e =>
              setUpdatedPatient({
                ...updatedPatient,
                mobilePhoneNumber: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Date of Birth">
          <DatePicker
            defaultValue={moment(updatedPatient.dateOfBirth)}
            onChange={d =>
              d &&
              setUpdatedPatient({
                ...updatedPatient,
                dateOfBirth: d.format("YYYY-MM-DD"),
              })
            }
          />
        </Form.Item>
        <Form.Item label="Clinic">
          <Dropdown
            options={clinicOptions}
            selected={clinicOptions.find(
              e => e.value === updatedPatient.homeClinicId
            )}
            onSelect={d => {
              d &&
                setUpdatedPatient({
                  ...updatedPatient,
                  homeClinicId: d.value,
                });
            }}
          />
        </Form.Item>

        {status.error && (
          <p style={{ color: color.red, textAlign: "right" }}>{status.error}</p>
        )}
      </Form>
    </Modal>
  );
};

export default PatientEdit;
