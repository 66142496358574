import React, { useState } from "react";
import { Tag, Menu, Dropdown, message } from "antd";
import { DateTime } from "luxon";
import styled, { css } from "react-emotion";
import { Link } from "react-router";

import { useQuery, useMutation } from "src/utils/http/gqlQuery";
import Table, { Header, Body } from "src/shared/Table";
import Button from "src/shared/Button";
import LegacyModal from "src/shared/LegacyModal";
import DeleteConfirmModal from "src/shared/DeleteConfirmModal";
import Settings from "src/shared/Icons/Settings";
import FiletypeFilter from "src/shared/FiletypeFilter";
import { filetypes } from "src/shared/file";
import FileIcon from "src/shared/Icons/Files/FileIcon";
import { url } from "src/shared/util";
import { File } from "src/types/gql";

import color from "src/styles/color";
import ModalLarge from "src/shared/ModalLarge";
import QuickLinks from "./QuickLinks";

const width = 750;

interface Props {
  params: {
    userId: string;
  };
}

const gql = `
query GetPatientFiles($userID: ID!) {
  files(userId: $userID) {
    id filename type description size internal createdAt
  }
}
`;

const getFileLink = (file: File) => {
  if (file.type === "TreatmentPlanZip") {
    // open treatment planning in new window
    return (
      <a rel="noopener noreferrer" target="_blank" href={`/txp/${file.id}`}>
        <FileIcon filename={file.filename} />
        {file.filename}
      </a>
    );
  }

  return (
    <a href={url(`/api/v2/files/${file.id}`)}>
      <FileIcon filename={file.filename} />
      {file.filename}
    </a>
  );
};

export default function Files(props: Props) {
  const [showDelete, setShowDelete] = useState<string | null>(null); // ID of file we're deleting
  const [showUpload, setShowUpload] = useState(false);
  const [filters, setFilters] = useState<string[]>([]);
  const [{ data }, refetch] = useQuery({
    query: gql,
    variables: { userID: props.params.userId },
  });
  const execDelete = useDelete();

  const filetypeFilters = filters.filter(f => !!filetypes[f]);
  const customFilters = filters.filter(f => !filetypes[f]);

  const filtered = (data || { files: [] }).files
    .filter(f => {
      if (filters.length === 0) {
        return true;
      }

      // If this is a filetype filter, add this as an "OR".  We can filter by
      // many filetypes at once.
      if (
        filetypeFilters.length > 0 &&
        !filetypeFilters.some(search => f.type === search)
      ) {
        return false;
      }

      // Cusom filters - non filetypes - must match every case.
      if (
        customFilters.length > 0 &&
        !customFilters.every(search =>
          f.filename.toLowerCase().includes(search)
        )
      ) {
        return false;
      }

      return true;
    })
    .sort((a, b) => b.createdAt.localeCompare(a.createdAt));

  const linkToBox = `/patients/${props.params.userId}/files/box`;

  return (
    <Wrapper onDragEnter={() => setShowUpload(true)}>
      <LegacyModal
        className={hideClose}
        isOpen={showUpload}
        closeModal={() => setShowUpload(false)}
        width={width + 48}
      >
        <LegacyModal.Content>
          <ModalLarge
            userID={props.params.userId}
            onStartUpload={(_, invalid) =>
              invalid.length === 0 && setShowUpload(false)
            }
          />
        </LegacyModal.Content>
      </LegacyModal>

      {showDelete && (
        <DeleteConfirmModal
          text="Are you sure you want to delete this file?"
          actionText="Delete"
          onConfirm={async () => {
            const hide = message.loading("Deleting file");
            const result = await execDelete({ id: showDelete || "" });
            hide();

            if (result.error) {
              message.error("Error deleting file");
              return;
            }

            message.success("File deleted");
            refetch();
            setShowDelete(null);
          }}
          onClose={() => setShowDelete(null)}
        />
      )}

      <Top>
        <Link to={linkToBox}>
          <Button>Box folder</Button>
        </Link>
        <TopLeft>
          <Tags>
            {filters.map(f => (
              <Tag
                key={f}
                closable
                onClose={() => setFilters(filters.filter(a => a !== f))}
              >
                {f}
              </Tag>
            ))}
          </Tags>
        </TopLeft>
        <TopRight>
          <FiletypeFilter
            uncontrolled
            allowOthers
            placeholder="Search by name or type"
            onChange={to =>
              setFilters(Array.from(new Set(filters.concat([to.trim()]))))
            }
          />
          <QuickLinks files={filtered} />
          <Button type="primary" onClick={() => setShowUpload(true)}>
            Upload
          </Button>
        </TopRight>
      </Top>

      <Table items={filtered}>
        <Header>
          <Header.Item className={name}>Name</Header.Item>
          <Header.Item className={other}>Type</Header.Item>
          <Header.Item className={other}>Uploaded</Header.Item>
          <Header.Item className={other}>Internal</Header.Item>
          <Header.Item className={name}>Description</Header.Item>
          <Header.Item className={actions} />
        </Header>
        <Body>
          {item => (
            <Body.Row key={item.id}>
              <Body.Item className={name}>{getFileLink(item)}</Body.Item>
              <Body.Item className={other}>{filetypes[item.type]}</Body.Item>
              <Body.Item className={other}>
                {DateTime.fromISO(item.createdAt).toLocaleString()}
              </Body.Item>
              <Body.Item className={other}>
                {item.internal ? <Internal>Yes</Internal> : "No"}
              </Body.Item>
              <Body.Item className={name}>{item.description || ""}</Body.Item>
              <Body.Item className={actions}>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <a href={url(`/api/v2/files/${item.id}`)}>Download</a>
                      </Menu.Item>
                      <Menu.Item>
                        <Button
                          kind="invisible"
                          onClick={() => setShowDelete(item.id)}
                        >
                          Delete
                        </Button>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <div
                    className="ant-dropdown-link"
                    style={{ cursor: "pointer" }}
                  >
                    <Settings />
                  </div>
                </Dropdown>
              </Body.Item>
            </Body.Row>
          )}
        </Body>
      </Table>
    </Wrapper>
  );
}

export const useDelete = () => {
  const [, execute] = useMutation(`
    mutation DeleteFile($id: ID!) {
      deleteFile(id: $id) {
        ids
      }
    }
  `);
  return execute;
};

const hideClose = css`
  .ant-modal-close {
    display: none;
  }
`;

const Wrapper = styled.div`
  border: 1px solid ${color.border};
  background: ${color.white};
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const TopLeft = styled.div`
  display: flex;
  flex: 1;
`;

const TopRight = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  button {
    margin: 0 0 0 10px;
    padding: 8px 24px;
  }
`;

const Tags = styled.div``;

const name = css`
  width: 35%;

  > a {
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 12px;
  }
`;

const other = css`
  width: 10%;
`;

const actions = css``;

const Internal = styled.span`
  opacity: 0.5;
`;
