import React from "react";
import { DateTime } from "luxon";
import Calendar, { Columns, ColumnsItem, Slots } from "src/shared/Calendar";
import { toWeekday } from "src/shared/time";
// local
import useCalendarViewContext from "./useCalendarViewContext";
import BaseSlots from "./Slots/Slots";

const getDays = (date: DateTime, numberOfDays = 5) => {
  const startOfWeek = date.startOf("week");
  const days: Array<{
    day: string;
    date: string;
    label: string;
    iso: string;
  }> = [];
  for (let i = 0; i < numberOfDays; i++) {
    const day = startOfWeek.plus({ days: i });
    const iso = day.toUTC().toISODate();
    days.push({
      day: day.weekdayLong,
      date: day.day.toString(),
      label: `${day.weekdayLong}, ${day.monthLong} ${day.day}`,
      iso,
    });
  }
  return days;
};

const getWeekday = date => toWeekday(date);

type Props = {
  calendarView: string | undefined;
};

const WeekView = (props: Props) => {
  const {
    date,
    appointments,
    selectedDoctorId,
    clinic,
  } = useCalendarViewContext();

  const days = getDays(date, 6);
  const schedule = days.map(({ day }) => {
    const matchingAppointments = appointments
      .filter(({ doctorStartTime }) => getWeekday(doctorStartTime) === day)
      .filter(
        ({ doctorId }) =>
          selectedDoctorId === "all" || doctorId === selectedDoctorId
      )
      .filter(appt => appt.clinic && appt.clinic.id === clinic.id);

    return {
      unsaved: [],
      appointments: matchingAppointments,
    };
  });

  const dates = days.map(({ iso }) => iso);

  return (
    <Calendar
      columns={days}
      schedule={schedule}
      dates={dates}
      timezone={clinic.timezone}
    >
      <Columns>
        {({ label }, n) => <ColumnsItem key={n}>{label}</ColumnsItem>}
      </Columns>
      <Slots>
        {({ appointments }, idx) => (
          <BaseSlots
            key={idx}
            unsaved={[]}
            appointments={appointments}
            calendarView={props.calendarView}
          />
        )}
      </Slots>
    </Calendar>
  );
};

export default WeekView;
