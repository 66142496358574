import { last, map } from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  post,
  preview,
  cancelPreview,
  save,
  // consts
  editDates,
  editCadence,
  // selectors
  getPreviewWearSchedule,
  getShowingPreviewForTCId,
  getModifiedStage,
  getEditType,
  getCadence,
  EditType,
  PreviewArgs,
  SaveArgs,
  loadTreatmentCycles,
} from "src/redux/modules/wear-schedule";
import {
  RaisedButton,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from "material-ui";
import WearScheduleRow from "./WearScheduleRow";
import PreviewBar from "./PreviewBar";
import SingleEditAlert from "./SingleEditAlert";
import CadenceAlert from "./CadenceAlert";
import styles from "./wear-schedule-style";
// types
import type { Stage } from "types";
import moment from "moment";
import { momentFormatDate } from "src/config/constant";
import EndOnStage from "./end-on-stage";

type Props = {
  // the original wear schedule
  treatmentCycle: {|
    wear_schedule: Array<Stage>,
    created_at: string,
  |},
  // the wear schedule with unsaved edits
  previewSchedule: Array<Stage>,
  // the stage modified to generate the preview
  modifiedStage: Stage,
  // the edit type to generate the preview
  editType: EditType,
  // the cadence used to generate the preview, if editing cadences
  cadence: number,
  actions: {|
    preview: (args: PreviewArgs) => void,
    save: (args: SaveArgs) => void,
    cancelPreview: () => void,
    post: (args: Stage) => void,
  |},
  ui: {|
    isShowingPreview: string,
  |},
};
const mapState = (state: Object, ownProps: Object) => ({
  // see Props for more info
  previewSchedule: getPreviewWearSchedule(state),
  modifiedStage: getModifiedStage(state),
  editType: getEditType(state),
  cadence: getCadence(state),
  ui: {
    isShowingPreview:
      getShowingPreviewForTCId(state) === ownProps.treatmentCycle.id,
  },
});

const mapActions = dispatch => ({
  actions: bindActionCreators(
    {
      preview,
      cancelPreview,
      save,
      loadTreatmentCycles,
      post,
    },
    dispatch
  ),
});

/**
 * WearSchedule is the contianer component for showing the wear schedule.  It
 * handles:
 *
 * - Rendering the currently saved wear schedule
 * - Fetching and previewing updated schedules from modified stage dates
 * - Fetching and previewing updated schedules from modified cadences
 * - Saving modified stage dates
 * - Saving modified cadences
 * - Showing a warning when more than one edit is attempted without saving
 *
 */
const enhance = connect(
  mapState,
  mapActions
);

class WearSchedule extends Component {
  static contextTypes = {
    patient: PropTypes.object,
  };
  props: Props;
  state: {|
    // isEditAlertOpen stores whether the SingleEditAlert dialog is visible
    isEditAlertOpen: boolean,
    // isCadenceAlertOpen stores whether the SingleEditAlert dialog is visible
    isCadenceAlertOpen: boolean,
    currentCadence: number,
    cadenceStage: Stage,
  |};

  constructor() {
    super();
    this.state = {
      isEndOnStageOpen: false,
      isEditAlertOpen: false,
      isCadenceAlertOpen: false,
      currentCadence: 7,
      cadenceStage: {},
    };
  }

  /**
   * onUpdateStage is called when any stage's start or end dates are modified
   * to create a preview of the schedule.
   *
   * This is passed to each WearScheduleRow.
   */
  onUpdateStage = (stage: Stage) => {
    const { modifiedStage, ui } = this.props;
    // If we're previewing a modification to a stage that hasn't yet been saved -
    // and the previous edit was for a different stage - show an alert saying
    // that modifications can only be made one at a time (due to the cascading
    // nature of the edits).
    //
    // Multiple edits can be enabled once we make the algorithm for
    // extrapolating dates smarter on the API.
    if (ui.isShowingPreview && modifiedStage.id !== stage.id) {
      this.setState({
        isEditAlertOpen: true,
        isCadenceAlertOpen: false,
      });
      return;
    }

    this.props.actions.preview({
      modifiedStage: stage,
      editType: editDates,
      treatmentCycleId: this.props.treatmentCycle.id,
    });
  };

  onPreviewCadence = (cadence: number) => {
    this.props.actions.preview({
      modifiedStage: this.state.cadenceStage,
      editType: editCadence,
      cadence,
      treatmentCycleId: this.props.treatmentCycle.id,
    });
    this.onHideAlert();
  };

  /**
   * onReset calls cancelPreview to remove all edits and reset the preview
   * state.
   *
   * The preview bar provides the UI for saving and resetting states, so this
   * is passed down to the PreviewBar.
   */
  onReset = () => {
    this.props.actions.cancelPreview();
    this.onHideAlert();
  };

  /**
   * onSave is the primary handler passed down to the PreviewBar to handle
   * saving edits of the wear schedule.
   *
   * This uses the stored editType, cadence, and modifiedStage from previewing
   * as arguments to save the data.
   */
  onSave = () => {
    this.props.actions
      .save({
        modifiedStage: this.props.modifiedStage,
        editType: this.props.editType,
        cadence: this.props.cadence,
      })
      .then(() => {
        this.props.actions.loadTreatmentCycles(this.context.patient.id);
      });
    this.onHideAlert();
  };

  /**
   * onShowCadenceAlert returns a closure with a reference to a stage used
   * to update the cadence, as this is needed when submitting from the alert.
   */
  onShowCadenceAlert = (stage: Stage) => (currentCadence: number) => {
    this.setState({
      isEditAlertOpen: false,
      isCadenceAlertOpen: true,

      currentCadence,
      cadenceStage: stage,
    });
  };

  onHideAlert = () => {
    this.setState({
      isEndOnStageOpen: false,
      isEditAlertOpen: false,
      isCadenceAlertOpen: false,
    });
  };
  openEndOnStage = () => {
    this.setState({ isEndOnStageOpen: true });
  };
  handleAddStage = () => {
    this.setState({ isAddingStage: true });
    const lastStageNum = last(this.props.treatmentCycle.wear_schedule).stage;
    const stage = {
      user_id: this.context.patient.id,
      treatment_cycle_id: this.props.treatmentCycle.id,
      stage: lastStageNum + 1,
      expected_start_date: new Date().toISOString().split("T")[0],
      expected_end_date: new Date().toISOString().split("T")[0],
    };
    this.props.actions.post(stage).then(() => {
      this.props.actions
        .loadTreatmentCycles(this.context.patient.id)
        .then(() => {
          this.setState({ isAddingStage: false });
        });
    });
  };
  render() {
    const { ui, treatmentCycle, modifiedStage } = this.props;
    // If we're rendering the preview of user-submitted updates iterate through
    // the `preview` prop. Otherwise, use the current wear schedule from
    // `this.props.wearSchedule` (which is what's currently saved in the DB).
    const wearSchedule = treatmentCycle.wear_schedule;
    const schedule = ui.isShowingPreview
      ? this.props.previewSchedule
      : wearSchedule;
    const isActive = treatmentCycle.is_active;
    return (
      <div>
        <SingleEditAlert
          isOpen={this.state.isEditAlertOpen}
          onSave={this.onSave}
          onReset={this.onReset}
          onClose={this.onHideAlert}
        />
        <CadenceAlert
          isOpen={this.state.isCadenceAlertOpen}
          onPreview={this.onPreviewCadence}
          onClose={this.onHideAlert}
          currentCadence={this.state.currentCadence}
          stage={this.state.cadenceStage}
        />
        <EndOnStage
          isOpen={this.state.isEndOnStageOpen}
          onClose={this.onHideAlert}
          treatmentCycleId={treatmentCycle.id}
        />
        <div style={styles.container}>
          <div style={styles.getTCHead(isActive)}>
            Treatment Cycle{" "}
            {treatmentCycle.refinement_prefix &&
              `${treatmentCycle.refinement_prefix} - `}{" "}
            created at:{" "}
            {moment(treatmentCycle.created_at).format(momentFormatDate)}
            {/*<RaisedButton*/}
            {/*style={styles.S.floatRight}*/}
            {/*onClick={this.openEndOnStage}*/}
            {/*label="End Early"*/}
            {/*/>*/}
            <RaisedButton
              disabled={this.state.isAddingStage}
              style={styles.S.floatRight}
              onClick={this.handleAddStage}
              label="Add Stage"
            />
          </div>
          <hr />
          <div>
            <PreviewBar
              modifiedStage={modifiedStage}
              isShowingPreview={ui.isShowingPreview}
              onSave={this.onSave}
              onReset={this.onReset}
            />
          </div>

          <div>
            <Table fixedHeader selectable={false}>
              <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                <TableRow>
                  <TableHeaderColumn style={{ width: 80 }}>
                    Stage
                  </TableHeaderColumn>
                  <TableHeaderColumn>Expected Start</TableHeaderColumn>
                  <TableHeaderColumn>Expected End</TableHeaderColumn>
                  <TableHeaderColumn>Cadence</TableHeaderColumn>
                  {/*<TableHeaderColumn>Doctor Note</TableHeaderColumn>*/}
                  {/*<TableHeaderColumn>Patient Note</TableHeaderColumn>*/}
                  <TableHeaderColumn>Skipped</TableHeaderColumn>
                </TableRow>
              </TableHeader>

              <TableBody displayRowCheckbox={false} showRowHover>
                {map(schedule, (stage, idx) => (
                  <WearScheduleRow
                    refinementPrefix={treatmentCycle.refinement_prefix}
                    displayStage={stage}
                    actualStage={wearSchedule[idx]}
                    modifiedStage={modifiedStage}
                    isShowingPreview={ui.isShowingPreview}
                    onUpdateStage={this.onUpdateStage}
                    onShowCadenceAlert={this.onShowCadenceAlert(stage)}
                    key={stage.id}
                  />
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

export default enhance(WearSchedule);
