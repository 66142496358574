import React from "react";
import styled from "react-emotion";
import color from "src/styles/color";
import { Tab, TabLink, Line } from "src/shared/Tabs";
import useSelf from "src/state/self";
import Loading from "src/shared/Loading";
import Staff from "./Staff";
import Promos from "./Promos";
import AuditLogs from "./AuditLogs";
import PromoUsers from "./PromoUsers";
import VerifiedSpecialists from "./VerifiedSpecialists";
import { useSchedulingRestrictions } from "src/state/self";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: ${color.background};
  min-height: 100%;
`;

const TabWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 40px;
  border-top: 1px solid ${color.border};
`;

const Title = styled.h6`
  color: ${color.primary};
`;

const SelectedTab = styled.div`
  display: flex;
`;

const getSelectedKey = (pathname: string) => {
  if (!pathname) {
    return "staff-members";
  }
  const parts = pathname.split("/").filter(path => path !== "");
  if (parts.length === 2) {
    return parts[parts.length - 1];
  }
  return "staff-members";
};

const Overview = props => {
  const { isAdmin } = useSchedulingRestrictions();

  const selectedKey = getSelectedKey(props.location.pathname);
  const self = useSelf();
  if (self.organizations.length < 1) {
    return <Loading />;
  }

  const organizationID = self.organizations[0].id;
  return (
    <Wrapper>
      <Title>EMR Admin</Title>
      <TabWrapper>
        <Tab theme={{ active: selectedKey === "staff-members" }}>
          <TabLink to={"/emr-admin/staff-members"}>Staff members</TabLink>
        </Tab>
        <Line />
        <Tab theme={{ active: selectedKey === "promo-codes" }}>
          <TabLink to={"/emr-admin/promo-codes"}>Promo codes</TabLink>
        </Tab>
        <Line />
        <Tab theme={{ active: selectedKey === "free-treatment-users" }}>
          <TabLink to={"/emr-admin/free-treatment-users"}>
            Free treatment users
          </TabLink>
        </Tab>
        <Line />
        <Tab theme={{ active: selectedKey === "verified-specialists" }}>
          <TabLink to={"/emr-admin/verified-specialists"}>
            Verified providers
          </TabLink>
        </Tab>
        {isAdmin ? <Line /> : null}
        {isAdmin ? (
          <Tab theme={{ active: selectedKey === "audit-logs" }}>
            <TabLink to={"/emr-admin/audit-logs"}>Audit Logs</TabLink>
          </Tab>
        ) : null}
      </TabWrapper>

      <SelectedTab>
        {selectedKey === "staff-members" && (
          <Staff organizationID={organizationID} />
        )}
        {selectedKey === "promo-codes" && <Promos />}
        {selectedKey === "free-treatment-users" && <PromoUsers />}
        {selectedKey === "verified-specialists" && <VerifiedSpecialists />}
        {selectedKey === "audit-logs" && <AuditLogs />}
      </SelectedTab>
    </Wrapper>
  );
};

export default Overview;
