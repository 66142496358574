import React, { useState } from "react";
import { DateTime } from "luxon";
import styled from "react-emotion";
import { message, notification } from "antd";
// shared
import color from "src/styles/color";
import Button, { ButtonRow } from "src/shared/Button";
import SelectList from "src/shared/SelectList";
import { BaseUser } from "src/shared/PatientSearch/PatientSearch";
import Popover from "src/shared/Popover";
import DatePopover from "src/shared/Popovers/Date";
import InputText from "src/shared/InputText";
import { titleCase } from "src/shared/util";
// local
import Label from "../Shared/Label";
import AssignPatientIcon from "../Icons/AssignPatient";
import PatientSearchPopover from "src/shared/Popovers/PatientSearch";
import { useNewHold } from "../useHolds";
import { holdCategories } from "../holds";
import InvisibleButton from "../NewPipeline/InvisibleButton";
import DueDateIcon from "src/scenes/Tasks/Icons/DueDate";

type Props = {
  onCancel: () => void;
  onComplete: () => void;

  // user represents the pre-filled user, if applicable.
  user?: BaseUser;
};

const NewHold: React.FC<Props> = ({ onCancel, onComplete, user }) => {
  const createHold = useNewHold();

  const [description, setDescription] = useState<string | undefined>();
  const [patient, setPatient] = useState<BaseUser | undefined>(user);
  const [holdCategory, setHoldCategory] = useState<string | undefined>();
  const [recallDate, setRecallDate] = useState<string | undefined>();

  const valid = !!patient && !!holdCategory;

  const onSave = async () => {
    if (!valid || !patient || !holdCategory) {
      return;
    }

    const hide = message.loading("Adding Pre-tx task pipeline...", 0);
    const result = await createHold({
      input: {
        userID: patient.id,
        category: holdCategory,
        description,
        dueAt: recallDate,
      },
    });
    hide();

    if (result.error) {
      notification.error({
        message: `Error creating Pre-tx task pipeline: ${result.error}`,
      });
      return;
    }

    notification.success({
      message: `Pre-tx task added. Aligner production is paused.`,
    });
    onComplete();
  };

  return (
    <>
      <Wrapper>
        <div>
          <Popover
            content={
              <SelectList<string>
                onClick={item => {
                  setHoldCategory(item);
                }}
                values={holdCategories.filter(
                  category => category !== "treatment_terminated"
                )}
                render={category => (
                  <span key={category}>{titleCase(category)}</span>
                )}
              />
            }
          >
            <h1>
              {!holdCategory && <span>Choose Pre-tx task</span>}
              {holdCategory && titleCase(holdCategory)}
            </h1>
          </Popover>

          <Label style={{ marginTop: "2rem" }}>
            <span>Patient</span>
            <PatientSearchPopover value={patient} onClick={setPatient}>
              <Button kind="invisible">
                <AssignPatientIcon />
                {patient ? (
                  <b>
                    {patient.firstName}{" "}
                    {patient.preferredName
                      ? `(${patient.preferredName})`
                      : null}{" "}
                    {patient.lastName}{" "}
                    {patient.pronouns ? `(${patient.pronouns})` : null}
                  </b>
                ) : (
                  "Assign patient"
                )}
              </Button>
            </PatientSearchPopover>
          </Label>

          {holdCategory === "observation" && (
            <Label style={{ marginTop: "2rem" }}>
              <span>Recall date</span>
              <DatePopover onClick={setRecallDate} value={recallDate}>
                <InvisibleButton>
                  <DueDateIcon />
                  {recallDate ? (
                    <span>{DateTime.fromISO(recallDate).toLocaleString()}</span>
                  ) : (
                    <>Set recall date</>
                  )}
                </InvisibleButton>
              </DatePopover>
            </Label>
          )}

          {holdCategory === "other" && (
            <Label style={{ marginTop: "2rem" }}>
              <span>Details</span>
              <InputText
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Label>
          )}
        </div>

        <div>
          <ButtonRow position="right">
            <Button onClick={onCancel}>Cancel</Button>
            <Button kind="primary" disabled={!valid} onClick={onSave}>
              Create
            </Button>
          </ButtonRow>
        </div>
      </Wrapper>
    </>
  );
};

export default NewHold;

const Wrapper = styled.div`
  > div {
    padding: 24px 32px;
  }

  > div + div {
    border-top: 1px solid ${color.border};
  }

  h1 span {
    opacity: 0.6;
  }

  width: 600px;
`;
