import React from "react";
import { diffInDays } from "src/shared/time";
import { pluralize } from "src/shared/util";
import color from "src/styles/color";

export const getOnScheduleText = (endTime, plannedEndTime) => {
  if (!endTime) {
    return <span>--</span>;
  }
  const daysBetween = Math.round(diffInDays(plannedEndTime, endTime));
  if (daysBetween === 0) {
    return <span>Yes</span>;
  }
  if (daysBetween < 0) {
    return (
      <span>
        {-1 * daysBetween} {pluralize("day", daysBetween)} over
      </span>
    );
  }
  return (
    <span style={{ color: color.red }}>
      {daysBetween} {pluralize("day", daysBetween)} under
    </span>
  );
};

export const isPatientOnImpressTM = treatment => {
  const impressChangeOverDate = new Date("2022-10-14");
  const afterChangeOver = new Date() > impressChangeOverDate;

  if (afterChangeOver && !treatment) {
    return true;
  } else if (
    afterChangeOver &&
    treatment &&
    treatment.treatmentCycles.length &&
    treatment.treatmentCycles[0].impressTreatmentPlanID
  ) {
    return true;
  }
  return false;
};
