import React from "react";
import color from "src/styles/color";

type Props = {
  fill?: string;
};

const Location = ({ fill = color.gray3 }) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 0C1.79439 0 0 1.62465 0 3.62161C0 6.09989 3.57961 9.73816 3.73201 9.89183C3.87516 10.0362 4.1251 10.0359 4.26799 9.89183C4.42039 9.73816 8 6.09989 8 3.62161C7.99996 1.62465 6.20559 0 4 0ZM4 5.44374C2.8903 5.44374 1.98752 4.62633 1.98752 3.62161C1.98752 2.61688 2.89032 1.79949 4 1.79949C5.10968 1.79949 6.01246 2.6169 6.01246 3.62163C6.01246 4.62635 5.10968 5.44374 4 5.44374Z"
      fill={fill}
    />
  </svg>
);

export default Location;
