import React, { useState } from "react";
import { DateTime } from "luxon";
import styled, { css } from "react-emotion";

import color from "src/styles/color";
import { titleCase } from "src/shared/util";

import { getOnScheduleText } from "./util";
import { TimelineItem } from "src/state/useTimeline";
import { Treatment } from "src/scenes/TrayLogs/query";
import TrayLogRows from "./TrayLogRows";
import TrayLogsUnworn from "./TrayLogsUnworn";
import RightChevron from "src/shared/Icons/RightChevron.svg";

type Props = {
  userID: string;
  treatment: Treatment;
  timeline: TimelineItem;
  selected: boolean;
  onToggle: () => void;
};

const dateFormat = "DD"; // Luxon's "Mar 2, 2020"

const TimelineRow: React.FC<Props> = props => {
  const t = props.timeline;
  const [showContent, setShowContent] = useState(t.status === "in_progress");

  return (
    <Wrapper>
      <Row
        className={statusCSS[t.status] || unknownCSS}
        onClick={() => setShowContent(!showContent)}
      >
        <div>
          <input
            type="checkbox"
            checked={props.selected}
            onChange={props.onToggle}
            onClick={e => e.stopPropagation()}
          />
        </div>
        <div>
          <b>
            {t.trayNumber}
            {!t.isWearable && " - Unwearable"}
          </b>
        </div>
        <div>
          {t.start ? DateTime.fromISO(t.start).toFormat(dateFormat) : "-"}
        </div>
        <div>{t.end ? DateTime.fromISO(t.end).toFormat(dateFormat) : "-"}</div>
        <div>
          {t.plannedEnd
            ? DateTime.fromISO(t.plannedEnd).toFormat(dateFormat)
            : "-"}
        </div>
        <div>{getOnScheduleText(t.end, t.plannedEnd)}</div>
        <div>
          {/* in V2 of the designs we don't want to warn on missing trays :thinking-face: */}
          {titleCase(t.status === "missing" ? "assumed_complete" : t.status)}
        </div>
        <div>
          <img
            alt=""
            src={RightChevron}
            css={[
              chevronCSS,
              showContent && rotateChevronCSS,
              t.status !== "complete" && incompleteChevronCSS,
            ]}
          />
        </div>
      </Row>
      {/* If this is the in-progress timeline, we always want to show the tray logs
      for this particular entry - as it's the only way to change what tray to move to and
      when */}
      {showContent && t.logs && t.logs.length > 0 && (
        <TrayLogRows
          trayLogs={t.logs}
          timelineArch={t.archType}
          userID={props.userID}
          treatment={props.treatment}
        />
      )}
      {showContent &&
        ["upcoming", "missing", "assumed_complete"].includes(t.status) && (
          <TrayLogsUnworn
            timeline={t}
            timelineArch={t.archType}
            userID={props.userID}
            treatment={props.treatment}
            status={t.status as any}
          />
        )}
    </Wrapper>
  );
};

export default TimelineRow;

const Wrapper = styled.div`
  border-bottom: 1px solid #f5f5f5;
`;

const completeCSS = css`
  border-left: 8px solid #59b8b8;
`;

const chevronCSS = css`
  transition: all 0.3s;
`;
const rotateChevronCSS = css`
  transform: rotate(90deg);
`;
const incompleteChevronCSS = css`
  opacity: 0.5;
`;

const inProgressCSS = css`
  background: #fff7e4;
  font-weight: bold;
  border-top: 1px solid ${color.borderLight};
  border-bottom: 1px solid ${color.borderLight};

  border-left: 8px solid #acdbdb;
`;

const unknownCSS = css`
  border-left: 8px solid #fff;
`;

const upcomingCSS = css`
  border-left: 8px solid #f3f3f3;

  * {
    color: #a2a9ad;
  }
`;

const missingCSS = css`
  border-left: 8px solid #dfa50d;
`;

const statusCSS = {
  complete: completeCSS,
  assumed_complete: completeCSS,
  in_progress: inProgressCSS,
  upcoming: upcomingCSS,
  // in V2 of the designs we don't want to warn on missing trays :thinking-face:
  missing: missingCSS,
};

const Row = styled.div`
  display: grid;
  grid-template-columns: 50px 210px repeat(5, 1fr) 40px;
  cursor: pointer;

  > div {
    padding: 20px 0;
  }

  > div:first-of-type {
    position: relative;
    display: flex;
    justify-content: center;

    img {
      position: relative;
      z-index: 1;
    }
  }

  & + & {
    > div:not(:first-of-type) {
      border-top: 1px solid ${color.borderLight};
    }
  }
`;
