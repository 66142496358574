import React, { useState } from "react";
import { get, upperFirst } from "lodash";
import styled, { css } from "react-emotion";
import { notification } from "antd";

import { useQuery } from "src/utils/http/gqlQuery";
import Modal from "src/shared/Modal";
import color from "src/styles/color";
import money from "src/utils/money";
import Loading from "src/shared/Loading";
import MoneyInput from "src/shared/MoneyInput";
import Box from "src/shared/Box";
import Radio from "src/shared/Icons/Radio";

import PaymentMethodSelect from "../../PaymentMethodSelect";

import Button, { ButtonRow } from "src/shared/Button";
import { usePaymentMethods } from "../../query";
import {
  orderGql,
  useCreateCharge,
  useRecordCareCreditPayment,
} from "../query";
import { PaymentMethod, Order, Patient } from "src/types/gql";

type Props = {
  patient: Patient;
  order: Order;
  onClose: () => void;
};

const ScreenWrapper = styled.div`
  height: 450px;
  width: 600px;

  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

const Footer = styled.div`
  height: 80px;
  padding: 16px 24px;

  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${color.border};
`;

const Title = styled.div`
  font-weight: bold;
  padding: 16px 24px;
  border-bottom: 1px solid ${color.border};
`;

const Content = styled.div`
  overflow-y: scroll;
  flex: 1;
  display: flex;
  flex-flow: column;
`;

const PaymentOptionButton = styled.button`
  display: flex;
  flex-flow: row;
  padding: 16px 18px;
  background: none;
  margin: 24px;
  border: 1px solid ${color.border};
  border-radius: 2px;
  cursor: pointer;

  & + & {
    margin-top: 0;
  }

  &:hover {
    background: ${color.background};
  }
`;

const PaymentOptionText = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-left: 18px;
`;

const BoldText = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

const PartialPaymentWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  color: ${color.gray3};
`;

const moneyInputStyles = css`
  border: none;
  border-bottom: 1px solid ${color.border};

  &:focus,
  &:active {
    border: none;
    border-bottom: 1px solid ${color.orange};
  }
`;

const careCreditInputStyles = css`
  margin: 16px 0 8px 0;
`;

const PartialPaymentText = styled.div`
  min-width: 100px;
  margin-right: 16px;
  color: ${color.gray3};
  margin-top: 7px;
`;

const GrayText = styled.div`
  color: ${color.gray3};
`;

const FULL = "pay_in_full";
const PARTIAL = "partial_payment";

const ChargeModal: React.FC<Props> = ({ order, patient, onClose }) => {
  const [charging, setCharging] = useState(false);
  const [screen, setScreen] = useState<
    "payment_list" | "charge" | "care_credit"
  >("payment_list");
  const [amount, setAmount] = useState(0);
  const [payOption, setPayOption] = useState(FULL);
  const [pm, setPM] = useState<PaymentMethod | "care_credit" | null>(null);
  const [fetchingPaymentMethods, , paymentMethods] = usePaymentMethods(
    patient.id
  );

  const [, refetch] = useQuery({
    query: orderGql,
    variables: { userID: patient.id },
    pause: true,
  });
  const createCharge = useCreateCharge();

  const onCharge = async () => {
    if (charging || !pm || pm === "care_credit") {
      return;
    }

    setCharging(true);
    const result = await createCharge({
      orderId: order.id,
      newCharges: [
        {
          amount: payOption === FULL ? order.balance : amount,
          paymentMethodId: pm.id,
        },
      ],
    });
    setCharging(false);

    if (result.error) {
      notification.error({
        message: result.error.message,
      });
      return;
    }
    notification.success({ message: "Charge created" });
    // We now refetch all orders and clear the cache.
    refetch();
    onClose();
  };

  const cards = fetchingPaymentMethods
    ? []
    : paymentMethods.filter(
        method => method.type === "card" && method.cardDetails
      );

  return (
    <Modal onClose={onClose}>
      <Box title="Charge">
        {screen === "payment_list" && (
          <ScreenWrapper>
            <Title>Select payment method</Title>
            <Content>
              {fetchingPaymentMethods && <Loading />}
              <PaymentMethodSelect
                cards={cards}
                onSelect={setPM}
                selected={pm}
                showCareCredit
              />
            </Content>
            <Footer>
              <Button
                style={{ width: "100px" }}
                kind="primary"
                onClick={() =>
                  setScreen(pm === "care_credit" ? "care_credit" : "charge")
                }
                disabled={!pm}
              >
                Next
              </Button>
            </Footer>
          </ScreenWrapper>
        )}

        {screen === "care_credit" && (
          <CareCreditScreen
            refetch={refetch}
            setScreen={setScreen}
            order={order}
            onClose={onClose}
          />
        )}

        {screen === "charge" && (
          <ScreenWrapper>
            <Content>
              <PaymentOptionButton
                type="button"
                onClick={() => setPayOption(FULL)}
              >
                <Radio checked={payOption === FULL} />
                <PaymentOptionText>
                  <BoldText>Pay balance in full</BoldText>
                  <GrayText>
                    Submit a payment of {money(order.balance)}
                  </GrayText>
                </PaymentOptionText>
              </PaymentOptionButton>
              <PaymentOptionButton
                type="button"
                onClick={() => setPayOption(PARTIAL)}
              >
                <Radio checked={payOption === PARTIAL} />
                <PaymentOptionText>
                  <BoldText>Partial payment</BoldText>
                  <PartialPaymentWrapper>
                    <PartialPaymentText>Payment amount:</PartialPaymentText>
                    <div>
                      <MoneyInput
                        className={moneyInputStyles}
                        value={amount}
                        onChange={setAmount}
                        max={order.balance}
                      />
                      <GrayText>(Maximum: {money(order.balance)})</GrayText>
                    </div>
                  </PartialPaymentWrapper>
                </PaymentOptionText>
              </PaymentOptionButton>
              <div style={{ marginLeft: 24 }}>
                This payment will be made from{" "}
                {upperFirst(get(pm, "cardDetails.brand"))} ending in{" "}
                {get(pm, "cardDetails.lastFour")}.
              </div>
            </Content>
            <Footer>
              <ButtonRow position="center">
                <Button
                  style={{ minWidth: 100 }}
                  kind="default"
                  onClick={() => setScreen("payment_list")}
                >
                  Back
                </Button>
                <Button
                  style={{ minWidth: 100 }}
                  kind="primary"
                  onClick={onCharge}
                  disabled={
                    !pm || charging || (payOption === PARTIAL && amount === 0)
                  }
                >
                  Pay
                </Button>
              </ButtonRow>
            </Footer>
          </ScreenWrapper>
        )}
      </Box>
    </Modal>
  );
};

const CareCreditScreen = ({ setScreen, order, refetch, onClose }) => {
  const [amount, setAmount] = useState(0);
  const record = useRecordCareCreditPayment();

  const onSubmit = async () => {
    const result = await record({
      input: { orderID: order.id, amount },
    });

    if (result.error) {
      notification.error({
        message: result.error.message,
      });
      return;
    }
    notification.success({ message: "CareCredit payment recorded" });

    // We now refetch all orders and clear the cache.
    refetch();
    onClose();
  };

  return (
    <ScreenWrapper>
      <Content>
        <div
          style={{ padding: "16px 24px", display: "flex", flexFlow: "column" }}
        >
          <div style={{ fontWeight: "bold" }}>
            Amount to record from order total (USD)
          </div>
          <MoneyInput
            className={careCreditInputStyles}
            value={amount}
            onChange={setAmount}
            max={order.total}
          />
          <div style={{ color: color.gray3 }}>
            Maximum amount: {money(order.total)}
          </div>
        </div>
      </Content>
      <Footer>
        <ButtonRow position="center">
          <Button
            style={{ minWidth: 100 }}
            kind="default"
            onClick={() => setScreen("payment_list")}
          >
            Back
          </Button>
          <Button style={{ minWidth: 100 }} kind="primary" onClick={onSubmit}>
            Record
          </Button>
        </ButtonRow>
      </Footer>
    </ScreenWrapper>
  );
};

export default ChargeModal;
