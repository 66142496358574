import { createContext } from "react";

import { request } from "src/shared/util";

// Create a new context for sharing Pipeline state modifiers.
export const PipelineContext = createContext({
  setPipelines: pipelines => [],
  pipelines: [],
});

export const getPipeline = async (pipelineId, pipelines, setPipelines) => {
  const result = await request(`/api/v1/manufacturing/pipelines/${pipelineId}`);
  if (result.errors) {
    return;
  }
  setPipelines(pipelines.filter(p => p.id !== pipelineId).concat([result]));
};
