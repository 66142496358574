import React from "react";
import TreatmentDetails from "../Treatment/TreatmentDetails";
import { Treatment, Patient, Appointment } from "src/types/gql";
import { ClinicalRecord } from "src/types/api";
import PatientTimeline, {
  TimelineParams,
} from "src/components/patient-timeline/PatientTimeline";

interface PassedProps {
  patient: Patient;
  clinicalRecord?: ClinicalRecord;
  treatment: Treatment;
  appointments: Appointment[];
  timelineParams: TimelineParams;
}

/**
 * The overview component shows a timeline of the patient, plus their latest
 * forms and appointments.
 */
export const Overview = (props: PassedProps) => {
  return (
    <div>
      <TreatmentDetails treatment={props.treatment} patient={props.patient} />

      <PatientTimeline
        patient={props.patient}
        clinicalRecord={props.clinicalRecord}
        appointments={props.appointments}
        params={props.timelineParams}
      />
    </div>
  );
};

export default Overview;
