import React from "react";

import CareCreditLogo from "./Orders/carecredit.png";
import styled, { css } from "react-emotion";
import Check from "src/shared/Icons/Check";
import color from "src/styles/color";
import { getCardIcon } from "src/utils/paymentMethod";

const CardItem = ({ card, onSelect, isSelected }) => {
  return (
    <CardItemContainer
      style={{
        backgroundColor: isSelected ? color.background : undefined,
      }}
      onClick={() => onSelect(card)}
    >
      {getCardIcon(card.cardDetails.brand)}
      <CareDetailsContainer>
        <CardTitle>
          <div>• • • • • • • • • • • • {card.cardDetails.lastFour}</div>
          {isSelected && <Selected />}
        </CardTitle>
        <CardDetails>
          {card.cardDetails.brand} expiring {card.cardDetails.expiryMonth}/
          {card.cardDetails.expiryYear}
        </CardDetails>
      </CareDetailsContainer>
    </CardItemContainer>
  );
};

const Selected = () => (
  <div style={{ fontWeight: "normal", color: color.green }}>
    <Check fill={color.green} /> Selected
  </div>
);

const PaymentMethodSelect = ({
  onSelect,
  selected,
  cards,
  showCareCredit = false,
}) => {
  return (
    <div style={{ display: "flex", flexFlow: "column" }}>
      {cards.map(card => (
        <CardItem
          key={card.id}
          card={card}
          onSelect={onSelect}
          isSelected={
            selected && selected !== "care_credit" && selected.id === card.id
          }
        />
      ))}
      {showCareCredit && (
        <CardItemContainer
          style={{
            backgroundColor:
              selected === "care_credit" ? color.background : undefined,
          }}
          onClick={() => onSelect("care_credit")}
        >
          <img
            className={careCreditLogo}
            src={CareCreditLogo}
            alt="Care credit logo"
          />
          <CareDetailsContainer>
            <CardTitle>
              CareCredit
              {selected === "care_credit" && <Selected />}
            </CardTitle>
            <CardDetails>Record CareCredit Payment</CardDetails>
          </CareDetailsContainer>
        </CardItemContainer>
      )}
    </div>
  );
};

const careCreditLogo = css`
  width: 64px;
`;

const CardTitle = styled.div`
  font-weight: bold;
  margin-bottom: 8px;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const CareDetailsContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-left: 34px;
  flex: 1;
`;

const CardDetails = styled.div`
  font-size: 14px;
  color: ${color.gray3};
`;

const CardItemContainer = styled.button`
  display: flex;
  flex-flow: row;
  align-items: center;
  cursor: pointer;

  background: none;
  border: none;
  border-bottom: 1px solid ${color.border};
  padding: 24px;

  &:focus {
    outline: none;
    background-color: ${color.background};
  }
`;

export default PaymentMethodSelect;
