import { V, S } from "src/shared-styles";

const radioWidth = 122;

export default {
  V,
  S,
  noteContainer: {
    marginTop: V.spacing,
  },
  container: {
    ...S.contentBlock,
    position: "relative",
    border: "1px solid #D7D7D7",
  },
  noteText: {
    paddingTop: V.spacingSmall,
  },
  noteSubmitContainer: {
    paddingTop: V.spacingTiny,
    textAlign: "right",
  },
  tabs: {},
  tabItemContainer: {
    background: V.colors.white,
    borderBottom: V.border,
  },
  tab: {
    color: V.colors.black,
    height: 36,
  },
  half: {
    width: `calc(50% - ${V.spacingSmall}px)`,
    marginRight: V.spacingSmall,
    display: "inline-block",
    overflow: "hidden",
    height: 48,
  },
  submitNote: {
    float: "right",
  },
  sendBy: {
    width: radioWidth * 3,
    marginTop: V.spacingSmall,
    marginRight: -21,
    marginLeft: "100%",
    float: "right",
  },
  radio: {
    width: radioWidth,
    display: "inline-block",
  },
  actionButton: {
    width: "48%",
  },
  actionButtonMiddle: {
    width: "48%",
    marginLeft: "4%",
  },
  chatBubbleLookAlike: {
    ...V.materialUIShallowCard,
    borderRadius: 8,
    backgroundColor: V.colors.lightBlue,
    padding: V.spacing,
    marginRight: 0,
    marginLeft: 0,
  },
  structuredMessageContainer: {
    ...S.materialUICard,
    margin: 0,
  },
};
