import React, { useState } from "react";
import { Tag, Input } from "antd";
import styled, { css } from "react-emotion";
import color from "src/styles/color";
import Loading from "src/shared/Loading";
import Error from "src/shared/Error";
import Table, { Header, Body } from "src/shared/Table";
import textStyles from "src/styles/textStyles";
import { Staff } from "src/types/gql";
import EditStaffModal from "./EditStaffModal";
import AddStaffModal from "./AddStaffModal";
import Button from "src/shared/Button";

import Edit from "src/scenes/Patient/BillingV2/Orders/OrderSettings/icons/edit.svg";
import ClinicPicker from "../SchedulingV2/ClinicPicker";
import { useGetAllStaff, useGetAllClinics } from "./query";
import UploadFileModal from "./UploadFileModal";

const Perimeter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${color.background};
`;

const Label = styled.div`
  ${textStyles("large")};
  font-weight: bold;
  margin-right: 16px;
`;

const Filter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  padding: 12px 0;
`;

const PickerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const clinicPickerStyle = css`
  width: 200px;
`;

const headerItemStyle = css`
  flex: 1;
`;

const editHeaderStyle = css`
  width: 120px;
`;

const EditIconWrapper = styled.div`
  cursor: pointer;
  background: none;
  border: none;
  padding: 5px 7px;
  border-radius: 5px;
  &:hover {
    background-color: ${color.gray1};
  }
`;

const UploadIconWrapper = styled.div`
  cursor: pointer;
`;

const editBodyStyle = css`
  ${editHeaderStyle}

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const bodyItemStyle = css`
  flex: 1;
  white-space: normal;
`;

const bodyRowStyle = css`
  min-height: 60px;
  height: auto;
`;

const tagItemStyle = css`
  ${bodyItemStyle}

  margin: 5px 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
`;

const StyledTag = styled(Tag)`
  mragin: 5px 10px 5px 0;
`;

const searchInput = css`
  width: 300px;
`;

const RightButtons = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row;
  justify-content: flex-end;
`;

const StaffRoles = ({ userRoles }) => (
  <div>
    {userRoles.map(({ role }) => (
      <StyledTag color="volcano" key={role}>
        {role}
      </StyledTag>
    ))}
  </div>
);

const StaffClinics = ({ clinics }) => (
  <div>
    {clinics.map(({ name }) => (
      <StyledTag color="orange" key={name}>
        {name}
      </StyledTag>
    ))}
  </div>
);

type Props = {
  organizationID: string;
};

const SHOW_ALL_CLINICS = "All clinics";
const StaffTable: React.FC<Props> = ({ organizationID }) => {
  const [fetching, err, staff] = useGetAllStaff(organizationID);
  const [cFetching, cErr, clinics] = useGetAllClinics(organizationID);
  const [filterByClinic, setFilterByClinic] = useState<string>(
    SHOW_ALL_CLINICS
  );
  const [search, setSearch] = useState("");
  const [staffToEdit, setStaffToEdit] = useState<Staff | null>(null);
  const [addingStaff, setAddingStaff] = useState(false);
  const [uploadFileToUser, setUploadFileToUser] = useState<string | null>(null);

  if (fetching || cFetching) {
    return <Loading />;
  }

  if (err || cErr) {
    return <Error />;
  }

  const onClinicFilterChange = clinicID => {
    setFilterByClinic(clinicID);
  };

  const getFilteredStaffByClinic = clinicID =>
    staff.filter(s => s.clinics.some(clinic => clinic.id === clinicID));

  const filteredStaffByClinic =
    filterByClinic === SHOW_ALL_CLINICS
      ? staff
      : getFilteredStaffByClinic(filterByClinic);

  let filteredStaff = !search
    ? filteredStaffByClinic
    : filteredStaffByClinic.filter(staff => {
        return staff.name.toLowerCase().indexOf(search) > -1;
      });

  filteredStaff = filteredStaff.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Perimeter>
      {staffToEdit && (
        <EditStaffModal
          onClose={() => setStaffToEdit(null)}
          organizationID={organizationID}
          staff={staffToEdit}
        />
      )}
      {addingStaff && (
        <AddStaffModal
          onClose={() => setAddingStaff(false)}
          organizationID={organizationID}
        />
      )}
      {uploadFileToUser && (
        <UploadFileModal
          userId={uploadFileToUser}
          onClose={() => setUploadFileToUser(null)}
        />
      )}

      <Filter>
        <Input
          className={searchInput}
          placeholder="Search by name"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <RightButtons>
          <PickerWrapper>
            <Label>Filter by clinic: </Label>
            <ClinicPicker
              className={clinicPickerStyle}
              selected={filterByClinic}
              onChange={onClinicFilterChange}
              clinics={clinics}
              clearOption={SHOW_ALL_CLINICS}
            />
          </PickerWrapper>
          <Button kind="primary" onClick={() => setAddingStaff(true)}>
            Add staff member
          </Button>
        </RightButtons>
      </Filter>
      <Table items={filteredStaff}>
        <Header>
          <Header.Item css={headerItemStyle}>Staff member</Header.Item>
          <Header.Item css={headerItemStyle}>Email</Header.Item>
          <Header.Item css={headerItemStyle}>Roles</Header.Item>
          <Header.Item css={headerItemStyle}>Clinics</Header.Item>
          <Header.Item css={editHeaderStyle} />
        </Header>
        <Body>
          {item => (
            <Body.Row key={item.name} css={bodyRowStyle}>
              <Body.Item css={bodyItemStyle}>
                <div>{item.name}</div>
              </Body.Item>
              <Body.Item css={bodyItemStyle}>{item.email}</Body.Item>
              <Body.Item css={tagItemStyle}>
                <StaffRoles userRoles={item.roles} />
              </Body.Item>
              <Body.Item css={tagItemStyle}>
                <StaffClinics clinics={item.clinics} />
              </Body.Item>
              <Body.Item css={editBodyStyle}>
                <EditIconWrapper onClick={() => setStaffToEdit(item)}>
                  <img src={Edit} alt="Edit" />
                </EditIconWrapper>
                <UploadIconWrapper>
                  <Button onClick={() => setUploadFileToUser(item.id)}>
                    Upload
                  </Button>
                </UploadIconWrapper>
              </Body.Item>
            </Body.Row>
          )}
        </Body>
      </Table>
    </Perimeter>
  );
};

export default StaffTable;
