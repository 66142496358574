import { Thunk, thunk } from "easy-peasy";
import { message } from "antd";
import { NewShippingAddress, Address } from "src/types/api";
import { request } from "src/shared/util";
import { useActions } from "../hooks";

export interface AddressState {
  create: Thunk<AddressState, NewShippingAddress>;
}

export const addresses: AddressState = {
  create: thunk(async (actions, a: NewShippingAddress) => {
    const result = await request(
      `/api/v1/addresses/create_shipping_address_for_patient`,
      {
        method: "POST",
        body: JSON.stringify(a),
      }
    );

    if (result.error) {
      message.error("Something went wrong creating new address");
      return null;
    }

    return result;
  }),
};

export const useCreateShippingAddress = () => {
  const createShippingAddress = useActions(actions => {
    return actions.addresses.create;
  });
  return (a: NewShippingAddress): [Address | null | undefined] => {
    return createShippingAddress(a);
  };
};
