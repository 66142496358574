import { useEffect, useState } from "react";
import { browserHistory } from "react-router";
import { message, notification } from "antd";

import { OperationResult, UseQueryResponse } from "src/utils/http/gqlQuery";
import { request } from "src/shared/util";
import { sortBySubmitOrCreateTime } from "src/shared/submissions";
import { useSubmissions as useSubmissionsQuery } from "./queries";
import { Submission, Question } from "./types";
import { useForm, FormContextType } from "./useFormsContext";
import { getSections } from "./Custom/CustomSections";
import {
  useSubmissionContext,
  UnsyncedToothData,
} from "src/state/submissions/useSubmissions";

type FormActionOpts = {
  notifyOnError: boolean;
};

const defaultFormActionOpts = {
  notifyOnError: true,
};

enum FormActions {
  Clone = "cloning submission",
  Create = "creating submission",
  Remove = "deleting submission",
  Update = "uploading submission",
  Submit = "submitting submission",
  Approve = "approving submission",
}

const notifyOfError = (
  response: { error?: { message?: string }; errors?: string[] },
  opts: FormActionOpts,
  action: FormActions
) => {
  if (!opts.notifyOnError) {
    return;
  }

  let description = "";

  if (response.error && response.error.message) {
    description = response.error.message;
  }

  if (response.errors && response.errors.length) {
    description = response.errors.join(", ");
  }

  if (description === "") {
    return;
  }

  notification.error({
    message: "Error " + action,
    description,
  });
};

/**
 * useFormsUI contains hooks for managing individual submissions and the UI
 * around specific forms.
 *
 */

type UpdateSubmissionInput = {
  appointmentID?: string;
};

export type FormUI = {
  form: FormContextType;

  submissions: Submission[];
  submission: Submission;
  fetching: boolean;

  section: string;
  // setSectionIndex sets the currently selected section by its within form.sections
  setSectionIndex: (n: number) => void;
  sectionQuestions: Question[];
  sectionTitlegroups: Set<string>;

  createSubmission: (
    patientID: string,
    parentID?: string
  ) => Promise<OperationResult<{ createSubmission: Submission }>>;
  updateSubmission: (
    input: UpdateSubmissionInput
  ) => Promise<OperationResult<{ updateSubmission: Submission }>>;
  submitSubmission: () => Promise<OperationResult<any>>;
  approveSubmission: () => Promise<OperationResult<any>>;
  // deleteSubmission deletes either the given submission ID or the currently selected submission.
  deleteSubmission: (id: string) => Promise<any>;
  // cloneSubmission clones a given submission by ID
  cloneSubmission: (
    id: string
  ) => Promise<OperationResult<{ cloneSubmission: Submission }>>;
  editSubmission: (
    id: string
  ) => Promise<OperationResult<{ editSubmission: Submission }>>;

  saveToothData: (data: UnsyncedToothData) => void;
  saveAnswer: (q: Question, answer: any) => void;
  getAnswerValue: (slug: string) => any;

  // answerMap is a memoized map of question slugs to answer values.
  // This is primarily used in the BV for backcompat when generating answers.
  answerMap: { [slug: string]: any };

  modal: string | null;
  setModal: (modal: string) => void;
};

// useFormUI creates a new UI context for each form for a given submission.  This UI
// context manages the current form, section, and submission.
//
// It contains helper functions for submitting GQL queries to answer questions, create
// submissions, etc.
export interface UseFormUIParams {
  slug: string;
  userID: string;
  submissionID: string | null;
  parentSubmissionID: string | null;
}

export const useFormUI = ({
  slug,
  userID,
  submissionID,
  parentSubmissionID,
}: UseFormUIParams): FormUI | null => {
  // we show/hide questions based off of the section you're currently on.
  const [sectionIndex, setSectionIndex] = useState(0);
  const [modal, setModal] = useState<string | null>(null);

  const form = useForm(slug);

  const ctx = useSubmissionContext();

  useEffect(() => {
    form && ctx.helpers.fetchSubmissions(form.id, userID);
    // eslint-disable-next-line
  }, [form ? form.id : "", userID]);

  useEffect(() => {
    ctx.helpers.setViewingSubmissions(true);
    return () => ctx.helpers.setViewingSubmissions(false);
    // eslint-disable-next-line
  }, []);

  if (!form) {
    return null;
  }

  // Split questions and titlegroups by section for easy rendering.  This saves the Form
  // component from having to manipulate data itself.
  const section = getSections(form)[sectionIndex];
  const sectionQuestions = Object.values(form.questions).filter(
    q => q.section === section
  );
  const sectionTitlegroups = new Set<string>();
  sectionQuestions.forEach(q => sectionTitlegroups.add(q.titlegroup));

  // We always take either the submission from the router URL or the first submission.
  // Normalize submissions into an array.
  const allSubmissions = sortBySubmitOrCreateTime(
    ctx.helpers.useSubmissions(form.id, userID)
  );
  let validSubmissions;
  if (parentSubmissionID) {
    validSubmissions = allSubmissions.filter(
      s => s.parentID === parentSubmissionID
    );
  } else {
    validSubmissions = allSubmissions;
  }

  const submission =
    validSubmissions.find(s => s.id === submissionID) || validSubmissions[0];

  // Normalize tooth chart
  if (submission && submission.toothData) {
    try {
      submission.toothData = JSON.parse(submission.toothData);
    } catch (e) {}
  }

  const saveToothData = (toothData: UnsyncedToothData) => {
    ctx.helpers.newToothData({
      localID: new Date().toISOString(),
      submissionID: submission.id,
      data: JSON.stringify(toothData),
      createdAt: new Date().toISOString(),
    });
  };

  // saveAnswer saves an answer for a given question
  const saveAnswer = (q: Question, answer: any) => {
    if (!submission) {
      message.error("Can't save an answer without a submission");
    }
    if (!q) {
      console.warn("unknown question");
      return;
    }

    // Optimistically store the local answer so that we show the correct value in
    // the form immediately, and show all predicates immediately.
    const string = JSON.stringify(answer);

    ctx.helpers.newAnswer({
      localID: new Date().toISOString(),
      submissionID: submission.id,
      questionSlug: q.slug,
      questionID: q.id,
      answer: string,
      createdAt: new Date().toISOString(),
    });
  };

  // createSubmission creates a new submission for the current form.
  const createSubmission = async (
    patientID: string,
    parentID?: string,
    opts: FormActionOpts = defaultFormActionOpts
  ) => {
    const response: any = await ctx.helpers.createSubmission({
      userID: patientID,
      parentID,
      formID: form.id,
    });
    notifyOfError(response, opts, FormActions.Create);
    return response;
  };

  const updateSubmission = async (
    input: UpdateSubmissionInput,
    opts: FormActionOpts = defaultFormActionOpts
  ) => {
    const response: any = ctx.helpers.updateSubmission({
      input: { id: submission.id, ...input },
    });
    notifyOfError(response, opts, FormActions.Update);
    return response;
  };

  const submitSubmission = async (
    opts: FormActionOpts = defaultFormActionOpts
  ) => {
    const hideLoadingMessage = message.loading("Submitting...", 0);
    const response = await request(
      `/api/v1/forms/submissions/${submission.id}/submit`,
      { method: "PUT" }
    );

    hideLoadingMessage();
    notifyOfError(response, opts, FormActions.Submit);

    if (response.error || (response.errors && response.errors.length > 0)) {
      return;
    }

    notification.success({ message: "Form submitted" });
    browserHistory.push("/tasks");
    return response;
  };

  const approveSubmission = async (
    opts: FormActionOpts = defaultFormActionOpts
  ) => {
    const hideLoadingMessage = message.loading("Approving...", 0);
    const response = await request(
      `/api/v1/forms/submissions/${submission.id}/approve`,
      { method: "PUT" }
    );

    hideLoadingMessage();
    notifyOfError(response, opts, FormActions.Approve);

    if (response.error || (response.errors && response.errors.length > 0)) {
      return;
    }

    notification.success({ message: "Form approved" });
    browserHistory.push("/tasks");
    return response;
  };

  // deleteSubmission deletes either the given submission ID or the currently selected
  // submission.
  const deleteSubmission = async (
    id: string,
    opts: FormActionOpts = defaultFormActionOpts
  ) => {
    const response: any = await ctx.helpers.deleteSubmission(id);
    notifyOfError(response, opts, FormActions.Remove);
    return response;
  };

  // cloneSubmission deletes either the given submission ID or the currently selected
  // submission.
  const cloneSubmission = async (
    id: string,
    opts: FormActionOpts = defaultFormActionOpts
  ) => {
    const response: any = await ctx.helpers.cloneSubmission(id);
    notifyOfError(response, opts, FormActions.Clone);
    return response;
  };

  const editSubmission = async (id: string) => {
    return await ctx.helpers.editSubmission(id);
  };

  const getAnswerValue = (slug: string): any => {
    if (!submission) {
      return;
    }

    const question = form.questions[slug];
    if (!question) {
      console.warn("no question found for slug " + slug);
      return;
    }

    const answer = ctx.helpers.getAnswer(submission.id, question.id);
    if (!answer) {
      return;
    }

    try {
      const parsed = JSON.parse(answer || "");
      return parsed;
    } catch (e) {
      console.error(e);
      return answer;
    }
  };

  const answerMap = {};
  Object.keys(form.questions).forEach(slug => {
    answerMap[slug] = getAnswerValue(slug);
  });

  const isFetching = () => {
    if (!form) return false;
    const currentSubmission = submission || {};
    return (
      ctx.state.creating[form.id] ||
      ctx.state.fetching[currentSubmission.id] ||
      ctx.state.fetching[form.id] ||
      false
    );
  };

  return {
    form,

    submissions: validSubmissions,
    submission,
    get fetching() {
      return isFetching();
    },

    updateSubmission,
    submitSubmission,
    approveSubmission,
    createSubmission,
    deleteSubmission,
    cloneSubmission,
    editSubmission,

    saveToothData,
    saveAnswer,
    getAnswerValue,
    answerMap,

    setSectionIndex,
    section,
    sectionQuestions,
    sectionTitlegroups,

    modal,
    setModal,
  };
};

export default useFormUI;

export const useAllSubmissions = (
  userID: string
): UseQueryResponse<{ submissions: Submission[] }> => {
  return useSubmissionsQuery({ userID }, false);
};

// useSubmissions is a hook which returns all submissions for a form, ensuring
// that we only call the API when we have a form.
//
// Because finding submissions occurs within a hook we _always_ need to call it,
// even with no form ID (because forms are loading and we need to keep hook call
// counts the same).
//
// This hook cleans up our mess via useEffect on form ID.
export const useSubmissions = (
  userID: string,
  formID: string
): UseQueryResponse<{ submissions: Submission[] }> => {
  const [{ data, error, fetching, stale }, refetch] = useSubmissionsQuery(
    { userID, formID },
    true
  );

  useEffect(() => {
    if (formID) {
      // Only fetch subs if we have a form ID
      refetch();
    }
    // eslint-disable-next-line
  }, [formID]);

  return [{ data, error, fetching, stale }, refetch];
};
