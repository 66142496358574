import styled, { css } from "react-emotion";

import Button from "src/shared/Button";
import color from "src/styles/color";
import Settings from "src/shared/Icons/Settings";
import InputText from "src/shared/InputText";

import { POPOVER_STYLES } from "../styles";

export const Item = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 42px;
  padding: 5px 8.5px 5px 16px;
  cursor: pointer;

  &:not(:last-of-type) {
    margin-bottom: 7px;
  }

  &:hover {
    background: ${color.background3};
  }
`;

export const ItemData = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ItemActions = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: 8px;
`;

export const ItemTitle = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.3%;

  &.waiting {
    color: ${color.gray3};
  }

  &.unsaved {
    font-style: italic;
  }
`;

export const ItemMeta = styled.span`
  font-size: 9px;
  line-height: 20px;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.3%;
  color: ${color.gray3};
`;

export const MoreButton = styled(Button)`
  width: 18px;
  height: 18px;
  box-sizing: content-box;
  padding: 2px 0;
  border-radius: 2px;
  background: transparent;
  transition: none;

  &:hover {
    background: #f8f8f8;
  }
`;

export const MoreIcon = styled(Settings)`
  &:hover {
    background: none;
  }
`;

export const MoreActionsMenu = styled.ul`
  ${POPOVER_STYLES};
  min-width: 70px;
  padding: 3px 1px;
  list-style: none;
`;

export const ItemAction = styled.button`
  width: 100%;
  padding: 5px 7px;
  border: none;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3%;
  text-align: left;
  white-space: nowrap;

  background: none;
  cursor: pointer;

  &.danger {
    color: #e0574f;
  }

  &:hover {
    background: #f8f8f8;
  }

  &:focus,
  &:active {
    outline: none;
  }
`;

export const SaveButton = styled(Button)`
  width: 18px;
  height: 18px;
  padding: 0;
  background: transparent;

  svg {
    margin: 0;
  }
`;

export const popupCss = css`
  padding: 0;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    padding: 0;
  }
`;

export const Input = styled(InputText)`
  input {
    height: 20px;
    padding: 3px;
    box-sizing: content-box;
    background: transparent;

    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.3%;

    &::selection {
      background: #d6ecf7;
      color: #333a3a;
    }

    &:focus,
    &:active,
    &:hover {
      outline: none;
      box-shadow: none;
      border: 1px solid ${color.border};
    }
  }
`;
