import React from "react";

const SolidWarning = ({ fill = "#E1A733" }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill={fill} />
    <path
      d="M15 6C13.8615 6 13 6.59551 13 7.55739V16.3799C13 17.3428 13.8613 17.9363 15 17.9363C16.1108 17.9363 17 17.3179 17 16.3799V7.55739C16.9999 6.61835 16.1108 6 15 6ZM15 20.3208C13.9061 20.3208 13 21.1127 13 22.2099C13 23.3052 13.9061 24 15 24C16.0939 24 17 23.3052 17 22.2099C16.9999 21.1126 16.0939 20.3208 15 20.3208Z"
      fill="white"
    />
  </svg>
);

export default SolidWarning;
