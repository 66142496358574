import styled from "react-emotion";
import color from "src/styles/color";

export default styled.button`
  display: flex;
  align-items: center;
  border: 0;
  background: #fff;
  cursor: pointer;
  color: ${color.gray5};
  opacity: 0.5;
  transition: all 0.3s;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: 0 none;
  }

  b {
    color: ${color.gray5};
  }

  svg {
    margin-right: 8px;
  }
`;
