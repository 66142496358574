import React from "react";
import { message } from "antd";
import Popover from "src/shared/Popover";
import SelectList from "src/shared/SelectList";
import Button from "src/shared/Button";
import autoDownload from "src/utils/download";

type Props = {
  files: File[];
};

type File = {
  id: string;
  filename: string;
  type: string;
  description?: string;
  size: number;
  internal: boolean;
};

const links = [
  {
    id: "xray",
    value: "Latest x-ray",
    files: (files: File[]) => files.filter(f => f.type === "CBCTZip"),
  },
  {
    id: "txp",
    value: "Latest .txp file",
    files: (files: File[]) => files.filter(f => f.type === "TreatmentPlanZip"),
  },
];

const downloadFile = async (f: File) => {
  const hide = message.loading("Downloading file");
  try {
    await autoDownload(`api/v2/files/${f.id}`, f.id, f.filename);
    hide();
  } catch (err) {
    hide();
    message.error(`Error downloading file: ${err.message}`);
  }
};

const QuickLinks: React.FC<Props> = props => {
  // Only show links if we have files uploaded.
  const filtered = links.filter(item => item.files(props.files).length > 0);
  if (filtered.length === 0) {
    return <Button disabled>Quick links</Button>;
  }

  return (
    <Popover
      content={
        <SelectList<{ id: string; value: string; files: (f: File[]) => File[] }>
          values={filtered}
          onClick={item => {
            if (!item) {
              return;
            }

            const files = item.files(props.files);

            if (files.length > 0) {
              // download the first file.
              downloadFile(files[0]);
            }
          }}
          render={i => <span>{i.value}</span>}
        />
      }
    >
      <Button>Quick links</Button>
    </Popover>
  );
};

export default QuickLinks;
