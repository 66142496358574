import { V, S } from "src/shared-styles";

export default {
  V,
  S,
  question: {
    fontWeight: "bold",
    color: V.colors.fontFaded,
    marginBottom: V.spacingTiny,
    fontSize: V.fontSmall,
  },
  isAdverse: {
    color: V.colors.red,
    fontWeight: "bold",
    margin: 0,
    fontSize: V.fontSmall,
  },
  response: {
    margin: 0,
    fontSize: V.fontSmall,
  },
};
