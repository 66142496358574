import React from "react";
import { CrownProps, RootProps } from "./types";

export const Crown: React.SFC<CrownProps> = ({ fill, stroke, style }) => (
  <svg width="29" height="21" viewBox="0 0 29 21" fill="none" style={style}>
    <path
      d="M15.7786 18.3836C13.1945 16.2081 11.1551 17.7189 10.4584 18.7462C10.0024 19.7615 8.36837 19.8944 7.60834 19.834C5.01161 18.7462 0.0841343 15.2653 1.14816 10.044C2.4782 3.51735 9.6984 1.16052 12.9285 0.797925C16.1586 0.435334 24.5188 2.97347 26.7989 10.4066C28.6229 16.3531 25.4055 19.1692 23.5688 19.834C22.0487 20.257 18.3626 20.5592 15.7786 18.3836Z"
      fill={fill || "#fff"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />
  </svg>
);

// Root always contains the portion as if it attached as a Crown.
export const Root: React.SFC<RootProps> = ({
  hasRootCanal,
  style,
  fill,
  stroke,
}) => (
  <svg
    width="26"
    height="44"
    viewBox="0 0 26 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M5.27969 2.85033C5.58676 4.01118 4.89586 6.11522 4.51202 7.02213L12.7644 26.0673H16.2189V15.3657L15.6432 13.7333C13.724 7.38488 9.30994 2.85033 7.00694 1.39927C5.16454 0.238418 5.08777 1.88296 5.27969 2.85033Z"
      fill={fill || "#C4D0D5"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />
    <path
      d="M2.61927 8.56834C5.4882 14.0271 4.21264 29.3494 3.00001 37H11V43H17V37H25C25 19.0758 21.4469 12.1508 19.0855 9.84344C17.7452 7.47541 11.2353 2.37503 13.9159 9.29697C15.8865 14.3858 15.7667 21.5622 14.8732 24.7803C14.1073 16.4739 10.5971 10.147 8.93772 8.02187C5.87423 3.10365 -1.01866 1.6464 2.61927 8.56834Z"
      fill={fill || "#F1F4F5"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />

    {hasRootCanal && (
      <g transform="translate(3,2.5)">
        <path
          d="M4.5 32.5C6.31132 25.2547 4.02153 9.80716 1.34484 2.49999C0.997371 1.64903 0.706365 0.964322 0.5 0.5C0.783013 1.06603 1.06603 1.73885 1.34484 2.49999C3.24238 7.14713 6.82382 16.7525 7.5 22.5C7.71573 24.3337 9.1 25.5 11.5 25.5C13.9 25.5 14.8333 23.5 15 22.5C15.5 20.8333 16.7457 15 12 2.5C17 15 19.4 20.1 19 32.5H4.5Z"
          fill="#C270A7"
        />
      </g>
    )}
  </svg>
);
