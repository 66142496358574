import React, { useState, useCallback, useEffect } from "react";
import styled from "react-emotion";
import { debounce, intersection } from "lodash";
import { Checkbox } from "antd";
import color from "src/styles/color";
import InputText from "src/shared/InputText";
import { useFormDecorations } from "../../Forms/formDecorations";
import { getRenderedOptions } from "./renderUtil";

const Perimeter = styled.div`
  display: flex;
  flex-direction: column;
  & .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    & > label {
      margin-top: 15px;
    }
  }
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${color.primary};
    border-color: ${color.primary};
  }
  & .ant-checkbox-checked::after {
    border-color: ${color.primary};
  }

  & .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${color.primary};
  }
`;

const MultiChoice = props => {
  const {
    value,
    setValue,
    withInput,
    placeholder,
    handleChange,
    disabled,
    options,
  } = props;

  const formDecorations = useFormDecorations();
  const other = Array.isArray(value)
    ? value.filter(f => !options.includes(f))[0]
    : undefined;

  const [inputTextValue, setInputTextValue] = useState(other);
  useEffect(() => {
    const other = Array.isArray(value)
      ? value.filter(f => !options.includes(f))[0]
      : undefined;
    setInputTextValue(other);
  }, [value, options]);
  const debounced = useCallback(debounce(handleChange, 1500), [handleChange]);

  if (options.length === 0) {
    return null;
  }

  return (
    <Perimeter>
      <Checkbox.Group
        disabled={disabled}
        options={getRenderedOptions(options, formDecorations)}
        value={value}
        onChange={v => {
          debounced(v);
          setValue(v);
        }}
      />

      {withInput && (
        <div style={{ marginTop: "16px" }}>
          <InputText
            disabled={disabled}
            style={{ maxWidth: "272px" }}
            placeholder={placeholder}
            value={inputTextValue}
            onChange={evt => setInputTextValue(evt.target.value)}
            onBlur={evt => {
              if (!Array.isArray(value) || !value.includes(evt.target.value)) {
                const union = intersection(options, value);
                debounced([...union, evt.target.value]);
              }
            }}
          />
        </div>
      )}
    </Perimeter>
  );
};

export default MultiChoice;
