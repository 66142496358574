import React, { useState } from "react";
import styled from "react-emotion";
import Popover from "src/shared/Popover";
import PatientSearch, {
  BaseUser,
} from "src/shared/PatientSearch/PatientSearch";

type Props = {
  value?: BaseUser;
  onClick: (selected?: BaseUser) => void;
};

const PatientSearchPopover: React.FC<Props> = ({
  children,
  value,
  onClick,
}) => {
  const [visible, setVisible] = useState(false);

  const Content = (
    <Wrapper>
      <PatientSearch
        view="basic"
        style={{ width: "300px" }}
        onChange={p => {
          onClick(p);
          setVisible(false);
        }}
      />
    </Wrapper>
  );

  return (
    <Popover
      content={Content}
      visible={visible}
      close={() => setVisible(false)}
    >
      <div onClick={() => setVisible(true)}>{children}</div>
    </Popover>
  );
};

export default PatientSearchPopover;

const Wrapper = styled.div`
  background: #fff;
  padding: 12px;
`;
