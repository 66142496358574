import React from "react";
import color from "src/styles/color";

const SolidCheck = ({ fill = color.green }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill={fill} />
    <path
      d="M8 15.0909L12.9412 20L22 11"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export default SolidCheck;
