import styled, { css } from "react-emotion";

import color from "src/styles/color";

export const OptionText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.003em;
  vertical-align: top;
`;

export const selectCss = css`
  & .ant-select-arrow svg {
    fill: ${color.gray3};
  }
`;

export const optionCss = css`
  padding: 5px 15px !important;
  margin-bottom: 2px;

  & .ant-select-selected-icon {
    display: none !important;
  }

  &.ant-select-dropdown-menu-item-selected {
    font-weight: 400;

    & .ant-select-selected-icon {
      display: inline-block !important;
    }
  }
`;

export const dropdownCss = css`
  border: 1px solid ${color.border};
  box-shadow: none;

  & .ant-select-dropdown-menu-item-group-title {
    padding: 9px 16px;
    color: ${color.primary};

    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
  }
`;
