import React from "react";
import { Avatar as AntAvatar } from "antd";

type Props = {
  name: string;
  url?: string;
  size?: number;

  className?: any;
  style?: any;
};

const Avatar: React.FC<Props> = ({
  name,
  url,
  className,
  style,
  size = 32,
}) => {
  const fontSize = size / 2.5;
  const styles = { ...style, fontSize };

  return (
    <AntAvatar size={size} style={styles} className={className}>
      {url ? <img src={url} alt={name} crossOrigin="" /> : initials(name)}
    </AntAvatar>
  );
};

export default Avatar;

const initials = (name: string) => {
  return name.split(" ").map(n => n[0]);
};
