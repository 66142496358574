import { V, S } from "src/shared-styles";

export default {
  V,
  S,
  button: {
    marginTop: V.spacingSmall,
    marginBottom: V.spacingSmall,
  },
};
