import React, { useState } from "react";
import styled from "react-emotion";
import gql from "graphql-tag";
import { notification } from "antd";
// shared
import { useMutation } from "src/utils/http/gqlQuery";
import PatientSearch from "src/shared/PatientSearch";
import { gqlError, getAppointmentName } from "src/shared/util";
import { useSchedulingRestrictions } from "src/state/self";
// local
import { AppointmentWithOffset } from "./types";
import { getLabel } from "../util";
import { Label, Item, Header } from "./Styled";
import EditForm from "./EditForm";
import textStyles from "src/styles/textStyles";

const Perimeter = styled.div`
  padding: 24px;
`;

type Props = {
  appt: AppointmentWithOffset;
  close: (e?: React.SyntheticEvent | undefined) => void;
};

const book = gql`
  mutation BookAppointment($appt: BookAppointment!) {
    appt: bookAppointment(request: $appt) {
      id
      userId
    }
  }
`;

const searchPatientQuery = gql`
  query SearchUser($search: String) {
    patients: patients(search: $search) {
      id
      name
      firstName
      lastName
      preferredName
      pronouns
      mobilePhoneNumber
      email
      nextAppointmentSubtype {
        id
        name
      }
    }
  }
`;

const EditUnbookedSlot = ({ appt, close }: Props) => {
  const [patient, setPatient] = useState<any>();
  const [, create] = useMutation(book);
  const { canCancelBV } = useSchedulingRestrictions();
  const allowPatientBooking =
    appt.appointmentType.name === "beginning" ? canCancelBV : true;

  const onBook = async (subtypeId?: string) => {
    if (!patient) {
      close();
      return;
    }
    const result = await create({
      appt: {
        appointmentId: appt.id,
        patientId: patient.id,
        subtypeId: subtypeId,
      },
    });
    if (result.error) {
      return notification.error({
        message: `Error scheduling patient: ${gqlError(result.error)}`,
      });
    }
    notification.success({ message: "Patient scheduled" });
    close();
  };

  return (
    <Perimeter>
      <Header>
        <b>{getLabel(appt.appointmentType)} visit (unbooked)</b>
        {appt.doctor && (
          <div css="margin-top: 8px">
            with Dr. {appt.doctor.firstName} {appt.doctor.lastName}
          </div>
        )}
      </Header>

      {allowPatientBooking && (
        <div>
          <Item>
            <Label>Patient</Label>
            <PatientSearch
              view="basic"
              onChange={setPatient}
              query={searchPatientQuery}
            />
            {patient && patient.nextAppointmentSubtype && (
              <p style={{ paddingTop: "4px" }} className={textStyles("medium")}>
                <strong>Next recommended type: </strong>
                {getAppointmentName(patient.nextAppointmentSubtype.name)}
              </p>
            )}
          </Item>
        </div>
      )}

      <EditForm
        patient={patient}
        appt={appt}
        enableSave={!!patient}
        onSaveCompleted={(subtypeId?: string) => {
          onBook(subtypeId);
        }}
        close={close}
      />
    </Perimeter>
  );
};

export default EditUnbookedSlot;
