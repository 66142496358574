import React from "react";

type Props = {
  fill?: string;
};

const Save = ({ fill = "#fff" }: Props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7917 4.41656C15.6529 4.08337 15.4861 3.8194 15.2916 3.625L12.3749 0.70826C12.1806 0.513974 11.9166 0.347234 11.5833 0.20826C11.2501 0.0694323 10.9445 0 10.6667 0H0.999927C0.722234 0 0.486062 0.0971614 0.291594 0.29163C0.0971249 0.485989 0 0.722125 0 0.999963V15.0002C0 15.2781 0.0971249 15.5143 0.291594 15.7087C0.486062 15.9029 0.722234 16.0002 0.999927 16.0002H15.0001C15.2781 16.0002 15.5142 15.9029 15.7086 15.7087C15.9029 15.5143 16 15.2781 16 15.0002V5.33341C16 5.05553 15.9306 4.75 15.7917 4.41656ZM6.66674 1.66656C6.66674 1.57629 6.69976 1.49832 6.76576 1.43221C6.83169 1.36639 6.90984 1.33341 7.00011 1.33341H9.00022C9.09027 1.33341 9.16838 1.36632 9.23435 1.43221C9.3005 1.49832 9.33348 1.57629 9.33348 1.66656V4.99996C9.33348 5.09037 9.30028 5.16845 9.23435 5.23446C9.16838 5.30024 9.09027 5.33326 9.00022 5.33326H7.00011C6.90984 5.33326 6.83165 5.30035 6.76576 5.23446C6.69976 5.16834 6.66674 5.09037 6.66674 4.99996V1.66656ZM12.0003 14.6669H3.99993V10.6668H12.0003V14.6669ZM14.6671 14.6669H13.3335H13.3333V10.3334C13.3333 10.0555 13.2361 9.8195 13.0417 9.62496C12.8473 9.43057 12.6112 9.3333 12.3335 9.3333H3.66663C3.38883 9.3333 3.15266 9.43057 2.95819 9.62496C2.76383 9.81936 2.66659 10.0555 2.66659 10.3334V14.6669H1.33322V1.33326H2.66656V5.6667C2.66656 5.94454 2.76368 6.18057 2.95815 6.37504C3.15262 6.5694 3.38879 6.66667 3.66659 6.66667H9.66689C9.94454 6.66667 10.1809 6.5694 10.3751 6.37504C10.5694 6.18068 10.6667 5.94454 10.6667 5.6667V1.33326C10.7709 1.33326 10.9062 1.36792 11.073 1.43735C11.2399 1.50675 11.3579 1.57618 11.4273 1.64561L14.3546 4.57286C14.424 4.64229 14.4935 4.76222 14.5629 4.93228C14.6325 5.10249 14.6671 5.23621 14.6671 5.33341V14.6669H14.6671Z"
      fill={fill}
    />
  </svg>
);

export default Save;
