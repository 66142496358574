import React from "react";

const PDF = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1342 0H4.55023C4.08852 0 3.71423 0.374286 3.71423 1.10057V31.4286C3.71423 31.6257 4.08852 32 4.55023 32H27.4497C27.9114 32 28.2857 31.6257 28.2857 31.4286V7.416C28.2857 7.01829 28.2325 6.89029 28.1388 6.796L21.4897 0.146857C21.3954 0.0531429 21.2674 0 21.1342 0Z"
      fill="#E9E9E0"
    />
    <path
      d="M21.4285 0.0862846V6.85714H28.1993L21.4285 0.0862846Z"
      fill="#D9D7CA"
    />
    <path
      d="M11.1508 19.0423C10.952 19.0423 10.7611 18.9777 10.5983 18.856C10.0034 18.4097 9.9234 17.9131 9.96112 17.5749C10.0651 16.6446 11.2154 15.6709 13.3811 14.6789C14.2405 12.7954 15.0583 10.4749 15.5457 8.536C14.9754 7.29486 14.4211 5.68457 14.8251 4.74C14.9668 4.40914 15.1434 4.15543 15.4731 4.04571C15.6034 4.00228 15.9325 3.94743 16.0537 3.94743C16.3417 3.94743 16.5948 4.31828 16.7743 4.54686C16.9428 4.76171 17.3251 5.21714 16.5611 8.43371C17.3314 10.0246 18.4228 11.6451 19.4685 12.7549C20.2177 12.6194 20.8623 12.5503 21.3874 12.5503C22.2823 12.5503 22.8245 12.7589 23.0457 13.1886C23.2285 13.544 23.1537 13.9594 22.8228 14.4229C22.5045 14.868 22.0657 15.1034 21.5543 15.1034C20.8594 15.1034 20.0503 14.6646 19.148 13.7977C17.5268 14.1366 15.6337 14.7411 14.1034 15.4103C13.6257 16.424 13.168 17.2406 12.7417 17.8394C12.156 18.6594 11.6508 19.0423 11.1508 19.0423ZM12.672 16.1131C11.4508 16.7994 10.9531 17.3634 10.9171 17.6811C10.9114 17.7337 10.896 17.872 11.1634 18.0766C11.2485 18.0497 11.7457 17.8229 12.672 16.1131ZM20.4645 13.5749C20.9303 13.9331 21.044 14.1143 21.3485 14.1143C21.4823 14.1143 21.8634 14.1086 22.04 13.8623C22.1251 13.7429 22.1583 13.6663 22.1714 13.6251C22.1011 13.588 22.008 13.5126 21.5 13.5126C21.2114 13.5131 20.8485 13.5257 20.4645 13.5749ZM16.196 9.81371C15.7874 11.2274 15.248 12.7537 14.668 14.136C15.8623 13.6726 17.1605 13.268 18.38 12.9817C17.6085 12.0857 16.8377 10.9669 16.196 9.81371ZM15.8491 4.97828C15.7931 4.99714 15.0891 5.98229 15.904 6.816C16.4463 5.60743 15.8737 4.97028 15.8491 4.97828Z"
      fill="#A2A9AD"
    />
    <path
      d="M27.4497 32H4.55023C4.08852 32 3.71423 31.6257 3.71423 31.164V22.2857H28.2857V31.164C28.2857 31.6257 27.9114 32 27.4497 32Z"
      fill="#A2A9AD"
    />
    <path
      d="M9.00828 30.2857H10.2163V28.2217H11.3123C12.5043 28.2217 13.2003 27.4777 13.2003 26.4537C13.2003 25.4217 12.5043 24.6857 11.3123 24.6857H9.00828V30.2857ZM10.2163 27.2217V25.6857H11.2403C11.8003 25.6857 12.0403 26.0457 12.0403 26.4537C12.0403 26.8617 11.8003 27.2217 11.2403 27.2217H10.2163ZM13.8598 30.2857H15.7398C17.2838 30.2857 18.6358 29.3897 18.6358 27.4857C18.6358 25.5737 17.2838 24.6857 15.7398 24.6857H13.8598V30.2857ZM15.0598 29.2457V25.7177H15.6198C16.5398 25.7177 17.4198 26.1657 17.4198 27.4857C17.4198 28.8057 16.5398 29.2457 15.6198 29.2457H15.0598ZM19.4302 30.2857H20.6302V28.0057H22.7102V26.9657H20.6302V25.7177H23.2382V24.6857H19.4302V30.2857Z"
      fill="white"
    />
  </svg>
);

export default PDF;
