import React from "react";
import { css } from "react-emotion";

import { TaskLabels } from "../types";
import { taskLabelToTitleMap } from "../mappings";

type Props = {
  label: TaskLabels;
};

const CustomLabel: React.FC<Props> = ({ label }) => (
  <span
    css={css`
      padding: 4px 0;

      font-size: 14px;
      letter-spacing: 0.5px;
      font-weight: 500;
    `}
  >
    {taskLabelToTitleMap[label]}
  </span>
);

export default CustomLabel;
