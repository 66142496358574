import styled from "react-emotion";
import color from "src/styles/color";

export default styled.label`
  display: grid;
  grid-template-columns: 120px auto;
  min-height 34px;

  > span {
    color: ${color.gray3};
    font-weight: bold;
    align-self: center;
  }

  &:last-of-type > span {
    align-self: flex-start;
  }

  textarea {
    border: 1px solid #fff;
    height: 8rem;
    padding: 8px;
    margin: -8px 0 0 -2px;
    resize: none;

    &:hover {
      border: 1px solid ${color.border};
    }
    &:focus {
      outline: 0 none;
      box-shadow: 0 0 5px ${color.primary}88;
    }
  }

  & + label {
    margin-top: 16px;
  }

  > div {
    justify-content: flex-start;
  }
`;
