import styled from "react-emotion";
import { Link } from "react-router";
import color from "src/styles/color";

export const Wrapper = styled.div`
  background: #fff;
  border-right: 1px solid ${color.border};

  > div:first-child {
    padding: 16px;
    position: relative;

    h1 {
      color: ${color.primary};
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    button {
      margin: 20px 0 0;
      width: 100%;
    }
  }
`;

export const Menu = styled.div`
  border-top: 1px solid ${color.borderLight};
  a:last-of-type {
    margin-bottom: 16px;
  }
`;

export const SectionTitle = styled.h3`
  padding: 0 !important;
  margin: 16px 16px 8px;
`;

export const NewDropdown = styled.div`
  position: absolute;
  width: calc(100% - 32px);
  transition: all 0.3s;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  height: 0;
  overflow: hidden;

  &.visible {
    height: 62px;
  }

  button {
    text-align: left !important;
    margin: -1px 0 0 0 !important;
  }
`;

export const TabWrapper = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 9px 16px 8px;
  display: flex;
  align-items: center;
  color: ${color.gray5};
  transition: all 0.2s;

  &.active {
    background: ${color.gray1};
  }

  &:hover {
    background: ${color.gray1}99;
  }

  svg {
    fill: #a2a9ad;
    margin: 0 8px 0 0;
  }
`;

export const TabText = styled.span`
  display: flex;
  align-items: center;
  flex: unset !important;
`;

export const CounterText = styled.span`
  display: flex;
  flex: unset !important;

  text-align: right;
  color: ${color.gray3};
`;
