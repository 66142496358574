// @flow
import Submission from "./Submission.js";

// const incisorVerticalAmount = answer => {
//   const values = {
//     "1mm": 4,
//     "1.1-2mm": 8,
//     "2.1-4mm": 12,
//     ">4mm": 16,
//     "⅓-⅔ coverage": 4,
//     "⅔-Full": 8,
//     Full: 12,
//     Impinging: 12,
//   };
//   if (answer) {
//     return values[answer] || 0;
//   }
//   return 0;
// };

const sagittal = answer => {
  // Cusp to Embrasure has a score of 0 as it is the ideal answer.
  const values = {
    Between: 3,
    "Cusp to Cusp": 6,
  };
  if (answer) {
    return values[answer] || 0;
  }
  return 0;
};

const upperArchLength = answer => {
  const values = {
    "2-5mm": 5,
    "5-9mm": 10,
    "9-13mm": 15,
    "13-17mm": 20,
    ">17mm": 25,
    Impacted: 25,
  };
  if (answer) {
    return values[answer] || 0;
  }
  return 0;
};

const complexity = score => {
  const maximums = {
    Easy: 28,
    Mild: 50,
    Moderate: 63,
    Difficult: 77,
    "Very Difficult": Infinity,
  };
  return (
    Object.keys(maximums).find(key => score <= maximums[key]) ||
    "Very Difficult"
  );
};

// type Complexity = "Easy" | "Mild" | "Moderate" | "Difficult" | "Very Difficult";

// type Score = {
//   score: number,

//   // A string representing the case complexity
//   complexity: Complexity,

//   // Whether this patient needs treatment
//   needs: boolean,

//   // warnings is an array of question titles which have no answers and
//   // are required to complete the ICON score.
//   missing: Array<string>,
// };

// Defined in the ICON paper.
const NEEDS_TX_SCORE = 43;

/**
 * ICONScore represents an ICON outcome calculation for a form submission
 * which includes the patient's diagnosis.
 *
 * This is defined in the ICON paper published in 2000.
 */
export default class ICONScore extends Submission {
  _result = {
    score: 0,
    complexity: "Easy",
    needs: false,
    missing: [],
  };

  get result() {
    // Get the esthetic rating.
    const esthetic = parseInt(this.answer("esthetic_dx"), 10);
    // If we have an esthetic score, multiply it by 7 and add it to
    // the result.
    this._result.score += esthetic ? esthetic * 7 : 0;

    // If a crossbite is present, add 5.
    this._result.score += this.answer("crossbite_dx") === "Present" ? 5 : 0;

    // Calculate the incisor bite amount
    this._result.score += this.incisorVerticalScore();
    // And the left/right sagittal buccal segment.
    this._result.score += sagittal(
      this.answer("right_sagittal_buccal_segment_dx", false)
    );
    this._result.score += sagittal(
      this.answer("left_sagittal_buccal_segment_dx", false)
    );
    this._result.score += upperArchLength(this.answer("upper_arch_length_dx"));

    this._result.complexity = complexity(this._result.score);
    this._result.needs = this._result.score >= NEEDS_TX_SCORE;

    return this._result;
  }

  // Attempt to get an answer from the submission, and if the answer is missing
  // automatically populate the question slug inside _result.missing.
  answer = (slug, necessary = true) => {
    const answer = Submission.prototype.answer.call(this, slug);

    // If we don't have an answer (and the answer is necessary for grading
    // the outcome), mark this in the "missing" stack so that we show the
    // missing responses on the UI.
    if (!answer && necessary) {
      const q = this.form.forms_questions.find(q => q.slug === slug);
      if (q) {
        this._result.missing.push(q.question_text);
      }
      return;
    }

    return answer;
  };

  incisorVerticalScore() {
    // This only has a score if the bite is open or closed.  If we just use
    // the answer for "incisor_vertical_bite_amount", it may report that the
    // answer is missing erroneously as we don't need to anwer it, so we
    // need to validate the bite answer first.
    const bite = this.answer("incisor_vertical_bite");
    if (bite === "Open" || bite === "Closed") {
      return sagittal(this.answer("incisor_vertical_bite_amount"));
    }
    return 0;
  }
}
