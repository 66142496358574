import { ProjectHelper, V10Project, Phase, Attachment } from "./types";
import { getPalmer } from "src/shared/ToothChart/util";
import { attachmentPosition } from "./txp";

type CanonicalTID = number;

const v10 = (p: V10Project): ProjectHelper => {
  const disclusionsDedup: Set<CanonicalTID> = new Set();
  const buttonCutoutsDedup: Set<CanonicalTID> = new Set();

  // Teeth IDs can be remapped in `p.Treatment.teethNames`.
  const canonicalTIDs: Map<number, number> = new Map(
    p.Treatment.teethNames
      ? Object.entries(p.Treatment.teethNames).map(([tid, { universalId }]) => [
          parseInt(tid, 10),
          universalId,
        ])
      : []
  );

  const getUniversal = (tid: string | number) => {
    const num = typeof tid === "number" ? tid : parseInt(tid, 10);
    return canonicalTIDs.get(num) || num;
  };

  return {
    get project(): V10Project {
      return p;
    },

    get phases(): Phase[] {
      // Ignore the "default" phase (also named "initial"), which is the initial position of the teeth.
      const IGNORE_PATTERN = /^default|initial$/i;
      return p.Treatment.Phases.filter(
        phase => !IGNORE_PATTERN.test(phase.type)
      );
    },

    toothChart(phase: Phase) {
      return ([] as any[]).concat(
        this.attachmentsForToothChart(phase, p.Treatment.attachments),
        this.disclusionsForToothChart(phase),
        this.buttonCutoutsForToothChart(phase),
        this.modifiersForToothChart(phase, p.Treatment.attachments),
        this.iprForToothChart(phase)
      );
    },

    attachmentsForToothChart(phase: Phase, attachments: Attachment[]) {
      return attachments
        .filter(a => a.installationPhase === phase.id)
        .map(a => {
          const palmer = getPalmer(getUniversal(a.tid));
          const position = attachmentPosition(phase, a);
          const type = a.type.toLowerCase();

          // Depending on the "type" of attachment it may be a powerarm.
          const entryType = (() => {
            if (type.includes("power arm")) {
              return "add_powerarms";
            }
            if (type.includes("elastic indicator")) {
              return "add_hook";
            }
            return "add_attachment";
          })();

          return {
            tooth_name: palmer,
            entry_stage: "prescribed",
            entry_type: entryType,
            entry_data: { position },
          };
        });
    },

    disclusionsForToothChart(phase: Phase) {
      const DISCLUSION_PATTERN = /disclusion/i;
      if (!phase.modifiers) {
        return [];
      }
      return phase.modifiers
        .filter(
          ({ tid, type, treatmentRelated }) =>
            DISCLUSION_PATTERN.test(type) &&
            treatmentRelated &&
            !disclusionsDedup.has(getUniversal(tid))
        )
        .map(({ tid }) => {
          const canonicalTID = getUniversal(tid);
          disclusionsDedup.add(canonicalTID);
          return {
            tooth_name: getPalmer(canonicalTID),
            entry_stage: "prescribed",
            entry_type: "disclusion",
            entry_data: {
              position: "occlusal",
            },
          };
        });
    },

    buttonCutoutsForToothChart(phase: Phase) {
      const BUTTON_CUTOUT_PATTERN = /button indicator/i;
      if (!phase.modifiers) {
        return [];
      }
      return phase.modifiers
        .filter(
          ({ tid, type, treatmentRelated }) =>
            BUTTON_CUTOUT_PATTERN.test(type) &&
            treatmentRelated &&
            !buttonCutoutsDedup.has(getUniversal(tid))
        )
        .map(toothTransform => {
          const canonicalTID = getUniversal(toothTransform.tid);
          buttonCutoutsDedup.add(canonicalTID);
          return {
            tooth_name: getPalmer(canonicalTID),
            entry_stage: "prescribed",
            entry_type: "tray_cutouts",
            entry_data: {
              position: attachmentPosition(phase, toothTransform),
            },
          };
        });
    },

    modifiersForToothChart(phase: Phase, attachments: Attachment[]) {
      // attachment type is a way of distinguishing attachment modifiers from trays
      // filter on type instead possibly...? TBD from txp team

      return attachments
        .filter(a => !a.isAttachment)
        .map(a => {
          const palmer = getPalmer(getUniversal(a.tid));
          const type = a.type.toLowerCase();

          // Get the type of modifier.  This may be a powerarm, disclusion, etc.
          const entryType = (() => {
            if (type.includes("disclusion")) {
              return "disclusion";
            }
            if (type.includes("power ridge")) {
              return "power_ridge";
            }
          })();

          if (!entryType) {
            return null;
          }
          return {
            tooth_name: palmer,
            entry_stage: "prescribed",
            entry_type: entryType,
          };
        })
        .filter(Boolean);
    },

    iprForToothChart(phase: Phase) {
      const IPR_TOOTH_PATTERN = /^(\d+)([md])/;

      return Object.entries(phase.ipr || {})
        .map(([str, iprValue]) => {
          try {
            const [, tid, flag] = str.match(IPR_TOOTH_PATTERN);
            const palmer = getPalmer(getUniversal(tid));

            return {
              tooth_name: palmer,
              entry_stage: "prescribed",
              entry_type: "ipr",
              entry_data: {
                amount: `${iprValue}mm`,
                position: flag === "m" ? "mesial" : "distal",
              },
            };
          } catch (err) {
            console.error(err);
            return null;
          }
        })
        .filter(it => !!it);
    },
  };
};

export default v10;
