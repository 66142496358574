import { Appointment } from "src/types/gql";
import { titleCase } from "src/shared/util";
import { Attribute } from "src/shared/ToothChartV2/types";
import { Submission } from "src/scenes/PatientProfile/Forms/GQLForms/types";
import dayjs from "dayjs";
import orderBy from "lodash/orderBy";
import { TimelineFormSubmissionData } from "./queries";

export interface TimelineAppointment {
  id: string;
  title: string;
  sortableDate?: string;
  formattedDate?: string;
  checkinTime?: string;
  doctorStartTime: string;
  clinicName: string;
  address: string;
  city: string;
  room: string;
  timezone: string;
  doctor?: string;
  assistant?: string;
}

export interface TimelineFormSubmission {
  id: string;
  title: string;
  formattedCreatedAt: string;
  sortableDate: string;
  formattedSubmittedAt?: string;
  formattedApprovedAt?: string;
  approver?: string;
  submitter?: string;
  doctor?: string;
  form: TimelineFormSubmissionData["form"];
}

export interface TimelineEntry {
  key: string;
  pathUrl: string;
  // NOTE: One or the other will always be present, never neither.
  appointment?: TimelineAppointment;
  submission?: TimelineFormSubmission;
}

export type TimelineEntryType = "appointment" | "submission";

export interface Selection {
  type: TimelineEntryType;
  id: string;
}

export function buildTimelineEntries(
  patientID: string,
  appointments: Array<Appointment>,
  submissions: Array<TimelineFormSubmissionData>
): Array<TimelineEntry> {
  const sortedSubmissions = orderBy(submissions, ["updatedAt"]);
  const latestSubmissionByAppt = new Map<string, TimelineFormSubmissionData>(
    sortedSubmissions
      .filter(it => !!it.appointmentID && it.form.slug !== "covid_results")
      .map(it => [it.appointmentID, it])
  );
  const entries: Array<TimelineEntry> = appointments.map(appt => {
    const submissionData = latestSubmissionByAppt.get(appt.id);
    const key = formatKeyForType("appointment", appt.id);
    return {
      key,
      pathUrl: buildPathUrl(patientID, key),
      appointment: buildTimelineAppointment(appt),
      submission: submissionData
        ? buildTimelineFormSubmission(submissionData)
        : undefined,
    };
  });

  // Include any submissions that we have not already grouped with an appointment.
  for (const submissionData of submissions) {
    const item = latestSubmissionByAppt.get(submissionData.appointmentID || "");
    if (item === submissionData) {
      continue;
    }
    const key = formatKeyForType("submission", submissionData.id);
    entries.push({
      key,
      pathUrl: buildPathUrl(patientID, key),
      submission: buildTimelineFormSubmission(submissionData),
    });
  }

  const sorted = orderBy(entries, [
    it =>
      it.appointment ? it.appointment.sortableDate : it.submission.sortableDate,
  ]);
  sorted.reverse(); // Latest at the top.
  return sorted;
}

export function buildTimelineAppointment(
  appt: Appointment
): TimelineAppointment {
  const firstStaff = appt.staff ? appt.staff[0] : null;
  const startTime = appt.startTime;
  return {
    id: appt.id,
    title: formatAppointmentLabel(appt),
    sortableDate: startTime,
    formattedDate: startTime ? formatDateForTimeline(startTime) : undefined,
    checkinTime: appt.actualStartTime,
    doctorStartTime: appt.doctorStartTime,
    clinicName: appt.clinic.nickname,
    address: appt.clinic.line1,
    city: appt.clinic.city,
    timezone: appt.clinic.timezone,
    room: appt.bookableResource.name,
    doctor: appt.doctor ? appt.doctor.name : undefined,
    assistant: firstStaff ? firstStaff.name : undefined,
  };
}

export function buildTimelineFormSubmission(
  data: TimelineFormSubmissionData
): TimelineFormSubmission {
  const {
    id,
    form,
    createdAt,
    submittedAt,
    approvedAt,
    submitter,
    approver,
  } = data;
  return {
    id,
    title: `${form.name} Form`,
    sortableDate: approvedAt || submittedAt || createdAt,
    formattedCreatedAt: formatDateForTimeline(createdAt),
    formattedSubmittedAt: submittedAt
      ? formatDateForTimeline(submittedAt)
      : undefined,
    formattedApprovedAt: approvedAt
      ? formatDateForTimeline(approvedAt)
      : undefined,
    submitter: submitter ? submitter.name : undefined,
    approver: approver ? approver.name : undefined,
    form,
  };
}

export function formatAppointmentLabel({
  appointmentType,
  appointmentSubtype,
}: Appointment): string {
  let label: string;
  if (appointmentSubtype) {
    label = titleCase(appointmentSubtype.name).trim();
  } else {
    label = titleCase(appointmentType.name).trim();
  }
  return `${label} Appointment`;
}

export function formatDateForTimeline(isoTime: string): string {
  return dayjs(isoTime).format("MMMM D, YYYY");
}

export function formatKeyForEntry(entry: TimelineEntry): string {
  return formatKeyForType(getEntryType(entry), getEntryId(entry));
}

export function formatKeyForType(type: TimelineEntryType, id: string): string {
  // This is used in the URL.
  return `${type}/${id}`;
}

export function getEntryType(entry: TimelineEntry): TimelineEntryType {
  return entry.appointment ? "appointment" : "submission";
}

export function getEntryId(entry: TimelineEntry): string {
  if (entry.appointment) {
    return entry.appointment.id;
  }
  if (entry.submission) {
    return entry.submission.id;
  }
  return "never";
}

export function parseSelection(
  type: string | undefined,
  id: string | undefined
): Selection | undefined {
  if ((type === "appointment" || type === "submission") && id) {
    return { type, id };
  }
}

export function getSelectedKey(
  entries: Array<TimelineEntry>,
  selection?: Selection
): string {
  if (selection) {
    const found = entries.find(entry => isEntrySelected(entry, selection));
    if (found) {
      return formatKeyForType(selection.type, selection.id);
    }
  }
  return formatKeyForEntry(entries[0]);
}

export function isEntrySelected(
  entry: TimelineEntry,
  selection: Selection
): boolean {
  return (
    getEntryType(entry) === selection.type && getEntryId(entry) === selection.id
  );
}

export function filterSubmissions(
  submissions: Array<TimelineFormSubmissionData>
): Array<TimelineFormSubmissionData> {
  // Only show submissions for root forms
  return submissions.filter(it => !it.parentID);
}

export function getAnswer(
  slug: string,
  submission: Submission | undefined
): any {
  if (!submission) {
    return undefined;
  }
  const answer = submission.answers.find(it => it.questionSlug === slug);
  const str = answer ? answer.answer : undefined;
  if (!str) {
    return undefined;
  }
  return JSON.parse(str);
}

export function buildPathUrl(patientID: string, entryKey: string): string {
  return `/patients/${patientID}/about/${entryKey}`;
}

export function parseToothData(
  toothData: null | string | Array<Attribute>
): Array<Attribute> {
  if (!toothData) {
    return [];
  }

  if (toothData instanceof Array) {
    return toothData;
  }

  try {
    return JSON.parse(toothData) as Array<Attribute>;
  } catch {
    return [];
  }
}
