import React, { useState } from "react";
import gql from "graphql-tag";
import styled from "react-emotion";
import { Input, Button, notification } from "antd";

import { useMutation } from "src/utils/http/gqlQuery";
import { DetailedPatient, InsurancePolicy } from "src/types/api";

interface Props {
  policy: InsurancePolicy;
  patient: DetailedPatient;
  afterCreate?: () => void;
}

interface Form {
  insurancePolicyId: string;
  eligibleAmount: number;
  percent: number;
}

const createEligibility = gql`
  mutation CreateInsurancePolicyEligibility(
    $input: NewInsurancePolicyEligibility!
  ) {
    createInsurancePolicyEligibility(input: $input) {
      id
      eligibleAmount
      percent
    }
  }
`;

const Header = styled("h2")`
  font-weight: 300;
  font-size: 1.125rem;
  color: #728389;
`;

const Form = styled("form")``;

const Grid = styled("div")`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 1rem;
`;

const Label = styled("label")`
  color: #728389;
  line-height: 2;
`;

const NewInsurance = ({ policy, patient, afterCreate }: Props) => {
  const [form, setForm] = useState<Form>({
    insurancePolicyId: policy.id,
    eligibleAmount: 0,
    percent: 0,
  });

  const [, execute] = useMutation(createEligibility);
  const onCreate = async evt => {
    evt.preventDefault();
    const resp = await execute({ input: form });

    if (resp.error) {
      notification.error({
        message: "Unable to save",
        description: resp.error.toString(),
      });
      return;
    }

    notification.success({
      message: "Eligibility created",
    });
    afterCreate && afterCreate();
  };

  return (
    <Form onSubmit={onCreate}>
      <Header>New eligibility</Header>
      <Grid>
        <div>
          <Label>Amount</Label>
          <Input
            placeholder="Eligible amount, in $"
            onChange={evt => {
              setForm({
                ...form,
                eligibleAmount:
                  parseFloat(evt.target.value.toString().replace(/\$/, "")) *
                  100,
              });
            }}
          />
        </div>
        <div>
          <Label>Percent</Label>
          <Input
            placeholder="Percent"
            onChange={evt => {
              setForm({
                ...form,
                percent: parseInt(evt.target.value.toString(), 10),
              });
            }}
          />
        </div>
      </Grid>

      <Button htmlType="submit" type="primary" style={{ marginTop: "1rem" }}>
        Save
      </Button>
    </Form>
  );
};

export default NewInsurance;
