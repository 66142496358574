import { DocumentNode } from "graphql";
import {
  CombinedError,
  OperationResult as URQLOperationResult,
  RequestPolicy as URQLRequestPolicy,
} from "@urql/core";

import { getDecodedJWT, isAuthExpired, logout } from "../auth";

import {
  cacheExchange,
  Client,
  Context,
  createClient,
  createRequest,
  dedupExchange,
  fetchExchange,
  Provider,
  useClient,
  useMutation as useURQLMutation,
  UseMutationResponse,
  useQuery as useURQLQuery,
  UseQueryArgs,
  UseQueryResponse as URQLUseQueryResponse,
} from "urql";

export type OperationResult<
  Data = any,
  Variables = object
> = URQLOperationResult<Data, Variables>;

export type RequestPolicy = URQLRequestPolicy;

export type UseQueryResponse<
  Data = any,
  Variables = object
> = URQLUseQueryResponse<Data, Variables>;

const useQuery = <Data = any, Variables = object>(
  args: UseQueryArgs<Variables, Data>
): UseQueryResponse<Data, Variables> => {
  const queryResult = useURQLQuery<Data, Variables>(args);
  logOutIfUnauthed(queryResult[0]);
  return queryResult;
};

const useMutation = <Data = any, Variables = object>(
  query: DocumentNode | string
): UseMutationResponse<Data, Variables> => {
  const mutationResult = useURQLMutation<Data, Variables>(query);
  logOutIfUnauthed(mutationResult[0]);
  return mutationResult;
};

type URQLResponse = {
  error?: CombinedError | undefined;
};

const logOutIfUnauthed = (result: URQLResponse | undefined) => {
  if (
    !(
      result &&
      result.error &&
      result.error.response &&
      result.error.response.status === 401
    )
  ) {
    return;
  }

  const authToken = getDecodedJWT();
  if (authToken !== null && isAuthExpired(authToken)) {
    logout();
  }
};

export {
  cacheExchange,
  Client,
  CombinedError,
  Context,
  createClient,
  createRequest,
  dedupExchange,
  fetchExchange,
  Provider,
  useClient,
  useMutation,
  useQuery,
};
