import styled, { css } from "react-emotion";

import color from "src/styles/color";

const BOTTOM_DIVIDER = `border-bottom: 1px solid ${color.border}`;
const SECTION_PADDING = "padding: 24px 16px";

export const Root = styled.section`
  display: flex;
  flex-direction: column;
  height: calc(100% - 63px);
  width: 440px;

  position: fixed;
  top: 63px;
  left: 100%;

  background: white;
  border: 1px solid ${color.border};
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 2px 2px 2px rgba(0, 0, 0, 0.24);

  transition: transform 0.2s ease-out;

  &.visible {
    transform: translateX(-100%);
  }
`;

export const Header = styled.header`
  flex-shrink: 0;
  ${SECTION_PADDING};
  ${BOTTOM_DIVIDER};
`;

export const Heading = styled.h1`
  margin: 0;

  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.003em;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  flex-grow: 1;
  ${SECTION_PADDING};
  ${BOTTOM_DIVIDER};

  overflow: auto;
`;

export const Footer = styled.footer`
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  flex-wrap: nowrap;
  gap: 10px;
  padding: 16px;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
`;

export const InputGroupHeading = styled.h2`
  margin: 0;

  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.003em;
`;

export const CheckboxGroupGrid = styled.div`
  display: grid;
  grid: repeat(2, auto) / repeat(2, 1fr);
  gap: 6px;
`;

export const submitCss = css`
  font-weight: 700;
`;

export const closeButtonCss = css`
  width: 12px;
  height: 12px;

  position: absolute;
  top: 16px;
  right: 16px;

  background-image: url(/static/cross.svg) !important;
  background-position: 50%;

  &:hover {
    box-shadow: none;
  }
`;
