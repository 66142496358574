import React from "react";

import Button from "src/shared/Button";
import Popover from "src/shared/Popover";

import {
  buttonCss,
  triangleCss,
  selectCss,
  itemCss,
  itemButtonCss,
} from "./styles";
import FiltersIcon from "../../Icons/Filters";

export type Props = {
  onCreateNew: VoidFunction;
};

const FiltersButton: React.FC<Props> = props => {
  const { onCreateNew } = props;

  return (
    <div>
      <Popover
        content={
          <ul css={selectCss}>
            <li css={itemCss}>
              <button css={itemButtonCss} onClick={onCreateNew}>
                Create New
              </button>
            </li>
          </ul>
        }
      >
        <Button type="primary" css={buttonCss}>
          <FiltersIcon />
          Filters
          <span css={triangleCss} />
        </Button>
      </Popover>
    </div>
  );
};

export default FiltersButton;
