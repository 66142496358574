// @flow
import React from "react";
import styled from "react-emotion";
import Moment from "moment";
import { Affix, Button, Popconfirm } from "antd";

import { useSelfRoles } from "src/state/self";
import StyledButton from "src/shared/Button";

// type Props = {
//   children: React.Node,
//   submission: Submission,
//   onSubmit: () => void,
//   onUnsubmit: () => void,
//   toothData: FormToothData,
// };

const WrapperBox = styled.div`
  background: white;
  .ant-affix {
    z-index: 0;
  }
`;

const SubmissionWrapper = props => {
  const { isDoctor } = useSelfRoles();
  const {
    children,
    submission,
    onSubmit,
    onUnsubmit,
    onApprove,
    toothData,
    form,
  } = props;

  const isSubmitted = submission && !!submission.submitted_at;
  const isApproved = submission && !!submission.approved_at;
  const showApproveButton = isDoctor && isSubmitted && !isApproved;

  // If this form is older than an hour AND hasn't been submitted the doctor
  // or staff member may have forgotten to submit it.  In this case we want
  // to show a warning.
  const age = new Date().valueOf() - new Date(submission.created_at).valueOf();
  const hour = 60 * 60 * 1000;

  return (
    <WrapperBox style={{ position: "relative" }}>
      <Wrapper>{children}</Wrapper>

      <Affix offsetBottom={0} style={{ bottom: 0, width: "100%", zIndex: 0 }}>
        <Footer>
          {isApproved ? (
            <div>
              <p>
                Approved on{" "}
                {new Moment(submission.approved_at).format(
                  "MMM Do YYYY, H:mma"
                )}
              </p>
            </div>
          ) : (
            showApproveButton && (
              <StyledButton
                kind="primary"
                style={{
                  marginLeft: "8px",
                  marginBottom: "1rem",
                }}
                onClick={onApprove}
              >
                Approve
              </StyledButton>
            )
          )}

          {!isApproved && !showApproveButton && isSubmitted && (
            <div>
              <p>
                Submitted on{" "}
                {new Moment(submission.submitted_at).format(
                  "MMM Do YYYY, H:mma"
                )}
              </p>
              {form.slug === "treatment_setup" && (
                <div>
                  <Popconfirm
                    title="Unsubmit this response? This will cause side-effects."
                    onConfirm={onUnsubmit}
                    okText="Yes"
                    cancelText="Cancel"
                    placement="topRight"
                  >
                    <Button type="danger">Unsubmit responses</Button>
                  </Popconfirm>
                </div>
              )}
            </div>
          )}

          {!isSubmitted && (
            <div>
              <div>
                {age > hour ? (
                  <p>
                    <b>Warning</b>: this form is over 1 hour old and is{" "}
                    <b>unsubmitted</b>.
                  </p>
                ) : (
                  <p>
                    This form is <b>unsubmitted</b>.
                  </p>
                )}
              </div>

              {toothData.length === 0 && (
                <Popconfirm
                  title="Submit without tooth chart?"
                  onConfirm={onSubmit}
                  okText="Yes"
                  cancelText="Cancel"
                  placement="topRight"
                >
                  <Button type="primary">
                    Submit responses and tooth chart
                  </Button>
                </Popconfirm>
              )}
              {toothData.length > 0 && (
                <Button type="primary" onClick={onSubmit}>
                  Submit responses and tooth chart
                </Button>
              )}
            </div>
          )}
        </Footer>
      </Affix>
    </WrapperBox>
  );
};

export default SubmissionWrapper;

const Wrapper = styled("div")`
  padding: 2rem;
`;

const Footer = styled("div")`
  background: #fff;
  border-top: 1px solid rgb(236, 236, 233);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  height: 4rem;
  padding: 1.5rem 2rem;
  line-height: 1rem;

  > div {
    display: flex;
    justify-content: space-between;
  }

  button {
    margin-top: -0.5rem;
  }
`;
