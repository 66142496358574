import styled from "react-emotion";
import color from "src/styles/color";

export const CardContent = styled.div`
  padding: 16px 24px;

  display: flex;
  flex-flow: column;

  hr {
    color: ${color.border};
    width: 100%;
  }
`;

export const Container = styled.div`
  border: 1px solid ${color.border};
  border-radius: 2px;
  margin-top: 16px;
  display: flex;
  flex-flow: column;
  width: 400px;
  margin-right: 16px;
`;

export const SelectProviderButton = styled.button`
  background: none;
  border: none;
  width: 100%;
  height: 48px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  color: ${color.red};
  font-weight: 600;
  border-top: 1px solid ${color.border};

  &:hover {
    cursor: pointer;
    background-color: ${color.gray1};
  }
`;

export const DetailsTitle = styled.div`
  font-weight: 600;
  font-size: 20px;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

export const DetailsSubtitle = styled.div`
  margin: 8px 0;
  color: ${color.gray3};
`;

export const DetailsRow = styled.div`
  display: flex;
  flex-flow: row;
  padding: 10px 0;

  > div:nth-child(1) {
    color: ${color.gray3};
    font-weight: 600;
    margin-right: 27px;
    width: 40px;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;

  > div {
    width: 150px;
    margin-right: 10px;
  }
`;

export const SpecialistState = styled.div`
  font-size: 10px;
  margin-left: 8px;
`;
