import React, { useState } from "react";
import styled, { css } from "react-emotion";

import color from "src/styles/color";
import InputText from "src/shared/InputText";
import Button, { ButtonRow } from "src/shared/Button";
import Modal from "src/shared/Modal";
import Box from "src/shared/Box";
import Switch from "src/shared/Switch";
import ExternalProviderTypeSelect from "src/shared/ExternalProvider/VerifiedSpecialistSummary/ExternalProviderTypeSelect";
import StateSelect from "src/shared/StateSelect";

const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row;
  padding: 10px 0;

  > div:nth-child(1) {
    color: ${color.gray3};
    font-weight: 600;
    margin-right: 27px;
    width: 100px;
  }
`;

const inputName = css`
  width: 150px;
  margin-right: 5px;
`;

const Container = styled.div`
  display: flex;
  flex-flow: column;
  border-left: 1px solid ${color.border};
  padding: 16px 24px;
  width: 600px;
  max-height: 500px;
  overflow: auto;

  hr {
    color: ${color.border};
    width: 100%;
  }
`;

const EditVerifiedSpecialistModal = ({
  specialist: {
    firstName,
    lastName,
    type,
    email,
    phone,
    clinicName,
    address,
    preferredProvider,
  },
  onSubmit,
  onClose,
  title,
}) => {
  const [details, setDetails] = useState({
    firstName,
    lastName,
    type,
    email,
    phone,
    clinicName,
    address,
    preferredProvider,
  });

  const onChange = (fieldName, maybeOptions?) => e => {
    const options = maybeOptions || {};
    if (options.isAddress) {
      setDetails({
        ...details,
        address: {
          ...details.address,
          [fieldName]: options.isSelect ? e : e.target.value,
        },
      });
      return;
    }

    setDetails({
      ...details,
      [fieldName]: options.isSelect ? e : e.target.value,
    });
  };

  const onSave = () => {
    onSubmit(details);
  };

  return (
    <Modal ignoreOutOfBoundariesClick onClose={onClose}>
      <Box title={title}>
        <Container>
          <DetailsRow>
            <div>Name</div>
            <InputText
              className={inputName}
              value={details.firstName}
              placeholder="First name"
              onChange={onChange("firstName")}
            />
            <InputText
              className={inputName}
              value={details.lastName}
              placeholder="Last name"
              onChange={onChange("lastName")}
            />
          </DetailsRow>
          <hr />
          <DetailsRow>
            <div>Provider type</div>
            <div>
              <ExternalProviderTypeSelect
                style={{ borderColor: color.border }}
                onChange={onChange("type", { isSelect: true })}
                value={details.type}
              />
            </div>
          </DetailsRow>
          <hr />
          <DetailsRow>
            <div>Email</div>
            <div>
              <InputText
                value={details.email}
                placeholder="Email"
                onChange={onChange("email")}
              />
            </div>
          </DetailsRow>
          <hr />
          <DetailsRow>
            <div>Phone</div>
            <div>
              <InputText
                value={details.phone}
                placeholder="Phone number"
                onChange={onChange("phone")}
              />
            </div>
          </DetailsRow>
          <hr />
          <DetailsRow>
            <div>Clinic</div>
            <div>
              <InputText
                value={details.clinicName}
                placeholder="Clinic name"
                onChange={onChange("clinicName")}
              />
            </div>
          </DetailsRow>
          <hr />
          <DetailsRow>
            <div>Preferred Provider</div>
            <div>
              <Switch
                checked={details.preferredProvider}
                onChange={onChange("preferredProvider", { isSelect: true })}
              />
            </div>
          </DetailsRow>
          <hr />
          <DetailsRow style={{ lineHeight: "25px" }}>
            <div>Address</div>
            <div>
              <InputText
                value={details.address.line1}
                placeholder="Line 1"
                onChange={onChange("line1", { isAddress: true })}
              />
              <br />
              <InputText
                value={details.address.line2}
                placeholder="Line 2"
                onChange={onChange("line2", { isAddress: true })}
              />
              <br />
              <div style={{ display: "flex" }}>
                <InputText
                  value={details.address.city}
                  placeholder="City"
                  onChange={onChange("city", { isAddress: true })}
                />
                <StateSelect
                  style={{ width: 200, marginLeft: 10, cursor: "pointer" }}
                  value={details.address.state}
                  onChange={onChange("state", {
                    isAddress: true,
                    isSelect: true,
                  })}
                />
              </div>
              <br />
              <InputText
                value={details.address.postalCode}
                placeholder="Zip code"
                onChange={onChange("postalCode", { isAddress: true })}
              />
            </div>
          </DetailsRow>
          <ButtonRow position="right">
            <Button kind="default" onClick={onClose}>
              Cancel
            </Button>
            <Button kind="primary" onClick={onSave}>
              Save
            </Button>
          </ButtonRow>
        </Container>
      </Box>
    </Modal>
  );
};

export default EditVerifiedSpecialistModal;
