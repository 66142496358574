import { useEffect } from "react";
import { Action, Thunk, thunk, action } from "easy-peasy";
import { message } from "antd";
import { request } from "src/shared/util";
import { ClinicalRecord } from "src/types/api";
import { ApiRequestState } from "src/types/local";
import { useStore, useActions } from "../hooks";

export interface ClinicalRecordState {
  data: {
    [userId: string]: ClinicalRecord;
  };
  fetchRequest: ApiRequestState;

  // Actions
  setClinicalRecord: Action<ClinicalRecordState, ClinicalRecord>;
  setFetchRequest: Action<ClinicalRecordState, ApiRequestState>;

  // Thunks
  fetch: Thunk<ClinicalRecordState, string>;
  update: Thunk<ClinicalRecordState, ClinicalRecord>;
}

export const clinicalRecords: ClinicalRecordState = {
  // Stores clinical records keyed by user ID
  data: {},
  fetchRequest: { loading: false, error: false },

  // Actions
  setClinicalRecord: action((state, cr) => {
    state.data[cr.user_id] = cr;
  }),
  setFetchRequest: action((state, to) => {
    state.fetchRequest = to;
  }),

  // Effects
  fetch: thunk(async (actions, id) => {
    actions.setFetchRequest({ loading: true, error: false });
    const result = await request(`/api/v1/patients/${id}/clinical_record`);

    if (result.error) {
      actions.setFetchRequest({
        loading: false,
        error: result.error,
      });
      return result.error;
    }

    actions.setClinicalRecord(result);
    actions.setFetchRequest({ loading: false, error: false });
    return result;
  }),

  update: thunk(async (actions, cr: ClinicalRecord) => {
    const result = await request(
      `/api/v1/patients/${cr.user_id}/clinical_record`,
      {
        method: "PUT",
        body: JSON.stringify(cr),
      }
    );

    if (result.error) {
      message.error("Something went wrong saving changes");
      return;
    }

    actions.setClinicalRecord(result);
    message.success("Saved!");
  }),
};

// useClinicalRecord is a helper that calls the "fetch" effect and returns
// the clinical record for the patient, plus the status of the fetch.
export const useClinicalRecord = (
  userId: string
): [ClinicalRecord | null | undefined, ApiRequestState] => {
  const cr = useStore(state => state.clinicalRecords);
  const fetchClinicalRecord = useActions(
    actions => actions.clinicalRecords.fetch
  );
  useEffect(() => {
    fetchClinicalRecord(userId);
  }, [fetchClinicalRecord, userId]);

  // Find the clinical record that matches the given user ID and return it
  // as a tuple.
  const record = Object.values(cr.data).find(
    (cr: ClinicalRecord) => cr.user_id === userId
  );
  return [record, cr.fetchRequest];
};

export const useUpdateClinicalRecord = () => {
  const updateClinicalRecord = useActions(actions => {
    return actions.clinicalRecords.update;
  });
  return (cr: ClinicalRecord) => {
    updateClinicalRecord(cr);
  };
};
