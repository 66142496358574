import { QUESTION_FORM } from "../event-type";
import { QUESTION_TYPE } from "../constant";
const { MODIFY_WEAR_SCHEDULE } = QUESTION_TYPE;

const FOLLOWUP_PRESCRIPTION_TEMPLATE = {
  doNotPrepopulate: true,
  attachToothChart: true,
  postTimestamp: null,
  postUserName: null,
  postUserUUID: null,
  complete: false,
  formName: "followupPrescription", // this must match the name of the form in config/treatment-forms/index
  type: QUESTION_FORM,
  questionList: [
    {
      displayName: "Reason for followup and Areas of Concern",
      type: "text",
    },
    {
      displayName: "Tentative plan (to be confirmed in-person)",
      type: "text",
    },
    {
      type: MODIFY_WEAR_SCHEDULE,
    },
    {
      displayName: "Please review and update the tooth chart before posting",
      type: "alert",
    },
  ],
};

export default FOLLOWUP_PRESCRIPTION_TEMPLATE;
