import React from "react";
import { TimelineHeader } from "../styles";
import { TimelineEntry } from "../util";
import Tablist from "./Tablist";

interface Props {
  selectedEntry: TimelineEntry;
  selectedTab: "summary" | "notes";
}

export default function Header({ selectedEntry, selectedTab }: Props) {
  return (
    <>
      <TimelineHeader>Patient Timeline</TimelineHeader>
      <Tablist selectedEntry={selectedEntry} selectedTab={selectedTab} />
    </>
  );
}
