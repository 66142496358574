import React from "react";
import { CrownProps, RootProps } from "./types";

export const Crown: React.SFC<CrownProps> = ({ fill, stroke, style }) => (
  <svg
    width="29"
    height="18"
    viewBox="0 0 29 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M13.3505 16.2881C9.07316 17.6491 4.97269 16.4582 3.79126 15.2673C-0.259271 11.1841 1.52296 5.9101 1.84701 4.71918C2.17105 3.52826 3.3052 2.84773 4.60137 3.01786C5.89754 3.18799 6.86967 3.01786 7.51776 2.50747C8.16584 1.99707 11.5683 1.14641 13.9986 2.1672C15.9429 2.98383 17.8331 2.39403 18.5352 1.99705C21.3436 0.862842 27.0251 -0.248684 27.2844 4.37889C27.6084 10.1634 25.9882 12.2049 25.6642 13.2257C25.4049 14.0424 22.8558 14.2465 21.6136 14.2465C15.6188 14.7569 18.6972 14.5868 13.3505 16.2881Z"
      fill={fill || "#fff"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />
  </svg>
);

// Root always contains the portion as if it attached as a Crown.
export const Root: React.SFC<RootProps> = ({
  hasRootCanal,
  style,
  fill,
  stroke,
}) => (
  <svg
    width="33"
    height="40"
    viewBox="0 0 33 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M8.91073 22.7858C9.74155 19.5184 10.3323 10.5743 10 6H17.8836V1H24V6H32C32.9305 19.3308 27.4657 28.3947 24.5301 31.6076C22.2592 33.9492 17.1857 38.6324 15.0588 38.6324C12.9319 38.6324 14.7265 35.1473 15.8896 33.4047C18.2159 29.6472 19.8775 22.7858 19.8775 20.9887C19.8775 19.5511 18.5482 22.0234 17.8836 23.4392C14.1615 30.366 10.3508 33.7314 8.91073 34.5482C7.1937 35.5284 3.36085 37.2275 1.76567 36.1819C0.170499 35.1364 1.43335 33.568 2.26417 32.9146C6.08594 29.8106 7.91537 26.7002 8.91073 22.7858Z"
      fill={fill || "#F1F4F5"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />

    {hasRootCanal && (
      <g transform="translate(1.5,2)">
        <path
          d="M0.5 33C12.1 28.6 13.6667 11.1667 13 3L29 0.5C27.4 20.5 18.3333 31.8333 14 35C21.2 27.8 23.6667 18.3333 24 14.5C24 13 23.4 10 21 10C18.6 10 17.3333 12.3333 17 13.5C11.8 26.7 3.83333 32 0.5 33Z"
          fill="#C270A7"
        />
      </g>
    )}
  </svg>
);
