import React, { useEffect, useState } from "react";

import styled from "react-emotion";
import { DateTime } from "luxon";
import { get } from "lodash";
// shared
import { useQuery } from "src/utils/http/gqlQuery";
import { useQueryState } from "src/shared/hooks";
import { Selected, SelectedObj } from "src/types/types";
import DatePickerToday from "src/shared/DatePickerToday";
import Switch from "src/shared/Switch";
import ButtonGroup from "src/shared/ButtonGroup";
import time from "src/shared/time";
import useSelf from "src/state/self";

// styles
import useOverflowHidden from "src/styles/useOverflowHidden";
import color from "src/styles/color";
import textStyles from "src/styles/textStyles";
// scenes
import CalendarView, {
  viewOptions,
} from "src/scenes/SchedulingV2/CalendarView/CalendarView";

import { getLabel as getAppointmentTypeLabel } from "../CalendarView/util";
import useDateRange from "../useDateRange";
import useAppointmentTypes from "../useAppointmentTypes";
import { schedule } from "../queries";
import useSchedulingContext, {
  SchedulingContextProvider,
} from "../useSchedulingContext";
import { useSchedulingRestrictions } from "src/state/self";

import Filters from "./Filters";
import {
  SearchFiltersContextData,
  SearchFiltersContext,
} from "./useSearchFiltersContext";

const Perimeter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${color.background};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Unit = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }
`;

const Label = styled.div`
  ${textStyles("normal")};
  font-weight: bold;
  margin-right: 16px;
`;

const BottomRow = styled.div`
  display: flex;
  flex: 1;
  padding: 0 24px;
  min-height: calc(100vh - 150px);
`;

const OptimizedScheduling = () => {
  useOverflowHidden();
  const self = useSelf();

  // context
  const { clinics, doctorOptions: baseDoctorOptions } = useSchedulingContext();

  // useQueryState
  const [selectedView, setSelectedView] = useQueryState("view", viewOptions[0]);
  const [clinicId, setClinic] = useQueryState(
    "clinic",
    get(self, "defaultClinic.id") || clinics[0].id
  );
  const clinic = clinics.find(c => c.id === clinicId);
  const clinicName = get(clinic, "nickname") || get(clinic, "name");
  useEffect(() => {
    document.title = `Uniform Teeth Schedule: Schedule (${clinicName})`;
    return () => {
      document.title = "";
    };
  }, [clinicName]);
  const { now, toISODate } = time(get("timezone", clinic));

  const [isoDate, setIsoDate] = useQueryState(
    "date",
    // ky 10/03/2019
    // NOTE: this is the only instance using toISODate with timezone.
    // Without timezone, you may get the wrong date when your local date is
    // different than the clinic date.
    toISODate(now(), get(clinic, "timezone"))
  );
  const [viewDoctorOnly, setViewDoctorOnly] = useState(false);
  // etc
  const doctorOptions = [{ label: "All", value: "all" }].concat(
    baseDoctorOptions
  );
  const [selectedDoctor, setSelectedDoctor] = useState(doctorOptions[0]);

  const enabledAppointmentTypes = useAppointmentTypes().filter(
    ({ enabled }) => enabled
  );
  const appointmentTypeOptions = [{ label: "All", value: "all" }].concat(
    enabledAppointmentTypes.map(t => ({
      value: t.id,
      label: getAppointmentTypeLabel(t),
    }))
  );
  const [
    selectedAppointmentTypeValue,
    setSelectedAppointmentTypeValue,
  ] = useState("all");
  const searchFiltersContextData: SearchFiltersContextData = {
    isSearchHit: entry => {
      if (selectedAppointmentTypeValue === "all") {
        return true;
      }
      const isBooked = !!(entry as any).user;
      return (
        !isBooked && entry.appointmentType.id === selectedAppointmentTypeValue
      );
    },
  };

  const { isScheduleManager } = useSchedulingRestrictions();

  // useQuery
  const { from, to } = useDateRange({
    isoDate,
    selectedView,
  });

  const date = DateTime.fromISO(isoDate, { zone: get(clinic, "timezone") });
  const [{ data }] = useQuery({
    query: schedule,
    variables: {
      // ky - 10/03/2019
      // NOTE: below toUTC(from) and toUTC(to) may need adjustments
      // if you do then probably need to fix up useDateRange as well
      from,
      to,
    },
  });

  const setDate = (newDate: string) => {
    setIsoDate(toISODate(newDate));
  };

  if (!clinic) {
    // TODO: Loading
    return null;
  }

  return (
    <Perimeter>
      <Row css="padding: 24px;">
        <Filters
          clinicOptions={clinics}
          selectedClinicId={clinicId}
          onChangeClinic={setClinic}
          selectedDate={isoDate}
          onChangeDate={setDate}
          timezone={clinic.timezone}
          doctorOptions={doctorOptions}
          selectedDoctor={selectedDoctor}
          onChangeDoctor={(selection: Selected) => {
            const entry = selection as SelectedObj;
            setSelectedDoctor(entry);
          }}
          appointmentTypeOptions={appointmentTypeOptions}
          selectedAppointmentTypeValue={selectedAppointmentTypeValue}
          onChangeAppointmentType={(selection: Selected) => {
            const entry = selection as SelectedObj;
            setSelectedAppointmentTypeValue(entry.value);
          }}
        />
      </Row>
      <Row css="padding: 0 24px;">
        <DatePickerToday
          date={isoDate}
          selectedView={selectedView}
          onChange={setIsoDate}
          timezone={clinic.timezone}
        />

        <Unit>
          <Unit css="margin-right: 24px;">
            <Label>Doctor Time:</Label>
            <Switch onChange={setViewDoctorOnly} />
          </Unit>
          {isScheduleManager ? (
            <ButtonGroup
              value={selectedView}
              onChange={value => setSelectedView(value)}
            >
              <ButtonGroup.Button key="day" value="Day">
                Day
              </ButtonGroup.Button>
              <ButtonGroup.Button key="week" value="Week">
                Week
              </ButtonGroup.Button>
              <ButtonGroup.Button key="list" value="List">
                List
              </ButtonGroup.Button>
              <ButtonGroup.Button key="logs" value="Logs">
                Logs
              </ButtonGroup.Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup
              value={selectedView}
              onChange={value => setSelectedView(value)}
            >
              <ButtonGroup.Button key="day" value="Day">
                Day
              </ButtonGroup.Button>
              <ButtonGroup.Button key="week" value="Week">
                Week
              </ButtonGroup.Button>
              <ButtonGroup.Button key="list" value="List">
                List
              </ButtonGroup.Button>
            </ButtonGroup>
          )}
        </Unit>
      </Row>
      <BottomRow>
        <SearchFiltersContext.Provider value={searchFiltersContextData}>
          <CalendarView
            appointments={(data && data.schedule) || []}
            clinic={clinic}
            date={date}
            view={selectedView as "Day" | "Week" | "List" | "Logs"}
            // view={selectedView as "Day" | "Week" | "List"}
            viewDoctorOnly={viewDoctorOnly}
            selectedDoctorId={selectedDoctor.value}
          />
        </SearchFiltersContext.Provider>
      </BottomRow>
    </Perimeter>
  );
};

const Wrapper = () => {
  return (
    <SchedulingContextProvider>
      <OptimizedScheduling />
    </SchedulingContextProvider>
  );
};

export default Wrapper;
