import React from "react";
import ConfirmModal from "../ConfirmModal";

type DeleteProps = {
  title?: string;
  text?: string;
  actionText?: string;
  hidden?: boolean;
  onClose: () => void;
  onConfirm: () => void | Promise<void>;
};

const DeleteConfirmModal: React.FC<DeleteProps> = props => (
  <ConfirmModal
    type="delete"
    title="Are you sure?"
    text="Do you really want to close this order?"
    actionText="Close"
    {...props}
  />
);

export default DeleteConfirmModal;
