import styled, { css, keyframes } from "react-emotion";

const bounce = keyframes`
0% {
  transform: none;
}
20% {
  transform: translate(0, 12px);
}
40% {
  transform: none;
}
`;

const width = 30;
const markBackgroundColor: any = props => props.color;

const Mark = styled("div")`
  display: block;
  width: ${width}px;
  background: ${markBackgroundColor};
  border-radius: 2px;
  height: ${width * 1.36}px;
  float: left;
  margin: 0 1px;
`;

export const Wrapper = styled("div")`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  opacity: 0.5;
`;

export const One = styled(Mark)`
  ${css};
  animation: ${bounce} 2.25s infinite;
`;

export const Two = styled(Mark)`
  ${css};
  animation: ${bounce} 2.25s infinite;
  animation-delay: 0.5625s;
`;

export const Three = styled(Mark)`
  ${css};
  animation: ${bounce} 2.25s infinite;
  animation-delay: 1.0625s;
`;

export const Four = styled(Mark)`
  ${css};
  animation: ${bounce} 2.25s infinite;
  animation-delay: 1.5625s;
`;
