import React from "react";
import styled from "react-emotion";
import color from "src/styles/color";

interface Props {
  style?: object;
  text: string;
  icon: React.ReactNode;
  onClick?: () => void;
}

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  width: 40px;

  left: 15px;
  top: 0;

  position: absolute;
  border-radius: 100rem;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  background: ${color.primary};

  transition: all 0.3s;
`;

const Button = styled.div`
  position: absolute;
  padding: 40px 0 0 0;
  width: 70px;
  margin-left: -15px;
  transition: all 0.3s;

  span {
    display: block;
    line-height: 1.2;
  }

  cursor: pointer;
  text-align: center;
  font-size: 12px;

  transition: all 0.3s;

  &:hover {
    transform: translateY(-3px);
  }

  &:hover ${Circle} {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
`;

const Child = (props: Props) => {
  return (
    <Button style={props.style} onClick={props.onClick}>
      <Circle>{props.icon}</Circle>
      <span>{props.text}</span>
    </Button>
  );
};

export default Child;
