import { sum } from "lodash";
import { Order } from "src/types/gql";
import { claimAmount } from "../utils/insurance";

export const PAYMENT_PLANS = [
  {
    name: "$299 Payment Plan With Interest",
    description: "$299 monthly payments",
    monthlyAmount: _ => 29900,
    deposit: _ => 50000,
    rate: 0.2499,
  },
];

export const calculateInsuranceClaims = (order: Order): number => {
  return sum(order.claims.map(c => claimAmount(c)));
};

export const calculateDepsoitAlreadyMet = (order: Order): number => {
  return (
    sum(order.charges.map(c => c.amount)) -
    sum(order.refunds.map(r => r.amount))
  );
};

export const calculateOrderTotal = (order: Order): number => {
  const insuranceClaims = calculateInsuranceClaims(order);
  const depositAlreadyMet = calculateDepsoitAlreadyMet(order);

  return order.total - depositAlreadyMet - insuranceClaims;
};
