import { get } from "lodash";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { getOrderSegmentList } from "src/selectors/order";
import {
  getAllOrderSegmentForOrder,
  createCharge,
  getAllOrdersForPatient,
} from "src/redux/modules/order";

import { connect } from "react-redux";
import styles from "./order-style";
import { momentFormatTime } from "../../config/constant";
import * as moment from "moment";
import { RaisedButton } from "material-ui";
import PaymentSourceList from "../payment-source-list/payment-source-list";
const initialState = {
  showPayment: false,
  paymentSourceId: null,
  waiting: false,
};

const connected = connect(
  (state, ownProps) => ({
    orderSegmentList: getOrderSegmentList(state, ownProps.patientId),
  }),
  { getAllOrderSegmentForOrder, createCharge, getAllOrdersForPatient }
);
class OrderSegment extends PureComponent {
  static propTypes = {
    orderId: PropTypes.string,
    orderSegment: PropTypes.object,
  };
  state = initialState;
  togglePayment = () => {
    this.setState({ showPayment: !this.state.showPayment });
  };
  selectCardForUse = paymentSourceId => {
    this.setState({ paymentSourceId });
  };
  charge = () => {
    this.setState({ waiting: true });
    this.props
      .createCharge({
        orderId: this.props.orderId,
        orderSegmentId: this.props.orderSegment.id,
        amount: this.props.orderSegment.amount,
        payment_source_id: this.state.paymentSourceId,
      })
      .then(() => {
        this.setState({ waiting: false, showPayment: false });
        this.props.getAllOrderSegmentForOrder(
          this.props.orderId,
          this.props.patientId
        );
        this.props.getAllOrdersForPatient(this.props.patientId);
      });
  };
  render = () => {
    const orderSegment = this.props.orderSegment;
    const showPayment = this.state.showPayment;
    const canSubmit = this.state.paymentSourceId && !this.state.waiting;
    const balance = get(orderSegment, "charged_amounts.balance");
    return (
      <div key={orderSegment.id} style={styles.orderContainer}>
        <div style={styles.order}>
          <div style={styles.orderMain}>
            <div>
              <span style={styles.paymentType}>
                {orderSegment.payment_type}{" "}
              </span>
              <span style={styles.details}>
                {" "}
                {moment(orderSegment.created_at).format(momentFormatTime)}
              </span>
              {balance > 0 ? (
                <span style={styles.details}>
                  {" "}
                  - Remaining Balance: {(balance / 100).toFixed(2)}
                </span>
              ) : (
                <span style={styles.details}> - Paid in full</span>
              )}
            </div>
            <div style={styles.details}>
              {orderSegment.payment_details &&
                orderSegment.payment_details.description}
            </div>
          </div>
          <div style={styles.cost}>
            ${(orderSegment.amount / 100).toFixed(2)}
          </div>
          {balance > 0 && (
            <RaisedButton
              label={showPayment ? "Hide" : "Pay"}
              style={styles.pay}
              onClick={this.togglePayment}
            />
          )}
        </div>
        {showPayment && (
          <div style={styles.showPayment}>
            <PaymentSourceList
              patientId={this.props.patientId}
              selectCardForUse={this.selectCardForUse}
            />
            <RaisedButton
              disabled={!canSubmit}
              secondary
              label="Charge"
              onClick={this.charge}
            />
          </div>
        )}
      </div>
    );
  };
}

export default connected(OrderSegment);
