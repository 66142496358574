import React, { Component } from "react";
import PropTypes from "prop-types";

export default class extends Component {
  static propTypes = {
    event: PropTypes.object,
  };

  render() {
    const { event } = this.props;
    return (
      <div className="note-event-display" style={{ whiteSpace: "pre-line" }}>
        {(event.data || {}).text}
      </div>
    );
  }
}
