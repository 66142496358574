import { getSubmissionAnswer } from "../answers";

export default class Submission {
  constructor(patient, form, submission, childForm) {
    this.patient = patient;
    this.form = form;
    this.submission = submission;
    this.childForm = childForm;
  }

  // getAnswer gets an answer for a question by slug.
  answer(slug) {
    const q = this.form.forms_questions.find(q => q.slug === slug);
    if (!q) {
      return;
    }

    const a = getSubmissionAnswer({
      answers: this.submission.answers,
      questionID: q.id,
      submissionID: this.submission.id,
    });
    if (!a) {
      return;
    }

    return a.answer;
  }
}
