import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./patient-task-list-style";
import { Step } from "material-ui/Stepper";
import { StepLabel } from "material-ui";
import moment from "moment";
import TaskDialog from "../../../../components/task-dialog/task-dialog";

export default class PatientTask extends Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    handleStepClick: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
  };
  state = {
    taskDialogOpen: false,
  };
  handleTaskDialogOpen = e => {
    e.stopPropagation();
    this.setState({ taskDialogOpen: true });
  };
  handleTaskDialogClose = () => {
    this.setState({ taskDialogOpen: false });
  };

  render() {
    const { task, index, active } = this.props;
    const displayNumber = index + 1;
    return (
      <div>
        <TaskDialog
          task={task}
          handleClose={this.handleTaskDialogClose}
          open={this.state.taskDialogOpen}
        />
        <Step
          style={styles.step}
          key={task.id}
          completed={task.is_complete}
          active={active}
        >
          <StepLabel
            className="test-steplabel"
            style={styles.stepButton}
            onClick={this.handleTaskDialogOpen}
            icon={displayNumber}
          >
            <div>
              {task.title}
              {task.due_date && (
                <div style={styles.dueDate}>
                  <div>{moment(task.due_date).format("MMM")}</div>
                  <div>{moment(task.due_date).format("DD")}</div>
                </div>
              )}
            </div>
          </StepLabel>
        </Step>
      </div>
    );
  }
}
