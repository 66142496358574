import React, { useState } from "react";
import { Modal as AntdModal, Form, Input, Icon } from "antd";
import { Patient } from "src/types/gql";

interface ModalProps {
  visible: boolean;
  setVisible(visible: boolean): void;
  disabled?: boolean;
  onConfirm?: Function;
  content?: string;
  children?: React.ReactNode;
}

export const Modal = (props: ModalProps) => {
  const { onConfirm, setVisible } = props;

  const onOk = onConfirm
    ? () => {
        onConfirm();
        setVisible(false);
      }
    : () => {
        setVisible(false);
      };

  return (
    <AntdModal
      visible={props.visible}
      onOk={onOk}
      onCancel={() => setVisible(false)}
      closable={false}
      destroyOnClose={true}
      okButtonProps={{ disabled: props.disabled === true }}
    >
      {props.content && <p>{props.content}</p>}
      <div>{props.children}</div>
    </AntdModal>
  );
};

interface PatientConfirmProps extends ModalProps {
  patient: Patient;
  children?: React.ReactNode;
}

export const PatientConfirm = (props: PatientConfirmProps) => {
  const [nameMatch, setNameMatch] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { firstName, lastName } = props.patient;
    setNameMatch(e.target.value === `${firstName} ${lastName}`);
  };

  return (
    <Modal {...props} disabled={!nameMatch}>
      <div>{props.children}</div>

      <Form>
        <Form.Item
          validateStatus={nameMatch ? "success" : "error"}
          hasFeedback
          help={nameMatch ? "" : "Full name is case sensitive"}
        >
          <Input
            placeholder="Enter patient name"
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            onChange={onChange}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
