import { css } from "emotion";
import styled from "react-emotion";
import Button from "src/shared/Button";

export const Submit = styled(Button)`
  width: 100%;
  margin: 50px 0 20px;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto minmax(280px, 300px);
  grid-gap: 20px;
  min-width: 1000px;
`;

export const disabled = css`
  opacity: 0.5;
  pointer-events: none;
`;

export const Inputs = styled.div`
  padding: 30px 20px;
  margin: 0 0 40px;
  background: #fafafa;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  border-radius: 4px;
`;

export const RightPanel = styled.div`
  background: #fafafa;
  padding: 0 20px 20px;

  p {
    margin: 30px 0 5px;
    font-weight: bold;
  }

  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    font-size: 0.8rem;
  }

  a {
    display: block;
    margin: 15px 0;
    text-align: right;
    font-size: 0.8rem;
  }
`;

export const Title = styled.p`
  text-align: center;
  margin: 30px 0 20px !important;
  font-weight: bold;
`;

export const trayGrid = css`
  display: grid;
  grid-template-columns: 60px repeat(4, 1fr) 2fr;
  min-height: 35px;
  align-items: center;
`;

export const Header = styled.div`
  ${trayGrid}
  font-weight: bold;
`;

export const Tray = styled.div`
  ${trayGrid}
  cursor: pointer;
  border-top: 1px solid #f3f3f3;

  &:hover {
    background: #ffeb0033;
  }

  &:nth-child(2n) {
    background: #fafafa;
  }

  > div:first-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Label = styled.label`
  input {
    display: block;
    border-radius: 2px;
    margin-top: 5px;
  }
`;
