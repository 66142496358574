import React from "react";
import styled, { css } from "react-emotion";
import Modal from "src/shared/Modal";
import Box from "src/shared/Box";
import Button, { ButtonRow } from "src/shared/Button";

const Inner = styled.div`
  padding: 30px;
`;

type Props = {
  title: string;
  description: string;
  id: string;
  onDelete: Function;
  onClose: () => void;
};

const DeleteModal: React.FC<Props> = props => {
  const onDelete = async () => {
    props.onDelete(props.id);
    props.onClose();
  };

  return (
    <Modal onClose={props.onClose}>
      <Box title={props.title}>
        <Inner>
          <p>{props.description}</p>
          <ButtonRow
            position="center"
            className={css`
              padding: 16px 0 0;
            `}
          >
            <Button onClick={props.onClose}>Cancel</Button>
            <Button onClick={onDelete} kind="warning">
              Confirm
            </Button>
          </ButtonRow>
        </Inner>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
