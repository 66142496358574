import React from "react";
import Button from "src/shared/Button";
import { Task } from "../../types";
import { Item, Wrapper } from "./styled";

type Props = {
  task: Task;
  onChangeContent: (to: string | null) => void;
};

// References are either references linked to from the task, or "fake" references used as
// buttons to link to task-specific content.
const References = props => {
  return (
    <Button
      kind="invisible"
      onClick={() => props.onChangeContent("external_care")}
    >
      <Wrapper>
        <Item>External Provider</Item>
      </Wrapper>
    </Button>
  );
};

export default References;
