import { isEmpty } from "lodash";

// Oxford joins words together, eg. ["1", "2", "3"] => `1, 2, and 3`.
const oxfordJoin = words => {
  if (typeof words === "string") {
    return words;
  }

  switch (words.length) {
    case 1:
      return words[0];
    case 2:
      return `${words[0]} and ${words[1]}`;
    default:
      return words
        .slice(0, words.length - 1)
        .concat([`and ${words[words.length - 1]}`])
        .join(", ");
  }
};

export const filterAnswers = (answers, answersToFilter: Array<any> = []) => {
  if (isEmpty(answers)) {
    return null;
  }
  if (
    Array.isArray(answers) &&
    answers.length === 1 &&
    (answers[0] === "None" || answers[0] === "Other" || answers[0] === "")
  ) {
    return null;
  }

  answersToFilter.push("Other", "None");

  if (!Array.isArray(answers)) {
    return answersToFilter.includes(answers) ? null : answers;
  }

  return answers.filter(a => !answersToFilter.includes(a));
};

const slugSummaryMap = {
  treatment_option_public_notes: answer => {
    if (!filterAnswers(answer)) {
      return null;
    }
    return answer;
  },
  oral_hygeine: answer => {
    if (answer === "Poor") {
      return " • Poor oral hygiene";
    }
    return null;
  },
  which_tmj_issues_are_present: answers => {
    const filteredAnswers = filterAnswers(answers);
    if (!filteredAnswers) {
      return null;
    }
    return `TMJ issues: ${oxfordJoin(filteredAnswers).toLowerCase()} `;
  },
  oral_habits: answers => {
    const filteredAnswers = filterAnswers(answers, ["Nail biting"]);
    if (!filteredAnswers) {
      return null;
    }
    return `Oral habits include ${oxfordJoin(filteredAnswers).toLowerCase()} `;
  },
  additional_sof_findings: answer => {
    if (!filterAnswers(answer)) {
      return null;
    }
    return answer
      .trim()
      .split("\n")
      .map(a => a.trim())
      .filter(a => !!a)
      .map(a => " • " + a)
      .join("\n");
  },
  additional_sof_diagnosis: answer => {
    // this is shown on the SOF
    if (!filterAnswers(answer)) {
      return null;
    }
    return answer
      .trim()
      .split("\n")
      .map(a => a.trim())
      .filter(a => !!a)
      .map(a => " • " + a)
      .join("\n");
  },
  lip_incompetence: answer => {
    if (!filterAnswers(answer) || answer !== "Present") {
      return null;
    }
    return "Lip incompetence";
  },
  smile_line: answer => {
    if (!answer || answer !== "High") {
      return null;
    }
    return "Excessive gingival display";
  },
  periodontal_issues_present: answers => {
    const filteredAnswers = filterAnswers(answers);
    if (!filteredAnswers) {
      return null;
    }

    return `Periodontal issues: ${oxfordJoin(filteredAnswers).toLowerCase()}`;
  },
  overjet: answer => {
    if (!filterAnswers(answer)) {
      return null;
    }
    if (answer === "Deficient") {
      return "Overjet: deficient (anterior crossbite)";
    }
    return `Overjet: ${answer.toLowerCase()}`;
  },
  overjet_rx: answer => {
    if (!filterAnswers(answer)) {
      return null;
    }
    return `${answer} overjet`;
  },
  incisor_vertical_bite: answer => {
    if (!filterAnswers(answer)) {
      return null;
    }
    if (answer === "Open") {
      return "Overbite: anterior open bite.";
    }
    return `Overbite: ${answer.toLowerCase()}`;
  },
  vertical_bite_rx: answer => {
    if (!filterAnswers(answer)) {
      return null;
    }
    return `${answer} overbite`;
  },
  crossbite_treatment_option_rx: answer => {
    if (!filterAnswers(answer, ["N/A"])) {
      return null;
    }
    if (answer === "Improve") {
      return "Eliminate posterior crossbite";
    }

    if (answer === "Maintain") {
      return "Maintain posterior crossbite";
    }

    return null;
  },
};

const getTeethMissingText = teethNumbers => {
  return `Missing teeth: #${teethNumbers.join(", ")}`;
};

const getBabyTeethText = teethNumbers => {
  return `Primary teeth: #${teethNumbers.join(", ")}`;
};

const getCariesText = teethNumbers => {
  return `Caries: #${teethNumbers.join(", ")}`;
};

const getImplantText = teethNumbers => {
  return `Implant teeth: #${teethNumbers.join(", ")}`;
};

const getCrownText = teethNumbers => {
  return `Crowns: #${teethNumbers.join(", ")}`;
};

const getImpactedText = teethNumbers => {
  return `Impacted: #${teethNumbers.join(", ")}`;
};

const getRootCanalText = teethNumbers => {
  return `Root canal: #${teethNumbers.join(", ")}`;
};

export const getTextPerEntryType = (entryType, teethNumbers) => {
  if (!teethNumbers.length) {
    return null;
  }

  switch (entryType) {
    case "missing":
      return getTeethMissingText(teethNumbers);
    case "deciduous":
      return getBabyTeethText(teethNumbers);
    case "caries":
      return getCariesText(teethNumbers);
    case "implant":
      return getImplantText(teethNumbers);
    case "crown":
      return getCrownText(teethNumbers);
    case "impacted":
      return getImpactedText(teethNumbers);
    case "root_canal":
      return getRootCanalText(teethNumbers);
    default:
      return null;
  }
};

export const getSummaryForSlugs = (slugs, answers) => {
  return slugs
    .map(slug => {
      const getSummary = slugSummaryMap[slug];
      if (!getSummary) {
        return null;
      }
      return getSummary(answers[slug]);
    })
    .filter(Boolean);
};
