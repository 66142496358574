import React from "react";
import styled, { css } from "react-emotion";
import { ToothOperation } from "src/types/api";

const Wrapper = styled("div")`
  position: absolute;
  width: 25px;
  height: 25px;
  right: 0;
  margin-right: -15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 0.75rem;
  line-height: 1;

  &:after {
    position: absolute;
    display: block;
    content: "";
    height: 100px;
    width: 1px;
    background: #ddd;
    z-index: 0;
  }

  > div {
    position: relative;
    z-index: 1;
    height: 25px;
    margin: 15px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      width: 40px;
      text-align: center;
      position: relative;
      z-index: 1;
      display: block;
    }
  }
`;

const Empty = styled("div")`
  content: " ";
`;

const upper = css`
  top: -100px;

  &:after {
    top: 0;
  }
`;

const lower = css`
  bottom: -100px;
  flex-direction: column-reverse;

  &:after {
    bottom: 0;
  }
`;

const Spacing = styled("div")`
  display: block;

  &:before {
    display: block;
    position: absolute;
    z-index: 0;
    content: "";
    border: 1px solid #ddd;
    width: 25px;
    height: 25px;
    transform: rotate(45deg);
  }
`;

const IPR = styled("div")`
  display: block;

  &:before {
    display: block;
    position: absolute;
    z-index: 0;
    content: "";
    background: #eee;
    width: 25px;
    height: 25px;
    transform: rotate(45deg);
  }
`;

type FlagProps = {
  spacing: number;
  rxIpr: number;
  ipr: number;

  position: "upper" | "lower";
};

type Props = {
  position: "upper" | "lower";
  procedures: Array<ToothOperation>;
};

const sumAmount = (procedures: Array<ToothOperation>) => {
  return procedures.reduce((sum, t) => {
    const amt = t.entry_data && t.entry_data["amount"];
    if (!amt) {
      return sum;
    }
    return sum + parseFloat(amt);
  }, 0.0);
};

// Proximal renders spacing and IPR in the mesial/distal section
// of each tooth.
//
// It combines the amounts of IPR/spacing from the left and right proximal
// of adjacent teeth into a single display where necessary.
export const ProximalFlags = ({ procedures, position }: Props) => {
  // We only want the most recent spacing, so we sort by created at (descending)
  // and take the first spacing.
  const spacing = procedures
    .filter(p => p.entry_type === "spacing")
    .sort((a, b) => {
      if (a.created_at === undefined || b.created_at === undefined) {
        return -1;
      }
      return a.created_at.localeCompare(b.created_at);
    })
    .shift();

  // Find all rx and rendered IPR
  const allIpr = procedures
    .filter(p => p.entry_type === "ipr")
    .sort((a, b) =>
      a.created_at ? a.created_at.localeCompare(b.created_at) : -1
    );

  // Take all prescriptions and sum the amounts
  const rxIpr = allIpr.filter(p => p.entry_stage === "prescribed");

  // And take all IPR since the prescription.  We only care about IPR performed
  // since the latest prescription right now.  We need to figure out a way to show
  // the history of a tooth chart nicely.
  const ipr = allIpr.filter(p => p.entry_stage === "rendered");

  const flagProps = {
    spacing: spacing ? sumAmount([spacing]) : 0,
    rxIpr: sumAmount(rxIpr),
    ipr: sumAmount(ipr),
  };

  return <Flags {...flagProps} position={position} />;
};

const Flags = ({ spacing, rxIpr, ipr, position }: FlagProps) => {
  if (!spacing && !rxIpr && !ipr) {
    return null;
  }

  return (
    <Wrapper className={position === "upper" ? upper : lower}>
      {rxIpr || ipr ? (
        <IPR>
          <span>{rxIpr > 0 && `${rxIpr} ℞`}</span>
          <span>{ipr > 0 && ipr}</span>
        </IPR>
      ) : (
        <Empty />
      )}
      {spacing ? (
        <Spacing>
          <span>{spacing}</span>
        </Spacing>
      ) : (
        <Empty />
      )}
    </Wrapper>
  );
};

export default ProximalFlags;
