import React, { useState } from "react";
import styled from "react-emotion";
import { isEmpty, get, isNil } from "lodash";
import { notification } from "antd";

import { useMutation } from "src/utils/http/gqlQuery";
import useSelf from "src/state/self";
import Button from "src/shared/Button";
import color from "src/styles/color";
import { useGetAllClinics } from "src/scenes/EMRAdmin/query";
import ClinicPicker from "src/scenes/SchedulingV2/ClinicPicker";

const setDefaultClinic = `
  mutation ($input: ID!) {
    setDefaultClinic(clinicID: $input) {
      id
      defaultClinic {
        id
        name
        nickname
      }
    }
  }
`;

const useSetDefaultClinic = () => {
  const [, execute] = useMutation(setDefaultClinic);
  return execute;
};

const Container = styled.div`
  padding: 40px;
`;

const Title = styled.h6`
  color: ${color.primary};
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Content = styled.div`
  max-width: 400px;
`;

const Settings = () => {
  const self = useSelf();
  const updateClinic = useSetDefaultClinic();
  const [defaultClinic, setDefaultClinic] = useState<string | null>(null);
  const [, , clinics] = useGetAllClinics(
    isEmpty(self.organizations) ? "" : self.organizations[0].id
  );

  const onSubmit = async () => {
    if (!isValid()) return;

    // @ts-ignore nil check above
    const res = await updateClinic({
      input: defaultClinic,
    });

    if (res.error) {
      notification.error({
        message: "There was an error saving your settings.",
      });
    } else {
      notification.success({ message: "Settings saved!" });
    }
  };

  const isValid = () => {
    return (
      !isNil(defaultClinic) && defaultClinic !== get(self, "defaultClinic.id")
    );
  };

  return (
    <Container>
      <Title>Settings</Title>
      <Content>
        <Label>Default Clinic (schedule view)</Label>
        <ClinicPicker
          onChange={setDefaultClinic}
          clinics={clinics}
          selected={
            defaultClinic || (self.defaultClinic && self.defaultClinic.id)
          }
        />
      </Content>

      <Button style={{ marginTop: 20 }} kind="primary" onClick={onSubmit}>
        Save
      </Button>
    </Container>
  );
};

export default Settings;
