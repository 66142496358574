import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import FlatButton from "material-ui/FlatButton";
import { Menu, Dropdown } from "antd";
import { routerShape } from "react-router/lib/PropTypes";
import { FormsEnhancer } from "src/state/Forms";
import { css } from "react-emotion";

import {
  ORDERED_FORM_LIST,
  TRANSLATED_FORM_NAME,
} from "src/config/treatment-forms/index";
import ToothChart from "../tooth-chart/tooth-chart";

import styles from "./treatment-nav-style";

const formMenuCss = css`
  > div {
    color: #fff;
  }
`;

class TreatmentNav extends Component {
  static contextTypes = {
    router: routerShape,
    patient: PropTypes.object.isRequired,
  };

  linkToPatient = () => {
    this.context.router.push(`/treatment/${this.context.patient.id}/patient`);
  };

  linkToWearSchedule = () => {
    this.context.router.push(
      `/treatment/${this.context.patient.id}/wear-schedule`
    );
  };

  linkToFileExplorer = () => {
    this.context.router.push(
      `/treatment/${this.context.patient.id}/file-explorer`
    );
  };

  linkToPipelines = () => {
    this.context.router.push(`/treatment/${this.context.patient.id}/pipelines`);
  };

  // Hack Warning! The code searches for the anchor element by ID
  // Material UI's onActive does not pass the ReactProxyEvent to this callback
  // Popover requires the anchorEl to position itself
  render() {
    const { forms } = this.props;

    const { patient } = this.context;
    const selectedTab = this.context.router.location.pathname.split("/")[3];
    return (
      <div style={styles.fillBackground}>
        <div style={styles.S.container}>
          <div>
            <FlatButton
              style={styles.getTabStyle(selectedTab === "patient", 6)}
              className={formMenuCss}
              primary
              onClick={this.linkToPatient}
            >
              {patient.name || `${patient.first_name} ${patient.last_name}`}
            </FlatButton>

            <Dropdown
              overlay={
                <Menu subMenuCloseDelay={0.5}>
                  {Object.keys(forms)
                    .filter((id: string) => {
                      return (
                        ["treatment_option"].indexOf(forms[id].slug) === -1
                      );
                    })
                    .map((id: string) => (
                      <Menu.Item key={id}>
                        <Link
                          to={`/patients/${patient.id}/forms/${forms[id].slug}`}
                        >
                          {forms[id].name}
                        </Link>
                      </Menu.Item>
                    ))}
                  <Menu.SubMenu title="Old forms">
                    {ORDERED_FORM_LIST.map(formName => (
                      <Menu.Item key={formName}>
                        <Link
                          to={`/patients-old/${patient.id}/form/${formName}`}
                        >
                          {TRANSLATED_FORM_NAME[formName]}
                        </Link>
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                </Menu>
              }
            >
              <FlatButton
                style={styles.getTabStyle(false, 6)}
                className={formMenuCss}
              >
                Forms
              </FlatButton>
            </Dropdown>

            <FlatButton
              style={styles.getTabStyle(selectedTab === "wear-schedule", 6)}
              className={formMenuCss}
              primary
              onClick={this.linkToWearSchedule}
            >
              Wear Schedule
            </FlatButton>
            <FlatButton
              style={styles.getTabStyle(selectedTab === "file-explorer", 6)}
              className={formMenuCss}
              primary
              onClick={this.linkToFileExplorer}
            >
              Files
            </FlatButton>
            <FlatButton
              style={styles.getTabStyle(selectedTab === "pipelines", 6)}
              className={formMenuCss}
              primary
              onClick={this.linkToPipelines}
            >
              Pipelines
            </FlatButton>
            <ToothChart
              style={styles.getTabStyle(selectedTab === "tooth-chart", 6)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default FormsEnhancer(TreatmentNav);
