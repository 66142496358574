import gql from "graphql-tag";

export const getConsentsGql = gql`
  query Consents($userId: ID!) {
    consents(userId: $userId) {
      id
      userId
      documentName
      providerType
      signUrl
      signatureWasRequested
      completedAt
    }
  }
`;

export const createConsentRequestGql = gql`
  mutation CreateConsentRequest($input: NewConsentRequest!) {
    createSignatureConsentRequest(input: $input) {
      id
      userId
      documentName
      providerType
      signUrl
      signatureWasRequested
      completedAt
    }
  }
`;
