import React, { useState, useCallback, useEffect } from "react";
import { flatten, sortBy, toPairs, isEqual } from "lodash";
import Error from "src/shared/Error";
import styled from "react-emotion";
import useSelf from "src/state/self";
import Sidebar from "../Sidebar/Sidebar";
import color from "src/styles/color";
import ProfileIcon from "../Icons/Profile";
import PipelineTable from "../PipelineTable/Table";
import { usePipelines } from "../queries";
import { PipelineFilter, PipelineStatus } from "../types";
import Loading from "src/shared/Loading";
import { titleCase } from "src/shared/util";

type Props = {
  params: {
    filter?: string;
    id?: string; // optional pipeline ID when inspecting a single pipeline
  };
};

const routeFilter = (
  filter: string | undefined,
  userID: string,
  uiStatus: PipelineStatus | undefined
): PipelineFilter => {
  switch (filter) {
    case undefined:
      return {
        assigneeIDs: [userID],
        status: uiStatus ? uiStatus : "incomplete",
      };
    case "authored":
      return { authorID: userID };
    case "completed":
      return { status: "complete" };
    default:
      return { status: uiStatus ? uiStatus : "incomplete" };
  }
};

const PIPELINES_PER_PAGE = 100;
const Pipelines = props => {
  const [uiFilters, setUIFilters] = useState<PipelineFilter>({});
  const [page, setPage] = useState(1);
  const [pipelinesByPage, setPipelinesByPage] = useState({});

  const self = useSelf();
  const { filter: routeFilterParam } = props.params;

  const filter = Object.assign(
    {},
    uiFilters,
    routeFilter(routeFilterParam, self.id, uiFilters.status)
  ) as PipelineFilter;

  const [{ error, data, fetching }] = usePipelines(
    filter,
    page,
    PIPELINES_PER_PAGE
  );

  const handleFilterChange = useCallback(
    newFilters => {
      if (!isEqual(uiFilters, newFilters)) {
        setPipelinesByPage({});
        setPage(1);
      }
      setUIFilters(newFilters);
    },
    [uiFilters]
  );

  const handleScroll = useCallback(() => {
    if ((data && data.pipelines.pageResults.totalPages <= page) || fetching)
      return;

    const bottom =
      window.innerHeight + window.pageYOffset >= document.body.scrollHeight;
    if (bottom) {
      setPage(currPage => currPage + 1);
    }
  }, [data, fetching, page]);

  useEffect(() => {
    document.title = "Uniform Teeth Pipeline: Pipelines - Uniform Teeth";
    return () => {
      document.title = "";
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (!data || !data.pipelines) return;
    setPipelinesByPage(currTasks => ({
      ...currTasks,
      [data.pipelines.pageResults.page]: data.pipelines.pipelines,
    }));
  }, [data]);

  const pipelines = flatten(
    sortBy(toPairs(pipelinesByPage), pair => parseInt(pair[0])).map(
      pair => pair[1]
    )
  );

  const pageResults = data && data.pipelines.pageResults;

  return (
    <>
      <Wrapper>
        <Sidebar />

        <div>
          <h1>
            <ProfileIcon size={20} /> {titleCase(routeFilterParam || "My")}{" "}
            Pipelines
          </h1>
          {pageResults && <h3>{pageResults.totalItems} pipelines</h3>}

          {error && <Error />}

          {!error && (
            <PipelineTable
              pipelines={pipelines}
              routeFilter={routeFilterParam || "my"}
              onUpdateFilter={handleFilterChange}
            />
          )}
          {fetching && <Loading />}
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-columns: 240px auto;
  align-items: stretch;

  > div:last-of-type {
    padding: 0 24px;

    h1 {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      padding: 32px 0 10px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 16px;
      }
    }
  }

  h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    color: ${color.gray3};
  }
`;

export default Pipelines;
