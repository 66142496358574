import React, { useState } from "react";
import styled from "react-emotion";
import { DateTime } from "luxon";
import { notification } from "antd";
import { useFormState } from "react-use-form-state";
// shared
import color from "src/styles/color";
import Button, { ButtonRow } from "src/shared/Button";
import Add from "src/shared/Icons/Add";
import { BaseUser } from "src/shared/PatientSearch/PatientSearch";
// local
import Label from "../Shared/Label";
import AssignPatientIcon from "../Icons/AssignPatient";
import DueDateIcon from "../Icons/DueDate";
import { Staff } from "src/types/gql";
import TaskCategoryPopover from "src/shared/Popovers/TaskCategoryPopover";
import DatePopover from "src/shared/Popovers/Date";
import PatientSearchPopover from "src/shared/Popovers/PatientSearch";
import Category from "../Category";
import Assignees from "../Shared/Assignees";
import InvisibleButton from "./InvisibleButton";
import { TaskCategory } from "../types";
import {
  useSingleTaskPipelines,
  useNewPipeline,
} from "../usePipelineTemplates";

type Props = {
  onCancel: () => void;
  onComplete: () => void;
};

const NewTask: React.FC<Props> = ({ onCancel, onComplete }) => {
  const [{ data }, execTaskQuery] = useSingleTaskPipelines();
  const create = useNewPipeline();

  // task states
  const [category, setCategory] = useState<TaskCategory | undefined>();
  const [assignedStaff, setAssignedStaff] = useState<Staff[]>([] as Staff[]);
  const [patient, setPatient] = useState<BaseUser | undefined>();
  const [dueDate, setDueDate] = useState<string | undefined>();
  const [formState, { text, textarea }] = useFormState();
  const valid = !!formState.values.name && !!patient;

  const onSave = async () => {
    if (!patient || !category || !data) {
      return;
    }

    // Find the pipeline with the right task category.
    const tpl = data.pipelines.find(
      p => p.taskTemplates[0].taskType.category === category
    );
    if (!tpl) {
      notification.error({ message: "Unable to find pipeline to create" });
      return;
    }

    notification.info({ message: "Creating task" });
    const result = await create({
      input: {
        userID: patient.id,
        pipelineTemplateID: tpl.id,
        pipelineOpts: {
          dueAt: dueDate,
        },
        opts: [
          {
            // The ID of the "task template"
            id: tpl.taskTemplates[0].id,
            opt: {
              name: formState.values.name,
              description: formState.values.description,
              assignedToIDs: assignedStaff.map(s => s.id),
            },
          },
        ],
      },
    });

    if (result.error) {
      notification.error({ message: `Error creating task: ${result.error}` });
      return;
    }

    notification.success({ message: "Task created" });

    execTaskQuery();

    onComplete();
  };

  return (
    <>
      <Wrapper>
        <div>
          <TaskName {...text("name")} className="h1" placeholder="Task name" />

          <RequiredButtons>
            <PatientSearchPopover value={patient} onClick={setPatient}>
              <InvisibleButton>
                <AssignPatientIcon />
                {patient ? (
                  <b>
                    {patient.firstName}{" "}
                    {patient.preferredName
                      ? `(${patient.preferredName})`
                      : null}{" "}
                    {patient.lastName}{" "}
                    {patient.pronouns ? `(${patient.pronouns})` : null}
                  </b>
                ) : (
                  "Assign patient"
                )}
              </InvisibleButton>
            </PatientSearchPopover>

            <TaskCategoryPopover
              onClick={setCategory}
              value={[category] as any}
              allowNone
            >
              {category ? (
                <SelectedCategory category={category} />
              ) : (
                <InvisibleButton className="category">
                  Task category
                </InvisibleButton>
              )}
            </TaskCategoryPopover>
          </RequiredButtons>
        </div>

        <div>
          <Label>
            <span>Assignees</span>
            <div>
              <Assignees value={assignedStaff} onChange={setAssignedStaff} />
            </div>
          </Label>

          <Label>
            <span>Due date</span>
            <DatePopover onClick={setDueDate} value={dueDate}>
              <InvisibleButton>
                {dueDate ? (
                  <Date>{DateTime.fromISO(dueDate).toLocaleString()}</Date>
                ) : (
                  <>
                    <DueDateIcon />
                    Set due date
                  </>
                )}
              </InvisibleButton>
            </DatePopover>
          </Label>

          <Label>
            <span>Description</span>
            <textarea {...textarea("description")} />
          </Label>

          {/* Related will be the last thing to implement */}
          <Related>
            <span>Related To</span>
            <InvisibleButton>
              <Add fill={color.gray2} /> Add reference
            </InvisibleButton>
          </Related>
        </div>

        <div>
          <ButtonRow position="right">
            <Button onClick={onCancel}>Cancel</Button>
            <Button kind="primary" disabled={!valid} onClick={onSave}>
              Create
            </Button>
          </ButtonRow>
        </div>
      </Wrapper>
    </>
  );
};

export default NewTask;

const Wrapper = styled.div`
  > div {
    padding: 24px 32px;
  }

  > div + div {
    border-top: 1px solid ${color.border};
  }

  width: 600px;
`;

const TaskName = styled.input`
  border: 0 none;
  border-radius: 3px;
  padding: 12px 8px;
  margin: -12px 0 0 -8px;
  border: 1px solid #fff;
  width: calc(100% + 16px);

  &:hover {
    border: 1px solid ${color.border};
  }

  &:focus {
    outline: 0 none;
    box-shadow: 0 0 5px ${color.primary}88;
  }
`;

const RequiredButtons = styled.div`
  display: flex;
  margin: 16px 0 0 -8px;

  .category {
    margin-left: 32px;
    border: 1px dashed ${color.border};
    border-radius: 100px;
    padding: 4px 12px;
    line-height: 12px;
    align-self: center;
    font-size: 12px;
    letter-spacing: 0.5px;
  }
`;

const SelectedCategory = styled(Category)`
  margin-left: 32px;
  cursor: pointer;
`;

const Date = styled.div`
  color: ${color.gray5};
`;

const Related = styled.div`
  display: none;

  span {
    display: block;
    color: ${color.gray3};
    font-weight: bold;
    padding: 4px 0;
    margin: 0 0 16px 0;
    border-bottom: 1px solid ${color.borderLight};
  }
`;
