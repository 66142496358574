import React from "react";
import { DateTime } from "luxon";
import Avatar from "src/shared/Avatar";
// import { Note } from "./queries";
import { Wrapper, Content, Meta, Text } from "./styles";

type Note = {
  id: string;
  author: { id: string; name: string };
  text: string;
  createdAt: string;
};

type Props = {
  note: Note;
};

const NoteItem: React.SFC<Props> = ({ note }) => (
  <Wrapper>
    <Avatar name={note.author.name} style={{ marginRight: "1rem" }} />
    <Content>
      <Meta>
        <span>{note.author.name}</span>
        <span>{DateTime.fromISO(note.createdAt).toFormat("h:mma, DD")}</span>
      </Meta>
      <Text>{note.text}</Text>
    </Content>
  </Wrapper>
);

export default NoteItem;
