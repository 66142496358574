import { V } from "src/shared-styles";

const theme = {
  palette: {
    accent1Color: V.colors.primary,
    primary1Color: V.colors.secondary,
    textColor: V.colors.black,
  },
};

export default theme;
