import React from "react";
import { Menu, Dropdown } from "antd";
import Button from "src/shared/Button";
import DownArrow from "src/shared/Icons/DownArrow";
import { Container, mainButton, secondButton } from "./styles";

type Action = {
  label: string;
  onClick: () => void;
};

type Props = {
  mainAction: Action;
  dropdownActions: Array<Action>;
};

// todo: make this it's own component fodler to export
const DropdownButton: React.FC<Props> = ({
  mainAction,
  dropdownActions,
  ...rest
}) => {
  const overlay = (
    <Menu>
      {dropdownActions.map(({ label, onClick }) => (
        <Menu.Item key={label} onClick={onClick}>
          {label}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Container>
      <Button onClick={mainAction.onClick} className={mainButton} {...rest}>
        {mainAction.label}
      </Button>
      <Dropdown placement="bottomRight" overlay={overlay} trigger={["click"]}>
        <Button style={{ marginLeft: 0 }} className={secondButton}>
          <DownArrow />
        </Button>
      </Dropdown>
    </Container>
  );
};

export default DropdownButton;
