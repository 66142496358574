import React from "react";
import color from "src/styles/color";

interface Props {
  fill?: string;
}

const ImpressTM = ({ fill = color.gray3 }: Props) => (
  <svg
    width="32"
    height="32"
    viewBox="-8 -8 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25031 2.46044L1.25167 2.24093L1.37119 2.0568C1.54362 1.79113 1.75296 1.5554 2.01721 1.40673C2.30629 1.24409 2.6302 1.20521 2.93696 1.30312C3.21523 1.39194 3.41043 1.5708 3.53344 1.71838C3.65992 1.87011 3.75007 2.03607 3.80934 2.18064L3.87071 2.33036L3.8649 2.49207C3.80753 4.08737 4.04438 6.46577 4.9072 8.41339C5.76025 10.339 7.1515 11.7184 9.41022 11.7184C11.6714 11.7184 13.0813 10.3358 13.9565 8.40724C14.841 6.45814 15.1034 4.07896 15.0632 2.48399L15.0564 2.21365L15.2237 2.00117C15.6104 1.50998 16.1605 1.13025 16.8043 1.31792C17.3385 1.47362 17.6004 1.94282 17.6979 2.1904L17.7513 2.3262L17.75 2.47214C17.7313 4.46555 17.3191 7.4657 16.1133 9.99697C14.902 12.5398 12.806 14.75 9.41022 14.75C6.01072 14.75 3.94408 12.5352 2.77053 9.98829C1.60321 7.45489 1.23788 4.45353 1.25031 2.46044Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.3"
    />
  </svg>
);

export default ImpressTM;
