import { isEmpty, map, partial } from "lodash";
import styles from "./payment-source-list-style";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { RaisedButton } from "material-ui";

import { getPaymentSources } from "src/redux/modules/order";

import { connect } from "react-redux";
import { getPaymentSourceList } from "../../selectors/order";

const initialState = {
  paymentSourceId: null,
};

const connected = connect(
  (state, ownProps) => ({
    paymentSourceList: getPaymentSourceList(state, ownProps.patientId),
  }),
  { getPaymentSources } // these are called actions
);

class PaymentSourceList extends Component {
  static propTypes = {
    patientId: PropTypes.string,
    selectCardForUse: PropTypes.func,
  };

  state = initialState;

  componentDidMount() {
    this.props.getPaymentSources(this.props.patientId);
  }
  componentWillReceiveProps(nextProps) {
    // if nothing is selected. default the payment to the first card available
    if (
      isEmpty(this.props.paymentSourceList) &&
      !isEmpty(nextProps.paymentSourceList)
    ) {
      this.selectCardForUse(nextProps.paymentSourceList[0].id);
    }
  }

  selectCardForUse = paymentSourceId => {
    this.setState({ paymentSourceId, stripeToken: null });
    this.props.selectCardForUse(paymentSourceId);
  };
  render() {
    const { paymentSourceList } = this.props;
    const isNoneSelected = this.state.paymentSourceId === undefined;
    return (
      <div>
        <div style={styles.paymentContainer(isNoneSelected)}>
          <div style={styles.paymentDetails(isNoneSelected)}>
            No payment method
          </div>
          {isNoneSelected && <div style={styles.verified}>✔︎</div>}
          <RaisedButton
            label="Use"
            onClick={partial(this.selectCardForUse, undefined)}
          />
        </div>

        {map(paymentSourceList, paymentSource => {
          const isSelected = paymentSource.id === this.state.paymentSourceId;
          return (
            <div
              style={styles.paymentContainer(isSelected)}
              key={paymentSource.id}
            >
              <div style={styles.paymentDetails(isSelected)}>
                {paymentSource.source_type} - {paymentSource.brand} -{" "}
                {paymentSource.last4}
              </div>
              {isSelected && <div style={styles.verified}>✔︎</div>}
              <RaisedButton
                label="Use"
                onClick={partial(this.selectCardForUse, paymentSource.id)}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

export default connected(PaymentSourceList);
