import React from "react";
import styled, { css } from "react-emotion";
import { PhotoType } from "./types";

type Props = {
  onRenderItem: (typ: PhotoType) => React.ReactNode;
};

const rows: [PhotoType[], PhotoType[], PhotoType[]] = [
  ["Profile", "Profile Smile", "Full Facial", "Full Facial Smile"],
  ["Occlusal Lower", "Intraoral Front Open", "Occlusal Upper"],
  ["Right Buccal", "Intraoral Front Closed", "Left Buccal"],
];

// Template renders a grid for clinical photos, but defers each photo
// component rendering to the parent.
//
// This allows us to use this component for rendering a grid to handle
// uploads as well as to view previously uploaded images.
const Template: React.FC<Props> = props => {
  return (
    <>
      <Four>{rows[0].map(typ => props.onRenderItem(typ))}</Four>

      <Three>{rows[1].map(typ => props.onRenderItem(typ))}</Three>

      <Three>{rows[2].map(typ => props.onRenderItem(typ))}</Three>
    </>
  );
};

export default Template;

const gridCSS = css`
  display: grid;
  grid-template-rows: 150px;
  justify-content: center;
  align-items: stretch;
  grid-gap: 8px;
  margin-bottom: 12px;
`;

const Four = styled.div`
  ${gridCSS}
  grid-template-columns: repeat(4, 1fr);
`;

const Three = styled.div`
  ${gridCSS}
  grid-template-columns: repeat(3, 1fr);
`;
