import React from "react";
import {
  Prewrap,
  SummaryField,
  SummaryFieldLabel,
  SummaryFieldContent,
} from "../styles";
import { getAnswer } from "../util";
import { NoteContentProps } from "./types";

export default function FollowupVisitForm({ submission }: NoteContentProps) {
  const notes = getAnswer("notes", submission);
  return (
    <>
      {notes && (
        <SummaryField key="notes">
          <SummaryFieldLabel>Notes</SummaryFieldLabel>
          <SummaryFieldContent>
            <Prewrap>{notes}</Prewrap>
          </SummaryFieldContent>
        </SummaryField>
      )}
    </>
  );
}
