import cloneDeep from "lodash/cloneDeep";
import indexOf from "lodash/fp/indexOf";
import pipe from "lodash/fp/pipe";
import isEqual from "lodash/fp/isEqual";
import get from "lodash/fp/get";
import uniq from "lodash/fp/uniq";
import isEmpty from "lodash/fp/isEmpty";
import isNil from "lodash/isNil";
import omitBy from "lodash/fp/omitBy";
import isUndefined from "lodash/fp/isUndefined";
import mapValues from "lodash/fp/mapValues";
import merge from "lodash/fp/merge";

import {
  FiltersState,
  Filters,
  ArrayFilters,
  SavedFilter,
  FiltersContextValue,
} from "./types";
import {
  TaskSortField,
  FiltersListItem,
  FiltersListResponse,
} from "../../types";
import {
  defaultFilters,
  UNSAVED_FILTER_TITLE,
  defaultUpdateFiltersRequestValue,
} from "./constants";

export const filtersStateReducer = (state: FiltersState) => {
  const update = (patch: Partial<FiltersState>) => {
    return Object.assign(cloneDeep(state), patch);
  };

  return {
    reset() {
      return {
        filterPanelFilters: null,
        tableHeaderFilters: null,
        hasUnsavedFilter: false,
        selectedFilter: null,
      };
    },
    setFilterPanelFilters(newValue: Filters) {
      return update({
        filterPanelFilters: newValue,
        hasUnsavedFilter: true,
        selectedFilter: null,
      });
    },
    resetFilterPanelFilters() {
      return update({
        filterPanelFilters: null,
        hasUnsavedFilter: false,
      });
    },
    setTableHeaderFilters(newValue: Filters) {
      return update({
        tableHeaderFilters: newValue,
      });
    },
    resetTableHeaderFilters() {
      return update({
        tableHeaderFilters: null,
        selectedFilter: null,
      });
    },
    selectFilter(id: string | null) {
      return update({
        selectedFilter: id,
      });
    },
    resetSelectedFilter() {
      return update({
        selectedFilter: null,
      });
    },
  };
};

export const computeRouteFilters = (
  userID: string,
  filter?: string | undefined
): Filters => {
  const assignees = {
    assigneeIDs: [userID],
    unassigned: false,
  };

  switch (filter) {
    case "my":
    case undefined:
      return {
        assignees,
        status: "unblocked",
        snoozed: false,
      };
    case "authored":
      return { authorID: userID, status: "incomplete" };
    case "snoozed":
      return {
        assignees,
        snoozed: true,
      };
    case "completed":
      return {
        assignees,
        status: "complete",
        sortOpts: [
          { sortField: TaskSortField.CompletedAt, sortDescending: true },
        ],
      };
    default:
      return {};
  }
};

export const mergeFilters = (...sources: (Filters | null)[]) => {
  const target = cloneDeep(defaultFilters);

  sources.forEach(filters => {
    if (!filters) {
      return;
    }

    Object.entries(filters).forEach(([key, value]) => {
      const filterName = key as Keys<Filters>;

      if (!target[filterName]) {
        target[filterName] = value;
      }

      applyMergeRules(target, filterName, value as Defined<Values<Filters>>);
    });
  });

  return clearRequestPayload(target);
};

const applyMergeRules = <K extends keyof Filters>(
  target: Required<Filters>,
  fieldKey: K,
  fieldValue: Defined<Filters[K]>
) => {
  switch (fieldKey) {
    case "sortOpts": {
      const sortOpts = fieldValue as Defined<Filters["sortOpts"]>;

      sortOpts.forEach(item => {
        const targetValueIndex = indexOf(
          pipe(
            get("sortOpts"),
            isEqual(item.sortField)
          ),
          target.sortOpts
        );
        const indexToReplace = Math.max(0, targetValueIndex);

        target.sortOpts[indexToReplace] = item;
      });

      break;
    }
    case "assignees": {
      const assignees = fieldValue as Defined<Filters["assignees"]>;

      target.assignees = {
        assigneeIDs: uniq([
          ...(target.assignees.assigneeIDs || []),
          ...(assignees.assigneeIDs || []),
        ]),
        unassigned: assignees.unassigned,
      };

      break;
    }
    case "categories":
    case "taskTypes":
    case "pipelineTypes":
    case "homeClinicIDs":
    case "treatmentLevels":
    case "patientStatusNames":
    case "taskCustomLabels": {
      const key = fieldKey as keyof ArrayFilters;
      const targetValue = target[key];

      target[key] = uniq([
        ...targetValue,
        ...(fieldValue as typeof targetValue),
      ]);

      break;
    }
    default: {
      // this is done to please TypeScript
      const key = fieldKey;
      const value = target[key];

      target[key] = fieldValue as typeof value;
    }
  }
};

export const prepareFiltersResponse = (
  data?: FiltersListResponse
): FiltersContextValue["allFilters"] => {
  if (!data || isEmpty(data.taskFilterSettings)) {
    return {
      accepted: [],
      waiting: [],
    };
  }

  return data.taskFilterSettings.reduce<FiltersContextValue["allFilters"]>(
    (acc, filtersItem) => {
      const preparedItem = prepareFiltersResponseItem(filtersItem);

      if (preparedItem.isWaitingApproval) {
        acc.waiting.push(preparedItem);
      } else {
        acc.accepted.push(preparedItem);
      }

      return acc;
    },
    {
      accepted: [],
      waiting: [],
    }
  );
};

export const prepareFiltersResponseItem = (
  item: FiltersListItem
): SavedFilter => {
  const result: Filters = omitBy(isNil, {
    homeClinicIDs: item.patientHomeClinicIDs,
    treatmentLevels: item.treatmentTypes,
    pipelineTypes: item.pipelineTypes,
    categories: item.taskTypeCategories,
    taskCustomLabels: item.taskCustomLabels,
    assignees: item.taskAssignees,
    taskTypes: item.taskTypeNames,
  });

  return {
    id: item.id,
    title: item.customName,
    // TODO compute this value from backend data
    isWaitingApproval: false,
    // TODO get this value from backend (for now backend response doesn't have this data)
    createdAt: new Date().toISOString(),
    filters: result,
  };
};

export const getFilter = (data: {
  filterId: string | null;
  state: FiltersState;
  allFilters: FiltersContextValue["allFilters"];
}) => {
  const { filterId, state, allFilters } = data;
  const { filterPanelFilters } = state;
  const { accepted } = allFilters;

  if (isNil(filterId)) {
    return buildUnsavedFilter(filterPanelFilters);
  }

  const result = accepted.find(filter => filter.id === filterId);

  return result ? result : buildUnsavedFilter(filterPanelFilters);
};

const buildUnsavedFilter = (filterSettings: Filters | null) => {
  return {
    id: null,
    title: UNSAVED_FILTER_TITLE,
    isWaitingApproval: false,
    createdAt: new Date().toISOString(),
    filters: filterSettings || {},
  };
};

export const getSelectedFilter = (
  state: FiltersState,
  allFilters: FiltersContextValue["allFilters"]
) => {
  const { selectedFilter } = state;

  return getFilter({
    filterId: selectedFilter,
    state,
    allFilters,
  });
};

export const mapFiltersValueToCreateRequest = (filters: Filters) => {
  return clearRequestPayload({
    taskTypeCategories: filters.categories,
    taskAssignees: filters.assignees,
    taskSnoozed: filters.snoozed,
    taskTypeNames: filters.taskTypes,
    taskCustomLabels: filters.taskCustomLabels,
    pipelineTypes: filters.pipelineTypes,
    patientHomeClinicIDs: filters.homeClinicIDs,
    patientStatusNames: filters.patientStatusNames,
    treatmentTypes: filters.treatmentLevels,
    sortOpts: filters.sortOpts,
  });
};

export const mapFiltersValueToUpdateRequest = (filters: Filters) => {
  const clearedValues = mapFiltersValueToCreateRequest(filters);
  const requestData = mapValues(item => {
    return {
      clearCurrentValue: false,
      newValue: item,
    };
  }, clearedValues);

  // sortOpts field has special format
  if (!isNil(requestData.sortOpts)) {
    requestData.newSortOpts = requestData.newValue;
    delete requestData.newValue;
  }

  return merge(defaultUpdateFiltersRequestValue, requestData);
};

const clearRequestPayload = pipe(
  omitBy(isUndefined),
  omitBy(isEmpty)
);
