import React from "react";
import styled from "react-emotion";
// shared
import LegacyModal from "src/shared/LegacyModal";
import Button from "src/shared/Button";
// styles
import textStyles from "src/styles/textStyles";
import color from "src/styles/color";

const Title = styled.div`
  ${textStyles("xlarge")};
  font-weight: bold;
`;

const Perimeter = styled.div`
  display: flex;
  flex-direction: column;
  justify-contenet: space-between;
`;

const Underline = styled.span`
  text-decoration: underline;
  color: ${color.primary};
`;

const BoldText = styled.div`
  ${textStyles("large")};
  font-weight: bold;
`;

const OrangeText = styled.div`
  color: ${color.primary};
  ${textStyles("xlarge")};
  font-weight: bold;
  margin: 16px 0;
`;

const Text = styled.div`
  ${textStyles("large")};
`;

const UniformTeethDifferencesModal = props => {
  return (
    <LegacyModal isOpen={true} closeModal={props.handleClose}>
      <LegacyModal.Title>
        <Title>Uniform Teeth's Differences</Title>
      </LegacyModal.Title>
      <LegacyModal.Content>
        <Perimeter>
          <div style={{ marginBottom: "40px" }}>
            <BoldText>
              Here are some important differences to go over with the patient:
            </BoldText>
            <OrangeText>
              We are{" "}
              <Underline>obsessed about the science & art of smiles</Underline>.
            </OrangeText>
            <Text>
              Our orthodontists are sourced from top 10 dental schools and they
              will oversee your entire treatment.
            </Text>
            <OrangeText>
              We are <Underline>precise, affordable, and fast</Underline>.
            </OrangeText>
            <Text>
              Our treatment times are normally between 6-10 months, compared to
              12+ month industry average.
            </Text>
            <BoldText style={{ marginTop: "40px" }}>
              Communicate and explain the following to the patient:
            </BoldText>
            <OrangeText>Attachments</OrangeText>
            <Text>
              Small molding on teeth to keep aligner in place and help rotate
              teeth faster.
            </Text>
            <OrangeText>IPR</OrangeText>
            <Text>
              Filing between teeth to create spaces to move teeth faster.
            </Text>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              kind="primary"
              onClick={props.handleDone}
              style={{ width: "100px", height: "36px" }}
            >
              Done
            </Button>
          </div>
        </Perimeter>
      </LegacyModal.Content>
    </LegacyModal>
  );
};

export default UniformTeethDifferencesModal;
