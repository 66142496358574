import React from "react";
import styled from "react-emotion";
import Popover from "src/shared/Popover";
import SelectList from "src/shared/SelectList";
import { cx } from "emotion";
import { ToothNumber, UIAttribute, State } from "./types";
import { useToothAttributes, getMesialNumber, getDistalNumber } from "./consts";
import { useStateContext } from "./state";

type Props = {
  // whether this el is before or after the circle elements.
  position: "before" | "after";
  surface: "mesial" | "distal";
  toothNumber: ToothNumber;
  restrictToDisplay?: boolean;
  editable: boolean;
};

const IPR: React.FC<Props> = ({
  toothNumber,
  restrictToDisplay,
  surface,
  position,
  editable,
}) => {
  const [state, dispatch] = useStateContext();

  const ipr = useAggregateIPR(state, toothNumber, !!restrictToDisplay);
  const { distalIPR, mesialIPR, neighbors } = ipr;

  if (surface === "mesial" && mesialIPR.length === 0) {
    return null;
  }

  if (surface === "distal" && distalIPR.length === 0) {
    return null;
  }

  const onChange = (surface: "mesial" | "distal", to: string) => {
    if (!editable) {
      return;
    }

    switch (to) {
      case "Remove": {
        dispatch({
          toothNumber,
          type: "removeAttribute",
          attribute: "ipr",
          location: surface,
        });

        if ([8, 9, 24, 25].includes(toothNumber) && surface === "mesial") {
          dispatch({
            toothNumber: getMesialNumber(toothNumber),
            type: "removeAttribute",
            attribute: "ipr",
            location: "mesial",
          });
          return;
        }

        // remove opposite
        dispatch({
          toothNumber:
            surface === "mesial"
              ? getMesialNumber(toothNumber)
              : getDistalNumber(toothNumber),
          type: "removeAttribute",
          attribute: "ipr",
          location: surface === "mesial" ? "distal" : "mesial",
        });
        return;
      }

      case "Left only": {
        // for teeth 8, 9, 24, 25 - explicitly adds IPR to 8/25.
        if ([8, 25].includes(toothNumber)) {
          // Remove from this tooth and add to opposite.
          dispatch({
            toothNumber,
            type: "removeAttribute",
            attribute: "ipr",
            location: "mesial",
          });
        }
        if ([8, 25].includes(toothNumber) === false) {
          // Keep on this tooth and remove from the oppposite.
          dispatch({
            toothNumber: neighbors.mesial.toothNumber,
            type: "removeAttribute",
            attribute: "ipr",
            location: "mesial",
          });
        }
        return;
      }

      case "Right only": {
        if ([8, 25].includes(toothNumber)) {
          dispatch({
            toothNumber: neighbors.mesial.toothNumber,
            type: "removeAttribute",
            attribute: "ipr",
            location: "mesial",
          });
        }
        if ([8, 25].includes(toothNumber) === false) {
          dispatch({
            toothNumber,
            type: "removeAttribute",
            attribute: "ipr",
            location: "mesial",
          });
        }
        return;
      }

      case "Distal only": {
        if (surface === "mesial") {
          // Simple - this is the mesial surface, so remove iot.
          dispatch({
            toothNumber,
            type: "removeAttribute",
            attribute: "ipr",
            location: "mesial",
          });
          // If this is tooth 8, 9, 24, or 25... then the mesial is also
          // on the other surface, as the mesials point together and face each other,
          // but only on these teeth.
          if ([8, 9, 24, 25].includes(toothNumber)) {
            dispatch({
              toothNumber: neighbors.mesial.toothNumber,
              type: "removeAttribute",
              attribute: "ipr",
              location: "mesial",
            });
          }
          return;
        }

        // This isn't the mesial - which we need to remove, so it must be the distal.
        // Remove the distal from the other side.
        dispatch({
          toothNumber: neighbors.distal.toothNumber,
          type: "removeAttribute",
          attribute: "ipr",
          location: "mesial",
        });
        return;
      }

      case "Mesial only": {
        if (surface === "distal") {
          // Simple - this is the distal surface, so remove iot.
          dispatch({
            toothNumber,
            type: "removeAttribute",
            attribute: "ipr",
            location: "distal",
          });
          return;
        }
        dispatch({
          toothNumber: neighbors.mesial.toothNumber,
          type: "removeAttribute",
          attribute: "ipr",
          location: "distal",
        });
      }
    }
  };

  // The mesial surfaces of the 8<>9 and 24<>25 face each other, so we check whether the
  // tooth number is one of these and the neighboring mesial has a value, OR that the distal
  // has a value in every other case, as normally mesial faces a distal.
  if (
    ([8, 9, 24, 25].includes(toothNumber) &&
      surface === "mesial" &&
      neighbors.mesial.mesialIPR.length > 0) ||
    (![8, 9, 24, 25].includes(toothNumber) &&
      surface === "mesial" &&
      neighbors.mesial.distalIPR.length > 0)
  ) {
    // Merge two together
    const amounts = [8, 9, 24, 25].includes(toothNumber)
      ? mesialIPR.concat(neighbors.mesial.mesialIPR)
      : mesialIPR.concat(neighbors.mesial.distalIPR);
    return (
      <Display
        toothNumber={toothNumber}
        type="full"
        surface={surface}
        position={position}
        attributes={amounts}
        onChange={onChange}
        editable={editable}
      />
    );
  }

  if (surface === "distal" && neighbors.distal.mesialIPR.length > 0) {
    // Merge two together
    const amounts = distalIPR.concat(neighbors.distal.mesialIPR);

    return (
      <Display
        toothNumber={toothNumber}
        type="full"
        surface={surface}
        position={position}
        attributes={amounts}
        onChange={onChange}
        editable={editable}
      />
    );
  }

  return (
    <Display
      toothNumber={toothNumber}
      type="half"
      surface={surface}
      position={position}
      attributes={surface === "mesial" ? mesialIPR : distalIPR}
      onChange={onChange}
      editable={editable}
    />
  );
};

export default IPR;

type DisplayProps = {
  type: "full" | "half";
  surface: "mesial" | "distal";
  // whether this el is before or after the circle elements.
  position: "before" | "after";
  toothNumber: number;
  attributes?: UIAttribute[];
  editable: boolean;

  onChange: (surface: "mesial" | "distal", selection: string) => void;
};

// Half flag, for a mesial or distal surface for a single tooth only
const Display: React.FC<DisplayProps> = props => {
  if (!props.attributes || props.attributes.length === 0) {
    return null;
  }

  let amount = 0;
  props.attributes.forEach(a => {
    if (!a.attribute || !a.attribute.amount) {
      return;
    }
    amount += a.attribute.amount;
  });

  const classes = cx([
    props.position === "before" &&
      (props.type === "full" ? beforeFullCSS : beforeCSS),
    props.position === "after" &&
      (props.type === "full" ? afterFullCSS : afterCSS),
  ]);

  const values = (() => {
    if (
      props.surface === "mesial" &&
      [8, 9, 24, 25].includes(props.toothNumber)
    ) {
      // can't select mesial/distal only IPR on middle teeth, as both surfaces are mesial
      // XXX: Left or right only?
      return ["Left only", "Right only", "Remove"];
    }
    return ["Mesial only", "Distal only", "Remove"];
  })();

  if (props.type === "full" && props.editable) {
    const popoverContent = (
      <SelectList<string>
        values={values}
        value={[]}
        onClick={(p?: string) => p && props.onChange(props.surface, p)}
        render={(p?: string) => <span key={p}>{p}</span>}
      />
    );
    return (
      <PopoverWrapper css={classes}>
        <Popover position="bottom" align="center" content={popoverContent}>
          <FullWrapper>
            <span>{amount.toFixed(2).substr(1)}</span>
          </FullWrapper>
        </Popover>
      </PopoverWrapper>
    );
  }
  if (props.type === "full" && !props.editable) {
    return (
      <PopoverWrapper css={classes}>
        <FullWrapper>
          <span>{amount.toFixed(2).substr(1)}</span>
        </FullWrapper>
      </PopoverWrapper>
    );
  }

  return (
    <HalfWrapper css={classes}>
      <span>{amount.toFixed(2).substr(1)}</span>
    </HalfWrapper>
  );
};

const useAggregateIPR = (s: State, t: ToothNumber, restrict: boolean) => {
  const ipr = useIPR(s, t, restrict);

  // On the mesial neighbour, we want the distal (the surface matching the current tooth).
  const mesial = useIPR(s, getMesialNumber(t), restrict);
  const distal = useIPR(s, getDistalNumber(t), restrict);

  return {
    ...ipr,
    neighbors: {
      // mesial neighboring tooth
      mesial,
      // distal neighboring tooth
      distal,
    },
  };
};

const useIPR = (s: State, toothNumber: ToothNumber, restrict: boolean) => {
  const { uiAttributes } = useToothAttributes(s, toothNumber, restrict);
  const distalIPR = uiAttributes.filter(
    a => a.type === "ipr" && a.attribute && a.attribute.location === "distal"
  );
  const mesialIPR = uiAttributes.filter(
    a => a.type === "ipr" && a.attribute && a.attribute.location === "mesial"
  );
  return { toothNumber, distalIPR, mesialIPR };
};

const HalfWrapper = styled.div`
  position: absolute;
  z-index: 1;
  height: 30px;
  overflow: hidden;
  bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  letter-spacing: -1px;
  line-height: 1;

  cursor: pointer;

  &:hover:before {
    background: #f1f4f5;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    content: "";
    display: block;
    height: 30px;
    width: 30px;
    border: 1px solid #d7d7d799;
    transform: rotate(45deg);
    background: #ebebeb;
  }

  span {
    position: relative;
    z-index: 1;
  }
  width: 15px;
`;

const PopoverWrapper = styled.div`
  position: absolute;
  bottom: 75px;
  height: 24px;
  width: 24px;
`;

const FullWrapper = styled.div`
  z-index: 1;
  height: 24px;
  width: 24px;
  transform: rotate(45deg);

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  letter-spacing: -1px;
  line-height: 1;
  cursor: pointer;

  border: 1px solid #d7d7d799;
  background: #ebebeb;

  &:hover:before {
    background: #f1f4f5;
  }

  span {
    transform: rotate(-45deg);
  }
`;

const beforeCSS = `
  left: 0;
  border-left: 1px solid #d7d7d799;
  cursor: pointer;

  &:before {
    right: 5px;
  }
`;

const afterCSS = `
  right: 0;

  &:before {
    left: 5px;
  }
`;

const beforeFullCSS = `
  left: -12px;
`;

const afterFullCSS = `
  right: -12px;
`;
