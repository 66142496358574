import React from "react";
import styled from "react-emotion";
import Row from "./TrayLogRow";
import color from "src/styles/color";
import { TrayLog, ArchType } from "src/types/gql";
import { Treatment } from "src/scenes/TrayLogs/query";

type Props = {
  trayLogs: TrayLog[];
  timelineArch: ArchType;
  userID: string;
  treatment: Treatment;
};

const TrayLogRows: React.FC<Props> = props => {
  return (
    <Wrapper>
      {props.trayLogs.map(t => {
        const {
          id,
          stage,
          startTime,
          endTime,
          plannedEndTime,
          trayBuild,
          archType,
        } = t;
        return (
          <Row
            key={id}
            userID={props.userID}
            treatment={props.treatment}
            stage={stage}
            nextStage={t.nextStage}
            startTime={startTime}
            endTime={endTime}
            plannedEndTime={plannedEndTime}
            serialNumber={trayBuild ? trayBuild.serialNumber : "Unknown"}
            status={endTime ? "Complete" : "In progress"}
            archType={archType}
            timelineArch={props.timelineArch}
            trayLogID={id}
            photos={t.photos}
          />
        );
      })}
    </Wrapper>
  );
};

export default TrayLogRows;

const Wrapper = styled.div`
  transition: all 0.3s;
  overflow: hidden;
  height: auto;
  position: relative;
  border-top: 1px solid ${color.border};
  border-bottom: 1px solid ${color.border};

  padding: 10px 0 10px 60px;
  background: #fafafa;
`;
