import { map } from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./consent-style";
import moment from "moment";
export default class Consent extends Component {
  static propTypes = {
    consent: PropTypes.object,
  };

  renderConsent = (value, key) => {
    if (key === "id") return null; // dont show the id
    return (
      <div key={key}>
        <label style={styles.S.label}>{key}</label>
        <div>{(value && moment(value).format("MM-DD-YY, hh:mma")) || "-"}</div>
      </div>
    );
  };

  render() {
    const { consent } = this.props;
    return (
      <div style={styles.S.contentBlock}>
        <div style={styles.S.sectionTitle}>
          <span style={styles.title}>Consent</span>
        </div>
        <div>{map(consent, this.renderConsent)}</div>
      </div>
    );
  }
}
