import React from "react";
import styled from "react-emotion";
import { Clinic } from "src/types/gql";
import DatePicker from "src/shared/DatePicker";
import Dropdown from "src/shared/Dropdown";
import { Selected, SelectedObj } from "src/types/types";

import textStyles from "src/styles/textStyles";
import ClinicPicker from "./ClinicPicker";

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Label = styled.div`
  ${textStyles("normal")};
  font-weight: bold;
  margin-right: 16px;
`;

interface Props {
  clinicOptions: Array<Clinic>;
  selectedClinicId: string | null;
  onChangeClinic: (id: string) => void;

  selectedDate: string;
  onChangeDate: (date: string) => void;
  timezone: string;

  doctorOptions: Array<SelectedObj>;
  selectedDoctor: SelectedObj;
  onChangeDoctor: (selection: Selected) => void;

  appointmentTypeOptions: Array<SelectedObj>;
  selectedAppointmentTypeValue: string;
  onChangeAppointmentType: (selection: Selected) => void;
}

export default function Filters(props: Props) {
  const { clinicOptions, selectedClinicId, onChangeClinic } = props;
  const { selectedDate, onChangeDate, timezone } = props;
  const { doctorOptions, selectedDoctor, onChangeDoctor } = props;
  const {
    appointmentTypeOptions,
    selectedAppointmentTypeValue,
    onChangeAppointmentType,
  } = props;
  return (
    <Row>
      <Field>
        <Label>Clinic:</Label>
        <div css="max-width: 200px">
          <ClinicPicker
            clinics={clinicOptions}
            selected={selectedClinicId}
            onChange={onChangeClinic}
          />
        </div>
      </Field>

      <Field>
        <Label>Date:</Label>
        <div css="max-width: 200px">
          <DatePicker
            date={selectedDate}
            onChange={onChangeDate}
            timezone={timezone}
          />
        </div>
      </Field>

      <Field>
        <Label>Doctor:</Label>
        <div css="max-width: 200px">
          <Dropdown
            options={doctorOptions}
            selected={selectedDoctor}
            onSelect={onChangeDoctor}
          />
        </div>
      </Field>

      <Field>
        <Label>Appointment Type:</Label>
        <div css="max-width: 200px">
          <Dropdown
            options={appointmentTypeOptions}
            selected={selectedAppointmentTypeValue}
            onSelect={onChangeAppointmentType}
          />
        </div>
      </Field>
    </Row>
  );
}
