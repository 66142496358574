import React from "react";
import styled from "react-emotion";
import { useStateContext } from "./state";
import { useUIAttributes } from "./consts";
import { getLegendItems, LegendItemProps } from "./legendUtil";

type Props = {};

const Legend: React.FC<Props> = () => {
  const [state] = useStateContext();
  const { uiAttributes } = useUIAttributes(state);
  const items = getLegendItems(
    state.display,
    state.cumulativeCutoff || null,
    uiAttributes
  );
  return (
    <Wrapper>
      {items.map(a => (
        <LegendItem key={a.name} {...a} />
      ))}
    </Wrapper>
  );
};

export const LegendItem: React.FC<LegendItemProps> = ({ color, name }) => {
  return (
    <div>
      <Color style={{ background: color }} />
      {name}
    </div>
  );
};

export default Legend;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 8px 8px 24px;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px 10px;
    font-size: 10px;
  }
`;

const Color = styled.div`
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 2px;
  margin-right: 8px;
`;
