import styled, { css } from "react-emotion";
import { Dropdown as BaseDropdown } from "antd";
import color from "src/styles/color";

export const StyledBaseDropdown = styled(BaseDropdown)`
  cursor: pointer;
  width: 100%;
  border: 1px solid ${color.border};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;

  ${({ theme: { disabled } }) =>
    css`
      background: ${disabled ? color.gray1 : color.white};
    `}
  transition: all 0.25s;
  ${({ theme: { height } }) =>
    height &&
    css`
      height: ${height};
    `}

  ${({ theme: { width } }) =>
    width &&
    css`
      width: ${width};
    `}

&:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  }

  &:disabled {
    border-color: ${color.gray1};
    cursor: not-allowed;
  }
`;

export const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;

  ${({ theme: { disabled } }) =>
    disabled &&
    css`
      color: ${color.gray3} !important;
      cursor: not-allowed !important;
    `}
`;

const MenuGroupHeaderBase = `
  padding: 8px 12px;
`;
export const MenuGroupHeader = styled.div`
  ${MenuGroupHeaderBase}
  color: ${color.orange};
`;

export const MenuGroupHeaderArchived = styled.div`
  ${MenuGroupHeaderBase}
  color: ${color.gray3};
`;

export const Label = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Placeholder = styled.span`
  color: ${color.gray3};
`;
