import React, { useState } from "react";
import styled from "react-emotion";
import { message } from "antd";
import useSelf from "src/state/self";
import Modal from "src/shared/Modal";
import Button from "src/shared/Button";
import InternalNotes from "src/shared/InternalNotes";
import Add from "src/shared/Icons/Add";
import color from "src/styles/color";
// local
import { useTask, useUpdateTasks } from "../queries";
import Header from "./Header";
import Details from "./Details";
import References from "./References/References";
import CustomContent from "./CustomContent/CustomContent";
import { TaskOption, Pipeline } from "../types";

type Props = {
  id: string;

  // If opened within the context of a pipeline, this refers to the current pipeline.
  // This allows us to add/remove tasks within the pipeline context.
  pipeline?: Pipeline;

  onClose: () => void;
};

const TaskModal: React.FC<Props> = ({ id, pipeline, onClose }) => {
  const self = useSelf();

  const [content, setContent] = useState<string | null>(null);
  const [showNewNote, setShowNewNote] = useState(false);
  const [{ fetching, error, data }] = useTask(id);
  const update = useUpdateTasks();

  if (!data || fetching || error) {
    return null;
  }

  const { task } = data;

  const onChange = async (opt: TaskOption, deleteFields?: string[]) => {
    const result = await update({
      input: [{ id, opt }],
      deleteFields: deleteFields || [],
    });
    if (result.error) {
      message.error(`Error updating task: ${result.error}`);
      return result;
    }
    message.success(`Task updated`);
    return result;
  };

  // We explicitly get the pipelineID since depending on the view, we may be
  // viewing the pipeline from the list view or within a task
  // single tasks are both pipelines and tasks which requires this nuance
  const pipelineID = pipeline ? pipeline.id : task.pipeline && task.pipeline.id;

  return (
    <Modal onClose={onClose} style={{ minWidth: content ? "auto" : "80vw" }}>
      <Wrapper>
        <Header
          task={task}
          pipelineID={pipelineID}
          onChange={onChange}
          onClose={onClose}
          showSnooze={!!task.assignedTo.find(a => a.id === self.id)}
        />

        {content ? (
          <CustomContent
            task={task}
            content={content}
            onChangeContent={setContent}
            pipelineID={pipelineID as string}
          />
        ) : (
          <>
            <Details task={task} onChange={onChange} />

            <Section title="Related to">
              <References task={task} onChangeContent={setContent} />
            </Section>

            <Section title="Comments">
              <InternalNotes
                objectKind="PipelineV2"
                objectID={pipelineID ? pipelineID : id}
                hideNewNote={!showNewNote}
                onCreate={() => setShowNewNote(false)}
                chronological
                max={3}
              />
              {!showNewNote && (
                <Button kind="link-blue" onClick={() => setShowNewNote(true)}>
                  <Add fill={color.gray3} size={12} /> Add comment
                </Button>
              )}
            </Section>
          </>
        )}
      </Wrapper>
    </Modal>
  );
};

export default TaskModal;

const Wrapper = styled.div`
  min-width: 800px;
  min-height: 600px;
  max-height: 90vh;
  max-width: 80vw;
  overflow: scroll;

  > div {
    padding: 24px 32px;
  }
`;

const Section: React.SFC<{ title: string }> = ({ title, children }) => (
  <div>
    <SectionTitle>{title}</SectionTitle>
    {children}
  </div>
);

const SectionTitle = styled.div`
  margin: 0 0 12px;
  padding: 6px 0;
  font-weight: 600;
  color: ${color.gray3};
  border-bottom: 1px solid ${color.borderLight};
`;
