import React, { useState } from "react";
import styled, { css } from "react-emotion";

import { useStaff } from "src/state/useStaff";
import color from "src/styles/color";
import InputText from "src/shared/InputText";
import SelectList from "src/shared/SelectList";
import { Staff } from "src/types/gql";

type Props = {
  value?: Staff[];
  onSelect: (s: Staff) => void;
};

const StaffSearch: React.FC<Props> = ({ value, onSelect }) => {
  const [search, setSearch] = useState<string>("");
  const [{ data }] = useStaff(["id", "firstName", "lastName", "email"]);

  const filtered = data.filter(
    s => s.name.toLowerCase().indexOf(search.toLowerCase()) > -1
  ) as Staff[];

  return (
    <Wrapper>
      <Search>
        <InputText
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder="Search for a staff member..."
        />
      </Search>
      <Results>
        <SelectList
          values={filtered}
          value={value}
          onClick={s => {
            s && onSelect(s);
          }}
          render={s => <span>{s.name}</span>}
          allowNone={false}
          className={resultCSS}
        />
      </Results>
    </Wrapper>
  );
};

export default StaffSearch;

const Wrapper = styled.div`
  width: 280px;
  input {
    border: 1px solid ${color.border};
  }
`;

const Search = styled.div`
  padding: 12px;
`;

const Results = styled.div`
  border-top: 1px solid ${color.border};
`;

const resultCSS = css`
  max-height: 13rem;
`;
