import { map } from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./event-style";

import SMSIcon from "material-ui/svg-icons/communication/textsms";
import EmailIcon from "material-ui/svg-icons/communication/email";
const SMS = "sms";
const EMAIL = "email";
export default class Comment extends Component {
  static propTypes = {
    event: PropTypes.object,
  };

  render() {
    const { event } = this.props;
    return (
      <div style={{ whiteSpace: "pre-line" }}>
        {event.content || event.commentType}
        {event.commentType === SMS && (
          <SMSIcon
            style={styles.comment.sms}
            color={styles.V.colors.fontFaded}
          />
        )}
        {event.commentType === EMAIL && (
          <EmailIcon
            style={styles.comment.email}
            color={styles.V.colors.fontFaded}
          />
        )}
        {event.links && (
          <div>
            {map(event.links, link => {
              return (
                <div key={link.text} style={styles.appLink}>
                  {link.text}
                </div>
              );
            })}
          </div>
        )}
        {event.attachments && (
          <div>
            {map(event.attachments, (attachment, i) => {
              return (
                <a
                  key={attachment.url}
                  href={attachment.url}
                  style={styles.attachment}
                >
                  Attachment {i + 1}
                </a>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}
