import React, { useState } from "react";
import styled, { css } from "react-emotion";
import color from "src/styles/color";
import { formatDecimal } from "./TeethMovementsModal";

export const Input = styled.input`
  display: flex;
  border: 1px transparent solid;
  border-radius: 2px;
  background-color: transparent;
  text-align: center;

  ${({ theme: { error } }) =>
    error &&
    css`
      border-color: ${color.red};
      &:focus,
      &:active,
      &:hover {
        border: 1px ${color.red} solid;
        outline: 0;
      }
    `}
  ${({ theme: { error } }) =>
    !error &&
    css`
      &:focus,
      &:active,
      &:hover {
        border: 1px ${color.blue} solid;
        outline: 0;
      }
    `}
`;

const EditableValue = React.forwardRef((props: any, ref) => {
  const { className, value, onChange, ...rest } = props;
  const [error, setError] = useState(false);
  const [textValue, setTextValue] = useState(formatDecimal(value));

  return (
    <Input
      {...rest}
      type="text"
      theme={{ error }}
      ref={ref}
      value={textValue}
      onChange={evt => {
        setTextValue(evt.target.value.replace(/[a-zA-Z]/gi, ""));
      }}
      onKeyDown={evt => {
        if (evt.key === "Enter") {
          let floatVal = parseFloat(textValue);
          console.log("floatVal", floatVal, ", textValue:", textValue);
          if (isNaN(floatVal)) {
            setError(true);
            return;
          }
          setError(false);
          onChange(floatVal);
        }
      }}
    />
  );
});

export default EditableValue;
