import React, { useState } from "react";
import { Icon, Dropdown, Menu, notification } from "antd";
import styled, { css } from "react-emotion";

import { useQuery } from "src/utils/http/gqlQuery";
import Button, { ButtonRow } from "src/shared/Button";
import color from "src/styles/color";
import DeleteModal from "./DeleteModal";
import NewEligibilityModal from "./NewEligibilityModal";
import PolicyForm, { isPublicProvider } from "./PolicyForm";
// types
import { InsurancePolicy, Patient } from "src/types/gql";
import { useCheck, eligibilityHistoryGql } from "./query";
import { getErrorMessage } from "src/utils/error";

type Props = {
  patient: Patient;
  policies: InsurancePolicy[];
  // Used to display the "add new" modal in the parent
  setShowNew: (bool) => void;
};

export const List: React.FC<Props> = props => {
  return (
    <div>
      {props.policies.map(p => (
        <Item policy={p} key={p.id} patient={props.patient} />
      ))}

      <ButtonRow
        position="center"
        className={css`
          margin: 0 0 16px;
        `}
      >
        <Button type="link" onClick={() => props.setShowNew(true)}>
          Add new policy
        </Button>
      </ButtonRow>
    </div>
  );
};

// ItemProps shows a specific policy
type ItemProps = {
  policy: InsurancePolicy;
  patient: Patient;
};

type ModalType = "add" | "edit" | "delete";

const Item: React.FC<ItemProps> = ({ policy, patient }) => {
  const [checking, setChecking] = useState(false);
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const check = useCheck();
  const [, refetchEligibilities] = useQuery({
    query: eligibilityHistoryGql,
    variables: { userID: patient.id },
    pause: true,
  });

  const closeModal = () => setModalType(null);
  const onClick = async ({ key }: { key: string }) => {
    switch (key as ModalType | "check") {
      case "check":
        if (checking === false) {
          setChecking(true);
          const resp = await check({ id: policy.id });
          if (resp.error) {
            setChecking(false);
            notification.error({
              message: getErrorMessage(resp.error.toString()),
            });
            return;
          }

          if (
            Array.isArray(resp.data.checkInsurancePolicy) &&
            resp.data.checkInsurancePolicy.length === 0
          ) {
            notification.warning({
              message: "No eligibility found",
              description:
                "The insurance company returned no eligibility information for this policy",
            });
            return;
          }

          notification.success({
            message: "Checked eligibility",
          });

          refetchEligibilities();
          setChecking(false);
        }
        break;
      default:
        setModalType(key as ModalType);
    }
  };

  const menu = (
    <Menu onClick={onClick}>
      {policy.InsuranceProvider.gateway === "onederful" && (
        <Menu.Item disabled={true} key="check">
          {checking ? "Checking..." : "Check eligibility"}
        </Menu.Item>
      )}
      <Menu.Item key="add">Manually add eligibility</Menu.Item>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="delete">Delete</Menu.Item>
    </Menu>
  );

  const modal = (() => {
    switch (modalType) {
      case "delete":
        return <DeleteModal policy={policy} onClose={closeModal} />;
      case "add":
        return (
          <NewEligibilityModal
            policy={policy}
            patient={patient}
            onClose={closeModal}
          />
        );
      case "edit":
        return (
          <PolicyForm
            patient={patient}
            current={Object.assign({}, policy, {
              userId: patient.id,
              insuranceProviderId: policy.InsuranceProvider.id,
            })}
            onClose={closeModal}
          />
        );
      default:
        return null;
    }
  })();

  return (
    <Wrapper>
      {modal}
      <div>
        <p>
          <b>
            {!isPublicProvider(policy.InsuranceProvider) && "[OLD]"}{" "}
            {policy.InsuranceProvider.name}
          </b>
        </p>

        <Members>
          <p>
            <b>Subscriber</b>: {policy.subscriberFirstName}{" "}
            {policy.subscriberLastName} ({policy.subscriberDOB}), ID:{" "}
            <code>{policy.subscriberMemberId}</code>
          </p>
          {policy.dependentFirstName && (
            <p>
              <b>Dependent</b>: {policy.dependentFirstName}{" "}
              {policy.dependentLastName} ({policy.dependentDOB}), ID:{" "}
              <code>{policy.dependentMemberId}</code>
            </p>
          )}
        </Members>
      </div>

      <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
        <Icon type="more" />
      </Dropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border: 1px solid ${color.border};
  border-radius: 3px;
  margin: 16px 30px;
  padding: 18px;
  & + & {
    margin-top: 12px;
  }

  display: grid;
  grid-template-columns: auto 40px;
  grid-gap: 20px;
  align-items: center;
  height: 100px;
`;

const Members = styled.div`
  color: ${color.gray3};
`;

export default List;
