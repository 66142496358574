import React, { useState } from "react";
import styled, { css } from "react-emotion";
import { notification, Input } from "antd";

import money from "src/utils/money";
import color from "src/styles/color";
import MoneyInput from "src/shared/MoneyInput";
import Box from "src/shared/Box";
import Button, { ButtonRow } from "src/shared/Button";
import Modal from "src/shared/Modal";
import { useApplyOrderAdjustment } from "../query";
import { Order } from "src/types/gql";

const Content = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;

  height: 450px;
  width: 600px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  padding: 24px;
  overflow-y: scroll;
`;

const TotalsContainer = styled.div`
  display: flex;
  flex-flow: row;
  border-bottom: 1px solid ${color.border};
`;

const TotalBox = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 24px 75px;
  flex: 1;

  & + & {
    border-left: 1px solid ${color.border};
  }
`;

const TotalLabel = styled.div`
  color: ${color.gray3};
  margin-bottom: 16px;
`;

const OrderTotal = styled.div`
  font-size: 32px;
`;

const NewOrderTotal = styled.div`
  color: ${color.orange};
  font-size: 32px;
`;

const InputLabel = styled.div`
  font-weight: bold;
  margin-bottom: 16px;
`;

const moneyInputStyles = css`
  margin-bottom: 24px;
`;

const Footer = styled.div`
  padding: 24px;
`;

type Props = {
  onClose: () => void;
  order: Order;
};

const MarkdownModal: React.FC<Props> = ({ order, onClose }) => {
  const execute = useApplyOrderAdjustment();
  const [amount, setAmount] = useState(0);
  const [reason, setReason] = useState("");

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const res = await execute({
      input: {
        orderID: order.id,
        amount,
        reason,
      },
    });

    if (res.error) {
      notification.error({
        message: "There was an error adjusting the order total.",
      });
    } else {
      notification.success({ message: "Order total adjusted!" });
      onClose();
    }
  };

  return (
    <Modal onClose={onClose}>
      <Box title="Markdown Amount">
        <Content>
          <TotalsContainer>
            <TotalBox>
              <TotalLabel>Current order total</TotalLabel>
              <OrderTotal>{money(order.total)}</OrderTotal>
            </TotalBox>
            <TotalBox>
              <TotalLabel>New order total</TotalLabel>
              <NewOrderTotal>{money(order.total - amount)}</NewOrderTotal>
            </TotalBox>
          </TotalsContainer>
          <InputContainer>
            <InputLabel>Amount to markdown from order total (USD)</InputLabel>
            <MoneyInput
              className={moneyInputStyles}
              value={amount}
              onChange={setAmount}
              max={order.total}
            />
            <InputLabel>State reason for the markdown (REQUIRED)</InputLabel>
            <Input.TextArea
              autosize={{ minRows: 3, maxRows: 12 }}
              value={reason}
              onChange={e => setReason(e.target.value)}
            />
          </InputContainer>
          <Footer>
            <ButtonRow position="right">
              <Button kind="default" onClick={onClose}>
                Cancel
              </Button>
              <Button
                kind="primary"
                onClick={submit}
                disabled={!amount || !reason}
              >
                Adjust
              </Button>
            </ButtonRow>
          </Footer>
        </Content>
      </Box>
    </Modal>
  );
};

export default MarkdownModal;
