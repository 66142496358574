import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";
import { compose, lifecycle, withProps } from "recompose";

const LOAD = "patient-list/LOAD";
const LOAD_SUCCESS = "patient-list/LOAD_SUCCESS";
const LOAD_PATIENT_DETAILED_ALL_SUCCESS =
  "@@patient-list/LOAD_PATIENT_DETAILED_ALL_SUCCESS";
const LOAD_NON_COMPLIANT_PATIENT_ALL_SUCCESS =
  "@@patient-list/LOAD_NON_COMPLIANT_PATIENT_ALL_SUCCESS";

export const PATIENT_LIST_LOAD_FAIL = "@@patient-list/LOAD_FAIL";

const PATIENT_ALL_API = "/api/v1/patients/all";
const NON_COMPLIANT_PATIENT_ALL_API = "/api/v1/patients/noncompliant";

export const getNonCompliantPatientList = state =>
  state.patientList.nonCompliantPatientList;

const initialState = {
  data: [],
  detailedPatientList: [],
  nonCompliantPatientList: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS:
      return {
        ...state,
        data: action.result,
      };
    case LOAD_PATIENT_DETAILED_ALL_SUCCESS:
      return {
        ...state,
        detailedPatientList: action.result,
      };
    case LOAD_NON_COMPLIANT_PATIENT_ALL_SUCCESS:
      return {
        ...state,
        nonCompliantPatientList: action.result,
      };
    default:
      return state;
  }
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, PATIENT_LIST_LOAD_FAIL],
    promise: client => client.get(PATIENT_ALL_API),
  };
}

export function loadNonCompliantPatientList() {
  return {
    types: [null, LOAD_NON_COMPLIANT_PATIENT_ALL_SUCCESS, null],
    promise: client => client.get(NON_COMPLIANT_PATIENT_ALL_API),
  };
}

/**
 * HOCs
 */

// WithPatientListActions is a HOC which mixes in all actions.
export const WithPatientListActions = connect(
  null,
  dispatch => ({
    actions: bindActionCreators(
      {
        load,
        loadNonCompliantPatientList,
      },
      dispatch
    ),
  })
);

// AutoloadResources auto-fetches all patients from the API on ComponentDidMount
export const AutoloadPatients = compose(
  WithPatientListActions,
  lifecycle({
    componentDidMount() {
      this.props.actions.load();
    },
  })
);

export const WithPatientListProps = connect(
  createStructuredSelector({
    data: state => state.patientList.data || [],
    detailedPatientList: state => state.patientList.detailedPatientList || [],
  })
);

export const WithPatientNames = compose(
  WithPatientListProps,
  withProps(props => {
    const result = {};
    // Add each patient's name as the value of the map, using their ID as the key.
    props.data.forEach(p => {
      result[p.id] = `${p.first_name} ${p.last_name}`;
    });
    return {
      patientNames: result,
    };
  })
);
