import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  putSocialNote,
  selectSocialNote,
} from "src/redux/modules/clinical-record";
import { TextField } from "material-ui";
import { debounce } from "lodash";

const DEBOUNCE_TIME = 500;

const connected = connect(
  state => ({
    socialNote: selectSocialNote(state),
  }),
  { putSocialNote }
);
export class SocialNote extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    style: PropTypes.object,
    patient: PropTypes.object,
  };
  static contextTypes = {
    patient: PropTypes.object,
  };

  constructor() {
    super();
    this.debouncedOnChange = debounce(this.handleUpdate, DEBOUNCE_TIME);
    this.state = { socialNote: "" };
  }
  componentDidMount = () => {
    this.setState({ socialNote: this.props.socialNote });
  };
  handleUpdate = ({ note }) => {
    this.props.putSocialNote(this.context.patient.id, note);
  };
  handleThrottledOnChange = event => {
    const note = event.target.value;
    this.setState({ socialNote: note });
    this.debouncedOnChange({ note });
  };
  render() {
    return (
      <TextField
        hintText="Social Notes"
        textareaStyle={this.props.style}
        id="social-note"
        disabled={this.props.disabled}
        onChange={this.handleThrottledOnChange}
        value={this.state.socialNote}
        fullWidth
        multiLine
        rowsMax={10}
      />
    );
  }
}

export default connected(SocialNote);
