import styled from "react-emotion";

export const Flag = styled.span`
  display: inline-block;
  margin-left: 8px;

  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  vertical-align: top;
`;
