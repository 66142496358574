import { DateTime } from "luxon";
import { diffInMonths, toISODate } from "src/shared/time";
import { Treatment, Patient } from "src/types/gql";

export function getTxEstimate(treatment: Treatment) {
  if (
    treatment &&
    treatment.activeOption &&
    treatment.activeOption.estimatedUpperBound
  ) {
    return {
      estimateText: " Tx estimate",
      timeText: `${treatment.activeOption.estimatedUpperBound} mos.`,
    };
  }
  return {
    estimateText: " Tx estimate",
    timeText: `-- mos.`,
  };
}

export default function getTxStatus(
  treatment: Treatment,
  timeline: Array<{ start: string | null; status: string }>,
  patient: Patient
) {
  if (patient.statuses.length > 0 && patient.statuses[0].name === "graduated") {
    return {
      statusText: " graduated",
      dateText: toISODate(patient.statuses[0].start),
    };
  }
  if (!treatment || (!treatment.startDate && timeline.length === 0)) {
    return {
      dateText: "-- mos.",
      statusText: " Not in Tx",
    };
  }

  // treatment was discontinued either before or after the start
  if (treatment.dismissedById) {
    if (treatment.endDate) {
      return {
        statusText: " was discontinued",
        dateText: toISODate(treatment.endDate),
      };
    }
    return { statusText: "Dismissed" };
  }

  // treatment is in progress
  if (!treatment.endDate) {
    const firstTL = timeline.find(item =>
      ["complete", "assumed_complete", "in_progress"].includes(item.status)
    );

    const converted = diffInMonths(DateTime.local(), treatment.startDate);
    const startDate = firstTL && diffInMonths(DateTime.local(), firstTL.start);

    return {
      statusText: startDate ? "into tx" : "",
      dateText: startDate
        ? `${startDate.toFixed(1) || 0} mos. `
        : " Awaiting trays",
      convertedTx:
        treatment.startDate && `${converted.toFixed(1)} mos. from payment`,
    };
  }

  // patient either already graduated or is in active retention
  return {
    statusText: " graduated",
    dateText: toISODate(treatment.endDate),
  };
}
