import React from "react";
import { Link } from "react-router";
import Cross from "src/shared/Icons/Cross";
import styled from "react-emotion";

const Warning: React.SFC<{ patientID: string }> = ({ patientID }) => (
  <WarningBox>
    <div>
      <Cross size={30} />
    </div>
    <div>
      <p>
        <b>Warning: Legacy data</b>
      </p>

      <p>
        This patient doesn't have any tray logs, which means their start and end
        dates are determined by{" "}
        <Link to={`/patients/${patientID}/wear-schedule`}>
          their wear schedule
        </Link>
        . These may be wrong: they do not guarantee that a patient changes
        trays.
      </p>

      <p style={{ margin: 0 }}>
        This will automatically be changed after the patient checks in using a
        new version of the app.
      </p>
    </div>
  </WarningBox>
);

export default Warning;

const WarningBox = styled.div`
  background: #ffdedc;
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  border-radius: 3px;

  > div:first-of-type {
    background: #ff3b30;
    flex: 0;
    display: flex;
    align-items: center;
    align-self: stretch;
  }

  > div:last-of-type {
    padding: 20px;

    p,
    b {
      color: #e24c4b;
      font-size: 16px;
    }
  }
`;
