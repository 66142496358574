import React from "react";
import styled from "react-emotion";
import PlusMinusButton from "./PlusMinusButton";
import EditableValue from "./EditableValue";
import { Tooltip } from "antd";

const ToothTransformStyle = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  margin: 10px 10px 0 0;

  > div {
    display: flex;
    justify-content: space-between;
  }
`;

const ToothTransformTitle = styled.div`
  margin: 10px 0;
  font-weight: bold;
`;

const ToothTransformRow = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: stretch;
`;

const ToothTransform = ({
  wasmModule,
  toothInfo,
  rotationStep,
  translationStep,
}) => {
  const ToothMovement = ({ text, value, step, modifier }) => (
    <ToothTransformRow>
      <Tooltip
        overlayStyle={{ whiteSpace: "pre-line" }}
        placement="top"
        trigger={"hover"}
        title={
          "Inclination:\tS, W\r\nAngulation:\t\tD, A\r\nRotation:\t\tQ, E\r\nStep:\t\tZ, X\r\nMesiodistal:\t\tShift+D, Shift+A\r\nBuccolingual:\t\tShift+Q, Shift+E\r\nOcclusogingival:\tShift+S, Shift+W\r\nStep:\tShift+Z, Shift+X"
        }
      >
        <div style={{ width: "50%" }}>{text}</div>
      </Tooltip>
      <PlusMinusButton
        text={"-"}
        onClick={() => {
          modifier(value - step);
        }}
      />
      <EditableValue
        style={{
          width: "75px",
        }}
        onChange={val => {
          console.log("change to:", val);
          modifier(val);
        }}
        value={value}
      />
      <PlusMinusButton
        text={"+"}
        onClick={() => {
          modifier(value + step);
        }}
      />
    </ToothTransformRow>
  );

  return (
    <ToothTransformStyle>
      <hr style={{ width: "100%" }} />
      <ToothTransformTitle>Tooth {toothInfo.toothNum}</ToothTransformTitle>
      <ToothMovement
        text={"Inclination"}
        value={toothInfo.transform.torque}
        step={rotationStep}
        modifier={val => {
          wasmModule.webSetToothCrownTorque(val);
        }}
      />
      <ToothMovement
        text={"Angulation"}
        value={toothInfo.transform.tip}
        step={rotationStep}
        modifier={val => {
          wasmModule.webSetToothCrownTip(val);
        }}
      />
      <ToothMovement
        text={"Rotation"}
        value={toothInfo.transform.rotation}
        step={rotationStep}
        modifier={val => {
          wasmModule.webSetToothRotation(val);
        }}
      />
      <ToothMovement
        text={"Step"}
        value={rotationStep}
        step={0.05}
        modifier={val => {
          wasmModule.webSetToothRotationStep(val);
        }}
      />
      <ToothMovement
        text={"Mesiodistal"}
        value={toothInfo.transform.mesiodistal}
        step={translationStep}
        modifier={val => {
          wasmModule.webSetToothMesiodistal(val);
        }}
      />
      <ToothMovement
        text={"Buccolingual"}
        value={toothInfo.transform.buccolingual}
        step={translationStep}
        modifier={val => {
          wasmModule.webSetToothBuccolingual(val);
        }}
      />
      <ToothMovement
        text={"Occlusogingival"}
        value={toothInfo.transform.extrusion}
        step={translationStep}
        modifier={val => {
          wasmModule.webSetToothOcclusogingival(val);
        }}
      />
      <ToothMovement
        text={"Step"}
        value={translationStep}
        step={0.05}
        modifier={val => {
          wasmModule.webSetToothTranslationStep(val);
        }}
      />
    </ToothTransformStyle>
  );
};

export default ToothTransform;
