import React from "react";
import color from "src/styles/color";

interface Props {
  fill?: string;
}

const Address = ({ fill = color.gray3 }: Props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00014 3.01855L1.7204 6.71309C1.7204 6.7183 1.71915 6.72598 1.71664 6.73643C1.71419 6.74681 1.71289 6.75434 1.71289 6.75969V10.5008C1.71289 10.6359 1.76005 10.7529 1.85435 10.8515C1.94862 10.9501 2.06025 10.9998 2.18927 10.9998H5.04733V8.0068H6.95297V10.9999H9.811C9.94 10.9999 10.0518 10.9504 10.1459 10.8515C10.2402 10.753 10.2875 10.6359 10.2875 10.5008V6.75969C10.2875 6.73894 10.2848 6.72327 10.28 6.71309L6.00014 3.01855Z"
      fill={fill}
    />
    <path
      d="M11.9172 5.87137L10.2873 4.45285V1.27285C10.2873 1.20016 10.265 1.14037 10.2202 1.09358C10.1757 1.04685 10.1187 1.02348 10.0491 1.02348H8.62001C8.55051 1.02348 8.49344 1.04685 8.44873 1.09358C8.40413 1.14037 8.38183 1.20018 8.38183 1.27285V2.79268L6.56574 1.20264C6.40713 1.06754 6.21854 1 6.00018 1C5.78184 1 5.59327 1.06754 5.43451 1.20264L0.0827089 5.87137C0.0330975 5.91286 0.00590637 5.96872 0.000848776 6.03888C-0.00418275 6.10898 0.0131539 6.17021 0.0529107 6.22214L0.514377 6.79891C0.554134 6.84565 0.60617 6.87423 0.670694 6.88469C0.730264 6.88993 0.789834 6.87169 0.849404 6.83014L6.00002 2.33283L11.1507 6.83011C11.1905 6.86637 11.2425 6.88447 11.307 6.88447H11.3294C11.3938 6.8742 11.4457 6.84543 11.4857 6.7988L11.9472 6.22211C11.9869 6.17008 12.0043 6.10895 11.9991 6.03877C11.994 5.9688 11.9667 5.91294 11.9172 5.87137Z"
      fill={fill}
    />
  </svg>
);

export default Address;
