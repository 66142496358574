import React, { Component } from "react";
import styled, { css } from "react-emotion";
import { DateTime } from "luxon";
import { notification } from "antd";
// unable to import because the lib/index is es6. TODO
// import { ContentExplorer } from 'box-ui-elements'
// import messages from 'box-ui-elements/lib/i18n/en-US'
import styles from "./file-explorer-style";

import { connect } from "react-redux";
import { getPatientBoxFolderData } from "src/redux/modules/patient";

import Button, { ButtonRow } from "src/shared/Button";
import Modal from "src/shared/Modal";
import color from "src/styles/color";
import { titleCase, request } from "src/shared/util";
import { usePatientAppointments } from "src/state/hooks/appointments";

// Add a functional component parent which calls `usePatientAppointments`.  The previous
// stuff below is all classes, and we can't use hooks there.  Don't want to refactor it,
// as the lifetime is limited and it's a fucking huge pain for something at end of life.
const ApptWrapperFC = props => {
  const [appointments] = usePatientAppointments(props.params.userId);

  return <EnhancedFileExplorer {...props} appointments={appointments} />;
};

export default ApptWrapperFC;

const enhance = connect(
  state => ({
    patientFolderId: state.patient.patientFolderId,
    boxToken: state.patient.boxToken,
  }),
  {
    getPatientBoxFolderData,
  }
);

class FileExplorer extends Component {
  constructor() {
    super();
    this.state = {
      showCreate: false,
    };
  }

  componentDidMount() {
    this.props.getPatientBoxFolderData(this.props.patient.id).then(res => {
      if (!res.patient_folder_id) return;
      const folderId = res.patient_folder_id.id;
      const accessToken = res.box_token;
      const contentExplorer = new Box.ContentExplorer(); // eslint-disable-line
      contentExplorer.show(folderId, accessToken, {
        container: ".container",
        logoUrl: "box",
      });
    });
  }

  render() {
    const makeFolder = async apptID => {
      let url = `/api/v1/patients/${
        this.props.params.userId
      }/create_another_patient_record_folder`;
      if (apptID) {
        url = `${url}?appointment_id=${apptID}`;
      }
      const result = await request(url, {
        method: "POST",
      });

      if (result.status === 200) {
        this.setState({ showCreate: false });
        notification.success({ message: "Folder created." });
        return;
      }

      notification.error({ message: result.error });
    };

    return (
      <div>
        <ButtonRow className={rowCSS} position="right">
          <Button onClick={() => this.setState({ showCreate: true })}>
            Create new folder
          </Button>
        </ButtonRow>

        <div style={styles.fileContainer} className="container">
          {" "}
          Loading patient box folder... If it does not load, the patient might
          not have a box folder.
        </div>

        {this.state.showCreate && (
          <Modal onClose={() => this.setState({ showCreate: false })}>
            <Content>
              <h1>Choose the appt</h1>
              {this.props.appointments.map(appt => (
                <div onClick={() => makeFolder(appt.id)}>
                  {DateTime.fromISO(appt.start_time).toLocaleString()} -{" "}
                  {titleCase(appt.appointment_type)}
                </div>
              ))}
            </Content>
            <ButtonRow position="right" className={modalRowCSS}>
              <Button
                type="danger"
                onClick={() => this.setState({ showCreate: false })}
              >
                Cancel
              </Button>
              <Button onClick={makeFolder}>Use today</Button>
            </ButtonRow>
          </Modal>
        )}
      </div>
    );
  }
}

const rowCSS = css`
  padding: 0 0 20px;
`;

const modalRowCSS = css`
  border-top: 1px solid ${color.borderLight};
  padding: 12px;
`;
const Content = styled.div`
  width: 300px;
  margin-bottom: 20px;

  h1 {
    padding: 20px 0 0 20px;
  }

  > div {
    cursor: pointer;
    padding: 10px 20px;
    &:hover {
      background: #f5f5f5;
    }
  }
`;

const EnhancedFileExplorer = enhance(FileExplorer);
