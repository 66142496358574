import React, { useState } from "react";
import Dropdown from "src/shared/Dropdown";
import { Clinic } from "src/types/gql";

type Props = {
  selected: string | null;
  onChange: (id: string) => void;
  clinics: Array<Clinic>;
  clearOption?: string;
  className?: string;
};

const ClinicPicker = ({
  selected,
  onChange,
  clinics,
  clearOption,
  className,
}: Props) => {
  // Take unique cities from all clinics.
  const cityOptions = ["All"].concat(
    Array.from(new Set<string>(clinics.map((c: any) => c.city)))
  );
  const [city] = useState(cityOptions[0]);

  // And only show clinics that are in the selected city.
  let clinicOptions = clinics
    .filter((c: any) => (city === "All" ? true : c.city === city))
    .map((c: any) => ({ label: c.nickname || c.name, value: c.id }));

  if (clearOption) {
    clinicOptions = [{ label: clearOption, value: clearOption }].concat(
      clinicOptions
    );
  }

  return (
    <Dropdown
      className={className}
      options={clinicOptions}
      selected={clinicOptions.find(e => e.value === selected)}
      onSelect={e => {
        onChange(e.value);
      }}
    />
  );
};

export default ClinicPicker;
