import React from "react";
import ConfirmModal from "../ConfirmModal";

type AddCustomTagConfirmModalProps = {
  hidden?: boolean;
  onClose: () => void;
  onConfirm: () => void | Promise<void>;
};

const AddCustomTagConfirmModal: React.FC<
  AddCustomTagConfirmModalProps
> = props => (
  <ConfirmModal
    type="warn"
    title="Warning"
    text="Adding a tag to the pipeline will add the corresponding label to each task within the pipeline. Would you like to proceed?"
    actionText="Add tag"
    {...props}
  />
);

export default AddCustomTagConfirmModal;
