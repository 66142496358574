import React, { Component } from "react";
import { compose, lifecycle, branch, renderComponent } from "recompose";
import { connect } from "react-redux";
// Previous redux actions to load data.
import { load as loadClinicalRecord } from "src/redux/modules/clinical-record";
import { loadPatient } from "src/redux/modules/patient";
import { load as loadConsents } from "src/redux/modules/consent";
import { ToothEnhancer } from "src/state/Tooth";
import { TreatmentCyclesEnhancer } from "src/state/TreatmentCycles";
import { InternalNotesEnhancer } from "src/state/InternalNote";
// Loading screen
import Loading from "src/shared/Loading";
import Treatment from "./treatment";
import { WithEnhancers } from "src/shared/hocs";

// We removed redyx-async-connect because it's shit and this is the parent component that
// refuses to render Kenzan's previous treatment container until it has the patient, clinical
// record and consents.
const modifiers = compose(
  // Get the patient's clinical record and patient info for the
  // treatment wrapper.
  connect(
    state => ({
      clinicalRecord: state.clinicalRecord,
      patient: state.patient.patient || {},
    }),
    { loadClinicalRecord, loadPatient, loadConsents }
  ),
  // Add in the tooth enhancer and TC enhancer, letting us access the current
  // trays for the patient.
  ...WithEnhancers(
    ToothEnhancer,
    TreatmentCyclesEnhancer,
    InternalNotesEnhancer
  ),
  lifecycle({
    componentDidMount() {
      const { userId } = this.props.params;

      this.props.loadPatient(userId);
      this.props.loadClinicalRecord(userId);
      this.props.loadConsents(userId);

      // Load the patient's tooth chart.
      this.props.actions.getTeeth(userId);
      // Get all TCs for the patient, which also gets each tray
      this.props.actions.getPatientTreatmentCycles(userId);
    },

    componentWillReceiveProps(next) {
      const { userId } = next.params;
      if (next.params.userId !== this.props.params.userId) {
        this.props.loadPatient(userId);
        this.props.loadClinicalRecord(userId);
        this.props.loadConsents(userId);
        this.props.actions.getTeeth(userId);
        // Get all TCs for the patient, which also gets each tray
        this.props.actions.getPatientTreatmentCycles(userId);
      }
    },
  }),
  // Don't render anything until we at least have the patient.
  branch(props => {
    return (
      // Ensure we have the clinical record
      !props.clinicalRecord.data ||
      !props.clinicalRecord.data.id ||
      // And ensure we have the patient
      !props.patient ||
      !props.patient.id ||
      props.patient.id !== props.params.userId
    );
  }, renderComponent(Loading)),

  // When we have the patient's clinical record, load all internal notes.
  lifecycle({
    componentDidMount() {
      this.props.actions.getInternalNotes({
        model_type: "ClinicalRecord",
        model_id: this.props.clinicalRecord.data.id,
      });
    },
  })
);

class TreatmentWrapper extends Component<*, *> {
  render() {
    return <Treatment {...this.props} key={this.props.patient.id} />;
  }
}

export default modifiers(TreatmentWrapper);
