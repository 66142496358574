import React, { useState } from "react";
import styled, { css } from "react-emotion";
import { isNil } from "lodash";
import { DateTime } from "luxon";

import Box from "src/shared/Box";
import Loading from "src/shared/Loading";
import Error from "src/shared/Error";
import money from "src/utils/money";
import color from "src/styles/color";
import ExpandArrow from "src/shared/ExpandArrow";

import ExpandedInsuranceEligibility from "./ExpandedInsuranceEligibility";
import { useEligibilityHistory } from "./query";

type Props = {
  patientID: string;
};

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  padding: 20px 30px;
  border-top: 1px solid ${color.border};
  grid-template-columns:
    minmax(100px, 140px)
    auto
    minmax(100px, 130px)
    minmax(100px, 130px)
    minmax(100px, 130px)
    30px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  cursor: pointer;
`;

const header = css`
  font-size: 14px;
  font-weight: bold;
  padding: 12px 30px;
  > div {
    color: ${color.gray3};
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
`;

const moneyOrUnknown = amount => {
  return isNil(amount) ? "Unknown" : money(amount);
};

const EligibilityItem = ({ e }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <Grid onClick={() => setExpanded(!expanded)}>
        <div>{DateTime.fromISO(e.createdAt).toFormat("LLLL d, yyyy")}</div>
        <div>{e.InsuranceProvider.name}</div>
        <div>{moneyOrUnknown(e.lifetimeMax)}</div>
        <div>{moneyOrUnknown(e.lifetimeMaxRemaining)}</div>
        <div>{`${e.percent}%`}</div>
        <ArrowContainer>
          <ExpandArrow expanded={expanded} />
        </ArrowContainer>
      </Grid>
      {expanded && <ExpandedInsuranceEligibility eligibility={e} />}
    </div>
  );
};

const InsuranceEligibilityHistory = ({ patientID }) => {
  const [fetching, error, eligibilities] = useEligibilityHistory(patientID);

  if (fetching || isNil(eligibilities)) {
    return <Loading />;
  }

  if (error) return <Error />;

  return (
    <Box title="Insurance Eligibility History">
      <div>
        <Grid className={header}>
          <div>Date</div>
          <div>Provider</div>
          <div>Lifetime Max</div>
          <div>Lifetime Remaining</div>
          <div>Co-insurance</div>
        </Grid>

        {eligibilities.map(e => (
          <EligibilityItem key={e.id} e={e} />
        ))}
      </div>
    </Box>
  );
};

export default InsuranceEligibilityHistory;
