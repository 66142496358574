import { set, get } from "lodash";
import { message } from "antd";
import { Action as ReduxAction } from "redux";
import { HTTPClient } from "src/types/local";

const GET_ALL_ORDERS_SUCCESS = "@@order/GET_ALL_ORDERS_SUCCESS";
const GET_PAYMENT_SOURCES_SUCCESS = "@@order/GET_PAYMENT_SOURCES_SUCCESS";
const GET_ALL_ORDER_SEGMENT_START = "@@order/GET_ALL_ORDER_SEGMENT_START";
const GET_ALL_ORDER_SEGMENT_SUCCESS = "@@order/GET_ALL_ORDER_SEGMENT_SUCCESS";
const DELETE_ORDER_SUCCESS = "@@order/DELETE_ORDER_SUCCESS";

const GET_KLARNA_CLIENT_TOKEN_SUCCESS =
  "@@order/GET_KLARNA_CLIENT_TOKEN_SUCCESS";

const ORDER_API = "/api/v1/billing/orders";
const getOrderSegmentAPI = id => `/api/v1/billing/orders/${id}/order_segments`;
const getChargeOrderSegmentAPI = ({ orderId, orderSegmentId }) =>
  `/api/v1/billing/orders/${orderId}/order_segments/${orderSegmentId}/charges`;
const getPaymentSourcesAPI = id =>
  `/api/v1/billing/users/${id}/payment_sources`;

const initialState = {
  mapByPatient: {
    initialUserId: {
      paymentSourceList: [],
      orderList: [],
      klarnaClientToken: "",
      orderSegmentList: [],
    },
  },
};

interface Response {
  id: string;
  patientId: string;
  client_token?: string;
}

interface Action extends ReduxAction {
  patientId: string;
  result: Response;
}

export default function reducer(state = initialState, action = {} as Action) {
  const newState = { ...state };
  switch (action.type) {
    case GET_PAYMENT_SOURCES_SUCCESS:
      return set(
        newState,
        `mapByPatient.${action.patientId}.paymentSourceList`,
        action.result
      );
    case GET_ALL_ORDERS_SUCCESS:
      return set(
        newState,
        `mapByPatient.${action.patientId}.orderList`,
        action.result
      );
    case GET_ALL_ORDER_SEGMENT_START:
      // return set(newState, `mapByPatient.${action.patientId}.orderSegmentList`, [])
      return newState;
    case DELETE_ORDER_SUCCESS:
      const { patientId, id } = action.result;
      const orders = get(state, `mapByPatient['${patientId}'].orderList`, []);
      return set(
        newState,
        `mapByPatient['${patientId}'].orderList`,
        orders.filter(o => o.id !== id)
      );
    case GET_ALL_ORDER_SEGMENT_SUCCESS:
      return set(
        newState,
        `mapByPatient.${action.patientId}.orderSegmentList`,
        action.result
      );
    case GET_KLARNA_CLIENT_TOKEN_SUCCESS:
      return set(
        newState,
        `mapByPatient.${action.patientId}.klarnaClientToken`,
        action.result.client_token
      );
    default:
      return newState;
  }
}

export function getAllOrderSegmentForOrder(orderId, patientId) {
  return {
    patientId,
    orderId,
    types: [GET_ALL_ORDER_SEGMENT_START, GET_ALL_ORDER_SEGMENT_SUCCESS, null],
    promise: (client: HTTPClient) => client.get(getOrderSegmentAPI(orderId)),
  };
}
export function getPaymentSources(patientId) {
  return {
    patientId,
    types: [null, GET_PAYMENT_SOURCES_SUCCESS, null],
    promise: (client: HTTPClient) =>
      client.get(getPaymentSourcesAPI(patientId)),
  };
}

export function getAllOrdersForPatient(patientId) {
  return {
    patientId,
    types: [null, GET_ALL_ORDERS_SUCCESS, null],
    promise: (client: HTTPClient) =>
      client.get(`${ORDER_API}?user_id=${patientId}`),
  };
}

export function getKlarnaClientTokenFromAPI(orderId, patientId) {
  return {
    patientId,
    types: [null, GET_KLARNA_CLIENT_TOKEN_SUCCESS, null],
    promise: (client: HTTPClient) =>
      client.get(`${ORDER_API}/${orderId}/get_klarna_client_token`),
  };
}

export function createOrder(data) {
  return {
    types: [null, null, null],
    promise: (client: HTTPClient) =>
      client.post(ORDER_API, {
        data,
      }),
  };
}

export function createOrderSegment(patientId, data) {
  return {
    types: [null, null, null],
    promise: (client: HTTPClient) =>
      client.post(getOrderSegmentAPI(patientId), {
        data,
      }),
  };
}

export const closeOrder = (patientId: string, id: string) => {
  // TODO: Move this into hooks and standard functions.  This previous stuff
  // sucks.
  const hide = message.loading("Deleting order...");
  return {
    types: [null, DELETE_ORDER_SUCCESS, null],
    promise: (client: HTTPClient) =>
      client
        .del(`/api/v1/billing/orders/${id}`)
        .catch(() => {
          hide();
          message.error("There was an error deleting your order");
        })
        .then(resp => {
          hide();
          message.success("Order deleted");
          return { patientId, id };
        }),
  };
};

export function createCharge({
  orderId,
  orderSegmentId,
  amount,
  payment_source_id,
}) {
  return {
    types: [null, null, null],
    promise: (client: HTTPClient) =>
      client.post(getChargeOrderSegmentAPI({ orderId, orderSegmentId }), {
        data: { amount, payment_source_id },
      }),
  };
}

export const getNonce = (patientId: string) => ({
  types: [null, null, null],
  promise: (client: HTTPClient) =>
    client.post(`/api/v1/billing/receipts/nonce`),
});
