import React, { useContext } from "react";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { Dropdown, Button, Icon } from "antd";

import { FiltersListProps } from "./types";
import { useDropdown } from "./hooks";
import { dropdownButtonCss, Menu, Title, SubTitle } from "./styles";
import FiltersItem from "./FiltersItem/FiltersItem";
import { FiltersContext } from "../FiltersContext/FiltersContext";
import { UNSAVED_FILTER_TITLE } from "../FiltersContext/constants";
import { formatDate } from "./utils";

const FiltersList: React.FC<FiltersListProps> = () => {
  const { allFilters, hasUnsavedFilter, getSelectedFilter } = useContext(
    FiltersContext
  );
  const { isOpen, menuRef, handleButtonClick } = useDropdown();

  const hasSharedFilters = !isEmpty(allFilters.waiting);
  const hasAcceptedFilters = !isEmpty(allFilters.accepted);
  const hasOwnFilters = hasUnsavedFilter || hasAcceptedFilters;
  const hasFilters = hasOwnFilters || hasSharedFilters;
  const selectedFilter = getSelectedFilter();
  const dropdownTitle = hasUnsavedFilter
    ? UNSAVED_FILTER_TITLE
    : !isNil(selectedFilter.id)
    ? selectedFilter.title
    : "Not selected";

  return hasFilters ? (
    <Dropdown
      visible={isOpen}
      overlay={
        <Menu innerRef={menuRef}>
          {hasOwnFilters && <Title>My Filters</Title>}
          {hasUnsavedFilter && (
            <FiltersItem
              title={UNSAVED_FILTER_TITLE}
              date={formatDate(new Date().toISOString())}
              state={selectedFilter.id === null ? "selected" : "accepted"}
            />
          )}
          {allFilters.accepted.map(({ id, title, createdAt, sharedBy }) => (
            <FiltersItem
              key={id}
              id={id}
              title={title}
              // date={formatDate(createdAt)}
              date=""
              state={id === selectedFilter.id ? "selected" : "accepted"}
              sharedBy={sharedBy}
            />
          ))}
          {hasSharedFilters && <SubTitle>Invites</SubTitle>}
          {allFilters.waiting.map(({ id, title, createdAt, sharedBy }) => (
            <FiltersItem
              key={id}
              id={id}
              title={title}
              // date={formatDate(createdAt)}
              date=""
              state="waiting"
              sharedBy={sharedBy}
            />
          ))}
        </Menu>
      }
    >
      <Button css={dropdownButtonCss} onClick={handleButtonClick}>
        {dropdownTitle} <Icon type="caret-down" />
      </Button>
    </Dropdown>
  ) : null;
};

export default FiltersList;
