import {
  getSummaryForSlugs,
  getTextPerEntryType,
} from "./generatedSummaryUtil";
import { getPrimary, getUniversal } from "src/shared/ToothChart/util";
import { AttributeType } from "src/shared/ToothChartV2/types";

export const generateFindingsSummary = (answers, toothData) => {
  // We want to order all operations by tooth.
  const procedures = toothData
    ? toothData
        .map(p =>
          Object.assign({}, p, { toothNumber: getUniversal(p.tooth_name) })
        )
        .sort((a, b) => a.toothNumber - b.toothNumber)
    : [];

  const getEntry = (t: AttributeType) => {
    return getTextPerEntryType(
      t,
      procedures
        .filter((p: any) => p.entry_type === t)
        .map((p: any) => p.toothNumber)
    );
  };

  const teethEntries = [
    getEntry("missing"),
    getEntry("caries"),
    getEntry("implant"),
    getEntry("crown"),
    getEntry("impacted"),
    getEntry("root_canal"),
    getTextPerEntryType(
      "deciduous",
      procedures
        .filter(p => p.entry_type === "deciduous")
        .map(p => {
          return getPrimary(p.toothNumber);
        })
    ),
  ].filter(Boolean);

  const summaryForSlugs = getSummaryForSlugs(
    ["oral_hygeine", "additional_sof_findings"],
    answers
  );

  const summaries = teethEntries.concat(summaryForSlugs).filter(Boolean);
  if (summaries.length === 0) {
    return null;
  }

  const str = " • " + summaries.join("\n • ");
  // Remove double bullet points cuz some qs insert their own -.-
  return str.replace(" •  • ", " • ");
};
