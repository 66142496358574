import styled, { css } from "react-emotion";
import color from "src/styles/color";

export const Content = styled.div`
  display: flex;
  flex-flow: column;

  hr {
    color: ${color.border};
    width: 100%;
  }

  min-height: 300px;
  height: 100%;
`;

export const contentWrapper = css`
  flex: 1;
`;
