import React from "react";
import styled, { css } from "react-emotion";
import UploadIcon from "src/shared/Icons/Upload";
import { uuid } from "src/shared/util";
import Box from "src/shared/Box";
import Modal from "src/shared/Modal";
import UseUploader from "src/shared/UseUploader";
import color from "src/styles/color";
import { useAssociatePhoto } from "./query";

type Props = {
  trayLogID?: string;
  userID: string;
  onClose: () => void;
};

export const Upload = (props: Props) => {
  const associatePhoto = useAssociatePhoto();
  const { userID } = props;
  const { dragging, setDragging, list, upload, onDrop } = UseUploader(props);

  console.log(list);

  return (
    <Modal onClose={props.onClose}>
      <Box title="Upload new picture">
        <Wrapper
          className={dragging ? draggingCSS : ""}
          onDrop={onDrop}
          onDragOver={e => {
            e.preventDefault();
            setDragging(true);
          }}
          onDragEnter={() => setDragging(true)}
          onDragLeave={() => setDragging(false)}
        >
          <Label>
            <UploadIcon />
            <p>Drag and drop file here</p>

            <input
              type="file"
              multiple
              onChange={e => {
                const f = Array.from<File>(e.target.files || []);
                if (f.length !== 1) {
                  return;
                }
                upload([
                  {
                    id: uuid(),
                    userID,
                    file: f[0],
                    filetype: "Selfie",
                    onComplete: file => {
                      associatePhoto(file.id, props.trayLogID || "");
                      props.onClose();
                    },
                  },
                ]);
                props.onClose();
              }}
            />
          </Label>
          <p>Or browse files...</p>
        </Wrapper>
      </Box>
    </Modal>
  );
};

export default Upload;

const draggingCSS = css`
  label {
    border-color: #ea6938;
  }
`;

const Wrapper = styled.div`
  width: 400px;
  padding: 40px 40px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 24px;
  }
`;

const Label = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px dashed ${color.border};
  box-sizing: border-box;
  border-radius: 12px;
  width: 300px;
  height: 96px;
  cursor: pointer;
  margin: 0 0 2rem;

  font-weight: bold;

  p {
    margin: 0 0 0 2rem;
  }

  input {
    visibility: hidden;
    height: 0;
    width: 0;
  }

  + p {
    opacity: 0.5;
    margin-bottom: 2rem;
  }
`;
