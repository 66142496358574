import React from "react";
import styled from "react-emotion";
// styles
import textStyles from "src/styles/textStyles";
import color from "src/styles/color";
// shared
import Button from "src/shared/Button";

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  padding: 0 32px;
`;

export const SectionTitle = styled.div`
  ${textStyles("xxlarge")};
  font-weight: bold;
  color: ${color.primary};
  margin-bottom: 8px;
`;

export const Divider = styled.div`
  width: calc(100% - 64px);
  margin: 0 auto 32px;
  border-bottom: 1px solid ${color.border};
`;

export const Row = styled.div`
  display: flex;
`;

export const Column = styled.div`
  width: 50%;
`;

export const Continue = props => (
  <div
    style={{
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: "12px 32px 12px 0",
      borderTop: `1px solid ${color.border}`,
    }}
  >
    <Button kind="primary" onClick={props.next}>
      Continue
    </Button>
  </div>
);
