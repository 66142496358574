import React, { useState, useEffect } from "react";
import { createOrderSegment, getPaymentSources } from "src/redux/modules/order";
import { getPaymentSourceList } from "src/selectors/order";
import { connect } from "react-redux";
import { Select, InputNumber, DatePicker, Button } from "antd";
import styled from "react-emotion";
import Moment from "moment";
import Stripe from "../stripe/stripe";
import { PaymentSource } from "src/types/api";

const modifier = connect(
  (state, ownProps) => ({
    paymentSourceList: getPaymentSourceList(state, ownProps.patientId),
  }),
  { createOrderSegment, getPaymentSources } // these are called actions
);

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  text-align: left;

  > span {
    margin: 1rem 0 0.5rem;
  }

  input {
    width: 100%;
  }
`;

const Details = styled("div")`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;

  > div {
    display: flex;
    flex-direction: column;
  }

  > div:first-child {
    flex: 2;
  }
  > div:last-child {
    flex: 3;
  }
`;

const CreateOS = props => {
  const {
    createOrderSegment,
    getPaymentSources,
    patientId,
    order,
    handleComplete,
    paymentSourceList,
  } = props;

  const [saving, setSaving] = useState(false);

  const [options, setOptions] = useState({
    payment_type: "debit",
    payment_details: {},
    source_id: undefined,
    stripe_token: undefined,
    amount: 0,
    monthly_amount: 0,
  });

  const submit = async () => {
    if (saving) {
      return;
    }

    setSaving(true);

    const payload = Object.assign({}, options);
    if (payload.stripe_token) {
      // Remote source_id;  we're using stripe token vs source_id instead
      delete payload.source_id;
    }

    try {
      await createOrderSegment(order.id, payload);
      handleComplete();
      return;
    } catch {}

    setSaving(false);
  };

  useEffect(() => {
    getPaymentSources(patientId);
  }, [getPaymentSources, patientId]);

  return (
    <Wrapper>
      <span>Payment type</span>
      <Select
        onChange={(val: string) => {
          setOptions(Object.assign({}, options, { payment_type: val }));
        }}
        value={options.payment_type}
      >
        <Select.Option value="debit">Debit/Credit card</Select.Option>
        <Select.Option value="stripe_subscription">Subscription</Select.Option>
        <Select.Option value="cash">Cash</Select.Option>
      </Select>

      <Details>
        <div>
          <span>
            {options.payment_type !== "stripe_subscription" && (
              <span>
                <b>Total</b> amount
              </span>
            )}
            {options.payment_type === "stripe_subscription" && (
              <span>
                <b>Total amount</b> for the subscription
              </span>
            )}
          </span>
          <InputNumber
            placeholder="Confirm amount"
            width="100%"
            defaultValue={options.amount}
            onChange={(val: number | undefined) => {
              val = val ? val : 0;
              setOptions(Object.assign({}, options, { amount: val * 100 }));
            }}
          />
        </div>

        {options.payment_type !== "cash" && (
          <div>
            <span>Payment method</span>
            <Select
              defaultValue=""
              onChange={(id: string) => {
                setOptions(Object.assign({}, options, { source_id: id }));
              }}
            >
              <Select.Option value="">No payment method</Select.Option>
              {paymentSourceList.map((s: PaymentSource) => (
                <Select.Option value={s.id}>
                  {s.source_type} - {s.brand} - {s.last4}
                </Select.Option>
              ))}
              <Select.Option value="new">Add a new card</Select.Option>
            </Select>
          </div>
        )}
      </Details>

      {options.payment_type === "stripe_subscription" && (
        <Details>
          <div>
            <span>
              <b>Monthly amount</b> for the subscription, in $
            </span>
            <InputNumber
              onChange={(m: number | undefined) => {
                m = m ? m : 0;
                setOptions({
                  ...options,
                  payment_details: {
                    ...options.payment_details,
                    monthly_amount: m * 100,
                  },
                });
              }}
            />
          </div>

          <div>
            <span>Start date</span>
            <DatePicker
              onChange={(date, ds) => {
                if (!date) {
                  return;
                }

                const diff = date.diff(Moment().startOf("day"), "days");
                if (diff <= 0) {
                  return;
                }
                setOptions({
                  ...options,
                  payment_details: {
                    ...options.payment_details,
                    day_delay: diff,
                  },
                });
              }}
            />
          </div>
        </Details>
      )}

      {options.source_id === "new" && (
        <Stripe
          onReceiveToken={t => {
            setOptions(Object.assign({}, options, { stripe_token: t.id }));
          }}
        />
      )}

      <Button
        disabled={saving || options.amount === 0}
        type="primary"
        onClick={submit}
        style={{ marginTop: "1rem" }}
      >
        Submit payment
      </Button>
    </Wrapper>
  );
};

export default modifier(CreateOS);
