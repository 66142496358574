import { QUESTION_FORM } from "../event-type";
import {
  QUESTION_TYPE,
  RETAINER,
  TREATMENT_TYPE_COMPREHENSIVE,
} from "../constant";

const PIV_TEMPLATE = {
  attachToothChart: true,
  postTimestamp: null,
  postUserName: null,
  postUserUUID: null,
  complete: false,
  formName: "piv", // this must match the name of the form in config/treatment-forms/index
  type: QUESTION_FORM,
  questionList: [
    {
      displayName: "Review  PRIVACY POLICY with the patient?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      displayName: "Reviewed medical & dental history with the patient?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      displayName: "Additional Notes from Reviewing the Medical History",
      type: "text",
    },
    {
      displayName: "Any cavities identified from X-Rays?",
      type: "select",
      possibleAnswerList: ["No", "Yes"],
    },
    {
      displayName: "Any Periodontal problems noted in X-Rays?",
      type: "select",
      possibleAnswerList: ["No", "Yes"],
    },
    {
      displayName: "TMJ & Psychosocial Evaluation",
      type: "multiselect",
      possibleAnswerList: [
        "Reviewed, TMJ Within Normal Limits",
        "History of TMJ Pain",
        "History of TMJ Clicking",
        "Current TMJ Pain",
        "Current TMJ Clicking",
        "Psychosocially Unrealistic Demands",
      ],
    },
    {
      displayName: "Gum Health",
      type: "multiselect",
      possibleAnswerList: [
        "All probing sites are within normal limits",
        "Attachment Loss Generalized",
        "Attachment Loss Localized",
        "Increased Pocket Depth",
        "Active Periodontal Disease",
      ],
    },
    {
      displayName: "Oral Hygiene",
      type: "select",
      possibleAnswerList: ["Good", "Fair", "Poor"],
    },
    {
      displayName: "Additional Notes From the Exam",
      type: "text",
    },
    {
      displayName:
        "Physical Exam Performed and the Patient Is Eligible for Treatment?",
      type: "select",
      possibleAnswerList: [
        "Yes",
        "No",
        "Observe 1mos",
        "Observe 3mos",
        "Observe 6mos",
      ],
    },
    {
      displayName: "Reviewed Treatment Plan with the Patient?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      displayName: "Does patient elect Night-Time-Only treatment?",
      type: "select",
      possibleAnswerList: ["No", "Yes"],
    },
    {
      displayName: "Review treatment CONSENT FORM with the patient?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      displayName: "Refer To",
      type: "text",
    },
    {
      displayName: "Refer To Email Address",
      type: "text",
    },
    {
      displayName: "Referral Reason",
      type: "text",
    },
    {
      displayName: "Any Attachments Placed on the Patient?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      displayName: "Any IPR Performed on the Patient?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      displayName: "Did you review and update the tooth chart?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      displayName: "Treatment Notes",
      type: "text",
    },
    {
      displayName:
        "Payment plan. What discounts did we offer the patient? What is the total amount we are charging the patient? Monthly payment or pay in full? Which card to use?",
      type: "text",
    },
    {
      displayName: "Social History",
      type: QUESTION_TYPE.SOCIAL_NOTE,
    },
    {
      displayName: "Did you export the scans to Box?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      displayName:
        "Did you take each of the following beginning patient photos? Check off each one. ",
      type: "multiselect",
      possibleAnswerList: [
        "frontal relaxed",
        "frontal smiling",
        "profile relaxed",
        "profile smiling",
      ],
    },
    {
      displayName: "Did you upload all of the photos to Box?",
      type: "select",
      possibleAnswerList: ["Yes", "No"],
    },
    {
      dataKey: "duration",
      displayName: "Estimated Duration of treatment (REQUIRED)",
      type: "select",
      possibleAnswerList: [
        "4-5 months",
        "6-8 months",
        "8-10 months",
        "10-12 months",
        "12-18 months",
        "over 18 months",
      ],
    },
    {
      dataKey: "treatmentType",
      displayName: "Treatment Type",
      type: "select",
      possibleAnswerList: [TREATMENT_TYPE_COMPREHENSIVE, RETAINER],
    },
  ],
};

export default PIV_TEMPLATE;
