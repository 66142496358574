const head = {
  titleTemplate: "Uniform: %s",
  meta: [
    { name: "description", content: "Uniform" },
    // For third party styles
    { name: "apple-mobile-web-app-capable", content: "yes" },
    // { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
    { name: "viewport", content: "width=device-width" }, // iphone 6plus height
    { charset: "utf-8" },
    { property: "og:site_name", content: "Uniform" },
    { property: "og:image", content: "" },
    { property: "og:locale", content: "en_US" },
    { property: "og:title", content: "Uniform" },
    { property: "og:description", content: "Uniform" },
    { property: "og:card", content: "summary" },
    { property: "og:site", content: "Uniform" },
    { property: "og:creator", content: "Uniform" },
    { property: "og:image:width", content: "200" },
    { property: "og:image:height", content: "200" },
  ],

  link: [
    {
      href: "https://fonts.googleapis.com/css?family=Roboto:400,300,500,600",
      rel: "stylesheet",
      type: "text/css",
    },
    {
      href: "/main.css",
      rel: "stylesheet",
      type: "text/css",
    },
    {
      href:
        "https://cdn01.boxcdn.net/platform/elements/1.1.0/en-US/explorer.css",
      rel: "stylesheet",
      type: "text/css",
    },
    {
      rel: "shortcut icon",
      href: "/favicon.ico",
    },
  ],
  script: [
    {
      src: "https://cdn01.boxcdn.net/platform/elements/1.1.0/en-US/explorer.js",
      async: true,
    },
  ],
};

export default head;
