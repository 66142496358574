import React, { useState } from "react";
import styled, { css } from "react-emotion";
import { Document, Page } from "react-pdf";
import { endsWith, truncate } from "lodash";

import Modal from "src/shared/Modal";
import Box from "src/shared/Box";
import color from "src/styles/color";
import Button, { ButtonRow } from "src/shared/Button";
import { url } from "src/shared/util";
import DownloadIcon from "./download.svg";
import EmailExternalSpecialist from "./EmailExternalSpecialist";

const buttonRow = css`
  padding: 16px 24px;
  border-top: 1px solid ${color.border};
`;

const Content = styled.div`
  height: 550px;
  width: 700px;
  display: flex;
  flex-flow: column;
`;

const FileContainer = styled.div`
  flex: 1;
  display: flex;
  background: ${color.gray1};
  overflow: auto;
  justify-content: center;

  > img {
    max-width: 650px;
    max-height: 450px;
    align-self: center;
  }

  > .react-pdf__Document {
    margin: 20px 0;
  }
`;

const DocumentViewer = ({ onClose, file, patientID }) => {
  const [fileURL, setFileURL] = useState("");
  const [selectedFileID, setselectedFileID] = useState<string | null>(null);

  const fetchData = async () => {
    const request = new Request(url(`/api/v2/files/${file.id}`), {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("auth_token")}`,
      },
    });

    const res = await fetch(request);
    if (res.status !== 200) {
      setFileURL(res.url);
    }
  };

  if (!fileURL) {
    fetchData();
  }

  return (
    <Modal onClose={onClose}>
      {selectedFileID && (
        <EmailExternalSpecialist
          onClose={() => setselectedFileID(null)}
          fileID={selectedFileID}
          patientID={patientID}
        />
      )}
      <Box title={truncate(file.filename, { length: 60 })}>
        <Content>
          <FileContainer>
            {fileURL && (
              <FileViewer fileURL={fileURL} filename={file.filename} />
            )}
          </FileContainer>
          <ButtonRow className={buttonRow} position="right">
            <a style={{ marginRight: "10px" }} href={fileURL}>
              <Button kind="default">
                <img alt="download" src={DownloadIcon} />
              </Button>
            </a>
            <Button
              onClick={() => setselectedFileID(file.id)}
              style={{ width: "150px" }}
              kind="primary"
            >
              Email
            </Button>
          </ButtonRow>
        </Content>
      </Box>
    </Modal>
  );
};

const getFileType = filename => {
  if (endsWith(filename, ".pdf")) return "pdf";
  if (endsWith(filename, ".png")) return "image";
  if (endsWith(filename, ".jpg")) return "image";
};

const FileViewer = ({ fileURL, filename }) => {
  const [data, setData] = useState<any>(null);
  const [numPages, setNumPages] = useState(0);

  const type = getFileType(filename);
  if (type === "image") return <img alt={filename} src={fileURL} />;

  const fetchPDFData = async () => {
    const req = new Request(fileURL);

    const res = await fetch(req);

    const buffer = await res.arrayBuffer();
    const dataArray = new Uint8Array(buffer);
    setData(dataArray);
  };

  if (!data) {
    fetchPDFData();
  }

  if (data) {
    return (
      <Document
        loading="Loading..."
        file={{ data }}
        onLoadSuccess={pdf => setNumPages(pdf.numPages)}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={550} />
        ))}
      </Document>
    );
  }
  return <div />;
};

export default DocumentViewer;
