import { V } from "src/shared-styles";
const appStyles = {
  V,
  root: {
    fontFamily: "roboto, sans-serif",
    color: V.colors.black,
    height: "100%",
  },
  contentContainer: {
    // marginTop: V.topBarHeight,
    height: "100%",
  },
  errorBody: {
    background: V.colors.primary,
    height: "inherit",
  },
};

export default appStyles;
