import React from "react";
import dayjs from "dayjs";
import { Icon } from "antd";
import styled from "react-emotion";
import { File } from "src/types/api";
import GalleryPhoto from "src/containers/treatment/components/gallery/gallery-photo";

interface Props {
  file: File;
}

const avatarSize = 40;

const Content = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div {
    padding: 0.5rem;
    border-radius: 3px;

    img {
      display: block;
      max-width: 100%;
      max-height: 400px;
    }
  }

  small {
    font-size: small;
    opacity: 0.5;
    display: block;
    margin: 0.25rem 0;
  }
`;

const Wrapper = styled("div")`
  display: flex;
  margin: 0.75rem 0;
  flex-direction: row-reverse;
  margin-right: 20%;

  > span {
    width: ${avatarSize}px;
  }
  ${Content} {
    > div {
      background: #eee;
      white-space: pre-line;
    }
  }
`;

const GenericFileBox = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  max-width: 350px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #d6dee0;
  transition: background-color 0.2s ease-out, border 0.2s ease-out;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 0.5rem;
  padding: 0.5rem;
`;

const GenericFileItem = styled("a")`
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
`;

const getShortenedUrl = (url: string) => {
  if (url.length > 60) {
    return url.substr(0, 20) + "..." + url.substr(url.length - 20, url.length);
  }
  return url;
};

interface GenericFileProps {
  url: string;
}

export const GenericFile = (props: GenericFileProps) => {
  return (
    <GenericFileBox>
      <Icon style={{ fontSize: "16px", paddingRight: "0.5rem" }} type="file" />
      <div>
        <GenericFileItem href={props.url}>
          {getShortenedUrl(props.url)}
        </GenericFileItem>
      </div>
    </GenericFileBox>
  );
};

const Item = ({ file }: Props) => {
  if (file.file_type === "avatar") {
    // Just don't show these uploads
    return null;
  }

  return (
    <Wrapper>
      <Content>
        <div>
          {(() => {
            switch (file.file_type) {
              case "clinical_photo":
              case "selfie":
              case "chat_upload":
                if (!file.file.url.match(/.(jpg|jpeg|png|gif)$/i)) {
                  return <GenericFile url={file.file.url} />;
                }
                return (
                  <GalleryPhoto
                    images={[{ src: file.file.url, thumbnail: file.file.url }]}
                  />
                );
              default:
                return <GenericFile url={file.file.url} />;
            }
          })()}
        </div>
        <small>{dayjs(file.created_at).format("MM/DD/YYYY h:mma")}</small>
      </Content>
    </Wrapper>
  );
};

export default Item;
