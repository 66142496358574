import isNil from "lodash/fp/isNil";
import map from "lodash/fp/map";
import get from "lodash/fp/get";
import isEqual from "lodash/fp/isEqual";
import { useEffect } from "react";
import { usePrevious } from "react-use";
import { FormState } from "react-use-form-state";

import { useStaff } from "src/state/useStaff";

import { useClinics } from "../../queries";
import { taskLabels } from "../../constants";
import { taskLabelToTitleMap } from "../../mappings";
import {
  prepareClinicsForSelect,
  preparePipelineTypesForSelect,
  prepareTaskTypesForSelect,
  prepareKeysArrayForSelect,
  prepareStaffForSelect,
  filterPipelineTypes,
  filterTaskTypes,
} from "./utils";
import { FormValues } from "./types";

export const useHomeClinics = () => {
  const [{ data }] = useClinics();

  if (isNil(data)) {
    return [];
  }

  return prepareClinicsForSelect(data!.clinics) || [];
};

export const useAssignees = () => {
  const [{ data }] = useStaff(["id", "firstName", "lastName"]);

  return prepareStaffForSelect(data);
};

export const useFiltersData = (values: FormValues) => {
  const { pipelineCategories, pipelineTypes } = values;
  const homeClinicsOptions = useHomeClinics();
  const assigneesOptions = useAssignees();

  const pipelineTypesOptions = preparePipelineTypesForSelect(
    pipelineCategories
  );
  const taskTypesOptions = prepareTaskTypesForSelect(
    map(get("id"), pipelineTypesOptions),
    pipelineTypes
  );
  const labelsOptions = prepareKeysArrayForSelect(
    taskLabels,
    taskLabelToTitleMap
  );

  return {
    homeClinicIDs: homeClinicsOptions,
    assignees: assigneesOptions,
    pipelineTypes: pipelineTypesOptions,
    taskTypes: taskTypesOptions,
    taskCustomLabels: labelsOptions,
  };
};

export const useFormSideEffects = (formState: FormState<FormValues>) => {
  const { pipelineCategories, pipelineTypes } = formState.values;
  const prevPipelineCategories = usePrevious(pipelineCategories);
  const prevPipelineTypes = usePrevious(pipelineTypes);

  useEffect(() => {
    const isPipelineTypesChanged = !isEqual(prevPipelineTypes, pipelineTypes);
    const isPipelineCategoriesChanged = !isEqual(
      prevPipelineCategories,
      pipelineCategories
    );

    const shouldChangeTaskTypes =
      isPipelineTypesChanged || isPipelineCategoriesChanged;
    const shouldChangePipelineTypes = isPipelineCategoriesChanged;

    if (shouldChangeTaskTypes) {
      formState.setField("taskTypes", filterTaskTypes(formState.values));
    }

    if (shouldChangePipelineTypes) {
      formState.setField(
        "pipelineTypes",
        filterPipelineTypes(formState.values)
      );
    }
  }, [
    formState,
    prevPipelineCategories,
    prevPipelineTypes,
    pipelineTypes,
    pipelineCategories,
  ]);
};
