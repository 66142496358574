import React from "react";
import color from "src/styles/color";
import { css } from "react-emotion";
import Button, { ButtonRow } from "src/shared/Button";
import Modal from "src/shared/Modal";

type Props = {
  onClose: () => void;
  url: string;
};

export default ({ onClose, url }: Props) => (
  <Modal onClose={onClose}>
    <img src={url} className={modalImageCSS} alt="Checkin" />
    <ButtonRow position="right" className={modalButtonsCSS}>
      <Button kind="default" onClick={onClose}>
        Close
      </Button>
    </ButtonRow>
  </Modal>
);

const modalImageCSS = css`
  max-height: calc(100vh - 100px);
  max-width: calc(100vw - 100px);
`;

const modalButtonsCSS = css`
  padding: 8px;
  border-top: 1px solid ${color.borderLight};
`;
