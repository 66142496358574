import React from "react";
import Button from "src/shared/Button";
import LeftArrow from "src/shared/Icons/LeftArrow";
import RightArrow from "src/shared/Icons/RightArrow";
import time from "src/shared/time";
import { Perimeter, StyledButton } from "./styles";

const DatePickerToday = props => {
  const { date, onChange, selectedView, timezone } = props;
  const { addDays, localIsoDate, toISODate } = time(timezone);
  const to = selectedView === "Day" ? 1 : 7;
  const changeDay = (to: number) => () =>
    onChange(toISODate(addDays(date, to)));

  const today = localIsoDate();

  const handleToday = () => {
    onChange(today);
  };

  return (
    <Perimeter>
      <StyledButton
        style={{ padding: "0 16px 0 15px" }}
        onClick={changeDay(-to)}
      >
        <LeftArrow />
      </StyledButton>

      <Button style={{ margin: "0 16px" }} onClick={handleToday}>
        <strong>Today</strong>
      </Button>

      <StyledButton
        style={{ padding: "0 15px 0 16px", margin: "0" }}
        onClick={changeDay(to)}
      >
        <RightArrow />
      </StyledButton>
    </Perimeter>
  );
};

export default DatePickerToday;
