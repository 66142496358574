import styled, { css } from "react-emotion";
import color from "src/styles/color";

const width = 750;

export const draggingCSS = css`
  label {
    border-color: #ea6938;
  }
`;

export const Wrapper = styled.div`
  width: ${width}px;
  padding: 40px 40px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 24px;
  }
`;

export const Label = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px dashed ${color.border};
  box-sizing: border-box;
  border-radius: 12px;
  width: 96px;
  height: 96px;
  cursor: pointer;
  margin: 0 0 40px;

  input {
    visibility: hidden;
    height: 0;
    width: 0;
  }
`;

export const FileList = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1fr;
  grid-gap: 5px 5px;
  align-items: center;

  text-align: left;
  width: 100%;
  margin: 30px 0;

  > div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  input {
    width: 100%;
  }
`;

export const left = css`
  position: absolute;
  left: -45px;
  top: -45px;
`;

export const right = css`
  position: absolute;
  right: -45px;
  top: -45px;
`;
