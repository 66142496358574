import React from "react";
import { CrownProps, RootProps } from "./types";

export const Crown: React.SFC<CrownProps> = ({ fill, stroke, style }) => (
  <svg
    width="15"
    height="25"
    viewBox="0 0 15 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M11.2702 1H4.1873C1.77912 1 1.05902 2.61 1 3.415L1.17707 7.50193C1.53122 11.9604 2.77073 21.286 4.89559 22.9208C7.55168 24.9643 10.3848 23.2923 12.1556 19.0196C13.5721 15.6015 14.0443 9.91693 14.1034 7.50193V3.415C14.1034 1.483 12.2146 1 11.2702 1Z"
      fill={fill || "#fff"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />
  </svg>
);

// Root always contains the portion as if it attached as a Crown.
export const Root: React.SFC<RootProps> = ({
  hasRootCanal,
  style,
  fill,
  stroke,
}) => (
  <svg
    width="12"
    height="50"
    viewBox="0 0 12 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M3.47334 33.1643C2.93317 24.1985 1.50641 11.8792 1 6H3V1H8.5V6H10.5C10.4437 6.61242 10.2255 7.88364 10.2255 16.2615C10.2255 26.7339 8.36864 43.2693 6.84941 46.76C5.33018 50.2508 3.47334 47.8624 3.47334 42.1669V33.1643Z"
      fill={fill || "#F1F4F5"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />

    {hasRootCanal && (
      <g transform="translate(4.5,2)">
        <path
          d="M1 48.5C2.6 35.3 0.666667 6.5 0 1L3.5 0C2.7 16.8 2.16667 44.1667 1 48.5Z"
          fill="#C270A7"
        />
      </g>
    )}
  </svg>
);
