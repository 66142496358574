import styled from "react-emotion";
import color from "src/styles/color";

export default styled.div`
  display: grid;
  grid-template-columns: repeat(4, 2fr) repeat(2, 120px) 3fr;
  grid-auto-flow: column;
  grid-gap: 10px;
  align-items: center;
  min-height: 32px;

  border-top: 1px solid ${color.borderLight};
  padding: 12px 24px;

  background: #fff;
`;
