import React, { useState } from "react";
import { useFormState } from "react-use-form-state";
import { Link } from "react-router";
import styled from "react-emotion";
import { message } from "antd";
// shared
import color from "src/styles/color";
import DeleteConfirmModal from "src/shared/DeleteConfirmModal";
import Actions from "./Actions/Actions";
import Trash from "src/shared/Icons/Trash";
import Cross from "src/shared/Icons/Cross";
import DatePopover from "src/shared/Popovers/Date";
// local
import Category from "../Category";
import Snooze from "../Icons/Snooze";
import InvisibleButton from "../NewTask/InvisibleButton";
import { useDeleteTask, useSnoozeTask } from "../queries";
import { Task, TaskOption } from "../types";
import moment from "moment";

type Props = {
  task: Task;
  // If opened within the context of a pipeline, this refers to the current pipeline.
  // This allows us to add/remove tasks within the pipeline context.
  pipelineID?: string;

  showSnooze: boolean;
  onChange: (t: TaskOption, deleteFields?: string[]) => Promise<any>;

  // Close the task modal after deleting
  onClose: () => void;
};

const Header: React.FC<Props> = ({
  task,
  pipelineID,
  onClose,
  onChange,
  showSnooze,
}) => {
  const [, { text }] = useFormState({
    name: task.name,
  });
  const [showDelete, setShowDelete] = useState(false);
  const executeDelete = useDeleteTask();
  const executeSnooze = useSnoozeTask();

  const onSnooze = async (snoozeUntil: string | undefined) => {
    if (!snoozeUntil) return;

    const m = moment(snoozeUntil)
      .startOf("day")
      .toISOString();

    const response = await executeSnooze({
      input: {
        taskID: task.id,
        snoozeUntil: m,
      },
    });
    if (response.error) {
      message.error(`Error snoozing task: ${response.error}`);
      return;
    }
    onClose();
    message.success(`Task snoozed`);
  };

  const onDelete = async () => {
    const response = await executeDelete({ id: task.id });
    if (response.error) {
      setShowDelete(false);
      message.error(`Error deleting task: ${response.error}`);
      return;
    }
    setShowDelete(false);
    onClose();
    message.success(`Task deleted`);
  };

  return (
    <>
      <Wrapper>
        <div>
          <TaskName
            className="h1"
            {...text({
              name: "name",
              onBlur: e => {
                onChange({ name: e.target.value });
              },
            })}
          />
          <Required>
            <div>
              <Link to={`/patients/${task.user.id}`}>{task.user.name}</Link>
            </div>
            <div>
              <Category category={task.taskType.category} />
            </div>
          </Required>
        </div>

        <HeaderActions>
          <div>
            {showSnooze && (
              <DatePopover onClick={onSnooze}>
                <InvisibleButton>
                  <Snooze />
                </InvisibleButton>
              </DatePopover>
            )}
            <InvisibleButton onClick={() => setShowDelete(true)}>
              <Trash fill={color.gray4} />
            </InvisibleButton>
            <InvisibleButton onClick={onClose}>
              <Cross fill={color.gray4} />
            </InvisibleButton>
          </div>
          <div>
            <Actions pipelineID={pipelineID} task={task} onChange={onChange} />
          </div>
        </HeaderActions>
      </Wrapper>
      {showDelete && (
        <DeleteConfirmModal
          text="Do you want to delete this task?"
          onClose={() => setShowDelete(false)}
          onConfirm={onDelete}
          actionText="Delete"
        />
      )}
    </>
  );
};

export default Header;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto minmax(140px, 300px);
  grid-gap: 20px;
  border-bottom: 1px solid ${color.borderLight};

  h1 {
    padding: 6px 0 0;
  }

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const HeaderActions = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    justify-content: flex-end;
  }

  > div:first-of-type {
    margin-right: -16px;
  }

  > div:last-of-type {
    margin-top: 12px;
  }
`;

const Required = styled.div`
  display: flex;
  padding: 0 0 6px;

  > div + div {
    margin-left: 32px;
  }
`;

const TaskName = styled.input`
  border: 0 none;
  border-radius: 3px;
  padding: 12px 8px;
  margin: -12px 0 0 -8px;
  border: 1px solid #fff;
  width: 100%;

  &:hover {
    border: 1px solid ${color.border};
  }

  &:focus {
    outline: 0 none;
    box-shadow: 0 0 5px ${color.primary}88;
  }
`;
