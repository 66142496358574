import React from "react";
import styled, { css } from "react-emotion";
import color from "src/styles/color";

export const BaseButton = styled.button`
  white-space: nowrap;
  border-radius: 2px;
  line-height: 1;
  padding: 8px 12px;
  transition: all 0.3s;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 6px;
  }

  &:active {
    border-style: solid;
  }

  & + button {
    margin-left: 24px;
  }

  ${({
    theme: { background, borderColor, color, hover = {}, disabled = {} },
  }) => css`
    background: ${background};
    border: 1px ${borderColor || background} solid;
    color: ${color};
    cursor: pointer;

    &:hover {
      background: ${hover.background};
      border-color: ${hover.borderColor || hover.background};
      color: ${hover.color || color};
      text-shadow: none;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transform: translateY(-1px);
    }

    &:focus {
      outline: ${borderColor || background} auto 5px;
    }

    ${disabled &&
      css`
        &:disabled {
          background: ${disabled.background};
          border-color: ${disabled.borderColor || disabled.background};
          color: ${disabled.color};
          cursor: not-allowed;
        }
      `}
  `}
`;

export const SecondaryButton = props => (
  <BaseButton
    theme={{
      background: color.gray5,
      color: color.white,
      hover: {
        background: color.gray2,
        color: color.gray5,
      },
      disabled: {
        background: color.white,
        color: color.gray5,
        borderColor: color.gray2,
      },
    }}
    {...props}
  />
);

export const PrimaryButton = props => (
  <BaseButton
    theme={{
      background: color.primary,
      color: color.white,
      hover: {
        background: color.primaryHover,
      },
      disabled: {
        background: color.gray1,
        color: color.gray5,
      },
    }}
    {...props}
  />
);

export const PrimaryInvertButton = props => (
  <BaseButton
    theme={{
      background: color.white,
      color: color.primary,
      borderColor: color.primary,
      hover: {
        background: color.primaryShading,
        borderColor: color.primary,
      },
    }}
    {...props}
  />
);

export const LinkButton = props => (
  <BaseButton
    theme={{
      background: "transparent",
      color: color.primary,
      borderColor: "transparent",
      hover: {
        background: color.primaryShading,
        borderColor: color.primary,
      },
    }}
    {...props}
  />
);

export const LinkBlueButton = props => (
  <BaseButton
    theme={{
      background: "transparent",
      color: color.blue,
      borderColor: "transparent",
      hover: {
        borderColor: color.blue,
      },
    }}
    {...props}
  />
);

export const SecondaryInvertButton = props => (
  <BaseButton
    theme={{
      background: color.white,
      color: color.gray3,
      borderColor: color.gray2,
      hover: {
        background: color.gray2,
        color: color.gray5,
      },
    }}
    {...props}
  />
);

export const WarningButton = props => (
  <BaseButton
    theme={{
      background: color.backgroundWarning,
      color: color.red,
    }}
    {...props}
  />
);

export const DefaultButton = props => (
  <BaseButton
    theme={{
      background: color.white,
      color: color.gray4,
      borderColor: color.border,
      hover: {
        color: color.gray5,
        background: color.gray1,
        borderColor: color.border,
      },
    }}
    {...props}
  />
);

export const ClearButton = styled(DefaultButton)`
  max-width: 32px;
  max-height: 32px;
  display: flex;
  padding: 0;
  margin: 0;
  border: none !important;
  background: none !important;
  cursor: pointer;

  &:focus {
    outline: none;
    border: none !important;
  }
  &:active: {
    border: none !important;
  }
`;

export const InvisibleButton = styled.button`
  display: flex;
  align-items: center;
  border: 0;
  background: #fff;
  cursor: pointer;
  color: ${color.gray5};
  transition: all 0.3s;

  &:focus {
    outline: 0 none;
  }

  b {
    color: ${color.gray5};
  }

  svg {
    margin-right: 8px;
  }
`;

// ButtonRow styles
export const Row = styled.div`
  display: flex;
  flex-flow: row;
`;

export const right = css`
  justify-content: flex-end;
`;

export const center = css`
  justify-content: center;
`;
