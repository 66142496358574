import React from "react";
import styled from "react-emotion";
import { DetailedPatient } from "src/types/api";
import { useLastTreatment } from "src/state/useTreatments";
import { useCurrentTray } from "src/state/useTimeline";
import { useAttributesForToothChart } from "src/state/useAttributes";
import { getRxSummary } from "src/scenes/Form/Submission/TreatmentSetup/Summary";
import { useSubmissions, useAllSubmissions } from "./useFormUI";
import { Answer, Submission } from "./types";
import { useForm } from "./useFormsContext";
import isEmpty from "lodash/isEmpty";

// These consts are hard coded.  There's no nice way around this
const BVSlug = "beginning_visit";
const TxOptSlug = "treatment_option";
const PatientConcernsSlug = "patient_concerns";
const CategorizedConcernsSlug = "what_would_you_like_to_change";
const ChosenOptSlug = "is_chosen_option";
const NextApptNotesSlug = "next_visit_notes";

// Questions to pull out from tx opt
const TxOptSlugs = {
  generated_summary: "treatment_goals_generated_summary", // Generated summary via chosen opts
  type: "treatment_type",
  upper_bound: "rx_estimated_length_option_upper_bound",
};

// PatientInformation renders a section related to the patient and the
// specific appointment the form is related to.
//
// There are no questions in this section;  it's entirely custom and dynamic,
// which is a shame, but at least the content can be shared across any visit form
// after the patient is in treatment.

type Props = {
  patient: DetailedPatient;
  isIndirect: boolean;
};

const PatientInformation: React.FC<Props> = props => {
  const { isIndirect, patient } = props;

  const [{ data }] = useAllSubmissions(patient.id);
  const lastApptNotes = useLastAppointmentNotes(data ? data.submissions : []);
  const { categorizedConcerns, writtenConcerns } = usePatientConcerns(
    patient.id
  );
  const info = useTreatmentOptionInfo(patient.id);

  let rxInfo: any;
  if (isIndirect) {
    // This component can never render and call different # of hooks: it'll be
    // removed and re-rendered if any form changes.
    //
    // We also don't want to make 3 unnecessary GQL calls to find rx procedures
    // for non-indirect forms.
    //
    // eslint-disable-next-line
    rxInfo = useRxProcedures(patient.id);
  }

  return (
    <div>
      <Title>Original patient concerns from BV</Title>
      {categorizedConcerns || writtenConcerns ? (
        <>
          {categorizedConcerns && (
            <p key="categorizedConcerns">{categorizedConcerns.join(", ")}</p>
          )}

          {writtenConcerns && (
            <p key="writtenConcerns" style={{ whiteSpace: "pre" }}>
              {writtenConcerns}
            </p>
          )}
        </>
      ) : (
        <p key="noConcernsFound">None specified from beginning visit</p>
      )}

      <Title>Appointment notes from last visit:</Title>
      <p>{lastApptNotes || "None"}</p>

      <Title>Treatment type:</Title>
      <p>{info.type}</p>

      <Title>Estimated upper treatment time:</Title>
      <p>{info.upper_bound ? info.upper_bound + " months" : "Unknown"}</p>

      <Title>Generated rx summary:</Title>
      {info.generated_summary ? (
        info.generated_summary
          .split("\n")
          .map(item => <ListP key={item}>{item}</ListP>)
      ) : (
        <p>None</p>
      )}

      {isIndirect && (
        <>
          <Title style={{ marginTop: "1em" }}>Rx to perform today:</Title>
          {rxInfo
            ? getRxSummary(rxInfo).map(rx => <ListP key={rx}>{rx}</ListP>)
            : "None"}
        </>
      )}
    </div>
  );
};

export default PatientInformation;

// useLastAppointmentNotes returns the notes for the "next appointment notes" question
// from the last form submission.
const useLastAppointmentNotes = (
  submissions: Submission[]
): string | undefined => {
  if (submissions.length === 0) {
    return;
  }

  const answer = submissions[0].answers.find(
    a => a.questionSlug === NextApptNotesSlug
  );
  if (!answer) {
    return;
  }

  try {
    return JSON.parse(answer.answer);
  } catch (e) {}

  return;
};

const useSubmittedFormAnswer = (
  userID: string,
  formSlug,
  questionSlug
): any => {
  const form = useForm(formSlug);
  const [{ data }] = useSubmissions(userID, form ? form.id : "");
  const entries = data ? data.submissions : [];

  const findAnswer = (s: Submission): Answer | undefined =>
    s.answers.find(a => a.questionSlug === questionSlug);

  const first = entries
    .filter(s => !!findAnswer(s))
    .filter(s => !!s.approvedAt)
    .slice()
    .shift();

  const answerObject = first ? findAnswer(first) : undefined;
  if (!answerObject) {
    return;
  }

  try {
    return JSON.parse(answerObject.answer);
  } catch (e) {
    return;
  }
};

// usePatientConcerns returns the original patient concerns answer from the beginning
// visit form.
const usePatientConcerns = (
  userID: string
): {
  categorizedConcerns: string[] | undefined;
  writtenConcerns: string | undefined;
} => {
  const categorizedConcerns = useSubmittedFormAnswer(
    userID,
    BVSlug,
    CategorizedConcernsSlug
  );
  const writtenConcerns = useSubmittedFormAnswer(
    userID,
    BVSlug,
    PatientConcernsSlug
  );
  return {
    categorizedConcerns: !isEmpty(categorizedConcerns)
      ? (categorizedConcerns as string[])
      : undefined,
    writtenConcerns: writtenConcerns ? (writtenConcerns as string) : undefined,
  };
};

type TreatmentOptionInfo = {
  ortho_summary?: string;
  generated_summary?: string;
  type?: string;
  upper_bound?: number;
};

// useTreatmentOptionInfo returns chosen treatment option info for the patient.
const useTreatmentOptionInfo = (userID: string): TreatmentOptionInfo => {
  const form = useForm(TxOptSlug);
  const [{ data }] = useSubmissions(userID, form ? form.id : "");
  const entries = data ? data.submissions : [];

  // We only care about the chosen tx option.
  const first = entries
    .filter(
      s =>
        !!s.answers.find(
          a => a.questionSlug === ChosenOptSlug && a.answer === '"Yes"'
        )
    )
    .slice()
    .shift();

  if (!first) {
    return {} as TreatmentOptionInfo;
  }

  // Pull each q out of the form and add to resp using the same key as in TxOptSlugs
  const resp = {};
  Object.keys(TxOptSlugs).forEach(key => {
    const slug = TxOptSlugs[key];
    const answer = first.answers.find(a => a.questionSlug === slug);

    if (!answer) {
      return;
    }

    try {
      resp[key] = JSON.parse(answer.answer || "");
    } catch (e) {}
  });

  return resp as TreatmentOptionInfo;
};

// useRxProcedures returns all procedures that need to happen for this procedure.
const useRxProcedures = (userID: string) => {
  const attrs = useAttributesForToothChart(userID);
  const current = useCurrentTray(userID);
  const [{ data }] = useLastTreatment(userID);

  if (!current || attrs.length === 0 || !data) {
    return;
  }

  // To find the procedures necessary for this indirect delivery, we find the first
  // phase which requires an appointment that encompasses this tray and return the
  // associated RX items.
  //
  // We do this because the patient may be on trays just before the phase needing
  // an indirect delivery, or may be marked as one or two trays within this phase.

  // Find the current active TC
  const currentTC = data.treatment.treatmentCycles.find(
    tc => tc.startsAt + tc.maxCount > current.trayNumber
  );
  if (!currentTC) {
    return;
  }

  // And find the phase that needs an appointment, which encompasses this tray.
  const phase = currentTC.phases.find(p => {
    // Phase must end after this tray
    return (
      p.startsAt + Math.max(p.upperCount, p.lowerCount) > current.trayNumber &&
      !!p.appointmentSubtypeID
    );
  });

  if (!phase) {
    return;
  }

  return attrs.filter(
    a => a.entry_stage === "prescribed" && a.phase_id === phase.id
  );
};

const Title = styled.p`
  font-weight: bold;
  margin: 0 0 4px;
`;

const ListP = styled.p`
  margin: 0;
`;
