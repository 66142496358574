import { V, S } from "src/shared-styles";
export default {
  V,
  S,
  orderContainer: {
    padding: V.spacingSmall,
    borderBottom: V.border,
  },
  order: {
    display: "flex",
  },
  orderMain: {
    flex: 1,
    textAlign: "left",
    color: V.colors.fontPrimary,
  },
  orderDetails: {
    margin: V.spacingSmall,
  },
  details: {
    fontSize: V.fontSmall,
    color: V.colors.fontFaded,
  },
  actions: {
    textAlign: "right",
  },
  cost: {
    fontSize: V.fontLarge,
    color: V.colors.fontPrimary,
  },
  amount: {
    color: V.colors.fontPrimary,
    fontSize: V.fontLarge,
    marginTop: V.spacingSmall,
    marginBottom: V.spacingSmall,
  },
  container: {
    textAlign: "right",
    marginTop: V.spacing,
  },
  button: {
    float: "right",
    marginTop: V.spacing,
  },
  orderSegmentContainer: {
    margin: V.spacingSmall,
  },
  paymentType: {
    fontWeight: V.fontWeightHeavy,
    textTransform: "capitalize",
  },
  pay: {
    marginLeft: V.spacing,
  },
  paymentContainer: {},
  downloadPDF: {
    float: "left",
    marginTop: V.spacing,
  },
};
