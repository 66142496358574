import React from "react";
import { titleCase } from "src/shared/util";
import {
  Prewrap,
  SummaryField,
  SummaryFieldLabel,
  SummaryFieldContent,
} from "../styles";
import { getAnswer } from "../util";
import { NoteContentProps } from "./types";

export default function ReferralForm({ submission }: NoteContentProps) {
  const referral_type = getAnswer("referral_type_v2", submission);
  const referral_reason = (
    getAnswer("referral_reason", submission) || []
  ).filter(it => !!it);
  return (
    <>
      {referral_type && (
        <SummaryField key="referral_type_v2">
          <SummaryFieldLabel>Referral type</SummaryFieldLabel>
          <SummaryFieldContent>
            <Prewrap>{referral_type}</Prewrap>
          </SummaryFieldContent>
        </SummaryField>
      )}

      {referral_reason && referral_reason.length > 0 && (
        <SummaryField key="referral_reason">
          <SummaryFieldLabel>Referral reason</SummaryFieldLabel>
          <SummaryFieldContent>
            <Prewrap>{referral_reason.map(titleCase).join("\n")}</Prewrap>
          </SummaryFieldContent>
        </SummaryField>
      )}
    </>
  );
}
