import React from "react";
import { FileData } from "src/shared/file";
import { Wrapper, Item, Name, Percent, Bar } from "./styles";

type Props = {
  queue: ReadonlyArray<FileData>;
  current: ReadonlyArray<FileData>;
  complete: ReadonlyArray<FileData>;
  error: ReadonlyArray<FileData>;
};

const getUploadPercent = (f: FileData) => {
  return f.uploadPercent ? `${f.uploadPercent}%` : "Waiting";
};

const Progress = ({ queue, current, complete, error }: Props) => {
  if (queue.length === 0 && current.length === 0) {
    return null;
  }

  const errored: { [id: string]: boolean } = {};
  error.forEach(file => (errored[file.id] = true));

  const list = current
    .concat(queue, complete, error)
    .sort((a, b) => a.id.localeCompare(b.id));

  return (
    <Wrapper>
      {list.map(f => (
        <Item>
          <Name>{f.file.name}</Name>
          <Percent>
            {errored[f.id] === true ? "Error" : getUploadPercent(f)}
          </Percent>
          <Bar style={{ width: `${f.uploadPercent || 0}%` }} />
        </Item>
      ))}
    </Wrapper>
  );
};

export default Progress;
