import React, { useMemo } from "react";
import styled from "react-emotion";
import { css, cx } from "emotion";
import { titleCase } from "src/shared/util";
import Popover from "src/shared/Popover";
import SelectList from "src/shared/SelectList";
import { Tabs, Tab } from "src/shared/Tabs";
import { useStateContext } from "./state";
import { uiAttributesForDisplay } from "./consts";
import { UIAttribute, AttributeType } from "./types";
import color from "src/styles/color";

type Props = {
  editable: boolean;
};

const Toolbar: React.FC<Props> = ({ editable }) => {
  const [
    { display, availableDisplayTypes, selectedTool, readOnlyDisplayTypes },
    dispatch,
  ] = useStateContext();

  const categories = useMemo(() => uiAttributesForDisplay(display), [display]);

  const onSelect = (t: AttributeType, data?: string) => {
    dispatch({
      type: "toggleTool",
      selectedTool: t,
      data,
    });
  };

  return (
    <ToolbarContent>
      <Tabs>
        {availableDisplayTypes.map(dt => (
          <Tab
            key={dt}
            theme={{ active: display === dt }}
            onClick={() => {
              dispatch({
                type: "display",
                display: dt,
              });
            }}
          >
            {titleCase(dt)}
          </Tab>
        ))}
      </Tabs>

      {display !== "cumulative" &&
        editable &&
        !readOnlyDisplayTypes.includes(display) && (
          <ToolsScroll>
            <ToolsContent>
              {Object.keys(categories).map(cat => {
                if (categories[cat].length === 0) {
                  return null;
                }

                return (
                  <Group>
                    <div>
                      {categories[cat].map((a: UIAttribute) => (
                        <Tool
                          key={a.type}
                          attribute={a}
                          selected={
                            selectedTool ? selectedTool.type === a.type : false
                          }
                          onClick={onSelect}
                        />
                      ))}
                    </div>
                    <span>{cat}</span>
                  </Group>
                );
              })}
            </ToolsContent>
          </ToolsScroll>
        )}
    </ToolbarContent>
  );
};

export default Toolbar;

type ToolProps = {
  attribute: UIAttribute;
  selected: boolean;
  onClick: (a: AttributeType, data?: string) => void;
};

const Tool: React.SFC<ToolProps> = ({ attribute, onClick, selected }) => {
  const children = (
    <ToolWrapper
      onClick={() => onClick(attribute.type)}
      className={cx([selected && selectedCSS])}
    >
      {attribute.icon}
      <span>{attribute.displayName}</span>
      {attribute.dropdown && <DownArrow />}
    </ToolWrapper>
  );

  if (!attribute.dropdown) {
    return children;
  }

  const popoverContent = (
    <SelectList<string>
      values={attribute.dropdown}
      value={[]}
      headings={typeof attribute.dropdown[0] !== "string"}
      onClick={(p?: string) => p && onClick(attribute.type, p)}
      render={(p?: string) => <span key={p}>{p}</span>}
    />
  );

  return (
    <Popover position="bottom" align="center" content={popoverContent}>
      {children}
    </Popover>
  );
};

const ToolbarContent = styled.div`
  background: #fff;
  border-bottom: 1px solid ${color.borderLight};

  ${Tab} {
    padding-top: 11px;
    padding-bottom: 10px;
  }
`;

const selectedCSS = css`
  background: #f1f4f5;
  font-weight: bold;
`;

const ToolsScroll = styled.div`
  border-top: 1px solid ${color.borderLight};
  overflow: auto;
  text-align: center;
`;

const ToolsContent = styled.div`
  padding: 8px;
  display: inline-flex;
  justify-content: center;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    display: flex;
  }

  span {
    font-size: 10px;
  }

  padding-right: 5px;
  & + & {
    padding-left: 5px;
    border-left: 1px solid ${color.borderLight};
  }
`;

const ToolWrapper = styled.div`
  width: 65px;
  padding: 0 3px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 2px;
  font-size: 10px;
  margin-right: 1px;
  font-weight: bold;
  color: #194f5b;

  img {
    margin-bottom: 3px;
  }

  &:hover {
    background: #f1f4f599;
  }
`;

const DownArrow = () => (
  <svg
    width="6"
    height="5"
    viewBox="0 0 6 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.72058 4.25115C3.32718 4.65999 2.67282 4.65999 2.27942 4.25115L0.299325 2.19338C-0.311996 1.55807 0.138241 0.5 1.0199 0.5L4.9801 0.500001C5.86176 0.500001 6.312 1.55807 5.70068 2.19338L3.72058 4.25115Z"
      fill="#194F5B"
    />
  </svg>
);
