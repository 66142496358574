import React, { useState } from "react";
import { Drawer } from "antd";
import orderBy from "lodash/orderBy";

import { LayoutGrid, SelectedContent } from "./styles";
import Header from "./header/Header";
import Timeline from "./timeline/Timeline";
import { Patient, Appointment } from "src/types/gql";
import { ClinicalRecord } from "src/types/api";
import GQLForms from "src/scenes/PatientProfile/Forms/GQLForms/Forms";
import { useTimelineFiles, useTimelineFormSubmissions } from "./queries";
import {
  TimelineEntry,
  filterSubmissions,
  buildTimelineEntries,
  parseSelection,
  getSelectedKey,
} from "./util";
import SummaryContent from "./summary-content/SummaryContent";
import NotesContent from "./notes-content/NotesContent";
import Loading from "src/shared/Loading";

export interface TimelineParams {
  timelineEntryType?: string;
  timelineEntryID?: string;
  selectedTab?: string;
}

export interface Props {
  patient: Patient;
  clinicalRecord?: ClinicalRecord;
  appointments: Array<Appointment>;
  params: TimelineParams;
}

export default function PatientTimeline({
  patient,
  clinicalRecord,
  appointments,
  params,
}: Props) {
  const [openedSubmission, setOpenedSubmission] = useState<
    TimelineEntry["submission"] | null
  >(null);
  const selectedTab = params.selectedTab === "notes" ? "notes" : "summary";
  const selection = parseSelection(
    params.timelineEntryType,
    params.timelineEntryID
  );
  const [{ data: filesData, fetching: fetchingFiles }] = useTimelineFiles(
    patient.id
  );
  const [
    { data: submissionsData, fetching: fetchingSubmissions },
  ] = useTimelineFormSubmissions(patient.id);

  if (fetchingSubmissions || fetchingFiles) {
    return <Loading />;
  }

  const hasAppointments = appointments.length > 0;
  const hasSubmissions =
    submissionsData && submissionsData.submissions.length > 0;
  if (!hasAppointments && !hasSubmissions) {
    return <div>There is no timeline data for this patient.</div>;
  }

  const allSubmissions = submissionsData ? submissionsData.submissions : [];

  const submissions = filterSubmissions(allSubmissions);
  const entries = buildTimelineEntries(patient.id, appointments, submissions);
  if (entries.length === 0) {
    return null;
  }

  const selectedKey = getSelectedKey(entries, selection);
  const selectedEntry = entries.find(
    it => selectedKey === it.key
  ) as TimelineEntry;

  const sortedFiles = filesData
    ? orderBy(filesData.files, [
        ({ createdAt }) => createdAt,
        ({ description, filename }) => description || filename,
      ])
    : [];

  return (
    <>
      <LayoutGrid>
        <Header selectedEntry={selectedEntry} selectedTab={selectedTab} />
        <Timeline
          patientID={patient.id}
          selectedEntry={selectedEntry}
          entries={entries}
          sortedFiles={sortedFiles}
          onClickSubmission={setOpenedSubmission}
        />
        <SelectedContent>
          {selectedTab === "notes" ? (
            <NotesContent patient={patient} />
          ) : (
            <SummaryContent
              patient={patient}
              clinicalRecord={clinicalRecord}
              timelineEntry={selectedEntry}
              sortedFiles={sortedFiles}
            />
          )}
        </SelectedContent>
      </LayoutGrid>

      {openedSubmission && (
        <Drawer
          width="70%"
          onClose={() => setOpenedSubmission(null)}
          closable
          visible
        >
          <GQLForms
            params={{
              formKey: openedSubmission.form.slug,
              submissionId: openedSubmission.id,
            }}
            patient={patient as any}
          />
        </Drawer>
      )}
    </>
  );
}
