import React from "react";
import color from "src/styles/color";

type Props = {
  fill?: string;
  background?: string;
};

const MagnifyingGlass = ({
  fill = color.gray3,
  background = color.white,
}: Props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.72738 7.63635H7.63647V8.72726L10.9092 12L12.0001 10.9091L8.72738 7.63635Z"
      fill={fill}
    />
    <circle cx="4.36364" cy="4.36364" r="4.36364" fill={fill} />
    <circle cx="4.36355" cy="4.36367" r="3.27273" fill={background} />
  </svg>
);

export default MagnifyingGlass;
