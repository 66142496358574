import styled from "react-emotion";
import { Menu } from "antd";
import color from "src/styles/color";
import { Link } from "react-router";
import Button from "src/shared/Button";

export const Perimeter = styled.div`
  min-height: 88px;
  background: ${color.white};
  padding: 0 24px;
  display: flex;
  z-index: 10;
  border-bottom: 1px solid ${color.border};
  position: sticky;
  top: 0;

  @media (max-width: 1280px) {
    flex-wrap: wrap;
  }

  @media (max-width: 900px) {
    padding: 0 16px;
  }
`;

export const Photo = styled.div`
  margin-top: 15px;
  margin-right: 11px;

  .avatar {
    width: 56px;
    height: 56px;
    line-height: 56px;
    font-size: 28px;

    @media (max-width: 900px) {
      width: 32px;
      height: 32px;
      line-height: 32px;
      font-size: 18px;
    }
  }
  @media (max-width: 1280px) {
    order: 1;
  }
`;

export const Info = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1280px) {
    order: 3;
    position: absolute;
    left: 96px;
  }

  @media (max-width: 900px) {
    left: 64px;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  @media (max-width: 1280px) {
    order: 2;
    padding-bottom: 32px;
  }
`;

export const MenuLink = styled(Link)`
  position: relative;
  font-size: 15px;
  color: ${color.black};
  font-weight: 500;
`;

export const StyledButton = styled(Button)`
  margin-left: 10px;

  &.ant-popover-open > div {
    background: ${color.gray1} !important;
  }
`;

export const RightButtons = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 12px;
`;

export const StyleMenu = styled(Menu)`
  min-width: 160px;
  & .ant-dropdown-menu-item:hover {
    background-color: ${color.gray1};
  }
`;

export const InfoTop = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PatientName = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.003em;

  @media (max-width: 900px) {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
  }
`;

export const PatientPronoun = styled.span`
  font-size: 18px;
  line-height: 1.4;
  transition: all 0.1s;
  margin-left: 5px;
  padding-top: 3px;

  @media (max-width: 900px) {
    font-size: 14px;
    padding-top: 4px;
  }
`;

export const PatientStatus = styled.div`
  margin-left: 8px;
  color: ${color.orange};
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  background: ${color.primaryShading};
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  letter-spacing: 0.003em;

  @media (max-width: 900px) {
    padding-top: 0px;
  }
`;

export const InfoBottom = styled.div`
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > li {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-right: 1em;
    color: ${color.gray3};
  }
  > li :first-child {
    list-style: none;
  }
`;
