import { find, orderBy, get } from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { connect } from "react-redux";

import { setPatientOfficeSyncFolder } from "src/redux/modules/patient";
import { load as loadClinicalRecord } from "src/redux/modules/clinical-record";
import { put as putTask } from "src/redux/modules/task-list";

import { getNewEmptyForm } from "./form-helper";
import FormSidebar from "./form-sidebar";
import Question from "../../components/question/question";
import EventCard from "src/containers/treatment/components/event-card/event-card";

import { ISSUE_LIST_FORM, QUESTION_FORM } from "src/config/event-type";
import styles from "./form-style";
import { routerShape } from "react-router/lib/PropTypes";
import ToothChart from "../../components/tooth-chart/tooth-chart";
import {
  fetchCheckinsForPatient,
  getCheckinsForPatientSelector,
} from "src/redux/modules/checkin";
import FormActionButton from "./form-action-buttons";
import { OFFICE_SYNC_FOLDERS } from "src/config/constant";
import { getFileListSelector, loadFiles } from "src/redux/modules/fileList";
import DoctorInput from "../../components/doctor-input/doctor-input";
import { FORM_NAME } from "src/config/treatment-forms";

const enhance = connect(
  (state, props) => ({
    checkins: getCheckinsForPatientSelector(state, props),
    selfie: getFileListSelector(state),
  }),
  {
    putTask,
    setPatientOfficeSyncFolder,
    loadClinicalRecord,
    fetchCheckinsForPatient,
    loadSelfie: loadFiles,
  }
);

class Form extends Component {
  static propTypes = {
    staffSubmittedData: PropTypes.array,
    patient: PropTypes.object,
    addEvent: PropTypes.func,
    updateEvent: PropTypes.func,
    syncFolderPath: PropTypes.string,
  };
  static contextTypes = {
    router: routerShape,
    patient: PropTypes.object,
  };
  constructor() {
    super();
    this.state = {
      localOfficeSyncFolder: OFFICE_SYNC_FOLDERS[0],
      waitingForFolderToCreate: false,
    };
  }
  componentWillMount = () => {
    const {
      params: { formName },
    } = this.props;
    this.generatedNewForm = getNewEmptyForm(formName);
  };
  componentDidMount = () => {
    this.props.loadSelfie(this.props.patient.id);
    this.props.fetchCheckinsForPatient({ patientId: this.props.patient.id });
  };

  shouldShowDoctorInput = () => {
    return [FORM_NAME.VP, FORM_NAME.VC, FORM_NAME.VFC].includes(
      this.props.params.formName
    );
  };
  renderFormContent = () => {
    const event = this.event;
    if (event.type === ISSUE_LIST_FORM) {
      return event.issueList.map((issue, issueIndex) => {
        return this.renderIssue(issue, issueIndex);
      });
    } else if (event.type === QUESTION_FORM) {
      return event.questionList.map((question, questionIndex) => (
        <Question
          patient={this.props.patient}
          disabled={this.posted}
          patientUUID={this.props.patient.id}
          pathToQuestion={`questionList[${questionIndex}]`}
          event={event}
          updateEvent={this.props.updateEvent}
          key={questionIndex}
        />
      ));
    }
    return <div>Form type not recognized</div>;
  };

  renderIssue = (issue, issueIndex) => {
    const event = this.event;
    const issueDisplayName = issue.displayName;
    return (
      <div key={issueDisplayName} style={styles.issueContainer}>
        <div style={styles.issueDisplayName}>{issueDisplayName}</div>
        <div style={styles.questionListContainer}>
          {issue.questionList.map((question, questionIndex) => (
            <Question
              disabled={this.posted}
              patient={this.props.patient}
              pathToQuestion={`issueList[${issueIndex}].questionList[${questionIndex}]`}
              event={event}
              updateEvent={this.props.updateEvent}
              key={questionIndex}
              hideQuestionDisplayName
            />
          ))}
        </div>
        <hr />
      </div>
    );
  };

  renderMainContent = () => {
    const {
      params: { formName },
    } = this.props;
    const posted = this.posted;
    return (
      <div className={"test-form-container"} style={styles.mainPanel}>
        {this.shouldShowDoctorInput() && (
          <DoctorInput addEvent={this.props.addEvent} />
        )}
        <EventCard
          key={this.event.uuid || formName}
          event={this.event}
          style={posted ? styles.disabled : {}}
        >
          {this.renderFormContent()}
          <FormActionButton
            staffSubmittedData={this.props.staffSubmittedData}
            event={this.event}
            patient={this.props.patient}
            referringPagePath={get(
              this.props,
              "location.state.referringPagePath"
            )}
            referringTaskId={get(this.props, "location.state.referringTaskId")}
            updateEvent={this.props.updateEvent}
            addEvent={this.props.addEvent}
          />
        </EventCard>
        {this.event.attachToothChart && (
          <ToothChart
            toothChart={this.event.toothchart}
            embedded
            readOnly={this.event.complete}
          />
        )}
      </div>
    );
  };

  render() {
    const {
      params: { formName, formUUID },
      staffSubmittedData,
    } = this.props;
    const orderedEventList = orderBy(
      staffSubmittedData,
      "postTimestamp",
      "desc"
    );
    const latestFormEvent = find(orderedEventList, { formName }); // find first
    const specificFormUUID =
      formUUID && find(orderedEventList, { uuid: formUUID });

    this.event = specificFormUUID || latestFormEvent || this.generatedNewForm;
    this.posted = !!this.event.complete;

    return (
      <div style={styles.S.container}>
        <Helmet title={formName} />
        <div>
          <div style={styles.S.content}>
            {this.renderMainContent()}
            <FormSidebar
              staffSubmittedData={this.props.staffSubmittedData}
              formData={this.event}
              formName={this.event.formName}
              patient={this.props.patient}
              selfie={this.props.selfie}
              syncFolderPath={this.props.syncFolderPath}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default enhance(Form);
