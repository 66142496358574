import React from "react";

const JPG = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1342 0H4.55023C4.08852 0 3.71423 0.374286 3.71423 1.10057V31.4286C3.71423 31.6257 4.08852 32 4.55023 32H27.4497C27.9114 32 28.2857 31.6257 28.2857 31.4286V7.416C28.2857 7.01829 28.2325 6.89029 28.1388 6.796L21.4897 0.146857C21.3954 0.0531429 21.2674 0 21.1342 0Z"
      fill="#E9E9E0"
    />
    <path
      d="M21.4285 0.0862846V6.85714H28.1993L21.4285 0.0862846Z"
      fill="#D9D7CA"
    />
    <path
      d="M10.8176 10.8571C12.2596 10.8571 13.4285 9.68822 13.4285 8.24629C13.4285 6.80435 12.2596 5.63543 10.8176 5.63543C9.37571 5.63543 8.20679 6.80435 8.20679 8.24629C8.20679 9.68822 9.37571 10.8571 10.8176 10.8571Z"
      fill="#C9C9C9"
    />
    <path
      d="M3.71423 22.2857H9.99995H28.2857V16L22.5714 10.5714L16.5714 17.1429L13.4382 14.0097L3.71423 22.2857Z"
      fill="#C9C9C9"
    />
    <path
      d="M27.4497 32H4.55023C4.08852 32 3.71423 31.6257 3.71423 31.164V22.2857H28.2857V31.164C28.2857 31.6257 27.9114 32 27.4497 32Z"
      fill="#A2A9AD"
    />
    <path
      d="M9.32072 29.2457V30.2857H10.1447C11.0807 30.2857 11.6407 29.7497 11.6407 28.6137V24.6857H10.4407V28.5577C10.4407 29.0297 10.3127 29.2457 9.90472 29.2457H9.32072ZM12.563 30.2857H13.771V28.2217H14.867C16.059 28.2217 16.755 27.4777 16.755 26.4537C16.755 25.4217 16.059 24.6857 14.867 24.6857H12.563V30.2857ZM13.771 27.2217V25.6857H14.795C15.355 25.6857 15.595 26.0457 15.595 26.4537C15.595 26.8617 15.355 27.2217 14.795 27.2217H13.771ZM19.9185 30.3497C20.6385 30.3497 21.2305 29.9817 21.4145 29.4297V30.2857H22.4785V28.1257C22.4785 27.6377 22.0945 27.2217 21.5745 27.2217H20.0545V28.1257H21.1345C21.2705 28.1257 21.3585 28.2137 21.3585 28.3497C21.3585 28.8697 20.6705 29.3737 19.9745 29.3737C19.0465 29.3737 18.3105 28.6457 18.3105 27.4857C18.3105 26.3257 19.0465 25.6537 19.9985 25.6537C20.7185 25.6537 21.2145 26.0457 21.3025 26.6457H22.4945C22.4225 25.4457 21.4145 24.6217 20.0065 24.6217C18.5265 24.6217 17.1665 25.5737 17.1665 27.4857C17.1665 29.3977 18.4865 30.3497 19.9185 30.3497Z"
      fill="white"
    />
  </svg>
);

export default JPG;
