import { includes, get } from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { destroy as destroyPatient } from "src/redux/modules/patient";

import { Dialog, FlatButton, TextField } from "material-ui";
import { connect } from "react-redux";
import { routerShape } from "react-router";

const connected = connect(
  null,
  { destroyPatient }
);
export class DeleteUser extends Component {
  static contextTypes = {
    patient: PropTypes.object,
    router: routerShape,
  };
  state = {
    text: "",
  };
  isDeleteAble = () => {
    if (
      !includes(
        this.state.text.toLowerCase(),
        this.context.patient.first_name.toLowerCase()
      )
    )
      return false;
    if (
      !includes(
        this.state.text.toLowerCase(),
        this.context.patient.last_name.toLowerCase()
      )
    )
      return false;
    return true;
  };
  handleTextChange = (e, value) => {
    this.setState({ text: value });
  };
  handleDelete = () => {
    this.props.destroyPatient(this.context.patient.id).then(() => {
      this.context.router.push("/");
    });
  };

  render() {
    if (!get(this, "context.patient.first_name")) return null;

    const actions = [
      <FlatButton label="Cancel" onClick={this.handleClose} />,
      <FlatButton
        disabled={!this.isDeleteAble()}
        label="Delete"
        primary
        keyboardFocused
        onClick={this.handleDelete}
      />,
    ];

    return (
      <Dialog
        title="Are you sure you want to delete this user?"
        actions={actions}
        modal={false}
        open={this.props.open}
        onRequestClose={this.props.handleClose}
      >
        <h3>Type the user's full name to delete</h3>
        <TextField
          id="textfield-delete-user"
          onChange={this.handleTextChange}
        />
      </Dialog>
    );
  }
}

export default connected(DeleteUser);
