import { ISSUE_LIST_FORM } from "../event-type";
import {
  ANY,
  LINE_BREAK,
  RETAINER,
  TREATMENT_TYPE_COMPREHENSIVE,
} from "src/config/constant";
// shaped like event

const RRX_TEMPLATE = {
  attachToothChart: true,
  postTimestamp: null,
  postUserName: null,
  postUserUUID: null,
  complete: false,
  formName: "rrx", // this must match the name of the form in config/treatment-forms/index
  type: ISSUE_LIST_FORM,
  issueList: [
    {
      displayName: "Chief concerns",
      questionList: [
        {
          conditionId: "chiefConcern",
          displayName: "Exam",
          type: "multiselect",
          possibleAnswerList: [
            "Crowding",
            "Spacing",
            "Bad Bite",
            "Protruding Teeth",
            "Jaw Joint Issues",
            "Replacement Retainers",
            "Whitening",
            "Night Guard",
            "Other",
          ],
        },
        {
          displayCondition: {
            id: "chiefConcern",
            answer: ["Other"],
          },
          displayName: 'Other - if exam selection is "Other"',
          type: "text",
        },
        {
          displayCondition: {
            id: "chiefConcern",
            answer: ANY,
          },
          displayName: "Goal",
          type: "select",
          possibleAnswerList: ["Address", "Reject & Refer"],
        },
      ],
    },
    {
      displayName: "TMJ",
      questionList: [
        {
          conditionId: "tmj",
          displayName: "Exam",
          type: "multiselect",
          possibleAnswerList: [
            "Within Norm Limits",
            "Hist of Pain",
            " Hist of Clicking",
            "Current Pain",
            "Current Clicking",
          ],
        },
        {
          displayName: "Goal",
          type: "select",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "tmj",
                answer: ["Within Norm Limits"],
              },
              displayName: "Maintain",
            },
            {
              displayCondition: {
                id: "tmj",
                answer: [
                  "Hist of Pain",
                  " Hist of Clicking",
                  "Current Pain",
                  "Current Clicking",
                ],
              },
              displayName: "Improve",
            },
            {
              displayCondition: {
                id: "tmj",
                answer: [
                  "Hist of Pain",
                  " Hist of Clicking",
                  "Current Pain",
                  "Current Clicking",
                ],
              },
              displayName: "Reject & Refer",
            },
          ],
        },
        {
          displayName: "Prescription",
          type: "select",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "tmj",
                answer: ["Within Norm Limits"],
              },
              displayName: "No Alterations to TP",
            },
          ],
        },
      ],
    },
    {
      displayName: "Caries",
      questionList: [
        {
          conditionId: "caries",
          displayName: "Exam",
          type: "select",
          possibleAnswerList: ["Apparent Caries Free", "Caries Present"],
        },
        {
          displayCondition: {
            id: "caries",
            answer: ["Caries Present"],
          },
          displayName: "Exam II",
          type: "select",
          possibleAnswerList: ["Confidence: high", "Confidence: low"],
        },
        {
          displayName: "Goal",
          type: "select",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "caries",
                answer: ["Apparent Caries Free"],
              },
              displayName: "Maintain",
            },
            {
              displayCondition: {
                id: "caries",
                answer: ["Caries Present"],
              },
              displayName: "Improve",
            },
            {
              displayCondition: {
                id: "caries",
                answer: ["Caries Present"],
              },
              displayName: "Reject",
            },
          ],
        },
        {
          displayName: "Prescription",
          type: "select",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "caries",
                answer: ["Apparent Caries Free"],
              },
              displayName: "No Alterations to TP",
            },
            {
              displayCondition: {
                id: "caries",
                answer: ["Caries Present"],
              },
              displayName: "Refer",
            },
          ],
        },
      ],
    },
    {
      displayName: "Perio Status",
      questionList: [
        {
          conditionId: "perio",
          displayName: "Exam",
          type: "select",
          possibleAnswerList: ["Apparent Healthy", "Unhealthy"],
        },
        {
          displayCondition: {
            id: "perio",
            answer: ["Unhealthy"],
          },
          displayName: "Exam II",
          type: "select",
          possibleAnswerList: ["Confidence: high", "Confidence: low"],
        },
        {
          displayName: "Goal",
          type: "select",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "perio",
                answer: ["Apparent Healthy"],
              },
              displayName: "Maintain",
            },
            {
              displayCondition: {
                id: "perio",
                answer: ["Unhealthy"],
              },
              displayName: "Improve",
            },
            {
              displayCondition: {
                id: "perio",
                answer: ["Unhealthy"],
              },
              displayName: "Reject",
            },
          ],
        },
        {
          displayName: "Prescription",
          type: "select",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "perio",
                answer: ["Apparent Healthy"],
              },
              displayName: "No Alterations to TP",
            },
            {
              displayCondition: {
                id: "perio",
                answer: ["Unhealthy"],
              },
              displayName: "Refer",
            },
          ],
        },
      ],
    },
    {
      displayName: "Profile",
      questionList: [
        {
          conditionId: "profile",
          displayName: "Exam",
          type: "select",
          possibleAnswerList: [
            "Convex",
            "Straight",
            "Concave",
            "Need More Information",
          ],
        },
        {
          displayName: "Goal",
          type: "select",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "profile",
                answer: ["Convex", "Straight", "Concave"],
              },
              displayName: "Maintain",
            },
            {
              displayCondition: {
                id: "profile",
                answer: ["Convex", "Concave"],
              },
              displayName: "Improve",
            },
            {
              displayCondition: {
                id: "profile",
                answer: ["Convex", "Concave"],
              },
              displayName: "Reject",
            },
          ],
        },
        {
          displayName: "Prescription",
          type: "multiselect",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "profile",
                answer: ["Straight"],
              },
              displayName: "No Alterations to TP",
            },
            {
              displayCondition: {
                id: "profile",
                answer: ["Convex"],
              },
              displayName: "Reduce Protrusion of Upper Incisors with IPR",
            },
            {
              displayCondition: {
                id: "profile",
                answer: ["Concave"],
              },
              displayName: "Expansion of Arch Width",
            },
            {
              displayCondition: {
                id: "profile",
                answer: ["Concave"],
              },
              displayName: "Procline Anterior Teeth",
            },
            {
              displayCondition: {
                id: "profile",
                answer: ANY,
              },
              displayName: "Refer",
            },
          ],
        },
      ],
    },
    {
      displayName: "Growth Pattern",
      questionList: [
        {
          conditionId: "growth",
          displayName: "Exam",
          type: "select",
          possibleAnswerList: [
            "Extremely Hyperdivergent",
            "Hyperdivergent",
            "Normal",
            "Hypodivergent",
            "Extreme Hypodivergent",
            "Need more Information",
          ],
        },
        {
          displayName: "Goal",
          type: "select",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "growth",
                answer: [
                  "Extremely Hyperdivergent",
                  "Hyperdivergent",
                  "Normal",
                  "Hypodivergent",
                  "Extreme Hypodivergent",
                ],
              },
              displayName: "Maintain",
            },
            {
              displayCondition: {
                id: "growth",
                answer: [
                  "Extremely Hyperdivergent",
                  "Hyperdivergent",
                  "Hypodivergent",
                  "Extreme Hypodivergent",
                ],
              },
              displayName: "Improve",
            },
            {
              displayCondition: {
                id: "growth",
                answer: [
                  "Extremely Hyperdivergent",
                  "Hyperdivergent",
                  "Hypodivergent",
                  "Extreme Hypodivergent",
                ],
              },
              displayName: "Reject",
            },
          ],
        },
        {
          conditionId: "growthOther",
          displayName: "Prescription",
          type: "select",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "growth",
                answer: ["Normal"],
              },
              displayName: "No Alterations to TP",
            },
            {
              displayCondition: {
                id: "growth",
                answer: ["Extremely Hyperdivergent", "Hyperdivergent"],
              },
              displayName: "Improve with posterior intrusion ",
            },
            {
              displayCondition: {
                id: "growth",
                answer: ["Hypodivergent", "Extreme Hypodivergent"],
              },
              displayName: "Improve with posterior extrusion ",
            },
            {
              displayCondition: {
                id: "growth",
                answer: [
                  "Extremely Hyperdivergent",
                  "Hyperdivergent",
                  "Hypodivergent",
                  "Extreme Hypodivergent",
                  "Need more Information",
                ],
              },
              displayName: "Refer",
            },
            {
              displayCondition: {
                id: "growth",
                answer: [
                  "Extremely Hyperdivergent",
                  "Hyperdivergent",
                  "Hypodivergent",
                  "Extreme Hypodivergent",
                  "Need more Information",
                ],
              },
              displayName: "Other",
            },
          ],
        },
        {
          displayCondition: {
            id: "growthOther",
            answer: ["Other"],
          },
          displayName: 'Other - if selection above is "Other"',
          type: "text",
        },
      ],
    },
    {
      displayName: "Frontal",
      questionList: [
        {
          conditionId: "front",
          displayName: "Exam",
          type: "select",
          possibleAnswerList: [
            "Normal",
            "Mx to Left",
            "Mx to Right",
            "Mn to Left",
            "Mn to Right",
          ],
        },
        {
          conditionId: "frontGoal",
          displayName: "Goal",
          type: "select",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "front",
                answer: ["Normal"],
              },
              displayName: "Maintain",
            },
            {
              displayCondition: {
                id: "front",
                answer: [
                  "Mx to Left",
                  "Mx to Right",
                  "Mn to Left",
                  "Mn to Right",
                ],
              },
              displayName: "Improve",
            },
            {
              displayCondition: {
                id: "front",
                answer: [
                  "Mx to Left",
                  "Mx to Right",
                  "Mn to Left",
                  "Mn to Right",
                ],
              },
              displayName: "Reject",
            },
          ],
        },
        {
          displayName: "Prescription",
          type: "select",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "front",
                answer: ["Normal"],
              },
              displayName: "No Alterations to TP",
            },
            {
              displayCondition: {
                id: "frontGoal",
                answer: ["Improve"],
              },
              displayName: "Compensate Teeth",
            },
            {
              displayCondition: {
                id: "frontGoal",
                answer: ["Reject"],
              },
              displayName: "Refer",
            },
          ],
        },
      ],
    },
    {
      displayName: "Aesthetic - 1 (most beautiful) to 10 (most unaesthetic)",
      questionList: [
        {
          conditionId: "aesthetic",
          displayName: "Exam",
          type: "select",
          dataKey: "aesthetic",
          possibleAnswerList: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
          ],
        },
        {
          conditionId: "aestheticGoal",
          displayCondition: {
            id: "aesthetic",
            answer: ANY,
          },
          displayName: "Goal",
          type: "select",
          possibleAnswerList: ["Improve", "Reject"],
        },
        {
          displayName: "Prescription",
          type: "multiselect",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "aestheticGoal",
                answer: ["Improve"],
              },
              displayName: "Aesthetic restorative work recommended post Tx",
            },
            {
              displayCondition: {
                id: "aestheticGoal",
                answer: ["Improve"],
              },
              displayName: "Improve with Attachments",
            },
            {
              displayCondition: {
                id: "aestheticGoal",
                answer: ["Improve"],
              },
              displayName: "Improve with IPR",
            },
            {
              displayCondition: {
                id: "aestheticGoal",
                answer: ["Reject"],
              },
              displayName: "Refer",
            },
          ],
        },
        {
          displayCondition: {
            id: "aestheticGoal",
            answer: ANY,
          },
          displayName: "Additional info",
          type: "text",
        },
      ],
    },
    {
      displayName: "Crossbite",
      questionList: [
        {
          conditionId: "crossbite",
          displayName: "Exam",
          type: "select",
          dataKey: "crossbite",
          possibleAnswerList: ["Not Present", "Present"],
        },
        {
          conditionId: "crossbiteGoal",
          displayName: "Goal",
          type: "select",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "crossbite",
                answer: ["Not Present"],
              },
              displayName: "Maintain",
            },
            {
              displayCondition: {
                id: "crossbite",
                answer: ["Present"],
              },
              displayName: "Improve",
            },
            {
              displayCondition: {
                id: "crossbite",
                answer: ["Present"],
              },
              displayName: "Reject",
            },
          ],
        },
        {
          displayName: "Prescription",
          type: "multiselect",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "crossbite",
                answer: ["Not Present"],
              },
              displayName: "No Alterations to TP",
            },
            {
              displayCondition: {
                id: "crossbiteGoal",
                answer: ["Improve"],
              },
              displayName: "Improve with Clear Aligners",
            },
            {
              displayCondition: {
                id: "crossbiteGoal",
                answer: ["Improve"],
              },
              displayName: "Improve with X-bite Elastics",
            },
            {
              displayCondition: {
                id: "crossbiteGoal",
                answer: ["Reject"],
              },
              displayName: "Refer",
            },
          ],
        },
      ],
    },
    {
      displayName: "Incisor Vertical Bite",
      questionList: [
        {
          conditionId: "incisor",
          displayName: "Exam",
          type: "select",
          dataKey: "incisorVerticalBite",
          possibleAnswerList: [
            "Edge to Edge", // 0
            "<1mm", // 1
            "1.1-2mm", // 2
            "2.1-4mm", // 3
            ">4mm", //4
            LINE_BREAK,
            "<1/3 Coverage", // 0
            "1/3-2/3 Coverage", // 1
            "2/3-full Coverage", // 2
            "Fully Covered", //3
          ],
        },
        {
          conditionId: "incisorGoal",
          displayName: "Goal",
          type: "select",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "incisor",
                answer: ["Edge to Edge", "<1/3 Coverage", "1/3-2/3 Coverage"],
              },
              displayName: "Maintain",
            },
            {
              displayCondition: {
                id: "incisor",
                answer: [
                  "<1mm",
                  "1.1-2mm",
                  "2.1-4mm",
                  ">4mm",
                  "1/3-2/3 Coverage",
                  "2/3-full Coverage",
                  "Fully Covered",
                ],
              },
              displayName: "Improve",
            },
            {
              displayCondition: {
                id: "incisor",
                answer: [
                  "<1mm",
                  "1.1-2mm",
                  "2.1-4mm",
                  ">4mm",
                  "1/3-2/3 Coverage",
                  "2/3-full Coverage",
                  "Fully Covered",
                ],
              },
              displayName: "Reject",
            },
          ],
        },
        {
          displayName: "Prescription",
          type: "multiselect",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "incisor",
                answer: ["Edge to Edge", "<1/3 Coverage"],
              },
              displayName: "No Alterations to TP",
            },
            {
              displayCondition: {
                id: "incisorGoal",
                answer: ["Reject"],
              },
              displayName: "Refer",
            },
            {
              displayCondition: {
                id: "incisor",
                answer: [
                  "1/3-2/3 Coverage",
                  "2/3-full Coverage",
                  "Fully Covered",
                ],
              },
              displayName: "Opening Mech. Upper",
            },
            {
              displayCondition: {
                id: "incisor",
                answer: [
                  "1/3-2/3 Coverage",
                  "2/3-full Coverage",
                  "Fully Covered",
                ],
              },
              displayName: "Opening Mech. Lower",
            },
            {
              displayCondition: {
                id: "incisor",
                answer: ["<1mm", "1.1-2mm", "2.1-4mm", ">4mm"],
              },
              displayName: "Closing Mech. Upper",
            },
            {
              displayCondition: {
                id: "incisor",
                answer: ["<1mm", "1.1-2mm", "2.1-4mm", ">4mm"],
              },
              displayName: "Closing Mech. Lower",
            },
          ],
        },
      ],
    },
    {
      displayName: "Right Sagittal Angle Class and Buccal Segment",
      questionList: [
        {
          conditionId: "rightSagittal",
          displayName: "Exam I",
          type: "select",
          possibleAnswerList: ["I", "II", "III", "Need More Information"],
        },
        {
          conditionId: "rightSagittalTwo",
          displayName: "Exam II",
          type: "select",
          dataKey: "rightSagittal",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "rightSagittal",
                answer: ["I", "II", "III"],
              },
              displayName: "Cusp to Embrasure",
            },
            {
              displayCondition: {
                id: "rightSagittal",
                answer: ["II", "III"],
              },
              displayName: "<Between>",
            },
            {
              displayCondition: {
                id: "rightSagittal",
                answer: ["II", "III"],
              },
              displayName: "Cusp to Cusp",
            },
          ],
        },
        {
          conditionId: "rightSagittalGoal",
          displayName: "Goal",
          type: "select",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "rightSagittal",
                answer: ["I", "II", "III"],
              },
              displayName: "Maintain",
            },
            {
              displayCondition: {
                id: "rightSagittal",
                answer: ["III", "II"],
              },
              displayName: "Improve",
            },
            {
              displayCondition: {
                id: "rightSagittal",
                answer: ["III", "II"],
              },
              displayName: "Reject",
            },
          ],
        },
        {
          displayName: "Prescription",
          type: "multiselect",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "rightSagittal",
                answer: ["II"],
              },
              displayName: "CL. II Elastics",
            },
            {
              displayCondition: {
                id: "rightSagittal",
                answer: ["III"],
              },
              displayName: "CL. III Elastics",
            },
            {
              displayCondition: {
                id: "rightSagittal",
                answer: ["II", "III"],
              },
              displayName: "Long",
            },
            {
              displayCondition: {
                id: "rightSagittal",
                answer: ["II", "III"],
              },
              displayName: "Vertical",
            },
            {
              displayCondition: {
                id: "rightSagittal",
                answer: ["II", "III"],
              },
              displayName: "Refer",
            },
          ],
        },
      ],
    },
    {
      displayName: "Left Sagittal Angle Class and Buccal Segment",
      questionList: [
        {
          conditionId: "leftSagittal",
          displayName: "Exam I",
          type: "select",
          possibleAnswerList: ["I", "II", "III", "Need More Information"],
        },
        {
          conditionId: "leftSagittalTwo",
          displayName: "Exam II",
          dataKey: "leftSagittal",
          type: "select",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "leftSagittal",
                answer: ["I", "II", "III"],
              },
              displayName: "Cusp to Embrasure",
            },
            {
              displayCondition: {
                id: "leftSagittal",
                answer: ["II", "III"],
              },
              displayName: "<Between>",
            },
            {
              displayCondition: {
                id: "leftSagittal",
                answer: ["II", "III"],
              },
              displayName: "Cusp to Cusp",
            },
          ],
        },
        {
          conditionId: "leftSagittalGoal",
          displayName: "Goal",
          type: "select",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "leftSagittal",
                answer: ["I", "II", "III"],
              },
              displayName: "Maintain",
            },
            {
              displayCondition: {
                id: "leftSagittal",
                answer: ["III"],
              },
              displayName: "Improve",
            },
            {
              displayCondition: {
                id: "leftSagittal",
                answer: ["III", "II"],
              },
              displayName: "Reject",
            },
          ],
        },
        {
          displayName: "Prescription",
          type: "multiselect",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "leftSagittal",
                answer: ["II"],
              },
              displayName: "CL. II Elastics",
            },
            {
              displayCondition: {
                id: "leftSagittal",
                answer: ["III"],
              },
              displayName: "CL. III Elastics",
            },
            {
              displayCondition: {
                id: "leftSagittal",
                answer: ["II", "III"],
              },
              displayName: "Long",
            },
            {
              displayCondition: {
                id: "leftSagittal",
                answer: ["II", "III"],
              },
              displayName: "Vertical",
            },
            {
              displayCondition: {
                id: "leftSagittal",
                answer: ["II", "III"],
              },
              displayName: "Refer",
            },
          ],
        },
      ],
    },
    {
      displayName: "Upper Arch Length",
      questionList: [
        {
          conditionId: "upper",
          displayName: "Exam",
          type: "select",
          dataKey: "upperArchLength",
          possibleAnswerList: [
            "- <2mm",
            "- 2-5mm",
            "- 5-9mm",
            "- 9-13mm",
            "- <13mm",
            LINE_BREAK,
            "+ <2mm",
            "+ 2-5mm",
            "+ 5-9mm",
            "+ 9-13mm",
            "+ >13mm",
          ],
        },
        {
          conditionId: "goalUpper",
          displayCondition: {
            id: "upper",
            answer: ANY,
          },
          displayName: "Goal",
          type: "select",
          possibleAnswerList: ["Maintain", "Improve", "Reject"],
        },
        {
          displayName: "Prescription",
          type: "multiselect",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "goalUpper",
                answer: ["Maintain"],
              },
              displayName: "No Alterations to TP",
            },
            {
              displayCondition: {
                id: "upper",
                answer: ["- <2mm", "- 2-5mm", "- 5-9mm", "- 9-13mm", "- <13mm"],
              },
              displayName: "Expansion",
            },
            {
              displayCondition: {
                id: "upper",
                answer: ["- <2mm", "- 2-5mm", "- 5-9mm", "- 9-13mm", "- <13mm"],
              },
              displayName: "Proclination",
            },
            {
              displayCondition: {
                id: "upper",
                answer: ["- <2mm", "- 2-5mm", "- 5-9mm", "- 9-13mm", "- <13mm"],
              },
              displayName: "IPR",
            },
            {
              displayCondition: {
                id: "upper",
                answer: [
                  "+ <2mm",
                  "+ 2-5mm",
                  "+ 5-9mm",
                  "+ 9-13mm",
                  "+ >13mm ",
                ],
              },
              displayName: "Close Spaces",
            },
            {
              displayCondition: {
                id: "upper",
                answer: [
                  "+ <2mm",
                  "+ 2-5mm",
                  "+ 5-9mm",
                  "+ 9-13mm",
                  "+ >13mm ",
                ],
              },
              displayName: "Root Parallelism Attachments",
            },
            {
              displayCondition: {
                id: "goalUpper",
                answer: ["Reject"],
              },
              displayName: "Refer",
            },
          ],
        },
      ],
    },
    {
      displayName: "Lower Arch Length",
      questionList: [
        {
          conditionId: "lower",
          displayName: "Exam",
          type: "select",
          possibleAnswerList: [
            "- <2mm",
            "- 2-5mm",
            "- 5-9mm",
            "- 9-13mm",
            "- <13mm",
            LINE_BREAK,
            "+ <2mm",
            "+ 2-5mm",
            "+ 5-9mm",
            "+ 9-13mm",
            "+ >13mm ",
          ],
        },
        {
          conditionId: "goalLower",
          displayCondition: {
            id: "lower",
            answer: ANY,
          },
          displayName: "Goal",
          type: "select",
          possibleAnswerList: ["Maintain", "Improve", "Reject"],
        },
        {
          displayName: "Prescription",
          type: "multiselect",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "goalLower",
                answer: ["Maintain"],
              },
              displayName: "No Alterations to TP",
            },
            {
              displayCondition: {
                id: "lower",
                answer: ["- <2mm", "- 2-5mm", "- 5-9mm", "- 9-13mm", "- <13mm"],
              },
              displayName: "Expansion",
            },
            {
              displayCondition: {
                id: "lower",
                answer: ["- <2mm", "- 2-5mm", "- 5-9mm", "- 9-13mm", "- <13mm"],
              },
              displayName: "Proclination",
            },
            {
              displayCondition: {
                id: "lower",
                answer: ["- <2mm", "- 2-5mm", "- 5-9mm", "- 9-13mm", "- <13mm"],
              },
              displayName: "IPR",
            },
            {
              displayCondition: {
                id: "lower",
                answer: [
                  "+ <2mm",
                  "+ 2-5mm",
                  "+ 5-9mm",
                  "+ 9-13mm",
                  "+ >13mm ",
                ],
              },
              displayName: "Close Spaces",
            },
            {
              displayCondition: {
                id: "lower",
                answer: [
                  "+ <2mm",
                  "+ 2-5mm",
                  "+ 5-9mm",
                  "+ 9-13mm",
                  "+ >13mm ",
                ],
              },
              displayName: "Root Parallelism Attachments",
            },
            {
              displayCondition: {
                id: "goalLower",
                answer: ["Reject"],
              },
              displayName: "Refer",
            },
          ],
        },
      ],
    },
    {
      displayName: "Warning Flags",
      questionList: [
        {
          conditionId: "warningFlags",
          displayName: "Exam",
          type: "select",
          possibleAnswerList: [
            "None",
            "Dental Trauma",
            "Gum Disease",
            "Medications",
            "Anxiety",
            "Unrealistic Expectations",
            "Grinding / Clenching",
            "Extr. Hypodivergence",
            "Needs TLC",
            "Other",
          ],
        },
        {
          displayCondition: {
            id: "warningFlags",
            answer: ["Other"],
          },
          displayName: "Other",
          type: "text",
        },
        {
          displayName: "Goal",
          type: "select",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "warningFlags",
                answer: ["None"],
              },
              displayName: "Maintain",
            },
            {
              displayCondition: {
                id: "warningFlags",
                answer: [
                  "Dental Trauma",
                  "Gum Disease",
                  "Medications",
                  "Anxiety",
                  "Unrealistic Expectations",
                  "Grinding / Clenching",
                  "Extr. Hypodivergence",
                  "Needs TLC",
                  "Other",
                ],
              },
              displayName: "Improve",
            },
            {
              displayCondition: {
                id: "warningFlags",
                answer: [
                  "Dental Trauma",
                  "Gum Disease",
                  "Medications",
                  "Anxiety",
                  "Unrealistic Expectations",
                  "Grinding / Clenching",
                  "Extr. Hypodivergence",
                  "Needs TLC",
                  "Other",
                ],
              },
              displayName: "Reject",
            },
          ],
        },
        {
          displayName: "Prescription",
          type: "select",
          possibleAnswerList: [
            {
              displayCondition: {
                id: "warningFlags",
                answer: ["None"],
              },
              displayName: "No Alterations to TP",
            },
            {
              displayCondition: {
                id: "warningFlags",
                answer: [
                  "Dental Trauma",
                  "Gum Disease",
                  "Medications",
                  "Anxiety",
                  "Unrealistic Expectations",
                  "Grinding / Clenching",
                  "Extr. Hypodivergence",
                  "Needs TLC",
                  "Other",
                ],
              },
              displayName: "Refer",
            },
          ],
        },
      ],
    },
    {
      displayName: "Conclusion",
      questionList: [
        {
          conditionId: "conclusionGoal",
          displayName: "Goal",
          type: "select",
          possibleAnswerList: [
            "Accept",
            "Reject",
            "Retainers",
            "Observation",
            "More Info Needed",
          ],
        },
        {
          displayCondition: {
            id: "conclusionGoal",
            answer: ["Accept"],
          },
          displayName: "nightTimeOnly",
          type: "select",
          possibleAnswerList: ["Night Time Only"],
        },
        {
          displayCondition: {
            id: "conclusionGoal",
            answer: ["Accept"],
          },
          displayName: "Duration",
          type: "select",
          possibleAnswerList: [
            "<6mos",
            "6-8mos",
            "8-10mos",
            "10-12mos",
            "12-18mos",
            ">18mos",
          ],
        },
        {
          displayCondition: {
            id: "conclusionGoal",
            answer: ["Reject"],
          },
          displayName: "Description",
          type: "select",
          possibleAnswerList: ["Too Complex", "Other"],
        },
        {
          displayCondition: {
            id: "conclusionGoal",
            answer: ["Reject"],
          },
          displayName: 'Other - if "Other" selected',
          type: "text",
        },
        {
          displayCondition: {
            id: "conclusionGoal",
            answer: ["Retainers"],
          },
          displayName: "Next Step",
          type: "select",
          possibleAnswerList: ["Scan Needed", "Dispense Next Stage"],
        },
        {
          displayCondition: {
            id: "conclusionGoal",
            answer: ["Observation"],
          },
          displayName: "Observation",
          type: "select",
          possibleAnswerList: ["Caries", "Perio", "Growth"],
        },
        {
          displayCondition: {
            id: "conclusionGoal",
            answer: ["Observation"],
          },
          displayName: "Observation Duration",
          type: "select",
          possibleAnswerList: ["1mo", "3mos", "6mos"],
        },
        {
          //   displayCondition: {
          //     path: 'data.rrx[0].issueList[17].questionList[0].answerList',
          //     answer: ['More Info Needed'],
          //   },
          //   type: 'select',
          //   possibleAnswerList: ['Other'],
          // }, {
          displayCondition: {
            id: "conclusionGoal",
            answer: ["More Info Needed"],
          },
          displayName: 'Other - if "Other" selected',
          type: "text",
        },
      ],
    },
    {
      displayName: "Refer Patient",
      questionList: [
        {
          displayName: "Refer To",
          type: "text",
        },
        {
          displayName: "Refer To Email Address",
          type: "text",
        },
        {
          displayName: "Referral Reason",
          type: "text",
        },
      ],
    },
    {
      displayName: "Treatment Summary",
      questionList: [
        {
          displayName: "Notes",
          type: "text",
        },
        {
          displayName:
            "Please review and update the tooth chart before posting",
          type: "alert",
        },
      ],
    },
    {
      displayName: "Estimate (REQUIRED) & Treatment Type",
      questionList: [
        {
          dataKey: "duration",
          displayName: "Estimated Duration of treatment",
          type: "select",
          possibleAnswerList: [
            "4-5 months",
            "6-8 months",
            "8-10 months",
            "10-12 months",
            "12-18 months",
            "over 18 months",
          ],
        },
        {
          dataKey: "treatmentType",
          displayName: "Treatment Type",
          type: "select",
          possibleAnswerList: [TREATMENT_TYPE_COMPREHENSIVE, RETAINER],
        },
      ],
    },
  ],
};

export default RRX_TEMPLATE;
