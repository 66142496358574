import React from "react";
import styled from "react-emotion";
import Dropdown from "src/shared/Dropdown";
import { hours, minutes } from "./util";
import { startHour } from "./consts";

type Props = {
  value: number;
  disabled?: boolean;
  onChange: (number) => void;
};

type Operator = "h" | "m";

const Times = styled.div`
  display: flex;

  div {
    margin: 0 0.25rem;
  }
`;

const minute = 60;
const hour = minute * 60;

const OffsetPicker = ({ value, disabled, onChange }: Props) => {
  const minVal = value % hour;
  const hourVal = Math.floor(value / hour);

  const edit = (type: Operator, to: string) => {
    const v = parseInt(to, 10);
    if (type === "h") {
      onChange(v * hour + minVal);
      return;
    }
    onChange(hourVal * hour + v);
  };

  return (
    <Times>
      <Dropdown
        selected={hourVal.toString()}
        disabled={disabled}
        options={hours.map((hr, n) => ({
          value: (n + startHour).toString(),
          label: hr,
        }))}
        onSelect={v => edit("h", v.value)}
      />
      <Dropdown
        selected={minVal.toString()}
        disabled={disabled}
        options={minutes.map((min, n) => ({
          value: (n * 5 * minute).toString(),
          label: min,
        }))}
        onSelect={v => edit("m", v.value)}
      />
    </Times>
  );
};

export default OffsetPicker;
