import React, { useState } from "react";
import { Avatar, Popover, Menu } from "antd";
import { capitalize } from "lodash";
import { browserHistory } from "react-router";
import { logout } from "src/utils/auth";
import Modal from "src/shared/Modal";
import PatientSearch from "src/shared/PatientSearch";
import PatientNotesIcon from "src/shared/Icons/PatientNotes";
import Settings from "src/shared/Icons/Settings";
import { PatientConfirm } from "src/shared/PatientConfirm/PatientConfirm";
import Popconfirm from "src/shared/Popconfirm";
import { toFullDateYear, getCurrentAge } from "./util";
import { getAddress } from "src/utils/address";
import { useDeletePatient } from "src/state/usePatientInfo";
import { useSendPasswordReset } from "src/state/hooks/patients";

// from patient profile v1
import NewHold from "src/scenes/Tasks/NewHold/NewHold";
import PatientEdit from "src/scenes/PatientProfile/PatientEdit";
import PatientStatusEdit from "src/scenes/PatientProfile/PatientStatusEdit";
import ShippingAddressEdit from "src/scenes/PatientProfile/ShippingAddressEdit";
import MarkAsSpam from "src/scenes/PatientProfile/MarkAsSpam";
import TreatmentEdit from "src/scenes/PatientProfile/TreatmentEdit";

import HeaderButton from "./HeaderButton";
import SocialNotes from "./SocialNotes";
import HeaderConsents from "./HeaderConsents";
import UserButton from "./UserButton";
import CompleteHoldConfirmation from "./CompleteHoldConfirmation";

import {
  Perimeter,
  Right,
  MenuLink,
  StyledButton,
  PatientStatus,
  PatientPronoun,
  RightButtons,
  StyleMenu,
  Info,
  InfoTop,
  InfoBottom,
  PatientName,
  Photo,
} from "./styles";
import { hasFeatureFlag } from "src/utils/featureFlag";

const PatientProfileHeader = props => {
  const {
    patientAddress,
    clinicalRecord,
    patient,
    treatment,
    patientStatuses,
    refetch,
  } = props;

  const deletePatient = useDeletePatient();

  const [markAsSpam, setMarkAsSpam] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [editPatient, setEditPatient] = useState(false);
  const [editPatientStatus, setEditPatientStatus] = useState(false);
  const [editShippingAddress, setEditShippingAddress] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const sendPasswordReset = useSendPasswordReset();
  const [treatmentEditVisible, setTreatmentEditVisible] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [addHoldVisible, setAddHoldVisible] = useState(false);
  const [completeHoldConfirmation, setCompleteHoldConfirmation] = useState(
    false
  );

  const holds = (treatment ? treatment.holds : []).filter(h => !h.completedAt);

  const OptionsMenu = (
    <StyleMenu>
      <Menu.Item onClick={() => setEditPatient(true)}>
        Edit Patient Details
      </Menu.Item>
      <Menu.Item onClick={() => setEditPatientStatus(true)}>
        Edit Patient Status
      </Menu.Item>
      <Menu.Item onClick={() => setEditShippingAddress(true)}>
        Edit Shipping Address
      </Menu.Item>
      <Menu.Item onClick={() => setTreatmentEditVisible(true)}>
        Edit Treatment
      </Menu.Item>
      <Menu.Item onClick={() => setAddHoldVisible(true)}>
        Add Pre-tx task
      </Menu.Item>
      <Menu.Item
        disabled={holds.length === 0}
        onClick={() => setCompleteHoldConfirmation(true)}
      >
        Complete Pre-tx task
      </Menu.Item>
      <Menu.Item onClick={() => setPasswordReset(true)}>
        Send password reset
      </Menu.Item>
      <Menu.Item onClick={() => setMarkAsSpam(true)}>Mark as spam</Menu.Item>
      <Menu.Item onClick={() => setDeleteUser(true)}>Delete patient</Menu.Item>
    </StyleMenu>
  );

  const {
    firstName,
    lastName,
    preferredName,
    pronouns,
    dateOfBirth,
    mobilePhoneNumber,
  } = patient;

  const getDoctorLabel = doctor => {
    return doctor ? `Dr. ${doctor.name}` : " -- ";
  };

  return (
    <Perimeter>
      <PatientEdit
        patient={patient}
        visible={editPatient}
        setVisible={setEditPatient}
      />
      {editPatientStatus && (
        <PatientStatusEdit
          patient={patient}
          patientStatuses={patientStatuses}
          onClose={() => setEditPatientStatus(false)}
        />
      )}
      <MarkAsSpam
        patient={patient}
        visible={markAsSpam}
        setVisible={setMarkAsSpam}
      />
      {completeHoldConfirmation && (
        <CompleteHoldConfirmation
          holds={holds}
          userID={patient.id}
          onClose={() => {
            setCompleteHoldConfirmation(false);
          }}
        />
      )}
      {addHoldVisible && (
        <Modal onClose={() => setAddHoldVisible(false)}>
          <NewHold
            user={patient}
            onCancel={() => setAddHoldVisible(false)}
            onComplete={() => {
              // XXX: here we re-fetch the treatment to ensure holds are captured if it's the
              // first hold for a patient.  To fix, we need to update to urql's denormalized caching.
              refetch();
              setAddHoldVisible(false);
            }}
          />
        </Modal>
      )}
      {treatment && (
        <TreatmentEdit
          treatment={treatment}
          visible={treatmentEditVisible}
          setVisible={setTreatmentEditVisible}
        />
      )}
      <ShippingAddressEdit
        patientId={patient.id}
        patientAddress={patientAddress}
        visible={editShippingAddress}
        setVisible={setEditShippingAddress}
      />
      <PatientConfirm
        patient={patient}
        visible={deleteUser}
        setVisible={setDeleteUser}
        content="Type patient's full name to delete"
        onConfirm={() => deletePatient({ id: patient.id })}
      />
      {clinicalRecord && (
        <SocialNotes
          isOpen={openNotes}
          clinicalRecord={clinicalRecord}
          onHide={() => {
            setOpenNotes(false);
          }}
        />
      )}

      <Photo>
        <Avatar icon="user" src={patient.avatarURL} className="avatar" />
      </Photo>
      <Info>
        <InfoTop>
          <PatientName>
            {firstName} {preferredName ? `(${preferredName})` : null} {lastName}
          </PatientName>
          {pronouns ? <PatientPronoun>({pronouns})</PatientPronoun> : null}

          {patient.statuses.length > 0 && (
            <PatientStatus>
              {capitalize(patient.statuses[0].name.replaceAll("_", " "))}
            </PatientStatus>
          )}
        </InfoTop>

        <InfoBottom>
          {dateOfBirth && (
            <li>
              {toFullDateYear(dateOfBirth)} (age {getCurrentAge(dateOfBirth)})
            </li>
          )}
          <li>{mobilePhoneNumber}</li>
          <li>{patient.email}</li>
          <li>
            {treatment ? getDoctorLabel(treatment.beginningDoctor) : " -- "}
          </li>
          <li>{getAddress(patientAddress)}</li>
        </InfoBottom>
      </Info>

      <Right>
        <RightButtons>
          <HeaderButton
            dropdownMenu={null}
            tooltip="Social notes"
            onClick={() => setOpenNotes(true)}
            theme={{
              showBadge: false,
            }}
          >
            <PatientNotesIcon />
          </HeaderButton>

          <HeaderConsents patient={patient} clinicalRecord={clinicalRecord} />

          <HeaderButton
            dropdownMenu={OptionsMenu}
            tooltip="Settings"
            theme={{
              showBadge: false,
            }}
          >
            <Settings />
          </HeaderButton>

          <Popconfirm
            placement="topLeft"
            visible={passwordReset}
            setVisible={setPasswordReset}
            title="Send reset password link?"
            onConfirm={() => {
              setPasswordReset(false);
              sendPasswordReset(patient.email);
            }}
            onCancel={() => setPasswordReset(false)}
          />
        </RightButtons>

        <PatientSearch
          view="basic"
          style={{ width: "200px" }}
          onChange={p => {
            browserHistory.push({ pathname: `/patients/${p.id}` });
          }}
        />

        <Popover
          placement="bottomLeft"
          trigger="click"
          content={
            <div>
              {hasFeatureFlag("newPatient") && (
                <p>
                  <MenuLink to="/patients/new">New patient</MenuLink>
                </p>
              )}
              <p>
                <MenuLink to="/reset-password">Password</MenuLink>
              </p>
              <p>
                <MenuLink to="/settings">Settings</MenuLink>
              </p>
              <p>
                <MenuLink to="" onClick={logout}>
                  Logout
                </MenuLink>
              </p>
            </div>
          }
        >
          <StyledButton type="clear">
            <UserButton />
          </StyledButton>
        </Popover>
      </Right>
    </Perimeter>
  );
};
export default PatientProfileHeader;
