// @flow
import React from "react";
import debounce from "lodash/debounce";
import styled from "react-emotion";
import { compose, withState } from "recompose";
import { Link } from "react-router";
import { testPredicate } from "src/utils/optionality";
import {
  Input,
  InputNumber,
  Radio,
  Checkbox,
  Tag,
  Button,
  Modal,
  Select,
  DatePicker,
} from "antd";
import moment from "moment";
import { request } from "src/shared/util";
import PreviousAnswers from "./PreviousAnswers";
import DocumentsForSignature from "src/components/consent-request/DocumentsForSignature";

// type Props = {
//   patient: Patient,
//   q: FormQuestion,
//   editable?: boolean,
//   value: mixed,
//   answer: ?Answer,
//   onChange: (val: mixed) => void,
//   submission: Submission,
//   // To figure out display and choice predicates
//   allAnswers: Array<mixed>,
// };

// This adds local state to the Header component for each question which
// toggles whether the modal for viewing previous answers is open.
const headerModifiers = compose(withState("showModal", "setShowModal", false));

const Header = headerModifiers(
  ({
    q,
    indicatePrevAnswers,
    patient,
    submission,
    showModal,
    setShowModal,
  }) => {
    return (
      <div>
        <h3>{q.question_text}</h3>
        <Tags>
          {q.is_required && <Tag>Required</Tag>}
          {indicatePrevAnswers && (
            <Tag color="#2db7f5" onClick={() => setShowModal(true)}>
              View previous answers
            </Tag>
          )}
        </Tags>

        {
          // Making this depend on `showModal` forces `PreviousAnswers` to
          // refetch answers, in case the user makes subsequent revisions.
        }
        {indicatePrevAnswers && showModal && (
          <Modal
            title={q.question_text}
            visible
            footer={null}
            onCancel={() => setShowModal(false)}
            width={675}
          >
            <PreviousAnswers
              submissionID={submission.id}
              q={q}
              patient={patient}
            />
          </Modal>
        )}
      </div>
    );
  }
);

const getValue = (a, s) => {
  if (a && a.form_submission_id === s.id) {
    return a.answer;
  }
};

const getDependentChoices = (q, allAnswers) => {
  if (
    ["dependent_multichoice", "dependent_choice"].indexOf(q.question_type) ===
    -1
  ) {
    return q.choices || [];
  }

  // Find the choice option where all predicates return true.
  const found = q.choices.find(c => {
    return (
      c.predicates &&
      c.predicates.every(p => {
        const answer = allAnswers.find(a => a.question_id === p.question_id);
        return answer && testPredicate(p, answer.answer);
      })
    );
  });

  if (!found) {
    return [];
  }

  return found.choices;
};

/**
 * ViewFormQuestion renders a component for the question based off of the
 * question_type.
 *
 * We pass all answers to the questions so we can figure out display and
 * choice predicates.
 */
const ViewFormQuestion = props => {
  const { patient, submission, q, answer, editable } = props;

  const answersToQuestion = props.allAnswers.filter(
    a => a.question_id === q.id && a.patient_id === patient.id
  );
  let hasPrevAnswers = false;
  if (answersToQuestion.length > 1) {
    hasPrevAnswers = true;
  } else if (answersToQuestion.length === 1) {
    const ans = answersToQuestion[0];
    hasPrevAnswers =
      ans.version > 1 || ans.form_submission_id !== submission.id;
  }
  const [indicatePrevAnswers, setIndicatePrevAnswers] = React.useState(
    hasPrevAnswers
  );
  React.useEffect(() => {
    if (hasPrevAnswers) {
      setIndicatePrevAnswers(true);
    }
  }, [hasPrevAnswers]);

  // Though this submission has an answer it *may be for another submission*:
  // the API response lists the latest answers for the question *across all forms and
  // submissions* when the submission is unsubmitted.  This allows us to show
  // "N previous answers for each question.
  //
  // getValue returns undefined if the answer does not match our submission.
  //
  // This is quirky but allows us to display the UI components without making a request
  // for each question.
  const value = getValue(answer, submission);

  // if a form is editable we'd like to show only current questions
  if (editable && !q.is_current) {
    return null;
  }

  // if a form is not editable we'd like to show only answered questions
  if (!editable && answersToQuestion.length === 0) {
    return null;
  }

  if (q.display_predicates && Array.isArray(q.display_predicates)) {
    // Run through all display predicates and ensure that each item returns
    // true in order to show the question.
    const show = q.display_predicates.every(p => {
      // Find the answer for this p
      const answer = props.allAnswers.find(
        a => a.question_id === p.question_id
      );
      // Return whether the answer matches the predicate.
      return answer && testPredicate(p, answer.answer);
    });

    if (!show) {
      return null;
    }
  }

  // "key" is included for each question to force updates when we toggle between
  // form versions.  Passing in a different set of answers does not trigger a re-
  // render.

  if (q.question_type === "text") {
    return (
      <FormItem>
        <h3>
          <Reminder>Reminder</Reminder>
          {q.question_text}
        </h3>
      </FormItem>
    );
  }

  // TODO: Move these into a separate module.
  const renderInputQuestion = () => (
    <InputQuestion {...props} value={value} key={value} />
  );
  const renderDateQuestion = () => (
    <DateQuestion {...props} value={value} key={value} />
  );
  const renderChoiceQuestion = () => (
    <ChoiceQuestion {...props} value={value} key={value} />
  );
  const renderMultichoiceQuestion = () => (
    <MultichoiceQuestion {...props} value={value} key={value} />
  );
  const renderDropdownQuestion = () => (
    <DropdownQuestion {...props} value={value} key={value} />
  );
  const WIDGETS = {
    input: renderInputQuestion,
    input_large: renderInputQuestion,

    date: renderDateQuestion,

    choice: renderChoiceQuestion,
    choice_with_input: renderChoiceQuestion,
    dependent_choice: renderChoiceQuestion,

    multichoice: renderMultichoiceQuestion,
    multichoice_with_input: renderMultichoiceQuestion,
    dependent_multichoice: renderMultichoiceQuestion,

    dropdown: renderDropdownQuestion,
  };

  const widget = WIDGETS[q.question_type];
  if (!widget) {
    return null;
  }

  return (
    <FormItem>
      <Header
        submission={submission}
        q={q}
        indicatePrevAnswers={indicatePrevAnswers}
        patient={patient}
      />
      {widget()}
    </FormItem>
  );
};

const DateQuestion = ({
  q,
  answer,
  editable,
  value,
  onChange,
  allAnswers,
  patient,
  submission,
}) => {
  return (
    <DatePicker
      disabled={!editable}
      defaultValue={value ? moment(value) : undefined}
      onChange={(d, ds) => onChange(d ? d.toISOString() : undefined)}
    />
  );
};

const InputQuestion = ({
  q,
  answer,
  editable,
  value,
  onChange,
  allAnswers,
  patient,
}) => {
  const C = q.response_type === "integer" ? InputNumber : Input.TextArea;

  // TODO: Each time an input has changed, move it into a queue of answers to save.  When
  // the answer is made, remove it from the queue.  Then, when submitting a form, wait
  // until the queue is empty before saving.
  const update = (previous, next) => {
    const val = q.response_type === "integer" ? parseInt(next, 10) : next;
    if (val && val !== value) {
      onChange(val);
    }
  };

  return (
    <C
      defaultValue={value}
      autosize={{ minRows: 2, maxRows: 12 }}
      disabled={!editable}
      onBlur={e => update(value, e.target.value)}
    />
  );
};

const ChoiceQuestion = ({
  q,
  editable,
  value,
  onChange,
  allAnswers,
  patient,
}) => {
  const choices = getDependentChoices(q, allAnswers);
  if (choices.length === 0) {
    return null;
  }
  // If there's only one choice - typically "Yes" or "OK" - this
  // is just a hint and verification for staff to have completed
  // something,.

  // Remove all of the choices from the given value, in case this allows us
  // to type in another item.  This prevents a selected choice from being
  // shown in the textbox.
  const other = choices.indexOf(value) === -1 ? value : null;

  return (
    <>
      <Radio.Group
        defaultValue={value}
        onChange={e => {
          if (e.target.value !== value) {
            onChange(e.target.value);
          }
        }}
      >
        {choices.map(c => (
          <Radio.Button key={c} value={c} disabled={!editable}>
            {c}
          </Radio.Button>
        ))}
      </Radio.Group>

      {q.question_type === "choice_with_input" && (
        <Input
          style={{ marginTop: ".75rem" }}
          defaultValue={other}
          placeholder="Other"
          disabled={!editable}
          onBlur={e => {
            if (e.currentTarget.value && e.currentTarget.value !== value) {
              onChange(e.target.value);
            }
          }}
        />
      )}

      {// TODO: Find a better way of adding the privacy policy
      q.slug === "review_privacy_policy" && (
        <Link to={`/patients-old/${patient.id}/consent-form/privacy`}>
          <LinkButton>Privacy Policy</LinkButton>
        </Link>
      )}
      {// TODO: Find a better way of adding the privacy policy
      q.slug === "financial_treatment_consent" && (
        <DocumentsForSignature userId={patient.id} patient={patient} />
      )}
      {// TODO: Find a better way of adding the privacy policy
      q.slug === "review_the_medical_and_dental_form" && (
        <LinkButton
          onClick={() => {
            request(
              `/api/v1/patients/${patient.id}/redirect_to_medical_form`
            ).then(resp => {
              window.location = resp.url;
            });
          }}
        >
          Medical and Dental Form
        </LinkButton>
      )}
    </>
  );
};

const MultichoiceQuestion = ({
  q,
  editable,
  value,
  onChange,
  allAnswers,
  patient,
}) => {
  const choices = getDependentChoices(q, allAnswers);
  if (choices.length === 0) {
    return null;
  }

  // Remove all of the choices from the given value, in case this allows us
  // to type in other items.
  const other = (value || []).filter(val => choices.indexOf(val) === -1);
  const selectedChoices = (value || []).filter(
    val => choices.indexOf(val) > -1
  );

  // When checkboxes are changed, debounce the onChange handler so that we only
  // save a response after 2 seconds.  This ensures that we only save one answer
  // when toggling 5 choices, vs 5 answers.

  return (
    <>
      <Checkbox.Group
        defaultValue={value}
        options={choices}
        disabled={!editable}
        onChange={debounce(onChange, 1500)}
      />

      {q.question_type === "multichoice_with_input" && (
        <Input
          style={{ marginTop: ".75rem" }}
          defaultValue={other}
          placeholder="Other"
          disabled={!editable}
          onBlur={e => {
            if (e.currentTarget.value !== other) {
              onChange(
                selectedChoices.concat([e.currentTarget.value]).filter(Boolean)
              );
            }
          }}
        />
      )}
    </>
  );
};

const DropdownQuestion = ({
  q,
  editable,
  value,
  onChange,
  allAnswers,
  patient,
}) => {
  const choices = getDependentChoices(q, allAnswers);

  if (choices.length === 0) {
    return null;
  }

  // @ky - 8/13/2019
  // NOTE: I'm stubbing values for other and selectedChoices.
  // this needs to be revisited if this page is actually used
  const other = "";
  const selectedChoices = [];

  const localOnChange = next => {
    const val = q.response_type === "integer" ? parseInt(next, 10) : next;
    onChange(val);
  };

  // When checkboxes are changed, debounce the onChange handler so that we only
  // save a response after 2 seconds.  This ensures that we only save one answer
  // when toggling 5 choices, vs 5 answers.
  return (
    <>
      <Select
        defaultValue={value}
        options={choices}
        disabled={!editable}
        onChange={debounce(localOnChange, 1500)}
        style={{ minWidth: "375px" }}
      >
        {choices.map(c => (
          <Select.Option key={c} value={c} disabled={!editable}>
            {c}
          </Select.Option>
        ))}
      </Select>

      {q.question_type === "multichoice_with_input" && (
        <Input
          style={{ marginTop: ".75rem" }}
          defaultValue={other}
          placeholder="Other"
          disabled={!editable}
          onBlur={e => {
            if (e.currentTarget.value !== other) {
              onChange(
                selectedChoices.concat([e.currentTarget.value]).filter(Boolean)
              );
            }
          }}
        />
      )}
    </>
  );
};

export default ViewFormQuestion;

const FormItem = styled("div")`
  padding: 2rem 0 0;

  h3 {
    font-size: 1rem;
    font-weight: 400;
    margin: 0 0 0.5rem;
    opacity: 0.8;
    display: inline-block;
  }

  .ant-radio-button-wrapper-checked {
    z-index: 0;
  }
`;

const LinkButton = styled(Button)`
  margin-left: 1.5rem;
`;

const Tags = styled("div")`
  line-height: 1;
  display: inline-block;
  margin: -0.5rem 1rem 0;
`;

const Reminder = styled("span")`
  opacity: 0.5;
  display: inline-block;
  margin-right: 0.5rem;
`;
