import React from "react";
import styled from "react-emotion";
import { ExternalSpecialistType } from "src/types/gql";
import { getReadableType } from "src/utils/externalProvider";

const typeToColor: { [specialistType in ExternalSpecialistType]: string } = {
  oral_surgeon: "#8168BB", // purple
  general_dentist: "#59B8B8", // teal
  endodontist: "#E1A733", // yellow
  periodontist: "#CE6E58", // red
  prosthodontist: "#98c76b", // lime
  tmj_specialist: "#C270A7", // pink
};

type Props = {
  specialistType: ExternalSpecialistType;
};

const Container = styled.div`
  width: 95px;
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  align-text: center;
  border-radius: 12px;
  font-weight: 600;
  font-size: 11px;
  color: #fff;
`;

const TypePill: React.FC<Props> = ({ specialistType }) => {
  return (
    <Container style={{ backgroundColor: typeToColor[specialistType] }}>
      {getReadableType(specialistType)}
    </Container>
  );
};

export default TypePill;
