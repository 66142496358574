import React from "react";
import ButtonGroup from "src/shared/ButtonGroup";

const ChoiceWithButtons = props => {
  const { value, handleChange, minWidth = "100px", disabled } = props;
  let options = props.options.slice();

  if (!options || options.length === 0) {
    return <ButtonGroup.Button disabled>N/A</ButtonGroup.Button>;
  }

  // XXX: This appears to be choices which have predicates:
  // [{ choices: ["a", "n"], predicates: {}}, { choices: ["b", "c"] }]
  // TODO: Support this with specific predicates.
  if (typeof options[0] === "object" && Array.isArray(options[0].choices)) {
    options = options.map((o: any) => o.choices);
  }

  const toggleValue = (newValue: string): void => {
    if (newValue === value) {
      // Resets to a blank answer.
      handleChange("");
    } else {
      handleChange(newValue);
    }
  };

  return (
    <ButtonGroup value={value} onChange={value => toggleValue(value)}>
      {options.map((c: any) => (
        <ButtonGroup.Button
          key={c}
          value={c}
          disabled={disabled}
          style={{ width: "auto", height: "36px", padding: "0 12px", minWidth }}
        >
          {c}
        </ButtonGroup.Button>
      ))}
    </ButtonGroup>
  );
};

export default ChoiceWithButtons;
