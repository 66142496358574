import React, { useReducer, useEffect } from "react";
import Progress from "src/shared/Progress";
import upload, { isError } from "src/shared/xhr";
import { FileData } from "src/shared/file";

const MAX_CONCURRENT_UPLOADS = 3;

export interface UploadData {
  queue: ReadonlyArray<FileData>;
  current: ReadonlyArray<FileData>;
  complete: ReadonlyArray<FileData>;
  error: ReadonlyArray<FileData>;

  push(items: Array<FileData>): void;
  replace(items: ReadonlyArray<FileData>): void;
  // update a file upload as progress
  setProgress(file: FileData, percent: number): void;
  // mark a file as uploaded
  setComplete(file: FileData): void;
  // mark a file as errored
  setError(file: FileData): void;
}

export const UploadContext = React.createContext<UploadData>({
  queue: [],
  current: [],
  complete: [],
  error: [],
  push: (items: Array<FileData>) => {},
  replace: (to: ReadonlyArray<FileData>) => to,
  setProgress: (file, percent) => {},
  setComplete: file => {},
  setError: file => {},
});

interface Props {
  children: React.ReactNode;
}

// uploadQueue
const uploadQueue = async (u: UploadData, num: number) => {
  const { queue } = u;

  if (queue.length === 0) {
    return;
  }

  const next = queue.slice(0, num);

  next.forEach(async file => {
    try {
      // Ensure that this file is moved to the queue with 0% progress
      u.setProgress(file, 0);
      // Attempt to upload the file
      const result = await upload(file, u);
      // If this item failed to upload, add it to the failed list.
      // If this succeeded, add this to the succeeded list.
      if (!isError(result)) {
        u.setComplete(file);
        if (file.onComplete) {
          file.onComplete(result);
        }
        return;
      }
      u.setError(file);
    } catch (e) {
      u.setError(file);
    }
  });

  // Replace the queueu with all other items remaining
  u.replace(queue.slice(num));
};

type State = {
  queue: ReadonlyArray<FileData>;
  current: ReadonlyArray<FileData>;
  complete: ReadonlyArray<FileData>;
  error: ReadonlyArray<FileData>;
};

type Action =
  | { type: "push"; files: Array<FileData> }
  | { type: "replace"; to: ReadonlyArray<FileData> }
  | { type: "progress"; file: FileData; percent: number }
  | { type: "complete"; file: FileData }
  | { type: "error"; file: FileData };

const reducer = (s: State, a: Action) => {
  switch (a.type) {
    case "push":
      return { ...s, queue: s.queue.concat(a.files) };
    case "replace":
      return { ...s, queue: a.to };
    case "complete":
      return {
        ...s,
        current: s.current.filter(f => f.id !== a.file.id),
        complete: s.complete.concat([{ ...a.file, uploadPercent: 100 }]),
      };
    case "error":
      return {
        ...s,
        current: s.current.filter(f => f.id !== a.file.id),
        error: s.error.concat([a.file]),
      };
    case "progress":
      return {
        ...s,
        current: s.current
          .filter(f => f.id !== a.file.id)
          .concat([{ ...a.file, uploadPercent: a.percent }]),
      };
  }
};

const Uploader = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, {
    queue: [],
    current: [],
    complete: [],
    error: [],
  });

  const contextData: UploadData = {
    queue: state.queue,
    current: state.current,
    complete: state.complete,
    error: state.error,
    replace: (to: ReadonlyArray<FileData>) => dispatch({ type: "replace", to }),
    push: (items: Array<FileData>) => dispatch({ type: "push", files: items }),
    setComplete: (file: FileData) => dispatch({ type: "complete", file }),
    setError: (file: FileData) => dispatch({ type: "error", file }),
    setProgress: (file: FileData, percent: number) => {
      dispatch({ type: "progress", file, percent });
    },
  };

  useEffect(() => {
    // Only allow N uploads at a time.  When an upload finishes, this
    // effect will be triggered again as it's based off of the queue and
    // progress amounts - therefore triggering the next upload.
    if (MAX_CONCURRENT_UPLOADS > state.current.length) {
      uploadQueue(contextData, MAX_CONCURRENT_UPLOADS - state.current.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.queue.length,
    state.current.length,
    state.complete.length,
    state.error.length,
  ]);

  return (
    <UploadContext.Provider value={contextData}>
      {children}
      <Progress
        queue={state.queue}
        current={state.current}
        complete={state.complete}
        error={state.error}
      />
    </UploadContext.Provider>
  );
};

export default Uploader;
