import React from "react";

const SolidError = ({ fill = "#DB2F25" }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill={fill} />
    <path
      d="M21.2599 8.7402C20.81 8.2903 20.0858 8.2903 19.6359 8.7402L15.0001 13.3761L10.3642 8.7402C9.91426 8.2903 9.19016 8.2903 8.74026 8.7402C8.29036 9.1901 8.29036 9.9142 8.74026 10.3641L13.3761 15L8.74026 19.6359C8.29036 20.0858 8.29036 20.8099 8.74026 21.2598C8.95906 21.4786 9.25468 21.5986 9.54935 21.5986C9.83647 21.5986 10.1359 21.4894 10.3607 21.2575L14.9943 16.6239L19.6302 21.2598C19.849 21.4786 20.1446 21.5986 20.4393 21.5986C20.7337 21.5986 21.0271 21.4883 21.2509 21.2573C21.6983 20.8072 21.6975 20.085 21.2484 19.6359L16.6237 15.0003L21.2599 10.3641C21.7098 9.9142 21.7098 9.1901 21.2599 8.7402Z"
      fill="white"
      stroke="white"
      strokeWidth="0.75"
    />
  </svg>
);

export default SolidError;
