import { url } from "src/shared/util";
import { ErrorResponse } from "src/shared/types";

type Response = boolean | ErrorResponse;

const deleteFile = (fileId: string): Promise<Response> => {
  return new Promise<Response>(function(resolve, reject) {
    const xhr = new XMLHttpRequest();
    const token = window.localStorage.getItem("auth_token");

    xhr.open("DELETE", url(`/api/v2/files/${fileId}`), true);
    xhr.setRequestHeader("Authorization", `Bearer ${token}`);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        try {
          xhr.response ? resolve(JSON.parse(xhr.response)) : resolve(true);
        } catch (e) {
          reject({ error: "unable to parse response" });
        }
      }
    };
    xhr.send();
  });
};

export default deleteFile;
