import mapValues from "lodash/fp/mapValues";
import get from "lodash/fp/get";
import { useCounters as useCountersQuery } from "../queries";
import { SidebarCounts } from "./types";
import { SidebarCountersResponse } from "../types";

const defaultCounters: SidebarCounts = {
  pipelinesAll: null,
  pipelinesCompleted: null,
  pipelinesUser: null,
  tasksAll: null,
  tasksCompleted: null,
  tasksCreatedByUser: null,
  tasksSnoozed: null,
  tasksUser: null,
};

const prepareCounters: UnaryFn<
  SidebarCountersResponse,
  SidebarCounts
> = mapValues(get("pageResults.totalItems"));

export const useCounters = (): SidebarCounts => {
  const [{ error, data, fetching }] = useCountersQuery();

  if (error || (fetching && !data)) {
    return defaultCounters;
  }

  return prepareCounters(data!);
};
