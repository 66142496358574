import React from "react";
import styled from "react-emotion";
import ReferenceIcon from "src/scenes/Tasks/Icons/Reference";
import color from "src/styles/color";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & + & {
    margin-top: 8px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  & + & {
    margin-top: 8px;
  }

  button {
    margin-left: 8px;
  }
`;

export const ItemWrapper = styled.div`
  padding: 10px 40px 10px 20px;
  border: 1px solid ${color.border};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color.link};

  svg {
    margin-right: 12px;
  }

  & + & {
    margin-top: 8px;
  }
`;

type Props = {
  className?: any;
  style?: any;
};

export const Item: React.SFC<Props> = ({ className, style, children }) => (
  <ItemWrapper className={className} style={style}>
    <ReferenceIcon />
    {children}
  </ItemWrapper>
);
