import React from "react";
import color from "src/styles/color";

interface Props {
  fill?: string;
}

const Email = ({ fill = color.gray3 }: Props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9284 11C11.1981 11 11.4318 10.9149 11.6305 10.7471L8.23077 7.50173C8.1492 7.55748 8.07014 7.6117 7.99545 7.66325C7.74103 7.84218 7.53453 7.98181 7.37597 8.08186C7.21743 8.18214 7.00649 8.28435 6.74318 8.38871C6.47969 8.49319 6.23422 8.54526 6.00651 8.54526H5.99984H5.99318C5.76544 8.54526 5.51997 8.49321 5.25651 8.38871C4.99304 8.28435 4.7821 8.18214 4.62371 8.08186C4.46517 7.98181 4.25879 7.8422 4.00423 7.66325C3.9333 7.61361 3.85462 7.55915 3.76957 7.50085L0.369141 10.7471C0.567813 10.9149 0.801692 11 1.07141 11H10.9284Z"
      fill={fill}
    />
    <path
      d="M0.676408 5.45803C0.422006 5.29613 0.196406 5.11071 0 4.90186V9.8394L2.99655 6.97907C2.39707 6.57957 1.62467 6.07314 0.676408 5.45803Z"
      fill={fill}
    />
    <path
      d="M11.3301 5.45803C10.418 6.04731 9.64281 6.55461 9.00439 6.98021L11.9997 9.83952V4.90186C11.8077 5.10651 11.5845 5.29181 11.3301 5.45803Z"
      fill={fill}
    />
    <path
      d="M10.9284 2H1.07148C0.727598 2 0.463218 2.11084 0.27801 2.3323C0.0926497 2.55387 0.00012207 2.83095 0.00012207 3.16326C0.00012207 3.43167 0.122911 3.72251 0.368381 4.03585C0.61385 4.34906 0.87505 4.59508 1.15185 4.77403C1.30359 4.87637 1.7612 5.18003 2.52467 5.68492C2.9368 5.95754 3.29521 6.19516 3.60321 6.40021C3.86574 6.57482 4.09215 6.72602 4.27907 6.85151C4.30053 6.86588 4.33428 6.88892 4.37908 6.91949C4.42733 6.95258 4.4884 6.99457 4.56374 7.04649C4.70882 7.14665 4.82934 7.22761 4.92533 7.28946C5.02119 7.35133 5.13734 7.42043 5.27359 7.49717C5.40973 7.5738 5.53814 7.63143 5.65867 7.66976C5.77921 7.70806 5.8908 7.72728 5.99346 7.72728H6.00013H6.00679C6.10943 7.72728 6.22104 7.70806 6.34161 7.66976C6.46211 7.63143 6.59041 7.57393 6.72667 7.49717C6.86279 7.42043 6.97878 7.35118 7.07495 7.28946C7.17094 7.22761 7.29146 7.14667 7.43656 7.04649C7.51175 6.99457 7.57282 6.95256 7.62107 6.9196C7.66589 6.8889 7.69961 6.866 7.7212 6.85151C7.86683 6.75479 8.09375 6.6042 8.39885 6.40198C8.95402 6.03378 9.77163 5.49185 10.8551 4.77403C11.181 4.55677 11.4532 4.29459 11.672 3.98787C11.8904 3.68115 11.9999 3.35941 11.9999 3.02276C11.9999 2.74149 11.8938 2.50081 11.6819 2.30036C11.4698 2.10015 11.2186 2 10.9284 2Z"
      fill={fill}
    />
  </svg>
);

export default Email;
