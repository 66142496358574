import React from "react";
import { Submission } from "src/scenes/PatientProfile/Forms/GQLForms/types";
import {
  SummaryHeader,
  SummaryField,
  SummaryFieldLabel,
  SummaryFieldContent,
} from "../styles";
import { formatDateForTimeline } from "../util";

export interface Props {
  submission: Submission;
  children?: any;
}

export default function GenericForm({ submission, children }: Props) {
  return (
    <>
      <SummaryHeader>{submission.form.name} Form</SummaryHeader>

      {submission.approvedAt ? (
        <SummaryField key="approvedAt">
          <SummaryFieldLabel>Form approved</SummaryFieldLabel>
          <SummaryFieldContent>
            {formatDateForTimeline(submission.approvedAt)}
          </SummaryFieldContent>
        </SummaryField>
      ) : submission.submittedAt ? (
        <SummaryField key="submittedAt">
          <SummaryFieldLabel>Form submitted</SummaryFieldLabel>
          <SummaryFieldContent>
            {formatDateForTimeline(submission.submittedAt)}
          </SummaryFieldContent>
        </SummaryField>
      ) : (
        <SummaryField key="createdAt">
          <SummaryFieldLabel>Form created</SummaryFieldLabel>
          <SummaryFieldContent>
            {formatDateForTimeline(submission.createdAt)}
          </SummaryFieldContent>
        </SummaryField>
      )}

      {children}
    </>
  );
}
