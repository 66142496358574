import React, { useState } from "react";
import styled from "react-emotion";
import { notification } from "antd";

import { useMutation } from "src/utils/http/gqlQuery";
import color from "src/styles/color";
import Box from "src/shared/Box";
import Button, { ButtonRow } from "src/shared/Button";
import Modal from "src/shared/Modal";
import { applyPromoCode, getPromoInput } from "../query";

const Content = styled.div`
  padding: 32px;
  display: flex;
  flex-flow: column;
  flex: 1;

  height: 400px;
  width: 600px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 16px;
`;

const Error = styled.div`
  font-weight: bold;
  color: ${color.red};
  margin-top: 16px;
`;

const PromoInput = styled.input`
  border: 1px solid ${color.border};
  padding: 10px 16px;
`;

type Props = {
  onClose: () => void;
  orderID: string;
};

const ApplyPromoModal: React.FC<Props> = ({ orderID, onClose }) => {
  const [, execute] = useMutation(applyPromoCode);
  const [input, setInput] = useState("");
  const [error, setError] = useState(false);

  const onInputChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    // all promo codes are capitalized, so auto-format for user
    setInput(value.toUpperCase());
    setError(false);
  };

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const res = await execute(getPromoInput(orderID, input));
    if (res.error) {
      setError(true);
    } else {
      notification.success({ message: "Promo code applied" });
      onClose();
    }
  };

  return (
    <Modal onClose={onClose}>
      <Box title="Apply promo code">
        <Content>
          <InputContainer>
            <Title>Please enter a promo code</Title>
            <PromoInput type="text" value={input} onChange={onInputChange} />
            {error && <Error>Invalid promo code</Error>}
          </InputContainer>
          <ButtonRow position="right">
            <Button kind="default" onClick={onClose}>
              Cancel
            </Button>
            <Button kind="primary" onClick={submit} disabled={!input || error}>
              Apply
            </Button>
          </ButtonRow>
        </Content>
      </Box>
    </Modal>
  );
};

export default ApplyPromoModal;
