import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";
import {
  fetchAllQuestionnaires,
  getQuestionnairesSelector,
  getQuestionnairesCountSelector,
  getIsQuestionnairesLoadingSelector,
} from "src/redux/modules/questionnaire";
import {
  load as loadClinicalRecord,
  getPatientQuestionnairesSelector,
} from "src/redux/modules/clinical-record";
// local components
import QuestionnaireResponse from "./questionnaire";
import styles from "./styles";

const mapState = createStructuredSelector({
  isLoading: getIsQuestionnairesLoadingSelector,
  numQuestionnaires: getQuestionnairesCountSelector,
  questionnaires: getQuestionnairesSelector,
  responses: getPatientQuestionnairesSelector,
});

const mapActions = dispatch => ({
  actions: bindActionCreators(
    {
      fetchAllQuestionnaires,
      loadClinicalRecord,
    },
    dispatch
  ),
});

const modifiers = connect(
  mapState,
  mapActions
);

class AdverseHistory extends PureComponent {
  state = {
    isOpen: true,
  };

  componentDidMount() {
    const { actions, patient } = this.props;
    actions.fetchAllQuestionnaires();
    actions.loadClinicalRecord(patient.id);
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const {
      responses,
      questionnaires,
      numQuestionnaires,
      isLoading,
    } = this.props;

    if (isLoading || numQuestionnaires === 0) {
      return (
        <div style={styles.S.contentBlock}>
          <p>Loading</p>
        </div>
      );
    }

    return (
      <div style={styles.S.contentBlock}>
        <div style={styles.S.sectionTitle} onClick={this.toggle}>
          Patient Questionnaire
        </div>
        {this.state.isOpen &&
          Object.keys(responses).map(id => (
            <QuestionnaireResponse
              key={id}
              questionnaire={questionnaires[id]}
              responses={responses[id]}
            />
          ))}
      </div>
    );
  }
}

export default modifiers(AdverseHistory);
