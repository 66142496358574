import React from "react";
import { css } from "react-emotion";
import { PipelineTags } from "../types";

type Props = {
  tag: PipelineTags;
};

const CustomTag: React.FC<Props> = ({ tag }) => (
  <span
    css={css`
      padding: 4px 0;

      font-size: 14px;
      letter-spacing: 0.5px;
      font-weight: 500;
    `}
  >
    {tagText[tag]}
  </span>
);

const tagText: Record<PipelineTags, string> = {
  virtual_refinement: "Virtual Refinement",
  rush: "Rush",
};

export default CustomTag;
