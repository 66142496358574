const template = (message = "", attrs = {}) => {
  // create a new Proxy so that, any time a user adds an invalid template or
  // our patient has missing data, we can respond with an empty string.
  //
  // If we don't use proxies then all data in the template needs to be
  // predefined, else the template will say 'undefined' instaed of an empty
  // string.
  const proxy = new Proxy(attrs || {}, {
    get(target, key) {
      return key in target ? target[key] : "**MISSING**";
    },
  });

  // We can't use lodash's template as it uses `with` to interpolate
  // with the given data;  with doesn't work with proxies so this would
  // throw errors if template data doesn't exist.
  //
  // Lodash's template also uses eval.  Eval is bad.
  const data = message.replace(/{{([\s\S]+?)}}/g, (match, token) => {
    return proxy[token.trim()];
  });

  // Ensure that if the template data was missing prior to punctuation marks
  // we don't have an empty space, eg. "Hi ,"
  return data.replace(/\s([.,?!;])/g, (_, token) => token);
};

export default template;
