import { DateTime } from "luxon";

export type IsoDate = string;

export const toDateTime = (d: IsoDate | DateTime): DateTime => {
  return typeof d === "string" ? DateTime.fromISO(d) : d;
};

export const toMillis = (isoDate: IsoDate): number => {
  return DateTime.fromISO(isoDate).toMillis();
};

export const toUTC = (isoDate: IsoDate | DateTime): IsoDate => {
  const d = typeof isoDate === "string" ? DateTime.fromISO(isoDate) : isoDate;
  return d.toUTC().toISO();
};

export const toWeekday = (isoDate: IsoDate): string => {
  return DateTime.fromISO(isoDate).weekdayLong;
};

export const toISODate = (isoDate: IsoDate, timezone?: string): string => {
  if (timezone) {
    return DateTime.fromISO(isoDate)
      .setZone(timezone)
      .toISODate();
  }
  return DateTime.fromISO(isoDate).toISODate();
};

export const diffInSeconds = (a, b) => {
  return DateTime.fromISO(a)
    .diff(DateTime.fromISO(b))
    .as("seconds");
};

export const diffInHours = (a, b) => {
  return DateTime.fromISO(a)
    .diff(DateTime.fromISO(b))
    .as("hours");
};

export const diffInDays = (a, b) => {
  return DateTime.fromISO(a)
    .diff(DateTime.fromISO(b))
    .as("days");
};

export const diffInMonths = (a, b) => {
  return DateTime.fromISO(a)
    .diff(DateTime.fromISO(b))
    .as("months");
};

export const addDays = (isoDate: IsoDate, days: number) => {
  return DateTime.fromISO(isoDate)
    .plus({ days })
    .toISO();
};

export default function time(timezone) {
  const now = () => {
    return DateTime.local()
      .setZone(timezone)
      .toISO();
  };

  const endOfDay = (isoDate: IsoDate | DateTime): IsoDate => {
    const d = typeof isoDate === "string" ? DateTime.fromISO(isoDate) : isoDate;
    return d
      .setZone(timezone)
      .endOf("day")
      .toISO();
  };
  const startOfDay = (isoDate: IsoDate | DateTime): IsoDate => {
    const d = typeof isoDate === "string" ? DateTime.fromISO(isoDate) : isoDate;
    return d
      .setZone(timezone)
      .startOf("day")
      .toISO();
  };

  const startOfWeek = (isoDate: IsoDate | DateTime): IsoDate => {
    const d = typeof isoDate === "string" ? DateTime.fromISO(isoDate) : isoDate;
    return d
      .setZone(timezone)
      .setZone(timezone)
      .startOf("week")
      .toISO();
  };

  const toHour = (isoDate?: IsoDate): string => {
    if (!isoDate) {
      return "";
    }

    return DateTime.fromISO(isoDate)
      .setZone(timezone)
      .toLocaleString({
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
  };

  const toDateYear = (isoDate: IsoDate): string => {
    return DateTime.fromISO(isoDate)
      .setZone(timezone)
      .toFormat("DD");
  };

  const localIsoDate = (): IsoDate => {
    return DateTime.local()
      .setZone(timezone)
      .toISODate();
  };

  const addDays = (isoDate: IsoDate | DateTime, days: number) => {
    const d = typeof isoDate === "string" ? DateTime.fromISO(isoDate) : isoDate;
    return d
      .setZone(timezone)
      .setZone(timezone)
      .plus({ days })
      .toISO();
  };

  const addSeconds = (isoDate: IsoDate | DateTime, seconds: number) => {
    const d = typeof isoDate === "string" ? DateTime.fromISO(isoDate) : isoDate;
    return d
      .setZone(timezone)
      .setZone(timezone)
      .plus({ seconds })
      .toISO();
  };

  return {
    addDays,
    addSeconds,
    diffInHours,
    diffInSeconds,
    endOfDay,
    localIsoDate,
    now,
    startOfDay,
    startOfWeek,
    toDateYear,
    toHour,
    toISODate,
    toMillis,
    toUTC,
    toWeekday,
  };
}
