import React from "react";
import { CrownProps, RootProps } from "./types";

export const Crown: React.SFC<CrownProps> = ({ fill, stroke, style }) => (
  <svg
    width="22"
    height="26"
    viewBox="0 0 22 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M16.3223 23.2249C13.632 25.9011 8.72471 24.5878 6.60736 23.5966C1.97409 22.2585 0.940324 17.9593 1.0026 15.9769C1.56307 8.72895 4.17863 1.66685 9.40974 0.923465C14.6408 0.180083 18.751 9.10066 19.872 13.9326C20.9929 18.7646 20.0588 20.2514 16.3223 23.2249Z"
      fill={fill || "#fff"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />
  </svg>
);

// Root always contains the portion as if it attached as a Crown.
export const Root: React.SFC<RootProps> = ({
  hasRootCanal,
  style,
  fill,
  stroke,
}) => (
  <svg
    width="15"
    height="52"
    viewBox="0 0 15 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M1 46C2.1762 40.28 2.05667 27.8947 2.17919 20.9891C2.26478 17.3225 1.62784 10.9058 1.62784 7.60581C1.62784 4.30581 1.44406 1.18917 3.09809 1.00584C4.42132 0.859174 5.85481 3.51139 6.40615 4.85583C13.1693 26.5625 14.1126 40.3778 13.5 46H10V51H5V46H1Z"
      fill={fill || "#F1F4F5"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />

    {hasRootCanal && (
      <g transform="translate(3,1.5)">
        <path
          d="M0.5 0.5C2.1 13.7 2.66667 37.5 2 43L6.5 44C4 25.5 1.66667 4.83333 0.5 0.5Z"
          fill="#C270A7"
        />
      </g>
    )}
  </svg>
);
