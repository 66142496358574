import useSelf from "src/state/self";
import { useQuery } from "src/utils/http/gqlQuery";
import { Staff } from "src/types/gql";

type StaffFields = keyof Staff;

type StaffResponseType<RequestedKeys extends StaffFields> = {
  staff: Pick<Staff, "name" | RequestedKeys>[];
};

const getQuery = (fields: StaffFields[]) => `
  query Staff($id: ID!) {
    staff: staffUsers(organizationID: $id) {
      ${fields.join("\n")}
    }
  }
`;

// name is being passed under the hood because
// it's needed for the further filtration
export const useStaff = <RequestedKeys extends Exclude<StaffFields, "name">>(
  fields: RequestedKeys[]
) => {
  // We use the "staffSelf" endpoint instead of "staff" so that the API dedupes
  // results for us.  This means we have to hard code the UT organization here.

  // TODO: Fix for multi-tenancy.
  const self = useSelf();
  const org = self.organizations.find(o => o.name === "Uniform Teeth") || {
    id: "",
  };
  const [{ data, ...meta }, ...opts] = useQuery<
    StaffResponseType<RequestedKeys>
  >({
    query: getQuery(["name", ...fields]),
    variables: {
      id: org.id,
    },
  });

  const staff = (data ? data.staff : []).filter(
    staff => staff.name !== "UniformTeeth App"
  );

  return [{ data: staff, ...meta }, ...opts] as const;
};
