import { V, S } from "src/shared-styles";
export default {
  V,
  S,
  orderListContainer: {
    ...S.materialUICard,
  },
  order: {
    display: "flex",
    padding: V.spacingSmall,
    borderBottom: V.border,
  },
  orderMain: {
    flex: 1,
  },
  actions: {
    textAlign: "right",
  },
  cost: {
    marginRight: V.spacing,
    fontSize: V.fontLarge,
  },
  status: {
    fontWeight: V.fontWeightHeavy,
    textTransform: "capitalize",
  },
};
