import React from "react";
import { Submission } from "src/scenes/PatientProfile/Forms/GQLForms/types";
import {
  Prewrap,
  SummaryHeader,
  SummaryField,
  SummaryFieldLabel,
  SummaryFieldContent,
} from "../styles";
import { getAnswer } from "../util";

export interface Props {
  sortedSubmissions: Array<Submission>;
}

export default function TreatmentGoals({ sortedSubmissions }: Props) {
  const latestBV = sortedSubmissions.find(
    it => it.form.slug === "beginning_visit"
  );
  if (!latestBV) {
    return null;
  }
  const writtenConcerns = getAnswer("patient_concerns", latestBV);
  const childSubmissions = sortedSubmissions.filter(
    it => it.parentID === latestBV.id
  );

  const chosen_treatment = childSubmissions.find(
    it => getAnswer("is_chosen_option", it) === "Yes"
  );
  const treatment_goals_summary =
    chosen_treatment &&
    getAnswer("treatment_goals_generated_summary", chosen_treatment);

  if (!writtenConcerns && !treatment_goals_summary) {
    return null;
  }

  return (
    <>
      <SummaryHeader>Treatment Goals</SummaryHeader>
      {writtenConcerns && (
        <SummaryField key="patient_concerns">
          <SummaryFieldLabel>Patient concerns</SummaryFieldLabel>
          <SummaryFieldContent>
            <Prewrap>{writtenConcerns}</Prewrap>
          </SummaryFieldContent>
        </SummaryField>
      )}
      <Prewrap key="treatment_goals_summary">{treatment_goals_summary}</Prewrap>
    </>
  );
}
