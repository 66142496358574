import React from "react";

const Discover = () => (
  <svg width="64" height="20" viewBox="0 0 64 20" fill="none">
    <path
      d="M33.3156 14.3561C35.8746 14.3561 37.9491 12.2816 37.9491 9.72258C37.9491 7.16358 35.8746 5.0891 33.3156 5.0891C30.7567 5.0891 28.6822 7.16358 28.6822 9.72258C28.6822 12.2816 30.7567 14.3561 33.3156 14.3561Z"
      fill="#EF9122"
    />
    <path
      d="M28.6822 9.72258C28.6822 7.13853 30.7316 5.0891 33.3156 5.0891C35.8997 5.0891 37.9491 7.13853 37.9491 9.72258C37.9491 12.3066 35.8997 14.3561 33.3156 14.3561"
      fill="#F26E21"
    />
    <path
      d="M30.1078 6.42568C31.8899 4.64358 34.8304 4.64358 36.6125 6.42568C38.3946 8.20779 38.3946 11.1483 36.6125 12.9304"
      fill="#F44500"
    />
    <path
      d="M6.49495 5.35641H4V14.0887H6.49495C7.83153 14.0887 8.81169 13.7323 9.61363 13.1086C10.5938 12.3066 11.2175 11.0592 11.2175 9.72257C11.2175 7.13852 9.34632 5.35641 6.49495 5.35641ZM8.54437 11.9502C8.00974 12.3957 7.2969 12.663 6.22763 12.663H5.693V6.87121H6.13853C7.20779 6.87121 7.92063 7.04941 8.45526 7.58405C9.079 8.11868 9.43542 8.92063 9.43542 9.72257C9.52453 10.5245 9.16811 11.4156 8.54437 11.9502Z"
      fill="#595A5B"
    />
    <path
      d="M13.7125 5.35641H12.0195V14.0887H13.7125V5.35641Z"
      fill="#595A5B"
    />
    <path
      d="M17.9895 8.74242C16.9203 8.386 16.6529 8.11869 16.6529 7.67316C16.6529 7.13853 17.1876 6.693 17.9004 6.693C18.435 6.693 18.8806 6.87121 19.3261 7.40585L20.2172 6.24748C19.5043 5.62374 18.6133 5.26732 17.6331 5.26732C16.1183 5.26732 14.8708 6.33658 14.8708 7.76227C14.8708 9.00974 15.4055 9.54437 17.0094 10.1681C17.7222 10.4354 17.9895 10.5245 18.1677 10.7027C18.5242 10.881 18.7024 11.2374 18.7024 11.5938C18.7024 12.3066 18.1677 12.8413 17.3658 12.8413C16.5638 12.8413 15.851 12.3957 15.4946 11.6829L14.4253 12.7522C15.2273 13.9105 16.2074 14.4452 17.4549 14.4452C19.237 14.4452 20.4845 13.2868 20.4845 11.5938C20.4845 10.079 19.9498 9.45527 17.9895 8.74242Z"
      fill="#595A5B"
    />
    <path
      d="M21.0191 9.72258C21.0191 12.3066 23.0685 14.2669 25.6526 14.2669C26.3654 14.2669 26.9892 14.0887 27.7911 13.7323V11.6829C27.0783 12.3957 26.4545 12.6631 25.7417 12.6631C24.0487 12.6631 22.8012 11.4156 22.8012 9.63348C22.8012 7.94047 24.0487 6.6039 25.6526 6.6039C26.4545 6.6039 27.0783 6.87121 27.7911 7.58405V5.53463C27.0783 5.17821 26.4545 5 25.6526 5C23.1576 5.17821 21.0191 7.22763 21.0191 9.72258Z"
      fill="#3B3D3F"
    />
    <path
      d="M41.7806 11.2374L39.4639 5.35641H37.5927L41.246 14.356H42.2262L46.0577 5.35641H44.1865L41.7806 11.2374Z"
      fill="black"
    />
    <path
      d="M46.7705 14.0887H51.6713V12.663H48.5526V10.2572H51.5822V8.74242H48.5526V6.87121H51.6713V5.35641H46.7705V14.0887Z"
      fill="black"
    />
    <path
      d="M56.483 10.4355C57.8196 10.1681 58.5325 9.27708 58.5325 7.9405C58.5325 6.33661 57.3741 5.35645 55.4138 5.35645H52.8297V14.0888H54.5227V10.6137H54.79L57.1959 14.0888H59.2453L56.483 10.4355ZM55.0573 9.36619H54.5227V6.69303H55.0573C56.1266 6.69303 56.7503 7.13855 56.7503 8.02961C56.7503 8.92066 56.1266 9.36619 55.0573 9.36619Z"
      fill="#595A5B"
    />
    <path
      d="M6.49495 5.35641H4V14.0887H6.49495C7.83153 14.0887 8.81169 13.7323 9.61363 13.1086C10.5938 12.3066 11.2175 11.0592 11.2175 9.72257C11.2175 7.13852 9.34632 5.35641 6.49495 5.35641ZM8.54437 11.9502C8.00974 12.3957 7.2969 12.663 6.22763 12.663H5.693V6.87121H6.13853C7.20779 6.87121 7.92063 7.04941 8.45526 7.58405C9.079 8.11868 9.43542 8.92063 9.43542 9.72257C9.52453 10.5245 9.16811 11.4156 8.54437 11.9502Z"
      fill="#595A5B"
    />
    <path
      d="M13.7125 5.35641H12.0195V14.0887H13.7125V5.35641Z"
      fill="#595A5B"
    />
    <path
      d="M17.9895 8.74242C16.9203 8.386 16.6529 8.11869 16.6529 7.67316C16.6529 7.13853 17.1876 6.693 17.9004 6.693C18.435 6.693 18.8806 6.87121 19.3261 7.40585L20.2172 6.24748C19.5043 5.62374 18.6133 5.26732 17.6331 5.26732C16.1183 5.26732 14.8708 6.33658 14.8708 7.76227C14.8708 9.00974 15.4055 9.54437 17.0094 10.1681C17.7222 10.4354 17.9895 10.5245 18.1677 10.7027C18.5242 10.881 18.7024 11.2374 18.7024 11.5938C18.7024 12.3066 18.1677 12.8413 17.3658 12.8413C16.5638 12.8413 15.851 12.3957 15.4946 11.6829L14.4253 12.7522C15.2273 13.9105 16.2074 14.4452 17.4549 14.4452C19.237 14.4452 20.4845 13.2868 20.4845 11.5938C20.4845 10.079 19.9498 9.45527 17.9895 8.74242Z"
      fill="#595A5B"
    />
    <path
      d="M25.7417 14.267C26.4545 14.267 27.0783 14.0888 27.8802 13.7324V11.6829C27.1674 12.3958 26.5436 12.6631 25.8308 12.6631C24.1378 12.6631 22.8903 11.4156 22.8903 9.63351C22.8903 8.74246 23.2467 8.02962 23.7814 7.40588L22.623 6.24751C21.732 7.04946 21.1082 8.29693 21.1082 9.63351C21.0191 12.3067 23.0685 14.267 25.7417 14.267Z"
      fill="#595A5B"
    />
    <path
      d="M56.483 10.4354C57.8196 10.1681 58.5325 9.27705 58.5325 7.94047C58.5325 6.33657 57.3741 5.35641 55.4138 5.35641H52.8297V14.0887H54.5227V10.6136H54.79L57.1959 14.0887H59.2453L56.483 10.4354ZM55.0573 9.36615H54.5227V6.69299H55.0573C56.1266 6.69299 56.7503 7.13852 56.7503 8.02957C56.7503 8.92063 56.1266 9.36615 55.0573 9.36615Z"
      fill="black"
    />
    <path
      d="M50.1565 12.6631H48.5526V11.0592L46.7705 9.27708V14.0888H51.5822L50.1565 12.6631Z"
      fill="#3B3D3F"
    />
    <path
      d="M43.741 6.24747L41.7806 11.2374L39.4639 5.35641H37.5927L41.246 14.356H42.2262L45.0775 7.58405L43.741 6.24747Z"
      fill="#3B3D3F"
    />
  </svg>
);

export default Discover;
