import React, { useState } from "react";
import styled from "react-emotion";
import { notification, DatePicker } from "antd";
import { isEmpty, upperFirst } from "lodash";

import { useQuery } from "src/utils/http/gqlQuery";
import color from "src/styles/color";
import Box from "src/shared/Box";
import Button, { ButtonRow } from "src/shared/Button";
import MoneyInput from "src/shared/MoneyInput";
import Loading from "src/shared/Loading";
import Error from "src/shared/Error";
import Modal from "src/shared/Modal";
import { orderGql } from "src/scenes/Patient/BillingV2/Orders/query";
import { useRecordInsuranceClaimsSubmission } from "../query";
import { usePolicies } from "src/scenes/Patient/BillingV2/Insurance/query";

const Content = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;

  height: 400px;
  width: 600px;
`;

const InputLabel = styled.div`
  font-weight: bold;
  margin-top: 16px;
`;

const Footer = styled.div`
  border-top: 1px solid ${color.border};
  padding: 16px 32px 32px 32px;
`;

const InputContainer = styled.div`
  padding: 16px 32px;
  overflow-y: scroll;
  flex: 1;
`;

const DateWrapper = styled.div`
  margin-top: 12px;
`;

const InsurancePolicyButton = styled.button`
  background: none;
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 5px;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 8px;
  max-width: 250px;
`;

type Props = {
  onClose: () => void;
  orderID: string;
  patientID: string;
};

const CLAIM_STATUSES = ["submitted", "unsubmitted", "approved", "denied"];
const DEFAULT_STATUS = "unsubmitted";

export const InsuranceClaimStatusSelect = ({ value, onChange }) => {
  const handleChange = (e: React.FormEvent<HTMLSelectElement>) =>
    onChange(e.currentTarget.value);

  return (
    <select value={value} onChange={handleChange}>
      {CLAIM_STATUSES.map(status => (
        <option key={status} value={status}>
          {upperFirst(status)}
        </option>
      ))}
    </select>
  );
};

export const getAmountLabel = status => {
  // amount input should be hidden when status is denied
  switch (status) {
    case "unsubmitted":
    case "submitted":
      return "Estimated total payout amount";
    case "approved":
      return "Total payout amount";
  }

  return "Total amount";
};

const Inner: React.FC<Props> = ({ onClose, orderID, patientID }) => {
  const recordClaim = useRecordInsuranceClaimsSubmission();
  const [amount, setAmount] = useState(0);
  const [status, setStatus] = useState(DEFAULT_STATUS);
  const [dateSubmitted, setDateSubmitted] = useState<string | null>(null);
  const [selectedPolicy, setSelectedPolicy] = useState<string | null>(null);
  const [fetching, error, policies] = usePolicies(patientID);
  const [, refetch] = useQuery({
    query: orderGql,
    variables: { userID: patientID },
    pause: true,
  });

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const res = await recordClaim({
      input: {
        orderID,
        insurancePolicyID: selectedPolicy || policies[0].id,
        amount,
        dateSubmitted,
        status,
      },
    });

    if (res.error) {
      notification.error({
        message: "There was an error recording this insurance claim.",
      });
    } else {
      notification.success({ message: "Insurance claim recorded!" });
      refetch();
      onClose();
    }
  };

  if (fetching) return <Loading />;
  if (error) return <Error />;

  return (
    <Content>
      <InputContainer>
        {isEmpty(policies) && <div>Please add an insurance policy first.</div>}
        {policies.length > 1 && (
          <>
            <InputLabel>Select a policy</InputLabel>
            <div>
              Patient has more than one policy. Please select the one this claim
              will be applied to:
            </div>
            <ButtonContainer>
              {policies.map(policy => (
                <InsurancePolicyButton
                  style={{
                    borderColor:
                      policy.id === selectedPolicy ? color.orange : undefined,
                  }}
                  onClick={() => setSelectedPolicy(policy.id)}
                >
                  <div>
                    {policy.InsuranceProvider.name}
                    <br />
                    Subscriber: {policy.subscriberFirstName}{" "}
                    {policy.subscriberLastName}
                  </div>
                </InsurancePolicyButton>
              ))}
            </ButtonContainer>
          </>
        )}
        {policies.length === 1 && (
          <div>
            <InputLabel>Insurance policy:</InputLabel>
            <div>{policies[0].InsuranceProvider.name}</div>
            <div>
              Subscriber: {policies[0].subscriberFirstName}{" "}
              {policies[0].subscriberLastName}
            </div>
          </div>
        )}
        {!isEmpty(policies) && (
          <div>
            <InputLabel>Status</InputLabel>
            <InsuranceClaimStatusSelect value={status} onChange={setStatus} />
            {status !== "denied" && (
              <>
                <InputLabel>{getAmountLabel(status)}</InputLabel>
                <MoneyInput value={amount} onChange={setAmount} />
              </>
            )}
            {status !== "unsubmitted" && (
              <DateWrapper>
                <InputLabel>Date submitted:</InputLabel>
                <DatePicker
                  allowClear
                  onChange={(_, dateString) => setDateSubmitted(dateString)}
                />
              </DateWrapper>
            )}
          </div>
        )}
      </InputContainer>
      <Footer>
        <ButtonRow position="right">
          <Button kind="default" onClick={onClose}>
            Cancel
          </Button>
          <Button kind="primary" onClick={submit}>
            Submit
          </Button>
        </ButtonRow>
      </Footer>
    </Content>
  );
};

const RecordInsuranceClaimModal: React.FC<Props> = props => {
  return (
    <Modal onClose={props.onClose}>
      <Box title="Record insurance claim">
        <Inner {...props} />
      </Box>
    </Modal>
  );
};

export default RecordInsuranceClaimModal;
