import React from "react";
import ChooseManufacturer from "./ChooseManufacturer";
import CreateKit from "./CreateKit";
import ShipKit from "./ShipKit";
import ManufacturingBuild from "./ManufacturingBuild";
import QA from "./QA";
import { Task } from "../../types";
import ExternalProvider from "./ExternalProvider";

type Props = {
  task: Task;
  // either a string, an array containing the content to show and reference ID, or null
  content: string | [string, string] | null;
  onChangeContent: (to: string | null) => void;
  pipelineID: string;
};

// Renders custom content depending on the state within the task modal.
//
// This custom content is interactively shown when hitting a reference, and is set by
// components within ../References/*.tsx.
const CustomContent: React.FC<Props> = props => {
  const item = Array.isArray(props.content) ? props.content[0] : props.content;

  switch (item) {
    case "choose_manufacturer":
      return (
        <ChooseManufacturer task={props.task} pipelineID={props.pipelineID} />
      );
    case "create_kit":
      return <CreateKit task={props.task} />;
    case "ship_kit":
      return <ShipKit task={props.task} />;
    case "build": {
      const content = props.content as [string, string];
      return <ManufacturingBuild task={props.task} referenceID={content[1]} />;
    }
    case "qa": {
      const content = props.content as [string, string];
      return <QA task={props.task} referenceID={content[1]} />;
    }
    case "external_care":
      return <ExternalProvider {...props} />;
  }
  return null;
};

export default CustomContent;
