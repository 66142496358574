import React, { useMemo } from "react";
import styled from "react-emotion";
import { css, cx } from "emotion";
import { useStateContext } from "./state";
import { ToothNumber, ToothLocation, UIAttribute, State } from "./types";
import ToothIcon from "./ToothIcon/ToothIcon";
import IPR from "./IPR";
import Spacing from "./Spacing";
import {
  useToothAttributes,
  getName,
  getMesialNumber,
  getDistalNumber,
} from "./consts";

type Props = {
  toothNumber: ToothNumber;

  // restrictToDisplay represents wqhether we want to restrict showing only the original
  // and currently displayed attributes on a tooth (eg. if you're on rx, only show rx + original).
  // default is true on the tooth chart, false in the selected tooth modal
  restrictToDisplay?: boolean;

  // ipr represents whether to show IPR as a diamond.  it is not shown in the selected
  // tooth modal.
  ipr?: boolean;

  editable: boolean;
};

const Tooth: React.SFC<Props> = ({
  toothNumber,
  ipr,
  restrictToDisplay,
  editable,
}) => {
  const [state, dispatch] = useStateContext();
  const { uiAttributes } = useToothAttributes(
    state,
    toothNumber,
    !!restrictToDisplay
  );

  const isBaby = !!uiAttributes.find(a => a.type === "deciduous");
  const toothIconProps = useToothIconProps(uiAttributes);
  const connecting = useConnectingAttributes(
    state,
    toothNumber,
    uiAttributes,
    !!restrictToDisplay
  );
  const lhs = toothNumber >= 9 && toothNumber <= 24;
  const lower = toothNumber > 16;
  // if the selected tool is spacing, show spacing.
  const spacing = state.display === "spacing";

  // Baby teeth change the name from palmer notation to an E-A range, depending
  // on the toot #.
  const name = getName(toothNumber, isBaby);

  const onClick = (s: ToothLocation) => {
    if (!state.selectedTool) {
      return;
    }

    // If we've selected IPR, we actually need to split the amount
    // between the currently selected tooth and either the tooth mesially/distally
    // depending on the clicked surface.  This is because we rarely do IPR on one
    // surface - it's usually split in the surface between teeth.
    //
    // Note that IPR is always added, not toggled.  IPR is removed by clicking on
    // the IPR indicator that shows on the tooth;  toggling leaves us in a difficult
    // situation, as you can use the IPR indicator to add "mesial only" IPR, then
    // toggling that tooth will always turn one side on and one side off.
    if (state.selectedTool.type === "ipr") {
      const data = (parseFloat(state.selectedTool.data || "0.2") / 2).toFixed(
        3
      );

      // Select and add to the current surface
      dispatch({
        toothNumber,
        type: "addAttribute",
        attribute: state.selectedTool.type,
        data: data,
        location: s,
      });

      // If this is tooth 8, 9, 24, or 25 and we're selecting the mesial, we need to
      // also select the mesial of the neighbouring tooth.  Else, for any other tooth
      // select the opposite face.
      if ([8, 9, 24, 25].includes(toothNumber) && s === "mesial") {
        dispatch({
          toothNumber: getMesialNumber(toothNumber),
          type: "addAttribute",
          attribute: state.selectedTool.type,
          data: data,
          location: s,
        });
        return;
      }

      // Select adjascent tooth and opposite surface.
      dispatch({
        toothNumber:
          s === "mesial"
            ? getMesialNumber(toothNumber)
            : getDistalNumber(toothNumber),
        type: "addAttribute",
        attribute: state.selectedTool.type,
        data: data,
        location: s === "mesial" ? "distal" : "mesial",
      });
      return;
    }

    dispatch({
      toothNumber,
      type: "toggleAttribute",
      attribute: state.selectedTool.type,
      data: state.selectedTool.data || undefined,
      location: s,
    });
  };

  const firstConnecting = connecting[lhs ? "mesial" : "distal"];
  const lastConnecting = connecting[lhs ? "distal" : "mesial"];

  return (
    <Wrapper className={cx([toothNumber > 16 && lowerCSS])}>
      {firstConnecting && (
        <Line
          toothNumber={toothNumber}
          attribute={firstConnecting}
          position="before"
        />
      )}
      {toothNumber > 1 && toothNumber < 32 && !!ipr && !spacing && (
        <IPR
          position="before"
          surface={lhs ? "mesial" : "distal"}
          toothNumber={toothNumber}
          restrictToDisplay={restrictToDisplay}
          editable={editable}
        />
      )}

      {toothNumber > 1 && toothNumber < 32 && spacing && (
        <Spacing
          position="before"
          surface={lhs ? "mesial" : "distal"}
          toothNumber={toothNumber}
          restrictToDisplay={restrictToDisplay}
          editable={editable}
        />
      )}

      <ToothIcon
        toothNumber={toothNumber}
        impacted={!!uiAttributes.find(a => a.type === "impacted")}
        hideRoot={
          firstConnecting && lastConnecting && firstConnecting.type === "bridge"
        }
        {...toothIconProps}
        isBaby={isBaby}
        onClick={() => {
          dispatch({
            type: "selectTooth",
            selectedTooth: toothNumber,
          });
        }}
      />

      <Number>{name}</Number>
      <Surface className={getEntireToothBorder(uiAttributes)}>
        <Quad>
          <Location
            key="lingual_buccal"
            location={lower ? "lingual" : "buccal"}
            onClick={onClick}
            uiAttributes={uiAttributes}
          />
          <Location
            key="distal_mesial"
            location={lhs ? "distal" : "mesial"}
            onClick={onClick}
            uiAttributes={uiAttributes}
          />
          <Location
            key="mesial_distal"
            location={lhs ? "mesial" : "distal"}
            onClick={onClick}
            uiAttributes={uiAttributes}
          />
          <Location
            key="buccal_lingual"
            location={lower ? "buccal" : "lingual"}
            onClick={onClick}
            uiAttributes={uiAttributes}
          />
        </Quad>
        <Location
          key="occlusal"
          location="occlusal"
          onClick={onClick}
          uiAttributes={uiAttributes}
        />
      </Surface>

      {toothNumber !== 16 && toothNumber !== 17 && !!ipr && !spacing && (
        <IPR
          position="after"
          surface={lhs ? "distal" : "mesial"}
          toothNumber={toothNumber}
          restrictToDisplay={restrictToDisplay}
          editable={editable}
        />
      )}
      {toothNumber !== 16 && toothNumber !== 17 && spacing && (
        <Spacing
          position="after"
          surface={lhs ? "distal" : "mesial"}
          toothNumber={toothNumber}
          restrictToDisplay={restrictToDisplay}
          editable={editable}
        />
      )}
      {lastConnecting && (
        <Line
          toothNumber={toothNumber}
          attribute={lastConnecting}
          position="after"
        />
      )}
    </Wrapper>
  );
};

export default Tooth;

const filterAttributesForLocation = (
  attributes: UIAttribute[],
  location: ToothLocation
) => {
  return attributes.filter(a => {
    if (!a.attribute) {
      return false;
    }

    if (
      a.attribute.location === location &&
      (a.locations || []).includes(location)
    ) {
      return true;
    }

    if (!a.attribute.location && location === "occlusal") {
      // Show things occlusally with no prior location
      return true;
    }

    return false;
  });
};

type LocationProps = {
  location: ToothLocation;
  onClick: (_: ToothLocation) => void;
  uiAttributes: UIAttribute[];
};

const Location: React.SFC<LocationProps> = ({
  location,
  onClick,
  uiAttributes,
}) => {
  const items = filterAttributesForLocation(uiAttributes, location);

  const C = location === "occlusal" ? Occlusal : Side;

  return (
    <C
      onClick={() => onClick(location)}
      className={cx([
        getLocationBorder(items, location),
        // We also have to render entire border classes to make those work
        getEntireToothBorder(uiAttributes),
      ])}
    >
      <RxContainer>
        {items.map((i, index) => {
          const key = i.attribute
            ? `${i.attribute.type}-at-${i.attribute.location}-${
                i.attribute.toothNumber
              }-${i.attribute.stage}`
            : index;
          return (
            <div
              key={key}
              className={cx([
                i.attribute &&
                  i.attribute.stage === "prescribed" &&
                  prescribedCSS(i.color),
                i.attribute &&
                  i.attribute.stage === "performed" &&
                  performedCSS(i.color),
                // if we always want to show a fileld tooth, do so.
                i.attribute &&
                  i.surfaceDisplay &&
                  i.surfaceDisplay.fill &&
                  performedCSS(i.color),
              ])}
            />
          );
        })}
      </RxContainer>
    </C>
  );
};

const useToothIconProps = (uiAttributes: UIAttribute[]) => {
  // We render many attributes to the tooth icon itself.  To simplify rendering, the tooth
  // SVG component's props accepts an enum for the type of crown, root, etc. to display.
  // We determine this based off of the uiAttributes found above, sorting them from their
  // priorities and returning the first item.
  return useMemo(() => {
    const roots = uiAttributes
      .filter(ua => {
        return [
          "bridge",
          "gum_disease",
          "implant",
          "root_canal",
          "missing",
        ].includes(ua.type);
      })
      .sort(
        (a, b) => (b.rootPriority || Infinity) - (a.rootPriority || Infinity)
      );

    const crowns = uiAttributes
      .filter(ua => {
        return ["missing", "crown", "chipped", "caries", "bridge"].includes(
          ua.type
        );
      })
      .sort(
        (a, b) => (b.crownPriority || Infinity) - (a.crownPriority || Infinity)
      );

    const icons = uiAttributes
      .filter(ua => {
        return ["attachment", "hook", "carriere", "powerarm"].includes(ua.type);
      })
      .sort(
        (a, b) =>
          (b.crownIconPriority || Infinity) - (a.crownIconPriority || Infinity)
      );

    return {
      root: roots[0] && (roots[0].type as any),
      crown: crowns[0] && (crowns[0].type as any),
      crownIcon: icons[0] && (icons[0].type as any),
    };
  }, [uiAttributes]);
};

type ConnectingAttribtues = {
  mesial?: UIAttribute | undefined;
  distal?: UIAttribute | undefined;
};

// useConnectingProps returns the UI attribute to render a connecting line for, if this
// tooth and its neighbor has a UI attribute that requires a line drawn to connect
// the crowns.
const useConnectingAttributes = (
  state: State,
  toothNumber: ToothNumber,
  uiAttributes: UIAttribute[],
  restrict: boolean
): ConnectingAttribtues => {
  const { uiAttributes: mesial } = useToothAttributes(
    state,
    getMesialNumber(toothNumber),
    restrict
  );
  const { uiAttributes: distal } = useToothAttributes(
    state,
    getDistalNumber(toothNumber),
    restrict
  );

  const connecting = uiAttributes.filter(a => a.connects);

  const result: ConnectingAttribtues = { mesial: undefined, distal: undefined };
  // For each connecting attribute this tooth has, see whether the same attribute is also
  // on the mesial or distal tooth.
  connecting.forEach(c => {
    if (attributesIncludes(mesial, c)) {
      result.mesial = c;
    }
    if (attributesIncludes(distal, c)) {
      result.distal = c;
    }
  });

  return result;
};

const attributesIncludes = (set: UIAttribute[], find: UIAttribute): boolean => {
  return !!set.find(a => a.type === find.type);
};

const Line = (props: {
  attribute: UIAttribute;
  position: "before" | "after";
  toothNumber: ToothNumber;
}) => {
  const lower = props.toothNumber > 16;
  const style = {
    background: props.attribute.stroke,
    left: props.position === "before" ? 0 : undefined,
    right: props.position === "after" ? 0 : undefined,
    bottom: lower ? 73 : undefined,
    top: lower ? undefined : 73,
  };
  return <LineEl style={style} />;
};

const LineEl = styled.div`
  position: absolute;
  z-index: 0;
  height: 2px;
  width: 50%;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
`;

const Number = styled.span`
  display: block;
  color: #a2a9ad;
  font-weight: bold;
  font-size: 10px;
  margin: 4px 0;
`;

const Surface = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 100px;
  position: relative;
  overflow: hidden;

  /* Outer circle */
  &:before {
    content: "";
    display: block;
    height: 50px;
    width: 50px;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100px;
    border: 2px solid #d7d7d7;
    pointer-events: none;
  }
`;

const Quad = styled.div`
  transform: rotate(45deg);
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0;

  > div {
    width: 26px;
    height: 26px;
    position: absolute;
    border: 1px solid #d7d7d7;
    background: #fff;
    cursor: pointer;
    overflow: hidden;

    display: flex;

    &:hover {
      background: #f1f4f5;
    }
  }

  > div:nth-child(1) {
    top: -1px;
    left: -1px;
  }
  > div:nth-child(2) {
    top: -1px;
    left: 25px;
  }
  > div:nth-child(3) {
    top: 25px;
    left: -1px;
  }
  > div:nth-child(4) {
    top: 25px;
    left: 25px;
  }
`;

const RxContainer = styled.div`
  overflow: hidden;
  display: flex;
  transform: rotate(-45deg) scale(1.25);
  flex: 1;
  height: 100%;

  > div {
    height: 100%;
    flex: 1;
  }
`;

const Side = styled.div``;

const Occlusal = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 26px;
  border: 2px solid #d7d7d7;
  background: #fff;
  cursor: pointer;

  position: absolute;
  top: calc(50% - 13px);
  left: calc(50% - 13px);

  &:hover {
    background: #f1f4f5;
  }

  overflow: hidden;
  display: flex;

  ${RxContainer} {
    /* RxContainer is rotated by default for side surfaces;  fix */
    transform: rotate(0deg) scale(1.25);
  }
`;

const lowerCSS = css`
  flex-direction: column-reverse;

  /* Fixes incorrect decimal point placement, due to parent container having 'rtl' */
  direction: ltr;
`;

const prescribedCSS = (color: string) => css`
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 1px,
    ${color} 2px,
    ${color} 3px
  );
`;

const performedCSS = (color: string) => css`
  background: ${color};
`;

const borderCSS = (color: string) => css`
  border-color: ${color} !important;

  &:before {
    /* for the outer circle */
    border-color: ${color} !important;
  }
`;

const getEntireToothBorder = (attrs: UIAttribute[]) => {
  const border = attrs.filter(
    a =>
      a.surfaceDisplay &&
      a.surfaceDisplay.borderOnly &&
      a.surfaceDisplay.entireTooth
  );
  return cx([border && border.length > 0 && borderCSS(border[0].stroke || "")]);
};

const getLocationBorder = (attrs: UIAttribute[], location: ToothLocation) => {
  const border = filterAttributesForLocation(attrs, location).filter(
    a => a.surfaceDisplay && a.surfaceDisplay.borderOnly
  );

  return cx([border && border.length > 0 && borderCSS(border[0].stroke || "")]);
};
