import React from "react";
import styled from "react-emotion";
import { Button, Tag } from "antd";
import { isEmpty } from "lodash";

/*
import type { Kit, Shipment } from "src/types/api";

type Props = {
  kit: Kit,
  onSelect: () => void,
};
*/

const Wrapper = styled("div")`
  display: flex;
  padding: 0.5rem;
  text-align: left;

  &:hover {
    background: #e6f7ff;
  }

  > div {
    flex: 1;
  }

  > div:first-child {
    display: flex;
    align-items: center;
  }

  > div:last-child {
    text-align: right;
  }
`;

const trayRange = kit => {
  const trays = kit.wear_timeline_stages;
  if (!Array.isArray(trays)) {
    return "";
  }

  const length = trays.length;

  switch (length) {
    case 0:
      return "";
    case 1:
      const template = trays[0].is_template ? " (template)" : "";
      return "Tray " + trays[0].stage + template;
    default:
      const templates = trays.filter(tray => tray.is_template);
      const templateStr =
        templates.length > 0
          ? ", Templates: " + templates.map(e => e.stage).join(", ")
          : "";
      return (
        "Trays: " + trays[0].stage + "-" + trays[length - 1].stage + templateStr
      );
  }
};

const KitItem = ({ kit, onSelect }) => {
  const range = trayRange(kit);
  return (
    <Wrapper>
      <div>
        Kit {kit.kit_number}: <b>{range}</b>
        {!isEmpty(kit.includes) && (
          <div>, includes {kit.includes.join(", ")}</div>
        )}
      </div>
      <div>
        {!kit.shipment && <Button onClick={onSelect}>Purchase shipping</Button>}
        {Boolean(kit.shipment) && (
          <div>
            {kit.shipment.label_url ? (
              <Button
                onClick={() => {
                  window.open(kit.shipment.label_url, "_blank");
                }}
              >
                Print shipping label
              </Button>
            ) : (
              <Tag>Hand delivery</Tag>
            )}
          </div>
        )}
        :
      </div>
    </Wrapper>
  );
};

export default KitItem;
