import React from "react";
import ConsentIcon from "src/shared/Icons/Consent";
import PatientConsents from "src/components/patient-consents/PatientConsents";

import useConsentsContext from "src/scenes/PatientProfile/Consents/useConsentsContext";
import HeaderButton from "../HeaderButton/HeaderButton";

import { StyleMenu } from "./styles";

const HeaderConsents = props => {
  const { patient, clinicalRecord } = props;
  const { consents, signConsent } = useConsentsContext();
  const outstandingConsents = consents && consents.filter(c => !c.signedAt);

  return (
    <HeaderButton
      dropdownMenu={
        <StyleMenu>
          <PatientConsents
            patient={patient}
            clinicalRecord={clinicalRecord}
            consents={consents}
            signConsent={signConsent}
          />
        </StyleMenu>
      }
      tooltip="Patient forms"
      theme={{
        showBadge: outstandingConsents && outstandingConsents.length,
      }}
    >
      <ConsentIcon />
    </HeaderButton>
  );
};

export default HeaderConsents;
