import React from "react";
import { Background } from "./styles";

type Props = {
  // defaults to false
  className?: string;
  style?: any;
  hidden?: boolean;
  onClose: () => void;
  ignoreOutOfBoundariesClick?: boolean;
};

const prevent = (e: React.SyntheticEvent) => {
  e.stopPropagation();
};

const Modal: React.FC<Props> = ({
  hidden,
  ignoreOutOfBoundariesClick,
  children,
  onClose,
}) => {
  if (hidden) {
    return null;
  }

  const onClick = () => {
    if (ignoreOutOfBoundariesClick) return;

    onClose();
  };

  return (
    <Background onClick={onClick}>
      <div onClick={prevent}>{children}</div>
    </Background>
  );
};

export default Modal;
