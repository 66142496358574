import { useEffect, useMemo } from "react";
import { Action, Thunk, thunk, action } from "easy-peasy";
import { request } from "src/shared/util";
import { File } from "src/types/api";
import { useActions, useStore } from "../hooks";

export interface FileState {
  data: {
    [userId: string]: Array<File>;
  };

  setData: Action<FileState, Array<File>>;
  fetch: Thunk<FileState, string>;
}

export const files: FileState = {
  data: {},

  setData: action((state, data: Array<File>) => {
    data.forEach((m: File) => {
      if (Array.isArray(state.data[m.user_id])) {
        state.data[m.user_id].push(m);
      } else {
        state.data[m.user_id] = [m];
      }
    });
  }),

  fetch: thunk(async (actions, patientId: string) => {
    const result = await request(`/api/v1/patients/${patientId}/files`);
    if (Array.isArray(result)) {
      actions.setData(result as Array<File>);
    }
  }),
};

export const useFilesForPatient = (patientId: string) => {
  const fetch = useActions(actions => {
    return actions.files.fetch;
  });
  const fileData = useStore(state => {
    return state.files.data;
  });

  useEffect(() => {
    fetch(patientId);
  }, [fetch, patientId]);

  return useMemo(() => {
    const msgs = fileData[patientId] || ([] as Array<File>);
    return msgs.sort((a, b) => (a.created_at < b.created_at ? 1 : 0));
  }, [fileData, patientId]);
};
