import styled from "react-emotion";
import font from "src/styles/font";
import { AutoComplete } from "antd";
import color from "src/styles/color";

export const Left = styled.div`
  width: 198px;
`;

export const Item = styled.div`
  flex: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Label = styled.div`
  ${font.label};
  margin: 0 0 0.25rem 0.5rem;
`;

export const Rule = styled.div`
  margin: 0.5rem 0 1rem 0;
  text-align: center;
  opacity: 0.6;
`;

export const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
  autocorrect: "off";
  autocapitalize: "off";
  spellcheck: "false";

  & input {
    border-radius: 2px !important;

    &:hover,
    &:focus {
      border-color: ${color.primary} !important;
    }

    &::placeholder {
      color: ${color.gray2};
    }
  }
`;
