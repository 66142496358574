import { FileData } from "src/shared/file";
import { url } from "src/shared/util";
import { ErrorResponse, FileResponse } from "src/shared/types";

interface ProgressTracker {
  setProgress(f: FileData, n: number): void;
  setComplete(f: FileData): void;
}

type Response = FileResponse | ErrorResponse;

export const isError = (r: Response): r is ErrorResponse => {
  return (r as FileResponse).id === undefined;
};

const upload = (f: FileData, p?: ProgressTracker): Promise<Response> => {
  return new Promise<Response>(function(resolve, reject) {
    const form = new FormData();

    if (f.description) {
      form.append("description", f.description);
    }
    if (f.internal) {
      form.append("internal", "true");
    }
    if (f.filetype) {
      form.append("filetype", f.filetype);
    }
    if (f.subtype) {
      form.append("subtype", f.subtype);
    }
    form.append("content", f.file, f.file.name);

    const xhr = new XMLHttpRequest();
    const token = window.localStorage.getItem("auth_token");

    if (p) {
      xhr.upload.onprogress = e => {
        // With 100% progress, this just means that we've sent the file
        // to the server, not that the upload has finished.  We need to wait
        // for the server to continue streaming to S3 and to properly insert
        // to the database. Therefore, in order to make people keep the page
        // open, lie and say that 100% uploaded is 99%, and only trigger complete
        // when we have a response
        const percent = Math.round((e.loaded / e.total) * 100);
        p.setProgress(f, percent === 100 ? 99 : percent);
      };
    }

    xhr.open("POST", url(`/api/v2/patients/${f.userID}/files`), true);
    xhr.setRequestHeader("Authorization", `Bearer ${token}`);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        try {
          resolve(JSON.parse(xhr.response));
        } catch (e) {
          reject({ error: "unable to parse response" });
        }
        if (p) {
          p.setComplete(f);
        }
      }
    };
    xhr.send(form);
  });
};

export default upload;
