import { RaisedButton } from "material-ui";
import { Modal, Button } from "antd";
import { map, partial, find } from "lodash";
import React, { Component } from "react";
import {
  getOrderList,
  getOrderSegmentList,
  getPaymentSourceList,
} from "src/selectors/order";
import { getAllOrdersForPatient } from "src/redux/modules/order";

import { connect } from "react-redux";
import styles from "./order-list-style";
import { momentFormatTime } from "../../config/constant";
import * as moment from "moment";
import Order from "../order/order";

const initialState = {
  showDialog: false,
  selectedOrderId: null,
};

const connected = connect(
  (state, ownProps) => ({
    orderList: getOrderList(state, ownProps.patientId),
    paymentSourceList: getPaymentSourceList(state, ownProps.patientId), // only used to force dialog re-render
    orderSegmentList: getOrderSegmentList(state, ownProps.patientId), // only used to force dialog re-render
  }),
  { getAllOrdersForPatient }
);

class OrderList extends Component {
  state = initialState;
  openListView = () => {
    this.props.getAllOrdersForPatient(this.props.patientId);
    this.setState({ showDialog: true });
  };
  closeListView = () => {
    this.setState(initialState);
  };
  setSelectedOrderId = selectedOrderId => {
    this.setState({ selectedOrderId });
  };
  clearSelectedOrder = () => {
    this.setState({ selectedOrderId: null });
  };
  renderList = () => {
    const orderList = this.props.orderList;
    return (
      <div>
        {map(orderList, order => (
          <div
            key={order.id}
            style={{
              display: "flex",
              marginBottom: "1.5rem",
              opacity: order.status === "closed" ? 0.5 : 1,
            }}
          >
            <div style={{ flex: 2 }}>
              <div style={styles.orderMain}>
                <div>
                  <span style={styles.status}>{order.status}</span> -{" "}
                  {moment(order.created_at).format(momentFormatTime)}
                </div>
                <div>{order.description}</div>
              </div>
              <div style={styles.cost}>
                ${(order.final_amount / 100).toFixed(2)}
              </div>
            </div>
            <Button onClick={partial(this.setSelectedOrderId, order.id)}>
              View
            </Button>
          </div>
        ))}
      </div>
    );
  };
  render() {
    const selectedOrder =
      this.state.selectedOrderId &&
      find(this.props.orderList, { id: this.state.selectedOrderId });
    return (
      <div>
        <RaisedButton onClick={this.openListView} label="View All Orders" />
        <Modal
          title="Orders"
          modal={false}
          visible={this.state.showDialog}
          onCancel={this.closeListView}
          contentClassName="full-width-dialog-content"
          footer={null}
          width={800}
        >
          {this.state.selectedOrderId ? (
            <div>
              <Order
                order={selectedOrder}
                onClose={this.clearSelectedOrder}
                patientId={this.props.patientId}
              />
            </div>
          ) : (
            this.renderList()
          )}
        </Modal>
      </div>
    );
  }
}

export default connected(OrderList);
