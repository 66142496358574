import gql from "graphql-tag";

export const baseData = gql`
  query SchedulingData {
    clinics: clinics {
      id
      name
      city
      line1
      timezone
      BookableResources {
        id
        name
      }
    }
    doctors: staff(role: "orthodontist") {
      id
      firstName
      lastName
    }
    types: appointmentTypes {
      id
      name
      enabled
      doctorDuration
      largestPatientBuffer
      largestRemainingDuration
    }
  }
`;

export const schedule = gql`
  query Schedule($from: Time!, $to: Time!) {
    schedule(from: $from, to: $to) {
      id
      startTime
      endTime
      actualStartTime
      actualEndTime
      doctorStartTime
      doctorEndTime
      confirmedAt
      doctorId
      userId
      user {
        id
        firstName
        lastName
        preferredName
        pronouns
      }
      doctor {
        id
        firstName
        lastName
      }
      staff {
        id
        name
        roles {
          role
        }
      }
      appointmentSubtype {
        id
        name
      }
      bookableResource {
        id
        name
      }
      appointmentType {
        id
        name
        largestPatientBuffer
        largestRemainingDuration
      }
      clinic {
        id
        name
        nickname
      }
    }
  }
`;

export const templateGql = gql`
  query FindTemplates($clinic: ID) {
    templates: templates(clinic: $clinic) {
      id
      group
      doctorOffset
      appointmentType {
        id
        name
        doctorDuration
        largestPatientBuffer
        largestRemainingDuration
      }
      bookableResource {
        id
      }
      clinic {
        id
      }
    }
  }
`;

export const upsertTemplateGql = gql`
  mutation UpsertTemplate(
    $new: [NewAppointmentTemplate!]!
    $update: [UpdateAppointmentTemplate!]!
    $delete: [ID!]!
  ) {
    upsertAppointmentTemplates(
      newTemplates: $new
      updateTemplates: $update
      deleteTemplates: $delete
    ) {
      id
    }
  }
`;

export const createTemplateGql = gql`
  mutation CreateTemplate($templates: [NewAppointmentTemplate]!) {
    createAppointmentTemplates(templates: $templates) {
      id
    }
  }
`;

export const openAvailabilityGql = gql`
  mutation OpenAvailability($appointments: [NewAppointment!]!) {
    createSchedule(appointments: $appointments) {
      id
      startTime
      endTime
      doctorStartTime
      doctorEndTime
      doctorId
      appointmentSubtype {
        id
        name
      }
      bookableResource {
        id
      }
      appointmentType {
        id
        name
      }
      clinic {
        id
      }
    }
  }
`;

export const updateAppointment = gql`
  mutation UpdateAppointment($appt: UpdateAppointment!) {
    update: updateAppointment(appointment: $appt) {
      id
      actualStartTime
      actualEndTime
    }
  }
`;

export const updateAppointmentConfirmation = gql`
  mutation UpdateAppointment($appt: UpdateAppointment!) {
    update: updateAppointment(appointment: $appt) {
      id
      confirmedAt
    }
  }
`;

export const checkInAppointment = gql`
  mutation CheckInAppointment($appointmentID: ID!) {
    checkInAppointment(appointmentID: $appointmentID) {
      id
      actualStartTime
    }
  }
`;
