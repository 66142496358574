import {
  useMutation,
  useQuery,
  UseQueryResponse,
} from "src/utils/http/gqlQuery";
import { Submission, Answer } from "./types";

export const useForms = (): UseQueryResponse<{ forms: any[] }> => {
  return useQuery<{ forms: any[] }>({
    query: formsQuery,
    // never re-fetch forms after the first load.
    requestPolicy: "cache-first",
  });
};

type useSubmissionsArgs = {
  userID: string;
  formID?: string;
};

// useSubmissions returns form submissions with answers
export const useSubmissions = (
  variables: useSubmissionsArgs,
  pause: boolean
): UseQueryResponse<{ submissions: Submission[] }> => {
  return useQuery({
    query: submissionQuery,
    requestPolicy: "cache-first",
    variables,
    pause,
  });
};

export const useCreateSubmission = () => {
  const [, execute] = useMutation(createSubmissionGQL);
  return execute;
};

export const useCloneSubmission = () => {
  const [, execute] = useMutation(cloneSubmissionGQL);
  return execute;
};

export type UpdateInput = {
  input: {
    id: string;
    toothData?: string;
    appointmentID?: string;
    addFiles?: string[];
    removeFiles?: string[];
  };
};

export const useUpdateSubmission = () => {
  const [, execute] = useMutation<
    { updateSubmission: Submission },
    UpdateInput
  >(updateSubmissionGQL);
  return execute;
};

export const useCreateAnswer = () => {
  const [, execute] = useMutation<
    { addAnswer: Answer },
    {
      answer: {
        submissionID: string;
        questionID: string;
        answer: string;
      };
    }
  >(createAnswer);
  return execute;
};

export const useDeleteSubmission = () => {
  const [, execute] = useMutation(deleteSubmissionGQL);
  return execute;
};

const formsQuery = `
query Forms {
  forms {
    id
    name
    description
    slug
    questions {
      id
      slug
      questionText
      questionType
      order
      isDeprecated
      isRequired
      responseType
      choices
      displayPredicates
      section
      titlegroup
    }
  }
}
`;

const answerFields = `
  id
  questionID
  questionSlug
  authorID
  answer
`;

export const submissionFields = `
  id
  formID
  userID
  parentID
  creatorID
  submitterID
  submittedAt
  approverID
  approvedAt
  appointmentID
  createdAt
  toothData
  answers {
    ${answerFields}
  }
  files {
    id
    type
    subtype
    createdAt
  }
  submitter {
    name
  }
  approver {
    name
  }
`;

export const submissionQuery = `
query Submissions($userID: ID!, $formID: ID) {
  submissions(userID: $userID, formID: $formID) {
    ${submissionFields}
    form {
      slug
      name
    }
    appointment {
      startTime
      appointmentSubtype {
        id
        name
      }
      doctor {
        id
        name
      }
      staff {
        id
        name
      }
    }
  }
}
`;

export const createSubmissionGQL = `
mutation NewSubmission($input: NewSubmission!) {
  createSubmission(input: $input) {
    ${submissionFields}
  }
}
`;

export const cloneSubmissionGQL = `
mutation CloneSubmission($id: ID!) {
  cloneSubmission(submissionID: $id) {
    ${submissionFields}
  }
}
`;

export const editSubmissionGQL = `
mutation EditSubmission($id: ID!) {
  editSubmission(submissionID: $id) {
    ${submissionFields}
  }
}
`;

export const updateSubmissionGQL = `
mutation UpdateSubmission($input: UpdateSubmission!) {
  updateSubmission(input: $input) {
    ${submissionFields}
  }
}
`;

const createAnswer = `
mutation NewAnswer($answer: NewAnswer!) {
  addAnswer(answer: $answer) {
    ${answerFields}
  }
}
`;

export const deleteSubmissionGQL = `
mutation DeleteSubmission($submissionID: ID!) {
  deleteSubmission(submissionID: $submissionID) {
    ids
  }
}
`;
