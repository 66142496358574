import React from "react";
import { Wrapper, Title, Content } from "./styles";

type Props = {
  title?: string;
  actions?: React.ReactNode;
  className?: string;
  style?: any;
  contentClassName?: string;
};

const Box: React.FC<Props> = props => (
  <Wrapper className={props.className} style={props.style}>
    <Title>
      <div>{props.title}</div>
      <div>{props.actions || null}</div>
    </Title>
    <Content className={props.contentClassName}>{props.children}</Content>
  </Wrapper>
);

export default Box;
