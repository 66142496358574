import React, { Component } from "react";
import PropTypes from "prop-types";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { endTreatmentOnStage } from "src/redux/modules/treatment-cycle";
import { loadTreatmentCycles } from "src/redux/modules/wear-schedule";

const mapActions = dispatch => ({
  actions: bindActionCreators(
    {
      endTreatmentOnStage,
      loadTreatmentCycles,
    },
    dispatch
  ),
});

const enhance = connect(
  null,
  mapActions
);

class EndOnStage extends Component {
  static contextTypes = {
    patient: PropTypes.object,
  };
  constructor() {
    super();
    this.state = {
      lastStage: "",
    };
  }
  onUpdate = (ev, value) => {
    this.setState({ lastStage: value });
  };

  setLastStage = () => {
    this.props.actions
      .endTreatmentOnStage({
        treatment_cycle_id: this.props.treatmentCycleId,
        end_stage_num: this.state.lastStage,
      })
      .then(() => {
        this.props.actions.loadTreatmentCycles(this.context.patient.id);
        this.props.onClose();
      });
  };
  render() {
    const actions = [
      <FlatButton label="Cancel" onClick={this.props.onClose} primary />,
      <FlatButton label="End on Stage" onClick={this.setLastStage} primary />,
    ];

    return (
      <Dialog
        title="Mark this stage as the last stage. All subsequent ones will be skipped"
        actions={actions}
        modal={false}
        open={this.props.isOpen}
        onRequestClose={this.props.onClose}
      >
        This is the last Stage. Subsequent ones will be marked skipped.
        <div>
          <TextField
            name="lastStage"
            value={this.state.lastStage}
            onChange={this.onUpdate}
          />
        </div>
      </Dialog>
    );
  }
}

export default enhance(EndOnStage);
