import { DateTime } from "luxon";

export const toFullDateYear = isoDate =>
  DateTime.fromISO(isoDate).toFormat("DDD");
export const getCurrentAge = birthdateInISO =>
  Math.floor(
    DateTime.fromISO(birthdateInISO)
      .diffNow("years")
      .negate().years
  );
