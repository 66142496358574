import { message } from "antd";

interface WithError {
  error?: string;
}

type DeleteSubmissionFn = (id: string) => Promise<WithError>;
type EditSubmissionFn = (id: string) => Promise<WithError>;
type ApproveSubmissionFn = (id: string) => Promise<WithError>;

export async function performDeleteSubmission(
  submissionId: string,
  deleteFn: DeleteSubmissionFn
): Promise<boolean> {
  const hide = message.loading("Deleting", 0);
  const { error } = await deleteFn(submissionId);
  hide();
  if (error) {
    message.error("Unable to delete");
    return false;
  }
  return true;
}

export async function performEditSubmission(
  submissionId: string,
  editFn: EditSubmissionFn
): Promise<boolean> {
  const hide = message.loading("Editing", 0);
  const { error } = await editFn(submissionId);
  hide();
  if (error) {
    message.error("Unable to edit");
    return false;
  }
  return true;
}

export async function performApproveSubmission(
  submissionId: string,
  approveFn: ApproveSubmissionFn
): Promise<boolean> {
  const hide = message.loading("Approving", 0);
  const { error } = await approveFn(submissionId);
  hide();
  if (error) {
    message.error("Unable to approve");
    return false;
  }
  return true;
}
