import React, { useState } from "react";
import styled, { css, cx } from "react-emotion";
import { Icon, Dropdown, Menu, notification } from "antd";
import { PaymentMethod } from "src/types/gql";
import ConfirmModal from "src/shared/ConfirmModal";
import color from "src/styles/color";
import { getCardIcon } from "src/utils/paymentMethod";
import { useDeletePaymentMethod } from "./query";

type Props = {
  patientID: string;
  paymentMethods: PaymentMethod[];
  // Selectable
  onSelect?: (pm: PaymentMethod) => void;
  selected?: PaymentMethod | null;
  closeDeleteModal?: () => void;
};

export const PaymentMethodList: React.FC<Props> = props => {
  const onSelect = props.onSelect;
  return (
    <div>
      {props.paymentMethods.map(pm => (
        <Item
          onClick={onSelect ? () => onSelect(pm) : undefined}
          patientID={props.patientID}
          pm={pm}
          key={pm.id}
          selected={(props.selected && props.selected.id === pm.id) || false}
          closeDeleteModal={props.closeDeleteModal}
        />
      ))}
    </div>
  );
};

type ItemProps = {
  patientID: string;
  pm: PaymentMethod;
  selected: boolean;
  onClick: (() => void) | undefined;
  closeDeleteModal?: () => void;
};

export const Item: React.FC<ItemProps> = props => {
  const { pm, onClick, patientID } = props;
  const [showDelete, setShowDelete] = useState(false);
  const executeDelete = useDeletePaymentMethod(patientID);

  const onMenuClick = async ({ key }: { key: string }) => {
    if (key === "delete") {
      setShowDelete(true);
    }
  };

  const menu = (
    <Menu onClick={onMenuClick}>
      <Menu.Item key="delete">Delete</Menu.Item>
    </Menu>
  );

  const deleteItem = async () => {
    const result = await executeDelete(pm.id);
    if (result.error) {
      notification.error({
        message: "There was an error deleting this card",
      });
      return;
    }
    notification.success({ message: "Card deleted" });
    if (props.closeDeleteModal) {
      props.closeDeleteModal();
    }
  };

  if (pm.type === "card" && pm.cardDetails) {
    return (
      <Wrapper
        className={cx([
          props.selected && selected,
          props.onClick && selectable,
        ])}
        onClick={onClick ? () => onClick() : undefined}
      >
        {showDelete && (
          <ConfirmModal
            type="delete"
            title="Are you sure?"
            text="Do you want to delete this payment method?"
            actionText="Delete"
            onClose={() => setShowDelete(false)}
            onConfirm={deleteItem}
          />
        )}

        {getCardIcon(pm.cardDetails.brand)}
        <div>
          <CardNo>• • • • • • • • • • • • {pm.cardDetails.lastFour}</CardNo>
          <Small>
            {pm.cardDetails.brand} expiring {pm.cardDetails.expiryMonth}/
            {pm.cardDetails.expiryYear}
          </Small>
        </div>

        <div>
          <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
            <Icon type="more" />
          </Dropdown>
        </div>
      </Wrapper>
    );
  }

  // TODO:  Show affirm ???
  return null;
};

const selectable = css`
  cursor: pointer;
  &:hover {
    background: ${color.background};
  }
`;

const selected = css`
  border: 1px solid ${color.primary};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
`;

const Wrapper = styled.div`
  border: 1px solid ${color.border};
  border-radius: 3px;
  margin: 16px 30px;
  padding: 18px;
  & + & {
    margin-top: 12px;
  }

  display: grid;
  grid-template-columns: 80px auto 30px;
  grid-gap: 20px;
  align-items: center;
  height: 100px;
`;

const CardNo = styled.p``;

const Small = styled.p`
  font-size: 14px;
  color: ${color.gray3};
  margin: 0;
`;

export default PaymentMethodList;
