import React, { PureComponent } from "react";
import { compose, lifecycle } from "recompose";
import { BuildsEnhancer } from "src/state/Builds";
import BuildTable from "./BuildTable";

const modifiers = compose(
  BuildsEnhancer,
  lifecycle({
    componentDidMount() {
      this.props.actions.getPendingBuilds();
    },
  })
);

/**
 * UnsentBuilds renders a list of all builds across the system.
 */
class UnsentBuilds extends PureComponent<*, *> {
  render() {
    return (
      <div>
        <BuildTable
          producers={this.props.producers}
          builds={this.props.pendingBuilds}
        />
      </div>
    );
  }
}

export default modifiers(UnsentBuilds);
