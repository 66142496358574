import React from "react";
import { TabWrapper, TabText } from "./styles";
import Counter from "./Counter";
import { TabProps } from "./types";

const Tab: React.FC<TabProps> = props => {
  const { title, route, counter, icon } = props;

  return (
    <TabWrapper to={route} activeClassName="active">
      <TabText>
        {icon} {title}
      </TabText>
      <Counter value={counter} />
    </TabWrapper>
  );
};

export default Tab;
