import React from "react";
import Button from "src/shared/Button";
import { Task } from "../../types";
import { Item, Wrapper } from "./styled";

type Props = {
  task: Task;
  onChangeContent: (to: string | null) => void;
};

// References are either references linked to from the task, or "fake" references used as
// buttons to link to task-specific content.
const References = props => {
  const { task } = props;

  const kit = task.references.find(t => t.model.__typename === "Kit");

  if (!kit) {
    return <p>No kit created.</p>;
  }

  return (
    <Button kind="invisible" onClick={() => props.onChangeContent("ship_kit")}>
      <Wrapper>
        <Item>Ship kits</Item>
      </Wrapper>
    </Button>
  );
};

export default References;
