import styled from "react-emotion";
import textStyles from "src/styles/textStyles";
import color from "src/styles/color";
import { Link } from "react-router";

export const Row = styled.div`
  display: flex;
`;

export const Label = styled.div`
  margin: 16px 0;
  ${textStyles("large")};
  font-weight: bold;
`;

export const Item = styled.div`
  flex: 1;
  margin: 0 0.25rem;
`;

export const FormWrapper = styled.div`
  padding: 0 0 76px 0;
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 0px;
  height: 64px;
  width: 606px;
  background: #fff;
  border-top: 1px solid rgb(236, 236, 233);
`;

export const Header = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid rgb(236, 236, 233);
  ${textStyles("xlarge")};
`;

export const StyledLink = styled(Link)`
  position: relative;
  color: ${color.link};
`;
