import { get } from "lodash";

import { useQuery } from "src/utils/http/gqlQuery";

const filesGql = `
    query ListFiles($opt: ListFilesOpts!) {
        listFiles(opt: $opt) {
            id 
            filename 
            type
            user {
                id
                name
                email
                avatarURL
                dateOfBirth
            }
        }
    }
`;

export const useFile = (id: string) => {
  const [{ fetching, error, data }] = useQuery({
    query: filesGql,
    variables: { opt: { ids: [id] } },
  });
  return [fetching, error, get(data, "listFiles[0]")];
};
