import React from "react";
import { TimelineParams } from "src/components/patient-timeline/PatientTimeline";
// state
import Loading from "src/shared/Loading";
import { useClinicalRecord } from "src/state/hooks/clinicalRecords";
import { usePatientStatus } from "src/state/usePatientStatus";
import { useShippingAddress } from "src/state/useAddresses";

// local
import Overview from "../Patient/Overview/Overview";
import Tabs from "src/scenes/PatientProfile/Tabs";
import { useTreatment } from "src/scenes/PatientProfile/queries";
import { ConsentsContextProvider } from "src/scenes/PatientProfile/Consents/useConsentsContext";
import {
  SelectedTab,
  TreatmentOverviewContainer,
  TreatmentTile,
  TreatmentDashboard,
} from "./styles";
import PatientProfileHeader from "./PatientProfileHeader";

interface Params extends TimelineParams {
  userId: string;
}
// TODO: New components that will be created and used should made and reference in
// this src/scenes/PatientTreatmentOverviewV2 directory. Remove this TODO once the
// Treatment Goals and Patient Journey is complete.
const PatientTreatmentOverviewV2 = (props: any) => {
  const params: Params = props.params;
  const { userId } = params;

  // Application-level data
  const [, , availablePatientStatuses] = usePatientStatus();

  // The core data for the patient
  const [{ fetching, data }, refetch] = useTreatment(userId);
  const [, , address] = useShippingAddress(userId);
  const [clinicalRecord] = useClinicalRecord(userId);
  // TODO: update Tabs to use new link
  const renderActiveTab = () =>
    data &&
    React.Children.map(props.children, (c: React.ReactNode) =>
      React.cloneElement(c as React.ReactElement<any>, {
        patient: data && data.user,
        appointments: data && data.user.appointments,
        clinicalRecord,
      })
    );

  if (fetching || !data) {
    return <Loading />;
  }

  return (
    <>
      <ConsentsContextProvider patientId={userId}>
        <PatientProfileHeader
          patient={data.user}
          patientStatuses={availablePatientStatuses}
          patientAddress={address}
          clinicalRecord={clinicalRecord}
          treatment={data && data.activeOrLastTreatment}
          refetch={refetch}
        />
        <TreatmentOverviewContainer>
          <ConsentsContextProvider patientId={userId}>
            <div>
              <TreatmentTile>Treatment Overview</TreatmentTile>
              <TreatmentDashboard />
              <Tabs
                patient={data.user}
                pathname={props.location.pathname}
                patientAvatarUrl={data.user.avatarURL}
              />
            </div>

            <SelectedTab>
              {renderActiveTab()}
              {!props.children && (
                <Overview
                  patient={data.user}
                  clinicalRecord={clinicalRecord || undefined}
                  treatment={data && data.activeOrLastTreatment}
                  appointments={data.user.appointments}
                  timelineParams={props.params}
                />
              )}
            </SelectedTab>
          </ConsentsContextProvider>
        </TreatmentOverviewContainer>
      </ConsentsContextProvider>
    </>
  );
};

export default PatientTreatmentOverviewV2;
