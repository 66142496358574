import React, { useState } from "react";
import color from "src/styles/color";
import Button, { ButtonRow } from "src/shared/Button";

import InputText from "src/shared/InputText";
import {
  PatientProviderStatusContent,
  DetailsRow,
  ProviderInformationContent,
  inputName,
  Row,
  Title,
} from "./styles";
import StateSelect from "src/shared/StateSelect";
import ExternalProviderTypeSelect from "src/shared/ExternalProvider/VerifiedSpecialistSummary/ExternalProviderTypeSelect";
import Switch from "src/shared/Switch";
import ExternalProviderStateStatus from "src/scenes/Patient/ExternalCare/ExternalProviderStateStatus/ExternalProviderStateStatus";
import { VerifiedSpecialist } from "src/types/gql";

export type Props = {
  currentState: string | undefined;
  specialist: VerifiedSpecialist;
  onClose: VoidFunction;
  onSave: (state: string, specialist: VerifiedSpecialist) => void;
};

const EditProviderContent: React.FC<Props> = ({
  currentState,
  specialist,
  onClose,
  onSave,
}) => {
  const [patientProviderStateStatus, setPatientProviderStateStatus] = useState(
    currentState || ""
  );
  const [updatedSpecialist, setUpdatedSpecialist] = useState(specialist);

  const updateSpecialist = (fieldName, maybeOptions?) => e => {
    const options = maybeOptions || {};

    if (options.isAddress) {
      setUpdatedSpecialist({
        ...updatedSpecialist,
        address: {
          ...updatedSpecialist.address,
          [fieldName]: options.isSelect ? e : e.target.value,
        },
      });
      return;
    }

    setUpdatedSpecialist({
      ...updatedSpecialist,
      [fieldName]: options.isSelect ? e : e.target.value,
    });
  };

  const handleSubmit = () => {
    onSave(patientProviderStateStatus, updatedSpecialist);
  };

  return (
    <div>
      <PatientProviderStatusContent>
        <Title>Update patient-provider status</Title>
        <Row>
          <div>Type</div>
          <ExternalProviderStateStatus
            state={patientProviderStateStatus}
            updateState={setPatientProviderStateStatus}
          />
        </Row>
      </PatientProviderStatusContent>
      <ProviderInformationContent>
        <Title>Update provider information</Title>
        <DetailsRow>
          <div>Name</div>
          <InputText
            className={inputName}
            value={updatedSpecialist.firstName}
            placeholder="First name"
            onChange={updateSpecialist("firstName")}
          />
          <InputText
            className={inputName}
            value={updatedSpecialist.lastName}
            placeholder="Last name"
            onChange={updateSpecialist("lastName")}
          />
        </DetailsRow>
        <hr />
        <DetailsRow>
          <div>Provider type</div>
          <div>
            <ExternalProviderTypeSelect
              style={{ borderColor: color.border }}
              onChange={updateSpecialist("type", { isSelect: true })}
              value={updatedSpecialist.type}
            />
          </div>
        </DetailsRow>
        <hr />
        <DetailsRow>
          <div>Email</div>
          <div>
            <InputText
              value={updatedSpecialist.email}
              placeholder="Email"
              onChange={updateSpecialist("email")}
            />
          </div>
        </DetailsRow>
        <hr />
        <DetailsRow>
          <div>Phone</div>
          <div>
            <InputText
              value={updatedSpecialist.phone}
              placeholder="Phone number"
              onChange={updateSpecialist("phone")}
            />
          </div>
        </DetailsRow>
        <hr />
        <DetailsRow>
          <div>Phone 2</div>
          <div>
            <InputText
              value={updatedSpecialist.phone2}
              placeholder="Phone number 2"
              onChange={updateSpecialist("phone2")}
            />
          </div>
        </DetailsRow>
        <hr />
        <DetailsRow>
          <div>Clinic</div>
          <div>
            <InputText
              value={updatedSpecialist.clinicName}
              placeholder="Clinic name"
              onChange={updateSpecialist("clinicName")}
            />
          </div>
        </DetailsRow>
        <hr />
        <DetailsRow>
          <div>Preferred Provider</div>
          <div>
            <Switch
              checked={updatedSpecialist.preferredProvider}
              onChange={updateSpecialist("preferredProvider", {
                isSelect: true,
              })}
            />
          </div>
        </DetailsRow>
        <hr />
        <DetailsRow style={{ lineHeight: "25px" }}>
          <div>Address</div>
          <div>
            <InputText
              value={updatedSpecialist.address.line1}
              placeholder="Line 1"
              onChange={updateSpecialist("line1", { isAddress: true })}
            />
            <br />
            <InputText
              value={updatedSpecialist.address.line2 || ""}
              placeholder="Line 2"
              onChange={updateSpecialist("line2", { isAddress: true })}
            />
            <br />
            <div style={{ display: "flex" }}>
              <InputText
                value={updatedSpecialist.address.city}
                placeholder="City"
                onChange={updateSpecialist("city", { isAddress: true })}
              />
              <StateSelect
                style={{ width: 200, marginLeft: 10, cursor: "pointer" }}
                value={updatedSpecialist.address.state}
                onChange={updateSpecialist("state", {
                  isAddress: true,
                  isSelect: true,
                })}
              />
            </div>
            <br />
            <InputText
              value={updatedSpecialist.address.postalCode}
              placeholder="Zip code"
              onChange={updateSpecialist("postalCode", { isAddress: true })}
            />
          </div>
        </DetailsRow>
        <ButtonRow position="right" style={{ marginTop: "15px" }}>
          <Button kind="default" onClick={onClose}>
            Cancel
          </Button>
          <Button kind="primary" onClick={handleSubmit}>
            Save
          </Button>
        </ButtonRow>
      </ProviderInformationContent>
    </div>
  );
};

export default EditProviderContent;
