const LOAD_STRUCTURED_MESSAGES = "structured-messages/LOAD";
const LOAD_STRUCTURED_MESSAGES_SUCCESS = "structured-messages/LOAD_SUCCESS";
const LOAD_STRUCTURED_MESSAGES_FAIL = "structured-messages/LOAD_FAIL";
const PUT_STRUCTURED_MESSAGES = "structured-messages/PUT";
const PUT_STRUCTURED_MESSAGES_SUCCESS = "structured-messages/PUT_SUCCESS";
const PUT_STRUCTURED_MESSAGES_FAIL = "structured-messages/PUT_FAIL";

const API_STRUCTURED_MESSAGE = "/api/v1/structured_messages";

const initialState = {
  loading: false,
  error: null,
  data: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_STRUCTURED_MESSAGES:
      return {
        ...state,
        loading: true,
      };
    case LOAD_STRUCTURED_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.result,
      };
    case LOAD_STRUCTURED_MESSAGES_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export const loadStructuredMessages = () => {
  return {
    types: [
      LOAD_STRUCTURED_MESSAGES,
      LOAD_STRUCTURED_MESSAGES_SUCCESS,
      LOAD_STRUCTURED_MESSAGES_FAIL,
    ],
    promise: client => client.get(API_STRUCTURED_MESSAGE),
  };
};

export const putStructuredMessagesContent = ({ id, contents }) => {
  return {
    types: [
      PUT_STRUCTURED_MESSAGES,
      PUT_STRUCTURED_MESSAGES_SUCCESS,
      PUT_STRUCTURED_MESSAGES_FAIL,
    ],
    promise: client =>
      client.put(`${API_STRUCTURED_MESSAGE}/${id}`, {
        data: { contents },
      }),
  };
};

export const createStructuredMessage = ({ name }) => {
  return {
    types: ["", "", ""],
    promise: client => client.post(API_STRUCTURED_MESSAGE, { data: { name } }),
  };
};

export const getStructuredMessages = state => {
  return state.structuredMessages.data || [];
};

export const getIsStructuredMessagesLoading = state => {
  return state.structuredMessages.loading;
};
