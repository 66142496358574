import decode from "jwt-decode";

export const logout = () => {
  document.cookie =
    "auth_token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;"; // expire at the beginning of time

  clearAuthToken();

  // TODO: this should use normal routing to re-route to login after logout
  setTimeout(() => {
    document.cookie =
      "auth_token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;"; // expire at the beginning of time
    window.location.href = "/login"; // we are doing this out side of react router to force a page refresh.
  }, 300); // we have to wait for a clock tick otherwise the cookie does not get set
};

export const getDecodedJWT = () => {
  const authToken = getAuthToken();
  if (!authToken) {
    return null;
  }

  let jwt;
  try {
    jwt = decode(authToken);
  } catch (e) {
    console.error("couldn't decode JWT", e);
    return null;
  }
  return jwt;
};

export const isAuthExpired = jwt => {
  return new Date(jwt.exp * 1000) <= new Date();
};

const getAuthToken = () => {
  const authToken = window.localStorage?.getItem("auth_token");
  // TODO: remove this check when we can be sure we are no
  // longer setting the token to the string "undefined"
  if (authToken === "undefined") {
    return null;
  }
  return authToken;
};

const clearAuthToken = () => {
  return window.localStorage?.removeItem("auth_token");
};
