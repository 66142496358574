const LOAD_ALL_QUESTIONNAIRES_PRE = "questionnaires/load-all";
const LOAD_ALL_QUESTIONNAIRES_SUCCESS = "questionnaires/load-all-success";
const LOAD_ALL_QUESTIONNAIRES_FAIL = "questionnaires/load-all-fail";

const initialState = {
  // data stores questionnaires keyed by each item's ID
  data: {},

  // the following are global "/api/v1/questionnaires" endpoint indicators
  isLoading: false,
  isError: false,
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL_QUESTIONNAIRES_PRE: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null,
      };
    }
    case LOAD_ALL_QUESTIONNAIRES_SUCCESS: {
      const data = {};
      action.result.forEach(q => {
        data[q.id] = q;
      });
      return {
        ...state,
        data,
        isLoading: false,
        isError: false,
        error: null,
      };
    }
    case LOAD_ALL_QUESTIONNAIRES_FAIL: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    }
    default:
      return state;
  }
}

// Actions

export function fetchAllQuestionnaires() {
  return {
    types: [
      LOAD_ALL_QUESTIONNAIRES_PRE,
      LOAD_ALL_QUESTIONNAIRES_SUCCESS,
      LOAD_ALL_QUESTIONNAIRES_FAIL,
    ],
    promise: client => client.get("/api/v1/questionnaires?all=true"),
  };
}

// Selectors
export const getQuestionnairesCountSelector = state =>
  Object.keys(state.questionnaire.data).length;
export const getQuestionnairesSelector = state => state.questionnaire.data;
export const getIsQuestionnairesLoadingSelector = state =>
  state.questionnaire.isLoading;
