import { User } from "src/types/gql";

import {
  pipelineCategories,
  taskTypes,
  pipelineTypes,
  manufacturingPipelinesTypes,
  clinicalPipelinesTypes,
  treatmentLevelTypes,
  patientStatusNames,
  taskLabels,
} from "./constants";

export type TaskTypes = UnionFromArray<typeof taskTypes>;

export type TaskReference = {
  id: string;
  model: TaskReferenceModel;
};

export type TaskReferenceModel =
  | ReferenceChat
  | ReferenceInternalNote
  | ReferenceSubmission
  | ReferenceManufacturingBuild;

export type ReferenceChat = {
  __typename: "Chat";
  id: string;
  body: string;
  createdAt: string;
};

export type ReferenceInternalNote = {
  __typename: "InternalNote";
  id: string;
  text: string;
  createdAt: string;
};

export type ReferenceManufacturingBuild = {
  __typename: "ManufacturingBuild";
  id: string;
  createdAt: string;
  producer: {
    source: string;
  };
};

export type ReferenceSubmission = {
  __typename: "Submission";
  id: string;
  createdAt: string;
  submitterID: string;
  submittedAt: string;
  approverID: string;
  approvedAt: string;
  form: {
    id: string;
    name: string;
    slug: string;
  };
};

export enum TaskSortField {
  DueAt = "due_at",
  CompletedAt = "completed_at",
  Priority = "priority",
  PipelineDueAt = "pipeline_due_at",
  PipelineRushedAt = "pipeline_rushed_at",
  PipelineCreatedAt = "pipeline_created_at",
}

export type TaskSort = {
  sortField: TaskSortField;
  sortDescending: boolean;
};

export type TaskAssigneesFilter = {
  assigneeIDs?: string[];
  unassigned: boolean;
};

export type TaskFilter = {
  search?: string;
  userID?: string;
  categories?: TaskCategory[];
  taskTypes?: string[];
  assignees?: TaskAssigneesFilter;
  authorID?: string;
  snoozed?: boolean;
  status?: TaskStatus;
  sortOpts?: TaskSort[];
  pipelineTypes?: PipelineTypes[];
  homeClinicIDs?: string[];
  treatmentLevels?: string[];
  patientStatusNames?: string[];
};

export type PatientStatusNames = UnionFromArray<typeof patientStatusNames>;

export type PipelineCategories = UnionFromArray<typeof pipelineCategories>;

export type PipelineTypes = UnionFromArray<typeof pipelineTypes>;

export type ManufacturingPipelineTypeKeys = UnionFromArray<
  typeof manufacturingPipelinesTypes
>;

export type ClinicalPipelineTypeKeys = UnionFromArray<
  typeof clinicalPipelinesTypes
>;

export type TreatmentLevel = UnionFromArray<typeof treatmentLevelTypes>;

export type PipelineStatus =
  | "complete"
  | "incomplete"
  | "blocked"
  | "unblocked"
  | "blocking";

export type TaskPriority = 1 | 2 | 3;

export enum PipelineSortFields {
  DueAt = "due_at",
}

export type ClinicTypes = "partner" | "flagship";

export type PipelineFilter = {
  userID?: string;
  authorID?: string;
  assigneeIDs?: string[];
  types?: string[];
  status?: PipelineStatus;
  includeSingleTasks?: boolean;
  sortOpt?: {
    sortDescending: boolean;
    sortField: PipelineSortFields;
  };
  homeClinicIDs?: string[];
  patientIDs?: string[];
};

export type TaskStatus =
  | "unstarted"
  | "in_progress"
  | "complete"
  | "incomplete"
  | "snoozed"
  | "not_snoozed"
  | "unblocked";

export type TaskLabels = UnionFromArray<typeof taskLabels>;

export type TaskCategory =
  | "form_review"
  | "external_care"
  | "comfort_check"
  | "internal"
  | "export"
  | "segmentation"
  | "treatment_plan"
  | "manufacturing";

export type PipelineTags = "rush" | "virtual_refinement";

export type Task = {
  id: string;
  name: string;
  description: string;
  assignedTo: User[];
  taskType: TaskType;
  dueAt: string | null;
  pipelineDueAt: string | null;
  priority: TaskPriority;
  startedAt: string | null;
  completedAt: string | null;
  completedBy: User | null;
  createdAt: string;
  customLabels: TaskLabels[];

  pipeline?: {
    id: string;
    onHoldReason?: string | null;
    onHoldCategory?: string | null;
    type?: string | null;
    createdAt: string;

    treatment?: {
      id: string;
      treatmentType: string | null;
    };
  };
  user: User;
  references: TaskReference[];
};

export type Pipeline = {
  id: string;
  userID: string;
  type: string;
  createdAt: string;
  dueAt: string | null;
  customTags: PipelineTags[];

  onHoldReasonV2?: string | null;
  onHoldReason?: string | null;
  onHoldCategory?: string | null;

  unblockedTasks?: Task[];
  tasks?: Task[];
  taskCounts: {
    remaining: number;
    started: number;
    completed: number;
  };

  user: {
    id: string;
    name: string;
    homeClinicID: string;
    email: string;
    mobilePhoneNumber: string;
    homeClinic: {
      id: string;
      name: string;
    };
  };
};

export type PipelineTemplate = {
  id: string;
  type: string;
  hidden: boolean;
  taskTemplates: Array<{
    id: string;
    taskTypeID: string;
    pipelineTemplateID: string;
    taskType: TaskType;
  }>;
  taskEdgeTemplates: Array<{
    id: string;
    pipelineTemplateID: string;
    parentTaskTemplateID: string;
    childTaskTemplateID: string;
  }>;
};

export type TaskType = {
  id: string;
  name: string;
  category: TaskCategory;
  description: string;
};

export type CreateTasksInput = {
  userID: string;
  pipelineTemplateID: string;
  opts: Array<TaskTemplateOption>;
};

export type SnoozeTaskInput = {
  taskID: string;
  snoozeUntil: string;
};

export type UpdateTask = {
  id: string; // TaskID
  opt: TaskOption;
};

export type AddPipelineCustomTag = {
  pipelineID: string;
  newCustomTag: string;
};

// TaskTemplateOption allows for configurations either by name or by id
// If both are used, id will override name
export type TaskTemplateOption = {
  id: string; // TaskTemplateID
  name: string; // TaskName
  opt: TaskOption;
};

export type TaskOption = {
  name?: string;
  description?: string;
  priority?: TaskPriority;
  assignedToIDs?: string[];
  unassignIDs?: string[];
  startedAt?: string;
  completedAt?: string;
  pipelineID?: string;
  parentIDs?: string[];
  childIDs?: string[];
  customLabels?: {
    newValue: TaskLabels[];
    clearCurrentValue: boolean;
  };
};

export type Counts = {
  myTasks: number;
  snoozed: number;
  recentlyCompleted: number;
  createdTasks: number;
};

export type PipelineCounts = {
  my: number;
  authored: number;
  completed: number;
};

export type Clinic = {
  id: string;
  name: string;
  type: ClinicTypes;
  state: string;
};

export type ClinicsResponse = {
  clinics: Clinic[];
};

type CounterResponse = {
  pageResults: {
    totalItems: number;
  };
};

export type SidebarCountersResponse = Record<
  | "tasksUser"
  | "tasksAll"
  | "tasksCreatedByUser"
  | "tasksSnoozed"
  | "tasksCompleted"
  | "pipelinesUser"
  | "pipelinesAll"
  | "pipelinesCompleted",
  CounterResponse
>;

export type FiltersListItem = {
  id: string;
  customName: string;
  patientHomeClinicIDs: string[] | null;
  treatmentTypes: TreatmentLevel[] | null;
  pipelineTypes: PipelineTypes[] | null;
  taskTypeCategories: TaskCategory[] | null;
  taskTypeNames: TaskTypes[] | null;
  taskCustomLabels: string[] | null;
  taskAssignees: {
    assigneeIDs: string[];
    unassigned: boolean;
  } | null;
  shareInvites:
    | {
        id: string;
        sharerID: string;
        shareeID: string;
        sharerTaskFilterSettingID: string;
        createdAt: string;
      }[]
    | null;
};

export type FiltersListResponse = {
  taskFilterSettings: FiltersListItem[];
};

export type CreateFilterRequest = {
  customName: string;
  taskNameSubstringSearch?: string;
  taskTypeCategories?: TaskCategory[];
  taskAssignees?: TaskAssigneesFilter;
  taskAuthorID?: string;
  taskSnoozed?: boolean;
  taskTypeNames?: TaskTypes[];
  taskCustomLabels?: string[];
  pipelineTypes?: PipelineTypes[];
  patientID?: string;
  patientHomeClinicIDs?: string[];
  patientStatusNames?: string[];
  treatmentTypes?: TreatmentLevel[];
  predefinedTaskFilters?: string[];
  sortOpts?: TaskSort[];
};

export type UpdateFilterRequest = {
  taskFilterSettingID: string;
  customName?: string;

  taskNameSubstringSearch?: {
    clearCurrentValue: boolean;
    newValue?: string;
  };
  taskTypeCategories?: {
    clearCurrentValue: boolean;
    newValue?: TaskCategory[];
  };
  taskAssignees?: {
    clearCurrentValue: boolean;
    newValue?: TaskAssigneesFilter;
  };
  taskAuthorID?: {
    clearCurrentValue: boolean;
    newValue?: string;
  };
  taskSnoozed?: {
    clearCurrentValue: boolean;
    newValue?: boolean;
  };
  taskTypeNames?: {
    clearCurrentValue: boolean;
    newValue?: TaskTypes[];
  };
  taskCustomLabels?: {
    clearCurrentValue: boolean;
    newValue?: string[];
  };
  pipelineTypes?: {
    clearCurrentValue: boolean;
    newValue?: PipelineTypes[];
  };
  patientID?: {
    clearCurrentValue: boolean;
    newValue?: string;
  };
  patientHomeClinicIDs?: {
    clearCurrentValue: boolean;
    newValue?: string[];
  };
  patientStatusNames?: {
    clearCurrentValue: boolean;
    newValue?: string[];
  };
  treatmentTypes?: {
    clearCurrentValue: boolean;
    newValue?: TreatmentLevel[];
  };
  predefinedTaskFilters?: {
    clearCurrentValue: boolean;
    newValue?: string[];
  };
  sortOpts?: {
    clearCurrentValue: boolean;
    newSortOpts?: TaskSort[];
  };
};
