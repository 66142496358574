import React, { useState } from "react";
import { DateTime } from "luxon";
import styled, { css } from "react-emotion";
import { notification } from "antd";
import { isEmpty } from "lodash";

import Modal from "src/shared/Modal";
import color from "src/styles/color";
import Box from "src/shared/Box";
import {
  getEmail,
  getReadableType,
  getLastSendEvent,
} from "src/scenes/Patient/ExternalCare/utils";
import Button, { ButtonRow } from "src/shared/Button";
import Check from "src/shared/Icons/Check";
import ConfirmModal from "src/shared/ConfirmModal";
import { useDelete } from "src/scenes/Patient/Files/Files";

const DeleteDocuments = ({ documents, patientID, onClose }) => {
  const [toDelete, setToDelete] = useState<Array<string>>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const deleteFile = useDelete();

  const onDocumentClick = documentID => {
    if (toDelete.includes(documentID)) {
      setToDelete(toDelete.filter(id => id !== documentID));
      return;
    }
    setToDelete([...toDelete, documentID]);
  };

  const onSubmit = () => setShowConfirmModal(true);

  const onConfirm = async () => {
    // TODO: Change once there is a bulk file deletion endpoint
    toDelete.forEach(async docID => {
      const resp = await deleteFile({ id: docID });
      if (resp.error) {
        notification.error({ message: `Error deleting file ID ${docID}` });
        return;
      }
    });

    notification.success({
      message: toDelete.length > 1 ? "Files deleted" : "File deleted",
    });

    setShowConfirmModal(false);
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <Box title="Select document(s) to delete">
        {showConfirmModal && (
          <ConfirmModal
            type="delete"
            title={
              toDelete.length > 1
                ? "Are you sure you want to delete these documents?"
                : "Are you sure you want to delete this document?"
            }
            actionText="Delete"
            onConfirm={onConfirm}
            onClose={() => setShowConfirmModal(false)}
          />
        )}
        <Container>
          <Content>
            {documents.map(doc => {
              const e = getLastSendEvent(doc.emailEvents);
              const isSelected = toDelete.includes(doc.id);
              return (
                <Item
                  style={{
                    backgroundColor: isSelected ? color.background : undefined,
                  }}
                  key={doc.id}
                  onClick={() => onDocumentClick(doc.id)}
                >
                  <FileTitle>
                    {getReadableType(doc.type)} - {doc.filename}
                    {isSelected && (
                      <div style={{ color: color.green, fontWeight: "normal" }}>
                        <Check fill={color.green} /> Selected
                      </div>
                    )}
                  </FileTitle>
                  <FileSubtitle>
                    {e &&
                      `Submitted: ${DateTime.fromISO(e.createdAt).toFormat(
                        "LLLL d, yyyy"
                      )} to ${getEmail(e)}`}
                    {!e && "Not yet submitted"}
                  </FileSubtitle>
                </Item>
              );
            })}
          </Content>
          <ButtonRow className={buttonRow} position="right">
            <Button kind="default" onClick={onClose}>
              Cancel
            </Button>
            <Button
              kind="primary"
              onClick={onSubmit}
              disabled={isEmpty(toDelete)}
            >
              Delete
            </Button>
          </ButtonRow>
        </Container>
      </Box>
    </Modal>
  );
};

const Container = styled.div`
  height: 450px;
  width: 600px;

  display: flex;
  flex-flow: column;
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  overflow: auto;
`;

const Item = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-flow: column;
  border-bottom: 1px solid ${color.border};

  &:hover {
    cursor: pointer;
  }
`;

const buttonRow = css`
  padding: 16px 24px;
  border-top: 1px solid ${color.border};
`;

const FileTitle = styled.div`
  font-weight: 600;
  margin-bottom: 8px;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

const FileSubtitle = styled.div`
  color: ${color.gray3};
`;

export default DeleteDocuments;
