import React from "react";
import { DateTime } from "luxon";
// types
import { EmptySlot } from "src/types/api";
// local
import { EditContextProvider } from "./useEditContext";
import { CalendarViewContextProvider } from "./useCalendarViewContext";
import { Appointment, Clinic } from "src/types/gql";
import DayView from "./DayView";
import ListView from "./ListView";
import WeekView from "./WeekView";
import CalendarLogs from "./CalendarLogs";

export const viewOptions = ["Day", "Week", "List"];

type Props = {
  // Which view to use.
  view?: "Day" | "Week" | "List" | "Logs";

  appointments: Array<Appointment>;

  // This shows the unsaved data on the calendar.
  unsaved?: Array<EmptySlot>;

  // This callback is used to update the unsaved state, when adding new items to the
  // calendar or template.
  //
  // The parent component should be responsible for managing unsaved state.
  //
  // Providing this function makes the calendar "editable".
  onUpdateUnsaved?: (to: Array<EmptySlot>) => void;

  // The clinic that we're displaying
  clinic: Clinic;

  // The date that we're showing appointments from
  date?: DateTime;

  viewDoctorOnly?: boolean;
  selectedDoctorId?: string;
};

const CalendarView = (props: Props) => {
  const { view, ...rest } = props;

  return (
    <CalendarViewContextProvider {...rest}>
      {/* {renderView(props)} */}
      {props.view === "Day" ? (
        <EditContextProvider>
          <DayView calendarView={props.view} />
        </EditContextProvider>
      ) : props.view === "Week" ? (
        <WeekView calendarView={props.view} />
      ) : props.view === "List" ? (
        <ListView />
      ) : (
        <CalendarLogs clinic={props.clinic} />
      )}
    </CalendarViewContextProvider>
  );
};

export default CalendarView;
