import React, { useState, useContext, useRef } from "react";
import styled, { css } from "react-emotion";
import { notification } from "antd";
import Button, { ButtonRow } from "src/shared/Button";
import ConfirmModal from "src/shared/ConfirmModal";
import color from "src/styles/color";
import { UploadContext } from "src/shared/Uploader";
import deleteFile from "src/shared/DeleteFile";
import { host, uuid, titleCase } from "src/shared/util";
import { useUpdateSubmission } from "src/scenes/PatientProfile/Forms/GQLForms/queries";
import Template from "./Template";
import { PhotoType } from "./types";

type Props = {
  editable?: boolean;
  patientID: string;
  submissionID?: string;
  files: { id: string; subtype: string }[];
  className?: any;
  formName?: string;
};

const Upload: React.FC<Props> = props => {
  const { push } = useContext(UploadContext);
  const updateSubmission = useUpdateSubmission();

  const [photos, setPhotos] = useState<{ [type: string]: File | undefined }>(
    {}
  );

  // disabled represents whether uploading is enabled or disabled.
  const disabled = !props.editable || Object.keys(photos).length === 0;

  const onUpload = () => {
    if (disabled) {
      return;
    }

    Object.keys(photos).forEach((photoType: string) => {
      const file = photos[photoType];
      if (!file) {
        return;
      }

      // The enums in go are, for example, "FullFacial".
      const subtype = photoType.replace(/ /g, "");

      push([
        {
          id: uuid(),
          userID: props.patientID,
          file,
          // If form name exists, prepend it to photoType
          description: [props.formName, photoType].filter(Boolean).join(": "),
          filetype: "ClinicalPhoto",
          subtype: subtype,
          internal: true,
          onComplete: async f => {
            if (props.submissionID) {
              const response = await updateSubmission({
                input: {
                  id: props.submissionID,
                  addFiles: [f.id],
                },
              });
              if (response.error) {
                notification.error({
                  message: response.error,
                });
                return;
              }
              notification.success({
                message: "Photo uploaded to form",
              });
            }
          },
        },
      ]);
    });
  };

  const photoFiles = {};
  props.files.forEach(photo => {
    if (!photoFiles[photo.subtype]) {
      photoFiles[photo.subtype] = [];
    }

    photoFiles[photo.subtype] = [...photoFiles[photo.subtype], photo];
  });
  const displayLatestPhotoSubtypes: any[] = [];
  for (const subtype in photoFiles) {
    const latestPhoto = photoFiles[subtype].reduce((a, b) => {
      return new Date(a.createdAt) > new Date(b.createdAt) ? a : b;
    });
    displayLatestPhotoSubtypes.push(latestPhoto);
  }

  return (
    <div className={props.className}>
      <Intro>Upload your clinical photos</Intro>
      <Template
        onRenderItem={typ => {
          const photo = displayLatestPhotoSubtypes.find(
            f => f.subtype === typ.replace(/ /g, "")
          );
          return (
            <Item
              photoType={typ}
              onChange={f => setPhotos({ ...photos, [typ]: f })}
              value={photo && photo.id}
              localValue={photos[typ]}
            />
          );
        }}
      />

      <ButtonRow position="center" style={{ margin: "1rem 0" }}>
        <Button type="primary" disabled={disabled} onClick={onUpload}>
          Upload
        </Button>
      </ButtonRow>
    </div>
  );
};

export default Upload;

type ItemProps = {
  photoType: PhotoType;
  onChange: (f: File | undefined) => void;
  // ID of uploaded photo
  value: string | undefined;
  localValue: File | undefined;
};

const Item: React.FC<ItemProps> = props => {
  const { photoType, onChange, value, localValue } = props;

  // Users are on an ipad, and need to replace or remote photos from being
  // uploaded on misclicks.  There's no hover state, so if you tap a selected
  // photo we'll show a modal asking if you want to replace or delete the modal.
  const [confirmReplace, setConfirmReplace] = useState(false);
  const upload = useRef(null);

  let url = value && `${host()}/api/v2/files/${value}`;
  if (localValue) {
    url = localValue && URL.createObjectURL(localValue);
  }

  const removeFile = async (fileId: string) => {
    try {
      await deleteFile(fileId);

      notification.success({
        message: "Photo deleted from profile",
      });
    } catch (_) {
      notification.error({
        message: "Unable to delete photo from profile. Try again later",
      });
    }
  };

  return (
    <ItemWrapper>
      {confirmReplace && (
        <ConfirmModal
          hidden={!confirmReplace}
          onClose={() => setConfirmReplace(false)}
          type="text-only"
          title="Replace or remove this image"
          text="Do you want to replace or remove this image from the form?"
          actionText="Replace"
          onConfirm={() => {
            if (upload && upload.current) {
              // @ts-ignore
              (upload.current as HTMLInputElement).click();
              setConfirmReplace(false);
            }
          }}
          middleButton={
            <Button
              kind="warning"
              onClick={() => {
                if (value) {
                  removeFile(value);
                }
                setConfirmReplace(false);
              }}
            >
              Remove
            </Button>
          }
        />
      )}

      <label
        css={uploadWrapperCSS}
        onClick={e => {
          if (url && !confirmReplace) {
            e.preventDefault();
            setConfirmReplace(true);
          }
        }}
      >
        {url ? (
          <img alt={titleCase(photoType)} src={url} />
        ) : (
          <span>{titleCase(photoType)}</span>
        )}
        <input
          type="file"
          ref={upload}
          onChange={e => {
            const files = (e.target as HTMLInputElement).files;
            if (files && files[0]) {
              onChange(files[0]);
            }
          }}
        />
      </label>
      <PhotoName>{url && titleCase(photoType)}</PhotoName>
    </ItemWrapper>
  );
};

const textCSS = css`
  color: ${color.gray3};
  font-weight: bold;
  text-align: center;
`;

const PhotoName = styled.p`
  margin: 0;
  height: 1rem;
  ${textCSS};
`;

const uploadWrapperCSS = css`
  flex: 1;
  margin: 0 0 3px !important;
  border: 1px solid ${color.borderLight};
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  > span {
    ${textCSS};
  }

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  input {
    position: absolute;
    left: -999999px;
  }

  > img {
    max-height: calc(150px - 1rem - 6px);
    max-width: 100%;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: stretch;
  align-self: stretch;
  height: 150px;
`;

const Intro = styled.p`
  font-weight: bold;
`;
