import JSZip from "jszip";
import {
  ProjectHelper,
  VersionedProject,
  V10Project,
  Phase,
  PhaseType,
  ToothTransform,
} from "./types";
import v10 from "./v10";

export const parseTXP = async (data: Blob | null): Promise<ProjectHelper> => {
  const result = await JSZip.loadAsync(data);
  const project: VersionedProject = JSON.parse(
    await result.file("project.json").async("text")
  );

  const int = parseInt(project._version);

  switch (true) {
    // eg case int > 15:
    // return v15();
    case int > 0:
      return v10(project as V10Project);
    default:
      throw new Error(
        `unknown _version '${project._version}' within project.json`
      );
  }
};

// emrPhaseType transforms the tx planner PhaseType string to the emr format
export const emrPhaseType = (p: PhaseType): string => {
  return p
    .toLowerCase()
    .replace(/'/g, "")
    .replace(/ /g, "_");
};

export const attachmentPosition = (
  p: Phase,
  { tid, transform }: ToothTransform
) => {
  const matrix = transform.elements;

  const attdir = [matrix[1], matrix[5], matrix[9]];
  const buccal = p.movements[tid].cs.axes[1];

  // Detect the rotation difference and use dot product based off of the buccolingual axis:
  // if an attachment is rotated to face buccally (vs lingually) the result will be negative
  return attdir[0] * buccal[0] + attdir[1] * buccal[1] + attdir[2] * buccal[2] >
    0
    ? "buccal"
    : "lingual";
};
