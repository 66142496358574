import { countBy } from "lodash";
import { Order, OrderItem } from "src/types/gql";

export const getOrderName = (order: Order) => {
  const productToCount: { [productName: string]: number } = countBy(
    order.orderItems,
    (oi: OrderItem) => oi.product.name
  );
  return Object.entries(productToCount)
    .map(([name, count]) => (count > 1 ? `${name} (${count})` : name))
    .join(", ");
};

export const CheckRefundDetails = "CheckRefundDetails";
export const RefundTypeCheck = "check";

export const RecordPaymentTypes = {
  AFFIRM: "affirm",
  WALNUT: "walnut",
  CHERRY: "cherry",
  PRIMA_HEALTH: "prima_health",
  SPLIT_IT: "split_it",
  PATIENT_FI: "patient_fi",
  CHOICE_PAYS: "choice_pays",
  I_CREDIT: "i_credit",
};
