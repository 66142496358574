import { useEffect, useRef, useState } from "react";
import { get } from "lodash";

import { Appointment } from "src/types/gql";
import { startHour } from "../consts";
import { EntryWithOffset } from "./types";

type Style = {
  height: number | string;
  top: number | string;
};

// styles returns the height and offset for the main wrapper and doctor
// component within a single entry.
export const styles = (e: EntryWithOffset, ratio: number): [Style, Style] => {
  const [apptStyle, drStyle] = [{ height: 0, top: 0 }, { height: "", top: "" }];
  // Calculate the height of the appt div, using the height of the wrapper as 1 hour.
  apptStyle.height = e.duration * ratio;
  // Remove N hours from the offset, depending on the calendar's starting hour
  apptStyle.top = (e.offset - startHour * 60 * 60) * ratio;

  // Calculate the offset of the doctor time
  drStyle.height = `${(e.doctorDuration / e.duration) * 100}%`;
  drStyle.top = `${(e.doctorOffset / e.duration) * 100}%`;

  return [apptStyle, drStyle];
};

export const hoursToSeconds = hours => hours * 60 * 60;
export const secondsToHours = seconds => seconds / 60 / 60;

type ApptValuesForDisplay = {
  apptName: string;
  apptTime: string;
  drName: string;
  drTime: string;
  location: string;
  ptName: string | null;
};

export const formatApptValuesForDisplay = (
  appt: Appointment,
  toHour: (isoDate?: string) => string
): ApptValuesForDisplay => ({
  apptName: get(appt, "appointmentType.name"),
  apptTime: `${toHour(appt.startTime)} - ${toHour(appt.endTime)}`,
  drName: appt.doctor
    ? `${appt.doctor.firstName} ${appt.doctor.lastName}`
    : "No doctor assigned",
  drTime: `${toHour(appt.doctorStartTime)} - ${toHour(appt.doctorEndTime)}`,
  location: `${appt.clinic.nickname} - ${appt.bookableResource.name}`,
  ptName: appt.user
    ? `${
        appt.user.preferredName ? appt.user.preferredName : appt.user.firstName
      } ${appt.user.lastName}`
    : null,
});

function isOverflow(el) {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const rect = el.getBoundingClientRect();
  const offsetHeight = get(el, "offsetHeight");
  return {
    bottom: rect.top + scrollTop + offsetHeight,
    right: rect.right,
    refHeight: rect.height,
  };
}

export const useAppointmentDetailPosition = ({
  height: initialHeight,
  left: initialLeft,
  top: initialTop,
  width: initialWidth,
}) => {
  const [left, setLeft] = useState(`calc(${initialWidth} + ${initialLeft})`);
  const [top, setTop] = useState(initialTop);
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      const { bottom: elBottom, right: elRight, refHeight } = isOverflow(
        ref.current
      );
      let top = initialTop;
      let left = `calc(${initialWidth} + ${initialLeft})`;

      if (elBottom > window.innerHeight) {
        top = `calc(${initialTop} - ${refHeight}px + ${initialHeight})`;
      }

      if (elRight > window.innerWidth) {
        left = `calc(${initialLeft} - ${get(ref, "current.offsetWidth")}px)`;
      }
      setTop(top);
      setLeft(left);
    }
  }, [ref, initialWidth, initialLeft, initialTop, initialHeight]);

  return {
    left,
    ref,
    top,
  };
};
