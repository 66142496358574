import { useState, useEffect, useCallback } from "react";
import { flatten, sortBy, toPairs } from "lodash";

const usePagination = (query, pageResults, items, fetching, page, setPage) => {
  const [resultsByPageByQuery, setResultsByPageByQuery] = useState({});

  const handleScroll = useCallback(() => {
    if ((pageResults && pageResults.totalPages <= page) || fetching) return;

    const bottom =
      window.innerHeight + window.pageYOffset >= document.body.scrollHeight;
    if (bottom) {
      setPage(currPage => currPage + 1);
    }
  }, [fetching, page, pageResults, setPage]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const apiPage = pageResults && pageResults.page;
  useEffect(() => {
    if (!items || !apiPage) return;

    setResultsByPageByQuery(currResults => ({
      ...currResults,
      [query]: {
        ...currResults[query],
        [apiPage]: items,
      },
    }));
  }, [items, apiPage, query]);

  const allResults = flatten(
    sortBy(toPairs(resultsByPageByQuery[query], pair => parseInt(pair[0]))).map(
      pair => pair[1]
    )
  );

  return allResults;
};

export default usePagination;
