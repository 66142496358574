import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "material-ui/TextField";
import { routerShape } from "react-router/lib/PropTypes";
import __htmlFinancial from "src/config/financial-treatment-consent";
import __htmlPrivacy from "src/config/piv-privacy-notice";

import { RaisedButton } from "material-ui";

import styles from "./consent-form-style";
import {
  CONSENT_FORM_FINANCIAL,
  NOTICE_OF_PRIVACY_PRACTICE_FORM_TYPES,
  TREATMENT_FORM_TYPES,
} from "src/config/constant";
import { post as postConsent } from "src/redux/modules/consent";
import { connect } from "react-redux";

const enhance = connect(
  () => ({}),
  { postConsent }
);

class ConsentForm extends Component {
  static propTypes = {
    addEvent: PropTypes.func,
  };
  static contextTypes = {
    patient: PropTypes.object,
    router: routerShape,
  };
  constructor() {
    super();
    this.state = {
      doctorView: false,
      patientName: "",
    };
  }
  logEventAndTransitionToDoctorView = () => {
    const { formName } = this.props.params;
    const isTreatmentFinancialConsent = formName === CONSENT_FORM_FINANCIAL;
    const formTypes = isTreatmentFinancialConsent
      ? TREATMENT_FORM_TYPES
      : NOTICE_OF_PRIVACY_PRACTICE_FORM_TYPES;
    this.props.postConsent(this.context.patient.id, formTypes);
    this.setState({ doctorView: true });
    this.props.addEvent({
      type: `${this.state.patientName} signed PIV ${formName}`,
    });
  };
  goBack = () => {
    this.context.router.goBack();
  };
  handlePatientNameChange = e => {
    this.setState({ patientName: e.target.value });
  };

  renderDoctor = () => {
    return (
      <div>
        <div style={{ textAlign: "center" }}>
          <h1>Thank you!</h1>
          <h3>Please return the device back to the doctor</h3>
        </div>
        <div style={styles.bottomPositioner}>
          <div style={styles.container}>
            <RaisedButton
              style={styles.buttonContainer}
              buttonStyle={styles.button}
              secondary
              label="Continue"
              onClick={this.goBack}
            />
          </div>
        </div>
      </div>
    );
  };
  renderPatient = () => {
    const formName = this.props.params.formName;
    const template = {
      __html:
        formName === CONSENT_FORM_FINANCIAL ? __htmlFinancial : __htmlPrivacy, // eslint-disable-line
    };
    return (
      <div>
        <div dangerouslySetInnerHTML={template} />
        <div style={styles.bottomPositioner}>
          <div style={styles.container}>
            <TextField
              style={styles.textField}
              hintText="Enter full name to sign"
              onChange={this.handlePatientNameChange}
              value={this.state.patientName}
            />
            <RaisedButton
              disabled={!this.state.patientName}
              style={styles.buttonContainer}
              buttonStyle={styles.button}
              secondary
              label="I agree to the policy"
              onClick={this.logEventAndTransitionToDoctorView}
            />
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { doctorView } = this.state;
    return (
      <div style={styles.positioner}>
        <div style={styles.container}>
          {doctorView ? this.renderDoctor() : this.renderPatient()}
        </div>
      </div>
    );
  }
}

export default enhance(ConsentForm);
