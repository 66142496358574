import React, { useState } from "react";
import styled, { css } from "react-emotion";
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  injectStripe,
} from "react-stripe-elements";
import { isNil, get, isEmpty } from "lodash";

import { useQuery } from "src/utils/http/gqlQuery";
import InputText from "src/shared/InputText";
import color from "src/styles/color";
import money from "src/utils/money";
import MoneyInput from "src/shared/MoneyInput";
import Box from "src/shared/Box";
import Modal from "src/shared/Modal";
import { useCreateUserAddress } from "src/state/useAddresses";
import { useAddresses } from "src/state/useAddresses";

import StateSelect from "src/shared/StateSelect";
import Button, { ButtonRow } from "src/shared/Button";
import { DEFAULT_COUNTRY } from "src/utils/address";
import { useAddToken } from "../../query";

import {
  orderGql,
  useCreateCharge,
} from "src/scenes/Patient/BillingV2/Orders/query";

const emptyAddress = {
  line1: "",
  line2: "",
  city: "",
  state: "",
  postalCode: "",
  country: DEFAULT_COUNTRY.code, // for now, assume insurance companies are based in US
};

const ScreenWrapper = styled.div`
  height: 450px;
  width: 600px;

  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

const BoldText = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

const moneyInputStyles = css`
  border: none;
  border-bottom: 1px solid ${color.border};

  &:focus,
  &:active {
    border: none;
    border-bottom: 1px solid ${color.orange};
  }
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

const Content = styled.div`
  overflow-y: scroll;
  flex: 1;
  display: flex;
  flex-flow: column;
  padding: 24px;
`;

const CardElementWrapper = styled.div`
  > div {
    margin-bottom: 5px;
  }
`;

// Necessary for stripe elements
const input = css`
  width: 100%;
  font-size: 16px;
  font-family: "Roobert";
  border: 1px ${color.border} solid !important;
  border-radius: 2px;
  padding: 6px 12px;
  height: 34px;
  box-shadow: none;
  &:focus,
  &:active {
    border: 1px ${color.primary} solid !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;
const Footer = styled.div`
  height: 80px;
  padding: 16px 24px;

  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${color.border};
`;

const InsuranceAddress = styled.button`
  background: none;
  cursor: pointer;
  border: 1px solid ${color.border};
  padding: 10px;
  width: 50%;
  margin-bottom: 5px;
`;

const NewAddressButton = styled.button`
  background: none;
  cursor: pointer;
  border: none;
  color: ${color.orange};
  overflow: visible;
  margin: 5px 0;
  padding: 0;

  align-self: flex-start;
  &:hover {
    text-decoration: underline;
  }
`;

const selectStyle = css`
  height: 34px;
  width: 100%;
  border: 1px solid ${color.border};
  border-radius: 2px;
  cursor: pointer;
`;

const InsuranceAddressContainer = styled.div`
  display: flex;
  flex-flow: column;
`;

const stripeStyle = {
  base: {
    border: "0 none",
    fontFamily:
      "'Roobert', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', sans-serif",
    fontSize: "16px",
    fontSmoothing: "antialiased",
    ":focus": {
      color: "#424770",
    },
  },
};

type AsyncState = {
  state: "loading" | "error" | null;
  error: string | null;
};

const CardInfoScreen = ({ stripe, patientID, setPaymentMethodID, onNext }) => {
  const [status, setStatus] = useState<AsyncState>({
    state: null,
    error: null,
  });
  const [address, setAddress] = useState(emptyAddress);
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
  const [, , addresses] = useAddresses(patientID, {
    requestPolicy: "network-only",
  });
  const insuranceBillingAddresses = addresses.filter(
    addr => addr.addressType === "insurance_billing"
  );

  const [isNewAddress, setIsNewAddress] = useState(
    isEmpty(insuranceBillingAddresses)
  );

  const createUserAddress = useCreateUserAddress();
  const addToken = useAddToken();

  const isValid = () => {
    if (!isNewAddress && Boolean(selectedAddress)) return true;

    return ["line1", "city", "state", "postalCode"].every(field =>
      Boolean(address[field])
    );
  };

  const onChangeAddress = fieldName => e => {
    // @ts-ignore
    setAddress({
      ...address,
      [fieldName]: e.target && e.target.value,
    });
  };

  const onAddressBtnClick = userAddressID => {
    setSelectedAddress(userAddressID);
    setIsNewAddress(false);
  };

  const onSubmit = async e => {
    e.preventDefault();
    if (!stripe || status.state === "loading") {
      return;
    }

    setStatus({ state: "loading", error: null });

    let userAddressID;
    if (isNewAddress) {
      const res = await createUserAddress({
        input: {
          userID: patientID,
          type: "insurance_billing",
          ...address,
        },
      });
      if (res.error) {
        setStatus({ state: "error", error: res.error.message });
        return;
      }
      if (!res.data) {
        setStatus({
          state: "error",
          error: "Something went wrong saving the address",
        });
        return;
      }
      userAddressID = res.data.createUserAddress.id;

      // If nothing was changed on the address form and we have no address ID, error.
      if (!userAddressID) {
        setStatus({ state: "error", error: "Please add an address" });
        return;
      }
    } else {
      userAddressID = selectedAddress;
    }

    // Tokenize the card and add the card to our backend.
    const token = await stripe.createToken({
      type: "card",
      name: "name",
    });

    if (!token || !token.token) {
      setStatus({
        state: "error",
        error: "There was an issue adding your card",
      });
      return;
    }

    const addTokenRes = await addToken({
      input: {
        userId: patientID,
        billingAddressId: userAddressID,
        token: token.token.id,
        type: "insurance_card",
        gateway: "stripe",
      },
    });

    if (addTokenRes.error) {
      setStatus({ state: "error", error: addTokenRes.error.message });
      return;
    }

    setPaymentMethodID(get(addTokenRes, "data.addPaymentMethod.id"));
    setStatus({ state: null, error: null });
    onNext();
  };

  return (
    <ScreenWrapper>
      <Content>
        <Title>Card information</Title>
        <CardElementWrapper>
          <div>Card number</div>
          <CardNumberElement className={input} style={stripeStyle} />
        </CardElementWrapper>
        <CardElementWrapper>
          <div>Expiration date</div>
          <CardExpiryElement className={input} style={stripeStyle} />
        </CardElementWrapper>
        <CardElementWrapper>
          <div>CVC</div>
          <CardCvcElement style={stripeStyle} className={input} />
        </CardElementWrapper>

        <Title>Billing address</Title>
        {!isEmpty(insuranceBillingAddresses) && (
          <>
            <div>Previously used addresses:</div>
            <InsuranceAddressContainer>
              {insuranceBillingAddresses.map(({ id, Address: address }) => (
                <InsuranceAddress
                  onClick={() => onAddressBtnClick(id)}
                  style={{
                    borderColor:
                      selectedAddress === id ? color.orange : undefined,
                  }}
                  key={id}
                >
                  {address.line1}
                  <br />
                  {address.line2}
                  {address.line2 && <br />}
                  {address.city}, {address.state} {address.postalCode}
                </InsuranceAddress>
              ))}
            </InsuranceAddressContainer>
            <NewAddressButton
              onClick={() => {
                setIsNewAddress(true);
                setSelectedAddress(null);
              }}
            >
              Add new address
            </NewAddressButton>
          </>
        )}
        {isNewAddress && (
          <>
            <label>
              Line 1
              <InputText
                placeholder="Street address"
                onChange={onChangeAddress("line1")}
                value={address.line1}
              />
            </label>
            <label>
              Line 2
              <InputText
                placeholder="Apt, building, suite..."
                onChange={onChangeAddress("line2")}
                value={address.line2}
              />
            </label>
            <label>
              City
              <InputText
                placeholder="City"
                onChange={onChangeAddress("city")}
                value={address.city}
              />
            </label>
            <label>
              State
              <StateSelect
                className={selectStyle}
                value={address.state}
                onChange={(state: string) => setAddress({ ...address, state })}
              />
            </label>
            <label>
              Zip
              <InputText
                placeholder="Zip"
                onChange={onChangeAddress("postalCode")}
                value={address.postalCode}
              />
            </label>
          </>
        )}

        {status.error && (
          <p style={{ color: color.red, textAlign: "right" }}>{status.error}</p>
        )}
      </Content>
      <Footer>
        <ButtonRow position="right">
          <Button
            type="primary"
            onClick={onSubmit}
            disabled={status.state === "loading" || !isValid()}
          >
            {status.state === "loading" ? "Loading..." : "Continue"}
          </Button>
        </ButtonRow>
      </Footer>
    </ScreenWrapper>
  );
};

const WrappedCardScreen = injectStripe(CardInfoScreen);

const CardScreenWrapper = props => (
  <Elements>
    <WrappedCardScreen {...props} />
  </Elements>
);

const ChargeScreen = ({
  patientID,
  paymentMethodID,
  insuranceClaimID,
  orderID,
  onClose,
}) => {
  const [status, setStatus] = useState<AsyncState>({
    state: null,
    error: null,
  });
  const [amount, setAmount] = useState(0);

  const isCharging = status.state === "loading";

  const [, refetch] = useQuery({
    query: orderGql,
    variables: { userID: patientID },
    pause: true,
  });
  const createCharge = useCreateCharge();

  if (isNil(paymentMethodID)) return <div>Please enter payment details</div>;

  const onCharge = async () => {
    if (!paymentMethodID || isCharging) return;
    setStatus({ state: "loading", error: null });

    const res = await createCharge({
      orderId: orderID,
      newCharges: [
        {
          amount,
          paymentMethodId: paymentMethodID,
          insuranceClaimId: insuranceClaimID,
        },
      ],
    });
    setStatus({ ...status, state: null });

    if (res.error) {
      setStatus({ error: res.error.message, state: null });
      return;
    }

    refetch();
    onClose();
  };

  return (
    <ScreenWrapper>
      <Content>
        <BoldText>Charge amount</BoldText>
        <div>
          <MoneyInput
            className={moneyInputStyles}
            value={amount}
            onChange={setAmount}
          />
        </div>
        {status.error && (
          <p style={{ color: color.red, textAlign: "right" }}>{status.error}</p>
        )}
      </Content>
      <Footer>
        <ButtonRow position="center">
          <Button
            style={{ minWidth: 100 }}
            kind="primary"
            onClick={onCharge}
            disabled={!paymentMethodID || amount === 0 || isCharging}
          >
            Charge
          </Button>
        </ButtonRow>
      </Footer>
    </ScreenWrapper>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row;
`;

const ClaimButton = styled.button`
  background: none;
  border-radius: 2px;
  padding: 10px;
  margin-right: 8px;
  margin-bottom: 5px;
  &:hover {
    cursor: pointer;
  }
  border: 1px solid;
`;

const InputLabel = styled.div`
  font-weight: bold;
  margin-top: 16px;
`;

const EmptyScreen = styled.div`
  height: 450px;
  width: 600px;
`;

const ClaimsContainer = styled.div`
  padding: 10px;
`;

const ChargeInsuranceCardModal = ({ patientID, orderID, claims, onClose }) => {
  const [screenNum, setScreenNum] = useState(0);
  const [address, setAddress] = useState(emptyAddress);
  const [paymentMethodID, setPaymentMethodID] = useState<string | null>(null);
  const [selectedClaim, setSelectedClaim] = useState<string | null>(null);

  const onChangeAddress = field => (e: React.SyntheticEvent) => {
    setAddress({
      ...address,
      [field]: (e.target as HTMLInputElement).value,
    });
  };

  const onNext = () => setScreenNum(num => num + 1);
  const insuranceClaimID = claims.length === 1 ? claims[0].id : selectedClaim;

  if (screenNum === 0) {
    return (
      <Modal onClose={onClose}>
        <Box title="Charge insurance card">
          {isEmpty(claims) && (
            <EmptyScreen>Please add an insurance claim first.</EmptyScreen>
          )}
          {claims.length > 1 && (
            <ClaimsContainer>
              <InputLabel>Select a claim</InputLabel>
              <div>
                Patient has more than one insurance claim. Please select the one
                this charge will be applied to:
              </div>
              <ButtonContainer>
                {claims.map(claim => (
                  <ClaimButton
                    style={{
                      borderColor:
                        claim.id === selectedClaim ? color.orange : color.gray3,
                    }}
                    onClick={() => setSelectedClaim(claim.id)}
                  >
                    <div>
                      Date submitted: {claim.dateSubmitted}
                      <br />
                      Amount:{" "}
                      {money(
                        claim.expectedPayoutAmount || claim.submittedAmount
                      )}
                      <br />
                      Status: {claim.status}
                    </div>
                  </ClaimButton>
                ))}
              </ButtonContainer>
            </ClaimsContainer>
          )}
          {!isEmpty(claims) && (
            <CardScreenWrapper
              onChangeAddress={onChangeAddress}
              address={address}
              patientID={patientID}
              onNext={onNext}
              setPaymentMethodID={setPaymentMethodID}
            />
          )}
        </Box>
      </Modal>
    );
  }

  return (
    <Modal onClose={onClose}>
      <Box title="Charge insurance card">
        <ChargeScreen
          onClose={onClose}
          patientID={patientID}
          orderID={orderID}
          paymentMethodID={paymentMethodID}
          insuranceClaimID={insuranceClaimID}
        />
      </Box>
    </Modal>
  );
};

export default ChargeInsuranceCardModal;
