import React, { useState } from "react";
import styled from "react-emotion";
import { isEmpty, get } from "lodash";
import color from "src/styles/color";
import Completed from "src/shared/Icons/Completed";
import Cross from "src/shared/Icons/NoShow";
import { request } from "src/shared/util";
import QuestionnaireModal from "src/scenes/PatientProfile/Forms/BeginningVisit/QuestionnaireModal";

const MenuItemBase = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 12px;
  & :hover {
    background-color: ${color.gray1};
  }
`;

const EnabledMenuItem = styled(MenuItemBase)`
  cursor: pointer;
`;

const DisabledMenuItem = styled(MenuItemBase)`
  cursor: not-allowed;
`;

const DocumentName = styled.span`
  padding-left: 6px;
`;

export default function PatientConsents(props) {
  const [openQuestionnaireModal, setOpenQuestionnaireModal] = useState(false);
  const { patient, clinicalRecord, consents, signConsent } = props;
  const ptQuestionnaires =
    get(clinicalRecord, "patient_submitted_data.questionnaires") || {};
  const hasFilledOutQuestionnaire = !isEmpty(ptQuestionnaires);
  const handleMedicalDentalHistoryClick = async () => {
    if (!hasFilledOutQuestionnaire) {
      const resp = await request(
        `/api/v1/patients/${patient.id}/redirect_to_medical_form`
      );
      window.location = resp.url;
    } else {
      setOpenQuestionnaireModal(true);
    }
  };

  return (
    <>
      {consents.map(c =>
        c.signedAt ? (
          <DisabledMenuItem key={c.key}>
            <Completed />
            <DocumentName>{c.title}</DocumentName>
          </DisabledMenuItem>
        ) : (
          <EnabledMenuItem
            key={c.key}
            onClick={() => {
              signConsent(c.key);
            }}
          >
            <Cross />
            <DocumentName>{c.title}</DocumentName>
          </EnabledMenuItem>
        )
      )}
      {clinicalRecord && (
        <EnabledMenuItem onClick={handleMedicalDentalHistoryClick}>
          {hasFilledOutQuestionnaire ? <Completed /> : <Cross />}
          <DocumentName>Medical and Dental</DocumentName>
        </EnabledMenuItem>
      )}
      {openQuestionnaireModal && (
        <QuestionnaireModal
          patient={patient}
          handleClose={() => setOpenQuestionnaireModal(false)}
        />
      )}
    </>
  );
}
