import React from "react";
import { cx } from "react-emotion";
import {
  Row,
  right,
  center,
  SecondaryInvertButton,
  PrimaryInvertButton,
  SecondaryButton,
  PrimaryButton,
  ClearButton,
  WarningButton,
  LinkButton,
  LinkBlueButton,
  InvisibleButton,
  DefaultButton,
} from "./styles";

type RowProps = {
  position: "right" | "center" | "left";
  style?: object;
  className?: string;
};

export const ButtonRow: React.FC<RowProps> = ({
  position,
  children,
  className,
  style,
}) => (
  <Row
    className={cx([
      position === "right" && right,
      position === "center" && center,
      className,
    ])}
    style={style}
  >
    {children}
  </Row>
);

const Button = props => {
  const switchBy = props.kind || props.type;
  switch (switchBy) {
    case "secondaryInvert":
      return <SecondaryInvertButton {...props} />;
    case "primaryInvert":
      return <PrimaryInvertButton {...props} />;
    case "secondary":
      return <SecondaryButton {...props} />;
    case "primary":
      return <PrimaryButton {...props} />;
    case "clear":
      return <ClearButton {...props} />;
    case "warning":
      return <WarningButton {...props} />;
    case "link":
      return <LinkButton {...props} />;
    case "link-blue":
      return <LinkBlueButton {...props} />;
    case "invisible":
      return <InvisibleButton {...props} />;
    default:
      return <DefaultButton {...props} />;
  }
};

export default Button;
