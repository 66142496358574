import { useQuery, UseQueryResponse } from "src/utils/http/gqlQuery";

export function useTimelineFormSubmissions(
  patientID: string
): UseQueryResponse<{ submissions: Array<TimelineFormSubmissionData> }> {
  return useQuery({
    query: TIMELINE_FORM_SUBMISSIONS_QUERY,
    requestPolicy: "cache-first",
    variables: { patientID },
    pause: false,
  });
}

export function useTimelineFiles(
  patientID: string
): UseQueryResponse<{ files: Array<TimelineFileData> }> {
  return useQuery({
    query: TIMELINE_FILES_QUERY,
    requestPolicy: "cache-first",
    variables: { patientID },
    pause: false,
  });
}

export interface TimelineFormSubmissionData {
  id: string;
  parentID: string;
  createdAt: string;
  updatedAt: string;
  submittedAt: string | null;
  approvedAt: string | null;
  appointmentID: string | null;
  form: {
    id: string;
    slug: string;
    name: string;
  };
  submitter?: {
    name: string;
  };
  approver?: {
    name: string;
  };
  files: Array<{
    id: string;
    type: string;
    subtype: string;
    createdAt: string;
    filename: string;
  }>;
  toothData?: string;
}

export const TIMELINE_FORM_SUBMISSIONS_QUERY = `
  query TimelineFormSubmissions($patientID: ID!) {
    submissions(userID: $patientID) {
      id
      parentID
      createdAt
      updatedAt
      submittedAt
      approvedAt
      appointmentID
      form {
        id
        slug
        name
      }
      submitter {
        name
      }
      approver {
        name
      }
      files {
        id
        type
        subtype
        createdAt
        filename
      }
      toothData
    }
  }
`;

export interface TimelineFileData {
  id: string;
  createdAt: string;
  type: string;
  filename: string;
  description: string;
}

export const TIMELINE_FILES_QUERY = `
  query TimelineFiles($patientID: ID!) {
    files(userId: $patientID) {
      id
      createdAt
      type
      filename
      description
    }
  }
`;
