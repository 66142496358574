import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { TableRow, TableRowColumn } from "material-ui";
import styles from "./wear-schedule-style";
import DatePicker from "material-ui/DatePicker";
import differenceInDays from "date-fns/difference_in_days";
import type { Stage } from "types";
import {
  put as putWearSchedule,
  loadTreatmentCycles,
} from "src/redux/modules/wear-schedule";
import { connect } from "react-redux";

type Props = {|
  // displayStage is the stage to show in the UI
  displayStage: Stage,
  // actualStage is the stage as saved currently in the database;
  // ie. the current stage and not the preview
  actualStage: Stage,
  isShowingPreview: boolean,
  onUpdateStage: (stage: Stage) => void,
  onShowCadenceAlert: (stage: Stage) => void,
|};

/**
 * WearScheduleRow renders a row in the wear schedule table with the given
 * stage information.
 *
 */
const enhance = connect(
  null,
  { putWearSchedule, loadTreatmentCycles }
);

class WearScheduleRow extends Component {
  static contextTypes = {
    patient: PropTypes.object,
  };
  props: Props;

  onUpdateStart = (_: void, date: Date) =>
    this.onUpdate("expected_start_date", date);
  onUpdateEnd = (_: void, date: Date) =>
    this.onUpdate("expected_end_date", date);

  onUpdate = (attribute: string, date: Date) => {
    const { displayStage, onUpdateStage } = this.props;
    onUpdateStage({
      ...displayStage,
      [attribute]: moment(date).format("YYYY-MM-DD"), // this has to be this format for date because the backend expects such
    });
  };

  /**
   * getDate returns the date from the attribute in displayStage rendered
   * as a UTC time.
   *
   * This adds the current timezone offset in minutes to the Date object such
   * that the date displayed matches the stored UTC counterpart.
   *
   * If we didn't do this, dates stored at midnight would render as the
   * previous or next day depending on your timezone.
   */
  getDate = (attribute: string): Date => {
    const { displayStage } = this.props;
    const date = new Date(displayStage[attribute]);
    date.setMinutes(new Date().getTimezoneOffset());
    return date;
  };

  isEdited = (attribute: string): boolean => {
    const { actualStage, displayStage } = this.props;
    return actualStage[attribute] !== displayStage[attribute];
  };

  getCellStyle = (attribute: string): Object => {
    if (this.isEdited(attribute)) {
      return {
        ...styles.td,
        ...styles.edited,
      };
    }
    return styles.td;
  };
  markSkipped = () => {
    this.props
      .putWearSchedule({ id: this.props.displayStage.id, skipped: true })
      .then(() => {
        this.props.loadTreatmentCycles(this.context.patient.id);
      });
  };
  markNotSkipped = () => {
    this.props
      .putWearSchedule({ id: this.props.displayStage.id, skipped: false })
      .then(() => {
        this.props.loadTreatmentCycles(this.context.patient.id);
      });
  };

  render() {
    const { displayStage, refinementPrefix } = this.props;
    const start = this.getDate("expected_start_date");
    const end = this.getDate("expected_end_date");
    const todayISO = new Date();
    const todayStyle = todayISO >= start && todayISO <= end ? styles.today : {};
    const skippedStyle = displayStage.skipped ? styles.skippedRow : {};
    const cadence = differenceInDays(end, start);
    const skipped = displayStage.skipped ? (
      <div style={styles.skipped} onClick={this.markNotSkipped}>
        Skipped
      </div>
    ) : (
      <div style={styles.placeholder} onClick={this.markSkipped}>
        Mark skipped
      </div>
    );
    return (
      <TableRow style={{ ...todayStyle, ...skippedStyle }}>
        <TableRowColumn style={styles.stage}>
          {refinementPrefix}
          {displayStage.stage}
        </TableRowColumn>
        <TableRowColumn style={this.getCellStyle("expected_start_date")}>
          <DatePicker
            name="start"
            onChange={this.onUpdateStart}
            value={start}
            textFieldStyle={{ cursor: "pointer" }}
            underlineShow={false}
            okLabel="Preview"
          />
        </TableRowColumn>
        <TableRowColumn style={this.getCellStyle("expected_end_date")}>
          <DatePicker
            name="end"
            onChange={this.onUpdateEnd}
            value={end}
            textFieldStyle={{ cursor: "pointer" }}
            underlineShow={false}
            okLabel="Preview"
          />
        </TableRowColumn>
        <TableRowColumn style={styles.td}>
          <span
            onClick={() => this.props.onShowCadenceAlert(cadence)}
            style={{ cursor: "pointer" }}
          >
            {cadence} days
          </span>
        </TableRowColumn>
        {/*<TableRowColumn style={styles.td}>{displayStage.doctor_notes}</TableRowColumn>*/}
        {/*<TableRowColumn style={styles.td}>{displayStage.patient_notes}</TableRowColumn>*/}
        <TableRowColumn style={styles.td}>{skipped}</TableRowColumn>
      </TableRow>
    );
  }
}

export default enhance(WearScheduleRow);
