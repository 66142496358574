import React from "react";
import { Task } from "../../types";
import ChooseManufacturer from "src/shared/TaskActions/ChooseManufacturer/ChooseManufacturer";

type Props = {
  task: Task;
  pipelineID: string;
};

const ChooseManufacturerWrapper: React.FC<Props> = ({ task, pipelineID }) => {
  return <ChooseManufacturer patient={task.user} pipelineID={pipelineID} />;
};

export default ChooseManufacturerWrapper;
