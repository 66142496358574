import styled, { css } from "react-emotion";
import color from "src/styles/color";

export const PatientProviderStatusContent = styled.div`
  padding: 0px 32px 23px;
  display: flex;
  flex-flow: column;
  flex: 1;

  height: 62px;
  width: auto;
`;

export const ProviderInformationContent = styled.div`
  padding: 23px 32px;
  display: flex;
  flex-flow: column;
  flex: 1;
  overflow: auto;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
`;

export const Title = styled.div`
  font-weight: bold;
  margin-bottom: 16px;
  padding-top: 16px;
`;

export const InputBox = styled.input`
  border: 1px solid ${color.border};
  padding: 10px 16px;
`;

export const Date = styled.div`
  color: ${color.gray5};
`;

export const InvisibleButton = styled.div`
  display: flex;
  align-items: center;
  border: 0;
  background: #fff;
  cursor: pointer;
  color: ${color.gray5};
  opacity: 1;
  transition: all 0.3s;

  &:focus {
    outline: 0 none;
  }

  b {
    color: ${color.gray5};
  }

  svg {
    margin-right: 8px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row;

  > div:nth-child(1) {
    color: ${color.gray3};
    font-weight: 600;
    margin-right: 27px;
    width: 100px;
  }
`;

export const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row;
  padding: 0px 0px 15px;
  border-bottom: 1px solid ${color.gray2};

  > div:nth-child(1) {
    color: ${color.gray3};
    font-weight: 600;
    margin-right: 27px;
    width: 100px;
  }
`;

export const inputName = css`
  width: 150px;
  margin-right: 5px;
`;

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  border-left: 1px solid ${color.border};
  padding: 16px 24px;
  width: 600px;
  max-height: 500px;
  overflow: auto;

  hr {
    color: ${color.border};
    width: 100%;
  }
`;
