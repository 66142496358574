export const pipelineCategories = ["manufacturing", "clinical"] as const;

export const segmentationTaskTypes = [
  "send_for_segmentation",
  "receive_from_segmentation",
] as const;

export const treatmentPlanTaskTypes = [
  "treatment_plan",
  "review_treatment_plan",
  "revise_treatment_plan",
  "approve_treatment_plan",
] as const;

export const stlTaskTypes = [
  "export_treatment_plan_files",
  "download_stl_files",
  "choose_manufacturer",
  "zip_stl_files",
  "send_stl_files_for_aligner_manufacturing",
] as const;

export const alignerManufacturingTaskTypes = [
  "receive_aligners_from_manufacturing",
] as const;

export const shipmentTaskTypes = [
  "qa_trays",
  "create_kits_for_shipment",
  "ship_kit_to_patient",
] as const;

export const manufacturingTaskTypes = [
  ...segmentationTaskTypes,
  ...treatmentPlanTaskTypes,
  ...stlTaskTypes,
  ...alignerManufacturingTaskTypes,
  ...shipmentTaskTypes,
];

export const clinicalTaskTypes = [
  "submit_form",
  "external_care",
  "comfort_check",
  "internal",
  "upload_raw_scan",
  "upload_cbct",
  "add_general_dentist",
] as const;

export const taskTypes = [...manufacturingTaskTypes, ...clinicalTaskTypes];

export const manufacturingPipelinesTypes = [
  "beginning",
  "refinement",
  "reboot",
  "retainer",
  "holding_stage",
  "reprint",
] as const;

export const clinicalPipelinesTypes = [
  "balance_unpaid",
  "comfort_check",
  "export",
  "external_care",
  "external_provider",
  "failed_payment",
  "form_review",
  "general_dentist_consult",
  "internal",
  "observation",
  "other_hold",
  "rescan",
  "sales",
  "specialist_consult",
  "treatment_terminated",
] as const;

export const pipelineTypes = [
  ...manufacturingPipelinesTypes,
  ...clinicalPipelinesTypes,
];

export const treatmentLevelTypes = [
  "level_1",
  "level_2",
  "level_3",
  "level_4",
  "undifferentiated",
  "retainer_only",
  "limited_orthodontic_treatment",
  "anomaly",
  "comprehensive_orthodontic_treatment",
  "complex_orthodontic_treatment",
] as const;

export const patientStatusNames = [
  "active_treatment",
  "retention_only",
  "debond",
  "discontinued",
  "simulation_only",
  "graduated",
  "not_eligible",
  "willful_lack_of_communication",
  "converted",
  "deactivated",
  "lost",
  "new_patient",
] as const;

export const taskLabels = [
  "needs_unblocking",
  "resolved",
  "pending_pt_sim_approval",
  "virtual_refinement",
  "rush",
];
