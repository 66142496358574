import React from "react";
import { Button } from "antd";
import Dropdown from "src/shared/Dropdown";
import { SelectedObj } from "src/types/types";
import LeftArrow from "src/shared/Icons/LeftArrow";
import RightArrow from "src/shared/Icons/RightArrow";
import {
  SummaryHeaderPhotosPagination,
  PHOTOS_DROPDOWN_WIDTH,
} from "../styles";

export interface Props {
  selected?: string;
  options: Array<SelectedObj>;
  onSelect: (value: string) => void;
}

export default function({ options, selected, onSelect }: Props) {
  if (options.length === 1) {
    return <div>{options[0].label}</div>;
  }
  if (options.length > 1) {
    const index = options.findIndex(({ value }) => value === selected);
    return (
      <SummaryHeaderPhotosPagination>
        <Button
          key="previous"
          disabled={index <= 0}
          onClick={() => onSelect(options[index - 1].value)}
        >
          <LeftArrow />
        </Button>
        <Dropdown
          options={options}
          selected={selected}
          onSelect={({ value }) => onSelect(value)}
          width={PHOTOS_DROPDOWN_WIDTH}
        />
        <Button
          key="next"
          disabled={index >= options.length - 1}
          onClick={() => onSelect(options[index + 1].value)}
        >
          <RightArrow />
        </Button>
      </SummaryHeaderPhotosPagination>
    );
  }
  return null;
}
