import { EmptySlot } from "src/types/api";
import {
  Appointment,
  AppointmentSubtype,
  AppointmentType,
} from "src/types/gql";
import { startHour, endHour } from "./consts";
import { titleCase } from "src/shared/util";

export const getLabel = (
  t: AppointmentType | AppointmentSubtype | string | null
) => {
  if (!t) {
    return "--";
  }
  if (typeof t === "string") {
    return titleCase(t.replace(/_/g, " "));
  }
  return titleCase(t.name.replace(/_/g, " "));
};

export const minutes = [
  "00",
  "05",
  "10",
  "15",
  "20",
  "25",
  "30",
  "35",
  "40",
  "45",
  "50",
  "55",
];

export const hours = new Array(endHour - startHour).fill(null).map((_, n) => {
  const h = n + startHour;
  return h >= 12 ? `${h % 12 || 12}pm` : `${h}am`;
});

export const slots = hours.map(h => ({ label: h }));

export const isAppt = (
  input: EmptySlot | Appointment
): input is Appointment => {
  return (input as EmptySlot).clientId === undefined;
};
