import React, { Component } from "react";
import PropTypes from "prop-types";
import { cloneDeep, get, findIndex } from "lodash";
import uuid from "uuid/v4";
// for actions and redux
import { connect } from "react-redux";
import { putStaffSubmittedData } from "src/redux/modules/clinical-record";
import { getPatientSelector } from "src/redux/modules/patient";
import TreatmentNav from "./components/treatment-nav/treatment-nav";

const makeEventWithUserData = (event, auth) => {
  return {
    ...event,
    postTimestamp: new Date().toISOString(),
    postUserUUID: auth.id,
    postUserName: auth.name,
  };
};

// @asyncConnect({
//   clinicalRecord: clinicalRecordHandler,
//   patient: patientAsyncConnectHandler,
//   consent: consentHandler,
// })

// The fuck is this shit.
const enhance = connect(
  (state, props) => ({
    auth: get(state, "auth"),
    staffSubmittedData:
      get(state, "clinicalRecord.data.staff_submitted_data.eventStream") || [],
    patientSubmittedData:
      get(state, "clinicalRecord.data.patient_submitted_data") || {},
    syncFolderPath: get(state, "clinicalRecord.data.folder_path"),
    toothChart:
      get(state, "clinicalRecord.data.dental_chart.eventStream") || [],
    patient: getPatientSelector(state, props),
    consent: state.consent.data,
  }),
  { putStaffSubmittedData },
  (stateProps, dispatchProps, ownProps) => {
    return Object.assign({}, ownProps, stateProps, dispatchProps, {
      addEvent: event => {
        const newEvent = makeEventWithUserData(event, stateProps.auth);
        newEvent.uuid = uuid();
        const cloneEventList = cloneDeep(stateProps.staffSubmittedData);
        cloneEventList.unshift(newEvent);
        dispatchProps.putStaffSubmittedData(
          ownProps.params.userId,
          cloneEventList
        );
      },

      // this is used by forms
      // new forms are created here, if theres no matching uuid we create a new event
      updateEvent: event => {
        const newEvent = makeEventWithUserData(event, stateProps.auth);
        if (newEvent.attachToothChart && event.complete) {
          newEvent.toothchart = stateProps.toothChart;
        }
        const cloneEventList = cloneDeep(stateProps.staffSubmittedData);
        const formEventIndex = findIndex(cloneEventList, {
          uuid: newEvent.uuid,
        }); // uuid is created on getNewEmptyForm
        if (formEventIndex === -1) {
          // if there is no existing form with matching UUID
          cloneEventList.unshift(newEvent); // new form event is created
        } else {
          cloneEventList[formEventIndex] = newEvent;
        }
        dispatchProps.putStaffSubmittedData(
          ownProps.params.userId,
          cloneEventList
        );
      },
    });
  }
);

class Treatment extends Component {
  static childContextTypes = {
    patient: PropTypes.object,
  };
  getChildContext = () => {
    return {
      patient: this.props.patient,
    };
  };
  render() {
    return (
      <div key={get(this.props, "patient.id")}>
        <TreatmentNav />
        <div>
          {this.props.children &&
            React.cloneElement(this.props.children, { ...this.props })}
        </div>
      </div>
    );
  }
}

export default enhance(Treatment);
