import { getPalmer, getUniversal, parseIPR } from "src/shared/ToothChart/util";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";

export type IPRPosition =
  | "mesial"
  | "distal"
  | "right proximal"
  | "left proximal";

// mesial or distal
export type IPRPlane = "M" | "D";

export interface IPRProcedure {
  toothNumber: number;
  entry_data: {
    position: IPRPosition;
    amount: string;
  };
}

export interface Quadrant {
  // The quadrant's start/end tooth numbers; quadrants do not overlap.
  start: number;
  end: number;

  // Specifies positions that match the quadrant's natural direction.
  // Movement happens in a circle from tooth 1 thru 32, ie: UR -> UL -> LL -> LR
  naturalDirection: Set<IPRPosition>;
}

export const UR: Quadrant = {
  start: 1,
  end: 8,
  naturalDirection: new Set<IPRPosition>(["mesial", "left proximal"]),
};

export const UL: Quadrant = {
  start: 9,
  end: 16,
  naturalDirection: new Set<IPRPosition>(["distal", "left proximal"]),
};

export const LL: Quadrant = {
  start: 17,
  end: 24,
  naturalDirection: new Set<IPRPosition>(["mesial", "right proximal"]),
};

export const LR: Quadrant = {
  start: 25,
  end: 32,
  naturalDirection: new Set<IPRPosition>(["distal", "right proximal"]),
};

export const ORDERED_QUADRANTS: Array<Quadrant> = [UR, UL, LL, LR];

// NOTE: Assumes `toothNumber` is valid, ie: 1 thru 32
export function getQuadrantForTooth(toothNumber: number): Quadrant {
  const n = toothNumber;
  const quad = ORDERED_QUADRANTS.find(it => n >= it.start && n <= it.end);

  // Satisfy typecheck.
  if (!quad) {
    throw new Error("Invalid toothNumber");
  }

  return quad;
}

export function getIPRClauses(allProcedures: Array<IPRProcedure>): string[] {
  if (allProcedures.length === 0) {
    return [];
  }

  // First, get canonical chunks of procs for each quadrant.
  const procsByQuadrant = getProceduresByQuadrant(allProcedures);
  const chunksByQuad = getChunksByQuadrant(procsByQuadrant);

  // Then, merge border chunks if possible for UR->UL and LL->LR
  const finalChunks = mergeBorderChunks(chunksByQuad);

  const allClauses: Array<string> = [];
  for (const chunk of finalChunks) {
    const startQuad = getQuadrantForTooth(chunk[0].toothNumber);
    const endQuad = getQuadrantForTooth(chunk[chunk.length - 1].toothNumber);
    if (startQuad !== endQuad) {
      allClauses.push(summarizeContinuousChunk(chunk));
    } else {
      allClauses.push(summarizeChunkInQuadrant(startQuad, chunk));
    }
  }

  return allClauses;
}

export default function getIPRText(allProcedures: Array<IPRProcedure>): string {
  const clauses = getIPRClauses(allProcedures);
  if (clauses.length === 0) {
    return "";
  }

  const text = clauses.join(", ");
  return `    -- IPR: ${text}`;
}

export function getProceduresByQuadrant(
  allProcedures: Array<IPRProcedure>
): Map<Quadrant, Array<IPRProcedure>> {
  const procsByQuadrant = new Map();
  for (const proc of allProcedures) {
    const quad = getQuadrantForTooth(proc.toothNumber);
    const procs = procsByQuadrant.get(quad);
    if (procs) {
      procs.push(proc);
    } else {
      procsByQuadrant.set(quad, [proc]);
    }
  }
  return procsByQuadrant;
}

export function getChunksByQuadrant(
  procsByQuadrant: Map<Quadrant, Array<IPRProcedure>>
): Map<Quadrant, Array<Array<IPRProcedure>>> {
  const chunksByQuad: Map<Quadrant, Array<Array<IPRProcedure>>> = new Map();
  for (const quad of ORDERED_QUADRANTS) {
    const quadProcs = procsByQuadrant.get(quad);
    if (!quadProcs) {
      continue;
    }
    const chunks = partitionProceduresInQuadrant(quad, quadProcs);
    chunksByQuad.set(quad, chunks);
  }
  return chunksByQuad;
}

// Merge border chunks if possible for UR->UL and LL->LR.
// Returns final, ordered chunks.
export function mergeBorderChunks(
  chunksByQuad: Map<Quadrant, Array<Array<IPRProcedure>>>
): Array<Array<IPRProcedure>> {
  let finalChunks: Array<Array<IPRProcedure>> = [];

  for (const [startQuad, endQuad] of [[UR, UL], [LL, LR]]) {
    const startQuadChunks = chunksByQuad.get(startQuad);
    const endQuadChunks = chunksByQuad.get(endQuad);

    if (startQuadChunks && endQuadChunks) {
      // ASCII ART (full merge, which includes complementary inner chunks):
      //   outerChunksA = ..
      //   innerChunkA  = [.., <-toothA]  // Merge
      //   borderChunkA = [toothA->]      // Merge
      //   borderChunkB = [<-toothB]      // Merge
      //   innerChunkB  = [toothB->, ..]  // Merge
      //   outerChunksB = ..

      const [borderChunkA, innerChunkA, ...outerChunksAReversed] = reverse(
        startQuadChunks
      );
      const [borderChunkB, innerChunkB, ...outerChunksB] = endQuadChunks;
      const borderProcA = borderChunkA[borderChunkA.length - 1];
      const borderProcB = borderChunkB[0];
      const toothA = borderProcA.toothNumber;
      const toothB = borderProcB.toothNumber;

      const isBordering = toothA === startQuad.end && toothB === endQuad.start;
      const areBorderProcsSameAmount =
        borderProcA.entry_data.amount === borderProcB.entry_data.amount;
      const isProcADirNatural = startQuad.naturalDirection.has(
        borderProcA.entry_data.position
      );
      const isProcBDirComplementary = !endQuad.naturalDirection.has(
        borderProcB.entry_data.position
      );

      if (
        isBordering &&
        areBorderProcsSameAmount &&
        isProcADirNatural &&
        isProcBDirComplementary
      ) {
        let mergedChunk = [borderProcA, borderProcB];
        let didMergeInnerChunkA = false;
        let didMergeInnerChunkB = false;

        // Also include inner chunks if appropriate.
        if (innerChunkA && innerChunkA.length >= 2) {
          const innerProcA = innerChunkA[innerChunkA.length - 1];
          const isInnerProcASameTooth = innerProcA.toothNumber === toothA;
          const isInnerProcASameAmount =
            innerProcA.entry_data.amount === borderProcA.entry_data.amount;
          if (isInnerProcASameTooth && isInnerProcASameAmount) {
            mergedChunk = innerChunkA.concat(mergedChunk);
            didMergeInnerChunkA = true;
          }
        }

        if (innerChunkB && innerChunkB.length >= 2) {
          const innerProcB = innerChunkB[0];
          const isInnerProcBSameTooth = innerProcB.toothNumber === toothB;
          const isInnerProcBSameAmount =
            innerProcB.entry_data.amount === borderProcB.entry_data.amount;
          if (isInnerProcBSameTooth && isInnerProcBSameAmount) {
            mergedChunk = mergedChunk.concat(innerChunkB);
            didMergeInnerChunkB = true;
          }
        }

        finalChunks = finalChunks.concat(reverse(outerChunksAReversed));
        if (!didMergeInnerChunkA && innerChunkA) {
          finalChunks.push(innerChunkA);
        }
        finalChunks.push(mergedChunk);
        if (!didMergeInnerChunkB && innerChunkB) {
          finalChunks.push(innerChunkB);
        }
        finalChunks = finalChunks.concat(outerChunksB);
        continue;
      }
    }

    if (startQuadChunks) {
      finalChunks = finalChunks.concat(startQuadChunks);
    }
    if (endQuadChunks) {
      finalChunks = finalChunks.concat(endQuadChunks);
    }
  }

  return finalChunks;
}

// Partition procedures in the quadrant by continuous in-between runs, ie: UR8-UR1 (aka UR8 mesial thru UR1 distal),
// and splits out discontinuities as separate chunks (ie: UR8 distal, UR1 mesial).
// NOTE: Assumes `procs` only contains teeth belonging to `quad`.
export function partitionProceduresInQuadrant(
  quad: Quadrant,
  procs: Array<IPRProcedure>
): Array<Array<IPRProcedure>> {
  const sortedProcedures = sortProceduresInQuadrant(quad, procs);
  const chunks: Array<Array<IPRProcedure>> = [];
  for (const curr of sortedProcedures) {
    const startNewChunk = () => {
      chunks.push([curr]);
    };

    if (chunks.length === 0) {
      startNewChunk();
      continue;
    }

    const chunk = chunks[chunks.length - 1];
    const addToChunk = () => {
      chunk.push(curr);
    };

    const prev = chunk[chunk.length - 1];
    const isAmountSame = curr.entry_data.amount === prev.entry_data.amount;
    if (!isAmountSame) {
      startNewChunk();
      continue;
    }

    const isPrevSingleton = chunk.length === 1;
    const isPrevDirNatural = quad.naturalDirection.has(
      prev.entry_data.position
    );

    if (isPrevSingleton && !isPrevDirNatural) {
      startNewChunk();
      continue;
    }

    const isCurrToothSame = curr.toothNumber === prev.toothNumber;
    if (isCurrToothSame) {
      addToChunk();
      continue;
    }

    const isCurrToothAdjacent = curr.toothNumber === prev.toothNumber + 1;
    if (!isCurrToothAdjacent) {
      startNewChunk();
      continue;
    }

    const isCurrDirComplementary = !quad.naturalDirection.has(
      curr.entry_data.position
    );
    if (isPrevDirNatural && isCurrDirComplementary) {
      addToChunk();
    } else {
      startNewChunk();
    }
  }

  const finalChunks: Array<Array<IPRProcedure>> = [];
  for (const chunk of chunks) {
    if (chunk.length === 1) {
      finalChunks.push(chunk);
      continue;
    }

    // Split hanging ends, we only want continuous in-between runs
    const end = chunk[chunk.length - 1];
    const isEndHanging = quad.naturalDirection.has(end.entry_data.position);
    if (isEndHanging) {
      chunk.pop();
      finalChunks.push(chunk, [end]);
    } else {
      finalChunks.push(chunk);
    }
  }
  return finalChunks;
}

// NOTE: Assumes `procs` only contains teeth belonging to `quad`.
export function sortProceduresInQuadrant(
  quad: Quadrant,
  procs: Array<IPRProcedure>
): Array<IPRProcedure> {
  const getPositionOrder = (proc: IPRProcedure): number => {
    return quad.naturalDirection.has(proc.entry_data.position) ? 1 : 0;
  };
  return sortBy(procs, ["toothNumber", getPositionOrder]);
}

// NOTE: Assumes `chunk.length >= 2`
export function summarizeContinuousChunk(chunk: Array<IPRProcedure>): string {
  const first = chunk[0];
  const { amount } = first.entry_data;
  const last = chunk[chunk.length - 1];
  const sum = ((parseIPR(amount) || 0) * 2) / 100;
  const from = getPalmer(first.toothNumber);
  const to = getPalmer(last.toothNumber);
  return `${from}-${to} (${sum}mm)`;
}

// NOTE: Assumes `chunk` only contains teeth belonging to `quad`.
export function summarizeChunkInQuadrant(
  quad: Quadrant,
  chunk: Array<IPRProcedure>
): string {
  if (chunk.length >= 2) {
    return summarizeContinuousChunk(chunk);
  } else {
    const proc = chunk[0];
    const { amount } = proc.entry_data;
    const palmer = getPalmer(proc.toothNumber);
    const plane = getIPRPlane(quad, proc);
    return `${palmer} (${plane} ${amount})`;
  }
}

// NOTE: Assume the tooth in `proc` belongs to `quad`.
export function getIPRPlane(quad: Quadrant, proc: IPRProcedure): IPRPlane {
  const isMesialNatural = quad.naturalDirection.has("mesial");
  if (quad.naturalDirection.has(proc.entry_data.position)) {
    return isMesialNatural ? "M" : "D";
  } else {
    return isMesialNatural ? "D" : "M";
  }
}

export function isIPR(procedure) {
  return procedure.entry_type === "ipr";
}

export function procedureToUniversal(procedure) {
  return Object.assign({}, procedure, {
    toothNumber: getUniversal(procedure.tooth_name),
  });
}
