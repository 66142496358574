import { map } from "lodash";
import type { Stage } from "types";
import { findAndReplaceMergeOnId } from "src/utils/helperFn";
import { createSelector } from "reselect";

const LOAD_TREATMENT_CYCLES = "@@wear-schedule/LOAD_TREATMENT_CYCLES";
// handles all previews; previews for cadence and updating a single stage
// both return a fully updated but unsaved schedule.
const PREVIEW_SUCCESS = "@@wear-schedule/PREVIEW_SUCCESS";
const CANCEL_PREVIEW = "@@wear-schedule/CANCEL-PREVIEW";
// SAVE saves a single stage's edits or cadence, depending on the edit type
const SAVE = "@@wear-schedule/SAVE";
const SAVE_SUCCESS = "@@wear-schedule/SAVE_SUCCESS";
const SAVE_FAIL = "@@wear-schedule/SAVE_FAIL";
const PUT = "@@wear-schedule/PUT";
const POST = "@@wear-schedule/POST";

const allTreatmentCycleUrl = "api/v1/treatment_cycles";

export const editDates = "dates";
export const editCadence = "cadence";

export type EditType = editDates | editCadence;

const initialState = {
  patientId: null,
  // a map of user IDs to treatment cycles
  treatmentCycles: {},
  // preview stores all information required to preview edits to a wear
  // schedule *then save* the data.
  //
  // In the UI, we need to be able to see how modifying dates and cadences
  // affects future stages prior to saving.  This means we need a place to
  // store the edits and the updated, unsaved schedule.
  preview: {
    data: [],

    // editType stores whether the preview is generated via editing a stage's
    // dates or by editing the cadence of a stage.
    editType: "", // of type editType.
    // modifiedStage is of type Stage and stores the stage which has been
    // edited to generate the preview.  When editing dates, this stores the
    // stage and the new dates.  When editing a cadence, this stores the
    // original stage (as no properties of the stage are edited).
    //
    // We need this to be stored so that we can prevent further edits without
    // saving; if the 2n+ edit doesn't match the modifiedStage ID it can't be
    // performed.
    modifiedStage: null,
    // cadence stores the new cadence when previewing a cadence edit.
    cadence: 0,
  },
  // ui stores ui-related metadata for the wear schedule as long as the data
  // is required across many components.
  //
  // Note that storing the data here vs' in state allows for much easier
  // development wrt hot reloading, as internal state is lost during hot
  // reloads
  ui: {
    showingPreviewForTCId: false,
  },
};

const modifiers = {
  [LOAD_TREATMENT_CYCLES]: (state, action) => {
    return {
      ...state,
      treatmentCycles: {
        ...state.treatmentCycles,
        [action.result.userId]: action.result.data,
      },
    };
  },
  [SAVE_SUCCESS]: state => ({
    ...state,
    preview: initialState.preview,
    ui: {
      ...state.ui,
      showingPreviewForTCId: null,
    },
  }),
  [PREVIEW_SUCCESS]: (state, action) => ({
    ...state,
    preview: {
      ...state.preview,
      data: action.result,
      // store the edit type, stage, and cadence info in redux state as
      // explained above.
      editType: action.meta.editType,
      modifiedStage: action.meta.modifiedStage,
      cadence: action.meta.cadence,
    },
    ui: {
      ...state.ui,
      // show the preview in the wear schedule
      showingPreviewForTCId: action.treatmentCycleId,
    },
  }),

  [CANCEL_PREVIEW]: state => ({
    ...state,
    preview: {
      data: [],
    },
    ui: {
      showingPreviewForTCId: null,
    },
  }),
  [PUT]: (state, action) => ({
    ...state,
    treatmentCycles: map(state.treatmentCycles, treatmentCycle => {
      return {
        ...treatmentCycle,
        wear_schedule: findAndReplaceMergeOnId(
          treatmentCycle.wear_schedule,
          action.result
        ),
      };
    }),
  }),
};

export default function reducer(state = initialState, action = {}) {
  if (typeof modifiers[action.type] === "function") {
    return modifiers[action.type](state, action);
  }
  return state;
}

/**
 * Actions
 */

export function loadTreatmentCycles(userId) {
  return {
    types: [null, LOAD_TREATMENT_CYCLES, null],
    promise: client =>
      client
        .get(allTreatmentCycleUrl, { params: { user_id: userId } })
        .then(json => {
          return {
            data: json,
            userId,
          };
        }),
  };
}

export function cancelPreview() {
  return { type: CANCEL_PREVIEW };
}

export type PreviewArgs = {|
  modifiedStage: Stage,
  editType: EditType,
  cadence?: number,
|};

// Saving a schedule uses the same args as peviewing.
export type SaveArgs = PreviewArgs;

export function preview({
  modifiedStage,
  editType,
  cadence,
  treatmentCycleId,
}: PreviewArgs) {
  if (editType === editDates) {
    return {
      treatmentCycleId,
      types: [null, PREVIEW_SUCCESS, null],
      promise: client =>
        client.put(`/api/v1/wear_timeline_stages/${modifiedStage.id}/preview`, {
          data: modifiedStage,
        }),
      // pass in the edit type and stage information to store in the reducer
      meta: {
        editType,
        modifiedStage,
      },
    };
  }

  return {
    treatmentCycleId,
    types: [null, PREVIEW_SUCCESS, null],
    promise: client =>
      client.put(
        `/api/v1/wear_timeline_stages/${modifiedStage.id}/preview_cadence`,
        {
          data: { delta_in_days: cadence },
        }
      ),
    // pass in the edit type, cadence, and stage information to store in the reducer
    meta: {
      editType,
      cadence,
      modifiedStage,
    },
  };
}

export function save({ modifiedStage, editType, cadence }: SaveArgs) {
  if (editType === editDates) {
    return {
      types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
      promise: client =>
        client.put(`/api/v1/wear_timeline_stages/${modifiedStage.id}`, {
          data: modifiedStage,
        }),
    };
  }

  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: client =>
      client.put(`/api/v1/wear_timeline_stages/${modifiedStage.id}/cadence`, {
        data: {
          delta_in_days: cadence,
        },
      }),
  };
}

export function put(stage) {
  return {
    types: [null, PUT, null],
    promise: client =>
      client.put(`/api/v1/wear_timeline_stages/${stage.id}`, {
        data: stage,
      }),
  };
}

export function post(stage) {
  return {
    types: [null, POST, null],
    promise: client =>
      client.post("/api/v1/wear_timeline_stages", {
        data: stage,
      }),
  };
}

/**
 * Selectors
 */

// returns ALL TCs
export const getAllTreatmentCycles = state => {
  return (
    Object.values(state.wearSchedule.treatmentCycles).reduce(
      (coll, item) => coll.concat(item),
      []
    ) || []
  );
};
// returns TCs for current patient only, as set in 'patientId' prop
export const getPatientTreatmentCycles = (state, props) => {
  return state.wearSchedule.treatmentCycles[props.patientId] || [];
};

export const getPreviewWearSchedule = state => state.wearSchedule.preview.data;
export const getModifiedStage = state =>
  state.wearSchedule.preview.modifiedStage;
export const getShowingPreviewForTCId = state =>
  state.wearSchedule.ui.showingPreviewForTCId;
export const getEditType = state => state.wearSchedule.preview.editType;
export const getCadence = state => state.wearSchedule.preview.cadence;
export const getTrays = createSelector(
  [getPatientTreatmentCycles],
  allCycles => {
    let trays = [];
    allCycles.forEach(tc => {
      // only select unskipped wear schedules
      trays = trays.concat(tc.wear_schedule);
    });
    return trays;
  }
);

export const getTreatmentCyclesAsObj = createSelector(
  [getPatientTreatmentCycles],
  tcs => {
    const byId = {};
    tcs.forEach(tc => {
      byId[tc.id] = tc;
    });
    return byId;
  }
);

export const getTraysAsObj = createSelector(
  [getTrays],
  trays => {
    const byId = {};
    trays.forEach(t => {
      byId[t.id] = t;
    });
    return byId;
  }
);
