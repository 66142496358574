import React from "react";
import styled from "react-emotion";

import LabelsPopover, { taskLabelsOptions } from "src/shared/Popovers/Labels";

import CustomLabel from "./CustomLabel";
import InvisibleButton from "../NewTask/InvisibleButton";
import { TaskLabels } from "../types";

type Props = {
  labels: TaskLabels[];
  onChange: (labels: TaskLabels[]) => void;
};

const CustomLabelsList: React.FC<Props> = ({ labels, onChange }) => {
  const isLabelsListEmpty = labels.length === 0;
  const isLabelsListFull = labels.length === taskLabelsOptions.length;
  const buttonText = isLabelsListFull
    ? ""
    : isLabelsListEmpty
    ? "Select Label"
    : "+ Add label";

  const handleClick = (label: TaskLabels) => {
    const isLabelIncluded = labels.includes(label);
    const isNotCurrentLabel = _label => _label !== label;
    const newLabels = isLabelIncluded
      ? labels.filter(isNotCurrentLabel)
      : labels.concat(label);

    onChange(newLabels);
  };

  return (
    <Wrapper>
      <List hide={isLabelsListEmpty}>
        {labels.map((label, index) => (
          <Item key={label}>
            <CustomLabel label={label} />
            {index === labels.length - 1 ? "" : ", "}
          </Item>
        ))}
      </List>
      <LabelsPopover value={labels} onClick={handleClick}>
        <InvisibleButton>{buttonText}</InvisibleButton>
      </LabelsPopover>
    </Wrapper>
  );
};

export default CustomLabelsList;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const List = styled.ul<{
  hide: boolean;
}>`
  display: ${({ hide }) => (hide ? "none" : "flex")};
  align-items: center;
  gap: 8px;
  padding: 0;
  margin: 0;
  margin-right: 12px;
  list-style-type: none;
`;

const Item = styled.li`
  display: inline-block;
  padding: 0;
  margin: 0;

  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 500;
`;
