export type User = {
  id: string;
  impressID: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  pronouns: string;
  name: string;
  email: string;
  mobilePhoneNumber: string;
  nextAppointmentSubtype: AppointmentSubtype;
  homeClinicID?: string;
  homeClinic?: {
    id: string;
    name: string;
  };
};

export type PaymentPlan = {
  id: string;
  start: string;
  order: { id: string };
  paymentMethod: PaymentMethod;
  nextPaymentAt: string | null;
  financingProduct: FinancingProduct;
  createdAt: string;
};

export type FinancingProduct = {
  id: string;
  rate: number;
  monthlyAmount: number;
  deposit: number;
  available: boolean;
};

export type DoctorLicense = {
  userID: string;
  licenseNumber: string;
  licenseState: string;
};

export type Doctor = {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  roles: Array<UserRole>;
  licenses: Array<DoctorLicense>;
};

export type BookableResource = {
  id: string;
  name: string;
  clinicId: string;
};

export type AppointmentType = {
  id: string;
  name: string;
  enabled: boolean;
  doctorDuration: number;
  largestPatientBuffer: number;
  largestRemainingDuration: number;
};

export type AppointmentSubtype = {
  id: string;
  name: string;
  enabled: boolean;
  appointmentType: AppointmentType;
  doctorOffset: number;
  appointmentDuration: number;
};

export type Appointment = {
  id: string;
  userId: string | undefined;
  startTime: string | undefined;
  endTime: string | undefined;
  appointmentType: AppointmentType;
  appointmentSubtype: AppointmentSubtype | undefined;
  doctorStartTime: string;
  doctorEndTime: string;
  doctorId: string;
  actualStartTime: string | undefined;
  actualEndTime: string | undefined;
  confirmedAt: string | undefined;
  user: User;
  doctor: Doctor;
  staff: Array<Staff>;
  clinic: Clinic;
  bookableResource: BookableResource;
};

export type ExternalSpecialistType =
  | "general_dentist"
  | "endodontist"
  | "periodontist"
  | "tmj_specialist"
  | "prosthodontist"
  | "oral_surgeon";

export type ExternalSpecialist = {
  id: string;
  userID: string;
  type: ExternalSpecialistType;
  lastVisit: string | undefined;
  verifiedSpecialist: VerifiedSpecialist;
  state: string | undefined;
  referral: Referral | undefined;
};

export type VerifiedSpecialist = {
  id: string;
  type: ExternalSpecialistType;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phone2: string;
  clinicName: string;
  address: Address;
  preferredProvider: boolean;
};

export type Eligibility = {
  id: string;
  insurancePolicyId: string;
  insuranceType: string;
  calendarYear: number;
  calendarYearRemaining: number;
  lifetimeMax: number;
  lifetimeMaxRemaining: number;
  percent: number;
  createdAt: string;
  npiUsed: string;
  InsuranceProvider: InsuranceProvider;
  coinsuranceDisclaimers: Array<string>;
  limitations: Array<string>;
};

export type InsuranceProvider = {
  id: string;
  name: string;
  externalPayerID: string | undefined;
  gateway: "onederful" | "manual" | "ability";
};

export type InsurancePolicy = {
  id: string;
  state: string;
  policyId: string;
  policyType: string;
  subscriberMemberId: string;
  subscriberGroupNumber: string | undefined;
  subscriberFirstName: string;
  subscriberLastName: string;
  subscriberDOB: string;
  dependentMemberId: string | null;
  dependentFirstName: string | null;
  dependentLastName: string | null;
  dependentDOB: string | null;
  InsuranceProvider: InsuranceProvider;
  Eligibilities: Eligibility[];
};

export type InsuranceClaimsSubmission = {
  id: string;
  orderID: string;
  insurancePolicyID: string;
  submittedAmount: number;
  expectedPayoutAmount: number | null;
  status: string;
  createdAt: string;
  dateSubmitted: string | null;
  insurancePolicy: InsurancePolicy;
  chargesTotal: number;
};

export type Shipment = {
  id: string;
  status: string;
  trackingNumber: string;
  carrier: string;
  deliveredAt?: string;
  externalShipmentId: string;
};

export type Kit = {
  id: string;
  userId: string;
  kitNumber: number;
  checkinTime?: string;
  createdAt: string;
  includes?: string[];
  shipment?: Shipment;
};

export type Address = {
  id?: string;
  firstName?: string;
  lastName?: string;
  line1: string;
  line2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  latitude: number | undefined;
  longitude: number | undefined;
};

export type UserAddress = {
  id: string;
  addressID: string;
  addressType: "shipping" | "billing" | "insurance_billing";
  isActive: boolean;
  Address: Address;
};

export type Product = {
  id: string;
  price: number;
  type: string;
  name: string;
  available: boolean;
  description: string;
};

export type PaymentMethod = {
  id: string;
  externalId: string;
  type: string;
  gateway: string;
  cardDetails: null | {
    id: string;
    brand: string;
    funding: string;
    country: string;
    expiryMonth: number;
    expiryYear: number;
    lastFour: string;
  };
};

export type OrderItem = {
  id: string;
  insuranceCode: string;
  description: string;
  product: Product;
};

export const PromoTypeFixedAmount = "fixed_amount";
export const PromoTypePercentageOff = "percentage_off";
export const PromoTypeEmployee = "employee";
export const PromoTypeInfluencer = "influencer";
export const PromoTypePaidInFull = "paid_in_full";
export const PromoTypeSameDay = "same_day";

export const PromotionTypeList = [
  PromoTypeFixedAmount,
  PromoTypePercentageOff,
  PromoTypeEmployee,
  PromoTypePaidInFull,
  PromoTypeSameDay,
  PromoTypeInfluencer,
];

export type PromotionType =
  | "fixed_amount"
  | "percentage_off"
  | "employee"
  | "influencer"
  | "paid_in_full"
  | "same_day";

export type Promotion = {
  id: string;
  type: PromotionType;
  amount: number;
  stackable: boolean;
  start: string;
  end?: string | null;
  code?: string | null;
  product?: Product;
};

export const PromoUserTypeEmployee = "employee";
export const PromoUserTypeInfluencer = "influencer";

export const PromoUserTypeList = [
  PromoUserTypeEmployee,
  PromoUserTypeInfluencer,
];

export type PromoUserType = "employee" | "influencer";

export type PromoUser = {
  id: string;
  email: string;
  type: string;
};

export type Discount = {
  id: string;
  promotion: Promotion;
  createdAt: string;
  deletedAt?: string;
};

export type Charge = {
  id: string;
  externalId: string;
  amount: number;
  paymentMethod: PaymentMethod;
  createdAt: string;
};

export type Refund = {
  id: string;
  chargeId: string;
  amount: number;
  createdAt: string;
};

export type Adjustment = {
  id: string;
  orderID: string;
  amount: number;
  reason: string;
  createdAt: string;
  deletedAt?: string;
};

export type OrderStatus = "draft" | "open" | "closed" | "completed";

export type Order = {
  id: string;
  total: number;
  subtotal: number;
  balance: number;
  createdAt: string;
  user: { id: string };
  status: OrderStatus;
  orderItems: OrderItem[];
  discounts: Discount[];
  charges: Charge[];
  refunds: Refund[];
  adjustments: Adjustment[];
  claims: InsuranceClaimsSubmission[];
  interests: Interest[];
};

export type Interest = {
  id: string;
  amount: number;
};

export type Referral = {
  id: string;
  userID: string;
  treatmentID: string;
  authorID: string;
  formSubmissionID: string;
  isHoldingTreatment: boolean;
  patientNeedsRescanAfter: boolean;
  referralType: string;
  referralReason: string | null;
  detailedReferralReason: string;
  completeProceduresBy: string;
  outcome: string | null;
  completedAt: string | null;
};

export type TreatmentHoldPipeline = {
  id: string;
  type: string;
  dueAt?: string;
};

export type HoldForTreatment = {
  id: string;
  category: string;
  description?: string;
  createdAt: string;
  completedAt?: string;

  pipeline: TreatmentHoldPipeline;
};

export type Treatment = {
  id: string;
  name: string;
  treatmentType: string;
  dismissedById: string;
  dismissalReason: string;
  beginningDoctor: {
    name: string;
  };
  beginningClinic: Clinic;
  clinic: Clinic;
  startDate: string;
  endDate: string;
  currentTrayNumber: number;
  activeOption: TreatmentOption;
  treatmentCycles: Array<TreatmentCycle>;
  onHoldCategory?: HoldCategory;
  onHoldReason?: string;
  onHoldDate?: string;
  referrals: Array<Referral>;

  holds: Array<HoldForTreatment>;
};

export type TreatmentCycle = {
  id: string;
  endType: string | undefined;
  startsAt: number;
  maxCount: number;
  phases: Array<Phase>;
  cadence: number;
  impressTreatmentPlanID: number;
};

export type TreatmentOption = {
  id: string;
  isActive: boolean;
  estimatedUpperBound: number;
};

export type Clinic = {
  id: string;
  name: string;
  nickname: string;
  timezone: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
  BookableResources: Array<BookableResource>;
  Staff: Array<Staff>;
};

export type Phase = {
  id: string;
  phaseType: string;
  upperCount: number;
  lowerCount: number;
  startsAt: number;
  appointmentSubtypeID: string | null;
  appointmentSubtype: {
    id: string;
    name: string;
  };
};

export type WearTimelineStage = {
  id: string;
  expectedStartDate: string;
  expectedEndDate: string;
  isTemplate: boolean;
  stage: number;
  isWearable: boolean;
};

export type ArchType = "upper" | "lower" | "both";

export type TrayBuild = {
  id: string;
  serialNumber: string;
  archType: ArchType;
};

export type TrayLog = {
  id: string;
  userID: string;
  treatmentCycleID: string;
  archType: ArchType;
  trayBuildID: string;
  trayBuild: TrayBuild | undefined;
  startTime: string;
  endTime?: string;
  stage: number;
  nextStage?: number;
  plannedEndTime: string;

  photos: Array<{
    id: string;
    file: {
      id: string;
      filename: string;
      type: string;
    };
  }>;
};

export type Patient = {
  id: string;
  impressID: string;
  firstName: string;
  lastName: string;
  name: string;
  preferredName: string;
  pronouns: string;
  dateOfBirth: string;
  email: string;
  mobilePhoneNumber: string;
  isSpam: boolean;
  spamReason: string;
  homeClinicID: string;
  avatarURL?: string;
  statuses: PatientStatus[];
  homeClinic: Clinic | undefined;
  currentAppVersion: string | undefined;
  appointments: Appointment[];
};

export type PatientStatus = {
  id: string;
  userID: string;
  name: string;
  start: string;
};

export type WrappedPatientStatus = {
  name: string;
  transitions: string[];
};

export type Features = {
  billing: boolean;
  consents: boolean;
  scheduling: boolean;
  manufacturing: boolean;
};

export type Organization = {
  id: string;
  name: string;
  features: Features;
};

export type UserRole = {
  role: string;
  organization: Organization;
};

export type Staff = {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  mobilePhoneNumber: string;
  email: string;
  roles: Array<UserRole>;
  clinics: Array<Clinic>;
  defaultClinic: Clinic | null;
  organizations: Array<Organization>;
};

export type AuditLog = {
  id: string;
  staffId: string | null;
  patientId: string | null;
  requestId: string | null;
  method: string | null;
  url: string | null;
  job: string | null;
  service: string | null;
  arguments: string | null;

  createdAt: string;
  updatedAt: string;
};

export type HoldCategory =
  | "unpaid"
  | "deposit_only"
  | "general_dentist_clearance"
  | "specialist"
  | "observation"
  | "treatment_terminated"
  | "not_eligible"
  | "external_provider"
  | "rescan"
  | "other";

export type AddProduct = {
  id: string;
  quantity: number;
};

export type DismissalReason =
  | "patient_led"
  | "practice_led"
  | "willful_lack_of_communication";

export type PageResults = {
  page: number;
  perPage: number;
  totalItems: number;
  totalPages: number;
};

export type EmailExternalSpecialistEvent = {
  externalSpecialistID: string;
  fileIDs: Array<string>;
};

export type EventData = EmailExternalSpecialistEvent;

export type Event = {
  id: string;
  userID: string;
  name: string;
  version: string;
  authorID: string;
  parentID: string | undefined;
  createdAt: string;
  eventData: EventData;
};

export type File = {
  id: string;
  userID: string;
  authorID: string;
  filename: string;
  type: string;
  subtype: string; // too many options here OTL
  description: string;
  size: number;
  internal: boolean;
  createdAt: string;
  emailEvents: Array<Event>;
};

export type DeleteRefund = {
  id: string;
  type: string;
};
