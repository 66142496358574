import { PageResults, PatientStatus } from "src/types/gql";
import {
  useMutation,
  useQuery,
  UseQueryResponse,
} from "src/utils/http/gqlQuery";
import useSelf from "src/state/self";
import {
  Pipeline,
  PipelineFilter,
  SnoozeTaskInput,
  Task,
  TaskFilter,
  UpdateTask,
  Clinic,
  AddPipelineCustomTag,
  SidebarCountersResponse,
  FiltersListResponse,
  CreateFilterRequest,
  UpdateFilterRequest,
} from "./types";

export const useTasks = (
  filter: TaskFilter,
  page,
  perPage
): UseQueryResponse<{
  tasks: {
    pageResults: PageResults;
    tasks: Task[];
  };
}> => {
  return useQuery<{
    tasks: {
      pageResults: PageResults;
      tasks: Task[];
    };
  }>({
    query: useTasksQuery,
    variables: { filter, page, perPage },
  });
};

export const usePipelines = (
  filter: PipelineFilter,
  page,
  perPage
): UseQueryResponse<{
  pipelines: {
    pageResults: PageResults;
    pipelines: Pipeline[];
  };
}> => {
  return useQuery<{
    pipelines: {
      pageResults: PageResults;
      pipelines: Pipeline[];
    };
  }>({
    query: usePipelinesQuery,
    variables: { filter, page, perPage },
  });
};

// Returns a single pipeline only
export const usePipeline = (
  id: string,
  paused?: boolean
): UseQueryResponse<{
  pipeline: Pipeline;
}> => {
  return useQuery<{
    pipeline: Pipeline;
  }>({
    query: usePipelineQuery,
    variables: { id },
    pause: paused,
  });
};

export const useTask = (
  id: string
): UseQueryResponse<{
  task: Task;
}> => {
  return useQuery<{ task: Task }>({ query: useTaskQuery, variables: { id } });
};

export const useUpdateTasks = () => {
  const [, execute] = useMutation<
    { tasks: Task[] },
    { input: UpdateTask[]; deleteFields: string[] }
  >(updateQuery);
  return execute;
};

export const useDeleteTask = () => {
  const [, execute] = useMutation<any, { id: string }>(deleteTaskQuery);
  return execute;
};

export const useAddPipelineCustomTag = () => {
  const [, execute] = useMutation<Pipeline, { input: AddPipelineCustomTag }>(
    addPipelineCustomTagQuery
  );
  return execute;
};

export const useDeletePipeline = () => {
  const [, execute] = useMutation<any, { id: string }>(deletePipelineQuery);
  return execute;
};

export const useSnoozeTask = () => {
  const [, execute] = useMutation<{ input: SnoozeTaskInput }>(snoozeTaskQuery);
  return execute;
};

export const useCurrentPatientStatuses = (
  userIDs: Array<string>
): UseQueryResponse<{ currentPatientStatuses: Array<PatientStatus> }> => {
  return useQuery<{ currentPatientStatuses: Array<PatientStatus> }>({
    query: сurrentPatientStatusesQuery,
    variables: { userIDs },
  });
};

export const useClinics = () => {
  return useQuery<{
    clinics: Clinic[];
  }>({
    query: clinicsQuery,
  });
};

export const useCounters = () => {
  const { id } = useSelf();

  return useQuery<SidebarCountersResponse>({
    query: sidebarCountersQuery,
    variables: {
      id,
    },
  });
};

export const useFiltersList = () => {
  return useQuery<FiltersListResponse>({ query: filtersListQuery });
};

export const useCreateFilter = () => {
  const [, execute] = useMutation<{ input: CreateFilterRequest }>(
    createFilterMutation
  );

  return execute;
};

export const useUpdateFilter = () => {
  const [, execute] = useMutation<{ input: UpdateFilterRequest }>(
    updateFilterMutation
  );

  return execute;
};

export const taskFields = `
  id
  name
  description
  dueAt
  pipelineDueAt
  priority
  customLabels
  startedAt
  completedAt
  pipeline {
    createdAt
  }
  completedBy {
    id
    name
    firstName
    lastName
    email
  }
  createdAt
  user {
    id
    name
    firstName
    lastName
    email
    homeClinicID
    homeClinic { 
      id 
      name 
    }
  }
  taskType {
    id
    name
    category
    description
  }
  assignedTo {
    id
    name
    firstName
    lastName
    email
  }
  references {
    id
    model {
      __typename
      ... on Chat {
        id body createdAt
      }
      ... on Submission {
        id
        createdAt
        submitterID
        submittedAt
        approverID
        approvedAt
        form {
          id
          name
          slug
        }
      }
      ... on File {
        id
      }
      ... on Kit {
        id
        createdAt
      }
      ... on TreatmentCycle {
        id
      }
      ... on ManufacturingBuild {
        id
        createdAt
        producer {
          source
        }
      }
      ... on InternalNote {
        id
        text
        createdAt
      }
    }
  }
`;

const filtersFields = `
  id
  customName
  patientHomeClinicIDs
  treatmentTypes
  pipelineTypes
  taskTypeCategories
  taskTypeNames
  taskCustomLabels
  taskAssignees {
    assigneeIDs
    unassigned
  }
  sortOpts {
    sortField
    sortDescending
  }
  shareInvites {
    id
    sharerID
    shareeID
    sharerTaskFilterSettingID
    createdAt
  }
`;

const useTaskQuery = `
query Task($id: ID!) {
  task(id: $id) {
    ${taskFields}

    pipeline {
      id
      createdAt
    }
  }
}
`;

const useTasksQuery = `
query Tasks($filter: TaskFilter!, $page: Int, $perPage: Int) {
  tasks(filter: $filter) @paginated(page: $page, perPage: $perPage) {
    pageResults {
      page
      perPage
      totalItems
      totalPages
    }
    tasks {
      ${taskFields}

      pipeline {
        id
        onHoldCategory
        onHoldReason
        type
        createdAt

        treatment {
          id
          treatmentType
        }
      }
    }
  }
}
`;

const pipelineFields = `
  id
  userID
  type
  createdAt
  customTags
  dueAt
  onHoldReasonV2
  onHoldReason
  onHoldCategory
  taskCounts {
    remaining
    started
    completed
  }
  unblockedTasks {
    ${taskFields}
  }
  user {
    id name homeClinicID email mobilePhoneNumber
    homeClinic { id name }
  }
`;

const usePipelinesQuery = `
query Pipelines($filter: PipelineFilter!, $page: Int, $perPage: Int) {
  pipelines(filter: $filter) @paginated(page: $page, perPage: $perPage) {
    pageResults {
      page
      perPage
      totalItems
      totalPages
    }
    pipelines {
      ${pipelineFields}
    }
  }
}
`;

const usePipelineQuery = `
query Pipeline($id: ID!) {
  pipeline(id: $id) {
    ${pipelineFields}
    tasks {
      ${taskFields}
    }
  }
}
`;

const updateQuery = `
mutation UpdateTasks($input: [UpdateTask!]!, $deleteFields: [String]) {
  tasks: updateTasks(updateTasks: $input) @deleteFields(fields: $deleteFields) {
    id
    name
    description
    dueAt
    pipelineDueAt
    priority
    customLabels
    startedAt
    completedAt
    user {
      id
      name
      email
    }
    taskType {
      id
      name
      category
      description
    }
    assignedTo {
      id
      name
      email
    }
  }
}
`;

const snoozeTaskQuery = `
mutation SnoozeTask($input: SnoozeTaskInput!) {
  snoozeTask(input: $input) {
    id
  }
}
`;

const deleteTaskQuery = `
mutation DeleteTask($id: ID!) {
  deleteTask(id: $id) {
    ids
  }
}
`;

const deletePipelineQuery = `
mutation DeletePipeline($id: ID!) {
  deletePipeline(id: $id) {
    ids
  }
}
`;

const addPipelineCustomTagQuery = `
mutation AddPipelineCustomTag($input: AddPipelineCustomTagInput!) {
  addPipelineCustomTag(input: $input) {
    ${pipelineFields}
  }
}
`;

const сurrentPatientStatusesQuery = `
query CurrentPatientStatuses($userIDs: [ID!]!) {
  currentPatientStatuses(userIDs: $userIDs) {
    id
    userID
    name
    start
  }
}
`;

const clinicsQuery = `
query Clinics {
  clinics {
    id
    name
    type
    state
  }
}
`;

const sidebarCountersQuery = `
query Counters($id:ID!) {
  tasksUser: tasks(filter: {
      assignees: {
        assigneeIDs: [$id],
        unassigned: false
      },
      status: "unblocked"
  }) { ...TasksCounter }
  
  tasksAll: tasks(filter: {
    status:"unblocked"
  }) { ...TasksCounter }
  
  tasksCreatedByUser: tasks(filter: {
    authorID: $id
    status: "incomplete"
  }) { ...TasksCounter }
  
  tasksSnoozed: tasks(filter: {
    assignees: {
      assigneeIDs: [$id]
      unassigned: false
    }
    status: "unblocked"
    snoozed: true
  }) { ...TasksCounter }
  
  tasksCompleted: tasks(filter: {
    assignees: {
      assigneeIDs: [$id]
      unassigned: false
    }
    status: "complete"
  }) { ...TasksCounter }
  
  pipelinesUser: pipelines(filter: {
    assigneeIDs: [$id],
    status: "incomplete"
  }) { ...PipelinesCounter }
  
  pipelinesAll: pipelines(filter: {
    status: "incomplete"
  }) { ...PipelinesCounter }
  
  pipelinesCompleted: pipelines(filter: {
    status: "complete"
  }) { ...PipelinesCounter }
}

fragment TasksCounter on TasksResponse {
  pageResults {
    totalItems
  }
}

fragment PipelinesCounter on PipelinesResponse {
  pageResults {
    totalItems
  }
}
`;

const filtersListQuery = `
query FiltersList {
  taskFilterSettings {
    ${filtersFields}
  } 
}
`;

export const createFilterMutation = `
mutation CreateFilter($input: CreateTaskFilterSettingInput!) {
  createTaskFilterSetting(input: $input) {
    ${filtersFields}
  }
}
`;

export const updateFilterMutation = `
mutation UpdateFilter($input: UpdateTaskFilterSettingInput!) {
  updateTaskFilterSetting(input: $input) {
    ${filtersFields}
  }
}
`;
