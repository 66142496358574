import styled from "react-emotion";
import color from "src/styles/color";

export const StyledUserButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${color.teal};
  border-radius: 50%;
  width: 32px;
  height: 32px;
`;

export const Initials = styled.div`
  color: ${color.white};
  font-style: normal;
  font-weight: 550;
  font-size: 12px;
  line-height: 16px;
`;
