import { pipe, tap } from "wonka";
import { browserHistory } from "react-router";

import { OperationResult } from "src/utils/http/gqlQuery";

const errorMessages = ["Invalid token", "No UUID found", "not authenticated"];

const redirectToLogin = errorMessage => {
  const shouldRedirect = errorMessages.find(m => errorMessage.includes(m));

  if (shouldRedirect) {
    const { pathname, search } = window.location;
    browserHistory.push({
      pathname: "/login",
      search: pathname.includes("/login")
        ? search
        : `?redirect=${encodeURIComponent(pathname)}`,
    });
  }
};

const logoutExchange = ({ client, forward }) => {
  return operations$ => {
    return pipe(
      forward(operations$),
      tap(op => {
        const o = op as OperationResult<any>;
        // TODO: With RBAC we'll need to define some way to detect unauthorized vs logged out
        o.error && console.info(o.error.message);
        if (!!o.error) {
          redirectToLogin(o.error.message);
        }
      })
    );
  };
};

export default logoutExchange;
