import { DateTime, Duration } from "luxon";
import { EmptySlot } from "src/types/api";
import { AppointmentType } from "src/types/gql";

export type AppointmentTemplate = {
  id: string;
  group: string;
  doctorOffset: number;
  doctorId: string;
  appointmentType: AppointmentType;
  bookableResource: { id: string };
  clinic: { id: string };
};

// NewAppointment is used when opening a specific time.
export type NewAppointment = {
  doctorId: string;
  bookableResourceId: string;
  appointmentTypeId: string;
  // RFC3339 dates
  doctorStartTime: string;
  doctorEndTime: string;
};

export const slotToTemplate = (name: string, e: EmptySlot, tz: string) => {
  // The doctor start time is the slot offset plus the largest patient buffer.
  const doctorOffset = Duration.fromObject({
    seconds: e.offset + e.appointmentType.largestPatientBuffer,
  }).as("seconds");

  return {
    group: name,
    doctorOffset,
    bookableResourceId: e.bookableResourceId,
    appointmentTypeId: e.appointmentType.id,
  };
};

export const slotToAppointment = (e: EmptySlot, tz: string): NewAppointment => {
  // The doctor start time is the slot offset plus the largest patient buffer.
  const date = e.date.setZone(tz);
  const drOffset = Duration.fromObject({
    seconds: e.offset + e.appointmentType.largestPatientBuffer,
  });

  return {
    doctorId: e.doctorId,
    doctorStartTime: date
      .plus(drOffset)
      .toUTC()
      .toISO(),
    doctorEndTime: date
      .plus(drOffset)
      .plus(Duration.fromObject({ seconds: e.appointmentType.doctorDuration }))
      .toUTC()
      .toISO(),
    bookableResourceId: e.bookableResourceId,
    appointmentTypeId: e.appointmentType.id,
  };
};

export const templateToSlot = (
  t: AppointmentTemplate,
  date: DateTime
): EmptySlot => ({
  date,
  clientId: t.id,
  bookableResourceId: t.bookableResource.id,
  doctorId: t.doctorId,
  appointmentType: t.appointmentType,
  // The offset is the start of the doctor time minus the largest patient buffer,
  // as a duration.
  offset: t.doctorOffset - t.appointmentType.largestPatientBuffer,
});
