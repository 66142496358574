import React from "react";
import styled from "react-emotion";
import color from "src/styles/color";

const PlusMinusButtonStyle = styled.button`
  background: none;
  border: none;
  padding: 0;
  align-self: center;

  font-weight: bold;
  color: white;

  &:hover {
    color: ${color.blue};
    cursor: pointer;
  }
`;

type Props = {
  onClick: () => void;
  text: string;
};

const PlusMinusButton = (props: Props) => (
  <PlusMinusButtonStyle
    type="button"
    onClick={() => {
      props.onClick();
    }}
  >
    {props.text}
  </PlusMinusButtonStyle>
);

export default PlusMinusButton;
