import styled, { css } from "react-emotion";
import color from "src/styles/color";

export const ButtonStyle = css`
  display: inline-block;
`;

export const headerItemStyle = css`
  flex: 1;
`;

export const bodyItemStyle = css`
  flex: 1;
  white-space: normal;
`;

export const bodyRowStyle = css`
  min-height: 60px;
  height: auto;
`;

export const NewStatus = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
`;

export const NewStatusSelects = styled.div`
  display: flex;
  flex-flow: row;

  > div {
    margin-right: 20px;
  }
`;

export const Container = styled.div`
  width: 600px;
  height: 400px;
  display: flex;
  flex-flow: column;
`;

export const Content = styled.div`
  flex: 1;
  overflow: scroll;

  display: flex;
  flex-flow: column;
  padding: 40px;
`;

export const Footer = styled.div`
  padding: 20px 40px;
  border-top: 1px solid ${color.border};
`;

export const addNewButton = css`
  width: 150px;
  margin-bottom: 20px;
  min-height: 30px;
`;
