import React from "react";
import Mapbox from "src/shared/Mapbox";
import { DetailsContainer } from "../styles";
import SpecialistDetails from "./SpecialistDetails";

const DEFAULT_ZOOM = 13;

const RightPanel = ({
  mapView,
  selectedSpecialist,
  mode,
  setMode,
  search,
  setSelectedSpecialistID,
  defaultLat,
  defaultLong,
  specialistsWithLatLong,
}) => {
  if (mapView) {
    return (
      <Mapbox
        specialists={specialistsWithLatLong}
        setSelectedSpecialistID={setSelectedSpecialistID}
        defaultLat={defaultLat}
        defaultLong={defaultLong}
        defaultZoom={DEFAULT_ZOOM}
        selectedSpecialistID={selectedSpecialist && selectedSpecialist.id}
      />
    );
  }

  if (selectedSpecialist || mode === "add")
    return (
      <SpecialistDetails
        specialist={selectedSpecialist}
        mode={mode}
        setMode={setMode}
        search={search}
        setSelectedSpecialistID={setSelectedSpecialistID}
      />
    );

  return <DetailsContainer />;
};

export default RightPanel;
