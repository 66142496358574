import React from "react";
import { CrownProps, RootProps } from "./types";

export const Crown: React.SFC<CrownProps> = ({ fill, stroke, style }) => (
  <svg
    width="20"
    height="28"
    viewBox="0 0 20 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M9.04698 1.7506C4.95034 1.45365 2.46309 7.6895 1.73154 10.8446C1.36577 12.5149 0.999993 16.9592 1 21.4232C1.00001 25.6918 5.99888 26.8672 8.31544 26.8054H14.3507C17.8621 26.8054 18.618 23.2173 18.557 21.4232C18.4107 15.7813 17.5207 12.02 17.094 10.8446C16.1186 7.93696 13.1436 2.04754 9.04698 1.7506Z"
      fill={fill || "#fff"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />
  </svg>
);

// Root always contains the portion as if it attached as a Crown.
export const Root: React.SFC<RootProps> = ({
  hasRootCanal,
  style,
  fill,
  stroke,
}) => (
  <svg
    width="17"
    height="53"
    viewBox="0 0 17 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M3.53415 17.9055C3.53415 31.824 2.88439 42.4826 2.5 47H7V52H12V47H16C13.3093 15.5003 11.2219 12.7776 9.68438 9.29803C8.14682 5.81841 6.41707 3.80389 3.53415 1.60624C0.65124 -0.591415 0.459046 3.62075 1.80441 7.28351C2.88069 10.2137 3.40602 15.5857 3.53415 17.9055Z"
      fill={fill || "#F1F4F5"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />

    {hasRootCanal && (
      <g transform="translate(3,1)">
        <path
          d="M0 0C6 15.5 4.66667 36.5 4 42L8.5 43C7.7 26.2 4 8 0 0Z"
          fill="#C270A7"
        />
      </g>
    )}
  </svg>
);
