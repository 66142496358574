import React from "react";

const GeneralDentistSvg = () => (
  <svg
    width="112"
    height="112"
    viewBox="0 0 112 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.2002 60.6666V109.2H100.8V60.6666H11.2002Z" fill="#D7D7D7" />
    <path d="M54.1328 86.8H87.7328V101.733H54.1328V86.8Z" fill="white" />
    <path d="M22.3994 86.8H41.0661V109.2H22.3994V86.8Z" fill="white" />
    <path
      d="M97.0667 32.6666L108.267 55.0666H3.7334L14.9334 32.6666H97.0667Z"
      fill="#CF5D32"
    />
    <path d="M108.267 55.0667V62.5333H3.7334V55.0667H108.267Z" fill="#FFE7DE" />
    <path
      d="M93.3327 62.5333V66.2667C93.3327 70.3901 89.9895 73.7333 85.8661 73.7333C81.7426 73.7333 78.3994 70.3901 78.3994 66.2667V62.5333H93.3327Z"
      fill="white"
    />
    <path
      d="M63.4665 62.5333V66.2667C63.4665 70.3901 60.1233 73.7333 55.9999 73.7333C51.8764 73.7333 48.5332 70.3901 48.5332 66.2667V62.5333H63.4665Z"
      fill="white"
    />
    <path
      d="M33.5993 62.5333V66.2667C33.5993 70.3901 30.2561 73.7333 26.1327 73.7333C22.0092 73.7333 18.666 70.3901 18.666 66.2667V62.5333H33.5993Z"
      fill="white"
    />
    <path
      d="M108.267 62.5333V66.2667C108.267 70.3901 104.924 73.7333 100.801 73.7333C96.6772 73.7333 93.334 70.3901 93.334 66.2667V62.5333H108.267Z"
      fill="#FD8354"
    />
    <path
      d="M78.4001 62.5333V66.2667C78.4001 70.3901 75.0569 73.7333 70.9335 73.7333C66.81 73.7333 63.4668 70.3901 63.4668 66.2667V62.5333H78.4001Z"
      fill="#FD8354"
    />
    <path
      d="M48.5339 62.5333V66.2667C48.5339 70.3901 45.1907 73.7333 41.0673 73.7333C36.9438 73.7333 33.6006 70.3901 33.6006 66.2667V62.5333H48.5339Z"
      fill="#FD8354"
    />
    <path
      d="M18.6667 62.5333V66.2667C18.6667 70.3901 15.3235 73.7333 11.2001 73.7333C7.07663 73.7333 3.7334 70.3901 3.7334 66.2667V62.5333H18.6667Z"
      fill="#FD8354"
    />
    <path
      d="M24.2666 0.93335H87.7333V49.4667H24.2666V0.93335Z"
      fill="#FC5F22"
    />
    <path
      d="M53.9084 10.1363C55.1644 11.0131 56.8342 11.0131 58.0902 10.1363C60.11 8.6761 62.6357 8.09641 65.0902 8.53027C68.9739 9.23482 72.0018 12.2937 72.669 16.1838C73.0126 18.1954 72.6781 20.2644 71.7165 22.0636C69.7204 25.7313 68.4535 29.7499 67.9832 33.8989L67.5357 38.4717C67.2631 40.4915 65.4894 41.9653 63.4541 41.8632C61.4189 41.7611 59.801 40.1169 59.7327 38.0797V32.6666C59.7172 30.6113 58.0547 28.9488 55.9993 28.9333C53.9403 28.9396 52.2724 30.6076 52.266 32.6666V38.0797C52.1976 40.1169 50.5798 41.7611 48.5445 41.8632C46.5092 41.9653 44.7355 40.4915 44.463 38.4717L44.0155 33.8989C43.5452 29.7499 42.2782 25.7313 40.2821 22.0636C39.3206 20.2644 38.9861 18.1954 39.3297 16.1838C39.9969 12.2937 43.0247 9.23482 46.9084 8.53027C49.363 8.09641 51.8887 8.6761 53.9084 10.1363Z"
      fill="#E6E7E8"
    />
    <path d="M0 107.333H112V111.067H0V107.333Z" fill="#A2A9AD" />
    <path
      d="M56.0005 17.7333C53.813 17.7561 51.6447 17.3186 49.6367 16.449L51.1716 13.0457C54.2715 14.2934 57.735 14.2934 60.8349 13.0457L62.3661 16.449C60.3573 17.3186 58.1889 17.7561 56.0005 17.7333Z"
      fill="white"
    />
  </svg>
);

export default GeneralDentistSvg;
