import React from "react";

export default () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8436 3.31237C11.7395 3.06249 11.6144 2.86451 11.4685 2.71871L9.28101 0.531188C9.1353 0.385475 8.93732 0.260422 8.68733 0.156193C8.43747 0.0520735 8.20824 0 7.99995 0H0.749935C0.541668 0 0.364542 0.0728701 0.218692 0.21872C0.0728427 0.364487 0 0.541586 0 0.749962V11.25C0 11.4584 0.0728427 11.6355 0.218692 11.7813C0.364542 11.927 0.541668 12 0.749935 12H11.25C11.4584 12 11.6355 11.927 11.7813 11.7813C11.927 11.6355 11.9998 11.4584 11.9998 11.25V4C11.9998 3.7916 11.9478 3.56245 11.8436 3.31237ZM4.99999 1.2499C4.99999 1.1822 5.02475 1.12373 5.07425 1.07414C5.1237 1.02478 5.18231 1.00004 5.25001 1.00004H6.75007C6.81761 1.00004 6.87619 1.02472 6.92567 1.07414C6.97528 1.12373 7.00001 1.1822 7.00001 1.2499V3.74992C7.00001 3.81773 6.97511 3.87629 6.92567 3.92579C6.87619 3.97513 6.81761 3.99989 6.75007 3.99989H5.25001C5.18231 3.99989 5.12367 3.97521 5.07425 3.92579C5.02475 3.87621 4.99999 3.81773 4.99999 3.74992V1.2499ZM9.00007 11H2.9999V7.99997H9.00007V11ZM11.0002 11H10H9.99981V7.74995C9.99981 7.54152 9.92694 7.36453 9.78114 7.21862C9.63535 7.07283 9.45825 6.99988 9.25001 6.99988H2.74994C2.54159 6.99988 2.36446 7.07283 2.21861 7.21862C2.07284 7.36442 1.99992 7.54152 1.99992 7.74995V11H0.999904V0.999932H1.99989V4.24997C1.99989 4.45835 2.07273 4.63536 2.21858 4.78121C2.36443 4.92698 2.54156 4.99993 2.74991 4.99993H7.25007C7.4583 4.99993 7.6356 4.92698 7.7812 4.78121C7.92691 4.63544 7.99995 4.45835 7.99995 4.24997V0.999932C8.07804 0.999932 8.17954 1.02593 8.30467 1.078C8.42978 1.13005 8.51827 1.18212 8.57035 1.23419L10.7658 3.4296C10.8179 3.48167 10.87 3.57162 10.922 3.69916C10.9742 3.82681 11.0002 3.9271 11.0002 4V11H11.0002Z"
      fill="#A2A9AD"
    />
  </svg>
);
