export const holdPipelineTypes = [
  "general_dentist_consult",
  "specialist_consult",
  "observation",
  "treatment_terminated",
  "external_provider",
  "rescan",
  "other",
];

export const holdCategories = [
  "observation",
  "treatment_terminated",
  "external_provider",
  "rescan",
  "other",
];
