const LOAD = "serverConfig/LOAD";
const LOAD_SUCCESS = "serverConfig/LOAD_SUCCESS";
const LOAD_FAIL = "serverConfig/LOAD_FAIL";

const SERVER_CONFIG_API = "/api/v1/configs";

const initialState = {
  data: {},
};

const MOCK_CONFIG = {
  statuses: [
    {
      id: 1234,
      name: "registration_start",
    },
  ],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        data: action.result,
        error: null,
      };
    case LOAD_FAIL:
      return {
        ...state,
        data: MOCK_CONFIG,
        error: action.error,
      };

    default:
      return state;
  }
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => client.get(`${SERVER_CONFIG_API}`),
  };
}
