import React, { useState } from "react";
import { UploadFile } from "src/types/local";

import { Upload, Button, Icon } from "antd";

import superagent from "superagent";
import cookie from "cookie";

export const CLINICAL_PHOTO = "clinical_photo";
export const CHAT_UPLOAD = "chat_upload";

const getFileUploadUrl = userId => {
  return `${process.env.REACT_APP_API_HOST}/api/v1/patients/${userId}/files`;
};

interface Props {
  patientUUID: string;
  onFileUploaded(file: UploadFile): Function;
  onFileRemoved(file: UploadFile): Function;
  fileType?: string;
}

const FileUpload = (props: Props) => {
  const initialValue: any[] = [];
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState(initialValue);

  const beforeUpload = (file: UploadFile) => {
    fileList.push(file);
    setFileList(fileList);
    if (file.status !== "done") {
      uploadFile(file);
    }
    return false;
  };

  const onRemove = (file: UploadFile) => {
    const filteredFileList = fileList.filter(f => f !== file);
    // TODO call generic files api and remove the file
    setFileList(filteredFileList);
    props.onFileRemoved && props.onFileRemoved(file);
  };

  const uploadFile = (file: UploadFile) => {
    // upload file code only runs on client side
    const authToken = cookie.parse(document.cookie).auth_token;

    setUploading(true);

    superagent
      .post(getFileUploadUrl(props.patientUUID))
      .set("Authorization", authToken)
      .field("user_id", props.patientUUID)
      .field("uploader_id", "doctor-tool-uploader")
      .field("file_type", props.fileType || CLINICAL_PHOTO)
      .field("details", "")
      .attach("file", file)
      .end((err, res) => {
        if (err) {
        } else {
          const copy = fileList.map(f => {
            if (f.name !== file.name) {
              return f;
            }

            f.url = res.body.file.url;
            f.status = "done";
            return f;
          });

          setFileList(copy);
          setUploading(false);
          props.onFileUploaded && props.onFileUploaded(res.body);
        }
      });
  };

  return (
    <div>
      <Upload
        fileList={fileList}
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        multiple={true}
      >
        <Button>
          {uploading ? <Icon type="loading" /> : <Icon type="upload" />}
          {uploading ? "Uploading" : "Select Attachment"}
        </Button>
      </Upload>
    </div>
  );
};

export default FileUpload;
