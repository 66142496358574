import React, { useState } from "react";
import styled, { css } from "react-emotion";
import { notification } from "antd";

import Modal from "src/shared/Modal";
import color from "src/styles/color";
import Box from "src/shared/Box";
import Button, { ButtonRow } from "src/shared/Button";
import Check from "src/shared/Icons/Check";

import { useEmailExternalSpecialist, useGetExternalSpecialists } from "./query";

const buttonRow = css`
  padding: 16px 24px;
  border-top: 1px solid ${color.border};
`;

const Content = styled.div`
  height: 400px;
  width: 450px;

  display: flex;
  flex-flow: column;
  overflow: hidden;
`;

const ItemContainer = styled.button`
  background: none;
  border: none;

  display: flex;
  flex-flow: column;
  padding: 20px 25px;
  line-height: 20px;
  border-bottom: 1px solid ${color.border};

  &:hover {
    background-color: ${color.background};
    cursor: pointer;
  }
`;

const SpecialistsContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  overflow: auto;
`;

const Title = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const ExternalSpecialistItem = ({ specialist, isSelected, onClick }) => {
  return (
    <ItemContainer
      style={{ backgroundColor: isSelected ? color.background : undefined }}
      onClick={onClick}
    >
      <Title>
        <b>
          {specialist.firstName} {specialist.lastName}
        </b>
        {isSelected && (
          <div style={{ color: color.green }}>
            <Check fill={color.green} /> Selected
          </div>
        )}
      </Title>
      <div>{specialist.clinicName}</div>
      <div>{specialist.email}</div>
      <div>{specialist.address.line1}</div>
      {specialist.address.line2 && <div>{specialist.address.line2}</div>}
      <div>
        {specialist.address.city}, {specialist.address.state}{" "}
        {specialist.address.postalCode}
      </div>
    </ItemContainer>
  );
};

const EmailExternalSpecialist = ({ fileID, patientID, onClose }) => {
  const [selectedSpecialist, setSelectedSpecialist] = useState<string | null>(
    null
  );
  const [, , specialists] = useGetExternalSpecialists({
    userID: patientID,
  });
  const emailSpecialist = useEmailExternalSpecialist();

  const onEmail = async () => {
    if (!selectedSpecialist) return;

    const resp = await emailSpecialist({
      opts: {
        externalSpecialistID: selectedSpecialist,
        fileIDs: [fileID],
      },
    });

    if (resp.error) {
      notification.error({
        message: "Error sending email to External Specialist & Patient",
      });
      return;
    }

    notification.success({
      message: "Email sent to External Specialist & Patient!",
    });
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <Box title="Select external provider">
        <Content>
          <SpecialistsContainer>
            {specialists.map(specialist => (
              <ExternalSpecialistItem
                onClick={() => setSelectedSpecialist(specialist.id)}
                key={specialist.id}
                specialist={specialist.verifiedSpecialist}
                isSelected={selectedSpecialist === specialist.id}
              />
            ))}
          </SpecialistsContainer>
          <ButtonRow className={buttonRow} position="right">
            <Button kind="default" onClick={onClose}>
              Cancel
            </Button>
            <Button
              kind="primary"
              onClick={onEmail}
              disabled={!selectedSpecialist}
            >
              Email
            </Button>
          </ButtonRow>
        </Content>
      </Box>
    </Modal>
  );
};

export default EmailExternalSpecialist;
