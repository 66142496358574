import React, { useState } from "react";
import styled, { css } from "react-emotion";
import { message } from "antd";
import Button from "src/shared/Button";
import Popconfirm from "src/shared/Popconfirm";
import color from "src/styles/color";
import Check from "src/shared/Icons/Check";
import Cross from "src/shared/Icons/Cross";
import { Task, TaskOption } from "../../types";
import { usePipeline } from "../../queries";
import {
  useApproveReviewTreatmentPlan,
  useApproveTreatmentPlan,
  useRejectReviewTreatmentPlan,
  useRejectTreatmentPlan,
} from "./useTreatmentPlanActions";

type Props = {
  task: Task;
  pipelineID: string;
  onChange: (opt: TaskOption, deleteFields?: string[]) => Promise<any>;
};

const Actions: React.FC<Props> = ({ task, pipelineID, onChange }) => {
  const [, refetch] = usePipeline(pipelineID, true);
  const [saving, setSaving] = useState(false);
  const approveTreatmentPlan = useApproveTreatmentPlan();
  const approveReviewTreatmentPlan = useApproveReviewTreatmentPlan();
  const rejectTreatmentPlan = useRejectTreatmentPlan();
  const rejectReviewTreatmentPlan = useRejectReviewTreatmentPlan();

  const action = (fn: ({ taskID }) => any, approved: boolean) => {
    return async () => {
      if (saving) {
        return;
      }
      setSaving(true);

      const result = await fn({
        taskID: task.id,
      });

      if (result.error) {
        message.error(
          `Error ${approved ? "approving" : "rejecting"} treatment plan: ${
            result.error
          }`
        );
        setSaving(false);
        return;
      }

      setSaving(false);
      setCancelVisible(false);

      message.success(`Treatment plan ${approved ? "approved" : "rejected"}`);
      refetch();
    };
  };

  const [cancelVisible, setCancelVisible] = useState(false);

  if (task.completedAt) {
    return (
      <Popconfirm
        placement="bottomRight"
        title="Are you sure you want to uncomplete this task?"
        okText="Yes"
        cancelText="No"
        visible={cancelVisible}
        onCancel={() => setCancelVisible(false)}
        onConfirm={() => {
          refetch();
          onChange({}, ["CompletedAt"]);
          setCancelVisible(false);
        }}
        setVisible={(visible: boolean) => setCancelVisible(visible)}
      >
        <UncompleteButton
          onClick={() => {
            setCancelVisible(false);
          }}
        >
          <Check fill="#fff" style={{ marginTop: 2 }} />
          Complete
        </UncompleteButton>
      </Popconfirm>
    );
  }

  return (
    <>
      <RejectButton
        onClick={action(
          task.taskType.name === "approve_treatment_plan"
            ? rejectTreatmentPlan
            : task.taskType.name === "review_treatment_plan"
            ? rejectReviewTreatmentPlan
            : () => Promise.resolve(null),
          false
        )}
        disabled={saving}
      >
        <Cross size={20} fill={color.red} className={crossCSS} />
        {saving ? "Rejecting..." : "Reject"}
      </RejectButton>
      <CompleteButton
        disabled={saving}
        onClick={action(
          task.taskType.name === "approve_treatment_plan"
            ? approveTreatmentPlan
            : task.taskType.name === "review_treatment_plan"
            ? approveReviewTreatmentPlan
            : () => Promise.resolve(null),
          true
        )}
      >
        <Check fill={color.green} style={{ marginTop: 2 }} />
        {saving ? "Approving..." : "Approve"}
      </CompleteButton>
    </>
  );
};

export default Actions;

const UncompleteButton = styled(Button)`
  border-color: ${color.green};
  color: #fff;
  background: ${color.green};

  &:hover {
    border-color: ${color.green};
    color: #fff;
    background: ${color.green};
  }
`;

const RejectButton = styled(Button)`
  border-color: ${color.red};
  color: ${color.red};

  &:hover {
    background: #fff;
    border-color: ${color.red};
    color: ${color.red};
  }
`;

const CompleteButton = styled(Button)`
  border-color: ${color.green};
  color: ${color.green};

  &:hover {
    background: #fff;
    border-color: ${color.green};
    color: ${color.green};
  }
`;

const crossCSS = css`
  width: 18px !important;
  height: 20px !important;
  margin-right: 4px;
`;
