import React, { useEffect, useState } from "react";
import { Section, SectionTitle, Continue, Divider } from "./styledComponents";
import ToothChart from "src/shared/ToothChartV2/ToothChart";
import styled, { css } from "react-emotion";
import {
  attributesToV2,
  attributesToV1,
} from "src/shared/ToothChartV2/versioning";
import { renderTitlegroupQuestions } from "./consts";
import { generateFindingsSummary } from "../Summary/findingsSummary";

const ToothChartWrapper = styled.div``;

const ToothChartStyles = css`
  min-width: 600px;
  padding-right: 24px;
`;

const DentalFindings = props => {
  const { ui } = props;
  const isSubmitted = ui.submission && !!ui.submission.submittedAt;
  const [toothData, setToothData] = useState<any[] | false>(false);

  useEffect(() => {
    if (ui.submission) {
      setToothData(ui.submission.toothData || []);
    }
  }, [ui.submission]);

  const onToothChartUpdate = async tData => {
    if (tData === toothData) {
      return;
    }
    setToothData(tData);
    ui.saveToothData(tData);

    // Update the automated dental findings answer.
    const answer = generateFindingsSummary(ui.answerMap, tData);
    ui.saveAnswer(ui.form.questions["generated_findings_summary"], answer);
  };

  if (!ui.submission) {
    return null;
  }

  return (
    <>
      <Section>
        <SectionTitle>Tooth Chart</SectionTitle>

        <ToothChartWrapper>
          {toothData && (
            <ToothChart
              userID={props.patient.id}
              submissionID={ui.submission.id}
              className={ToothChartStyles}
              defaultDisplayType={"original"}
              availableDisplayTypes={["original", "performed"]}
              unsavedAttributes={attributesToV2(toothData || [])}
              disabled={isSubmitted}
              onChange={(a: any) => {
                onToothChartUpdate(attributesToV1(a));
              }}
            />
          )}
        </ToothChartWrapper>

        {renderTitlegroupQuestions(ui, "Tooth Chart")}
      </Section>

      <Divider />

      <Section>
        <SectionTitle>Dental findings summary</SectionTitle>
        {renderTitlegroupQuestions(ui, "Findings summary")}
      </Section>

      <Continue next={props.next} />
    </>
  );
};

export default DentalFindings;
