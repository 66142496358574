import React from "react";
import { DateTime } from "luxon";
import getTxStatus, { getTxEstimate } from "src/utils/getTreatmentStatus";
import { titleCase } from "src/shared/util";
import { Treatment, Patient } from "src/types/gql";
import { Popover } from "antd";
import {
  Perimeter,
  TxStatus,
  StatusText,
  StatusDate,
  OnHoldStatus,
} from "./styles";

type Props = {
  treatment: Treatment;
  timeline: Array<{ start: string | null; status: string }>;
  patient: Patient;
};

const TreatmentStatus: React.FC<Props> = ({ treatment, timeline, patient }) => {
  const txStatus = getTxStatus(treatment, timeline, patient);
  const txEstimate = getTxEstimate(treatment);

  const holds = (treatment ? treatment.holds : []).filter(h => !h.completedAt);

  return (
    <Perimeter>
      <TxStatus>
        <StatusDate>{txEstimate.timeText}</StatusDate>
        <StatusText>{txEstimate.estimateText}</StatusText>
      </TxStatus>
      <TxStatus>
        <StatusDate>{txStatus.dateText}</StatusDate>
        <StatusText>{txStatus.statusText}</StatusText>
      </TxStatus>
      {txStatus.convertedTx && (
        <TxStatus style={{ opacity: 0.7 }}>
          <StatusText>{txStatus.convertedTx}</StatusText>
        </TxStatus>
      )}
      {holds.length > 0 && (
        <Popover
          overlayStyle={{ backgroundColor: "white", color: "red" }}
          placement="topLeft"
          trigger={"hover"}
          content={
            <div>
              {holds
                .map(h => titleCase(h.category))
                .filter(Boolean)
                .join(", ")}
            </div>
          }
        >
          <OnHoldStatus>
            Pending Tasks: added{" "}
            {DateTime.fromISO(holds[0].createdAt).toRelative()}
          </OnHoldStatus>
        </Popover>
      )}
    </Perimeter>
  );
};

export default TreatmentStatus;
