import React from "react";
import { Task } from "../../types";
import ExternalSpecialists from "src/scenes/Patient/ExternalCare/ExternalSpecialists/ExternalSpecialists";

type Props = {
  task: Task;
};

const Content: React.FC<Props> = ({ task }) => (
  <div>
    <ExternalSpecialists
      patientID={task.user.id}
      boxDisplay
      TaskName={task.taskType.name}
    />
  </div>
);

export default Content;
