import { useEffect, useState } from "react";
import { useMethods } from "react-use";
import { WithRouterProps } from "react-router";

import useSelf from "src/state/self";

import {
  FiltersContextValue,
  FiltersProps,
  FiltersState,
  Filters,
} from "./types";
import {
  filtersStateReducer,
  computeRouteFilters,
  mergeFilters,
  prepareFiltersResponse,
  getSelectedFilter,
  mapFiltersValueToCreateRequest,
  mapFiltersValueToUpdateRequest,
} from "./utils";
import { initialState } from "./constants";
import {
  useFiltersList,
  useCreateFilter,
  useUpdateFilter,
} from "../../queries";

export const useFiltersContextValue = (
  props: FiltersProps
): FiltersContextValue => {
  const { params } = props;

  const [state, methods] = useMethods<
    ReturnType<typeof filtersStateReducer>,
    FiltersState
  >(filtersStateReducer, initialState);
  const routeFilters = useRouteFilters(params.filter);
  const allFilters = useAllFilters();
  const createFilter = useCreateNewFilter(state.filterPanelFilters);
  const replaceFilter = useReplaceFilter(state.filterPanelFilters);
  const renameFilter = useRenameFilter();

  const selectedFilter = getSelectedFilter(state, allFilters.data);

  const finalFilters = mergeFilters(
    selectedFilter.filters,
    state.tableHeaderFilters,
    routeFilters
  );

  return {
    allFilters: allFilters.data,
    appliedFilters: finalFilters,
    hasUnsavedFilter: state.hasUnsavedFilter,

    setFilterPanelFilters: methods.setFilterPanelFilters,
    setTableHeaderFilters: methods.setTableHeaderFilters,
    setSelectedFilter: methods.selectFilter,
    resetFilterPanelFilters: methods.resetFilterPanelFilters,
    getSelectedFilter: getSelectedFilter.bind(null, state, allFilters.data),

    createFilter,
    replaceFilter,
    renameFilter,
  };
};

const useRouteFilters = (routeFilter?: string) => {
  const self = useSelf();

  return computeRouteFilters(self.id, routeFilter);
};

const useAllFilters = () => {
  const [{ data, fetching, error }] = useFiltersList();
  const preparedData = prepareFiltersResponse(data);

  return {
    data: preparedData,
    isLoading: fetching,
    isError: error,
  };
};

export const useUnsavedChangesWarning = (
  props: FiltersProps,
  value: FiltersContextValue
) => {
  const { router } = props;
  const {
    isUnsavedFilterConfirmOpen,
    requestedLocation,
    closeUnsavedFilterConfirm,
    resetRequestedLocation,
  } = useUnsavedChangesWarningOnRouteChange(props, value);

  useUnsavedChangesWarningOnPageClose(value);

  const handleConfirm = () => {
    closeUnsavedFilterConfirm();
    value.resetFilterPanelFilters();

    if (requestedLocation) {
      router.push(requestedLocation);
    }
  };
  const handleClose = () => {
    closeUnsavedFilterConfirm();
    resetRequestedLocation();
  };

  return {
    isUnsavedFilterConfirmOpen,
    handleConfirm,
    handleClose,
  };
};

const useUnsavedChangesWarningOnRouteChange = (
  props: FiltersProps,
  value: FiltersContextValue
) => {
  const { router, location, route } = props;
  const [requestedLocation, setRequestedLocation] = useState<
    WithRouterProps["location"] | null
  >(null);
  const [isUnsavedFilterConfirmOpen, setUnsavedFilterConfirmOpen] = useState(
    false
  );

  const closeUnsavedFilterConfirm = () => {
    setUnsavedFilterConfirmOpen(false);
  };
  const resetRequestedLocation = () => {
    setRequestedLocation(null);
  };

  useEffect(() => {
    return router.setRouteLeaveHook(
      route,
      (nextLocation?: WithRouterProps["location"]) => {
        const pathnameSeparated = /^\/\w+/.exec(location.pathname);
        const pathnameRoot = pathnameSeparated ? pathnameSeparated[0] : "";

        if (nextLocation && nextLocation.pathname.startsWith(pathnameRoot)) {
          return true;
        }

        if (value.hasUnsavedFilter && !requestedLocation) {
          setRequestedLocation(nextLocation || null);
          setUnsavedFilterConfirmOpen(true);

          return false;
        }

        return true;
      }
    );
  }, [value, route, router, requestedLocation, location]);

  return {
    isUnsavedFilterConfirmOpen,
    requestedLocation,
    closeUnsavedFilterConfirm,
    resetRequestedLocation,
  };
};

const useUnsavedChangesWarningOnPageClose = (value: FiltersContextValue) => {
  useEffect(() => {
    if (!value.hasUnsavedFilter) {
      return;
    }

    const listener = (event: BeforeUnloadEvent) => {
      event.preventDefault();

      return (event.returnValue =
        "Unsaved Filter. Are you sure you want to leave this page? Your filter will be lost.");
    };

    window.addEventListener("beforeunload", listener);

    return () => {
      window.removeEventListener("beforeunload", listener);
    };
  }, [value]);
};

const useCreateNewFilter = (
  filtersPanelValue: Filters | null
): FiltersContextValue["createFilter"] => {
  const execute = useCreateFilter();
  const self = useSelf();
  const [, refetchFilters] = useFiltersList();

  return data => {
    if (!filtersPanelValue) {
      return Promise.reject();
    }

    const input = Object.assign(
      mapFiltersValueToCreateRequest(filtersPanelValue),
      {
        customName: data.title,
        taskAuthorID: self.id,
      }
    );

    return execute({ input }).then(() => refetchFilters());
  };
};

const useReplaceFilter = (
  filtersPanelValue: Filters | null
): FiltersContextValue["replaceFilter"] => {
  const execute = useUpdateFilter();
  const [, refetchFilters] = useFiltersList();

  return data => {
    if (!filtersPanelValue) {
      return Promise.reject();
    }

    const { id } = data;
    const input = Object.assign(
      mapFiltersValueToUpdateRequest(filtersPanelValue),
      {
        taskFilterSettingID: id,
      }
    );

    return execute({ input }).then(() => refetchFilters());
  };
};

const useRenameFilter = (): FiltersContextValue["renameFilter"] => {
  const execute = useUpdateFilter();
  const [, refetchFilters] = useFiltersList();

  return data => {
    const { id, newTitle } = data;

    if (!newTitle.length) {
      return Promise.reject();
    }

    return execute({
      input: {
        taskFilterSettingID: id,
        customName: newTitle,
      },
    }).then(() => refetchFilters());
  };
};
