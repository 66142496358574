import React, { useState } from "react";
import { Select, Input } from "antd";

import { useQuery } from "src/utils/http/gqlQuery";
import color from "src/styles/color";

import { request } from "src/shared/util";
import { every, isEmpty } from "lodash";
import styled, { css } from "react-emotion";
import { notification } from "antd";

import Modal from "src/shared/Modal";
import Box from "src/shared/Box";
import Loading from "src/shared/Loading";
import Button, { ButtonRow } from "src/shared/Button";

import textStyles from "src/styles/textStyles";
import Error from "src/shared/Error";
import {
  useGetAllClinics,
  useAddUserRoles,
  staffUsers,
  useAddClinicsForStaff,
  allRoles,
} from "./query";

const Content = styled.div`
  display: flex;
  flex-flow: column;

  height: 450px;
  width: 600px;
  padding: 32px;
  overflow-y: auto;
`;

const InputContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
`;

const Label = styled.div`
  ${textStyles("large")};
  font-weight: bold;
  margin-bottom: 5px;
`;

const selectedStyle = css`
  margin-bottom: 10px;
`;

type Props = {
  organizationID: string;
  onClose: () => void;
};

type StaffTextFields = {
  firstName: string;
  lastName: string;
  email: string;
  mobilePhoneNumber: string;
};

const InputWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-flow: column;
`;

const ErrorWrapper = styled.div`
  color: ${color.red};
`;

const AddStaffModal: React.FC<Props> = ({ onClose, organizationID }) => {
  const [values, setValues] = useState<StaffTextFields>({
    firstName: "",
    lastName: "",
    email: "",
    mobilePhoneNumber: "",
  });

  const [roles, setRoles] = useState<string[]>([]);
  const [clinics, setClinics] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const [cFetching, cErr, allClinics] = useGetAllClinics(organizationID);
  const addUserRoles = useAddUserRoles();
  const addClinics = useAddClinicsForStaff();

  const [, refetchStaff] = useQuery({
    query: staffUsers,
    variables: { organizationID },
  });

  if (cFetching) return <Loading />;
  if (cErr) return <Error />;

  const onValueChange = fieldName => e => {
    setValues({
      ...values,
      [fieldName]: e.target.value,
    });
  };

  const submit = async () => {
    setIsSaving(true);
    const result = await request("/api/v1/staff", {
      method: "POST",
      body: JSON.stringify({
        first_name: values.firstName,
        last_name: values.lastName,
        mobile_phone_number: values.mobilePhoneNumber,
        email: values.email,
        organization_id: organizationID,
      }),
    });

    if (result.error) {
      setIsSaving(false);
      setError(result.error);
      return;
    }

    if (result.errors) {
      setIsSaving(false);

      const errorStrings: string[] = Object.keys(result.errors).flatMap(key =>
        result.errors[key].map(
          (errorMessage: string) => `${key} ${errorMessage}`
        )
      );

      setError(errorStrings.join(", "));
      return;
    }

    const rolesRes = await addUserRoles({
      input: {
        userID: result.id,
        organizationID,
        roles,
      },
    });

    if (rolesRes.error) {
      setIsSaving(false);
      setError(rolesRes.error.message);
      return;
    }

    const clinicIDs = allClinics
      .filter(clinic => {
        return clinics.includes(clinic.name);
      })
      .map(clinic => clinic.id);

    const addedClinicRes = await addClinics({
      input: {
        staffID: result.id,
        clinicIDs,
      },
    });

    if (addedClinicRes.error) {
      setIsSaving(false);
      setError(addedClinicRes.error.message);
      return;
    }

    setIsSaving(false);
    refetchStaff();
    notification.success({
      message: "Staff added!",
    });
    onClose();
  };

  const isInputValid = () => {
    return (
      every(Object.values(values).map(Boolean)) &&
      !isEmpty(roles) &&
      !isEmpty(clinics)
    );
  };

  return (
    <Modal onClose={onClose}>
      <Box title="Add staff member">
        <Content>
          <InputContainer>
            <div>
              <InputWrapper>
                <Label>First name</Label>
                <Input
                  value={values.firstName}
                  onChange={onValueChange("firstName")}
                />
              </InputWrapper>
              <InputWrapper>
                <Label>Last name</Label>
                <Input
                  value={values.lastName}
                  onChange={onValueChange("lastName")}
                />
              </InputWrapper>
              <InputWrapper>
                <Label>Email</Label>
                <Input value={values.email} onChange={onValueChange("email")} />
              </InputWrapper>
              <InputWrapper>
                <Label>Phone number</Label>
                <Input
                  value={values.mobilePhoneNumber}
                  onChange={onValueChange("mobilePhoneNumber")}
                />
              </InputWrapper>
            </div>
            <Label>User roles</Label>
            <Select
              className={selectedStyle}
              mode="multiple"
              placeholder="Select roles"
              onChange={setRoles}
              optionLabelProp="value"
              value={roles}
            >
              {allRoles.map(role => (
                <Select.Option key={role} value={role}>
                  <div>{role}</div>
                </Select.Option>
              ))}
            </Select>
            <Label>Clinics</Label>
            <Select
              className={selectedStyle}
              mode="multiple"
              placeholder="Select clinics"
              onChange={setClinics}
              optionLabelProp="value"
              value={clinics}
            >
              {allClinics.map(({ name }) => (
                <Select.Option key={name} value={name}>
                  <div>{name}</div>
                </Select.Option>
              ))}
            </Select>
          </InputContainer>
          <ButtonRow position="right">
            {error && <ErrorWrapper>{error}</ErrorWrapper>}
            <Button kind="default" onClick={onClose}>
              Cancel
            </Button>
            <Button
              kind="primary"
              disabled={!isInputValid() || isSaving}
              onClick={submit}
            >
              {isSaving ? "Loading..." : "Save"}
            </Button>
          </ButtonRow>
        </Content>
      </Box>
    </Modal>
  );
};

export default AddStaffModal;
