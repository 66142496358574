import React from "react";
import { Link } from "react-router";
import { Task } from "../../types";
import { name, link } from "./util";
import { Item, Wrapper } from "./styled";
import ChooseManufacturer from "./ChooseManufacturer";
import CreateKit from "./CreateKit";
import ShipKit from "./ShipKit";
import QA from "./QA";
import ReceiveBuild from "./ReceiveBuild";
import SendToManufacturer from "./SendToManufacturer";
import ExternalProvider from "./ExternalProvider";
import { TaskTypeNameAddGeneralDentistToSendNewPatientReferralTo } from "src/scenes/Tasks/Task/consts";

type Props = {
  task: Task;
  onChangeContent: (to: any) => void;
};

// References are either references linked to from the task, or "fake" references used as
// buttons to link to task-specific content.
//
// This calls onChangeContent which toggles the parent Task modle.
const References = props => {
  const { task } = props;

  switch (task.taskType.name) {
    case "send_stl_files_for_aligner_manufacturing":
      return <SendToManufacturer {...props} />;
    case "receive_aligners_from_manufacturing":
      return <ReceiveBuild {...props} />;
    case "choose_manufacturer":
      return <ChooseManufacturer {...props} />;
    case "qa_trays":
      return <QA {...props} />;
    case "create_kits_for_shipment":
      return <CreateKit {...props} />;
    case "ship_kit_to_patient":
      return <ShipKit {...props} />;
    case TaskTypeNameAddGeneralDentistToSendNewPatientReferralTo:
      return <ExternalProvider {...props} />;
  }

  return (
    <Wrapper>
      {task.references.map(r => {
        const href = link(task, r);

        if (href) {
          return (
            <Link to={href}>
              <Item key={r.id}>{name(r)}</Item>
            </Link>
          );
        }

        return <Item key={r.id}>{name(r)}</Item>;
      })}
    </Wrapper>
  );
};

export default References;
