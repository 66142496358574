import { createStore as _createStore, applyMiddleware } from "redux";
import thunkPlugin from "kea-thunk";
import { activatePlugin } from "kea";

// TODO: figure out if all of this file is necessary
import createMiddleware from "./middleware/clientMiddleware";
import ApiClient from "src/helpers/ApiClient";
import cookie from "src/utils/CUSTOM-cookie";
import merge from "lodash/merge";

export const client = new ApiClient();

// Get the auth token from our cookie
// TODO: This should never happen; our auth cookie should be HTTP only and the
// API should allow us to auth via cookie
const authToken = cookie.parse(document.cookie).auth_token;
if (authToken) {
  window.localStorage.setItem("auth_token", authToken);
} else {
  window.localStorage.removeItem("auth_token");
}

const data = merge(window.__data, { auth: { authToken } });

// Sync dispatched route actions to the history
// const reduxRouterMiddleware = routerMiddleware(history);

const middleware = [createMiddleware(client)];
const finalCreateStore = applyMiddleware(...middleware)(_createStore);

const reducer = require("./modules/reducer").default;

export const store = finalCreateStore(reducer, data);

if (module.hot) {
  // (__DEVELOPMENT__ && module.hot) {
  module.hot.accept("./modules/reducer", () => {
    store.replaceReducer(require("./modules/reducer"));
  });
}

// TODO: Supersede this with `getStore` from kea.
activatePlugin(thunkPlugin);

export default store;
