import React from "react";
import UploadIcon from "src/shared/Icons/Upload";
import FiletypeFilter from "src/shared/FiletypeFilter";
import { FileData } from "src/shared/file";
import Button from "src/shared/Button";
import InputText from "src/shared/InputText";
import Switch from "src/shared/Switch";
import LeftIcon from "src/shared/Icons/LeftIcon";
import RightIcon from "src/shared/Icons/RightIcon";
import UseUploader from "src/shared/UseUploader";
import { Wrapper, draggingCSS, left, right, Label, FileList } from "./styles";

type Props = {
  userID: string;
  onStartUpload?: (valid: FileData[], invalid: FileData[]) => void;
};

const ModalLarge = (props: Props) => {
  const {
    dragging,
    setDragging,
    list,
    updateList,
    upload,
    onChange,
    onDrop,
  } = UseUploader(props);

  return (
    <Wrapper
      className={dragging ? draggingCSS : ""}
      onDrop={onDrop}
      onDragOver={e => {
        e.preventDefault();
        setDragging(true);
      }}
      onDragEnter={() => setDragging(true)}
      onDragLeave={() => setDragging(false)}
    >
      <LeftIcon className={left} />
      <RightIcon className={right} />
      <Label>
        <UploadIcon />
        <input type="file" multiple onChange={onChange} />
      </Label>

      <h1>Drag and drop files here to upload</h1>
      <p>Files will be added to this user’s profile.</p>

      {list.length > 0 && (
        <FileList>
          <div>
            <b>Name</b>
          </div>
          <div>
            <b>Type</b>
          </div>
          <div>
            <b>Description</b>
          </div>
          <div style={{ textAlign: "center" }}>
            <b>Internal</b>
          </div>
          {list
            .sort((a, b) => a.file.name.localeCompare(b.file.name))
            .map(l => (
              <React.Fragment key={l.id}>
                <div>{l.file.name}</div>
                <div>
                  <FiletypeFilter
                    value={l.filetype}
                    onChange={filetype => {
                      const updated = list
                        .filter(i => i.file !== l.file)
                        .concat([{ ...l, filetype }]);
                      updateList(updated);
                    }}
                  />
                </div>
                <div>
                  <InputText
                    onChange={e => {
                      const updated = list
                        .filter(i => i.file !== l.file)
                        .concat([{ ...l, description: e.target.value }]);
                      updateList(updated);
                    }}
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <Switch
                    checked={l.internal}
                    onChange={internal => {
                      const updated = list
                        .filter(i => i.file !== l.file)
                        .concat([{ ...l, internal }]);
                      updateList(updated);
                    }}
                  />
                </div>
              </React.Fragment>
            ))}
        </FileList>
      )}

      <Button
        type="primary"
        disabled={list.length === 0}
        style={{ margin: "1rem 0 0" }}
        onClick={() => upload()}
      >
        Upload
      </Button>
    </Wrapper>
  );
};

export default ModalLarge;
