import React from "react";

const LeftIcon = ({ className }) => (
  <div className={className}>
    <svg
      width="197"
      height="168"
      viewBox="0 0 197 168"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M10.1523 29.8136L137.357 9.10891L154.054 111.693L26.8497 132.398L10.1523 29.8136Z"
          fill="#E6E9ED"
        />
        <path
          d="M137.357 9.10888L10.1522 29.8136L9.4843 25.7102C9.11362 23.4328 10.6445 21.3093 12.9198 20.9389L131.918 1.57001C134.177 1.20234 136.318 2.72812 136.689 5.0055L137.357 9.10888Z"
          fill="#CCD1D9"
        />
        <path
          d="M134.466 55.9124L136.47 68.2226L128.263 69.5584L127.929 67.5067L126.593 59.2999L126.259 57.2482L134.466 55.9124Z"
          fill="#656D78"
        />
        <path
          d="M126.593 59.2999L127.929 67.5067L120.369 72.9492L100.562 67.7069L117.698 56.5357L126.593 59.2999Z"
          fill="#CCD1D9"
        />
        <g clipPath="url(#clip0)">
          <path
            d="M44.5448 107.115L20.6447 81.1966L34.3984 44.9041L58.2986 70.8228L44.5448 107.115Z"
            fill="#F6DDB6"
          />
          <path
            d="M44.545 107.115L79.6201 103.546L93.3738 67.254L58.2988 70.8229L44.545 107.115Z"
            fill="#EFBC71"
          />
          <path
            d="M34.3989 44.904L68.3398 40.9054L93.3741 67.2539L58.299 70.8228L34.3989 44.904Z"
            fill="#E0901A"
          />
        </g>
        <path
          d="M120.718 74.9986C120.433 75.045 120.138 75.0319 119.852 74.9563L100.033 69.7075C99.2402 69.4974 98.6419 68.8366 98.5124 68.0279C98.3822 67.2151 98.741 66.4049 99.4303 65.9557L116.578 54.791C117.096 54.456 117.741 54.3658 118.333 54.5495L127.211 57.3144C128.307 57.6562 128.919 58.8225 128.577 59.9185C128.237 61.0142 127.068 61.6215 125.973 61.2838L118.028 58.8092L105.62 66.8879L119.965 70.6857L126.711 65.8201C127.643 65.1524 128.942 65.3579 129.614 66.289C130.285 67.22 130.076 68.5199 129.145 69.1916L121.602 74.6335C121.334 74.8246 121.032 74.9475 120.718 74.9986Z"
          fill="#CCD1D9"
        />
        <path
          d="M136.799 70.2729L128.594 71.6084C127.46 71.793 126.393 71.0252 126.209 69.8906L124.205 57.5805C124.02 56.4459 124.788 55.3795 125.923 55.1948L134.127 53.8594C135.262 53.6747 136.328 54.4425 136.513 55.5771L138.517 67.8873C138.701 69.0219 137.934 70.0882 136.799 70.2729ZM129.978 67.171L134.079 66.5035L132.744 58.2967L128.642 58.9643L129.978 67.171Z"
          fill="#656D78"
        />
        <path
          d="M16.034 26.746C14.8995 26.9306 13.8228 26.1645 13.6381 25.0299C13.4535 23.8953 14.211 22.8306 15.3436 22.6463L15.3661 22.6426C16.4987 22.4582 17.5671 23.2257 17.7518 24.3603C17.9365 25.4949 17.1666 26.5616 16.034 26.746Z"
          fill="#E6E9ED"
        />
        <path
          d="M22.1874 25.7444C21.0528 25.9291 19.9761 25.163 19.7915 24.0284C19.6068 22.8938 20.3644 21.8291 21.4969 21.6447L21.5195 21.6411C22.652 21.4567 23.7204 22.2242 23.9051 23.3588C24.0898 24.4934 23.3199 25.5601 22.1874 25.7444Z"
          fill="#E6E9ED"
        />
        <path
          d="M28.3485 24.7416C27.2139 24.9263 26.1373 24.1601 25.9526 23.0255C25.7679 21.8909 26.5255 20.8262 27.658 20.6419L27.6806 20.6382C28.8131 20.4539 29.8816 21.2214 30.0662 22.356C30.2509 23.4905 29.481 24.5573 28.3485 24.7416Z"
          fill="#E6E9ED"
        />
        <path
          d="M152.335 114.083L29.2381 134.12C28.1036 134.304 27.0372 133.536 26.8525 132.402C26.6678 131.267 27.4357 130.201 28.5702 130.016L151.668 109.98C152.802 109.795 153.868 110.563 154.053 111.698C154.238 112.832 153.47 113.899 152.335 114.083Z"
          fill="#E6E9ED"
        />
        <path
          d="M95.9981 104.259C94.8635 104.444 93.7971 103.676 93.6124 102.541L88.9428 73.8523C88.7582 72.7178 89.526 71.6514 90.6606 71.4667C91.7952 71.282 92.8615 72.0499 93.0462 73.1845L97.7158 101.873C97.9005 103.008 97.1326 104.074 95.9981 104.259Z"
          fill="#545C66"
        />
        <path
          d="M89.9923 67.3633C88.8578 67.548 87.7914 66.7802 87.6067 65.6456L82.9311 36.9199C82.7464 35.7853 83.5143 34.7189 84.6489 34.5342C85.7834 34.3496 86.8498 35.1174 87.0345 36.252L91.7101 64.9777C91.8948 66.1123 91.1269 67.1786 89.9923 67.3633Z"
          fill="#545C66"
        />
        <path
          d="M130.439 31.3037L99.6639 36.313C98.5293 36.4976 97.463 35.7298 97.2783 34.5952C97.0936 33.4606 97.8614 32.3942 98.996 32.2096L129.771 27.2004C130.906 27.0157 131.972 27.7835 132.157 28.9181C132.342 30.0527 131.574 31.1191 130.439 31.3037Z"
          fill="#CCD1D9"
        />
        <path
          d="M129.437 25.1424L98.6615 30.1516C97.5269 30.3363 96.4605 29.5685 96.2758 28.4339C96.0912 27.2993 96.859 26.2329 97.9936 26.0483L128.769 21.0391C129.904 20.8544 130.97 21.6222 131.155 22.7568C131.339 23.8914 130.571 24.9578 129.437 25.1424Z"
          fill="#CCD1D9"
        />
        <path
          d="M131.441 37.4588L100.666 42.468C99.5313 42.6527 98.4649 41.8849 98.2802 40.7503C98.0956 39.6157 98.8634 38.5493 99.998 38.3646L130.773 33.3554C131.908 33.1707 132.974 33.9386 133.159 35.0732C133.344 36.2077 132.576 37.2741 131.441 37.4588Z"
          fill="#CCD1D9"
        />
        <path
          d="M118.081 45.9515L101.668 48.6231C100.533 48.8077 99.4669 48.0399 99.2822 46.9053C99.0975 45.7707 99.8653 44.7044 101 44.5197L117.413 41.8481C118.548 41.6634 119.614 42.4313 119.799 43.5659C119.984 44.7004 119.216 45.7668 118.081 45.9515Z"
          fill="#CCD1D9"
        />
        <path
          d="M140.122 90.7925L109.347 95.8017C108.212 95.9863 107.146 95.2185 106.961 94.0839C106.777 92.9493 107.545 91.883 108.679 91.6983L139.454 86.6891C140.589 86.5044 141.655 87.2722 141.84 88.4068C142.025 89.5414 141.257 90.6078 140.122 90.7925Z"
          fill="#CCD1D9"
        />
        <path
          d="M139.119 84.6312L108.344 89.6404C107.209 89.8251 106.143 89.0573 105.958 87.9227C105.773 86.7881 106.541 85.7217 107.676 85.5371L138.451 80.5279C139.586 80.3432 140.652 81.111 140.837 82.2456C141.021 83.3802 140.254 84.4466 139.119 84.6312Z"
          fill="#CCD1D9"
        />
        <path
          d="M141.124 96.9475L110.348 101.957C109.214 102.141 108.148 101.374 107.963 100.239C107.778 99.1044 108.546 98.038 109.681 97.8534L140.456 92.8441C141.591 92.6595 142.657 93.4273 142.842 94.5619C143.026 95.6965 142.258 96.7629 141.124 96.9475Z"
          fill="#CCD1D9"
        />
        <path
          d="M127.764 105.44L111.35 108.112C110.216 108.296 109.149 107.529 108.965 106.394C108.78 105.259 109.548 104.193 110.683 104.008L127.096 101.337C128.231 101.152 129.297 101.92 129.482 103.055C129.666 104.189 128.899 105.256 127.764 105.44Z"
          fill="#CCD1D9"
        />
        <path
          d="M89.5 64.2844C92.3272 63.8496 94.9803 65.7617 95.4395 68.5828C95.8987 71.4039 93.9889 74.0588 91.1698 74.5429L91.1452 74.5469C88.2995 75.0101 85.6402 73.0736 85.1811 70.2525C84.7219 67.4315 86.6297 64.7516 89.4754 64.2885L89.5 64.2844Z"
          fill="#656D78"
        />
        <path
          d="M83.6312 28.3839C86.4543 27.9244 89.1318 29.8305 89.5953 32.6783C90.0544 35.4993 88.1447 38.1542 85.3255 38.6384L85.3009 38.6424C82.4552 39.1055 79.796 37.1691 79.3368 34.348C78.8733 31.5003 80.7855 28.8471 83.6312 28.3839Z"
          fill="#656D78"
        />
        <path
          d="M95.3192 100.193C98.1423 99.7335 100.824 101.666 101.283 104.487C101.747 107.335 99.812 109.992 96.9889 110.451C94.1432 110.915 91.4883 109.005 91.0248 106.157C90.5656 103.336 92.4735 100.656 95.3192 100.193Z"
          fill="#656D78"
        />
      </g>
      <g clipPath="url(#clip1)" filter="url(#filter1_d)">
        <path
          d="M168.266 33.4877L125.64 25.0025C124.454 24.7662 123.3 25.5367 122.929 27.4035L107.411 105.356C107.31 105.862 108.081 107.016 109.268 107.252L168.126 118.969C169.313 119.205 170.466 118.434 170.567 117.928L182.853 56.208C183.057 55.1858 182.986 54.8296 182.793 54.5393L169.105 34.047C168.91 33.7579 168.609 33.5558 168.266 33.4877Z"
          fill="#E9E9E0"
        />
        <path
          d="M168.979 33.8601L165.515 51.2632L182.918 54.7275L168.979 33.8601Z"
          fill="#D9D7CA"
        />
        <path
          d="M132.863 77.324C132.352 77.2222 131.894 76.9586 131.538 76.5624C130.237 75.111 130.286 73.7937 130.556 72.9435C131.299 70.6056 134.754 68.6914 140.828 67.2498C144 62.8485 147.289 57.3023 149.534 52.5683C148.704 49.0864 148.103 44.6639 149.624 42.4428C150.158 41.6649 150.742 41.1031 151.645 40.9898C152.002 40.9448 152.876 40.9722 153.188 41.0342C153.928 41.1816 154.389 42.2643 154.733 42.9436C155.056 43.5821 155.806 44.9483 152.197 52.8249C153.362 57.308 155.339 62.0318 157.459 65.4191C159.453 65.4544 161.146 65.6064 162.495 65.8751C164.795 66.333 166.083 67.1465 166.431 68.3642C166.719 69.3713 166.314 70.4008 165.227 71.4226C164.181 72.4039 162.932 72.7845 161.618 72.5228C159.832 72.1673 157.977 70.6253 156.101 67.9356C151.761 67.9771 146.586 68.5624 142.31 69.4993C140.563 71.8604 138.969 73.7251 137.567 75.0462C135.642 76.8542 134.148 77.5798 132.863 77.324ZM138.271 70.5735C134.781 71.7126 133.213 72.9076 132.958 73.7058C132.917 73.838 132.806 74.1856 133.389 74.8482C133.622 74.8227 135.016 74.494 138.271 70.5735ZM159.599 68.0364C160.613 69.1956 160.812 69.7194 161.595 69.8752C161.939 69.9436 162.922 70.1239 163.501 69.5812C163.781 69.3178 163.906 69.138 163.961 69.039C163.799 68.9075 163.598 68.666 162.292 68.4061C161.55 68.2599 160.611 68.1066 159.599 68.0364ZM150.552 56.1851C148.779 59.6097 146.611 63.2568 144.413 66.5129C147.72 65.9328 151.264 65.5572 154.545 65.4453C153.02 62.7476 151.611 59.4774 150.552 56.1851ZM152.135 43.5792C151.981 43.599 149.667 45.7709 151.335 48.3307C153.347 45.5018 152.202 43.5712 152.135 43.5792Z"
          fill="#CC4B4C"
        />
        <path
          d="M168.126 118.969L109.268 107.252C108.081 107.016 107.311 105.862 107.547 104.675L112.089 81.8557L175.245 94.4276L170.703 117.247C170.466 118.434 169.313 119.205 168.126 118.969Z"
          fill="#CC4B4C"
        />
        <path
          d="M118.8 105.623L122.354 106.331L123.563 100.258L126.788 100.9C130.295 101.598 132.779 99.8166 133.379 96.8037C133.983 93.7672 132.366 91.1941 128.859 90.4959L122.08 89.1465L118.8 105.623ZM124.149 97.3156L125.049 92.7963L128.062 93.396C129.709 93.724 130.204 94.9238 129.966 96.1243C129.727 97.3247 128.81 98.2434 127.162 97.9154L124.149 97.3156ZM133.075 108.465L138.606 109.566C143.149 110.47 147.652 108.626 148.767 103.024C149.887 97.3981 146.429 93.9935 141.886 93.0891L136.355 91.988L133.075 108.465ZM137.215 106.108L139.281 95.7273L140.929 96.0553C143.636 96.5941 145.962 98.4277 145.189 102.311C144.416 106.195 141.569 106.974 138.862 106.436L137.215 106.108ZM149.464 111.727L152.995 112.43L154.33 105.722L160.45 106.94L161.059 103.88L154.939 102.662L155.67 98.9898L163.344 100.517L163.948 97.4809L152.744 95.2505L149.464 111.727Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter2_d)">
        <path
          d="M113.846 75.8952L50.8022 92.7876L68.3985 158.458L131.442 141.565L113.846 75.8952Z"
          fill="#424A60"
          stroke="#E7ECED"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M72.5943 111.074C75.9085 110.186 77.8753 106.779 76.9873 103.465C76.0992 100.15 72.6926 98.1837 69.3784 99.0717C66.0642 99.9598 64.0973 103.366 64.9854 106.681C65.8734 109.995 69.2801 111.962 72.5943 111.074Z"
          fill="#EFCE4A"
        />
        <path
          d="M127.922 128.431L64.8789 145.324L68.3982 158.458L131.442 141.565L127.922 128.431Z"
          fill="#E7ECED"
        />
        <path
          d="M123.481 117.108L121.776 116.001L102.144 105.777L92.4007 124.576L101.532 129.848L108.221 133.71L126.609 128.783L123.481 117.108Z"
          fill="#1A9172"
        />
        <path
          d="M101.532 129.848L83.2696 119.304L66.1923 144.972L108.221 133.71L101.532 129.848Z"
          fill="#25AE88"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="4.81641"
          y="0.902115"
          width="153.571"
          height="141.551"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d"
          x="93.5718"
          y="22.6743"
          width="106.622"
          height="106.622"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d"
          x="45.5776"
          y="74.6705"
          width="91.0892"
          height="93.0122"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <clipPath id="clip0">
          <rect
            width="70.3452"
            height="70.3452"
            fill="white"
            transform="translate(36.5845 28.8708) rotate(20.7552)"
          />
        </clipPath>
        <clipPath id="clip1">
          <rect
            width="83.8633"
            height="83.8633"
            fill="white"
            transform="translate(113.945 22.6743) rotate(11.2583)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default LeftIcon;
