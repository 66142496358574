import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "src/utils/http/gqlQuery";
import Button from "src/shared/Button";
import ExternalProviderModal from "src/shared/ExternalProvider/ExternalProviderModal";
import VerifiedProviderCard from "src/scenes/PatientProfile/Forms/BeginningVisit/VerifiedProviderCard";
import Question from "src/scenes/PatientProfile/Forms/Question";
import {
  Section,
  SectionTitle,
} from "src/scenes/PatientProfile/Forms/BeginningVisit/styledComponents";
import { questionProps } from "src/scenes/PatientProfile/Forms/BeginningVisit/consts";
import { FormUI } from "src/scenes/PatientProfile/Forms/GQLForms/useFormUI";
import { ProviderBox, VerifiedProviderContainer } from "./styles";
import { forEach, map } from "lodash";
import {
  FormDecorationsContext,
  getFormDecorations,
} from "../../../formDecorations";

const bvAppointments = gql`
  query BeginningVisitIntroduction($userId: ID!) {
    appointments: appointments(userId: $userId) {
      id
      clinic {
        line1
        line2
        city
        state
        postalCode
        timezone
        longitude
        latitude
      }
    }
  }
`;

const NOB_HILL_LAT = 37.7880317;
const NOB_HILL_LONG = -122.4166611;

type FormProps = {
  patientID: string;
  ui: FormUI;
};

// Note: the names of the fields on this type match those of the Go type by the
// same name in our saas repository. These names should stay in sync with those
// of the Go type.
export type ReferralFormReferralProvider = {
  verifiedSpecialistID: string;
  state: string;
};

const ReferralForm: React.FC<FormProps> = props => {
  const { ui, patientID } = props;
  const [showExternalProviderModal, setShowExternalProviderModal] = useState(
    false
  );
  const isSubmitted = ui.submission && !!ui.submission.submittedAt;

  const [{ data }] = useQuery({
    query: bvAppointments,
    variables: {
      userId: patientID,
    },
  });

  const appointments = data ? data.appointments : [];

  const handleReferralProviderChange = (
    verifiedSpecialistID: string,
    state: string,
    action: string
  ) => {
    const newReferralProviderArray: Array<ReferralFormReferralProvider> = [];

    forEach(
      referralProviderArray,
      (referralProvider: ReferralFormReferralProvider) => {
        if (
          action === "delete" &&
          verifiedSpecialistID === referralProvider.verifiedSpecialistID
        )
          return;

        newReferralProviderArray.push(referralProvider);
      }
    );

    if (!action) {
      newReferralProviderArray.push({
        verifiedSpecialistID: verifiedSpecialistID,
        state: state,
      });
    }

    questionProps(ui, "referral_providers").handleBlur(
      newReferralProviderArray
    );
  };

  const referralProviderArray = ui.getAnswerValue(
    "referral_providers"
  ) as Array<ReferralFormReferralProvider>;
  const appointmentID = ui.getAnswerValue("appointment_id");
  const appointment = appointments.find((a: any) => a.id === appointmentID);

  const defaultLatLong = appointment
    ? { lat: appointment.clinic.latitude, long: appointment.clinic.longitude }
    : // if no appointment information found, default to Nob Hill clinic information
      {
        lat: NOB_HILL_LAT,
        long: NOB_HILL_LONG,
      };

  const providerButton = (text: string, key: any) => (
    <ProviderBox>
      <Button
        key={key}
        kind="primary"
        disabled={isSubmitted}
        onClick={() => setShowExternalProviderModal(true)}
      >
        {text}
      </Button>
    </ProviderBox>
  );

  return (
    <FormDecorationsContext.Provider value={getFormDecorations("referral")}>
      <Section>
        <SectionTitle>Referrals</SectionTitle>
        {showExternalProviderModal && (
          <ExternalProviderModal
            onClose={() => setShowExternalProviderModal(false)}
            onChange={handleReferralProviderChange}
            mapView
            defaultLat={defaultLatLong.lat}
            defaultLong={defaultLatLong.long}
            defaultFilterPreferredProviders
          />
        )}
        {Object.values(ui.form.questions).map(({ slug }: any) => {
          if (slug === "referral_providers" && referralProviderArray) {
            const VerifiedProviderCards = map(
              referralProviderArray,
              (referralProvider: ReferralFormReferralProvider) => (
                <VerifiedProviderCard
                  key={slug}
                  disabled={true}
                  specialistID={referralProvider.verifiedSpecialistID}
                  openModal={() => setShowExternalProviderModal(true)}
                  removeCard={handleReferralProviderChange}
                  state={referralProvider.state}
                />
              )
            );
            return (
              <>
                <VerifiedProviderContainer>
                  {VerifiedProviderCards}
                </VerifiedProviderContainer>
                {providerButton("Add a Provider", slug)}
              </>
            );
          }
          if (slug === "referral_providers") {
            return providerButton("Select a provider", slug);
          }
          return (
            <Question
              key={slug}
              {...questionProps(ui, slug)}
              disabled={isSubmitted}
            />
          );
        })}
      </Section>
    </FormDecorationsContext.Provider>
  );
};

export default ReferralForm;
