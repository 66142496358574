import styled from "react-emotion";
import color from "src/styles/color";

export const Wrapper = styled.div`
  display: flex;
  margin: 1.5rem 0;

  .content {
    flex: 1;
  }
`;

export const Meta = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.85rem;
  margin: 0 0 0.3rem 0;

  > span {
    color: ${color.gray3};
  }
`;

export const Content = styled.div`
  flex: 1;
`;

export const Text = styled.div`
  color: #111;
  white-space: pre-line;
`;
