import React, { useState } from "react";
import styled, { css } from "react-emotion";
import SelectList from "src/shared/SelectList";
import Popover from "src/shared/Popover";
import { titleCase } from "src/shared/util";
import { taskTypes } from "src/scenes/Tasks/constants";
import _ from "lodash";

type Props = {
  filteredTaskTypes?: string[];
  onClick: (selected?: string) => void;
};

const TaskTypePopover: React.FC<Props> = ({
  children,
  filteredTaskTypes,
  onClick,
}) => {
  const [visible, setVisible] = useState(false);

  const filterTaskTypes = () => {
    if (filteredTaskTypes && filteredTaskTypes.length === 0) {
      return [];
    }

    return _.map(filteredTaskTypes, filteredTaskType => {
      if (taskTypes.includes(filteredTaskType)) {
        return filteredTaskType;
      }
    });
  };
  const filteredTaskTypeValues = filterTaskTypes();

  const Content = (
    <Wrapper>
      <SelectList<string>
        values={taskTypes}
        value={filteredTaskTypeValues}
        onClick={val => onClick(val)}
        render={(tt: string | undefined) => titleCase(tt || "")}
        className={selectCSS}
      />
    </Wrapper>
  );

  return (
    <Popover
      content={Content}
      visible={visible}
      close={() => setVisible(false)}
    >
      <div
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setVisible(true);
        }}
      >
        {children}
      </div>
    </Popover>
  );
};

export default TaskTypePopover;

const Wrapper = styled.div`
  background: #fff;
  padding: 12px;
`;

const selectCSS = css`
  max-height: 16rem;
`;
