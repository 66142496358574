import { kea } from "kea";
import { isFunction } from "lodash";
import { request } from "src/shared/util";
import { Form, Submission, Answer, NewSubmissionRequest } from "src/types/api";
import { ApiRequestState, FormToothData } from "src/types/local";

interface Modifiers {
  setForms(forms: Array<Form>): Array<Form>;
  getFormsRequestState(state: ApiRequestState): ApiRequestState;

  addAnswer(answer: Answer): Answer;

  addPreviousAnswers(answers: Array<Answer>): Array<Answer>;

  setSubmissions(s: Array<Submission>): Array<Submission>;
  getSubmissionsRequestState(state: ApiRequestState): ApiRequestState;
  addSubmission(s: Submission): Submission;
  putSubmission(s: Submission): Submission; //this updates an existing submission
}

interface Thunks {
  getForms(): Promise<any>;
  getPatientSubmissions(formId: string, patientId: string): Promise<any>;
  newSubmission(submission: NewSubmissionRequest): Promise<any>;
  postAnswer(
    submissionId: string,
    questionId: string,
    answer: unknown
  ): Promise<any>;
  getPreviousAnswers(questionSlug: string, patientId: string): Promise<any>;
  getAllSubmissions(patientId: string): Promise<any>;
}

type Actions = Modifiers & Thunks;

type ReducerProps = {
  forms: { [id: string]: Form };
  getFormsRequestState: ApiRequestState;

  // all submissions are stored in an array, and are replaced each time
  // they are fetched.
  submissions: Array<Submission>;
  getSubmissionsRequestState: ApiRequestState;

  previousAnswers: Array<Answer>;
};

type Selectors = {};

export type Props = ReducerProps &
  Selectors & {
    actions: Actions;
  };

type ObjMap = { [id: string]: any };

export const FormsEnhancer = kea<Props>({
  actions: () => ({
    setForms: (forms: Array<Form>) => forms,
    getFormsRequestState: (state: ApiRequestState) => state,

    addAnswer: (a: Answer) => a,
    addPreviousAnswers: (a: Array<Answer>) => a,

    // Pull all children from the given array of submissions into a
    // single array
    setSubmissions: (s: Array<Submission>) =>
      s
        .map((s: Submission) => {
          if (s.children.length > 0) {
            return [s].concat(s.children);
          }
          return [s];
        })
        .flat(),

    getSubmissionsRequestState: (state: ApiRequestState) => state,
    addSubmission: (s: Submission) => s,
    putSubmission: (s: Submission) => s,
    removeSubmission: (id: string) => id,
  }),

  reducers: ({ actions }: { actions: { [action: string]: string } }) => ({
    forms: [
      {},
      {
        [actions.setForms]: (
          prev: { [id: string]: Form },
          next: Array<Form>
        ): { [id: string]: Form } => {
          const map: ObjMap = {};
          next.forEach((f: Form) => {
            map[f.id] = f;
          });
          return map;
        },
      },
    ],

    getFormsRequestState: [
      {},
      {
        [actions.getFormsRequestState]: (
          prev: ApiRequestState,
          next: ApiRequestState
        ): ApiRequestState => next,
      },
    ],

    submissions: [
      [],
      {
        [actions.setSubmissions]: (
          prev: Array<Submission>,
          next: Array<Submission>
        ) => {
          const submissions: ObjMap = {};
          prev.forEach((s: Submission) => {
            submissions[s.id] = s;
          });
          next.forEach((s: Submission) => {
            submissions[s.id] = s;
          });
          return Object.values(submissions).sort(
            (a: Submission, b: Submission) =>
              new Date(b.created_at).valueOf() -
              new Date(a.created_at).valueOf()
          );
        },
        [actions.addSubmission]: (prev: Array<Submission>, add: Submission) =>
          // Assume that when you create a submission it is the newest,
          // so it should be first in the array.  Hence we make an array
          // for "add" and concatenate the previous submissions to it.
          //
          // We could also copy the array and unshift, but that's not a one-liner.
          [add].concat(prev),
        [actions.putSubmission]: (
          submissions: Array<Submission>,
          put: Submission
        ) => {
          return submissions.map((s: Submission) => {
            if (s.id === put.id) {
              return put;
            }
            return s;
          });
        },
        [actions.removeSubmission]: (
          submissions: Array<Submission>,
          id: string
        ) => {
          return submissions.filter(s => s.id !== id);
        },
        [actions.addAnswer]: (
          submissions: Array<Submission>,
          newAnswer: Answer
        ) => {
          // Replace the answer for the qeuston in the submission.
          return submissions.map((s: Submission) => {
            if (s.id !== newAnswer.form_submission_id) {
              return s;
            }

            // Return a copy of the submission, with the answers copied and either:
            // 1. replaced, if there was a previous answer or this questipn
            // 2. added, if there was not an answer for this question
            return Object.assign({}, s, {
              answers: (s.answers || [])
                .filter(a => a.question_id !== newAnswer.question_id)
                .concat([newAnswer]),
            });
          });
        },
      },
    ],

    getSubmissionsRequestState: [
      {},
      {
        [actions.getSubmissionsRequestState]: (
          prev: ApiRequestState,
          next: ApiRequestState
        ): ApiRequestState => next,
      },
    ],

    previousAnswers: [
      {},
      {
        [actions.addPreviousAnswers]: (p: Object, next: Array<Answer>) => {
          const map: ObjMap = {};
          // Iterate through the set of questions from the server and add this to
          // a new map.  This is faster than doing Object.assign each time as we don't
          // need to do constant reallocations.
          next.forEach((a: Answer) => {
            map[a.id] = a;
          });
          // Then copy all answers into a new object so that comparison via reference
          // detects new state.
          return Object.assign({}, p, map);
        },
      },
    ],
  }),

  thunks: ({ actions }: any) => ({
    getForms: async () => {
      actions.getFormsRequestState({ loading: true, error: false });
      const forms = await request(`/api/v1/forms`);
      if (forms instanceof Array) {
        actions.getFormsRequestState({ loading: false, error: false });
        actions.setForms(forms);
        return;
      }
      actions.getFormsRequestState({ loading: false, error: true });
    },

    getPatientSubmissions: async (formId: string, patientId: string) => {
      // Reset submissions so that they're an empty array, ensuring that we don't
      // carry submissions over from one patient to the other
      // TODO: Save state so that we only update forms and don't refetch.
      actions.setSubmissions([]);
      actions.getSubmissionsRequestState({ loading: true, error: false });

      const submissions = await request(
        `/api/v1/forms/${formId}/submissions?patient_id=${patientId}`
      );
      if (submissions instanceof Array) {
        actions.setSubmissions(submissions);
        actions.getSubmissionsRequestState({ loading: false, error: false });
        return;
      }

      actions.getSubmissionsRequestState({ loading: false, error: true });
    },

    newSubmission: async (opts: NewSubmissionRequest) => {
      const body =
        opts.parentId && JSON.stringify({ parent_id: opts.parentId });

      const submission = await request(
        `/api/v1/forms/${opts.formId}/submissions?patient_id=${opts.patientId}`,
        {
          method: "POST",
          body,
        }
      );
      if (submission.id) {
        actions.addSubmission(submission);
      }
      if (isFunction(opts.onComplete)) {
        // @ky - 02/09/2010
        // @ts-ignore typescript might need integration with lodash
        opts.onComplete();
      }
      return submission;
    },

    getAllSubmissions: async (patientId: string) => {
      const r = await request(`/api/v1/patients/${patientId}/submissions`);
      if (r.error) {
        // TODO: Error
        return;
      }
      actions.setSubmissions(r);
    },

    submitSubmission: async (
      submissionId: string,
      toothData: FormToothData
    ) => {
      const response = await request(
        `/api/v1/forms/submissions/${submissionId}/submit`,
        {
          method: "PUT",
          // Send along tooth data with submission
          body: JSON.stringify({ tooth_data: toothData }),
        }
      );
      if (response.form_submission && response.form_submission.id) {
        actions.putSubmission(response.form_submission);
      }
      return response;
    },

    approveSubmission: async (
      submissionId: string,
      toothData: FormToothData
    ) => {
      const response = await request(
        `/api/v1/forms/submissions/${submissionId}/approve`,
        {
          method: "PUT",
          // Send along tooth data with submission
          body: JSON.stringify({ tooth_data: toothData }),
        }
      );
      if (response.form_submission && response.form_submission.id) {
        actions.putSubmission(response.form_submission);
      }
      return response;
    },

    deleteSubmission: async (submissionId: string) => {
      const response = await request(
        `/api/v1/forms/submissions/${submissionId}`,
        {
          method: "DELETE",
        }
      );
      if (!response.error) {
        actions.removeSubmission(submissionId);
      }
      return response;
    },

    unsubmitSubmission: async (submissionId: string) => {
      const response = await request(
        `/api/v1/forms/submissions/${submissionId}/unsubmit`,
        {
          method: "PUT",
        }
      );
      if (response.form_submission && response.form_submission.id) {
        actions.putSubmission(response.form_submission);
      }
      return response;
    },

    updateSubmission: async (submissionId: string, updateAtr: {}) => {
      const response = await request(
        `/api/v1/forms/submissions/${submissionId}/`,
        {
          method: "PUT",
          body: JSON.stringify(updateAtr),
        }
      );

      actions.putSubmission(response);
      return response;
    },

    postAnswer: async (
      submissionId: string,
      questionId: string,
      answer: unknown
    ) => {
      const response = await request(
        `/api/v1/forms/submissions/${submissionId}/questions/${questionId}`,
        {
          method: "POST",
          body: JSON.stringify({ answer }),
        }
      );

      actions.addAnswer(response);
      return response;
    },

    getPreviousAnswers: async (questionSlug: string, patientId: string) => {
      const response = await request(
        `/api/v1/forms/${patientId}/answers/${questionSlug}`
      );
      if (Array.isArray(response)) {
        actions.addPreviousAnswers(response);
      }
    },
  }),
});
