import { useMutation, useQuery } from "src/utils/http/gqlQuery";

const clinicQuery = `
query Clinics {
  clinics {
    id name organizationId
  }
}
`;

const gql = `
mutation CreatePatient($input: NewUser!) {
  createPatient(user: $input) {
    id
    firstName
    lastName
  }
}
`;

export const useClinics = () => {
  const [result] = useQuery({ query: clinicQuery });
  const clinics = (result.data && result.data.clinics) || [];
  return [result.fetching, result.error, clinics];
};

export const useCreatePatient = () => {
  const [, execute] = useMutation(gql);
  return execute;
};
