import {
  useMutation,
  useQuery,
  UseQueryResponse,
} from "src/utils/http/gqlQuery";
import { PipelineTemplate, TaskType, CreateTasksInput } from "./types";
import { holdPipelineTypes } from "./holds";

export const usePipelineTemplates = (): UseQueryResponse<{
  pipelines: PipelineTemplate[];
  taskTypes: TaskType[];
}> => {
  return useQuery({
    query: `
    query ListPipelineTemplates {
      pipelines: listPipelineTemplates {
        id
        type 
        hidden
        taskTemplates {
          id
          pipelineTemplateID
          taskTypeID
          taskType {
            id
            name
            category
            description
          }
        }
        taskEdgeTemplates {
          id
          pipelineTemplateID
          parentTaskTemplateID
          childTaskTemplateID
        }
      },

      taskTypes {
        id
        name
        category
        description
      }
    }
  `,
  });
};

// So, when we're making new tasks we're actually making *new pipelines*.
//
// Here, we want to filter those pipelines so that they contain only one task
// when they're being made.
//
// It's unsafe to rely on pipeline.type matching the task category.
//
// This lets us show a UI for creating "single tasks" without leaking pipelines
// in the UI.
export const useSingleTaskPipelines = (): UseQueryResponse<{
  pipelines: PipelineTemplate[];
  taskTypes: TaskType[];
}> => {
  const [{ fetching, error, data, stale }, refetch] = usePipelineTemplates();
  const filtered = (data ? data.pipelines : []).filter(
    p => p.taskTemplates.length === 1 && !holdPipelineTypes.includes(p.type)
  );
  return [
    {
      fetching,
      error,
      stale,
      data: {
        pipelines: filtered,
        taskTypes: data ? data.taskTypes : [],
      },
    },
    refetch,
  ];
};

// We split the UI into "new tasks" and "new pipelines", but both are pipelines.
// Therefore, in the 'new pipeline" UI only show pipelines with > 1 task, OR
// pipelines that are holds.
export const useMultiTaskPipelines = (): UseQueryResponse<{
  pipelines: PipelineTemplate[];
  taskTypes: TaskType[];
}> => {
  const [{ fetching, error, data, stale }, refetch] = usePipelineTemplates();
  const filtered = (data ? data.pipelines : []).filter(
    p => p.taskTemplates.length > 1 || holdPipelineTypes.includes(p.type)
  );
  return [
    {
      fetching,
      error,
      stale,
      data: {
        pipelines: filtered,
        taskTypes: data ? data.taskTypes : [],
      },
    },
    refetch,
  ];
};

export const useNewPipeline = () => {
  const [, execute] = useMutation<{ input: CreateTasksInput }>(mutation);
  return execute;
};

const mutation = `
  mutation NewPipeline($input: CreateTasks) {
    createTasks(createTasks: $input) {
      tasks {
        id
        name
      }
    }
  }
`;

export const useInjectTreatmentPlanTasks = () => {
  const [, execute] = useMutation(injectTreatmentPlanTasksGQL);
  return execute;
};

const injectTreatmentPlanTasksGQL = `
mutation NewPipeline($parents: [ID!]!, $input: CreateTasks!) {
  appendTasks(parentIDs: $parents, createTasks: $input) {
    tasks {
      id
      name
    }
  }
}
`;
