import { kea } from "kea";
import { message } from "antd";
import { request } from "src/shared/util";
/*
import type {
  Build,
  CreateBuildRequest,
  UpdateBuildRequest,
} from "src/types/api";

type Modifiers = {
  setBuilds: () => void,
};

type Thunks = {
  getTrayBuildsForUser: string => Promise<*>,
  getPendingBuilds: () => Promise<*>,
  createBuild: CreateBuildRequest => Promise<*>,
  updateBuild: (id: string, req: UpdateBuildRequest) => Promise<*>,
  getDetailedBuild: string => Promise<*>,
};

type ReducerProps = {
  builds: Array<Build>,
};

type SelectorProps = {
  pendingBuilds: Array<Build>,
};

type Actions = Modifiers & Thunks;

export type Props = ReducerProps &
  SelectorProps & {
    actions: Actions,
  };

// @ky - 08/13/2019
// NOTE: ModifierKeys type may not be used anywhere
export type ModifierKeys = { [$Keys<Modifiers>]: $Keys<Modifiers> };
type ReducerItem<T, A> = [T, { [$Keys<Modifiers>]: ReducerFunc<T, A> }];
type Reducer = $ObjMap<ReducerProps, <T>() => ReducerItem<T, *>>;
*/

export const BuildsEnhancer = kea({
  actions: () => ({
    setBuilds: builds => builds,
    setDetailedBuild: b => b,
    setTrayBuilds: tb => tb,

    getPendingBuildsStart: () => null,
    getPendingBuildsEnd: () => null,

    createBuildStart: () => null,
    createBuildError: () => null,
    createBuildEnd: () => null,
  }),

  reducers: ({ actions }) => ({
    detailedTrayBuilds: [
      {},
      {
        [actions.setDetailedBuild]: (state, build) =>
          Object.assign({}, state, {
            [build.id]: build,
          }),
      },
    ],

    trayBuilds: [
      [],
      {
        [actions.setTrayBuilds]: (prev, builds) => {
          const ids = {};
          return prev.concat(builds).filter(b => {
            if (ids[b.id]) {
              return false;
            }
            ids[b.id] = true;
            return true;
          });
        },

        [actions.setBuilds]: (prev, builds) => {
          // Add all tray builds to
          const ids = {};
          return prev
            .concat(builds.map(b => b.tray_builds || []).flatten())
            .filter(b => {
              if (ids[b.id]) {
                return false;
              }
              ids[b.id] = true;
              return true;
            });
        },
      },
    ],

    builds: [
      [],
      {
        [actions.setBuilds]: (prev, builds) => {
          const ids = {};
          // We can't necessarily use a set as each build object may have a
          // different reference.  Therefore, dedupe based off of a filter.
          return prev.concat(builds).filter(b => {
            b.tray_builds = (b.tray_builds || []).map(tb => {
              tb.build = b;
              return tb;
            }); // eslint-disable-line
            if (ids[b.id]) {
              return false;
            }
            ids[b.id] = true;
            return true;
          });
        },
      },
    ],
  }),

  selectors: ({ selectors }) => ({
    pendingBuilds: [
      () => [selectors.builds],
      builds => {
        // Select all internal unprinted builds and external unsent builds.
        return builds.filter(b => !b.sent_at);
      },
    ],
    sentBuilds: [
      () => [selectors.builds],
      builds => {
        return builds.filter(b => !!b.sent_at && !b.actual_receipt_at);
      },
    ],
    receivedBuilds: [
      () => [selectors.builds],
      builds => {
        return builds.filter(b => !!b.actual_receipt_at);
      },
    ],
  }),

  thunks: ({ actions }) => ({
    // This allows us to fetch all a list of all tray builds that a user has;
    // essentially listing how each tray has been printed for the user
    getTrayBuildsForUser: async userId => {
      const trayBuilds = await request(
        `/api/v1/manufacturing/tray_builds?user_id=${userId}`
      );
      if (Array.isArray(trayBuilds)) {
        actions.setTrayBuilds(trayBuilds);
      }
    },

    getPendingBuilds: async () => {
      actions.getPendingBuildsStart();

      // And get all builds which have not been sent to the producer or printer
      const builds = await request(
        `/api/v1/manufacturing/builds?sent_at=false`
      );

      if (Array.isArray(builds)) {
        actions.setBuilds(builds);
      }
      actions.getPendingBuildsEnd();
    },

    getBuilds: async () => {
      actions.getPendingBuildsStart();
      // And get all builds which have not been sent to the producer or printer
      const builds = await request(`/api/v1/manufacturing/builds`);
      if (Array.isArray(builds)) {
        actions.setBuilds(builds);
      }
      actions.getPendingBuildsEnd();
    },

    createBuild: async req => {
      const hide = message.loading("Creating manufacturing build");
      actions.createBuildStart();
      const res = await request(`/api/v1/manufacturing/builds`, {
        method: "POST",
        body: JSON.stringify(req),
      });
      hide();
      if (res.id) {
        message.success("Build created");
        actions.setBuilds([res]);
        actions.createBuildEnd();
        return res;
      }

      message.error(`Build failed: ${res.error}`);
      actions.createBuildError();
      return res;
    },

    createBuildV2: async req => {
      const hide = message.loading("Creating manufacturing build");
      actions.createBuildStart();
      const res = await request(`/api/v2/manufacturing/builds`, {
        method: "POST",
        body: JSON.stringify(req),
      });
      hide();
      if (res.id) {
        message.success("Build created");
        actions.setBuilds([res]);
        actions.createBuildEnd();
        return res;
      }

      message.error(`Build failed: ${res.error}`);
      actions.createBuildError();
      return res;
    },

    updateBuild: async (buildId, req) => {
      const res = await request(`/api/v1/manufacturing/builds/${buildId}`, {
        method: "PUT",
        body: JSON.stringify(req),
      });
      if (res.id) {
        actions.setBuilds([res]);
      }
    },

    deleteBuild: async buildId => {
      await request(`/api/v2/manufacturing/builds/${buildId}`, {
        method: "DELETE",
      });
    },

    getDetailedBuild: async buildId => {
      const res = await request(`/api/v1/manufacturing/builds/${buildId}`);
      if (res.id) {
        actions.setDetailedBuild(res);
      }
      return res;
    },
  }),
});
