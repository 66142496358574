import { useMutation } from "src/utils/http/gqlQuery";
import { taskFields } from "../../queries";

export const useApproveTreatmentPlan = () => {
  const [, execute] = useMutation(approveTreatmentPlan);
  return execute;
};

const approveTreatmentPlan = `
  mutation ApproveTreatmentPlan($taskID: ID!) {
    approveTreatmentPlan(taskID: $taskID) {
      ${taskFields}
    }
  }
`;

export const useRejectTreatmentPlan = () => {
  const [, execute] = useMutation(rejectTreatmentPlan);
  return execute;
};

const rejectTreatmentPlan = `
  mutation RejectTreatmentPlan($taskID: ID!) {
    rejectTreatmentPlan(taskID: $taskID) {
      ${taskFields}
    }
  }
`;

export const useApproveReviewTreatmentPlan = () => {
  const [, execute] = useMutation(approveReviewTreatmentPlan);
  return execute;
};

const approveReviewTreatmentPlan = `
  mutation ApproveReviewTreatmentPlan($taskID: ID!) {
    approveReviewTreatmentPlan(taskID: $taskID) {
      ${taskFields}
    }
  }
`;

export const useRejectReviewTreatmentPlan = () => {
  const [, execute] = useMutation(rejectReviewTreatmentPlan);
  return execute;
};

const rejectReviewTreatmentPlan = `
  mutation RejectReviewTreatmentPlan($taskID: ID!) {
    rejectReviewTreatmentPlan(taskID: $taskID) {
      ${taskFields}
    }
  }
`;
