import React from "react";
import styled from "react-emotion";
import { DetailedPatient } from "src/types/api";
import ExternalSpecialists from "./ExternalSpecialists";

import Documents from "./Documents";

const Container = styled.div`
  display: flex;
  flex-flow: row;

  > div {
    flex: 1;
  }

  > div + div {
    margin-left: 24px;
  }
`;

type Props = {
  patient: DetailedPatient;
};

const ExternalCareOverview: React.FC<Props> = ({ patient }) => (
  <Container>
    <ExternalSpecialists patientID={patient.id} />
    <Documents patientID={patient.id} />
  </Container>
);

export default ExternalCareOverview;
