import React from "react";

type Props = {
  verifiedSpecialist: {
    id: string;
    type: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    phone2: string;
    clinicName: string;
    address: {
      line1: string;
      line2: string;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  };
};

const Item = ({ verifiedSpecialist }: Props) => {
  return (
    <div>
      <span style={{ display: "block" }}>
        {verifiedSpecialist.clinicName}, {verifiedSpecialist.firstName}{" "}
        {verifiedSpecialist.lastName}
      </span>
      <small>
        Tel: {verifiedSpecialist.phone}, Tel2: {verifiedSpecialist.phone2},
        email: {verifiedSpecialist.email}
      </small>
    </div>
  );
};

export default Item;
