import React, { useState, useEffect } from "react";
import { StripeProvider } from "react-stripe-elements";

declare global {
  interface Window {
    // Global SDK
    Stripe: any;
  }
}

const APIKey =
  process.env.REACT_APP_STRIPE_KEY || "pk_test_5Uyvze7dc28KinHdmq1t3JuS";

const Provider: React.FC = props => {
  const [stripe, setStripe] = useState<any>(null);

  useEffect(() => {
    // Only inject once
    if (!window.Stripe) {
      const el = document.createElement("script");
      el.src = "https://js.stripe.com/v3/";
      document.body.appendChild(el);

      el.onload = () => {
        setStripe(window.Stripe(APIKey));
      };
      return;
    }

    setStripe(window.Stripe(APIKey));
  }, []);

  return <StripeProvider stripe={stripe}>{props.children}</StripeProvider>;
};

export default Provider;
