export const download = async (
  path: string,
  withoutHeaders: boolean = false,
  tries: number = 0
): Promise<Blob> => {
  let url = path;
  if (url.indexOf("://") === -1) {
    url = `${process.env.REACT_APP_API_HOST}/${path}`;
  }

  // Fuck request, fuck whatwg, fuck their shitty decisions.
  //
  // Sometimes we'll be downloading files that are hosted on S3.  Patient files.  Files
  // that we get by hitting our api (/api/v2/files/{id}).  Which redirects to S3:
  //
  // api -> 303 -> S3.
  //
  // To download the file we need to remove the authorization header from the redirected
  // request.  Theres no way to do this with fetch nicely...
  //
  // There's no way to manually handle the redirection.  INSANE.  You CAN specify
  // `redirect: manual` on the request/fetch.  BUT GUESS WHAT! IT IGNORES THE REDIRECT
  // AND YOU CAN'T MANUALLY FOLLOW IT BECAUSE IT DOESN'T GIVE YOU THE NEW LOCATION.
  // ARE. YOU. KIDDING. ME.
  //
  // That, and... fetch doesn't do progress.  Of course it doesn't.  Another feature
  // they're snoozing on that microsoft had IN THE 90's!!!
  //
  //    alskjhdfgsdkyflhuijoshdbfklnahsyvf
  //
  // We can remove this status/url match if we rely on cookies for auth, as we won't
  // need to send headers to make any requests to our API.
  //
  // https://github.com/whatwg/fetch/issues/763#issuecomment-397263573 for more info.

  let headers = {};
  if (!withoutHeaders) {
    headers = {
      authorization: `Bearer ${window.localStorage.getItem("auth_token")}`,
    };
  }

  var res;

  try {
    const request = new Request(url, { headers });
    res = await fetch(request);
  } catch (err) {
    throw err;
  }

  if (res.status !== 200 && res.url !== url) {
    if (tries > 1) {
      throw new Error("Too many retries");
    }
    // We've been handed a redirect.  Make a new request without HTTP headers.
    return await download(res.url, true, tries + 1);
  }

  return await res.blob();
};

const autoDownload = async (path: string, id: string, filename: string) => {
  let blob;
  if (filename.includes(".txp")) {
    blob = await getTXP(id);
  } else {
    blob = await download(path);
  }
  createDownload(blob, filename);
};

export default autoDownload;

export const getTXP = async (id: string): Promise<Blob> => {
  const url = `${process.env.REACT_APP_API_HOST}/api/v2/files/${id}?txp=true`;

  let headers = {
    authorization: `Bearer ${window.localStorage.getItem("auth_token")}`,
  };

  const request = new Request(url, { headers });
  const res = await fetch(request);

  if (res.status !== 200) {
    throw new Error("EMR response != 200");
  }

  const body = await res.json();

  const s3BlobRequest = new Request(body.url);
  const s3Blob = await fetch(s3BlobRequest);

  return await s3Blob.blob();
};

const createDownload = (blob: Blob | null, filename: string) => {
  if (blob === null) {
    throw new Error("blob recieved was null");
  }

  // create anchor element on-the-fly here because we don't
  // query for blob data until user presses "download" button
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  // clean up DOM after download
  link.remove();
  URL.revokeObjectURL(link.href);
};
