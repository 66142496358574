import React, { useState, useMemo } from "react";
import { DateTime } from "luxon";
import { notification } from "antd";
import styled from "react-emotion";

import { useQuery } from "src/utils/http/gqlQuery";
import { url } from "src/shared/util";
import Box from "src/shared/Box";
import Button from "src/shared/Button";
import Loading from "src/shared/Loading";
import Modal from "src/shared/Modal";
import color from "src/styles/color";

interface Props {
  buttonText: string;
  generatedMessage: string;
  getURL: (id: string) => string;
  onClose: () => void;
  patientID: string;
  refetch: () => void;
  title: string;
}

const query = `
query BVSubmissions($userID: ID!) {
  submissions(userID: $userID) {
    id
    form { slug }
    approvedAt
  }
}
`;

const BVModal = (props: Props) => {
  const [{ fetching, data }] = useQuery({
    query,
    variables: { userID: props.patientID },
  });
  const [loading, setLoading] = useState(false);

  const subs = useMemo(() => {
    return (
      data &&
      data.submissions.filter(
        s => s.form.slug === "beginning_visit" && !!s.approvedAt
      )
    );
  }, [data]);

  const onClick = async (id: string) => {
    if (loading) {
      return false;
    }

    setLoading(true);
    const request = await fetch(url(props.getURL(id)), {
      credentials: "include",
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("auth_token")}`,
      },
    });
    setLoading(false);

    if (!request.ok) {
      const result = await request.json();
      return notification.error({ message: result.error });
    }

    notification.success({ message: props.generatedMessage });
    props.refetch();
    props.onClose();
  };

  return (
    <Modal onClose={props.onClose}>
      {fetching || !data ? (
        <Box title="Loading">
          <Loading />
        </Box>
      ) : (
        <Box title={props.title}>
          {subs.length === 0 && <p>No approved beginning visits</p>}
          {subs.map(s => (
            <ListItem>
              <p>
                Beginning visit, approved{" "}
                <b>{DateTime.fromISO(s.approvedAt).toLocaleString()}</b>
              </p>

              <Button loading={loading} onClick={() => onClick(s.id)}>
                {loading ? "Loading..." : props.buttonText}
              </Button>
            </ListItem>
          ))}
        </Box>
      )}
    </Modal>
  );
};

export default BVModal;

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 15px;

  min-width: 400px;

  & + & {
    border-top: 1px solid ${color.borderLight};
  }

  p {
    margin: 0;
    padding-right: 80px;
  }
`;
