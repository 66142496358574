import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm, formValues } from "redux-form";
import styles from "./doctor-input-style";
import {
  RadioButton,
  RadioButtonGroup,
  RaisedButton,
  TextField,
} from "material-ui";
import StructuredMessages from "../../../../components/structured-messages/structured-messages";
import AppLinks from "../../../../components/app-links/app-links";

const FORM_NAME = "chatForm";
export const MESSAGE = "message";
export const SMS = "sms";
export const EMAIL = "email";
export const APP_ONLY = "app_only";
export const SEND_BY = "sendBy";

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    hintText={label}
    floatingLabelText={label}
    errorText={touched && error}
    {...input}
    {...custom}
  />
);
const renderRadioGroup = ({ input, ...rest }) => (
  <RadioButtonGroup
    {...input}
    {...rest}
    valueSelected={input.value || undefined}
    onChange={(event, value) => input.onChange(value)}
  />
);

const form = reduxForm({
  form: FORM_NAME,
});
const v1 = formValues(MESSAGE);
const v2 = formValues(SEND_BY);

class ChatForm extends Component {
  static contextTypes = {
    patient: PropTypes.object,
  };
  state = { showStructuredMessage: false };

  handleSubmit = e => {
    e.preventDefault();
    const { handleSubmit, reset } = this.props;
    handleSubmit(e);
    reset();
  };
  showStructuredMessage = () => {
    this.setState({ showStructuredMessage: true });
  };
  selectStructuredMessage = message => {
    this.props.change(MESSAGE, message);
    this.setState({ showStructuredMessage: false });
  };
  attachAppLink = appLink => {
    const message = this.props[MESSAGE];
    this.props.change(
      MESSAGE,
      `${message || ""}

~~~
link to: ${appLink.to}
link text: ${appLink.text}`
    );
  };

  render() {
    const sendBySMS = this.props.sendBy === SMS;
    return (
      <form>
        <div style={styles.noteText}>
          <div style={styles.chatBubbleLookAlike}>
            <Field
              id={MESSAGE}
              name={MESSAGE}
              hintText="Message to patient"
              multiLine
              fullWidth
              component={renderTextField}
            />
          </div>
          <AppLinks onSelect={this.attachAppLink} />
          {this.state.showStructuredMessage ? (
            <div style={styles.structuredMessageContainer}>
              <StructuredMessages
                patient={this.context.patient}
                onSelect={this.selectStructuredMessage}
              />
            </div>
          ) : (
            <RaisedButton
              type="button"
              label="Structured Message"
              onClick={this.showStructuredMessage}
            />
          )}
          <div style={styles.sendBy}>
            <Field name={SEND_BY} component={renderRadioGroup}>
              <RadioButton
                style={styles.radio}
                value={APP_ONLY}
                label=" App only"
              />
              <RadioButton style={styles.radio} value={EMAIL} label=" Email" />
              <RadioButton style={styles.radio} value={SMS} label="SMS" />
            </Field>
          </div>
          <div style={styles.noteSubmitContainer}>
            <RaisedButton
              onClick={this.handleSubmit}
              id="chat-submit"
              type="submit"
              label={sendBySMS ? "Send by SMS!" : "Send as chat"}
              secondary={sendBySMS}
              primary={!sendBySMS}
              disabled={this.props.sendAsChatDisabled}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default form(v1(v2(ChatForm)));
