import React from "react";
import BVModal from "./BVModal";

interface Props {
  patientID: string;
  onClose: () => void;
  refetch: () => void;
}

const Clearance = (props: Props) => (
  <BVModal
    buttonText="Create"
    generatedMessage="Clearance form generated"
    getURL={(id: string) => `/api/v2/clearance/${id}/pdf`}
    onClose={props.onClose}
    patientID={props.patientID}
    refetch={props.refetch}
    title="Select a BV for the clearance form"
  />
);

export default Clearance;
