import React, { useState, useEffect } from "react";
import styled, { css } from "react-emotion";
import color from "src/styles/color";
import Row from "./Row";
import DownArrow from "src/shared/Icons/DownArrow";
import { titleCase, toggle } from "src/shared/util";
// popovers
import Popover from "src/shared/Popover";
import SelectList from "src/shared/SelectList";
import PatientSearchPopover from "src/shared/Popovers/PatientSearch";
import StaffPopover from "src/shared/Popovers/StaffPopover";
import PipelineTypePopover from "src/shared/Popovers/PipelineTypePopover";
// types
import { BaseUser } from "src/shared/PatientSearch/PatientSearch";
import { Staff } from "src/types/gql";
import {
  PipelineStatus,
  Pipeline,
  PipelineFilter,
  PipelineSortFields,
} from "../types";
import PipelineRow from "./PipelineRow";

type Props = {
  urlPrefix?: string;
  pipelines: Pipeline[];
  // routeFilter is needed for proper linking
  routeFilter: string;
  onUpdateFilter: (f: PipelineFilter) => void;
};

const Table: React.FC<Props> = ({
  pipelines,
  routeFilter,
  onUpdateFilter,
  urlPrefix,
}) => {
  const [sortAsc, setSortAsc] = useState(true);
  const [assignedStaff, setAssignedStaff] = useState<Staff[]>([] as Staff[]);
  const [patient, setPatient] = useState<BaseUser | undefined>();
  const [statusFilter, setStatusFilter] = useState<
    PipelineStatus | undefined
  >();
  const [types, setTypes] = useState<string[]>([] as string[]);

  useEffect(() => {
    const filter: PipelineFilter = {
      sortOpt: {
        sortDescending: !sortAsc,
        sortField: PipelineSortFields.DueAt,
      },
    };
    if (patient) {
      filter.userID = patient.id;
    }
    if (assignedStaff.length > 0) {
      filter.assigneeIDs = assignedStaff.map(s => s.id);
    }
    if (types.length > 0) {
      filter.types = types;
    }
    if (statusFilter && routeFilter !== "completed") {
      filter.status = statusFilter;
    }
    onUpdateFilter(filter);
    // eslint-disable-next-line
  }, [assignedStaff, patient, types, sortAsc, statusFilter]);

  return (
    <Wrapper>
      <Row className={headerCSS}>
        <div>
          <PatientSearchPopover value={patient} onClick={setPatient}>
            <Inner>
              Patient{" "}
              <DownArrow fill={!!patient ? color.primary : color.gray2} />
            </Inner>
          </PatientSearchPopover>
        </div>
        <div>Home clinic</div>
        <div>
          <PipelineTypePopover
            onClick={t => {
              setTypes(toggle<string>(types, t as string));
            }}
            value={types}
          >
            Type{" "}
            <DownArrow fill={types.length > 0 ? color.primary : color.gray2} />
          </PipelineTypePopover>
        </div>
        <div>
          {routeFilter !== "my" ? (
            <StaffPopover
              onClick={(s?: Staff) => {
                if (!s) {
                  return;
                }
                setAssignedStaff(toggle(assignedStaff, s));
              }}
              value={assignedStaff}
            >
              <Inner>
                Assignee{" "}
                <DownArrow
                  fill={assignedStaff.length > 0 ? color.primary : color.gray2}
                />
              </Inner>
            </StaffPopover>
          ) : (
            <Inner>Assignee</Inner>
          )}
        </div>
        <div>Start date</div>
        <DueDate onClick={() => setSortAsc(!sortAsc)}>
          Weeks
          <span className={sortAsc ? rotated : ""}>
            <DownArrow fill={color.primary} />
          </span>
        </DueDate>
        <div>
          {routeFilter !== "completed" && (
            <Popover
              content={
                <SelectList<any>
                  values={["unblocked", "blocked", "blocking"]}
                  value={[statusFilter] as Array<any>}
                  onClick={val => setStatusFilter(val)}
                  render={(c: any) => titleCase(c || "")}
                />
              }
            >
              <Inner>
                Progress
                <DownArrow fill={statusFilter ? color.primary : color.gray2} />
              </Inner>
            </Popover>
          )}
        </div>
      </Row>

      {pipelines.map(p => (
        <PipelineRow
          pipeline={p}
          key={p.id}
          routeFilter={routeFilter || "my"}
          urlPrefix={urlPrefix}
        />
      ))}
    </Wrapper>
  );
};

export default Table;

const Wrapper = styled.div`
  margin: 8px 0;
  border: 1px solid ${color.borderLight};
  border-radius: 3px;
`;

const headerCSS = css`
  font-weight: bold;
  border-top: 0;
`;

const Inner = styled.div`
  cursor: pointer;

  svg {
    margin: 0 0 0 6px;
  }
`;

const DueDate = styled.div`
  cursor: pointer;
  span {
    margin-left: 8px;
  }
`;

const rotated = css`
  > svg {
    transform: rotate(180deg);
  }
`;
