import styled from "react-emotion";

export const Background = styled.div`
  z-index: 999;

  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(135, 135, 135, 0.5);

  > div {
    max-height: 100vh;
    background: #fff;
    position: relative;
    z-index: 2;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.22),
      0px 19px 38px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }
`;
