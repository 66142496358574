import styled from "react-emotion";
import color from "src/styles/color";

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 16px 0;
  overflow-x: hidden;
  overflow: auto;
  color: #194f5b;

  > div {
    padding: 6px 24px 6px 36px;

    &:hover {
      cursor: pointer;
      background: ${color.gray1}99;
    }
  }

  svg {
    margin-left: -24px;
    margin-right: 12px;
  }

  .checked {
    background: ${color.gray1};
  }
`;

export const ListHeading = styled.span`
  font-weight: bold;
  padding: 6px 24px 6px 36px;
`;
