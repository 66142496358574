const LOAD = "consent/LOAD";
const LOAD_SUCCESS = "consent/LOAD_SUCCESS";
const LOAD_FAIL = "consent/LOAD_FAIL";
const POST = "consent/POST";
const POST_SUCCESS = "consent/POST_SUCCESS";
const POST_FAIL = "consent/POST_FAIL";

const CONSENT_API = "/api/v1/patient_consents";

const initialState = {
  data: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS:
      return {
        ...state,
        data: action.result,
        error: null,
      };
    case LOAD_FAIL:
      return {
        ...state,
        data: null,
        error: action.error,
      };
    default:
      return state;
  }
}

export function load(userId) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => client.get(`${CONSENT_API}?user_id=${userId}`),
  };
}
export function post(userId, formTypes) {
  return {
    types: [POST, POST_SUCCESS, POST_FAIL],
    promise: client =>
      client.post(CONSENT_API, {
        data: {
          user_id: userId,
          method_type: "piv",
          form_types: formTypes,
        },
      }),
  };
}
