import React, { PureComponent } from "react";
import type { Checkin, PeriodicCheckin } from "types";

type Props = {
  checkin: Checkin,
};

export default class PeriodicCheckinView extends PureComponent<Props> {
  render() {
    const { checkin } = this.props;
    const data = (checkin.data: PeriodicCheckin);
    return (
      <div>
        <p>
          {data.is_healthy ? <b>Healthy </b> : <b>Unhealthy </b>}
          checkin for {checkin.date}.<br />
          Estimated hours worn: ~{data.est_hours_worn}
        </p>
      </div>
    );
  }
}
