import React, { Component } from "react";
import { resetPassword } from "src/redux/modules/auth";
import { connect } from "react-redux";

import ResetPasswordForm from "./form";
import styles from "./reset-password-style";

const enhance = connect(
  state => ({
    authError: state.auth.error,
  }),
  { resetPassword }
);

class ResetPassword extends Component {
  handleReset = ({ oldPassword, newPassword }) => {
    this.props.resetPassword({ oldPassword, newPassword }).then(() => {
      this.props.router.push("/");
    });
  };

  render() {
    const { authError } = this.props;
    return (
      <div style={styles.loginContainer}>
        <div>
          <h2>Reset Password</h2>
          <ul>
            <li>Do not use a previously used password.</li>
            <li>Password must be more than 12 characters long.</li>
            <li>
              Avoid keeping a record of passwords, unless this can be stored
              securely and the method of storing has been approved.
            </li>
            <li>Do not provide their password to anyone for any reason.</li>
            <li>
              Do not use the same password for business and non-business
              purposes.
            </li>
            <li>
              At least four (4) changed characters are changed when new
              passwords are created.
            </li>
            <li>
              Passwords contain a combination of alphabetic, upper and lower
              case characters, numbers
            </li>
          </ul>
        </div>
        <ResetPasswordForm onSubmit={this.handleReset} />
        {authError && <div>{authError.error}</div>}
      </div>
    );
  }
}

export default enhance(ResetPassword);
