import styled from "react-emotion";
import color from "src/styles/color";

export const SelectedTab = styled.div``;

export const TreatmentOverviewContainer = styled.div`
  margin: 22px 40px;
`;

export const TreatmentTile = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
`;

export const TreatmentDashboard = styled.div`
  height: 315px;
  border: 1px solid ${color.border};
  background: ${color.white};
`;
