import React from "react";
import { Pipeline } from "../types";
import styled from "react-emotion";
import color from "src/styles/color";

type Props = {
  pipeline: Pipeline;
};

export default ({ pipeline }: Props) => {
  const { taskCounts } = pipeline;
  const sum = taskCounts.remaining + taskCounts.started + taskCounts.completed;
  const done = (taskCounts.completed / sum) * 100;
  const started = (taskCounts.started / sum) * 100;

  const onHold = pipeline.onHoldReasonV2 != null;

  return (
    <Wrapper>
      {onHold ? (
        <span>
          {pipeline.onHoldReasonV2 &&
            pipeline.onHoldReasonV2.split("\n").map(partialReason => (
              <div>
                <Hold>{partialReason}</Hold>
              </div>
            ))}
        </span>
      ) : (
        <span>
          {Math.round(done)}% complete{" "}
          {started > 0 && `, ${Math.round(started)}% in progress`}
        </span>
      )}
      <Progress>
        <Completed
          style={{
            width: `${done}%`,
            background: onHold ? color.red : "#7cc993",
          }}
        />
        <Started
          style={{
            width: `${started}%`,
            background: onHold ? color.red : "#7cc993",
          }}
        />
      </Progress>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  flex-direction: column;

  span {
    font-size: 11px;
    margin: 0 0 4px;
  }
`;
const Progress = styled.div`
  display: flex;
  height: 12px;
  width: 100%;
  align-items: stretch;
  background: ${color.gray2};
  border-radius: 3px;
  overflow: hidden;
`;

const Completed = styled.div`
  background: #7cc993;
`;

const Started = styled.div`
  opacity: 0.5;
`;

const Hold = styled.span`
  color: ${color.red};
`;
