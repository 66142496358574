/**
 * DEPRECATED!
 * this file was used to handle chat with Layer. we no longer use layer as of Dec 2017
 * Do not add any additional functions in here. We will be removing this file.
 */

import moment from "moment";
import superagent from "superagent";
import sortBy from "lodash/sortBy";
import uuidV4 from "uuid/v4";

const LAYER_AUTH_PRE = "chat/layer-auth";
const LAYER_AUTH_SUCCESS = "chat/layer-auth-success";
const LAYER_AUTH_FAIL = "chat/layer-auth-fail";
const LAYER_CONVERSATIONS_PRE = "chat/layer-conversations";
const LAYER_CONVERSATIONS_SUCCESS = "chat/layer-conversations-success";
const LAYER_CONVERSATIONS_FAIL = "chat/layer-conversations-fail";
const LAYER_MESSAGES_PRE = "chat/layer-messages-pre";
const LAYER_MESSAGES_SUCCESS = "chat/layer-messages-success";
const LAYER_MESSAGES_FAIL = "chat/layer-messages-fail";
const LAYER_ADD_MESSAGE = "chat/layer-add-message";
const LAYER_SEND_PRE = "chat/layer-send-pre";
const LAYER_SEND_SUCCESS = "chat/layer-send-success";
const LAYER_SEND_FAIL = "chat/layer-send-fail";

const normalizeParticipants = (peeps = []) => {
  return peeps.reduce((acc, person) => {
    acc[person.user_id] = person; // eslint-disable-line no-param-reassign
    return acc;
  }, {});
};

// normalizeMessage ensures that we parse the first message part of the message
// (the client's sent at date) and add it as clientSentAt.
const normalizeMessage = m => {
  return {
    ...m,
    clientSentAt: moment(parseInt(m.parts[0].body, 10)),
  };
};

// addMessages is a utility function for adding new messages to a given
// conversation in our state.
function addMessages({ state, conversationUuid, messages = [] }) {
  // get the existing conversation data
  const conversation = state.messages[conversationUuid] || {
    ids: [],
    data: {},
    isLoading: false,
    isError: false,
    error: null,
  };

  // copy the existing messages into a new object
  const data = { ...conversation.data };
  messages.forEach(m => {
    data[m.id] = normalizeMessage(m);
  });
  const ids = sortBy(data, m => 1 * m.parts[0].body).map(m => m.id);

  return {
    ...conversation,
    data,
    ids,
  };
}

const initialState = {
  layerToken: {
    data: null,
    isLoading: false,
    isError: false,
    error: null,
    fetchedAt: 0, // unix timestamp in milliseconds
  },
  conversations: {
    // conversation IDs ordered by most recent latest message > least recent
    // Note that these are layer IDs, not the individual UUIDs.
    ids: [],
    // map of conversations keyed by id to conversation object
    data: {},
    isLoading: false,
    isError: false,
    error: null,
  },

  // messages stores a list of messages for each conversation, keyed by
  // conversation UUID:
  // messages: {
  //   [conversationUuid]: {
  //     ids: [],
  //     data: {},
  //     error: null,
  //     isLoading: false,
  //     isError: false,
  //   },
  // }
  // TODO: Potentially make this a proxy when there's a good polyfill that
  // isn't slow as balls, allowing us to always return the basic
  // unfilled data structure in the above comments.  This will simplify
  // selector code and guaranteen consistency across all cases regardless
  // of whehter a conversation has been loaded.
  messages: {},

  send: {
    isSending: false,
    isError: false,
    error: null,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LAYER_AUTH_PRE:
      return {
        ...state,
        layerToken: {
          ...state.layerToken,
          isLoading: true,
        },
      };
    case LAYER_AUTH_SUCCESS:
      return {
        ...state,
        layerToken: {
          ...state.layerToken,
          isLoading: false,
          isError: false,
          error: null,
          data: action.result.layer_session_token,
          fetchedAt: new Date().getTime(),
        },
      };
    case LAYER_AUTH_FAIL:
      return {
        ...state,
        layerToken: {
          ...state.layerToken,
          isError: true,
          error: action.error,
        },
      };

    // Loading all conversations
    case LAYER_CONVERSATIONS_PRE:
      return {
        ...state,
        conversations: {
          ...state.conversations,
          isLoading: true,
        },
      };
    case LAYER_CONVERSATIONS_SUCCESS: {
      const { result } = action;
      // When loading conversations we need to update the 'conversations'
      // object within our state, and also add the conversation's latest
      // message to the 'messages' object.
      //
      // This ensures that we always pop the last ID off of the messages
      // list to get the conversation's latest message.
      const conversations = {};
      const messages = { ...state.messages };
      result.forEach(convo => {
        // add the conversation to the conversations list.
        const uuid = convo.id.replace("layer:///conversations/", "");
        conversations[convo.id] = {
          ...convo,
          participants: normalizeParticipants(convo.participants),
          uuid,
          last_message: null,
        };

        // add the message to our messages list
        messages[uuid] = addMessages({
          state,
          conversationUuid: uuid,
          messages: convo.last_message ? [convo.last_message] : [],
        });
      });

      return {
        ...state,
        conversations: {
          ...state.conversations,
          isLoading: false,
          isError: false,
          error: null,
          ids: state.conversations.ids.concat(result.map(c => c.id)),
          data: { ...state.conversations.data, ...conversations },
        },
        messages,
      };
    }
    case LAYER_CONVERSATIONS_FAIL:
      return {
        ...state,
        conversations: {
          ...state.conversations,
          isLoading: false,
          error: action.error,
        },
      };

    // Loading a specific conversation.  Each of these have a new scope
    // surrounding the case such that variables declared in each case exist
    // for the lifetime of the case only, and are not hoisted.
    case LAYER_MESSAGES_PRE: {
      const { conversationUuid } = action;
      const existing = state.messages[conversationUuid] || {};
      return {
        ...state,
        [conversationUuid]: {
          ...existing,
          isLoading: true,
        },
      };
    }
    case LAYER_MESSAGES_SUCCESS: {
      const { conversationUuid } = action;
      const messages = action.result;
      return {
        ...state,
        messages: {
          ...state.messages,
          [conversationUuid]: {
            ...addMessages({ state, conversationUuid, messages }),
            isLoading: false,
            isError: false,
            error: null,
          },
        },
      };
    }
    case LAYER_MESSAGES_FAIL: {
      // TODO
      return state;
    }
    case LAYER_SEND_PRE: {
      return {
        ...state,
        send: {
          ...state.send,
          isSending: true,
        },
      };
    }
    case LAYER_SEND_SUCCESS: {
      return {
        ...state,
        send: {
          ...state.send,
          isSending: false,
          isError: false,
          error: null,
        },
      };
    }
    case LAYER_SEND_FAIL: {
      return {
        ...state,
        send: {
          ...state.send,
          isSending: false,
          isError: true,
          error: state.error,
        },
      };
    }
    case LAYER_ADD_MESSAGE: {
      // Handle adding a new message to the given conversation.
      const { message } = action.payload;
      // we use UUIDs, not layer IDs, to map our conversations
      const conversationUuid = message.conversation.id.replace(
        "layer:///conversations/",
        ""
      );

      return {
        ...state,
        messages: {
          ...state.messages,
          [conversationUuid]: addMessages({
            state,
            conversationUuid,
            messages: [message],
          }),
        },
      };
    }
    default:
      return state;
  }
}

// getLayerToken authenticates with layer using the given user ID.
// This has no persistence it will always request a new token.
export function getLayerToken() {
  return {
    types: [LAYER_AUTH_PRE, LAYER_AUTH_SUCCESS, LAYER_AUTH_FAIL],
    promise: client => client.post("/api/v1/layer/auth"),
  };
}

export function getLayerTokenAsync(params, { store: { dispatch, getState } }) {
  const state = getState();
  if (!state.chat.layerToken.data) {
    return dispatch(getLayerToken());
  }
  return Promise.resolve();
}

function getNextSet({ dispatch, layerToken, fromConvoId }) {
  dispatch({
    types: [
      LAYER_CONVERSATIONS_PRE,
      LAYER_CONVERSATIONS_SUCCESS,
      LAYER_CONVERSATIONS_FAIL,
    ],
    promise: () => {
      return new Promise((resolve, reject) => {
        const url = fromConvoId
          ? `https://api.layer.com/conversations?sort_by=last_message&from_id=${fromConvoId}`
          : "https://api.layer.com/conversations?sort_by=last_message";
        superagent
          .get(url)
          .set("accept", "application/vnd.layer+json; version=2.0")
          .set("authorization", `Layer session-token="${layerToken}"`)
          .set("Content-Type", "application/json")
          .end((err, res) => {
            // IF the status code is 206 (partial) load up the next set of pages
            if (err) {
              reject(err);
              return;
            }
            resolve(res.body);
            // IF the status code is 206 (partial) load up the next set of pages
            if (res.statusCode === 206) {
              resolve(res.body);
              const lastId = res.body[res.body.length - 1].id;
              getNextSet({ dispatch, layerToken, fromConvoId: lastId });
            }
          });
      });
    },
  });
}

export function getLatestConversations({ layerToken }) {
  // Layer paginates conversations and limits page sizes to 100; we return
  // a function which can dispatch new actions once we fetch all pages
  return function handler(dispatch) {
    // Fetch the first conversation page
    getNextSet({ dispatch, layerToken });
  };
}

export function getConversationMessages({ layerToken, conversationUuid }) {
  return {
    types: [LAYER_MESSAGES_PRE, LAYER_MESSAGES_SUCCESS, LAYER_MESSAGES_FAIL],
    promise: () => {
      return new Promise((resolve, reject) => {
        superagent
          .get(
            `https://api.layer.com/conversations/${conversationUuid}/messages`
          )
          .set("accept", "application/vnd.layer+json; version=2.0")
          .set("authorization", `Layer session-token="${layerToken}"`)
          .set("Content-Type", "application/json")
          .end((err, res) => {
            if (!err) {
              resolve(res.body);
              return;
            }
            reject(err);
          });
      });
    },
    conversationUuid,
  };
}

// addMessage adds a new message to a specific conversation
export function addMessage({ message }) {
  return {
    type: LAYER_ADD_MESSAGE,
    payload: {
      message,
    },
  };
}

export function sendMessage({ conversationUuid, parts }) {
  const message = {
    parts,
    id: uuidV4(),
    conversation_uuid: conversationUuid,
  };

  return {
    types: [LAYER_SEND_PRE, LAYER_SEND_SUCCESS, LAYER_SEND_FAIL],
    promise: client => client.post("/api/v1/messages", { data: message }),
  };
}

export const chatActions = {
  getLayerToken,
  getLayerTokenAsync,
  getLatestConversations,
  getConversationMessages,
  addMessage,
  sendMessage,
};

export const getConversationIdsSelector = state => state.chat.conversations.ids;
export const getConversationsSelector = state => state.chat.conversations.data;
export const getPatientListSelector = state => state.patientList.data;
export const getLayerTokenSelector = state => state.chat.layerToken.data;
export const getIsChatSending = state => state.chat.send.isSending;
export const getIsChatError = state => state.chat.send.isError;
// returns whether the layer token was fetched over 5 minutes ago (incl. 10
// seconds buffer time)
// TODO: Potentially bump to 30 days in prod, though fetching a new token is no
// issue right now.
export const getIsLayerTokenStaleSelector = state => {
  return (
    new Date().getTime() - state.chat.layerToken.fetchedAt >
    5 * 60 * 1000 - 10 * 1000
  );
};
