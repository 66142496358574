// @flow
import React from "react";
import ViewFormQuestion from "./FormQuestion";
import { getSubmissionAnswer } from "./answers";

const TreatmentOption = props => {
  const { submission, form, sessionAnswers, editable, patient } = props;

  return (
    <div>
      {form.forms_questions.map((q, n) => {
        return (
          <ViewFormQuestion
            allAnswers={sessionAnswers.concat(submission.answers || [])}
            patient={patient}
            submission={submission}
            answer={getSubmissionAnswer({
              answers: submission.answers,
              questionID: q.id,
              submissionID: submission.id,
            })}
            onChange={value => props.onAnswer(q.id, value)}
            editable={editable}
            key={q.id}
            q={q}
          />
        );
      })}
    </div>
  );
};

export default TreatmentOption;
