import React, { useContext } from "react";
import { cx } from "react-emotion";
import { useFormState } from "react-use-form-state";

import Button from "src/shared/Button";
import { CheckboxGroup } from "src/shared/CheckboxGroup";
import { HomeClinicSelect } from "src/shared/HomeClinicSelect";
import { Select } from "src/shared/Select";

import { FiltersContext } from "../FiltersContext/FiltersContext";

import {
  Root,
  Header,
  Main,
  Footer,
  Heading,
  CheckboxGroupGrid,
  InputGroup,
  InputGroupHeading,
  closeButtonCss,
  submitCss,
} from "./styles";
import {
  treatmentLevelCheckboxes,
  pipelineCategoryCheckboxes,
  initialFormValues,
  treatmentLevelTitles,
  pipelineCategoryTitles,
} from "./constants";
import { Props, FormValues } from "./types";
import { useFiltersData, useFormSideEffects } from "./hooks";
import { prepareFilterValue } from "./utils";

const FiltersPanel: React.FC<Props> = props => {
  const { isOpen, onClose } = props;
  const { setFilterPanelFilters } = useContext(FiltersContext);
  const [formState, { raw }] = useFormState(initialFormValues);
  const formValues = formState.values as FormValues;
  const {
    homeClinicIDs,
    assignees,
    pipelineTypes,
    taskTypes,
    taskCustomLabels,
  } = useFiltersData(formValues);

  const handleReset = () => {
    formState.reset();
  };
  const handleSubmit = () => {
    onClose();
    setFilterPanelFilters(prepareFilterValue(formValues));
    handleReset();
  };

  useFormSideEffects(formState);

  return (
    <Root className={cx([isOpen && "visible"])}>
      <Header>
        <Heading>Filters</Heading>
      </Header>
      <Main>
        <InputGroup>
          <InputGroupHeading>Home Clinic</InputGroupHeading>
          <HomeClinicSelect
            options={homeClinicIDs}
            {...raw({
              name: "homeClinicIDs",
              touchOnChange: true,
            })}
          />
        </InputGroup>
        <InputGroup>
          <InputGroupHeading>Treatment level</InputGroupHeading>
          <CheckboxGroupGrid>
            <CheckboxGroup
              titles={treatmentLevelTitles}
              order={treatmentLevelCheckboxes}
              {...raw({
                name: "treatmentLevels",
                touchOnChange: true,
              })}
            />
          </CheckboxGroupGrid>
        </InputGroup>
        <InputGroup>
          <InputGroupHeading>Pipeline Category</InputGroupHeading>
          <CheckboxGroupGrid>
            <CheckboxGroup
              titles={pipelineCategoryTitles}
              order={pipelineCategoryCheckboxes}
              {...raw({
                name: "pipelineCategories",
                touchOnChange: true,
              })}
            />
          </CheckboxGroupGrid>
        </InputGroup>
        <InputGroup>
          <InputGroupHeading>Pipeline Type</InputGroupHeading>
          <Select
            placeholder="Type or select pipeline"
            options={pipelineTypes}
            {...raw({
              name: "pipelineTypes",
              touchOnChange: true,
            })}
          />
        </InputGroup>
        <InputGroup>
          <InputGroupHeading>Task Type</InputGroupHeading>
          <Select
            placeholder="Type or select task"
            options={taskTypes}
            {...raw({
              name: "taskTypes",
              touchOnChange: true,
            })}
          />
        </InputGroup>
        <InputGroup>
          <InputGroupHeading>Labels</InputGroupHeading>
          <Select
            placeholder="Type label here"
            options={taskCustomLabels}
            {...raw({
              name: "taskCustomLabels",
              touchOnChange: true,
            })}
          />
        </InputGroup>
        <InputGroup>
          <InputGroupHeading>Assignee</InputGroupHeading>
          <Select
            placeholder="Type or select assignee"
            options={assignees}
            {...raw({
              name: "assignees",
              touchOnChange: true,
            })}
          />
        </InputGroup>
      </Main>
      <Footer>
        <Button type="invisible" onClick={handleReset}>
          Reset
        </Button>
        <Button type="primary" className={submitCss} onClick={handleSubmit}>
          Show results
        </Button>
      </Footer>

      <Button type="clear" css={closeButtonCss} onClick={onClose} />
    </Root>
  );
};

export default FiltersPanel;
