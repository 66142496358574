import { V, S } from "src/shared-styles";

export default {
  S,
  galleryContainer: {
    display: "inline-block",
  },
  gallery: {
    overflow: "hidden",
    // marginTop: V.spacing,
    // marginRight: V.spacing,
  },

  // anchor
  thumbnail: {
    boxSizing: "border-box",
    display: "block",
    float: "left",
    lineHeight: 0,
    overflow: "hidden",
    marginBottom: V.spacingSmall,
    paddingLeft: V.spacingTiny,
    paddingRight: V.spacingTiny,
  },

  // actual <img />
  source: {
    border: 0,
    display: "block",
    height: "auto",
  },
};
