import { CombinedError, useQuery } from "src/utils/http/gqlQuery";
import { Shipment } from "src/types/gql";

export const recentShipments = `
  query RecentShipments($count: Int!) {
    recentShipments(count: $count) {
      id 
      status 
      trackingNumber 
      carrier 
      externalShipmentId
      isClinicAddress
      trackingNumber
      userId
    }
  }
`;

export const useGetRecentShipments = (
  count: number
): [boolean, CombinedError | undefined, Shipment[]] => {
  const [{ fetching, error, data }] = useQuery({
    query: recentShipments,
    variables: { count },
  });
  return [fetching, error, data && data.recentShipments];
};

export const easypostShipments = `
    query EasypostShipments($startTime: Time!, $endTime: Time!) {
        easypostShipments(startTime: $startTime, endTime: $endTime) {
            createdAt 
            user {
                id 
                firstName
                lastName 
                name
            }

            ToAddress {
                line1 
                line2 
                city 
                state 
                country
                postalCode
            }

            externalShipmentID
            status 
            trackingURL 
            data 
        }
    }
`;

export const useGetEasypostShipments = (startTime: string, endTime: string) => {
  const [{ fetching, error, data }] = useQuery({
    query: easypostShipments,
    variables: { startTime, endTime },
  });
  return [fetching, error, data ? data.easypostShipments : []];
};
