import React, { useContext, useState, useEffect } from "react";

import { useQuery } from "src/utils/http/gqlQuery";
import { Staff } from "src/types/gql";
import Loading from "src/shared/Loading";

type Features = {
  billing: boolean;
  consents: boolean;
  scheduling: boolean;
  manufacturing: boolean;
};

type Role = { id: string; role: string; organization: { id: string } };

interface SelfRoles {
  isDoctor: boolean;
  isTreatmentPlanner: boolean;
  isInternalManufacturing: boolean;
}

const empty: Staff = {
  id: "",
  name: "",
  email: "",
  roles: [],
  firstName: "",
  lastName: "",
  mobilePhoneNumber: "",
  clinics: [],
  defaultClinic: null,
  organizations: [],
};

const OrgContext = React.createContext<Staff>(empty);

export const useSelf = () => {
  return useContext(OrgContext);
};

export function useSelfRoles(): SelfRoles {
  const self = useSelf();
  const isDoctor = !!self.roles.find(({ role }) => role === "orthodontist");
  const isTreatmentPlanner = !!self.roles.find(
    ({ role }) => role === "treatment_planner"
  );
  const isInternalManufacturing = !!self.roles.find(
    ({ role }) => role === "internal_manufacturing"
  );

  return { isDoctor, isTreatmentPlanner, isInternalManufacturing };
}

export function useSchedulingRestrictions() {
  const self = useSelf();
  const isScheduleManager = !!self.roles.find(
    ({ role }) => role === "schedule_manager"
  );
  const isAdmin = !!self.roles.find(({ role }) => role === "admin");

  const canCancelBV = isScheduleManager && isAdmin;

  return { isScheduleManager, canCancelBV, isAdmin };
}

export const SelfProvider: React.FC = props => {
  const [self, setSelf] = useState<Staff>(empty);
  const [result] = useQuery({ query });

  useEffect(() => {
    if (!result.fetching && result.data) {
      setSelf(result.data.self || empty);
    }
  }, [result.fetching, result.data]);

  if (result.fetching) {
    return <Loading />;
  }

  return (
    <OrgContext.Provider value={self || empty}>
      {props.children}
    </OrgContext.Provider>
  );
};

const query = `
query StaffSelf {
  self: staffSelf {
    id
    name
    email
    roles {
      id
      role
      organization { id }
    }
    organizations {
      id
      name
      features {
        manufacturing
        scheduling
        billing
        consents
        emrAdmin
      }
    }
    defaultClinic {
      id
      name
      nickname
    }
  }
}
`;

type FeatureKeys =
  | "scheduling"
  | "billing"
  | "manufacturing"
  | "consents"
  | "emrAdmin";

// hasFeature is a utility which returns whether the currently logged in user self has the
// given feature enabled.
export const hasFeature = (f: FeatureKeys, s: Staff = {} as Staff): boolean => {
  return (
    (s.organizations || []).find(o => o.features[f] === true) !== undefined
  );
};

// the most used component
export default useSelf;
