import React from "react";
import { notification } from "antd";
import ConfirmModal from "src/shared/ConfirmModal";
import { Charge } from "@/types/gql";
import { useDeletePaymentRecord } from "../query";

type Props = {
  charge: Charge;
  onClose: () => void;
  refetchOrders?: () => void;
};

const DeletePaymentRecordModal: React.FC<Props> = ({
  charge,
  onClose,
  refetchOrders,
}) => {
  const deletePaymentRecord = useDeletePaymentRecord();

  const onClick = async () => {
    if (!charge) return;

    const result = await deletePaymentRecord({
      input: {
        type: charge.paymentMethod.type,
        chargeID: charge.id,
      },
    });

    if (!result || result.error) {
      notification.error({
        message: "There was an error deleteing the payment.",
      });
      return;
    }
    notification.success({ message: "Payment removed successfully" });

    if (refetchOrders) {
      refetchOrders();
    }

    onClose();
  };

  return (
    <ConfirmModal
      onClose={onClose}
      onConfirm={onClick}
      type="delete"
      title="Are you sure?"
      text="Do you really want to remove this payment? This process cannt be undone."
      actionText="Delete"
    />
  );
};

export default DeletePaymentRecordModal;
