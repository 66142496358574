import React, { useState } from "react";
import { DateTime } from "luxon";
import { notification } from "antd";
import styled from "react-emotion";

import { useQuery } from "src/utils/http/gqlQuery";
import { titleCase, url } from "src/shared/util";
import Box from "src/shared/Box";
import Button from "src/shared/Button";
import Loading from "src/shared/Loading";
import Modal from "src/shared/Modal";
import color from "src/styles/color";

interface PassedProps {
  patientID: string;
  onClose: () => void;
  refetch: () => void;
}

const query = `
query BVSubmissions($userID: ID!) {
  activeTreatment(userID: $userID) {
    referrals {
      id
      createdAt
      referralType
      referralReason
    }
  }
}
`;

const Referrals = (props: PassedProps) => {
  const [{ fetching, data }] = useQuery({
    query,
    variables: { userID: props.patientID },
  });
  const [loading, setLoading] = useState(false);

  const referrals = data ? data.activeTreatment.referrals : [];

  const onClick = async (id: string) => {
    if (loading) {
      return false;
    }

    setLoading(true);
    const request = await fetch(url(`/api/v2/referrals/${id}/pdf`), {
      credentials: "include",
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("auth_token")}`,
      },
    });
    setLoading(false);

    if (!request.ok) {
      const result = await request.json();
      notification.error({ message: result.error });
    }

    notification.success({ message: "Referral generated" });
    props.refetch();
    props.onClose();
  };

  return (
    <Modal onClose={props.onClose}>
      {fetching || !data ? (
        <Box title="Loading">
          <Loading />
        </Box>
      ) : (
        <Box title="Select a BV for the new referral">
          {referrals.length === 0 && <p>No treatment referrals</p>}
          {referrals.map(r => (
            <ListItem>
              <p>
                {titleCase(r.referralType)} referral, created{" "}
                <b>{DateTime.fromISO(r.createdAt).toLocaleString()}</b>
              </p>

              <Button loading={loading} onClick={() => onClick(r.id)}>
                {loading ? "Loading..." : "Generate referral"}
              </Button>
            </ListItem>
          ))}
        </Box>
      )}
    </Modal>
  );
};

export default Referrals;

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 15px;

  min-width: 400px;

  & + & {
    border-top: 1px solid ${color.borderLight};
  }

  p {
    margin: 0;
    padding-right: 80px;
  }
`;
