import styled from "react-emotion";
import color from "src/styles/color";

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row;

  > div:nth-child(1) {
    color: ${color.gray3};
    font-weight: 600;
    margin-right: 27px;
    width: 100px;
  }
`;
