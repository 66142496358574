import React, { useContext } from "react";
import { DateTime } from "luxon";

import { AppointmentType } from "src/types/api";
import { Clinic, Appointment } from "src/types/gql";
import useAppointmentTypes from "../useAppointmentTypes";

export type ContextType = {
  date: DateTime;

  appointments: Array<Appointment>;

  clinic: Clinic;

  timezone: string;

  // The appointment types available to choose
  types: Array<AppointmentType>;

  viewDoctorOnly: boolean;
  selectedDoctorId?: string;
  view: string;
};

const CalendarViewContext = React.createContext<ContextType>({
  // This will always be set.
  clinic: {} as Clinic,
  date: {} as DateTime,
  appointments: [],
  // Updating unsaved slots needs timezone information
  timezone: "",
  types: [],
  viewDoctorOnly: false,
  selectedDoctorId: undefined,
  view: "Day",
});

export const CalendarViewContextProvider = props => {
  const {
    clinic,
    view,
    unsaved,
    onUpdateUnsaved,
    appointments,
    date,
    viewDoctorOnly = false,
    selectedDoctorId,
  } = props;

  const appointmentTypes = useAppointmentTypes();

  const value = {
    date,
    appointments,
    clinic,
    timezone: clinic.timezone,
    types: appointmentTypes,
    onUpdateUnsaved,
    unsaved: unsaved || [],
    viewDoctorOnly,
    selectedDoctorId,
    view,
  };

  return (
    <CalendarViewContext.Provider value={value}>
      {props.children}
    </CalendarViewContext.Provider>
  );
};

export default function useCalendarViewContext() {
  return useContext(CalendarViewContext);
}
