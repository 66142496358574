import styled, { css } from "react-emotion";
import color from "src/styles/color";

export const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 32px;
  height: 19px;
`;

export const Slider = css`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 11px;
    width: 11px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 50%;
  }
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: ${color.primary};
  }

  &:focus + span {
    box-shadow: 0 0 1px ${color.primary};
  }

  &:checked + span:before {
    transform: translateX(13px);
  }
`;

export const Span = styled.span``;
