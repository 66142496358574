import React from "react";
import Popover from "src/shared/Popover";
import SelectList from "src/shared/SelectList";
import Category, { categories } from "src/scenes/Tasks/Category";
import { TaskCategory } from "src/scenes/Tasks/types";

type Props = {
  allowNone?: boolean;
  value?: TaskCategory[];
  onClick: (c?: TaskCategory | undefined) => void;
};

const CategoryPopover: React.FC<Props> = ({
  children,
  value,
  onClick,
  allowNone,
}) => {
  const Content = (
    <SelectList<TaskCategory | undefined>
      values={categories as any}
      value={value}
      onClick={onClick}
      render={(c: TaskCategory | undefined) =>
        c ? <Category category={c} key={c} /> : null
      }
      allowNone={allowNone}
    />
  );

  return <Popover content={Content}>{children}</Popover>;
};

export default CategoryPopover;
