import React, { useRef, useContext, useState } from "react";
import isNil from "lodash/isNil";
import { useClickAway, useKey } from "react-use";

import { useFSMState, useEffectOnceFSMState } from "src/shared/useFSMState";

import { FiltersItemProps, FilterItemSignals, FilterItemStates } from "./types";
import {
  buildActionsList,
  getInputValue,
  createFilterHandler,
  renameFilterHandler,
  replaceFilterHandler,
} from "./utils";
import { transitions } from "./constants";
import { FiltersContext } from "../../FiltersContext/FiltersContext";
import { SavedFilter } from "../../FiltersContext/types";

// 1) select filters to apply when one is clicked
// 2) prevent selecting filter when any button inside is clicked (ie deadzone)
export const useFiltersItemClick = (props: FiltersItemProps) => {
  const { setSelectedFilter } = useContext(FiltersContext);
  const { id } = props;
  const filterClickDeadzoneRef = useRef<HTMLDivElement>();

  const handleFiltersItemClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const selectedFilterId = typeof id === "string" ? id : null;

    if (!filterClickDeadzoneRef.current) {
      return setSelectedFilter(selectedFilterId);
    }

    const target = event.target as Node | null;

    if (filterClickDeadzoneRef.current.contains(target)) {
      return;
    }

    setSelectedFilter(selectedFilterId);
  };

  return {
    filterClickDeadzoneRef: filterClickDeadzoneRef as React.LegacyRef<
      HTMLDivElement
    >,
    handleFiltersItemClick,
  };
};

export const useFilterItemSetup = (props: FiltersItemProps) => {
  const { state: componentState, nextState } = useFSMState("idle", transitions);
  const { titleInputRef, itemWithCollidingTitle } = useTypingState(
    props.id || null,
    componentState,
    nextState
  );
  const [titleInputValue, setTitleInputValue] = useState(props.title);

  const actions = buildActionsList(props);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitleInputValue(event.target.value);
  };

  useFilterItemSideFX({
    ...props,
    title: titleInputValue,
    componentState,
    nextState,
    itemWithCollidingTitle: itemWithCollidingTitle as SavedFilter,
  });

  return {
    actions,
    componentState,

    titleInputRef,
    titleInputValue,

    handleInputChange,
    handleActionClick: nextState,
  };
};

const useTypingState = (
  id: string | null,
  componentState: FilterItemStates,
  nextState: UnaryFn<FilterItemSignals, void>
) => {
  const titleInputRef = useRef<HTMLElement>(null);
  const itemWithCollidingTitle = useCollidedItem(
    getInputValue(titleInputRef.current)
  );
  const isNewFilter = isNil(id);

  const saveChanges = () => {
    if (isNewFilter && !isNil(itemWithCollidingTitle)) {
      nextState("titleExist");
    } else if (isNewFilter) {
      nextState("titleNew");
    } else {
      nextState("titleUpdate");
    }
  };
  const handleEnterKey = () => {
    if (componentState === "typing") {
      saveChanges();
    }
  };

  useClickAway(titleInputRef, saveChanges);
  useKey("Enter", handleEnterKey, { event: "keypress" }, [componentState]);

  return { titleInputRef, itemWithCollidingTitle };
};

const useCollidedItem = (title: string) => {
  const { allFilters } = useContext(FiltersContext);

  const itemWithTheSameTitle = allFilters.accepted.find(
    filter => filter.title === title
  );

  return itemWithTheSameTitle;
};

const useFilterItemSideFX = (
  data: FiltersItemProps & {
    title: string;
    componentState: FilterItemStates;
    nextState: UnaryFn<FilterItemSignals, void>;
    itemWithCollidingTitle: SavedFilter;
  }
) => {
  const { id, componentState, title, nextState, itemWithCollidingTitle } = data;
  const context = useContext(FiltersContext);

  useEffectOnceFSMState("creating", componentState, () =>
    createFilterHandler({
      title,
      context,
      nextState,
    })
  );
  useEffectOnceFSMState("replacing", componentState, () =>
    replaceFilterHandler({
      id: itemWithCollidingTitle.id,
      context,
      nextState,
    })
  );
  useEffectOnceFSMState("renaming", componentState, () =>
    renameFilterHandler({
      id,
      newTitle: title,
      context,
      nextState,
    })
  );
};
