import React from "react";
import dayjs from "dayjs";
import { Avatar } from "antd";
import styled, { css } from "react-emotion";
import { Message, MessageType } from "src/types/api";
import GalleryPhoto from "src/containers/treatment/components/gallery/gallery-photo";
import { GenericFile } from "./File";

interface Props {
  isPatient: boolean;
  message: Message;
}

const avatarSize = 40;

const Wrapper = styled("div")`
  display: flex;
  margin: 0.75rem 0;

  > span {
    width: ${avatarSize}px;
  }
`;

const Content = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div {
    padding: 0.5rem;
    border-radius: 3px;

    img {
      display: block;
      max-width: 100%;
      max-height: 400px;
    }
  }

  small {
    font-size: small;
    opacity: 0.5;
    display: block;
    margin: 0.25rem 0;
  }
`;

const external = css`
  margin-right: 20%;

  ${Content} {
    > div {
      background: #eee;
      white-space: pre-line;
    }
  }
`;

const internal = css`
  flex-direction: row-reverse;
  margin-left: 20%;

  ${Content} {
    align-items: flex-end;
    > div {
      background: #fff1e1;
      white-space: pre-line;
    }
  }
`;

const typeToString = (m: MessageType) => {
  switch (m) {
    case "sms":
      return "SMS";
    case "message":
      return "app message";
    case "email":
      return "email";
    default:
      return "";
  }
};

const Item = ({ message, isPatient }: Props) => {
  if (!message.body && message.attachments.length === 0) {
    return null;
  }

  if (
    message.comment_type === "inbound_call" ||
    message.comment_type === "outbound_call"
  ) {
    // TODO: Show call
    return null;
  }

  return (
    <Wrapper className={isPatient ? external : internal}>
      <Avatar
        src={message.author_avatar || ""}
        style={{ margin: "0 .5rem" }}
        size={avatarSize}
      >
        {message.author_name}
      </Avatar>
      <Content>
        <div>
          {message.body}
          {message.attachments.map(a => {
            switch (a.content_type) {
              case "image/jpeg":
                return (
                  <GalleryPhoto images={[{ src: a.url, thumbnail: a.url }]} />
                );
              default:
                return <GenericFile url={a.url} />;
            }
          })}
        </div>
        <small>
          {message.author_name},{" "}
          {dayjs(message.sent_at).format("MM/DD/YYYY h:mma")} via{" "}
          <b>{typeToString(message.comment_type)}</b>
        </small>
      </Content>
    </Wrapper>
  );
};

export default Item;
