import React from "react";
import { compose, lifecycle } from "recompose";
import { BuildsEnhancer } from "src/state/Builds";
import { useFetchProducers } from "src/state/hooks/producerIdentifiers";
import { Tabs, Tab } from "material-ui/Tabs";
import UnsentBuilds from "./UnsentBuilds";
import SentBuilds from "./SentBuilds";
import ReceivedBuilds from "./ReceivedBuilds";

const modifiers = compose(
  BuildsEnhancer,
  lifecycle({
    componentDidMount() {
      this.props.actions.getBuilds();
    },
  })
);

/**
 * BuildsScene renders a list of all builds across the system.
 */
const BuildsScene = () => {
  const [producers] = useFetchProducers();

  return (
    <div>
      <Tabs tabItemContainerStyle={{ background: "transparent" }}>
        <Tab label="Unsent Builds" style={{ color: "black" }}>
          <UnsentBuilds producers={producers} />
        </Tab>
        <Tab label="Sent Builds" style={{ color: "black" }}>
          <SentBuilds producers={producers} />
        </Tab>
        <Tab label="Received Builds" style={{ color: "black" }}>
          <ReceivedBuilds producers={producers} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default modifiers(BuildsScene);
