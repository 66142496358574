import React, { useState } from "react";
import { Button } from "antd";
import Insurance from "./insurance-dialog";
import styled from "react-emotion";

type Props = {
  patientId: string;
};

const Wrapper = styled("div")`
  color: "rgba(0, 0, 0, 0.87)",
  backgroundColor: "#ffffff",
  transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
  boxSizing: "border-box",
  boxShadow: "0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12)",
  borderRadius: 2,
  zIndex: 1,
  margin: 12px,
  padding: 12px 24px,
    overflow: auto,
`;

const Sidebar = (props: Props) => {
  const [open, setOpen] = useState(false);

  const toggleDialog = () => {
    setOpen(!open);
  };

  return (
    <Wrapper>
      <div>
        <Button onClick={toggleDialog}>Generate a claims form</Button>
        <Insurance
          patientId={props.patientId}
          open={open}
          onClose={toggleDialog}
        />
      </div>
    </Wrapper>
  );
};

export default Sidebar;
