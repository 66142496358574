import { Predicate } from "./types";

function stringToRegexp(str: string): RegExp {
  const pos = str.lastIndexOf("/");
  return new RegExp(str.slice(1, pos), str.slice(pos + 1));
}

function toNumberUnsafe(input: string | number): number {
  if (typeof input === "number") {
    return input;
  }

  return parseFloat(input);
}

/**
 * testPredicate takes an operator (defined in src/types.js) and asserts
 * whether the the given input matches the predicate operator/value.
 */
export function testPredicate(
  p: Predicate,
  input: string | Array<string> | number | Array<number> | undefined
): boolean {
  switch (p.operator) {
    case "contains":
      if (Array.isArray(input)) {
        return (input as Array<any>).indexOf(p.value) > -1;
      }
      return p.value === input;
    case "in":
      if (Array.isArray(input)) {
        const found = (p.value as Array<any>).find(
          val => (input as Array<any>).indexOf(val) > -1
        );
        return found !== undefined;
      }
      return (p.value as Array<any>).indexOf(input) > -1;
    case "nin":
      if (Array.isArray(input) && input.length === 1) {
        return (p.value as Array<any>).indexOf(input[0]) === -1;
      }
      return (p.value as Array<any>).indexOf(input) === -1;
    case "eq":
      return p.value === input;
    case "neq":
      return p.value !== input;
    case "lt":
      if (
        Array.isArray(p.value) ||
        Array.isArray(input) ||
        input === undefined
      ) {
        return false;
      }
      return toNumberUnsafe(p.value) > toNumberUnsafe(input);
    case "gt":
      if (
        Array.isArray(p.value) ||
        Array.isArray(input) ||
        input === undefined
      ) {
        return false;
      }
      return toNumberUnsafe(p.value) < toNumberUnsafe(input);
    case "regex":
      // only strings can be tested by regex; return false.
      if (
        Array.isArray(input) ||
        Array.isArray(p.value) ||
        typeof input === "number" ||
        typeof p.value === "number" ||
        input === undefined
      ) {
        return false;
      }
      return stringToRegexp(p.value).test(input);
  }
  return true;
}
