import React, { useState } from "react";
import styled, { css } from "react-emotion";
import SelectList from "src/shared/SelectList";
import Popover from "src/shared/Popover";
import _ from "lodash";

type Props = {
  filterHomeClinicIds?: string[] | undefined;
  onClick: (selected?: string | null) => void;
  allHomeClinics: Map<string, string> | null;
};

const HomeClinicPopover: React.FC<Props> = ({
  children,
  filterHomeClinicIds,
  onClick,
  allHomeClinics,
}) => {
  const [visible, setVisible] = useState(false);

  const filterHomeClinics = () => {
    if (!allHomeClinics) {
      return;
    }

    if (filterHomeClinicIds && filterHomeClinicIds.length === 0) {
      return [];
    }

    return _.map(filterHomeClinicIds, homeClinicId => {
      return allHomeClinics.get(homeClinicId);
    });
  };
  const filteredHomeClinics = filterHomeClinics();

  const homeClinicNames = allHomeClinics ? [...allHomeClinics.values()] : [];

  const getClinicId = homeClinicName => {
    if (!allHomeClinics || !homeClinicName) {
      return;
    }

    const entry = [...allHomeClinics.entries()].find(
      ([id, name]) => name === homeClinicName
    );
    const homeClinicId = entry ? entry[0] : null;

    return homeClinicId;
  };

  const Content = (
    <Wrapper>
      <SelectList<string>
        values={homeClinicNames}
        value={filteredHomeClinics as Array<string>}
        onClick={homeClinicName => {
          if (!homeClinicName) {
            return;
          }

          onClick(getClinicId(homeClinicName));
        }}
        render={(homeClinicName: string) => homeClinicName || ""}
        className={selectCSS}
      />
    </Wrapper>
  );

  return (
    <Popover
      content={Content}
      visible={visible}
      close={() => setVisible(false)}
    >
      <div
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setVisible(true);
        }}
      >
        {children}
      </div>
    </Popover>
  );
};

export default HomeClinicPopover;

const Wrapper = styled.div`
  background: #fff;
  padding: 12px;
`;

const selectCSS = css`
  max-height: 16rem;
`;
