import { useMutation } from "src/utils/http/gqlQuery";

const addPatientStatusGql = `
mutation CreatePatientStatus($input: CreatePatientStatus!) {
  createPatientStatus(opts: $input) {
    id
    name
    start
  }
}
`;

export const useAddPatientStatus = () => {
  const [, execute] = useMutation(addPatientStatusGql);
  return execute;
};

const updatePatientStatusesGql = `
mutation UpdatePatientStatuses($input: UpdatePatientStatuses!) {
  updatePatientStatus(opts: $input) {
    id
    name
    start
  }
}
`;

export const useUpdatePatientStatuses = () => {
  const [, execute] = useMutation(updatePatientStatusesGql);
  return execute;
};
