import { remove, indexOf, without, cloneDeep, findIndex, last } from "lodash";

const IMAGE_TYPES = ["jpg", "png", "jpeg"];
export function toggleItemInArray(collection = [], item) {
  const index = indexOf(collection, item);
  if (index !== -1) {
    return without(collection, item);
  }
  return [...collection, item];
}

export function isImage(inputUrl = "") {
  const fileType = last(inputUrl.split(".")).toLowerCase();
  return IMAGE_TYPES.indexOf(fileType) >= 0;
}
export function getThumbnailUrl(inputUrl) {
  if (isImage(inputUrl)) {
    return inputUrl.replace(/(.+)\/.+\.(.+)/g, (str, group1, group2) => {
      return `${group1}/small.${group2}`;
    });
  }
  return inputUrl;
}

export function findAndReplaceMergeOnId(list, newObject) {
  const id = newObject.id;
  const newList = cloneDeep(list) || [];
  const index = findIndex(newList, { id });
  if (index >= 0) newList[index] = { ...newList[index], ...newObject };
  return newList;
}

export function findAndDeleteById(list, id) {
  const newList = cloneDeep(list) || [];
  remove(newList, { id });
  return newList;
}

export function getConversationId(layerConversationId) {
  if (!layerConversationId) return null;
  return layerConversationId.replace("layer:///conversations/", "");
}
