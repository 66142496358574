import React from "react";

const Mastercard = () => (
  <svg width="64" height="20" viewBox="0 0 64 20" fill="none">
    <path
      d="M26 20C31.5228 20 36 15.5228 36 10C36 4.47715 31.5228 0 26 0C20.4772 0 16 4.47715 16 10C16 15.5228 20.4772 20 26 20Z"
      fill="#EE0005"
    />
    <path
      d="M38.4244 20C43.9473 20 48.4244 15.5228 48.4244 10C48.4244 4.47715 43.9473 0 38.4244 0C32.9016 0 28.4244 4.47715 28.4244 10C28.4244 15.5228 32.9016 20 38.4244 20Z"
      fill="#F9A000"
    />
    <path
      d="M28.4244 9.99982C28.4244 13.175 29.9046 16.0043 32.2122 17.8361C34.5198 16.0042 36.0001 13.175 36.0001 9.99982C36.0001 6.82461 34.5199 3.99535 32.2122 2.16358C29.9046 3.9955 28.4244 6.82469 28.4244 9.99982Z"
      fill="#FF6300"
    />
  </svg>
);

export default Mastercard;
