import styled, { css } from "react-emotion";
import color from "src/styles/color";
import Skeleton from "src/shared/Skeleton";
import { Dropdown, Menu } from "antd";
import { Tab, TabLink, TabTitle } from "src/shared/Tabs";

export const Perimeter = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 40px;
  border-top: 1px solid ${color.border};
  background: ${color.white};
  align-items: center;
`;

export const StickyPerimeter = styled.div`
  z-index: 10;
  background: #f9f9f9;
  border: 1px solid ${color.border};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ theme: { width } }) => width}px;
  height: 40px;
  position: fixed;
  transition: top 1s;
  ${({ theme: { isSticky } }) =>
    isSticky
      ? css`
          top: 64px;
        `
      : css`
          top: -40px;
        `}
`;

export const FormsTitle = styled.div`
  display: flex;
  color: ${color.gray3};
`;

export const DownTriangle = styled.div`
  width: 0px;
  height: 0px;
  border: 4px solid;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: ${color.gray3};
  border-bottom-color: transparent;
  margin-top: 6px;
  margin-left: 4px;
`;

export const SmTab = styled(Tab)`
  height: 100%;
  padding: 0 20px;

  &:hover ${DownTriangle} {
    border-top-color: ${color.primary};
  }
  &:hover ${TabTitle} {
    color: ${color.primary};
  }
  &:hover ${TabLink} {
    color: ${color.primary};
  }

  ${({ theme: { active } }) =>
    active &&
    css`
      border-bottom: 4px solid ${color.primary};
      ${TabLink},
      ${TabTitle} {
        color: ${color.primary};
      }
    `}
`;

export const Photo = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background: ${color.border};
  margin: 0 8px 0 24px;
`;

export const Name = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export const Col = styled.div`
  display: flex;
  align-items: center;
`;

export const Line = styled.div`
  border-left: 1px solid ${color.border};
  height: 16px;
`;

export const SkeletonTab = styled(Skeleton)`
  width: 80px;
  height: 16px;
  margin-right: 40px;
  &:last-child {
    margin-right: 0;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  cursor: pointer;
`;

export const StyleMenu = styled(Menu)`
  min-width: 160px;

  & .ant-dropdown-menu-item:hover {
    background-color: ${color.gray1};
  }
`;

export const PatientPronoun = styled.span`
  font-size: 18px;
  line-height: 1.4;
  transition: all 0.1s;
  margin-left: 5px;
`;
