import React from "react";
import { Wrapper } from "./styles";

type Props = {
  value: number;
  onChange: (count: number) => void;
};

const Counter: React.FC<Props> = ({ onChange, value }) => (
  <Wrapper>
    <button onClick={() => onChange(Math.max(value - 1, 0))}>-</button>
    <input
      type="number"
      value={value}
      onChange={e => onChange(Math.max(parseInt(e.target.value), 0))}
    />
    <button onClick={() => onChange(value + 1)}>+</button>
  </Wrapper>
);

export default Counter;
