import React, { Component } from "react";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";

import { Field, reduxForm } from "redux-form";
import styles from "./reset-password-style";

const FORM_NAME = "reset-password";
const passwordValidate = password => {
  return password &&
    password.length >= 12 &&
    /[A-Z]/g.test(password) &&
    /[a-z]/g.test(password) &&
    /[0-9]/g.test(password)
    ? undefined
    : "Password must be 12 or more characters and have Lowercase, Uppercase, and number";
};
const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    hintText={label}
    floatingLabelText={label}
    errorText={touched && error}
    {...input}
    {...custom}
  />
);

const enhance = reduxForm({
  form: FORM_NAME,
});

class ResetPasswordForm extends Component {
  onSubmit = values => {
    const { handleSubmit } = this.props;
    handleSubmit(values);
  };

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <div>
          <div>
            <Field
              required
              id="oldPassword"
              name="oldPassword"
              hintText="Old Password"
              type="password"
              component={renderTextField}
            />
          </div>
          <div>
            <Field
              id="newPassword"
              name="newPassword"
              hintText="New Password"
              component={renderTextField}
              type="password"
              validate={passwordValidate}
            />
          </div>
          <div style={styles.buttonContainer}>
            <RaisedButton type="submit" label="Reset Password" primary />
          </div>
        </div>
      </form>
    );
  }
}

export default enhance(ResetPasswordForm);
