import React, { useState } from "react";
import gql from "graphql-tag";
import styled from "react-emotion";
import { Icon, Button, Dropdown, Modal, Menu, notification } from "antd";

import { InsurancePolicy, DetailedPatient, Eligibility } from "src/types/api";
import { useMutation } from "src/utils/http/gqlQuery";
import NewEligibility from "./NewEligibility";
import PolicyForm from "./PolicyForm";

interface Props {
  patient: DetailedPatient;
  policy: InsurancePolicy;
}

const deleteEligibility = gql`
  mutation DeleteInsurancePolicy($id: ID!) {
    deleteInsurancePolicy(id: $id) {
      ids
    }
  }
`;

const checkEligibility = gql`
  mutation CheckInsurancePolicy($id: ID!) {
    checkInsurancePolicy(id: $id) {
      id
      insuranceType
      calendarYear
      calendarYearRemaining
      lifetimeMax
      lifetimeMaxRemaining
      percent
    }
  }
`;

const Policy = ({ patient, policy }: Props) => {
  const [checking, setChecking] = useState(false);
  const [showManual, setShowManual] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [, execute] = useMutation(checkEligibility);
  const [, del] = useMutation(deleteEligibility);

  const check = async () => {
    setChecking(true);
    const resp = await execute({ id: policy.id });
    setChecking(false);

    if (resp.error) {
      notification.error({
        message: "Error checking eligibility",
        description: resp.error.toString(),
      });
      return;
    }

    if (
      Array.isArray(resp.data.checkInsurancePolicy) &&
      resp.data.checkInsurancePolicy.length === 0
    ) {
      notification.warning({
        message: "No eligibility found",
        description:
          "The insurance company returned no eligibility information for this policy",
      });
      return;
    }

    notification.success({
      message: "Checked eligibility",
    });
  };

  const onClick = ({ key }) => {
    switch (key) {
      case "check":
        check();
        break;
      case "add":
        setShowManual(true);
        break;
      case "edit":
        setShowEdit(true);
        break;
      case "delete":
        setShowDelete(true);
        break;
    }
  };

  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key="check">
        {checking ? "Checking..." : "Check eligibility"}
      </Menu.Item>
      <Menu.Item key="add">Manually add eligibility</Menu.Item>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="delete">Delete</Menu.Item>
    </Menu>
  );

  return (
    <Wrapper>
      <Header>
        <h2>
          {policy.InsuranceProvider.name} - {policy.state}
        </h2>
        <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
          <Icon type="more" />
        </Dropdown>
      </Header>

      <Members>
        <p>
          <b>Subscriber</b>: {policy.subscriberFirstName}{" "}
          {policy.subscriberLastName} ({policy.subscriberDOB}), ID:{" "}
          <code>{policy.subscriberMemberId}</code>
        </p>
        {policy.dependentFirstName && (
          <p>
            <b>Dependent</b>: {policy.dependentFirstName}{" "}
            {policy.dependentLastName} ({policy.dependentDOB}), ID:{" "}
            <code>{policy.dependentMemberId}</code>
          </p>
        )}
      </Members>

      {policy.Eligibilities.map((e: Eligibility) => {
        return (
          <p style={{ margin: "0.25rem 0" }}>
            <b>Eligibility ({e.insuranceType}):</b> Lifetime max $
            {e.lifetimeMax} up to {e.percent}% (remaining: $
            {e.lifetimeMaxRemaining}) %
          </p>
        );
      })}

      <Modal
        width={900}
        visible={showEdit}
        footer={null}
        onCancel={() => setShowEdit(false)}
      >
        <PolicyForm
          patient={patient}
          current={Object.assign({}, policy, {
            userId: patient.id,
            insuranceProviderId: policy.InsuranceProvider.id,
          })}
          afterSave={() => setShowEdit(false)}
        />
      </Modal>

      <Modal
        width={900}
        visible={showManual}
        footer={null}
        onCancel={() => setShowManual(false)}
      >
        <NewEligibility
          policy={policy}
          patient={patient}
          afterCreate={() => setShowManual(false)}
        />
      </Modal>

      <Modal
        width={900}
        visible={showDelete}
        footer={null}
        onCancel={() => setShowDelete(false)}
      >
        <Delete>
          Ae you sure you want
          <br />
          to delete this policy?
        </Delete>
        <div>
          <Button onClick={() => setShowDelete(false)}>No</Button>
          <Button
            type="danger"
            onClick={() => {
              del({ id: policy.id });
              setShowDelete(false);
            }}
          >
            Yes
          </Button>
        </div>
      </Modal>
    </Wrapper>
  );
};

export default Policy;

const Wrapper = styled("div")`
  background: #fff;
  margin: 0 0 1.5rem 0;
  padding: 1rem;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
`;

const Header = styled("div")`
  flex: 1;
  display: flex;
  align-items: space-between;
  h2 {
    font-size: 1.125rem;
    flex: 1;
    color: #728389;
  }

  div {
    margin-left: 1.5rem;
  }
`;

const Members = styled("div")`
  p {
    margin: 0.25rem 0;
  }

  margin: 0 0 0.5rem 0;
`;

const Delete = styled("h2")`
  text-align: center;

  & + div {
    display: flex;
    justify-content: center;

    > * {
      margin: 0 1rem;
    }
  }
`;
