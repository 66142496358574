import React from "react";
import color from "src/styles/color";
import { findByType, extractChildrenByType } from "src/shared/subcomponents";
import { StyledTable, HeaderRow, Tbody, EmptyBody } from "./styles";

// Header
export const Header: any = props => <div {...props} />;

// Body
export const Body: any = props => <div {...props} />;

interface Props<T> {
  items: Array<T>;
  children: React.ReactNode;
}

const Table = <T extends unknown>(props: Props<T>) => {
  const headerItems = extractChildrenByType(Header, props.children);
  const renderBody = extractChildrenByType(Body, props.children);

  return (
    <>
      <StyledTable>
        <thead
          css={`
            border: 1px solid ${color.border};
            border-left: none;
            border-right: none;
          `}
        >
          <HeaderRow>{headerItems.map((c, idx) => c)}</HeaderRow>
        </thead>
        {props.items.length > 0 && (
          <Tbody>{props.items.map((c, idx) => renderBody(c))}</Tbody>
        )}
      </StyledTable>
      {props.items.length === 0 && findByType(EmptyBody, props.children)}
    </>
  );
};

export default Table;
