import { last } from "lodash";

import {
  CombinedError,
  useMutation,
  useQuery,
  RequestPolicy,
} from "src/utils/http/gqlQuery";
import { UserAddress } from "src/types/gql";

export const useCreateUserAddress = () => {
  const [, execute] = useMutation<
    { createUserAddress: UserAddress },
    { input: CreateUserAddressType }
  >(createUserAddress);
  return execute;
};

export const useUpdateUserAddress = () => {
  const [, execute] = useMutation<
    { updateUserAddress: UserAddress },
    { input: UpdateUserAddressType }
  >(updateUserAddress);
  return execute;
};

type QueryOptions = {
  requestPolicy: RequestPolicy;
};

export const useAddresses = (
  userID: string,
  options?: QueryOptions
): [boolean, CombinedError | undefined, UserAddress[]] => {
  const [{ fetching, error, data }] = useQuery({
    variables: { userID },
    query: listAddresses,
  });
  return [
    fetching,
    error,
    (data && data.addresses) || ([] as Array<UserAddress>),
  ];
};

export const useBillingAddress = (
  userID: string
): [boolean, CombinedError | undefined, UserAddress | undefined] => {
  const [fetching, error, addresses] = useAddresses(userID);
  const billing = addresses.find(
    a => a.addressType === "billing" && a.isActive
  );
  return [fetching, error, billing];
};

export const useShippingAddress = (
  userID: string
): [boolean, CombinedError | undefined, UserAddress | undefined] => {
  const [fetching, error, addresses] = useAddresses(userID);
  const shipping = last(
    addresses.filter(a => a.addressType === "shipping" && a.isActive)
  );
  return [fetching, error, shipping];
};

export const createUserAddress = `
  mutation CreateUserAddress($input: CreateUserAddress!) {
    createUserAddress(input:$input) {
      id
      userID
      addressID
      addressType
      Address {
        id
        line1
        line2
        city
        state
        postalCode
        country
      }
    }
  }
`;

export const updateUserAddress = `
  mutation UpdateUserAddress($input: UpdateUserAddress!) {
    updateUserAddress(input:$input) {
      addressID
      userID
      addressType
      isActive
      Address {
        line1
        line2
        city
        state
        postalCode
        country
      }
    }
  }
`;

const listAddresses = `
query ListAddresses($userID: ID!) {
  addresses(userID: $userID) {
    id
    userID
    addressID
    addressType
    isActive
    Address {
      firstName
      lastName
      line1
      line2
      city
      state
      postalCode
      country
    }
  }
}
`;

type CreateUserAddressType = {
  userID: string;
  type: "shipping" | "billing" | "insurance_billing";
  line1: string;
  line2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
};

type UpdateUserAddressType = {
  id: string;
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  firstName?: String;
  lastName?: String;
};
