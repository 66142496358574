import React from "react";
import styled from "react-emotion";
import color from "src/styles/color";

type Props = Partial<{
  className: string;
  css: string;
}>;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;
  border-radius: 34px;
  transition: background 0.3s;

  &:hover {
    background: ${color.gray1};
  }
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 34px;
  background: ${color.gray3};
  margin-bottom: 2px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Settings = (props: Props) => (
  <Wrapper className={props.className} css={props.css}>
    <Dot />
    <Dot />
    <Dot />
  </Wrapper>
);

export default Settings;
