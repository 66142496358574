import React, { useState } from "react";
import { Modal, Form, DatePicker } from "antd";
import Button from "src/shared/Button";
import { Promotion } from "src/types/gql";
import moment from "moment";
import { UpdatePromotion } from "./queries";
import { css } from "emotion";

const buttonStyle = css`
  display: inline-block;
`;

interface Props {
  promotion: Promotion;
  onUpdate: Function;
  setVisible: Function;
}

const UpdatePromoModal = (props: Props) => {
  const [updatedPromotion, setUpdatedPromotion] = useState<UpdatePromotion>({
    id: props.promotion.id,
    start: props.promotion.start,
    end: props.promotion.end,
  });

  const changed = () =>
    updatedPromotion.start !== props.promotion.start ||
    updatedPromotion.end !== props.promotion.end;

  const onUpdate = () => {
    if (changed()) {
      props.onUpdate({
        input: updatedPromotion,
      });

      props.setVisible(false);
    }
  };

  return (
    <Modal
      title={`Edit promotion ${props.promotion.code}`}
      onOk={onUpdate}
      onCancel={() => props.setVisible(false)}
      visible
      destroyOnClose
      footer={[
        <Button
          className={buttonStyle}
          key="back"
          onClick={() => props.setVisible(false)}
        >
          Cancel
        </Button>,
        <Button
          className={buttonStyle}
          key="submit"
          type="primary"
          disabled={!changed()}
          onClick={onUpdate}
        >
          Update
        </Button>,
      ]}
    >
      <Form>
        <Form.Item label="Start date">
          <DatePicker
            defaultValue={
              updatedPromotion.start
                ? moment(updatedPromotion.start)
                : undefined
            }
            allowClear={false}
            onChange={(d, ds) =>
              setUpdatedPromotion({
                ...updatedPromotion,
                start: d ? d.toISOString() : undefined,
              })
            }
          />
        </Form.Item>
        <Form.Item label="End date">
          <DatePicker
            defaultValue={
              updatedPromotion.end ? moment(updatedPromotion.end) : undefined
            }
            allowClear={false}
            onChange={(d, ds) =>
              setUpdatedPromotion({
                ...updatedPromotion,
                end: d ? d.toISOString() : undefined,
              })
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdatePromoModal;
