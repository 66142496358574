import { V, S } from "src/shared-styles";
import { PRESCRIPTION, GOAL } from "src/config/constant";

const styles = {
  V,
  S,

  answerButton: {
    ...S.bordered,
    margin: V.spacingTiny,
  },
  answerButtonLabel: {
    color: V.colors.fontLight,
  },
  questionDisplayName: {
    color: V.colors.fontLight,
  },
  question: {
    borderBottom: `1px solid ${V.colors.borderColorLight}`,
  },
  textField: {},
  photoButton: {
    verticalAlign: "top",
    margin: V.spacingSmall,
  },
  buttonBg: {
    [GOAL]: V.colors.buttonSecondaryGrey,
    [PRESCRIPTION]: V.colors.buttonTertiaryGrey,
  },
  whiteButtonBg: V.colors.white,
  imgThumbnail: {
    ...S.imgThumbnail,
    margin: 0,
  },
  fileName: {
    display: "inline-block",
    marginLeft: V.spacing,
    color: V.colors.fontLight,
    fontWeight: V.fontWeightLight,
    verticalAlign: "top",
  },
  uploadContainer: {
    margin: V.spacingSmall,
  },
  alert: {
    marginTop: V.spacing,
    marginBottom: V.spacing,
    fontSize: V.fontLarge,
    fontWeight: V.fontWeightBolder,
    // color: V.colors.primary,
  },
  download: {
    color: V.colors.fontLight,
    textDecoration: "underline",
    fontWeight: V.fontWeightBolder,
  },
  modifyWearSchedule: {
    marginTop: V.spacing,
    marginBottom: V.spacing,
  },
};

styles[PRESCRIPTION] = {
  borderBottom: "none",
};

export default styles;
