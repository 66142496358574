import React from "react";
import color from "src/styles/color";

type Props = {
  fill?: string;
};

const NoShow = ({ fill = color.red }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" stroke={fill} strokeWidth="1.75">
        <circle id="Oval" cx="8" cy="8" r="7.125" />
        <path d="M5.5,10.5 L10.5,5.5" id="Line" strokeLinecap="round" />
        <path
          d="M5.5,10.5 L10.5,5.5"
          id="Line"
          strokeLinecap="round"
          transform="translate(8.000000, 8.000000) rotate(-90.000000) translate(-8.000000, -8.000000) "
        />
      </g>
    </g>
  </svg>
);

export default NoShow;
