import React from "react";
import styled from "react-emotion";
import { injectGlobal } from "emotion";
import Loading from "src/shared/Loading";
import SignDocument from "./SignDocument";
import { DetailedPatient } from "src/types/api";
import useConsentsContext from "src/scenes/PatientProfile/Consents/useConsentsContext";

const Wrapper = styled.div`
  display: flex;
  margin-top: 24px;
`;

interface Props {
  patient: DetailedPatient;

  visibleConsents?: string[];
}

injectGlobal`
  .x-hellosign-embedded--in-modal {
    z-index: 9999;
  }
`;

const DocumentsForSignature = (props: Props) => {
  const { visibleConsents } = props;
  const { consents, fetchingConsents, signConsent } = useConsentsContext();

  if (fetchingConsents) {
    return <Loading />;
  }

  const visible = visibleConsents
    ? consents.filter(k => visibleConsents.includes(k.key))
    : consents;

  return (
    <Wrapper>
      {visible.map(c => (
        <SignDocument
          key={c.key}
          consent={c}
          oldConsentPresent={
            props.patient.consents && props.patient.consents[c.key] !== null
          }
          userId={props.patient.id}
          signConsent={signConsent}
        />
      ))}
    </Wrapper>
  );
};

export default DocumentsForSignature;
