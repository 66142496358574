import React from "react";
import { notification } from "antd";
import styled from "react-emotion";
// Use the old containers for now.
import KitForm from "src/shared/KitForm";

const Wrapper = styled("div")`
  flex: 1;
`;

const CreateKit = ({ patient }) => {
  return (
    <Wrapper>
      <KitForm
        userID={patient.id}
        onAfterCreate={result => {
          notification.success({
            message: "Kit created",
            description: "Your kit was created",
          });
        }}
      />
    </Wrapper>
  );
};

export default CreateKit;
