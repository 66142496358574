import { DisplayType, Attribute, RFC3339 } from "./types";

export function filterAttrsForDisplay(
  display: DisplayType,
  restrict: boolean,
  cutoff: RFC3339 | null,
  attrs: Attribute[]
): Attribute[] {
  let result = attrs;

  if (restrict) {
    if (display === "cumulative") {
      result = result.filter(
        it => it.stage === "original" || it.stage === "performed"
      );
    } else {
      result = result.filter(it => it.stage === display);
    }
  }

  if (cutoff) {
    result = result.filter(it => !it.createdAt || it.createdAt < cutoff);
  }

  return result;
}
