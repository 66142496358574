import { filterAttrsForDisplay } from "./attrsUtil";
import { Attribute, UIAttribute, DisplayType, RFC3339 } from "./types";
import uniqBy from "lodash/uniqBy";
import sortBy from "lodash/sortBy";

export interface LegendItemProps {
  color: string;
  name: string;
}

export function getLegendItems(
  display: DisplayType,
  cumulativeCutoff: RFC3339 | null,
  uiAttributes: UIAttribute[]
): LegendItemProps[] {
  // Legend items for visible attributes only.
  let uiAttrs = uiAttributes.filter(
    it => it.type !== "spacing" && it.attribute
  );
  const attrs = uiAttrs.map(uiA => uiA.attribute) as Attribute[];
  const filteredAttrs = new Set<Attribute>(
    filterAttrsForDisplay(display, true, cumulativeCutoff, attrs)
  );
  uiAttrs = uiAttrs.filter(it => filteredAttrs.has(it.attribute as Attribute));
  let itemProps = uiAttrs.map(uiA => ({
    color: uiA.color,
    name: uiA.displayName,
  }));
  itemProps = uniqBy(itemProps, "name");
  return sortBy(itemProps, "name");
}
