import { useQuery } from "src/utils/http/gqlQuery";

const clinicQuery = `
query Clinics {
  clinics {
    id name organizationId
  }
}
`;

export const useClinics = () => {
  const [result] = useQuery({ query: clinicQuery });
  const clinics = (result.data && result.data.clinics) || [];
  return [result.fetching, result.error, clinics];
};
