import React from "react";
import { TimelineEntry } from "../util";
import { TablistRow, ActiveTab, Tab } from "../styles";

interface Props {
  selectedEntry: TimelineEntry;
  selectedTab: "summary" | "notes";
}

export default function Tablist({ selectedEntry, selectedTab }: Props) {
  return (
    <TablistRow>
      {selectedTab === "summary" ? (
        <ActiveTab>Summary</ActiveTab>
      ) : (
        <Tab to={selectedEntry.pathUrl}>Summary</Tab>
      )}

      {selectedTab === "notes" ? (
        <ActiveTab>Notes</ActiveTab>
      ) : (
        <Tab to={`${selectedEntry.pathUrl}/notes`}>Notes</Tab>
      )}
    </TablistRow>
  );
}
