import React from "react";
import color from "src/styles/color";

const LeftArrow = ({ stroke = color.gray5 }) => (
  <svg
    width="5"
    height="8"
    viewBox="0 0 5 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 0.571442L1 4.00001L4 7.42858"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LeftArrow;
