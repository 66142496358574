import { startHour } from "src/shared/consts";

export default function formatTime(time) {
  let modified = time.replace(/\s/g, "").toUpperCase();
  if (!modified.includes(":")) {
    let nums = modified.replace(/^\D+/g, "");
    modified =
      modified.slice(0, nums.length - 2) +
      ":" +
      modified.slice(nums.length - 2);
  }

  let hour = modified.split(":")[0];

  // AM and PM defined
  if (!modified.includes("AM") && !modified.includes("PM")) {
    if (hour < startHour) {
      return `${modified}PM`;
    } else {
      return `${modified}AM`;
    }
  }

  return modified;
}
