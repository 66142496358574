import { QUESTION_FORM } from "../event-type";
import { QUESTION_TYPE } from "../constant";
const { MODIFY_WEAR_SCHEDULE } = QUESTION_TYPE;

const TP_TEMPLATE = {
  postTimestamp: null,
  postUserName: null,
  postUserUUID: null,
  complete: false,
  formName: "tp", // this must match the name of the form in config/treatment-forms/index
  type: QUESTION_FORM,
  questionList: [
    {
      id: "number_of_aligners",
      displayName: "Number of Stages",
      type: "text",
    },
    {
      id: "time_per_stage_in_days",
      displayName: "How often will the patient change stages?",
      type: "select",
      possibleAnswerList: [
        "Once a Week",
        "Once Every Two Weeks",
        "Once Every Three Weeks",
        "Once Every Four Weeks",
      ], // do not change text. create manufacturing task code depends on this
    },
    {
      id: "production_location",
      displayName:
        "Production Location ( will be added to manufacturing tasks )",
      type: "select",
      possibleAnswerList: ["sutter_999_sf", "hl_packaging_hk"],
    },
    {
      id: "ortho_planning_env",
      displayName:
        "Ortho Planning Env ( will be added to manufacturing tasks )",
      type: "select",
      possibleAnswerList: ["three_shape", "archform", "sure_smile", "ecligner"],
    },
    {
      id: "treatment_note",
      displayName: "Treatment notes ( will be added to manufacturing tasks )",
      type: "multiselect",
      possibleAnswerList: [
        "Build-ups",
        "Elastics-BH",
        "Elastics-BL",
        "Elastics-SH",
        "Elastics-SL",
        "Hand delivery",
        "Indirect attachments",
      ], // do not change Indirect attachments.
    },
    {
      id: "additional_note",
      displayName:
        "Additional free form note ( will be added to manufacturing tasks )",
      type: "text",
    },
    {
      type: MODIFY_WEAR_SCHEDULE,
    },
    {
      displayName: "Below is for refinement only",
      type: "alert",
    },
    {
      id: "is_refinement",
      displayName: "Is this a refinement treatment plan",
      type: "select",
      possibleAnswerList: ["Yes", "No"], // do not change these! is used a a key
    },
    {
      id: "refinement_prefix",
      displayName: "Choose Prefix Character (number of refinements)",
      type: "select",
      possibleAnswerList: ["A", "B", "C", "D", "E", "F", "G"],
    },
    {
      id: "end_prior_treatment_cycle_on_stage",
      displayName: "End the last treatment cycle on this stage (REQUIRED)",
      type: "text",
    },
    {
      displayName:
        "Please enter in the last stage of that the patient will end on. This number is required.",
      type: "alert",
    },
  ],
};

export default TP_TEMPLATE;
