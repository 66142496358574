import { V, S } from "src/shared-styles";
const HEIGHT = "calc(100vh - 89px)";
const styles = {
  V,
  S,
  mainPanel: {
    ...S.mainPanel,
    maxHeight: HEIGHT,
    overflow: "scroll",
    WebkitOverflowScrolling: "touch",
  },
  sidePanel: {
    ...S.sidePanel,
    maxHeight: HEIGHT,
    overflow: "scroll",
    WebkitOverflowScrolling: "touch",
  },
  title: {
    fontSize: V.fontLarge,
    marginBottom: V.spacingSmall,
  },
  questionListContainer: {
    marginLeft: V.spacingLarge,
  },
  issueDisplayName: {
    fontWeight: V.fontWeightBolder,
  },
  issueContainer: {},
  formTitle: {
    ...S.sectionTitle,
    textTransform: "uppercase",
  },
  raisedButton: {
    textAlign: "right",
    margin: V.spacingTiny,
  },
  disabled: {
    background: V.colors.whiteGreyBackground,
    // borderLeft: `${V.spacingTiny}px solid ${V.colors.borderColor}`,
    // background: 'rgba(0,0,0,0.05)',
  },
  cloneButton: {
    textAlign: "right",
  },
  patientConsentContainer: {
    margin: V.spacingSmall,
  },
  patientConsent: {
    width: "100%",
  },
  officeSyncButton: {
    marginTop: V.spacingSmall,
  },
  formButton: {
    marginLeft: V.spacing,
    marginTop: V.spacingSmall,
  },
};

export default styles;
