import React from "react";
import { Wrapper } from "./styles";

type Props = {
  text?: string;
};

const Error: React.FC<Props> = props => {
  return (
    <Wrapper>{props.text || <p>"Something went wrong! Try again?"</p>}</Wrapper>
  );
};

export default Error;
