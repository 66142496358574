import React, { useState } from "react";
import { DateTime } from "luxon";
import styled, { css } from "react-emotion";
import gql from "graphql-tag";
import { notification, Tooltip } from "antd";
import color from "src/styles/color";
import dayjs from "dayjs";
import { useSchedulingRestrictions } from "src/state/self";
// shared
import { useMutation } from "src/utils/http/gqlQuery";
import Button from "src/shared/Button";
import Popconfirm from "src/shared/Popconfirm";
import { gqlError } from "src/shared/util";
import time from "src/shared/time";
import InputText from "src/shared/InputText";
import { updateAppointment } from "src/scenes/SchedulingV2/queries";
import NoShow from "src/shared/Icons/NoShow";
import Info from "src/shared/Icons/Info.svg";
// local
import useCalendarViewContext from "../useCalendarViewContext";
import { AppointmentWithOffset } from "./types";
import { getLabel } from "../util";
import EditForm from "./EditForm";
import CheckInAndOutModal from "../CheckInAndOutModal";
import { StyledLink } from "./Styled";
import formatTime from "./formatTime";

type Props = {
  appt: AppointmentWithOffset;
  close: (e?: React.SyntheticEvent | undefined) => void;
};

const Header = styled.div`
  padding: 24px 24px 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CheckInStatus = styled.div`
  padding: 8px 24px;
  display: flex;
  background-color: ${color.background};
  font-weight: bold;
`;

const Column = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  &:first-child {
    margin-right: 8px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

const Text = styled.span`
  color: ${color.primary};
  margin-left: 8px;
  cursor: pointer;
  width: 100%;
  &:hover {
    text-decoration: underline;
  }
`;

const Span = styled.div`
  flex: 0;
  white-space: nowrap;
  display: inline-block;
  margin-right: 8px;
`;

const Error = styled.div`
  color: ${color.red};
`;

const NoShowPerimeter = styled.div`
  display: flex;
  height: 46px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  background: ${color.backgroundWarning};
`;

const NoShowText = styled.div`
  margin-left: 8px;
  color: ${color.red};
  line-height: 1;
`;

const icon = css`
  color: ${color.primary};
`;

const confirmedButtonStyle = {
  border: "none",
  color: "#27AE60",
  background: "rgba(95, 203, 141, 0.4)",
  borderRadius: "4px",
};

const pendingButtonStyle = {
  border: "none",
  color: "#979A9A",
  background: "#F3F3F3",
  borderRadius: "4px",
};

const cancelGql = gql`
  mutation CancelAppointment($id: ID!) {
    appt: cancelAppointment(appointmentId: $id) {
      id
    }
  }
`;

const EditBookedSlot = ({ appt, close }: Props) => {
  const { isScheduleManager, canCancelBV } = useSchedulingRestrictions();
  const isBV = appt.appointmentType.name === "beginning";
  // quries
  const [, cancel] = useMutation(cancelGql);
  const [, update] = useMutation(updateAppointment);
  // state
  const [cancelVisible, setCancelVisible] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editing2, setEditing2] = useState(false);
  const [error, setError] = useState("");
  const { clinic } = useCalendarViewContext();
  const { toHour, toDateYear, now, diffInSeconds } = time(clinic.timezone);
  const [checkInTime, setCheckInTime] = useState(toHour(appt.actualStartTime));
  const [checkOutTime, setCheckOutTime] = useState(toHour(appt.actualEndTime));

  const noShow =
    !appt.actualStartTime && diffInSeconds(now(), appt.startTime) > 1200;

  // handlers
  const onCancel = async () => {
    setCancelVisible(false);

    const result = await cancel({ id: appt.id });
    if (result.error) {
      return notification.error({
        message: `Error cancelling appointment: ${gqlError(result.error)}`,
      });
    }
    notification.success({ message: "Appointment cancelled" });
    close();
  };

  const toUTC = time => {
    const dateYear = toDateYear(appt.doctorStartTime);
    return DateTime.fromFormat(
      `${dateYear} ${formatTime(time)}`,
      "MMM d, yyyy h:mma",
      { zone: clinic.timezone }
    ).setZone("utc");
  };

  const handleConfirm = async () => {
    if (checkInTime && checkInTime !== toHour(appt.actualStartTime)) {
      if (!toUTC(checkInTime).isValid) {
        setError("Time format is 7:45 AM or 745");
        return;
      }
    } else if (checkOutTime && checkOutTime !== toHour(appt.actualEndTime)) {
      if (!toUTC(checkOutTime).isValid) {
        setError("Time format is 7:45 AM or 745");
        return;
      }
    }

    const result = await update({
      appt: {
        id: appt.id,
        actualStartTime: checkInTime ? toUTC(checkInTime) : null,
        actualEndTime: checkOutTime ? toUTC(checkOutTime) : null,
        clearActualStartTime: !checkInTime,
        clearActualEndTime: !checkOutTime,
      },
    });

    if (result.error) {
      return notification.error({ message: "Error checking in patient" });
    }

    notification.success({
      message: "Patient check in/out time edited",
    });

    setEditing(false);
    setEditing2(false);
    setError("");
  };

  const handleConfirmedAtToggle = async appt => {
    if (appt.confirmedAt === undefined || appt.confirmedAt === null) {
      let result = await update({
        appt: {
          id: appt.id,
          confirmedAt: true,
        },
      });

      if (result.error) {
        return notification.error({
          message: "Error updating patient confirmation",
        });
      }

      notification.success({
        message: `Appointment confirmed! ${appt.user.firstName} ${
          appt.user.lastName
        } is confirmed for ${dayjs(appt.startTime).format("dddd MMMM DD")} at ${
          appt.clinic.nickname
        }`,
      });
    } else {
      let result = await update({
        appt: {
          id: appt.id,
          clearConfirmedAt: true,
        },
      });

      if (result.error) {
        return notification.error({
          message: "Error updating patient confirmation",
        });
      }

      notification.success({
        message: `Appointment confirmation reverted!`,
      });
    }

    setError("");
  };

  const handleCancel = () => {
    setCheckInTime(toHour(appt.actualStartTime));
    setCheckOutTime(toHour(appt.actualEndTime));
    setEditing(false);
    setEditing2(false);
    setError("");
  };

  const handleKeyDown = evt => {
    if (evt.key === "Enter") {
      handleConfirm();
    }
  };

  return (
    <div>
      <Header>
        <div css="flex: 1">
          <h6>
            {appt.confirmedAt ? (
              <button
                style={confirmedButtonStyle}
                disabled={!isScheduleManager}
                onClick={() => handleConfirmedAtToggle(appt)}
              >
                Confirmed
              </button>
            ) : (
              <button
                disabled={!isScheduleManager}
                style={pendingButtonStyle}
                onClick={() => handleConfirmedAtToggle(appt)}
              >
                Pending
              </button>
            )}{" "}
            {getLabel(appt.appointmentType)} visit -{" "}
            {appt.user && (
              <StyledLink to={`/patients/${appt.userId}`}>
                {appt.user.firstName}{" "}
                {appt.user.preferredName
                  ? `(${appt.user.preferredName})`
                  : null}{" "}
                {appt.user.lastName.charAt(0)}{" "}
                {appt.user.pronouns ? `(${appt.user.pronouns})` : null}
              </StyledLink>
            )}
            <Tooltip placement="bottom" title="View Patient Profile">
              <img className={icon} src={Info} alt="View Patient Profile" />
            </Tooltip>
          </h6>
          {appt.doctor && (
            <p>
              <small>with</small> Dr. {appt.doctor.firstName}{" "}
              {appt.doctor.lastName}
            </p>
          )}
        </div>

        <ActionButtons>
          <Popconfirm
            placement="bottomLeft"
            title="Are you sure you want to cancel this appointment?"
            onConfirm={onCancel}
            okText="Yes"
            cancelText="No"
            visible={cancelVisible}
            onCancel={() => setCancelVisible(false)}
            setVisible={(visible: boolean) => setCancelVisible(visible)}
          >
            <Button
              style={{ width: "235px" }}
              disabled={(isBV && !canCancelBV) || !!appt.actualEndTime}
              type="secondary"
              onClick={() => setCancelVisible(true)}
            >
              Cancel appointment
            </Button>
          </Popconfirm>
          <CheckInAndOutModal
            styles={{ width: "235px" }}
            appointment={appt}
            hideNoShow
          />
        </ActionButtons>
      </Header>

      {noShow ? (
        <NoShowPerimeter>
          <NoShow />
          <NoShowText>
            It has been 20 mins past the appt. time and the patient has not
            checked in yet!
          </NoShowText>
        </NoShowPerimeter>
      ) : (
        <CheckInStatus>
          <Column>
            <Row>
              <Span>Check-In Time:</Span>
              {editing ? (
                <InputText
                  autoFocus
                  value={checkInTime}
                  onChange={evt => setCheckInTime(evt.target.value)}
                  onBlur={handleCancel}
                  onKeyDown={handleKeyDown}
                  error={error}
                />
              ) : (
                <Text
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  {appt.actualStartTime ? (
                    toHour(appt.actualStartTime)
                  ) : (
                    <span> -</span>
                  )}
                </Text>
              )}
            </Row>
            {editing && <Error>{error}</Error>}
          </Column>
          <Column>
            <Row>
              <Span>Check-Out Time:</Span>
              {editing2 ? (
                <InputText
                  autoFocus
                  value={checkOutTime}
                  onChange={evt => setCheckOutTime(evt.target.value)}
                  onBlur={handleCancel}
                  onKeyDown={handleKeyDown}
                  error={error}
                />
              ) : (
                <Text onClick={() => setEditing2(true)}>
                  {appt.actualEndTime ? (
                    toHour(appt.actualEndTime)
                  ) : (
                    <span> -</span>
                  )}
                </Text>
              )}
            </Row>
            {editing2 && <Error>{error}</Error>}
          </Column>
        </CheckInStatus>
      )}

      <div css="padding: 0 24px;">
        <EditForm
          appt={appt}
          onSaveCompleted={() => {
            close();
          }}
          close={close}
        />
      </div>
    </div>
  );
};

export default EditBookedSlot;
