import React from "react";
import { FormDecorationsData } from "../../Forms/formDecorations";

export function getRenderedOptions(
  options: string[],
  formDecorations: FormDecorationsData
) {
  return options.map(value => {
    const decoration = formDecorations.choiceDecorations.get(value);
    if (decoration && decoration.hint) {
      return {
        value,
        label: (
          <>
            <span>{value}</span> <strong>{`(${decoration.hint})`}</strong>
          </>
        ),
      };
    }

    return {
      value,
      label: <span>{value}</span>,
    };
  });
}
