import {
  startsWith,
  some,
  includes,
  orderBy,
  filter,
  get,
  map,
  pull,
  isEmpty,
} from "lodash";
import { createSelector } from "reselect";
import {
  STATUS,
  CHECKIN,
  COMMENT,
  FILE,
  NOTE,
  SYSTEM_EVENT,
} from "src/config/event-type";
import { getCheckinsForPatientSelector } from "../redux/modules/checkin";
import { capitalize } from "src/helpers/utils";
import { getFileListSelector } from "../redux/modules/fileList";

const getPatientSystemEvent = (state, props) =>
  get(state, `patient.patientMap[${props.patient.id}].systemEvent`);
const getPatientStaffSubmittedEvent = state =>
  get(state, "clinicalRecord.data.staff_submitted_data.eventStream", []);
const getPatientStatus = (state, props) => {
  return get(state, `patient.patientMap[${props.patient.id}].status`);
};
const getPatientChat = (state, props) => {
  return get(state, `patient.patientMap[${props.patient.id}].chat`);
};
const getPatient = state => state.patient.patient;
const getEventStreamFilter = state => state.UI.eventStreamFilter;

const getInternalNotes = (state, props) => {
  // Get all internal notes that match the current clinical record.
  //
  // props.notes is passed from the InternalNotesEnhancer.
  const data = props.notes.filter(
    n =>
      n.model_type === "ClinicalRecord" &&
      n.model_id === state.clinicalRecord.data.id
  );
  return data;
};

export const getEventList = createSelector(
  [
    getPatientStaffSubmittedEvent,
    getPatientStatus,
    getCheckinsForPatientSelector,
    getPatientChat,
    getPatient,
    getFileListSelector,
    getEventStreamFilter,
    getPatientSystemEvent,
    getInternalNotes,
  ],
  (
    staffSubmitted,
    patientStatus,
    checkins,
    comments,
    patient,
    fileList,
    eventStreamFilter,
    systemEvents,
    internalNotes
  ) => {
    /* Comments selector */
    // mutate patient comment to match our display of events
    const patientComments = map(comments, comment => {
      // if (!comment.body) return null
      if (comment.body === "[upload]") return null;
      if (startsWith(comment.body, "[import]")) return null;
      // if (!comment.is_public) return null
      return {
        id: comment.id,
        name: `${comment.author_name}`,
        postTimestamp: comment.sent_at,
        type: COMMENT,
        isPatient: patient.id === comment.author_id,
        content: comment.body,
        commentType: comment.comment_type,
        isPublic: comment.is_public,
        links: comment.links,
        attachments: comment.attachments,
        unseen:
          patient.last_seen < comment.sent_at &&
          comment.author_id !== comment.user_id &&
          comment.author_id != null,
      };
    });
    pull(patientComments, null);

    /* Selfie events creator */
    const fileEvents = map(fileList, file => {
      return {
        name: file.file_type,
        type: FILE,
        postTimestamp: file.created_at,
        fileList: [file],
      };
    });

    const patientSystemEvents = map(systemEvents, systemEvent => {
      return {
        name: `${systemEvent.name}`,
        type: SYSTEM_EVENT,
        data: systemEvent.data,
        postTimestamp: systemEvent.created_at,
      };
    });

    const patientStatusEvents = map(patientStatus, status => {
      return {
        name: `Status: ${status.status.name}`,
        type: STATUS,
        postTimestamp: status.start_time,
      };
    });

    const internalNoteEvents = internalNotes.map(c => ({
      uuid: c.id,
      name: `Internal Note`,
      type: NOTE,
      data: c,
      pinned: c.pinned_at !== null,
      postTimestamp: c.created_at,
    }));

    const checkinEvents = checkins.map(c => ({
      name: `${capitalize(c.checkin_type)} checkin`,
      type: CHECKIN,
      data: c,
      postTimestamp: c.created_at,
    }));

    /* remove incompleted forms */
    const staffSubmittedEventList = filter(
      staffSubmitted,
      "postTimestamp",
      "desc",
      event => {
        if (event.complete === false) {
          return false;
        } // explicitly check for false.
        return true;
      }
    );

    let eventList = orderBy(
      staffSubmittedEventList.concat(
        fileEvents,
        patientStatusEvents,
        patientSystemEvents,
        checkinEvents,
        patientComments,
        internalNoteEvents
      ),
      ["postTimestamp"],
      "desc"
    );
    eventList = orderBy(eventList, e => {
      return e.pinned ? 0 : 1; // reverse ordering
    });

    let filteredEventList = eventList;
    if (!isEmpty(eventStreamFilter)) {
      filteredEventList = filter(eventList, event => {
        return some(eventStreamFilter, type => {
          return includes(event.type, type); // handles question-form and issue-list-form
        });
      });
    }

    return filteredEventList;
  }
);
