import React, { useState } from "react";
import { AutoComplete } from "antd";
import styled, { css } from "react-emotion";
import color from "src/styles/color";
import LegacyModal from "src/shared/LegacyModal";
import Button from "src/shared/Button";
import { StyledAutoComplete } from "../Styled";

type Props = {
  closeModal: () => void;
  onSubmit: (string) => void;
  staffOptions: Array<{ value: string; label: string }>;
  staffType: "doctor" | "assistant";
};

const Title = styled.p`
  color: ${color.gray5};
  font-weight: bold;
  border: none;
`;

const Actions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0 8px;
`;

const StaffAssignmentModal: React.FC<Props> = ({
  staffOptions,
  closeModal,
  staffType,
  onSubmit,
}) => {
  const [selectedStaffMember, setSelectedStaffMember] = useState();
  const [searchText, setSearchText] = useState("");

  const filteredStaffOptions = searchText
    ? staffOptions.filter(s => {
        return s.label.toLowerCase().indexOf(searchText) >= 0;
      })
    : staffOptions;

  const assignSelectedStaff = () => {
    if (!selectedStaffMember || !selectedStaffMember.value) {
      return;
    }

    onSubmit(selectedStaffMember.value);
    closeModal();
  };

  return (
    <LegacyModal
      isOpen={true}
      width={614}
      openModal={() => {}}
      closeModal={closeModal}
    >
      <LegacyModal.Title>
        <Title
          className={css`
            border: none;
          `}
        >
          Assign appointment(s) to {staffType === "assistant" ? "an" : "a"}{" "}
          {staffType}
        </Title>
      </LegacyModal.Title>
      <LegacyModal.Content>
        <StyledAutoComplete
          style={{ width: "100%" }}
          dataSource={filteredStaffOptions as any}
          placeholder={`Find ${staffType} by name`}
          onSearch={text => {
            setSearchText(text);
            if (text === "") {
              setSelectedStaffMember(undefined);
            }
          }}
          onSelect={id => {
            const staff = filteredStaffOptions.find(s => s.value === id);
            if (staff) {
              setSelectedStaffMember(staff);
            }
          }}
        >
          {filteredStaffOptions.map(s => (
            <AutoComplete.Option key={s.value} value={s.value}>
              {s.label}
            </AutoComplete.Option>
          ))}
        </StyledAutoComplete>
      </LegacyModal.Content>

      <LegacyModal.Footer>
        <Actions>
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            disabled={!selectedStaffMember}
            type="primary"
            onClick={assignSelectedStaff}
          >
            Assign
          </Button>
        </Actions>
      </LegacyModal.Footer>
    </LegacyModal>
  );
};

export default StaffAssignmentModal;
