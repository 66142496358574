import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles, { actionColor } from "./tooth-style";

const SELECTABLE_REGIONS = [1, 2, 3, 4];

const getNeighborIPRToothParams = ({
  quadrantLabel,
  toothDisplayNum,
  section,
  region,
}) => {
  if (toothDisplayNum === 8) {
    if (section === "left" && quadrantLabel.charAt(1) === "R") return null;
    if (section === "right" && quadrantLabel.charAt(1) === "L") return null;
  }
  const neighborSection = section === "left" ? "right" : "left";
  let neighborRegion;
  if (region === 1) neighborRegion = 2;
  if (region === 2) neighborRegion = 1;
  if (region === 3) neighborRegion = 4;
  if (region === 4) neighborRegion = 3;
  let neighborQuadrant = quadrantLabel;
  let neighborToothNum;
  if (quadrantLabel.charAt(1) === "R") {
    if (section === "left") {
      neighborToothNum = toothDisplayNum + 1;
    }
    if (section === "right") {
      neighborToothNum = toothDisplayNum - 1;
    }
  }
  if (quadrantLabel.charAt(1) === "L") {
    if (section === "left") {
      neighborToothNum = toothDisplayNum - 1;
    }
    if (section === "right") {
      neighborToothNum = toothDisplayNum + 1;
    }
  }
  if (toothDisplayNum === 1) {
    if (section === "left" && quadrantLabel.charAt(1) === "L") {
      neighborToothNum = 1;
      neighborQuadrant = `${quadrantLabel.charAt(0)}R`;
    }
    if (section === "right" && quadrantLabel.charAt(1) === "R") {
      neighborToothNum = 1;
      neighborQuadrant = `${quadrantLabel.charAt(0)}L`;
    }
  }
  const neighborTooth = `${neighborQuadrant}-${neighborToothNum}`;
  return {
    tooth: neighborTooth,
    action: "IPR",
    section: neighborSection,
    region: neighborRegion,
  };
};

export default class Tooth extends Component {
  static propTypes = {
    quadrantLabel: PropTypes.string,
    toothDisplayNum: PropTypes.number,
    allToothActionEventStream: PropTypes.array,
    saveActionToEventStream: PropTypes.func,
    activeAction: PropTypes.string,
    activeStatus: PropTypes.string,
    activeElasticType: PropTypes.string,
    activeElasticLabel: PropTypes.string,
    activeIPRType: PropTypes.string,
  };
  getBackground = ({ action, status, IPRType }) => {
    let color = actionColor[action];
    if (action === "IPR") {
      color = actionColor[IPRType];
    }
    if (status === "Rx") {
      return `repeating-linear-gradient(
                45deg,
                ${color},
                ${color} 2px,
                ${styles.V.colors.white} 2px,
                ${styles.V.colors.white} 4px
              )`;
    }
    return color;
  };
  getLastActionRenderable = params => {
    if (!params) return null;
    const { allToothActionEventStream } = this.props;
    const filterParams = { ...params };
    delete filterParams.isSynthClick; // eslint-disable-line
    const lastAction = _.last(
      _.filter(allToothActionEventStream, {
        tooth: this.tooth,
        ...filterParams,
      })
    );
    if (!lastAction) return null;
    if (lastAction.resolve) return null;
    return lastAction;
  };
  synthIPRClick = ({ region, section }) => {
    const { quadrantLabel, toothDisplayNum } = this.props;
    const neighborIPRTooth = getNeighborIPRToothParams({
      quadrantLabel,
      toothDisplayNum,
      region,
      section,
    });
    this.toothRegionClick({ ...neighborIPRTooth, isSynthClick: true });
  };
  toothRegionClick = ({ region, tooth, isSynthClick }) => {
    const {
      saveActionToEventStream,
      activeAction,
      activeStatus,
      activeElasticType,
      activeElasticLabel,
      activeIPRType,
    } = this.props;
    if (!activeAction) return;
    let section = null;
    const params = { tooth, action: activeAction, section };
    switch (activeAction) {
      case "Gum Disease":
      case "Caries":
        break;
      case "Attachments":
        if (region === 1 || region === 2) section = "upper";
        if (region === 3 || region === 4) section = "lower";
        params.section = section;
        params.status = activeStatus;
        break;
      // left and right are camera right, not oriented by patient mouth for this.
      case "IPR":
        if (region === 1 || region === 3) section = "left";
        if (region === 2 || region === 4) section = "right";
        params.status = activeStatus;
        params.section = section;
        params.IPRType = activeIPRType;
        if (isSynthClick) {
          params.isSynthClick = true;
        } else {
          this.synthIPRClick({ section, region });
        }
        break;
      case "Elastics":
        if (region === 1 || region === 2) section = "upper";
        if (region === 3 || region === 4) section = "lower";
        params.status = activeStatus;
        params.section = section;
        params.elasticLabel = activeElasticLabel;
        params.elasticType = activeElasticType;
        break;
      default:
    }
    // we will be duplicating the same last actions - resolve it instead
    const dupCheckParams = { tooth, action: activeAction, section }; // ignore status or label or type
    const lastAction = this.getLastActionRenderable(dupCheckParams);
    if (lastAction) {
      if (_.isEqual(lastAction, params)) {
        params.resolve = true;
      }
    }
    saveActionToEventStream(params);
  };

  renderCaries = () => {
    if (this.getLastActionRenderable({ action: "Caries" }))
      return <div style={styles.tooth.action.caries} />;
    return null;
  };
  renderGumDisease = () => {
    if (this.getLastActionRenderable({ action: "Gum Disease" }))
      return <div style={styles.tooth.action.gumDisease} />;
    return null;
  };
  renderAttachment = section => {
    const attachment = this.getLastActionRenderable({
      action: "Attachments",
      section,
    });
    if (attachment)
      return (
        <div
          style={{
            ...styles.tooth.action.attachments,
            background: this.getBackground(attachment),
          }}
        />
      );
    return null;
  };
  renderIPR = section => {
    const IPR = this.getLastActionRenderable({ action: "IPR", section });
    if (IPR) {
      return (
        <div
          style={{
            ...styles.tooth.action.IPR,
            background: this.getBackground(IPR),
          }}
        />
      );
    }
    return null;
  };
  renderElastic = section => {
    const elastic = this.getLastActionRenderable({
      action: "Elastics",
      section,
    });
    if (elastic) {
      const borderStyle = elastic.status === "Rx" ? "dashed" : "solid";
      return (
        <div style={{ ...styles.tooth.action.Elastics, borderStyle }}>
          <div>
            {elastic.elasticLabel}
            {elastic.elasticType}
          </div>
        </div>
      );
    }
    return null;
  };
  render() {
    const { quadrantLabel, toothDisplayNum } = this.props;
    const tooth = `${quadrantLabel}-${toothDisplayNum}`;
    this.tooth = tooth;
    return (
      <div style={styles.toothWrapper} key={tooth}>
        <div style={styles.displayContainer}>
          {this.renderGumDisease()}
          <div style={styles.toothDisplayNum[quadrantLabel.charAt(0)]}>
            {toothDisplayNum}
          </div>
          <div style={styles.tooth.upper}>
            {this.renderAttachment("upper")}
            {this.renderElastic("upper")}
          </div>
          <div style={styles.tooth.middle}>{this.renderCaries()}</div>
          <div style={styles.tooth.lower}>
            {this.renderAttachment("lower")}
            {this.renderElastic("lower")}
          </div>
          <div style={styles.tooth.left}>{this.renderIPR("left")}</div>
          <div style={styles.tooth.right}>{this.renderIPR("right")}</div>
        </div>
        <div style={styles.selectableRegionsContainer}>
          {SELECTABLE_REGIONS.map(region => {
            return (
              <div
                key={region}
                style={styles[`selectRegion${region}`]}
                onClick={this.toothRegionClick.bind(this, { region, tooth })}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
