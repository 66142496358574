import React from "react";
import color from "src/styles/color";

type Props = {
  fill?: string;
};

const Completed = ({ fill = color.green }: Props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="7.125" stroke={fill} strokeWidth="1.75" />
    <path
      d="M4 8.27273L6.82353 11L12 6"
      stroke={fill}
      strokeWidth="1.75"
      strokeLinecap="round"
    />
  </svg>
);

export default Completed;
