import React, { PureComponent } from "react";
import ErrorIcon from "material-ui/svg-icons/alert/error";
import { V } from "src/shared-styles";

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  text: {
    color: V.colors.error,
  },
};

export default class Error extends PureComponent {
  render() {
    const { error } = this.props;

    return (
      <div style={styles.wrapper}>
        <ErrorIcon color={V.colors.error} />
        <p style={styles.text}>{error}</p>
      </div>
    );
  }
}
