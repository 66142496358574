import React, { PureComponent } from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import Slider from "material-ui/Slider";

type Props = {|
  isOpen: boolean,
  currentCadence: number,
  onPreview: (cadence: number) => void,
  onClose: () => void,
|};

/**
 *
 * CadenceAlert shows a dialog which allows a doctor to change the delta
 * between start and end times for all subsequent stages.
 *
 */
export default class CadenceAlert extends PureComponent {
  props: Props;

  state: {|
    newCadence: number,
  |};

  constructor(props: Props) {
    super();
    this.state = { newCadence: props.currentCadence };
  }

  componentWillReceiveProps(next: Props) {
    if (next.currentCadence !== this.props.currentCadence) {
      this.setState({ newCadence: next.currentCadence });
    }
  }

  onUpdate = (_, value: number | string) => {
    if (typeof value === "string") {
      this.setState({ newCadence: parseInt(value, 10) || 1 });
      return;
    }
    this.setState({ newCadence: value });
  };

  onPreview = () => {
    this.props.onPreview(this.state.newCadence);
  };

  render() {
    const actions = [
      <FlatButton label="Cancel" onClick={this.props.onClose} primary />,
      <FlatButton label="Save" onClick={this.onPreview} primary />,
    ];

    return (
      <Dialog
        title="Change future cadence"
        actions={actions}
        modal={false}
        open={this.props.isOpen}
        onRequestClose={this.props.onClose}
      >
        This will change the cadence for wearing trays{" "}
        <b>from this stage onwards</b>.
        <TextField
          name="cadence"
          value={this.state.newCadence}
          onChange={this.onUpdate}
        />
        <Slider
          min={1}
          max={21}
          step={1}
          value={this.state.newCadence}
          onChange={this.onUpdate}
        />
      </Dialog>
    );
  }
}
