import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createPatientMessage } from "src/redux/modules/patient";
import ChatForm, { MESSAGE, SMS, SEND_BY, APP_ONLY } from "./chat-form";
import { useAddMessage } from "src/state/hooks/chat";
import FileUpload, {
  CHAT_UPLOAD,
} from "../../../../components/file-upload/file-upload";

import styles from "./doctor-input-style";

const appendSendByToMessage = (message, type) => `${message}
~~~
send by: ${type}`;

const modifiers = connect(
  () => ({}),
  { createPatientMessage }
);

// We make this because this entire shitty component heirarchy uses
// shitty contecxt because it's shitty code, but we also need functional
// components to use hooks for our new chat state.
//
// This parent just loads patient from shitty context to do what we need.
class ChatParent extends Component {
  static contextTypes = {
    patient: PropTypes.object,
  };

  render() {
    const patient = this.context.patient;

    return (
      <Chat
        patient={patient}
        createPatientMessage={this.props.createPatientMessage}
      />
    );
  }
}

const Chat = ({ patient, createPatientMessage }) => {
  const [disabled, setDisabled] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const addMessage = useAddMessage();
  const sendBySMSOn = !patient.push_token;
  const initialValues = sendBySMSOn
    ? { [SEND_BY]: SMS }
    : { [SEND_BY]: APP_ONLY };

  const onFileUploaded = file => {
    const attachment = {
      date: file.created_at,
      url: file.file.url,
    };
    attachments.push(attachment);
    setAttachments(attachments);
  };

  const onFileRemoved = file => {
    const filteredAttachments = attachments.filter(a => a.url !== file.url);
    setAttachments(filteredAttachments);
  };

  // wtf is values?
  const onSubmit = values => {
    // not sure values/message is.  i didn't write this - this is kenzan.
    // this is literally copy-pasta so we can add the sent chat message to
    // the new hook state.
    let message = values[MESSAGE];
    if (!message) {
      return;
    }

    if (values[SEND_BY] !== APP_ONLY) {
      message = appendSendByToMessage(message, values[SEND_BY]);
    }

    setDisabled(true);

    createPatientMessage({
      uuid: patient.id,
      body: message,
      is_public: true,
      attachments: attachments,
    }).then(message => {
      setDisabled(false);
      // use new hooks to add the message to our UI.  We'll update this shit later.
      addMessage(message);
      return message;
    });
  };

  return (
    <div style={styles.chatContainer}>
      <ChatForm
        onSubmit={onSubmit}
        sendAsChatDisabled={disabled}
        initialValues={initialValues}
      />
      <FileUpload
        patientUUID={patient.id}
        onFileUploaded={onFileUploaded}
        onFileRemoved={onFileRemoved}
        fileType={CHAT_UPLOAD}
      />
    </div>
  );
};
export default modifiers(ChatParent);
