import { V, S } from "src/shared-styles";
export default {
  V,
  S,
  buttonContainer: {
    textAlign: "right",
  },
  loginContainer: {
    ...S.contentBlock,
    margin: `${V.spacing}px auto`,
    maxWidth: V.mainWidth,
  },
};
