import React, { PureComponent } from "react";
import { compose } from "recompose";
import { BuildsEnhancer } from "src/state/Builds";
import BuildTable from "./BuildTable";

const modifiers = compose(BuildsEnhancer);

/**
 * UnsentBuilds renders a list of all builds across the system.
 */
class SentBuilds extends PureComponent<*, *> {
  render() {
    return (
      <div>
        <BuildTable
          producers={this.props.producers}
          builds={this.props.sentBuilds}
        />
      </div>
    );
  }
}

export default modifiers(SentBuilds);
