import { createStore } from "easy-peasy";
import { appointments } from "./hooks/appointments";
import { clinicalRecords } from "./hooks/clinicalRecords";
import { producerIdentifiers } from "./hooks/producerIdentifiers";
import { issues } from "./hooks/issues";
import { patients } from "./hooks/patients";
import { addresses } from "./hooks/addresses";
import { chat } from "./hooks/chat";
import { files } from "./hooks/files";
import { clinics } from "./hooks/clinics";
import { treatments } from "./hooks/treatments";
import { StoreModel } from "./hooks";

const stateParams: StoreModel = {
  appointments,
  clinicalRecords,
  producerIdentifiers,
  issues,
  patients,
  addresses,
  chat,
  files,
  clinics,
  treatments,
};

const store = createStore(stateParams);
export default store;
