import styled, { css } from "react-emotion";
import color from "src/styles/color";
import { cellHeight, slotWidth } from "src/shared/consts";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: ${cellHeight}px;
  min-width: ${slotWidth}px;
  border-bottom: 1px solid ${color.border};
  border-right: 1px solid ${color.border};
  background: ${color.background};
`;

export const Quarter = styled.div`
  z-index: 1;
  flex: 1;
`;

export const pointer = css`
  cursor: pointer;
  &:hover {
    background: #f5f5f5;
  }
`;
