// @flow
// @ky - 8/13/2019
// NOTE: enable eslint again after a refactor
/* eslint-disable getter-return, default-case */
import Moment from "moment";
import Submission from "./Submission";
import { getSubmissionAnswer } from "../answers";

// Capitalize capitalizes a word.
const capitalize = word => {
  if (word === "") {
    return "";
  }
  const letter = word[0].toUpperCase();
  return `${letter}${word
    .split("")
    .slice(1)
    .join("")
    .toLowerCase()}`;
};

// Oxford joins words together, eg. ["1", "2", "3"] => `1, 2, and 3`.
const oxfordJoin = words => {
  if (typeof words === "string") {
    return words;
  }

  switch (words.length) {
    case 1:
      return words[0];
    case 2:
      return `${words[0]} and ${words[1]}`;
    default:
      return words
        .slice(0, words.length - 1)
        .concat([`and ${words[words.length - 1]}`])
        .join(", ");
  }
};

const map = {
  were_caries_present: answer => {
    return answer === "Yes" ? "Caries appear to be present. " : "";
  },
  latex_allergy: answer => {
    return answer === "Yes" ? "Latex allergy is present. " : "";
  },
  specify_medical_condition: answer => {
    return answer
      ? `These other medical conditions are present: ${answer}. `
      : "";
  },
  which_tmj_issues_are_present: answers => {
    if (!answers || (answers.length === 1 && answers[0] === "None")) {
      return;
    }
    return `The patient's TMJ is significant for ${oxfordJoin(
      answers
    ).toLowerCase()}. `;
  },
  periodontal_issues_present: answer => {
    return answer === "Yes" ? "Periodontium appear to be unhealthy. " : "";
  },
  psychosocial_issues_present: answer => {
    return answer === "Yes" ? "Psychosocial issues appear to be present. " : "";
  },
  oral_hygeine: answer => {
    return `Oral hygeine appears to be ${answer.toLowerCase()}. `;
  },
  oral_habits: answers => {
    if (!answers) {
      return;
    }
    return `Oral habits include ${oxfordJoin(answers).toLowerCase()}. `;
  },

  // Diagnosis
  profile_dx: answer => {
    return answer !== "Straight" && `The profile is ${answer.toLowerCase()}. `;
  },
  growth_pattern_dx: answer => {
    if (answer === "Normal") {
      return;
    }
    if (answer === "Ex. Hypodivergent") {
      return `The facial growth pattern is extremely hypodivergent, and it should be noted that this may impede orthodontic correction. `;
    }
    // Replace "Ex. " with "extremely" and form a sentence
    const replaced = answer.replace("Ex. ", "extremely ").toLowerCase();
    return (
      replaced !== "normal" && `The facial growth pattern is ${replaced}. `
    );
  },
  frontal_dx: answer => {
    const full = (function() {
      switch (answer) {
        case "Mx to Left":
          return "maxilla appears to be to the left";
        case "Mx to Right":
          return "maxilla appears to be to the right";
        case "Mn to Left":
          return "mandible appears to be to the left";
      }
    })();
    return (
      answer !== "Normal" &&
      `On frontal evaluation, one can appreciate that the ${full}. `
    );
  },
  crossbite_dx: answer => {
    return answer === "Present" && `There is a crossbite present. `;
  },
  crossbite_position: answer => {
    if (!answer) {
      return;
    }
    return `Crossbite position is ${answer.toLowerCase()}. `;
  },
  crossbite_teeth_included: answer => {
    if (!answer) {
      return;
    }
    return `Teeth included into the crossbite are ${answer.toLowerCase()}. `;
  },
  esthetic_dx: answer => {
    return (
      answer !== "1" &&
      `On an esthetic scale from 1-10, the smile is assessed to be ${answer}. `
    );
  },
  incisor_vertical_bite: answer => {
    // Note: these do not end with sentences because they will be pared with vertical_amount_dx.
    switch (answer) {
      case "Open":
        return "The patient has an open bite";
      case "Closed":
        return "The patient has a deep bite";
    }
  },
  incisor_vertical_bite_amount: answer => {
    switch (answer) {
      case "Full":
        return ` of full coverage. `;
      case "Impinging":
        return ` of impinging coverage. `;
      case "⅔-Full":
        return ` of ⅔ to full coverage. `;
      default:
        return ` of ${answer}mm. `;
    }
  },
  overjet_dx: answer => {
    return `Overjet is ${answer}mm. `;
  },
  other_warning_flags: answer => {
    if (!answer) {
      return;
    }
    const flags = answer.map(item => {
      switch (item) {
        case "Medication":
          return "is taking medications that may alter teeth movement";
        case "Dental trauma":
          return "has experienced dental trauma which may affect tooth movement and the stability of current restorations";
        case "Bruxism":
          return "appears to exhibit tooth grinding or bruxism";
        case "Anxiety":
          return "has a pattern of anxiety";
        case "TLC":
          return "may exhibit traits that require TLC";
        case "Expectations":
          return "has exceedingly high expectations";
        default:
          return item;
      }
    });
    return `It should be noted that the patient ${oxfordJoin(flags)}. `;
  },
};

class Summary extends Submission {
  // Summary returns the summary for a beginning visit form.
  get summary() {
    return `
${this.intro}.  ${this.adverse}

Dx:
${this.diagnosis}

Rx:
${this.prescription}

Notes:
${this.answer("notes") || "No notes"}

Summary:
${this.answer("summary") || "No summary"}

    `;
  }

  // getIntro returns the introductory sentence with name, age, and concerns, if available.
  get intro() {
    const concerns = this.answer("what_would_you_like_to_change");
    if (!concerns) {
      return `${this.personal}.`;
    }
    return `${this.personal} with the chief concerns of "${oxfordJoin(
      concerns
    )}".`;
  }

  get adverse() {
    const hasAdverse = this.answer("were_there_adverse_issues_in_the_exam");
    if (hasAdverse !== "Yes") {
      return "\n";
    }

    return this.fromList([
      "were_caries_present",
      "latex_allergy",
      "specify_medical_condition",
      "which_tmj_issues_are_present",
      "periodontal_issues_present",
      "psychosocial_issues_present",
      "oral_habits",
      "oral_hygeine",
    ]).join("");
  }

  get diagnosis() {
    return [
      this.fromList([
        "profile_dx",
        "growth_pattern_dx",
        "frontal_dx",
        "esthetic_dx",
        "crossbite_dx",
        "crossbite_position",
        "crossbite_teeth_included",
        "incisor_vertical_bite",
        "incisor_vertical_bite_amount",
        "overjet_dx",
      ]),
      this.upperMidlinePosition,
      this.lowerMidlinePosition,
      this.leftSagittal,
      this.rightSagittal,
      this.upperArchDx,
      this.lowerArchDx,
      this.fromList(["other_warning_flags"]),
    ]
      .flatten()
      .filter(Boolean)
      .join("\n");
  }

  get goalSummary() {
    let summary = "";
    const goals = this.submission.children.filter(
      s => s.parent_id === this.submission.id
    );

    goals.forEach((goal, index) => {
      const goalSubmission = new Submission(this.patient, this.childForm, goal);
      summary += `
Treatment Option ${index + 1}:
${goalSubmission.answer("treatment_option_summary") || ""}
Estimated tx length in months is ${goalSubmission.answer(
        "rx_estimated_length_option"
      ) || "not specified"}.
This ${
        goalSubmission.answer("is_chosen_option") === "Yes" ? "IS " : "IS NOT"
      } a chosen option of the patient.
      `;
    });

    return summary;
  }

  get prescription() {
    const eligible = this.answer("eligible");
    const observation = this.answer("observation_length");

    // Eligible is an implicit "yes"; only detect ineligibility if "No" is explicitly
    // selected or the patient has been rejected based off of the Observation choice.
    if (eligible === "No" || observation === "Reject") {
      const ineligible =
        "Given the limitations of orthodontic treatment with clear aligners in this case, we feel that the patient would be better served by another provider since we are unable to address the chief complaints.  ";

      // Are we instead referring this patient elsewhere?
      const referTo = this.answer("refer_to");
      if (referTo) {
        return `${ineligible}Therefore, we recommend the patient to visit ${referTo} who may be able to better fill the needs required.  `;
      }

      return ineligible;
    }

    return [
      this.profileRx,
      this.growthPatternRx,
      this.crossbiteRx,
      this.incisorRx,
      // TODO:
      this.rightSagittalRx,
      this.leftSagittalRx,
      this.upperArchRx,
      this.lowerArchRx,
      this.frontalRx,
      // Done
      this.tmjRx,
      this.cariesRx,
      this.goalSummary,
    ]
      .filter(Boolean)
      .join("\n");
  }

  /**
   * personal returns the patient's personal information
   */
  get personal() {
    const now = new Moment();
    const dob = new Moment(this.patient.date_of_birth);

    // This gives us the total number of months between now and the DOB, eg. 346
    const diff = now.diff(dob, "months");
    const years = Math.floor(diff / 12);
    const months = diff % 12;

    // We should say "an 8 year" or "an 18 year".
    const prefix =
      [8, 11, 18, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89].indexOf(years) > -1
        ? "an"
        : "a";

    return `${capitalize(this.patient.first_name)} ${capitalize(
      this.patient.last_name
    )} is ${prefix} ${years} year, ${months} month old`;
  }

  // getAnswer gets an answer for a question by slug.
  answer(slug) {
    const q = this.form.forms_questions.find(q => q.slug === slug);
    if (!q) {
      return;
    }

    const a = getSubmissionAnswer({
      answers: this.submission.answers,
      questionID: q.id,
      submissionID: this.submission.id,
    });
    if (!a) {
      return;
    }

    return a.answer;
  }

  fromList(slugs) {
    return slugs
      .map(slug => {
        const answer = this.answer(slug);
        if (!answer) {
          return undefined;
        }

        const func = map[slug];
        return func(answer);
      })
      .filter(Boolean);
  }

  get rightSagittal() {
    const angle = this.answer("right_sagittal_angle_dx");
    const buccal = this.answer("right_sagittal_buccal_segment_dx");
    if (!angle || !buccal || angle === "I") {
      return "";
    }
    return `On the right side, the patient displays an angle class ${angle}, ${buccal} occlusal relationship. `;
  }

  get leftSagittal() {
    const angle = this.answer("left_sagittal_angle_dx");
    const buccal = this.answer("left_sagittal_buccal_segment_dx");
    if (!angle || !buccal || angle === "I") {
      return "";
    }
    return `On the left side, the patient displays an angle class ${angle}, ${buccal} occlusal relationship. `;
  }

  get upperMidlinePosition() {
    const upperMidlinePosition = this.answer("upper_midline_position");
    const upperMidlineShift = this.answer("upper_midline_shift");
    if (upperMidlinePosition === "Normal") {
      return `Upper midline position is normal. `;
    }
    if (upperMidlineShift && upperMidlinePosition) {
      return `Upper midline position is ${upperMidlinePosition.toLowerCase()} by ${upperMidlineShift}mm. `;
    }
  }

  get lowerMidlinePosition() {
    const lowerMidlinePosition = this.answer("lower_midline_position");
    const lowerMidlineShift = this.answer("lower_midline_shift");
    if (lowerMidlinePosition === "Normal") {
      return `Lower midline position is normal. `;
    }
    if (lowerMidlineShift && lowerMidlinePosition) {
      return `Lower midline position is ${lowerMidlinePosition.toLowerCase()} by ${lowerMidlineShift}mm. `;
    }
  }

  // Upper arch diagnosis is comprised of two answers.
  get upperArchDx() {
    const type = this.answer("upper_arch_type_dx");
    const length = this.answer("upper_arch_length_dx");
    if (!type || !length || type === "Normal") {
      return "";
    }
    return `In the maxillary arch, ${length}mm of ${type.toLowerCase()} is present. `;
  }

  get lowerArchDx() {
    const type = this.answer("lower_arch_type_dx");
    const length = this.answer("lower_arch_length_dx");
    if (!type || !length || type === "Normal") {
      return "";
    }
    return `In the mandibular arch, ${length}mm of ${type.toLowerCase()} is present. `;
  }

  get tmjRx() {
    const tmj = this.answer("which_tmj_issues_are_present") || [];
    if (tmj.length > 0 && tmj[0] !== "None") {
      // This patient has TMJ issues.
      return "Given the patient's history of Temporomandibular Joint symptoms, recommend monitoring the patient closely throughout treatment.  ";
    }
    return "";
  }

  get cariesRx() {
    const caries = this.answer("were_caries_present");
    if (caries === "Yes") {
      return "Since the patient appears to have caries, we recommend the patient visits a general dentist to receive treatment.  We would like the opportunity to meet the patient's orthodontic needs after caries have been controlled. If fillings are needed, we recommend those to be done before beginning full time clear aligners treatment. If crowns or other restorations are needed, we recommend temporary restorations to be placed before orthodontic treatment and the permanent restorations delivered after orthodontic correction. This will help achieve the most ideal outcome.";
    }
    return "";
  }

  get profileRx() {
    const dx = this.answer("profile_dx");
    const rx = this.answer("profile_rx");

    if (!rx) {
      return;
    }

    if (dx === "Convex") {
      return `${oxfordJoin(
        rx
      )} to improve the prominence of incisors and improve convex profile.  `;
    }
    if (dx === "Concave") {
      return `${oxfordJoin(rx)} to improve the patient's convex profile.  `;
    }
  }

  get growthPatternRx() {
    const dx = this.answer("growth_pattern_dx");
    const rx = this.answer("growth_pattern_rx");

    if (!rx) {
      return;
    }

    if (["Ex. Hyperdivergent", "Hyperdivergent"].indexOf(dx)) {
      return `Hyperdivergency improved via ${rx}`;
    }
    if (["Ex. Hypodivergent", "Hypodivergent"].indexOf(dx)) {
      return `Hypodivergency improved via ${rx}`;
    }
  }

  get crossbiteRx() {
    const rx = this.answer("crossbite_rx");
    switch (rx) {
      case "Clear Aligners":
        return `Crossbite corrected with clear aligners and no auxiliary mechanics.  `;
      case "Clear Aligners and X-bite Elastics":
        return `Crossbite corrected with clear aligners and through-the-bite elastics.  `;
    }
  }

  get incisorRx() {
    const dx = this.answer("incisor_vertical_bite");
    const rx = this.answer("incisor_vertical_bite_rx");
    if (!rx) {
      return;
    }

    return `${dx} bite to be addressed by ${oxfordJoin(rx).replace(
      "Mech. ",
      "Mechanics "
    )}. `;
  }

  get rightSagittalRx() {
    const angle = this.answer("right_sagittal_angle_dx");
    const relationship = this.answer("right_sagittal_buccal_segment_dx");
    const rx = this.answer("right_sagittal_rx");
    if (!rx) {
      return;
    }

    return `Right angle class ${angle}, ${relationship} occlusal relationship will be corrected with ${oxfordJoin(
      rx
    )}. `;
  }

  get leftSagittalRx() {
    const angle = this.answer("left_sagittal_angle_dx");
    const relationship = this.answer("left_sagittal_buccal_segment_dx");
    const rx = this.answer("left_sagittal_rx");
    if (!rx) {
      return;
    }

    return `Left angle class ${angle}, ${relationship} occlusal relationship will be corrected with ${oxfordJoin(
      rx
    )}.  `;
  }

  get upperArchRx() {
    const type = this.answer("upper_arch_type_dx");
    const rx = this.answer("upper_arch_dx");
    if (!rx || type === "Normal") {
      return;
    }
    return `Upper arch ${type.toLowerCase()} will be addressed with ${oxfordJoin(
      rx
    )}.  `;
  }

  get lowerArchRx() {
    const type = this.answer("lower_arch_type_dx");
    const rx = this.answer("lower_arch_dx");
    if (!rx || type === "Normal") {
      return;
    }
    return `Lower arch ${type.toLowerCase()} will be addressed with ${oxfordJoin(
      rx
    )}.  `;
  }

  get frontalRx() {
    const rx = this.answer("frontal_rx");
    switch (rx) {
      case "Surgery":
        return "Since the patient appears to have a transverse skeletal disharmony of the upper and lower jaws, we recommend the patient to visit an oral surgeon.  ";
      case "Compensate Teeth":
        return `Although an underlying transverse skeletal disharmony exists, teeth will be compensated to avoid surgical correction of the malocclusion.`;
    }
  }
}

export const getSummary = (patient, form, submission, childForm) => {
  return new Summary(patient, form, submission, childForm).summary;
};

export default getSummary;
