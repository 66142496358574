import React from "react";

export default () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.314 11.82l-.854-1.288a1.083 1.083 0 01-.18-.597V6.237a5.37 5.37 0 00-1.463-3.703A4.88 4.88 0 007.286 1a4.88 4.88 0 00-3.532 1.534 5.37 5.37 0 00-1.463 3.703v3.698c0 .213-.063.421-.18.597l-.854 1.288a1.094 1.094 0 00-.172 1.126c.079.185.206.342.368.453.162.11.35.17.544.17h2.841c.115.592.421 1.124.867 1.507a2.427 2.427 0 001.58.59c.577 0 1.135-.208 1.581-.59.446-.383.752-.915.867-1.507h2.842c.193 0 .382-.06.543-.17.162-.11.29-.268.368-.453a1.094 1.094 0 00-.172-1.126zm-6.028 2.797c-.31-.001-.61-.103-.863-.29a1.566 1.566 0 01-.546-.758h2.817c-.103.306-.294.57-.546.758a1.452 1.452 0 01-.862.29zm-5.29-2.095a.415.415 0 00.06-.074l.875-1.32c.233-.35.358-.766.36-1.193V6.237c0-1.111.42-2.177 1.17-2.962a3.904 3.904 0 012.825-1.228c1.06 0 2.076.442 2.825 1.228a4.295 4.295 0 011.17 2.962v3.698c.001.427.127.843.36 1.194l.874 1.32a.416.416 0 00.06.073H1.997z"
      fill="currentColor !important"
      stroke="currentColor !important"
      strokeWidth=".4"
    />
    <path fill="#fff" d="M9 1h5v5H9z" />
    <path
      d="M13.54 4.003h-2.097C13.898.813 13.89.818 13.893.818c.247-.32.04-.818-.353-.818h-3.08c-.608 0-.609.997 0 .997h2.097c-2.489 3.235-2.453 3.185-2.45 3.185-.247.32-.04.818.353.818h3.08c.608 0 .609-.997 0-.997zM8.501 7.119h-.936C8.997 5.603 9 5.656 9 5.44 9 5.197 8.777 5 8.501 5H6.5c-.66 0-.66.881 0 .881h.935L6.117 7.277c-.273.289-.035.723.382.723h2.002c.66 0 .66-.881 0-.881z"
      fill="currentColor !important"
    />
  </svg>
);
