import React from "react";
import styled from "react-emotion";
import Button from "src/shared/Button";
import color from "src/styles/color";
import Check from "src/shared/Icons/Check";
import { Task, TaskOption } from "../../types";
// local
import TreatmentPlanActions from "./TreatmentPlan";

type Props = {
  task: Task;
  pipelineID?: string;
  onChange: (opt: TaskOption, deleteFields?: string[]) => Promise<any>;
};

// Actions typically represents the "complete" button in the top right corner.  The
// UI of these is overridden for specific tasks (eg. treatment plan approval).
const Actions: React.FC<Props> = props => {
  const { task, pipelineID, onChange } = props;

  if (
    (task.taskType.name === "approve_treatment_plan" ||
      task.taskType.name === "review_treatment_plan") &&
    pipelineID
  ) {
    // typescript isn't smart enough to see that we check for pipeline above,
    // so we can't spread {...props}
    return (
      <TreatmentPlanActions
        task={task}
        onChange={onChange}
        pipelineID={pipelineID}
      />
    );
  }

  return task.completedAt ? (
    <UncompleteButton
      onClick={() => {
        onChange({}, ["CompletedAt"]);
      }}
    >
      <Check fill="#fff" style={{ marginTop: 2 }} />
      Complete
    </UncompleteButton>
  ) : (
    <CompleteButton
      onClick={() => {
        onChange({ completedAt: new Date().toISOString() });
      }}
    >
      <Check fill={color.gray3} style={{ marginTop: 2 }} />
      Mark complete
    </CompleteButton>
  );
};

export default Actions;

const UncompleteButton = styled(Button)`
  border-color: ${color.green};
  color: #fff;
  background: ${color.green};

  &:hover {
    border-color: ${color.green};
    color: #fff;
    background: ${color.green};
  }
`;

const CompleteButton = styled(Button)`
  border-color: ${color.green};
  color: ${color.green};

  &:hover {
    background: #fff;
    border-color: ${color.green};
    color: ${color.green};
  }
`;
