import gql from "graphql-tag";
import { PromotionType } from "@/types/gql";
import { useQuery } from "src/utils/http/gqlQuery";

export const getAvailablePromotionsGql = gql`
  query AvailablePromotions {
    availablePromotions {
      id
      type
      amount
      stackable
      start
      end
      code
    }
  }
`;

export const getExpiredPromotionsGql = gql`
  query ExpiredPromotions {
    expiredPromotions {
      id
      type
      amount
      stackable
      start
      end
      code
    }
  }
`;

export const getUpcomingPromotionsGql = gql`
  query UpcomingPromotions {
    upcomingPromotions {
      id
      type
      amount
      stackable
      start
      end
      code
    }
  }
`;

export const deletePromotionCode = `
  mutation DeletePromotion($id: ID!) {
    deletePromotion(id: $id) {
      ids
    }
  }
`;

export const addPromotionCode = `
  mutation AddPromotion($input: AddPromotion!) {
    addPromotion(addPromotion: $input) {
      id
      type
      amount
      stackable
      start
      end
      code
    }
  }
`;

export const updatePromotion = `
  mutation UpdatePromotion($input: UpdatePromotion!) {
    updatePromotion(updatePromotion: $input) {
      id
      type
      amount
      stackable
      start
      end
      code
    }
  }
`;

export type UpdatePromotion = {
  id: string;
  start?: string | null;
  end?: string | null;
};

export type AddPromotion = {
  code: string;
  start: string;
  end: string;
  amount: number;
  type: PromotionType;
  stackable: boolean;
};

export type NewPromotion = {
  code: string;
  start: string;
  end: string;
  amount: number;
  type: PromotionType;
  stackable: boolean;
};

export const getPromotionUsers = gql`
  query PromotionUsers {
    freeTreatmentUsers {
      id
      email
      type
    }
  }
`;

export const deletePromoUser = `
  mutation DeletePromoUser($id: ID!) {
    deleteFreeTreatmentUser(id: $id) {
      ids
    }
  }
`;

export const addPromoUser = `
  mutation AddFreeTreatmentUser($input: AddFreeTreatmentUser!) {
    addFreeTreatmentUser(addFreeTreatmentUser: $input) {
      id
      email
      type
    }
  }
`;

export type AddPromoUser = {
  email: string;
  type: string;
};

export type NewPromoUser = {
  email: string;
  type: string;
};

export const getVerifiedSpecialists = gql`
  query VerifiedSpecialists($filter: VerifiedSpecialistFilter!, $page: Int) {
    verifiedSpecialists(filter: $filter) @paginated(page: $page) {
      verifiedSpecialists {
        id
        type
        firstName
        lastName
        email
        phone
        phone2
        clinicName
        preferredProvider
        address {
          id
          line1
          line2
          city
          state
          postalCode
          country
          latitude
          longitude
        }
      }
      pageResults {
        page
        perPage
        totalItems
        totalPages
      }
    }
  }
`;

export const createVerifiedSpecialist = `
  mutation CreateVerifiedSpecialist($input: CreateVerifiedSpecialist!) {
    createVerifiedSpecialist(createVerifiedSpecialist: $input) {
      id
      type
      firstName
      lastName
      email
      phone
      phone2
      clinicName
      address {
        id
        line1
        line2
        city
        state
        postalCode
        country
      }
    }
  }
`;

export const updateVerifiedSpecialist = `
  mutation UpdateVerifiedSpecialist($input: UpdateVerifiedSpecialist!) {
    updateVerifiedSpecialist(updateVerifiedSpecialist: $input) {
      id
      type
      firstName
      lastName
      email
      phone
      phone2
      clinicName
      address {
        id
        line1
        line2
        city
        state
        postalCode
        country
      }
    }
  }
`;

export const deleteVerifiedSpecialist = `
  mutation DeleteVerifiedSpecialist($id: ID!) {
    deleteVerifiedSpecialist(id: $id) {
      ids
    }
  }
`;

export const getAuditLogs = gql`
  query AuditLogs($filter: AuditLogFilters!, $page: Int) {
    auditLogs(filter: $filter) @paginated(page: $page) {
      auditLogs {
        id
        staffId
        patientId
        requestId
        method
        url
        job
        service
        createdAt

        patient {
          id
          firstName
          lastName
          preferredName
        }
        staff {
          id
          firstName
          lastName
        }
      }
      pageResults {
        page
        perPage
        totalItems
        totalPages
      }
    }
  }
`;

export const useGetAuditLogs = (page, filter) => {
  if (filter === null) {
    filter = {
      ids: [] as String[],
      staffIds: [] as String[],
      patientIds: [] as String[],
      clinicIds: [],
      requestIds: [] as String[],
      methods: [] as String[],
      urls: [] as String[],
      jobs: [] as String[],
      services: [] as String[],
    };
  }

  const [{ fetching, error, data }] = useQuery({
    query: getAuditLogs,
    variables: {
      page,
      filter,
    },
  });
  return [fetching, error, (data && data.auditLogs) || undefined];
};
