import { useMutation } from "src/utils/http/gqlQuery";

type NewInternalNote = {
  modelType: string;
  modelId: string;
  text: string;
};

export const useCreateNote = () => {
  const [, execute] = useMutation<any, { note: NewInternalNote }>(mutation);
  return execute;
};

const mutation = `
mutation NewInternalNote($note: NewInternalNote!) {
  note: createInternalNote(internalNote: $note) {
    id
    author { id name }
    text
    createdAt
  }
}
`;
