import { getSummaryForSlugs } from "./generatedSummaryUtil";

const MIDLINES_RX_KEY = "midlines_rx";

const getMolarClassificationRx = answers => {
  const rightMolarA = answers["right_molar_classification_rx"];
  const leftMolarA = answers["left_molar_classification_rx"];

  if (!rightMolarA && !leftMolarA) {
    return null;
  }

  if (rightMolarA === "Maintain" && leftMolarA === "Maintain") {
    return "Maintain posterior occlusion";
  }
  if (
    (rightMolarA && rightMolarA !== "Maintain") ||
    (leftMolarA && leftMolarA !== "Maintain")
  ) {
    return "Improve posterior occlusion";
  }

  return null;
};

const getCrowdingSpacingRx = answers => {
  const upperAldRxA = answers["upper_ald_rx"];
  const lowerAldRxA = answers["lower_ald_rx"];

  if (!upperAldRxA || !lowerAldRxA) {
    return null;
  }

  if (upperAldRxA === "Maintain" && lowerAldRxA === "Maintain") {
    return "Maintain upper and lower crowding/spacing";
  }
  if (upperAldRxA === "Improve" && lowerAldRxA === "Improve") {
    return "Resolve upper and lower crowding/spacing";
  }

  if (upperAldRxA === "Improve" && lowerAldRxA === "Maintain") {
    return "Resolve upper and maintain lower crowding/spacing";
  }
  if (upperAldRxA === "Maintain" && lowerAldRxA === "Improve") {
    return "Resolve lower and maintain upper crowding/spacing";
  }
  return null;
};

const spacesRx = answers => {
  const spacesRxA = answers["spaces_rx"];
  const spacesRxTeethA = answers["teeth_to_maintail_space_for"];

  if (
    !spacesRxA ||
    !spacesRxTeethA ||
    spacesRxTeethA.length === 0 ||
    spacesRxA === "No"
  ) {
    return null;
  }
  return `Maintain space #${spacesRxTeethA
    .sort((a, b) => a - b)
    .join(", ")} for future restoration`;
};

const getMidlinesSummary = answers => {
  if (!answers[MIDLINES_RX_KEY]) return null;

  const midlinesAnswer = answers[MIDLINES_RX_KEY];

  return `${midlinesAnswer} midlines`;
};

export const generateOptionsAndGoalsSummary = answers => {
  const summaries = [
    getMolarClassificationRx(answers),
    getSummaryForSlugs(["vertical_bite_rx", "overjet_rx"], answers).join(", "),
    getSummaryForSlugs(["crossbite_treatment_option_rx"], answers).join(
      "\n • "
    ),
    getMidlinesSummary(answers),
    getCrowdingSpacingRx(answers),
    spacesRx(answers),
    getSummaryForSlugs(["treatment_option_public_notes"], answers).join(
      "\n • "
    ),
  ].filter(Boolean);

  return summaries ? " • " + summaries.join("\n • ") : null;
};
