import React from "react";
import Popover from "src/shared/Popover";
import SelectList from "src/shared/SelectList";
import CustomLabel from "src/scenes/Tasks/CustomLabelsList/CustomLabel";
import { TaskLabels } from "src/scenes/Tasks/types";

type Props = {
  value: TaskLabels[];
  onClick: (p: TaskLabels) => void;
};

export const taskLabelsOptions: TaskLabels[] = [
  "needs_unblocking",
  "resolved",
  "pending_pt_sim_approval",
  "virtual_refinement",
  "rush",
];

const LabelsPopover: React.FC<Props> = ({ children, value, onClick }) => {
  const Content = (
    <SelectList<TaskLabels>
      values={taskLabelsOptions}
      value={value}
      onClick={(p?: TaskLabels) => p && onClick(p)}
      render={(p: TaskLabels) => <CustomLabel label={p} key={p} />}
    />
  );

  return <Popover content={Content}>{children}</Popover>;
};

export default LabelsPopover;
