import styled, { css } from "react-emotion";
import color from "src/styles/color";

export const Content = styled.div`
  max-width: 320px;
  padding: 40px;
  background: ${color.white};

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin: 24px 0 12px;
`;

export const Text = styled.p`
  color: ${color.gray3};
  margin: 0 0 37px;
`;

export const icon = css`
  color: ${color.primary};
  height: 64px;
  width: 64px;
`;
