import React from "react";
import styled, { css } from "react-emotion";
import color from "src/styles/color";
import Logotype from "src/shared/Icons/Logotype";
import background from "./background.svg";

// TODO: same style used in src/shared/InputText/styles.tsx, do we need a shared styles file?
export const BaseInput = styled.input`
  width: 100%;
  display: flex;
  border: 1px ${color.border} solid;
  border-radius: 2px;
  height: 34px;
  padding: 6px 11px;

  &:focus,
  &:active {
    border: 1px ${color.blue} solid;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  ${({ theme: { error } }) =>
    error &&
    css`
      border-color: ${color.red};
    `}

  &:hover {
    border-color: ${color.blue};
  }
`;

export const Perimeter = styled.div`
  background-image: url(${background});
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  padding: 20vh 0 0 5vw;

  @media screen and (orientation: portrait) {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const FormWrapperPerimeter = styled.div`
  background: ${color.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 360px;
  padding: 56px 0 54px;

  & > svg {
    padding-bottom: 36px;
  }
`;

export const FormWrapper = props => (
  <FormWrapperPerimeter>
    <Logotype width={150} />

    {props.children}
  </FormWrapperPerimeter>
);

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 40px;
`;

const Input = styled(BaseInput)`
  height: 48px;
  border-color: ${color.gray1} !important;
  border-color: ${({ theme: { error } }) =>
    error ? color.red : color.gray1} !important;
  border-radius: 4px;
  background: ${color.gray1};

  &:focus,
  &:active {
    background: ${color.white};
  }
`;

const InputTextPerimeter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 16px;
  }
`;

const Label = styled.label`
  color: ${color.gray3};
  margin-bottom: 4px;
`;

export const Error = styled.div`
  color: ${color.red};
  margin: 8px 0 -26px;
`;

export const InputText = ({ error, label, ...props }) => (
  <InputTextPerimeter>
    <Label>{label}</Label>
    <Input type="text" {...props} theme={{ error }} />
  </InputTextPerimeter>
);

export const Button = styled.button`
  width: 100%;
  height: 40px;
  color: ${color.white};
  background: ${color.primary};
  border: 1px ${color.primary} solid;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  margin: 40px 0 0;
  &:disabled {
    cursor: not-allowed;
  }
  &:focus {
    outline: ${color.primary} auto 5px;
  }
`;

export const SecondaryButton = styled.button`
  width: 100%;
  height: 40px;
  color: ${color.gray5};
  background: ${color.white};
  border: 1px ${color.gray2} solid;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  margin: 16px 0 0;
  &:focus {
    outline: ${color.gray2} auto 5px;
  }
`;

export const ClearButton = styled.button`
  display: flex;
  padding: 0;
  margin: 16px 0 0;
  border: none !important;
  background: none !important;
  color: ${color.gray3};
  cursor: pointer;

  &:focus {
    outline: none;
    border: none !important;
  }
  &:active: {
    border: none !important;
  }
  &:hover {
    text-decoration: underline;
  }
`;
