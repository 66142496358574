import { injectGlobal } from "react-emotion";

injectGlobal`
  /* apply a natural box layout model to all elements, but allowing components to change */
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }


  @font-face{
    font-family: Roobert;
    font-weight: normal;
    src:url("/fonts/Roobert-Regular.otf") format("opentype");
  }

  @font-face{
    font-family: Roobert;
    font-weight: bold;
    src:url("/fonts/Roobert-Bold.otf") format("opentype");
  }

  @font-face{
    font-family: Roobert;
    font-weight: 100;
    src:url("/fonts/Roobert-Light.otf") format("opentype");
  }

  body {
    line-height: 1.285714286;
  }

  #root {
    height: 100%;
  }

  * {
    color: #343A3A;
    font-family: Roobert;
  }

  h1, .h1 {
    font-family: Roobert;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  h2 {
    font-size: 57px;
    line-height: 1.192982456;
  }

  h3 {
    font-size: 44px;
    line-height: 1.204545455;
  }

  h4 {
    font-size: 36px;
    line-height: 1.194444444;
  }

  h5 {
    font-size: 28px;
    line-height: 1.214285714;
  }

  h6 {
    font-size: 24px;
    line-height: 1.208333333;
  }
`;
