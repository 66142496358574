import { map, includes, capitalize } from "lodash";
import React, { Component } from "react";
import { compose } from "recompose";
import { getEventList } from "src/selectors/event";
import {
  NOTE,
  ISSUE_LIST_FORM,
  QUESTION_FORM,
  CHECKIN,
  COMMENT,
  FILE,
  SYSTEM_EVENT,
  EVENT_FILTERS,
} from "src/config/event-type";
import EventCard from "src/containers/treatment/components/event-card/event-card";
import Checkin from "src/containers/treatment/components/checkin/checkin";
import Note from "./note";
import Comment from "./comment";
import SystemEvent from "./system-event";
import { connect } from "react-redux";
import File from "./file";
import { Menu, MenuItem, Popover, RaisedButton } from "material-ui";
import styles from "./event-style";
import { updateUI } from "src/redux/modules/UI";
import { toggleItemInArray } from "src/utils/helperFn";
import FilterIcon from "material-ui/svg-icons/content/filter-list";
import { InternalNotesEnhancer } from "src/state/InternalNote";

const POP_OVER_FILTER_ID = "pop-over-filter";

const enhance = compose(
  InternalNotesEnhancer,
  connect(
    (state, props) => ({
      eventList: getEventList(state, props),
      eventStreamFilter: state.UI.eventStreamFilter,
    }),
    { updateUI }
  )
);

class EventList extends Component {
  static renderEvent(event) {
    switch (event.type) {
      case ISSUE_LIST_FORM:
        return null; // (<IssueList event={event} />)
      case QUESTION_FORM:
        return null; // (<QuestionForm event={event} />)
      case NOTE:
        return <Note event={event} />;
      case CHECKIN:
        return <Checkin checkin={event.data} />;
      case COMMENT:
        return <Comment event={event} />;
      case FILE:
        return <File event={event} />;
      case SYSTEM_EVENT:
        return <SystemEvent event={event} />;
      default:
        // only display title
        return null;
    }
  }
  state = { filterOpen: false };
  openFilter = () => {
    this.setState({
      filterOpen: true,
      anchorEl: document.getElementById(POP_OVER_FILTER_ID),
    });
  };
  closeFilter = () => {
    this.setState({ filterOpen: false });
  };
  updateFilter = filter => {
    this.props.updateUI({
      eventStreamFilter: toggleItemInArray(
        this.props.eventStreamFilter,
        filter
      ),
    });
  };
  render() {
    const eventList = this.props.eventList;
    const eventStreamFilter = this.props.eventStreamFilter;
    if (!eventList) return null;
    return (
      <div>
        <div style={styles.filterContainer}>
          <RaisedButton
            onClick={this.openFilter}
            id={POP_OVER_FILTER_ID}
            style={styles.filterButton}
            label="Filter Stream"
            icon={<FilterIcon />}
            labelPosition="before"
          />
          <Popover
            open={this.state.filterOpen}
            anchorEl={this.state.anchorEl} // Hack
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            targetOrigin={{ horizontal: "right", vertical: "top" }}
            onRequestClose={this.closeFilter}
          >
            <Menu>
              {map(EVENT_FILTERS, filter => (
                <MenuItem
                  id={`menu-${filter}`}
                  key={filter}
                  value={filter}
                  primaryText={`${
                    includes(eventStreamFilter, filter) ? "✓ " : ""
                  }${capitalize(filter)}`}
                  onClick={this.updateFilter.bind(this, filter)}
                />
              ))}
            </Menu>
          </Popover>
        </div>
        {map(eventList, event => {
          const linkToSelfForm =
            event.type === ISSUE_LIST_FORM || event.type === QUESTION_FORM;
          return (
            <EventCard
              inEventStream
              event={event}
              key={
                event.uuid || event.id || `${event.postTimestamp}-${event.type}`
              }
              linkToSelfForm={linkToSelfForm}
            >
              {EventList.renderEvent(event)}
            </EventCard>
          );
        })}
      </div>
    );
  }
}

export default enhance(EventList);
