import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { useWindowScroll } from "react-use";
import { anyNonNil as isUuid } from "is-uuid";
import { Link } from "react-router";
import { Menu, Avatar } from "antd";
import { Tab, TabLink, TabTitle } from "src/shared/Tabs";
import {
  Perimeter,
  StickyPerimeter,
  FormsTitle,
  DownTriangle,
  SmTab,
  Photo,
  Name,
  Col,
  Line,
  SkeletonTab,
  StyledDropdown,
  StyleMenu,
  PatientPronoun,
} from "./styles";

const availableForms = {
  beginning_visit: "Beginning Visit",
  treatment_setup: "Treatment Setup",
  indirect: "Indirect",
  refinement: "Refinement",
  outlier: "Outlier",
  followup_visit: "Followup Visit",
  referral: "Referral",
  covid_prescreen: "COVID-19 Prescreen",
  covid_results: "COVID-19 In-person Screen",
  previsit: "Previsit",
  restorations: "Restorations",
  hygiene: "Hygiene",
  whitening: "Whitening",
};

const getSelectedKey = (pathname: string) => {
  const parts = pathname.split("/").filter(path => path !== "");
  if (parts[2] === "about") {
    return "about";
  }
  if (parts.length > 3) {
    if (isUuid(parts[parts.length - 1])) {
      return parts[parts.length - 3];
    }
    return parts[parts.length - 2];
  }
  if (parts.length > 2) {
    if (isUuid(parts[parts.length - 1])) {
      return parts[parts.length - 2];
    }
    return parts[parts.length - 1];
  }
  return "about";
};

const TAB_TITLES = new Map([
  ["about", "About"],
  ["chat", "Chat"],
  ["forms", "Forms"],
  ["files", "Files"],
  ["billing", "Billing"],
  ["pipelines", "Pipelines"],
  ["wear-schedule", "Tray logs"],
  ["tray-logs", "Tray logs"],
  ["external-care", "External Care"],
]);

const Tabs = props => {
  const { y } = useWindowScroll();
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    setIsSticky(y >= 360);
  }, [y]);

  const { patient, patientAvatarUrl } = props;
  const selectedKey = getSelectedKey(props.pathname);
  const tabTitle = TAB_TITLES.get(selectedKey) || selectedKey;
  const patientName =
    get(patient, "preferredName") ||
    `${get(patient, "firstName")} ${get(patient, "lastName")}`;
  useEffect(() => {
    document.title = `Patient Profile: ${patientName} (${tabTitle})`;
    return () => {
      document.title = "";
    };
  }, [patientName, tabTitle]);

  const isLoading = !patient;
  const FormsMenu = isLoading ? (
    undefined
  ) : (
    <StyleMenu>
      {Object.keys(availableForms).map(formKey => (
        <Menu.Item key={formKey}>
          <Link to={`/patients/${patient.id}/forms/${formKey}`}>
            {availableForms[formKey]}
          </Link>
        </Menu.Item>
      ))}
    </StyleMenu>
  );

  if (isLoading) {
    return (
      <Perimeter>
        <SkeletonTab />
        <SkeletonTab />
        <SkeletonTab />
        <SkeletonTab />
        <SkeletonTab />
        <SkeletonTab />
        <SkeletonTab />
        <SkeletonTab />
      </Perimeter>
    );
  }

  return (
    <>
      <Perimeter>
        <Tab theme={{ active: selectedKey === "about" }}>
          <TabLink to={`/patients/${patient.id}`}>
            {TAB_TITLES.get("about")}
          </TabLink>
        </Tab>
        <Line />
        <Tab theme={{ active: selectedKey === "chat" }}>
          <TabLink to={`/patients/${patient.id}/chat`}>
            {TAB_TITLES.get("chat")}
          </TabLink>
        </Tab>
        <Line />
        <StyledDropdown
          overlay={FormsMenu}
          trigger={["hover", "click"]}
          placement="bottomLeft"
        >
          <Tab theme={{ active: selectedKey === "forms" }}>
            <FormsTitle>
              <TabTitle>{TAB_TITLES.get("forms")}</TabTitle>
              <DownTriangle />
            </FormsTitle>
          </Tab>
        </StyledDropdown>
        <Line />
        <Tab theme={{ active: selectedKey === "files" }}>
          <TabLink to={`/patients/${patient.id}/files`}>
            {TAB_TITLES.get("files")}
          </TabLink>
        </Tab>
        <Line />
        <Tab theme={{ active: selectedKey === "billing" }}>
          <TabLink to={`/patients/${patient.id}/billing`}>
            {TAB_TITLES.get("billing")}
          </TabLink>
        </Tab>
        <Line />
        <Tab theme={{ active: selectedKey === "pipelines" }}>
          <TabLink to={`/patients/${patient.id}/pipelines`}>
            {TAB_TITLES.get("pipelines")}
          </TabLink>
        </Tab>
        <Line />
        <Tab
          theme={{
            active: ["wear-schedule", "tray-logs"].includes(selectedKey),
          }}
        >
          <TabLink to={`/patients/${patient.id}/tray-logs`}>
            {TAB_TITLES.get("tray-logs")}
          </TabLink>
        </Tab>
        <Line />
        <Tab theme={{ active: selectedKey === "external-care" }}>
          <TabLink to={`/patients/${patient.id}/external-care`}>
            {TAB_TITLES.get("external-care")}
          </TabLink>
        </Tab>
      </Perimeter>

      <StickyPerimeter
        innerRef={props.innerRef}
        theme={{ isSticky, width: 1094 }}
      >
        <Col>
          <Photo>
            <Avatar icon="user" src={patientAvatarUrl} size={24} />
          </Photo>
          <Name>{patientName}</Name>
          {get(patient, "pronouns") ? (
            <PatientPronoun>({get(patient, "pronouns")})</PatientPronoun>
          ) : null}
        </Col>
        <Col style={{ height: "100%" }}>
          <SmTab theme={{ active: selectedKey === "about" }}>
            <TabLink to={`/patients/${patient.id}`}>
              {TAB_TITLES.get("about")}
            </TabLink>
          </SmTab>
          <Line />
          <SmTab theme={{ active: selectedKey === "chat" }}>
            <TabLink to={`/patients/${patient.id}/chat`}>
              {TAB_TITLES.get("chat")}
            </TabLink>
          </SmTab>
          <Line />
          <StyledDropdown
            overlay={FormsMenu}
            trigger={["hover", "click"]}
            placement="bottomLeft"
          >
            <SmTab theme={{ active: selectedKey === "forms" }}>
              <FormsTitle>
                <TabTitle>{TAB_TITLES.get("forms")}</TabTitle>
                <DownTriangle />
              </FormsTitle>
            </SmTab>
          </StyledDropdown>
          <Line />
          <SmTab theme={{ active: selectedKey === "files" }}>
            <TabLink to={`/patients/${patient.id}/files`}>
              {TAB_TITLES.get("files")}
            </TabLink>
          </SmTab>
          <Line />
          <SmTab theme={{ active: selectedKey === "billing" }}>
            <TabLink to={`/patients/${patient.id}/billing`}>
              {TAB_TITLES.get("billing")}
            </TabLink>
          </SmTab>
          <Line />
          <SmTab theme={{ active: selectedKey === "pipelines" }}>
            <TabLink to={`/patients/${patient.id}/pipelines`}>
              {TAB_TITLES.get("pipelines")}
            </TabLink>
          </SmTab>
          <Line />
          <SmTab
            theme={{
              active: ["wear-schedule", "tray-logs"].includes(selectedKey),
            }}
          >
            <TabLink to={`/patients/${patient.id}/tray-logs`}>
              {TAB_TITLES.get("tray-logs")}
            </TabLink>
          </SmTab>
          <Line />
          <SmTab theme={{ active: selectedKey === "external-care" }}>
            <TabLink to={`/patients/${patient.id}/external-care`}>
              {TAB_TITLES.get("external-care")}
            </TabLink>
          </SmTab>
        </Col>
      </StickyPerimeter>
    </>
  );
};

export default Tabs;
