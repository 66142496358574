import React, { useState } from "react";
import { Patient } from "src/types/gql";
import Box from "src/shared/Box";
import BlankSlate from "src/shared/BlankSlate";
import Button from "src/shared/Button";
import Loading from "src/shared/Loading";
import Error from "src/shared/Error";
import { usePolicies } from "./query";
import List from "./PolicyList";
import NewPolicyModal from "./PolicyForm";
// XXX: Update w/ new insurance stuff
import ClaimsFormModal from "src/containers/insurance/insurance-dialog";

type Props = {
  patient: Patient;
};

const Insurance: React.FC<Props> = props => {
  const [showNew, setShowNew] = useState(false);
  const [showClaims, setShowClaims] = useState(false);
  const [fetching, error, policies] = usePolicies(props.patient.id);

  if (error) {
    return <Error />;
  }

  return (
    <Box
      title="Insurance"
      actions={
        <Button onClick={() => setShowClaims(true)}>
          Generate claims form
        </Button>
      }
    >
      {showNew && (
        <NewPolicyModal
          patient={props.patient}
          onClose={() => setShowNew(false)}
        />
      )}
      {showClaims && (
        <ClaimsFormModal
          patientId={props.patient.id}
          open={true}
          onClose={() => setShowClaims(false)}
        />
      )}
      {fetching && <Loading />}
      {!fetching && policies.length === 0 && (
        <BlankSlate
          title="No insurance policies saved"
          text="To view a patient's insurance details add a new policy"
        />
      )}
      <List
        policies={policies}
        patient={props.patient}
        setShowNew={setShowNew}
      />
    </Box>
  );
};

export default Insurance;
