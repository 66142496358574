import React from "react";
import { Tooltip, Dropdown } from "antd";
import { StyledHeaderButton } from "./styles";

const HeaderButton = props => {
  if (props.dropdownMenu) {
    return (
      <Dropdown
        overlay={props.dropdownMenu}
        trigger={["click"]}
        placement="bottomRight"
      >
        <Tooltip placement="top" trigger={"hover"} title={props.tooltip}>
          <StyledHeaderButton theme={props.theme}>
            {props.children}
          </StyledHeaderButton>
        </Tooltip>
      </Dropdown>
    );
  }

  return (
    <Tooltip placement="top" trigger={"hover"} title={props.tooltip}>
      <StyledHeaderButton theme={props.theme} onClick={props.onClick}>
        {props.children}
      </StyledHeaderButton>
    </Tooltip>
  );
};

export default HeaderButton;
