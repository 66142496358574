import React from "react";
import { Task } from "../../types";
import QA from "src/shared/TaskActions/QA/QA";

type Props = {
  task: Task;
  referenceID: string;
};

const Content: React.FC<Props> = ({ task, referenceID }) => {
  const ref = task.references.find(r => r.id === referenceID);
  if (!ref) {
    return <p>Reference not found</p>;
  }
  return <QA build_id={ref.model.id} />;
};

export default Content;
