import React from "react";
import styled from "react-emotion";
import { titleCase } from "src/shared/util";
import { holdPipelineTypes } from "./holds";

type Props = {
  type: string;
  style?: any;
  className?: any;
  large?: boolean;
};

const PipelineType: React.FC<Props> = ({ type, style, className, large }) => (
  <Wrapper className={large ? "h1" : ""}>
    <Dot
      style={Object.assign(style || {}, { background: color(type) })}
      className={className}
    />
    {titleCase(type.replace(/_/g, " "))}
  </Wrapper>
);

export default PipelineType;

export const color = (type: string): string => {
  const color = colors[type];
  if (color) {
    return color;
  }
  if (holdPipelineTypes.includes(type) || type === "Pre-tx task") {
    return colors["hold"];
  }

  return "#fff";
};

export const colors = {
  export: "#59B8B8",
  segmentation: "#59B8B8",
  beginning: "#8168BB",
  retainer: "#E1A733",
  refinement: "#5893CE",
  reprint: "#C270A7",
  reboot: "#99C76B",
  hold: "#CE6E58",
  general_dentist_consult: "#999",
};

const Dot = styled.span`
  display: block;
  height: 12px;
  width: 12px;
  border-radius: 50px;
  margin: 0 8px 0 0;
`;

const Wrapper = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;

  h1 span {
    height: 24px !important;
    width: 24px !important;
  }
`;
