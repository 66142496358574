import { V } from "src/shared-styles";
const style = {
  base: {
    fontFamily: "roboto, sans-serif",
    color: V.colors.black,
    background: "white",
    display: "block",
    margin: "0 auto",
    width: "21cm",
  },
  page: {
    width: "21cm",
    height: "29.5cm",
    pageBreakAfter: "always",
  },
  inner: {
    marginTop: ".5in",
  },
  row: {
    display: "flex",
    height: "1in",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  item: {
    width: "2.625in",
    height: "1in",
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    display: "flex",
    flex: "3",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "0",
    padding: "3mm 0 1.5mm",
  },
  logoImg: {
    height: "100%",
    maxHeight: "100%",
  },
  content: {
    flex: "7",
  },
  text: {
    margin: "0",
    textAlign: "center",
    opacity: ".8",
  },
};

export default style;
