import { useQuery, UseQueryResponse } from "src/utils/http/gqlQuery";
import { TrayLog, ArchType } from "src/types/gql";

export type TimelineItem = {
  trayNumber: number;
  phase: string;
  status: string;
  isWearable: boolean;
  archType: ArchType;

  start: string | null;
  end: string | null;
  plannedEnd: string | null;
  appointmentSubtypeID: string | null;

  appointmentSubtype: null | { name: string };
  log: null | SimpleTrayLog;
  logs: TrayLog[];
};

export type SimpleTrayLog = {
  id: string;
  archType: ArchType;
  stage: number;
  nextStage: number | null;
  startTime: string;
  plannedEndTime: string;
  endTime: string | null;
  photos: Array<{ id: string; file: string }>;
};

export const useTimeline = (
  userID: string
): UseQueryResponse<{ timeline: TimelineItem[] }> => {
  return useQuery({ query, variables: { userID } });
};

export const useCurrentTray = (userID: string): TimelineItem | undefined => {
  const [{ fetching, error, data }] = useTimeline(userID);
  if (fetching || error || !data) {
    return;
  }
  return data.timeline.find(t => t.status === "in_progress");
};

export const query = `
query Timeline($userID: ID!) {
  timeline: trayTimeline(userID: $userID) {
    trayNumber
    phase
    status
    start
    end
    plannedEnd
    appointmentSubtypeID
    isWearable
    archType

    log {
      id
      archType
      stage
      nextStage
      startTime
      endTime
      plannedEndTime
      photos {
        id
        file {
          id
          filename
          type
          createdAt
        }
      }
    }
  }
}
`;
