import { useQuery, useMutation } from "src/utils/http/gqlQuery";
import {
  getAvailablePromotionsGql,
  getExpiredPromotionsGql,
  getUpcomingPromotionsGql,
  deletePromotionCode,
  addPromotionCode,
  updatePromotion,
  getPromotionUsers,
  deletePromoUser,
  addPromoUser,
  getVerifiedSpecialists,
  createVerifiedSpecialist,
  updateVerifiedSpecialist,
  deleteVerifiedSpecialist,
} from "./queries";

export const useGetAvailablePromotions = () => {
  const [{ fetching, error, data }] = useQuery({
    query: getAvailablePromotionsGql,
  });

  return [fetching, error, (data && data.availablePromotions) || undefined];
};

export const useGetExpiredPromotions = () => {
  const [{ fetching, error, data }] = useQuery({
    query: getExpiredPromotionsGql,
  });

  return [fetching, error, (data && data.expiredPromotions) || undefined];
};

export const useGetUpcomingPromotions = () => {
  const [{ fetching, error, data }] = useQuery({
    query: getUpcomingPromotionsGql,
  });

  return [fetching, error, (data && data.upcomingPromotions) || undefined];
};

export const useDeletePromoCode = () => {
  const [, refetchAvailable] = useQuery({
    query: getAvailablePromotionsGql,
    pause: true,
  });

  const [, refetchUpcoming] = useQuery({
    query: getUpcomingPromotionsGql,
    pause: true,
  });

  const [, refetchExpired] = useQuery({
    query: getExpiredPromotionsGql,
    pause: true,
  });

  const [, execute] = useMutation<undefined, { id: string }>(
    deletePromotionCode
  );

  const onDelete = async (id: string) => {
    const result = await execute({ id });
    refetchAvailable();
    refetchUpcoming();
    refetchExpired();
    return result;
  };

  return onDelete;
};

export const useAddNewPromotion = () => {
  const [, execute] = useMutation(addPromotionCode);
  return execute;
};

export const useUpdatePromotion = () => {
  const [, execute] = useMutation(updatePromotion);
  return execute;
};

export const useGetPromoUsers = () => {
  const [{ fetching, error, data }] = useQuery({
    query: getPromotionUsers,
  });

  return [fetching, error, (data && data.freeTreatmentUsers) || undefined];
};

export const useDeletePromoUser = () => {
  const [, refetchAvailable] = useQuery({
    query: getPromotionUsers,
    pause: true,
  });

  const [, execute] = useMutation(deletePromoUser);

  const onDelete = async (id: string) => {
    const result = await execute({ id });
    refetchAvailable();
    return result;
  };

  return onDelete;
};

export const useAddNewPromoUser = () => {
  const [, execute] = useMutation(addPromoUser);
  return execute;
};

export const useGetVerifiedSpecialists = () => {
  const [{ fetching, error, data }] = useQuery({
    query: getVerifiedSpecialists,
    variables: { filter: {} },
  });
  return [fetching, error, (data && data.verifiedSpecialists) || undefined];
};

export const topLevelVSFields = [
  "type",
  "firstName",
  "lastName",
  "email",
  "phone",
  "phone2",
  "clinicName",
  "preferredProvider",
];

export const addressFields = ["line1", "line2", "city", "state", "postalCode"];

export const useCreateVerifiedSpecialist = () => {
  const [, execute] = useMutation(createVerifiedSpecialist);
  return execute;
};

export const useUpdateVerifiedSpecialist = () => {
  const [, execute] = useMutation(updateVerifiedSpecialist);
  return execute;
};

export const useDeleteVerifiedSpecialsit = () => {
  const [, refetchAvailable] = useQuery({
    query: getVerifiedSpecialists,
    pause: true,
  });

  const [, execute] = useMutation(deleteVerifiedSpecialist);

  const onDelete = async (id: string) => {
    const result = await execute({ id });
    refetchAvailable();
    return result;
  };

  return onDelete;
};
