import React, { Component } from "react";
import PropTypes from "prop-types";
import { RaisedButton } from "material-ui";

import styles from "./doctor-input-style";
import TaskDialog from "../../../../components/task-dialog/task-dialog";
import Chat from "./chat";
import Note from "./note";

export default class extends Component {
  static childContextTypes = {
    patient: PropTypes.object,
  };

  getChildContext() {
    return { patient: this.props.patient };
  }

  state = {
    activeAction: null,
    taskDialogOpen: false,
  };
  handleTaskDialogClose = () => this.setState({ taskDialogOpen: false });
  handleTaskDialogOpen = () => this.setState({ taskDialogOpen: true });
  setActiveActionChat = () => this.setState({ activeAction: "chat" });
  setActiveActionNote = () => this.setState({ activeAction: "note" });

  render() {
    return (
      <div style={styles.container}>
        <div style={styles.S.sectionTitle}>
          <span style={styles.title}>Patient Actions</span>
        </div>
        <div>
          <RaisedButton
            style={styles.actionButton}
            label="Send chat"
            onClick={this.setActiveActionChat}
          />
          <RaisedButton
            style={styles.actionButtonMiddle}
            label="Internal note"
            id="test-internal-note"
            onClick={this.setActiveActionNote}
          />
        </div>
        {this.state.taskDialogOpen && (
          <TaskDialog open handleClose={this.handleTaskDialogClose} />
        )}
        {this.state.activeAction === "chat" && <Chat />}
        {this.state.activeAction === "note" && (
          <Note clinicalRecordId={this.props.clinicalRecord.id} />
        )}
      </div>
    );
  }
}
