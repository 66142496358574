import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import decode from "jwt-decode";
import { AuthContext } from "src/state/useAuth";

const enhance = connect(state => ({
  auth: state.auth,
  isLoggedIn: !!state.auth.authToken,
}));

class EnsureLoggedInContainer extends Component {
  static childContextTypes = {
    auth: PropTypes.object,
  };

  getChildContext() {
    return { auth: this.props.auth };
  }

  // @ky - 12/20/2019
  // Note: this is required for V1 api calls
  setRedirectAndReLogin = () => {
    const { pathname, search } = this.props.location;
    browserHistory.push({
      pathname: "/login",
      search: pathname.includes("/login")
        ? search
        : `?redirect=${encodeURIComponent(pathname)}`,
    });
  };
  // CLIENT only
  componentDidMount() {
    const { isLoggedIn } = this.props;
    if (!isLoggedIn) {
      this.setRedirectAndReLogin();
    }
  }

  render() {
    const { isLoggedIn, auth } = this.props;
    if (!isLoggedIn) return null;

    const user = decode(auth.authToken);
    user.id = user.user_id; // make the naming obvious... who named this?

    return (
      <AuthContext.Provider value={{ user }}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default enhance(EnsureLoggedInContainer);
