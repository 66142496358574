import React from "react";
import styled, { css } from "react-emotion";

import { notification, Menu, Dropdown } from "antd";

import Settings from "src/shared/Icons/Settings";
import useSelf from "src/state/self";
import color from "src/styles/color";
import { Order, Patient } from "src/types/gql";
import { isRefundManager } from "src/utils/roles";
import autoDownload from "src/utils/download";

import PdfIcon from "./icons/pdf.svg";
import CloseIcon from "./icons/close.svg";
import ApplyPromo from "./icons/apply_promo.svg";
import ChargeIcon from "./icons/charge.svg";
import ReopenIcon from "./icons/reopen.svg";
import SwitchIcon from "./icons/switch.svg";
import EditIcon from "./icons/edit.svg";
import DollarIcon from "./icons/dollar.svg";
import ClaimIcon from "./icons/claim.svg";
import UserIcon from "./icons/user.svg";
import InsuranceIcon from "./icons/insurance.svg";
import PaymentPlanIcon from "./icons/payment_plan.svg";
import RefundCheckIcon from "./icons/refundCheck.svg";

type Props = {
  patient: Patient;
  order: Order;
  setModalType: (string) => void;
  isPaymentPlan: boolean;
};

const StyledMenu = styled(Menu)`
  min-width: 200px;

  & .ant-dropdown-menu-item:hover {
    background-color: ${color.gray1};
  }

  & .ant-dropdown-menu-submenu-title {
    padding: 5px 12px;
    width: 100%;
    display: flex;
    align-items: center;

    > div {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const StyledSubmenu = styled(Menu.SubMenu)`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const StyledMenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const icon = css`
  width: 18px;
  height: 18px;
  margin: 8px;
`;

const openOrderActions = [
  "download_receipts",
  "close",
  "charge",
  "promo",
  "record_insurance",
  "add_payment_plan",
  "refund_check",
  "record_payment",
];

const orderStatusToOptions = {
  completed: ["download_receipts", "record_insurance"],
  open: openOrderActions,
  closed: ["reopen"],
  draft: openOrderActions,
};

const OrderSettings: React.FC<Props> = ({
  patient,
  order,
  setModalType,
  isPaymentPlan,
}) => {
  const self = useSelf();
  const downloadInsuranceReceipt = async () => {
    try {
      autoDownload(
        `api/v2/receipts/${order.id}/pdf`,
        "",
        `${patient.firstName}${patient.lastName}_insurance_receipt.pdf`
      );
    } catch (_) {
      notification.error({ message: "Error downloading insurance receipt" });
    }
  };

  const downloadPatientReceipt = async () => {
    try {
      autoDownload(
        `api/v2/receipts/${order.id}/patient/pdf`,
        "",
        `${patient.firstName}${patient.lastName}_receipt.pdf`
      );
    } catch (_) {
      notification.error({ message: "Error downloading patient receipt" });
    }
  };

  const getMenuItem = (actionName: string) => {
    switch (actionName) {
      case "download_receipts":
        return (
          <StyledSubmenu
            key={actionName}
            title={
              <div>
                <img className={icon} src={PdfIcon} alt="PDF" /> Download
                receipt
              </div>
            }
          >
            <StyledMenuItem onClick={downloadInsuranceReceipt}>
              <img className={icon} src={InsuranceIcon} alt="Building" />
              Insurance
            </StyledMenuItem>
            <StyledMenuItem onClick={downloadPatientReceipt}>
              <img className={icon} src={UserIcon} alt="User" />
              Patient
            </StyledMenuItem>
          </StyledSubmenu>
        );
      case "charge":
        return (
          <StyledMenuItem
            key={actionName}
            onClick={() => setModalType("newCharge")}
          >
            <img className={icon} src={ChargeIcon} alt="Charge" />
            Charge
          </StyledMenuItem>
        );
      case "refund_check":
        return (
          <StyledMenuItem
            key={actionName}
            onClick={() => setModalType("refundCheck")}
          >
            <img className={icon} src={RefundCheckIcon} alt="Refund Check" />
            Refund Check
          </StyledMenuItem>
        );
      case "close":
        return (
          <StyledMenuItem
            key={actionName}
            onClick={() => setModalType("cancelOrder")}
          >
            <img className={icon} src={CloseIcon} alt="PDF" />
            Close order
          </StyledMenuItem>
        );
      case "promo":
        return (
          <StyledMenuItem
            key={actionName}
            onClick={() => setModalType("promo")}
          >
            <img className={icon} src={ApplyPromo} alt="promo" />
            Apply promo code
          </StyledMenuItem>
        );
      case "reopen":
        return (
          <StyledMenuItem
            key={actionName}
            onClick={() => setModalType("reopen")}
          >
            <img className={icon} src={ReopenIcon} alt="open" />
            Reopen order
          </StyledMenuItem>
        );
      case "switch_payment_method":
        return (
          <StyledMenuItem
            key={actionName}
            onClick={() => setModalType("switchPaymentMethod")}
          >
            <img className={icon} src={SwitchIcon} alt="switch" />
            Switch payment method
          </StyledMenuItem>
        );
      case "markdown_amount":
        return (
          <StyledMenuItem
            key={actionName}
            onClick={() => setModalType("markdown")}
          >
            <img className={icon} src={EditIcon} alt="Edit" />
            Markdown amount
          </StyledMenuItem>
        );
      case "record_insurance":
        return (
          <StyledSubmenu
            key={actionName}
            title={
              <div>
                <img className={icon} src={ClaimIcon} alt="Paper" /> Record
                insurance
              </div>
            }
          >
            <StyledMenuItem
              onClick={() => setModalType("recordInsurancePayment")}
            >
              <img className={icon} src={DollarIcon} alt="Dollar" />
              Payment
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => setModalType("recordInsuranceClaim")}
            >
              <img className={icon} src={ClaimIcon} alt="Paper" /> Record Claim
            </StyledMenuItem>
            <StyledMenuItem onClick={() => setModalType("chargeInsuranceCard")}>
              <img className={icon} src={ChargeIcon} alt="charge" />
              Charge insurance card
            </StyledMenuItem>
          </StyledSubmenu>
        );
      case "add_payment_plan":
        return (
          <StyledMenuItem
            key={actionName}
            onClick={() => setModalType("addPaymentPlan")}
            disabled={isPaymentPlan}
          >
            <img
              className={icon}
              src={PaymentPlanIcon}
              alt="Line drawing of bank"
            />
            Add Payment Plan
          </StyledMenuItem>
        );
      case "record_payment":
        return (
          <StyledMenuItem
            key={actionName}
            onClick={() => setModalType("recordPayment")}
          >
            <img className={icon} src={ChargeIcon} alt="Record Payment" />
            Record Payment
          </StyledMenuItem>
        );
      default:
        return null;
    }
  };

  let options = orderStatusToOptions[order.status] || [];
  if ((order.status === "draft" || order.status === "open") && isPaymentPlan) {
    options = options.concat(["switch_payment_method"]);
  }

  if (
    (order.status === "draft" || order.status === "open") &&
    isRefundManager(self)
  ) {
    options = options.concat(["markdown_amount"]);
  }

  const OptionsMenu = (
    <StyledMenu mode="vertical">{options.map(getMenuItem)}</StyledMenu>
  );

  return (
    <Dropdown
      // @ts-ignore used for making dropdown sticky: https://github.com/ant-design/ant-design/issues/8268#issuecomment-438419077
      getPopupContainer={trigger => trigger.parentNode}
      overlay={OptionsMenu}
      trigger={["click"]}
      placement="bottomRight"
    >
      <StyledMenuButton type="button">
        <Settings />
      </StyledMenuButton>
    </Dropdown>
  );
};

export default OrderSettings;
