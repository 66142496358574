import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./order-utils-style";
import CreateOrder from "src/components/create-order/create-order";
import OrderList from "src/components/order-list/order-list";

export default class OrderUtils extends Component {
  static contextTypes = {
    patient: PropTypes.object,
  };

  render() {
    return (
      <div style={styles.S.contentBlock}>
        <div style={styles.S.sectionTitle}>
          <span style={styles.title}>Orders</span>
        </div>
        <div>
          <div style={styles.button}>
            <CreateOrder patient={this.context.patient} />
          </div>
          <div style={styles.button}>
            <OrderList patientId={this.context.patient.id} />
          </div>
        </div>
      </div>
    );
  }
}
