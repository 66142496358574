import React, { Component } from "react";
import PropTypes from "prop-types";
import RaisedButton from "material-ui/RaisedButton";
import styles from "./stripe-style";

export default class Stripe extends Component {
  static propTypes = {
    onReceiveToken: PropTypes.func,
  };
  static contextTypes = {
    patient: PropTypes.object,
    auth: PropTypes.object,
  };

  state = {
    showDialog: false,
  };

  componentDidMount() {
    // most of this was copied from stripe's dev documenation
    const stripeKey =
      process.env.REACT_APP_STRIPE_KEY || "pk_test_5Uyvze7dc28KinHdmq1t3JuS";
    this.stripe = window.Stripe(stripeKey);
    const elements = this.stripe.elements();
    // Create an instance of the card Element
    this.card = elements.create("card", {
      hidePostalCode: true,
      style: styles.card,
    });
    // Add an instance of the card Element into the `card-element` <div>
    this.card.mount("#card-element");

    this.card.addEventListener("change", ({ error }) => {
      const displayError = document.getElementById("card-errors");
      if (error) {
        displayError.textContent = error.message;
      } else {
        displayError.textContent = "";
      }
    });
  }
  submitStripeForm = e => {
    e.preventDefault();
    this.stripe.createToken(this.card).then(result => {
      if (result.error) {
        // Inform the customer that there was an error
        const errorElement = document.getElementById("card-errors");
        errorElement.textContent = result.error.message;
        this.setState({ verified: false });
      } else {
        // Send the token to your server
        this.setState({ verified: true });
        this.props.onReceiveToken && this.props.onReceiveToken(result.token);
      }
    });
  };

  render() {
    return (
      <div>
        <form
          onSubmit={this.submitStripeForm}
          id="payment-form"
          style={styles.form}
        >
          <div className="form-row" style={styles.formRow}>
            <div id="card-element" />
            <div id="card-errors" role="alert" />
          </div>
          {/*{this.state.verified &&*/}
          {/*<div style={styles.verified}>✔︎</div>*/}
          {/*}*/}
          <RaisedButton
            primary={!this.state.verified}
            style={styles.submit}
            type="submit"
            label="Verify Card"
          />
        </form>
      </div>
    );
  }
}
