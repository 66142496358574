import styled from "react-emotion";

export const Wrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;
`;

export const Label = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.003em;

  &.hidden {
    display: none;
  }
`;

export const HiddenInput = styled.input`
  position: fixed;
  top: -99px;
  left: -99px;
  opacity: 0;
  pointer-events: none;
`;

export const CheckboxView = styled.span`
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;

  position: relative;

  &.checked {
    background-color: #ea6938;
    background-image: url(/static/check.svg);
    background-repeat: no-repeat;
    background-position: 50%;
    border: none;
  }
`;
