import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import Loading from "src/shared/Loading";
import { BuildsEnhancer } from "src/state/Builds";
import { TrayTable } from "./TrayTable.js";
import { resolveDefects } from "src/state/hooks/issues";
import AddDefect from "./AddDefect.js";

/*
type Props = {
  buildID: string
};
*/

const QA = props => {
  // In order to QA trays we need:
  // 1. All of the individual TrayBuild items from the API
  // 2. All of the issues from the API
  // 3. All of the defects that currently exist for the build
  //
  // In order to load the build, we use the "model_id" from the task that we
  // are passed.
  //
  // TODO: Change the API to load each build from a pipeline ID so that we
  //       don't need to modify each task with the build model after creation.
  useEffect(() => {
    props.actions.getDetailedBuild(props.build_id);
  }, [props.actions, props.build_id]);

  const [selected, setSelected] = useState([]);
  const [selectedDefects, setSelectedDefects] = useState({});

  // Get the build from props.detailedTrayBuilds
  const build = Object.values(props.detailedTrayBuilds).find(
    b => b.id === props.build_id
  );

  if (!build || !Array.isArray(build.tray_builds)) {
    return <Loading />;
  }

  const onResolveDefects = async () => {
    const hide = message.loading("Resolving defects");
    const result = await resolveDefects({
      defectIds: Object.keys(selectedDefects),
    });
    hide();
    if (Array.isArray(result)) {
      message.success("Issues resolved");

      // TODO: This is fkn hacky. Refetch the API to update the UI.
      // Move Builds into a hook and share state across defects/builds.
      props.actions.getDetailedBuild(build.id);

      return;
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <AddDefect selected={selected} build={build} />
      <Button
        disabled={Object.keys(selectedDefects).length === 0}
        onClick={onResolveDefects}
      >
        Mark resolved
      </Button>
      <TrayTable
        selected={selected}
        setSelected={setSelected}
        build={build}
        selectedDefects={selectedDefects}
        setSelectedDefects={setSelectedDefects}
      />
    </div>
  );
};

export default BuildsEnhancer(QA);
