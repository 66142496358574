import React from "react";
import color from "src/styles/color";

interface Props {
  fill?: string;
}

const Birthday = ({ fill = color.gray3 }: Props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99734 3.33493C6.61088 3.33493 7.10785 2.83748 7.10785 2.22328C7.10785 2.01484 7.04955 1.81754 6.9496 1.65079L5.99734 0L5.04509 1.65079C4.94514 1.81754 4.88684 2.01484 4.88684 2.22328C4.88684 2.83748 5.3838 3.33493 5.99734 3.33493Z"
      fill={fill}
    />
    <path
      d="M9.32871 4.44661H6.55247V3.33496H5.44196V4.44661H2.66574C1.7468 4.44661 1 5.19418 1 6.11408V6.97003C1 7.57032 1.48861 8.05943 2.08828 8.05943C2.37978 8.05943 2.65184 7.9455 2.8573 7.73983L4.04552 6.55314L5.23099 7.73704C5.64188 8.14836 6.35815 8.14836 6.76901 7.73704L7.95723 6.55314L9.1427 7.73704C9.34813 7.94269 9.62022 8.05665 9.91172 8.05665C10.5114 8.05665 11 7.56753 11 6.96725V6.11408C10.9945 5.19418 10.2477 4.44661 9.32871 4.44661Z"
      fill={fill}
    />
    <path
      d="M8.5486 8.6567L7.95172 8.0592L7.35205 8.6567C6.62745 9.38205 5.36149 9.38205 4.63689 8.6567L4.03998 8.0592L3.44031 8.6567C3.08218 9.02076 2.6019 9.22085 2.08828 9.22085C1.68571 9.22085 1.31092 9.09301 1 8.87902V11.4442C1 11.7499 1.24986 12 1.55524 12H10.4392C10.7446 12 10.9944 11.7499 10.9944 11.4442V8.87905C10.6835 9.09303 10.3115 9.22088 9.90615 9.22088C9.39256 9.22088 8.91228 9.02078 8.5486 8.6567Z"
      fill={fill}
    />
  </svg>
);

export default Birthday;
