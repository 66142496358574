import { map, get, last } from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import GalleryPhotos from "./gallery-photo";
import { getThumbnailUrl, isImage } from "src/utils/helperFn";
import moment from "moment";

export default class Gallery extends Component {
  static propTypes = {
    fileList: PropTypes.array,
    thumbnailStyle: PropTypes.object,
  };
  render() {
    const { fileList } = this.props;
    let isFiles = false;
    const FILE_SET = map(fileList, fileObj => {
      const fileUrl = get(fileObj, "file.url");
      if (!isImage(fileUrl)) {
        isFiles = true;
        return { src: fileUrl };
      }
      return {
        src: fileUrl,
        thumbnail: getThumbnailUrl(fileUrl),
        caption: moment(fileObj.created_at).format("DD-MM-YY, hh:mma"),
      };
    });
    if (isFiles) {
      return (
        <div>
          {map(FILE_SET, file => (
            <div key={file.src}>
              <a download href={file.src}>
                {last(get(file, "src", "").split("/"))}
              </a>
            </div>
          ))}
        </div>
      );
    }
    return (
      <GalleryPhotos
        images={FILE_SET}
        thumbnailStyle={this.props.thumbnailStyle}
      />
    );
  }
}
