import React, { useState } from "react";
import styled from "react-emotion";
import { FormsEnhancer, Props as FormProps } from "src/state/Forms";
import { Button, message } from "antd";
import ViewSubmission from "src/scenes/Form/Submission";
import { Separator } from "./Shared";
import { Patient, Submission } from "src/types/api";

interface PassedProps {
  patient: Patient;
  parentSubmission: Submission;
}

const Title = styled("p")`
  font-weight: bold;
  margin: 1rem 0;
`;

const Referral = (props: PassedProps & FormProps) => {
  const isSubmitted = !!props.parentSubmission.submitted_at;

  // This records whether we're adding a submission so that we can disable the "Add"
  // button while waiting for our response
  const [adding, setAdding] = useState(false);

  const referralForm = Object.values(props.forms).find(f => {
    return f.slug === "referral";
  });

  if (!referralForm) {
    return null;
  }

  const referrals = props.submissions.filter(
    s =>
      s.parent_id === props.parentSubmission.id && s.form_id === referralForm.id
  );

  return (
    <div>
      <Button
        disabled={adding || isSubmitted || referrals.length > 0}
        onClick={async () => {
          const hide = message.loading("Creating a referral...");
          setAdding(true);
          await props.actions.newSubmission({
            formId: "referral",
            patientId: props.patient.id,
            parentId: props.parentSubmission.id,
          });
          setAdding(false);
          hide();
        }}
      >
        Add a referral
      </Button>

      {referrals.map(s => {
        return (
          <>
            <Title>Referral</Title>
            <ViewSubmission
              form={referralForm}
              submission={s}
              patient={props.patient}
            />
            <Separator />
          </>
        );
      })}
    </div>
  );
};

export default FormsEnhancer(Referral);
