import React from "react";

const STL = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1342 0H4.55023C4.08852 0 3.71423 0.374286 3.71423 1.10057V31.4286C3.71423 31.6257 4.08852 32 4.55023 32H27.4497C27.9114 32 28.2857 31.6257 28.2857 31.4286V7.416C28.2857 7.01829 28.2325 6.89029 28.1388 6.796L21.4897 0.146857C21.3954 0.0531429 21.2674 0 21.1342 0Z"
      fill="#E9E9E0"
    />
    <path
      d="M21.4285 0.0862846V6.85714H28.1993L21.4285 0.0862846Z"
      fill="#D9D7CA"
    />
    <path
      d="M27.4497 32H4.55023C4.08852 32 3.71423 31.6257 3.71423 31.164V22.2857H28.2857V31.164C28.2857 31.6257 27.9114 32 27.4497 32Z"
      fill="#A2A9AD"
    />
    <path
      d="M11.653 30.3497C12.909 30.3497 13.757 29.6937 13.757 28.6457C13.757 26.4297 10.813 27.3737 10.813 26.2057C10.813 25.8777 11.109 25.6537 11.581 25.6537C12.117 25.6537 12.453 25.9417 12.493 26.3817H13.637C13.605 25.3177 12.789 24.6217 11.581 24.6217C10.445 24.6217 9.64497 25.2777 9.64497 26.2057C9.64497 28.4057 12.549 27.5657 12.549 28.6617C12.549 29.0857 12.189 29.3097 11.653 29.3097C11.061 29.3097 10.685 28.9497 10.637 28.3817H9.48497C9.53297 29.5737 10.381 30.3497 11.653 30.3497ZM15.5442 30.2857H16.7442V25.7177H18.4002V24.6857H13.8882V25.7177H15.5442V30.2857ZM18.8677 30.2857H22.6197V29.2457H20.0757V24.6857H18.8677V30.2857Z"
      fill="white"
    />
    <g clip-path="url(#clip0)">
      <path
        d="M12.7956 17.5912L9.00838 11.8552L12.7916 5.30246L16.5788 11.0384L12.7956 17.5912Z"
        fill="#D7D7D7"
      />
      <path
        d="M12.7956 17.5912L19.6567 18.003L23.44 11.4502L16.5789 11.0384L12.7956 17.5912Z"
        fill="#BDBDBD"
      />
      <path
        d="M12.7917 5.30246L19.448 5.59606L23.4399 11.4502L16.5789 11.0384L12.7917 5.30246Z"
        fill="#A3A3A3"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="13.7143"
          height="13.7143"
          fill="white"
          transform="translate(13.7142 2.28572) rotate(30)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default STL;
