import React, { useMemo, useEffect } from "react";
import { find, sortBy } from "lodash/fp";
import { notification } from "antd";
import gql from "graphql-tag";
// shared
import { useQuery, useMutation } from "src/utils/http/gqlQuery";
import time from "src/shared/time";
import Skeleton from "src/shared/Skeleton";
// scenes/Scheduling
import CheckInAndOutModal from "src/scenes/SchedulingV2/CalendarView/CheckInAndOutModal";
import { getLabel } from "src/scenes/SchedulingV2/CalendarView/util";
// local
import Question, {
  QuestionLabelText,
} from "src/scenes/PatientProfile/Forms/Question";
import { FormUI } from "src/scenes/PatientProfile/Forms/GQLForms/useFormUI";
import { snakedQuestion } from "../../BeginningVisit/consts";

import { SectionTitle } from "../../BeginningVisit/styledComponents";

type Props = {
  patientID: string;
  ui: FormUI;
};

const Introduction = (props: Props) => {
  const { ui, patientID } = props;

  const [, updateAppointmentDoctor] = useMutation(setAppointmentDoctor);
  const [, updateAppointmentStaff] = useMutation(setAppointmentStaff);

  // TODO: add fetching and error mechanism
  const [{ data }, execute] = useQuery({
    query,
    variables: { userId: patientID },
  });

  useEffect(() => {
    if (patientID && !data) {
      execute();
    }
  }, [patientID, data, execute]);

  const appointmentOptions = useMemo(() => {
    return !data
      ? []
      : data.appointments.map(a => {
          const { toDateYear } = time(a.clinic.timezone);
          return {
            value: a.id,
            label:
              getLabel(a.appointmentSubtype.name) +
              " - " +
              toDateYear(a.startTime),
          };
        });
  }, [data]);

  const appointmentId = appointmentOptions.find(
    a => a.value === ui.submission.appointmentID
  );

  const appt = useMemo(() => {
    return (
      data && data.appointments.find(a => a.id === ui.submission.appointmentID)
    );
  }, [data, ui.submission]);

  const doctorOptions = useMemo(() => {
    return !data
      ? []
      : data.doctors
          .map(d => ({
            value: d.id,
            label: d.name.indexOf("Dr. ") === 0 ? d.name : "Dr. " + d.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
  }, [data]);

  const doctorId = appt && appt.doctor && appt.doctor.id;

  const staffOptions = useMemo(() => {
    if (!data) return [];
    return sortBy(
      "label",
      data.staff.map(s => ({ value: s.id, label: s.name }))
    );
  }, [data]);

  const staffId =
    appt && appt.staff && appt.staff.length > 0 && appt.staff[0].id;

  const handleUpdateStaff = async staffId => {
    const result = await updateAppointmentStaff({
      id: appointmentId.value,
      staff: [staffId],
    });

    if (result.error) {
      notification.error({
        message: `There was an error assigning this assistant`,
      });
    } else {
      notification.info({
        message: `Finished assigning assistant`,
      });
    }

    execute();
  };

  const handleUpdateDoctor = async doctorId => {
    const result = await updateAppointmentDoctor({
      input: {
        id: appointmentId.value,
        doctorId,
      },
    });

    if (result.error) {
      notification.error({
        message: `There was an error assigning this doctor`,
      });
    } else {
      notification.info({
        message: `Finished assigning doctor`,
      });
    }

    execute();
  };

  if (!ui) {
    return (
      <div style={{ padding: "0 0 0 32px" }}>
        <Skeleton dark width="286px" height="24px" margin="0 0 24px" />
        <Skeleton width="286px" height="16px" margin="0 0 16px" />
        <Skeleton width="143px" height="16px" />

        <Skeleton dark width="286px" height="24px" margin="32px 0 24px" />
        <Skeleton width="286px" height="16px" margin="0 0 16px" />
        <Skeleton width="143px" height="16px" />
      </div>
    );
  }

  return (
    <>
      <SectionTitle>Before we get started</SectionTitle>
      <Question
        question={{
          question_type: "dropdown",
          question_text: "Which appointment is this form for?",
          default_placeholder: "Select appointment",
          choices: appointmentOptions,
        }}
        answer={appointmentId}
        handleChange={({ value }) => {
          ui.updateSubmission({
            appointmentID: value,
          });
        }}
      />
      {ui.submission.appointmentID && (
        <>
          <QuestionLabelText>Did you check in the patient?</QuestionLabelText>
          {/* TODO: CheckInAndOutModal does not update properly */}
          <CheckInAndOutModal
            appointment={appt}
            style={{
              width: "100px",
              height: "36px",
            }}
            handleSuccess={() => execute()}
          />
          <Question
            question={{
              ...snakedQuestion(ui.form.questions["doctor_id"]),
              choices: doctorOptions,
              question_type: "dropdown",
            }}
            answer={find({ value: doctorId }, doctorOptions)}
            handleChange={({ value }) => handleUpdateDoctor(value)}
          />
          <Question
            question={{
              ...snakedQuestion(ui.form.questions["staff_id"]),
              choices: staffOptions,
              question_type: "dropdown",
            }}
            answer={find({ value: staffId }, staffOptions)}
            handleChange={({ value }) => handleUpdateStaff(value)}
          />
        </>
      )}
    </>
  );
};

export default Introduction;

const query = gql`
  query SubmissionAppointmentInfo($userId: ID!) {
    appointments: appointments(userId: $userId) {
      id
      startTime
      endTime
      actualStartTime
      actualEndTime
      appointmentType {
        id
        name
      }
      appointmentSubtype {
        id
        name
      }
      clinic {
        timezone
      }
      doctor {
        id
      }
      staff {
        id
      }
    }
    doctors: staff(role: "orthodontist") {
      id
      name
    }
    staff: staff(role: "sales_manager") {
      id
      name
    }
  }
`;

const setAppointmentDoctor = gql`
  mutation UpdateAppointment($input: UpdateAppointment!) {
    updateAppointment(appointment: $input) {
      id
      doctor {
        id
      }
    }
  }
`;

const setAppointmentStaff = gql`
  mutation UpdateAppointment($id: ID!, $staff: [ID!]) {
    setStaff: updateAppointmentStaff(appointmentId: $id, staff: $staff) {
      id
      staff {
        id
        name
        roles {
          role
        }
      }
    }
  }
`;
