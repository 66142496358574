import styled from "react-emotion";
import { Link } from "react-router";
import color from "src/styles/color";

export const Perimeter = styled.div`
  width: 65px;
  height: 100%;
  background: ${color.white};
  flex: 0;
  position: absolute;
  top: 0;
  border-right: 1px solid ${color.border};
  z-index: 1;
`;

export const StyledLink = styled(Link)`
  position: relative;
  color: ${color.gray5};

  &:hover {
    color: #eee;
  }

  &:last-child {
    flex: flex;
  }
`;

export const Logo = styled.div`
  margin-left: 16px;
  margin-top: 24px;
`;
