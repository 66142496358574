import React, { useReducer, useContext } from "react";
import { notification } from "antd";
import { useSchedulingRestrictions } from "src/state/self";
// types
import { EmptySlot } from "src/types/api";
// local
import { useMutation } from "src/utils/http/gqlQuery";
import useCalendarViewContext from "./useCalendarViewContext";
import { slotToAppointment, slotToTemplate } from "../types";
import { upsertTemplateGql, openAvailabilityGql } from "../queries";

type EditMode = "availability" | "edit-template" | "new-template";
type UpdateUnsaved = (unsaved: Array<EmptySlot>) => void;

type EditContextType = {
  editMode: EditMode;
  editable: boolean;
  canCancelBV: boolean;
  // A list of unsaved openings for a template or a day.
  unsaved: Array<EmptySlot>;
  // A callback supplied to the calendar which should be called when the unsaved
  // list changes.
  //
  // This allows components that own the Calendar to manage the saving of slots,
  // vs the calendar having to do this itself.
  onUpdateUnsaved: UpdateUnsaved;

  // Allow any component to modify the editable state.
  onUpdateEditable: (to: boolean) => void;
  state: State;
  onSave: () => void;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onNewTemplate: (newTemplateName: string) => void;
};

// EditContext is used to store the current edits to a calendar, and for a callback
// to edit the unsaved array.
//
// This allows a single parent component to manage unsaved state for either:
//
// 1. Opening availability
// 2. Creating a template
const EditContext = React.createContext<EditContextType>({
  editMode: "availability",
  editable: false,
  canCancelBV: false,
  unsaved: [],
  onUpdateUnsaved: () => {},
  onUpdateEditable: (to: boolean) => {},
  state: {
    newTemplateName: "",
    editMode: "availability",
    unsaved: [],
    editable: false,
    modal: false,
  },
  onSave: () => {},
  showModal: false,
  setShowModal: () => {},
  onNewTemplate: () => {},
});

type State = {
  // Used when saving a new template
  editMode: EditMode;
  editable: boolean;
  modal: boolean;
  newTemplateName: string;
  unsaved: EmptySlot[];
};

type Action =
  | { type: "setEditable"; editable: boolean }
  | { type: "updateUnsaved"; unsaved: EmptySlot[] }
  | { type: "editMode"; mode: EditMode; newTemplateName?: string }
  | { type: "modal"; show: boolean };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "setEditable":
      const { editable } = action;
      // When we're disabling editability, always toggle the edit mode back to availability
      // by default.
      return {
        ...state,
        editable,
        editMode: editable ? state.editMode : "availability",
      };
    case "updateUnsaved":
      return { ...state, unsaved: action.unsaved };
    case "modal":
      return { ...state, modal: action.show };
    case "editMode":
      return {
        ...state,
        editMode: action.mode,
        modal: false,
        newTemplateName: action.newTemplateName || "",
      };
  }
  return state;
};

export const EditContextProvider = props => {
  const { isScheduleManager, canCancelBV } = useSchedulingRestrictions();
  const { clinic } = useCalendarViewContext();
  const [state, dispatch] = useReducer(reducer, {
    editMode: "availability",
    editable: false,
    modal: false,
    newTemplateName: "",
    unsaved: [],
  });

  const [, openAvailability] = useMutation(openAvailabilityGql);
  const [, upsertTemplate] = useMutation(upsertTemplateGql);

  const onSave = async () => {
    let result;

    // Depending on the edit mode, we may be opening availability or creating a template.
    switch (state.editMode) {
      case "availability": {
        // We need to map all templates to the correct GQL types.  Unfortunately, slots
        // are stored with an actual date - not an offset.
        const appointments = state.unsaved.map(u =>
          slotToAppointment(u, clinic.timezone)
        );
        result = await openAvailability({ appointments });
        break;
      }
      case "new-template": {
        const templates = state.unsaved.map(u =>
          slotToTemplate(state.newTemplateName, u, clinic.timezone)
        );
        result = await upsertTemplate({
          new: templates,
          update: [],
          delete: [],
        });
        break;
      }
    }

    if (result.error) {
      return notification.error({ message: "Error saving" });
    }

    notification.success({ message: "Saved" });
    dispatch({ type: "updateUnsaved", unsaved: [] });
    dispatch({ type: "editMode", mode: "availability" });
  };

  const value = {
    dispatch,
    editMode: state.editMode,
    editable: isScheduleManager && state.editable,
    canCancelBV,
    onSave,
    onUpdateEditable: (to: boolean) =>
      dispatch({ type: "setEditable", editable: to }),
    onUpdateUnsaved: (to: EmptySlot[]) =>
      dispatch({ type: "updateUnsaved", unsaved: to }),
    onNewTemplate: (newTemplateName: string) =>
      dispatch({ type: "editMode", mode: "new-template", newTemplateName }),
    state,
    unsaved: state.unsaved,
    showModal: state.modal,
    setShowModal: show => dispatch({ type: "modal", show }),
  };

  return (
    <EditContext.Provider value={value}>{props.children}</EditContext.Provider>
  );
};

export default function useEditContext(): EditContextType {
  return useContext(EditContext);
}
