import React from "react";
import { DateTime } from "luxon";
import m from "moment";
import { DatePicker as AntDatePicker } from "antd";

type Props = {
  disabled?: boolean;
  date?: string;
  style?: object;
  className?: string;
  onChange: (string) => void;
  allowBlank?: boolean;
  timezone: string;
};

const DatePicker = ({
  disabled,
  date,
  allowBlank,
  onChange,
  style,
  className,
  timezone,
}: Props) => {
  const onAntChange = m => {
    if (!m) {
      onChange("");
    } else {
      const result = DateTime.fromJSDate(m.toDate()).toISO();
      onChange(result);
    }
  };

  const fallbackValue = allowBlank ? undefined : m();

  return (
    <div style={style} className={className}>
      <AntDatePicker
        disabled={disabled}
        style={{ flex: "1", margin: "0" }}
        value={date ? m(date) : fallbackValue}
        onChange={onAntChange}
        allowClear={false}
      />
    </div>
  );
};

export default DatePicker;
