import { CombinedError, useQuery, useMutation } from "src/utils/http/gqlQuery";
import { InsurancePolicy, Eligibility } from "src/types/gql";

export const policiesQuery = `
query SelectPatientPolicies($userId: ID!) {
  insurancePolicies(userId: $userId) {
    id,
    state,
    policyId,
    policyName,
    policyType,
    subscriberMemberId,
    subscriberFirstName,
    subscriberLastName,
    subscriberDOB,
    subscriberGroupNumber,
    dependentMemberId,
    dependentFirstName,
    dependentLastName,
    dependentDOB,

    InsuranceProvider {
      id,
      name
      externalPayerID
      gateway
    },

    Eligibilities {
      id,
      insuranceType,
      calendarYear,
      calendarYearRemaining,
      lifetimeMax,
      lifetimeMaxRemaining,
      percent
    }
  }
}
`;

export const usePolicies = (
  userID: string
): [boolean, CombinedError | undefined, InsurancePolicy[]] => {
  const [{ fetching, data, error }] = useQuery<{
    insurancePolicies: InsurancePolicy[];
  }>({
    query: policiesQuery,
    variables: { userId: userID },
  });

  return [fetching, error, (data && data.insurancePolicies) || []];
};

const deleteEligibility = `
  mutation DeleteInsurancePolicy($id: ID!) {
    deleteInsurancePolicy(id: $id) {
      ids
    }
  }
`;

export const useDelete = () => {
  const [, del] = useMutation(deleteEligibility);
  return del;
};

const addInsuranceProvider = `
  mutation AddInsuranceProvider(
    $providerName: String!
  ) {
    addInsuranceProvider(providerName: $providerName) {
      id
      name
      externalPayerID
    }
  }
`;

export const useAddInsuranceProvider = () => {
  const [, create] = useMutation(addInsuranceProvider);
  return create;
};

const createEligibility = `
  mutation CreateInsurancePolicyEligibility(
    $input: NewInsurancePolicyEligibility!
  ) {
    createInsurancePolicyEligibility(input: $input) {
      id
      percent
    }
  }
`;

export const useCreate = () => {
  const [, create] = useMutation(createEligibility);
  return create;
};

const checkEligibilityGql = `
  mutation CheckInsurancePolicy($id: ID!) {
    checkInsurancePolicy(id: $id) {
      id
      insuranceType
      calendarYear
      calendarYearRemaining
      lifetimeMax
      lifetimeMaxRemaining
      percent
    }
  }
`;

export const useCheck = () => {
  const [, execute] = useMutation<any, { id: string }>(checkEligibilityGql);
  return execute;
};

export const eligibilityHistoryGql = `
  query InsuranceEligibilityHistory($userID: ID!) {
    insuranceEligibilityHistory(userID: $userID) {
      id
      insurancePolicyId,
      npiUsed,
      inNetwork,
      createdAt,
      lifetimeMax,
      lifetimeMaxRemaining,
      calendarYear,
      calendarYearRemaining,
      percent,
      limitations,
      coinsuranceDisclaimers,
      InsuranceProvider {
        id
        name
      }
    }
  }
`;

const byCreatedAt = (histA, histB) => {
  return histB.createdAt.localeCompare(histA.createdAt);
};

const parseHistory = data => {
  if (!data || !data.insuranceEligibilityHistory) return [];

  return [...data.insuranceEligibilityHistory].sort(byCreatedAt);
};

export const useEligibilityHistory = (
  userID: string
): [boolean, CombinedError | undefined, Eligibility[]] => {
  const [{ fetching, data, error }] = useQuery<{
    insuranceEligibilityHistory: Eligibility[];
  }>({
    query: eligibilityHistoryGql,
    variables: { userID },
  });
  return [fetching, error, parseHistory(data)];
};
