import React, { useState } from "react";
import moment from "moment";
import { DatePicker } from "antd";
import styled from "react-emotion";
import Popover from "src/shared/Popover";

type Props = {
  value?: string | null;
  onClick: (s?: string) => void;
};

const DatePopover: React.FC<Props> = ({ children, value, onClick }) => {
  const [visible, setVisible] = useState(false);

  const Content = (
    <Wrapper>
      <DatePicker
        value={value ? moment(value) : undefined}
        onChange={m => {
          onClick(m ? m.toISOString() : undefined);
          setVisible(false);
        }}
        autoFocus={true}
        open={true}
      />
    </Wrapper>
  );

  return (
    <Popover
      content={Content}
      visible={visible}
      close={() => setVisible(false)}
    >
      <div onClick={() => setVisible(true)}>{children}</div>
    </Popover>
  );
};

export default DatePopover;

const Wrapper = styled.div`
  .ant-calendar-picker-input {
    display: none;
  }
`;
