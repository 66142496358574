import React from "react";
import { notification } from "antd";
import Box from "src/shared/Box";
import Modal from "src/shared/Modal";

import { VerifiedSpecialist } from "src/types/gql";
import _ from "lodash";
import { useUpdateVerifiedSpecialist } from "src/scenes/EMRAdmin/useEMRAdminInfo";
import { topLevelFields } from "src/utils/externalProvider";

import { useUpdateExternalSpecialist } from "../query";
import EditProviderForm, {
  Props as EditProviderFormProps,
} from "./EditProviderForm";

const addressFields = ["id", "line1", "line2", "city", "state", "postalCode"];

type Props = {
  onClose: () => void;
  refetch: () => void;
  specialistId: string;
  state: string | undefined;
  specialist: VerifiedSpecialist;
  boxDisplay: boolean | undefined;
};

const EditExternalProviderSpecialistModal: React.FC<Props> = ({
  onClose,
  specialistId,
  state,
  specialist,
  boxDisplay,
}) => {
  const updateExternalSpecialist = useUpdateExternalSpecialist();
  const updateVerifiedSpecialist = useUpdateVerifiedSpecialist();
  const Child = boxDisplay ? EditProviderForm : ModalContainer;

  const onSave = async (patientProviderStateStatus, updatedSpecialist) => {
    if (!updatedSpecialist) {
      notification.error({
        message: "External Specialist required.",
      });
      return;
    }

    let payload: any = {
      id: specialistId,
    };

    if (patientProviderStateStatus) {
      payload = {
        ...payload,
        state: patientProviderStateStatus,
      };
    }

    let result: any = await updateExternalSpecialist({
      updateExternalSpecialist: {
        ...payload,
      },
    });

    if (!result || result.error) {
      notification.error({
        message: "Unable to update External Specialist Status",
      });
      return;
    }

    result = await updateVerifiedSpecialist({
      input: {
        ..._.pick(updatedSpecialist, topLevelFields),
        id: updatedSpecialist.id,
        updateAddress: _.pick(updatedSpecialist.address, addressFields),
      },
    });

    if (!result || result.error) {
      notification.error({ message: "Unable to update Verified Specialist" });
      return;
    }

    notification.success({
      message: "External Specialist Status & Verified Specialist Updated",
    });

    onClose();
  };

  return (
    <Child
      currentState={state}
      specialist={specialist}
      onSave={onSave}
      onClose={onClose}
    />
  );
};

const ModalContainer: React.FC<EditProviderFormProps> = ({
  currentState,
  specialist,
  onSave,
  onClose,
}) => (
  <Modal onClose={onClose}>
    <Box
      title="Update Provider"
      style={{ maxHeight: "75vh", overflow: "scroll" }}
    >
      <EditProviderForm
        currentState={currentState}
        specialist={specialist}
        onSave={onSave}
        onClose={onClose}
      />
    </Box>
  </Modal>
);

export default EditExternalProviderSpecialistModal;
