import React, { PureComponent } from "react";
import Table from "antd/lib/table";

type PassedProps = {
  builds: Array<Build>,
};

type Props = PassedProps;

export default class BuildTable extends PureComponent<Props, void> {
  render() {
    const columns = [
      {
        title: "Tray",
        width: 50,
        defaultSortOrder: "ascend",
        sorter: (a: Build, b: Build) => a.tray.stage - b.tray.stage,
        render: (record: DetailedBuild) => record.tray.stage,
      },
      {
        title: "Arch type",
        width: 50,
        dataIndex: "arch_type",
      },
    ];

    const { builds } = this.props;

    return (
      <Table
        columns={columns}
        dataSource={builds}
        size="small"
        pagination={{
          defaultPageSize: 100,
        }}
      />
    );
  }
}
