import React from "react";

export default () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="8"
      cy="8"
      r="7.25"
      stroke="#A2A9AD"
      strokeWidth="1.5"
      fill="none"
    />
    <path
      d="M4.571 7.792l2.42 2.494 4.438-4.572"
      stroke="#A2A9AD"
      strokeWidth="1.5"
      strokeLinecap="round"
      fill="none"
    />
  </svg>
);
