import React from "react";
import styled from "react-emotion";
// shared
import LegacyModal from "src/shared/LegacyModal";
import Button from "src/shared/Button";
// styles
import textStyles from "src/styles/textStyles";
import color from "src/styles/color";
// local
import GeneralDentistSvg from "./GeneralDentistSvg";

const Title = styled.div`
  ${textStyles("xlarge")};
  font-weight: bold;
`;

const Perimeter = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LargeText = styled.div`
  ${textStyles("xxlarge")};
  font-weight: bold;
  margin: 32px 0 16px;
`;

const LightText = styled.div`
  ${textStyles("large")};
  color: ${color.gray3};
  margin-bottom: 40px;
  max-width: 360px;
  text-align: center;
`;

// TODO fix outer most padding
export const GeneralDentistModal = (props: any) => (
  <LegacyModal
    isOpen={props.isOpen}
    closeModal={props.handleClose}
    width="440px"
  >
    <LegacyModal.Title>
      <Title>General Dentist Appointment</Title>
    </LegacyModal.Title>
    <LegacyModal.Content>
      <Perimeter>
        <GeneralDentistSvg />
        <LargeText>Does the patient need to see a dentist?</LargeText>
        <LightText>
          If so, please make sure to schedule an appointment with  the general
          dentist. Click “Done” once complete.
        </LightText>
        <Button
          kind="primary"
          onClick={props.handleDone}
          style={{ width: "100px", height: "36px" }}
        >
          Done
        </Button>
      </Perimeter>
    </LegacyModal.Content>
  </LegacyModal>
);

export default GeneralDentistModal;
