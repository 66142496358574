import { startCase, get, map } from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./update-status-style";

import DropDownMenu from "material-ui/DropDownMenu";
import MenuItem from "material-ui/MenuItem";

import {
  postPatientStatus,
  loadPatientStatus,
  loadPatient,
} from "src/redux/modules/patient";
import { connect } from "react-redux";

const enhance = connect(
  state => ({
    statuses: get(state, "serverConfig.data.statuses"),
  }),
  {
    postPatientStatus,
    loadPatientStatus,
    loadPatient,
  }
);

class UpdateStatus extends Component {
  static propTypes = {
    patient: PropTypes.object,
  };
  handleChange = (event, index, value) => {
    const { patient } = this.props;
    this.props.postPatientStatus(patient.id, value).then(() => {
      this.props.loadPatient(patient.id);
      this.props.loadPatientStatus(patient.id);
    });
  };
  render() {
    return (
      <div style={styles.S.contentBlock}>
        <div style={styles.S.sectionTitle}>
          <span style={styles.title} className="test-event-title">
            Status
          </span>
        </div>
        <div>
          <DropDownMenu
            style={styles.dropDownMenu}
            value={get(this.props, "patient.status.name")}
            onChange={this.handleChange}
          >
            {map(this.props.statuses, status => {
              return (
                <MenuItem
                  key={status.id}
                  value={status.name}
                  primaryText={startCase(status.name)}
                />
              );
            })}
          </DropDownMenu>
        </div>
      </div>
    );
  }
}

export default enhance(UpdateStatus);
