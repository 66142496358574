import styled from "react-emotion";

export const ProviderBox = styled.div`
  margin-top: 16px;
  width: 147px;
`;

export const VerifiedProviderContainer = styled.div`
  display: flex;
`;
