import gql from "graphql-tag";
import { useQuery } from "src/utils/http/gqlQuery";
import { AppointmentType } from "src/types/gql";

const query = gql`
  query SchedulingData {
    types: appointmentTypes {
      id
      name
      enabled
      doctorDuration
      largestPatientBuffer
      largestRemainingDuration
    }
  }
`;

export default function useAppiontmentTypes(): Array<AppointmentType> {
  const [{ fetching, data }] = useQuery({ query });
  return fetching ? [] : data.types;
}
