import * as React from "react";
import styled from "react-emotion";
import { AppointmentWithOffset } from "./types";
import EditBookedSlot from "./EditBookedSlot";
import EditUnbookedSlot from "./EditUnbookedSlot";

type Props = {
  appt: AppointmentWithOffset;
  close: (e?: React.SyntheticEvent | undefined) => void;
};

const Wrapper = styled.div`
  cursor: default;

  h2 {
    margin: 0;
  }

  small {
    opacity: 0.6;
    font-size: 0.75rem;
  }
`;

const EditScheduledSlot = ({ appt, close }: Props) => {
  const C = appt.userId ? EditBookedSlot : EditUnbookedSlot;

  return (
    <Wrapper>
      <C appt={appt} close={close} />
    </Wrapper>
  );
};

export default EditScheduledSlot;
