import React, { Component } from "react";
import PropTypes from "prop-types";
import { issueListFormParser } from "src/utils/form-parser";
import { S } from "src/shared-styles";
import { find, orderBy } from "lodash";
import { FORM_NAME } from "../../config/treatment-forms";
const incisorCalculator = [
  { key: "Edge to Edge", val: 0 },
  { key: "<1mm", val: 4 },
  { key: "1.1-2mm", val: 8 },
  { key: "2.1-4mm", val: 12 },
  { key: ">4mm", val: 16 },
  { key: "<1/3 Coverage", val: 0 },
  { key: "1/3-2/3 Coverage", val: 4 },
  { key: "2/3-full Coverage", val: 8 },
  { key: "Fully Covered", val: 12 },
];
const sagittalCalculator = [
  { key: "Cusp to Embrasure", val: 0 },
  { key: "<Between>", val: 3 },
  { key: "Cusp to Cusp", val: 6 },
];
const upperArchLengthCalculator = [
  { key: "- <2mm", val: 0 },
  { key: "- 2-5mm", val: 5 },
  { key: "- 5-9mm", val: 10 },
  { key: "- 9-13mm", val: 15 },
  { key: "- <13mm", val: 20 },
  { key: "+ <2mm", val: 0 },
  { key: "+ 2-5mm", val: 5 },
  { key: "+ 5-9mm", val: 10 },
  { key: "+ 9-13mm", val: 15 },
  { key: "+ >13mm", val: 20 },
  { key: "+ >13mm ", val: 20 },
];

const caseComplexityDefinition = [
  {
    score: 28,
    name: "Easy",
  },
  {
    score: 50,
    name: "Mild",
  },
  {
    score: 63,
    name: "Moderate",
  },
  {
    score: 77,
    name: "Difficult",
  },
  {
    score: 9999,
    name: "Very Difficult",
  },
];
const improvementGradeDefinition = [
  {
    score: -85,
    name: "Not Improved or Worse",
  },
  {
    score: -54,
    name: "Minimally Improved",
  },
  {
    score: -26,
    name: "Moderately Improved",
  },
  {
    score: -1,
    name: "Substantially Improved",
  },
  {
    score: 9999,
    name: "Greatly Improved",
  },
];
const calculateScore = data => {
  const aesthetic = issueListFormParser(data, "aesthetic");
  const aestheticScore = aesthetic ? aesthetic * 7 : 0;

  const crossbite = issueListFormParser(data, "crossbite");
  const crossbiteScore = crossbite === "Present" ? 5 : 0;

  const incisorVerticalBite = issueListFormParser(data, "incisorVerticalBite");
  const incisorVerticalBiteCalc = find(incisorCalculator, {
    key: incisorVerticalBite,
  });
  const incisorVerticalBiteScore =
    (incisorVerticalBiteCalc && incisorVerticalBiteCalc.val) || 0;

  const rightSagittal = issueListFormParser(data, "rightSagittal");
  const rightSagittalCalc = find(sagittalCalculator, { key: rightSagittal });
  const rightSagittalScore = (rightSagittalCalc && rightSagittalCalc.val) || 0;

  const leftSagittal = issueListFormParser(data, "leftSagittal");
  const leftSagittalCalc = find(sagittalCalculator, { key: leftSagittal });
  const leftSagittalScore = (leftSagittalCalc && leftSagittalCalc.val) || 0;

  const upperArchLength = issueListFormParser(data, "upperArchLength");
  const upperArchLengthCalc = find(upperArchLengthCalculator, {
    key: upperArchLength,
  });
  const upperArchLengthScore =
    (upperArchLengthCalc && upperArchLengthCalc.val) || 0;

  return (
    aestheticScore +
    crossbiteScore +
    incisorVerticalBiteScore +
    rightSagittalScore +
    leftSagittalScore +
    upperArchLengthScore
  );
};
export const getICONScoreData = staffSubmittedData => {
  const orderedEventList = orderBy(staffSubmittedData, "postTimestamp", "desc");
  const outcomeForm = find(orderedEventList, { formName: FORM_NAME.OUTCOME });
  const rrxForm = find(orderedEventList, { formName: FORM_NAME.RRX });
  const outcomeScore = calculateScore(outcomeForm);
  const initialScore = calculateScore(rrxForm);
  const caseComplexity = find(caseComplexityDefinition, definition => {
    return initialScore <= definition.score;
  });
  const treatmentNeed =
    initialScore >= 43 ? "Needs treatment" : "Does not need treatment";
  const outcome =
    outcomeScore >= 31 ? "Unsatisfactory" : "Satisfactory Outcome";
  const improvementGrade = find(improvementGradeDefinition, definition => {
    return initialScore - 4 * outcomeScore <= definition.score;
  });
  return {
    initialScore,
    caseComplexity,
    treatmentNeed,
    outcomeScore,
    outcome,
    improvementGrade,
  };
};
export default class OutcomeScore extends Component {
  static propTypes = {
    staffSubmittedData: PropTypes.array,
  };

  render() {
    const { staffSubmittedData } = this.props;
    const {
      initialScore,
      caseComplexity,
      treatmentNeed,
      outcomeScore,
      outcome,
      improvementGrade,
    } = getICONScoreData(staffSubmittedData);

    return (
      <div id="top-search" style={S.contentBlock}>
        <div style={S.sectionTitle}>ICON Score</div>
        <div>
          Initial score: <strong>{initialScore}</strong>
        </div>
        <div>
          Case complexity: <strong>{caseComplexity.name}</strong>
        </div>
        <div>
          Treatment need: <strong>{treatmentNeed}</strong>
        </div>
        <hr />
        <div>
          Outcome score: <strong>{outcomeScore}</strong>
        </div>
        <div>
          Outcome: <strong>{outcome}</strong>
        </div>
        <div>
          Improvement grade: <strong>{improvementGrade.name}</strong>
        </div>
      </div>
    );
  }
}
