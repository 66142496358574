import {
  useMutation,
  useQuery,
  UseQueryResponse,
} from "src/utils/http/gqlQuery";
import { Treatment, HoldCategory } from "src/types/gql";

export const useLastTreatment = (
  userID: string
): UseQueryResponse<{ treatment: Treatment }> => {
  return useQuery({ query: lastTreatmentGQL, variables: { userID } });
};

export const useActiveTreatment = (
  userID: string
): UseQueryResponse<{ treatment: Treatment }> => {
  return useQuery({ query: activeTreatmentGQL, variables: { userID } });
};

export const useUpdateTreatment = () => {
  const [, execute] = useMutation<
    { updateTreatment: Treatment },
    { updateRequest: UpdateTreatmentType; deleteFields: Array<string> }
  >(updateTreatment);
  return execute;
};

export const updateTreatment = `
  mutation UpdateTreatment($updateRequest: UpdateTreatment!, $deleteFields: [String]) {
    updateTreatment(updateRequest:$updateRequest) @deleteFields(fields: $deleteFields) {
      id
      dismissedById
      dismissalReason
      startDate
      endDate
      onHoldCategory
      onHoldReason
      onHoldDate
    }
  }
`;

export type UpdateTreatmentType = {
  id: string;
  startDate?: string;
  endDate?: string;
  onHoldCategory?: HoldCategory;
  onHoldReason?: string;
  onHoldDate?: string;
  dismissalReason?: string;
};

const lastTreatmentGQL = `
  query LastTreatment($userID: ID!) {
    treatment: activeOrLastTreatment(userID: $userID) {
      id
      startDate
      endDate
      treatmentType
      dismissedById
      dismissalReason
      onHoldCategory
      onHoldReason
      onHoldDate

      activeOption {
        id
        estimatedUpperBound
      }

      treatmentCycles {
        id
        endType
        startsAt
        maxCount
        cadence
        phases {
          id
          phaseType
          upperCount
          lowerCount
          appointmentSubtypeID
          startsAt
        }
      }

      beginningDoctor {
        id
        name
      }
      beginningClinic {
        id
        name
      }
    }
  }
`;

const activeTreatmentGQL = `
  query ActiveTreatment($userID: ID!) {
    treatment: activeTreatment(userID: $userID) {
      id
      startDate
      endDate
      treatmentType
      dismissedById
      dismissalReason
      onHoldCategory
      onHoldReason
      onHoldDate

      activeOption {
        id
        estimatedUpperBound
      }

      treatmentCycles {
        id
        endType
        startsAt
        maxCount
        cadence
        phases {
          id
          phaseType
          upperCount
          lowerCount
          appointmentSubtypeID
          startsAt
        }
      }

      beginningDoctor {
        id
        name
      }
      beginningClinic {
        id
        name
      }
    }
  }
`;
