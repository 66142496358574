import React from "react";
import { CrownProps, RootProps } from "./types";

export const Crown: React.SFC<CrownProps> = ({ fill, stroke, style }) => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M7.76256 3.91334C12.2384 -1.29034 17.5846 2.98411 19.6982 5.7718C23.4281 11.9047 23.2416 17.6659 23.2416 23.9847C23.2416 29.0397 19.3874 30.4273 17.4603 30.4893H4.77864C3.34884 30.6751 0.601154 30.0061 1.04874 25.8431C1.60823 20.6395 2.16771 10.4179 7.76256 3.91334Z"
      fill={fill || "#fff"}
      stroke={stroke || "#A2A9AD"}
      strokeWidth="1.5"
    />
  </svg>
);

// Root always contains the portion as if it attached as a Crown.
export const Root: React.SFC<RootProps> = ({
  hasRootCanal,
  style,
  fill,
  stroke,
}) => {
  return (
    <svg
      width="19"
      height="52"
      viewBox="0 0 19 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M3.02022 11.4974C3.61714 22.5393 1.80833 37.5774 1 45H7V51H13V45H18C17.8135 35.2463 15.7048 23.2754 14.5856 17.3864C13.4664 11.4974 9.92215 3.58409 8.05676 2.29587C6.19137 1.00765 2.27406 -2.30491 3.02022 11.4974Z"
        fill={fill || "#F1F4F5"}
        stroke={stroke || "#A2A9AD"}
        strokeWidth="1.5"
      />

      {hasRootCanal && (
        <g transform="translate(6,1)">
          <path
            d="M0 0C1.6 13.2 2.66667 36.5 2 42L6.5 43C5.7 26.2 1.16667 4.33333 0 0Z"
            fill="#C270A7"
          />
        </g>
      )}
    </svg>
  );
};
