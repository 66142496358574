import React, { useState } from "react";
import moment, { Moment as MomentType } from "moment";
import styled from "react-emotion";
import {
  DatePicker,
  Switch,
  Input,
  AutoComplete,
  Button,
  notification,
} from "antd";

import { useQuery, useMutation } from "src/utils/http/gqlQuery";
import Loading from "src/shared/Loading";
import StateSelect from "src/shared/OldStateSelect";
import { DetailedPatient } from "src/types/api";
import { getErrorMessage } from "src/utils/error";

interface Props {
  patient: DetailedPatient;
  afterSave?: () => void;

  // The current insurance policy, if we're editing.
  current?: FormValues;
}

interface FormUI {
  filter: string;
  isPrimary: boolean;
}

interface FormValues {
  id?: string;
  userId: string;
  state: string;
  insuranceProviderId: string | undefined;
  policyId: string | undefined;
  subscriberFirstName: string | undefined;
  subscriberLastName: string | undefined;
  subscriberMemberId: string | undefined;
  subscriberDOB: string | undefined;
  dependentFirstName?: string;
  dependentLastName?: string;
  dependentMemberId?: string;
  dependentDOB?: string;
}

interface Form extends FormUI, FormValues {}

const providers = `
query SelectProviders {
  insuranceProviders {
    id,
    name,
    States
  }
}
`;

const updatePolicy = `
mutation UpdateInsurancePolicy($id: ID!, $input: EditInsurancePolicy!) {
  updateInsurancePolicy(id: $id, input: $input) {
    id,
    state,
    policyId,
    policyName,
    policyType,
    subscriberMemberId,
    subscriberFirstName,
    subscriberLastName,
    subscriberDOB,
    dependentMemberId,
    dependentFirstName,
    dependentLastName,
    dependentDOB,
    InsuranceProvider {
      name
    },
  }
}
`;

const createPolicy = `
mutation CreateInsurancePolicy($input: NewInsurancePolicy!) {
  createInsurancePolicy(input: $input) {
    id,
    state,
    policyId,
    policyName,
    policyType,
    subscriberMemberId,
    subscriberFirstName,
    subscriberLastName,
    subscriberDOB,
    dependentMemberId,
    dependentFirstName,
    dependentLastName,
    dependentDOB,
    InsuranceProvider {
      name
    },
  }
}
`;

const PolicyForm = ({ patient, afterSave, current }: Props) => {
  const [{ fetching, data }] = useQuery({
    query: providers,
  });

  // Are we creating or updating the policy?
  const isUpdate = current && current.id !== undefined;

  const [form, setForm] = useState<Form>(
    Object.assign(
      {
        filter: "",
        isPrimary: true,

        userId: patient.id,
        state: "",
        insuranceProviderId: undefined,
        policyId: undefined,
        subscriberFirstName: patient.first_name,
        subscriberLastName: patient.last_name,
        subscriberMemberId: undefined,
        subscriberDOB: patient.date_of_birth,
        dependentFirstName: undefined,
        dependentLastName: undefined,
        dependentMemberId: undefined,
        dependentDOB: undefined,
      },
      current || {}
    )
  );

  const [, update] = useMutation(updatePolicy);
  const [, create] = useMutation(createPolicy);

  const submit = async evt => {
    evt.preventDefault();

    const { filter, isPrimary, ...input } = form;

    let resp;
    if (isUpdate && current) {
      // Remove unused field from input
      [
        "id",
        "userId",
        "InsuranceProvider",
        "Eligibilities",
        "__typename",
      ].forEach(e => delete input[e]);
      resp = await update({ id: current.id, input });
    } else {
      resp = await create({ input });
    }

    if (resp.error) {
      notification.error({
        message: getErrorMessage(resp.error.toString()),
      });
      return;
    }

    notification.success({
      message: isUpdate ? "Policy updated" : "Policy created",
    });

    afterSave && afterSave();
  };

  if (fetching) {
    return <Loading />;
  }

  const source = data.insuranceProviders
    .filter(p => p.States.indexOf(form.state) !== -1)
    .filter(
      ds => ds.name.toUpperCase().indexOf(form.filter.toUpperCase()) !== -1
    )
    .map(p => ({
      value: p.id,
      text: p.name,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

  const handleChangeFor = (field: string) => evt => {
    setForm({ ...form, [field]: evt.target.value });
  };

  return (
    <Form onSubmit={submit}>
      <Header>{isUpdate ? "Update " : "New "} insurance policy</Header>
      <Grid>
        <div>
          <Label>State</Label>
          <StateSelect
            onChange={(to: string) =>
              setForm({ ...form, state: to, filter: "" })
            }
            value={form.state}
          />
        </div>
        <div>
          <Label>Insurance company</Label>
          <AutoComplete
            disabled={form.state === ""}
            dataSource={source}
            defaultValue={form.insuranceProviderId}
            onChange={val =>
              setForm({
                ...form,
                filter: val.toString(),
                insuranceProviderId: val.toString(),
              })
            }
            onSelect={val => {
              setForm({ ...form, insuranceProviderId: val.toString() });
            }}
          />
        </div>
        <div>
          <Label>Optional Policy ID</Label>
          <Input
            placeholder="Policy ID"
            onChange={handleChangeFor("policyId")}
          />
        </div>
      </Grid>

      <div style={{ margin: "1rem 0 0.75rem 0" }}>
        <Label>Patient is the primary subscriber</Label>
        <Switch
          size="small"
          style={{ marginLeft: "1rem" }}
          checked={form.isPrimary}
          onChange={(to: boolean) => {
            setForm({
              ...form,
              isPrimary: to,
              subscriberFirstName: to ? patient.first_name : undefined,
              subscriberLastName: to ? patient.last_name : undefined,
              subscriberDOB: to ? patient.date_of_birth : undefined,
              subscriberMemberId: undefined,

              dependentFirstName: !to ? patient.first_name : undefined,
              dependentLastName: !to ? patient.last_name : undefined,
              dependentDOB: !to ? patient.date_of_birth : undefined,
              dependentMemberId: undefined,
            });
          }}
        />
      </div>

      <Grid>
        <div>
          <Label>Subscriber first name</Label>
          <Input
            placeholder="First name"
            value={form.subscriberFirstName}
            onChange={handleChangeFor("subscriberFirstName")}
          />
        </div>
        <div>
          <Label>Subscriber last name</Label>
          <Input
            placeholder="Last name"
            value={form.subscriberLastName}
            onChange={handleChangeFor("subscriberLastName")}
          />
        </div>
        <div>
          <Label>Subscriber Member ID</Label>
          <Input
            placeholder="Member ID (don't add dashes to SSNs: 5556667890)"
            value={form.subscriberMemberId}
            onChange={handleChangeFor("subscriberMemberId")}
          />
        </div>
        <div>
          <Label>Subscriber date of birth</Label>
          <DatePicker
            placeholder="Date of birth"
            value={form.subscriberDOB ? moment(form.subscriberDOB) : undefined}
            onChange={(date: MomentType | null) => {
              date &&
                setForm({ ...form, subscriberDOB: date.format("YYYY-MM-DD") });
            }}
          />
        </div>
      </Grid>

      {!form.isPrimary && (
        <Grid style={{ marginTop: "1rem" }}>
          <div>
            <Label>Dependent first name</Label>
            <Input
              placeholder="First name"
              value={form.dependentFirstName}
              onChange={handleChangeFor("dependentFirstName")}
            />
          </div>
          <div>
            <Label>Dependent last name</Label>
            <Input
              placeholder="Last name"
              value={form.dependentLastName}
              onChange={handleChangeFor("dependentLastName")}
            />
          </div>
          <div>
            <Label>Dependent Member ID</Label>
            <Input
              placeholder="Member ID (don't add dashes to SSNs: 5556667890)"
              value={form.dependentMemberId}
              onChange={handleChangeFor("dependentMemberId")}
            />
          </div>
          <div>
            <Label>Dependent date of birth</Label>
            <DatePicker
              placeholder="Date of birth"
              value={form.dependentDOB ? moment(form.dependentDOB) : undefined}
              onChange={date => {
                date &&
                  setForm({ ...form, dependentDOB: date.format("YYYY-MM-DD") });
              }}
            />
          </div>
        </Grid>
      )}

      <Button htmlType="submit" type="primary" style={{ marginTop: "1rem" }}>
        Save
      </Button>
    </Form>
  );
};

export default PolicyForm;

const Header = styled("h2")`
  font-weight: 300;
  font-size: 1.125rem;
  color: #728389;
`;

const Form = styled("form")``;

const Grid = styled("div")`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 1rem;
`;

const Label = styled("label")`
  color: #728389;
  line-height: 2;
`;
