import React, { useState } from "react";
import styled from "react-emotion";
import Loading from "src/shared/Loading";
import { Modal, Button } from "antd";

import { useQuery } from "src/utils/http/gqlQuery";
import PolicyForm from "./PolicyForm";
import Policy from "./Policy";
import { DetailedPatient } from "src/types/api";
// TODO: REMOVE
import Sidebar from "src/containers/insurance/sidebar";

const policies = `
query SelectPatientPolicies($userId: ID!) {
  insurancePolicies(userId: $userId) {
    id,
    state,
    policyId,
    policyName,
    policyType,
    subscriberMemberId,
    subscriberFirstName,
    subscriberLastName,
    subscriberDOB,
    dependentMemberId,
    dependentFirstName,
    dependentLastName,
    dependentDOB,

    InsuranceProvider {
      id,
      name
    },

    Eligibilities {
      id,
      insuranceType,
      calendarYear,
      calendarYearRemaining,
      lifetimeMax,
      lifetimeMaxRemaining,
      percent
    }
  }
}
`;

interface Props {
  patient: DetailedPatient;
}

const InsuranceList = ({ patient }: Props) => {
  const [showForm, setShowForm] = useState(false);
  const [{ fetching, data, error }] = useQuery({
    query: policies,
    variables: { userId: patient.id },
  });

  if (fetching) {
    return <Loading />;
  }

  if (error) {
    return <p>Error: {JSON.stringify(error)}</p>;
  }

  return (
    <div style={{ flex: 1 }}>
      <Actions>
        <Button style={{ margin: "0 1rem" }} onClick={() => setShowForm(true)}>
          Add new policy
        </Button>
        <Sidebar patientId={patient.id} />
      </Actions>

      {data.insurancePolicies.length === 0 && <p>No policies added</p>}

      <Policies>
        {data.insurancePolicies.map(p => (
          <Policy key={p.id} patient={patient} policy={p} />
        ))}
      </Policies>

      <Modal
        visible={showForm}
        footer={null}
        onCancel={() => setShowForm(false)}
      >
        <div style={{ maxHeight: 400, width: 650 }}>
          <PolicyForm patient={patient} afterSave={() => setShowForm(false)} />
        </div>
      </Modal>
    </div>
  );
};

export default InsuranceList;

const Actions = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin: 0 0 1rem;
`;

const Policies = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
`;
